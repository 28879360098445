import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { DbUtils } from '@smooved/core';

@Component({
    selector: 'app-telecom-offer-suggestion',
    template: `
        <div
            class="u-flex-row u-flex-align-items-center"
            [ngSwitch]="!!move?.telecomOffer?.telecomOfferSelection"
            [ngClass]="{ 'u-color-muted': !move?.telecomOffer?.telecomOfferSelection }"
        >
            <div *ngSwitchCase="true">
                <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                <span>{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.SELECTION_FOUND' | translate }}</span>
            </div>
            <div *ngSwitchCase="false">
                <div class="u-flex-row u-flex-align-items-center">
                    <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                    <span>{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.SELECTION_FOUND' | translate }}</span>
                </div>
                <div class="u-margin-left u-margin-top-half">
                    <app-button [theme]="'secondary'" (onClick)="impersonate()">{{
                        'ADMIN.DASHBOARD.DETAIL.TELECOM.CREATE' | translate
                    }}</app-button>
                </div>
            </div>
        </div>
    `,
})
export class TelecomOfferSuggestionComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private navigationSandbox: AppNavigationSandbox,
        public dialogRef: MatDialogRef<any>
    ) {
        super(null, moveSandbox);
    }

    public impersonate(): void {
        this.moveSandbox.impersonate(DbUtils.getStringId(this.move), this.dialogRef).subscribe((_) => {
            this.navigationSandbox.goToTelecomFlow().then();
        });
    }
}
