import { FeatureScopesConfig } from '../interfaces/feature-scopes-config';
import { createAction, props } from '@ngrx/store';
import { FeatureScopeState } from './feature-scope.reducer';

export const getFeatureScopesSuccess = createAction('[Feature scope] get feature scopes success', props<FeatureScopeState>());

/**
 * Bugfix: temp solution to be able to set featureScopeConfig during impersonation
 * Refactor Issue #1317: https://gitlab.com/smooved-app/smooved-app/-/issues/1317
 * */
export const setFeatureScopesConfig = createAction(
    '[Feature scope] set feature scopes config',
    props<{ featureScopesConfig: FeatureScopesConfig }>()
);
