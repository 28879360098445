import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { Colors } from '../../../charts';
import { animationDelayMs, initialWidth } from './review-rating-bar.constants';

@Component({
    selector: 'ui-review-rating-bar',
    templateUrl: './review-rating-bar.component.html',
    styleUrls: ['./review-rating-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewRatingBarComponent implements OnChanges {
    @Input() public rating: number;
    @Input() public total: number;
    @Input() public color: Colors;

    public width = initialWidth;

    constructor(private readonly cdr: ChangeDetectorRef) {}

    public ngOnChanges({ rating, total }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(rating) || SimpleChangesUtils.hasChanged(total)) {
            setTimeout(() => {
                const newWidth = this.calculateWidth();
                if (this.width !== newWidth) {
                    this.width = newWidth;
                    this.cdr.detectChanges();
                }
            }, animationDelayMs);
        }
    }

    private calculateWidth(): any {
        return `${(this.rating / this.total) * 100}%`;
    }
}
