import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { ExternalInfluencerReviews, Interviewee, ObjectUtils, ReviewsData, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { CheckInput } from '../../../form';
import { EmittableReviewsFilter, ReviewSegment } from '../../../reviews/interfaces';
import { ReviewsUtils } from '../../../reviews/reviews.utils';
import { NpsReviewsFilterService } from '../../../reviews/services/nps-reviews-filter.service';
import { SvgIllustration } from '../../../svg';
import { UiContext } from '../../../ui.enums';
import { defaultTitle, SegmentsForm } from './reviews-segments.constants';

@Component({
    selector: 'smvd-ui-reviews-segments',
    templateUrl: 'reviews-segments.component.html',
    styleUrls: ['./reviews-segments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ReviewsSegmentsComponent extends RxjsComponent implements OnInit, AfterViewInit, OnChanges, CanDisable {
    @Input() disabled: boolean;
    @Input() title = defaultTitle;
    @Input() showArcScore = true;

    @Input()
    public set reviews(value: ExternalInfluencerReviews) {
        this.setSegments(value);
        this.setFormSegments();
    }

    @Output()
    public selected = new EventEmitter<Interviewee[]>();

    public filter: Interviewee[] = [];
    public segments: ReviewSegment[];

    public uiContext = UiContext;
    public svgIllustration = SvgIllustration;
    public readonly segmentsForm = SegmentsForm;
    public readonly form = new UntypedFormGroup({
        [SegmentsForm.Segments]: new UntypedFormArray([]),
    });

    constructor(private readonly filterService: NpsReviewsFilterService, private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public get segmentsFormArray(): UntypedFormArray {
        return this.form.controls[SegmentsForm.Segments] as UntypedFormArray;
    }

    public ngOnInit(): void {
        this.reset();
        this.filterService.filter$.pipe(takeUntil(this.destroy$)).subscribe(this.handleFilterChanged);
    }

    public ngAfterViewInit(): void {
        this.segmentsFormArray.valueChanges
            .pipe(skipWhile(this.isSegmentsEmpty), takeUntil(this.destroy$))
            .subscribe(this.handleInnerFilterChanged);
    }

    public ngOnChanges({ disabled }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(disabled)) {
            this.updateFormSegmentsDisabled();
        }
    }

    private setSegments(value: ExternalInfluencerReviews): void {
        if (value?.categories) {
            this.segments = value.categories.map((category) => this.setReviewSegment(category.type, category.data));
        } else {
            this.segments = Object.keys(Interviewee).map((key) => this.setReviewSegment(Interviewee[key], null));
        }
    }

    private setReviewSegment(type: Interviewee, data: ReviewsData): ReviewSegment {
        return {
            type,
            data,
            chartData: ReviewsUtils.mapArcScore(data),
            option: this.setOption(type),
        };
    }

    private setOption(value: Interviewee): CheckInput<Interviewee> {
        return {
            id: value,
            name: 'segment',
            value,
        };
    }

    private setFormSegments(): void {
        if (this.segmentsFormArray.length) return;
        this.segments.forEach(() => this.segmentsFormArray.push(new UntypedFormControl({ value: null, disabled: this.disabled })));
    }

    private updateFormSegmentsDisabled(): void {
        if (!this.segmentsFormArray) return;
        this.segmentsFormArray.controls.forEach((segment) => (this.disabled ? segment.disable() : segment.enable()));
    }

    private handleInnerFilterChanged = (value: Interviewee[]): void => {
        const segments = value.reduce((list: Interviewee[], bucket: Interviewee) => (bucket ? [...list, bucket] : list), []);
        if (ObjectUtils.isEqual(this.filter, segments || [])) return;
        this.filter = segments;
        this.filterService.filterBySegment(this.filter);
        this.selected.emit(this.filter);
    };

    private handleFilterChanged = ({ emit, filter }: EmittableReviewsFilter): void => {
        if (!ObjectUtils.isEqual(this.filter, filter.segments || [])) this.reset(emit);
    };

    private isSegmentsEmpty = (values: Interviewee[]): boolean => {
        return values.every((val) => !val);
    };

    private reset(emitEvent = true): void {
        this.filter = this.filterService.filter.segments || [];
        const segments = this.segments.map((segment) => this.filter.includes(segment.option.id as Interviewee));
        this.segmentsFormArray.patchValue(segments, { emitEvent });
        this.cdr.markForCheck();
    }
}
