import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { UiContext } from '../../../ui.enums';
import { SvgIllustration } from './svg-illustration.enum';

@Component({
    selector: 'app-svg-illustration, a[app-svg-illustration], smvd-ui-svg-illustration, a[smvd-ui-svg-illustration]',
    template: `<div [inlineSVG]="svg" [class]="classNames"></div>`,
    styleUrls: ['./svg-illustration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class SvgIllustrationComponent implements OnChanges {
    @Input() public svg: SvgIllustration;
    @Input() public context: UiContext;
    @Input() public grayscale = false;

    public classNames: string;

    public ngOnChanges({ context, grayscale }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(context, grayscale)) {
            this.classNames = [this.getContextClass(this.context), this.grayscaleClass()].join(' ');
        }
    }

    private getContextClass(context: UiContext): string {
        return context ? `u-color-${context}` : '';
    }

    private grayscaleClass(): string {
        return this.grayscale ? 'u-filter-grayscale' : '';
    }
}
