import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CoreProvidersModule } from '../core.providers.module';

@Injectable({
    providedIn: CoreProvidersModule,
})
export class NavigationSandbox {
    protected startRouterEvents = startWith(new NavigationEnd(0, this.location.path(), this.location.path()));
    protected onNavigationStart = filter((event) => event instanceof NavigationStart);
    protected onNavigationEnd = filter((event) => event instanceof NavigationEnd);

    private getRouteData = map(() => {
        let data: { [key: string]: any } = {};
        let route = this.route.snapshot;

        while (route) {
            if (route.outlet === 'primary') data = { ...data, ...route.data };
            route = route.firstChild;
        }

        return data;
    });

    public routeNavigationStartEvent$: Observable<NavigationStart> = this.router.events.pipe(
        this.startRouterEvents,
        this.onNavigationStart,
        map((event) => <NavigationStart>event)
    );

    public routeNavigationEndEvent$: Observable<NavigationEnd> = this.router.events.pipe(
        this.startRouterEvents,
        this.onNavigationEnd,
        map((event) => <NavigationEnd>event)
    );

    public routeNavigationEndData$ = this.router.events.pipe(this.startRouterEvents, this.onNavigationEnd, this.getRouteData);

    constructor(
        protected router: Router,
        protected readonly route: ActivatedRoute,
        protected readonly location: Location
    ) {}
}
