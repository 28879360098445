import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { contractSuccessRoute, meterReadingsRoute } from '@app/energy-contract/energy-contract.constants';
import { checkboxRequired } from '@app/form/validators/checkbox-required.validator';
import { Move } from '@app/move/interfaces/move';
import { MoveConversionSandbox } from '@app/move/sandboxes/move-conversion.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { CreatedByFlow } from '@app/wizard/move/interfaces/created-by-flow.interface';
import { MoveForLeaver } from '@app/wizard/move/interfaces/move-for-leaver';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { filter, takeUntil } from 'rxjs/operators';
import {
    createdByEots,
    ILeaverDetailsForm,
    languageOptions,
    LeaverDetailsForm,
    leaverTypeOptions,
    realEstateAgentConsentOption,
} from './contact-details-leaver.constants';

@Component({
    selector: 'app-contact-details-leaver-container',
    templateUrl: './contact-details-leaver.container.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ContactDetailsLeaverContainer extends RxjsComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup = this.formBuilder.group({
        [LeaverDetailsForm.LeaverType]: null,
        [LeaverDetailsForm.FirstName]: [null, [Validators.required]],
        [LeaverDetailsForm.LastName]: [null, [Validators.required]],
        [LeaverDetailsForm.PhoneNumber]: [null, [Validators.required]],
        [LeaverDetailsForm.Email]: [
            null,
            {
                validators: [Validators.required, Validators.email],
                updateOn: 'blur',
            },
        ],
        [LeaverDetailsForm.RealEstateAgentConsent]: [false, checkboxRequired],
        [LeaverDetailsForm.Language]: this.translateService.currentLang,
    });

    public readonly leaverDetailsForm = LeaverDetailsForm;
    public readonly leaverTypeOptions = leaverTypeOptions;
    public readonly realEstateAgentConsentOption = realEstateAgentConsentOption;
    public readonly languageOptions = languageOptions;
    public readonly uiContext = UiContext;
    public readonly today = DateUtils.today();
    public movingDate: Date;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly moveSandbox: MoveSandbox,
        private readonly moveConversionSandbox: MoveConversionSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.moveOnce$.pipe(filter((x) => !!x)).subscribe((moveState) => {
            this.leaverTypeFormControl().patchValue(moveState.leaverType);
            this.phoneNumberFormControl().patchValue(moveState.phoneNumberLeaver);
            this.emailFormControl().patchValue(moveState.emailLeaver);
            if (moveState.languageLeaver) {
                this.languageFormControl().patchValue(moveState.languageLeaver);
            }
            this.handleEmailChanges();
            this.movingDate = moveState.movingDate;
            if (MoveUtils.meterComplete(moveState)) {
                this.form.addControl(LeaverDetailsForm.MeterReadingsTakeover, this.formBuilder.control(false, Validators.requiredTrue));
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.moveSandbox.moveOnce$.subscribe((moveState) => {
            const {
                leaverType,
                firstName: firstNameLeaver,
                lastName: lastNameLeaver,
                email: emailLeaver,
                phoneNumber: phoneNumberLeaver,
                language: languageLeaver,
                realEstateAgentConsent,
            } = <ILeaverDetailsForm>this.form.getRawValue();
            const payload: MoveForLeaver = {
                id: moveState._id,
                leaverType,
                firstNameLeaver,
                lastNameLeaver,
                emailLeaver,
                phoneNumberLeaver,
                languageLeaver,
                legal: {
                    realEstateAgent: realEstateAgentConsent,
                },
            };

            const createdByFlow: CreatedByFlow =
                this.isEots() || this.navigationSandbox.isEotsRoute() ? createdByEots : moveState.createdByFlow;

            this.moveSandbox.createMoveForLeaverByFlow(payload, createdByFlow, this.handleNextPageNavigation);
        });
    }

    public goToPrevious(): void {
        const extras: NavigationExtras = {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
        };
        void this.router.navigate([meterReadingsRoute], extras).then();
    }

    public isEots(): boolean {
        return this.route.snapshot.queryParams.eots === 'true';
    }

    public onSkipStep(): void {
        this.handleNextPageNavigation();
    }

    public ngOnDestroy(): void {
        const {
            leaverType,
            firstName: firstNameLeaver,
            lastName: lastNameLeaver,
            email: emailLeaver,
            phoneNumber: phoneNumberLeaver,
            meterReadingsTakeover,
            realEstateAgentConsent,
        } = <ILeaverDetailsForm>this.form.getRawValue();
        const payload: Move = {
            leaverType,
            firstNameLeaver,
            lastNameLeaver,
            phoneNumberLeaver,
            emailLeaver,
            legal: {
                realEstateAgent: realEstateAgentConsent,
            },
        };
        if (this.form.get(LeaverDetailsForm.MeterReadingsTakeover)) {
            payload.legal.meterReadingsTakeover = meterReadingsTakeover;
        }
        this.moveSandbox.patchProperty('', payload);
    }

    public openMeterInfo(): void {
        this.realEstateAgentModalsSandbox.openMeterInfoModal();
    }

    private leaverTypeFormControl(): AbstractControl {
        return this.form?.get(LeaverDetailsForm.LeaverType);
    }

    private phoneNumberFormControl(): AbstractControl {
        return this.form?.get(LeaverDetailsForm.PhoneNumber);
    }

    private emailFormControl(): AbstractControl {
        return this.form?.get(LeaverDetailsForm.Email);
    }

    private languageFormControl(): AbstractControl {
        return this.form?.get(LeaverDetailsForm.Language);
    }

    private handleEmailChanges(): void {
        this.emailFormControl()
            .valueChanges.pipe(
                filter((value) => !!value),
                takeUntil(this.destroy$)
            )
            .subscribe(this.checkActiveMove);
    }

    private checkActiveMove = (value: string): void => {
        this.moveSandbox.getActiveMoveByEmail(value).subscribe(this.handleActiveMove);
    };

    private handleActiveMove = (activeMove: Move): void => this.moveConversionSandbox.showPrefillMove(activeMove, this.populateData);

    private populateData = (activeMove: Move): void => {
        if (!activeMove) return;
        const formData = this.form.getRawValue() as ILeaverDetailsForm;
        const patch = {
            ...formData,
            [LeaverDetailsForm.FirstName]: formData[LeaverDetailsForm.FirstName] || activeMove.user?.firstName,
            [LeaverDetailsForm.LastName]: formData[LeaverDetailsForm.LastName] || activeMove.user?.lastName,
            [LeaverDetailsForm.PhoneNumber]: formData[LeaverDetailsForm.PhoneNumber] || activeMove.user?.phoneNumber,
        };
        this.form.patchValue(patch, { emitEvent: false });
    };

    private handleNextPageNavigation = (): void => {
        const extras: NavigationExtras = {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
        };
        void this.router.navigate([contractSuccessRoute], extras);
    };
}
