<app-card [bordered]="false">
    <ng-container>
        <main class="u-flex-column u-flex-align-items-stretch">
            <div class="u-flex-responsive-desktop-up">
                <div
                    class="u-flex-1-1-0 u-flex-column u-padding-right-triple-desktop-up u-margin-bottom-double-up-to-and-including-tablet-landscape"
                >
                    <div class="u-flex-row u-flex-align-items-center">
                        <h3 class="u-margin-right">
                            {{
                                'INVOICING.INVOICE.TITLE'
                                    | translate
                                        : {
                                              quarter: invoice?.quarter,
                                              year: invoice?.year
                                          }
                            }}
                        </h3>
                        <smvd-ui-invoice-status [invoiceStatus]="invoice.status"></smvd-ui-invoice-status>
                    </div>
                    <span class="u-color-gray-dark" *ngIf="invoice.version === version.v2">{{
                        'INVOICING.INVOICE.INVOICE_TO_ACCOUNTANT' | translate
                    }}</span>
                    <div class="u-flex-column u-flex-justify-content-flex-end u-flex-grow-1">
                        <app-alert
                            *ngIf="invoice.version === version.v1; else downloadSection"
                            class="u-margin-top"
                            [context]="uiContext.Info"
                            [icon]="uiIcon.Info"
                        >
                            {{ 'INVOICING.INVOICING_CHANGES_2024.LEGACY_INVOICE_ALERT' | translate }}
                        </app-alert>
                    </div>
                </div>
                <div>
                    <app-invoice-total-amount *ngIf="invoice.version === version.v2" [invoice]="invoice"></app-invoice-total-amount>
                </div>
            </div>
            <div *ngIf="invoice.version === version.v1">
                <ng-container *ngTemplateOutlet="downloadSection"></ng-container>
            </div>
        </main>
    </ng-container>
</app-card>

<ng-template #downloadSection>
    <ng-container *ngIf="invoice.version === version.v2; else downloadSectionV1">
        <ng-container
            *ngTemplateOutlet="downloadLink; context: { template: commissionTemplate, invoiceType: regInvoicingType.commission }"
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="downloadLink; context: { template: subscriptionTemplate, invoiceType: regInvoicingType.subscription }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #downloadSectionV1>
    <ng-container
        *ngTemplateOutlet="downloadLink; context: { template: netTemplate, invoiceType: regInvoicingType.commission }"
    ></ng-container>
</ng-template>

<ng-template #commissionTemplate>
    <div class="__download-text">{{ 'INVOICING.INVOICES_SENT.DOWNLOAD_COMMISSION_INVOICE' | translate }}</div>
</ng-template>
<ng-template #subscriptionTemplate>
    <div class="__download-text">{{ 'INVOICING.INVOICES_SENT.DOWNLOAD_SUBSCRIPTION_INVOICE' | translate }}</div>
</ng-template>
<ng-template #netTemplate>
    <div class="__download-text">{{ 'INVOICING.INVOICES_SENT.DOWNLOAD_NET_INVOICE' | translate }}</div>
</ng-template>

<ng-template #downloadLink let-template="template" let-invoiceType="invoiceType">
    <div
        (click)="downloadInvoice(invoiceType)"
        class="__download-wrapper u-flex-row u-cursor-pointer u-margin-top"
        [class.u-color-gray-dark]="!disableDownloadLink(invoiceType)"
        [class.u-color-gray-light]="disableDownloadLink(invoiceType)"
        [class.u-pointer-events-none]="disableDownloadLink(invoiceType)"
    >
        <div class="__download-icon u-margin-right">
            <app-svg-illustration [svg]="svgs.Download"></app-svg-illustration>
            <a #download class="u-display-none" download></a>
        </div>
        <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
</ng-template>
