import { AuthUser, Role } from '@smooved/core';

export class AuthUtils {
    public static isRealEstateAgent = (authorization: AuthUser): boolean => !!authorization?.realEstateAgentId;

    public static isImpersonatedRealEstateAgent = (authorization: AuthUser): boolean =>
        authorization?.role === Role.Admin && !!authorization?.realEstateAgentId;

    public static isRealEstateAgentOrImpersonated = (authorization: AuthUser): boolean =>
        AuthUtils.isRealEstateAgent(authorization) || AuthUtils.isImpersonatedRealEstateAgent(authorization);

    public static isAdmin = (authorization: AuthUser): boolean => authorization?.role === Role.Admin && !authorization?.realEstateAgentId;
}
