import { AfterViewInit, Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { documentTypes } from '@smooved/core';
import { BaseInput } from '@smooved/ui';
import { merge } from 'lodash';

@Component({
    selector: 'app-water-document-assets-input',
    template: `
        <h6>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.ASSETS' | translate }}
        </h6>
        <p class="u-color-muted u-font-size-small u-margin-bottom-half">
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.ASSETS_DESCRIPTION' | translate }}
        </p>

        <div class="u-margin-bottom u-flex-row u-flex-wrap u-flex-align-items-center">
            <app-thumbnail
                *ngFor="let file of move?.waterDocumentAssets; let last = last"
                [file]="file"
                class="u-margin-right"
                (detail)="detail.emit($event)"
            ></app-thumbnail>

            <app-thumbnail
                *ngFor="let preview of previews; let last = last"
                [isPreview]="true"
                class="u-margin-right"
                [file]="preview"
            ></app-thumbnail>
        </div>

        <p *ngIf="!(move?.waterDocumentAssets?.length > 0)" class="u-text-truncate u-white-space-no-wrap u-color-muted u-margin-bottom">
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.NO_ASSETS' | translate }}
        </p>

        <app-file-input
            id="image"
            [(ngModel)]="innerModel"
            *ngIf="!readOnly"
            multiple="true"
            [accept]="acceptTypes"
            [label]="'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.UPLOAD_ASSETS' | translate"
            (change)="onChange($event)"
        ></app-file-input>
    `,
    styleUrls: ['./water-document-assets-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WaterDocumentAssetsInputComponent),
            multi: true,
        },
    ],
})
export class WaterDocumentAssetsInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public formControlName: string;
    @Input() public move: Move;
    @Input() public readOnly = false;

    @Output() public preview: EventEmitter<void> = new EventEmitter<void>();
    @Output() public detail: EventEmitter<FileInput> = new EventEmitter<FileInput>();

    public readonly acceptTypes = documentTypes;
    public previews: FileInput[];
    public files: File[] = [];

    public innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onChange(event): void {
        if (event.target.files) {
            this.files = <File[]>event.target.files;
            this.previews = [];
            Array.from(event.target.files).forEach((file: File) => {
                const reader = new FileReader();
                reader.onload = (_) =>
                    this.previews.push(
                        merge(file, {
                            value: reader.result,
                        })
                    );
                reader.readAsDataURL(file);
            });
        }
        this.onModelChange();
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
