import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomMessageType } from '@app/communication/enums/custom-message-type.enum';
import { MessageContext } from '@app/communication/enums/message-context.enum';
import { CustomMessageTemplate } from '@app/communication/interfaces/custom-message-template';
import { CommunicationSandbox } from '@app/communication/sandboxes/communication.sandbox';
import { Move } from '@app/move/interfaces/move';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, Language, RxjsComponent } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { languageOptions } from 'projects/ui/src/lib/language/language-menu/language-menu.constants';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
    contextOptions,
    CustomMessageFilter,
    CustomMessageFilterEnum,
    messageTypeDefault,
    messageTypeOptions,
    senderOptions,
} from './custom-message-filter.constants';

@Component({
    selector: 'app-custom-message-filter',
    templateUrl: 'custom-message-filter.component.html',
})
export class CustomMessageFilterComponent extends RxjsComponent implements OnInit {
    public readonly optionsFields = CustomMessageFilterEnum;
    public readonly typeOptions = messageTypeOptions;
    public readonly senderOptions = senderOptions;
    public readonly contextOptions = contextOptions;
    public readonly languageOptions = languageOptions;
    public readonly i18nKeyType = AppI18nKeyType;
    public templateOptions$: Observable<DropdownInput<CustomMessageTemplate>[]>;

    @Input() move: Move;
    @Output() filterChange = new EventEmitter<CustomMessageFilter>();

    public configForm = this.fb.group({
        [CustomMessageFilterEnum.Type]: messageTypeDefault,
        [CustomMessageFilterEnum.Context]: null,
        [CustomMessageFilterEnum.Template]: null,
        [CustomMessageFilterEnum.Language]: this.translateService.defaultLang as Language,
    });

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly translationSandbox: TranslationSandbox,
        private readonly communicationSandbox: CommunicationSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.communicationSandbox.init(DbUtils.getStringId(this.move));
        this.templateOptions$ = combineLatest([
            this.configForm.get(CustomMessageFilterEnum.Context).valueChanges,
            this.communicationSandbox.templates$,
            this.translationSandbox.selected$,
        ]).pipe(map(this.setTemplateOptions), tap(this.resetTemplateOptions));

        this.configForm.get(CustomMessageFilterEnum.Language).patchValue(this.move.user.language, { emitEvent: false });
        this.configForm.get(CustomMessageFilterEnum.Type).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onMessageTypeChange);
        this.configForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => this.filterChange.next(values));
    }

    private setTemplateOptions = ([value, templates, lang]: [
        MessageContext,
        CustomMessageTemplate[],
        Language
    ]): DropdownInput<CustomMessageTemplate>[] => {
        return templates?.filter(this.filterByContextAndType(value)).map((t) => ({
            id: `template-${t._id}`,
            name: 'templates',
            value: t,
            label: t.name[lang.toString()],
        }));
    };

    private resetTemplateOptions = (): void => {
        this.configForm.get(CustomMessageFilterEnum.Template).reset(null, { emitEvent: false });
    };

    private onMessageTypeChange = (): void => {
        this.configForm.get(CustomMessageFilterEnum.Context).reset(null, { emitEvent: false });
        this.resetTemplateOptions();
    };

    private filterByContextAndType(selectedContext: MessageContext): (t: CustomMessageTemplate) => boolean {
        const selectedType = this.configForm.get(CustomMessageFilterEnum.Type).value as CustomMessageType;
        return ({ context, type }: CustomMessageTemplate): boolean => context === selectedContext && type === selectedType;
    }
}
