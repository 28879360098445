import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NumberUtils, SimpleChangesUtils } from '@smooved/core';
import { UiIconSize } from '../../../icon';
import { SvgIllustration } from '../../../svg';
import { UiContext } from '../../../ui.enums';
import { maxStarScoreDefault, minStarScore } from './star-score.constants';

@Component({
    selector: 'ui-star-score',
    templateUrl: 'star-score.component.html',
    styleUrls: ['star-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarScoreComponent implements OnChanges {
    @Input() max = maxStarScoreDefault;
    @Input() score: number = 0;
    @Input() width = '20px';
    @Input() context = UiContext.Warning;

    public readonly iconSize = UiIconSize;
    public readonly uiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;

    public vm = {
        inactiveStars: new Array(5),
        activeStars:  new Array(0),
        hasHalfStar: false,
    };

    public ngOnChanges({ max, score }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(max, score)) {
            const scoreStars = NumberUtils.clamp(NumberUtils.roundHalf(this.score), minStarScore, this.max);
            const vm = {
                activeStars: new Array(Math.floor(scoreStars) - (NumberUtils.hasDecimals(scoreStars) ? 1 : 0)),
                inactiveStars: new Array(this.max - Math.floor(scoreStars)),
                hasHalfStar: NumberUtils.hasDecimals(scoreStars),
            };
            if (vm.hasHalfStar) {
                vm.activeStars.push(undefined);
                vm.inactiveStars.pop();
            }
            this.vm = vm;
        }
    }
}
