import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ExternalInfluencerConfigureAboutComponent } from '@app/external-influencer/components/external-influencer-configure-about/external-influencer-configure-about.component';
import { ExternalInfluencerConfigureContactComponent } from '@app/external-influencer/components/external-influencer-configure-contact/external-influencer-configure-contact.component';
import { ExternalInfluencerConfigureHeaderComponent } from '@app/external-influencer/components/external-influencer-configure-header/external-influencer-configure-header.component';
import { ExternalInfluencerConfigureLocationsComponent } from '@app/external-influencer/components/external-influencer-configure-locations/external-influencer-configure-locations.component';
import { ExternalInfluencerConfigureContainer } from '@app/external-influencer/containers/external-influencer-configure/external-influencer-configure.container';
import { ConfigureExternalInfluencerModal } from '@app/external-influencer/modals/configure-external-influencer/configure-external-influencer.modal';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';
import { FormModule } from '@app/form/form.module';
import { RealEstateGroupModule } from '@app/real-estate-group/real-estate-group.module';
import { TranslationModule } from '@app/translation/translation.module';
import { NavigationModule } from '@app/ui/navigation/navigation.module';
import { ButtonModule, IconModule, MenuModule, PipeModule, UiModalModule } from '@smooved/ui';

@NgModule({
    declarations: [
        ExternalInfluencerConfigureContainer,
        ExternalInfluencerConfigureLocationsComponent,
        ExternalInfluencerConfigureHeaderComponent,
        ExternalInfluencerConfigureAboutComponent,
        ExternalInfluencerConfigureContactComponent,
        ConfigureExternalInfluencerModal,
    ],
    imports: [
        CommonModule,
        FormModule,
        TranslationModule,
        ButtonModule,
        RouterModule,
        NavigationModule,
        UiModalModule,
        MatTabsModule,
        MenuModule,
        RealEstateGroupModule,
        IconModule,
        PipeModule,
    ],
    exports: [ExternalInfluencerConfigureContainer],
    providers: [ExternalInfluencerService],
})
export class ExternalInfluencerModule {}
