import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ArrayUtils, CoreUtils, NpsReview, PaginationRequest, ReviewFilterBy, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { ReviewBucketRequest } from '../../analytics';
import { ANALYTICS_URI_CONFIG, AnalyticsUriConfig } from '../configs';
import { ReviewsFilter } from '../interfaces';
import { ReviewsDataResponse } from '../interfaces/reviews-data-response.interface';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(ANALYTICS_URI_CONFIG) public config: AnalyticsUriConfig
    ) {}

    public getReview(id: string): Observable<NpsReview> {
        return this.httpClient.get<NpsReview>(StringUtils.parseUri(this.config.reviewUri, { id }));
    }

    public getReviews(
        realEstateGroupId: string,
        reviewFilter: ReviewsFilter = {},
        paginationRequest?: PaginationRequest
    ): Observable<ReviewsDataResponse> {
        let params: HttpParams = new HttpParams();
        if (reviewFilter.durationFilter) {
            params.append('interval', reviewFilter.durationFilter);
        }
        if (paginationRequest) {
            params = params
                .append('pageIndex', paginationRequest.pageIndex?.toString() || null)
                .append('pageSize', paginationRequest.pageSize?.toString() || null)
                .append('sortDirection', paginationRequest.sortDirection?.toString() || null);
        }

        params = this.setReviewFilterParams(reviewFilter, params);

        return this.httpClient.get<ReviewsDataResponse>(StringUtils.parseUri(this.config.reviewsUri, { realEstateGroupId }), {
            params,
        });
    }

    public getReviewBuckets(realEstateGroupId: string, reviewFilter: ReviewsFilter): Observable<ReviewBucketRequest> {
        let params = new HttpParams();
        params = this.setReviewFilterParams(reviewFilter, params);
        return this.httpClient.get<ReviewBucketRequest>(this.config.bucketsUri.replace(':realEstateGroupId', realEstateGroupId), {
            params,
        });
    }

    private setReviewFilterParams(filter: ReviewsFilter, httpParams: HttpParams): HttpParams {
        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.scoreFilter))) {
            httpParams = httpParams.append(ReviewFilterBy.Score, JSON.stringify(filter.scoreFilter));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.group))) {
            httpParams = httpParams.append(ReviewFilterBy.Group, filter.group);
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.unanswered))) {
            httpParams = httpParams.append(ReviewFilterBy.Unanswered, JSON.stringify(filter.unanswered));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.source))) {
            httpParams = httpParams.append(ReviewFilterBy.Source, JSON.stringify(filter.source));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.bucket))) {
            httpParams = httpParams.append(ReviewFilterBy.Bucket, JSON.stringify(filter.bucket));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.segments))) {
            httpParams = httpParams.append(ReviewFilterBy.Segment, JSON.stringify(filter.segments));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.ids))) {
            httpParams = httpParams.append(ReviewFilterBy.Id, JSON.stringify(filter.ids));
        }

        if (!CoreUtils.isEmpty(filter.location)) {
            httpParams = httpParams.append(ReviewFilterBy.Location, filter.location);
        }

        if (filter.includeAllExternal) {
            httpParams = httpParams.append(ReviewFilterBy.IncludeAllExternal, JSON.stringify(filter.includeAllExternal));
        }

        if (filter.extendWithOtherSources) {
            httpParams = httpParams.append(ReviewFilterBy.ExtendWithOtherSources, JSON.stringify(filter.extendWithOtherSources));
        }

        if (filter.realEstateAgentId) {
            httpParams = httpParams.append(ReviewFilterBy.RealEstateAgentId, filter.realEstateAgentId);
        }

        return httpParams;
    }
}
