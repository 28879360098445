import { HttpParams } from '@angular/common/http';
import { PaginationRequest } from '@smooved/core';
import { isNull, isNumber, isUndefined } from 'lodash';

export class RequestBuilder {
    private formData: FormData = new FormData();
    private httpParams: HttpParams = new HttpParams();

    public addPagination(paginationRequest: PaginationRequest): RequestBuilder {
        if (paginationRequest.sortDirection) {
            this.addHttpParam('sortDirection', paginationRequest.sortDirection);
        }

        if (paginationRequest.sortField) {
            this.addHttpParam('sortField', paginationRequest.sortField);
        }

        if (isNumber(paginationRequest.pageIndex)) {
            this.addHttpParam('pageIndex', paginationRequest.pageIndex?.toString());
        }

        if (isNumber(paginationRequest.pageSize)) {
            this.addHttpParam('pageSize', paginationRequest.pageSize?.toString());
        }

        return this;
    }

    public addHttpParam(label: string, value: any): RequestBuilder {
        if (isUndefined(value) || isNull(value) || value === '') return this;
        this.httpParams = this.httpParams.set(label, value ?? null);
        return this;
    }

    public addHttpParams(object: object): RequestBuilder {
        for (const key in object) {
            this.addHttpParam(key, object[key]);
        }
        return this;
    }

    public addDataToFormData(value: any): RequestBuilder {
        this.formData.append('data', JSON.stringify(value));
        return this;
    }

    public addToFormData(name: string, value: any): RequestBuilder {
        this.formData.append(name, value);
        return this;
    }

    public addFile(file: File): RequestBuilder {
        this.formData.append('file', file, file['name']);
        return this;
    }

    public addFiles(files: File[]): RequestBuilder {
        for (const file of files) {
            this.formData.append('files', file, file['name']);
        }
        return this;
    }

    public getFormData(): FormData {
        return this.formData;
    }

    public getHttpParams(): HttpParams {
        return this.httpParams;
    }
}
