import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, UiContext } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Movers } from '../../../move/interfaces/movers';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { MoveUtils } from '../../../move/state/move.utils';
import { Navigation } from '../../../navigation/enums/navigation.enum';

@Component({
    selector: 'smvd-app-energy-stop-not-interested',
    templateUrl: 'energy-stop-not-interested.container.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EnergyStopNotInterestedContainer {
    public movers$: Observable<Movers> = this.moveSandbox.move$.pipe(map((move) => MoveUtils.getMovers(move)));
    public loading = false;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly readingsRoute = [Navigation.Dash, Navigation.EnergyTransfer, Navigation.RegularizationBill];

    constructor(private readonly moveSandbox: MoveSandbox, private readonly router: Router) {}

    public confirmNotInterested(): void {
        this.loading = true;
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.moveSandbox.patch({
                moveId: DbUtils.getStringId(move),
                payload: { interested: { energyStop: false } },
                withNotification: true,
                callback: this.onConfirmNotInterested,
            });
        });
    }

    private onConfirmNotInterested = (): void => {
        this.loading = false;
        void this.router.navigate([Navigation.Dashboard]).then();
    };
}
