import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Language } from '@smooved/core';
import { CheckInput, SelectInput } from '@smooved/ui';

const leaverType = 'leaver-Type';

export const leaverTypeOptions: SelectInput<Leaver>[] = [
    {
        name: leaverType,
        value: Leaver.OwnerLandlord,
        id: Leaver.OwnerLandlord,
        labelResource: 'LEAVER.TYPE.OWNER_LANDLORD',
    },
    {
        name: leaverType,
        value: Leaver.OwnerLeaver,
        id: Leaver.OwnerLeaver,
        labelResource: 'LEAVER.TYPE.OWNER_LEAVER',
    },
    {
        name: leaverType,
        value: Leaver.TenantLeaver,
        id: Leaver.TenantLeaver,
        labelResource: 'LEAVER.TYPE.TENANT_LEAVER',
    },
    {
        name: leaverType,
        value: Leaver.LandAgent,
        id: Leaver.LandAgent,
        labelResource: 'LEAVER.TYPE.LAND_AGENT',
    },
];

export const realEstateAgentConsentOption: CheckInput<boolean> = {
    id: 'real-estate-agent-consent',
    name: 'real-estate-agent-consent',
    value: true,
    labelResource: 'LEAVER.REAL_ESTATE_AGENT_CONSENT',
};

export const languageOptions: DropdownInput<Language>[] = Object.keys(Language).map((key) => {
    return {
        id: Language[key],
        labelResource: `LANGUAGE.${key}.LABEL`,
        name: 'Language',
        value: Language[key],
    };
});

export const createdByEots = { eots: true };

export enum LeaverDetailsForm {
    LeaverType = 'leaverType',
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Language = 'language',
    RealEstateAgentConsent = 'realEstateAgentConsent',
    MeterReadingsTakeover = 'meterReadingsTakeover',
}

export interface ILeaverDetailsForm {
    [LeaverDetailsForm.LeaverType]: Leaver;
    [LeaverDetailsForm.FirstName]: string;
    [LeaverDetailsForm.LastName]: string;
    [LeaverDetailsForm.PhoneNumber]: string;
    [LeaverDetailsForm.Email]: string;
    [LeaverDetailsForm.Language]: Language;
    [LeaverDetailsForm.RealEstateAgentConsent]: boolean;
    [LeaverDetailsForm.MeterReadingsTakeover]?: boolean;
}
