import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MoveStateDeactivator {
    constructor(private readonly moveSandbox: MoveSandbox) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.deactivate();
    }

    public canDeactivate(): Observable<boolean> {
        return this.deactivate();
    }

    private deactivate(): Observable<boolean> {
        this.moveSandbox.setMoveState(null);
        return of(true);
    }
}
