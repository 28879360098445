import { AbstractControl } from '@angular/forms';

export function zipCodeOptional(control: AbstractControl) {
    const zipCode: string = control.value;
    if (!control || !control.value) {
        return null;
    }
    if (!zipCode.match(/^[0-9]*$/)) {
        return {
            'address.zipCode.only4Numbers': true,
        };
    }
    if (zipCode.length !== 4) {
        return {
            'address.zipCode.only4Numbers': true,
        };
    }
    return null;
}
