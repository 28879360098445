import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { CreatedByFlowEnum } from '@smooved/core';

@Component({
    selector: 'app-table-column-energy-flow-type',
    template: `
        <ng-container matColumnDef="energyFlowType">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'TYPE' | translate }}</th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="getType(row)" class="__cell u-padding-left u-padding-right">
                <div *ngSwitchCase="createdByFlow.Vacancy" class="u-white-space-no-wrap">{{ 'VACANCY.LABEL' | translate }}</div>
                <div *ngSwitchCase="createdByFlow.Eots" class="u-white-space-no-wrap">{{ 'EOTS.LABEL' | translate }}</div>
                <div *ngSwitchCase="createdByFlow.Lp" class="u-white-space-no-wrap">{{ 'LP.LABEL' | translate }}</div>
                <div *ngSwitchCase="createdByFlow.EnergyOptimization" class="u-white-space-no-wrap">
                    {{ 'ENERGY_OPTIMIZATION.LABEL' | translate }}
                </div>
            </td>
        </ng-container>
    `,
})
export class TableColumnEnergyFlowTypeComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    public createdByFlow = CreatedByFlowEnum;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }

    public getType(move): CreatedByFlowEnum {
        if (!move) return null;
        if (MoveUtils.isVacancy(move)) {
            return CreatedByFlowEnum.Vacancy;
        } else if (move.createdByFlow?.eots) {
            return CreatedByFlowEnum.Eots;
        } else if (move.createdByFlow?.lp) {
            return CreatedByFlowEnum.Lp;
        } else if (move.createdByFlow?.energyOptimization) {
            return CreatedByFlowEnum.EnergyOptimization;
        } else {
            return null;
        }
    }
}
