<div
    [ngClass]="{
        'u-bordered u-padding-top-half u-padding-bottom-half u-padding-left': bordered,
        'u-padding-right': bordered && !unselectable,
        'u-padding-right-triple': bordered && unselectable
    }"
    [ngSwitch]="type"
    class="u-position-relative"
>
    <app-button type="button" theme="no-style" *ngIf="unselectable" (click)="unselect()" class="__unselect u-color-muted">
        <app-svg width="14px" height="14px" [name]="svg.Close"></app-svg>
    </app-button>

    <app-suggestion-selection-energy-offer
        *ngSwitchCase="uiOffer.Energy"
        [minimal]="minimal"
        [minimalWithoutPrice]="minimalWithoutPrice"
        [paymentType]="paymentType"
        [showPaymentType]="showPaymentType"
        [suggestion]="suggestion"
        [suggestionsRoute]="suggestionsRoute"
        [showEdit]="showEdit"
        [extraInfo]="extraInfoEnergy"
    ></app-suggestion-selection-energy-offer>

    <app-suggestion-selection-energy-transfer *ngSwitchCase="uiOffer.EnergyTransfer"></app-suggestion-selection-energy-transfer>

    <app-suggestion-selection-telecom-offer
        *ngSwitchCase="uiOffer.Telecom"
        [suggestionsRoute]="suggestionsRoute"
        [suggestion]="suggestion"
        [showPaymentType]="showPaymentType"
        [showEdit]="showEdit"
        [paymentType]="paymentType"
        [minimalWithoutPrice]="minimalWithoutPrice"
        [minimal]="minimal"
    ></app-suggestion-selection-telecom-offer>

    <app-suggestion-selection-telecom-transfer *ngSwitchCase="uiOffer.TelecomTransfer"></app-suggestion-selection-telecom-transfer>

    <app-suggestion-selection-water *ngSwitchCase="uiOffer.Water"></app-suggestion-selection-water>

    <app-suggestion-selection-insurances-offer
        *ngSwitchCase="uiOffer.Insurances"
        [suggestion]="suggestion"
        [minimal]="minimal"
        [minimalWithoutPrice]="minimalWithoutPrice"
        [showEdit]="showEdit"
        [paymentType]="paymentType"
        [showExtraInfoInsurances]="showExtraInfoInsurances"
        [suggestionsRoute]="suggestionsRoute"
        [showPaymentType]="showPaymentType"
    ></app-suggestion-selection-insurances-offer>
</div>
