export enum NotificationLabel {
    MoveCreateSuccess = 'MOVE.CREATE.SUCCESS',
    AuthenticationUserConfirmSuccess = 'AUTHENTICATION.USER.CONFIRM.SUCCESS',
    AuthenticationUserLoginSuccess = 'AUTHENTICATION.USER.LOGIN.SUCCESS',
    AuthenticationRealEstateAgentRegisterSuccess = 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.SUCCESS.LABEL',
    AuthenticationRealEstateAgentInviteSuccess = 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.NOTIFICATION',
    MovePatchSuccess = 'MOVE.PATCH.SUCCESS',
    CreateAgreementsInviteSuccess = 'AGREEMENTS.CREATE.INVITE.SUCCESS',
    CreateAgreementsPdfSuccess = 'AGREEMENTS.CREATE.PDF.SUCCESS',
    AuthenticationRealEstateAgentResetPasswordSuccess = 'AUTHENTICATION.FORGOT_PASSWORD.RESET.SUCCESS',
    AuthenticationRealEstateAgentResetPasswordNewSuccess = 'AUTHENTICATION.RESET_PASSWORD.RESET.SUCCESS',
    MoveSendConfirmationSuccess = 'MOVE.SEND_CONFIRMATION.SUCCESS',
    MoveMeterPictureDeleteSuccess = 'MOVE.NOTIFICATIONS.METER_PICTURE_DELETE.SUCCESS',
}
