import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LateMovingDateReadMoreModalComponent } from '@app/real-estate-agent/modals/late-moving-date-read-more/late-moving-date-read-more.modal';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, UiContext, UiIcon, UiIconAppearance, UiIconSize } from '@smooved/ui';
import { MAX_PAST_DAYS_BEFORE_MOVING_DATE } from '../../../move/constants/move.constants';

@Component({
    selector: 'app-late-moving-date-red-notification',
    templateUrl: './late-moving-date-red-notification.component.html',
})
export class LateMovingDateRedNotificationComponent implements OnInit, OnChanges {
    @Input() public createdOn: Date;
    @Input() public movingDate: Date;
    @Input() public context: UiContext;

    public readonly uiIcon = UiIcon;
    public readonly uiIconSize = UiIconSize;
    public readonly iconAppearance = UiIconAppearance;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly maxPastDaysBeforeMovingDate = MAX_PAST_DAYS_BEFORE_MOVING_DATE;

    public showRedNotification = false;

    constructor(private readonly modalSandbox: ModalSandbox, private readonly translateService: TranslateService) { }

    ngOnInit(): void {
        this.updateShowRedNotification();
    }

    public ngOnChanges({ createdOn, movingDate }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(createdOn) || SimpleChangesUtils.hasChanged(movingDate)) {
            this.updateShowRedNotification();
        }
    }

    public openReadMoreModal(): void {
        const input = {
            createdOn: this.createdOn,
            movingDate: this.movingDate,
            daysBetweenCreatedOnAndMovingDate: Math.abs(DateUtils.getDaysIntervalBetweenTwoDates(this.movingDate, this.createdOn)),
        };

        this.modalSandbox.openClosableModal({
            component: LateMovingDateReadMoreModalComponent,
            config: {
                input,
                header: this.translateService.instant('MOVE.USER.MOVING_DATE_TOO_LATE.MODAL.ALERT.LABEL', { days: this.maxPastDaysBeforeMovingDate }) as string,
            },
        });
    }

    private updateShowRedNotification(): void {
        this.showRedNotification =
            DateUtils.isValidDate(this.movingDate) && DateUtils.isValidDate(this.createdOn)
                ? this.shouldShowRedNotification(this.movingDate, this.createdOn)
                : false;
    }

    /**
     * Method that verify wheter or not the red notification should be shown.
     * Red notificaiton will be shown if moving date is smaller than 14 days of the creation date.
     * @param {movingDate} Date: the moving date.
     * @param {createdOn} Date: the optional creation date that receives today's date as default in case of null.
     * @returns {boolean}: whether or not the red notification should be shown.
     */
    private shouldShowRedNotification(movingDate: Date, createdOn: Date = DateUtils.today()): boolean {
        if (!movingDate) return false;
        const daysInterval = DateUtils.getDaysIntervalBetweenTwoDates(movingDate, createdOn);
        return daysInterval < -MAX_PAST_DAYS_BEFORE_MOVING_DATE;
    }
}
