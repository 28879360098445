import { CheckInput } from '@smooved/ui';

export const consentOption: CheckInput<boolean> = {
    id: 'consent',
    name: 'consent',
    value: true,
    labelResource: 'INVOICING.CONSENT',
};

export enum FormControlNames {
    Consent = 'consent',
}
