import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as analyticsUri from '@app/analytics/constants/uri.constants';
import * as moveUri from '@app/move/constants/uri.constants';
import { MovesByRealEstateAgentPerWeek } from '@app/move/interfaces/move-by-real-estate-agent-per-week-response.interface';
import { MoveCountByCreatedFlow } from '@app/move/interfaces/move-count-by-created-flow';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupState } from '@app/real-estate-group/state/real-estate-group.reducer';
import { Interval } from '@app/shared/enums/interval.enum';
import { ArrayUtils, CoreUtils, PaginationRequest, RealEstateLevel, ReviewFilterBy, ReviewsData } from '@smooved/core';
import { ReviewBucketRequest, ReviewsDataResponse, ReviewsFilter } from '@smooved/ui';
import { Observable } from 'rxjs';
import * as uri from './constants/uri.constants';
import { MoveCountByOtsConfirmationClusterLevel } from './interfaces/move-count-by-ots-confirmation-cluster-level';

@Injectable()
export class AnalyticsService {
    constructor(private httpClient: HttpClient) {}

    public getWeeklyMoveCounts(
        realEstateGroup: RealEstateGroupState | RealEstateGroup,
        from: Date,
        until: Date
    ): Observable<MovesByRealEstateAgentPerWeek[]> {
        const httpParams: HttpParams = new HttpParams()
            .set('search', realEstateGroup.name)
            .set('from', from.toISOString())
            .set('until', until.toISOString())
            .set('role', MoverRole.Transferee);

        return this.httpClient.get<MovesByRealEstateAgentPerWeek[]>(moveUri.weeklyMoveCountsUri, {
            params: httpParams,
        });
    }

    public getConfirmationsAnalytics(
        interval: Interval,
        level: RealEstateLevel,
        from: Date,
        until: Date
    ): Observable<MoveCountByCreatedFlow[]> {
        let httpParams: HttpParams = new HttpParams().set('level', level).set('interval', interval);

        httpParams = from ? httpParams.append('from', from?.toISOString()) : httpParams;
        httpParams = until ? httpParams.append('until', until?.toISOString()) : httpParams;

        return this.httpClient.get<MoveCountByCreatedFlow[]>(analyticsUri.confirmations, {
            params: httpParams,
        });
    }

    public getOtsConfirmationClusterLevel(
        interval: Interval,
        level: RealEstateLevel,
        from?: Date,
        until?: Date
    ): Observable<MoveCountByOtsConfirmationClusterLevel[]> {
        let httpParams: HttpParams = new HttpParams().set('level', level).set('interval', interval);

        if (from) {
            httpParams = httpParams.append('from', from.toISOString());
        }

        if (until) {
            httpParams = httpParams.append('until', until.toISOString());
        }

        return this.httpClient.get<MoveCountByOtsConfirmationClusterLevel[]>(analyticsUri.clusterLevels, {
            params: httpParams,
        });
    }

    public npsReviews(reviewFilter: ReviewsFilter, paginationRequest?: PaginationRequest): Observable<ReviewsDataResponse> {
        let params: HttpParams = new HttpParams().set('interval', reviewFilter.durationFilter);
        if (paginationRequest) {
            params = params
                .append('pageIndex', paginationRequest.pageIndex?.toString() || null)
                .append('pageSize', paginationRequest.pageSize?.toString() || null);
        }

        params = this.setReviewFilterParams(reviewFilter, params);

        return this.httpClient.get<ReviewsDataResponse>(uri.npsReviewsUri, { params });
    }

    public getNpsUnreadCount(): Observable<number> {
        return this.httpClient.get<number>(uri.npsUnreadCount);
    }

    public getReviewBuckets(reviewFilter: ReviewsFilter): Observable<ReviewBucketRequest> {
        let params = new HttpParams();
        params = this.setReviewFilterParams(reviewFilter, params);
        return this.httpClient.get<ReviewBucketRequest>(analyticsUri.npsReviewBucketsUri, { params });
    }

    public getNpsSummaryData(): Observable<ReviewsData> {
        return this.httpClient.get<ReviewsData>(analyticsUri.npsSummaryDataUri);
    }

    private setReviewFilterParams(filter: ReviewsFilter, httpParams: HttpParams): HttpParams {
        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.scoreFilter))) {
            httpParams = httpParams.append(ReviewFilterBy.Score, JSON.stringify(filter.scoreFilter));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.group))) {
            httpParams = httpParams.append(ReviewFilterBy.Group, filter.group);
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.unanswered))) {
            httpParams = httpParams.append(ReviewFilterBy.Unanswered, JSON.stringify(filter.unanswered));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.source))) {
            httpParams = httpParams.append(ReviewFilterBy.Source, JSON.stringify(filter.source));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.bucket))) {
            httpParams = httpParams.append(ReviewFilterBy.Bucket, JSON.stringify(filter.bucket));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.segments))) {
            httpParams = httpParams.append(ReviewFilterBy.Segment, JSON.stringify(filter.segments));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.ids))) {
            httpParams = httpParams.append(ReviewFilterBy.Id, JSON.stringify(filter.ids));
        }

        if (!CoreUtils.isEmpty(ArrayUtils.toArray(filter.intervalCounts))) {
            httpParams = httpParams.append(ReviewFilterBy.IntervalCounts, JSON.stringify(filter.intervalCounts));
        }

        if (!CoreUtils.isEmpty(filter.location)) {
            httpParams = httpParams.append(ReviewFilterBy.Location, filter.location);
        }

        if (!CoreUtils.isEmpty(filter.topicCategory)) {
            httpParams = httpParams.append(ReviewFilterBy.TopicCategory, filter.topicCategory);
        }

        if (!CoreUtils.isEmpty(filter.topicCategorySentiment)) {
            httpParams = httpParams.append(ReviewFilterBy.TopicCategorySentiment, filter.topicCategorySentiment.toString());
        }

        return httpParams;
    }
}
