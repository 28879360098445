import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[matBadgeWide]',
})
export class BadgeWideDirective {
    public isWide: boolean;

    @Input()
    set matBadgeWide(value: boolean) {
        this.isWide = !!value;
        this.updateView();
    }

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    private updateView(): void {
        if (this.isWide) {
            this.renderer.addClass(this.el.nativeElement, 'mat-badge-wide');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'mat-badge-wide');
        }
    }
}
