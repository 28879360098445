<app-loading-container [loading]="analyticsDashboardService.loading$ | async">
    <ng-container *ngIf="analyticsDashboardService.analytics$ | async as analytics">
        <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-end u-bordered-bottom">
            <h5>{{ 'ANALYTICS.DASHBOARD.TITLE' | translate }}</h5>
            <p class="u-color-muted u-font-size-small">
                {{ 'ANALYTICS.DASHBOARD.LAST_UPDATE' | translate: { minutes: lastUpdateInMinutes$ | async } }}
            </p>
        </div>
        <div class="u-grid u-grid-col-2-repeat u-grid-double-gap u-margin-top">
            <app-analytics-transactions [analytics]="analytics"></app-analytics-transactions>
            <app-analytics-influencers [analytics]="analytics"></app-analytics-influencers>
            <app-analytics-services [analytics]="analytics"></app-analytics-services>
            <app-analytics-attention-required [analytics]="analytics"></app-analytics-attention-required>
        </div>
    </ng-container>
</app-loading-container>
