import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TelecomSuggestion } from '@app/telecom/interfaces/telecom-suggestion';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { NumberUtils } from '@smooved/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-suggestion-selection-telecom-offer',
    templateUrl: 'suggestion-selection-telecom-offer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionTelecomOfferComponent {
    @Input() public suggestion: TelecomSuggestion;
    @Input() public paymentType: PaymentType;
    @Input() public showEdit = false;
    @Input() public showPaymentType = false;
    @Input() public minimal = false;
    @Input() public minimalWithoutPrice = false;
    @Input() public suggestionsRoute: string;

    public svg = Svg;

    constructor(public uiSandbox: AppUiSandbox) {}

    public hasDiscount(): boolean {
        return NumberUtils.isNumber(this.suggestion?.discount) && this.suggestion?.discount < this.suggestion?.price;
    }
}
