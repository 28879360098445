import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MobileModalComponent, Svg } from '@smooved/ui';

@Component({
    selector: 'app-mobile-move-general-details-modal',
    template: `
        <app-mobile-modal [mainHasPaddingTop]="false" *ngIf="move$ | async as move">
            <div mobile-modal-header>
                <span class="u-font-weight-bold">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.DATA' | translate }}</span>
            </div>
            <div mobile-modal-main class="u-padding u-background-white">
                <app-moving-address-detail [move]="move" class="u-display-block u-margin-bottom-double"></app-moving-address-detail>
                <app-moving-date-detail [move]="move" class="u-display-block u-margin-bottom-double"></app-moving-date-detail>
                <app-user-detail [move]="move" class="u-display-block u-margin-bottom-double"></app-user-detail>
                <app-user-detail
                    [move]="moveLeaver$ | async"
                    [label]="'MOVE.MOVER_ROLE.LEAVER'"
                    class="u-display-block u-margin-bottom"
                ></app-user-detail>
                <p class="u-margin-bottom-double">
                    <a class="u-link" *ngIf="!!move?.linkedMove" (click)="showLeaver(move.linkedMove._id)">{{
                        'MOVE.MOVER_ROLE.LEAVER.VIEW_DETAILS' | translate
                    }}</a>
                </p>
            </div>
        </app-mobile-modal>
    `,
    styleUrls: ['./mobile-move-general-details.component.scss'],
})
export class MobileMoveGeneralDetailsComponent extends MoveData implements OnInit {
    @ViewChild(MobileModalComponent) public modal: MobileModalComponent;

    public exclamation = Svg.Alert;

    constructor(protected moveSandbox: MoveSandbox, @Inject(MAT_DIALOG_DATA) public data: ModalData) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit(): void {
        this.fetch(this.data.id);
    }

    public showLeaver(leaverId: string): void {
        this.modal.close({ openMove: leaverId });
    }
}
