import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { noDecimals, ReviewScoreScale, ReviewsData, SimpleChangesUtils } from '@smooved/core';
import { uiI18nKeyTypes } from '../../../i18n';
import { UiContext, UiDirection, UiSize } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { ArcScoreData } from '../../../widget-influencer-score';
import { ArcScoreDataPipe } from '../../pipes/arc-score-data.pipe';
import { ReviewsUtils } from '../../reviews.utils';

@Component({
    selector: 'smvd-ui-review-arc-score',
    templateUrl: './reviews-arc-score.component.html',
    styleUrls: ['./reviews-arc-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewArcScoreComponent implements OnChanges {
    @Input() public meta: ReviewsData;
    @Input() public arcSize = UiSize.Default;
    @Input() public fontSize = UiSize.Default;
    @Input() public compact: boolean;
    @Input() public minified: boolean;
    @Input() public activated = true;
    @Input() public title: string;
    @Input() public description: string;
    @Input() public direction = UiDirection.Horizontal;

    @HostBinding('class.__reviews-arc-score--vertical') public isVertical: boolean;

    public readonly uiSize = UiSize;
    public readonly uiContext = UiContext;
    public readonly i18nKeyType = uiI18nKeyTypes;
    public readonly noDecimals = noDecimals;
    public readonly directions = UiDirection;
    public readonly tabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$;

    public scoreScale: ReviewScoreScale;
    public arcScoreData: ArcScoreData;

    private readonly arcScoreDataFormatter = new ArcScoreDataPipe();

    constructor(private readonly uiSandbox: UiSandbox) {}

    public ngOnChanges({ meta, direction }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(meta)) {
            const { arcScore, scoreScale } = this.activated
                ? this.arcScoreDataFormatter.transform(this.meta)
                : { arcScore: ReviewsUtils.mapInactiveArcScore(), scoreScale: null };
            this.arcScoreData = arcScore;
            this.scoreScale = scoreScale;
        }

        if (SimpleChangesUtils.hasChanged(direction)) {
            this.isVertical = this.direction === UiDirection.Vertical;
        }
    }
}
