import { Injectable } from '@angular/core';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { OrderDetailService } from '@app/partner/services/order-detail.service';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { NotificationSandbox } from '@smooved/ui';
import { map } from 'rxjs/operators';

@Injectable()
export class TelecomDetailService extends OrderDetailService {
    public isTelecomTransfer$ = this.order$.pipe(map((order) => MoveUtils.isTelecomTransfer(order?.move)));

    constructor(readonly orderSandbox: OrderSandbox, readonly notificationSandbox: NotificationSandbox) {
        super(orderSandbox, notificationSandbox);
    }
}
