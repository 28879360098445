import { ContactLog } from '@app/move/interfaces/contact-log';
import { ActionAudit, AuditUtils, DateUtils, Language, ObjectUtils, SortDirection, SortUtils } from '@smooved/core';
import { LogAction } from './constants/escalation.constants';
import { EscalationCategory } from './interfaces/category';
import { Escalation } from './interfaces/escalation';
import { EscalationState } from './enums/state.enum';

export class EscalationUtils {
    public static mapLogActions({ contactLogs, actions }: Escalation): LogAction[] {
        return [...(actions || []), ...((contactLogs as ContactLog[]) || [])].sort(
            SortUtils.sortByDate(SortDirection.Desc, (logAction: LogAction) =>
                DateUtils.tz(AuditUtils.getLastModifiedOn(logAction as unknown as ActionAudit<void>)).toDate()
            )
        );
    }

    /**
     * Retrieves the category label for a given language from an escalation category object.
     * If the label is not available in the specified language, it falls back to the Dutch label.
     * If the Dutch label is also not available, it returns a default message.
     * @param escalationCategory - An object representing the escalation category, containing labels for different languages.
     * @param language - The language for which the category label is requested.
     * @returns The category label for the specified language, or a default message if the label is not available.
     */
    public static getCategoryLabelsByLanguage(escalationCategory: EscalationCategory, language: Language): string {
        return (
            escalationCategory?.labels?.[language] ||
            escalationCategory?.labels?.[Language.NL] ||
            'Label not available in the specified language.'
        );
    }
    
    /**
     * Generates the tooltip content for a move's escalations.
     * It checks if each escalation is an object and not null, and then calls a utility function to get the category labels for the escalation.
     * It returns a string that contains the category labels of all escalations joined by a newline character.
     *
     * @param move - The move object for which to generate the tooltip content.
     * @returns The tooltip content for the move's escalations, with each category label separated by a newline character.
     */
    public static getEscalationTooltip(escalations: Escalation[], lang: Language): string {
        return (
            escalations
                .map((escalation: Escalation) => {
                    if (ObjectUtils.isObject(escalation) && escalation !== null) {
                        return EscalationUtils.getCategoryLabelsByLanguage(escalation.category as EscalationCategory, lang);
                    }
                    return escalation;
                })
                .join('\n') ?? ''
        );
    }
    public static showEscalationTooltip(escalations: Escalation[]): boolean {
        return (
            escalations?.some((escalation: Escalation): boolean => {
                return escalation.state.value === EscalationState.Open || escalation.state.value === EscalationState.Waiting;
            }) ?? false
        );
    }
}
