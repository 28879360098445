import { Component } from '@angular/core';
import { illustrationRelax } from '@smooved/ui';
import { Navigation } from '../../../navigation/enums/navigation.enum';

@Component({
    selector: 'app-contracts-prepared-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-margin-bottom u-flex-column u-flex-align-items-center">
                    <img class="u-margin-bottom-double" width="220" [src]="image" alt="Smooved" />

                    <h3 class="u-margin-bottom-double">{{ 'CONTRACTS.PREPARED.TITLE' | translate }}</h3>

                    <p class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium">
                        {{ 'CONTRACTS.PREPARED.TEXT' | translate }}
                    </p>

                    <app-button [routerLink]="completeLink">{{ 'CONTRACTS.COMPLETE_DETAILS' | translate }}</app-button>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class ContractsPreparedContainer {
    public completeLink = `/${Navigation.Complete}/full`;
    public readonly image = illustrationRelax;
}
