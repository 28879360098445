import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '../utils';

@Pipe({ name: 'greaterThan' })
export class GreaterThanPipe implements PipeTransform {

    public transform(value: number, min: number): boolean {
        return NumberUtils.isGreatherThan(value, min);
    }
}
