import { NgModule } from '@angular/core';
import { SvgModule } from '@smooved/ui';
import { EmptyStateComponent } from './empty-state.component';

@NgModule({
    imports: [SvgModule],
    declarations: [EmptyStateComponent],
    exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
