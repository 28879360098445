<div class="u-flex-row u-flex-align-items-center">
    <span>{{ 'INSIGHTS.DASHBOARD.SCORE.SCORE' | translate }}</span>
    <div class="__graph-legend legend-red"></div>
    <span>{{ 'INSIGHTS.DASHBOARD.SCORE.BAD_RANGE' | translate }}</span>
    <div class="__graph-legend legend-yellow"></div>
    <span>{{ 'INSIGHTS.DASHBOARD.SCORE.AVERAGE_RANGE' | translate }}</span>
    <div class="__graph-legend legend-green"></div>
    <span>{{ 'INSIGHTS.DASHBOARD.SCORE.GOOD_RANGE' | translate }}</span>
</div>

<span class="u-font-size-14 u-color-gray-dark">{{ 'INSIGHTS.DASHBOARD.REVIEWS_PER_MONTH' | translate }}</span>
