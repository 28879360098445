export enum ButtonAppearance {
    Default = 'unelevated',
    Link = 'link',
    LinkReversed = 'link-reversed',

    Basic = 'basic',
    Raised = 'raised',
    Stroked = 'stroked',
    Flat = 'flat',
    FlatTransparent = 'flat-transparent',
    Icon = 'icon',
    StrokedIcon = 'stroked-icon',
    FlatIcon = 'flat-icon',
    MobileIcon = 'mobile-icon',
}

export enum ButtonSize {
    Default = 'md',
    Xs = 'xs',
    Sm = 'sm',
    Md = 'md',
}
