import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { UiDirection } from '../../../ui.enums';

@Component({
    selector: 'app-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendComponent implements OnChanges {
    @Input() public direction = UiDirection.Horizontal;

    @HostBinding('class.u-flex-row') public isHorizontal = true;
    @HostBinding('class.u-flex-column') public isVertical: boolean;

    public ngOnChanges({ direction }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(direction)) {
            this.isHorizontal = this.direction === UiDirection.Horizontal;
            this.isVertical = this.direction === UiDirection.Vertical;
        }
    }
}
