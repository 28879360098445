import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnergySuggestion } from '../../interfaces/energy-suggestion';

@Component({
    selector: 'app-electricity-price-details-modal',
    template: `
        <app-closable-modal-template *ngIf="{ details: data.electricityPricesDetails } as vm">
            <h2 class="u-margin-bottom">{{ 'MOVE.ENERGY.SUGGESTION.ELECTRICITY_PRICE.DETAILS.TITLE' | translate }}</h2>

            <app-electricity-details [details]="data.electricityPricesDetails"></app-electricity-details>

            <p *showIfNumber="data.promoConsumption" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_CONSUMPTION' | translate }} {{ data.promoConsumption }}
            </p>
            <p *showIfNumber="data.promoDiscountFixed" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_DISCOUNT_FIXED' | translate }} {{ data.promoDiscountFixed | price }}
            </p>
            <p *showIfNumber="data.promoDiscountVariableCost" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_DISCOUNT_VARIABLE_COST' | translate }}
                {{ data.promoDiscountVariableCost | price }}
            </p>
            <p *showIfNumber="data.promoPercentageVariableCost" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_PERCENTAGE_VARIABLE_COST' | translate }}
                {{ data.promoPercentageVariableCost | percent }}
            </p>
            <div class="u-flex-row u-flex-align-items-center">
                <a
                    *ngIf="data.electricityTariffCard"
                    [href]="data.electricityTariffCard"
                    download
                    target="_blank"
                    class="u-link u-margin-right"
                    >{{ 'MOVE.ENERGY.ELECTRICITY_RATE_CARD' | translate }}</a
                >
                <a
                    *ngIf="data.electricityInjectionTariffCard"
                    [href]="data.electricityInjectionTariffCard"
                    download
                    target="_blank"
                    class="u-link u-margin-right"
                    >{{ 'MOVE.ENERGY.ELECTRICITY_INJECTION_RATE_CARD' | translate }}</a
                >
            </div>
        </app-closable-modal-template>
    `,
    styleUrls: ['./electricity-price-details-modal.component.scss'],
})
export class ElectricityPriceDetailsModalComponent {
    constructor(
        public dialogRef: MatDialogRef<ElectricityPriceDetailsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EnergySuggestion
    ) {}
}
