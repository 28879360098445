import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TelecomSuggestion } from '@app/telecom/interfaces/telecom-suggestion';
import { TelecomSandbox } from '@app/telecom/sandboxes/telecom.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface TelenetProductOption {
    name: string;
    value: TelecomSuggestion;
}

@Component({
    selector: 'app-telenet-product-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="telenetProducts"
                [placeholder]="'PRODUCT' | translate"
                searchKeyword="name"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                name="telenet-product"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.name"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./telenet-product-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TelenetProductInputComponent),
            multi: true,
        },
    ],
})
export class TelenetProductInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: TelenetProductOption | string = '';

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    public telenetProducts: TelenetProductOption[];

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly telecomSandbox: TelecomSandbox,
        private readonly translateService: TranslateService,
        private readonly cdr: ChangeDetectorRef
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.loadingSubject.next(true);

        this.telecomSandbox
            .getTelenetProducts()
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe((response) => {
                this.telenetProducts = response.map((telenetProduct) => this.telenetProductOptionFactory(telenetProduct));
            });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    private telenetProductOptionFactory = (telenetProduct: TelecomSuggestion): TelenetProductOption => {
        const lang = this.translateService.currentLang || this.translateService.defaultLang;
        return {
            name: telenetProduct.productNameLabels[lang] || '',
            value: telenetProduct,
        };
    };

    public writeValue(telenetProduct: TelecomSuggestion): void {
        if (telenetProduct) {
            this.innerModel = this.telenetProductOptionFactory(telenetProduct);
        }
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
            this.cdr.markForCheck();
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.propagateChange(this.innerModel ? (this.innerModel as TelenetProductOption).value : null);
            this.cdr.markForCheck();
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
