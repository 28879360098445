import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { NumberUtils } from '@smooved/core';

@Directive({
    selector: '[showIfNumber]',
})
export class ShowIfNumberDirective {
    @Input() set showIfNumber(value: any) {
        NumberUtils.isNumber(value) ? this.container.createEmbeddedView(this.templateRef) : this.container.clear();
    }

    constructor(private templateRef: TemplateRef<any>, private container: ViewContainerRef) {}
}
