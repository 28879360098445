<app-card [bordered]="false" class="u-h100p">
    <header class="u-flex-row u-flex-align-items-center u-padding-bottom-double">
        <h6>{{ cardTitle | translate }}</h6>
    </header>
    <app-loading-container [loading]="!loaded">
        <app-chart-container>
            <app-stacked-bar-chart
                [data]="data"
                [stacks]="stacks"
                [colors]="colors"
                [tooltipTotal]="true"
                [hasLabels]="false"
                width="800"
            ></app-stacked-bar-chart>
            <app-chart-legend-item
                *ngFor="let item of legend"
                [color]="item.color"
                [label]="item.label"
                [sub]="item.sub"
            ></app-chart-legend-item>
        </app-chart-container>
    </app-loading-container>
</app-card>
