import { NgModule } from '@angular/core';
import { PosthogService } from './posthog.service';
import { EffectsModule } from '@ngrx/effects';
import { PosthogEffects } from '@app/tracking/state/posthog.effects';

@NgModule({
    imports: [EffectsModule.forFeature([PosthogEffects])],
    providers: [PosthogService],
    exports: [],
})
export class TrackingModule {}
