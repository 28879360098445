import { DateUtils } from '@smooved/core';
import { ChartLegend, ColorScale } from '@smooved/ui';

export const onTheSpot = 'ots';
export const leadPassing = 'lp';
export const chartWidth = 800;
export const labels = {
    leadPassing: 'MOVE.CREATED_BY_FLOW.LP.EXTENSION',
    onTheSpot: 'MOVE.CREATED_BY_FLOW.OTS.EXTENSION',
};

export const stackNames = [onTheSpot, leadPassing];
export const colors = [ColorScale.Color3, ColorScale.Color1];
export const legend: ChartLegend[] = stackNames.map((name, i) => ({
    label: `MOVE.CREATED_BY_FLOW.${name.toUpperCase()}.EXTENSION`,
    color: colors[i],
}));
export const fromDate = DateUtils.tz().subtract(3, 'Q').startOf('Q').toDate();
