import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { Address, CountryCode, ObjectUtils } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { uiI18nKeyTypes } from '../../i18n';
import { FormControlNames } from '../address-input/address-input.constants';
import { BaseInput } from '../base-input';
import { AddressValidators } from '../validators/address.validators';

@Component({
    selector: 'smvd-ui-dynamic-address-input',
    templateUrl: 'dynamic-address-input.component.html',
    styleUrls: ['dynamic-address-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DynamicAddressInputComponent),
            multi: true,
        },
    ],
})
export class DynamicAddressInputComponent extends BaseInput implements ControlValueAccessor, OnInit {
    @ViewChild('formElement', { static: true }) public formGroupDirective: FormGroupDirective;

    @Input() public label: string;
    @Input() public labelResource: string;
    @Input() public small: boolean;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public showLabels = false;
    @Input() public showPlaceholders = true;
    @Input() public fields: { [key in FormControlNames]: boolean };
    @Input() public required = true;

    public readonly i18nKeys = uiI18nKeyTypes;
    public readonly addressNames = FormControlNames;
    public form: UntypedFormGroup;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        public readonly controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        const formFields = {
            [FormControlNames.Street]: !this.fields || this.fields?.street ? [null, this.required ? [Validators.required] : []] : null,
            [FormControlNames.HouseNumber]:
                !this.fields || this.fields.houseNumber ? [null, this.required ? [Validators.required] : []] : null,
            [FormControlNames.BusNumber]: !this.fields || this.fields.busNumber ? [null] : null,
            [FormControlNames.ZipCode]:
                !this.fields || this.fields.zipCode
                    ? [null, this.required ? [Validators.required] : [AddressValidators.validateZipCodeOptional]]
                    : null,
            [FormControlNames.City]: !this.fields || this.fields.city ? [null, this.required ? [Validators.required] : []] : null,
            [FormControlNames.Country]: !this.fields || this.fields.country ? [CountryCode.Belgium, [Validators.required]] : null,
        };
        ObjectUtils.removeEmpty(formFields);
        this.form = this.formBuilder.group(formFields);

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: Address) => {
            if (this.form.invalid) {
                this.propagateChange(null);
            } else {
                this.propagateChange(value);
            }
        });

        this.controlContainer.control.markAllAsTouched = (): void => this.markAllAsTouched();
    }

    public writeValue(address: Address): void {
        this.form.patchValue(address || {});
    }

    public markAsSubmitted(): void {
        this.formGroupDirective.onSubmit(null);
        this.cdr.detectChanges();
    }

    public markAllAsTouched(): void {
        this.form.markAllAsTouched();
        this.cdr.detectChanges();
    }

    public onAddressSelected(event: Address): void {
        this.form.patchValue(event, { emitEvent: true });
        this.cdr.markForCheck();
    }
}
