import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { gasControlNames } from '@smooved/ui';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import { Move } from '../../../move/interfaces/move';
import { MeterInfoGasRequest } from './meter-info-gas.constants';

@Component({
    selector: 'app-meter-info-gas',
    template: `
        <form [formGroup]="form" *ngIf="showGas">
            <div class="u-flex-column u-flex-align-items-start">
                <h6 class="u-color-muted u-margin-bottom" *ngIf="showTitle">{{ 'GAS' | translate }}</h6>

                <app-ean-code-input [formControlName]="formControlNames.eanCodeGas" ngDefaultControl></app-ean-code-input>

                <div *ngIf="isAdmin" class="u-padding-left-double u-w100p">
                    <smvd-ui-text-input
                        [label]="'EAN_CODE_BY_ADMIN.LABEL' | translate"
                        [formControlName]="formControlNames.eanCodeGasByAdmin"
                        ngDefaultControl
                    ></smvd-ui-text-input>
                    <smvd-ui-text-input
                        [label]="'METER_NUMBER_BY_ADMIN.LABEL' | translate"
                        [formControlName]="formControlNames.meterNumberGasByAdmin"
                        ngDefaultControl
                    ></smvd-ui-text-input>
                </div>

                <app-meter-reading-input
                    [formControlName]="formControlNames.gasMeterReading"
                    ngDefaultControl
                    *ngIf="!isEots"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [label]="'METER_READING' | translate"
                ></app-meter-reading-input>
            </div>
        </form>
    `,
})
export class MeterInfoGasComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public isEots: boolean;
    @Input() public move: Move;
    @Input() public showTitle = true;
    @Input() public disabled = false;
    @Input() public readingDisabled = false;
    @Input() public show: boolean;

    @Output() public formChange: EventEmitter<void> = new EventEmitter<void>();

    public showGas: boolean;
    public form: UntypedFormGroup;
    public isAdmin = false;
    public readonly formControlNames = gasControlNames;

    private automaticMeterReadings = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly moveSandbox: MoveSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            this.isAdmin = isAdmin;

            this.form = this.formBuilder.group({
                [gasControlNames.eanCodeGas]: [{ value: null, disabled: this.disabled }],
                [gasControlNames.gasMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
            });

            if (isAdmin) {
                this.form.addControl(
                    gasControlNames.eanCodeGasByAdmin,
                    this.formBuilder.control({
                        value: null,
                        disabled: this.disabled,
                    })
                );
                this.form.addControl(
                    gasControlNames.meterNumberGasByAdmin,
                    this.formBuilder.control({
                        value: null,
                        disabled: this.disabled,
                    })
                );
            }

            if (this.move) {
                const energyType = this.move?.energyOffer?.energyType;
                const showGas =
                    this.show || !energyType || MoveUtils.showMeterReadingGas(this.move) || !!MoveUtils.energyTransferSelected(this.move);

                this.init(this.move, showGas);
            } else {
                combineLatest([
                    this.moveSandbox.moveOnce$,
                    this.moveSandbox.energyTransferSelectedOnce$,
                    this.moveSandbox.energyTypeHasGas$.pipe(take(1)),
                    of(this.route.snapshot.queryParams.id).pipe(take(1)),
                ])
                    .pipe(
                        concatMap(([moveState, energyTransferSelected, energyTypeHasGas, id]): Observable<any> => {
                            const moveId = id || moveState?._id;
                            if (!moveId) {
                                return of([moveState || null, energyTypeHasGas || !!energyTransferSelected]);
                            } else {
                                return this.moveSandbox.get(moveId).pipe(
                                    map((move) => {
                                        const energyType = move.energyOffer?.energyType;
                                        const showGas = !energyType || EnergyUtils.hasGas(energyType) || !!energyTransferSelected;
                                        return [move, showGas];
                                    }),
                                    catchError((e) => of([moveState || null, energyTypeHasGas || !!energyTransferSelected]))
                                );
                            }
                        })
                    )
                    .subscribe(([move, showGas]) => {
                        this.init(move, showGas);
                    });
            }
        });
    }

    public ngOnChanges({ disabled, readingDisabled }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(disabled) || SimpleChangesUtils.hasChanged(readingDisabled)) {
            this.setFormControlDisabled(gasControlNames.eanCodeGas, this.disabled);
            this.setFormControlDisabled(gasControlNames.gasMeterReading, !this.canEditMeterReading());
            if (this.isAdmin) {
                this.setFormControlDisabled(gasControlNames.eanCodeGasByAdmin, this.disabled);
                this.setFormControlDisabled(gasControlNames.meterNumberGasByAdmin, this.disabled);
            }
        }
    }

    public createPatch(): MeterInfoGasRequest {
        const patch: MeterInfoGasRequest = {
            eanCodeGas: this.showGas && !!this.eanCodeGasFormControl().value ? (this.eanCodeGasFormControl().value as string) : null,
            gasMeterReading:
                this.showGas && !!this.gasMeterReadingFormControl().value && !this.automaticMeterReadings
                    ? (this.gasMeterReadingFormControl().value as string)
                    : null,
        };
        if (this.isAdmin) {
            patch.eanCodeGasByAdmin = this.showGas && (this.form.get(gasControlNames.eanCodeGasByAdmin).value as string);
            patch.meterNumberGasByAdmin = this.showGas && (this.form.get(gasControlNames.meterNumberGasByAdmin).value as string);
        }
        return patch;
    }

    public setAutomaticMeterReading(automatic: boolean): void {
        this.automaticMeterReadings = automatic;
        this.gasMeterReadingFormControl()[automatic || !this.canEditMeterReading() ? 'disable' : 'enable']();
    }

    private init(move: Move, showGas: boolean): void {
        this.showGas = showGas;
        if (!move) {
            return;
        }
        if (move.eanCodeGas && showGas) {
            this.eanCodeGasFormControl().patchValue(move.eanCodeGas);
        }
        if (move.gasMeterReading && showGas) {
            this.gasMeterReadingFormControl().patchValue(move.gasMeterReading);
        }
        this.setAutomaticMeterReading(move.energyDigitalMeterReadings?.automatic);
        if (this.isAdmin) {
            if (move.eanCodeGasByAdmin) this.form.get(gasControlNames.eanCodeGasByAdmin).patchValue(move.eanCodeGasByAdmin);
            if (move.meterNumberGasByAdmin) this.form.get(gasControlNames.meterNumberGasByAdmin).patchValue(move.meterNumberGasByAdmin);
        }
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((x) => {
            this.formChange.emit();
        });
        this.cdr.detectChanges();
    }

    private eanCodeGasFormControl(): AbstractControl {
        return this.form.get(gasControlNames.eanCodeGas);
    }

    private gasMeterReadingFormControl(): AbstractControl {
        return this.form.get(gasControlNames.gasMeterReading);
    }

    private setFormControlDisabled(formControlName: string, disabled: boolean): void {
        this.form?.get(formControlName)[disabled ? 'disable' : 'enable']();
    }

    private canEditMeterReading(): boolean {
        return !(this.disabled || this.readingDisabled);
    }
}
