import { Pipe, PipeTransform } from '@angular/core';
import { emptyValue, NumberUtils } from '@smooved/core';

@Pipe({
    name: 'realEstateAgentCluster',
})
export class RealEstateAgentClusterPipe implements PipeTransform {
    transform(value: number): string {
        switch (true) {
            case value === 1:
                return '1';
            case NumberUtils.isBetween(value, 2, 3):
                return '2-3';
            case NumberUtils.isBetween(value, 4, 6):
                return '4-6';
            case NumberUtils.isBetween(value, 7, 10):
                return '7-10';
            case NumberUtils.isBetween(value, 11, 15):
                return '11-15';
            case NumberUtils.isBetween(value, 16, 20):
                return '16-20';
            case NumberUtils.isBetween(value, 21, 30):
                return '21-30';
            case NumberUtils.isBetween(value, 31, 40):
                return '31-40';
            case NumberUtils.isBetween(value, 41, 50):
                return '41-50';
            case NumberUtils.isBetween(value, 51, 75):
                return '51-75';
            case NumberUtils.isBetween(value, 76, 100):
                return '76-100';
            case value > 100:
                return '+100';
            default:
                return emptyValue;
        }
    }
}
