import { isPlatformBrowser } from '@angular/common';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter, skipWhile } from 'rxjs/operators';
import { CoreUtils } from '../utils/core.utils';

export function skipWhileEmpty<T>(): MonoTypeOperatorFunction<T> {
    return skipWhile((x: T) => CoreUtils.isEmpty(x));
}

export function skipWhileNull<T>(): MonoTypeOperatorFunction<T> {
    return skipWhileValue(null);
}

export function skipWhileValue<T>(value: any): MonoTypeOperatorFunction<T> {
    return skipWhile((x: T) => x === value);
}

export function keepTruthy<T>(fn: (x: T) => unknown = (x): unknown => x): MonoTypeOperatorFunction<T> {
    return filter((x: T) => !!fn(x));
}

export function keepFalsy<T>(fn: (x: T) => unknown = (x): unknown => x): MonoTypeOperatorFunction<T> {
    return filter((x: T) => !fn(x));
}

export const isBrowser = <T>(platformId: object): MonoTypeOperatorFunction<T> => {
    return filter(() => isPlatformBrowser(platformId));
};
