import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ClientPathnameInterceptor implements HttpInterceptor {
    constructor(protected readonly location: Location) {}
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const path = this.location?.path() || null;
        if (!path) return next.handle(req);
        const clone = req.clone({
            headers: req.headers.append('Smvd-Client-Pathname', path),
        });
        return next.handle(clone);
    }
}
