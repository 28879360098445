import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-move-detail-insurances',
    template: `
        <div *appLoading="!move">
            <app-check-item class="u-margin-bottom-half" [checked]="!!move.insurancesStates?.selected?.value">
                <ng-container ngProjectAs="checked">
                    {{ 'INSURANCES.STATUS.IS_SELECTED' | translate: { partnerName: partner } }}
                    <ng-container *ngIf="move.insurancesStates?.selected?.createdOn as createdOn">
                        ({{ createdOn | formatDate }})
                    </ng-container>
                </ng-container>
                <ng-container ngProjectAs="unchecked">{{
                    'INSURANCES.STATUS.IS_SELECTED' | translate: { partnerName: partner }
                }}</ng-container>
            </app-check-item>

            <app-check-item class="u-margin-bottom-half" [checked]="!!move.insurancesStates?.ordered?.value">
                <ng-container ngProjectAs="checked">
                    {{ 'INSURANCES.STATUS.IS_ORDERED' | translate: { name: move | userName } }}
                </ng-container>
                <ng-container ngProjectAs="unchecked">
                    {{ 'INSURANCES.STATUS.IS_ORDERED' | translate: { name: move | userName } }}
                </ng-container>
            </app-check-item>

            <app-check-item *ngIf="!!move.insurancesStates?.noInteractionByCustomer?.value" class="u-margin-bottom-half" [blocked]="true">
                <ng-container ngProjectAs="blocked">
                    {{ 'IOTS.STATUS.NO_INTERACTION_BY_CUSTOMER' | translate: { name: move | userName } }}
                    <ng-container *ngIf="move.insurancesStates.noInteractionByCustomer?.createdOn as createdOn">
                        ({{ createdOn | formatDate }})
                    </ng-container>
                </ng-container>
            </app-check-item>

            <app-check-item *ngIf="!!move.insurancesStates?.notAcceptedByInsurer?.value" class="u-margin-bottom-half" [blocked]="true">
                <ng-container ngProjectAs="blocked">
                    <span>
                        {{
                            'IOTS.STATUS.NOT_ACCEPTED_BY_INSURER'
                                | translate
                                    : {
                                          insurerName:
                                              move.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                              ('INSURANCES.INSURER' | translate)
                                      }
                        }}
                        <ng-container *ngIf="move.insurancesStates.notAcceptedByInsurer?.createdOn as createdOn">
                            ({{ createdOn | formatDate }})
                        </ng-container>
                    </span>
                </ng-container>
            </app-check-item>

            <app-check-item *ngIf="!!move.insurancesStates?.notAcceptedByCustomer?.value" class="u-margin-bottom-half" [blocked]="true">
                <ng-container ngProjectAs="blocked">
                    <span>
                        {{ 'IOTS.STATUS.NOT_ACCEPTED_BY_CUSTOMER' | translate: { name: move | userName } }}
                        <ng-container *ngIf="move.insurancesStates.notAcceptedByCustomer?.createdOn as createdOn">
                            ({{ createdOn | formatDate }})
                        </ng-container>
                    </span>
                </ng-container>
            </app-check-item>

            <app-check-item class="u-margin-bottom-half" [checked]="!!move.insurancesStates?.confirmed?.value">
                <ng-container ngProjectAs="checked">
                    <div>
                        {{
                            'INSURANCES.STATUS.IS_CONFIRMED'
                                | translate
                                    : {
                                          insurerName:
                                              move.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                              ('INSURANCES.INSURER' | translate)
                                      }
                        }}
                        <ng-container *ngIf="move.insurancesStates?.confirmed?.createdOn as createdOn">
                            ({{ createdOn | formatDate }})
                        </ng-container>
                    </div>
                    <app-insurances-contract-pdf [move]="move"></app-insurances-contract-pdf>
                </ng-container>
                <ng-container ngProjectAs="unchecked">
                    {{
                        'INSURANCES.STATUS.IS_CONFIRMED'
                            | translate
                                : {
                                      insurerName:
                                          move.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                          ('INSURANCES.INSURER' | translate)
                                  }
                    }}
                </ng-container>
            </app-check-item>

            <app-check-item class="u-margin-bottom-half" [checked]="!!move.insurancesStates?.paid?.value">
                <ng-container ngProjectAs="checked">
                    {{
                        'INSURANCES.STATUS.IS_PAID'
                            | translate
                                : {
                                      insurerName:
                                          move.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                          ('INSURANCES.INSURER' | translate)
                                  }
                    }}
                    <ng-container *ngIf="move.insurancesStates?.paid?.createdOn as createdOn">
                        ({{ createdOn | formatDate }})
                    </ng-container>
                </ng-container>
                <ng-container ngProjectAs="unchecked">
                    {{
                        'INSURANCES.STATUS.IS_PAID'
                            | translate
                                : {
                                      insurerName:
                                          move.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                          ('INSURANCES.INSURER' | translate)
                                  }
                    }}
                </ng-container>
            </app-check-item>

            <app-check-item *ngIf="!!move.insurancesStates?.cancelled?.value" class="u-margin-bottom-half" [blocked]="true">
                <ng-container ngProjectAs="blocked">
                    <span>
                        {{ 'IOTS.STATUS.IS_CANCELLED' | translate }}
                        <ng-container *ngIf="move.insurancesStates.cancelled?.createdOn as createdOn">
                            ({{ createdOn | formatDate }})
                        </ng-container>
                    </span>
                </ng-container>
            </app-check-item>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailInsurancesComponent {
    @Input() public move: Move;
    public readonly partner = environment.insurancesPartner;
}
