import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiFormModule } from '../form/form.module';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { LanguageProvidersModule } from './language-providers.module';

@NgModule({
    declarations: [LanguageMenuComponent],
    imports: [CommonModule, UiFormModule, FormsModule, ReactiveFormsModule, LanguageProvidersModule],
    exports: [LanguageMenuComponent],
})
export class LanguageModule {}
