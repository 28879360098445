import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ButtonAppearance, UiContext, UiIcon, UiIconAppearance, UriSchemaType } from '@smooved/ui';
import { meterInfoStateBaseLabel } from './meter-info-state.constants';
import { DbUtils, SimpleChangesUtils } from '@smooved/core';

@Component({
    selector: 'smvd-app-meter-info-state',
    templateUrl: 'meter-info-state.component.html',
})
export class MeterInfoStateComponent implements OnChanges {
    @Input() public move: Move;

    public icon = UiIcon.Info;
    public iconAppearance = UiIconAppearance.Default;
    public context = UiContext.None;
    public stateLabel = `${meterInfoStateBaseLabel}.NOT_COMPLETE`;
    public baseLabel = meterInfoStateBaseLabel;
    public mailContent: string;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly uriSchemaType = UriSchemaType;

    constructor(private readonly translateService: TranslateService) {}

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.icon = this.move.moveStates?.meterComplete ? UiIcon.CheckCircle : UiIcon.Info;
            this.context = this.move.moveStates?.meterComplete ? UiContext.Success : UiContext.None;
            this.iconAppearance = this.move.moveStates?.meterComplete ? UiIconAppearance.Filled : UiIconAppearance.Default;
            this.stateLabel = this.move.moveStates?.meterComplete
                ? `${meterInfoStateBaseLabel}.CONFIRMED`
                : `${meterInfoStateBaseLabel}.NOT_COMPLETE`;

            const subject = this.translateService.instant(`${meterInfoStateBaseLabel}.SUBJECT`)
            this.mailContent = `${environment.mailTo[this.translateService.currentLang] as string}?subject=[${DbUtils.getStringId(this.move)}] - ${subject}`;
        }
    }
}
