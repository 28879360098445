import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsurancesInsurerReview } from '@app/iots/interfaces/insurances-insurer-review';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-insurances-suggestion-review',
    template: `
        <div
            class="__container u-cursor-pointer u-flex-column u-flex-align-items-center u-flex-justify-content-center u-border-radius"
            (mouseenter)="isOpen = true"
            (mouseleave)="isOpen = false"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
        >
            <app-svg class="u-color-white" [name]="thumb"></app-svg>
            <span class="u-font-size-small u-color-white">{{ review?.total }}%</span>
        </div>

        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
            <div class="__overlay u-background-white u-padding u-border-radius u-box-shadow">
                <div class="u-margin-bottom-double">
                    <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.REVIEW.OVERALL.TITLE' | translate }}</h6>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.OVERALL.TOTAL' | translate"
                        [percentage]="review?.overall?.total"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.OVERALL.PRICE_QUALITY' | translate"
                        [percentage]="review?.overall?.pricequality"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.OVERALL.ADMINISTRATION' | translate"
                        [percentage]="review?.overall?.administration"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.OVERALL.COVERAGE' | translate"
                        [percentage]="review?.overall?.coverage"
                    ></app-insurances-suggestion-review-more-info>
                </div>
                <div>
                    <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.REVIEW.DAMAGE.TITLE' | translate }}</h6>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.DAMAGE.LEAD_TIME' | translate"
                        [percentage]="review?.damage?.leadtime"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.DAMAGE.SWIFTNESS' | translate"
                        [percentage]="review?.damage?.swiftness"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.DAMAGE.COMMUNICATION' | translate"
                        [percentage]="review?.damage?.communication"
                    ></app-insurances-suggestion-review-more-info>
                    <app-insurances-suggestion-review-more-info
                        [label]="'IOTS.SUGGESTIONS.REVIEW.DAMAGE.PAYMENT_LEAD_TIME' | translate"
                        [percentage]="review?.damage?.paymentleadtime"
                    ></app-insurances-suggestion-review-more-info>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./insurances-suggestion-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancesSuggestionReviewComponent {
    @Input() public review: InsurancesInsurerReview;
    public thumb = Svg.Thumb;
    public isOpen = false;
}
