import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { environment } from '@environments/environment';
import { TranslateUtils } from '@smooved/core';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-insights-upgrade-info',
    templateUrl: 'insights-upgrade-info.component.html',
    styleUrls: ['insights-upgrade-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsUpgradeInfoComponent {
    public readonly appI18nKeyType = AppI18nKeyType;
    public readonly link$ = this.translationSandbox.selected$.pipe(
        map((language) =>
            TranslateUtils.getAvailableLanguage(environment.packageInfoUri, language || this.translationSandbox.defaultLanguage)
        )
    );

    constructor(private translationSandbox: TranslationSandbox) {}
}
