import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressOutputComponent } from './components/address-output/address-output.component';

@NgModule({
    declarations: [AddressOutputComponent],
    exports: [AddressOutputComponent],
    imports: [CommonModule],
})
export class AddressModule {}
