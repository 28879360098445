import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationData } from '../../notification.interfaces';

@Component({
    selector: 'app-notification-success',
    template: `
        <span translate [translateParams]="data.translateParams" data-testid="notification-success-message">{{ data.labelResource }}</span>
    `,
})
export class NotificationSuccessComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData) {}
}
