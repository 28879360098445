<app-closable-modal-template style="max-width: 700px">
    <app-loading-container [loading]="loading">
        <h5 modal-header>{{ 'ANALYTICS.REVIEWS.ACTIONS.EDIT' | translate }}</h5>
        <form [formGroup]="form" class="__form u-margin-bottom-double u-flex-column">
            <app-textarea-input
                [formControlName]="formControlNames.GeneratedSummary"
                [hasMarginDouble]="false"
                [hasMargin]="false"
                [rows]="4"
                width="100%"
                [label]="'UI.REVIEWS.GENERATED_SUMMARY.TITLE' | translate"
                id="generated-summary"
            >
            </app-textarea-input>
            <app-textarea-input
                [formControlName]="formControlNames.Suggestion"
                [hasMarginDouble]="false"
                [hasMargin]="false"
                [rows]="8"
                width="100%"
                [label]="'ANALYTICS.REVIEWS.REVIEW' | translate"
                id="suggestion"
            >
            </app-textarea-input>

            <div class="u-flex-row u-flex-gap-column">
                <ui-searchable-dropdown-input
                    id="assessed-to"
                    [label]="'SURVEYS.INTERVIEW.SELECT_INTERVIEWEE' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [formControlName]="formControlNames.AssessedTo"
                    [options]="intervieweeOptions"
                ></ui-searchable-dropdown-input>
                <smvd-ui-number-input
                    [label]="'UI.REVIEWS.SCORE.TITLE' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    id="score"
                    [formControlName]="formControlNames.Score"
                ></smvd-ui-number-input>
            </div>
            <div class="u-flex-row u-flex-gap-column u-flex-align-items-end">
                <ui-searchable-dropdown-input
                    [formControlName]="formControlNames.Location"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [includeOtherOption]="true"
                    [label]="'UI.LOCATIONS.FORM_LABEL' | translate"
                    [multiple]="false"
                    [options]="locationOptions"
                    [showOverflowValuesAsCount]="true"
                    [showRequiredAsterisk]="true"
                ></ui-searchable-dropdown-input>
                <ui-searchable-dropdown-input
                    [style.width]="'auto'"
                    [formControlName]="formControlNames.LinkedRealEstateAgents"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.AGENTS.REVIEW_FORM_LABEL' | translate"
                    [placeholder]="'SEARCH' | translate"
                    [multiple]="true"
                    [options]="agentOptions"
                    [otherOptions]="agentOptionsOthers"
                    [otherOptionsGroupName]="'OTHER'"
                    [showOverflowValuesAsCount]="true"
                    [showRequiredAsterisk]="true"
                    [includeOtherOption]="true"
                ></ui-searchable-dropdown-input>
            </div>
        </form>
        <div class="u-flex-row">
            <app-nps-score [score]="data.score"></app-nps-score>
            <app-nps-review-author [review]="data" class="u-margin-top-half u-margin-left"></app-nps-review-author>
        </div>
        <div class="u-flex-row u-color-accent u-margin-top" *ngIf="data.isAnonymous">
            <app-icon class="u-margin-right-half" size="md" icon="error"></app-icon>
            {{ 'ANALYTICS.REVIEWS.ANONYMOUS_ALERT' | translate }}
        </div>
        <div class="u-flex u-flex-justify-content-space-between u-margin-top-double">
            <button
                [context]="uiContext.Warning"
                (click)="confirmDeleteReview()"
                class="__update_rea_modal_revoke_access_button"
                smvd-ui-button
            >
                {{ 'COMMON.DELETE' | translate }}
            </button>
            <app-button (onClick)="submit()">{{ 'SAVE' | translate }}</app-button>
        </div>
    </app-loading-container>
</app-closable-modal-template>
