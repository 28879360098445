import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReviewsData } from '@smooved/core';
import { uiI18nKeyTypes } from '../../../i18n';
import { SvgIllustration } from '../../../svg';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'smvd-match-external-score',
    templateUrl: 'match-external-score.component.html',
    styleUrls: ['match-external-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchExternalScoreComponent {
    @Input() public meta: ReviewsData;

    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly uiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;
}
