import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { crmClientDropdownOptions } from '@app/client/constants/client.constants';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupUtils } from '@app/real-estate-group/utils/real-estate-group.utils';
import { TranslateService } from '@ngx-translate/core';
import {
    ButtonAppearance,
    ButtonSize,
    NotificationSandbox,
    PhoneNumberValidators,
    SearchableDropdownInputOption,
    UiContext,
} from '@smooved/ui';
import { FormControlNames } from './agent-form.constants';
import { RealEstateAgentFormData } from '@app/real-estate-agent/components/agent-form/agent-form.interfaces';
import { environment } from '@environments/environment';
import { faker } from '@faker-js/faker/locale/nl_BE';
import { DbUtils } from '@smooved/core';
import { finalize } from 'rxjs/operators';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';

@Component({
    selector: 'app-agent-form',
    templateUrl: 'agent-form.component.html',
    styleUrls: ['./agent-form.component.scss'],
})
export class AgentFormComponent implements OnInit {
    @Input() public realEstateGroup: RealEstateGroup;
    @Input() public submitLabel: string;

    public officesOptions: SearchableDropdownInputOption[] = [];
    public locationOptions: SearchableDropdownInputOption[] = [];
    public showMockData = environment.mockFormData.createRealEstateAgent;
    public loading = false;

    public realEstateAgentForm: UntypedFormGroup = this.fb.group({
        [FormControlNames.firstName]: [null, Validators.required],
        [FormControlNames.lastName]: [null, Validators.required],
        [FormControlNames.phoneNumber]: [null, PhoneNumberValidators.isValidPhoneNumber()],
        [FormControlNames.email]: [null, Validators.required],
        [FormControlNames.office]: [null, Validators.required],
        [FormControlNames.locations]: [null, Validators.required],
        [FormControlNames.language]: [this.translateService.currentLang, Validators.required],
        [FormControlNames.bivCode]: [],
        [FormControlNames.crmSystem]: null,
        [FormControlNames.crmId]: null,
    });

    public readonly crmClientOptions = crmClientDropdownOptions;
    public readonly formControlNames = FormControlNames;

    protected readonly buttonSize = ButtonSize;
    protected readonly uiContext = UiContext;
    protected readonly buttonAppearance = ButtonAppearance;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly reaSandbox: RealEstateAgentSandbox,
        private readonly dialogRef: MatDialogRef<any>,
        private readonly translateService: TranslateService,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public ngOnInit(): void {
        this.officesOptions = RealEstateGroupUtils.officeOptionsFactory(this.realEstateGroup);
        this.locationOptions = RealEstateGroupUtils.locationsOptionsFactory(this.realEstateGroup);
    }

    public createAgent(): void {
        if (!this.realEstateAgentForm.valid) return;
        this.loading = true;

        const { firstName, lastName, phoneNumber, email, office, locations, language, bivCode, crmSystem, crmId } = this.realEstateAgentForm
            .value as RealEstateAgentFormData;

        this.reaSandbox
            .invite({
                firstName,
                lastName,
                phoneNumber,
                email,
                office,
                locations,
                language,
                bivCode,
                realEstateGroup: DbUtils.getStringId(this.realEstateGroup),
                ...(crmSystem ? { [crmSystem]: crmId } : {}),
            })
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((_) => {
                this.notificationSandbox.success(NotificationLabel.AuthenticationRealEstateAgentInviteSuccess, {
                    email,
                });
                this.dialogRef.close(true);
            });
    }

    public prefillMockData() {
        const data: Partial<RealEstateAgentFormData> = {
            [FormControlNames.firstName]: faker.person.firstName(),
            [FormControlNames.lastName]: faker.person.lastName(),
            [FormControlNames.email]: faker.internet.email().toLowerCase(),
            [FormControlNames.phoneNumber]: '0477112233',
        };
        this.realEstateAgentForm.patchValue(data);
    }
}
