<form [formGroup]="externalInfluencerService.form.get(formGroupNames.about)">
    <mat-tab-group
        dynamicHeight
        class="mat-tab-group-theme mat-tab-group-theme--pill"
        animationDuration="0ms"
        [disableRipple]="true"
        mat-align-tabs="center"
    >
        <mat-tab *ngFor="let option of languageOptions" [label]="option.labelResource | translate">
            <ng-template matTabContent>
                <div [formGroupName]="formControlNames.about" class="u-margin-bottom">
                    <smvd-ui-textarea-input
                        [hasMargin]="false"
                        width="100%"
                        [maxLength]="maxAboutUsLength"
                        [formControlName]="option.value"
                    ></smvd-ui-textarea-input>
                </div>
                <app-select-input
                    [custom]="false"
                    [options]="[aboutDefaultLanguageOptions.get(option.value)]"
                    [formControlName]="formControlNames.aboutDefaultLanguage"
                ></app-select-input>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</form>
