<button
    app-button
    icon="close"
    [appearance]="buttonAppearance.Icon"
    *ngIf="options.closeButton"
    (click)="remove()"
    class="toast-close-button u-color-white"
    aria-label="Close"
    [iconSize]="compact ? iconSize.Md : iconSize.Xm"
></button>
<ng-container *ngIf="!componentRef; else useComponent">
    <!-- START code copied from toastr html -->
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
    </div>
    <div *ngIf="options.progressBar">
        <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
    <!-- END code copied from toastr html -->
</ng-container>
<ng-template #useComponent></ng-template>
