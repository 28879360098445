import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencySystemUnit',
})
export class CurrencySystemUnitPipe implements PipeTransform {
    transform(value: number): string {
        if (!value || ((value > -1000) && (value < 1000)))
            return this.currencyPipe.transform(value || 0, 'EUR', 'symbol', '1.0-0', 'nl-BE');

        return this.currencyPipe.transform(value / 1000, 'EUR', 'symbol', '1.0-0', 'nl-BE') + 'K';
    }

    constructor(private currencyPipe: CurrencyPipe) {}
}
