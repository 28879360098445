import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { INVOICING_MAILTO_EMAIL } from '@app/real-estate-agent/constants/invoicing.constants';
import { TranslateService } from '@ngx-translate/core';
import { UiContext, UiIcon } from '@smooved/ui';

@Component({
    selector: 'app-invoices-billing-changes-info',
    templateUrl: 'invoices-billing-changes-info.component.html',
    styleUrls: ['invoices-billing-changes-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class InvoicesBillingChangesInfoComponent {
    public realEstateGroupId: string;

    public invoicingMailtoEmail = INVOICING_MAILTO_EMAIL;
    public invoicingPricingUrl = this.translate.instant('INVOICING.INVOICING_CHANGES_2024.PRICING_URL');

    public readonly uiContext = UiContext;
    public readonly uiIcon = UiIcon;

    constructor(private translate: TranslateService) {}
}
