import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { TranslateService } from '@ngx-translate/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-services-interest',
    template: `
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">{{ 'ADMIN.DASHBOARD.DETAIL.SERVICES_INTEREST' | translate }}</h6>
            <div class="u-flex-row" *ngIf="hasSomeInterest(); else empty">
                <app-svg
                    *ngFor="let service of getInterests()"
                    [name]="icons[service]"
                    width="40"
                    height="40"
                    [title]="translateService.instant('REAL_ESTATE_AGENT.EOTS.OTHER_SERVICES.OPTIONS.' + service.toUpperCase())"
                    class="u-margin-right-half u-color-brand-dark"
                ></app-svg>
            </div>
            <ng-template #empty>-</ng-template>
        </div>
    `,
})
export class ServicesInterestComponent {
    @Input() public move: Move;

    public icons = {
        energy: Svg.GasAndElect,
        telecom: Svg.Phone,
        water: Svg.Water,
        insurances: Svg.Umbrella,
        logistics: Svg.Box,
        post: Svg.Mail,
    };

    constructor(public translateService: TranslateService) {}

    public getInterests(): string[] {
        return Object.keys(this.move?.interested)?.filter((key) => !!this.move.interested[key]);
    }

    public hasSomeInterest(): boolean {
        if (!this.move?.interested) {
            return false;
        }
        return Object.values(this.move?.interested).some((interest) => !!interest);
    }
}
