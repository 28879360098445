import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class NameValidators {
    public static firstName(control: AbstractControl): ValidationErrors {
        return Validators.maxLength(50)(control);
    }

    public static lastName(control: AbstractControl): ValidationErrors {
        return Validators.maxLength(50)(control);
    }
}
