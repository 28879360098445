import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateScreenshotRequest } from '@app/document-center/interfaces/create-screenshot-request';
import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Move } from '@app/move/interfaces/move';
import { FileUtils, HttpUtils, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { DocumentCenterProvidersModule } from '../document-center-providers.module';
import { byMoveUri, createScreenshotUri, templateMappingsUri, transactionsTemplateUri } from '../document-center-uri.constants';
import { TemplateTag } from '@app/document-center/enums/template-tag';
import { TemplateMappings } from '@app/document-center/interfaces/template-mapping';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: DocumentCenterProvidersModule })
export class DocumentCenterService {
    constructor(private readonly httpClient: HttpClient) {}

    public downloadEnergyDocumentSummary(moveId: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.downloadMoveFile(moveId, AttachmentId.EnergyDocumentSummary);
    }

    public downloadMoveFile(moveId: string, type: AttachmentId): Observable<HttpResponse<ArrayBuffer>> {
        const params = {
            moveId,
            type,
        };
        return this.httpClient.get(StringUtils.parseUri(byMoveUri, params), {
            responseType: 'arraybuffer',
            observe: 'response',
        });
    }

    public downloadFileLocation(location: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.httpClient.get(location, {
            responseType: 'arraybuffer',
            observe: 'response',
        });
    }

    public createScreenshot(request: CreateScreenshotRequest): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(createScreenshotUri, {
            observe: 'response',
            responseType: 'blob',
            params: HttpUtils.buildQueryParameters(request),
        });
    }

    public saveMoveFiles(moveId: string, type: AttachmentId, formData: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(byMoveUri, { moveId, type }), formData);
    }

    public deleteMoveFile(moveId: string, type: AttachmentId): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(byMoveUri, { moveId, type }));
    }

    public getTemplateMappings(tag?: TemplateTag): Observable<TemplateMappings> {
        return this.httpClient.get<TemplateMappings>(templateMappingsUri, {
            params: HttpUtils.buildQueryParameters({
                tag,
            }),
        });
    }

    public generateTransactionTemplate(transactionId: string, templateMappingId: string): Observable<any> {
        return this.httpClient.post(
            StringUtils.parseUri(transactionsTemplateUri, {
                transactionId,
                templateMappingId,
            }),
            null,
            {
                responseType: 'blob',
                observe: 'response',
            }
        );
    }
}
