import { EnumUtils } from '@app/shared/utils/enum-utils';
import { Language } from '@smooved/core';
import { SelectInput } from '@smooved/ui';

export const languageOptions: { value: Language; labelResource: string }[] = EnumUtils.keys(Language).map((key) => ({
    labelResource: `LANGUAGE.${key.toUpperCase()}.LABEL`,
    value: Language[key],
}));

const defaultOption = {
    name: 'defaultLanguage',
    labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.DEFAULT_LANGUAGE',
    id: 'default-language',
};
export const aboutDefaultLanguageOptions: Map<Language, SelectInput<Language>> = new Map<Language, SelectInput<Language>>()
    .set(Language.NL, {
        ...defaultOption,
        value: Language.NL,
    })
    .set(Language.FR, {
        ...defaultOption,
        value: Language.FR,
    })
    .set(Language.EN, {
        ...defaultOption,
        value: Language.EN,
    });

export const editorModules = {
    toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline'], ['link', 'image', 'video']],
};
