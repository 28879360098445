import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsightsData } from '@app/real-estate-agent/interfaces/insights';

@Component({
    selector: 'app-insights-house-match-score-info',
    templateUrl: 'insights-house-match-score-info.component.html',
    styleUrls: ['insights-house-match-score-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsHouseMatchScoreInfoComponent {
    @Input() public insightsData: InsightsData;
}
