<app-loading-container [loading]="!dataSource">
    <header class="u-flex-row u-flex-align-items-center u-padding-bottom">
        <h6>{{ 'SMOOVED_ANALYTICS.OVERVIEW.TITLE' | translate }}</h6>
    </header>
    <table mat-table [dataSource]="dataSource" class="u-w100p u-data-table-with-hover">
        <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef class="__cell __cell-label"></th>
            <td
                mat-cell
                *matCellDef="let row"
                class="__cell __cell-label"
                [class.__zero-cell]="row.isActive === false"
                [title]="'SMOOVED_ANALYTICS.OVERVIEW.ARCHIVED' | translate"
            >
                <div class="u-flex-row u-flex-align-items-center">
                    {{ row.label }}
                    <app-svg-illustration
                        *ngIf="row.isActive === false"
                        class="u-margin-left-half"
                        [svg]="svgIllustration.Archived"
                    ></app-svg-illustration>
                </div>
            </td>
        </ng-container>

        <ng-container [matColumnDef]="weeklyCol" *ngFor="let weeklyCol of weeklyColumns; index as index">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="__cell __cell-week"
                [class.__cell-week--current]="index === weeklyColumns.length - 1"
            >
                {{ weeklyCol | formatDate: weekNumber }}
            </th>
            <td
                mat-cell
                *matCellDef="let row"
                class="__cell __cell-week"
                [class.__cell-week--current]="index === weeklyColumns.length - 1"
                [class.__zero-cell]="row[weeklyCol | formatDate: 'GGGG-W'] === 0"
            >
                {{ row[weeklyCol | formatDate: isoYearWeek] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="__cell __cell-total">{{ 'TOTAL' | translate }}</th>
            <td mat-cell *matCellDef="let row" class="__cell __cell-total u-font-weight-bold" [class.__zero-cell]="row.total === 0">
                {{ row.total }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: columns"
            [class.__row-group]="row.isGroup"
            [class.__row--grouped]="!row.isGroup && row.group"
            [class.__row-group--open]="row.isGroup && row.visible"
            [class.u-font-weight-bold]="row.isTotal"
            [hidden]="!row.isGroup && !row.visible"
            (click)="onClickRow(row)"
        ></tr>
    </table>
</app-loading-container>
