import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nKeyTranslatePipe } from './i18n-key-translate.pipe';
import { I18nKeyPipe } from './i18n-key.pipe';

@NgModule({
    declarations: [I18nKeyPipe, I18nKeyTranslatePipe],
    imports: [CommonModule],
    exports: [I18nKeyPipe, I18nKeyTranslatePipe],
})
export class I18nKeyModule {}
