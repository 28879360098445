import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-leaving-address-detail',
    template: `
        <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.USER.LEAVING_ADDRESS' | translate }}</h6>
        <app-copy-to-clipboard
            *ngIf="!!move?.linkedMove?.user?.movingAddress || !!move?.user?.currentAddress; else noAddress"
            class="u-display-block"
        >
            <app-address-output [address]="move?.linkedMove?.user?.movingAddress || move?.user?.currentAddress"></app-address-output>
        </app-copy-to-clipboard>

        <ng-template #noAddress>
            <span class="u-color-muted u-opacity-50">{{ 'UNKNOWN' | translate }}</span>
        </ng-template>
    `,
    styles: [':host { display: block; }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeavingAddressDetailComponent {
    @Input() move: Move;
}
