import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiLibModule } from '../ui.module';
import { CollapsibleDirective } from './collapsible.directive';

@NgModule({
    declarations: [CollapsibleDirective],
    imports: [CommonModule, UiLibModule],
    exports: [CollapsibleDirective],
})
export class AccordionModule {}
