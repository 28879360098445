export interface ServiceOrderedI18n {
    Todo: string;
    Resend: string;
    Confirm: string;
    Success: string;
}

export const ServiceOrderedI18nKeys: ServiceOrderedI18n = {
    Todo: 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.ORDERED',
    Resend: 'RESEND_OPTIMIZATION',
    Confirm: 'RESEND.CONFIRM',
    Success: 'RESEND.SUCCESS',
};
