/** Error when invalid control is touched. */
import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CoreUtils } from '@smooved/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        if (!control && !CoreUtils.isEmpty(this.formControlName)) return false;

        const formControl = this.getAbstractControl(control, form, this.formControlName);
        const isSubmitted = form?.submitted;

        const checkControl = (c): boolean => c && (c.touched || isSubmitted) && c.invalid;

        return formControl ? !!checkControl(formControl) : false;
    }

    constructor(private formControlName: string) {}

    private getAbstractControl(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null,
        formControlName: string | null
    ): AbstractControl {
        return !CoreUtils.isEmpty(formControlName) ? form?.control.get(formControlName.toString()) : control;
    }
}
