import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CompanyPageProvidersModule } from '../../company-page-providers.module';

@Injectable({ providedIn: CompanyPageProvidersModule })
export class CompanyPageTemplateService {
    private hasContentAsideSubject = new BehaviorSubject<boolean>(false);
    private hasContentAsideStickySubject = new BehaviorSubject<boolean>(false);
    private hasFooterSubject = new BehaviorSubject<boolean>(false);

    public hasContentAside$ = this.hasContentAsideSubject.asObservable();
    public hasContentAsideSticky$ = this.hasContentAsideStickySubject.asObservable();
    public hasFooter$ = this.hasFooterSubject.asObservable();

    public showContentAside(show: boolean): void {
        this.hasContentAsideSubject.next(show);
    }

    public showContentAsideSticky(show: boolean): void {
        this.hasContentAsideStickySubject.next(show);
    }

    public showFooter(show: boolean): void {
        this.hasFooterSubject.next(show);
    }
}
