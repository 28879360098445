import { Component, OnInit } from '@angular/core';
import { AnalyticsSandbox } from '@app/analytics/analytics.sandbox';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { ArcScoreData } from '@app/external-influencer/interfaces/arc-score-data';
import {
    realEstateAgentManagementModulesReviews,
    realEstateAgentReviewsInsights,
    realEstateAgentReviewsOverview,
} from '@app/navigation/navigation.constants';
import { SurveyInviteModal } from '@app/real-estate-agent/modals/survey-invite/survey-invite.modal';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { InsightsSandbox } from '@app/real-estate-group/sandboxes/insight.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { appI18nKeyTypes, i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CategoryEnum } from '@shared/review/enums/category.enum';
import { SentimentEnum } from '@shared/review/enums/sentiment.enum';
import { AnalyticsEventsEnum, AnalyticsService, FeatureScope, FeatureScopeSandbox, I18nKeyUtils, NumberUtils } from '@smooved/core';
import { BaseDashboardCardComponent, ButtonAppearance, ModalSandbox, SvgIllustration, UiContext } from '@smooved/ui';
import { Observable, zip } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-nps',
    templateUrl: 'nps.component.html',
    styleUrls: ['./nps.component.scss'],
})
export class NpsComponent extends BaseDashboardCardComponent implements OnInit {
    public npsReviewsRoute = ['', ...realEstateAgentReviewsOverview].join('/');
    public reviewsInsightsRoute = ['', ...realEstateAgentReviewsInsights].join('/');
    public settingsRoute = ['', ...realEstateAgentManagementModulesReviews].join('/');
    public npsUnreadReviewsCount = 0;

    public widgetData$: Observable<ArcScoreData>;
    public readonly featureScope = FeatureScope;
    public readonly environment = environment;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly housematchIcon = SvgIllustration.HouseMatchIcon;

    public emptyScore$ = this.insightsSandbox.houseMatchScore$.pipe(
        take(1),
        map((houseMatchScore) => NumberUtils.isEmpty(houseMatchScore))
    );

    constructor(
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        private readonly analyticsSandbox: AnalyticsSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        public readonly insightsSandbox: InsightsSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly authSandbox: AuthenticationSandbox,
        private readonly analyticsService: AnalyticsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.realEstateAgentSandbox.unreadReviewCount$
            .pipe(takeUntil(this.destroy$))
            .subscribe((count) => (this.npsUnreadReviewsCount = count));
        this.widgetData$ = this.analyticsSandbox.getArcScoreData();
        this.insightsSandbox.getTopicsAnalysis();
        this.insightsSandbox.getHouseMatchScore();
        this.insightsSandbox.getInsightsData();
    }

    public surveyInvite(): void {
        this.modalSandbox.openClosableModal({
            component: SurveyInviteModal,
            config: {
                header: this.translate.instant(I18nKeyUtils.map(i18nKeyTypeMap, appI18nKeyTypes.RealEstateAgentSurveyInvite, 'HEADER')),
            },
        });
    }

    public sendAnalyticsOnSentimentPillClick(event: { topic: CategoryEnum; sentiment: SentimentEnum }) {
        zip([this.realEstateGroupSandbox.name$, this.authSandbox.userId$])
            .pipe(
                take(1),
                tap(([regName, userId]) => {
                    this.analyticsService.sendEventForRealEstateAgent(
                        AnalyticsEventsEnum.ReviewsInsightsHMCardtopicClicked,
                        regName,
                        userId,
                        {
                            topic: event.topic,
                            sentiment: event.sentiment,
                        }
                    );
                })
            )
            .subscribe();
    }
}
