import { Component, ElementRef, forwardRef, Host, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-basic-file-input',
    template: `
        <div class="u-flex-column" [ngClass]="{ 'u-margin-bottom-double': hasMarginDouble, 'u-margin-bottom': hasMargin }">
            <label [for]="id" class="u-margin-bottom-half">{{ label }}</label>

            <div class="u-flex-row u-flex-align-items-center">
                <app-label-container [required]="required" [id]="id" class="u-margin-right" [hasMargin]="false" [hasMarginDouble]="false">
                    <mat-form-field floatLabel="never" appearance="outline">
                        <input
                            [id]="id"
                            type="text"
                            matInput
                            readonly
                            [placeholder]="placeholder"
                            [value]="getValue()"
                            autocomplete="smooved"
                        />
                    </mat-form-field>
                </app-label-container>

                <app-button (onClick)="input.click()" type="button" theme="secondary-outline">{{ 'SELECT' | translate }}</app-button>
            </div>
        </div>

        <input
            type="file"
            hidden
            [accept]="accept"
            #input
            [(ngModel)]="innerModel"
            [multiple]="multiple"
            (ngModelChange)="onModelChange()"
        />
        <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BasicFileInputComponent),
            multi: true,
        },
    ],
})
export class BasicFileInputComponent extends BaseInput implements ControlValueAccessor {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public accept = '*/*';
    @Input() public formControlName: string;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public multiple = false;
    @Input() public placeholder: string = this.translateService.instant('NO_ASSET');

    public innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, private translateService: TranslateService) {
        super(controlContainer);
    }

    public getValue(): string {
        return this.multiple ? this.getFileNames() : this.getFileName();
    }

    public getFileName(): string {
        const files = this.input?.nativeElement?.files;
        if (!files?.length) return '';
        return files[0]?.name;
    }

    public getFileNames(): string {
        const files = this.input.nativeElement.files;
        if (!files?.length) return '';
        return Array.from(files)
            ?.map((x: File) => x.name)
            .join(', ');
    }

    public writeValue(value: any): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
