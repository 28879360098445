<ng-container *ngIf="isBrowser">
    <app-label-container
        [required]="required"
        [id]="id"
        [label]="showLabel ? label : ''"
        [hasMargin]="hasMargin"
        [hasMarginDouble]="hasMarginDouble"
    >
        <mat-form-field class="u-input-size-{{ size }}" floatLabel="never" [appearance]="appearance">
            <div matPrefix class="__prefix">
                <ng-content select="[prefix]"></ng-content>
            </div>
            <input
                matInput
                [id]="id"
                [placeholder]="showPlaceholder ? placeholder : ''"
                type="text"
                autocomplete="off"
                googlePlacesAutocomplete
                #input
                [(ngModel)]="innerModel"
                (blur)="onBlur($event)"
                (ngModelChange)="onModelChange()"
                [disabled]="innerDisabled"
                [errorStateMatcher]="errorStateMatcher"
                (autocompleteSelected)="onAutoCompleteSelected($event)"
                [types]="types"
            />
            <div matSuffix class="__suffix">
                <ng-content select="[suffix]"></ng-content>
            </div>
            <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
        </mat-form-field>
    </app-label-container>
</ng-container>
