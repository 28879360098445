import { Pipe, PipeTransform } from '@angular/core';
import { ReviewSource } from '@smooved/core';
import { smoovedSourceScoreToStarFactor } from '../../reviews';

@Pipe({ name: 'starsScore' })
export class StarsScorePipe implements PipeTransform {
    public transform(value: number, source = ReviewSource.Smooved): number {
        switch (source) {
            case ReviewSource.Smooved:
                return value / smoovedSourceScoreToStarFactor;
            default:
                return value;
        }
    }
}
