import { TodoType } from '@app/move/enums/todo-type.enum';

export enum MoveLeaverDetailTab {
    energy = 'transfer-energy',
    water = 'transfer-water',
    contactLogs = 'contact-logs',
    nps = 'nps',
}

export const defaultActive = MoveLeaverDetailTab.energy;

export const energyTodoList = [
    TodoType.energyOptimizationNotOrdered,
    TodoType.energyNotOrdered,
    TodoType.energyNotOrderedBySmooved,
    TodoType.meterInfoNotComplete,
];

export const waterTodoList = [TodoType.waterDocumentsMovingAddressByRealEstateAgent];
