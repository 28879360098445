import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getProviderChoiceState } from '../../move/state/move.reducer';
import { TelecomSuggestionsFilter } from '../interfaces/telecom-suggestions-filter';
import { TelecomSuggestionsFilterOptions } from '../interfaces/telecom-suggestions-filter-options';
import {
    clearTelecomSuggestions,
    getTelecomSuggestionsFilterOptionsSuccess,
    getTelecomSuggestionsSuccess,
    setTelecomSuggestionsFilter,
} from './telecom.actions';

export interface TelecomState {
    telecomSuggestionsData: any[];
    telecomSuggestionsFilter?: TelecomSuggestionsFilter;
    telecomSuggestionsFilterOptions: TelecomSuggestionsFilterOptions;
}

export const initialState: TelecomState = {
    telecomSuggestionsData: [],
    telecomSuggestionsFilterOptions: null,
};

const reducer = createReducer(
    initialState,

    on(clearTelecomSuggestions, (state) => ({
        ...state,
        telecomSuggestionsData: [],
    })),

    on(getTelecomSuggestionsSuccess, (state, { telecomSuggestions }) => ({
        ...state,
        telecomSuggestionsData: [...telecomSuggestions],
    })),

    on(setTelecomSuggestionsFilter, (state, { telecomSuggestionsFilter }) => ({
        ...state,
        telecomSuggestionsFilter: { ...telecomSuggestionsFilter },
    })),

    on(getTelecomSuggestionsFilterOptionsSuccess, (state, { telecomSuggestionsFilterOptions }) => ({
        ...state,
        telecomSuggestionsFilterOptions: { ...telecomSuggestionsFilterOptions },
    }))
);

export function telecomReducer(state: TelecomState | undefined, action: Action) {
    return reducer(state, action);
}

export const getTelecomState = createFeatureSelector<TelecomState>('telecom');

export const getTelecomSuggestionsDataState = createSelector(
    getTelecomState,
    getProviderChoiceState,
    (telecomState, providerChoiceState) => {
        const suggestions = [
            ...telecomState.telecomSuggestionsData.filter(
                (telecomSuggestion) => telecomSuggestion.provider?.toLowerCase() !== providerChoiceState?.toLowerCase()
            ),
        ];
        const providerChoiceSuggestion = telecomState.telecomSuggestionsData.find(
            (telecomSuggestion) => telecomSuggestion.provider?.toLowerCase() === providerChoiceState?.toLowerCase()
        );
        if (providerChoiceSuggestion) {
            suggestions.unshift(providerChoiceSuggestion);
        }
        return suggestions;
    }
);

export const getTelecomSuggestionsFilterState = createSelector(getTelecomState, (state) => state.telecomSuggestionsFilter);

export const getTelecomSuggestionsFilterOptionsState = createSelector(getTelecomState, (state) => state.telecomSuggestionsFilterOptions);
