<app-loading-container [loading]="loading">
    <form (ngSubmit)="createAgent()" [formGroup]="realEstateAgentForm" novalidate>
        <h5 class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.CREATE.PERSONAL_INFO' | translate }}</h5>
        <section>
            <div class="u-row-equal-width-responsive u-grid-align-items-center u-margin-bottom-tablet-portrait-up">
                <app-text-input
                    [formControlName]="formControlNames.firstName"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.FIRST_NAME.LABEL' | translate"
                    [placeholder]="'UI.FIRST_NAME.PLACEHOLDER' | translate"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-up-to-and-including-phone-landscape"
                    id="firstName"
                    width="100%"
                ></app-text-input>
                <app-text-input
                    [formControlName]="formControlNames.lastName"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.LAST_NAME.LABEL' | translate"
                    [placeholder]="'UI.LAST_NAME.PLACEHOLDER' | translate"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-up-to-and-including-phone-landscape"
                    id="lastName"
                    width="100%"
                ></app-text-input>
            </div>
            <div class="u-row-equal-width-responsive u-grid-align-items-center u-margin-bottom-tablet-portrait-up">
                <app-text-input
                    [formControlName]="formControlNames.phoneNumber"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.MOBILE_PHONE.LABEL' | translate"
                    [placeholder]="'UI.MOBILE_PHONE.PLACEHOLDER' | translate"
                    class="u-margin-bottom-up-to-and-including-phone-landscape"
                    id="phoneNumber"
                    width="100%"
                ></app-text-input>
                <app-text-input
                    [formControlName]="formControlNames.email"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.EMAIL.LABEL' | translate"
                    [placeholder]="'UI.EMAIL.PLACEHOLDER' | translate"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-up-to-and-including-phone-landscape"
                    id="email"
                    width="100%"
                ></app-text-input>
            </div>
            <div class="u-row-equal-width-responsive u-grid-align-items-center u-margin-bottom-tablet-portrait-up">
                <app-language-input
                    [formControlName]="formControlNames.language"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'LANGUAGE.LABEL' | translate"
                    [required]="true"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-up-to-and-including-phone-landscape"
                    width="100%"
                ></app-language-input>
            </div>
            <div class="u-row-equal-width-responsive u-grid-align-items-center">
                <app-text-input
                    [formControlName]="formControlNames.bivCode"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.BIV_NUMBER.LABEL' | translate"
                    [placeholder]="'UI.BIV_NUMBER.PLACEHOLDER' | translate"
                    class="u-margin-bottom-half-up-to-and-including-phone-landscape"
                    id="bivCode"
                    width="100%"
                ></app-text-input>
                <p class="u-color-gray-dark u-font-size-sm">
                    {{ 'UI.BIV_NUMBER.FORM_DESCRIPTION' | translate }}
                </p>
            </div>
        </section>

        <div class="u-divider"></div>

        <h5 class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.CREATE.SMOOVED_SETTINGS' | translate }}</h5>
        <section>
            <div class="u-row-equal-width-responsive u-grid-align-items-center u-margin-bottom-tablet-portrait-up">
                <ui-searchable-dropdown-input
                    [formControlName]="formControlNames.office"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.OFFICES.FORM_LABEL_SINGLE' | translate"
                    [options]="officesOptions"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-half-up-to-and-including-phone-landscape"
                    id="office"
                    width="100%"
                ></ui-searchable-dropdown-input>

                <p class="u-color-gray-dark u-font-size-sm u-margin-bottom-up-to-and-including-phone-landscape">
                    {{ 'UI.OFFICES.FORM_DESCRIPTION' | translate }}
                </p>
            </div>

            <div class="u-row-equal-width-responsive u-grid-align-items-center">
                <ui-searchable-dropdown-input
                    [formControlName]="formControlNames.locations"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [includeOtherOption]="true"
                    [label]="'UI.LOCATIONS.FORM_LABEL_PLURAL' | translate"
                    [multiple]="true"
                    [options]="locationOptions"
                    [showOverflowValuesAsCount]="true"
                    [showRequiredAsterisk]="true"
                    class="u-margin-bottom-half-up-to-and-including-phone-landscape"
                    id="locations"
                    width="100%"
                ></ui-searchable-dropdown-input>
                <p class="u-color-gray-dark u-font-size-sm">{{ 'UI.LOCATIONS.FORM_DESCRIPTION' | translate }}</p>
            </div>
        </section>

        <div class="u-divider"></div>

        <h5 class="u-margin-bottom">{{ 'UI.CRM_SETTINGS.TITLE' | translate }}</h5>
        <section>
            <div class="u-row-equal-width-responsive u-grid-align-items-center u-margin-bottom-tablet-portrait-up">
                <smvd-ui-dropdown-input
                    [formControlName]="formControlNames.crmSystem"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.CRM_SYSTEM.LABEL' | translate"
                    [options]="crmClientOptions"
                    [placeholder]="'UI.CRM_SYSTEM.PLACEHOLDER' | translate"
                    class="u-margin-bottom-half-up-to-and-including-phone-landscape"
                    id="crmSystem"
                    width="100%"
                ></smvd-ui-dropdown-input>
                <p class="u-color-gray-dark u-font-size-sm u-margin-bottom-up-to-and-including-phone-landscape">
                    {{ 'UI.CRM_SYSTEM.FORM_DESCRIPTION' | translate }}
                </p>
            </div>
            <div class="u-row-equal-width-responsive u-grid-align-items-center">
                <smvd-ui-text-input
                    [formControlName]="formControlNames.crmId"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'UI.CRM_USER_ID.LABEL' | translate"
                    [placeholder]="'UI.CRM_USER_ID.PLACEHOLDER' | translate"
                    class="u-margin-bottom-half-up-to-and-including-phone-landscape"
                    width="100%"
                ></smvd-ui-text-input>
                <p class="u-color-gray-dark u-font-size-sm u-margin-bottom-up-to-and-including-phone-landscape">
                    {{ 'UI.CRM_USER_ID.FORM_DESCRIPTION' | translate }}
                </p>
            </div>
        </section>

        <div class="u-margin-top u-flex-row u-flex-justify-content-flex-end">
            <app-button [disabled]="realEstateAgentForm.invalid" type="submit"
                >{{ submitLabel || 'REAL_ESTATE_AGENT.OFFICE.INVITE' | translate }}
            </app-button>
        </div>
    </form>
</app-loading-container>

<button
    (click)="prefillMockData()"
    *ngIf="showMockData"
    [appearance]="buttonAppearance.Raised"
    [context]="uiContext.Accent"
    [size]="buttonSize.Default"
    class="u-position-fixed-bottom-right"
    smvd-ui-button
>
    Mock Data
</button>
