import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    HostBinding,
    NgZone,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { ButtonAppearance } from '../../button';
import { UiIconSize } from '../../icon';
import { MessageIndividualConfig } from './message-config.interface';

@Component({
    selector: 'smvd-ui-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent extends Toast implements OnInit, AfterViewInit {
    @ViewChild('useComponent', { read: ViewContainerRef }) public vcr: ViewContainerRef;

    constructor(
        protected readonly toastService: ToastrService,
        public readonly messagePackage: ToastPackage,
        public readonly ngZone: NgZone,
        protected resolver: ComponentFactoryResolver,
        protected cdr: ChangeDetectorRef
    ) {
        super(toastService, messagePackage, ngZone);
    }
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public componentRef: ComponentRef<any>;

    @HostBinding('class.__compact')
    public compact = false;

    public ngOnInit(): void {
        this.compact = !!(this.messagePackage.config as MessageIndividualConfig).compact;
    }

    public ngAfterViewInit(): void {
        const templateComponent = (this.messagePackage.config as MessageIndividualConfig).templateComponent; // templateComponent is custom Smooved property
        if (templateComponent) {
            const comp = this.resolver.resolveComponentFactory(templateComponent);
            this.componentRef = this.vcr.createComponent(comp);
            this.cdr.detectChanges();
        }
    }
}
