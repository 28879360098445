import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckItemComponent } from '@app/ui/check-item/check-item.component';
import { SvgModule } from '@smooved/ui';

export const components = [CheckItemComponent];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [CommonModule, SvgModule],
})
export class CheckItemModule {}
