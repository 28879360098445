import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GetOrdersRequest } from '@app/order/interfaces/get-orders-request';
import { Order } from '@app/order/interfaces/order';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { ModalData } from '@app/partner/interfaces/modal-data';
import { GiftDetailModal } from '@app/partner/modals/gift-detail/gift-detail.modal';
import { TelecomDetailModal } from '@app/partner/modals/telecom-detail/telecom-detail.modal';
import * as orderModel from '@shared/order/constants/models.constants';
import { PaginationResponse } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, take } from 'rxjs/operators';
import { RentalInspectionDetailModal } from '../modals/rental-inspection-detail/rental-inspection-detail.modal';

@Injectable()
export class OrderListContainerService {
    private loadingBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$: Observable<boolean> = this.loadingBehaviorSubject.asObservable();

    private ordersBehaviorSubject: BehaviorSubject<PaginationResponse<Order>> = new BehaviorSubject({
        count: 0,
        data: [],
    });
    public orders$: Observable<PaginationResponse<Order>> = this.ordersBehaviorSubject.asObservable();

    public searchControl = this.formBuilder.control(null);

    constructor(
        private readonly orderSandbox: OrderSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly formBuilder: UntypedFormBuilder
    ) {}

    public initSearchControl(): Observable<string> {
        return this.searchControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged());
    }

    public openDetail(id: string, callbackAfterPatch: () => void): void {
        this.orderSandbox.findById(id).subscribe((order) => {
            this.showDetail(order, callbackAfterPatch);
        });
    }

    public fetch(getOrdersRequest: GetOrdersRequest): void {
        this.loadingBehaviorSubject.next(true);
        this.orderSandbox
            .get(getOrdersRequest)
            .pipe(finalize(() => this.loadingBehaviorSubject.next(false)))
            .subscribe((response) => {
                this.ordersBehaviorSubject.next(response);
            });
    }

    public onRowClick(order: Order, callbackAfterPatch: () => void): void {
        this.showDetail(order, callbackAfterPatch);
    }

    private showDetail(order: Order, callbackAfterPatch: () => void): void {
        const orderUpdated = new BehaviorSubject<boolean>(false);
        const data: ModalData<Order> = {
            order,
            orderUpdated,
        };
        const config = { data };
        const closeModalHandler = (): void => {
            orderUpdated
                .asObservable()
                .pipe(take(1))
                .subscribe((updated) => {
                    if (updated) {
                        callbackAfterPatch();
                    }
                });
        };

        switch (order.__t) {
            case orderModel.orderGiftModel:
                this.modalSandbox.openModal(GiftDetailModal, config, closeModalHandler, GiftDetailModal, config, closeModalHandler);
                break;
            case orderModel.orderRentalInspectionModel:
                this.modalSandbox.openModal(
                    RentalInspectionDetailModal,
                    config,
                    closeModalHandler,
                    RentalInspectionDetailModal,
                    config,
                    closeModalHandler
                );
                break;
            default:
                this.modalSandbox.openModal(TelecomDetailModal, config, closeModalHandler, TelecomDetailModal, config, closeModalHandler);
        }
    }
}
