<app-loading-container [loading]="loading" [ngSwitch]="closed">
    <table class="u-w100p u-padding-right-double u-padding-left-double u-data-table-with-hover u-margin-bottom-double">
        <thead>
            <tr>
                <th class="u-text-align-left u-color-muted u-font-weight-normal">
                    {{ 'INVOICING.SUBSCRIPTION_COSTS.DESCRIPTION' | translate }}
                </th>
                <th class="u-text-align-right u-color-muted u-font-weight-normal">
                    {{ 'INVOICING.SUBSCRIPTION_COSTS.TOTAL' | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="u-flex-row u-flex-align-items-center">
                        <span>{{ 'INVOICING.SUBSCRIPTION_COSTS.TOTAL_COSTS' | translate }}</span>
                        <a
                            class="u-h-auto"
                            app-button
                            target="_blank"
                            icon="info"
                            [appearance]="buttonAppearance.Icon"
                            [href]="'INVOICING.SUBSCRIPTION_COSTS.TOTAL_COSTS_INFO' | translate"
                        ></a>
                    </div>
                </td>
                <td class="u-text-align-right">{{ quarterlyInvoicing?.subscriptionCosts?.totalSubscriptionCost | price }}</td>
            </tr>
            <tr *ngIf="quarterlyInvoicing?.subscriptionCosts?.totalDiscount">
                <td class="u-padding-bottom-half">{{ 'INVOICING.SUBSCRIPTION_COSTS.DISCOUNTS' | translate }}</td>
                <td class="u-text-align-right u-padding-bottom-half">
                    {{ quarterlyInvoicing?.subscriptionCosts?.totalDiscount | price }}
                </td>
            </tr>
            <tr *ngIf="quarterlyInvoicing?.subscriptionCosts?.totalDiscount">
                <td class="u-font-weight-bold u-bordered-top u-padding-top-half">
                    {{ 'INVOICING.SUBSCRIPTION_COSTS.TOTAL_COSTS_INCLUSIVE_DISCOUNTS' | translate }}
                </td>
                <td class="u-text-align-right u-font-weight-bold u-bordered-top u-padding-top-half">
                    {{ quarterlyInvoicing?.subscriptionCosts?.totalSubscriptionCostInclusiveDiscounts | price }}
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-container>
