import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiIconSize } from '../../../icon/icon.enums';

@Component({
    selector: 'app-nps-score',
    template:
        '<div class="__score __score-{{size}} __score-bg-{{ score }} u-color-white u-font-weight-bold">{{ showValue ? score : null }}</div>',
    styleUrls: ['nps-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsScoreComponent {
    @Input() score: number;
    @Input() showValue = true;
    @Input() size = UiIconSize.Default;
}
