import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ReviewsForMonth } from '@app/real-estate-agent/interfaces/insights';
import { GraphUtils } from '@app/shared/utils/graph-utils';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from '@smooved/core';
import { Colors, SvgIllustration, UiContext } from '@smooved/ui';
import * as d3 from 'd3';

@Component({
    selector: 'app-insights-reviews-per-month-chart',
    templateUrl: 'insights-reviews-per-month-chart.component.html',
    styleUrls: ['insights-reviews-per-month-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsReviewsPerMonthChartComponent implements OnChanges {
    public uiContext = UiContext;
    public svgIllustration = SvgIllustration;

    @ViewChild('chart', { static: true }) public chartContainer: ElementRef<SVGElement>;

    @Input()
    public data: ReviewsForMonth[] = [];

    constructor(private translate: TranslateService) {}

    private colors = {
        good: Colors.Success,
        average: Colors.Warning,
        bad: Colors.Danger,
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (!ArrayUtils.isEmpty(this.data)) {
            this.createChart();
        }
    }

    private createChart(): void {
        d3.selectAll('#year-overview-chart > *').remove();

        const element = this.chartContainer.nativeElement;
        const processedData = this.processData(this.data);

        const margins = { top: 10, right: 0, bottom: 20, left: 20 };
        const containerDimensions = element.getBoundingClientRect();
        const width = 420 - margins.left - margins.right;
        const height = containerDimensions.height - margins.top - margins.bottom;

        const svg = d3
            .select(element)
            .append('svg')
            .attr('width', width + margins.left + margins.right)
            .attr('height', height + margins.top + margins.bottom)
            .append('g')
            .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

        const xScale = d3
            .scaleBand()
            .range([0, width])
            .domain(processedData.map((d) => d.month))
            .padding(0.3);

        const maxValue = d3.max(processedData, (d) => d.good + d.average + d.bad);
        const numSteps = 3;
        const stepSize = maxValue / numSteps;
        const tickValues = d3.range(0, maxValue + stepSize, stepSize);

        const yScale = d3.scaleLinear().range([height, 0]).domain([0, maxValue]);

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        svg.append('g').attr('transform', `translate(0,${height})`).call(xAxis).selectAll('text').style('fill', '#62787A');
        svg.append('g')
            .attr('transform', `translate(5, 0)`)
            .call(d3.axisLeft(yScale).tickValues(tickValues))
            .selectAll('text')
            .style('fill', '#62787A');

        svg.selectAll('.domain').attr('display', 'none');
        svg.selectAll('.tick line').attr('display', 'none');

        const stack = d3.stack().keys(['bad', 'average', 'good']);
        const stackedValues = stack(processedData);

        svg.selectAll('.layer')
            .data(stackedValues)
            .enter()
            .append('g')
            .attr('class', 'layer')
            .attr('fill', (d) => this.colors[d.key])
            .selectAll('.bar')
            .data((d) => d)
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('d', (d, i, nodes) => {
                const x = xScale(d.data.month.toString());
                const y = yScale(d[1]);
                const width = xScale.bandwidth();
                const height = yScale(d[0]) - yScale(d[1]);
                const isBottomPart = d[0] === 0;
                const good = Object.values(this.data[i])[0].good;
                const average = Object.values(this.data[i])[0].average;
                const bad = Object.values(this.data[i])[0].bad;
                const isTopPart = d[1] === bad + average + good;
                const rectangle = `M${x},${y} h${width} v${height} h${-width} Z`;

                if (height === 0) {
                    return null;
                }

                if (isTopPart && isBottomPart) {
                    return GraphUtils.roundedRect(x, y, width, height, 3);
                }

                if (isBottomPart) {
                    return GraphUtils.roundedBottomRect(x, y, width, height, 3);
                }

                if (isTopPart) {
                    return GraphUtils.roundedTopRect(x, y, width, height, 3);
                }

                return rectangle;
            })
            .on('mouseover', (event, d) => {
                const containerDimensions = element.getBoundingClientRect();
                const reviewsString = this.translate.instant('INSIGHTS.DASHBOARD.REVIEWS');
                const value = `${d.data.good + d.data.average + d.data.bad} ${reviewsString}`;

                const total = d.data.good + d.data.average + d.data.bad;
                let y = yScale(total);

                const targetRect = event.target.getBoundingClientRect();
                const menuWidth = 240 + 88;

                const tooltip = document.getElementById('tooltip');
                const tooltipValue = document.getElementById('tooltip-value');

                tooltipValue.innerHTML = value;
                tooltip.style.opacity = '1';

                tooltip.style.left = `${targetRect.left + targetRect.width / 2 - menuWidth}px`;
                tooltip.style.top = `${
                    window.scrollY + containerDimensions.y - containerDimensions.height + y - tooltip.offsetHeight + 65
                }px`;
            })
            .on('mouseout', function (d) {
                const tooltip = document.getElementById('tooltip');
                tooltip.style.opacity = '0';
            });
    }

    private processData(data: { [key: string]: { [key: string]: number } }[]): any[] {
        return data.map((item) => {
            const month = Object.keys(item)[0];
            return {
                month,
                ...item[month],
            };
        });
    }
}
