import { ChangeDetectorRef, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ArrayUtils, DbUtils, RealEstateAgency } from '@smooved/core';
import { first, takeUntil } from 'rxjs/operators';
import { BaseAutocompleteInput, baseInputWidth, DropdownInput } from '../../../form';
import { RealEstateGroupLocationsService } from '../../services';
import { buildLocationsOptions, locationDefault } from './locations-input.constants';

@Component({
    selector: 'smvd-ui-locations-input',
    templateUrl: 'locations-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocationsInputComponent),
            multi: true,
        },
    ],
})
export class LocationsInputComponent extends BaseAutocompleteInput implements ControlValueAccessor, OnInit {
    public innerModel: RealEstateAgency;
    public locationOptions: DropdownInput<RealEstateAgency>[] = buildLocationsOptions([]);

    @Input()
    public width = baseInputWidth;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly realEstateGroupLocationsService: RealEstateGroupLocationsService,
        private readonly cdr: ChangeDetectorRef
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.realEstateGroupLocationsService.locations$.pipe(takeUntil(this.destroy$)).subscribe((locations) => {
            this.locationOptions = buildLocationsOptions(locations);
        });
    }

    public writeValue(location: RealEstateAgency): void {
        this.innerModel = location;
        this.realEstateGroupLocationsService.locations$.pipe(first((values) => !ArrayUtils.isEmpty(values))).subscribe((values) => {
            const match = values.find((value) => DbUtils.getStringId(value) === DbUtils.getStringId(location));
            this.innerModel = match ?? locationDefault.value;
            this.cdr.detectChanges();
        });
    }

    public selected(location: RealEstateAgency): void {
        this.propagateChange(location);
    }
}
