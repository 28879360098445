<div class="u-flex-column u-margin-top-double">
    <div class="__reverse-row">
        <div class="__info-segment u-padding-double">
            <h2 class="u-font-size-20 u-margin-bottom">{{ 'INSIGHTS.DASHBOARD.PREVIEW.YEAR_OVERVIEW_TITLE' | translate }}</h2>
            <p class="__info-content">{{ 'INSIGHTS.DASHBOARD.PREVIEW.YEAR_OVERVIEW_CONTENT' | translate }}</p>
        </div>
        <div class="__info-segment u-padding __align-end">
            <img src="assets/images/insights/insights_year_overview_graph.jpg" width="400px" />
        </div>
    </div>
    <div class="u-flex-responsive u-margin-top-triple">
        <div class="__info-segment u-padding-double __align-end">
            <div>
                <h2 class="u-font-size-20 u-margin-bottom">{{ 'INSIGHTS.DASHBOARD.PREVIEW.CLIENT_SOURCES_TITLE' | translate }}</h2>
                <p class="__info-content">{{ 'INSIGHTS.DASHBOARD.PREVIEW.CLIENT_SOURCES_CONTENT' | translate }}</p>
            </div>
        </div>
        <div class="__info-segment u-flex-justify-content-center">
            <img src="assets/images/insights/client_sources_graph.jpg" width="240px" />
        </div>
    </div>
    <div class="__reverse-row u-margin-top-triple">
        <div class="__info-segment u-padding-double">
            <h2 class="u-font-size-20 u-margin-bottom u-display-inline-block">
                <div
                    class="__header__beta u-display-inline-block u-margin-right u-line-height-1 u-color-white u-background-info u-border-radius-large u-padding-x-axis-half u-padding-y-axis-quarter"
                >
                    <span class="u-font-weight-semi-bold">{{ 'TITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentBeta }}</span>
                </div>
                {{ 'INSIGHTS.DASHBOARD.PREVIEW.REVIEW_ANALYSIS_TITLE' | translate }}
            </h2>
            <p class="__info-content">{{ 'INSIGHTS.DASHBOARD.PREVIEW.REVIEW_ANALYSIS_CONTENT' | translate }}</p>
        </div>
        <div class="__info-segment __align-end u-padding-double u-flex-justify-content-flex-start">
            <img src="assets/images/insights/reviews.jpg" width="375px" />
        </div>
    </div>
</div>
