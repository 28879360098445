import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ButtonAppearance, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-energy-documents-moving-address-without-contract',
    templateUrl: './energy-documents-moving-address-without-contract.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyDocumentsMovingAddressWithoutContractComponent extends BaseServiceActionComponent {
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public isAfterFinalReminder: boolean;

    constructor(protected readonly moveSandbox: MoveSandbox, protected readonly cdr: ChangeDetectorRef) {
        super(cdr, moveSandbox);
    }

    public check(): void {
        this.moveSandbox.patchProperty(
            'energyMeterTransferDocumentsMovingAddressWithoutContract',
            true,
            true,
            this.handleUpdateMove,
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'energyMeterTransferDocumentsMovingAddressWithoutContract',
            false,
            true,
            this.handleUpdateMove,
            false,
            this.move,
            false
        );
    }

    private handleUpdateMove = (move: Move): void => {
        if (!move) return;
        this.updateMove(move);
    };
}
