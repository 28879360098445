<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin"
                     [hasMarginDouble]="hasMarginDouble">
    <mat-form-field floatLabel="never" [appearance]="appearance" class="u-input-size-{{ size }}">
        <input
            [id]="id"
            type="text"
            matInput
            #input
            [(ngModel)]="innerModel"
            (blur)="onBlur($event)"
            (ngModelChange)="onModelChange($event)"
            [disabled]="innerDisabled"
            [mask]="mask"
            [thousandSeparator]="thousandSeparator"
            [placeholder]="placeholder"
            [errorStateMatcher]="errorStateMatcher"
            autocomplete="smooved"
        />
        <div matPrefix class="u-flex-row u-flex-align-items-center u-color-muted u-padding-right"
             *ngIf="currency || prefix">
            {{ currencySymbol || prefix }}
        </div>
        <div matSuffix class="u-flex-row u-flex-align-items-center" *ngIf="showButtons">
            <button
                app-button
                [disabled]="innerDisabled"
                icon="remove"
                (click)="decrease()"
                [appearance]="buttonAppearance.StrokedIcon"
                [context]="context.Secondary"
                [size]="buttonSize.Xs"
                [tabIndex]="-1"
                class="__button-minus"
            ></button>
            <button
                app-button
                [disabled]="innerDisabled"
                icon="add"
                (click)="increase()"
                [appearance]="buttonAppearance.StrokedIcon"
                [context]="context.Secondary"
                [size]="buttonSize.Xs"
                [tabIndex]="-1"
                class="__button-plus"
            ></button>
        </div>
        <div matSuffix>
            <ng-content select="[suffix]"></ng-content>
        </div>
        <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
    </mat-form-field>
</app-label-container>
