import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FeaturePermission } from '@app/feature-scope/sandboxes/feature-permission.interface';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { SendGiftModal } from '@app/gift/modals/send-gift/send-gift.modal';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { FeatureScope, RxjsComponent } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { ServiceFeature, services } from './service-dropdown-menu.constants';

@Component({
    selector: 'app-service-dropdown-menu',
    templateUrl: './service-dropdown-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDropdownMenuComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public move: Move;
    @Output() public reload = new EventEmitter<boolean>();

    public get allowedServices(): FeaturePermission[] {
        return services.filter((s) => s.hasPermission);
    }

    private services = services;

    constructor(
        private readonly permissionsSandbox: FeaturePermissionsSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox
    ) {
        super();
    }

    public hasOptions(): boolean {
        return services.some((s) => s.hasPermission);
    }

    public ngOnInit(): void {
        this.setProperty('callback', FeatureScope.RentalInspection, this.startRequestRentalInspection);
        this.setProperty('callback', FeatureScope.Gift, this.startRequestGifts);
        this.setProperty('callback', FeatureScope.Eots, this.startRequestEots);
    }

    public ngOnChanges(): void {
        this.verifyPermission(FeatureScope.RentalInspection);
        this.verifyPermission(FeatureScope.Gift);
        this.verifyPermission(FeatureScope.Eots);
    }

    public trackyByService(_, { service }: ServiceFeature): string {
        return service;
    }

    private setProperty(prop: string, service: FeatureScope | string, value: unknown): void {
        const result = this.services.find((s) => s.service === service);
        result[prop] = value;
    }

    private verifyPermission(feature: FeatureScope): void {
        this.permissionsSandbox
            .getPermissions$(feature, this.move)
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ hasPermission, canExecute }) => {
                this.setProperty('hasPermission', feature, hasPermission);
                this.setProperty('canExecute', feature, canExecute);
            });
    }

    private startRequestEots = (): void => {
        this.moveSandbox.setMoveState(this.move);
        void this.navigationSandbox.goToEotsFlow();
    };

    private startRequestGifts = (): void => {
        const data = {
            data: this.move._id,
        };
        this.modalSandbox.openModal(SendGiftModal, data, this.handleModalClose, SendGiftModal, data, this.handleModalClose);
    };

    private handleModalClose = (reload: boolean): void => {
        if (reload) {
            this.reload.emit(true);
        }
    };

    private startRequestRentalInspection = (): void => {
        this.moveSandbox.setMoveState(this.move);
        void this.navigationSandbox.goToRentalInspectionFlow();
    };
}
