import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Observable } from 'rxjs';
import { RealEstateAgentDashboardSandbox } from '../../../../real-estate-agent/sandboxes/real-estate-agent-dashboard.sandbox';
import { Move } from '../../../../move/interfaces/move';

export class RealEstateAgentMovesDataSource implements DataSource<Move> {
    constructor(private realEstateAgentDashboardSandbox: RealEstateAgentDashboardSandbox) {}

    public connect(collectionViewer: CollectionViewer): Observable<Move[] | ReadonlyArray<Move>> {
        return this.realEstateAgentDashboardSandbox.moves$;
    }

    public disconnect(collectionViewer: CollectionViewer): void {
        // todo
        // this.movesSubject.complete();
    }
}
