<app-mobile-modal *ngIf="move$ | async as move">
    <div mobile-modal-header>
        <h6>{{ move | userName }}</h6>
        <span class="u-color-muted u-font-size-small">{{ move.linkedMove?.user | formatAddress: ',':false }}</span>
    </div>
    <div mobile-modal-main class="u-flex-column u-flex-justify-content-space-between">
        <div>
            <app-menu class="u-margin-bottom-double">
                <app-menu-item (click)="menuItemGeneralDetails.navigate()" [level]="menuItemGeneralDetails.level">
                    {{ menuItemGeneralDetails.label }}
                    <span menu-item-caption>{{ menuItemGeneralDetails.subLabel }}</span>
                </app-menu-item>
            </app-menu>

            <app-menu>
                <app-menu-item [readonly]="true">
                    <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center">
                        <span>{{ 'DETAIL.SERVICES' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}</span>
                    </div>
                </app-menu-item>

                <ng-container *ngFor="let service of availableServices; trackBy: trackByService">
                    <app-menu-item [size]="menuSize.Small" (click)="service.navigate()" [level]="service.level">
                        {{ service.label }}
                    </app-menu-item>
                </ng-container>
            </app-menu>
        </div>

        <div class="u-border-radius-top-extra-large u-background-white u-padding u-flex-no-shrink">
            <p class="u-margin-bottom">{{ 'MOVE.METER_READING.READ' | translate }}</p>
            <div class="u-flex-column">
                <button app-button [disabled]="!move" (click)="openEnergy(move)" class="u-margin-bottom u-display-block">
                    {{ 'MOVE.METER_READING.ENERGY' | translate }}
                </button>
                <button app-button class="u-display-block" [disabled]="!move" (click)="openWater(move)">
                    {{ 'MOVE.METER_READING.WATER' | translate }}
                </button>
            </div>
        </div>
    </div>
</app-mobile-modal>
