import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@app/header/services/header.service';
import { TodoCount } from '@app/move/interfaces/todo-count';
import { DashboardMenuService } from '@app/real-estate-agent/components/dashboard-menu/dashboard-menu.service';
import { UiRotate } from '@smooved/ui';
import { Observable } from 'rxjs';
import { DashboardMenu } from './dashboard-menu.constants';

@Component({
    selector: 'app-dashboard-menu',
    templateUrl: 'dashboard-menu.component.html',
    styleUrls: ['dashboard-menu.component.scss'],
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
    public readonly dashboardMenu = DashboardMenu;
    public readonly rotate = UiRotate;
    public todosCount$: Observable<TodoCount>;

    constructor(public dashboardMenuService: DashboardMenuService, private readonly headerService: HeaderService) {}

    public ngOnInit(): void {
        this.todosCount$ = this.dashboardMenuService.todosCount$;
        this.dashboardMenuService.reloadTodosCount();
    }

    public ngOnDestroy(): void {
        this.dashboardMenuService.resetMenu();
    }

    public setMenu(item: DashboardMenu): void {
        this.dashboardMenuService.setActiveMenu(item);
        this.headerService.dispatch();
    }
}
