<app-mobile-modal *ngIf="move$ | async as move">
    <div mobile-modal-header>
        <h6>{{ move.user?.formattedName }}</h6>
        <span class="u-color-muted u-font-size-small">{{ move.user?.formattedMovingAddressWithoutCountry }}</span>
    </div>
    <div mobile-modal-main class="u-flex-column u-flex-justify-content-space-between">
        <div>
            <app-menu class="u-margin-bottom-double">
                <app-menu-item (click)="menuItemGeneralDetails.navigate()" [level]="menuItemGeneralDetails.level">
                    {{ menuItemGeneralDetails.label }}
                    <span menu-item-caption>{{ menuItemGeneralDetails.subLabel }}</span>
                </app-menu-item>
            </app-menu>

            <app-menu>
                <app-menu-item [readonly]="true">
                    <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center">
                        <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.SERVICES' | translate }}</span>
                    </div>
                </app-menu-item>

                <ng-container *ngFor="let service of availableServices; trackBy: trackByService">
                    <app-menu-item
                        *ngIf="service.isVisible"
                        [size]="menuItemSize.Small"
                        (click)="service.navigate()"
                        [level]="service.level"
                    >
                        {{ service.label }}
                    </app-menu-item>
                </ng-container>
            </app-menu>
        </div>

        <div class="u-border-radius-top-extra-large u-background-white u-padding u-flex-no-shrink">
            <p class="u-margin-bottom">{{ 'MOVE.METER_READING.ADD' | translate }}</p>
            <div class="u-flex-column">
                <button app-button class="u-margin-bottom u-display-block" [disabled]="!move" (click)="openEnergy()">
                    {{ 'MOVE.METER_READING.ENERGY' | translate }}
                </button>
                <button app-button class="u-display-block" [disabled]="!move" (click)="openWater()">
                    {{ 'MOVE.METER_READING.WATER' | translate }}
                </button>
            </div>
        </div>
    </div>
</app-mobile-modal>
