import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { MessageComponent } from './message/message.component';
import { MessagesProvidersModule } from './messages-providers.module';

@NgModule({
    declarations: [MessageComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({ positionClass: 'toast-bottom-right', maxOpened: 3, preventDuplicates: true, tapToDismiss: false }),
        IconModule,
        ButtonModule,
        MessagesProvidersModule,
    ],
    exports: [MessageComponent],
})
export class MessagesModule {}
