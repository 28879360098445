<div class="__drag-boundary" [style.bottom.px]="boundaries.bottom" [style.top.px]="boundaries.top" cdkDragLockAxis="y">
    <div
        class="__bottom-sheet-container"
        cdkDrag
        [cdkDragFreeDragPosition]="dragPosition"
        cdkDragBoundary=".__drag-boundary"
        (cdkDragEnded)="onDragEnd($event)"
    >
        <div
            #topdrop
            class="__bottom-sheet-topdrop __bottom-sheet-topdrop-{{ topdropContext }} u-padding-y-axis-half u-padding-x-axis u-color-white "
        >
            <ng-content select="[bottom-sheet-topdrop]"></ng-content>
        </div>
        <div class="__bottom-sheet u-shadow-maps-card u-padding-right u-padding-bottom u-padding-left">
            <div class="u-position-relative u-padding-top-md">
                <div class="__slider u-w100p u-flex-row u-flex-align-items-center u-padding-bottom-lg" cdkDragHandle></div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
