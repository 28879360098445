export enum HomeDescription {
    Detached = 'detached',
    HalfOpen = 'halfOpen',
    Closed = 'closed',
    Apartment = 'apartment',
}

export enum SimplifiedHomeDescription {
    Apartment = 'apartment',
    House = 'house',
}
