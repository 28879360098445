import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, NumberUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { takeUntil } from 'rxjs/operators';
import { barGapMap } from '../../charts.constants';
import { BarGap } from '../../enums/bar-gap.enum';
import { ColorScale } from '../../enums/color-scale.enum';
import { ChartItem } from '../../interfaces/chart-item';

@Component({
    selector: 'app-stacked-bar-chart',
    template: `
        <div class="u-position-relative" [style.width.px]="width" [style.height.px]="height">
            <svg class="__stacked-bar"></svg>
            <div appTooltip [label]="label" [sub]="sub" class="__tooltip"></div>
        </div>
    `,
    styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public data: ChartItem[];
    @Input() public stacks: string[];
    @Input() public width = 400;
    @Input() public height = 300;
    @Input() public hasTotals = false;
    @Input() public hasLabels = true;
    @Input() public barGap = BarGap.Medium;
    @Input() public colors = Object.values(ColorScale);
    @Input() public tooltipTotal: boolean;
    @Input() public percentage = false;
    @Input() public noDataY = 100;

    public label: string;
    public sub: string;

    private svg; // Top level svg element;
    private chart; // group element
    private stack; // d3 stack dataset;
    private series;
    private texts;

    private x; // X scales;
    private y; // Y scales;
    private xAxis;
    private yAxis;

    private layersBar;
    private layersBarArea;

    constructor(private elRef: ElementRef, private translateService: TranslateService) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateChart(this.data));
    }

    public ngOnChanges({ data, colors }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(colors)) {
            this.colors = [...this.colors, ...Object.values(ColorScale)];
        }

        if (SimpleChangesUtils.hasChanged(data) || SimpleChangesUtils.hasChanged(colors)) {
            this.updateChart(this.data);
        }
    }

    private updateChart(data: ChartItem[]): void {
        if (this.svg) {
            d3.select(this.elRef.nativeElement).selectAll('g').remove();
        }
        if (!data) return;
        this.createChart(data);
    }

    private createChart(data: ChartItem[]): void {
        this.setData();
        this.setScales();
        this.setChart();
        this.drawAxis();
        this.setLayers();
        this.createStacks(data);
        this.setTooltip();
    }

    private setChart(): void {
        this.svg = d3.select(this.elRef.nativeElement).select('svg');

        if (this.width) {
            this.svg.attr('width', `${this.width}px`);
        }

        if (this.height) {
            this.svg.attr('height', `${this.height}px`);
        }

        if (this.width && this.height) {
            this.svg.attr('viewBox', '0 0 ' + this.width + ' ' + this.height);
        }

        this.chart = this.svg.append('g');
    }

    private setLayers(): void {
        this.layersBarArea = this.chart.append('g').classed('layers', true);
    }

    private setData(): void {
        this.stack = d3
            .stack<ChartItem>()
            .keys(this.stacks)
            .value((d, i) =>
                !this.percentage
                    ? d.stacks[i]?.value || 0
                    : NumberUtils.percentage(d.stacks[i]?.value || 0, ArrayUtils.sum(Object.values(d.stacks).map((s) => s.value)), true) *
                      100
            )
            .order(d3.stackOrderNone)
            .offset(d3.stackOffsetNone);
    }

    private setScales(): void {
        this.x = this.getScaleX();
        this.y = d3.scaleLinear().range([this.height - 30, 20]);
    }

    private getScaleX(): ScaleBand<string> {
        const paddingGap = barGapMap[this.barGap] * (this.data?.length ?? 0);
        return d3
            .scaleBand()
            .rangeRound([40, this.width])
            .domain(this.data ? this.data.map((d) => d.label) : [])
            .padding(1 - Math.min(1, paddingGap));
    }

    private drawAxis(): void {
        this.xAxis = this.chart
            .append('g')
            .classed('xAxis', true)
            .attr('transform', `translate(0,${this.height - 25})`)
            .call(d3.axisBottom(this.x));

        this.yAxis = this.chart.append('g').classed('yAxis', true).attr('transform', 'translate(40, 0)').call(d3.axisLeft(this.y).ticks(5));
    }

    private createStacks(data: any): void {
        const stackData = this.addMissingStacks(data);
        this.series = this.stack(stackData);
        this.drawChart(this.series);
    }

    private drawChart(data: any): void {
        this.setAxisDomains(data);

        this.layersBar = this.layersBarArea
            .selectAll('.layer')
            .data(data)
            .join('g')
            // .classed('layer', true)
            .attr('data-label', (d, i) => {
                return d[0]?.data?.stacks[d.key]?.label;
            })
            .attr('data-key', (d, _) => d.key)
            .style('fill', (d, i) => {
                return d[i]?.data?.stacks[d.key]?.hidden ? 'transparent' : this.colors[i];
            });

        this.layersBar
            .selectAll('rect')
            .data((d: any) => {
                return d;
            })
            .enter()
            .append('rect')
            .attr('y', () => {
                return this.y(0);
            })
            .attr('x', (d: any) => {
                return this.x(d.data?.label);
            })
            // .attr('rx', '4')
            // .attr('ry', '4')
            .attr('width', this.x.bandwidth())
            .transition()
            .duration(500)
            .attr('y', (d: any) => {
                return this.y(d[1]);
            })
            .attr('height', (d: any) => {
                return this.y(d[0]) - this.y(d[1]);
            });

        if (!this.hasTotals) return;

        this.texts = this.layersBar.selectAll('.text');

        this.texts.exit().remove();
        this.texts
            .data((d, i) => (i === this.stacks.length - 1 ? d : []))
            .enter()
            .append('text')
            .attr('class', 'text')
            .attr('text-anchor', 'middle')
            .attr('fill', '#000')
            .attr('x', (d) => this.x(d.data?.label) + this.x.bandwidth() / 2)
            .attr('y', this.y(0) - 5)
            .transition()
            .duration(500)
            .attr('y', (d) => this.y(d[1]) - 5)
            .text((d) => d.data?.value);
    }

    private setAxisDomains(data: any): void {
        this.x.domain(this.data.map((d: ChartItem) => d.label));

        this.y.domain([
            0,
            +d3.max(data, function (dStack: any) {
                return d3.max(dStack, (d: any) => d[1]);
            }) || this.noDataY,
        ]);

        this.xAxis.call(d3.axisBottom(this.x).tickFormat((x) => (this.hasLabels ? this.translateService.instant(x.toString()) : '')));

        this.yAxis.call(
            d3
                .axisLeft(this.y)
                .ticks(5)
                .tickFormat((d) => (this.percentage ? `${d}%` : d.toString()))
        );

        this.yAxis
            .selectAll('g.yAxis g.tick')
            .append('line')
            .attr('class', 'gridline')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', this.width)
            .attr('y2', 0);
    }

    private setTooltip(): void {
        this.resetTooltip();
        this.layersBar.selectAll('rect').on('mouseover', (event, dStack) => {
            const label = d3.select(event.target.parentElement).attr('data-label');
            const key = d3.select(event.target.parentElement).attr('data-key');

            // No Tooltip when bar is hidden
            if (dStack.data.stacks[key].hidden) return;

            const currentBar = d3.select(event.target);
            const width = currentBar.attr('width');
            const height = currentBar.attr('height');
            const x = currentBar.attr('x');
            const y = currentBar.attr('y');

            d3.select(this.elRef.nativeElement)
                .select('.__tooltip')
                .style('left', `${x}px`)
                .style('top', `${y}px`)
                .style('width', `${width}px`)
                .style('height', `${height}px`);

            const calculatedStackValue = dStack[1] - dStack[0];
            const tooltipLabel = label ? (this.translateService.instant(label) as string) : undefined;
            const total = this.percentage
                ? `${dStack.data.stacks[key]?.value} (${NumberUtils.toFixed(calculatedStackValue)}%)`
                : `${calculatedStackValue}`;

            this.label = this.tooltipTotal ? total : tooltipLabel;
            this.sub = this.tooltipTotal ? null : total;
        });
    }

    private resetTooltip(): void {
        d3.select(this.elRef.nativeElement).select('.__tooltip').style('width', 0).style('height', 0);
    }

    private addMissingStacks(data: ChartItem[]): ChartItem[] {
        const allStacks = this.getStackEntries(data);
        return data.map((chartItem): ChartItem => {
            const existingStacks = Object.keys(chartItem.stacks);
            allStacks.forEach(([key, label]) => {
                if (!existingStacks.includes(key)) {
                    chartItem.stacks[key] = { label, value: 0 };
                }
            });
            return chartItem;
        });
    }

    private getStackEntries(data: ChartItem[]): (string | string[])[] {
        return [
            ...new Set(
                data.reduce((entries: string[], chartItem: ChartItem) => {
                    return [...entries, ...Object.entries(chartItem.stacks).map(([key, stack]) => [key, stack.label])];
                }, [])
            ),
        ];
    }
}
