export const mobileEnergyMetersTableColumns: string[] = ['labels', 'detail'];
export const energyAssetSize: number = 10 * 1000 * 1000;

export enum mobileEnergyMetersForm {
    MeterReadingAssets = 'energyMeterReadingAssets',
    DocumentAssets = 'energyDocumentAssets',
    MovingDate = 'movingDate',
    MeterReadingsTakeover = 'meterReadingsTakeover',
    EnergyType = 'energyType',
}
