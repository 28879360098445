import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import { ShortViewComponent } from './components/short-view/short-view.component';

@NgModule({
    imports: [CommonModule, TranslateModule, ButtonModule],
    declarations: [ShortViewComponent],
    exports: [ShortViewComponent],
})
export class ShortViewModule {}
