import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-container',
    template: `
        <div class="u-position-relative u-h100p">
            <app-loading-bar *ngIf="!!loading" class="__loading u-w100p"></app-loading-bar>

            <div class="__container u-h100p" [ngClass]="{ '__container--loading': loading }">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./loading-container.component.scss'],
})
export class LoadingContainerComponent {
    @Input() public loading = false;
}
