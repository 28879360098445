import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { I18nKeyType } from '@smooved/core';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-contact-logs-overview',
    template: `
        <app-loading-container [loading]="loading">
            <ng-container *ngIf="allLogs$ | async as allLogs">
                <app-check-item *ngFor="let log of allLogs" [checked]="true" class="u-display-block u-margin-bottom-half">
                    <div ngProjectAs="checked">
                        <span>{{
                            log.value
                                | i18nKey: i18nKeyTypes.Log
                                | translate
                                    : {
                                          createdOn: log.formattedCreatedOn,
                                          linkedMove: move.linkedMove?.user?.role | i18nKey: i18nKeyTypes.MoverRole | translate | lowercase
                                      }
                        }}</span>
                    </div>
                </app-check-item>
            </ng-container>
        </app-loading-container>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactLogsOverviewComponent implements OnInit {
    @Input() public move: Move;

    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    public allLogs$;
    public loading = true;

    public i18nKeyTypes = I18nKeyType;

    constructor(public moveSandbox: MoveSandbox) {}

    public ngOnInit() {
        this.allLogs$ = this.moveSandbox.getAllLogs(this.move?._id).pipe(tap(() => (this.loading = false)));
    }
}
