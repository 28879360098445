import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Package } from '@app/package/interfaces/package';
import { PackageProvidersModule } from '@app/package/package-providers.module';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';

@Injectable({
    providedIn: PackageProvidersModule,
})
export class PackageService {
    constructor(private readonly httpClient: HttpClient) {}

    public getAll(): Observable<Package[]> {
        return this.httpClient.get<Package[]>(uri.baseUri);
    }
}
