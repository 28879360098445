import { Component, forwardRef, Host, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EscalationCategoryName } from '@app/escalation/interfaces/category';
import { EscalationSandbox } from '@app/escalation/sandboxes/escalation.sandbox';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { ArrayUtils, DbUtils, Language, StringUtils } from '@smooved/core';
import { BaseAutocompleteInput } from '@smooved/ui';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'smvd-app-escalation-categories-input',
    templateUrl: 'escalation-categories-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EscalationCategoriesInputComponent),
            multi: true,
        },
    ],
})
export class EscalationCategoriesInputComponent extends BaseAutocompleteInput implements ControlValueAccessor, OnInit {
    public innerModel: EscalationCategoryName | string = undefined;
    public categories$ = combineLatest([this.escalationSandbox.categories$, this.translationSandbox.selected$]).pipe(
        map(this.mapCategories)
    );
    public loading$ = this.escalationSandbox.categoriesLoading$;
    public language$ = this.translationSandbox.selected$;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly escalationSandbox: EscalationSandbox,
        private readonly translationSandbox: TranslationSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.escalationSandbox.getEscalationCategories();
    }

    public writeValue(categoryId: string): void {
        if (!StringUtils.hasValue(categoryId)) {
            this.innerModel = undefined;
            return;
        }

        this.categories$.pipe(take(1)).subscribe(this.setCategoryValue(categoryId));
    }

    public selected(category: EscalationCategoryName): void {
        this.propagateChange(DbUtils.getStringId(category));
    }

    private setCategoryValue(id: string): (categories: EscalationCategoryName[]) => void {
        return (categories): void => {
            this.innerModel = ArrayUtils.isEmpty(categories)
                ? undefined
                : categories.find((category) => DbUtils.getStringId(category) === id);
        };
    }

    private mapCategories([categories, language]: [EscalationCategoryName[], Language]): EscalationCategoryName[] {
        return categories ? categories.map((category) => ({ ...category, name: category.labels[language] })) : [];
    }
}
