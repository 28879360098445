import { ISO_8601, Moment } from 'moment';
import moment from 'moment-timezone';
import { dateFormatDefault, isoYearWeek } from '../constants/date-string-format';
import { TimeUnit } from '../constants/date.constants';
import { NumberUtils } from './number.utils';

export class DateUtils {
    public static getQuarter(): number {
        return DateUtils.tz().quarter();
    }

    public static getYear(date: Date): number {
        if (!DateUtils.isValidDate(date)) return null;
        return moment(date).year();
    }

    public static getCurrentYear(): number {
        return DateUtils.tz().year();
    }

    public static max(values: (Moment | Date)[]): Moment {
        return moment.max(values.map((value) => DateUtils.tz(value)));
    }

    public static tz(value?: string | Date | Moment | number, tz: string = 'Europe/Brussels'): Moment {
        return moment(value).tz(tz);
    }

    public static today(): Date {
        return DateUtils.todayMoment().toDate();
    }

    public static addDays(days: number): Date {
        return DateUtils.tz().add(days, TimeUnit.Days).toDate();
    }

    public static substractDays(days: number): Date {
        return DateUtils.tz().subtract(days, TimeUnit.Days).toDate();
    }

    public static addDaysToDate(days: number, baseDate?: Date): Date {
        return (baseDate ? DateUtils.tz(baseDate) : DateUtils.tz()).add(days, TimeUnit.Days).toDate();
    }

    public static todayMoment(): Moment {
        return DateUtils.tz().startOf('d');
    }

    public static now(): Date {
        return DateUtils.tz().toDate();
    }

    /**
     * Method checks whether or not the given date is of type Date
     * @param {any} date: the date to check
     * @returns {boolean}: Whether or not the given value is a Date
     */
    public static isDate(date: unknown): boolean {
        return date instanceof Date;
    }

    /**
     * Method checks whether or not the given value can be parsed to a date
     * @param {string} value: the value to parse
     * @returns {Date}
     */
    public static toDate(value: string | Date): Date {
        if (!value) return;
        if (value instanceof Date) return value;
        if (moment(value, ISO_8601).isValid()) return DateUtils.tz(value).toDate();
        return;
    }

    /**
     * Method checks whether or not the given value can be parsed to a Year Week
     * @param {string} value: the value to parse
     * @returns {string}: Year Week e.g. 2020-1
     */
    public static getYearWeek(value: string | Date): string {
        const date = DateUtils.toDate(value);
        return DateUtils.isDate(date) ? DateUtils.tz(date).format(isoYearWeek) : '';
    }

    public static getMonthlyAverage(valuePerYear: number): number {
        return NumberUtils.toFixed(Math.ceil(valuePerYear / 12));
    }

    public static isTodaySameOrBefore(date: Date): boolean {
        return date ? DateUtils.todayMoment().isSameOrBefore(date) : null;
    }

    /**
     * Method that calculate the interval in days between two dates.
     * Negative days interval means that the first date came after second date.
     * 0 day interval means that the first date is equal to second date.
     * Positive days interval means that the first date came before second date.
     * Null means first date are null or undefined.
     * @param {firstDate} Date: the first date.
     * @param {secondDateOn} Date: the optional second date that receives today's date as default in case of null.
     * @returns {number}: interval in days between two dates.
     */
    public static getDaysIntervalBetweenTwoDates(firstDate: Date, secondDate: Date = DateUtils.today()): number {
        if (!firstDate) return null;
        return DateUtils.tz(firstDate).diff(DateUtils.tz(secondDate), TimeUnit.Days);
    }

    public static isValidDate(date: Date): boolean {
        return date && moment(date).isValid();
    }

    public static isValidDateString(value: string, format = dateFormatDefault): boolean {
        return !!value && moment(value, format, true).isValid();
    }

    public static format(date: Date, format = dateFormatDefault): string {
        if (!DateUtils.isValidDate(date)) return null;
        return DateUtils.tz(date).format(format);
    }

    public static dynamicTimeInterval(initial: Date, compare = DateUtils.now()): { value: number; unit: TimeUnit } {
        let diff = DateUtils.tz(compare).diff(DateUtils.tz(initial), TimeUnit.Days);
        if (diff < 32) return { value: diff, unit: TimeUnit.Days };
        diff = DateUtils.tz(compare).diff(DateUtils.tz(initial), TimeUnit.Months);
        if (diff < 13) return { value: diff, unit: TimeUnit.Months };
        return { value: DateUtils.tz(compare).diff(DateUtils.tz(initial), TimeUnit.Years), unit: TimeUnit.Years };
    }
}
