import { UntypedFormGroup } from '@angular/forms';

export function confirmPasswordValidator(formGroup: UntypedFormGroup): null | { confirmPassword: boolean } {
    const { password, confirmPassword } = formGroup.getRawValue();

    if (!password || !confirmPassword) {
        return null;
    }
    return password === confirmPassword ? null : { confirmPassword: true };
}
