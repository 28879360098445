import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { sent } from '@shared/order/constants/properties.constants';
import { dateTimeFormatDefault } from '@smooved/core';

@Component({
    selector: 'app-check-item-gift-sent',
    template: `
        <app-check-item [checked]="!!order.sent.value" align="top" *ngIf="{ showCta: !!order.prepared.value && !readonly } as vm">
            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="unchecked">
                <p [ngClass]="{ 'u-margin-bottom-half': vm.showCta }">
                    <span *ngIf="vm.showCta; else textSent">{{ 'PARTNER.GIFT.SENT_ON' | translate }}</span>
                    <ng-template #textSent>{{ 'PARTNER.GIFT.SENT' | translate }}</ng-template>
                </p>
                <app-date-input
                    class="u-color-text"
                    *ngIf="vm.showCta"
                    id="sent"
                    placeholder="dd/mm/jjjj"
                    [hasMargin]="true"
                    [hasMarginDouble]="false"
                    [(ngModel)]="deliveryDate"
                ></app-date-input>
                <app-button [disabled]="!deliveryDate" *ngIf="vm.showCta" (onClick)="set(deliveryDate)">{{
                    'CHECK' | translate
                }}</app-button>
            </div>

            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="checked">
                <p class="u-margin-bottom-half">{{ 'PARTNER.GIFT.SENT' | translate }}</p>
                <p class="u-color-muted u-font-size-small">{{ order.sent.value | formatDate }}</p>
                <a *ngIf="!readonly" (click)="set(null)" class="u-link">{{ 'UNCHECK' | translate }}</a>
            </div>
        </app-check-item>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemGiftSentComponent {
    @Input() public order: Order;
    @Input() public readonly: boolean;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    public deliveryDate;
    public dateTimeFormat = dateTimeFormatDefault;

    public set(value: Date | null): void {
        this.patch.emit({
            [sent]: value,
        });
    }
}
