import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommunicationChannel } from '@app/energy/enums/communication-channel';
import { MeterSituation } from '@app/energy/enums/meter-situation';
import { UsageOfResidence } from '@app/energy/enums/usage-of-residence';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { OrderService } from '@app/order/services/order.service';
import { CreatedByFlowEnum, Currency, DbUtils, ErrorResponse, HttpUtils } from '@smooved/core';
import { UiContext } from '@smooved/ui';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    communicationChannelOptions,
    EnergyOrderInformationFormData,
    FormControlName,
    meterSituationOptions,
    usageOfResidenceOptions,
} from './energy-order-information.constants';

@Component({
    selector: 'app-energy-order-information',
    templateUrl: './energy-order-information.component.html',
})
export class EnergyOrderInformationComponent implements OnInit {
    @Input() public move: Move;

    private readonly loadingSubject = new BehaviorSubject<boolean>(false);

    public readonly uiContext = UiContext;
    public readonly currency = Currency;
    public readonly formControlName = FormControlName;
    public readonly usageOfResidenceOptions = usageOfResidenceOptions;
    public readonly meterSituationOptions = meterSituationOptions;
    public readonly communicationChannelOptions = communicationChannelOptions;
    public readonly loading$ = this.loadingSubject.asObservable();

    public isElectricityOrBoth: boolean;
    public isGasOrBoth: boolean;
    public errorMessage: string;
    public form: UntypedFormGroup;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly dialogRef: MatDialogRef<any>,
        private readonly orderService: OrderService
    ) {}

    public ngOnInit(): void {
        this.isElectricityOrBoth = MoveUtils.isElectricityOrBoth(this.move);
        this.isGasOrBoth = MoveUtils.isGasOrBoth(this.move);

        this.form = this.formBuilder.group({
            [FormControlName.UsageOfResidence]: [this.move.usageOfResidence || this.getDefaultUsageOfResidence(), Validators.required],
            [FormControlName.MeterSituation]: [this.move.meterSituation || this.getDefaultMeterSituation(), Validators.required],
            [FormControlName.CommunicationChannel]: [this.move.communicationChannel || CommunicationChannel.Email, Validators.required],
        });

        if (this.isElectricityOrBoth) {
            this.form.addControl(
                FormControlName.ElectricityAdvanceAmount,
                this.formBuilder.control(
                    this.move.electricityAdvanceAmount ?? this.getDefaultElectricityAdvanceAmount(),
                    Validators.required
                )
            );
        }
        if (this.isGasOrBoth) {
            this.form.addControl(
                FormControlName.GasAdvanceAmount,
                this.formBuilder.control(this.move.gasAdvanceAmount ?? this.getDefaultGasAdvanceAmount(), Validators.required)
            );
        }
    }

    public submit(): void {
        if (this.form.invalid) return;
        const { usageOfResidence, communicationChannel, electricityAdvanceAmount, gasAdvanceAmount, meterSituation } = this.form
            .value as EnergyOrderInformationFormData;

        const payload: Pick<
            Move,
            'usageOfResidence' | 'meterSituation' | 'electricityAdvanceAmount' | 'gasAdvanceAmount' | 'communicationChannel'
        > = {
            usageOfResidence,
            meterSituation,
            communicationChannel,
        };

        if (this.isElectricityOrBoth) payload.electricityAdvanceAmount = electricityAdvanceAmount;
        if (this.isGasOrBoth) payload.gasAdvanceAmount = gasAdvanceAmount;

        this.loadingSubject.next(true);
        this.moveSandbox.patch({
            moveId: DbUtils.getStringId(this.move),
            payload,
            callback: this.afterPatch,
        });
    }

    private getDefaultMeterSituation(): MeterSituation {
        return MoveUtils.isCreatedByFlow(this.move, CreatedByFlowEnum.EnergyOptimization)
            ? MeterSituation.SupplierSwitch
            : MeterSituation.TakeOverOpenMeters;
    }

    private getDefaultUsageOfResidence(): UsageOfResidence {
        return MoveUtils.isProfessional(this.move) ? UsageOfResidence.ProfessionalCustomer : UsageOfResidence.ResidentialCustomer;
    }

    private getDefaultElectricityAdvanceAmount(): number {
        return MoveUtils.getElectricityAdvanceAmount(this.move);
    }

    private getDefaultGasAdvanceAmount(): number {
        return MoveUtils.getGasAdvanceAmount(this.move);
    }

    private afterPatch = (move: Move): void => {
        if (!move) {
            this.loadingSubject.next(false);
            this.dialogRef.close();
            return;
        }

        this.orderService
            .createOrderEnergy(DbUtils.getStringId(this.move), { noErrorNotification: true })
            .pipe(catchError(this.onOrderError))
            .subscribe(this.onOrderSuccess);
    };

    private onOrderError = (err: ErrorResponse): Observable<never> => {
        this.errorMessage = HttpUtils.getErrorCode(err).message;
        this.loadingSubject.next(false);
        return throwError(err);
    };

    private onOrderSuccess = (move: Move): void => {
        this.loadingSubject.next(false);
        this.dialogRef.close(move);
    };
}
