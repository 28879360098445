import { Injectable } from '@angular/core';
import { State } from '@app/store/state';
import { select, Store } from '@ngrx/store';
import { CommunicationProvidersModule } from '../communcation-providers.module';
import { clearCustomMessageTemplates, getCustomMessageTemplates } from '../state/communication.actions';
import { getLoadingState, getTemplatesState } from '../state/communication.reducer';

@Injectable({
    providedIn: CommunicationProvidersModule,
})
export class CommunicationSandbox {
    public templates$ = this.store$.pipe(select(getTemplatesState));
    public loading$ = this.store$.pipe(select(getLoadingState));

    constructor(private readonly store$: Store<State>) {}

    public init(moveId: string): void {
        this.store$.dispatch(getCustomMessageTemplates({ moveId }));
    }

    public clear(): void {
        this.store$.dispatch(clearCustomMessageTemplates());
    }
}
