import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { PasswordValidators, SiblingValidators } from '@smooved/ui';
import { ResetPasswordFields, ResetPasswordForm } from './reset-password.constants';

@Component({
    selector: 'smvd-app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    public readonly fields = ResetPasswordFields;
    public expirationDays: number;
    public errorMessages = {
        notEquals: 'AUTHENTICATION.RESET_PASSWORD.PASSWORD_CANNOT_BE_THE_SAME',
    };

    @Output() public formSubmit = new EventEmitter<ResetPasswordForm>();

    public form = this.formBuilder.group({
        [ResetPasswordFields.CurrentPassword]: [null, Validators.required],
        [ResetPasswordFields.NewPassword]: [
            null,
            [Validators.required, PasswordValidators.validatePolicy, SiblingValidators.notEquals(ResetPasswordFields.CurrentPassword)],
        ],
        [ResetPasswordFields.ConfirmPassword]: [null, [Validators.required, SiblingValidators.equals(ResetPasswordFields.NewPassword)]],
    });

    constructor(private readonly formBuilder: UntypedFormBuilder, private readonly authenticationSandbox: AuthenticationSandbox) {}

    public ngOnInit(): void {
        this.expirationDays = this.authenticationSandbox.resetData?.expirationDays;
    }

    public onSubmit(): void {
        if (!this.form.valid) return;
        this.formSubmit.emit(this.form.value);
    }
}
