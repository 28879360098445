import { Injectable } from '@angular/core';
import { ArrayUtils } from '@smooved/core';
import { totalStack, waterfallStack } from './charts.constants';
import { ChartItem, ChartItemStack } from './interfaces/chart-item';
import { StackedBarInput } from './interfaces/stacked-bar-input';

type valueGetterXFn = <T extends StackedBarInput>(i: T) => string;
type valueGetterYFn = <T extends StackedBarInput>(i: T) => string;

interface Preset extends StackedBarInput {
    xKey: string;
    yKey: string;
    label: string;
}

@Injectable()
export class StackedBarChartUtils {
    public static createWaterfall(chartItems: ChartItem[]): void {
        chartItems.forEach((chartItem, index) => {
            let previousTotal = ArrayUtils.sum(chartItems.slice(0, index).map((chartItem) => chartItem.value));
            if (chartItem.label === totalStack) previousTotal = 0; // reset for Totals chartItem
            chartItem.stacks[waterfallStack] = StackedBarChartUtils.chartItemStackFactory('', waterfallStack, previousTotal, true);
        });
    }

    public static chartItemStackFactory = (key: string, label: string, value: number, hidden?: boolean): ChartItemStack => {
        return { key, label, value, hidden };
    };

    public static prepareData<T>(items: T[], valueGetterX: valueGetterXFn, valueGetterY: valueGetterYFn): ChartItem[] {
        const chartItems = [];
        const presetItems: Preset[] = StackedBarChartUtils.presetData(items, valueGetterY, valueGetterX);
        const xValues = StackedBarChartUtils.getStackKeys(presetItems);

        chartItems.push(...xValues.map((x) => StackedBarChartUtils.chartItemFactory(x, presetItems)));
        return chartItems;
    }

    private static chartItemFactory(x: string, items: Preset[]): ChartItem {
        const stackList = items
            .filter((item) => item.xKey === x)
            .map((item) => ({ ...StackedBarChartUtils.createChartItemStack(item), value: item.count }));
        const stacks = stackList.reduce((stacks, item) => ({ ...stacks, [item.key]: item }), {});

        return {
            label: x,
            value: ArrayUtils.sum(stackList.map((stack) => stack.value)),
            stacks,
        };
    }

    private static createChartItemStack = (item: Preset): ChartItemStack => {
        return StackedBarChartUtils.chartItemStackFactory(item.yKey, item.label, item.count);
    };

    private static defineLabelPlaceholders(valueGetterY: valueGetterYFn, valueGetterX: valueGetterXFn) {
        return (item: any): Preset => ({
            ...item,
            xKey: valueGetterX(item),
            yKey: valueGetterY(item),
            label: valueGetterY(item),
        });
    }

    private static getStackKeys(items: Preset[]): string[] {
        return items
            .reduce((xVals: string[], item) => {
                if (xVals.includes(item.xKey)) return xVals;
                return [...xVals, item.xKey];
            }, [])
            .sort();
    }

    private static group = (result: Preset[], item: Preset): Preset[] => {
        const found = result.find((i) => i.yKey === item.yKey && i.xKey === item.xKey);
        if (found) {
            found.count += item.count;
            return result;
        }
        return [...result, item];
    };

    private static presetData(items: any[], valueGetterY: valueGetterYFn, valueGetterX: valueGetterXFn): Preset[] {
        return items.map(StackedBarChartUtils.defineLabelPlaceholders(valueGetterY, valueGetterX)).reduce(StackedBarChartUtils.group, []);
    }
}
