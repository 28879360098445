<app-closable-modal-template [maxWidth]="maxWidth" [width]="width" [hideCloseButton]="hideCloseButton">
    <div modal-header *ngIf="!!header || !!subheader">
        <div class="u-flex-row u-flex-justify-content-center u-flex-align-items-center">
            <h5 *ngIf="!!header" [ngClass]="headerClass">{{ header | translate }}</h5>
            <button
                *ngIf="delete"
                smvd-ui-button
                [icon]="uiIcon.Delete"
                [context]="uiContext.Warning"
                [appearance]="buttonAppearance.Icon"
                [iconSize]="iconSize.Md"
                [title]="'COMMON.DELETE' | translate"
                (click)="onDelete()"
            >
                {{ 'COMMON.DELETE' | translate }}
            </button>
        </div>
        <span class="u-font-size-medium u-color-muted" *ngIf="!!subheader">{{ subheader | translate }}</span>
    </div>
    <ng-template #ref></ng-template>
</app-closable-modal-template>
