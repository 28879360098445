import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ArrayUtils, Asset } from '@smooved/core';
import { SvgIllustration, UiIcon, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-water-meter-reading-details',
    templateUrl: 'water-meter-reading-details.component.html',
})
export class WaterMeterReadingDetailsComponent {
    @Input() public move: Move;
    @Input() public showDocument = true;
    @Input() public showMovingDate = true;

    public readonly UiIconSize = UiIconSize;
    public readonly UiIcon = UiIcon;
    public readonly uiIcon = UiIcon;
    public readonly svgs = SvgIllustration;
    public readonly ArrayUtils = ArrayUtils;

    constructor(private readonly moveSandbox: MoveSandbox) {}

    public downloadFile(asset: Asset): void {
        this.moveSandbox.openAsset(asset);
    }
}
