import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isArray, isObject } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseUri } from '../constants/uri.constants';
import { MoveSandbox } from '../sandboxes/move.sandbox';

@Injectable()
export class MoveResponseInterceptor implements HttpInterceptor {
    constructor(private moveSandbox: MoveSandbox) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((response: HttpEvent<any>) => {
                if (response instanceof HttpResponse && response.url.includes(baseUri)) {
                    if (isArray(response.body?.data)) {
                        // pagination response
                        response.body.data.forEach((move) => this.moveSandbox.enrichMove(move));
                    } else if (isArray(response.body)) {
                        response.body.forEach((move) => this.moveSandbox.enrichMove(move));
                    } else if (isObject(response.body)) {
                        this.moveSandbox.enrichMove(response.body);
                    }
                }
                return response;
            })
        );
    }
}
