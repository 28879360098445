<form [formGroup]="form" *ngIf="showElectricity">
    <div class="u-flex-column u-flex-align-items-start u-w100p">
        <h6 *ngIf="showTitle" class="u-color-muted u-margin-bottom">{{ 'ELECTRICITY' | translate }}</h6>

        <app-label-container [label]="'METER_TYPE' | translate" [hasMarginDouble]="true" class="u-w100p" [hasMargin]="false">
            <div class="u-flex-column u-flex-align-items-start">
                <app-select-input
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    width="auto"
                    class="u-margin-right-double-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape"
                    [formControlName]="controls.MeterType"
                    ngDefaultControl
                    [direction]="uiDirection.Vertical"
                    [options]="meterTypeOptions"
                ></app-select-input>

                <app-check-input
                    [custom]="false"
                    width="auto"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [option]="exclusiveNightOption"
                    [formControlName]="controls.ExclusiveNight"
                    ngDefaultControl
                    class="u-margin-top"
                >
                </app-check-input>
            </div>
        </app-label-container>

        <app-ean-code-input [formControlName]="controls.EanCode" ngDefaultControl></app-ean-code-input>

        <div *ngIf="isAdmin" class="u-padding-left-double u-w100p">
            <smvd-ui-text-input
                [label]="'EAN_CODE_BY_ADMIN.LABEL' | translate"
                [formControlName]="controls.EanCodeByAdmin"
                ngDefaultControl
            ></smvd-ui-text-input>
            <smvd-ui-text-input
                [label]="'METER_NUMBER_BY_ADMIN.LABEL' | translate"
                [formControlName]="controls.MeterNumberByAdmin"
                ngDefaultControl
            ></smvd-ui-text-input>
        </div>

        <div class="u-margin-bottom-triple u-mw100p">
            <div
                [ngClass]="{
                    'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                    'u-flex-column': layout === uiDirection.Vertical
                }"
                *ngIf="!isEots"
                [ngSwitch]="meterTypeFormControl().value"
            >
                <ng-container *ngSwitchCase="meterType.Single">
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.singleMeterReading }"
                    ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="meterType.Double">
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.doubleDayMeterReading }"
                    ></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.doubleNightMeterReading }"
                    ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="meterType.Digital">
                    <div class="u-flex-column">
                        <app-check-input
                            [custom]="false"
                            width="300px"
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                            [option]="automaticDigitalReadingOption"
                            formControlName="automaticDigitalReadings"
                            ngDefaultControl
                            class="u-margin-bottom-half"
                        >
                        </app-check-input>

                        <app-date-input
                            formControlName="movingDate"
                            ngDefaultControl
                            maxWidth="auto"
                            class="__sub-check-input u-margin-bottom"
                        >
                        </app-date-input>

                        <div
                            class="u-margin-bottom u-mw100p"
                            [ngClass]="{
                                'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                                'u-flex-column': layout === uiDirection.Vertical
                            }"
                        >
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.consumptionDayMeterReading }"
                            >
                            </ng-container>
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.consumptionNightMeterReading }"
                            >
                            </ng-container>
                        </div>
                        <div class="u-margin-bottom-half __info">
                            <p class="u-font-size-small">{{ 'ENERGY.METER_READING.DIGITAL.INJECTION_NOTE' | translate }}</p>
                        </div>
                        <div
                            class="u-margin-bottom u-mw100p"
                            [ngClass]="{
                                'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                                'u-flex-column': layout === uiDirection.Vertical
                            }"
                        >
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.injectionDayMeterReading }"
                            >
                            </ng-container>
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.injectionNightMeterReading }"
                            >
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <ng-template #fieldContent let-field="field">
                    <app-meter-reading-input
                        [formControlName]="field.control"
                        ngDefaultControl
                        [label]="field.label | translate"
                        [hasMargin]="false"
                        [ngClass]="{
                            'u-margin-right-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape':
                                layout === uiDirection.Horizontal && !field.noMargins,
                            'u-margin-bottom': layout === uiDirection.Vertical && !field.noMargins
                        }"
                        [hasMarginDouble]="false"
                    ></app-meter-reading-input>
                </ng-template>
            </div>
            <div *ngIf="!!exclusiveNightFormControl().value && !isEots" class="u-margin-top">
                <app-meter-reading-input
                    [formControlName]="controls.ExclusiveNightMeterReading"
                    ngDefaultControl
                    [label]="'METER_READING_EXCLUSIVE_NIGHT' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                >
                </app-meter-reading-input>
            </div>
        </div>
    </div>
</form>
