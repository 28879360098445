export enum MenuItemAppearance {
    Default = 'beam',
    Button = 'button',
    Filter = 'filter',
    Link = 'link',
}

export enum MenuDirection {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

export enum MenuAlignment {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum MenuItemSize {
    Default = 'default',
    Small = 'sm',
}

export enum MenuItemIconSize {
    Default = 'default',
    Sm = 'sm',
    Md = 'md',
}
