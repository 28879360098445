import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CheckWithBackgroundComponent } from './components/check-with-background/check-with-background.component';
import { SvgCheckedComponent } from './components/svg-checked/svg-checked.component';
import { SvgIllustrationComponent } from './components/svg-illustration/svg-illustration.component';
import { SvgComponent } from './components/svg/svg.component';

@NgModule({
    declarations: [SvgComponent, CheckWithBackgroundComponent, SvgCheckedComponent, SvgIllustrationComponent],
    imports: [CommonModule, InlineSVGModule],
    exports: [SvgComponent, CheckWithBackgroundComponent, SvgCheckedComponent, SvgIllustrationComponent],
})
export class SvgModule {}
