import { Component, HostBinding } from '@angular/core';
import { AnimationUtils } from '@smooved/ui';

@Component({
    selector: 'app-loading-overlay',
    template: `
        <div class="__container u-flex-row u-flex-align-items-center u-flex-justify-content-center">
            <mat-progress-spinner [diameter]="96" [strokeWidth]="4" [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
    `,
    styleUrls: ['./loading-overlay.component.scss'],
    animations: [AnimationUtils.fade('enter', 100)],
})
export class LoadingOverlayComponent {
    @HostBinding('@enter')
    public enter: any;
}
