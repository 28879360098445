import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiI18nKeyType } from '../../i18n';
import { BaseInput } from '../base-input';

@Component({
    selector: 'app-textarea-input, smvd-ui-textarea-input',
    template: `
        <app-label-container
            [required]="required"
            [bold]="labelBold"
            [id]="id"
            [label]="label"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
        >
            <mat-form-field floatLabel="never" appearance="outline">
                <textarea
                    [id]="id"
                    [ngModelOptions]="ngModelOptions"
                    [rows]="rows"
                    matInput
                    [maxlength]="maxLength"
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    autocomplete="smooved"
                >
                </textarea>
                <mat-hint *ngIf="showMinLengthCounter()" align="end" [class.u-color-error]="formControl.touched && formControl.invalid">{{
                    'MINLENGTH_COUNTER' | i18nKeyTranslate: uiI18nKeyType.FormValidation : { value: minLengthCounterValue() }
                }}</mat-hint>
                <mat-error *ngIf="showErrors">{{ getFirstErrorState() | errorState }}</mat-error>
            </mat-form-field>
            <div class="__input-hint">
                <ng-content select="[hint]"></ng-content>
            </div>
        </app-label-container>
    `,
    styleUrls: ['./textarea-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaInputComponent),
            multi: true,
        },
    ],
})
export class TextareaInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public labelBold = false;
    @Input() public rows = 3;
    @Input() public ngModelOptions;
    @Input() public showErrors = true;
    @Input() public minLengthCounter: number;
    @Input() public maxLength: number;

    public readonly uiI18nKeyType = UiI18nKeyType;

    public innerModel: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel.trim());
    }

    public showMinLengthCounter(): boolean {
        if (!this.minLengthCounter) return false;
        return !!this.minLengthCounterValue();
    }

    public minLengthCounterValue(): number {
        return Math.max(this.minLengthCounter - this.innerModel?.length || 0, 0);
    }
}
