import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    dateFormatPubDate,
    HomeDescription,
    HouseTypePipe,
    I18nKeyService,
    I18nKeyType,
    NpsReview,
    ReviewSource,
    SimpleChangesUtils,
} from '@smooved/core';
import { SurveyUtils } from '../../../../../../shared/src/lib/survey/utils/survey.utils';
import { ApplicationUtils } from '../../../application';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { SvgIllustration } from '../../../svg';
import { UiContext, UiSize } from '../../../ui.enums';
import { NPS_REVIEWS_TEMPLATE_CONFIG, translationPathReviewVia } from '../../constants/reviews.constants';
import { NpsReviewsTemplateConfig } from '../../interfaces';
import { houseTypeIcons } from './nps-review-author.constants';

@Component({
    selector: 'app-nps-review-author',
    templateUrl: './nps-review-author.component.html',
    styleUrls: ['./nps-review-author.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsReviewAuthorComponent implements OnChanges {
    @Input() review: NpsReview;

    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly pubDateformat = dateFormatPubDate;
    public readonly iconSize = UiIconSize;
    public readonly uiSize = UiSize;
    public readonly iconAppearance = UiIconAppearance;
    public readonly uiContext = UiContext;
    public readonly reviewSources = ReviewSource;
    public readonly svgIllustration = SvgIllustration;

    public houseTypeIcon: string;
    public houseTypeI18n: string;
    public assesorI18n: string;
    public isVerified: boolean;
    public showMeta: boolean;
    public reviewViaI18n: string;
    public reviewViaIcon: string;

    constructor(
        private readonly i18nKeyService: I18nKeyService,
        private houseTypePipe: HouseTypePipe,
        @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig
    ) {}

    public ngOnChanges({ review }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(review)) {
            this.assesorI18n = this.i18nKeyService.map(this.review.assessedTo, I18nKeyType.Interviewee);
            this.houseTypeIcon = this.review.houseType === HomeDescription.Apartment ? houseTypeIcons.apartment : houseTypeIcons.default;
            this.houseTypeI18n = this.i18nKeyService.map(this.houseTypePipe.transform(this.review.houseType), I18nKeyType.HouseType);
            this.isVerified = this.review.source === ReviewSource.Smooved;
            this.showMeta =
                this.review.showReviewMeta ||
                !this.isVerified ||
                (!this.review.showReviewMeta && ApplicationUtils.isSmoovedApplication(this.templateConfig.application));
            this.reviewViaIcon = SurveyUtils.interviewViaIcon(this.review.initiatedBy);
            this.reviewViaI18n = this.isVerified ? translationPathReviewVia + SurveyUtils.interviewVia(this.review.initiatedBy) : null;
        }
    }
}
