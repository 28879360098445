import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { MaskSandbox } from '../../../shared/sandboxes/mask.sandbox';

@Component({
    selector: 'app-passport-number-input',
    template: `
        <div [ngClass]="{ 'u-margin-bottom-double': hasMarginDouble, 'u-margin-bottom': hasMargin }" [ngSwitch]="isBelgium">
            <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="true" [hasMarginDouble]="false">
                <mat-form-field floatLabel="never" appearance="outline">
                    <input
                        [id]="id"
                        *ngSwitchCase="true"
                        type="text"
                        matInput
                        #input
                        [mask]="maskSandbox.idCardNumber"
                        [showMaskTyped]="true"
                        [clearIfNotMatch]="true"
                        [placeHolderCharacter]="'_'"
                        [(ngModel)]="innerModel"
                        (blur)="onBlur($event)"
                        (ngModelChange)="onModelChange()"
                        [disabled]="innerDisabled"
                        [placeholder]="placeholder"
                        [errorStateMatcher]="errorStateMatcher"
                        autocomplete="smooved"
                    />
                    <input
                        [id]="id"
                        *ngSwitchCase="false"
                        type="text"
                        matInput
                        #input
                        [(ngModel)]="innerModel"
                        (blur)="onBlur($event)"
                        (ngModelChange)="onModelChange()"
                        [disabled]="innerDisabled"
                        [placeholder]="placeholder"
                        [errorStateMatcher]="errorStateMatcher"
                        autocomplete="smooved"
                    />
                    <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
                </mat-form-field>
            </app-label-container>

            <p class="u-color-muted u-font-size-small">{{ 'MOVE.USER.PASSPORT_NUMBER.EXTRA.1' | translate }}</p>
            <p class="u-margin-top-half u-color-muted u-font-size-small" *ngIf="moveSandbox.telecomOfferSelection$ | async">
                {{ 'MOVE.USER.PASSPORT_NUMBER.EXTRA.2' | translate: { provider: (moveSandbox.telecomOfferSelection$ | async)?.provider } }}
            </p>
            <a (click)="isBelgium = false" *ngSwitchCase="true" class="u-display-block u-margin-top-half u-link u-font-size-small">{{
                'MOVE.USER.PASSPORT_NUMBER.EXTRA.3a' | translate
            }}</a>
            <a (click)="isBelgium = true" *ngSwitchCase="false" class="u-display-block u-margin-top-half u-link u-font-size-small">{{
                'MOVE.USER.PASSPORT_NUMBER.EXTRA.3b' | translate
            }}</a>
        </div>
    `,
    styleUrls: ['./passport-number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PassportNumberInputComponent),
            multi: true,
        },
    ],
})
export class PassportNumberInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id = 'passport-number';
    @Input() public label: string = this.translateService.instant('MOVE.USER.PASSPORT_NUMBER');
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: string;

    public isBelgium = true;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private translateService: TranslateService,
        public moveSandbox: MoveSandbox,
        public maskSandbox: MaskSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
