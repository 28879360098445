import { Injectable } from '@angular/core';
import { RxjsService } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { SkeletonBackgroundSheetConfig } from './skeleton-config.interface';
import { defaultBackgroundSheetConfig } from './skeleton.constants';

@Injectable({ providedIn: 'root' })
export class SkeletonService extends RxjsService {
    private hasBackgroundSheetSubject = new BehaviorSubject<SkeletonBackgroundSheetConfig>(null);
    public hasBackgroundSheet$ = this.hasBackgroundSheetSubject.asObservable();

    private hasToolbarSubject = new BehaviorSubject<boolean>(false);
    public hasToolbar$ = this.hasToolbarSubject.asObservable();

    constructor() {
        super();
    }

    public showBackgroundSheet(config: SkeletonBackgroundSheetConfig = defaultBackgroundSheetConfig): void {
        this.hasBackgroundSheetSubject.next({
            ...defaultBackgroundSheetConfig,
            ...config,
            classNames: [...defaultBackgroundSheetConfig.classNames, ...config?.classNames],
        });
    }

    public hideBackgroundSheet(): void {
        this.hasBackgroundSheetSubject.next(null);
    }

    public showToolbar(show: boolean): void {
        this.hasToolbarSubject.next(show);
    }
}
