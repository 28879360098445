import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomeDescription } from '@smooved/core';
import { State } from '../../store/state';
import { EmojiIcon } from '../../wizard/icon/models/emoji-icon.enum';
import { WizardInputSelection } from '../../wizard/interfaces/wizard-input-selection';
import { WizardInputSubSelection } from '../../wizard/interfaces/wizard-input-sub-selection';
import { HomeConstructionYear, HomeDescriptionSize } from '../state/shared.reducer';

@Injectable({
    providedIn: 'root',
})
export class SharedSandbox {
    public homeDescriptionOptions: WizardInputSelection<HomeDescription>[] = [
        {
            icons: [EmojiIcon.House],
            id: 'home-description-detached',
            label: 'Vrijstaand',
            value: HomeDescription.Detached,
            name: 'home-description',
        },
        {
            icons: [EmojiIcon.HalfOpen],
            id: 'home-description-half-open',
            label: 'Halfopen',
            value: HomeDescription.HalfOpen,
            name: 'home-description',
        },
        {
            icons: [EmojiIcon.Closed],
            id: 'home-description-closed',
            label: 'Gesloten',
            value: HomeDescription.Closed,
            name: 'home-description',
        },
        {
            icons: [EmojiIcon.Apartment],
            id: 'home-description-apartment',
            label: 'Appartement',
            value: HomeDescription.Apartment,
            name: 'home-description',
        },
    ];

    public apartmentSizeOptions: WizardInputSubSelection<HomeDescriptionSize>[] = [
        {
            id: 'size-0-50',
            label: '0m² - 50m²',
            value: HomeDescriptionSize.Size0to50,
            name: 'home-description-size',
        },
        {
            id: 'size-50-100',
            label: '50m² - 100m²',
            value: HomeDescriptionSize.Size50to100,
            name: 'home-description-size',
        },
        {
            id: 'size-100-150',
            label: '100m² - 150m²',
            value: HomeDescriptionSize.Size100to150,
            name: 'home-description-size',
        },
    ];

    public closedSizeOptions: WizardInputSubSelection<HomeDescriptionSize>[] = [
        {
            id: 'size-0-125',
            label: '0m² - 125m²',
            value: HomeDescriptionSize.Size0to125,
            name: 'home-description-size',
        },
        {
            id: 'size-125-200',
            label: '125m² - 200m²',
            value: HomeDescriptionSize.Size125to200,
            name: 'home-description-size',
        },
        {
            id: 'size-200-250',
            label: '200m² - 250m²',
            value: HomeDescriptionSize.Size200to250,
            name: 'home-description-size',
        },
    ];

    public halfOpenSizeOptions: WizardInputSubSelection<HomeDescriptionSize>[] = this.closedSizeOptions;

    public openSizeOptions: WizardInputSubSelection<HomeDescriptionSize>[] = [
        {
            id: 'size-0-200',
            label: '0m² - 200m²',
            value: HomeDescriptionSize.Size0to200,
            name: 'home-description-size',
        },
        {
            id: 'size-200-250',
            label: '200m² - 250m²',
            value: HomeDescriptionSize.Size200to250,
            name: 'home-description-size',
        },
        {
            id: 'size-250-300',
            label: '250m² - 300m²',
            value: HomeDescriptionSize.Size250to300,
            name: 'home-description-size',
        },
    ];

    public homeConstructionYearOptions: WizardInputSelection<HomeConstructionYear>[] = [
        {
            icons: [EmojiIcon.OldHouse],
            id: 'home-construction-year-Before2000BuiltOrRenovated',
            label: 'Voor 2000 gebouwd of gerenoveerd',
            value: HomeConstructionYear.Before2000BuiltOrRenovated,
            name: 'home-construction-year',
        },
        {
            icons: [EmojiIcon.House],
            id: 'home-construction-year-After2000BuiltOrRenovated',
            label: 'Na 2000 gebouwd of gerenoveerd',
            value: HomeConstructionYear.After2000BuiltOrRenovated,
            name: 'home-construction-year',
        },
        {
            icons: [EmojiIcon.NewHouse],
            id: 'home-construction-year-NewConstruction',
            label: 'Nieuwbouw',
            value: HomeConstructionYear.NewConstruction,
            name: 'home-construction-year',
        },
    ];

    constructor(private store$: Store<State>) {}
}
