import { Pipe, PipeTransform } from '@angular/core';
import { UriSchemaType } from './uri-schema-type.enum';

@Pipe({
    name: 'uriSchema',
})
export class UriSchemaPipe implements PipeTransform {
    transform(value: string, type: UriSchemaType): string {
        if (!value || !type) return;

        if (type === UriSchemaType.Website) {
            return value.replace(/(https|http):\/\//, '');
        }
        return `${type}:${value}`;
    }
}
