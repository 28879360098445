import { eanLength, eanStartWith } from '@app/move/constants/move.constants';
import { Move } from '@app/move/interfaces/move';
import { Movers } from '@app/move/interfaces/movers';
import { Order } from '@app/order/interfaces/order';
import { OrderGift } from '@app/order/interfaces/order-gift';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { EnergyTransfer } from '@app/wizard/energy/interfaces/energy-transfer';
import { TelecomTransfer } from '@app/wizard/telecom/interfaces/telecom-transfer';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import {
    CreatedByFlowEnum,
    DateUtils,
    DbUtils,
    FeatureScope,
    MoveTransactionType,
    NpsReview,
    ObjectUtils,
    ReviewSource,
} from '@smooved/core';
import { ElectricityMeterType } from '@smooved/ui';
import { ContactLogType } from '../enums/contact-log-type.enum';
import { Leaver } from '../enums/leaver.enum';
import { MoverRole } from '../enums/mover-role.enum';
import { TodoType } from '../enums/todo-type.enum';
import { Transferee } from '../enums/transferee.enum';
import { ContactLog } from '../interfaces/contact-log';
import { User } from '../interfaces/user';

export class MoveUtils {
    public static isTransferee({ user }: Move): boolean {
        return user?.role === MoverRole.Transferee;
    }

    public static isLeaver({ user }: Move): boolean {
        return user?.role === MoverRole.Leaver;
    }

    public static isElectricityOrBoth(move: Move): boolean {
        return [EnergyType.Electricity, EnergyType.Both].includes(move?.energyOffer?.energyOfferSelection?.type);
    }

    public static isCreatedByFlow(move: Move, flow: CreatedByFlowEnum): boolean {
        return ObjectUtils.get(move.createdByFlow, flow);
    }

    public static hasElectricityEanCode(move: Move): boolean {
        return !!move?.eanCodeElectricity;
    }

    public static hasDigitalMeter(move: Move): boolean {
        return move?.energyOffer?.meterType === ElectricityMeterType.Digital && !!move?.energyDigitalMeterReadings;
    }

    public static hasElectricitySingleMeter(move: Move): boolean {
        return move?.energyOffer?.meterType === ElectricityMeterType.Single && !!move?.electricitySingleMeterReading;
    }

    public static hasElectricityDoubleOrUnknownMeter(move: Move): boolean {
        return (
            (move?.energyOffer?.meterType === ElectricityMeterType.Double ||
                move?.energyOffer?.meterType === ElectricityMeterType.Unknown) &&
            !!move?.electricityDoubleDayMeterReading &&
            !!move?.electricityDoubleNightMeterReading
        );
    }

    public static hasElectricityMeter(move: Move): boolean {
        return (
            !!move?.electricitySingleMeterReading ||
            (!!move?.electricityDoubleDayMeterReading && !!move?.electricityDoubleNightMeterReading)
        );
    }

    public static isGasOrBoth(move: Move): boolean {
        return [EnergyType.Gas, EnergyType.Both].includes(move?.energyOffer?.energyOfferSelection?.type);
    }

    public static hasGasEanCode(move: Move): boolean {
        return !!move?.eanCodeGas;
    }

    public static hasGasMeter(move: Move): boolean {
        return !!move?.gasMeterReading;
    }

    public static hasOnlyMobile(move: Move): boolean {
        return (
            !!move?.telecomOffer?.telecomOfferSelection?.mobilePhone &&
            !move?.telecomOffer?.telecomOfferSelection?.fixedLine &&
            move?.telecomOffer?.mobileBundle?.length > 0
        );
    }

    public static hasOnlyFixedLine(move: Move): boolean {
        return (
            !move?.telecomOffer?.telecomOfferSelection?.mobilePhone &&
            !!move?.telecomOffer?.telecomOfferSelection?.fixedLine &&
            !!move?.telecomOffer?.fixedLine
        );
    }

    public static hasMobileAndFixedLine(move: Move): boolean {
        return (
            !!move?.telecomOffer?.telecomOfferSelection?.mobilePhone &&
            !!move?.telecomOffer?.telecomOfferSelection?.fixedLine &&
            move?.telecomOffer?.mobileBundle?.length > 0 &&
            !!move?.telecomOffer?.fixedLine
        );
    }

    public static isMeterInfoGasFilled(move: Move): boolean {
        return (!!move?.eanCodeGas || !!move?.eanCodeGasCurrent) && !!move?.gasMeterReading;
    }

    // todo - refactor to use existing methods
    public static isMeterInfoGasComplete(move: Move): boolean {
        return MoveUtils.isMeterInfoGasFilled(move) || !!move?.energyDocumentsMovingAddressByAdmin;
    }

    public static isMeterInfoGasIncompleteAndOutOfDate(move: Move, movingDate: Date): boolean {
        if (!movingDate) {
            return false;
        }
        return !MoveUtils.isMeterInfoGasComplete(move) && new Date().getTime() >= movingDate.getTime();
    }

    public static showMeterReadingElectricity(move: Move): boolean {
        const energyType = move.energyOffer?.energyType;

        switch (energyType) {
            case EnergyType.Gas:
                return false;

            case EnergyType.Both:
            case EnergyType.Electricity:
            default:
                return true;
        }
    }

    public static isMeterInfoElectricityFilled(move: Move): boolean {
        return (
            (!!move?.eanCodeElectricity || !!move?.eanCodeElectricityCurrent) &&
            (!!move?.electricitySingleMeterReading ||
                (!!move?.electricityDoubleDayMeterReading && !!move?.electricityDoubleNightMeterReading))
        );
    }

    // todo - refactor to use existing methods
    public static isMeterInfoElectricityComplete(move: Move): boolean {
        return MoveUtils.isMeterInfoElectricityFilled(move) || !!move?.energyDocumentsMovingAddressByAdmin;
    }

    public static isMeterInfoElectricityIncompleteAndOutOfDate(move: Move, movingDate: Date): boolean {
        if (!movingDate) {
            return false;
        }
        return !MoveUtils.isMeterInfoElectricityComplete(move) && new Date().getTime() >= movingDate.getTime();
    }

    public static energyTransferSuccess(energyTransfer: EnergyTransfer, user: User): boolean {
        return !!energyTransfer?.currentSupplier && !!user?.currentAddress;
    }

    public static energyOfferSelected = (move: Move): boolean => {
        return !!move?.energyOffer?.energyOfferSelection && !!move?.energySelected;
    };

    public static energyStopSupplierComplete = (partial: Partial<Move>): boolean => {
        if (!partial) return false;
        const { leaver } = MoveUtils.getMovers(partial);
        if (!leaver) return false;
        const { energyStop } = leaver;
        if (!energyStop) return false;

        switch (energyStop.energyType) {
            case EnergyType.Gas:
                return MoveUtils.energyStopSupplierGasComplete({ energyStop });
            case EnergyType.Electricity:
                return MoveUtils.energyStopSupplierElectricityComplete({ energyStop });
            case EnergyType.Both:
            default:
                return (
                    MoveUtils.energyStopSupplierGasComplete({ energyStop }) &&
                    MoveUtils.energyStopSupplierElectricityComplete({ energyStop })
                );
        }
    };

    public static energyStopSupplierGasComplete({ energyStop }: { energyStop: EnergyStop }): boolean {
        return (
            (energyStop.hasSameSupplier && MoveUtils.energyStopSupplierElectricityComplete({ energyStop })) ||
            !!energyStop?.currentGasSupplier
        );
    }

    public static energyStopSupplierElectricityComplete({ energyStop }: { energyStop: EnergyStop }): boolean {
        return !!energyStop?.currentElectricitySupplier;
    }

    public static showMeterReadingGas = (move: Move): boolean => {
        const energyType = move.energyOffer?.energyType;

        switch (energyType) {
            case EnergyType.Electricity:
                return false;

            case EnergyType.Both:
            case EnergyType.Gas:
            default:
                return true;
        }
    };

    public static energyTransferSelected(move: Partial<Move>): boolean {
        return !!move?.energyTransfer && !!move?.energySelected;
    }

    public static eanCodeElectricityComplete(move: Partial<Move>): boolean {
        const moveEnergyType = move?.energyOffer?.energyType;
        if (moveEnergyType !== EnergyType.Electricity && moveEnergyType !== EnergyType.Both) return true;
        if (MoveUtils.energyTransferSelected(move)) {
            return !!move?.eanCodeElectricity;
        } else {
            return !!move?.eanCodeElectricity;
        }
    }

    public static eanCodeGasComplete(move: Partial<Move>): boolean {
        const moveEnergyType = move?.energyOffer?.energyType;
        if (moveEnergyType !== EnergyType.Gas && moveEnergyType !== EnergyType.Both) return true;
        if (MoveUtils.energyTransferSelected(move)) {
            return !!move?.eanCodeElectricity;
        } else {
            return !!move?.eanCodeGas;
        }
    }

    public static eanCodesComplete = (
        move: Pick<Move, 'eanCodeElectricity' | 'eanCodeGas' | 'energyOffer' | 'energyTransfer' | 'energySelected'>
    ): boolean => {
        return MoveUtils.eanCodeElectricityComplete(move) && MoveUtils.eanCodeGasComplete(move);
    };

    public static meterReadingElectricityComplete = (move: Partial<Move>): boolean => {
        if (!move) return false;

        const energyType = MoveUtils.getEnergyType(move);
        if (!!energyType && !MoveUtils.isElectricityEnergyType(energyType)) return true;
        if (move.electricityExclusiveNight && !move.electricityDoubleExclusiveNightMeterReading) return false;

        switch (move?.energyOffer?.meterType) {
            case ElectricityMeterType.Digital:
                return (
                    !!move?.energyDigitalMeterReadings?.automatic ||
                    (!!move?.energyDigitalMeterReadings?.consumption?.day &&
                        !!move?.energyDigitalMeterReadings?.consumption?.night &&
                        !!move?.energyDigitalMeterReadings?.injection?.day &&
                        !!move?.energyDigitalMeterReadings?.injection?.night)
                );
            case ElectricityMeterType.Single:
                return !!move?.electricitySingleMeterReading;

            case ElectricityMeterType.Double:
            case ElectricityMeterType.Unknown:
                return !!move?.electricityDoubleDayMeterReading && !!move?.electricityDoubleNightMeterReading;

            default:
                return (
                    !!move?.electricitySingleMeterReading ||
                    (!!move?.electricityDoubleDayMeterReading && !!move?.electricityDoubleNightMeterReading)
                );
        }
    };

    public static getEnergyType(move: Partial<Move>): EnergyType {
        return move?.energyOffer?.energyType;
    }

    public static meterReadingGasComplete = (move: Partial<Move>): boolean => {
        if (!move) return false;
        const energyType = MoveUtils.getEnergyType(move);
        if (!!energyType && !MoveUtils.isGasEnergyType(energyType)) return true;
        return !!move?.energyDigitalMeterReadings?.automatic || !!move?.gasMeterReading;
    };

    public static meterReadingsComplete = (move: Partial<Move>): boolean => {
        if (MoveUtils.energyTransferSelected(move)) {
            return MoveUtils.meterReadingGasComplete(move) || MoveUtils.meterReadingElectricityComplete(move);
        } else {
            switch (MoveUtils.getEnergyType(move)) {
                case EnergyType.Electricity:
                    return MoveUtils.meterReadingElectricityComplete(move);

                case EnergyType.Gas:
                    return MoveUtils.meterReadingGasComplete(move);

                case EnergyType.Both:
                    return MoveUtils.meterReadingGasComplete(move) && MoveUtils.meterReadingElectricityComplete(move);

                default:
                    return MoveUtils.meterReadingGasComplete(move) || MoveUtils.meterReadingElectricityComplete(move);
            }
        }
    };

    public static meterComplete(move: Partial<Move>): boolean {
        if (!move) return false;

        return (
            !!move.rentalInspectionAsset ||
            !!move.energyDocumentsMovingAddressByAdmin ||
            !!move.energyDocumentsLeavingAddressByAdmin ||
            !!move.energyReadingsConfirmedByRealEstateAgent ||
            (MoveUtils.eanCodesComplete(move) && MoveUtils.meterReadingsComplete(move))
        );
    }

    public static energyComplete = (
        eanCodesComplete: boolean,
        meterReadingsComplete: boolean,
        energyDocumentsMovingAddressByAdmin: boolean
    ): boolean => {
        return (!!eanCodesComplete && !!meterReadingsComplete) || !!energyDocumentsMovingAddressByAdmin;
    };

    public static getHasCallInContactLogs = (contactLogs: ContactLog[]): boolean => {
        if (!contactLogs) return null;
        return !!contactLogs.some((contactLog) => contactLog.value === ContactLogType.Call);
    };

    public static getFirstCallInContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.Call);
    };

    public static getSecondCallInContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        const calls = contactLogs.filter((contactLog) => contactLog.value === ContactLogType.Call);
        return calls[1];
    };

    public static getFirstVoicemailInContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.Voicemail);
    };

    public static getSecondVoicemailInContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        const voicemails = contactLogs.filter((contactLog) => contactLog.value === ContactLogType.Voicemail);
        return voicemails[1];
    };

    public static getEmailPitchInContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.EmailPitch);
    };

    public static getEmailEnergyUrgencyContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.EmailEnergyUrgency);
    };

    public static getEmailMoverContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.EmailMover);
    };

    public static getEmailRealEstateAgentContactLogs = (contactLogs: ContactLog[]): ContactLog => {
        if (!contactLogs) return null;
        return contactLogs.find((contactLog) => contactLog.value === ContactLogType.EmailRealEstateAgent);
    };

    public static getServiceActionsAsString = (
        energyOrderedByAdmin,
        telecomOrderedByAdmin,
        energyDocumentsMovingAddressByAdmin,
        energyDocumentsCurrentAddressByAdmin,
        waterDocumentsMovingAddressByAdmin,
        waterDocumentsCurrentAddressByAdmin
    ): string => {
        const stringArray = [];

        if (energyOrderedByAdmin) stringArray.push('EO');
        if (telecomOrderedByAdmin) stringArray.push('TO');
        if (energyDocumentsMovingAddressByAdmin) stringArray.push('EDM');
        if (energyDocumentsCurrentAddressByAdmin) stringArray.push('EDC');
        if (waterDocumentsMovingAddressByAdmin) stringArray.push('WDM');
        if (waterDocumentsCurrentAddressByAdmin) stringArray.push('WDC');

        if (!stringArray.length) {
            return '';
        }
        return `[${stringArray.join(' | ')}]`;
    };

    public static getMoveTransactionType(move: Move): MoveTransactionType | null {
        if (move.moveStates?.transactionType) return move.moveStates.transactionType;
        if (!move.user) return null;
        if (
            (move.user.role === MoverRole.Leaver && move.leaverType === Leaver.OwnerLeaver) ||
            (move.user.role === MoverRole.Transferee && move.user.transfereeType === Transferee.OwnerTransferee)
        )
            return MoveTransactionType.Sale;

        return MoveTransactionType.Rental;
    }

    public static telecomTransferSuccess = (telecomTransfer: TelecomTransfer, user: User): boolean => {
        return !!telecomTransfer?.fixedLine && !!telecomTransfer?.currentSupplier && !!user?.currentAddress;
    };

    public static hasTelecomInstallation = (move: Move): boolean => {
        return !!move?.telecomOffer?.telecomInstallation?.date && !!move?.telecomOffer?.telecomInstallation?.timeSlot;
    };

    public static hasMeterReadingInfo = (move: Move): boolean => {
        return (
            !!move &&
            (!!move.eanCodeGas ||
                !!move.eanCodeGasCurrent ||
                !!move.gasMeterReading ||
                !!move.eanCodeElectricity ||
                !!move.eanCodeElectricityCurrent ||
                !!move.electricitySingleMeterReading ||
                !!move.electricityDoubleDayMeterReading ||
                !!move.electricityDoubleNightMeterReading)
        );
    };

    public static handleCanOrderStateSelector = (
        energyOfferSelected,
        energyTransferSelected,
        energyOrdered,
        telecomOfferSelected,
        telecomTransferSelected,
        telecomOrdered,
        insurancesOfferSelected,
        insurancesOrdered,
        props
    ) => {
        // ALL
        if (props?.energy && props?.telecom && props?.insurances) {
            return (
                (energyOfferSelected || (energyTransferSelected && !props.onlyOffers)) &&
                !energyOrdered &&
                (telecomOfferSelected || (telecomTransferSelected && !props.onlyOffers)) &&
                !telecomOrdered &&
                insurancesOfferSelected &&
                !insurancesOrdered
            );
        }

        // TWO (ENERGY & TELECOM)
        else if (props?.energy && props?.telecom && !props.insurances) {
            return (
                (energyOfferSelected || (energyTransferSelected && !props.onlyOffers)) &&
                !energyOrdered &&
                (telecomOfferSelected || (telecomTransferSelected && !props.onlyOffers)) &&
                !telecomOrdered &&
                (props.insurances === false ? !insurancesOfferSelected || insurancesOrdered : true)
            );
        }

        // TWO (ENERGY & INSURANCES)
        else if (props?.energy && !props?.telecom && props.insurances) {
            return (
                (energyOfferSelected || (energyTransferSelected && !props.onlyOffers)) &&
                !energyOrdered &&
                insurancesOfferSelected &&
                !insurancesOrdered &&
                (props.telecom === false ? !telecomOfferSelected || telecomOrdered : true)
            );
        }

        // TWO (TELECOM & INSURANCES)
        else if (props?.energy && props?.telecom && !props.insurances) {
            return (
                insurancesOfferSelected &&
                !insurancesOrdered &&
                (telecomOfferSelected || (telecomTransferSelected && !props.onlyOffers)) &&
                !telecomOrdered &&
                (props.energy === false ? !energyOfferSelected || energyOrdered : true)
            );
        }

        // ONE (TELECOM)
        else if (!props?.energy && props?.telecom && !props.insurances) {
            return (
                (telecomOfferSelected || (telecomTransferSelected && !props.onlyOffers)) &&
                !telecomOrdered &&
                (props.energy === false
                    ? (!energyOfferSelected && (!energyTransferSelected || props?.onlyOffers)) || energyOrdered
                    : true) &&
                (props.insurances === false ? !insurancesOfferSelected || insurancesOrdered : true)
            );
        }

        // ONE (ENERGY)
        else if (props?.energy && !props?.telecom && !props.insurances) {
            return (
                (energyOfferSelected || (energyTransferSelected && !props.onlyOffers)) &&
                !energyOrdered &&
                (props.telecom === false
                    ? (!telecomOfferSelected && (!telecomTransferSelected || props?.onlyOffers)) || telecomOrdered
                    : true) &&
                (props.insurances === false ? !insurancesOfferSelected || insurancesOrdered : true)
            );
        }

        // ONE (INSURANCES)
        else if (!props?.energy && !props?.telecom && props.insurances) {
            return (
                insurancesOfferSelected &&
                !insurancesOrdered &&
                (props.telecom === false
                    ? (!telecomOfferSelected && (!telecomTransferSelected || props?.onlyOffers)) || telecomOrdered
                    : true) &&
                (props.energy === false ? (!energyOfferSelected && (!energyTransferSelected || props?.onlyOffers)) || energyOrdered : true)
            );
        }
    };

    public static mapToNpsReview(move: Move): Partial<NpsReview> {
        const { nps } = move.surveys;
        return {
            _id: undefined,
            score: nps.answers.score.value,
            suggestion: nps.answers.suggestion?.value,
            assessedTo: nps.assessedTo,
            hasOrderGift: !!move.orderGift,
            isAnonymous: nps.answers.isAnonymous?.value,
            moveId: move._id,
            notes: nps.notes,
            source: ReviewSource.Smooved,
            language: move.user?.language,
            createdBy: {
                role: undefined,
                firstName: move.user?.firstName ? `${move.user.firstName?.substring(0, 1)}.` : undefined,
                lastName: move.user?.lastName,
                email: move.user?.email,
            },
        };
    }

    public static getRentalInspection(move: Move): OrderRentalInspection {
        // There can only be 1 rental inspection per move
        return MoveUtils.getOrders<OrderRentalInspection>(move, FeatureScope.RentalInspection)?.[0];
    }

    public static getGifts(move: Move): OrderGift[] {
        return MoveUtils.getOrders<OrderGift>(move, FeatureScope.Gift);
    }

    public static getOrders<T extends Order>(move: Move, scope: FeatureScope): T[] {
        return move.orders?.filter((order): boolean => order.scope.startsWith(scope)) as T[];
    }

    public static getMovers(move: Move): Movers {
        const linkedMove = ObjectUtils.isObject(move.linkedMove)
            ? {
                  ...(move.linkedMove as Move),
                  linkedMove: move,
              }
            : undefined;

        if (MoveUtils.isLeaver(move)) {
            return { leaver: move, transferee: linkedMove };
        } else {
            return { leaver: DbUtils.getStringId(linkedMove) ? linkedMove : undefined, transferee: move };
        }
    }

    public static isElectricityEnergyType(energyType: EnergyType): boolean {
        return [EnergyType.Both, EnergyType.Electricity].includes(energyType);
    }

    public static isGasEnergyType(energyType: EnergyType): boolean {
        return [EnergyType.Both, EnergyType.Gas].includes(energyType);
    }

    public static getEanElectricity(move: Move): string {
        return MoveUtils.getEan(move?.eanCodeElectricity || '');
    }

    public static getEanGas(move: Move): string {
        return MoveUtils.getEan(move?.eanCodeGas || '');
    }

    public static getMeterNumberGas(move: Move): string {
        if (MoveUtils.getEanGas(move)) return null;
        return move.eanCodeGas;
    }

    public static getMeterNumberElectricity(move: Move): string {
        if (MoveUtils.getEanElectricity(move)) return null;
        return move.eanCodeElectricity;
    }

    public static getElectricityAdvanceAmount(move: Move): number {
        if (move.electricityAdvanceAmount) return move.electricityAdvanceAmount;
        if (!move?.energyOffer?.energyOfferSelection) return null;
        return DateUtils.getMonthlyAverage(move.energyOffer.energyOfferSelection.electricityTotalPrice);
    }

    public static getGasAdvanceAmount(move: Move): number {
        if (move.gasAdvanceAmount) return move.gasAdvanceAmount;
        if (!move?.energyOffer?.energyOfferSelection) return null;
        return DateUtils.getMonthlyAverage(move.energyOffer.energyOfferSelection.gasTotalPrice);
    }

    public static isProfessional(move: Pick<Move, 'professional'>): boolean {
        return !!move?.professional;
    }

    public static hasMovingDatePassed(move: Move): boolean {
        return DateUtils.now() > move.movingDate;
    }

    public static checkForTodo(move: Move, todoType: TodoType): boolean {
        return !!move.todosForRealEstateAgent?.find((todoForRealEstateAgent) => todoForRealEstateAgent.todo === todoType);
    }

    private static getEan(value: string): string {
        return value.length === eanLength && value.startsWith(eanStartWith) && value;
    }
}
