import { createAction, props } from '@ngrx/store';

export enum UiActionTypes {
    ShowLoadingOverlay = '[Ui] Show loading overlay',
    HideLoadingOverlay = '[Ui] Hide loading overlay',
    MoveLoading = '[Ui] Move loading',
    PatchLoading = '[Ui] Patch loading',
}

type Loading = {
    loading: boolean;
};

export const showLoadingOverlay = createAction(UiActionTypes.ShowLoadingOverlay);
export const hideLoadingOverlay = createAction(UiActionTypes.HideLoadingOverlay);
export const moveLoading = createAction(UiActionTypes.MoveLoading, props<Loading>());
export const patchLoading = createAction(UiActionTypes.PatchLoading, props<Loading>());
