import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isImage } from '@app/form/utils/is-image.util';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { TranslateService } from '@ngx-translate/core';
import { dateTimeFormatDefault } from '@smooved/core';
import { ConfirmModalComponent, MobileModalComponent, ModalSandbox } from '@smooved/ui';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-water-document-detail-modal',
    template: `
        <app-mobile-modal [mainHasPaddingTop]="false">
            <div mobile-modal-header>
                <h6>{{ data.file.name }}</h6>
                <p class="u-color-muted">{{ data.file.createdOn | formatDate: dateTimeFormat }}</p>
            </div>
            <div mobile-modal-extra-action>
                <mat-icon *ngIf="!data.readOnly" class="u-color-accent" (click)="showRemoveModal()">delete</mat-icon>
            </div>
            <div mobile-modal-main class="u-flex-row u-flex-align-items-center u-flex-justify-content-center" [ngSwitch]="isImage()">
                <img *ngSwitchCase="true" [src]="fileData$ | async" class="u-mw100p" />
                <a
                    class="m-button"
                    [class.u-disabled]="!loaded"
                    *ngSwitchCase="false"
                    [download]="data.file.name"
                    [href]="fileData$ | async"
                    download
                    >{{ 'DOWNLOAD_PREVIEW' | translate }}</a
                >
            </div>
        </app-mobile-modal>
    `,
})
export class MobileWaterDocumentAssetDetailComponent {
    public fileData$ = this.moveSandbox.getAsset(this.data.file).pipe(tap((url) => (this.loaded = !!url)));
    public loaded = false;
    public dateTimeFormat = dateTimeFormatDefault;

    @ViewChild(MobileModalComponent) private detail: MobileModalComponent;

    constructor(
        private moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: FileModalData
    ) {}

    public showRemoveModal(): void {
        this.modalSandbox
            .openInfoModal(
                ConfirmModalComponent,
                this.translate.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.CONFIRM_DELETE')
            )
            .afterClosed()
            .subscribe((response) => this.remove(response));
    }

    public isImage(): boolean {
        return isImage(this.data.file);
    }

    private remove(confirm?: boolean): void {
        if (!confirm) return;
        switch (this.data.type) {
            case 'Meter-Reading':
                this.moveSandbox.deleteWaterMeterReadingAsset(this.data.moveId, this.data.file.key).subscribe((move) => {
                    this.detail.dialogRef.close(move);
                });
                break;
            case 'Id-Card':
                this.moveSandbox.deleteWaterIdCardAsset(this.data.moveId, this.data.file.key).subscribe((move) => {
                    this.detail.dialogRef.close(move);
                });
                break;
            default:
                this.moveSandbox.deleteWaterDocumentAsset(this.data.moveId, this.data.file.key).subscribe((move) => {
                    this.detail.dialogRef.close(move);
                });
        }
    }
}
