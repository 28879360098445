/* **************************************************************************
 *
 *  WARNING: DO NOT RENAME -> ENUMS MUST BE KEPT IN SYNC WITH POSTHOG
 *
 * ***************************************************************************/

export enum PostHogEventsEnum {
    CompanionEnergyMetersEditAttachmentUploaded = 'CompanionEnergyMetersEditAttachmentUploaded',
    CompanionEnergyMetersEditManual = 'CompanionEnergyMetersEditManual',
    CompanionEnergyMetersConfirmed = 'CompanionEnergyMetersConfirmed',
    DesktopEnergyMetersEditAttachmentUploaded = 'DesktopEnergyMetersEditAttachmentUploaded',
    DesktopEnergyMetersEditManual = 'DesktopEnergyMetersEditManual',
    DesktopEnergyMetersConfirmed = 'DesktopEnergyMetersConfirmed',
}
