import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { BadgeVerticalAlignDirective } from './badge-vertical-align.directive';
import { BadgeWideDirective } from './badge-wide.directive';

@NgModule({
    imports: [MatBadgeModule],
    declarations: [BadgeVerticalAlignDirective, BadgeWideDirective],
    exports: [BadgeVerticalAlignDirective, BadgeWideDirective, MatBadgeModule],
})
export class BadgeModule {}
