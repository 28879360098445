import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';

Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: environment.sentry.tracesSampleRate, // set spans in traces tab
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // set replays for sessions
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // set replays when errors occours
    environment: environment.environmentName,
});

if (environment.production) {
    enableProdMode();
}

posthog.init(environment.postHogKey, {
    api_host: environment.postHogHost,
    person_profiles: 'identified_only',
    capture_pageview: true,
    capture_pageleave: false,
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
