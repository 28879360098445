import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { I18nKeyType, NpsReview, ReviewSource } from '@smooved/core';
import { NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';

@Component({
    selector: 'app-nps-review-smooved-header',
    templateUrl: './nps-review-smooved-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsReviewSmoovedHeaderComponent {
    @Input() public item: NpsReview;

    public readonly i18nKey = I18nKeyType;
    public readonly source = ReviewSource.Smooved;

    constructor(@Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig) {}
}
