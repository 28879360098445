import { EventEmitter, Injectable } from '@angular/core';
import { MeterReadingsOutputService } from '@app/meter-readings/components/meter-readings-output/meter-readings-output.service';
import { Move } from '@app/move/interfaces/move';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { CheckedState, TrackUtils } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MeterReadingsService extends MeterReadingsOutputService {
    public refetch: EventEmitter<void>;

    private isMovePatchedSubject = new BehaviorSubject<boolean>(false);

    constructor(private realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox) {
        super();
    }

    protected onClickElectricity = this.openMeterInfoModal;
    protected onClickGas = this.openMeterInfoModal;
    protected onClickWater = this.openWaterModal;

    public resetMeterReadingsState(move: Move): void {
        this.move = move;
        this.meterInfoWaterState = this.parseChecked(TrackUtils.checked(this.move.track.order?.waterDocumentsMovingAddress));
        this.meterInfoElectricityState = this.parseChecked(this.move.moveStates.meterElectricityComplete);
        this.meterInfoGasState = this.parseChecked(this.move.moveStates.meterGasComplete);
    }

    private modalDataMoveFactory(): ModalDataMove {
        return {
            move: this.move,
            patchedSubject: this.isMovePatchedSubject,
        };
    }

    private openMeterInfoModal(event: MouseEvent): void {
        event.stopImmediatePropagation();
        this.realEstateAgentModalsSandbox.openMeterInfoModal(this.onModalClose);
    }

    private onModalClose = (): void => {
        this.refetch.emit();
    };

    private openWaterModal(event: MouseEvent): void {
        event.stopImmediatePropagation();
        this.realEstateAgentModalsSandbox.openWaterDocumentModal(this.modalDataMoveFactory(), this.onModalClose);
    }

    public parseChecked(value: boolean): CheckedState {
        return value ? CheckedState.Ok : CheckedState.Nok;
    }
}
