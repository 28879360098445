import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-section',
    template: `
        <h3 *ngIf="!!title" class="u-margin-bottom-double">{{ title }}</h3>
        <ng-content></ng-content>
    `,
    styles: [
        `
                    :host {
                        display: block;
                    }
                `,
    ],
})
export class SectionComponent {
    @Input() public title: string;

    @Input()
    @HostBinding('class.u-bordered-bottom')
    @HostBinding('class.u-padding-bottom-double')
    @HostBinding('class.u-margin-bottom-double')
    public bordered = false;
}
