import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-mortgage-details',
    templateUrl: 'mortgage-details.modal.html',
})
export class MortgageDetailsModal extends MoveData implements OnInit {
    public loading = true;
    public move: Move;

    constructor(protected readonly moveSandbox: MoveSandbox, @Inject(MAT_DIALOG_DATA) public data: string) {
        super(moveSandbox, undefined);
    }

    public ngOnInit(): void {
        this.fetch(this.data, () => (this.loading = false));
        this.move$.pipe(takeUntil(this.destroy$)).subscribe((move) => (this.move = move));
    }
}
