import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MoveSandbox } from '../../sandboxes/move.sandbox';
import { MoveService } from '../../services/move.service';

@Component({
    selector: 'app-confirm-container',
    template: ``,
    styles: [],
})
export class ConfirmContainer implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private authenticationSandbox: AuthenticationSandbox,
        private moveSandbox: MoveSandbox,
        private moveService: MoveService,
        private router: Router,
        private navigationSandbox: AppNavigationSandbox
    ) {}

    public ngOnInit(): void {
        const accessToken: string = this.activatedRoute.snapshot.queryParams['at'];
        const moveId: string = this.activatedRoute.snapshot.params['id'];
        const submit: boolean = this.activatedRoute.snapshot.queryParams['submit'];
        const redirectUrl: string = this.activatedRoute.snapshot.queryParams['redirectUrl'];
        if (accessToken && moveId) {
            this.moveService
                .confirm({ accessToken, moveId })
                .pipe(
                    concatMap((_) => {
                        if (submit) {
                            return this.moveSandbox.submit(moveId);
                        } else {
                            return of(null);
                        }
                    }),
                    concatMap((_) => this.moveSandbox.initSessionForMover(accessToken, moveId))
                )
                .subscribe(
                    (move) => {
                        this.redirect(redirectUrl);
                    },
                    (_) => this.redirect(redirectUrl)
                );
        } else {
            this.redirect(redirectUrl);
        }
    }

    private redirect(redirectUrl: string): void {
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        } else {
            this.navigationSandbox.goToHome();
        }
    }
}
