<div class="u-flex-row u-flex-align-items-start __todo-align-{{ align }} __todo-variant-{{ variant }}" [ngSwitch]="currentState">
    <div *ngSwitchCase="states.Checked" class="u-flex-row __content" [class.u-w100p]="inList">
        <app-check-with-background width="20" height="20" class="__icon u-margin-right-half u-inline-flex-row"></app-check-with-background>
        <ng-container *ngIf="checkedTemplate; else defaultTemplate" [ngTemplateOutlet]="checkedTemplate"></ng-container>
    </div>

    <div *ngSwitchCase="states.Readonly" class="u-flex-row __content" [class.u-w100p]="inList">
        <app-check-with-background
            *ngIf="checked; else uncheckedIcon"
            width="20"
            height="20"
            class="__icon u-margin-right-half u-inline-flex-row"
        ></app-check-with-background>
        <ng-container *ngIf="readonlyTemplate; else defaultTemplate" [ngTemplateOutlet]="readonlyTemplate"></ng-container>
    </div>

    <div *ngSwitchCase="states.Disabled" class="u-flex-row __content u-color-muted" [class.u-w100p]="inList">
        <ng-container *ngTemplateOutlet="uncheckedIcon"></ng-container>
        <ng-container *ngIf="readonlyTemplate; else defaultTemplate" [ngTemplateOutlet]="readonlyTemplate"></ng-container>
    </div>

    <div *ngSwitchCase="states.Blocked" class="u-flex-row __content" [class.u-w100p]="inList">
        <app-svg [name]="svg.Blocked" width="24" height="24" class="__icon u-margin-right-half u-inline-flex-row"></app-svg>
        <ng-container *ngIf="blockedTemplate; else defaultTemplate" [ngTemplateOutlet]="blockedTemplate"></ng-container>
    </div>

    <div
        *ngSwitchCase="states.Warning"
        class="u-flex-row __content u-color-{{ disabled || muted ? 'muted' : 'danger' }}"
        [class.u-w100p]="inList"
    >
        <app-svg [name]="svg.Alert" width="24" height="24" class="__icon u-margin-right-half u-inline-flex-row"></app-svg>
        <ng-container *ngIf="warningTemplate; else defaultTemplate" [ngTemplateOutlet]="warningTemplate"></ng-container>
    </div>

    <div *ngSwitchCase="states.Processing" class="u-flex-row __content" [class.u-w100p]="inList">
        <app-svg
            [name]="svg.CheckCircle"
            width="24"
            height="24"
            class="__icon u-margin-right-half u-inline-flex-row u-color-muted"
        ></app-svg>
        <ng-container *ngIf="processingTemplate; else defaultTemplate" [ngTemplateOutlet]="processingTemplate"></ng-container>
    </div>

    <div *ngSwitchDefault class="u-flex-row __content u-color-muted" [class.u-w100p]="inList">
        <ng-container *ngTemplateOutlet="uncheckedIcon"></ng-container>
        <ng-container *ngIf="uncheckedTemplate; else defaultTemplate" [ngTemplateOutlet]="uncheckedTemplate"></ng-container>
    </div>
</div>

<ng-template #defaultTemplate>
    <app-todo-template></app-todo-template>
</ng-template>

<ng-template #uncheckedIcon>
    <app-svg
        [name]="svg.Empty"
        width="24"
        class="__icon u-margin-right-half u-inline-flex-row"
        [class.u-color-success]="checked"
        height="24"
    ></app-svg>
</ng-template>
