import { Navigation } from '@app/navigation/enums/navigation.enum';
import { SmoovedAnalyticsConfirmationsContainer } from '@app/real-estate-agent/containers/smooved-analytics-confirmations/smooved-analytics-confirmations.container';
import { SmoovedAnalyticsInputsContainer } from '@app/real-estate-agent/containers/smooved-analytics-inputs/smooved-analytics-inputs.container';
import { SmoovedAnalyticsOverviewContainerComponent } from '@app/real-estate-agent/containers/smooved-analytics-overview/smooved-analytics-overview.container';
import { Route } from "@angular/router";

export const analyticsRouting: Route = {
    path: Navigation.Analytics,
    children: [
        {
            path: Navigation.Overview,
            component: SmoovedAnalyticsOverviewContainerComponent,
        },
        {
            path: Navigation.Inputs,
            component: SmoovedAnalyticsInputsContainer,
        },
        {
            path: Navigation.Confirmations,
            component: SmoovedAnalyticsConfirmationsContainer,
        },
        {
            path: '**',
            pathMatch: 'full',
            redirectTo: Navigation.Overview,
        },
    ],
};
