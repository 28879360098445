import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { WaterInfoResponse } from '../interfaces/water-info-response';

@Injectable({
    providedIn: 'root',
})
export class WaterService {
    public static readonly baseUri: string = `${environment.apiUri}/water`;

    constructor(private httpClient: HttpClient) {}

    public getWaterInfoByZipCode(zipCode: string): Observable<WaterInfoResponse[]> {
        const httpParams: HttpParams = new HttpParams().set('zipCode', zipCode);

        return this.httpClient.get<WaterInfoResponse[]>(WaterService.baseUri, {
            params: httpParams,
        });
    }

    public getWaterSuppliers(): Observable<WaterSupplier[]> {
        return this.httpClient.get<WaterSupplier[]>(`${WaterService.baseUri}/water-suppliers`);
    }
}
