import { Injectable } from '@angular/core';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox } from '@smooved/ui';
import { I18nKey } from '../constants/feature-scope.constants';
import { FeatureScopeProvidersModule } from '../feauture-scope-providers.module';

@Injectable({
    providedIn: FeatureScopeProvidersModule,
})
export class FeatureScopeModalService {
    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}

    public activate(featureScopeLabel: string): void {
        const config = {
            data: this.translateService.instant(I18nKey.ActivateConfirmation, { featureScope: featureScopeLabel }) as string,
        };

        this.modalSandbox.openConfirmModal(
            config,
            this.activateConfirmationHandler(featureScopeLabel),
            config,
            this.activateConfirmationHandler(featureScopeLabel)
        );
    }

    public deactivate(featureScopeLabel: string): void {
        const config = {
            data: this.translateService.instant(I18nKey.DeactivateConfirmation, {
                featureScope: featureScopeLabel,
            }) as string,
        };

        this.modalSandbox.openConfirmModal(
            config,
            this.deactivateConfirmationHandler(featureScopeLabel),
            config,
            this.deactivateConfirmationHandler(featureScopeLabel)
        );
    }

    private activateConfirmationHandler(label: string) {
        return (confirm: boolean): void => {
            if (confirm) {
                this.realEstateGroupSandbox.sendActivationMail(label);
            }
        };
    }

    private deactivateConfirmationHandler(label: string) {
        return (confirm: boolean): void => {
            if (confirm) {
                this.realEstateGroupSandbox.sendDeactivateMail(label);
            }
        };
    }
}
