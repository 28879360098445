<ng-container>
    <app-icon
        appTooltip
        [icon]="icon"
        [size]="iconSize.Md"
        [template]="verifiedTooltip"
        [templateOutletContext]="{ $implicit: isVerified }"
        [appearance]="appearance"
        [context]="context"
    ></app-icon>
    <ng-template #verifiedTooltip let-verified>
        <div class="__verified-tooltip u-color-muted" *ngIf="verified; else unverified">
            <app-icon
                [icon]="icon"
                [size]="iconSize.Xl"
                [appearance]="iconAppearance.Filled"
                [context]="checkSmoovedSource() ? uiContext.Success : uiContext.Warning"
                aria-hidden="true"
                tabindex="-1"
            ></app-icon>
            <div *ngIf="checkSmoovedSource(); else externalSource">
                <p class="u-font-size-small u-margin-top-xs">{{ 'VERIFIED_REVIEW' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</p>
            </div>
            <ng-template #externalSource>
                <p
                    class="u-font-size-small u-margin-top-xs"
                    [innerHtml]="'EXTERNAL_REVIEW' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate"
                ></p>
                <p class="u-font-size-xs u-margin-top-half">
                    {{ 'UNVERIFIED_EXTERNAL_REVIEW' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate : { name: locationOrGroupName } }}
                </p>
            </ng-template>
        </div>
        <ng-template #unverified>
            <div class="u-color-muted">
                <app-icon
                    [icon]="icon"
                    [size]="iconSize.Xl"
                    [appearance]="iconAppearance.Outlined"
                    [context]="uiContext.Muted"
                    aria-hidden="true"
                    tabindex="-1"
                ></app-icon>
                <p class="u-font-size-small u-margin-top-xs">{{ 'SMOOVED_REVIEW' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</p>
                <p class="u-font-size-xs u-margin-top-half">{{ 'UNVERIFIED_REVIEW' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</p>
            </div>
        </ng-template>
    </ng-template>
</ng-container>
