import { Component, Input } from '@angular/core';
import { UiHeaderVariant } from '../../../ui.enums';
import { CardContext } from '../../cards.constants';

@Component({
    selector: 'app-context-card',
    templateUrl: 'context-card.component.html',
    styleUrls: ['context-card.component.scss'],
})
export class ContextCardComponent {
    @Input() public context = CardContext.Default;
    @Input() public header: string;
    @Input() public sub: string;
    @Input() public variant: UiHeaderVariant;
    @Input() public mainClass: string;
    @Input() public cardClass: string;
    @Input() public stretchOnMobile: boolean;

    @Input() public border: boolean;

    public cardContext = CardContext;
}
