import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrayUtils, DbUtils, MatchSearchResult } from '@smooved/core';
import { finalize } from 'rxjs/operators';
import { NotificationSandbox } from '../../../notification';
import { MakeAppointmentFormComponent } from '../../components/make-appointment-form/make-appointment-form.component';
import { FormControlNames } from '../../components/make-appointment-form/make-appointment-form.constants';
import { companyPageI18nKeyTypes } from '../../constants';
import { SmoovedApplicationSource } from '../../enums';
import { RealEstateGroupAppointment } from '../../interfaces';
import { RealEstateGroupAppointmentService } from '../../services/real-estate-group-appointment.service';
import { MakeAppointmentData } from './make-appointment.interfaces';

@Component({
    selector: 'app-make-appointment-modal',
    templateUrl: './make-appointment.modal.html',
})
export class MakeAppointmentModal implements OnInit {
    @ViewChild(MakeAppointmentFormComponent, { static: true }) public makeAppointmentFormComponent: MakeAppointmentFormComponent;

    public loading = false;
    public realEstateGroups: string[] = [];

    public readonly companyPageI18nKeyTypes = companyPageI18nKeyTypes;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MakeAppointmentData,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly realEstateGroupAppointmentService: RealEstateGroupAppointmentService,
        private readonly dialogRef: MatDialogRef<any>
    ) {}

    public ngOnInit(): void {
        if (this.data.externalInfluencer?.realEstateGroup) {
            this.realEstateGroups = [DbUtils.getStringId(this.data.externalInfluencer.realEstateGroup)];
        }

        if (this.data.selectedBrokers) {
            this.data.selectedBrokers.map((item: MatchSearchResult) => {
                this.realEstateGroups.push(item.id);
            });
        }

        if (this.data.data) {
            this.makeAppointmentFormComponent.form.patchValue(this.data.data, { emitEvent: false });
        }

        if (this.data.prefill) {
            this.makeAppointmentFormComponent.form.get(FormControlNames.Message).patchValue(this.data.prefill);
        }
    }

    public onSubmit(realEstateGroupAppointment: RealEstateGroupAppointment): void {
        this.loading = true;
        const source = this.data.source ?? (ArrayUtils.isEmpty(this.data.selectedBrokers) ? null : SmoovedApplicationSource.MapPage);
        this.realEstateGroupAppointmentService
            .makeAppointment(realEstateGroupAppointment, source)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((_) => {
                this.notificationSandbox.sendSuccess();
                this.dialogRef.close({
                    source,
                    realEstateGroupAppointment,
                });
            });
    }
}
