import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import { IconModule } from '../icon/icon.module';
import { PipeModule } from '../pipes';
import { SvgModule } from '../svg';
import { TodoListCtaDirective } from './todo-list/todo-list-cta.directive';
import { TodoListComponent } from './todo-list/todo-list.component';
import { CheckedComponent } from './todo/checked/checked.component';
import { TodoStateCheckedDirective } from './todo/todo-state/todo-state-checked.directive';
import { TodoStateDisabledDirective } from './todo/todo-state/todo-state-disabled.directive';
import { TodoStateProcessingDirective } from './todo/todo-state/todo-state-processing.directive';
import { TodoStateReadonlyDirective } from './todo/todo-state/todo-state-readonly.directive';
import { TodoStateUncheckedDirective } from './todo/todo-state/todo-state-unchecked.directive';
import { TodoStateWarningDirective } from './todo/todo-state/todo-state-warning.directive';
import { TodoTemplateLabelDirective } from './todo/todo-template/todo-template-label.directive';
import { TodoTemplateComponent } from './todo/todo-template/todo-template.component';
import { TodoComponent } from './todo/todo.component';

export const components = [
    TodoComponent,
    TodoTemplateComponent,
    TodoStateCheckedDirective,
    TodoStateUncheckedDirective,
    TodoStateReadonlyDirective,
    TodoStateDisabledDirective,
    TodoStateProcessingDirective,
    TodoStateWarningDirective,
    TodoListComponent,
    TodoListCtaDirective,
    TodoTemplateLabelDirective,
    CheckedComponent,
];

@NgModule({
    imports: [CommonModule, SvgModule, PipeModule, IconModule, ButtonModule, TranslateModule],
    declarations: [...components],
    exports: [...components],
})
export class TrackAndTraceModule {}
