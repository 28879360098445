import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EnergySuggestion } from '../interfaces/energy-suggestion';
import {
    getGroupedEnergySuggestions,
    getGroupedEnergySuggestionsByConsumptionProfile,
    getGroupedEnergySuggestionsFailed,
    getGroupedEnergySuggestionsSuccess,
} from './energy.actions';

export interface EnergyState {
    energySuggestions: {
        groupedSuggestions: any[];
        suggestions: EnergySuggestion[];
        loading: boolean;
    };
}

export const initialState: EnergyState = {
    energySuggestions: {
        groupedSuggestions: undefined,
        suggestions: undefined,
        loading: false,
    },
};

const reducer = createReducer(
    initialState,
    on(getGroupedEnergySuggestions, (state) => ({
        ...state,
        energySuggestions: {
            ...state.energySuggestions,
            loading: true,
        },
    })),
    on(getGroupedEnergySuggestionsByConsumptionProfile, (state) => ({
        ...state,
        energySuggestions: {
            ...state.energySuggestions,
            loading: true,
        },
    })),
    on(getGroupedEnergySuggestionsSuccess, (state, { groupedEnergySuggestions }) => ({
        ...state,
        energySuggestions: {
            ...state.energySuggestions,
            groupedSuggestions: groupedEnergySuggestions,
            loading: false,
        },
    })),
    on(getGroupedEnergySuggestionsFailed, (state) => ({
        ...state,
        energySuggestions: {
            ...state.energySuggestions,
            loading: false,
        },
    }))
);

export function energyReducer(state: EnergyState | undefined, action: Action) {
    return reducer(state, action);
}

export const getEnergyState = createFeatureSelector<EnergyState>('energy');

export const getEnergySuggestionsState = createSelector(
    getEnergyState,
    (state) => state.energySuggestions && state.energySuggestions.suggestions
);

export const getGroupedEnergySuggestionsState = createSelector(
    getEnergyState,
    (state) => state.energySuggestions && state.energySuggestions.groupedSuggestions
);

export const getEnergySuggestionsLoadingState = createSelector(
    getEnergyState,
    (state) => state.energySuggestions && state.energySuggestions.loading
);
