import { createAction, props } from '@ngrx/store';
import { Provider } from '../../wizard/telecom/telecom.interfaces';
import { TelecomSuggestion } from '../interfaces/telecom-suggestion';
import { TelecomSuggestionsFilter } from '../interfaces/telecom-suggestions-filter';
import { TelecomSuggestionsFilterOptions } from '../interfaces/telecom-suggestions-filter-options';

export const getTelecomSuggestions = createAction('[Telecom] Get telecom suggestions');
export const getTelecomSuggestionsSuccess = createAction(
    '[Telecom] Get telecom suggestions success',
    props<{ telecomSuggestions: TelecomSuggestion[] }>()
);
export const getTelecomSuggestionsFailed = createAction('[Telecom] Get telecom suggestions failed');
export const setTelecomSuggestionsFilter = createAction(
    '[Telecom] Set telecom suggestions filter',
    props<{ telecomSuggestionsFilter: TelecomSuggestionsFilter }>()
);
export const clearTelecomSuggestions = createAction('[Telecom] Clear telecom suggestions');
export const getTelecomSuggestionsFilterOptions = createAction(
    '[Telecom] Get telecom suggestions filter options',
    props<{ providers: Provider[]; callback?: (telecomSuggestionsFilterOptions: TelecomSuggestionsFilterOptions) => void }>()
);
export const getTelecomSuggestionsFilterOptionsSuccess = createAction(
    '[Telecom] Get telecom suggestions filter options success',
    props<{
        telecomSuggestionsFilterOptions: TelecomSuggestionsFilterOptions;
        callback?: (telecomSuggestionsFilterOptions: TelecomSuggestionsFilterOptions) => void;
    }>()
);
export const getTelecomSuggestionsFilterOptionsFailed = createAction('[Telecom] Get telecom suggestions filter options failed');
