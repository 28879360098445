<div class="u-flex-column u-h100p u-flex-align-items-start __container">
    <div class="__image-container u-margin-bottom-half u-flex-row u-flex-align-items-center u-flex-justify-content-center">
        <img
            *ngIf="groupedSuggestion[termFormControl().value]?.image as image"
            [src]="'assets/images/suppliers/' + image"
            [alt]="'Logo' + groupedSuggestion[termFormControl().value]?.supplier"
        />
    </div>
    <div class="u-flex-column u-font-size-sm">
        <span
            [ngClass]="{
                'u-color-muted u-text-line-through': !!groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions
            }"
        >
            {{ groupedSuggestion[termFormControl().value]?.totalPrice | price | perYear }}
        </span>
        <span *ngIf="groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions">
            {{ groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions | price | perYear }}
        </span>
        <span class="u-color-muted u-font-size-xs"
            >({{
                (!!groupedSuggestion[termFormControl().value]?.professional
                    ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL'
                    : 'MOVE.ENERGY.SUGGESTION.VAT_INCL'
                ) | translate
            }})</span
        >
        <span *ngIf="label" class="u-font-weight-semi-bold u-font-size-xs u-margin-top-half">{{ label }}</span>
    </div>
</div>
