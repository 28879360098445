import { Pipe, PipeTransform } from '@angular/core';
import { TranslationLabels } from '@app/db/interfaces/translation-labels.interface';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from '@smooved/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Pipe({
    name: 'translatedLabel',
})
export class TranslatedLabelPipe implements PipeTransform {
    transform(value: TranslationLabels, args = []): Observable<string> {
        if (!value) {
            // try fallback
            const fallback = args['fallback'];
            const fallbackObject = fallback?.object;
            const fallbackLabel = fallback?.label;
            if (!fallbackLabel || !fallbackObject || !fallbackObject[fallbackLabel]) {
                return of(null);
            }
            return of(fallbackObject[fallbackLabel]);
        }

        return this.translationSandbox.selected$.pipe(
            map((selectedLanguage) => {
                return (
                    value[selectedLanguage] || value[this.translateService.currentLang] || value[this.translationSandbox.defaultLanguage]
                );
            }),
            mergeMap(this.setRealEstateGroupName)
        );
    }

    constructor(
        private translateService: TranslateService,
        private translationSandbox: TranslationSandbox,
        private moveSandbox: MoveSandbox
    ) {}

    private setRealEstateGroupName = (translatedLabel: string): Observable<string> => {
        return this.moveSandbox.move$.pipe(
            map((move) =>
                StringUtils.parse(translatedLabel, {
                    broker: move.realEstateGroup?.name || this.translateService.instant('SURVEYS.INTERVIEW.YOUR_BROKER'),
                })
            )
        );
    };
}
