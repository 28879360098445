import { AfterContentInit, Component, ContentChild, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { takeUntil, tap } from 'rxjs/operators';
import { UiHeaderVariant } from '../../../ui.enums';
import { TodoComponent } from '../todo.component';
import { TodoTemplateLabelDirective } from './todo-template-label.directive';

@Component({
    selector: 'app-todo-template',
    templateUrl: './todo-template.component.html',
    styleUrls: ['./todo-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TodoTemplateComponent extends RxjsComponent implements OnInit, OnChanges, AfterContentInit {
    @ContentChild(TodoTemplateLabelDirective) public readonly labelRef: TodoTemplateLabelDirective;
    @Input() public label: string;
    @Input() public variant: UiHeaderVariant;

    public labelElement: string;

    constructor(@Optional() public readonly todo: TodoComponent) {
        super();
    }

    public ngOnInit(): void {
        this.todo.labelChanged$
            .pipe(
                tap(() => this.setLabel()),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    public ngOnChanges({ label, variant }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(label, variant)) {
            this.setLabel();
        }
    }

    public ngAfterContentInit(): void {
        this.setLabel();
    }

    public setLabel = (): void => {
        if (this.labelRef) {
            delete this.labelElement;
            return;
        }
        const label = this.label || this.todo.label || '';
        const variant = this.variant || this.todo.variant || UiHeaderVariant.Span;
        const classes = 'u-display-inline-block u-margin-right';
        this.labelElement = label ? `<${variant} class="${classes}">${label}</${variant}>` : '';
    };
}
