<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <smvd-ui-text-input
        [label]="'NAME' | translate"
        [formControlName]="formControlNames.Name"
        class="u-display-block"
        width="100%"
    ></smvd-ui-text-input>
    <smvd-ui-email-input
        [label]="'COMMON.EMAIL' | translate"
        [formControlName]="formControlNames.Email"
        class="u-display-block"
        width="100%"
    ></smvd-ui-email-input>
    <div class="u-grid u-grid-col-2">
        <smvd-ui-phone-input
            [label]="'COMMON.PHONE_FIXED_LINE' | translate"
            [formControlName]="formControlNames.FixedLine"
            width="100%"
        ></smvd-ui-phone-input>
        <smvd-ui-phone-input
            [label]="'COMMON.PHONE' | translate"
            [formControlName]="formControlNames.PhoneNumber"
            width="100%"
        ></smvd-ui-phone-input>
    </div>
    <smvd-ui-text-input
        [label]="'COMMON.BIV_CODE' | translate"
        [formControlName]="formControlNames.BivCode"
        [uiMask]="bivCodeMask"
        [keepMask]="true"
        class="u-display-block"
    ></smvd-ui-text-input>
    <smvd-ui-text-input
        [label]="'COMMON.BIV_URL' | translate"
        [formControlName]="formControlNames.BivUrl"
        class="u-display-block"
        width="100%"
    ></smvd-ui-text-input>
    <button smvd-ui-button type="submit" class="u-margin-top-double">{{(data ? 'COMMON.SAVE' : 'COMMON.ADD') | translate }}</button>
</form>
