import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorScale } from '@smooved/ui';

@Component({
    selector: 'app-invoicing-type-cell',
    template: `
        <div class="u-flex-row">
            <div [style.background-color]="colorScale[order - 1]" class="__dot u-margin"></div>
            <div class="u-flex-column u-flex-justify-content-center">
                <h6>{{ title }}</h6>
                <span *ngIf="!!label" class="u-color-muted u-font-size-small">{{ label }}</span>
            </div>
        </div>
    `,
    styleUrls: ['./invoicing-type-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicingTypeCellComponent {
    @Input() public title: string;
    @Input() public label: string;
    @Input() public order = 1;

    public colorScale = Object.values(ColorScale);
}
