import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { translator } from '../../../../smooved-match/src/i18n/server';
import { Language, MetaGroupService } from '@smooved/core';
import { BaseMetaService } from './meta.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MetaZipCodePageService extends BaseMetaService {
    constructor(
        protected readonly meta: Meta,
        protected readonly title: Title,
        protected readonly translateService: TranslateService,
        @Inject(DOCUMENT) protected document: Document,
        rendererFactory: RendererFactory2,
        private readonly metaGroupService: MetaGroupService
    ) {
        super(meta, title, document, rendererFactory);
    }

    public addAlternateLanguageLinks(externalInfluencerBaseUri: string, slug: string): void {
        this.cleanUpOldElements('link[rel="alternate"]');
        Object.values(Language).forEach((lang) => {
            const href = `${externalInfluencerBaseUri}/${lang}/${translator.translateRoute(lang, 'comparison')}/${slug}`;
            this.addAlternateLangLink(lang, href);
        });
        const href = `${externalInfluencerBaseUri}/${Language.EN}/${translator.translateRoute(Language.EN, 'comparison')}/${slug}`;
        this.addAlternateLangLink('x-default', href);
    }

    public addMetaData(slug: string, externalInfluencerBaseUri: string, language: Language): void {
        const metaData = this.buildMeta(slug, externalInfluencerBaseUri, language);
        this.addMetaTags(metaData);
    }

    protected buildMeta = (
        slug: string,
        externalInfluencerBaseUri: string,
        language: Language
    ): {
        [key: string]: string;
    } => {
        const completeUrl = `${externalInfluencerBaseUri}/${language}/${translator.translateRoute(language, 'comparison')}/${slug}`;

        const metaProps: object = {
            'og:locale': language,
            'og:type': 'website',
            ...(slug ? { 'og:site_name': completeUrl, 'og:url': completeUrl, 'twitter:url': completeUrl } : {}),
        };

        return {
            ...metaProps,
        };
    };
}
