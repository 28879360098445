<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <mat-form-field floatLabel="never" [appearance]="appearance">
        <input
            [id]="id"
            matInput
            #input
            [(ngModel)]="innerModel"
            (ngModelChange)="onModelChange()"
            [disabled]="innerDisabled"
            [placeholder]="placeholder"
            [errorStateMatcher]="errorStateMatcher"
            (blur)="onBlur($event)"
        />
        <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
    </mat-form-field>
</app-label-container>
