import { CdkDragEnd, Point } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NumberUtils } from '@smooved/core';
import { UiContext } from '../../ui.enums';
import { swipeIncreaseDistance } from './slider-bottom-sheet.constants';

@Component({
    selector: 'smvd-ui-slider-bottom-sheet',
    templateUrl: 'slider-bottom-sheet.component.html',
    styleUrls: ['slider-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderBottomSheetComponent implements OnInit {
    @Input() public boundaries = { top: 0, bottom: 0 };
    @Input() public topdropContext = UiContext.Primary;
    @Input() public canSnap = true;

    public dragPosition: Point;
    public animationDisabled = false;
    public readonly uiContext = UiContext;
    public isClosed = true;

    public ngOnInit(): void {
        this.setDragPosition(-this.boundaries.bottom);
    }

    private setDragPosition(y: number): void {
        this.isClosed = y === -this.boundaries.bottom;
        this.dragPosition = { x: 0, y };
    }

    public onDragEnd(event: CdkDragEnd): void {
        if (this.canSnap) {
            const bounds = event.source._dragRef['_boundaryRect'];
            const pointerPos = event.source.getFreeDragPosition().y;

            /**
             * increase the pointer position depending on state
             * This will result in a more logical snap position when swiping.
             */
            const swipeHelper = this.isClosed ? -swipeIncreaseDistance : swipeIncreaseDistance;
            const snapToY = NumberUtils.closest(pointerPos + swipeHelper, -bounds.height - this.boundaries.bottom, -this.boundaries.bottom);
            this.setDragPosition(snapToY);
        }
    }
}
