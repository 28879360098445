import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnknownIfEmptyDirective } from './unknown.directive';
import { FeatureToggleDirective } from './feature-toggle.directive';

const declarations = [FeatureToggleDirective, UnknownIfEmptyDirective];

@NgModule({
    declarations: declarations,
    exports: declarations,
    imports: [CommonModule],
})
export class DirectiveModule {}
