<app-closable-modal-template maxWidth="none" class="u-padding-none">
    <ng-container modal-header>
        <ng-content select="[modal-header]"></ng-content>
    </ng-container>
    <app-loading-container [loading]="loading">
        <main class="__main __tri-panel-modal__main u-h100p">
            <section class="__left u-bordered-right u-flex u-flex-column u-flex-justify-content-space-between">
                <div class="__left-body">
                    <ng-content select="[modal-left]"></ng-content>
                </div>
                <div class="__left-actions u-margin">
                    <ng-content select="[modal-left-actions]"></ng-content>
                </div>
            </section>
            <section class="__center u-bordered-right">
                <ng-content select="[modal-center]"></ng-content>
            </section>
            <section class="__right">
                <div class="u-container-semi-small u-margin-0-auto u-flex-column">
                    <ng-content select="[modal-right]"></ng-content>
                </div>
            </section>
        </main>
    </app-loading-container>
</app-closable-modal-template>
