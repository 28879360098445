import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { HttpStatusCode, HttpUtils } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Exception } from '../constants/exceptions.constants';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';
import { Router } from '@angular/router';

@Injectable()
export class HttpInvalidAuthTokenInterceptor implements HttpInterceptor {
    public isRefreshingToken = false;
    public tokenSubject = new BehaviorSubject<string>(null);

    constructor(
        private readonly notificationSandbox: NotificationSandbox,
        private readonly storageSandbox: StorageSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly router: Router
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.useAuthenticationToken(request)).pipe(
            catchError((errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
                if (this.router.url.includes('companion')) {
                    return next.handle(request);
                }
                if (errorResponse instanceof HttpErrorResponse && errorResponse.status === HttpStatusCode.Unauthorized) {
                    const errorCode = HttpUtils.getErrorCode(errorResponse);

                    if (errorCode.message === Exception.NoAuthToken) {
                        return this.handleInvalidAuthTokenError(request, next);
                    }
                }
                return throwError(errorResponse);
            })
        );
    }

    private useAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const token: string = this.storageSandbox.getToken(LocalStorageKeys.Token);
        if (token) {
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            request = HttpUtils.setHeaders(request, headers);
        }
        request = request.clone({
            withCredentials: true,
        });
        return request;
    }

    private handleInvalidAuthTokenError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authenticationSandbox.refreshToken().pipe(
                switchMap((token): Observable<HttpEvent<any>> => {
                    if (token) {
                        this.tokenSubject.next(token.access_token);
                        return next.handle(this.useAuthenticationToken(request));
                    }
                    // If we don't get a new token, we are in trouble so throw error to logout.
                    return throwError({ status: HttpStatusCode.Unauthorized });
                }),
                catchError((errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
                    // If we receive another Unauthorized, user needs to login again
                    if (errorResponse.status === HttpStatusCode.Unauthorized) {
                        this.notificationSandbox.error('ERROR.401.LOGOUT');
                        this.authenticationSandbox.logout();
                    }
                    // if other error, repeat error logic
                    return throwError(errorResponse);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter((token) => !!token),
                take(1),
                switchMap(() => {
                    return next.handle(this.useAuthenticationToken(request));
                })
            );
        }
    }
}
