import { createAction, props } from '@ngrx/store';
import { CustomMessageTemplate } from '../interfaces/custom-message-template';

export enum CommunicationActionTypes {
    GetTemplates = '[Communication] get templates',
    LoadTemplates = '[Communication] load templates',
    ClearTemplates = '[Communication] clear templates',
    GetTemplatesSuccess = '[Communication] get templates success',
    GetTemplatesFailure = '[Communication] get templates failure',
}

export type GetTemplates = { moveId: string };
export type LoadTemplates = { moveId: string };

export const getCustomMessageTemplates = createAction(CommunicationActionTypes.GetTemplates, props<GetTemplates>());
export const loadCustomMessageTemplates = createAction(CommunicationActionTypes.LoadTemplates, props<LoadTemplates>());
export const clearCustomMessageTemplates = createAction(CommunicationActionTypes.ClearTemplates);
export const getCustomMessageTemplatesSuccess = createAction(
    CommunicationActionTypes.GetTemplatesSuccess,
    props<{ templates: CustomMessageTemplate[] }>()
);
export const getCustomMessageTemplatesFailure = createAction(CommunicationActionTypes.GetTemplatesFailure);
