import { Injectable } from '@angular/core';
import { defaultLevel } from '@app/analytics/constants/analytics.constants';
import { CreatedByFlowBucket } from '@app/move/enums/created-by-flow-bucket.enum';
import { MoveCountByCreatedFlow } from '@app/move/interfaces/move-count-by-created-flow';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { Interval } from '@app/shared/enums/interval.enum';
import { CreatedByFlowEnum, quarterYearIntervalFormat } from '@smooved/core';
import { ChartItem, StackedBarChartUtils } from '@smooved/ui';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { excludeCurrentQuarter, last3Q, mapLabelToSubLabel, mapTypeToLabel, otsTypes } from './confirmatons-long-period-chart.constants';

@Injectable()
export class ConfirmationsLongPeriodChartService {
    private activeCreatedByFlow$ = new BehaviorSubject<CreatedByFlowBucket>(CreatedByFlowBucket.Ots);

    constructor(private smoovedAnalyticsSandbox: SmoovedAnalyticsSandbox) {}

    public mapToChart = map((items: MoveCountByCreatedFlow[]): ChartItem[] => {
        const data = this.prepareData(items);
        return this.sortItems(
            this.mapLabels(
                StackedBarChartUtils.prepareData(
                    data,
                    (item: any) => item.interval,
                    (item: any) => item.type
                )
            )
        );
    });

    public setCreatedByFlow(flow: CreatedByFlowBucket): void {
        this.activeCreatedByFlow$.next(flow);
    }

    public getData(): Observable<ChartItem[]> {
        return combineLatest([
            this.smoovedAnalyticsSandbox.getConfirmationsAnalytics(Interval.Quarter, defaultLevel, last3Q, excludeCurrentQuarter),
            this.activeCreatedByFlow$,
        ]).pipe(
            map(([data, _]) => data),
            this.mapToChart
        );
    }

    private mapLabels(items: ChartItem[]): ChartItem[] {
        return items.map((item) => {
            return {
                ...item,
                stacks: {
                    ...Object.entries(item.stacks).reduce((stack, [key, item]) => {
                        const label = mapTypeToLabel[item.label] as string;
                        return { ...stack, [key]: { ...item, label, sub: mapLabelToSubLabel[label] } };
                    }, {}),
                },
            };
        });
    }

    private sortItems(items: ChartItem[]): ChartItem[] {
        return items.sort((first: ChartItem, second: ChartItem) =>
            moment(first.label, quarterYearIntervalFormat).diff(moment(second.label, quarterYearIntervalFormat))
        );
    }

    private mapCreatedByFlow(flow: CreatedByFlowEnum): CreatedByFlowBucket {
        return otsTypes.includes(flow) ? CreatedByFlowBucket.Ots : CreatedByFlowBucket.Lp;
    }

    private prepareData(items: MoveCountByCreatedFlow[]): MoveCountByCreatedFlow[] {
        return items.filter((item) => this.mapCreatedByFlow(item.createdByFlow) === this.activeCreatedByFlow$.value);
    }
}
