const vatRegex = /^(BE)((0|1)?\d{9})$/;

export class VatNumberUtils {
    public static validate(vatNumber: string): { isValid: boolean; value: string } {
        if (!vatNumber)
            return {
                isValid: false,
                value: null,
            };
        const { isValid, value } = VatNumberUtils.check(vatNumber);
        return {
            isValid,
            value,
        };
    }

    /**
     * Currently, only Belgium VAT numbers are allowed
     * @param vatNumber
     * @private
     */
    public static check(vatNumber: string) {
        if (!vatNumber) return VatNumberUtils.makeResult(vatNumber, false);
        const cleanVAT = VatNumberUtils.removeExtraChars(vatNumber);
        const isValid = VatNumberUtils.isVatValid(cleanVAT);
        return VatNumberUtils.makeResult(cleanVAT, isValid);
    }

    private static isVatValidToRegexp(vat: string) {
        return vatRegex.test(vat) ? { isValid: true, regex: vatRegex } : { isValid: false, regex: undefined };
    }

    private static isVatValidFunction(vatNumber: string): boolean {
        const newVat = vatNumber.length === 9 ? '0' + vatNumber : vatNumber;
        const check = 97 - (Number(newVat.slice(0, 8)) % 97);
        return check === Number(newVat.slice(8, 10));
    }

    private static isVatValid(vatNumber: string): boolean {
        const regexpValidRes = VatNumberUtils.isVatValidToRegexp(vatNumber);
        if (!regexpValidRes.isValid || !regexpValidRes.regex) return false;
        const regexResult = regexpValidRes.regex.exec(vatNumber);
        if (!regexResult) return false;
        return VatNumberUtils.isVatValidFunction(regexResult[2]);
    }

    private static makeResult(vatNumber: string, isValid: boolean) {
        return {
            value: vatNumber || undefined,
            isValid: Boolean(isValid),
            country: {
                name: 'Belgium',
                isoCode: {
                    short: 'BE',
                    long: 'BEL',
                    numeric: '056',
                },
            },
        };
    }

    private static removeExtraChars(value: string): string {
        return value
            .toString()
            .toUpperCase()
            .replace(/(\s|-|\.|\/)+/g, '');
    }
}
