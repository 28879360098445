import { Injectable } from '@angular/core';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { select, Store } from '@ngrx/store';
import { PaginationRequest } from '@smooved/core';
import { getMoves } from '../state/real-estate-agent-dashboard.actions';
import { getCount, getLoading, getMovesState, RealEstateAgentDashboardState } from '../state/real-estate-agent-dashboard.reducer';

@Injectable({
    providedIn: 'root',
})
export class RealEstateAgentDashboardSandbox {
    public moves$ = this.store$.pipe(select(getMovesState));
    public count$ = this.store$.pipe(select(getCount));
    public loading$ = this.store$.pipe(select(getLoading));

    constructor(private store$: Store<RealEstateAgentDashboardState>) {}

    public getMoves(pagination: PaginationRequest, realEstateAgentFilter: RealEstateAgentFilter): void {
        this.store$.dispatch(getMoves({ pagination, realEstateAgentFilter }));
    }
}
