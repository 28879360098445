import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '@app/card/card.module';
import { FormModule } from '@app/form/form.module';
import { GiftInputComponent } from '@app/gift/components/gift-input/gift-input.component';
import { GiftTrackAndTraceComponent } from '@app/gift/components/gift-track-and-trace/gift-track-and-trace.component';
import { SendGiftBoxComponent } from '@app/gift/components/send-gift-box/send-gift-box.component';
import { SendGiftModal } from '@app/gift/modals/send-gift/send-gift.modal';
import { ModalModule } from '@app/modal/modal.module';
import { MoveWizardModule } from '@app/move-wizard/move-wizard.module';
import { OrderModule } from '@app/order/order.module';
import { PartnerModule } from '@app/partner/partner.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslationModule } from '@app/translation/translation.module';
import { CheckItemModule } from '@app/ui/check-item/check-item.module';
import { IconModule, TrackAndTraceModule } from '@smooved/ui';

@NgModule({
    declarations: [GiftInputComponent, SendGiftBoxComponent, GiftTrackAndTraceComponent, SendGiftModal],
    imports: [
        CommonModule,
        FormModule,
        CardModule,
        IconModule,
        ModalModule,
        TranslationModule,
        MoveWizardModule,
        PartnerModule,
        CheckItemModule,
        TrackAndTraceModule,
        OrderModule,
        SharedModule,
    ],
    exports: [GiftInputComponent, SendGiftBoxComponent, GiftTrackAndTraceComponent],
})
export class GiftModule {}
