import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { eotsAbsoluteRoute } from '@app/eots/constants/eots.constants';
import { EotsSandbox } from '@app/eots/sandboxes/eots.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { BaseDashboardCardComponent } from '@smooved/ui';

@Component({
    selector: 'app-energy',
    template: `
        <app-dashboard-card
            [first]="true"
            class="u-h100p"
            height="100%"
            [title]="'REAL_ESTATE_AGENT.START.ENERGY.TITLE' | translate"
            [text]="'REAL_ESTATE_AGENT.START.ENERGY.TEXT' | translate"
            [hasBorderBottom]="true"
            imageName="smooved-energy-bulb.png"
            [last]="true"
        >
            <ng-container up-to-and-including-phone-landscape>
                <div class="u-flex-column u-flex-align-items-stretch">
                    <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-bottom-half' }"></ng-container>
                </div>
            </ng-container>
            <ng-container tablet-portrait-up>
                <div class="u-flex-row u-flex-align-items-center">
                    <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-right' }"></ng-container>
                </div>
            </ng-container>
        </app-dashboard-card>

        <ng-template #actions let-class="class">
            <app-button theme="secondary" [class]="class" [routerLink]="eotsRoute">{{
                'REAL_ESTATE_AGENT.START.ENERGY.NEW_CONTRACT' | translate
            }}</app-button>
            <app-button theme="secondary-outline" (onClick)="onNewContractVacancyClick()">{{
                'REAL_ESTATE_AGENT.START.ENERGY.NEW_CONTRACT_VACANCY' | translate
            }}</app-button>
        </ng-template>
    `,
})
export class EnergyComponent extends BaseDashboardCardComponent {
    public eotsRoute = eotsAbsoluteRoute;

    constructor(private router: Router, private moveSandbox: MoveSandbox, private eotsSandbox: EotsSandbox) {
        super();
    }

    public onNewContractVacancyClick(): void {
        this.moveSandbox.patchProperty('energyOffer.vacancy', true, false, () => {
            this.eotsSandbox.goToFirst();
        });
    }
}
