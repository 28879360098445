import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';

@Component({
    selector: 'app-contact-details-container',
    template: `
        <app-contact-details
            (previous)="goToPrevious()"
            (next)="submit()"
            stepEnd="3"
            [showPassportNumber]="!!(moveSandbox.telecomOfferSelection$ | async)"
            [showNationalNumber]="!!(moveSandbox.energyOfferSelection$ | async)?.brussels"
        ></app-contact-details>
    `,
    styles: [],
})
export class ContactDetailsContainer {
    constructor(public moveSandbox: MoveSandbox, private route: ActivatedRoute, private router: Router) {}

    public goToPrevious(): void {
        this.router
            .navigate(['../move-details'], {
                relativeTo: this.route,
            })
            .then();
    }

    public submit(): void {
        this.router
            .navigate(['../payment-details'], {
                relativeTo: this.route,
            })
            .then();
    }
}
