import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ExternalInfluencer, noDecimals, ReviewScoreScale, SimpleChangesUtils } from '@smooved/core';
import { ReviewsUtils } from '../../../reviews/reviews.utils';
import { UiSize } from '../../../ui.enums';
import { ArcScoreData } from '../../../widget-influencer-score';
import { CompanyPageI18nKeyType } from '../../constants';

@Component({
    selector: 'ui-header-box-widget',
    templateUrl: './header-box-widget.component.html',
    styleUrls: ['./header-box-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBoxWidgetComponent implements OnChanges {
    @Input() public externalInfluencer: ExternalInfluencer;
    @Input() public cropped: boolean;

    @HostBinding('class.__minified')
    @Input()
    public minified: boolean;

    public scoreScale: ReviewScoreScale;
    public data: ArcScoreData;
    public readonly noDecimals = noDecimals;
    public readonly i18nKeyType = CompanyPageI18nKeyType;
    public readonly uiSize = UiSize;

    public ngOnChanges({ externalInfluencer }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(externalInfluencer)) {
            this.setExternalInfluencer(externalInfluencer.currentValue);
        }
    }

    private setExternalInfluencer(influencer: ExternalInfluencer): void {
        this.data = ReviewsUtils.mapArcScore(influencer.reviews?.overall);
        this.scoreScale = ReviewsUtils.mapScoreScale(influencer.reviews?.overall?.score);
    }
}
