import { createAction, props } from '@ngrx/store';
import { RealEstateAgency } from '@smooved/core';

export enum ActionKey {
    GetLocations = '[Real estate group] get locations',
    GetLocationsSuccess = '[Real estate group] get locations success',
    GetLocationsFailure = '[Real estate group] get locations failure',
}

export const getLocations = createAction(ActionKey.GetLocations, props<{ realEstateGroupId: string }>());
export const getLocationsSuccess = createAction(
    ActionKey.GetLocationsSuccess,
    props<{
        data: RealEstateAgency[];
    }>()
);

export const getLocationsFailure = createAction(ActionKey.GetLocationsFailure);
