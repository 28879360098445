import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';

@Component({
    selector: 'app-mobile-agent-form-modal',
    template: `
        <app-mobile-modal>
            <div mobile-modal-header>
                <span>{{ (data?.invite ? 'REAL_ESTATE_AGENT.OFFICE.INVITE' : 'REAL_ESTATE_AGENT.OFFICE.ADD_AGENT') | translate }}</span>
            </div>
            <div mobile-modal-main>
                <div class="u-margin">
                    <app-agent-form
                        [realEstateGroup]="data.realEstateGroup"
                        [submitLabel]="(data?.invite ? 'REAL_ESTATE_AGENT.OFFICE.INVITE' : 'ADD') | translate"
                    ></app-agent-form>
                </div>
            </div>
        </app-mobile-modal>
    `,
})
export class MobileAgentFormModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { realEstateGroup: RealEstateGroup; invite: boolean }) {}
}
