import { AfterViewInit, Component, forwardRef, Host, Input, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GiftSandbox } from '@app/gift/sandboxes/gift.sandbox';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { Move } from '@app/move/interfaces/move';
import { SimpleChangesUtils } from '@smooved/core';
import { BaseInput, ModalSandbox, PricePipe } from '@smooved/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { imagesUri } from '../../constants/uri.constants';

interface GiftOption {
    _id: string;
    name: string;
    images: string[];
    price: number;
    descriptionShort?: string;
    descriptionLong?: string;
    enabled?: boolean;
}

@Component({
    selector: 'app-gift-input',
    template: `
        <app-label-container [required]="true" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="giftOptions$ | async"
                [placeholder]="'INPUT.DROPDOWN_PLACEHOLDER' | translate"
                searchKeyword="name"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                name="gift"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [ngClass]="{ 'u-disabled': !item.enabled }" [innerHTML]="item.name"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
        <div *ngIf="selectedOption" class="u-bordered u-padding u-flex-column u-flex-align-items-center">
            <p *ngIf="!selectedOption.enabled" class="u-color-error">{{ 'MOVE.GIFT.SEND_GIFT_MODAL.FORM.GIFT.FEE_TO_LOW' | translate }}</p>
            <p *ngIf="selectedOption.descriptionShort" class="u-color-muted" [innerHTML]="selectedOption.descriptionShort"></p>
            <a (click)="openMoreInfo(selectedOption.descriptionLong)" class="u-link">{{ 'COMMON.MORE_INFO' | translate }}</a>
        </div>
    `,
    styleUrls: ['./gift-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GiftInputComponent),
            multi: true,
        },
    ],
})
export class GiftInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public move: Move;

    public width = '450px';

    public innerModel: string | GiftOption = '';
    public selectedOption: GiftOption = null;

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    public imagesUri = imagesUri;

    private moveSubject: BehaviorSubject<Move> = new BehaviorSubject<Move>(null);
    private giftOptionsSubject: BehaviorSubject<GiftOption[]> = new BehaviorSubject<GiftOption[]>([]);
    public giftOptions$: Observable<GiftOption[]> = combineLatest(
        this.moveSubject.asObservable(),
        this.giftOptionsSubject.asObservable()
    ).pipe(
        map(([move, giftOptions]) => {
            return giftOptions.map((option) => ({
                ...option,
                enabled: true, // For now always enabled. This will be changed when changes are made for commissions
            }));
        })
    );

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly giftSandbox: GiftSandbox,
        private readonly translationSandbox: TranslationSandbox,
        private readonly pricePipe: PricePipe,
        private readonly modalSandbox: ModalSandbox
    ) {
        super(controlContainer);
    }

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.moveSubject.next(this.move);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.loadingSubject.next(true);
        this.giftSandbox
            .get()
            .pipe(withLatestFrom(this.translationSandbox.selectedOnce$))
            .subscribe(
                ([response, translation]) => {
                    this.giftOptionsSubject.next(
                        response.map((gift) => ({
                            _id: gift._id,
                            name: `${gift.nameLabels[translation]} (${this.pricePipe.transform(gift.price)})`,
                            images: gift.images,
                            price: gift.price,
                            descriptionShort: gift.descriptionShortLabels[translation],
                            descriptionLong: gift.descriptionLongLabels[translation],
                        }))
                    );
                },
                (_) => {},
                () => this.loadingSubject.next(false)
            );
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(realEstateGroup: GiftOption): void {}

    public inputClear(e) {
        setTimeout(() => {
            this.selectedOption = null;
            this.propagateChange(null);
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.selectedOption = this.innerModel as GiftOption;
            this.propagateChange(this.innerModel);
        }, 0);
    }

    public openMoreInfo(text: string): void {
        this.modalSandbox.showTextModal(text);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
