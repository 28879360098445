import { ChangeDetectionStrategy, Component } from '@angular/core';
import { eotsAbsoluteRoute } from '@app/eots/constants/eots.constants';
import { EotsSandbox } from '@app/eots/sandboxes/eots.sandbox';
import { MainContainerTemplateService } from '@app/main-container-template/main-container-template.service';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ButtonAppearance, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-real-estate-agent-navigation',
    template: `
        <ng-container *ngIf="{ open: templateService.secondaryNavigationOpen$ | async } as navState">
            <button
                app-button
                [context]="uiContext.Secondary"
                [appearance]="navState.open ? buttonAppearance.Default : buttonAppearance.FlatIcon"
                [matMenuTriggerFor]="menu"
                icon="add"
                class="__trigger"
            >
                <span *ngIf="navState.open">{{ 'REAL_ESTATE_AGENT.DASHBOARD.NAVIGATION.LABEL' | translate }}</span>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="registerMoveRoute">
                    {{ 'REAL_ESTATE_AGENT.DASHBOARD.NAVIGATION.LEAD_PASSING.LABEL' | translate }}
                </button>
                <button mat-menu-item [routerLink]="eotsRoute">
                    {{ 'REAL_ESTATE_AGENT.DASHBOARD.NAVIGATION.EOTS.LABEL' | translate }}
                </button>
                <button mat-menu-item (click)="onNewContractVacancyClick()">
                    {{ 'REAL_ESTATE_AGENT.DASHBOARD.NAVIGATION.VACANCY.LABEL' | translate }}
                </button>
                <button mat-menu-item *ngIf="realEstateGroupSandbox.serviceIots$ | async" [routerLink]="'/ots/iots'">
                    {{ 'REAL_ESTATE_AGENT.START.IOTS.NEW' | translate }}
                </button>
            </mat-menu>
        </ng-container>
    `,
    styleUrls: ['./real-estate-agent-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateAgentNavigationComponent {
    public readonly eotsRoute = eotsAbsoluteRoute;
    public readonly registerMoveRoute = `/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentRegisterMove}`;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;

    constructor(
        private readonly eotsSandbox: EotsSandbox,
        public readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly moveSandbox: MoveSandbox,
        public readonly templateService: MainContainerTemplateService
    ) {}

    public onNewContractVacancyClick(): void {
        this.moveSandbox.patchProperty('energyOffer.vacancy', true, false, () => {
            this.eotsSandbox.goToFirst();
        });
    }
}
