import { Injectable } from '@angular/core';
import { AccessLevel, NpsReview } from '@smooved/core';
import { Observable } from 'rxjs';
import { ReviewsProvidersModule } from '../reviews-providers.module';
import { ReviewsService } from '../services/reviews.service';

@Injectable({
    providedIn: ReviewsProvidersModule,
})
export class ReviewsSandbox {
    constructor(private readonly reviewsService: ReviewsService) {}

    public addOrUpdateNote(reviewId: string, note: string, accessLevel: AccessLevel, noteId?: string): Observable<NpsReview> {
        if (noteId) {
            return this.reviewsService.editNote(reviewId, noteId, note, accessLevel);
        }
        return this.reviewsService.addNote(reviewId, note, accessLevel);
    }

    public deleteNote(reviewId: string, noteId: string): Observable<NpsReview> {
        return this.reviewsService.deleteNote(reviewId, noteId);
    }
}
