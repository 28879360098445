<header class="u-margin-bottom-md">
    <div class="__header">
        <h2 class="__header__title u-font-size-md">{{ 'TITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentiment }}</h2>
        <div
            class="__header__beta u-line-height-1 u-color-white u-flex-row u-flex-align-items-center u-background-info-dark u-border-radius-large u-padding-x-axis-half u-padding-y-axis-quarter"
            appTooltip
            [trigger]="tooltipTrigger.Click"
            [template]="betaTooltipContent"
            [tooltipClasses]="'u-padding'"
        >
            <span class="u-margin-right-half u-font-weight-semi-bold">{{
                'TITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentBeta
            }}</span>
            <app-icon icon="info"></app-icon>
        </div>
    </div>
    <p class="u-color-muted">{{ 'SUBTITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentiment }}</p>
</header>
<div
    *ngFor="let item of topicAnalysisResults | topicAnalysisResults"
    class="js-topic-analysis-results-item u-margin-bottom-half u-border-radius"
>
    <ng-template
        [collapsible]="{
            template: topicAnalysisResult,
            context: { topicAnalysis: item, location: location }
        }"
        (opened)="categoryOpened(item)"
        (closed)="categoryClosed(item)"
        [initialCollapsed]="collapsibleId !== item.category"
    >
        <div [id]="item.category" class="__item-collapsible u-background-gray-ultralight u-padding u-flex-row u-border-radius-large">
            <div class="u-w100p __item">
                <h3 class="__item__category u-font-weight-semi-bold">
                    {{ item.category | uppercase | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentCategories }}
                </h3>
                <app-topic-analysis-sentiment
                    [topicAnalysisSentiment]="{
                        sentimentPositive: item.sentimentPositive,
                        sentimentNegative: item.sentimentNegative,
                        sentimentMax: maxValue
                    }"
                ></app-topic-analysis-sentiment>
            </div>
        </div>
    </ng-template>
</div>

<div
    *ngFor="let item of topicAnalysisResults | topicAnalysisNoResults"
    class="js-topic-analysis-no-results-item u-background-gray-ultralight u-margin-bottom-half u-border-radius"
>
    <div class="u-padding u-w100p __item">
        <h3 class="__item__category __item__category--disabled u-color-gray u-font-weight-semi-bold">
            {{ item.category | uppercase | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentCategories }}
        </h3>
        <app-topic-analysis-sentiment
            [topicAnalysisSentiment]="{
                sentimentPositive: item.sentimentPositive,
                sentimentNegative: item.sentimentNegative,
                sentimentMax: maxValue
            }"
        ></app-topic-analysis-sentiment>
    </div>
</div>
<ng-template #topicAnalysisResult let-topicAnalysis="topicAnalysis" let-location="location">
    <div class="u-background-gray-ultralight __top-analysis-result-wrapper">
        <div class="u-background-white u-padding">
            <app-topic-analysis-results-reviews [topicAnalysis]="topicAnalysis" [location]="location"></app-topic-analysis-results-reviews>
        </div>
    </div>
</ng-template>

<ng-template #betaTooltipContent>
    <div style="width: 660px; max-width: calc(100vw - 2rem)">
        <div class="u-flex-row u-flex-align-items-center u-margin-bottom">
            <div
                class="__header__beta __tooltip u-margin-right u-line-height-1 u-color-white u-background-info-dark u-border-radius-large u-padding-x-axis-half u-padding-y-axis-quarter"
            >
                <span class="u-font-weight-semi-bold">{{ 'TITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentBeta }}</span>
            </div>
            <h3 class="u-font-size-md">{{ 'TOOLTIP.TITLE' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentBeta }}</h3>
        </div>
        <p class="u-text-align-left" [innerHTML]="'TOOLTIP.CONTENT' | i18nKeyTranslate : appI18nKeyType.ReviewTopicSentimentBeta"></p>
    </div>
</ng-template>
