import { Injectable } from '@angular/core';
import { ArcScoreData } from '@app/external-influencer/interfaces/arc-score-data';
import { MovesByRealEstateAgentPerWeek } from '@app/move/interfaces/move-by-real-estate-agent-per-week-response.interface';
import { MoveCountByCreatedFlow } from '@app/move/interfaces/move-count-by-created-flow';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupState } from '@app/real-estate-group/state/real-estate-group.reducer';
import { Interval } from '@app/shared/enums/interval.enum';
import { PaginationRequest, RealEstateLevel } from '@smooved/core';
import { ReviewBucketRequest, ReviewsDataResponse, ReviewsFilter, ReviewsUtils } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { defaultInterval, defaultLevel } from './constants/analytics.constants';
import { MoveCountByOtsConfirmationClusterLevel } from './interfaces/move-count-by-ots-confirmation-cluster-level';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsSandbox {
    constructor(private readonly analyticsService: AnalyticsService, private readonly realEstateGroupSandbox: RealEstateGroupSandbox) {}

    public getWeeklyMoveCounts(from: Date, until: Date): Observable<MovesByRealEstateAgentPerWeek[]> {
        return this.realEstateGroupSandbox.realEstateGroup$.pipe(
            take(1),
            switchMap((reg: RealEstateGroupState) => this.analyticsService.getWeeklyMoveCounts(reg, from, until))
        );
    }

    public getConfirmationsAnalytics(
        interval: Interval = defaultInterval,
        level: RealEstateLevel = defaultLevel,
        from?: Date,
        until?: Date
    ): Observable<MoveCountByCreatedFlow[]> {
        return this.analyticsService.getConfirmationsAnalytics(interval, level, from, until);
    }

    public getOtsConfirmationClusterLevel(
        interval: Interval,
        level: RealEstateLevel,
        from?: Date,
        until?: Date
    ): Observable<MoveCountByOtsConfirmationClusterLevel[]> {
        return this.analyticsService.getOtsConfirmationClusterLevel(interval, level, from, until);
    }

    public npsReviews(reviewFilter: ReviewsFilter, paginationRequest?: PaginationRequest): Observable<ReviewsDataResponse> {
        return this.analyticsService.npsReviews(reviewFilter, paginationRequest);
    }

    public getNpsUnreadCount(): Observable<number> {
        return this.analyticsService.getNpsUnreadCount();
    }

    public getReviewBuckets(reviewFilter: ReviewsFilter): Observable<ReviewBucketRequest> {
        return this.analyticsService.getReviewBuckets(reviewFilter);
    }

    public getArcScoreData(): Observable<ArcScoreData> {
        return this.analyticsService.getNpsSummaryData().pipe(
            map((data) => ({
                overall: data,
                arcScore: ReviewsUtils.mapArcScore(data),
                scoreScale: ReviewsUtils.mapScoreScale(data.score),
                score: data.score,
            }))
        );
    }
}
