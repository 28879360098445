import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AdminContactLogService } from '@app/admin/services/admin-contact-log.service';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { I18nKeyType } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { Move } from '../../../move/interfaces/move';

@Component({
    selector: 'app-admin-todo-leaver-suppliers-received',
    templateUrl: './todo-leaver-suppliers-received.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverSuppliersReceivedComponent extends BaseServiceActionComponent {
    @Input() public move: Move;

    public readonly i18nKeys = { ...I18nKeyType, ...AppI18nKeyType };
    public readonly moverRole = MoverRole;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;

    constructor(
        private readonly adminModalService: AdminModalService,
        private readonly adminContactLogService: AdminContactLogService,
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public editSuppliers(): void {
        this.adminModalService.openUpdateLeaverSuppliersModal(this.move, (move) => this.updateMove(move));
    }

    public resendInvite(): void {
        this.adminContactLogService.resendLeaverCollectionInvite(this.move).subscribe();
    }
}
