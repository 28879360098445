<h6 class="u-color-muted u-margin-bottom-half">{{ 'REVIEWS.REAL_ESTATE_AGENT' | translate }}</h6>
<ui-searchable-dropdown-input
    [style.width]="'auto'"
    [formControl]="control"
    [hasMarginDouble]="false"
    [hasMargin]="false"
    [placeholder]="'UI.AGENTS.SEARCH' | translate"
    [options]="agentOptions"
    [otherOptions]="agentOptionsOthers"
    [otherOptionsGroupName]="'UI.OTHER'"
    [includeOtherOption]="false"
    [includeAllOption]="true"
    [allOptionLabel]="'UI.AGENTS.ALL'"
    (onChange)="realEstateAgentSelected($event)"
></ui-searchable-dropdown-input>
