import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enums';
import { TranslationLabels } from '../interfaces';
import { ObjectUtils } from './object-utils';
import { StringUtils } from './string.utils';

export class TranslateUtils {
    public static getAvailableLanguage(labels: Partial<TranslationLabels>, language: Language, defaultLanguage?: Language): string {
        return labels[language] || labels[defaultLanguage] || labels[Language.NL] || labels[Language.FR] || labels[Language.EN];
    }

    public static translate(path: string, translations: object, interpolateParams?: object): string {
        const translation = ObjectUtils.get(translations, path) as string;
        return StringUtils.parse(translation, interpolateParams);
    }

    public static getTranslateLanguage(translate: TranslateService): string {
        return translate.currentLang || translate.defaultLang;
    }
}
