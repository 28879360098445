<h6 class="u-color-muted">{{ title }}</h6>
<ng-container *ngIf="!edit; else editTemplate">
    <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center">
        <span>{{ value || innerControl?.value || emptyValue }}</span>
        <button app-button [appearance]="buttonAppearance.Link" (click)="showEdit()">{{ 'EDIT' | translate }}</button>
    </div>
</ng-container>

<ng-template #editTemplate>
    <div>
        <ng-content></ng-content>
        <div class="u-flex-row u-flex-align-items-center">
            <button
                app-button
                [appearance]="buttonAppearance.Icon"
                [iconContext]="uiContext.Success"
                icon="check"
                (click)="save.emit()"
                class="u-margin-top-none"
            ></button>
            <button
                app-button
                [appearance]="buttonAppearance.Icon"
                [iconContext]="uiContext.Muted"
                icon="close"
                (click)="hideEdit()"
                class="u-margin-top-none"
            ></button>
        </div>
    </div>
</ng-template>
