import { Pipe, PipeTransform } from '@angular/core';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { ArrayUtils } from '@smooved/core';

const sortFunction = (a: TopicAnalysis, b: TopicAnalysis) => {
    const sumA = a.sentimentPositive + a.sentimentNegative;
    const sumB = b.sentimentPositive + b.sentimentNegative;
    return sumB - sumA;
};

const filterFunction = (result: TopicAnalysis) => {
    return result?.sentimentPositive || result?.sentimentNegative;
};

@Pipe({
    name: 'topicAnalysisResults',
})
export class TopicAnalysisResultsPipe implements PipeTransform {
    transform(results: TopicAnalysis[]): TopicAnalysis[] {
        if (ArrayUtils.isEmpty(results)) return [];
        return results.filter(filterFunction).sort(sortFunction);
    }
}
