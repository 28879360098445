import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { completedTodoStates, serviceInvitedI18nKeys } from './todo-energy-invited.constants';

@Component({
    selector: 'app-todo-energy-invited',
    templateUrl: './todo-energy-invited.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEnergyInvitedComponent implements OnChanges {
    @Input() public move: Move;

    public readonly i18nKeys = serviceInvitedI18nKeys;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public canResend: boolean;

    constructor(
        private moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService,
        private notificationSandbox: NotificationSandbox
    ) {}

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move))
            this.canResend = !completedTodoStates.includes(this.move.track.transferee.energyOfferConfirmed);
    }

    public resendConfirm(): void {
        this.modalSandbox.openConfirmModal({ data: this.translateService.instant(this.i18nKeys.Confirm) }, this.handleConfirmModalClosed);
    }

    private handleConfirmModalClosed = (result): void => {
        if (!result) return;

        this.moveSandbox.sendConfirmationEmail(DbUtils.getStringId(this.move)).subscribe((move) => {
            this.moveSandbox.fetch(DbUtils.getStringId(move));
            this.notificationSandbox.success(this.i18nKeys.Success);
        });
    };
}
