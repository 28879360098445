import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ArrayUtils } from '@smooved/core';
import { take } from 'rxjs/operators';
import { BottomSheetModule } from './bottom-sheet.module';
import { BottomSheetConfig } from './bottom-sheet/bottom-sheet-config.interface';

@Injectable({
    providedIn: BottomSheetModule,
})
export class BottomSheetSandbox {
    constructor(private matBottomSheet: MatBottomSheet) {}

    public open<T, U>(config: BottomSheetConfig<T, U>): MatBottomSheetRef {
        const panelClass = ArrayUtils.clean(
            ArrayUtils.trim([
                ...ArrayUtils.toArray(config.panelClass),
                '__smvd-ui-bottom-sheet',
                config.context ? `ui-bottom-sheet-${config.context}` : null,
            ])
        );
        const ref = this.matBottomSheet.open(config.component, {
            ...config,
            panelClass,
        });

        ref.afterDismissed().pipe(take(1)).subscribe(config.afterClosed);
        return ref;
    }
}
