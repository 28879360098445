import { Action } from '@ngrx/store';

export enum RootActionTypes {
    SetRootState = '[Root] Set root state',
    NoopAction = '[Root] Noop action',
}

export class SetRootState implements Action {
    readonly type = RootActionTypes.SetRootState;

    constructor(public payload: any) {}
}

export class NoopAction implements Action {
    readonly type = RootActionTypes.NoopAction;
}

export type RootActions = SetRootState | NoopAction;
