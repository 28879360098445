import { Action } from '@ngrx/store';
import { AuthenticationActionTypes } from '@app/authentication/state/authentication.actions';

export enum ActivityActionTypes {
    HeartBeat = '[HeartBeat] HeartBeat',
}

export class HeartBeatAction implements Action {
    public readonly type = ActivityActionTypes.HeartBeat;
}
