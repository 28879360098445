export enum InvoicingType {
    EnergyOrdered = 'energy-ordered',
    EnergyToOrder = 'energy-to-order',
    EnergyToOrderBySmooved = 'energy-to-order-by-smooved',
    EnergyCarriedOverToNextQuarter = 'energy-carried-over-to-next-quarter',
    EnergyNotInterested = 'energy-not-interested',

    TelecomOrdered = 'telecom-ordered',
    TelecomToOrder = 'telecom-to-order',
    TelecomToOrderBySmooved = 'telecom-to-order-by-smooved',
    TelecomCarriedOverToNextQuarter = 'telecom-carried-over-to-next-quarter',
    TelecomNotInterested = 'telecomNotInterested',

    InsurancesConfirmed = 'insurances-confirmed',
    InsurancesPending = 'insurances-pending',
    InsurancesLost = 'insurances-lost',

    MortgagesConfirmed = 'mortgages-confirmed',
    MortgagesPending = 'mortgages-pending',

    GiftsOrdered = 'gifts-ordered',

    SubscriptionCosts = 'subscription-costs',
}

export enum RealEstateGroupInvoicingType {
    commission = 0,
    subscription = 1,
}
