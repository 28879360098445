import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TextValidators {
    public static startsWith(value: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.value) return null;
            const valid = (control.value || '').startsWith(value);
            return !valid ? { startsWith: { requiredText: value, ActualText: control.value } } : null;
        };
    }

    public static isBivCode(control: AbstractControl): ValidationErrors {
        if (!control.value) return null;
        const valid = !!(control.value || '').match(/^(\d{3}.\d{3})$/);
        return !valid ? { isBivCode: true } : null;
    }
}
