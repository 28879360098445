import { Injectable } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ObjectUtils } from '@smooved/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { FixedTariff } from '../enums/fixed-tariff.enum';
import { EnergyService } from '../services/energy.service';
import {
    getGroupedEnergySuggestions,
    getGroupedEnergySuggestionsByConsumptionProfile,
    getGroupedEnergySuggestionsFailed,
    getGroupedEnergySuggestionsSuccess,
} from './energy.actions';

@Injectable()
export class EnergyEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly energyService: EnergyService,
        private readonly uiSandbox: AppUiSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {}

    getGroupedSuggestionsByConsumptionProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getGroupedEnergySuggestionsByConsumptionProfile),
            mergeMap(({ query }) => {
                ObjectUtils.removeEmpty(query);
                return this.energyService.groupedSuggestionsByConsumptionProfile(query).pipe(
                    map((groupedEnergySuggestions) =>
                        getGroupedEnergySuggestionsSuccess({
                            groupedEnergySuggestions,
                        })
                    ),
                    catchError((errorResponse) => of(getGroupedEnergySuggestionsFailed()))
                );
            })
        )
    );

    getGroupedEnergySuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getGroupedEnergySuggestions),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            mergeMap(() => this.moveSandbox.moveOnce$),
            map((move) => {
                const {
                    energyOffer,
                    user,
                    professional,
                    consumptionKnown,
                    gasConsumption,
                    electricitySingleConsumption,
                    electricityDoubleDayConsumption,
                    electricityDoubleNightConsumption,
                    currentEnergySupplier,
                    electricityExclusiveNightConsumption,
                    meta,
                } = move || {};
                const {
                    energyType,
                    meterType,
                    hasExclusiveNightMeter,
                    heatElectricity,
                    hasSolarPanels,
                    convertPowerSolarPanels,
                    vacancy,
                    electricitySingleInjection,
                    electricityDoubleDayInjection,
                    electricityDoubleNightInjection,
                    meterConfiguration,
                    peakCapacity,
                } = energyOffer || {};

                const { familyMemberCount, homeDescription, homeDescriptionSize, homeConstructionYear } = meta || {};

                const baseQuery: any = {
                    energyType,
                    meterType,
                    zipCode: user?.movingAddress?.zipCode,
                    hasExclusiveNightMeter,
                    heatElectricity,
                    hasSolarPanels,
                    convertPowerSolarPanels,
                    vacancy,
                    professional,
                    fixedTariff: FixedTariff.Both,
                };

                if (consumptionKnown) {
                    return {
                        ...baseQuery,
                        energyConsumptionElectricitySingle: electricitySingleConsumption,
                        electricitySingleInjection,
                        energyConsumptionElectricityDoubleDay: electricityDoubleDayConsumption,
                        energyConsumptionElectricityDoubleNight: electricityDoubleNightConsumption,
                        electricityDoubleDayInjection,
                        electricityDoubleNightInjection,
                        energyConsumptionGas: gasConsumption,
                        currentEnergySupplier,
                        energyConsumptionElectricityExclusiveNight: electricityExclusiveNightConsumption,
                        meterConfiguration,
                        peakCapacity,
                    };
                } else {
                    let all = 0;
                    if (familyMemberCount) {
                        for (const prop in familyMemberCount) {
                            if (!familyMemberCount.hasOwnProperty(prop)) {
                                continue;
                            }
                            all += familyMemberCount[prop];
                        }
                    }
                    return {
                        ...baseQuery,
                        familySize: all.toString(),
                        homeDescription,
                        homeDescriptionSize,
                        homeConstructionYear,
                    };
                }
            }),

            mergeMap((query) => {
                ObjectUtils.removeEmpty(query);
                return this.energyService.groupedSuggestions(query).pipe(
                    map((groupedEnergySuggestions) =>
                        getGroupedEnergySuggestionsSuccess({
                            groupedEnergySuggestions,
                        })
                    ),
                    catchError((errorResponse) => of(getGroupedEnergySuggestionsFailed()))
                );
            })
        )
    );

    getGroupedEnergySuggestionsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getGroupedEnergySuggestionsSuccess),
                tap((_) => this.uiSandbox.hideLoadingOverlay())
            ),
        {
            dispatch: false,
        }
    );

    getGroupedEnergySuggestionsFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getGroupedEnergySuggestionsFailed),
                tap((_) => this.uiSandbox.hideLoadingOverlay())
            ),
        {
            dispatch: false,
        }
    );
}
