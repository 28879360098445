import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-move-remark-detail',
    template: `
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.DETAILS.REMARK' | translate }}</h6>
            <form [formGroup]="form">
                <app-textarea-input
                    formControlName="remark"
                    (ngModelChange)="onRemarkChange($event)"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                ></app-textarea-input>
            </form>
        </div>
        <ng-template #empty>-</ng-template>
    `,
})
export class MoveRemarkDetailComponent implements OnInit {
    @Input() move: Move;

    public form: UntypedFormGroup;

    constructor(private moveSandbox: MoveSandbox, private formBuilder: UntypedFormBuilder) {}

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            remark: [this.move?.remark || null],
        });
    }

    public onRemarkChange($event: string): void {
        this.moveSandbox.patchMove(this.move?._id, { remark: $event }, true);
    }
}
