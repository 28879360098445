import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/interfaces/order';

@Component({
    selector: 'app-check-item-telecom-ordered',
    template: `
        <app-check-item [checked]="true" align="top">
            <ng-container ngProjectAs="checked">
                <p>{{ 'REQUESTED_BY' | translate: { by: order.move.user.formattedName } }}</p>
            </ng-container>
        </app-check-item>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemTelecomOrderedComponent {
    @Input() public order: Order;
}
