export enum DashboardMenu {
    Transferees = 'transferees',
    Leavers = 'leavers',
    AllTodos = 'all-todos',
    EneryNotOrderedTodos = 'energy-not-ordered-todos',
    MetersNotCompletedTodos = 'meters-not-completed-todos',
    WaterNotComletedTodos = 'water-not-completed-todos',
    Gifts = 'gifts',
}

export const defaultDashboardMenu = DashboardMenu.Transferees;
