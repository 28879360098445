import { EscalationCategory } from '@app/escalation/interfaces/category';
import { Action, createReducer, on } from '@ngrx/store';
import { failure, getCategories, init, success } from './category.actions';

export const featureKey = 'escalationCategory';

export interface CategoryState {
    list: EscalationCategory[];
    loading: boolean;
}

export const initialState: CategoryState = {
    list: null,
    loading: false,
};

export const escalationCategoryReducer = (state: CategoryState, action: Action): CategoryState => {
    return createReducer(
        initialState,
        on(getCategories, (state) => ({ ...state, loading: true })),
        on(success, (state, { data }) => ({
            ...state,
            list: data,
            loading: false,
        })),
        on(failure, (state) => ({ ...state, list: null, loading: false })),
        on(init, () => ({ ...initialState }))
    )(state, action);
};
