import { CheckInput, ElectricityForm, ElectricityFormFields } from '@smooved/ui';

export enum AdminElectricityFormFields {
    EanCodeByAdmin = 'eanCodeElectricityByAdmin',
    MeterNumberByAdmin = 'meterNumberElectricityByAdmin',
};

export interface AdminElectricityForm extends ElectricityForm {
    [AdminElectricityFormFields.EanCodeByAdmin]: string;
    [AdminElectricityFormFields.MeterNumberByAdmin]: string;
}

export const electricityI18n = {
    day: 'METER_READING_DAY',
    night: 'METER_READING_NIGHT',
    automaticReading: 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING',
    automaticReadingSale: 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING_SALE',
    consumptionDay: 'ENERGY.METER_READING.DIGITAL.CONSUMPTION_DAY',
    consumptionNight: 'ENERGY.METER_READING.DIGITAL.CONSUMPTION_NIGHT',
    injectionDay: 'ENERGY.METER_READING.DIGITAL.INJECTION_DAY',
    injectionNight: 'ENERGY.METER_READING.DIGITAL.INJECTION_NIGHT',
    confirmAutomaticReadings: 'ENERGY.METER_READING.DIGITAL.CONFIRM_AUTOMATIC_READING',
};

export const automaticDigitalReadingOptionRental: CheckInput<boolean> = {
    labelResource: electricityI18n.automaticReading,
    id: 'automatic-digital-reading',
    name: 'automatic-digital-reading',
    value: true,
};

export const automaticDigitalReadingOptionSale: CheckInput<boolean> = {
    ...automaticDigitalReadingOptionRental,
    labelResource: electricityI18n.automaticReadingSale,
};

export const fieldContexts = {
    singleMeterReading: { control: ElectricityFormFields.SingleMeterReading, label: electricityI18n.day, noMargins: true },
    doubleDayMeterReading: { control: ElectricityFormFields.DoubleDayMeterReading, label: electricityI18n.day },
    doubleNightMeterReading: { control: ElectricityFormFields.DoubleNightMeterReading, label: electricityI18n.night, noMargins: true },
    consumptionDayMeterReading: { control: ElectricityFormFields.ConsumptionDayMeterReading, label: electricityI18n.consumptionDay },
    consumptionNightMeterReading: { control: ElectricityFormFields.ConsumptionNightMeterReading, label: electricityI18n.consumptionNight },
    injectionDayMeterReading: { control: ElectricityFormFields.InjectionDayMeterReading, label: electricityI18n.injectionDay },
    injectionNightMeterReading: {
        control: ElectricityFormFields.InjectionNightMeterReading,
        label: electricityI18n.injectionNight,
        noMargins: true,
    },
};
