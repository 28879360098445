import { createAction, props } from '@ngrx/store';
import { OtherScores } from '../constants/reviews.constants';
import { NpsScore } from '@app/move/interfaces/nps-score';

export enum ReviewInsightsActionTypes {
    ReviewInsightsGetHouseMatchScore = '[Review insights] Get nps score',
    ReviewInsightsGetHouseMatchScoreSuccess = '[Review insights] Get nps score success',
    getReviewInsightsOtherScores = '[Review insights] Get other nps scores',
    getReviewInsightsOtherScoresSuccess = '[Review insights] Get other nps scores success',
}

export type ReviewInsightsGetHouseMatchScore = { realEstateGroupId: string };
export type ReviewInsightsGetHouseMatchScoreSuccess = { houseMatchScore: NpsScore };

export type ReviewInsightsOtherScores = { realEstateGroupIds: string[] };
export type ReviewInsightsOtherScoresSuccess = { otherScores: OtherScores };

export const getReviewInsightsHouseMatchScoreAction = createAction(
    ReviewInsightsActionTypes.ReviewInsightsGetHouseMatchScore,
    props<ReviewInsightsGetHouseMatchScore>()
);
export const getReviewInsightsHouseMatchScoreSuccess = createAction(
    ReviewInsightsActionTypes.ReviewInsightsGetHouseMatchScoreSuccess,
    props<ReviewInsightsGetHouseMatchScoreSuccess>()
);

export const getReviewInsightsOtherScoresAction = createAction(
    ReviewInsightsActionTypes.getReviewInsightsOtherScores,
    props<ReviewInsightsOtherScores>()
);
export const getReviewInsightsOtherScoresSuccess = createAction(
    ReviewInsightsActionTypes.getReviewInsightsOtherScoresSuccess,
    props<ReviewInsightsOtherScoresSuccess>()
);
