import { CheckInput } from '../check-input/check-input';

export const termsAndConditionsOption: CheckInput<boolean> = {
    id: 'terms-and-conditions',
    name: 'terms-and-conditions',
    value: true,
    labelResource: 'UI.TERMS_AND_CONDITIONS_INPUT.LABEL',
};

export const consentField = 'consent';
