import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { WaterMetersState } from '../../../../../../core/src/lib/enums/meters-state.enum';
import { MeterStatePillBaseComponent } from '../meter-state-pill-base/meter-state-pill-base.component';

@Component({
    selector: 'smvd-ui-water-meter-state-pill',
    templateUrl: '../meter-state-pill-base/meter-state-pill-base.component.html',
    styleUrls: ['../meter-state-pill-base/meter-state-pill-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterMeterStatePillComponent extends MeterStatePillBaseComponent implements OnInit, OnChanges {
    public stateClass: string = `__meter-state--${WaterMetersState.Incomplete}`;

    private readonly defaultMetersStateKey = WaterMetersState[WaterMetersState.Incomplete];

    override setAppearanceAndState(): void {
        this.stateKey = WaterMetersState[this.meterState]
            ? WaterMetersState[this.meterState]
            : WaterMetersState[WaterMetersState.Incomplete];
        this.stateClass =
            this.todo && this.meterState === WaterMetersState.Incomplete
                ? '__meter-state-todo'
                : `__meter-state--${this.stateKey.toLowerCase()}`;
        this.setSvgIcon();
    }
}
