import { CheckInput } from '@smooved/ui';

export enum TakeOverFormControlNames {
    LeaverFirstName = 'leaverFirstName',
    LeaverLastName = 'leaverLastName',
    RealEstateAgentFirstName = 'realEstateAgentFirstName',
    RealEstateAgentLastName = 'realEstateAgentLastName',
    MeterReadingsTakeover = 'meterReadingsTakeover',
}

export interface TakeOverInformationForm {
    [TakeOverFormControlNames.LeaverFirstName]?: string;
    [TakeOverFormControlNames.LeaverLastName]?: string;
    [TakeOverFormControlNames.RealEstateAgentFirstName]?: string;
    [TakeOverFormControlNames.RealEstateAgentLastName]?: string;
    [TakeOverFormControlNames.MeterReadingsTakeover]: boolean;
}

export const meterReadingsTakeoverOption: CheckInput<boolean> = {
    id: 'meterReadingsTakeover',
    labelResource: 'MOVE.LEGAL.METER_READINGS_TAKEOVER.LABEL',
    value: true,
    name: 'meterReadingsTakeover',
};
