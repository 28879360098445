import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TooltipService {
    private closeAllSubject = new Subject<number>();
    public closeAll$ = this.closeAllSubject.asObservable();

    public closeAll(ignoreUid?: number): void {
        this.closeAllSubject.next(ignoreUid);
    }
}
