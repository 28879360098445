import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';
import { CountryCode } from '../enums/country-code.enum';
import { ArrayUtils } from './array.utils';
import { EnumUtils } from './enum-utils';

const phoneTypeMobile = 'MOBILE';
const defaultCountryCodes: CountryCode[] = EnumUtils.values(CountryCode);

export class PhoneNumberUtils {
    public static parsePhoneNumber(phoneNumber: string, country = CountryCode.Belgium): PhoneNumber {
        if (!phoneNumber) return null;
        return parsePhoneNumber(phoneNumber, country);
    }

    public static isValidPhoneNumber(phoneNumber: string, country: CountryCode | CountryCode[] = defaultCountryCodes): boolean {
        if (!phoneNumber) return false;
        try {
            return ArrayUtils.toArray(country || defaultCountryCodes).some((country) =>
                PhoneNumberUtils.parsePhoneNumber(phoneNumber, country).isValid()
            );
        } catch (e) {
            return false;
        }
    }

    public static isValidMobilePhoneNumber(phoneNumber: string, country: CountryCode | CountryCode[] = defaultCountryCodes): boolean {
        if (!phoneNumber) return false;
        try {
            return ArrayUtils.toArray(country || defaultCountryCodes).some((country) => {
                const parsedPhoneNumber = PhoneNumberUtils.parsePhoneNumber(phoneNumber, country);
                const phoneType = parsedPhoneNumber.getType();
                return parsedPhoneNumber.isValid() && phoneType === phoneTypeMobile;
            });
        } catch (e) {
            return false;
        }
    }
}
