import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MeterReadingsOutputComponent } from '@app/meter-readings/components/meter-readings-output/meter-readings-output.component';
import { ButtonModule, SvgModule } from '@smooved/ui';

const components = [MeterReadingsOutputComponent];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [CommonModule, ButtonModule, SvgModule],
})
export class MeterReadingsModule {}
