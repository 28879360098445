import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { YoutubeModalComponent } from '@app/modal/components/youtube-modal/youtube-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule, SvgModule, UiFormModule, UiModalModule } from '@smooved/ui';
import { FormModule } from '../form/form.module';
import { InfoModalComponent } from './components/info/info.modal';
import { NoteModalComponent } from './components/note-modal/note-modal.component';

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        FormModule,
        SvgModule,
        TranslateModule,
        CommonModule,
        IconModule,
        ButtonModule,
        UiModalModule,
        UiFormModule,
    ],
    declarations: [InfoModalComponent, YoutubeModalComponent, NoteModalComponent],
    exports: [UiModalModule, InfoModalComponent, YoutubeModalComponent, NoteModalComponent],
})
export class ModalModule {}
