<app-closable-modal-template>
    <div modal-header *ngIf="!!header || !!subheader">
        <h6 *ngIf="!!header">{{ header }}</h6>
        <span *ngIf="!!subheader">{{ subheader }}</span>
    </div>
    <p
        class="u-margin-bottom"
        [ngClass]="!!header || !!subheader ? 'u-margin-top' : 'u-margin-top-double'"
        [innerHTML]="question || data | translate"
    ></p>
    <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-margin-top-half">
        <button
            app-button
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Stroked"
            (click)="close()"
            class="u-margin-right-half"
        >
            {{ cancelLabel | translate }}
        </button>
        <button app-button (click)="close(true)">{{ submitLabel | translate }}</button>
    </div>
</app-closable-modal-template>
