import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '@app/card/card.module';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { TranslationModule } from '@app/translation/translation.module';
import { UiModule } from '@app/ui/ui.module';
import { AnalyticsSandbox } from './analytics.sandbox';
import { AnalyticsService } from './analytics.service';
import { ConfirmationsClusterLevelsChartComponent } from './components/confirmations-cluster-levels-chart/confirmations-cluster-levels-chart.component';

@NgModule({
    imports: [CommonModule, UiModule, CardModule, TranslationModule],
    declarations: [ConfirmationsClusterLevelsChartComponent],
    exports: [ConfirmationsClusterLevelsChartComponent],
    providers: [AnalyticsSandbox, AnalyticsService, RealEstateGroupSandbox],
})
export class AnalyticsModule {}
