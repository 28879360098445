import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { InterviewInitiatedByType } from '../enums/interview-initiated-by-type.enum';
import { Interview } from '../interfaces/meta/interview';
import { getInterviewSuccess, setHasExistingSurvey, setInitiatedBy, setPresetScore } from './surveys.actions';

export interface SurveyState {
    interview: Interview;
    hasExistingSurvey: boolean;
    presetScore: number;
    initiatedBy: InterviewInitiatedByType;
}

export const initialState: SurveyState = {
    interview: null,
    hasExistingSurvey: false,
    presetScore: null,
    initiatedBy: null,
};

export const surveyReducer = createReducer(
    initialState,
    on(getInterviewSuccess, (state, { interview }) => ({
        ...state,
        interview,
    })),
    on(setHasExistingSurvey, (state, { hasExistingSurvey }) => ({
        ...state,
        hasExistingSurvey,
    })),
    on(setPresetScore, (state, { score: presetScore }) => ({
        ...state,
        presetScore,
    })),
    on(setInitiatedBy, (state, { initiatedBy }) => ({
        ...state,
        initiatedBy,
    }))
);

export const getSurveyState = createFeatureSelector<SurveyState>('survey');

export const getInterviewState = createSelector(getSurveyState, (surveyState) => surveyState.interview);
