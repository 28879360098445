export enum FormControlNames {
    MovingAddress = 'movingAddress',
    HomeConstructionYear = 'homeConstructionYear',

    PropertyPrice = 'propertyPrice',
    OwnResourcesAmount = 'ownResourcesAmount',
    SoloProperty = 'soloProperty',
    StakeholdersNumber = 'stakeholdersNumber',

    FormulasCount = 'formulasCount',
    AdvisorId = 'advisorId',

    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Language = 'language',
    ImmothekerConsent = 'immothekerConsent',
}
