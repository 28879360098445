import { Pipe, PipeTransform } from '@angular/core';
import { ActionAudit } from '../interfaces';
import { AuditUtils } from '../utils';

@Pipe({
    name: 'auditValue',
})
export class AuditValuePipe implements PipeTransform {
    public transform<T>(value: T | ActionAudit<T>): T {
        return AuditUtils.getValue(value);
    }
}
