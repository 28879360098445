import { Action } from '@ngrx/store';

export enum AgreementsActionTypes {
    CreateAgreementByMoveId = '[Agreements] Create agreement by moveId',
    CreateAgreementByMoveIdSuccess = '[Agreements] Create agreement by moveId success',
    CreateAgreementByMoveIdFailure = '[Agreements] Create agreement by moveId failure',
}

export class CreateAgreementByMoveId implements Action {
    public readonly type = AgreementsActionTypes.CreateAgreementByMoveId;

    constructor(public payload: { moveId: string; sendInvite: boolean; onlyPrint: boolean }) {}
}

export class CreateAgreementByMoveIdSuccess implements Action {
    public readonly type = AgreementsActionTypes.CreateAgreementByMoveIdSuccess;

    constructor(public payload: { url?: string; sendInvite?: boolean }) {}
}

export class CreateAgreementByMoveIdFailure implements Action {
    public readonly type = AgreementsActionTypes.CreateAgreementByMoveIdFailure;
}

export type AgreementActions = CreateAgreementByMoveId | CreateAgreementByMoveIdSuccess | CreateAgreementByMoveIdFailure;
