<div class="u-flex u-flex-align-items-center u-flex-justify-content-space-between">
    <div class="u-flex-row">
        <smvd-ui-review-title-generated-summary
            [source]="source"
            [titles]="{
                generatedSummary: item.generatedSummary,
                transactionTitleAttributes: {
                    houseType: item.houseType,
                    transactionType: item.assessedTo,
                    city: item.city
                }
            }"
        >
        </smvd-ui-review-title-generated-summary>
        <smvd-ui-review-verification-badge
            [locationOrGroupName]="location?.name || item.group"
            [reviewSource]="item.source"
            class="u-margin-left-half u-line-height-normal"
        ></smvd-ui-review-verification-badge>
        <ng-content selector="[item-chip-list]"></ng-content>
    </div>
</div>
<div *ngIf="!templateConfig.hideAuthor" class="u-flex-responsive u-margin-bottom-sm-md">
    <ui-star-score [max]="googleMaxScore" [score]="item.stars" class="u-flex u-margin-right"></ui-star-score>
    <app-nps-review-author [review]="item" class="u-w100p"></app-nps-review-author>
</div>
