<div>
    <button
        *ngIf="showCloseBtn"
        app-button
        [appearance]="buttonAppearance.Icon"
        [iconAppearance]="iconAppearance.Round"
        [icon]="context ? 'close' : 'cancel'"
        [context]="context ? uiContext.Muted : null"
        class="__close-button"
        [class.u-color-white]="context"
        (click)="close()"
    ></button>
    <ng-content></ng-content>
</div>
