import { PackageId } from '@app/package/enums/package-id.enum';
import { RealEstateAgentOffice } from '@app/real-estate-agent/interfaces/real-estate-agent-office';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { ArrayUtils, DbUtils } from '@smooved/core';
import { SearchableDropdownInputOption } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export class RealEstateGroupUtils {
    public static getRealEstateAgentsCount(realEstateGroup: RealEstateGroup): number {
        if (!realEstateGroup) return 0;
        return realEstateGroup.offices?.reduce((previousValue, currentValue) => {
            return previousValue + (currentValue.realEstateAgents?.length || 0);
        }, 0);
    }

    public static getOffices(realEstateGroup: RealEstateGroup): RealEstateAgentOffice[] {
        return ArrayUtils.clean(ArrayUtils.toArray(realEstateGroup?.offices));
    }

    public static officeOptionsFactory(realEstateGroup: Pick<RealEstateGroup, 'offices'>): SearchableDropdownInputOption[] {
        if (ArrayUtils.isEmpty(realEstateGroup?.offices)) return [];
        return realEstateGroup.offices.map((office) => {
            return {
                id: `office-${DbUtils.getStringId(office)}`,
                value: DbUtils.getStringId(office),
                label: office.name,
            };
        });
    }

    public static locationsOptionsFactory(realEstateGroup: Pick<RealEstateGroup, 'locations'>): SearchableDropdownInputOption[] {
        if (ArrayUtils.isEmpty(realEstateGroup?.locations)) return [];
        return realEstateGroup.locations.map((location) => ({
            id: `location-${DbUtils.getStringId(location)}`,
            value: DbUtils.getStringId(location),
            label: location.name,
        }));
    }

    public static hasSmoovedPackage(realEstateGroup$: Observable<RealEstateGroup>, packageId: PackageId): Observable<boolean> {
        return realEstateGroup$.pipe(
            take(1),
            map((realEstateGroup) => realEstateGroup?.package?.value?.key === packageId)
        );
    }
}
