import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AnalyticsEventsEnum } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    public sendEvent(event: AnalyticsEventsEnum, params: {}): void {
        this.isBrowser &&
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.dataLayer.push({
                event,
                ...params,
            });
    }

    public sendEventForRealEstateAgent(
        event: AnalyticsEventsEnum,
        realEstateGroupName: string,
        realEstateAgentId: string,
        params: {}
    ): void {
        this.isBrowser &&
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.dataLayer.push({
                event,
                realEstateGroupName,
                realEstateAgentId,
                ...params,
            });
    }
}
