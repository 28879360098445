import { Injectable } from '@angular/core';
import { AnalyticsSandbox } from '@app/analytics/analytics.sandbox';
import { NpsReview, PaginationRequest, skipWhileNull } from '@smooved/core';
import { DurationFilter, ReviewsDataSource, ReviewsFilter, TimeValue } from '@smooved/ui';
import { BehaviorSubject, noop, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class NpsReviewsDataSource extends ReviewsDataSource {
    private intervalCountsSubject: BehaviorSubject<TimeValue[]> = new BehaviorSubject<TimeValue[]>(null);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public intervalCounts$ = this.intervalCountsSubject.asObservable().pipe(skipWhileNull());
    public loading$ = this.loadingSubject.asObservable();

    constructor(private analyticsSandbox: AnalyticsSandbox) {
        super();
    }

    public load(filter: ReviewsFilter, paginationRequest?: PaginationRequest, extend?: boolean): Observable<void> {
        this.loadingSubject.next(true);
        const customFilter: ReviewsFilter = { ...filter, intervalCounts: [DurationFilter.LatestNinetyDays] };
        return this.analyticsSandbox.npsReviews(customFilter, paginationRequest).pipe(
            map((response) => {
                this.suggestionsSubject.next(extend ? [...this.suggestionsSubject.value, ...response?.data] : response?.data);
                this.suggestionsCountSubject.next(response?.count);
                this.intervalCountsSubject.next(response?.intervals);
            }),
            catchError(() => of(noop())),
            finalize(() => this.loadingSubject.next(false))
        );
    }

    public connect(): Observable<NpsReview[] | ReadonlyArray<NpsReview>> {
        return this.suggestionsSubject.asObservable();
    }

    public disconnect(): void {
        this.suggestionsSubject.complete();
        this.suggestionsCountSubject.complete();
    }
}
