import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StringUtils } from '@smooved/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-stars',
    template: `
        <div class="stars-line" (mouseleave)="readonly ? noop() : onStarsUnhover()">
            <span
                class="star zero-star"
                [ngStyle]="starSize()"
                aria-hidden="true"
                (click)="onZeroStarClick()"
                (mousemove)="readonly ? noop() : onZeroStarHover()"
            ></span>
            <div
                class="u-flex-row u-flex-align-items-center u-flex-justify-content-center"
                *ngFor="let star of editableStars"
                [ngStyle]="starPadding()"
                (click)="readonly ? noop() : onStarClick($event, star)"
                (mousemove)="readonly ? noop() : onStarHover($event, star)"
            >
                <span class="star" [ngClass]="star.classname" [ngStyle]="starColorAndSize()" aria-hidden="true"></span>
            </div>
        </div>
    `,
    styleUrls: ['./stars.component.scss'],
    imports: [CommonModule],
    standalone: true,
})
export class StarsComponent implements OnInit {
    @Input() public initialStars = 0;
    @Input() public maxStars = 5;
    @Input() public readonly: boolean;
    @Input() public size: number;
    @Input() public color: string;
    @Input() public animation: boolean;
    @Input() public animationSpeed = 100;
    @Input() public customPadding: string;
    @Input() public wholeStars = false;
    @Input() public customStarIcons: { empty: string; half: string; full: string };

    @Output() public ratingOutput: EventEmitter<number> = new EventEmitter();

    public rating: number;
    public editableStars: EditableStar[];
    public animationInterval: any;
    public animationRunning: boolean;

    private customCssClasses: HTMLStyleElement[];
    private customClassIdentifier = StringUtils.getRandomString();

    public ngOnInit(): void {
        this.setupStarImages();
        this.editableStars = Array.from(new Array(this.maxStars)).map((elem, index) => new EditableStar(index));
        this.setRating(this.initialStars);

        if (this.animation) {
            this.animationInterval = setInterval(this.starAnimation.bind(this), this.animationSpeed);
        }
    }

    public ngOnDestroy(): void {
        // remove the three custom classes we created if custom image urls were provided
        if (this.customCssClasses) {
            this.customCssClasses.forEach((style) => {
                if (style && style.parentNode) {
                    style.parentNode.removeChild(style);
                }
            });
        }
    }

    private setupStarImages() {
        if (this.customStarIcons) {
            this.customCssClasses = [];
            Object.keys(this.customStarIcons)
                .map((key) => key as StarType)
                .forEach((starType) => {
                    const classname = this.getStarClass(starType);
                    this.createCssClass(classname, starType);
                });
        }
    }

    private createCssClass(classname: string, starType: StarType) {
        const clazz = document.createElement('style');
        clazz.type = 'text/css';
        clazz.innerHTML = `.${classname} {
      -webkit-mask-image: url(${this.customStarIcons[starType]});
      mask-image: url(${this.customStarIcons[starType]});
    }`;
        document.getElementsByTagName('head')[0].appendChild(clazz);
        this.customCssClasses.push(clazz);
    }

    public starPadding(): { [p: string]: string } {
        return { 'margin-right': this.customPadding || `0.${this.safeSize()}rem` };
    }

    public starColorAndSize(): { [p: string]: string } {
        return Object.assign({}, this.starColor(), this.starSize());
    }

    private starColor(): { [p: string]: string } {
        return { 'background-color': this.color || '#f9be02' };
    }

    public starSize(): { [p: string]: string } {
        return {
            height: `${15 * this.safeSize()}px`,
            width: `${16 * this.safeSize()}px`,
        };
    }

    private safeSize = () => (Number.isInteger(this.size) && this.size > 0 && this.size < 6 ? this.size : 1);

    public starAnimation(): void {
        this.animationRunning = true;
        if (this.rating < this.maxStars) {
            this.setRating((this.rating += 0.5));
        } else {
            this.setRating(0);
        }
    }

    public cancelStarAnimation(): void {
        if (this.animationRunning) {
            clearInterval(this.animationInterval);
            this.rating = 0;
            this.animationRunning = false;
        }
    }

    public setRating(rating: number) {
        this.rating = Math.round(rating * 2) / 2;
        this.onStarsUnhover();
    }

    public onStarHover(event: MouseEvent, clickedStar: EditableStar): void {
        this.cancelStarAnimation();

        const clickedInFirstHalf = this.clickedInFirstHalf(event);

        // fill in either a half or whole star depending on where user clicked
        clickedStar.classname = !this.wholeStars && clickedInFirstHalf ? this.getStarClass('half') : this.getStarClass('full');

        // fill in all stars in previous positions and clear all in later ones
        this.editableStars.forEach((star) => {
            if (star.position > clickedStar.position) {
                star.classname = this.getStarClass('empty');
            } else if (star.position < clickedStar.position) {
                star.classname = this.getStarClass('full');
            }
        });
    }

    public onStarClick(event: MouseEvent, clickedStar: EditableStar): void {
        this.cancelStarAnimation();

        // lock in current rating
        const clickedInFirstHalf = this.clickedInFirstHalf(event);
        this.rating = clickedStar.position + (!this.wholeStars && clickedInFirstHalf ? 0.5 : 1);
        this.ratingOutput.emit(this.rating);
    }

    // hidden star to left of first star lets user click there to set to 0
    public onZeroStarClick(): void {
        this.setRating(0);
        this.ratingOutput.emit(this.rating);
    }

    public onZeroStarHover(): void {
        // clear all stars
        this.editableStars.forEach((star) => (star.classname = this.getStarClass('empty')));
    }

    public onStarsUnhover() {
        // when user stops hovering we want to make stars reflect the last rating applied by clicking
        this.editableStars.forEach((star) => {
            const starNumber = star.position + 1;
            if (this.rating >= starNumber) {
                star.classname = this.getStarClass('full');
            } else if (this.rating > starNumber - 1 && this.rating < starNumber) {
                star.classname = this.getStarClass('half');
            } else {
                star.classname = this.getStarClass('empty');
            }
        });
    }

    private clickedInFirstHalf(event: MouseEvent): boolean {
        const starIcon = event.target as HTMLElement;
        return event.pageX < starIcon.getBoundingClientRect().left + starIcon.offsetWidth / 2;
    }

    public noop(): void {}

    private getStarClass(starType: StarType) {
        if (this.customCssClasses) {
            return `ngx-stars-star-${starType}-${this.customClassIdentifier}`;
        }
        return `star-${starType}`;
    }
}

export type StarType = 'empty' | 'half' | 'full';

export class EditableStar {
    position: number;
    classname: string;

    constructor(position: number) {
        this.position = position;
    }
}
