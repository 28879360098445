import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderListOpenTableComponent } from '@app/partner/components/order-list-open-table/order-list-open-table.component';
import { OrderListBaseContainer } from '@app/partner/containers/order-list-base/order-list-base.container';
import { OrderListContainerService } from '@app/partner/services/order-list-container.service';
import { OrderState } from '@smooved/core';

@Component({
    selector: 'app-order-list-open-container',
    template: `
        <app-card-table-with-filter [hasPaddingTabletLandscapeUp]="false" [hasPaddingTabletPortraitUp]="false">
            <ng-container filter>
                <app-text-input
                    [placeholder]="'SEARCH' | translate"
                    ngProjectAs="filter"
                    [formControl]="orderListContainerService.searchControl"
                ></app-text-input>
            </ng-container>

            <ng-container table>
                <app-loading-container [loading]="orderListContainerService.loading$ | async">
                    <app-order-list-open-table
                        [dataSource]="orderListContainerService.orders$ | async"
                        (rowClick)="orderListContainerService.onRowClick($event, handleOrderPatched)"
                        (pageEventChange)="fetch()"
                        (sortChange)="fetch()"
                    ></app-order-list-open-table>
                </app-loading-container>
            </ng-container>
        </app-card-table-with-filter>
    `,
    providers: [OrderListContainerService],
})
export class OrderListOpenContainer extends OrderListBaseContainer {
    @ViewChild(OrderListOpenTableComponent, {
        static: true,
    })
    public tableComponent: OrderListOpenTableComponent;

    protected orderState = OrderState.Open;

    constructor(public readonly orderListContainerService: OrderListContainerService, public readonly activatedRoute: ActivatedRoute) {
        super(orderListContainerService, activatedRoute);
    }
}
