<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <div class="__container" [class.__container--disabled]="innerDisabled">
        <textarea
            [id]="id"
            [rows]="rows"
            matInput
            #input
            [disabled]="innerDisabled"
            [placeholder]="placeholder"
            [errorStateMatcher]="errorStateMatcher"
            autocomplete="smooved"
        >
        </textarea>
    </div>
    <p class="u-margin-top-half u-font-size-small u-color-error" *ngIf="isErrorState()">{{ getFirstErrorState() | errorState }}</p>
</app-label-container>
