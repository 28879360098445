import { commaSeparator } from '../constants';
import { extendedCountry } from '../constants/country';
import { CountryCode } from '../enums';
import { GoogleGeoCoderType } from '../enums/google.enum';
import { Address } from '../interfaces';
import { PlaceResult } from '../interfaces/google';
import { ArrayUtils } from './array.utils';
export class AddressUtils {
    public static buildFormattedAddress(address: Address, separator = commaSeparator, withCountry = false): string {
        let line1 = '';
        let line2 = '';
        let line3 = '';

        if (!address) {
            return '';
        }

        if (address.street) {
            line1 += address.street;
        }

        if (address.houseNumber) {
            line1 += ` ${address.houseNumber}`;
        }

        if (address.busNumber) {
            line1 += ` (${address.busNumber})`;
        }

        if (address.zipCode) {
            line2 += `${address.zipCode}`;
        }

        if (address.city) {
            line2 += ` ${address.city}`;
        }

        if (!!address.country && withCountry) {
            line3 += `${extendedCountry[address.country] || extendedCountry[CountryCode.Belgium]}`;
        }

        return [line1, line2, line3].filter((x) => !!x).join(separator);
    }

    public static buildFormattedStreet(address: Address): string {
        if (!address?.street) return '';
        return ArrayUtils.clean([address.street, address.houseNumber, address.busNumber]).join(' ');
    }

    public static googlePlaceResultToAddress(result: PlaceResult): Address {
        if (!result || !result.address_components) return;

        const address: Address = {
            street: null,
            houseNumber: null,
            busNumber: null,
            zipCode: null,
            city: null,
            country: CountryCode.Belgium,
        };

        const { address_components } = result;
        function getGeoCoderType(type: GoogleGeoCoderType): string {
            return address_components.find((a: google.maps.GeocoderAddressComponent): boolean => a.types.includes(type))?.long_name || null;
        }

        address.street = getGeoCoderType(GoogleGeoCoderType.Route);
        address.houseNumber = getGeoCoderType(GoogleGeoCoderType.StreetNumber);
        address.zipCode = getGeoCoderType(GoogleGeoCoderType.PostalCode);
        address.city = getGeoCoderType(GoogleGeoCoderType.Locality);

        return address;
    }
}
