export enum EmojiIcon {
    Apartment = 'emoji-apartment',
    EuroBankNote = 'emoji-euro-banknote',
    Fire = 'emoji-fire',
    Light = 'emoji-light',
    WomanRaisingHand = 'emoji-woman-raising-hand',
    TwoMenHoldingHands = 'emoji-two-men-holding-hands',
    FamilyManWomanGirl = 'emoji-family-man-woman-girl',
    FamilyManWomanBoyBoy = 'emoji-family-man-woman-boy-boy',
    House = 'emoji-house',
    NewHouse = 'emoji-new-house',
    OldHouse = 'emoji-old-house',
    Trumpet = 'emoji-trumpet',
    SingleMeter = 'emoji-single-meter',
    Sun = 'emoji-sun',
    NightMoon = 'emoji-night-moon',
    WomanDontKnow = 'emoji-woman-dont-know',
    ThumbsUp = 'emoji-thumbs-up',
    Movies = 'emoji-movies',
    Football = 'emoji-football',
    Laptop = 'emoji-laptop',
    Television = 'emoji-television',
    MobilePhone = 'emoji-mobile-phone',
    FixedLine = 'emoji-fixed-line',
    Adult = 'emoji-adult',
    Child = 'emoji-child',
    Teenager = 'emoji-teenager',
    Messaging = 'emoji-messaging',
    Music = 'emoji-music',
    News = 'emoji-news',
    Games = 'emoji-games',
    BankCard = 'emoji-bank-card',
    Closed = 'emoji-closed',
    HalfOpen = 'emoji-half-open',
    ContactAddress = 'emoji-contact-address',
    Insurance = 'emoji-insurance',
    Post = 'emoji-post',
    PostForwarding = 'emoji-post-forwarding',
    Water = 'emoji-water',
    MovingVan = 'emoji-moving-van',
    MovingBox = 'emoji-moving-box',
    Clover = 'emoji-clover',
    Wrench = 'emoji-wrench',
    Stress = 'emoji-stress',
    Nerd = 'emoji-nerd',
    Calendar = 'emoji-calendar',
    Dashboard = 'emoji-dashboard',
    Rocket = 'emoji-rocket',
    Male = 'emoji-male',
    Female = 'emoji-female',
    Art = 'emoji-art',
    EStep = 'emoji-estep',
    Garden = 'emoji-garden',
    GardenHouse = 'emoji-garden-house',
    Holiday = 'emoji-holiday',
    HomeOffice = 'emoji-home-office',
    Ring = 'emoji-ring',
    Sharing = 'emoji-sharing',
    Student = 'emoji-student',
    SwimmingPool = 'emoji-swimming-pool',
    Trex = 'emoji-trex',
    Ufo = 'emoji-ufo',
    Bike = 'emoji-bike',
    Wizard = 'emoji-wizard',
    Checkbox = 'emoji-checkbox',
    Cancel = 'emoji-cancel',
    Writing = 'emoji-writing',
}
