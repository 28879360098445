import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/real-estate-groups`;
export const logoUri = `${baseUri}/logo`;
export const officeUri = `${baseUri}/office`;
export const officesUri = `${baseUri}/offices`;
export const realEstateAgentsUri = `${baseUri}/offices/:officeId/real-estate-agents`;
export const meUri = `${environment.apiUri}/start/me`;
export const idUri = `${baseUri}/:id`;
export const teamUri = `${baseUri}/team`;
export const findTeamUri = `${teamUri}/find`;
export const activateServiceUri = `${baseUri}/services/:service/activate`;
export const deactivateServiceUri = `${baseUri}/services/:service/deactivate`;
export const locationsUri = `${idUri}/locations`;
export const locationByIdUri = `${idUri}/locations/:locationId`;
export const addFeatureScopesUri = `${idUri}/feature-scopes/add`;
export const deleteFeatureScopesUri = `${idUri}/feature-scopes/delete`;
export const includeFeatureScopesUri = `${idUri}/feature-scopes/include`;
export const excludeFeatureScopesUri = `${idUri}/feature-scopes/exclude`;
export const featureScopeConfigUri = `${idUri}/feature-scopes/config`;
export const officeIdUri = `${idUri}/offices/:officeId`;
export const analyticsUri = `${idUri}/analytics`;
export const realEstateGroupBivRealEstateAgentsUri = `${idUri}/biv-agents`;
export const realEstateGroupBivRealEstateAgentUri = `${idUri}/biv-agents/:agentId`;

export const encodedIdUri = `${environment.apiUri}/scripts/real-estate-groups/:id/encoded`;
