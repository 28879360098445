import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ClosableModalTemplateComponent, NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-mail-advance-amount-modal',
    template: `
        <app-closable-modal-template>
            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <h3 class="u-margin-bottom">{{ 'ADMIN.ADVANCE_AMOUNT.TITLE' | translate }}</h3>

                <quill-editor
                    formControlName="content"
                    #editor
                    [modules]="editorModules"
                    class="u-display-block u-margin-bottom"
                ></quill-editor>

                <div class="u-flex-row u-flex-justify-content-center">
                    <app-button type="submit">{{ 'ADMIN.ADVANCE_AMOUNT.CTA' | translate }}</app-button>
                </div>
            </form>
        </app-closable-modal-template>
    `,
    styleUrls: ['./mail-advance-amount.modal.scss'],
})
export class MailAdvanceAmountModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public editor;

    public editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image'],
        ],
    };

    public form: UntypedFormGroup = this.formBuilder.group({
        content: [null, [Validators.required]],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { content: string; moveId: string },
        private notificationSandbox: NotificationSandbox,
        private moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.contentFormControl().patchValue(this.data.content);
    }

    public onSubmit(): void {
        if (this.form.valid) {
            // quill editor changes div tags to p tags. We want to use div tags in the email.
            const content = this.contentFormControl().value.replace('<p>', '<div>').replace('</p>', '</div>');
            this.moveSandbox.sendMailAdvanceAmount(this.data.moveId, content).subscribe((move) => {
                this.notificationSandbox.success('ADMIN.ADVANCE_AMOUNT.SUCCESS');
                this.closeDialog(move);
            });
        }
    }

    private closeDialog(result?: any): void {
        this.closableModalComponent.close(result);
    }

    private contentFormControl(): AbstractControl {
        return this.form?.get('content');
    }
}
