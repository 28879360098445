<ng-container
    *ngIf="{
        sentimentPositiveColorClass: !!topicAnalysisSentiment.sentimentPositive ? 'u-color-gray-dark' : 'u-color-gray-light',
        sentimentNegativeColorClass: !!topicAnalysisSentiment.sentimentNegative ? 'u-color-gray-dark' : 'u-color-gray-light'
    } as vm"
>
    <div class="__container u-font-size-small">
        <span class="__count {{ vm.sentimentPositiveColorClass }}">{{ topicAnalysisSentiment.sentimentPositive }}</span>
        <smvd-ui-svg-illustration
            class="__icon {{ vm.sentimentPositiveColorClass }}"
            [svg]="svgIllustration.ThumbsUp"
        ></smvd-ui-svg-illustration>

        <ui-review-rating-bar
            [rating]="topicAnalysisSentiment.sentimentPositive"
            [total]="topicAnalysisSentiment.sentimentMax"
            [color]="colors.Success"
        ></ui-review-rating-bar>

        <span class="__count {{ vm.sentimentNegativeColorClass }}">{{ topicAnalysisSentiment.sentimentNegative }}</span>
        <smvd-ui-svg-illustration
            class="__icon {{ vm.sentimentNegativeColorClass }}"
            [svg]="svgIllustration.ThumbsDown"
        ></smvd-ui-svg-illustration>

        <ui-review-rating-bar
            [rating]="topicAnalysisSentiment.sentimentNegative"
            [total]="topicAnalysisSentiment.sentimentMax"
            [color]="colors.Danger"
        ></ui-review-rating-bar>
    </div>
</ng-container>
