import { NgModule } from '@angular/core';
import { SearchableDropdownInputComponent } from './searchable-dropdown-input.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { LabelContainerModule } from '../label-container';
import { MatOptionModule } from '@angular/material/core';
import { ErrorStateModule } from '../error-state';
import { OptionDisplayDirective } from './option-display.directive';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [SearchableDropdownInputComponent, OptionDisplayDirective],
    exports: [SearchableDropdownInputComponent, OptionDisplayDirective],
    imports: [
        CommonModule,
        ErrorStateModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatFormFieldModule,
        LabelContainerModule,
        MatOptionModule,
        MatInputModule,
    ],
})
export class SearchableDropdownInputModule {}
