import { EscalationCategory } from '@app/escalation/interfaces/category';
import { createAction, props } from '@ngrx/store';

export enum ActionKeys {
    Init = '[Escalation Categories] init',
    Get = '[Escalation Categories] get categories',
    Success = '[Escalation Categories] success on getting categories',
    Failure = '[Escalation Categories] failure on getting categories',
}

export const init = createAction(ActionKeys.Init);
export const getCategories = createAction(ActionKeys.Get);
export const success = createAction(ActionKeys.Success, props<{ data: EscalationCategory[] }>());
export const failure = createAction(ActionKeys.Failure);
