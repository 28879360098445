import { SsrTranslator } from '../../ssr/classes/ssr-translator';
import { i18n } from '../../ssr/i18n';
import { mergeTranslations, translationsFactory } from '../../ssr/interfaces/translations.interface';
import { default as clientEn } from '../en.json';
import { default as clientFr } from '../fr.json';
import { default as clientNl } from '../nl.json';
import { default as serverEn } from './en.json';
import { default as serverFr } from './fr.json';
import { default as serverNl } from './nl.json';

export const translator = new SsrTranslator(
    translationsFactory(
        mergeTranslations(i18n.en, clientEn, serverEn),
        mergeTranslations(i18n.fr, clientFr, serverFr),
        mergeTranslations(i18n.nl, clientNl, serverNl)
    )
);
