import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NameValidators, PhoneNumberValidators, TermsAndConditionsInputComponent } from '../../../form';
import { RealEstateGroupAppointment } from '../../interfaces';
import { MakeAppointmentFormData } from './make-appointment-form-data.interface';
import { FormControlNames, LabelCommonMessage, LabelMessageMultiSelect, subjectOptions } from './make-appointment-form.constants';

@Component({
    selector: 'ui-make-appointment-form',
    templateUrl: './make-appointment-form.component.html',
    styleUrls: ['./make-appointment-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeAppointmentFormComponent implements OnInit, OnDestroy {
    @ViewChild(TermsAndConditionsInputComponent, { static: true }) termsInput: TermsAndConditionsInputComponent;

    @Input() public realEstateGroups: string[];
    @Input() public messageDescription: string;
    @Input() public isFromMultiSelectFlow = false;
    @Input() public loading = false;
    @Output() public send: EventEmitter<RealEstateGroupAppointment> = new EventEmitter<RealEstateGroupAppointment>();

    @ViewChild(RecaptchaComponent) private readonly recaptcha: RecaptchaComponent;

    public readonly formControlNames = FormControlNames;
    public readonly subjectOptions = subjectOptions;

    public messageLabel = LabelCommonMessage;

    public form = this.formBuilder.group({
        [FormControlNames.FirstName]: [null, [Validators.required, NameValidators.firstName]],
        [FormControlNames.LastName]: [null, [Validators.required, NameValidators.lastName]],
        [FormControlNames.PhoneNumber]: [null, [Validators.required, PhoneNumberValidators.isValidPhoneNumber()]],
        [FormControlNames.Email]: [null, [Validators.required, Validators.email]],
        [FormControlNames.Subject]: [null, [Validators.required]],
        [FormControlNames.Message]: [null, [Validators.required, Validators.maxLength(500)]],
        [FormControlNames.Consent]: [null, Validators.requiredTrue],
        [FormControlNames.Recaptcha]: [null, Validators.required],
    });

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    public ngOnInit(): void {
        if (this.isFromMultiSelectFlow) {
            this.messageLabel = LabelMessageMultiSelect;
        }
    }

    public ngOnDestroy(): void {
        /**
         * bugfix 28/04/2022: Error: Uncaught (in promise): Error: reCAPTCHA client element has been removed: 0
         * Error received when resetting the form in deactivation guard.
         */
        this.recaptcha.reset();
        (this.recaptcha as any).widget = undefined;
    }

    public submit(): void {
        this.form.markAllAsTouched();
        this.form.controls.recaptcha.markAsTouched();
        if (this.form.invalid) return;
        this.send.emit(this.payloadFactory());
    }

    private payloadFactory(): RealEstateGroupAppointment {
        const { firstName, lastName, phoneNumber, email, subject, message, recaptcha: token } = this.form.value as MakeAppointmentFormData;
        return {
            firstName,
            lastName,
            email,
            phoneNumber,
            subject,
            message,
            token,
            recipients: this.realEstateGroups.map((realEstateGroup) => ({ realEstateGroup })),
        };
    }
}
