import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MentionInputComponent } from '@app/user/components/mention-input/mention-input.component';
import { userReducer } from '@app/user/state/user.reducer';
import { StoreModule } from '@ngrx/store';

const components = [MentionInputComponent];

@NgModule({
    imports: [SharedModule, StoreModule.forFeature('user', userReducer)],
    declarations: [...components],
    exports: [...components],
})
export class UserModule {}
