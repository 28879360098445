export class GraphUtils {
    public static roundedTopRect(x: number, y: number, width: number, height: number, radius: number): string {
        return `M${x},${y + radius}
            a${radius},${radius} 0 0 1 ${radius},${-radius}
            h${width - 2 * radius}
            a${radius},${radius} 0 0 1 ${radius},${radius}
            v${height - radius}
            h${-width}
            Z`;
    }

    public static roundedBottomRect(x: number, y: number, width: number, height: number, radius: number): string {
        return `M${x},${y}
            h${width}
            v${height - radius}
            a${radius},${radius} 0 0 1 ${-radius},${radius}
            h${-width + 2 * radius}
            a${radius},${radius} 0 0 1 ${-radius},${-radius}
            Z`;
    }

    public static roundedRect(x: number, y: number, width: number, height: number, radius: number): string {
        return `M${x + radius},${y}
            h${width - 2 * radius}
            a${radius},${radius} 0 0 1 ${radius},${radius}
            v${height - 2 * radius}
            a${radius},${radius} 0 0 1 ${-radius},${radius}
            h${-width + 2 * radius}
            a${radius},${radius} 0 0 1 ${-radius},${-radius}
            v${-height + 2 * radius}
            a${radius},${radius} 0 0 1 ${radius},${-radius}
            Z`;
    }
}
