<div class="u-flex-column u-margin-top">
    <div *ngIf="showMovingDate">
        <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.MOVING_DATE' | translate }}</p>
        <p class="u-color-gray-ultradark" id="label-moving-date" *ngIf="move.movingDate; else unknownLabel">
            {{ move.movingDate | formatDate }}
        </p>
    </div>

    <div class="u-margin-top">
        <p class="u-color-gray-dark">
            {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate }}
            <app-icon
                appTooltip
                [template]="nrnNumberTooltip"
                [icon]="uiIcon.Info"
                [size]="UiIconSize.Md"
                class="u-margin-right-half"
            ></app-icon>
        </p>
        <p class="u-color-gray-ultradark" id="label-nrn" *ngIf="move.water?.nationalRegistrationNumber; else unknownLabel">
            {{ move.water?.nationalRegistrationNumber }}
        </p>
    </div>

    <div class="u-margin-top">
        <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate }}</p>
        <p class="u-color-gray-ultradark" id="label-water-supplier" *ngIf="move.water?.waterSupplier?.name; else unknownLabel">
            {{ move.water?.waterSupplier?.name }}
        </p>
    </div>

    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-meter-number" *ngIf="move.water?.meterNumber; else unknownLabel">
                {{ move.water?.meterNumber }}
            </p>
        </div>
        <div id="meter-reading" class="u-flex-grow-1 u-margin-top-up-to-and-including-phone-landscape">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-meter-reading" *ngIf="move?.water?.meterReading | isNumber; else unknownLabel">
                {{ move?.water?.meterReading }}
            </p>
        </div>
    </div>

    <div *ngIf="showDocument" class="u-margin-top">
        <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TAKEOVER_DOCUMENT.LABEL' | translate }}</p>
        <p class="u-color-gray-light" id="label-no-download" *ngIf="ArrayUtils.isEmpty(move.waterDocumentAssets)">
            {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TAKEOVER_DOCUMENT.NONE_UPLOADED' | translate }}
        </p>
        <div class="__icon-wrapper u-flex-column">
            <div
                *ngFor="let asset of move.waterDocumentAssets"
                (click)="downloadFile(asset)"
                class="__download-wrapper u-flex-row u-cursor-pointer"
            >
                <div class="__download-icon u-margin-right">
                    <app-svg-illustration [svg]="svgs.Download"></app-svg-illustration>
                    <a #download class="u-display-none" download></a>
                </div>
                <div class="u-font-weight-semi-bold" id="label-download">{{ 'WATER.DOWNLOAD_DOCUMENT' | translate }}</div>
            </div>
        </div>
    </div>
</div>

<ng-template #unknownLabel>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.UNKNOWN' | translate }}</p>
</ng-template>

<ng-template #nrnNumberTooltip>
    <p>{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NRN_TOOLTIP' | translate }}</p>
</ng-template>
