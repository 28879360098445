import { Author } from '../interfaces';
import { ActionAudit } from '../interfaces/action-audit';
import { ObjectUtils } from './object-utils';

export class AuditUtils {
    public static getValue<T>(value: T | ActionAudit<T>): T {
        return ObjectUtils.isObject(value) ? (value as ActionAudit<T>).value : (value as T);
    }

    public static getLastModifiedBy<T>(value: Author | ActionAudit<T>): Author {
        return ObjectUtils.isObject(value)
            ? (value as ActionAudit<T>).lastModifiedBy || (value as ActionAudit<T>).createdBy
            : (value as Author);
    }

    public static getLastModifiedOn<T>(value: Date | ActionAudit<T>): Date {
        return ObjectUtils.isObject(value)
            ? (value as ActionAudit<T>).lastModifiedOn || (value as ActionAudit<T>).createdOn
            : (value as Date);
    }

    public static isTruthy<T>(value: T | ActionAudit<T>): boolean {
        return !!AuditUtils.getValue(value);
    }

    public static isFalsy<T>(value: T | ActionAudit<T>): boolean {
        return !AuditUtils.getValue(value);
    }
}
