import { EnumUtils } from '@app/shared/utils/enum-utils';
import { ObjectUtils, StringUtils } from '@smooved/core';
import { DropdownInput, TextInput } from '@smooved/ui';

export enum CrmClient {
    Whise = 'whise',
    Zabun = 'zabun',
    Omnicasa = 'omnicasa',
    Skarabee = 'skarabee',
    Dewaele = 'dewaele',
    Apimo = 'apimo',
    Amaiimmo = 'amaiimmo',
}

export interface ICrmClient {
    whiseId?: string;
    zabunId?: string;
    skarabeeId?: string;
    omnicasaId?: string;
    dewaeleId?: string;
    apimoId?: string;
}

export const crmClientsToObject = (setter: (value: string) => any, idSuffix = 'Id'): object => {
    const obj = {};
    EnumUtils.values(CrmClient).forEach((value) => {
        const label = `${value}${idSuffix}`;
        ObjectUtils.set(obj, label, setter(label));
    });
    return obj;
};

export const crmClientOptions: TextInput[] = EnumUtils.entries(CrmClient).map(([key, value]) => ({
    id: key,
    formControlName: `${value.toString()}Id`,
    label: `${value.toString().toUpperCase()}_ID`,
    name: StringUtils.capitalize(value as string),
}));

export const crmClientDropdownOptions: DropdownInput<string>[] = EnumUtils.entries<CrmClient>(CrmClient).map(([key, value]) => ({
    id: key,
    label: StringUtils.capitalize(value as string),
    name: StringUtils.capitalize(value as string),
    value: `${value.toString()}Id`,
}));
