import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ArrayUtils, ObjectUtils } from '@smooved/core';
import { UiAlignment, UiVerticalAlignment } from '../ui.enums';
import { colFixPaddingEndTable, defaultColumnWidth } from './table.constants';
import { ColConf, ColDef, RowDef, TableDef } from './table.interfaces';

@Injectable()
export class TableService<T> {
    private colsConf: ColConf<T>[];
    private tableDef: TableDef<T>;

    public colConfFactory(colDef: ColDef<T>): ColConf<T> {
        const alignment = colDef.alignment ?? UiAlignment.Left;
        const verticalAlignment = colDef.verticalAlignment ?? UiVerticalAlignment.Top;
        const field = (colDef.field ? this.getLastPath(colDef.field) : undefined) as string & keyof T;
        const width = colDef.width ?? defaultColumnWidth;

        return {
            ...colDef,
            alignment,
            verticalAlignment,
            field,
            width,
        };
    }

    public dataSourceFactory(items: T[]): MatTableDataSource<RowDef<T>> {
        return new MatTableDataSource((items || []).map(this.colDataFactory));
    }

    public handleColumnsChanged(tableDef: TableDef<T>): [ColConf<T>[], string[]] {
        this.tableDef = tableDef;
        this.colsConf = this.tableDef.columns.map(this.createColConfig);
        const cols = [...this.tableDef.columns.filter(this.isVisible).map(this.getId), colFixPaddingEndTable];

        return [this.colsConf, cols];
    }

    public getTableDef(): TableDef<T> {
        return this.tableDef;
    }

    private colDataFactory = (item: T): RowDef<T> => {
        return this.tableDef.columns.reduce(
            (obj, def) => {
                return {
                    ...obj,
                    [def.id]: def.fieldGet ? def.fieldGet(item) : def.field ? ObjectUtils.get(item, def.field) : null,
                    data: item,
                };
            },
            { data: undefined }
        );
    };

    private createColConfig = (col): ColConf<T> => this.colConfFactory(col);

    private isVisible = (col): boolean => !col.hide;
    private getId = (col): any => col.id;

    private getLastPath(path: string): string {
        return ArrayUtils.last(path.split('.'));
    }
}
