import { Pipe, PipeTransform } from '@angular/core';
import { HomeDescription, SimplifiedHomeDescription } from '../enums';

@Pipe({
    name: 'houseType',
})
export class HouseTypePipe implements PipeTransform {
    transform(value: HomeDescription, opts?: { emptyAs?: string }): string {
        if (!(value && Object.values(HomeDescription).includes(value))) return opts?.emptyAs ?? value;
        return this.rewrite(value);
    }

    private rewrite(value: HomeDescription): string {
        return value === HomeDescription.Apartment ? SimplifiedHomeDescription.Apartment : SimplifiedHomeDescription.House;
    }
}
