<app-dashboard-card
    [first]="true"
    [title]="'REAL_ESTATE_AGENT.START.NPS.TITLE' | translate"
    [text]="'REAL_ESTATE_AGENT.START.NPS.TEXT' | translate"
    [hasBorderBottom]="true"
    [ngSwitch]="emptyScore$ | async"
    [last]="true"
    [svgTitle]="housematchIcon"
    [svgUiContext]="context.Terracota"
    [hasBottomPanel]="featureScopeSandbox.hasAccess(featureScope.ReviewInsights) | async"
    [sideAlwaysVisible]="true"
>
    <ng-container aside>
        <div class="u-flex-align-items-baseline u-margin-y-axis-half">
            <div class="visual u-flex-column u-flex-align-items-center u-flex-justify-content-center">
                <app-insights-house-match-score
                    [score]="insightsSandbox.houseMatchScore$ | async"
                    [smallVersion]="true"
                ></app-insights-house-match-score>
                <p class="__house-match-score u-color-muted u-font-family-cera-pro u-font-weight">
                    {{ (insightsSandbox.insightsData$ | async)?.total || 0 }} {{ 'ANALYTICS.MENU.REVIEWS' | translate | lowercase }}
                </p>
            </div>
        </div>
    </ng-container>

    <ng-container up-to-and-including-phone-landscape>
        <div class="u-flex-column u-flex-align-items-stretch">
            <ng-container
                [ngTemplateOutlet]="actions"
                [ngTemplateOutletContext]="{ class: 'u-margin-bottom u-text-align-center' }"
            ></ng-container>
        </div>
    </ng-container>
    <ng-container tablet-portrait-up>
        <div class="u-flex-row u-flex-align-items-center">
            <ng-container
                [ngTemplateOutlet]="actions"
                [ngTemplateOutletContext]="{ class: 'u-margin-right u-text-align-center' }"
            ></ng-container>
        </div>
    </ng-container>

    <ng-container bottomPanel>
        <div class="u-w100p u-flex-column u-flex-align-items-start u-padding-x-axis">
            <h5>{{ 'REAL_ESTATE_AGENT.START.NPS.CUSTOMER_POINTS' | translate }}</h5>
            <div class="u-w100p u-flex-align-items-start u-padding-top" *ngIf="insightsSandbox.topicsAvailable$ | async; else emptyState">
                <ng-container *ngIf="{ results: insightsSandbox.topicsAnalysisTopPositiveResults$ | async } as vm">
                    <p class="u-color-muted u-margin-bottom-half">{{ 'REAL_ESTATE_AGENT.START.NPS.POSITIVE_POINTS' | translate }}</p>
                    <ui-review-topic-sentiment-pill
                        *ngFor="let topic of vm.results; let last = last"
                        [topicSentiment]="topic"
                        [clickable]="true"
                        [class.u-margin-right-half]="!last"
                        (pillClicked)="sendAnalyticsOnSentimentPillClick($event)"
                        class="u-margin-bottom-half"
                    ></ui-review-topic-sentiment-pill>
                    <p *ngIf="!vm.results.length">{{ 'REAL_ESTATE_AGENT.START.NPS.POSITIVE_POINTS_NONE' | translate }}</p>
                </ng-container>

                <ng-container *ngIf="{ results: insightsSandbox.topicsAnalysisTopNegativeResults$ | async } as vm">
                    <p class="u-color-muted u-margin-bottom-half">{{ 'REAL_ESTATE_AGENT.START.NPS.NEGATIVE_POINTS' | translate }}</p>
                    <ui-review-topic-sentiment-pill
                        *ngFor="let topic of vm.results; let last = last"
                        [topicSentiment]="topic"
                        [clickable]="true"
                        [class.u-margin-right-half]="!last"
                        class="u-margin-bottom-half"
                    ></ui-review-topic-sentiment-pill>
                    <p *ngIf="!vm.results.length">{{ 'REAL_ESTATE_AGENT.START.NPS.NEGATIVE_POINTS_NONE' | translate }}</p>
                </ng-container>
            </div>
            <a
                app-button
                [routerLink]="reviewsInsightsRoute"
                [appearance]="buttonAppearance.LinkReversed"
                [appendIcon]="true"
                icon="chevron_right"
                class="u-no-padding-left"
            >
                {{ 'REAL_ESTATE_AGENT.START.NPS.MORE_INSIGHTS' | translate }}</a
            >
        </div>
    </ng-container>

    <ng-template #emptyState>
        <p class="u-color-gray-dark u-margin-y-axis">{{ 'REAL_ESTATE_AGENT.START.NPS.NO_REVIEWS' | translate }}</p>
    </ng-template>

    <ng-template #actions let-class="class">
        <p *ngSwitchCase="true" class="u-color-muted">{{ 'REAL_ESTATE_AGENT.START.NPS.EMPTY' | translate }}</p>
        <ng-container *ngSwitchCase="false">
            <a
                app-button
                [class]="class"
                [context]="context.Secondary"
                [routerLink]="npsReviewsRoute"
                [matBadge]="npsUnreadReviewsCount"
                [matBadgeHidden]="!npsUnreadReviewsCount"
                matBadgeColor="warn"
                [matBadgeWide]="npsUnreadReviewsCount > 99"
            >
                {{ 'REAL_ESTATE_AGENT.START.NPS.REVIEWS' | translate }}
            </a>
            <a app-button [context]="context.Secondary" [appearance]="buttonAppearance.Stroked" (click)="surveyInvite()" [class]="class">{{
                'CTA' | i18nKeyTranslate : i18nKeys.RealEstateAgentSurveyInvite
            }}</a>
            <a
                app-button
                [context]="context.Secondary"
                [appearance]="buttonAppearance.Stroked"
                [routerLink]="settingsRoute"
                [class]="class"
                >{{ 'REAL_ESTATE_AGENT.START.NPS.SETTINGS' | translate }}</a
            >
        </ng-container>
    </ng-template>
</app-dashboard-card>
