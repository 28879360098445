import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-cta-card-inactive-move',
    templateUrl: 'cta-card-inactive-move.component.html',
})
export class CtaCardInactiveMoveComponent {
    @Input() private move: Move;

    constructor(private readonly moveSandbox: MoveSandbox, private readonly router: Router) {}

    public goToEotS(): void {
        this.moveSandbox.setMoveState(this.move);
        void this.router.navigate([Navigation.OTS, Navigation.EOTS]);
    }
}
