import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-office-filter',
    template: `
        <div class="u-flex-row u-flex-align-items-center">
            <div class="u-flex-row">
                <form [formGroup]="searchForm" class="u-w100p-up-to-and-including-phone-landscape u-margin-right-tablet-portrait-up">
                    <app-text-input
                        formControlName="search"
                        [width]="(uiSandbox.tabletPortraitUp$ | async) ? '300px' : '100%'"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        [placeholder]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.SEARCH.PLACEHOLDER' | translate"
                    ></app-text-input>
                </form>

                <form [formGroup]="otherForm" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                    <ui-dropdown-input
                        formControlName="office"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        width="240px"
                        [options]="officesOptions"
                    ></ui-dropdown-input>
                </form>
            </div>
        </div>
    `,
    styles: [],
})
export class OfficeFilterComponent implements OnInit, OnDestroy {
    @Input() public officesOptions: DropdownInput<string>[];

    @Output() public filterChange: EventEmitter<{
        filter: RealEstateAgentFilter;
        persist: boolean;
    }> = new EventEmitter<{
        filter: RealEstateAgentFilter;
        persist: boolean;
    }>();

    public searchForm = this.formBuilder.group({
        search: [],
    });

    public otherForm = this.formBuilder.group({
        office: [],
    });

    private subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private realEstateAgentSandBox: RealEstateAgentSandbox,
        public uiSandbox: AppUiSandbox
    ) {}

    public ngOnInit(): void {
        this.realEstateAgentSandBox.getFilter().subscribe((filter) => {
            this.otherForm.patchValue(filter);

            this.filterChange.emit({
                filter: this.getFilterValue(),
                persist: true,
            });

            this.subscriptions.push(
                this.searchForm
                    .get('search')
                    .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
                    .subscribe((_) => {
                        this.filterChange.emit({
                            filter: this.getFilterValue(),
                            persist: false,
                        });
                    }),

                this.otherForm.valueChanges.subscribe((_) => {
                    this.filterChange.emit({
                        filter: this.getFilterValue(),
                        persist: true,
                    });
                })
            );
        });
    }

    public getFilterValue(): RealEstateAgentFilter {
        return {
            ...this.searchForm.value,
            ...this.otherForm.value,
        };
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }
}
