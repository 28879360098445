import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SecurityContext,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementUtils, SimpleChangesUtils } from '@smooved/core';
import { UiContext, UiHeaderVariant } from '../../../ui.enums';
import { cardBaseClass, stretchOnMobileClass } from './card.constants';

@Component({
    selector: 'app-ui-card, smvd-ui-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit, OnChanges {
    @Input() public header: string;
    @Input() public sub: string;
    @Input() public variant = UiHeaderVariant.Default;
    @Input() public mainClass: string;
    @Input() public context: UiContext;
    @Input() public stretchOnMobile: boolean;

    @Input()
    @HostBinding('class.has-box-shadow')
    public hasBoxShadow = false;

    @Input()
    @HostBinding('class.has-padding')
    public hasPadding = true;

    @Input()
    @HostBinding('class.has-border')
    public border = true;
    public headerText;

    constructor(private readonly sanitizer: DomSanitizer, private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    public ngOnInit(): void {
        this.resetClasses();
    }

    public ngOnChanges({ header, context, stretchOnMobile }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(header)) {
            this.headerText = this.sanitizer.sanitize(SecurityContext.HTML, `<${this.variant}>${this.header}</${this.variant}>`);
        }

        if (SimpleChangesUtils.hasChanged(context, stretchOnMobile)) {
            this.resetClasses();
        }
    }

    private resetClasses(): void {
        const classList = ElementUtils.getElementClassList(this.el, cardBaseClass);
        classList.forEach((c) => this.renderer.removeClass(this.el.nativeElement, c));

        this.renderer.addClass(this.el.nativeElement, `${cardBaseClass}${this.context || UiContext.None}`);
        if (this.stretchOnMobile) this.renderer.addClass(this.el.nativeElement, stretchOnMobileClass);
    }
}
