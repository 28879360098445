import { Component, forwardRef, Host, Input, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { MailFields } from '@app/admin/enums/mail-fields.enum';
import { MailData } from '@app/admin/interfaces/mail-data';
import { createEscalationOptions, stateOptions } from '@app/escalation/constants/escalation.constants';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { ArrayUtils, AuditUtils, DbUtils, SimpleChangesUtils } from '@smooved/core';
import { BaseInput, DropdownInput } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { SmsCustomForm } from './sms-custom-form.constants';

@Component({
    selector: 'app-sms-custom-form',
    templateUrl: 'sms-custom-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SmsCustomFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SmsCustomFormComponent),
            multi: true,
        },
    ],
})
export class SmsCustomFormComponent extends BaseInput implements ControlValueAccessor, Validator, OnChanges, OnInit {
    @Input() public data: MailData;

    public readonly mailFields = MailFields;
    public readonly i18nKeytype = appI18nKeyTypes;
    public readonly stateOptions = stateOptions;

    public editor;
    public form: UntypedFormGroup;
    public escalationOptions: DropdownInput<string>[] = [];

    constructor(@Optional() @Host() @SkipSelf() readonly controlContainer: ControlContainer, private readonly formBuilder: UntypedFormBuilder) {
        super(controlContainer);
    }

    public ngOnChanges({ data }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(data)) {
            this.setData();
        }
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            [MailFields.to]: [this.data.preData?.to, Validators.required],
            [MailFields.content]: [this.data.preData?.content, Validators.required],
            [MailFields.escalation]: DbUtils.getStringId(this.data.preData?.escalation),
            [MailFields.state]: AuditUtils.getValue(this.data.preData?.escalation?.state),
        });
        this.setEscalationOptions();

        this.formControl.markAsTouched = () => {
            this.form.markAllAsTouched();
        };

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: object) => this.propagateChange(value));
        this.form.get(MailFields.escalation).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onSelectEscalation);
    }

    public writeValue(obj: SmsCustomForm): void {
        this.form.patchValue({ ...obj });
    }

    public validate(): ValidationErrors | null {
        return this.form.invalid ? { to: this.form.get(MailFields.to).invalid, content: this.form.get(MailFields.content).invalid } : null;
    }

    private setData(): void {
        if (!this.data) return;
        this.form?.patchValue(
            {
                [MailFields.to]: this.data.preData?.to,
                [MailFields.content]: this.data.preData?.content,
                [MailFields.escalation]: DbUtils.getStringId(this.data.preData?.escalation),
                [MailFields.state]: AuditUtils.getValue(this.data.preData?.escalation?.state),
            },
            { emitEvent: false }
        );
    }

    private setEscalationOptions(): void {
        ArrayUtils.isEmpty(this.data.move.escalations)
            ? this.form.get(MailFields.escalation).disable()
            : (this.escalationOptions = createEscalationOptions(this.data.move.escalations as Escalation[]));
    }

    private onSelectEscalation = (escalationId: string): void => {
        const escalation = this.data.move.escalations.find((escalation) => DbUtils.getStringId(escalation) === escalationId) as Escalation;
        this.form.get(MailFields.state).patchValue(AuditUtils.getValue(escalation.state));
    };
}
