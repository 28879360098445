import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { MoveTransactionType } from '@smooved/core';

export enum FormControlNames {
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Subject = 'subject',
    Message = 'message',
    Consent = 'consent',
    Recaptcha = 'recaptcha',
}

export const subjectOptions: DropdownInput<MoveTransactionType>[] = [
    ...Object.entries(MoveTransactionType).map(([key, value]: [string, MoveTransactionType]) => {
        return {
            id: `subject-${key}`,
            labelResource: key.toUpperCase(),
            value,
            name: 'subject',
        };
    }),
];

export const LabelCommonMessage = 'COMMON.MESSAGE';
export const LabelMessageMultiSelect = 'UI.COMPANY_PAGE.CTA_BOX.CONTACT.FORM.MESSAGE_LABEL_MULTI_SELECT';
