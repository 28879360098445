import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepComponent } from '@app/form/components/base-step/base-step.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ElectricityMeterType } from '@smooved/ui';

@Component({
    selector: 'app-meter-type-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'REAL_ESTATE_AGENT.EOTS.METER_TYPE.TITLE' | translate }}</h3>
                    <app-meter-type (previous)="goToPrevious($event)" [showPrevious]="true" (next)="onSubmit($event)"></app-meter-type>
                </app-card>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterTypeContainer extends BaseStepComponent {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        protected moveSandbox: MoveSandbox
    ) {
        super(moveSandbox);
    }

    public goToPrevious(event: ElectricityMeterType): void {
        this.moveSandbox.patchProperty('energyOffer.meterType', event, true, () => {
            this.router
                .navigate(['../energy-type'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }

    public onSubmit(event: ElectricityMeterType): void {
        this.moveSandbox.patchProperty('energyOffer.meterType', event, true, () => {
            this.router
                .navigate(['../../energy-suggestions'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }
}
