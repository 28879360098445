<ng-container
    *ngIf="{
        tabletPortraitUp: (uiSandbox.tabletPortraitUp$ | async),
        upToAndIncludingPhoneLandscape: (uiSandbox.upToAndIncludingPhoneLandscape$ | async)
    } as vm"
>
    <div
        class="u-flex-row u-flex-align-items-center u-flex-wrap"
        [class.u-flex-align-items-start]="vm.tabletPortraitUp"
        [class.u-flex-align-items-center]="vm.upToAndIncludingPhoneLandscape"
    >
        <form
            [formGroup]="searchForm"
            class="__search-form u-flex-row u-flex-align-items-center u-w100p-up-to-and-including-phone-landscape"
        >
            <app-text-input
                formControlName="search"
                [class.u-w100p]="vm.upToAndIncludingPhoneLandscape"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [title]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.SEARCH.PLACEHOLDER' | translate"
                [placeholder]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.SEARCH.PLACEHOLDER' | translate"
                class="u-margin-top-half"
            ></app-text-input>
        </form>

        <form [formGroup]="otherForm" class="u-flex-row u-flex-align-items-center white-inputs u-flex-wrap">
            <ng-container *ngIf="vm.tabletPortraitUp">
                <ui-dropdown-input
                    *ngIf="showCreatedBy"
                    [formControlName]="otherFormFields.CreatedBy"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [options]="createdByOptions"
                    class="u-margin-right u-margin-top-half"
                ></ui-dropdown-input>

                <ui-dropdown-input
                    *ngIf="showCreatedByFlow"
                    [formControlName]="otherFormFields.CreatedByFlow"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right u-margin-top-half"
                    [options]="createdByFlowOptions"
                >
                </ui-dropdown-input>

                <ui-dropdown-input
                    *ngIf="showMoverRole"
                    [formControlName]="otherFormFields.MoverRole"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right u-margin-top-half"
                    [options]="moverRoleOptions"
                >
                </ui-dropdown-input>

                <ui-dropdown-input
                    *ngIf="showClusterLevel"
                    [formControlName]="otherFormFields.ClusterLevel"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right u-margin-top-half"
                    [options]="clusterLevelOptions"
                >
                </ui-dropdown-input>
                <ui-dropdown-input
                    *ngIf="showScoreFilter"
                    [formControlName]="otherFormFields.ScoreFilter"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right u-margin-top-half"
                    [options]="scoreBucketsOptions"
                >
                </ui-dropdown-input>
            </ng-container>
        </form>
    </div>
</ng-container>
