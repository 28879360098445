<smvd-ui-card [border]="false" class="__total-amount-card u-background-gray-ultralight u-padding" [hasPadding]="false">
    <h5 class="u-margin-bottom">{{ 'INVOICING.INVOICE.NET_AMOUNT' | translate }}</h5>
    <div class="u-flex-row u-flex-align-items-center u-margin-bottom">
        <p class="u-font-line-height-sm">
            {{ invoice.totalCommissions | price }}<br />
            <span class="u-color-gray u-font-size-sm-up-to-and-including-phone-landscape">{{
                'INVOICING.INVOICE.COMMISSION.LABEL' | translate
            }}</span>
        </p>
        <p class="u-font-size-28 u-font-weight-semi-bold u-margin-x-axis">-</p>
        <p class="u-font-line-height-sm">
            {{ invoice.totalSubscriptionCostInclusiveDiscounts | price }}<br />
            <span class="u-color-gray u-font-size-sm-up-to-and-including-phone-landscape">{{
                'INVOICING.INVOICE.SUBSCRIPTION.LABEL' | translate
            }}</span>
        </p>
        <p class="u-font-size-28 u-font-weight-semi-bold u-margin-x-axis">=</p>
        <p class="u-font-line-height-sm">
            <span class="u-font-size-20 u-font-weight-semi-bold">{{ invoice.feeTotal | price }}</span
            ><br />
            <span class="u-color-gray u-font-size-sm-up-to-and-including-phone-landscape">{{
                'INVOICING.INVOICE.VAT' | translate : { excludeValue: true }
            }}</span>
        </p>
    </div>
    <div
        class="u-color-gray-dark"
        [innerHTML]="
            (invoice.status.value === invoiceStatus.InvoicePaid ? 'INVOICING.INVOICE.FINAL_AMOUNT_PAID' : 'INVOICING.INVOICE.FINAL_AMOUNT')
                | translate : { amount: includingVAT(invoice.feeTotal) | price }
        "
    ></div>
</smvd-ui-card>
