export enum CompanyPageNavigation {
    Home = 'home',
    Widgets = 'widgets',
    EmailPreferences = 'email-preferences',
    Reviews = 'reviews',
    About = 'about',
    Contact = 'contact',
    Offices = 'offices',
    Overview = 'overview',
    Comparison = 'comparison',
    Realtors = 'realtors',
    Locations = 'locations',
}
