import { Component, Input, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Store } from '@ngrx/store';
import { DbUtils, FeatureScopeState, setFeatureScopesConfig } from '@smooved/core';
import { ButtonAppearance, UiContext, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-impersonate',
    template: `
        <button
            app-button
            icon="launch"
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Icon"
            [iconSize]="iconSize.Md"
            [title]="'IMPERSONATE' | translate"
            [class.u-disabled]="disabled"
            (click)="impersonate($event)"
        >
            {{ 'IMPERSONATE' | translate }}
        </button>
    `,
})
export class ImpersonateComponent {
    @Input() move: Move;
    @Input() returnUrl: string;
    @Input() disabled = false;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;

    constructor(
        @Optional() private readonly dialogRef: MatDialogRef<any>,
        private readonly moveSandbox: MoveSandbox,
        private readonly router: Router,
        private readonly store$: Store<FeatureScopeState>
    ) {}

    public impersonate(event: MouseEvent): void {
        event.stopImmediatePropagation();
        if (this.disabled) return;
        this.moveSandbox.impersonate(DbUtils.getStringId(this.move), this.dialogRef).subscribe((move) => {
            /**
             * Bugfix: temp solution to be able to set featureScopeConfig during impersonation
             * Refactor Issue #1317: https://gitlab.com/smooved-app/smooved-app/-/issues/1317
             * */
            this.store$.dispatch(setFeatureScopesConfig({ featureScopesConfig: move.realEstateGroup?.featureScopesConfig }));
            void this.router.navigateByUrl(this.returnUrl);
        });
    }
}
