import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CheckInput } from '@smooved/ui';
import { RealEstateAgentRegisterRequest } from '../../../authentication/interfaces/real-estate-agent-register.request';

@Component({
    selector: 'app-real-estate-agent-register',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column">
            <h3 class="u-margin-bottom">Registreren als makelaar</h3>

            <app-email-input formControlName="email" [placeholder]="'EMAIL' | translate"></app-email-input>

            <app-text-input
                [id]="'phoneNumber'"
                [label]="'Wat is jouw telefoonnummer?'"
                [placeholder]="'PHONE_NUMBER' | translate"
                formControlName="phoneNumber"
            ></app-text-input>

            <app-password-input
                [id]="'password'"
                [label]="'Kies een wachtwoord'"
                [placeholder]="'PASSWORD' | translate"
                formControlName="password"
            ></app-password-input>

            <app-password-input
                [id]="'confirmPassword'"
                [label]="'Herhaal je wachtwoord'"
                [placeholder]="'Wachtwoord'"
                formControlName="confirmPassword"
            ></app-password-input>

            <app-text-input
                [id]="'bivCode'"
                [label]="'Wat is jouw BIV-code?'"
                [placeholder]="'OPTIONAL' | translate"
                formControlName="bivCode"
            ></app-text-input>

            <app-check-input [width]="'auto'" [custom]="false" [option]="whiseOption" formControlName="whiseCheck"></app-check-input>

            <app-text-input
                [id]="'whiseId'"
                *ngIf="!!form.get('whiseCheck')?.value"
                [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.WHISE.LABEL' | translate"
                formControlName="whiseId"
            ></app-text-input>

            <div class="u-flex-column u-flex-align-items-center u-margin-top">
                <app-button [disabled]="form.invalid || loading" [ngSwitch]="loading" type="submit" class="u-margin-bottom u-w100p">
                    <span *ngSwitchCase="false">Registreren</span>
                    <span *ngSwitchCase="true">Registreren...</span>
                </app-button>
                <a (click)="goToLogin.emit()" class="u-link">Inloggen</a>
            </div>
        </form>
    `,
})
export class RealEstateAgentRegisterComponent {
    @Input() public loading: boolean;

    @Output() public formSubmit: EventEmitter<RealEstateAgentRegisterRequest> = new EventEmitter();
    @Output() public goToLogin: EventEmitter<void> = new EventEmitter();

    public form: UntypedFormGroup = this.formBuilder.group(
        {
            bivCode: [null, []],
            email: [null, [Validators.required, Validators.email]],
            phoneNumber: [null, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
            whiseCheck: [false],
            whiseId: [null],
        },
        {
            validator: (formGroup: UntypedFormGroup) => {
                const password = formGroup.controls.password.value;
                const confirmPassword = formGroup.controls.confirmPassword.value;

                if (!password || !confirmPassword) {
                    return null;
                }
                return password === confirmPassword
                    ? null
                    : {
                          confirmPassword: true,
                      };
            },
        }
    );

    public whiseOption: CheckInput<boolean> = {
        id: 'whise-option',
        name: 'whise-option',
        value: true,
        label: this.translateService.instant('AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.WHISE.CHECKBOX.LABEL'),
    };

    constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService) {}

    public onSubmit(): void {
        if (this.form.valid) {
            const payload: RealEstateAgentRegisterRequest = {
                bivCode: this.form.value.bivCode,
                email: this.form.value.email,
                phoneNumber: this.form.value.phoneNumber,
                password: this.form.value.password,
                confirmPassword: this.form.value.confirmPassword,
            };
            if (!!this.form.get('whiseCheck').value && !!this.form.get('whiseId').value) {
                payload.whiseId = this.form.get('whiseId').value;
            }
            this.formSubmit.emit(payload);
        }
    }
}
