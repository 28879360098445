import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { map } from 'rxjs/operators';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { packageInfoUri } from '../../constants/reviews.constants';

@Component({
    selector: 'smvd-ui-review-upgrade-package',
    templateUrl: './review-upgrade-package.component.html',
    styleUrls: ['./review-upgrade-package.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewUpgradePackageComponent {
    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly link$ = this.translationSandbox.selected$.pipe(
        map((language) => packageInfoUri[language || this.translationSandbox.defaultLanguage])
    );

    constructor(private readonly translationSandbox: TranslationSandbox) {}
}
