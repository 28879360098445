<app-loading-container [loading]="loading" *ngIf="move$ | async as move">
    <ng-container
        *ngIf="{ waterTransferLocked: waterTransferLocked$ | async, hasCollectiveWaterMeter: hasCollectiveWaterMeter$ | async } as vm"
    >
        <app-alert
            id="locked-info"
            [context]="uiContext.Muted"
            [icon]="uiIcon.Info"
            class="u-margin-bottom-double"
            *ngIf="vm.waterTransferLocked"
        >
            <p>{{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.INFO_1" | translate }}</p>
            <p>
                {{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.INFO_2" | translate }}
                <a [href]="mailTo | uriSchema: uriSchemaType.MailTo">{{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.CTA" | translate }}</a>
            </p>
        </app-alert>

        <div class="u-flex u-flex-column">
            <mat-radio-button
                id="transfer-via-smooved"
                [checked]="!vm.hasCollectiveWaterMeter"
                [disabled]="vm.waterTransferLocked"
                (change)="toggleWaterTransferNeeded(true)"
            >
                {{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.TITLE" | translate }}
            </mat-radio-button>

            <mat-radio-button
                id="no-transfer"
                class="u-margin-top u-margin-bottom"
                [checked]="vm.hasCollectiveWaterMeter"
                [disabled]="vm.waterTransferLocked"
                (change)="toggleWaterTransferNeeded(false)"
            >
                {{ "MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.TITLE" | translate }}
            </mat-radio-button>
        </div>

        <div class="u-flex-column u-color-gray-ultradark" *ngIf="!(hasCollectiveWaterMeter$ | async); else noWaterTransferCommunication">
            <p class="u-font-size-12 u-margin-bottom-half">{{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.INFO_1" | translate }}</p>
            <p class="u-font-size-12">{{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.INFO_2" | translate }}</p>

            <app-separator></app-separator>

            <div class="u-flex-responsive u-flex-justify-content-space-between u-margin-bottom">
                <h5 class="u-inline-flex-row">{{ "WATER.DOCUMENTS.TITLE" | translate }}</h5>
                <smvd-ui-water-meter-state-pill
                    [meterState]="move?.track?.waterMeterReadings?.waterMetersState"
                    [todo]="moveUtils.checkForTodo(move, TodoType.waterDocumentsMovingAddressByRealEstateAgent)"
                ></smvd-ui-water-meter-state-pill>
            </div>

            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TAKEOVER_DOCUMENT.LABEL' | translate }}</p>
            <p class="u-color-gray-light" id="label-no-download" *ngIf="ArrayUtils.isEmpty(move.waterDocumentAssets)">
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TAKEOVER_DOCUMENT.NONE_UPLOADED' | translate }}
            </p>
            <div class="u-flex-column">
                <div *ngFor="let asset of move.waterDocumentAssets" class="u-flex-row u-cursor-pointer">
                    <div class="u-margin-right" (click)="downloadFile(asset)">
                        <app-svg-illustration [svg]="svgs.Download"></app-svg-illustration>
                        <a #download class="u-display-none" download></a>
                    </div>
                    <div class="__label-download u-font-weight-semi-bold" id="label-download" (click)="downloadFile(asset)">
                        {{ asset.name }}
                    </div>
                    <a *ngIf="!vm.waterTransferLocked" class="u-margin-left u-margin-top-xs u-font-size-12" (click)="showRemoveModal(asset)"
                        >{{ "COMMON.DELETE" | translate }}</a
                    >
                </div>
            </div>
            <app-file-input
                class="u-w-auto u-margin-top"
                id="pick-water-transfer-document"
                [(ngModel)]="innerModel"
                multiple="true"
                [buttonAppearance]="buttonAppearance.Default"
                [context]="uiContext.Primary"
                [label]="'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.UPLOAD_ASSETS' | translate"
                [disabled]="vm.waterTransferLocked"
                (change)="onModelChange($event)"
            ></app-file-input>

            <app-alert
                id="water-transfer-documents-info"
                *ngIf="!vm.waterTransferLocked"
                [context]="uiContext.Muted"
                [icon]="uiIcon.Info"
                class="u-flex-justify-self-end u-margin-top-double"
            >
                <p class="u-font-weight-semi-bold">{{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DOCS.INFO_1" | translate }}</p>
                <a [href]="'MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DOCS.LINK' | translate" target="_blank" class="u-link-no-color">
                    {{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DOCS.INFO_2" | translate }}</a
                >
            </app-alert>

            <app-separator></app-separator>

            <div class="u-inline-flex-column u-margin-bottom">
                <div>
                    <h5 class="u-inline-flex-row">{{ "DATA" | translate }}</h5>
                    <span class="u-color-gray-dark u-margin-left-half">({{ "OPTIONAL" | translate }})</span>
                </div>

                <p class="__extra-data--sub-header u-font-size-12 u-color-muted">
                    {{ "ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.INFO" | translate }}
                </p>
            </div>

            <app-water-meter-reading-details [move]="move$ | async" [showDocument]="false"></app-water-meter-reading-details>

            <div>
                <button
                    smvd-ui-button
                    class="u-w-100p-up-to-and-including-phone-landscape u-margin-top"
                    id="complete-transfer-button"
                    (click)="openCompleteWaterDataModal()"
                    [appearance]="buttonAppearance.Stroked"
                    [context]="uiContext.Secondary"
                    [disabled]="vm.waterTransferLocked"
                >
                    {{ "MOVE.SERVICE_ACTIONS.WATER.ADD_EXTRA_DATA.CTA" | translate }}
                </button>
            </div>

            <app-separator></app-separator>

            <h5>{{ "FOLLOW_UP" | translate }}</h5>
            <app-todo
                id="rea-todo"
                class="u-display-block u-margin-top"
                [readonly]="true"
                [checked]="move.waterDocumentsMovingAddressByRealEstateAgent"
                [label]="'DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_REAL_ESTATE_AGENT.GENERAL' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
            ></app-todo>
            <app-todo
                id="admin-todo"
                class="u-display-block"
                [readonly]="true"
                [checked]="move.waterDocumentsMovingAddressByAdmin"
                [label]="'DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_ADMIN' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
            ></app-todo>
        </div>
    </ng-container>
</app-loading-container>

<ng-template #noWaterTransferCommunication>
    <p class="u-font-size-12 u-color-muted">{{ "MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.INFO" | translate }}</p>
</ng-template>
