import { Component, forwardRef, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-slide-toggle',
    template: `
        <mat-slide-toggle
            [(ngModel)]="innerModel"
            disableRipple="true"
            (ngModelChange)="onModelChange()"
            [color]="color"
        ></mat-slide-toggle>
    `,
    styles: [
        `
            :host {
                display: contents;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SlideToggleComponent),
            multi: true,
        },
    ],
})
export class SlideToggleComponent extends BaseInput implements ControlValueAccessor {
    @Input() public formControlName: string;

    public innerModel: string;
    public color: ThemePalette = 'primary';

    constructor(@Optional() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public writeValue(value): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
