import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StringUtils } from '@smooved/core';
import { ButtonAppearance } from '../../../button/button.enums';
import { UiContext } from '../../../ui.enums';
import { BaseModalComponent } from '../base.modal';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent extends BaseModalComponent {
    @Input() public header: string;
    @Input() public subheader: string;

    @Input() public cancelLabel = 'CANCEL';
    @Input() public submitLabel = 'SUBMIT';
    @Input() public question: string;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        protected readonly cdr: ChangeDetectorRef,
        protected dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
        super(cdr, dialogRef, data);
        if (StringUtils.isString(data)) this.question = data;
    }
}
