import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { Move } from '@app/move/interfaces/move';
import { DbUtils, UrlUtils } from '@smooved/core';
import { SvgIllustration, UiContext, UiDirection } from '@smooved/ui';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-success-container',
    templateUrl: './success.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessContainer implements OnInit {
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly uiDirection = UiDirection;
    public readonly uiContext = UiContext;
    public readonly illustrations = SvgIllustration;

    public isEots: boolean;
    public move$: Observable<Move>;

    constructor(
        public readonly moveSandbox: MoveSandbox,
        public readonly navigationSandbox: AppNavigationSandbox,
        private readonly route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        const id = UrlUtils.getQueryParam('id');
        if(!!id) this.moveSandbox.fetch(id);

        this.move$ = this.moveSandbox.move$.pipe(tap(this.handleFetchedMove));
        this.isEots = !!this.route.snapshot.queryParams.eots;
    }

    public openMoveDetail = (): void => {
        /** */
    };

    private handleFetchedMove = (move: Move): void => {
        this.openMoveDetail = (): Promise<boolean> => this.navigationSandbox.goToRealEstateDashboard(DbUtils.getStringId(move));
    };
}
