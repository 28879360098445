import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UiContext } from '@smooved/ui';
import { ButtonTheme, ButtonType } from './button.enums';

@Component({
    selector: 'app-button',
    template: `
        <div [ngSwitch]="type" class="__container u-flex-row u-flex-align-items-center">
            <a
                *ngSwitchCase="'link'"
                [routerLink]="routerLink"
                [class.has-icon]="icon"
                [class.disabled]="disabled"
                [ngClass]="{ 'u-w100p': theme !== 'circle' && theme !== 'icon-circle' && theme !== 'icon-circle-gray' }"
                (click)="onClick.emit($event)"
                class="__button __link m-button{{ theme ? ' m-button--' + theme : '' }}{{ context ? ' u-color-' + context : '' }}"
            >
                <app-icon *ngIf="icon" [icon]="icon"></app-icon>
                <span class="__label"><ng-container *ngTemplateOutlet="content"></ng-container></span>
            </a>
            <button
                [type]="type"
                *ngSwitchDefault
                [disabled]="disabled"
                [tabIndex]="tabIndex"
                [class.has-icon]="icon"
                [ngClass]="{
                    'u-w100p': theme !== 'circle' && theme !== 'icon-circle' && theme !== 'icon-circle-gray',
                    'u-line-height-0': ignoreLineHeight
                }"
                class="__button m-button{{ theme ? ' m-button--' + theme : '' }}"
                (click)="onClick.emit($event)"
            >
                <div *ngIf="showBackground" class="__background u-w100p u-h100p"></div>
                <app-icon *ngIf="icon" [icon]="icon"></app-icon>
                <span class="__label"><ng-container *ngTemplateOutlet="content"></ng-container></span>
            </button>
            <div
                class="__badge u-padding-right-half u-padding-left-half u-flex-row u-flex-align-items-center u-flex-justify-content-center u-font-weight-bold u-font-size-small"
                *ngIf="!!badge"
            >
                {{ badge }}
            </div>
        </div>
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>
    `,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    @Output() public onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Input() public type = ButtonType.Default;
    @Input() public disabled: boolean;
    @Input() public theme: ButtonTheme;
    @Input() public ignoreLineHeight = false;
    @Input() public showBackground = true;
    @Input() public tabIndex: number;
    @Input() public badge: string;
    @Input() public routerLink: string;
    @Input() public context: UiContext;
    @Input() public icon: string;
}
