import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonAppearance } from '../../../button';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'app-mobile-modal',
    template: `
        <header
            class="__header u-flex-row u-flex-align-items-center"
            [class.__mobile-modal--can-navigate-back]="showBackNavigation"
            [class.__mobile-modal__header--hidden]="!showHeader"
        >
            <div class="__left u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                <button
                    *ngIf="showBackNavigation"
                    app-button
                    class="__close"
                    icon="chevron_right"
                    [appearance]="buttonAppearance.Icon"
                    [context]="uiContext.Muted"
                    mat-dialog-close
                ></button>
            </div>
            <div class="__center u-text-align-center u-flex-row u-flex-justify-content-center u-flex-align-items-center">
                <ng-content select="[mobile-modal-header], [modal-header]"></ng-content>
            </div>
            <div class="__right u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                <ng-content select="[mobile-modal-extra-action]"></ng-content>
            </div>
        </header>
        <main class="__main u-flex-1" [ngClass]="{ 'u-padding-top': mainHasPaddingTop }">
            <ng-content select="[mobile-modal-main]"></ng-content>
        </main>
    `,
    styleUrls: ['./mobile-modal.component.scss'],
})
export class MobileModalComponent {
    @Input() public mainHasPaddingTop = true;
    @Input() public showBackNavigation = true;
    @Input() public showHeader = true;

    public uiContext = UiContext;
    public buttonAppearance = ButtonAppearance;

    constructor(public dialogRef: MatDialogRef<any>) {}

    public close(dailogResult?: any): void {
        this.dialogRef.close(dailogResult);
    }
}
