import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReviewSource } from '@smooved/core';
import { NpsReviewsFilterService } from '../../services';
import { sourcesToggleGroupOptions } from './reviews-sources-toggle.constants';

@Component({
    selector: 'smvd-ui-influencer-reviews-sources-toggle',
    templateUrl: './reviews-sources-toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsSourcesToggleComponent implements OnInit {
    @Input() label: string;

    public readonly toggleGroupOptions = sourcesToggleGroupOptions;
    public currentValue: ReviewSource[];

    constructor(public readonly filterService: NpsReviewsFilterService) {}

    public ngOnInit(): void {
        this.currentValue = this.filterService.filter.source;
    }

    public filterBySource(value: ReviewSource[]): void {
        this.filterService.clear(false);
        this.filterService.filterBySource(value);
    }
}
