import { Move } from '@app/move/interfaces/move';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getMoves, getMovesFailed, getMovesSuccess } from './real-estate-agent-dashboard.actions';

export interface RealEstateAgentDashboardState extends EntityState<Move> {
    loading: boolean;
    count: number;
}

export const adapter: EntityAdapter<Move> = createEntityAdapter<Move>({
    selectId: (move: Move) => move._id,
});

export const initialState: RealEstateAgentDashboardState = adapter.getInitialState({
    loading: false,
    count: 0,
});

const reducer = createReducer(
    initialState,

    on(getMoves, (state) => ({
        ...state,
        loading: true,
    })),

    on(getMovesSuccess, (state, action) => {
        return adapter.setAll(action.response?.data, {
            ...state,
            loading: false,
            count: action.response?.count,
        });
    }),

    on(getMovesFailed, (state) => ({
        ...state,
        loading: false,
    }))
);

export function realEstateAgentDashboardReducer(state: RealEstateAgentDashboardState | undefined, action: Action) {
    return reducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getRealEstateAgentDashboardState = createFeatureSelector<RealEstateAgentDashboardState>('real-estate-agent-dashboard');

export const getMovesState = createSelector(getRealEstateAgentDashboardState, selectAll);

export const getCount = createSelector(getRealEstateAgentDashboardState, (realEstateAgentDashboard) => realEstateAgentDashboard?.count);

export const getLoading = createSelector(getRealEstateAgentDashboardState, (realEstateAgentDashboard) => realEstateAgentDashboard?.loading);
