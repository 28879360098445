<p class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_FIXED_FEE' | translate }}
    {{ details.fixedFee | price }}
</p>
<p class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.TOTAL_EXTRA_COST' | translate }}
    {{ details.totalExtraCost | price }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricitySingle">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_SINGLE.USAGE' | translate }}
    {{ details.electricitySingle | kwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityCostSingle">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PRICE_SINGLE' | translate }}
    {{ details.electricityCostSingle | pricePerKwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityCostDoubleDay">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PRICE_DOUBLE_DAY' | translate }}
    {{ details.electricityCostDoubleDay | pricePerKwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityCostDoubleNight">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PRICE_DOUBLE_NIGHT' | translate }}
    {{ details.electricityCostDoubleNight | pricePerKwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityCostExclusiveNight">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PRICE_EXCLUSIVE_NIGHT' | translate }}
    {{ details.electricityCostExclusiveNight | pricePerKwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityDoubleDay">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_DOUBLE_DAY.USAGE' | translate }}
    {{ details.electricityDoubleDay | kwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityDoubleNight">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_DOUBLE_NIGHT.USAGE' | translate }}
    {{ details.electricityDoubleNight | kwh }}
</p>
<p class="u-margin-bottom" *showIfNumber="details.electricityExclusiveNight">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_EXCLUSIVE_NIGHT.USAGE' | translate }}
    {{ details.electricityExclusiveNight | kwh }}
</p>
<p *showIfNumber="details.distribution" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_DISTRIBUTION_PRICE' | translate }}
    {{ details.distribution | price }}
</p>
<p *showIfNumber="details.transmission" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_TRANSMISSION_PRICE' | translate }}
    {{ details.transmission | price }}
</p>
<p *showIfNumber="details.capacityTariff" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.CAPACITY_TARIFF' | translate }}
    {{ details.capacityTariff | price }}
</p>
<p *showIfNumber="details.charges" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_CHARGES_PRICE' | translate }}
    {{ details.charges | price }}
</p>
<p *showIfNumber="details.contributionEnergyFund" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.CONTRIBUTION_ENERGY_FUND_PRICE' | translate }}
    {{ details.contributionEnergyFund | price }}
</p>
<p *showIfNumber="details.injection" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION' | translate }}
    {{ details.injection | kwh }}
</p>
<p *showIfNumber="details.injectionSingle" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_SINGLE' | translate }}
    {{ details.injectionSingle | pricePerKwh }}
</p>
<p *showIfNumber="details.injectionDoubleDay" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_DOUBLE_DAY' | translate }}
    {{ details.injectionDoubleDay | pricePerKwh }}
</p>
<p *showIfNumber="details.injectionDoubleNight" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_DOUBLE_NIGHT' | translate }}
    {{ details.injectionDoubleNight | pricePerKwh }}
</p>
<p *showIfNumber="details.injectionFee" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE' | translate }}
    {{ details.injectionFee | price }}
</p>
<p *showIfNumber="details.injectionFeeDoubleDay" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE_DOUBLE_DAY' | translate }}
    {{ details.injectionFeeDoubleDay | price }}
</p>
<p *showIfNumber="details.injectionFeeDoubleNight" class="u-margin-bottom">
    {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE_DOUBLE_NIGHT' | translate }}
    {{ details.injectionFeeDoubleNight | price }}
</p>
