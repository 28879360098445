import { Navigation } from '@app/navigation/enums/navigation.enum';
import { FinancialReportContainer } from '@app/real-estate-agent/containers/financial-report/financial-report.container';
import { InvoicesContainer } from '@app/real-estate-agent/containers/invoices/invoices.container';
import { QuarterReportContainer } from '@app/real-estate-agent/containers/quarter-report/quarter-report.container';
import { Route } from "@angular/router";

export const financialRouting: Route = {
    path: Navigation.FinancialReport,
    children: [
        {
            path: '',
            component: FinancialReportContainer,
            children: [
                {
                    path: '',
                    redirectTo: Navigation.QuarterReport,
                    pathMatch: 'full',
                },
                {
                    path: Navigation.QuarterReport,
                    component: QuarterReportContainer,
                },
                {
                    path: Navigation.Invoices,
                    component: InvoicesContainer,
                },
            ],
        },
    ],
};
