import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { CheckedComponent } from '@app/ui/components/checked/checked.component';
import { ExpansionPanelComponent } from '@app/ui/components/expansion-panel/expansion-panel.component';
import { SeparatorComponent } from '@app/ui/components/separator/separator.component';
import {
    BadgeModule,
    ButtonModule,
    CardsModule,
    ChartsModule,
    ColorBulletModule,
    GlobalRippleOptions,
    IconModule,
    MenuModule,
    SvgModule,
    TooltipModule,
} from '@smooved/ui';
import { defaultModalMaxWidth } from 'projects/ui/src/lib/modal/constants/modal.constants';
import { EmptyStateModule } from './empty-state/empty-state.module';
import { LoadingModule } from './loading/loading.module';
import { NavigationModule } from './navigation/navigation.module';
import { PowerBarsModule } from './power-bars/power-bars.module';
import { WizardModule } from './wizard/wizard.module';
import { StarsComponent } from '@app/ui/components/stars/stars.component';

@NgModule({
    declarations: [ExpansionPanelComponent, CheckedComponent, SeparatorComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatProgressBarModule,
        RouterModule,
        SvgModule,
        ChartsModule,
        TooltipModule,
        IconModule,
        ColorBulletModule,
        LoadingModule,
        CardsModule,
        BadgeModule,
        NavigationModule,
        MenuModule,
        EmptyStateModule,
        PowerBarsModule,
        ButtonModule,
        WizardModule,
        StarsComponent,
    ],
    exports: [
        ExpansionPanelComponent,
        CheckedComponent,
        SvgModule,
        ChartsModule,
        TooltipModule,
        IconModule,
        ColorBulletModule,
        LoadingModule,
        CardsModule,
        NavigationModule,
        MenuModule,
        BadgeModule,
        EmptyStateModule,
        PowerBarsModule,
        ButtonModule,
        WizardModule,
        SeparatorComponent,
        StarsComponent,
    ],
    providers: [
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: new GlobalRippleOptions() },
        // Below added as backup in case module still uses the old UI module. Also provided by ModalModule
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: defaultModalMaxWidth, hasBackdrop: true } },
    ],
})
export class UiModule {}
