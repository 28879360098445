import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@smooved/ui';
import { FormModule } from '../../form/form.module';
import { ModalModule } from '../../modal/modal.module';
import { SharedModule } from '../../shared/shared.module';
import { EanCodesComponent } from './components/ean-codes/ean-codes.component';
import { ElectricityDetailsComponent } from './components/electricity-details/electricity-details.component';
import { ElectricityGasListSuggestionComponent } from './components/electricity-gas-list-suggestion/electricity-gas-list-suggestion.component';
import { ElectricityGasPriceDetailsModalComponent } from './components/electricity-gas-price-details-modal/electricity-gas-price-details-modal.component';
import { ElectricityGasSuggestionComponent } from './components/electricity-gas-suggestion/electricity-gas-suggestion.component';
import { ElectricityListSuggestionComponent } from './components/electricity-list-suggestion/electricity-list-suggestion.component';
import { ElectricityPriceDetailsModalComponent } from './components/electricity-price-details-modal/electricity-price-details-modal.component';
import { ElectricitySuggestionComponent } from './components/electricity-suggestion/electricity-suggestion.component';
import { GasListSuggestionComponent } from './components/gas-list-suggestion/gas-list-suggestion.component';
import { GasPriceDetailsModalComponent } from './components/gas-price-details-modal/gas-price-details-modal.component';
import { GasSuggestionComponent } from './components/gas-suggestion/gas-suggestion.component';
import { MeterTypeComponent } from './components/meter-type/meter-type.component';
import { CheapestPipe } from './pipes/cheapest.pipe';
import { FixedTariffPipe } from './pipes/fixed-tariff.pipe';
import { GreenestPipe } from './pipes/greenest.pipe';
import { EnergyEffects } from './state/energy.effects';
import { energyReducer } from './state/energy.reducer';

@NgModule({
    imports: [
        SharedModule,
        FormModule,
        TranslateModule,
        ModalModule,
        StoreModule.forFeature('energy', energyReducer),
        EffectsModule.forFeature([EnergyEffects]),
        DirectivesModule,
    ],
    declarations: [
        MeterTypeComponent,
        EanCodesComponent,
        ElectricitySuggestionComponent,
        ElectricityListSuggestionComponent,
        GasSuggestionComponent,
        GasListSuggestionComponent,
        ElectricityGasSuggestionComponent,
        ElectricityGasListSuggestionComponent,
        GreenestPipe,
        CheapestPipe,
        FixedTariffPipe,
        ElectricityPriceDetailsModalComponent,
        GasPriceDetailsModalComponent,
        ElectricityGasPriceDetailsModalComponent,
        ElectricityDetailsComponent,
    ],
})
export class EnergyModule {}
