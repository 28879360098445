import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonAppearance, MessageTemplateComponent, UiContext } from '@smooved/ui';

@Component({
    selector: 'smvd-app-version-check-message',
    templateUrl: 'version-check-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionCheckMessageComponent extends MessageTemplateComponent {
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    public reload(): void {
        window.location.reload();
    }
}
