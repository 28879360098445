<app-closable-modal-template maxWidth="none">
    <ng-container modal-header>
        <h5>{{ "MOVE.SERVICE_ACTIONS.WATER.ADD_EXTRA_DATA.TITLE" | translate }}</h5>
        <p class="u-font-size-small u-color-muted">{{ (moveSandbox.move$ | async)?.user?.formattedMovingAddress }}</p>
    </ng-container>

    <app-loading-container [loading]="!(moveSandbox.move$ | async) || loading">
        <div [formGroup]="form" class="u-flex-column u-flex-align-items-end u-margin-half">
            <div class="u-flex-column u-w100p">
                <app-date-input
                    [formControlName]="waterDetailFields.MovingDate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.MOVING_DATE' | translate"
                ></app-date-input>

                <smvd-ui-text-input
                    class="u-margin-top"
                    [formControlName]="waterDetailFields.NationalRegistrationNumber"
                    [placeholder]="placeholders[waterDetailFields.NationalRegistrationNumber]"
                    [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate"
                ></smvd-ui-text-input>

                <smvd-ui-dropdown-input
                    [formControlName]="waterDetailFields.WaterSupplier"
                    [options]="waterSupplierOptions$ | async"
                    [placeholder]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.PLACEHOLDER' | translate"
                    [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate"
                ></smvd-ui-dropdown-input>

                <div class="u-flex-responsive">
                    <smvd-ui-text-input
                        class="u-flex-grow-1"
                        [formControlName]="waterDetailFields.MeterNumber"
                        [placeholder]="placeholders[waterDetailFields.MeterNumber]"
                        [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate"
                    ></smvd-ui-text-input>
                    <smvd-ui-number-input
                        id="meter-reading-input"
                        class="u-flex-grow-1"
                        [showButtons]="false"
                        [formControlName]="waterDetailFields.MeterReading"
                        [placeholder]="placeholders[waterDetailFields.MeterReading]"
                        [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate"
                    ></smvd-ui-number-input>
                </div>
            </div>

            <button id="submit-button" class="u-margin-top" smvd-ui-button [context]="context.Secondary" (click)="completeWaterData()">
                {{ 'SAVE' | translate }}
            </button>
        </div>
    </app-loading-container>
</app-closable-modal-template>
