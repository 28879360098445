import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, CheckInput, ClosableModalTemplateComponent, UiContext } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { DeleteMoveForm, removeLinkedMoveOption } from './delete-move.constants';

@Component({
    selector: 'app-delete-move-modal',
    templateUrl: 'delete-move.modal.html',
})
export class DeleteMoveModal extends RxjsComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public readonly formFields = DeleteMoveForm;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonContext = UiContext;
    public removeLinkedMoveOption: CheckInput<boolean>;

    public form = this.formBuilder.group({
        [DeleteMoveForm.RemoveLinkedMove]: null,
        [DeleteMoveForm.Reason]: [null, Validators.required],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly moveSandbox: MoveSandbox,
        @Inject(MAT_DIALOG_DATA) public data: Move
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.data?.linkedMove) this.form.get(DeleteMoveForm.RemoveLinkedMove).patchValue(true);
        const role = ((this.data.linkedMove as Move)?.user?.role ?? '').toUpperCase();
        this.translateService
            .stream(`MOVE.MOVER_ROLE.${role}`)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: string) => {
                this.removeLinkedMoveOption = removeLinkedMoveOption(value.toLowerCase());
            });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.moveSandbox.delete(DbUtils.getStringId(this.data), this.form.value, () => this.closableModalComponent.close(true));
    }
}
