import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbUtils, RealEstateAgency } from '@smooved/core';
import { ButtonAppearance, UiContext } from '@smooved/ui';
import { formControlNames } from './location-form.constants';
import { LocationFormConfig } from './location-form.interface';

@Component({
    selector: 'app-location-form-modal',
    templateUrl: './location-form.modal.html',
})
export class LocationFormModal implements OnInit {
    public form: UntypedFormGroup;
    public isEdit: boolean;

    public readonly formControlNames = formControlNames;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: LocationFormConfig,
        private readonly fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<any>
    ) {}

    public ngOnInit(): void {
        const dialogDatalocation = this.dialogData?.location || ({} as RealEstateAgency);
        this.form = this.fb.group({
            [formControlNames.name]: [dialogDatalocation.name, Validators.required],
            [formControlNames.email]: [dialogDatalocation.email, [Validators.required, Validators.email]],
            [formControlNames.phoneNumber]: dialogDatalocation.phoneNumber,
            [formControlNames.address]: [dialogDatalocation.address, Validators.required],
        });

        this.isEdit = !!dialogDatalocation._id;
    }

    public onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        this.dialogRef.close(this.locationFactory());
    }

    public close(): void {
        this.dialogRef.close();
    }

    private locationFactory(): RealEstateAgency {
        const result = this.form.getRawValue();
        const locationId = DbUtils.getStringId(this.dialogData?.location);
        if (locationId) {
            result._id = locationId;
        }
        return result;
    }
}
