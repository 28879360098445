import { Component, OnInit } from '@angular/core';
import { columns } from '@app/real-estate-agent/containers/settings-offices/settings-offices.constants';
import { CreateOfficeModal } from '@app/real-estate-agent/modals/create-office/create-office.modal';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-settings-offices-container',
    templateUrl: './settings-offices.container.html',
})
export class SettingsOfficesContainer implements OnInit {
    dataSource: { name: string; realEstateAgents: number }[];

    public columns = columns;

    constructor(private readonly realEstateGroupSandbox: RealEstateGroupSandbox, private readonly modalSandbox: ModalSandbox) {}

    public ngOnInit(): void {
        this.fetch();
    }

    public onCreate(): void {
        const configFactory = {};
        const handlerFactory = (reFetch) => {
            if (reFetch) {
                this.fetch();
            }
        };
        this.modalSandbox.openModal(CreateOfficeModal, configFactory, handlerFactory, CreateOfficeModal, configFactory, handlerFactory);
    }

    private fetch(): void {
        this.realEstateGroupSandbox.getRealEstateGroupForMe().subscribe((realEstateGroup) => {
            this.dataSource = realEstateGroup.offices?.map((office) => ({
                name: office.name,
                realEstateAgents: office.realEstateAgents?.length,
            }));
        });
    }
}
