import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';

export const i18nKeys = {
    ...AppI18nKeyType,
    energy: 'ENERGY.LABEL',
    telecom: 'TELECOM.LABEL',
    water: 'WATER.LABEL',
    insurances: 'INSURANCES.LABEL',
    rentalInspection: 'ORDERS.ORDER_SCOPE.PRODUCT.RENTALINSPECTION',
};
