import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@smooved/core';
import { AuthorCaption } from './author-caption.interface';

@Pipe({
    name: 'authorCaption',
})
export class AuthorCaptionPipe implements PipeTransform {
    transform(value: AuthorCaption, emptyAs: string = ''): string {
        if (!value || value.isAnonymous) return emptyAs;
        return (
            value.name ||
            [StringUtils.capitalize(value.firstName), value.lastName ? StringUtils.capitalize(value.lastName[0]) + '.' : '']
                .filter((x) => x)
                .join(' ') ||
            value.email?.split('@')[0] ||
            emptyAs
        );
    }
}
