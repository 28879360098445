import { Role } from '@smooved/core';
import { QuestionContainerType } from '../enums/question-container-type.enum';
import { Question } from '../interfaces/meta/question';
import { QuestionContainer } from '../interfaces/meta/question-container';

export class SurveyUtils {
    public static isQuestionContainer(question: Question | QuestionContainer): boolean {
        return question.type === QuestionContainerType.Container;
    }

    public static interviewVia(createdBy: Role): string {
        switch (createdBy) {
            case Role.Admin:
                return 'PHONE';
            case Role.Mover:
                return 'EMAIL';
            case Role.Guest:
                return 'HOUSEMATCH';
            case Role.Client:
                return 'client';
            default:
                return '';
        }
    }

    public static interviewViaIcon(createdBy: Role): string {
        switch (createdBy) {
            case Role.Admin:
                return 'phone';
            case Role.Mover:
                return 'email';
            case Role.Guest:
                return 'house';
            default:
                return '';
        }
    }
}
