import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { Language } from '../enums';
import { ArrayUtils } from '../utils/array.utils';
import { EnumUtils } from '../utils/enum-utils';
import { ObjectUtils } from '../utils/object-utils';

const defaultTranslationFields = ['label', 'description', 'tag'];
const languageFields = [{ source: 'labels', target: 'label' }];

@Pipe({ name: 'translateProp', pure: false })
export class TranslatePropPipe<T> implements PipeTransform, OnDestroy {
    public value: T | T[];
    private asyncPipe: AsyncPipe;

    constructor(private readonly translateService: TranslateService, private readonly cdr: ChangeDetectorRef) {}

    public transform(value: T | T[], propertyToTranslate = defaultTranslationFields): T | T[] {
        const items = ArrayUtils.toArray(value);
        const props = ArrayUtils.toArray(propertyToTranslate);

        const translatedObj = this.translateService.onLangChange.pipe(
            startWith(this.translateService.currentLang),
            map((): T | T[] => {
                if (!items) return items;
                const translated = items.map((item) => {
                    const obj = { ...item };
                    languageFields.forEach(this.pickLanguage(obj));
                    props.forEach(this.translateProps(obj));
                    return obj;
                });

                return Array.isArray(value) ? [...translated] : translated[0];
            })
        );
        this.dispose(); // Make sure there is no  existing AsyncPipe left
        this.asyncPipe = new AsyncPipe(this.cdr);
        return this.asyncPipe.transform(translatedObj);
    }

    private dispose(): void {
        this.asyncPipe?.ngOnDestroy();
    }

    public ngOnDestroy(): void {
        this.dispose();
    }

    private isLanguageProp(obj: object): boolean {
        return ObjectUtils.isObject(obj) && EnumUtils.values(Language).some((language: string) => !!obj[language]);
    }

    private pickLanguage(obj: T) {
        return (prop): void => {
            if (this.isLanguageProp(obj[prop.source])) {
                obj[prop.target] = obj[prop.source][this.translateService.currentLang] as string;
            }
        };
    }

    private translateProps(obj: T) {
        return (prop): void => {
            if (!obj[prop]) return;
            obj[prop] = this.translateService.instant(obj[prop], obj[`${prop}Interpolation`]) as string;
        };
    }
}
