import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EscalationCategory } from '@app/escalation/interfaces/category';
import { EscalationsRequest } from '@app/escalation/interfaces/escalations-request';
import { EscalationSandbox } from '@app/escalation/sandboxes/escalation.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { HttpUtils, ObjectUtils, PaginationRequest, PaginationResponse, StringUtils } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { actionUri, baseUri, byIdUri, escalationCategoriesUri } from '../constants/uri.constants';
import { EscalationProvidersModule } from '../escalation-providers.module';
import { CreateEscalationAction } from '../interfaces/action';
import { CreateEscalation, Escalation, UpdateEscalation } from '../interfaces/escalation';

@Injectable({ providedIn: EscalationProvidersModule })
export class EscalationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly escalationSandbox: EscalationSandbox
    ) {}

    public createEscalation(payload: CreateEscalation): Observable<void> {
        this.escalationSandbox.uiLoading(true);
        ObjectUtils.removeEmpty(payload);
        return this.httpClient.post<void>(baseUri, payload).pipe(
            tap(() => this.notificationSandbox.createdSuccess()),
            finalize(() => this.escalationSandbox.uiLoading(false))
        );
    }

    public updateEscalation(id: string, payload: UpdateEscalation): Observable<void> {
        this.escalationSandbox.uiLoading(true);
        ObjectUtils.removeEmpty(payload);
        return this.httpClient.patch<void>(StringUtils.parseUri(byIdUri, { id }), payload).pipe(
            tap(() => this.notificationSandbox.updatedSuccess()),
            finalize(() => this.escalationSandbox.uiLoading(false))
        );
    }

    public deleteEscalation(id: string): Observable<void> {
        this.uiSandbox.moveLoading(true);
        this.escalationSandbox.uiLoading(true);
        return this.httpClient.delete<void>(StringUtils.parseUri(byIdUri, { id })).pipe(
            tap(() => this.notificationSandbox.deletedSuccess()),
            finalize(() => {
                this.uiSandbox.moveLoading(false);
                this.escalationSandbox.uiLoading(false);
            })
        );
    }

    public getEscalationCategories(): Observable<EscalationCategory[]> {
        return this.httpClient.get<EscalationCategory[]>(escalationCategoriesUri);
    }

    public getEscalations(request: EscalationsRequest, pagination: PaginationRequest): Observable<PaginationResponse<Escalation>> {
        const params = HttpUtils.buildPaginationRequest(pagination, request);
        return this.httpClient.get<PaginationResponse<Escalation>>(baseUri, { params });
    }

    public createAction(id: string, payload: CreateEscalationAction): Observable<void> {
        return this.httpClient.post<void>(StringUtils.parseUri(actionUri, { id }), payload);
    }
}
