import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@smooved/core';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { LoadingModule } from '../loading';
import { TableModule } from '../table';
import { UiLibModule } from '../ui.module';
import { ClosableModalTemplateComponent } from './components/closable-modal-template/closable-modal-template.component';
import { ClosableMobileModalComponent } from './components/closable-modal/closable-mobile-modal.component';
import { ClosableModalComponent } from './components/closable-modal/closable-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { HubspotModal } from './components/hubspot-modal/hubspot-modal.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { MobileModalComponent } from './components/mobile-modal/mobile-modal.component';
import { TableModalComponent } from './components/table-modal/table-modal.component';
import { TextModalComponent } from './components/text-modal/text-modal.component';
import { TriPanelModalComponent } from './components/tri-panel/tri-panel.modal';
import { TwoPanelModalComponent } from './components/two-panel/two-panel.modal';
import { defaultModalMaxWidth } from './constants/modal.constants';
import { ModalSandbox } from './sandboxes/modal.sandbox';

const components = [
    ClosableModalTemplateComponent,
    ClosableModalComponent,
    ClosableMobileModalComponent,
    ConfirmModalComponent,
    ImageModalComponent,
    TextModalComponent,
    MobileModalComponent,
    TwoPanelModalComponent,
    TriPanelModalComponent,
    TableModalComponent,
    HubspotModal,
];

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        IconModule,
        ButtonModule,
        UiLibModule,
        CoreModule,
        DragDropModule,
        LoadingModule,
        TableModule,
    ],
    declarations: [...components],
    providers: [
        ModalSandbox,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { maxWidth: defaultModalMaxWidth, hasBackdrop: true },
        },
    ],
    exports: [...components],
})
export class UiModalModule {}
