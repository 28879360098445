import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Svg } from '../../../icon/icon.enums';
import { CheckedState } from './svg-checked.enum';

@Component({
    selector: 'app-svg-checked',
    template: `
        <div class="u-position-relative u-inline-flex-row u-flex-align-items-center u-flex-justify-content-center" [ngSwitch]="checked">
            <app-svg class="__svg" [name]="name"></app-svg>
            <app-svg *ngSwitchCase="state.Ok" [name]="okSvg" class="__check" [title]="title"></app-svg>
            <app-svg *ngSwitchCase="state.Nok" [name]="nokSvg" class="__error" [title]="title"></app-svg>
        </div>
    `,
    styleUrls: ['./svg-checked.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class SvgCheckedComponent {
    @Input() public name: Svg;
    @Input() public checked: CheckedState = CheckedState.Nok;
    @Input() public title: string;

    public okSvg = Svg.Ok;
    public nokSvg = Svg.Nok;
    public state = CheckedState;
}
