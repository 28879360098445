import { ObjectUtils } from './object-utils';

export class DbUtils {
    public static getStringId(obj: any): string | null {
        if (DbUtils.isObjectId(obj)) {
            return obj.toString();
        } else if (obj?.hasOwnProperty('id') || !!obj?.id) {
            return obj.id?.toString();
        } else if (obj?.hasOwnProperty('_id') || !!obj?._id) {
            return obj._id?.toString();
        } else {
            return null;
        }
    }

    public static isObjectId(value: any): boolean {
        if (!value) return false;
        const valueToCheck = value.toString();
        return typeof valueToCheck === 'string' && /^[a-fA-F0-9]{24}$/.test(valueToCheck);
    }

    public static isDocument(doc: any): boolean {
        return ObjectUtils.isObject(doc) && !!doc._id;
    }
}
