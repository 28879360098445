<form novalidate [formGroup]="form">
    <div class="u-grid u-grid-col-3-repeat u-margin-bottom-double">
        <app-label-container
            [label]="'MULTI_CHANNEL.ADDRESSEE' | i18nKeyTranslate: i18nKeytype.ContactLog"
            [hasMarginDouble]="false"
            [hasMargin]="false"
            [bold]="true"
        >
            <app-contacts [move]="data?.move" [formControlName]="mailFields.to"></app-contacts>
        </app-label-container>
    </div>

    <app-textarea-input
        class="u-w100p"
        [rows]="7"
        [hasMargin]="false"
        [hasMarginDouble]="true"
        [label]="'MULTI_CHANNEL.BODY' | i18nKeyTranslate: i18nKeytype.ContactLog"
        [placeholder]="'PLACEHOLDER.TEXT.LABEL' | translate"
        [formControlName]="mailFields.content"
    ></app-textarea-input>

    <div class="u-flex-row u-margin-bottom-double">
        <smvd-ui-dropdown-input
            [formControlName]="mailFields.escalation"
            [label]="'CONNECT_ESCALATION' | i18nKeyTranslate: i18nKeytype.ContactLog"
            [options]="escalationOptions | translateProp"
        ></smvd-ui-dropdown-input>
        <smvd-ui-dropdown-input
            [formControlName]="mailFields.state"
            [label]="'STATE.TITLE' | i18nKeyTranslate: i18nKeytype.Escalation"
            [options]="stateOptions"
            class="u-margin-left"
        ></smvd-ui-dropdown-input>
    </div>
</form>
