import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { notifyAccountManagerOption, serviceOptions, stateOptions, tagOptions } from '@app/escalation/constants/escalation.constants';
import { EscalationFormControls } from '@app/escalation/enums/form.enum';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { EscalationCategory } from '@app/escalation/interfaces/category';
import { CreateEscalation, Escalation, UpdateEscalation } from '@app/escalation/interfaces/escalation';
import { EscalationForm } from '@app/escalation/interfaces/form';
import { EscalationSandbox } from '@app/escalation/sandboxes/escalation.sandbox';
import { EscalationService } from '@app/escalation/services/escalation.service';
import { Move } from '@app/move/interfaces/move';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import { AuditUtils, DbUtils, ObjectUtils, RxjsComponent, StringUtils } from '@smooved/core';
import { DropdownInput, NotificationSandbox, UiHeaderVariant } from '@smooved/ui';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-escalation',
    templateUrl: './escalation.modal.html',
    styleUrls: ['./escalation.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EscalationModal extends RxjsComponent implements OnInit {
    @Input() public escalation: Escalation;
    @Input() public userId: string;
    @Input() public move: Move;

    public readonly formControl = EscalationFormControls;
    public readonly tagOptions = tagOptions;
    public readonly stateOptions = stateOptions;
    public readonly notifyAccountManagerOption = notifyAccountManagerOption;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly uiHeaderVariant = UiHeaderVariant;
    public readonly serviceOptions = serviceOptions;
    public readonly form: UntypedFormGroup = this.formBuilder.group({
        [EscalationFormControls.Category]: [null, [Validators.required]],
        [EscalationFormControls.Tag]: [null, [Validators.required]],
        [EscalationFormControls.State]: [EscalationState.Open, [Validators.required]],
        [EscalationFormControls.Service]: null,
        [EscalationFormControls.SupplierGroup]: null,
        [EscalationFormControls.ServicedBy]: null,
        [EscalationFormControls.AccountManager]: null,
        [EscalationFormControls.NotifyAccountManager]: false,
        [EscalationFormControls.ExternalUrls]: null,
        [EscalationFormControls.Description]: [null, [Validators.required]],
    });

    public loading$ = this.escalationSandbox.uiLoading$;
    public servicedByOptions: DropdownInput<string>[] = [];
    public accountManagersOptions: DropdownInput<string>[] = [];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly escalationSandbox: EscalationSandbox,
        private readonly escalationService: EscalationService,
        private readonly userSandbox: UserSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly dialogRef: MatDialogRef<EscalationModal>
    ) {
        super();
    }

    public ngOnInit(): void {
        this.prePopulateForm();
        this.populateEscalation();
        this.listenOnCategoryChanges();
        this.userSandbox.getServicedByOptions().subscribe((result) => (this.servicedByOptions = result));
        this.userSandbox.getAccountManagerOptions().subscribe((result) => (this.accountManagersOptions = result));
        this.escalationSandbox.getEscalationCategories();
    }

    private populateEscalation(): void {
        if (!this.escalation) return;
        const { category, state, tag, service, supplierGroup, servicedBy, accountManager, externalUrls, description } = this.escalation;
        const payload: EscalationForm = {
            category: DbUtils.getStringId(category),
            state: AuditUtils.getValue(state),
            tag,
            service,
            supplierGroup: DbUtils.getStringId(supplierGroup),
            servicedBy: DbUtils.getStringId(servicedBy),
            accountManager: DbUtils.getStringId(accountManager),
            externalUrls: StringUtils.join(externalUrls, '; '),
            description,
        };
        this.form.patchValue(payload);
    }

    private prePopulateForm(): void {
        this.form.get(EscalationFormControls.ServicedBy).patchValue(this.userId);
        this.form.get(EscalationFormControls.AccountManager).patchValue(DbUtils.getStringId(this.move?.realEstateGroup?.accountManager));
    }

    private listenOnCategoryChanges(): void {
        this.form
            .get(EscalationFormControls.Category)
            .valueChanges.pipe(
                takeUntil(this.destroy$),
                mergeMap((id: string) =>
                    this.escalationSandbox.categories$.pipe(
                        map((categories) => categories.find((category) => DbUtils.getStringId(category) === id))
                    )
                )
            )
            .subscribe((result: EscalationCategory) => {
                if (result?.defaultTag) {
                    this.form.get(EscalationFormControls.Tag).patchValue(result.defaultTag);
                }
            });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        DbUtils.getStringId(this.escalation) ? this.update() : this.create();
    }

    private create(): void {
        const {
            category,
            tag,
            accountManager,
            description,
            state,
            service,
            servicedBy,
            notifyAccountManager,
            externalUrls,
            supplierGroup,
        } = <EscalationForm>this.form.getRawValue();
        const payload: CreateEscalation = {
            move: DbUtils.getStringId(this.move),
            category,
            tag,
            service,
            state,
            accountManager,
            description,
            servicedBy,
            supplierGroup,
            externalUrls: StringUtils.splitBy(externalUrls),
            notifyAccountManager: !!notifyAccountManager,
        };
        this.escalationService.createEscalation(payload).subscribe(() => {
            this.dialogRef.close(true);
        });
    }

    private update(): void {
        const values = <EscalationForm>this.form.getRawValue();
        const payload = ObjectUtils.difference(
            { ...values, externalUrls: StringUtils.splitBy(values.externalUrls) },
            {
                ...this.escalation,
                category: DbUtils.getStringId(this.escalation.category),
                supplierGroup: DbUtils.getStringId(this.escalation.supplierGroup),
                servicedBy: DbUtils.getStringId(this.escalation.servicedBy),
                accountManager: DbUtils.getStringId(this.escalation.accountManager),
            }
        ) as UpdateEscalation;
        this.escalationService.updateEscalation(DbUtils.getStringId(this.escalation), payload).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
