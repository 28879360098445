import { User } from '@app/user/interfaces/user';
import { setInviteData } from '@app/user/state/user.actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { merge } from 'lodash';

export interface UserState {
    inviteData?: User;
}

export const initialUserState: UserState = {};

const reducer = createReducer(
    initialUserState,

    on(setInviteData, (state, action) => ({
        ...state,
        inviteData: merge({ ...state.inviteData }, { ...action.inviteData }),
    }))
);

export function userReducer(state: UserState | undefined, action: Action) {
    return reducer(state, action);
}

export const getUserState = createFeatureSelector<UserState>('user');
export const getInviteDataState = createSelector(getUserState, (state) => state?.inviteData);
export const getFirstNameInviteDataState = createSelector(getInviteDataState, (state) => state?.firstName);
