import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Navigation } from '@app/navigation/enums/navigation.enum';

@Injectable({ providedIn: 'root' })
export class RedirectSandbox {
    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly router: Router
    ) {}

    public toDashboard(): Observable<boolean> {
        return zip(this.authenticationSandbox.isRealEstateAgent$, this.authenticationSandbox.isPartnerAgent$).pipe(
            take(1),
            map(this.redirect)
        );
    }

    private redirect = ([isRealEstateAgent, isPartnerAgent]): boolean => {
        if (isRealEstateAgent) {
            void this.navigationSandbox.goToRealEstateAgentStart();
            return false;
        } else if (isPartnerAgent) {
            void this.navigationSandbox.goToPartnerAgentHome();
            return false;
        } else {
            void this.router.navigateByUrl(`/${Navigation.Dashboard}`);
            return true;
        }
    };
}
