import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector, Type } from '@angular/core';
import { CoreProvidersModule } from '../core.providers.module';

@Injectable({ providedIn: CoreProvidersModule })
export class DomService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private readonly document
    ) {}

    public createCanonicalUrl(url: string): void {
        const head = this.document.getElementsByTagName('head')[0];
        let element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
        if (!element) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }

    public createAnchor({ url, target, label }: { url: string; target?: string; label: string }): HTMLAnchorElement {
        const el = this.document.createElement('a') as HTMLAnchorElement;
        el.href = url;
        el.target = target;
        el.innerHTML = label;
        return el;
    }

    public createComponent<T>(component: Type<T>, componentProps?: object): ComponentRef<T> {
        // 1. Create a component reference from the component
        const componentRef = this.componentFactoryResolver.resolveComponentFactory<T>(component).create(this.injector);

        if (componentProps && typeof componentRef.instance === 'object') {
            Object.assign(componentRef.instance, componentProps);
        }

        return componentRef;
    }

    public attachComponent<T>(componentRef: ComponentRef<T>, appendTo?: Element): void {
        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        // 3. Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the body
        (appendTo ?? this.document.body).appendChild(domElem);

        return;
    }

    public createAndAttach<T>(component: Type<T>, componentProps?: object, appendTo?: Element): ComponentRef<T> {
        const componentRef = this.createComponent(component, componentProps);
        this.attachComponent(componentRef, appendTo);
        return componentRef;
    }

    public getDataElement<T>(elementId: string): T {
        const element = this.document.getElementById(elementId);
        if (!element) return;

        const data = JSON.parse(unescape(element.getAttribute('data-value'))) as T;
        return data;
    }
}
