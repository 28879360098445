import { Injectable } from '@angular/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationSandbox } from '../../authentication/sandboxes/authentication.sandbox';

@Injectable({
    providedIn: 'root',
})
export class CoBrandingSandbox {
    constructor(
        private authenticationSandbox: AuthenticationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox
    ) {}

    public hasCoBranding$: Observable<boolean> = combineLatest([
        this.navigationSandbox.routeNavigationEndData$,
        this.authenticationSandbox.isRealEstateAgent$,
    ]).pipe(map(([data, isRealEstateAgent]) => (isRealEstateAgent ? true : !!data.coBranding)));
}
