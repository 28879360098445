import { Component } from '@angular/core';
import { escalationsLink, navlinks } from './header-move-filters.constants';

@Component({
    selector: 'app-header-move-filters',
    template: `
        <a *ngFor="let nav of navLinks" [routerLinkActive]="'__link-menu--active'" class="__link-menu" [routerLink]="nav.path">{{
            nav.label | translate
        }}</a>
        <div class="u-bordered-left u-display-inline-block">
            <a [routerLinkActive]="'__link-menu--active'" class="__link-menu" [routerLink]="escalationsLink.path">{{
                escalationsLink.label | translate
            }}</a>
        </div>
    `,
    styleUrls: ['./header-move-filters.component.scss'],
})
export class HeaderMoveFiltersComponent {
    public readonly navLinks = navlinks;
    public readonly escalationsLink = escalationsLink;
}
