import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { BaseInput } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, defaultValue, maxResults, minQueryLength } from './real-estate-group-input.constants';

interface RealEstateGroupInput {
    _id: string;
    name: string;
}

@Component({
    selector: 'app-real-estate-group-input',
    template: `
        <app-label-container
            [required]="required"
            [id]="id"
            [label]="label"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [showRequiredAsterisk]="showRequiredAsterisk"
        >
            <ng-autocomplete
                [data]="realEstateGroups"
                [placeholder]="'REAL_ESTATE_GROUP.LABEL' | translate"
                searchKeyword="name"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear()"
                (inputChanged)="inputChange($event)"
                (selected)="selected()"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                [minQueryLength]="minQueryLength"
                [name]="'real-estate-group'"
                [debounceTime]="debounceTime"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.name"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./real-estate-group-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RealEstateGroupInputComponent),
            multi: true,
        },
    ],
})
export class RealEstateGroupInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: any = defaultValue;
    public realEstateGroups: RealEstateGroupInput[];
    public readonly debounceTime = debounceTime;
    public readonly minQueryLength = minQueryLength;
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private realEstateGroupSandbox: RealEstateGroupSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(realEstateGroup: RealEstateGroupInput): void {
        // first time we need to set the innerModel as the query ( name ). Otherwise the lib will throw an error?
        this.innerModel = realEstateGroup?.name || defaultValue;
    }

    public inputChange(search: string): void {
        this.getResults(search);
    }

    public inputClear(): void {
        setTimeout(() => {
            this.propagateChange(null);
            this.getResults(defaultValue);
        }, 0);
    }

    public selected(): void {
        setTimeout(() => {
            this.propagateChange(this.innerModel);
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }

    private getResults(search: string): void {
        this.loadingSubject.next(true);
        this.realEstateGroupSandbox
            .getRealEstateGroups({
                search,
                pageSize: !search.length ? maxResults : null,
            })
            .subscribe(
                (response) => {
                    this.realEstateGroups = response.data.map(({ _id, name, locations, offices }) => ({
                        _id,
                        name,
                        locations,
                        offices,
                    }));
                },
                (_) => {},
                () => this.loadingSubject.next(false)
            );
    }
}
