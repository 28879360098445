import { Component, Input } from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { UiContext, UiSize } from '../../../ui.enums';
import { ArcScoreData } from '../../../widget-influencer-score';
import { arcScoreSizeMap, arcScoreThicknessMap, endAngleDefault, startAngleDefault } from './arc-score.constants';

@Component({
    selector: 'app-arc-score',
    templateUrl: 'arc-score.component.html',
    styleUrls: ['arc-score.component.scss'],
})
export class ArcScoreComponent implements CanDisable {
    @Input() public data: ArcScoreData;
    @Input() public value: number;
    @Input() public size = UiSize.Medium;
    @Input() public scoreSize: UiSize;
    @Input() public context: UiContext;
    @Input() public showLabels = false;
    @Input() public isMonochrome = false;
    @Input() public disabled: boolean;
    @Input() public houseMatchScore?: number;

    public readonly startAngle = startAngleDefault;
    public readonly endAngle = endAngleDefault;
    public readonly arcScoreSizeMap = arcScoreSizeMap;
    public readonly arcScoreThicknessMap = arcScoreThicknessMap;
}
