<app-tri-panel-modal [loading]="loading">
    <ng-container modal-header>
        <h5>{{move | userName}}</h5>
    </ng-container>
    <ng-container modal-left>
        <app-transferee-detail [move]="move" class="u-margin-bottom-double" [readOnly]="true"></app-transferee-detail>
        <app-moving-address-detail [move]="move"></app-moving-address-detail>
    </ng-container>
    <ng-container modal-right>
        <div class="u-margin-double">
            <h5 class="u-margin-bottom-double">{{'MORTGAGE.OVERVIEW.TITLE' | translate }}</h5>
            <app-check-item [checked]="true" class="u-margin-bottom-triple u-display-block">
                <ng-container ngProjectAs="checked">
                    <div>
                        <p>{{'MORTGAGE.OVERVIEW.DETAILS.APPOINTMENT_SCHEDULED' | translate }}</p>
                        <p class="u-color-muted u-font-size-small">{{ move?.createdOn | formatDate }}</p>
                    </div>
                </ng-container>
            </app-check-item>
            <app-cta-card-inactive-move [move]="move"> </app-cta-card-inactive-move>
        </div>
    </ng-container>
</app-tri-panel-modal>
