<ng-container *ngIf="value">
    <div class="u-display-inline" [innerHTML]="value | limit : 0 : maxChars"></div>
    <a
        app-button
        *ngIf="value?.length > maxChars && maxChars"
        [appearance]="buttonAppearance.Link"
        [size]="buttonSize.Sm"
        (click)="expand()"
        class="u-padding-none u-h-auto u-margin-left-half"
        >{{ 'UI.READ_ALL' | translate }}</a
    ></ng-container
>
