import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';
import { Navigation } from '../../../navigation/enums/navigation.enum';

@Component({
    selector: 'app-header-menu-dashboard',
    template: `
        <ul class="u-flex-row u-flex-align-items-center">
            <li>
                <app-select-language
                    width="40px"
                    class="u-color-gray"
                    [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                ></app-select-language>
            </li>
            <li class="u-margin-left">
                <a
                    class="u-link-muted u-inline-flex-row u-flex-align-items-center u-flex-justify-content-center"
                    [routerLink]="dashboardUrl"
                    [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                >
                    <app-svg [name]="closeSvg" width="15px" height="15px"></app-svg>
                </a>
            </li>
        </ul>
    `,
    styleUrls: ['./header-menu-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuDashboardComponent {
    @Input() fontSizeSmall: boolean;

    public closeSvg = Svg.Close;

    public dashboardUrl = Navigation.Dashboard;
}
