import { ProductState } from '@app/product/state/product.combiner';
import { TranslationState } from '@app/translation/state/translation.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { TelecomState } from '../telecom/state/telecom.reducer';
import { uiReducer, UiState } from '../ui/state/ui.reducer';
import { Move } from '../move/interfaces/move';

export interface State {
    ui?: UiState;
    move?: Move;
    telecom?: TelecomState;
    translation: TranslationState;
    product?: ProductState;
}

export const reducers: ActionReducerMap<any> = {
    ui: uiReducer,
};
