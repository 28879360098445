export const formControlNames = {
    name: 'name',
    email: 'email',
    phoneNumber: 'phoneNumber',
    address: 'address',
};

export enum I18nKeysHeader {
    Add = 'REAL_ESTATE_AGENT.LOCATIONS.CREATE',
    Edit = 'REAL_ESTATE_AGENT.LOCATIONS.EDIT',
}
