import { Component, forwardRef, Host, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BillingSettings } from '@app/real-estate-group/interfaces/billing-settings';
import { DateUtils, skipWhileEmpty } from '@smooved/core';
import { BaseInput } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { billingCycleOptions, BillingSettingsNames } from './billing-settings.constants';

@Component({
    selector: 'smvd-app-billing-settings',
    templateUrl: 'billing-settings.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BillingSettingsComponent),
            multi: true,
        },
    ],
})
export class BillingSettingsComponent extends BaseInput implements ControlValueAccessor, OnInit {
    public readonly billingCycleOptions = billingCycleOptions;
    public readonly billingSettingsNames = BillingSettingsNames;

    public billingForm = this.fb.group({
        [BillingSettingsNames.BillingCycle]: null,
        [BillingSettingsNames.BillingStartDate]: null,
    });

    public innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() public readonly controlContainer: ControlContainer, private readonly fb: UntypedFormBuilder) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        this.billingForm
            .get(BillingSettingsNames.BillingCycle)
            .valueChanges.pipe(takeUntil(this.destroy$), skipWhileEmpty())
            .subscribe((value) => {
                if (!value || !!this.billingForm.get(BillingSettingsNames.BillingStartDate).value) return;
                this.billingForm.get(BillingSettingsNames.BillingStartDate).patchValue(DateUtils.today());
            });
        this.billingForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: BillingSettings) => {
            if (this.billingForm.invalid) {
                this.propagateChange(null);
            } else {
                this.propagateChange(value);
            }
        });
    }

    public writeValue(value: BillingSettings): void {
        this.innerModel = value;
        this.billingForm?.patchValue(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.billingForm?.disable();
        } else {
            this.billingForm?.enable();
        }
        this.billingForm?.updateValueAndValidity();
    }
}
