import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExternalInfluencer } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { UiIconSize } from '../../../icon';
import { UiContext } from '../../../ui.enums';
import { companyPageI18nKeyTypes } from '../../constants/i18n-key-type.map';
import { ModalsService } from '../../services/modals.service';

@Component({
    selector: 'ui-company-cta-box',
    templateUrl: './company-cta-box.component.html',
    styleUrls: ['./company-cta-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCtaBoxComponent {
    @Input() public externalInfluencer: ExternalInfluencer;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public readonly uiContext = UiContext;
    public readonly companyPageI18nKeyTypes = companyPageI18nKeyTypes;

    constructor(private readonly modalsService: ModalsService) {}

    public initAppointment(): void {
        this.modalsService.openAppointment(this.externalInfluencer);
    }

    public initCallback(): void {
        this.modalsService.openCallback(this.externalInfluencer);
    }

    public initMessage(): void {
        this.modalsService.openMessage(this.externalInfluencer);
    }
}
