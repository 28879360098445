import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, tap } from 'rxjs';
import { PosthogService } from '../posthog.service';
import { DbUtils, FeatureScope, FeatureScopeSandbox, PostHogEventsEnum } from '@smooved/core';
import { MoveActionTypes } from '@app/move/state/move.actions';
import { switchMap } from 'rxjs/operators';
import { AuthenticationActionTypes, LogoutSuccess } from '@app/authentication/state/authentication.actions';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AuthUtils } from '@app/authentication/auth.utils';
import { ActivityActionTypes } from '@app/activity-log/activity.actions';
import posthog from 'posthog-js';

@Injectable()
export class PosthogEffects {
    public realEstateAgentLoginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionTypes.RealEstateAgentLoginSuccess, AuthenticationActionTypes.LoginSuccess),
                switchMap((_) =>
                    forkJoin([this.authenticationSandbox.authorizationOnce$, this.realEstateGroupSandbox.realEstateGroupOnce$])
                ),
                tap(([authUser, realEstateGroup]) => {
                    const userId = AuthUtils.isAdmin(authUser) ? DbUtils.getStringId(authUser) : authUser?.realEstateAgentId;
                    this.posthogService.identify(userId, authUser);
                    this.posthogService.groupByCompany(AuthUtils.isAdmin(authUser), realEstateGroup);
                    this.posthogService.groupByOffice(realEstateGroup?.officeId, realEstateGroup);
                })
            ),
        {
            dispatch: false,
        }
    );

    public heartBeat$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSuccess>(ActivityActionTypes.HeartBeat),
                switchMap((_) =>
                    forkJoin([this.authenticationSandbox.authorizationOnce$, this.realEstateGroupSandbox.realEstateGroupOnce$])
                ),
                tap(([authUser, realEstateGroup]) => {
                    const distinctId = posthog.get_distinct_id();
                    const groups = posthog.getGroups();

                    if (authUser && distinctId !== authUser?.realEstateAgentId) {
                        this.posthogService.identify(authUser.realEstateAgentId, authUser);
                    }
                    if (!groups?.company && DbUtils.getStringId(realEstateGroup)) {
                        this.posthogService.groupByCompany(AuthUtils.isAdmin(authUser), realEstateGroup);
                    }
                    if (!groups?.office && DbUtils.getStringId(realEstateGroup?.officeId)) {
                        this.posthogService.groupByOffice(realEstateGroup?.officeId, realEstateGroup);
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    public logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSuccess>(AuthenticationActionTypes.LogoutSuccess),
                tap(() => this.posthogService.resetIdentity())
            ),
        {
            dispatch: false,
        }
    );

    public desktopTransactionEnergyMeterReadingsAttachUploaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsAttachmentUploaded),
                switchMap(({ moveId }: { moveId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(
                                    PostHogEventsEnum.DesktopEnergyMetersEditAttachmentUploaded,
                                    moveId
                                );
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditManual$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsManual),
                switchMap(({ moveId }: { moveId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersEditManual, moveId);
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditConfirmed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsConfirmed),
                switchMap(({ moveId }: { moveId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersConfirmed, moveId);
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly posthogService: PosthogService,
        private readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}
}
