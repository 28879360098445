import { Component } from '@angular/core';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

@Component({
    selector: 'app-real-estate-agent-login-container',
    template: `
        <div class="u-padding-top-double u-flex-row u-flex-justify-content-center">
            <app-real-estate-agent-login
                (formSubmit)="authenticationSandbox.realEstateAgentLogin($event)"
                (goToRegister)="navigationSandbox.goToRealEstateAgentRegister().then()"
            ></app-real-estate-agent-login>
        </div>
    `,
    styles: [],
})
export class RealEstateAgentLoginContainer {
    constructor(public authenticationSandbox: AuthenticationSandbox, public navigationSandbox: AppNavigationSandbox) {}
}
