import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessLevel, NpsReview, StringUtils, UpdateNpsReview } from '@smooved/core';
import { Observable } from 'rxjs';
import * as reviewsUri from '../constants/uri.constants';
import { ReviewsProvidersModule } from '../reviews-providers.module';
import { Review } from '../interfaces/review';

@Injectable({
    providedIn: ReviewsProvidersModule,
})
export class ReviewsService {
    constructor(private httpClient: HttpClient) {}

    public addNote(reviewId: string, note: string, accessLevel: AccessLevel): Observable<NpsReview> {
        return this.httpClient.post<NpsReview>(StringUtils.parseUri(reviewsUri.note, { reviewId }), { note, accessLevel });
    }

    public editNote(reviewId: string, noteId: string, note: string, accessLevel: AccessLevel): Observable<NpsReview> {
        return this.httpClient.patch<NpsReview>(StringUtils.parseUri(reviewsUri.byNoteId, { reviewId, noteId }), {
            note,
            accessLevel,
        });
    }

    public deleteNote(reviewId: string, noteId: string): Observable<NpsReview> {
        return this.httpClient.delete<NpsReview>(StringUtils.parseUri(reviewsUri.byNoteId, { reviewId, noteId }));
    }

    public updateReview(reviewId: string, review: UpdateNpsReview): Observable<NpsReview> {
        return this.httpClient.patch<NpsReview>(StringUtils.parseUri(reviewsUri.review, { reviewId }), review);
    }

    public deleteReview(reviewId: string): Observable<void> {
        return this.httpClient.delete<void>(StringUtils.parseUri(reviewsUri.review, { reviewId }));
    }

    public getPublishedReview(reviewId: string): Observable<Review> {
        return this.httpClient.get<Review>(StringUtils.parseUri(reviewsUri.publishedReview, { reviewId }));
    }
}
