<ng-container *ngIf="isTabletPortraitUp$ | async">
    <app-ui-card [border]="false">
        <a
            app-button
            [title]="office.name"
            [context]="uiContext.Primary"
            [appearance]="buttonAppearance.LinkReversed"
            class="u-color-primary u-margin-none u-display-block u-padding-x-axis-none u-text-truncate"
            *ngFor="let office of offices | sort: 'name'; trackBy: trackBySlug"
            (click)="scrollIntoView(office)"
            >{{ office.name }}</a
        >
    </app-ui-card>
</ng-container>
