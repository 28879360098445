import { CheckInput, UiHeaderVariant } from '@smooved/ui';

export const optionElectricity: CheckInput<boolean> = {
    id: 'has-electricity',
    labelResource: 'ELECTRICITY',
    value: true,
    name: 'has-electricity',
    variant: UiHeaderVariant.H6,
};

export const optionGas: CheckInput<boolean> = {
    id: 'has-gas',
    labelResource: 'GAS',
    value: true,
    name: 'has-gas',
    variant: UiHeaderVariant.H6,
};

export const optionSameSupplier: CheckInput<boolean> = {
    id: 'has-same-supplier',
    labelResource: 'ENERGY.IS_SAME_SUPPLIER',
    value: true,
    name: 'has-same-supplier',
};

export const differentAddress: CheckInput<boolean> = {
    id: 'different-address',
    labelResource: 'ENERGY_TRANSFER.INVOICES.DIFFERENT_ADDRESS',
    value: true,
    name: 'different-address',
};
