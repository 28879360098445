import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AnalyticsSandbox } from '@app/analytics/analytics.sandbox';
import { ArcScoreData } from '@app/external-influencer/interfaces/arc-score-data';
import { RealEstateGroupAnalytics } from '@app/real-estate-group/interfaces/real-estate-group-analytics';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { CardContext, WidgetI18nKeyType } from '@smooved/ui';
import { Observable } from 'rxjs';
import { dashboardRoute, giftsQueryParams, reviewsRoute } from '../analytics-dashboard/analytics-dashboard.constants';
import { filterBadGoodBuckets, filterUnanswered } from './analytics-influencers.constants';

@Component({
    selector: 'app-analytics-influencers',
    templateUrl: 'analytics-influencers.component.html',
    styleUrls: ['analytics-influencers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsInfluencersComponent implements OnInit {
    @Input() public analytics: RealEstateGroupAnalytics;

    public widgetData$: Observable<ArcScoreData>;
    public readonly i18nKeyType = WidgetI18nKeyType;

    public cardContext = CardContext;
    public readonly dashboardRoute = dashboardRoute;
    public readonly reviewsRoute = reviewsRoute;
    public readonly giftsQueryParams = giftsQueryParams;
    public readonly filterBuckets = filterBadGoodBuckets;
    public readonly filterUnanswered = filterUnanswered;

    constructor(public readonly translationSandbox: TranslationSandbox, private readonly analyticsSandbox: AnalyticsSandbox) {}

    public ngOnInit(): void {
        this.widgetData$ = this.analyticsSandbox.getArcScoreData();
    }
}
