import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { InsurancesSandbox } from '@app/iots/sandboxes/insurances.sandbox';
import { WizardComponent } from '@app/move/components/wizard/wizard.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Move } from '@app/move/interfaces/move';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-insurances-familial',
    template: `
        <div *ngIf="!!suggestion" class="u-flex-column u-flex-align-items-center">
            <app-insurances-suggestion
                class="u-display-block u-margin-bottom-double"
                type="familial"
                minHeight="auto"
                (select)="onSelect(suggestion)"
                [suggestion]="suggestion"
            ></app-insurances-suggestion>
            <a class="u-link" (click)="cancel.emit()">{{ 'IOTS.FAMILIAL.CANCEL' | translate }}</a>
        </div>
    `,
    styles: [],
})
export class InsurancesFamilialComponent extends WizardComponent implements OnInit {
    @Output() public notFound: EventEmitter<void> = new EventEmitter<void>();
    @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

    public suggestion: InsurancesSuggestion;

    constructor(
        private insurancesSandbox: InsurancesSandbox,
        private uiSandbox: AppUiSandbox,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private moveSandbox: MoveSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.moveOnce$
            .pipe(
                tap((_) => this.uiSandbox.showLoadingOverlay()),
                switchMap((move) => {
                    const { insurer, quoteId } = move?.insurancesOffer?.insurancesOfferSelection;
                    if (!insurer?.name || !quoteId) return of(null);
                    return this.insurancesSandbox.getFamilial({
                        insurer: move?.insurancesOffer?.insurancesOfferSelection?.insurer?.name,
                        quoteId: move?.insurancesOffer?.insurancesOfferSelection?.quoteId,
                    });
                }),
                finalize(() => this.uiSandbox.hideLoadingOverlay())
            )
            .subscribe((suggestion) => {
                if (!suggestion) {
                    this.notFound.emit();
                }
                this.suggestion = suggestion;
                this.cdr.detectChanges();
            });
    }

    public onSelect(suggestion): void {
        const payload: Move = {
            insurancesSelected: true,
            insurancesOffer: {
                insurancesOfferSelection: suggestion,
            },
        };
        this.moveSandbox.patchProperty('', payload, true, (_) => {
            this.next.emit();
        });
    }
}
