import { Component, OnInit } from '@angular/core';
import { RxjsComponent } from '@smooved/core';
import { ChartItem } from '@smooved/ui';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfirmationsShortPeriodChartService } from './confirmations-short-period-chart.service';
import { labelTranslateKeys, legend, stackColors, stacks } from './confirmatons-short-period-chart.constants';

@Component({
    selector: 'app-confirmations-short-period-chart',
    templateUrl: './confirmations-short-period-chart.component.html',
    styleUrls: ['./confirmations-short-period-chart.component.scss'],
    providers: [ConfirmationsShortPeriodChartService],
})
export class ConfirmationsShortPeriodChartComponent extends RxjsComponent implements OnInit {
    public data: ChartItem[] = [];
    public loaded = false;

    public stacks = stacks;
    public colors = stackColors;
    public legend = legend;
    public cardTitle = labelTranslateKeys.title;

    constructor(private confirmationsShortPeriodChartService: ConfirmationsShortPeriodChartService) {
        super();
    }

    public ngOnInit(): void {
        this.initChart();
    }

    private initChart(): void {
        this.confirmationsShortPeriodChartService
            .getData()
            .pipe(
                tap((_) => (this.loaded = true)),
                takeUntil(this.destroy$)
            )
            .subscribe((data) => (this.data = data));
    }
}
