import { ActivationStageUtils, ExternalInfluencer, HomeDescription, I18nKeyUtils } from '@smooved/core';
import { i18nKeyTypeMap, UiI18nKeyType } from '../i18n';
import { MatchPropertyType } from './enums';

export class CompanyPageUtils {
    public static canCollectReviews(externalInfluencer: ExternalInfluencer): boolean {
        if (!externalInfluencer?.meta) return false;
        return ActivationStageUtils.isVerifiedOrActive(externalInfluencer.meta?.activationStage);
    }

    public static convertHomeDescriptionToMatchPropertyType(homeDescription: HomeDescription): MatchPropertyType {
        switch (homeDescription) {
            case HomeDescription.Apartment:
                return MatchPropertyType.Apartment;
            case HomeDescription.Closed:
            case HomeDescription.Detached:
            case HomeDescription.HalfOpen:
                return MatchPropertyType.House;
            default:
                return null;
        }
    }

    public static getPropertyTypeLabel(propertyType?: HomeDescription): string {
        const property = `YOUR_${propertyType ? CompanyPageUtils.convertHomeDescriptionToMatchPropertyType(propertyType) : 'HOUSE'}`;
        return I18nKeyUtils.map(i18nKeyTypeMap, UiI18nKeyType.PropertyType, property);
    }
}
