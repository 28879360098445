import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginSandbox } from '@app/partner/containers/login/login.sandbox';
import { formControlNames } from '../../constants/login-form.constants';

@Component({
    selector: 'app-login-container',
    templateUrl: './login.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainer {
    public formControlNames = formControlNames;

    public form = this.loginSandbox.createForm();

    constructor(private loginSandbox: LoginSandbox) {}

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        this.loginSandbox.login({
            email: this.form.get(formControlNames.email).value,
            password: this.form.get(formControlNames.password).value,
        });
    }
}
