import { NgModule } from '@angular/core';
import { OtsContractContainerComponent } from '@app/contract/components/ots-contract-container/ots-contract-container.component';
import { SuggestionSelectionEnergyTransferComponent } from '@app/contract/components/suggestion-selection-energy-transfer/suggestion-selection-energy-transfer.component';
import { SuggestionSelectionEnergyOfferComponent } from '@app/contract/components/suggestion-selection-energy/suggestion-selection-energy-offer.component';
import { SuggestionSelectionInsurancesOfferComponent } from '@app/contract/components/suggestion-selection-insurances-offer/suggestion-selection-insurances-offer.component';
import { SuggestionSelectionTelecomOfferComponent } from '@app/contract/components/suggestion-selection-telecom-offer/suggestion-selection-telecom-offer.component';
import { SuggestionSelectionTelecomTransferComponent } from '@app/contract/components/suggestion-selection-telecom-transfer/suggestion-selection-telecom-transfer.component';
import { SuggestionSelectionWaterComponent } from '@app/contract/components/suggestion-selection-water/suggestion-selection-water.component';
import { TranslationModule } from '@app/translation/translation.module';
import { SvgModule } from '@smooved/ui';
import { AddressModule } from '../address/address.module';
import { CardModule } from '../card/card.module';
import { EnergyModule } from '../energy/energy.module';
import { SharedModule } from '../shared/shared.module';
import { LegalModule } from '../wizard/legal/legal.module';
import { AllContractContainerComponent } from './components/all-contract-container/all-contract-container.component';
import { ContactDetailsReadonlyComponent } from './components/contact-details-readonly/contact-details-readonly.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { ContractContainerComponent } from './components/contract-container/contract-container.component';
import { MoveDetailsComponent } from './components/move-details/move-details.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { SuggestionSelectionComponent } from './components/suggestion-selection/suggestion-selection.component';
import { ConfirmContainer } from './containers/confirm/confirm.container';
import { ContactDetailsContainer } from './containers/contact-details/contact-details.container';
import { ContractContainerContainer } from './containers/contract-container/contract-container.container';
import { ContractsCheckContainer } from './containers/contracts-check/contracts-check.container';
import { ContractsPreparedContainer } from './containers/contracts-prepared/contracts-prepared.container';
import { ContractsRequestedContainer } from './containers/contracts-requested/contracts-requested.container';
import { MoveDetailsContainer } from './containers/move-details/move-details.container';
import { PaymentDetailsContainer } from './containers/payment-details/payment-details.container';
import { RegisterSuccessContainer } from './containers/register-success/register-success.container';
import { ContractRoutingModule } from './contract-routing.module';
import { ConfirmService } from './services/confirm.service';
import { ContractService } from './services/contract.service';
import { AlertModule } from 'projects/ui/src/lib/alerts/alert.module';
import { MoveWizardModule } from '@app/move-wizard/move-wizard.module';

@NgModule({
    declarations: [
        ContactDetailsComponent,
        PaymentDetailsComponent,
        MoveDetailsComponent,
        ContractContainerComponent,
        OtsContractContainerComponent,
        AllContractContainerComponent,
        ContractContainerContainer,
        MoveDetailsContainer,
        ContactDetailsContainer,
        PaymentDetailsContainer,
        ContactDetailsReadonlyComponent,
        ShoppingCartComponent,
        SuggestionSelectionComponent,
        SuggestionSelectionEnergyOfferComponent,
        SuggestionSelectionEnergyTransferComponent,
        SuggestionSelectionTelecomOfferComponent,
        SuggestionSelectionTelecomTransferComponent,
        SuggestionSelectionWaterComponent,
        SuggestionSelectionInsurancesOfferComponent,
        ConfirmContainer,
        RegisterSuccessContainer,
        ContractsCheckContainer,
        ContractsPreparedContainer,
        ContractsRequestedContainer,
    ],
    providers: [ConfirmService, ContractService],
    imports: [ContractRoutingModule, CardModule, SharedModule, EnergyModule, AddressModule, LegalModule, SvgModule, TranslationModule, AlertModule, MoveWizardModule],
    exports: [
        ContactDetailsComponent,
        PaymentDetailsComponent,
        MoveDetailsComponent,
        ContractContainerComponent,
        OtsContractContainerComponent,
        ContractContainerContainer,
        AllContractContainerComponent,
        ShoppingCartComponent,
        SuggestionSelectionComponent,
    ],
})
export class ContractModule {}
