import { Component } from '@angular/core';

@Component({
    selector: 'app-header-real-estate-agent-filters',
    template: `
        <a routerLinkActive="__link-menu--active" class="__link-menu" routerLink="admin/real-estate-agents/leads">{{
            'HEADER.ADMIN.MENU.LEADS' | translate
        }}</a>
        <a routerLinkActive="__link-menu--active" class="__link-menu" routerLink="admin/real-estate-agents/all">{{
            'HEADER.ADMIN.MENU.REAL_ESTATE_AGENTS' | translate
        }}</a>
        <a routerLinkActive="__link-menu--active" class="__link-menu" routerLink="admin/real-estate-agents/offices">{{
            'HEADER.ADMIN.MENU.OFFICES' | translate
        }}</a>
        <a routerLinkActive="__link-menu--active" class="__link-menu" routerLink="admin/real-estate-agents/real-estate-groups">{{
            'HEADER.ADMIN.MENU.REAL_ESTATE_GROUPS' | translate
        }}</a>
        <a routerLinkActive="__link-menu--active" class="__link-menu" routerLink="admin/real-estate-agents/billing">{{
            'HEADER.ADMIN.MENU.BILLING' | translate
        }}</a>
    `,
    styleUrls: ['./header-real-estate-agent-filters.component.scss'],
})
export class HeaderRealEstateAgentFiltersComponent {}
