import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Transferee } from '@app/move/enums/transferee.enum';
import { LeadPassingForm } from './lead-passing-form.enum';

export class LeadPassingValidators {
    public static differentEmail(group: UntypedFormGroup): ValidatorFn {
        return (_control: AbstractControl): ValidationErrors | null => {
            const { emailTransferee, emailLeaver } = group.getRawValue();
            const invalid = !!emailTransferee && !!emailLeaver && emailTransferee === emailLeaver;
            return invalid ? { 'DIFFERENT_EMAILS': true } : null;
        };
    }

    public static tentantType(group: UntypedFormGroup): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalid = group.controls[LeadPassingForm.TransfereeType].value === Transferee.TenantTransferee && !control.value;
            return invalid ? { 'TENANT_TYPE_REQUIRED': true } : null;
        };
    }
}
