import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DnbMatch } from '@app/energy/interfaces/dnb-match';
import { DnbMatchesRequest } from '@app/energy/interfaces/dnb-matches-request';
import { EanResult } from '@app/energy/interfaces/ean-result';
import { GetEanRequestByAddress } from '@app/energy/interfaces/get-ean-request-by-address';
import { GetEanRequestByMeterNumbers } from '@app/energy/interfaces/get-ean-request-by-meter-numbers';
import { RequestBuilder } from '@app/http/builders/request.builder';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';

@Injectable({
    providedIn: 'root',
})
export class EnergyService {
    constructor(private readonly httpClient: HttpClient) {}

    public getDnbMatches(dnbMatchesRequest: DnbMatchesRequest): Observable<DnbMatch[]> {
        const requestBuilder = new RequestBuilder().addHttpParams(dnbMatchesRequest);

        return this.httpClient.get<DnbMatch[]>(uri.dnbMatchesUri, {
            params: requestBuilder.getHttpParams(),
        });
    }

    public getEanByAddress(eanRequest: GetEanRequestByAddress): Observable<EanResult[]> {
        const { language, address } = eanRequest;
        const requestBuilder = new RequestBuilder().addHttpParam('language', language).addHttpParam('address', JSON.stringify(address));
        return this.httpClient.get<EanResult[]>(uri.eanByAddressUri, {
            params: requestBuilder.getHttpParams(),
        });
    }

    public getEanByMeterNumbers(eanRequest: GetEanRequestByMeterNumbers): Observable<EanResult[]> {
        const { address } = eanRequest;
        const requestBuilder = new RequestBuilder().addHttpParam('address', JSON.stringify(address));
        return this.httpClient.get<EanResult[]>(uri.eanByMeterNumbersUri, {
            params: requestBuilder.getHttpParams(),
        });
    }
}
