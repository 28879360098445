import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@smooved/core';

@Pipe({
    name: 'quarter',
})
export class QuarterPipe implements PipeTransform {
    transform(value: Date, args?: any): number {
        if (!DateUtils.isDate(value) || !value) return null;
        return DateUtils.tz(value).quarter();
    }
}
