import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivationStageUtils, ExternalInfluencer, noDecimals, SimpleChangesUtils } from '@smooved/core';
import { UiDirection, UiSize } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';

@Component({
    selector: 'ui-real-estate-group-box',
    templateUrl: './real-estate-group-box.component.html',
    styleUrls: ['./real-estate-group-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateGroupBoxComponent implements OnChanges {
    @Input() public externalInfluencer: ExternalInfluencer;

    public influencer: ExternalInfluencer;
    public activated = true;
    public readonly noDecimals = noDecimals;
    public readonly uiDirection = UiDirection;
    public readonly uiSize = UiSize;

    constructor(public readonly uiSandbox: UiSandbox) {}

    public ngOnChanges({ externalInfluencer }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(externalInfluencer) && !!externalInfluencer) {
            this.setExternalInfluencer(this.externalInfluencer);
        }
    }

    private setExternalInfluencer(influencer: ExternalInfluencer): void {
        this.influencer = influencer;
        this.activated = ActivationStageUtils.hasVerifiedBadge(influencer.meta.activationStage);
    }
}
