import { Injectable } from '@angular/core';
import { MeterReadingsContainer } from '@app/meter-readings/interfaces/meter-readings-container';
import { MeterReadingsOutputConfig } from '@app/meter-readings/interfaces/meter-readings-output-config';
import { MoveUtils } from '@app/move/state/move.utils';
import { Move } from '@app/move/interfaces/move';
import { CheckedState, Svg } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export abstract class MeterReadingsOutputService {
    protected meterInfoGasStateSubject = new BehaviorSubject<CheckedState>(CheckedState.Nok);
    protected meterInfoElectricityStateSubject = new BehaviorSubject<CheckedState>(CheckedState.Nok);
    protected meterInfoWaterStateSubject = new BehaviorSubject<CheckedState>(CheckedState.Nok);
    protected meterInfoInsurancesStateSubject = new BehaviorSubject<CheckedState>(CheckedState.Nok);

    public move: Move;

    protected onClickGas = (_: MouseEvent) => {};
    protected onClickElectricity = (_: MouseEvent) => {};
    protected onClickWater = (_: MouseEvent) => {};
    protected onClickInsurances = (_: MouseEvent) => {};

    public meterInfoGasState$ = this.meterInfoGasStateSubject.asObservable();
    public meterInfoElectricityState$ = this.meterInfoElectricityStateSubject.asObservable();
    public meterInfoWaterState$ = this.meterInfoWaterStateSubject.asObservable();
    public meterInfoInsurancesState$ = this.meterInfoInsurancesStateSubject.asObservable();

    public getContainers(config: MeterReadingsOutputConfig): MeterReadingsContainer[] {
        const containers: MeterReadingsContainer[] = [];
        if (this.canShowGas(config))
            containers.push({
                click: this.onClickGas.bind(this),
                svgName: Svg.Gas,
                checked$: this.meterInfoGasState$,
            });

        if (this.canShowElectricity(config))
            containers.push({
                click: this.onClickElectricity.bind(this),
                svgName: Svg.Elect,
                checked$: this.meterInfoElectricityState$,
            });
        if (this.canShowWater(config))
            containers.push({
                click: this.onClickWater.bind(this),
                svgName: Svg.Water,
                checked$: this.meterInfoWaterState$,
            });

        if (this.canShowInsurances(config))
            containers.push({
                click: this.onClickInsurances.bind(this),
                svgName: Svg.Umbrella,
                checked$: this.meterInfoInsurancesState$,
            });

        return containers;
    }

    public canShowWater(config: MeterReadingsOutputConfig): boolean {
        return config?.showWater && this.move?.realEstateGroupServices?.water;
    }

    public canShowElectricity(config: MeterReadingsOutputConfig): boolean {
        return config?.showElectricity && MoveUtils.isElectricityEnergyType(this.move?.energyOffer?.energyType);
    }

    public canShowGas(config: MeterReadingsOutputConfig): boolean {
        return config?.showGas && MoveUtils.isGasEnergyType(this.move?.energyOffer?.energyType);
    }

    public canShowInsurances(config: MeterReadingsOutputConfig): boolean {
        return config?.showInsurances && !!this.move?.realEstateGroupServices?.insurances && !!this.move?.createdByFlow?.iots;
    }

    public set meterInfoGasState(state: CheckedState) {
        this.meterInfoGasStateSubject.next(state);
    }

    public set meterInfoElectricityState(state: CheckedState) {
        this.meterInfoElectricityStateSubject.next(state);
    }

    public set meterInfoWaterState(state: CheckedState) {
        this.meterInfoWaterStateSubject.next(state);
    }

    public set meterInfoInsurancesState(state: CheckedState) {
        this.meterInfoInsurancesStateSubject.next(state);
    }
}
