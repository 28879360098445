import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MobileModalComponent } from '@smooved/ui';

@Component({
    selector: 'app-mobile-move-detail-insurances-modal',
    template: `
        <app-mobile-modal [mainHasPaddingTop]="false">
            <div mobile-modal-header class="">
                <span class="u-font-weight-bold">{{ 'INSURANCES.LABEL' | translate }}</span>
            </div>
            <div mobile-modal-main class="u-padding u-background-white">
                <app-move-detail-insurances [move]="move$ | async" class="u-display-block"></app-move-detail-insurances>
            </div>
        </app-mobile-modal>
    `,
    styleUrls: ['./mobile-move-detail-insurances.component.scss'],
})
export class MobileMoveDetailInsurancesModalComponent extends MoveData implements OnInit {
    @ViewChild(MobileModalComponent) public detail: MobileModalComponent;

    constructor(protected moveSandbox: MoveSandbox, @Inject(MAT_DIALOG_DATA) public data: ModalData) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit(): void {
        this.fetch(this.data.id);
    }
}
