import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    calendlyUrls,
    defaultCalendlyUrl,
} from '@app/authentication/containers/confirm-real-estate-agent-register/confirm-real-estate-agent-register.constants';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@smooved/core';
import { illustrationRelax } from '@smooved/ui';

@Component({
    selector: 'app-confirm-real-estate-agent-register-container',
    template: `
        <app-loading-container [loading]="loading">
            <app-card-container>
                <div class="u-container u-margin-0-auto">
                    <app-card class="u-margin-bottom u-flex-column u-flex-align-items-center">
                        <img class="u-margin-bottom-double" width="220" [src]="image" alt="Smooved" />

                        <h3 class="u-margin-bottom-double">{{ 'CONTRACTS.PROCESSING.TITLE' | translate }}</h3>

                        <p class="u-container-small u-font-size-medium u-text-align-center u-margin-bottom">
                            {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.CONFIRM.TEXT1' | translate }}
                        </p>
                        <p class="u-container-small u-font-size-medium u-text-align-center">
                            {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.CONFIRM.TEXT2' | translate }}
                        </p>
                        <div class="u-flex-column u-h100p u-text-align-center __calendly-holder">
                            <div class="u-overflow-y-auto __calendly-container">
                                <div class="__calendly calendly-inline-widget" #calendly data-auto-load="false" style="width: 100%;"></div>
                            </div>
                        </div>
                    </app-card>
                </div>
            </app-card-container>
        </app-loading-container>
    `,
    styles: [
        `
                    .__calendly-holder {
                        width: 100%;
                    }
        
                    .__calendly {
                        height: 1088px;
                        overflow: hidden;
                    }
        
                    @media (min-width: 780px) {
                        .__calendly {
                            height: 1155px;
                        }
                    }
        
                    @media (min-width: 1050px) {
                        .__calendly {
                            height: 664px;
                        }
        
                        .__calendly-container {
                            width: 1034px;
                            margin-left: -37px;
                        }
                    }
                `,
    ],
})
export class ConfirmRealEstateAgentRegisterContainer implements OnInit {
    @ViewChild('calendly', { static: true }) public calendly: ElementRef;
    public readonly image = illustrationRelax;
    public loading = true;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        public readonly navigationSandbox: AppNavigationSandbox,
        private readonly translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        const confirmationToken = this.activatedRoute.snapshot.queryParams['ct'] as string;
        const currentLanguage = this.translateService.currentLang as Language;
        if (confirmationToken) {
            this.realEstateAgentSandbox.confirm({ confirmationToken }).subscribe((realEstateAgent) => {
                this.loading = false;
                this.initCalendly(realEstateAgent.language || currentLanguage);
            }, this.goToHomepage);
        } else {
            this.goToHomepage();
        }
    }

    private initCalendly(language?: Language): void {
        setTimeout(() => {
            const url = language ? calendlyUrls[language] : defaultCalendlyUrl;
            // @ts-ignore
            Calendly.initInlineWidget({ url, parentElement: this.calendly.nativeElement });
        }, 0);
    }

    private goToHomepage = () => {
        this.navigationSandbox.goToHome();
    };
}
