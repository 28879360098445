import { FormControlGroups } from '@app/mortgage/enums/form-control-groups.enum';
import { FormControlNames } from '@app/mortgage/enums/form-control-names.enum';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Move } from '@app/move/interfaces/move';
import { HomeConstructionYear } from '@app/shared/state/shared.reducer';
import { DateUtils, HomeDescription, Language, StringUtils } from '@smooved/core';
import { EnergyType } from '../app/wizard/energy/enums/energy-type.enum';

const street = 'Stationsstraat';
const houseNumber = '1';
const zipCode = '2800';
const city = 'Mechelen';
const homeConstructionYear = HomeConstructionYear.NewConstruction;

const firstName = 'John';
const lastName = 'Doe';
const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}+${StringUtils.getRandomString()}@gmail.com`;
const phoneNumber = '0477112233';
const language = Language.NL;

const firstNameLeaver = 'Jane';
const lastNameLeaver = 'Smith';
const emailLeaver = `${firstNameLeaver.toLowerCase()}.${lastNameLeaver.toLowerCase()}+${StringUtils.getRandomString()}@gmail.com`;
const phoneNumberLeaver = '0477778899';
const languageLeaver = Language.NL;

const transferee: Partial<Move> = {
    user: {
        firstName,
        lastName,
        email,
        phoneNumber,
        language,
        movingAddress: {
            street,
            houseNumber,
            zipCode,
            city,
        },
        transfereeType: Transferee.OwnerTransferee,
    },
    movingDate: DateUtils.addDays(20),
    meta: {
        homeDescription: HomeDescription.HalfOpen,
        homeDescriptionSizeInput: 130,
    },
    energyOffer: {
        energyType: EnergyType.Both,
    },
    professional: false,
};

const mortgage = {
    [FormControlGroups.Address]: {
        [FormControlNames.MovingAddress]: {
            street,
            houseNumber,
            zipCode,
            city,
        },
    },
    [FormControlGroups.HomeConstructionYear]: {
        [FormControlNames.HomeConstructionYear]: homeConstructionYear,
    },
    [FormControlGroups.Financial]: {
        [FormControlNames.PropertyPrice]: 250000,
        [FormControlNames.OwnResourcesAmount]: 50000,
        [FormControlNames.SoloProperty]: true,
        [FormControlNames.StakeholdersNumber]: 2,
    },
};

export interface MockFormData {
    mortgage?: any;
    leadPassingWithoutLeaver?: Partial<Move>;
    leadPassingWithLeaver?: Partial<Move>;
    createRealEstateAgent: boolean;
}

export const mockFormData: MockFormData = {
    mortgage,
    leadPassingWithoutLeaver: transferee,
    leadPassingWithLeaver: {
        ...transferee,
        leaverType: Leaver.OwnerLeaver,
        firstNameLeaver,
        lastNameLeaver,
        emailLeaver,
        phoneNumberLeaver,
        languageLeaver,
    },
    createRealEstateAgent: true,
};
