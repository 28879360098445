<form novalidate [formGroup]="form">
    <div class="__addressees-container u-margin-bottom-double">
        <app-label-container
            [label]="'MULTI_CHANNEL.ADDRESSEE' | i18nKeyTranslate: i18nKeytype.ContactLog"
            [hasMarginDouble]="false"
            [hasMargin]="false"
            [bold]="true"
        >
            <app-addressees
                [move]="data?.move"
                [prefix]="mailFields?.to"
                [formControlName]="mailFields.to"
                ngDefaultControl
            ></app-addressees>
        </app-label-container>
        <app-label-container *ngIf="showCc" [label]="mailFields.cc" [hasMargin]="false" [hasMarginDouble]="false" [bold]="true">
            <app-addressees
                [move]="data?.move"
                [prefix]="mailFields.cc"
                [formControlName]="mailFields.cc"
                ngDefaultControl
            ></app-addressees>
        </app-label-container>
        <app-label-container *ngIf="showBcc" [label]="mailFields.bcc" [hasMargin]="false" [hasMarginDouble]="false" [bold]="true">
            <app-addressees
                [move]="data?.move"
                [prefix]="mailFields.bcc"
                [formControlName]="mailFields.bcc"
                ngDefaultControl
            ></app-addressees>
        </app-label-container>
    </div>

    <app-alert *ngIf="alert" [context]="uiContext.Danger" [icon]="uiIcon.Warning">
        <h6 class="u-margin-bottom">{{ alert.title | translate }}</h6>
        <p>{{ alert.content | translate }}</p>
    </app-alert>

    <app-label-container [label]="'MULTI_CHANNEL.SUBJECT' | i18nKeyTranslate: i18nKeytype.ContactLog">
        <app-text-input
            [formControlName]="mailFields.subject"
            [hasMarginDouble]="false"
            width="100%"
            [hasMargin]="false"
            [placeholder]="'MULTI_CHANNEL.SUBJECT' | i18nKeyTranslate: i18nKeytype.ContactLog"
            ngDefaultControl
        ></app-text-input>
    </app-label-container>

    <app-label-container [label]="'MULTI_CHANNEL.BODY' | i18nKeyTranslate: i18nKeytype.ContactLog" class="u-margin-bottom-double">
        <quill-editor
            [formControlName]="mailFields.content"
            #editor
            [styles]="{ height: '350px' }"
            [modules]="editorModules"
            class="u-display-block"
            ngDefaultControl
        ></quill-editor>
    </app-label-container>

    <div class="u-flex-row u-margin-bottom-double">
        <smvd-ui-dropdown-input
            [formControlName]="mailFields.escalation"
            [label]="'CONNECT_ESCALATION' | i18nKeyTranslate: i18nKeytype.ContactLog"
            [options]="escalationOptions | translateProp"
            [hasMargin]="false"
            ngDefaultControl
        ></smvd-ui-dropdown-input>
        <smvd-ui-dropdown-input
            [formControlName]="mailFields.state"
            [label]="'STATE.TITLE' | i18nKeyTranslate: i18nKeytype.Escalation"
            [options]="stateOptions"
            [hasMargin]="false"
            class="u-margin-left"
            ngDefaultControl
        ></smvd-ui-dropdown-input>
    </div>

    <app-assets-input
        [showFileName]="assetsShowFileName"
        [readOnly]="assetsReadOnly"
        [label]="'ATTACHMENT' | translate"
        [assets]="assets"
        (change)="onFilesChange($event)"
        (remove)="removeItem($event)"
        (detail)="openDetail($event)"
    >
    </app-assets-input>

    <div class="u-flex-row u-flex-justify-content-flex-end">
        <app-button data-testid="mail-form-submit-button" type="submit">{{ 'SEND' | translate }}</app-button>
    </div>
</form>
