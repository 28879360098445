import { Component, Input } from '@angular/core';
import { UiIconAppearance, UiIconSize } from '../icon';
import { UiContext } from '../ui.enums';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styles: [':host {display: block;}'],
})
export class AlertComponent {
    @Input() public icon: string;
    @Input() public iconAppearance = UiIconAppearance.Default;
    @Input() public iconContext: UiContext;
    @Input() public context: UiContext;
    @Input() public border: boolean = true;

    public iconSize = UiIconSize.Xm;
    public readonly uiContext = UiContext;
}
