import { Injectable } from '@angular/core';
import { TodoCount } from '@app/move/interfaces/todo-count';
import { MoveService } from '@app/move/services/move.service';
import { DashboardMenu, defaultDashboardMenu } from '@app/real-estate-agent/components/dashboard-menu/dashboard-menu.constants';
import { RealEstateAgentProvidersModule } from '@app/real-estate-agent/real-estate-agent-providers.module';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class DashboardMenuService {
    private activeMenuSubject = new BehaviorSubject<DashboardMenu>(defaultDashboardMenu);
    private todosCountSubject = new BehaviorSubject<TodoCount>(null);
    public activeMenu$ = this.activeMenuSubject.asObservable();
    public todosCount$ = this.todosCountSubject.asObservable();

    constructor(private moveService: MoveService) {}

    public getActiveMenu(): DashboardMenu {
        return this.activeMenuSubject.value;
    }

    public setActiveMenu(dashboardMenu: DashboardMenu): void {
        this.activeMenuSubject.next(dashboardMenu);
    }

    public resetMenu(): void {
        this.activeMenuSubject.next(defaultDashboardMenu);
    }

    public reloadTodosCount(): void {
        this.moveService.getRealEstateAgentUrgentTodoCount().subscribe((todos) => this.todosCountSubject.next(todos));
    }
}
