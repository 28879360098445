import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    template: ` <ng-content></ng-content> `,
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
    @Input()
    @HostBinding('class.is-padding')
    public padding = false;

    @Input()
    @HostBinding('class.is-padding-double')
    public paddingDouble = true;

    @Input()
    @HostBinding('class.has-border')
    public bordered = true;

    @Input()
    @HostBinding('class')
    public backgroundClass = 'u-background-white';
}
