import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ClientSource } from '@app/real-estate-agent/interfaces/insights';
import { GraphUtils } from '@app/shared/utils/graph-utils';
import { ArrayUtils } from '@smooved/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-insights-client-source-chart',
    templateUrl: './insights-clients-source-chart.component.html',
    styleUrls: ['./insights-clients-source-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsClientsSourceChartComponent implements OnChanges {
    @ViewChild('chart', { static: true }) public chartContainer: ElementRef<SVGElement>;

    @Input() data: ClientSource[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (!ArrayUtils.isEmpty(this.data)) {
            this.createChart();
        } else {
            this.clearChart();
        }
    }

    createChart(): void {
        this.clearChart();
        const mappedData = [...this.data].reverse();

        const element = this.chartContainer.nativeElement;
        const svg = d3.select(element);
        const margin = { top: 5, right: 5, bottom: 20, left: 5 };
        const width = +svg.attr('width') - margin.left - margin.right;
        const height = +svg.attr('height') - margin.top - margin.bottom;

        const yScale = d3
            .scaleLinear()
            .domain([0, d3.sum(mappedData, (d) => d.value)])
            .nice()
            .rangeRound([height, 0]);

        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        let yOffset = 0;
        g.selectAll('.bar')
            .data(mappedData)
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('d', (d, i, nodes) => {
                const x = 0;
                const y = yScale(d.value + yOffset);
                yOffset += d.value;
                const width = 40;
                const height2 = height - yScale(d.value) + 1;
                const rectangle = `M${x},${y} h${width} v${height2} h${-width} Z`;

                if (height2 === 0) {
                    return null;
                }

                if (i === 0) {
                    return GraphUtils.roundedBottomRect(x, y, width, height2, 3);
                }

                if (i === this.data.length - 1) {
                    return GraphUtils.roundedTopRect(x, y, width, height2, 3);
                }

                return rectangle;
            })
            .attr('fill', (d) => d.color);
    }

    private clearChart(): void {
        const chart = this.chartContainer?.nativeElement;
        while (chart?.firstChild) {
            chart?.removeChild(chart?.firstChild);
        }
    }
}
