import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { UiModalModule } from '../modal/modal.module';
import { RatingModal } from './modals/rating/rating.modal';

@NgModule({
    imports: [CommonModule, ButtonModule, IconModule, TranslateModule, UiModalModule],
    declarations: [RatingModal],
    exports: [RatingModal],
})
export class UiSimulationModule {}
