<div class="upgrade-box u-flex-responsive u-flex-justify-content-space-between u-border-radius u-background-gray-ultralight">
    <div class="content-wrapper">
        <h2 class="u-color-gray-ultradark">{{ 'INSIGHTS.DASHBOARD.UPGRADE_INFO.TITLE' | translate }}</h2>
        <ul>
            <li>{{ 'INSIGHTS.DASHBOARD.UPGRADE_INFO.POINT_1' | translate }}</li>
            <li>{{ 'INSIGHTS.DASHBOARD.UPGRADE_INFO.POINT_2' | translate }}</li>
            <li>{{ 'INSIGHTS.DASHBOARD.UPGRADE_INFO.POINT_3' | translate }}</li>
            <li>{{ 'INSIGHTS.DASHBOARD.UPGRADE_INFO.POINT_4' | translate }}</li>
        </ul>
    </div>
    <div class="u-margin-left-md">
        <a app-button class="more-info-btn u-text-align-center u-cursor-pointer u-color-white" [href]="link$ | async" target="_blank">{{
            'INSIGHTS.DASHBOARD.UPGRADE_INFO.MORE_INFO' | translate
        }}</a>
    </div>
</div>
