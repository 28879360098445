import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';

@Component({
    selector: 'app-move-detail-escalations',
    templateUrl: 'move-detail-escalations.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailEscalationsComponent {
    @Input() move: Move;
    public readonly i18nKeys = appI18nKeyTypes;
}
