import { AfterViewInit, Component, forwardRef, Host, Inject, Input, LOCALE_ID, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { maxDecimals } from '@app/form/components/number-input/number-input.constants';
import { NumberUtils } from '@smooved/core';
import { BaseInput, ButtonAppearance, ButtonSize, Svg, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-number-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    [id]="id"
                    type="text"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [mask]="mask"
                    thousandSeparator="."
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    autocomplete="smooved"
                />
                <div matSuffix class="u-flex-row u-flex-align-items-center" *ngIf="showButtons">
                    <button
                        app-button
                        [disabled]="innerDisabled"
                        icon="remove"
                        (click)="decrease()"
                        [appearance]="buttonAppearance.StrokedIcon"
                        [context]="context.Secondary"
                        [size]="buttonSize.Xs"
                        [tabIndex]="-1"
                        class="__button-minus"
                    ></button>
                    <button
                        app-button
                        [disabled]="innerDisabled"
                        icon="add"
                        (click)="increase()"
                        [appearance]="buttonAppearance.StrokedIcon"
                        [context]="context.Secondary"
                        [size]="buttonSize.Xs"
                        [tabIndex]="-1"
                        class="__button-plus"
                    ></button>
                </div>
                <div matSuffix>
                    <ng-content select="[suffix]"></ng-content>
                </div>
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    public svg = Svg;
    public buttonAppearance = ButtonAppearance;
    public buttonSize = ButtonSize;
    public context = UiContext;

    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public min = 0;
    @Input() public max = 99999;
    @Input() public showButtons = true;
    @Input() public step = 1;
    @Input() public decimals = 2;

    public innerModel = '0';

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, @Inject(LOCALE_ID) private readonly localeId) {
        super(controlContainer);
    }

    public get mask(): string {
        return `separator.${Math.min(this.decimals, maxDecimals)}`;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: number): void {
        this.innerModel = this.preFormatInnerModel(value);
    }

    public onModelChange(): void {
        this.propagateChange(Number(this.innerModel));
    }

    public decrease(): void {
        this.innerModel = this.preFormatInnerModel(Math.max(this.min, (Number(this.innerModel) ?? 0) - this.step));
        this.propagateChange(Number(this.innerModel));
    }

    public increase(): void {
        this.innerModel = this.preFormatInnerModel(Math.min(this.max, (Number(this.innerModel) ?? 0) + this.step));
        this.propagateChange(Number(this.innerModel));
    }

    private preFormatInnerModel(value: number): string {
        if (!NumberUtils.isNumber(value)) return null;
        return new Intl.NumberFormat(this.localeId).format(value);
    }
}
