import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { PowerScale } from './power-scale.enum';

@Component({
    selector: 'app-power-bars',
    templateUrl: 'power-bars.component.html',
    styleUrls: ['power-bars.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PowerBarsComponent implements OnInit, OnChanges {
    @Input() public total = 3;
    @Input() public current = 0;
    @Input() public scale: PowerScale;

    public powerClass: string;
    public counterArray = [];

    public ngOnInit() {
        this.powerClass = this.scale ? `--${this.scale}` : '';
    }

    public ngOnChanges({ total }: SimpleChanges) {
        if (SimpleChangesUtils.hasChanged(total)) {
            this.counterArray = Array(total.currentValue);
        }
    }
}
