import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { QuarterlyTableComponent } from '@app/invoicing/components/quarterly-table/quarterly-table.component';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { Quarter } from '@app/invoicing/interfaces/quarter';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { Move } from '@app/move/interfaces/move';
import { PackageId } from '@app/package/enums/package-id.enum';
import { DateUtils, NumberUtils } from '@smooved/core';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-quarter-report',
    template: `
        <div [ngSwitch]="lastBillableInvoiceAvailableFromPast">
            <a routerLink="../invoices" class="u-text-decoration-none u-display-block u-margin-bottom" *ngSwitchCase="true">
                <app-commissions-notification [invoice]="lastBillableInvoice"></app-commissions-notification>
            </a>
            <a routerLink="../invoices" class="u-text-decoration-none u-display-block u-margin-bottom" *ngSwitchCase="false">
                <app-commissions-notification-unavailable [invoice]="lastBillableInvoice"></app-commissions-notification-unavailable>
            </a>
        </div>

        <app-card [bordered]="false" [paddingDouble]="false">
            <div class="u-padding u-bordered-bottom u-flex-row u-flex-align-items-center u-flex-justify-content-space-between">
                <div class="u-flex-column">
                    <h4 *ngIf="!!quarterDate">Q{{ quarterDate | quarter }} {{ quarterDate | formatDate: 'YYYY' }}</h4>

                    <span *ngIf="!quarter?.closed; else closed" class="u-color-muted u-font-size-small">{{
                        'INVOICING.OVERALL.CURRENT_STATUS.CLOSED_ON' | translate: { date: (quarter?.closesOn | formatDate: 'DD/MM') }
                    }}</span>
                    <ng-template #closed class="u-color-muted u-font-size-small">{{
                        'INVOICING.OVERALL.CURRENT_STATUS.CLOSED' | translate
                    }}</ng-template>
                </div>
                <form [formGroup]="quarterForm">
                    <app-quarter-input (ngModelChange)="onQuarterChange($event)" formControlName="quarter"></app-quarter-input>
                </form>
            </div>

            <mat-tab-group
                class="mat-tab-group-theme mat-tab-group-theme--pill"
                animationDuration="0ms"
                [disableRipple]="true"
                mat-align-tabs="center"
            >
                <mat-tab [label]="'ENERGY.LABEL' | translate">
                    <ng-template matTabContent>
                        <app-quarter-report-energy
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                            [quarterDate]="quarterDate"
                            [type]="type"
                            [realEstateGroupId]="realEstateGroupId"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            (openDetail)="openDetail.emit($event)"
                            (typeChange)="onTypeChange($event)"
                        ></app-quarter-report-energy>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'TELECOM.LABEL' | translate">
                    <ng-template matTabContent>
                        <app-quarter-report-telecom
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                            [quarterDate]="quarterDate"
                            [type]="type"
                            [realEstateGroupId]="realEstateGroupId"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            (openDetail)="openDetail.emit($event)"
                            (typeChange)="onTypeChange($event)"
                        ></app-quarter-report-telecom>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    [label]="'INSURANCES.LABEL' | translate"
                    *ngIf="featurePermissionsSandbox.featureGranted$(featureScope.Insurances) | async"
                >
                    <ng-template matTabContent>
                        <app-quarter-report-insurances
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                            [quarterDate]="quarterDate"
                            [type]="type"
                            [realEstateGroupId]="realEstateGroupId"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            (openDetail)="openDetail.emit($event)"
                            (typeChange)="onTypeChange($event)"
                        ></app-quarter-report-insurances>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    [label]="'MORTGAGE.LABEL' | translate"
                    *ngIf="featurePermissionsSandbox.featureGranted$(featureScope.Mortgage) | async"
                >
                    <ng-template matTabContent>
                        <app-quarter-report-mortgages
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                            [quarterDate]="quarterDate"
                            [type]="type"
                            [realEstateGroupId]="realEstateGroupId"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            (openDetail)="openDetail.emit($event)"
                            (typeChange)="onTypeChange($event)"
                        ></app-quarter-report-mortgages>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    [label]="'GIFTS.LABEL_PLURAL' | translate"
                    *ngIf="featurePermissionsSandbox.featureGranted$(featureScope.Gift) | async"
                >
                    <ng-template matTabContent>
                        <app-quarter-report-gifts
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                            [quarterDate]="quarterDate"
                            [type]="type"
                            [realEstateGroupId]="realEstateGroupId"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            (openDetail)="openDetail.emit($event)"
                            (typeChange)="onTypeChange($event)"
                        ></app-quarter-report-gifts>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'PACKAGE.SMOOVED_PLUS' | translate" *ngIf="showSubscriptionCosts()">
                    <ng-template matTabContent>
                        <app-quarter-report-subscription-costs
                            (typeChange)="onTypeChange($event)"
                            [quarterlyInvoicing]="quarterlyInvoicing"
                            [loading]="quarterlyInvoicingLoading"
                            [closed]="!!quarter?.closed"
                        ></app-quarter-report-subscription-costs>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <app-quarterly-table
                *ngIf="!!quarterDate && type !== invoicingType.SubscriptionCosts"
                [realEstateGroupId]="realEstateGroupId"
                [quarterDate]="quarterDate"
                (clickDetail)="openDetail.emit($event)"
                [type]="type"
            ></app-quarterly-table>
        </app-card>
    `,
    styleUrls: ['./quarter-report.component.scss'],
})
export class QuarterReportComponent implements OnInit {
    @Input() public realEstateGroupId: string;
    @Input() public quarterDate: Date;
    @Input() public lastBillableInvoiceAvailableFromPast: boolean;
    @Input() public lastBillableInvoice: Invoice;
    @Input() public quarterlyInvoicing: QuarterlyInvoicing;
    @Input() public quarter: Quarter;
    @Input() public quarterlyInvoicingLoading: boolean;

    @Output() public openDetail: EventEmitter<Move> = new EventEmitter<Move>();

    @ViewChild(QuarterlyTableComponent) public quarterlyTableComponent: QuarterlyTableComponent;

    public featureScope = FeatureScope;
    public type: InvoicingType;
    public invoicingType = InvoicingType;
    public packageId = PackageId;

    public quarterForm = this.formBuilder.group({
        quarter: null,
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly invoicingSandbox: InvoicingSandbox,
        public readonly featurePermissionsSandbox: FeaturePermissionsSandbox
    ) {}

    public ngOnInit(): void {
        this.quarterForm.get('quarter').patchValue(this.quarterDate ? DateUtils.tz(this.quarterDate).toDate().getTime() : null);
    }

    public onTypeChange(event: InvoicingType): void {
        this.quarterlyTableComponent?.resetPageIndex();
        this.type = event;
    }

    public onQuarterChange(quarter: number): void {
        this.quarterlyTableComponent?.resetPageIndex();
        this.invoicingSandbox.setQuarter(DateUtils.tz(quarter).toDate());
    }

    public showSubscriptionCosts(): boolean {
        return this.quarter?.closed && NumberUtils.isNumber(this.quarterlyInvoicing?.subscriptionCosts?.totalSubscriptionCost);
    }
}
