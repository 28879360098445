export enum LegalForm {
    SA_NV_AG = 'SA_NV_AG',
    SCRL_CVBA_GMBH = 'SCRL_CVBA_GMBH',
    SPRL_BVBA_GMBH = 'SPRL_BVBA_GMBH',
    SPRLU_EBVA_EPGMBH = 'SPRLU_EBVA_EPGMBH',
    CV_SC_GEN = 'CV_SC_GEN',
    SCRI_CVOA_GMUH = 'SCRI_CVOA_GMUH',
    AI_IV_IV = 'AI_IV_IV',
    ASBL_VZW_VOG = 'ASBL_VZW_VOG',
    GIE_ESV_WIV = 'GIE_ESV_WIV',
    GEIE_EESV_EWIV = 'GEIE_EESV_EWIV',
    SNC_VOF_OHG = 'SNC_VOF_OHG',
    SCS_COMMV_KG = 'SCS_COMMV_KG',
    SCA_COMMVA_KGAA = 'SCA_COMMVA_KGAA',

    SARL = 'SARL',
    SE_EV_EG = 'SE_EV_EG',
    SAGR_LV_LG = 'SAGR_LV_LG',
    AISBL_IVZW_IGOE = 'AISBL_IVZW_IGOE',
    ENTREPRISE_INDIVIDUELLE_EENMANSZAAK_EINZELFIRMA = 'ENTREPRISE_INDIVIDUELLE_EENMANSZAAK_EINZELFIRMA',
    ACP_VME_WEG = 'ACP_VME_WEG',
    ECV_SCE_SCE = 'ECV_SCE_SCE',
    SRL_BV_GMBH = 'SRL_BV_GMBH',
    SC_COMMV_KG = 'SC_COMMV_KG',
}
