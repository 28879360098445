import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { Address, CountryCode } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { uiI18nKeyTypes } from '../../i18n/i18n-key-type-map.constants';
import { autoWidth } from '../../ui.constant';
import { BaseInput } from '../base-input';
import { AddressValidators } from '../validators/address.validators';
import { FormControlNames } from './address-input.constants';

@Component({
    selector: 'smvd-ui-address-input',
    templateUrl: './address-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressInputComponent),
            multi: true,
        },
    ],
    styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent extends BaseInput implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('formElement', { static: true }) public formGroupDirective: FormGroupDirective;

    @Input() public label: string;
    @Input() public labelResource: string;
    @Input() public small: boolean;
    @Input() public showLabels = false;
    @Input() public showPlaceholders = true;

    /**
     * Possible to turn off required validation.
     * E.g.: this is used in real estate agent edit move dialog.
     */
    @Input() public optional = false;

    public width = autoWidth;
    public readonly i18nKeys = uiI18nKeyTypes;
    public readonly formControlNames = FormControlNames;
    public readonly form: UntypedFormGroup = this.formBuilder.group({
        [FormControlNames.Street]: '',
        [FormControlNames.HouseNumber]: '',
        [FormControlNames.BusNumber]: '',
        [FormControlNames.ZipCode]: '',
        [FormControlNames.City]: '',
        [FormControlNames.Country]: [CountryCode.Belgium, [Validators.required]],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly changeDetectionRef: ChangeDetectorRef,
        public readonly controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    public markAsSubmitted(): void {
        this.formGroupDirective.onSubmit(null);
        this.changeDetectionRef.detectChanges();
    }

    public markAllAsTouched(): void {
        this.form.markAllAsTouched();
        this.changeDetectionRef.detectChanges();
    }

    public ngOnInit(): void {
        if (this.optional) {
            this.form.get(FormControlNames.ZipCode).setValidators(AddressValidators.validateZipCodeOptional);
        } else {
            this.form.get(FormControlNames.Street).setValidators(Validators.required);
            this.form.get(FormControlNames.HouseNumber).setValidators(Validators.required);
            this.form.get(FormControlNames.ZipCode).setValidators(AddressValidators.validateZipCode);
            this.form.get(FormControlNames.City).setValidators(Validators.required);
        }

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: Address) => {
            if (this.form.invalid) {
                this.propagateChange(null);
            } else {
                this.propagateChange(value);
            }
        });

        this.controlContainer.control.markAllAsTouched = (): void => this.markAllAsTouched();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form?.disable();
        } else {
            this.form?.enable();
        }
        this.form?.updateValueAndValidity();
    }

    public writeValue(address: Address): void {
        this.form.patchValue(address || {});
    }

    public onAddressSelected(event: Address): void {
        this.form.patchValue(event);
    }
}
