import { Injectable } from '@angular/core';
import { RealEstateAgentFilter as AdminRealEstateAgentFilter } from '@app/admin/interfaces/real-estate-agent-filter.interface';
import { RealEstateGroupFilter } from '@app/admin/interfaces/real-estate-group-filter.interface';
import { I18nKey } from '@app/feature-scope/constants/feature-scope.constants';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentOffice } from '@app/real-estate-agent/interfaces/real-estate-agent-office';
import { OfficeRequest } from '@app/real-estate-group/interfaces/office-request';
import { RealEstateGroupRequest } from '@app/real-estate-group/interfaces/real-estate-group-request';
import { State } from '@app/store/state';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    BivRealEstateAgent,
    CreateBivRealEstateAgent,
    DbUtils,
    FeatureScopesConfig,
    PaginationRequest,
    PaginationResponse,
    RealEstateAgency,
    UiUtils,
    UpdateBivRealEstateAgent,
} from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { LogoResponse } from '../interfaces/logo-response.interface';
import { RealEstateGroup } from '../interfaces/real-estate-group.interface';
import { Theme } from '../interfaces/theme.interface';
import { RealEstateGroupService } from '../services/real-estate-group.service';
import {
    createBivRealEstateAgent,
    createBivRealEstateAgentSuccess,
    deleteBivRealEstateAgents,
    fetchBivRealEstateAgents,
    patchFeatureScopeConfig,
    resetRealEstateGroup,
    setLogo,
    setRealEstateGroup,
    setTheme,
    updateBivRealEstateAgent,
    updateBivRealEstateAgentSuccess,
} from '../state/real-estate-group.actions';
import {
    getBivRealEstateAgents,
    getExternalInfluencerUrl,
    getIdState,
    getLogoState,
    getNameState,
    getRealEstateGroupLocations,
    getRealEstateGroupState,
    getRealEstateGroupStateLoading,
    getServiceInsurancesState,
    getServiceIotsState,
    getServiceLPState,
    getServicesState,
    getThemeState,
} from '../state/real-estate-group.reducer';

@Injectable({
    providedIn: 'root',
})
export class RealEstateGroupSandbox {
    public readonly realEstateGroup$ = this.store$.pipe(select(getRealEstateGroupState));
    public readonly realEstateGroupOnce$ = this.realEstateGroup$.pipe(take(1));
    public readonly id$ = this.store$.pipe(select(getIdState));
    public readonly idOnce$ = this.id$.pipe(take(1));
    public readonly name$ = this.store$.pipe(select(getNameState));
    public readonly logo$ = this.store$.pipe(select(getLogoState));
    public readonly externalInfluencerUrl$ = this.store$.pipe(select(getExternalInfluencerUrl));
    public readonly theme$ = this.store$.pipe(select(getThemeState));
    public readonly themeOnce$ = this.theme$.pipe(take(1));
    public readonly services$ = this.store$.pipe(select(getServicesState));
    public readonly serviceInsurances$ = this.store$.pipe(select(getServiceInsurancesState));
    public readonly serviceIots$ = this.store$.pipe(select(getServiceIotsState));
    public readonly serviceIotsOnce$ = this.serviceIots$.pipe(take(1));
    public readonly serviceLP$ = this.store$.pipe(select(getServiceLPState));
    public readonly bivRealEstateAgents$ = this.store$.pipe(select(getBivRealEstateAgents));
    public readonly locations$ = this.store$.pipe(select(getRealEstateGroupLocations));
    public readonly loading$ = this.store$.pipe(select(getRealEstateGroupStateLoading));
    public readonly createOrPatchBivRealEstateAgentSuccess$ = this.actions$.pipe(
        ofType(createBivRealEstateAgentSuccess, updateBivRealEstateAgentSuccess)
    );

    constructor(
        private readonly realEstateGroupService: RealEstateGroupService,
        private readonly store$: Store<State>,
        private readonly actions$: Actions,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public create(realEstateGroupRequest: RealEstateGroupRequest): Observable<RealEstateGroup> {
        return this.realEstateGroupService.create(realEstateGroupRequest);
    }

    public update(id: string, payload): Observable<RealEstateGroup> {
        return this.realEstateGroupService.update(id, payload);
    }

    public getRealEstateGroups(realEstateGroupFilter?: RealEstateGroupFilter): Observable<PaginationResponse<RealEstateGroup>> {
        return this.realEstateGroupService.getAll(realEstateGroupFilter);
    }

    public getLogo(realEstateAgentId: string): Observable<LogoResponse> {
        return this.realEstateGroupService.getLogo(realEstateAgentId);
    }

    public getOffice(realEstateAgentId: string): Observable<RealEstateAgentOffice> {
        return this.realEstateGroupService.getOffice(realEstateAgentId);
    }

    public getOffices(realEstateGroupFilter?: RealEstateGroupFilter): Observable<RealEstateAgentOffice[]> {
        return this.realEstateGroupService.getOffices(realEstateGroupFilter);
    }

    public createOffice(officeRequest: OfficeRequest): Observable<RealEstateGroup> {
        return this.realEstateGroupService.createOffice(officeRequest);
    }

    public getRealEstateGroupForMe(): Observable<RealEstateGroup> {
        return this.realEstateGroupService.getRealEstateGroupForMe();
    }

    public setRealEstateGroup(realEstateGroup: RealEstateGroup): void {
        this.store$.dispatch(setRealEstateGroup({ realEstateGroup }));
    }

    public resetRealEstateGroup(): void {
        this.store$.dispatch(resetRealEstateGroup());
    }

    public findTeam(
        realEstateAgentFilter: AdminRealEstateAgentFilter,
        paginationRequest: PaginationRequest
    ): Observable<PaginationResponse<RealEstateAgent>> {
        return this.realEstateGroupService.findTeam(realEstateAgentFilter, paginationRequest);
    }

    public getTheme(query?: { accessToken?: string; id?: string; realEstateAgentId?: string }): Observable<Theme> {
        return this.realEstateGroupService.getTheme(query);
    }

    public setTheme(theme: Theme): void {
        this.store$.dispatch(setTheme({ theme }));
    }

    public addRealEstateAgentToOffice(realEstateAgentId: string, officeId: string): Observable<RealEstateGroup> {
        return this.realEstateGroupService.addRealEstateAgentToOffice(realEstateAgentId, officeId);
    }

    public setThemeStyling(theme: Theme): void {
        if (!!theme && !!theme.accentBackground) {
            const rgb = UiUtils.hexToRgb(theme.accentBackground);
            if (rgb) {
                document.documentElement.style.setProperty('--theme-accent-background-rgb', `${rgb.r}, ${rgb.g}, ${rgb.b}`);
            } else {
                document.documentElement.style.setProperty('--theme-accent-background-rgb', null);
            }
        } else {
            document.documentElement.style.setProperty('--theme-accent-background-rgb', null);
        }

        if (!!theme && !!theme.accentBackgroundSecondary) {
            const rgb = UiUtils.hexToRgb(theme.accentBackgroundSecondary);
            if (rgb) {
                document.documentElement.style.setProperty('--theme-accent-background-secondary-rgb', `${rgb.r}, ${rgb.g}, ${rgb.b}`);
            } else {
                document.documentElement.style.setProperty('--theme-accent-background-secondary-rgb', null);
            }
        } else {
            document.documentElement.style.setProperty('--theme-accent-background-secondary-rgb', null);
        }

        if (!!theme && !!theme.accentColor) {
            const rgb = UiUtils.hexToRgb(theme.accentColor);
            if (rgb) {
                document.documentElement.style.setProperty('--theme-accent-color-rgb', `${rgb.r}, ${rgb.g}, ${rgb.b}`);
            } else {
                document.documentElement.style.setProperty('--theme-accent-color-rgb', null);
            }
        } else {
            document.documentElement.style.setProperty('--theme-accent-color-rgb', null);
        }
    }

    public unsetThemeStyling(): void {
        document.documentElement.style.setProperty('--theme-accent-background-rgb', null);
        document.documentElement.style.setProperty('--theme-accent-background-secondary-rgb', null);
        document.documentElement.style.setProperty('--theme-accent-color-rgb', null);
    }

    public setLogo(logo: string): void {
        this.store$.dispatch(setLogo({ logo }));
    }

    /**
     * filter source list by comparing searched value with group name
     * @param value: searched value
     * @param source: list source
     */
    public filterRealEstateGroup(value: string, source: RealEstateGroup[]): RealEstateGroup[] {
        return source?.filter((group) => group.name.toLowerCase().includes(value.toLowerCase()));
    }

    public activateService(service: string): Observable<void> {
        return this.realEstateGroupService.activateService(service);
    }

    public deactivateService(service: string): Observable<void> {
        return this.realEstateGroupService.deactivateService(service);
    }

    public getLocations(): Observable<RealEstateAgency[]> {
        return this.idOnce$.pipe(switchMap((id) => this.realEstateGroupService.getLocations(id)));
    }

    public addLocation(location: RealEstateAgency): Observable<RealEstateAgency[]> {
        return this.idOnce$.pipe(switchMap((id) => this.realEstateGroupService.addLocation(id, location)));
    }

    public editLocation(location: RealEstateAgency): Observable<RealEstateAgency[]> {
        return this.idOnce$.pipe(switchMap((id) => this.realEstateGroupService.patchLocation(id, location._id, location)));
    }

    public deleteLocation(location: RealEstateAgency): Observable<RealEstateAgency[]> {
        return this.idOnce$.pipe(switchMap((id) => this.realEstateGroupService.deleteLocation(id, location._id)));
    }

    public patchFeatureScopeConfig(featureScopesConfig: FeatureScopesConfig, realEstateGroup: RealEstateGroup): void {
        this.store$.dispatch(patchFeatureScopeConfig({ id: DbUtils.getStringId(realEstateGroup), featureScopesConfig }));
    }

    public fetchBivRealEstateAgents(realEstateGroup: RealEstateGroup): void {
        this.store$.dispatch(fetchBivRealEstateAgents({ realEstateGroup }));
    }

    public deleteBivRealEstateAgents(realEstateGroup: RealEstateGroup, bivAgents: BivRealEstateAgent[]): void {
        const agentIds = bivAgents.map((agent) => DbUtils.getStringId(agent));
        this.store$.dispatch(deleteBivRealEstateAgents({ realEstateGroup, agentIds }));
    }

    public createBivRealEstateAgent(realEstateGroup: RealEstateGroup, agent: CreateBivRealEstateAgent): void {
        this.store$.dispatch(createBivRealEstateAgent({ realEstateGroup, agent }));
    }

    public patchBivRealEstateAgent(realEstateGroup: RealEstateGroup, agent: UpdateBivRealEstateAgent): void {
        this.store$.dispatch(updateBivRealEstateAgent({ realEstateGroup, agent }));
    }

    public createOrUpdateBivRealEstateAgent(agent: CreateBivRealEstateAgent | UpdateBivRealEstateAgent): void {
        this.realEstateGroupOnce$.pipe(take(1)).subscribe((realEstateGroup) => {
            DbUtils.getStringId(agent)
                ? this.patchBivRealEstateAgent(realEstateGroup, agent as UpdateBivRealEstateAgent)
                : this.createBivRealEstateAgent(realEstateGroup, agent as CreateBivRealEstateAgent);
        });
    }

    public sendDeactivateMail(featureScope: string): void {
        this.deactivateService(featureScope).subscribe((_) => {
            this.notificationSandbox.success(I18nKey.DeactivateSuccess, { featureScope });
        });
    }

    public sendActivationMail(featureScope: string): void {
        this.activateService(featureScope).subscribe((_) => {
            this.notificationSandbox.success(I18nKey.ActivateSuccess, { featureScope });
        });
    }
}
