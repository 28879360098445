import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MetaLocationService } from './meta-location.service';
import { MetaGroupService } from './meta-group.service';
import { MetaReviewService } from './meta-review.service';
import { MetaZipCodePageService } from './meta-zip-code-page.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [MetaLocationService, MetaGroupService, MetaReviewService, MetaZipCodePageService],
})
export class MetaModule {}
