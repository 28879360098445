import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { HasFeatureAccessDirective } from './directives/has-feature-access.directive';
import { FeatureScopeProvidersUiModule } from './feauture-scope-providers.module';
import { featureScopeReducer } from './state/feature-scope.reducer';

const directives = [HasFeatureAccessDirective];

@NgModule({
    declarations: [...directives],
    exports: [...directives],
    imports: [CommonModule, FeatureScopeProvidersUiModule, StoreModule.forFeature('feature-scope', featureScopeReducer)],
})
export class FeatureScopeUiModule {}
