import { EnumUtils } from '@app/shared/utils/enum-utils';
import { Language } from '@smooved/core';
import { Colors, DropdownInput, SelectInput, UiDirection, UiSize } from '@smooved/ui';

export const darkMode = 'flashlight_off';
export const lightMode = 'flashlight_on';

export const screenshotWidth = 400;
export const screenshotHeight = 400;

export enum FormFields {
    Direction = 'direction',
    Size = 'size',
    Language = 'language',
    HasShadow = 'shadow',
    HasBackground = 'background',
    IsMonochrome = 'monochrome',
    Color = 'color',
}

export const directionOptions: DropdownInput<UiDirection>[] = [
    ...EnumUtils.entries(UiDirection).map(([key, value]: [string, UiDirection]) => ({
        id: `direction-${key}`,
        name: 'direction',
        value,
        labelResource: `UI.DIRECTION.${key.toUpperCase()}`,
    })),
];

const sizeFilter = [UiSize.Small, UiSize.Medium];

export const sizeOptions: DropdownInput<UiSize>[] = [
    ...sizeFilter.map((value) => ({
        id: `size-${value}`,
        name: 'size',
        value: value,
        labelResource: `UI.SIZE.${value.toUpperCase()}`,
    })),
];

export const languageOptions: SelectInput<Language>[] = [
    ...Object.entries(Language).map(([key, value]: [string, Language]) => {
        return {
            id: `language-${key}`,
            labelResource: `LANGUAGE.${key.toUpperCase()}.LABEL`,
            value,
            name: 'language',
        };
    }),
];

export const directionDefault = UiDirection.Horizontal;
export const sizeDefault = UiSize.Medium;
export const colorDefault = Colors.White;
