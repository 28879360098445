import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpUtils, ObjectUtils, PaginationRequest, PaginationResponse, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { BaseUriConfig, BASE_URI_CONFIG } from '../../configs';
import { CompanyPageProvidersModule } from '../company-page-providers.module';
import { SMOOVED_APPLICATION_SOURCE } from '../configs';
import { RealEstateGroupAppointmentEndpoint, uriMapFactory } from '../constants';
import { SmoovedApplicationSource } from '../enums';
import { RealEstateGroupAppointment, RealEstateGroupAppointmentsFilter } from '../interfaces';

@Injectable({ providedIn: CompanyPageProvidersModule })
export class RealEstateGroupAppointmentService {
    constructor(
        private readonly httpClient: HttpClient,
        @Optional() @Inject(SMOOVED_APPLICATION_SOURCE) private readonly smoovedApplicationSource: SmoovedApplicationSource,
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig
    ) {}

    public getById(id: string): Observable<RealEstateGroupAppointment> {
        const uri = uriMapFactory(this.baseUriConfig.apiUri).get(RealEstateGroupAppointmentEndpoint.Id);
        return this.httpClient.get<RealEstateGroupAppointment>(StringUtils.parseUri(uri, { id }));
    }

    public getAll(
        filter: RealEstateGroupAppointmentsFilter,
        request: PaginationRequest
    ): Observable<PaginationResponse<RealEstateGroupAppointment>> {
        const uri = uriMapFactory(this.baseUriConfig.apiUri).get(RealEstateGroupAppointmentEndpoint.Base);
        ObjectUtils.removeEmpty(filter);
        ObjectUtils.removeEmpty(request);
        return this.httpClient.get<PaginationResponse<RealEstateGroupAppointment>>(uri, {
            params: HttpUtils.buildPaginationRequest(request, filter),
        });
    }

    public makeAppointment(
        realEstateGroupAppointment: RealEstateGroupAppointment,
        applicationSource?: SmoovedApplicationSource
    ): Observable<void> {
        return this.httpClient.post<void>(
            uriMapFactory(this.baseUriConfig.apiUri).get(applicationSource || this.smoovedApplicationSource),
            realEstateGroupAppointment
        );
    }
}
