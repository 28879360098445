import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-real-estate-agent-register-complete-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-margin-bottom u-flex-column u-flex-align-items-center">
                    <img class="u-margin-bottom-double" width="220" src="assets/images/hero.png" alt="Smooved" />

                    <h3 class="u-margin-bottom-double">
                        {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.SUCCESS.TITLE' | translate }}
                    </h3>

                    <p class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium">
                        {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.SUCCESS.TEXT' | translate }}
                    </p>

                    <app-button routerLink="/real-estate-agent">{{
                        'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.SUCCESS.CTA' | translate
                    }}</app-button>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class RealEstateAgentRegisterCompleteContainer implements OnInit {
    constructor() {}

    public ngOnInit(): void {}
}
