import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@smooved/ui';
import { AuthenticationModule } from '../authentication/authentication.module';
import { EnergyModule } from '../energy/energy.module';
import { FormModule } from '../form/form.module';
import { RealEstateAgentModule } from '../real-estate-agent/real-estate-agent.module';
import { SharedModule } from '../shared/shared.module';
import { StartComponent } from './components/start/start.component';
import { MoverStartContainer } from './containers/mover-start/mover-start.container';
import { StartContainer } from './containers/start/start.container';
import { StartRoutingModule } from './start-routing.module';

@NgModule({
    declarations: [StartComponent, StartContainer, MoverStartContainer],
    imports: [
        StartRoutingModule,
        CommonModule,
        FormModule,
        AuthenticationModule,
        IconModule,
        MatButtonModule,
        MatCheckboxModule,
        SharedModule,
        RealEstateAgentModule,
        MatDividerModule,
        MatExpansionModule,
        EnergyModule,
        TranslateModule,
    ],
})
export class StartModule {}
