import { AbstractControl } from '@angular/forms';

export function checkboxRequired(control: AbstractControl) {
    const checked: boolean = control.value;
    if (!control || !checked) {
        return {
            required: true,
        };
    }
    return null;
}
