import { Language } from '@smooved/core';
import { DropdownInput } from '../../form';

export const languageOptions = Object.entries(Language).map(([key, value]: [string, Language]): DropdownInput<string> => {
    return {
        id: `language-${value}`,
        label: key,
        name: 'language',
        value: value,
    };
});
