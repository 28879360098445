<table>
    <tr class="u-h-auto" *ngIf="data.eanCodeElectricity || data.meterNumberElectricity">
        <td class="u-padding-top-none u-padding-bottom-none u-padding-left-none">{{'ELECTRICITY.LETTER' | translate}}</td>
        <td class="u-padding-none">
            <app-copy-to-clipboard>{{data.eanCodeElectricity}}</app-copy-to-clipboard>
        </td>
        <td class="u-padding-none">
            <app-copy-to-clipboard>{{data.meterNumberElectricity}}</app-copy-to-clipboard>
        </td>
    </tr>
    <tr class="u-h-auto" *ngIf="data.eanCodeGas || data.meterNumberGas">
        <td class="u-padding-top-none u-padding-bottom-none u-padding-left-none">{{'GAS.LETTER' | translate}}</td>
        <td class="u-padding-none">
            <app-copy-to-clipboard>{{data.eanCodeGas}}</app-copy-to-clipboard>
        </td>
        <td class="u-padding-none">
            <app-copy-to-clipboard>{{data.meterNumberGas}}</app-copy-to-clipboard>
        </td>
    </tr>
</table>
