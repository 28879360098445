import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-moving-address',
    template: `
        <ng-container matColumnDef="movingAddress">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>
                {{ 'ADMIN.DASHBOARD.TABLE.MOVING_ADDRESS' | translate }}
            </th>
            <td
                mat-cell
                *matCellDef="let row"
                [title]="row.user?.formattedMovingAddress"
                class="__cell u-padding-left u-padding-right u-white-space-no-wrap u-text-truncate"
            >
                {{ row.user?.formattedMovingAddress }}
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-moving-address.component.scss'],
})
export class TableColumnMovingAddressComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
