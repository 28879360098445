import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { HeaderService } from '@app/header/services/header.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { Checked } from '@app/ui/components/checked/checked.enum';
import { Observable } from 'rxjs';
import { menuItems } from './settings-details-menu.constants';
import { FeatureScope, FeatureScopeSandbox } from '@smooved/core';

@Component({
    selector: 'app-settings-details-menu',
    templateUrl: './settings-details-menu.component.html',
    styles: [':host {display: block; padding: 1rem;}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsDetailsMenuComponent {
    public checked = Checked;
    public items = Array.from(menuItems.values());
    public navigation = Navigation;
    public featureScope = FeatureScope;

    constructor(
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly featurePermissionsSandbox: FeaturePermissionsSandbox,
        private readonly headerService: HeaderService
    ) {}

    public featureGranted$(featureScope: FeatureScope): Observable<boolean> {
        return this.featurePermissionsSandbox.featureGranted$(featureScope);
    }

    public trackByFeatureScope(index: Number, featureScope: FeatureScope): string {
        return featureScope;
    }

    public onMenuClick(): void {
        this.headerService.dispatch();
    }
}
