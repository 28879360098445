import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-insurances-suggestion-review-more-info',
    template: `
        <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-margin-bottom-half u-font-size-small">
            <span>{{ label }}</span>
            <app-stars [readonly]="true" class="u-margin-left-triple" [initialStars]="percentage / 20"></app-stars>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancesSuggestionReviewMoreInfoComponent {
    @Input() public label: string;
    @Input() public percentage: number;
}
