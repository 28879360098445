import { Action, createReducer, on } from '@ngrx/store';
import { uiLoading } from './ui.actions';

export const featureKey = 'ui';

export interface UiState {
    loading: boolean;
}

export const initialState: UiState = {
    loading: false,
};

export const escalationUiReducer = (state: UiState, action: Action): UiState => {
    return createReducer(
        initialState,
        on(uiLoading, (state, { loading }) => ({ ...state, loading }))
    )(state, action);
};
