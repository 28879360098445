import { Component } from '@angular/core';
import { AuthenticationSandbox } from '../../../authentication/sandboxes/authentication.sandbox';

@Component({
    selector: 'app-header-energy-contract',
    template: ` <h5 class="__title">{{ 'HEADER.ENERGY_CONTRACT' | translate }}</h5> `,
    styleUrls: ['./header-energy-contract.component.scss'],
})
export class HeaderEnergyContractComponent {
    constructor(public authenticationSandbox: AuthenticationSandbox) {}
}
