import { Injectable } from '@angular/core';
import { TokenResponse } from '@app/authentication/interfaces/token-response';
import { State } from '@app/store/state';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { User } from '@app/user/interfaces/user';
import { UserService } from '@app/user/services/user.service';
import { getFirstNameInviteDataState, getInviteDataState } from '@app/user/state/user.reducer';
import { select, Store } from '@ngrx/store';
import { ArrayUtils, DbUtils } from '@smooved/core';
import { DropdownInput, dropdownInputOptionAll } from '@smooved/ui';
import { Observable } from 'rxjs';
import { filter, finalize, map, mergeMap, take } from 'rxjs/operators';
import { UserGroups } from '../enums/user-groups.enum';
import { setInviteData } from '../state/user.actions';

@Injectable({
    providedIn: 'root',
})
export class UserSandbox {
    public inviteData$ = this.store$.pipe(select(getInviteDataState));
    public inviteDataOnce$ = this.inviteData$.pipe(take(1));
    public inviteDataOnceSet$ = this.inviteDataOnce$.pipe(filter((x) => !!x));

    public firstNameInviteData$ = this.store$.pipe(
        select(getFirstNameInviteDataState),
        map((firstName) => firstName || '')
    );

    constructor(private userService: UserService, private store$: Store<State>, private uiSandbox: AppUiSandbox) {}

    public getByConfirmationToken(confirmationToken: string): Observable<User> {
        return this.userService.getByConfirmationToken(confirmationToken);
    }

    public setInviteData(inviteData: User): void {
        this.store$.dispatch(setInviteData({ inviteData }));
    }

    public submitInviteData(password: string, confirmPassword: string): Observable<TokenResponse> {
        this.uiSandbox.showLoadingOverlay();
        return this.inviteDataOnce$.pipe(
            mergeMap((inviteData) => {
                const request: User = {
                    ...inviteData,
                    password,
                    confirmPassword,
                };
                return this.confirmInvite(request);
            }),
            finalize(() => this.uiSandbox.hideLoadingOverlay())
        );
    }

    public getUsersByUserGroups(...userGroups: UserGroups[]): Observable<User[]> {
        return this.userService.getUsersList(userGroups);
    }

    public getServicedByOptions(): Observable<DropdownInput<string>[]> {
        return this.userService.getUsersList().pipe(
            map((users) => {
                return [
                    dropdownInputOptionAll('serviced-by'),
                    ...users.map((user) => {
                        const id = DbUtils.getStringId(user);
                        return {
                            id: `serviced-by-${id}`,
                            label: `${user.firstName || user.email} ${user.lastName || ''}`,
                            name: 'serviced-by',
                            value: id,
                        };
                    }),
                ];
            })
        );
    }

    public getAccountManagerOptions(allOption = true): Observable<DropdownInput<string>[]> {
        return this.getUsersByUserGroups(UserGroups.Sales).pipe(
            map((users) => {
                return ArrayUtils.clean([
                    allOption ? dropdownInputOptionAll('account-manager') : undefined,
                    ...users.map((user) => {
                        const { firstName, lastName } = user;
                        return {
                            id: `${firstName}_${lastName}`,
                            label: `${firstName} ${lastName}`,
                            value: DbUtils.getStringId(user),
                            name: 'account-manager',
                        };
                    }),
                ]);
            })
        );
    }

    private confirmInvite(inviteData: User): Observable<TokenResponse> {
        return this.userService.confirmInvite(inviteData);
    }
}
