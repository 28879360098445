import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { i18nTakeOverInformation } from '@app/energy/constants/i18n.constants';
import { Move } from '@app/move/interfaces/move';
import { TranslateService } from '@ngx-translate/core';
import { ActionAudit, AuditUtils, DateUtils, DbUtils, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, NameUtils, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-meter-readings-takeover-done',
    templateUrl: './meter-readings-takeover-done.component.html',
})
export class MeterReadingsTakeoverDoneComponent implements OnChanges {
    @Input() public meterReadingsTakeoverByRealEstateAgent: ActionAudit<boolean>;
    @Input() public leaver: Move;

    public meterReadingsTakeoverDone: string;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;

    constructor(private readonly translateService: TranslateService, private readonly documentCenter: DocumentCenterSandbox) {}

    public ngOnChanges({ meterReadingsTakeoverByRealEstateAgent }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(meterReadingsTakeoverByRealEstateAgent)) {
            this.setMeterReadingsTakeoverDone();
        }
    }

    public downloadEDS(): void {
        this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(this.leaver));
    }

    private setMeterReadingsTakeoverDone(): void {
        if (AuditUtils.isFalsy(this.meterReadingsTakeoverByRealEstateAgent)) return;
        this.meterReadingsTakeoverDone = this.translateService.instant(i18nTakeOverInformation('DONE'), {
            realEstateAgent: NameUtils.getName(AuditUtils.getLastModifiedBy(this.meterReadingsTakeoverByRealEstateAgent)),
            date: DateUtils.format(AuditUtils.getLastModifiedOn(this.meterReadingsTakeoverByRealEstateAgent)),
        });
    }
}
