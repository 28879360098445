import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { UiDirection } from '@smooved/ui';
import { horizontalClass } from './empty-state.constants';

@Component({
    selector: 'app-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnChanges, AfterViewChecked {
    @Input() public direction: UiDirection;
    @Input() public hideCta = false;

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    public ngOnChanges({ direction }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(direction)) {
            this.setDirection();
        }
    }

    public ngAfterViewChecked(): void {
        this.setDirection();
    }

    private setDirection(): void {
        this.direction === UiDirection.Horizontal ? this.setHorizontal() : this.setVertical();
    }

    private setHorizontal(): void {
        this.renderer.addClass(this.el.nativeElement, horizontalClass);
    }

    private setVertical(): void {
        this.renderer.removeClass(this.el.nativeElement, horizontalClass);
    }
}
