import { CheckInput } from '@smooved/ui';

export enum DeleteMoveForm {
    RemoveLinkedMove = 'removeLinkedMove',
    Reason = 'reason',
}

export const removeLinkedMoveOption = (data: string): CheckInput<boolean> => ({
    id: 'remove-linked-move',
    name: 'remove-linked-move',
    value: true,
    labelResource: 'MOVE.DELETE.INCLUDE_LINKED_MOVE',
    labelParams: { linkedMove: data },
});
