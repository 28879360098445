import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'perYear',
})
export class PerYearPipe implements PipeTransform {
    transform(value: string | number, args?: any): string {
        if (!value) return null;
        const perYear = this.translate.instant('UI.YEAR').toLowerCase() as string;
        return `${value} / ${perYear}`;
    }

    constructor(private readonly translate: TranslateService) {}
}
