export enum Mimetypes {
    Pdf = 'application/pdf',
    Csv = 'text/csv',
    Word = 'application/msword',
    Json = 'application/json',
    Jpg = 'image/jpg',
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Txt = 'text/plain',
}
