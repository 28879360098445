import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FeatureScopeModule } from '@app/feature-scope/feature-scope.module';
import { BreadcrumbComponent } from '@app/header/components/breadcrumb/breadcrumb.component';
import { HeaderPartnerAgentMenuComponent } from '@app/header/components/header-partner-agent-menu/header-partner-agent-menu.component';
import { TranslationModule } from '@app/translation/translation.module';
import { UiModule } from '@app/ui/ui.module';
import { SvgModule } from '@smooved/ui';
import { AuthenticationModule } from '../authentication/authentication.module';
import { FormModule } from '../form/form.module';
import { RealEstateAgentModule } from '../real-estate-agent/real-estate-agent.module';
import { HeaderAdminMenuComponent } from './components/header-admin-menu/header-admin-menu.component';
import { HeaderBasicComponent } from './components/header-basic/header-basic.component';
import { HeaderDashboardExtraComponent } from './components/header-dashboard-extra/header-dashboard-extra.component';
import { HeaderEnergyContractComponent } from './components/header-energy-contract/header-energy-contract.component';
import { HeaderEnergyComponent } from './components/header-energy/header-energy.component';
import { HeaderEotsComponent } from './components/header-eots/header-eots.component';
import { HeaderMenuDashboardComponent } from './components/header-menu-dashboard/header-menu-dashboard.component';
import { HeaderMenuMobileComponent } from './components/header-menu-mobile/header-menu-mobile.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderMoveFiltersComponent } from './components/header-move-filters/header-move-filters.component';
import { HeaderOfferComponent } from './components/header-offer/header-offer.component';
import { HeaderRealEstateAgentFiltersComponent } from './components/header-real-estate-agent-filters/header-real-estate-agent-filters.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoButtonComponent } from './components/logo-button/logo-button.component';
import { RealEstateAgentLogoButtonComponent } from './components/real-estate-agent-logo-button/real-estate-agent-logo-button.component';
import { HeaderCoBrandedContainer } from './containers/header-co-branded/header-co-branded.container';
import { HeaderContainer } from './containers/header/header.container';
import { HeaderProvidersModule } from './header-providers.module';

@NgModule({
    declarations: [
        HeaderCoBrandedContainer,
        RealEstateAgentLogoButtonComponent,
        HeaderContainer,
        HeaderComponent,
        HeaderEotsComponent,
        HeaderOfferComponent,
        HeaderEnergyContractComponent,
        HeaderBasicComponent,
        LogoButtonComponent,
        BreadcrumbComponent,
        HeaderDashboardExtraComponent,
        HeaderEnergyComponent,
        HeaderMenuComponent,
        HeaderMenuMobileComponent,
        HeaderMenuDashboardComponent,
        HeaderMoveFiltersComponent,
        HeaderAdminMenuComponent,
        HeaderRealEstateAgentFiltersComponent,
        HeaderPartnerAgentMenuComponent,
    ],
    exports: [
        HeaderCoBrandedContainer,
        HeaderContainer,
        HeaderComponent,
        HeaderEotsComponent,
        HeaderEnergyContractComponent,
        HeaderBasicComponent,
        LogoButtonComponent,
    ],
    imports: [
        CommonModule,
        HeaderProvidersModule,
        RealEstateAgentModule,
        MatIconModule,
        MatMenuModule,
        MatDialogModule,
        MatButtonModule,
        TranslationModule,
        RouterModule,
        AuthenticationModule,
        MatButtonToggleModule,
        FormModule,
        SvgModule,
        UiModule,
        FeatureScopeModule,
    ],
})
export class HeaderModule {}
