import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { columns } from '@app/real-estate-agent/components/office-table/office-table.constants';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { UiIconAppearance } from '@smooved/ui';

@Component({
    selector: 'app-office-table',
    templateUrl: './office-table.component.html',
    styles: [
        `
                    .__icon {
                        width: 3rem;
                    }
        
                    .__icon-images {
                        margin-top: 0.4rem;
                    }
                `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeTableComponent {
    @Input() dataSource: RealEstateAgent[];
    public readonly iconAppearance = UiIconAppearance;
    public readonly columns = columns;
}
