import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergyOffer } from '@app/wizard/energy/interfaces/energy-offer';
import { TranslateService } from '@ngx-translate/core';
import { SelectInput, Svg } from '@smooved/ui';
import { isBoolean } from 'lodash';
import { Subscription, zip } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { SolarPanelComponent } from '../solar-panel/solar-panel.component';

@Component({
    selector: 'app-energy-type',
    template: `
        <p class="u-color-muted u-margin-bottom-triple">
            {{
                'STEP_INDICATION'
                    | translate
                        : {
                              start: stepStart,
                              end: stepEnd
                          }
            }}
        </p>

        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-select-input
                [label]="'MAKE_A_CHOICE.LABEL' | translate"
                [options]="energyTypeOptions"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                formControlName="energyType"
            ></app-select-input>

            <app-select-input
                [options]="heatElectricityOptions"
                *ngIf="showElectricityHeat()"
                [label]="'ENERGY.HEAT_ELECTRICITY.LABEL' | translate"
                [hasMargin]="false"
                [custom]="false"
                [hasMarginDouble]="true"
                formControlName="heatElectricity"
            ></app-select-input>

            <div *ngIf="showHasExclusiveNightMeter()" class="u-margin-bottom-double">
                <app-select-input
                    [options]="hasExclusiveNightMeterOptions"
                    [custom]="false"
                    [label]="'ENERGY.HAS_EXCLUSIVE_NIGHT_METER.LABEL' | translate"
                    [hasMargin]="true"
                    [hasMarginDouble]="false"
                    formControlName="hasExclusiveNightMeter"
                ></app-select-input>
                <p class="u-color-muted u-font-size-small u-font-style-italic">{{ 'ENERGY.HAS_EXCLUSIVE_NIGHT_METER.INFO' | translate }}</p>
            </div>

            <app-select-input
                [options]="hasSolarPanelsOptions"
                *ngIf="showHasSolarPanels()"
                [custom]="false"
                [label]="'ENERGY.SOLAR_PANEL.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                formControlName="hasSolarPanels"
            ></app-select-input>

            <app-solar-panel *ngIf="showSolarPanelOptions()"></app-solar-panel>

            <app-previous-submit [showPrevious]="showPrevious" (previous)="onPrevious()"></app-previous-submit>
        </form>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
})
export class EnergyTypeComponent implements OnInit, OnDestroy {
    @ViewChild(SolarPanelComponent) public solarPanel: SolarPanelComponent;

    @Input() public showPrevious: boolean;
    @Input() public stepStart: number;
    @Input() public stepEnd: number;

    @Output() public previous: EventEmitter<EnergyOffer> = new EventEmitter<EnergyOffer>();
    @Output() public energyTypeChanges: EventEmitter<EnergyType> = new EventEmitter<EnergyType>();
    @Output() public next: EventEmitter<EnergyOffer> = new EventEmitter<EnergyOffer>();

    public form: UntypedFormGroup = this.formBuilder.group({
        energyType: [null, Validators.required],
        hasExclusiveNightMeter: [null, null],
        heatElectricity: [null, null],
        hasSolarPanels: [null, null],
    });

    public heatElectricityOptions: SelectInput<boolean>[] = [
        {
            id: 'heat-electricity-yes',
            label: this.translateService.instant('YES'),
            value: true,
            name: 'heat-electricity',
        },
        {
            id: 'heat-electricity-no',
            label: this.translateService.instant('NO'),
            value: false,
            name: 'heat-electricity',
        },
    ];

    public hasExclusiveNightMeterOptions: SelectInput<boolean>[] = [
        {
            id: 'has-exclusive-night-meter-yes',
            label: this.translateService.instant('YES'),
            value: true,
            name: 'has-exclusive-night-meter',
        },
        {
            id: 'has-exclusive-night-meter-no',
            label: this.translateService.instant('NO'),
            value: false,
            name: 'has-exclusive-night-meter',
        },
    ];

    public hasSolarPanelsOptions: SelectInput<boolean>[] = [
        {
            id: 'has-solar-panel-yes',
            label: this.translateService.instant('YES'),
            value: true,
            name: 'has-solar-panel',
        },
        {
            id: 'has-solar-panel-no',
            label: this.translateService.instant('NO'),
            value: false,
            name: 'has-solar-panel',
        },
    ];

    public energyTypeOptions: SelectInput<EnergyType>[] = [
        {
            id: EnergyType.Both,
            svg: Svg.GasAndElect,
            label: this.translateService.instant('ENERGY_TYPE.BOTH'),
            value: EnergyType.Both,
            name: 'energy-type',
        },
        {
            id: EnergyType.Electricity,
            svg: Svg.Elect,
            label: this.translateService.instant('ENERGY_TYPE.ELECTRICITY'),
            value: EnergyType.Electricity,
            name: 'energy-type',
        },
        {
            id: EnergyType.Gas,
            svg: Svg.Gas,
            label: this.translateService.instant('ENERGY_TYPE.GAS'),
            value: EnergyType.Gas,
            name: 'energy-type',
        },
    ];

    private subscriptions: Subscription[] = [];

    constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private moveSandbox: MoveSandbox) {}

    public showHasExclusiveNightMeter(): boolean {
        return this.energyTypeFormControl()?.value === EnergyType.Both || this.energyTypeFormControl()?.value === EnergyType.Electricity;
    }

    public showHasSolarPanels(): boolean {
        return this.energyTypeFormControl()?.value === EnergyType.Both || this.energyTypeFormControl()?.value === EnergyType.Electricity;
    }

    public showElectricityHeat(): boolean {
        return this.energyTypeFormControl()?.value === EnergyType.Electricity;
    }

    public showSolarPanelOptions(): boolean {
        return !!this.hasSolarPanelsFormControl()?.value;
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.energyTypeFormControl()
                .valueChanges.pipe(startWith(this.energyTypeFormControl().value))
                .subscribe((value) => {
                    this.energyTypeChanges.emit(value);
                })
        );

        zip(
            this.moveSandbox.energyTypeOnce$,
            this.moveSandbox.hasExclusiveNightMeterOnce$,
            this.moveSandbox.heatElectricityOnce$,
            this.moveSandbox.hasSolarPanelsOnce$
        )
            .pipe(filter(([energyType]) => !!energyType))
            .subscribe(([energyType, hasExclusiveNightMeter, heatElectricity, hasSolarPanels]) => {
                this.energyTypeFormControl().patchValue(energyType);
                if (this.showHasExclusiveNightMeter() && isBoolean(hasExclusiveNightMeter)) {
                    this.hasExclusiveNightMeterFormControl().patchValue(hasExclusiveNightMeter);
                }
                if (this.showElectricityHeat() && isBoolean(heatElectricity)) {
                    this.heatElectricityFormControl().patchValue(heatElectricity);
                }
                if (this.showHasSolarPanels() && isBoolean(hasSolarPanels)) {
                    this.hasSolarPanelsFormControl().patchValue(hasSolarPanels);
                }
            });
    }

    public onSubmit(event): void {
        if (this.showSolarPanelOptions()) {
            this.solarPanel?.markAsSubmitted();
            if (this.form.valid && this.solarPanel?.form.valid) {
                this.next.emit(this.createPayload());
            }
        } else if (this.form.valid) {
            this.next.emit(this.createPayload());
        }
    }

    public onPrevious(): void {
        this.previous.emit(this.createPayload());
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    private createPayload(): EnergyOffer {
        const energyType = this.energyTypeFormControl().value;
        let payload: EnergyOffer = {
            energyType,
        };
        if (this.showHasExclusiveNightMeter()) {
            payload.hasExclusiveNightMeter = this.hasExclusiveNightMeterFormControl()?.value;
        } else {
            payload.hasExclusiveNightMeter = false;
        }

        if (this.showElectricityHeat()) {
            payload.heatElectricity = this.heatElectricityFormControl()?.value;
        } else {
            payload.heatElectricity = false;
        }

        if (this.showHasSolarPanels()) {
            payload.hasSolarPanels = this.hasSolarPanelsFormControl()?.value;
            if (this.showSolarPanelOptions()) {
                payload = {
                    ...payload,
                    ...this.solarPanel.getFormValue(),
                };
            }
        } else {
            payload.hasSolarPanels = false;
        }

        return payload;
    }

    private energyTypeFormControl(): AbstractControl {
        return this.form.get('energyType');
    }

    private heatElectricityFormControl(): AbstractControl {
        return this.form.get('heatElectricity');
    }

    private hasExclusiveNightMeterFormControl(): AbstractControl {
        return this.form.get('hasExclusiveNightMeter');
    }

    private hasSolarPanelsFormControl(): AbstractControl {
        return this.form.get('hasSolarPanels');
    }
}
