import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseInitContainer } from '@app/client/classes/base.init';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-client-init-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card>
                    <h3>{{ 'CLIENT.INIT.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">{{ 'CLIENT.INIT.PARAGRAPH' | translate }}</p>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-start">
                        <app-select-input
                            data-testid="proposal-options"
                            [options]="options"
                            [hasMargin]="false"
                            [hasMarginDouble]="true"
                            formControlName="moveType"
                        ></app-select-input>

                        <app-button type="submit" data-testid="submit-button">{{ 'SUBMIT' | translate }}</app-button>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ClientInitContainer extends BaseInitContainer {
    constructor(
        protected readonly formBuilder: UntypedFormBuilder,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly moveSandbox: MoveSandbox
    ) {
        super(formBuilder, moveSandbox, route, router);
    }
}
