import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { CreatedByFlowEnum } from '@smooved/core';

@Component({
    selector: 'app-table-column-insurances-flow-type',
    template: `
        <ng-container matColumnDef="insurancesFlowType">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'TYPE' | translate }}</th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="getType(row)" class="__cell u-padding-left u-padding-right">
                <div *ngSwitchCase="createdByFlow.Iots" class="u-white-space-no-wrap">{{ 'IOTS.LABEL' | translate }}</div>
            </td>
        </ng-container>
    `,
})
export class TableColumnInsurancesFlowTypeComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    public createdByFlow = CreatedByFlowEnum;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }

    public getType(move): CreatedByFlowEnum {
        if (!move) return null;
        if (move.createdByFlow?.iots) {
            return CreatedByFlowEnum.Iots;
        } else {
            return null;
        }
    }
}
