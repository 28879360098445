import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FamilySizeComponent } from './components/family-size/family-size.component';
import { HomeConstructionYearComponent } from './components/home-construction-year/home-construction-year.component';
import { HomeDescriptionComponent } from './components/home-description/home-description.component';
import { RoomDivisionComponent } from './components/room-division/room-division.component';

@NgModule({
    declarations: [FamilySizeComponent, HomeDescriptionComponent, HomeConstructionYearComponent, RoomDivisionComponent],
    imports: [SharedModule],
    exports: [FamilySizeComponent, HomeDescriptionComponent, HomeConstructionYearComponent, RoomDivisionComponent],
})
export class MetaModule {}
