import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { StopImpersonateComponent } from '@app/authentication/components/stop-impersonate/stop-impersonate.component';
import { PartnerRegisterCompleteContainer } from '@app/authentication/containers/partner-register-complete/partner-register-complete.container';
import { PartnerRegisterContactDetailsContainer } from '@app/authentication/containers/partner-register-contact-details/partner-register-contact-details.container';
import { PartnerRegisterPasswordContainer } from '@app/authentication/containers/partner-register-password/partner-register-password.container';
import { PartnerRegisterWelcomeContainer } from '@app/authentication/containers/partner-register-welcome/partner-register-welcome.container';
import { RealEstateAgentRegisterContactDetailsContainer } from '@app/authentication/containers/real-estate-agent-register-contact-details/real-estate-agent-register-contact-details.container';
import { RealEstateAgentRegisterOtherDetailsContainer } from '@app/authentication/containers/real-estate-agent-register-other-details/real-estate-agent-register-other-details.container';
import { RealEstateAgentRegisterPasswordContainer } from '@app/authentication/containers/real-estate-agent-register-password/real-estate-agent-register-password.container';
import { RealEstateAgentRegisterSuccessContainer } from '@app/authentication/containers/real-estate-agent-register-success/real-estate-agent-register-success.container';
import { RealEstateAgentRegisterWelcomeContainer } from '@app/authentication/containers/real-estate-agent-register-welcome/real-estate-agent-register-welcome.container';
import { CardModule } from '@app/card/card.module';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../form/form.module';
import { ModalModule } from '../modal/modal.module';
import { LegalModule } from '../wizard/legal/legal.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { RealEstateAgentLoginComponent } from './components/real-estate-agent-login/real-estate-agent-login.component';
import { RealEstateAgentRegisterComponent } from './components/real-estate-agent-register/real-estate-agent-register.component';
import { RealEstateAgentResetPasswordNewComponent } from './components/real-estate-agent-reset-password-new/real-estate-agent-reset-password-new.component';
import { RealEstateAgentResetPasswordComponent } from './components/real-estate-agent-reset-password/real-estate-agent-reset-password.component';
import { RegisterButtonComponent } from './components/register-button/register-button.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AdminLoginContainer } from './containers/admin-login/admin-login.container';
import { ConfirmRealEstateAgentRegisterContainer } from './containers/confirm-real-estate-agent-register/confirm-real-estate-agent-register.container';
import { ConfirmUserLoginContainer } from './containers/confirm-user-login/confirm-user-login.container';
import { RealEstateAgentLoginContainer } from './containers/real-estate-agent-login/real-estate-agent-login.container';
import { RealEstateAgentRegisterCompleteContainer } from './containers/real-estate-agent-register-complete/real-estate-agent-register-complete.container';
import { RealEstateAgentRegisterContainer } from './containers/real-estate-agent-register/real-estate-agent-register.container';
import { ResetPasswordContainer } from './containers/reset-password/reset-password.container';
import { LoginDialog } from './dialogs/login/login.dialog';
import { RegisterDialog } from './dialogs/register/register.dialog';
import { AuthenticationEffects } from './state/authentication.effects';
import { authenticationReducer } from './state/authentication.reducer';

@NgModule({
    declarations: [
        LoginDialog,
        RegisterDialog,
        LoginButtonComponent,
        RealEstateAgentLoginComponent,
        RealEstateAgentRegisterComponent,
        RealEstateAgentResetPasswordComponent,
        RealEstateAgentResetPasswordNewComponent,
        RegisterButtonComponent,
        ConfirmUserLoginContainer,
        RealEstateAgentLoginContainer,
        RealEstateAgentRegisterContainer,
        ConfirmRealEstateAgentRegisterContainer,
        AdminLoginComponent,
        AdminLoginContainer,
        RealEstateAgentRegisterWelcomeContainer,
        RealEstateAgentRegisterContactDetailsContainer,
        RealEstateAgentRegisterOtherDetailsContainer,
        RealEstateAgentRegisterPasswordContainer,
        RealEstateAgentRegisterSuccessContainer,
        RealEstateAgentRegisterCompleteContainer,
        StopImpersonateComponent,
        PartnerRegisterWelcomeContainer,
        PartnerRegisterContactDetailsContainer,
        PartnerRegisterPasswordContainer,
        PartnerRegisterCompleteContainer,
        ResetPasswordContainer,
        ResetPasswordComponent,
    ],
    imports: [
        AuthenticationRoutingModule,
        CommonModule,
        ModalModule,
        FormModule,
        MatButtonModule,
        TranslateModule,
        StoreModule.forFeature('authentication', authenticationReducer),
        EffectsModule.forFeature([AuthenticationEffects]),
        LegalModule,
        CardModule,
        SharedModule,
    ],
    exports: [LoginButtonComponent, RegisterButtonComponent, StopImpersonateComponent],
})
export class AuthenticationModule {}
