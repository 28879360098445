import { AbstractControl, ValidationErrors } from '@angular/forms';

export class AddressValidators {
    public static validateZipCode(control: AbstractControl): ValidationErrors {
        if (!control || !control.value) {
            return {
                required: true,
            };
        }
        const zipCode: string = control.value as string;
        return AddressValidators.zipCodeValidator(zipCode);
    }

    public static validateZipCodeOptional(control: AbstractControl): ValidationErrors {
        if (!control || !control.value) {
            return null;
        }
        const zipCode: string = control.value as string;
        return AddressValidators.zipCodeValidator(zipCode);
    }

    private static zipCodeValidator(zipCode: string): ValidationErrors {
        if (!zipCode.match(/^[0-9]*$/)) {
            return {
                'address.zipCode.only4Numbers': true,
            };
        }
        if (zipCode.length !== 4) {
            return {
                'address.zipCode.only4Numbers': true,
            };
        }
        return null;
    }
}
