import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceStatus } from '@app/move/enums/service-status.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { WaterDocumentMovingAddressModal } from '@app/water/modals/water-document-moving-address/water-document-moving-address.modal';
import { Move } from '@app/move/interfaces/move';
import { ModalSandbox, Svg } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-move-leaver-detail-water',
    template: `
        <ng-container *ngIf="moveSandbox.getWaterStatus(move?.linkedMove) as status" [ngSwitch]="status">
            <div *ngSwitchCase="serviceStatus.NoInfo" class="u-flex-row u-flex-align-items-start u-color-muted">
                <app-svg [name]="svg.Empty" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'MOVE.SERVICE_ACTIONS.NO_INFO' | translate }}</span>
            </div>

            <div *ngIf="status !== serviceStatus.NoInfo" class="u-flex-row u-flex-align-items-start u-margin-bottom">
                <app-check-with-background class="u-margin-right-half" width="20" height="20"></app-check-with-background>
                <span>{{ 'MOVE.SERVICE_ACTIONS.INFO_ADDED' | translate }}</span>
            </div>

            <div *ngSwitchCase="serviceStatus.Processing" class="u-flex-row u-flex-align-items-start u-color-muted">
                <app-svg [name]="svg.CheckCircle" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'MOVE.SERVICE_ACTIONS.PROCESSING' | translate }}</span>
            </div>

            <ng-container *ngSwitchCase="serviceStatus.Processed">
                <div class="u-flex-row u-flex-align-items-start">
                    <app-check-with-background class="u-margin-right-half" width="20" height="20"></app-check-with-background>
                    <span>{{ 'MOVE.SERVICE_ACTIONS.DOCUMENTS_COMPLETE' | translate }}</span>
                </div>
                <p class="u-font-size-small">{{ 'MOVE.SERVICE_ACTIONS.WATER_ATTENTION' | translate }}</p>
            </ng-container>
        </ng-container>

        <p *ngIf="showCTA" class="u-margin-top-half">
            <a class="u-link" (click)="openDocuments()">{{ 'MOVE.SERVICE_ACTIONS.WATER_CTA' | translate }}</a>
        </p>
    `,
    styles: [':host {display:block}'],
})
export class MoveLeaverDetailWaterComponent {
    @Input() public move: Move; //leaver
    @Input() public showCTA = true;

    @Output() public moveChange: EventEmitter<Move> = new EventEmitter<Move>();

    public svg = Svg;
    public serviceStatus = ServiceStatus;

    public movePatchedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public moveSandbox: MoveSandbox, private modalSandbox: ModalSandbox) {}

    public openDocuments(): void {
        const data: ModalDataMove = {
            move: this.move?.linkedMove as Move,
            patchedSubject: this.movePatchedSubject,
            readOnly: !!(this.move.linkedMove as Move)?.moveStates?.waterDocumentsMovingAddressByAdmin,
        };

        this.modalSandbox.openModal(null, null, null, WaterDocumentMovingAddressModal, { data }, (_) => this.handleModalClose());
    }

    private handleModalClose(): void {
        const movePatched = this.movePatchedSubject.value;
        if (!movePatched) return;

        this.moveSandbox.get(this.move._id).subscribe((move) => {
            this.moveChange.emit(move);
            this.movePatchedSubject.next(false);
        });
    }
}
