import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

@Component({
    selector: 'app-real-estate-agent-reset-password-new',
    template: `
        <div class="u-padding-top-double u-flex-row u-flex-justify-content-center">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column">
                <h3 class="u-margin-bottom">{{ 'AUTHENTICATION.FORGOT_PASSWORD.TITLE' | translate }}</h3>

                <app-password-input
                    [id]="'password'"
                    [label]="'Kies een wachtwoord'"
                    [placeholder]="'Wachtwoord'"
                    [formControlName]="'password'"
                ></app-password-input>

                <app-password-input
                    [id]="'confirmPassword'"
                    [label]="'Herhaal je wachtwoord'"
                    [placeholder]="'Wachtwoord'"
                    [formControlName]="'confirmPassword'"
                ></app-password-input>

                <div class="u-flex-column u-flex-align-items-center">
                    <app-button [disabled]="form.invalid" class="u-margin-bottom u-w100p" type="submit">
                        {{ 'AUTHENTICATION.FORGOT_PASSWORD.RESET.TEXT' | translate }}
                    </app-button>
                    <a class="u-link" [routerLink]="realEstateAgentLoginRouting">{{ 'CANCEL' | translate }}</a>
                </div>
            </form>
        </div>
    `,
})
export class RealEstateAgentResetPasswordNewComponent {
    public realEstateAgentLoginRouting = AppNavigationSandbox.getAbsolute(realEstateAgentLoginRouting);
    public form: UntypedFormGroup = this.formBuilder.group(
        {
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
        },
        {
            validator: (formGroup: UntypedFormGroup) => {
                const password = formGroup.controls.password.value;
                const confirmPassword = formGroup.controls.confirmPassword.value;

                if (!password || !confirmPassword) {
                    return null;
                }
                return password === confirmPassword
                    ? null
                    : {
                          confirmPassword: true,
                      };
            },
        }
    );

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authenticationSandbox: AuthenticationSandbox,
        private activatedRoute: ActivatedRoute
    ) {}

    public onSubmit(): void {
        const token: string = this.activatedRoute.snapshot.queryParams['t'];
        const id: string = this.activatedRoute.snapshot.queryParams['id'];
        if (this.form.valid && !!token && !!id) {
            this.authenticationSandbox.realEstateAgentResetPasswordNew(this.form.value.password, token, id);
        }
    }
}
