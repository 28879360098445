import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-check-item',
    template: `
        <div class="u-flex-row u-flex-align-items-start __container--align-{{ align }}">
            <div *ngIf="checked === true" class="u-flex-row __content">
                <app-check-with-background
                    width="20"
                    height="20"
                    class="__icon u-margin-right-half u-inline-flex-row"
                ></app-check-with-background>
                <ng-content select="checked"></ng-content>
            </div>

            <div *ngIf="checked === false" class="u-flex-row __content u-color-muted">
                <app-svg [name]="svg.Empty" width="24" class="__icon u-margin-right-half u-inline-flex-row" height="24"></app-svg>
                <ng-content select="unchecked"></ng-content>
            </div>

            <div *ngIf="blocked === true" class="u-flex-row __content">
                <app-svg [name]="svg.Blocked" width="24" height="24" class="__icon u-margin-right-half u-inline-flex-row"></app-svg>
                <ng-content select="blocked"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./check-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemComponent {
    @Input() public checked: boolean;
    @Input() public blocked: boolean;
    @Input() public align: 'top' | 'center' = 'center';

    public svg = Svg;
}
