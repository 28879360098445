import { CheckInput } from '@smooved/ui';

const basei18nKey = 'TELECOM.SUGGESTIONS.ASIDE';

export const unlimitedMobileMinutesOption: CheckInput<boolean> = {
    id: 'unlimited-mobile-minutes-option',
    name: 'unlimited-mobile-minutes-option',
    value: true,
    labelResource: `${basei18nKey}.MOBILE_MINUTES.LABEL`,
};

export const unlimitedInternetOption: CheckInput<boolean> = {
    id: 'unlimited-internet-option',
    name: 'unlimited-internet-option',
    value: true,
    labelResource: `${basei18nKey}.UNLIMIT_INTERNET`,
};

export const ignorePromotionsOption: CheckInput<boolean> = {
    id: 'ignore-promotions-option',
    name: 'ignore-promotions-option',
    value: true,
    labelResource: `${basei18nKey}.IGNORE_PROMOTIONS.LABEL`,
};

export enum TelecomSuggestionsFilterForm {
    UnlimitedInternet = 'unlimitedInternet',
    WifiSpeed = 'wifiSpeed',
    MobileNumberSims = 'mobileNumberSims',
    MobileData = 'mobileData',
    UnlimitedMobileMinutes = 'unlimitedMobileMinutes',
    OtherProvider = 'otherProvider',
    IgnorePromotions = 'ignorePromotions',
}

export const unlimitedInternetVolume = 999;
