import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { ModalDataMove } from '../interfaces/modal-data-move.interfaces';
import { ModalData } from '../interfaces/modal-data.interfaces';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';
import { RealEstateAgentDetailModalsService } from './real-estate-agent-detail-modals.service';
import { RealEstateAgentModalsService } from './real-estate-agent-modals.service';

@Injectable({ providedIn: RealEstateAgentProvidersModule })
export class RealEstateAgentModalsSandbox {
    constructor(
        private readonly modalService: RealEstateAgentModalsService,
        private readonly detailModalService: RealEstateAgentDetailModalsService
    ) {}

    public openMeterInfoModal(closeCallback?: (_?: any) => void): void {
        return this.modalService.openMeterInfoModal(closeCallback);
    }

    public openWaterDocumentModal(data: ModalDataMove, closeCallback: (_?: any) => void): void {
        return this.modalService.openWaterDocumentModal(data, closeCallback);
    }

    public openInsurancesModal(data: ModalData, closeCallback?: (_?: any) => void): void {
        return this.detailModalService.openInsurancesModal(data, closeCallback);
    }

    public openMortgageDetail(moveId: string, closeCallback?: (_?: any) => void): void {
        return this.modalService.openMortgageDetail(moveId, closeCallback);
    }

    public openTransfereeDetail(moveId: string, modalData = {}, closeCallback?: () => void): void {
        return this.detailModalService.openTransfereeDetail(moveId, modalData, closeCallback);
    }

    public openLeaverDetail(moveId: string, modalData = {}, closeCallback?: () => void): void {
        return this.detailModalService.openLeaverDetail(moveId, modalData, closeCallback);
    }

    public openUpdateLeaverSuppliersModal(move: Move, closeCallback?: (move: Move) => void): void {
        return this.modalService.openUpdateLeaverSuppliersModal(move, closeCallback);
    }

    public openMoverDetail(moveId: string, modalData = {}, closeCallback?: () => void): void {
        return this.detailModalService.openMoverDetail(moveId, modalData, closeCallback);
    }
}
