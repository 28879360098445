import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TelecomInstallationModal } from '@app/admin/modals/telecom-installation/telecom-installation.modal';
import { Order } from '@app/order/interfaces/order';
import { moveProperties } from '@shared/move/constants/properties.constants';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-check-item-telecom-installation-date-by-admin',
    template: `
        <app-check-item [checked]="!!order?.move?.telecomInstallationScheduleByAdmin" align="top">
            <div ngProjectAs="checked" class="u-flex-column u-flex-align-items-start">
                <span class="u-margin-bottom-half">{{ 'TELECOM.INSTALLATION_DATE_BY_ADMIN' | translate }}</span>
                <a (click)="showIndicateModal()" class="u-link">{{ 'EDIT_OR_VIEW' | translate }}</a>
            </div>
            <div ngProjectAs="unchecked" class="u-flex-column u-flex-align-items-start">
                <span class="u-margin-bottom-half">{{ 'TELECOM.INSTALLATION_DATE_BY_ADMIN' | translate }}</span>
                <app-button (onClick)="showIndicateModal()">{{ 'INDICATE' | translate }}</app-button>
            </div>
        </app-check-item>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemTelecomInstallationDateByAdminComponent {
    @Input() public order: Order;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public showIndicateModal(): void {
        const data = this.order?.move?.telecomInstallationScheduleByAdmin;
        this.modalSandbox.openModal(
            TelecomInstallationModal,
            { data },
            this.handleModalClosed,
            TelecomInstallationModal,
            { data },
            this.handleModalClosed
        );
    }

    private handleModalClosed = (installationDate) => {
        if (!installationDate) return;
        this.patch.emit({
            [moveProperties.telecomInstallationScheduleByAdmin]: installationDate,
        });
    };
}
