<app-ui-card
    [header]="header"
    [sub]="sub"
    [variant]="variant"
    [border]="border"
    [mainClass]="mainClass"
    [context]="context"
    [stretchOnMobile]="stretchOnMobile"
    class="u-h100p u-flex-column u-flex-justify-content-center u-position-relative u-padding-md-tablet-portrait-up {{ cardClass }}"
>
    <app-icon *ngIf="context === cardContext.Danger" icon="warning" size="Xm" appearance="round" class="__icon u-color-danger"></app-icon>
    <ng-content></ng-content>
</app-ui-card>
