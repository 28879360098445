import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CustomMessageTemplate } from '../interfaces/custom-message-template';
import { communication } from './communcation.constants';
import {
    clearCustomMessageTemplates,
    getCustomMessageTemplatesFailure,
    getCustomMessageTemplatesSuccess,
    loadCustomMessageTemplates,
} from './communication.actions';

export interface CommunicationState {
    loading: boolean;
    templates: CustomMessageTemplate[];
    moveId: string;
}

export const initialCommunicationState: CommunicationState = {
    loading: false,
    templates: null,
    moveId: null,
};

const reducer = createReducer(
    initialCommunicationState,

    on(loadCustomMessageTemplates, (state, action) => ({
        ...state,
        loading: true,
        moveId: action.moveId,
    })),

    on(getCustomMessageTemplatesSuccess, (state, action) => ({
        ...state,
        loading: false,
        templates: action.templates || [],
    })),

    on(getCustomMessageTemplatesFailure, (state, _) => ({
        ...state,
        loading: false,
        templates: null,
        moveId: null,
    })),

    on(clearCustomMessageTemplates, (state, _) => ({
        ...state,
        templates: null,
        moveId: null,
    }))
);

export const communicationReducer = (state: CommunicationState | undefined, action: Action): CommunicationState => reducer(state, action);
const communicationState = createFeatureSelector<CommunicationState>(communication);
export const getCommunicationState = createSelector(communicationState, (state) => state);
export const getTemplatesState = createSelector(communicationState, (state) => state?.templates);
export const getLoadingState = createSelector(communicationState, (state) => state?.loading);
