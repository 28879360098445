import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NoRealEstateAgentGuard {
    constructor(
        private authenticationSandbox: AuthenticationSandbox,
        private navigationSandbox: AppNavigationSandbox
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.checkAccess();
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAccess();
    }

    private checkAccess(): Observable<boolean> {
        return this.authenticationSandbox.isRealEstateAgent$.pipe(
            map((isRealEstateAgent) => {
                if (!isRealEstateAgent) {
                    return true;
                }
                this.navigationSandbox.goToRealEstateAgentStart().then();
                return false;
            }),
            take(1)
        );
    }
}
