import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { WaterDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/water-document-asset-detail/water-document-asset-detail.component';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-water-info-detail',
    templateUrl: 'water-info-detail.component.html',
    styleUrls: ['./water-info-detail.component.scss'],
})
export class WaterInfoDetailComponent {
    @Input() public move: Move;

    constructor(private dialog: MatDialog) {}

    public readonly environment = environment;

    public onWaterDocumentAssetDetail(file: FileInput) {
        this.dialog
            .open(WaterDocumentAssetDetailComponent, {
                data: {
                    moveId: this.move._id,
                    file,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.move = move;
            });
    }
}
