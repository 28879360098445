import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { crmClientOptions, crmClientsToObject } from '@app/client/constants/client.constants';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-real-estate-agent-register-other-details-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.OTHER_DETAILS.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: 2,
                                          end: 3
                                      }
                        }}
                    </p>

                    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
                        <div
                            class="u-flex-responsive u-flex-align-items-center-tablet-portrait-up u-margin-bottom-double-up-to-and-including-phone-landscape"
                        >
                            <app-text-input
                                formControlName="bivCode"
                                [autoFocus]="true"
                                [placeholder]="'OPTIONAL' | translate"
                                [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.OTHER_DETAILS.BIV_CODE' | translate"
                            ></app-text-input>
                            <div class="__slice u-margin-left-tablet-portrait-up">
                                <p class="u-color-muted u-font-size-small">
                                    {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.BIV_CODE' | translate }}
                                </p>
                            </div>
                        </div>

                        <div
                            class="u-flex-responsive u-flex-align-items-center-tablet-portrait-up"
                            [class.u-margin-bottom-double-up-to-and-including-phone-landscape]="!last"
                            [class.u-margin-bottom-double]="last"
                            *ngFor="let option of crmClientOptions; let last = last"
                        >
                            <app-text-input
                                [formControlName]="option.formControlName"
                                [placeholder]="'OPTIONAL' | translate"
                                [label]="option.label | translate"
                            ></app-text-input>
                            <div class="__slice u-margin-left-tablet-portrait-up">
                                <p class="u-color-muted u-font-size-small">
                                    {{
                                        'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.CRM_CLIENT_ID'
                                            | translate: { client: option.name }
                                    }}
                                </p>
                            </div>
                        </div>

                        <app-previous-submit (previous)="goToPrevious()"></app-previous-submit>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    styleUrls: ['./real-estate-agent-register-other-details.container.scss'],
})
export class RealEstateAgentRegisterOtherDetailsContainer implements OnInit, OnDestroy {
    public form: UntypedFormGroup = this.formBuilder.group({
        bivCode: [null, []],
        ...crmClientsToObject(() => [null, []]),
    });

    public readonly crmClientOptions = crmClientOptions;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private realEstateAgentSandbox: RealEstateAgentSandbox,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.realEstateAgentSandbox.realEstateAgentRegisterDataOnce$.pipe(filter((x) => !!x)).subscribe((registeredData) => {
            this.form.patchValue(registeredData);
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.router
                .navigate(['../password'], {
                    relativeTo: this.route,
                })
                .then();
        }
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../contact-details'], {
                relativeTo: this.route,
            })
            .then();
    }

    public ngOnDestroy(): void {
        this.realEstateAgentSandbox.setInviteData(this.form.value);
    }
}
