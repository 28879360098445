import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Note, NpsReview, ReviewSource, RxjsComponent } from '@smooved/core';
import { Observable } from 'rxjs';
import { ApplicationUtils } from '../../../application';
import { ButtonAppearance, ButtonSize } from '../../../button';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { UiIconSize } from '../../../icon/icon.enums';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { AuthorInterpolationParams } from '../../interfaces/author-interpolation-params';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';
import { NpsReviewsTemplateService } from '../nps-reviews-template/nps-reviews-template.service';

@Component({
    selector: 'app-nps-review-item',
    templateUrl: 'nps-review-item.component.html',
    styleUrls: ['nps-review-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NpsReviewItemComponent extends RxjsComponent implements OnInit {
    @Input() public item: NpsReview;
    @Input() public showUpgradeBox: boolean;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly iconSize = UiIconSize;
    public readonly reviewSources = ReviewSource;
    public readonly svgs = SvgIllustration;
    public readonly i18nKeyTypes = uiI18nKeyTypes;

    public maxChars: number;
    public showContent: boolean;

    constructor(
        private readonly npsReviewTemplateService: NpsReviewsTemplateService,
        @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.maxChars = this.templateConfig.maxChars || 0;
        this.showContent =
            this.item?.showReviewContent ||
            (!this.item?.showReviewContent && ApplicationUtils.isSmoovedApplication(this.templateConfig.application));
    }

    public onEdit(review: NpsReview, note: Note): void {
        this.npsReviewTemplateService.onEdit(review, note);
    }

    public getAuthorInterpolationParams(note: Note): Observable<AuthorInterpolationParams> {
        return this.npsReviewTemplateService.getAuthorInterpolationParams(note, this.item);
    }
}
