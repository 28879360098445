<app-nps-reviews-template [triggerAfter]="0" [npsActionsTemplate]="npsActions">
    <app-ui-card nps-reviews-header [border]="false" class="u-padding-none">
        <app-reviews-topics-sentiment-filter
            [topicAnalysis]="topicAnalysis"
            class="u-display-block u-bordered-ultralight-bottom u-padding-bottom-half"
        ></app-reviews-topics-sentiment-filter>
    </app-ui-card>
</app-nps-reviews-template>

<ng-template #npsActions let-item>
    <app-nps-actions-full [review]="item" [npsReviewsTemplateService]="npsReviewsTemplateService"></app-nps-actions-full>
</ng-template>
