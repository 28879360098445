import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReviewsData } from '@smooved/core';
import { UiSize } from '../../../ui.enums';

@Component({
    selector: 'smvd-ui-reviews-score',
    templateUrl: './reviews-score.component.html',
    styleUrls: ['./reviews-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @Deprecated use MatchScoreComponent in CompanyPage module
 */
export class ReviewsScoreComponent {
    @Input() public meta: ReviewsData;
    @Input() public showExternalScore = true;
    @Input() public showSmoovedScore = true;

    public readonly uiSizes = UiSize;
}
