import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-insurances-suggestion-more-info',
    template: `
        <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-margin-bottom-half u-font-size-small">
            <span class="u-color-muted">{{ label }}</span>
            <span>{{ value }}</span>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancesSuggestionMoreInfoComponent {
    @Input() public label: string;
    @Input() public value: any;
}
