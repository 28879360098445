<div *ngIf="{ active: dashboardMenuService.activeMenu$ | async, todos: todosCount$ | async } as vm">
    <app-menu [label]="'OVERVIEW' | translate">
        <app-menu-item
            (click)="setMenu(dashboardMenu.Transferees)"
            [active]="vm.active === dashboardMenu.Transferees"
            appearance="button"
            icon="shortcut"
            [iconRotate]="rotate.Deg90"
        >
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.MOVER_ROLE.TRANSFEREE' | translate }}
        </app-menu-item>
        <app-menu-item
            (click)="setMenu(dashboardMenu.Leavers)"
            [active]="vm.active === dashboardMenu.Leavers"
            appearance="button"
            icon="shortcut"
        >
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.MOVER_ROLE.LEAVER' | translate }}
        </app-menu-item>
    </app-menu>

    <app-menu [label]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.LABEL' | translate">
        <app-menu-item
            (click)="setMenu(dashboardMenu.AllTodos)"
            [active]="vm.active === dashboardMenu.AllTodos"
            [badge]="vm.todos?.all"
            appearance="button"
            icon="check_circle_outline"
        >
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.ALL' | translate }}
        </app-menu-item>
        <app-menu-item
            (click)="setMenu(dashboardMenu.MetersNotCompletedTodos)"
            [active]="vm.active === dashboardMenu.MetersNotCompletedTodos"
            [badge]="vm.todos?.meterInfoNotComplete"
            appearance="button"
            icon="speed"
            class="u-padding-left"
        >
            <span class="u-font-size-default u-white-space-no-wrap">{{
                'REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.METERINFONOTCOMPLETE' | translate
            }}</span>
        </app-menu-item>
        <app-menu-item
            (click)="setMenu(dashboardMenu.WaterNotComletedTodos)"
            [active]="vm.active === dashboardMenu.WaterNotComletedTodos"
            [badge]="vm.todos?.waterDocumentsMovingAddressByRealEstateAgent"
            appearance="button"
            icon="water_drop"
            class="u-padding-left"
        >
            <span class="u-font-size-default u-white-space-no-wrap">{{
                'REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.WATERDOCUMENTSMOVINGADDRESSBYREALESTATEAGENT' | translate
            }}</span>
        </app-menu-item>
    </app-menu>

    <app-menu [label]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.POTENTIAL' | translate">
        <app-menu-item
            (click)="setMenu(dashboardMenu.Gifts)"
            [active]="vm.active === dashboardMenu.Gifts"
            appearance="button"
            icon="redeem"
        >
            {{ 'GIFTS.LABEL_PLURAL' | translate }}
        </app-menu-item>
    </app-menu>
</div>

<app-real-estate-agent-navigation class="__menu-button u-display-block u-padding-top-double"></app-real-estate-agent-navigation>
