import { Injectable } from '@angular/core';
import { FeatureScopeGuard, FeatureScopeSandbox } from '@smooved/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppFeatureScopeGuard extends FeatureScopeGuard {
    constructor(
        protected readonly featureScopeSandbox: FeatureScopeSandbox,
        protected readonly router: Router
    ) {
        super(featureScopeSandbox, router);
    }
}
