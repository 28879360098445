import { Component, Input } from '@angular/core';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { Move } from '../../../move/interfaces/move';

@Component({
    selector: 'app-todo-energy-tansfer-meter-readings-sent',
    templateUrl: './todo-energy-transfer-meter-readings-sent.component.html',
})
export class TodoEnergyTransferMeterReadingsSentComponent {
    @Input() public move: Move;
    public readonly i18nKeys = AppI18nKeyType;
}
