<app-alert [context]="uiContext.Info" [icon]="uiIcon.Info" class="u-margin-bottom-double u-container-big">
    <div class="u-font-size-18 u-font-weight-semi-bold u-margin-bottom-half">
        {{ 'INVOICING.INVOICING_CHANGES_2024.TITLE' | translate }}
    </div>
    <p class="u-margin-bottom-half">
        {{ 'INVOICING.INVOICING_CHANGES_2024.EXPLANATION.SENTENCE_1.PART_1' | translate }}
        <span class="u-font-weight-semi-bold">{{ 'PACKAGE.SMOOVED_PLUS' | translate }}</span>
        {{ 'INVOICING.INVOICING_CHANGES_2024.EXPLANATION.SENTENCE_1.PART_2' | translate }}
        <span class="u-font-weight-semi-bold">{{ 'INVOICING.INVOICING_CHANGES_2024.EXPLANATION.SENTENCE_2' | translate }}</span>
        {{ 'INVOICING.INVOICING_CHANGES_2024.EXPLANATION.SENTENCE_3' | translate }}
    </p>

    <p>
        {{ 'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.HEADING.PART_1' | translate }}
        <span class="u-font-weight-semi-bold">{{ 'PACKAGE.SMOOVED_PLUS' | translate }}</span>
        {{ 'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.HEADING.PART_2' | translate }}
    </p>
    <ul class="u-margin-none u-list-style-disc">
        <li>{{ 'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.COMMISSION' | translate }}</li>
        <li>
            {{ 'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.SUBSCRIPTION.PART_1' | translate }}
            <a [href]="invoicingPricingUrl" target="_blank" class="u-font-weight-normal">{{
                'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.SUBSCRIPTION.LINK' | translate
            }}</a>
            {{ 'INVOICING.INVOICING_CHANGES_2024.TWO_INVOICES.SUBSCRIPTION.PART_2' | translate }}
        </li>
    </ul>
    <p class="u-margin-top-half">
        {{ 'INVOICING.INVOICING_CHANGES_2024.UNCHANGED.PART_1' | translate
        }}<span class="u-font-weight-semi-bold">{{ 'INVOICING.INVOICING_CHANGES_2024.UNCHANGED.BOLD' | translate }}</span>
    </p>
    <p class="u-margin-top-half">
        {{ 'INVOICING.INVOICING_CHANGES_2024.LIVE_SUBSCRIPTION.PART_1' | translate }}
        <span class="u-font-weight-semi-bold">{{ 'PACKAGE.SMOOVED_LIVE' | translate }}</span>
        {{ 'INVOICING.INVOICING_CHANGES_2024.LIVE_SUBSCRIPTION.PART_2' | translate }}
    </p>
    <p class="u-margin-top-half">
        {{ 'INVOICING.INVOICING_CHANGES_2024.QUESTIONS.PART_1' | translate
        }}<a [href]="'mailto:' + invoicingMailtoEmail" class="u-font-weight-normal">{{
            'INVOICING.INVOICING_CHANGES_2024.QUESTIONS.LINK' | translate
        }}</a>
    </p>
</app-alert>
