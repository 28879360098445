import { NavigationData } from '@smooved/core';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AnalyticsReviewsContainer } from '@app/real-estate-agent/containers/analytics-reviews/analytics-reviews.container';
import { InsightsContainer } from '@app/real-estate-agent/containers/insights/insights.container';
import { Routes } from '@angular/router';

export const reviewsRouting: Routes = [
    {
        path: Navigation.Overview,
        data: {
            [NavigationData.TrackPageView]: true,
        },
        component: AnalyticsReviewsContainer,
    },
    {
        path: Navigation.Insights,
        component: InsightsContainer,
    },
    {
        path: Navigation.RealEstateAgentNps,
        redirectTo: Navigation.Insights,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: Navigation.Overview,
    },
];
