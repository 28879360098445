import { MoveTransactionType } from '@smooved/core';
import { CheckInput } from '../../form/check-input/check-input';
import { SelectInput } from '../../form/select-input/select-input';
import { Svg } from '../../icon/icon.enums';
import { ElectricityMeterType } from '../enums/electricity-meter-type.enum';

export const energyI18n = {
    meterDigital: 'ENERGY.METER_TYPE.DIGITAL',
    meterSingle: 'ENERGY.METER_TYPE.SINGLE',
    meterDouble: 'ENERGY.METER_TYPE.DOUBLE',
    unknown: 'ENERGY.METER_TYPE.UNKNOWN',
    solarPanels: 'ENERGY.SOLAR_PANEL.LABEL',
    exclusiveNight: 'EXCLUSIVE_NIGHT',
    confirmAutomaticReadings: 'ENERGY.METER_READING.DIGITAL.CONFIRM_AUTOMATIC_READING',
};

export const placeholders = {
    eanPlaceholder: '54------------------',
    consumptionkWhLabel: 'ENERGY.METER_READING.METER_INFO.PLACEHOLDER_KWH_CONSUMPTION',
    injectionkWhLabel: 'ENERGY.METER_READING.METER_INFO.PLACEHOLDER_KWH_INJECTION',
    consumptionMcLabel: 'ENERGY.METER_READING.METER_INFO.PLACEHOLDER_MC_CONSUMPTION',
};

export enum ElectricityFormFields {
    Active = 'active',
    MeterType = 'meterType',
    ExclusiveNight = 'exclusiveNight',
    EanCode = 'eanCodeElectricity',
    SingleMeterReading = 'electricitySingleMeterReading',
    DoubleDayMeterReading = 'electricityDoubleDayMeterReading',
    DoubleNightMeterReading = 'electricityDoubleNightMeterReading',
    AutomaticDigitalReadings = 'automaticDigitalReadings',
    MovingDate = 'movingDate',
    ConsumptionDayMeterReading = 'consumptionDayMeterReading',
    ConsumptionNightMeterReading = 'consumptionNightMeterReading',
    SolarPanels = 'solarPanels',
    InjectionDayMeterReading = 'injectionDayMeterReading',
    InjectionNightMeterReading = 'injectionNightMeterReading',
    ExclusiveNightMeterReading = 'electricityExclusiveNightMeterReading',
}

export interface ElectricityForm {
    active: boolean;
    meterType?: ElectricityMeterType;
    exclusiveNight?: boolean;
    eanCodeElectricity?: string;
    electricitySingleMeterReading?: string;
    electricityDoubleDayMeterReading?: string;
    electricityDoubleNightMeterReading?: string;
    automaticDigitalReadings?: boolean;
    movingDate?: Date;
    consumptionDayMeterReading?: string;
    consumptionNightMeterReading?: string;
    solarPanels?: boolean;
    injectionDayMeterReading?: string;
    injectionNightMeterReading?: string;
    electricityExclusiveNightMeterReading?: string;
}

export const electricityMeterTypeOptions: SelectInput<ElectricityMeterType>[] = [
    {
        labelResource: energyI18n.meterSingle,
        name: 'meter-type',
        value: ElectricityMeterType.Single,
        id: ElectricityMeterType.Single,
        svg: Svg.Day,
    },
    {
        labelResource: energyI18n.meterDouble,
        name: 'meter-type',
        value: ElectricityMeterType.Double,
        id: ElectricityMeterType.Double,
        svg: Svg.DayNight,
    },
    {
        labelResource: energyI18n.meterDigital,
        name: 'meter-type',
        value: ElectricityMeterType.Digital,
        id: ElectricityMeterType.Digital,
        icon: 'router',
    },
];

export const solarPanelsOption: CheckInput<boolean> = {
    labelResource: energyI18n.solarPanels,
    id: 'solar-panels',
    name: 'solar-panels',
    value: true,
};

export const exclusiveNightOption: CheckInput<boolean> = {
    labelResource: energyI18n.exclusiveNight,
    id: 'exclusive-night',
    name: 'exclusive-night',
    value: true,
};

export const exclusiveNightOptionFn = (label: string): CheckInput<boolean> => ({
    label,
    id: 'exclusive-night',
    name: 'exclusive-night',
    value: true,
});

export const meterTypeUnknownOption: SelectInput<ElectricityMeterType> = {
    labelResource: energyI18n.unknown,
    id: ElectricityMeterType.Unknown,
    name: 'meter-type',
    value: ElectricityMeterType.Unknown,
    svg: Svg.Unknown,
};

export const automaticDigitalReadingOptionFn = (transactionType: MoveTransactionType): CheckInput<boolean> => ({
    labelResource:
        transactionType === MoveTransactionType.Sale
            ? 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING_SALE'
            : 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING',
    id: 'automatic-digital-reading',
    name: 'automatic-digital-reading',
    value: true,
});

/** GAS */
export const gasControlNames = {
    active: 'active',
    eanCodeGas: 'eanCodeGas',
    eanCodeGasByAdmin: 'eanCodeGasByAdmin',
    meterNumberGasByAdmin: 'meterNumberGasByAdmin',
    gasMeterReading: 'gasMeterReading',
};

export interface GasMetersForm {
    active: boolean;
    eanCodeGas?: string;
    gasMeterReading?: string;
}
