import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { CheckInput, SelectInput } from '@smooved/ui';

export const paymentFields = {
    generalPaymentType: 'generalPaymentType',
    energyPaymentType: 'energyPaymentType',
    telecomPaymentType: 'telecomPaymentType',
    insurancesPaymentType: 'insurancesPaymentType',
    accountNumber: 'accountNumber',
    differentPaymentMethods: 'differentPaymentMethods',
    suppliersTermsAndConditions: 'suppliersTermsAndConditions',
    termsAndConditions: 'termsAndConditions',
    hopalaOptInPartner: 'hopalaOptInPartner',
    sepa: 'sepa',
};

export interface IPaymentFields {
    generalPaymentType: PaymentType;
    energyPaymentType: PaymentType;
    telecomPaymentType: PaymentType;
    insurancesPaymentType: PaymentType;
    accountNumber: string;
    differentPaymentMethods: boolean;
    suppliersTermsAndConditions: boolean;
    termsAndConditions: boolean;
    hopalaOptInPartner: boolean;
    sepa: boolean;
}

export const differentPaymentMethodsOption: CheckInput<boolean> = {
    id: 'different-payment-options',
    name: 'different-payment-options',
    value: true,
    labelResource: 'CONTRACT.PAYMENT_DETAILS.DIFFERENT.LABEL',
};

export const hopalaOptInPartnerOption: CheckInput<boolean> = {
    id: 'hopala-opt-in-partner',
    name: 'hopala-opt-in-partner',
    value: true,
    labelResource: 'INSURANCES.OPT_IN_PARTNER',
};

export const sepaOption: CheckInput<boolean> = {
    id: 'sepa-option',
    name: 'sepa-option',
    value: true,
    labelResource: 'SEPA',
};

export const generalPaymentTypeOptions: SelectInput<PaymentType>[] = [
    {
        id: 'general-payment-type-transfer',
        value: PaymentType.Transfer,
        name: 'general-payment-type',
        labelResource: 'TRANSFER',
    },
    {
        id: 'general-payment-type-domiciliation',
        value: PaymentType.Domiciliation,
        name: 'general-payment-type',
        labelResource: 'DOMICILIATION',
    },
];

export const energyPaymentTypeOptions: SelectInput<PaymentType>[] = [
    {
        id: 'energy-payment-type-transfer',
        value: PaymentType.Transfer,
        name: 'energy-payment-type',
        labelResource: 'TRANSFER',
    },
    {
        id: 'energy-payment-type-domiciliation',
        value: PaymentType.Domiciliation,
        name: 'energy-payment-type',
        labelResource: 'DOMICILIATION',
    },
];

export const telecomPaymentTypeOptions: SelectInput<PaymentType>[] = [
    {
        id: 'telecom-payment-type-transfer',
        value: PaymentType.Transfer,
        name: 'telecom-payment-type',
        labelResource: 'TRANSFER',
    },
    {
        id: 'telecom-payment-type-domiciliation',
        value: PaymentType.Domiciliation,
        name: 'telecom-payment-type',
        labelResource: 'DOMICILIATION',
    },
];

export const insurancesPaymentTypeOptions: SelectInput<PaymentType>[] = [
    {
        id: 'insurances-payment-type-transfer',
        value: PaymentType.Transfer,
        name: 'insurances-payment-type',
        labelResource: 'TRANSFER_YEARLY',
    },
    {
        id: 'insurances-payment-type-domiciliation',
        value: PaymentType.Domiciliation,
        name: 'insurances-payment-type',
        labelResource: 'DOMICILIATION_MONTHLY',
    },
];
