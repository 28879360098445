import { Component } from '@angular/core';
import { ResetPasswordForm } from '@app/authentication/components/reset-password/reset-password.constants';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.container.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ResetPasswordContainer {
    constructor(private readonly authenticationSandbox: AuthenticationSandbox) {}

    public resetPassword(form: ResetPasswordForm): void {
        this.authenticationSandbox.resetToken$
            .pipe(
                take(1),
                switchMap((resetToken) => this.authenticationSandbox.userResetPassword({ ...form, resetToken }))
            )
            .subscribe(() => {
                this.authenticationSandbox.resetToken = null;
            });
    }
}
