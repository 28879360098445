import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { UiPlacement } from '../../ui.enums';

@Directive({ selector: '[appInViewTrigger]' })
export class InViewTriggerDirective implements AfterViewInit, OnDestroy {
    @Input() public observeParent = false;
    @Input() public triggerOn: UiPlacement.Top | UiPlacement.Bottom = UiPlacement.Bottom;
    @Output() public visibleChanged = new EventEmitter<boolean>();

    private isBrowser: boolean;
    private observer: IntersectionObserver;

    constructor(@Inject(PLATFORM_ID) platformId: Object, private el: ElementRef) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public ngAfterViewInit(): void {
        const threshold = [this.triggerOn === UiPlacement.Top ? 1 : 0];
        if (this.isBrowser) {
            this.observer = new IntersectionObserver((entries) => this.visibleChanged.emit(entries[0]?.isIntersecting), { threshold });
            this.observer.observe(this.observeParent ? this.el.nativeElement.parentElement : this.el.nativeElement);
        }
    }

    public ngOnDestroy(): void {
        this.observer?.disconnect();
    }
}
