<app-menu direction="horizontal">
    <app-menu-item
        *ngFor="let item of filters"
        [active]="active === item.goTo"
        (click)="setActive(item.goTo)"
        [appearance]="menuItemAppearance.Button"
        [size]="menuItemSizes.Small"
        >{{ item.labelResource | translate }}</app-menu-item
    >
</app-menu>
