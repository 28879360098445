export enum FormControlNames {
    firstName = 'firstName',
    lastName = 'lastName',
    phoneNumber = 'phoneNumber',
    email = 'email',
    language = 'language',
    office = 'office',
    locations = 'locations',
    bivCode = 'bivCode',
    crmSystem = 'crmSystem',
    crmId = 'crmId'
}
