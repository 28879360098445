import { InjectionToken } from '@angular/core';
import { I18nKeyType, I18nKeyUtils, Interviewee } from '@smooved/core';
import { i18nKeyTypeMap, uiI18nKeyTypes } from '../../i18n/i18n-key-type-map.constants';
import { NpsReviewsTemplateConfig } from '../interfaces/nps-reviews-template-config';

export const NPS_REVIEWS_TEMPLATE_CONFIG = new InjectionToken<NpsReviewsTemplateConfig>('NPS_REVIEWS_TEMPLATE_CONFIG');

export const googleI18n = I18nKeyUtils.map(i18nKeyTypeMap, uiI18nKeyTypes.Socials, 'GOOGLE.LABEL');
export const facebookI18n = I18nKeyUtils.map(i18nKeyTypeMap, uiI18nKeyTypes.Socials, 'FACEBOOK.LABEL');

export const reviewsTemplateConfig: NpsReviewsTemplateConfig = {
    maxChars: 300,
    templateClasses: 'u-padding-left-none u-padding-right-none',
};

export const intervieweeOptions = Object.values(Interviewee).map((key) => ({
    id: key,
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, I18nKeyType.Interviewee, key),
    name: `interviewee-${key}`,
    value: key,
}));

export const smoovedSourceScoreToStarFactor = 2;

export const translationPathReviewVia = 'COMMON.REVIEWS.REVIEW_VIA.';

export const downloadUserAgent = 'puppeteer';

export const npsScoreWithRealEstateGroupIdUri = `:apiUri/surveys/nps-score/:realEstateGroupId`;

export const packageInfoUri = {
    nl: 'https://smooved.be/pakketten/',
    fr: 'https://smooved.be/fr/formules/',
    en: 'https://smooved.be/pakketten/',
};

export interface OtherScore {
    id: string;
    score: number;
}

export interface OtherScores extends Array<OtherScore> {}
