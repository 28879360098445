<app-cta-card
    [label]="'MORTGAGE.OVERVIEW.DETAILS.CLIENT_INACTIVE.TITLE' | translate"
    icon="warning"
    iconAppearance="round"
    iconClass="u-color-attention"
    [showContentMobile]="true"
>
    <p class="u-margin-bottom-half">{{ 'MORTGAGE.OVERVIEW.DETAILS.CLIENT_INACTIVE.DESC1' | translate }}</p>
    <p>{{ 'MORTGAGE.OVERVIEW.DETAILS.CLIENT_INACTIVE.DESC2' | translate }}</p>
    <button app-button type="link" (click)="goToEotS()">
        {{ 'MORTGAGE.OVERVIEW.DETAILS.CLIENT_INACTIVE.CTA' | translate }}
    </button>
</app-cta-card>
