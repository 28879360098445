import { ClientSource, InsightsData, ReviewsForMonth } from '@app/real-estate-agent/interfaces/insights';
import {
    getClientSourceSuccess,
    getHouseMatchScoreSuccess,
    getInsightsDataAction,
    getInsightsDataSuccess,
    getReviewScoresAction,
    getReviewScoresSuccess,
    getReviewsYearOverviewSuccess,
    getTopicsAnalysisAction,
    getTopicsAnalysisFailed,
    getTopicsAnalysisSuccess,
} from '@app/real-estate-group/state/insights.actions';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SentimentEnum } from '@shared/review/enums/sentiment.enum';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { ArrayUtils, ExternalInfluencerReviews } from '@smooved/core';

export interface InsightsState {
    loading: boolean;
    insightsData: InsightsData;
    reviewScores: ExternalInfluencerReviews;
    reviewsYearOverview: ReviewsForMonth[];
    clientSources: ClientSource[];
    topicsAnalysis: TopicAnalysis[];
    houseMatchScore: number;
}

export const initialInsightsState: InsightsState = {
    loading: null,
    insightsData: null,
    reviewScores: null,
    clientSources: [],
    reviewsYearOverview: [],
    topicsAnalysis: [],
    houseMatchScore: null,
};

const startLoading = (state): InsightsState => ({
    ...state,
    loading: true,
});

export const insightsReducer = createReducer(
    initialInsightsState,
    on(getInsightsDataAction, (state) => ({
        ...state,
        loading: true,
    })),
    on(getInsightsDataSuccess, (state, { insightsData }) => ({
        ...state,
        insightsData,
        loading: false,
    })),
    on(getHouseMatchScoreSuccess, (state, { houseMatchScore }) => ({
        ...state,
        houseMatchScore,
    })),
    on(getReviewScoresAction, startLoading),
    on(getReviewScoresSuccess, (state, { scores }) => ({
        ...state,
        loading: false,
        reviewScores: scores,
    })),
    on(getReviewsYearOverviewSuccess, (state, { data }) => ({
        ...state,
        reviewsYearOverview: data,
        loading: false,
    })),
    on(getClientSourceSuccess, (state, { data }) => ({
        ...state,
        clientSources: data,
        loading: false,
    })),
    on(getTopicsAnalysisAction, (state) => ({
        ...state,
        loading: true, // TODO: add loading state for own component? this will set the same loading container on the insights page right now
    })),
    on(getTopicsAnalysisSuccess, (state, { data }) => ({
        ...state,
        topicsAnalysis: data,
        loading: false,
    })),
    on(getTopicsAnalysisFailed, (state) => ({
        ...state,
        loading: false,
    }))
);

export const insightsStoreFeatureName = 'insights';
export const getInsightsState = createFeatureSelector<InsightsState>(insightsStoreFeatureName);
export const getInsightsData = createSelector(getInsightsState, (state) => state?.insightsData);
export const getReviewScores = createSelector(getInsightsState, (state: InsightsState) => state?.reviewScores);
export const getReviewsYearOverviewData = createSelector(getInsightsState, (state) => state?.reviewsYearOverview);
export const getClientSourcesData = createSelector(getInsightsState, (state) => state?.clientSources);
export const getTopicsAnalysis = createSelector(getInsightsState, (state) => state?.topicsAnalysis);
export const getHouseMatchScore = createSelector(getInsightsState, (state) => state?.houseMatchScore);
export const getTopicsAnalysisTopPositiveResults = createSelector(getTopicsAnalysis, (state) => {
    if (ArrayUtils.isEmpty(state)) return [];
    return state
        .filter((item) => !!item.sentimentPositive)
        .map((item) => ({
            category: item.category,
            sentiment: SentimentEnum.Positive,
            count: item.sentimentPositive,
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 3);
});

export const getTopicsAnalysisTopNegativeResults = createSelector(getTopicsAnalysis, (state) => {
    if (ArrayUtils.isEmpty(state)) return [];
    return state
        .filter((item) => !!item.sentimentNegative)
        .map((item) => ({
            category: item.category,
            sentiment: SentimentEnum.Negative,
            count: item.sentimentNegative,
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 3);
});

export const getTopicsAvailable = createSelector(
    getTopicsAnalysisTopNegativeResults,
    getTopicsAnalysisTopPositiveResults,
    (negative, positive) => {
        return negative.concat(...positive).reduce((sum, topic) => sum + topic.count, 0) > 0;
    }
);

export const getLoading = createSelector(getInsightsState, (state) => state?.loading);
