import { InjectionToken } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { RealEstateLevel } from '@smooved/core';
import { QuestionValidatorType } from '../enums/question-validator-type.enum';
import { SurveyConfig } from '../interfaces/survey-config.interface';

export const defaultNpsLevel = RealEstateLevel.Group;

export const SURVEY_CONFIG = new InjectionToken<SurveyConfig>('SURVEY_CONFIG');

export const mapQuestionValidator: { [key in QuestionValidatorType]: (value?: any) => ValidatorFn } = {
    [QuestionValidatorType.Required]: () => Validators.required,
    [QuestionValidatorType.Min]: (min: number) => Validators.min(min),
    [QuestionValidatorType.MinLength]: (minLength: number) => Validators.minLength(minLength),
    [QuestionValidatorType.Max]: (max: number) => Validators.max(max),
    [QuestionValidatorType.MaxLength]: (maxLength: number) => Validators.maxLength(maxLength),
    [QuestionValidatorType.Email]: () => Validators.email,
    [QuestionValidatorType.Pattern]: (pattern: string) => Validators.pattern(pattern),
};

export enum SurveyNavigation {
    Start = 'survey-start',
    SurveyStep = ':interview/:page',
}
