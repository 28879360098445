import { Invoice } from '@app/invoicing/interfaces/invoice';
import { Quarter } from '@app/invoicing/interfaces/quarter';
import { QuarterlyInvoicesTotals } from '@app/invoicing/interfaces/quarterly-invoices-totals';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import {
    getBillableInvoices,
    getBillableInvoicesFailed,
    getBillableInvoicesSuccess,
    getInvoice,
    getInvoiceFailed,
    getInvoices,
    getInvoicesFailed,
    getInvoicesSent,
    getInvoicesSentFailed,
    getInvoicesSentSuccess,
    getInvoicesSuccess,
    getInvoiceSuccess,
    getLastBillableInvoiceSuccess,
    getQuarterlyInvoicesTotals,
    getQuarterlyInvoicesTotalsFailed,
    getQuarterlyInvoicesTotalsSuccess,
    getQuarterlyInvoicing,
    getQuarterlyInvoicingFailed,
    getQuarterlyInvoicingSuccess,
    getQuarterSuccess,
    setQuarter,
} from '@app/invoicing/state/invoicing.actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { DateUtils } from '@smooved/core';
import moment from 'moment';
import { InvoiceStatus } from '../enums/invoice-status.enum';

export interface InvoicingState {
    quarterMoment: moment.Moment;
    quarter: Quarter;
    quarterlyInvoicesTotalsLoading: boolean;
    quarterlyInvoicesTotals: QuarterlyInvoicesTotals;
    invoiceLoading: boolean;
    invoice: Invoice;
    invoices: Invoice[];
    invoicesCount: number;
    invoicesLoading: boolean;
    quarterlyInvoicing: QuarterlyInvoicing;
    quarterlyInvoicingLoading: boolean;
    lastBillableInvoice: Invoice;
    billableInvoicesLoading: boolean;
    billableInvoices: Invoice[];
    invoicesSentLoading: boolean;
    invoicesSent: Invoice[];
}

export const initialInvoicingState: InvoicingState = {
    quarterMoment: null,
    quarter: null,
    quarterlyInvoicesTotalsLoading: false,
    quarterlyInvoicesTotals: null,
    invoiceLoading: false,
    invoice: null,
    invoices: null,
    invoicesCount: null,
    invoicesLoading: false,
    quarterlyInvoicing: null,
    quarterlyInvoicingLoading: false,
    lastBillableInvoice: null,
    billableInvoicesLoading: false,
    billableInvoices: null,
    invoicesSentLoading: false,
    invoicesSent: null,
};

const reducer = createReducer(
    initialInvoicingState,

    on(getQuarterlyInvoicesTotals, (state) => ({
        ...state,
        quarterlyInvoicesTotalsLoading: true,
    })),

    on(getQuarterSuccess, (state, action) => ({
        ...state,
        quarter: action.quarter,
    })),

    on(getQuarterlyInvoicesTotalsSuccess, (state, action) => ({
        ...state,
        quarterlyInvoicesTotals: action.quarterlyInvoicesTotals,
        quarterlyInvoicesTotalsLoading: false,
    })),

    on(getQuarterlyInvoicesTotalsFailed, (state) => ({
        ...state,
        quarterlyInvoicesTotalsLoading: false,
    })),

    on(getInvoices, (state) => ({
        ...state,
        invoicesLoading: true,
    })),

    on(getInvoicesSuccess, (state, action) => ({
        ...state,
        invoices: action.invoices,
        invoicesCount: action.count,
        invoicesLoading: false,
    })),

    on(getInvoicesFailed, (state) => ({
        ...state,
        invoicesLoading: false,
    })),

    on(getQuarterlyInvoicing, (state) => ({
        ...state,
        quarterlyInvoicingLoading: true,
    })),

    on(getQuarterlyInvoicingSuccess, (state, action) => ({
        ...state,
        quarterlyInvoicing: action.quarterlyInvoicing,
        quarterlyInvoicingLoading: false,
    })),

    on(getQuarterlyInvoicingFailed, (state) => ({
        ...state,
        quarterlyInvoicingLoading: false,
    })),

    on(getInvoice, (state) => ({
        ...state,
        invoiceLoading: true,
    })),

    on(getInvoiceSuccess, (state, action) => ({
        ...state,
        invoice: action.invoice,
        invoiceLoading: false,
    })),

    on(getInvoiceFailed, (state) => ({
        ...state,
        invoiceLoading: false,
    })),

    on(getLastBillableInvoiceSuccess, (state, action) => ({
        ...state,
        lastBillableInvoice: action.invoice,
    })),

    on(getBillableInvoices, (state, action) => ({
        ...state,
        billableInvoicesLoading: true,
    })),

    on(getBillableInvoicesSuccess, (state, action) => ({
        ...state,
        billableInvoicesLoading: false,
        billableInvoices: action.invoices,
    })),

    on(getBillableInvoicesFailed, (state, action) => ({
        ...state,
        billableInvoicesLoading: false,
    })),

    on(setQuarter, (state, action) => ({
        ...state,
        quarterMoment: DateUtils.tz(action.quarter),
    })),

    on(getInvoicesSent, (state, action) => ({
        ...state,
        invoicesSentLoading: true,
    })),

    on(getInvoicesSentSuccess, (state, action) => ({
        ...state,
        invoicesSentLoading: false,
        invoicesSent: action.invoices,
    })),

    on(getInvoicesSentFailed, (state, action) => ({
        ...state,
        invoicesSentLoading: false,
    }))
);

export function invoicingReducer(state: InvoicingState | undefined, action: Action) {
    return reducer(state, action);
}

export const getInvoicingState = createFeatureSelector<InvoicingState>('invoicing');

export const getQuarterMomentState = createSelector(getInvoicingState, (state) => state?.quarterMoment);

export const getQuarterDateState = createSelector(getQuarterMomentState, (quarterMoment) => quarterMoment?.toDate());

export const getQuarterState = createSelector(getInvoicingState, (state) => state?.quarter);

export const getQuarterlyInvoicesTotalsLoadingState = createSelector(getInvoicingState, (state) => !!state?.quarterlyInvoicesTotalsLoading);

export const getQuarterlyInvoicesTotalsState = createSelector(getInvoicingState, (state) => state?.quarterlyInvoicesTotals);

export const getInvoicesState = createSelector(getInvoicingState, (state) => state?.invoices);

export const getInvoicesCountState = createSelector(getInvoicingState, (state) => state?.invoicesCount);

export const getInvoicesLoadingState = createSelector(getInvoicingState, (state) => state?.invoicesLoading);

export const getQuarterlyInvoicingState = createSelector(getInvoicingState, (state) => state?.quarterlyInvoicing);

export const getQuarterlyInvoicingLoadingState = createSelector(getInvoicingState, (state) => !!state?.quarterlyInvoicingLoading);

export const getLastBillableInvoiceState = createSelector(getInvoicingState, (state) => state?.lastBillableInvoice);

export const getLastBillableInvoiceAvailableFromPastState = createSelector(getLastBillableInvoiceState, (invoice) => {
    if (!invoice) return null;
    return !!invoice?.availableFromPast;
});

export const getBillableInvoicesState = createSelector(getInvoicingState, (state) => state?.billableInvoices);

export const getBillableInvoicesLoadingState = createSelector(getInvoicingState, (state) => !!state?.billableInvoicesLoading);

export const getInvoiceLoadingState = createSelector(getInvoicingState, (state) => !!state?.invoiceLoading);

export const getInvoiceState = createSelector(getInvoicingState, (state) => state?.invoice);

export const getInvoicesSentLoadingState = createSelector(getInvoicingState, (state) => !!state?.invoicesSentLoading);

export const getInvoicesSentState = createSelector(getInvoicingState, (state) => state?.invoicesSent);

export const getCommissionsPaidState = createSelector(getInvoicesSentState, (invoices) =>
    invoices?.filter((invoice) => invoice.status?.value === InvoiceStatus.InvoicePaid)
);
