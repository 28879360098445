<app-loading-container [loading]="loading">
    <div [formGroup]="form" class="u-flex-column u-flex-align-items-end u-margin-half">
        <div class="u-grid u-grid-col-2-repeat">
            <smvd-ui-text-input
                [formControlName]="waterDetailFields.NationalRegistrationNumber"
                [placeholder]="placeholders[waterDetailFields.NationalRegistrationNumber]"
                [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate"
            ></smvd-ui-text-input>
            <smvd-ui-text-input
                [formControlName]="waterDetailFields.ClientNumber"
                [placeholder]="placeholders[waterDetailFields.ClientNumber]"
                [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.CLIENT_NUMBER' | translate"
            ></smvd-ui-text-input>

            <smvd-ui-text-input
                [formControlName]="waterDetailFields.MeterNumber"
                [placeholder]="placeholders[waterDetailFields.MeterNumber]"
                [label]="('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate) + requiredLabel"
            ></smvd-ui-text-input>
            <smvd-ui-number-input
                [showButtons]="false"
                [formControlName]="waterDetailFields.MeterReading"
                [placeholder]="placeholders[waterDetailFields.MeterReading]"
                [label]="('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate) + requiredLabel"
            ></smvd-ui-number-input>

            <div>
                <smvd-ui-dropdown-input
                    [formControlName]="waterDetailFields.WaterSupplier"
                    [options]="waterSupplierOptions$ | async"
                    [placeholder]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.PLACEHOLDER' | translate"
                    [label]="('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate) + requiredLabel"
                ></smvd-ui-dropdown-input>
                <p class="u-color-muted u-font-size-small">
                    {{'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.NOTE' | translate}}
                    <a [href]="waterSupplierURI" target="_blank">{{'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LINK' | translate}}</a>
                </p>
            </div>
            <div class="u-w100p u-margin-bottom">
                <label class="u-w100p u-display-block u-margin-bottom-half"
                    >{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TAKEOVER_DOCUMENT.LABEL' | translate }}</label
                >
                <div class="u-flex-row u-flex-align-items-center" *ngFor="let file of move?.waterDocumentAssets; let last = last">
                    <button
                        smvd-ui-button
                        [appearance]="buttonAppearance.LinkReversed"
                        [title]="file.name"
                        [context]="context.Text"
                        icon="download"
                        class="u-padding-half"
                        (click)="onWaterDocumentAssetDetail(file)"
                    >
                        {{ 'COMMON.DOWNLOAD.DOCUMENT' | translate }}
                    </button>
                </div>
                <p class="u-color-muted u-font-size-small u-margin-bottom" *ngIf="(move?.waterDocumentAssets | length) === 0">
                    {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.NO_ASSETS' | translate }}
                </p>
            </div>
        </div>

        <button smvd-ui-button [context]="context.Secondary" (click)="saveWaterDetails()">{{ 'SAVE' | translate }}</button>
    </div>
</app-loading-container>
