import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReviewsData } from '@smooved/core';

@Component({
    selector: 'smvd-ui-match-score',
    templateUrl: 'match-score.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchScoreComponent {
    @Input() public meta: ReviewsData;
    @Input() public showDefault = false;
}
