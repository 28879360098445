import { Pipe, PipeTransform } from '@angular/core';
import { RowDef } from './table.interfaces';
import { TableService } from './table.service';

@Pipe({
    name: 'tableRowClass',
})
export class TableRowClassPipe<T> implements PipeTransform {
    constructor(private tableService: TableService<T>) {}

    transform(row: RowDef<T>): string {
        const rules = this.tableService.getTableDef().rowClassRules;
        if (!rules) return;
        return Object.keys(rules)
            .filter((rule) => rules[rule](row.data))
            .join(' ');
    }
}
