import { MovesFilter } from '@app/admin/interfaces/moves-filter.interface';
import { AdminDashboardActions, AdminDashboardActionTypes } from '@app/admin/state/admin-dashboard.actions';
import { Move } from '@app/move/interfaces/move';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AdminDashboardState extends EntityState<Move> {
    count: number;
    filter?: MovesFilter;
}

export const adapter: EntityAdapter<Move> = createEntityAdapter<Move>({
    selectId: (move: Move) => move._id,
});

const getPreviousWeek = (): Date => {
    const now = new Date();
    const previousWeek = now.getDate() - 7;
    now.setDate(previousWeek);
    return now;
};

export const initialState: AdminDashboardState = adapter.getInitialState({
    count: 0,
    filter: {
        minMovingDate: getPreviousWeek(),
    },
});

export function adminDashboardReducer(state = initialState, action: AdminDashboardActions): AdminDashboardState {
    switch (action.type) {
        case AdminDashboardActionTypes.GetMovesSuccess:
            return adapter.setAll(action.payload?.response?.data, {
                ...state,
                count: action.payload?.response?.count,
            });

        case AdminDashboardActionTypes.SetFilterAndPagination:
            return adapter.setAll([], {
                ...state,
                filter: {
                    ...state?.filter,
                    ...action.payload?.filter,
                },
            });

        default:
            return state;
    }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getAdminDashboardState = createFeatureSelector<AdminDashboardState>('admin-dashboard');

export const getMovesState = createSelector(getAdminDashboardState, selectAll);

export const getCount = createSelector(getAdminDashboardState, (adminDashboard) => adminDashboard?.count);

export const getFilter = createSelector(getAdminDashboardState, (adminDashboard) => adminDashboard?.filter);
