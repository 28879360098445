export enum WidgetVersion {
    V1 = 'v1',
    V2 = 'v2',
}

export const defaultWidth = '306';
export const defaultHeight = '122';
export const defaultSize = 'md';
export const defaultDirection = 'horizontal';
export const supportedLanguages = ['nl', 'fr', 'en'];
export const defaultLanguage = 'nl';

export const reviewPaths = {
    nl: 'beoordelingen',
    fr: 'avis',
    en: 'reviews',
};

export const iframeSizeMap = {
    md: {
        horizontal: {
            width: '306',
            height: '122',
        },
        vertical: {
            width: '186',
            height: '222',
        },
    },
    sm: {
        horizontal: {
            width: '366',
            height: '71',
        },
        vertical: {
            width: '146',
            height: '184',
        },
    },
};
