import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Office } from '@app/real-estate-group/interfaces/office.interface';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { formControlNames } from '@app/real-estate-group/modals/office-form/office-form.constants';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, NotificationSandbox, UiContext, VatNumberValidators } from '@smooved/ui';

@Component({
    selector: 'app-office-form-modal',
    templateUrl: './office-form.modal.html',
})
export class OfficeFormModal implements OnInit {
    @Input() public office: Office;
    @Input() public realEstateGroup: RealEstateGroup;

    public form: UntypedFormGroup;

    public readonly formControlNames = formControlNames;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly dialogRef: MatDialogRef<OfficeFormModal>,
        private readonly realEstateGroupService: RealEstateGroupService,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public ngOnInit(): void {
        const office = this?.office || ({} as Office);
        this.form = this.fb.group({
            [formControlNames.name]: [office.name, Validators.required],
            [formControlNames.address]: [office.address],
            [formControlNames.vatNumber]: [office.vatNumber, VatNumberValidators.isValid],
            [formControlNames.location]: [DbUtils.getStringId(office.location)],
        });
    }

    public onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const { name, address, vatNumber, location } = this.form.value;

        this.realEstateGroupService
            .updateOffice(DbUtils.getStringId(this.realEstateGroup), DbUtils.getStringId(this.office), {
                name,
                address,
                vatNumber,
                location,
            })
            .subscribe((response) => {
                this.notificationSandbox.updatedSuccess();
                this.dialogRef.close(response);
            });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
