import { MovesFilter } from '@app/admin/interfaces/moves-filter.interface';
import { Move } from '@app/move/interfaces/move';
import { Action } from '@ngrx/store';
import { PaginationRequest, PaginationResponse } from '@smooved/core';

export enum AdminDashboardActionTypes {
    GetMovesSuccess = '[AdminDashboard] Get moves success',
    SetFilterAndPagination = '[AdminDashboard] Set filter and pagination',
}

export class GetMovesSuccess implements Action {
    public readonly type = AdminDashboardActionTypes.GetMovesSuccess;

    constructor(public payload: { response: PaginationResponse<Move> }) {}
}

export class SetFilterAndPagination implements Action {
    public readonly type = AdminDashboardActionTypes.SetFilterAndPagination;

    constructor(
        public payload: {
            filter: MovesFilter;
            pagination: PaginationRequest;
            callback?: () => void;
        }
    ) {}
}

export type AdminDashboardActions = GetMovesSuccess | SetFilterAndPagination;
