import { Service } from '@app/move/enums/service.enum';
import { OrderEnergySupplierStatus } from '@app/order/interfaces/order-energy-supplier-status';
import { ColType, TableDef, UiVerticalAlignment } from '@smooved/ui';

export const orderEnergySupplierStatusTableDef: TableDef<OrderEnergySupplierStatus> = {
    columns: [
        {
            id: 'externalOrderId',
            header: 'EXTERNAL_ORDER_ID',
            type: ColType.Text,
            field: 'externalOrderId',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
        {
            id: 'ean',
            header: 'EAN.LABEL',
            type: ColType.Text,
            field: 'ean',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
        {
            id: 'energyType',
            header: 'ENERGY_TYPE.LABEL',
            type: ColType.Text,
            field: 'energyType',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
        {
            id: 'status',
            header: 'STATE',
            type: ColType.Text,
            field: 'status',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
    ],
};

export const transfereeServices = [Service.energy, Service.telecom];
export const leaverServices = [...transfereeServices, Service.energyStop];
