import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { getInitialMoveState } from '@app/move/state/move.reducer';
import { initialMoveStateMock } from '@app/move/state/move.reducer.mock';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { environment } from '@environments/environment';
import { Language, Role } from '@smooved/core';
import { Observable, of, zip } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';

@Injectable()
export class NewAccessTokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly storageSandbox: StorageSandbox
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            mergeMap((response: HttpResponse<{ language?: Language; token?: string; access_token?: string }>) => {
                const body = response.body;

                const token = body?.token || body?.access_token;
                if (token) {
                    this.storageSandbox.setToken(LocalStorageKeys.Token, token);
                    return this.authenticationSandbox.authorization().pipe(
                        mergeMap((authorizationResponse) => {
                            const observables: Observable<any>[] = [of(authorizationResponse), this.realEstateGroupSandbox.getTheme()];

                            this.authenticationSandbox.setAuthorization(authorizationResponse);
                            if (authorizationResponse.role === Role.Guest || authorizationResponse.role === Role.Mover) {
                                observables.push(this.moveSandbox.getNewestDraft());
                            } else {
                                observables.push(of(null));
                            }

                            if (authorizationResponse.realEstateAgentId) {
                                observables.push(
                                    this.realEstateGroupSandbox.getRealEstateGroupForMe().pipe(
                                        filter((x) => !!x),
                                        tap((realEstateGroup) => this.realEstateGroupSandbox.setRealEstateGroup(realEstateGroup))
                                    )
                                );
                            }

                            return zip(...observables);
                        }),
                        mergeMap(([authorizationResponse, theme, move]) => {
                            if (theme) {
                                this.realEstateGroupSandbox.setTheme(theme);
                            }

                            if (authorizationResponse.role === Role.RealEstateAgent) {
                                this.moveSandbox.setMoveState({
                                    ...(environment.initialData ? { ...initialMoveStateMock } : { ...getInitialMoveState() }),
                                    realEstateAgent: authorizationResponse.realEstateAgentId,
                                });
                            } else if (move?.isDraft) {
                                this.moveSandbox.setLatestMoveState(move);
                                this.moveSandbox.setMoveState(move);
                            }

                            return of(response);
                        })
                    );
                } else {
                    return of(response);
                }
            })
        );
    }
}
