import { Component } from '@angular/core';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';

@Component({
    selector: 'app-base-step',
    template: ``,
    styles: [],
})
export class BaseStepComponent {
    constructor(protected moveSandbox: MoveSandbox) {}

    protected persist(propertyPath: string, value: any, callback: () => void): void {
        this.moveSandbox.patchProperty(propertyPath, value, true, callback);
    }
}
