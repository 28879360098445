<div class="u-flex-row u-flex-align-items-start" [ngSwitch]="checked" [ngClass]="{ 'u-color-muted': !checked }">
    <app-check-with-background width="20" height="20" *ngSwitchCase="true" class="u-margin-right-half"></app-check-with-background>

    <app-svg [name]="emptySvg" *ngSwitchDefault width="24" height="24" class="u-margin-right-half"></app-svg>

    <div>
        <span>{{ i18nKeys.Todo | translate: { name: move | userName } }}</span>
        <div *ngIf="!checked && isEnergy && !(move.createdByFlow?.eots && !move.energySelected)" class="u-margin-top-half">
            <a class="u-link" (click)="resendConfirm()">{{ i18nKeys.Resend | translate }}</a>
        </div>
    </div>
</div>
