import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActivityLogHeartbeatComponent } from './components/activity-log-heartbeat/activity-log-heartbeat.component';

@NgModule({
    imports: [HttpClientModule],
    declarations: [ActivityLogHeartbeatComponent],
    exports: [ActivityLogHeartbeatComponent],
})
export class ActivityLogModule {}
