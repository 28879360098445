import { I18nKeyType, i18nKeyTypeMap as CoreI18nKeyTypeMap } from '@smooved/core';
import { CompanyPageI18nKeyType } from '../company-page/constants/i18n-key-type.map';

export enum WidgetI18nKeyType {
    ReviewScore = 'reviewScore',
    Score = 'score',
}

export enum UiI18nKeyType {
    ReviewTemplate = 'reviewTemplate',
    Address = 'address',
    FormValidation = 'formValidation',
    RealEstateGroupAppointmentDetail = 'realEstateGroupAppointmentDetail',
    HomeDescription = 'homeDescription',
    TransactionDueTime = 'transactionDueTime',
    PropertyType = 'propertyType',
}

export const uiI18nKeyTypes = {
    ...I18nKeyType,
    ...WidgetI18nKeyType,
    ...CompanyPageI18nKeyType,
    ...UiI18nKeyType,
};

export const i18nKeyTypeMap = {
    ...CoreI18nKeyTypeMap,
    [WidgetI18nKeyType.ReviewScore]: 'UI.WIDGET_INFLUENCER_SCORE.SCORE_SCALE',
    [WidgetI18nKeyType.Score]: 'UI.WIDGET_INFLUENCER_SCORE',
    [UiI18nKeyType.ReviewTemplate]: 'UI.NPS_REVIEW_TEMPLATE_ITEM',
    [UiI18nKeyType.Address]: 'UI.ADDRESS',
    [UiI18nKeyType.FormValidation]: 'UI.FORM_VALIDATORS',
    [UiI18nKeyType.RealEstateGroupAppointmentDetail]: 'UI.REAL_ESTATE_GROUP_APPOINTMENT.DETAIL',
    [UiI18nKeyType.HomeDescription]: 'UI.HOME_DESCRIPTION',
    [UiI18nKeyType.TransactionDueTime]: 'UI.TRANSACTION_DUE_TIME',
    [UiI18nKeyType.PropertyType]: 'UI.PROPERTY_TYPE',
};
