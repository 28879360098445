import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreatedByFlowBucket } from '@app/move/enums/created-by-flow-bucket.enum';
import { RxjsComponent } from '@smooved/core';
import { ChartItem } from '@smooved/ui';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfirmationsLongPeriodChartService } from './confirmations-long-period-chart.service';
import { labelTranslateKeys, legend, stackColors, stacks } from './confirmatons-long-period-chart.constants';

@Component({
    selector: 'app-confirmations-long-period-chart',
    templateUrl: './confirmations-long-period-chart.component.html',
    styleUrls: ['./confirmations-long-period-chart.component.scss'],
    providers: [ConfirmationsLongPeriodChartService],
})
export class ConfirmationsLongPeriodChartComponent extends RxjsComponent implements OnInit {
    public data: ChartItem[] = [];
    public loaded = false;

    public stacks = stacks;
    public colors = stackColors;
    public legend = legend;
    public translateKeys = labelTranslateKeys;
    public createdByFlowBuckets = CreatedByFlowBucket;

    public createdBySwitch = new UntypedFormControl(null);

    constructor(private confirmationsLongPeriodChartService: ConfirmationsLongPeriodChartService) {
        super();
    }

    public ngOnInit(): void {
        this.initChart();
        this.createdBySwitch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleToggle);
        this.createdBySwitch.setValue(CreatedByFlowBucket.Ots);
    }

    private initChart(): void {
        this.confirmationsLongPeriodChartService
            .getData()
            .pipe(tap((_) => (this.loaded = true), takeUntil(this.destroy$)))
            .subscribe((data) => (this.data = data));
    }

    private handleToggle = (value: CreatedByFlowBucket): void => this.confirmationsLongPeriodChartService.setCreatedByFlow(value);
}
