import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { PaginationRequest, PaginationResponse } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RealEstateGroupAppointment, RealEstateGroupAppointmentsFilter } from '../interfaces';
import { RealEstateGroupAppointmentsProvidersModule } from '../real-estate-group-appointments-providers.module';
import { RealEstateGroupAppointmentService } from '../services';

@Injectable({
    providedIn: RealEstateGroupAppointmentsProvidersModule,
})
export class RealEstateGroupAppointmentsDataSource implements DataSource<RealEstateGroupAppointment> {
    private appointmentsSubject = new BehaviorSubject<RealEstateGroupAppointment[]>(null);
    private countSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public count$ = this.countSubject.asObservable();
    public loading$ = this.loadingSubject.asObservable();

    constructor(private readonly realEstateGroupAppointmentsService: RealEstateGroupAppointmentService) {}

    public connect(): Observable<RealEstateGroupAppointment[] | ReadonlyArray<RealEstateGroupAppointment>> {
        return this.appointmentsSubject.asObservable();
    }

    public disconnect(): void {
        this.appointmentsSubject.complete();
        this.countSubject.complete();
        this.loadingSubject.complete();
    }

    public load(filter: RealEstateGroupAppointmentsFilter, request: PaginationRequest): void {
        this.loadingSubject.next(true);
        this.realEstateGroupAppointmentsService.getAll(filter, request).subscribe(this.populateSubjects);
    }

    private populateSubjects = (response: PaginationResponse<RealEstateGroupAppointment>) => {
        this.appointmentsSubject.next(response.data);
        this.loadingSubject.next(false);
        this.countSubject.next(response.count);
    };
}
