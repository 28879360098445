import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UiPlacement } from '../../../ui.enums';
import { InViewTriggerDirective } from '../../directives/in-view-trigger.directive';

@Component({
    selector: 'app-load-trigger',
    templateUrl: './load-trigger.component.html',
    styleUrls: ['./load-trigger.component.scss'],
})
export class LoadTriggerComponent implements OnDestroy {
    @ViewChild(InViewTriggerDirective) public triggerDirective: InViewTriggerDirective;
    @Input() public triggerAfter = 500; //ms
    @Output() public trigger = new EventEmitter<boolean>();

    public readonly uiPlacement = UiPlacement;

    public onVisible(isVisible: boolean): void {
        if (isVisible) {
            // set a timeout for better UI.
            // When too fast.. the user will not see the loader and magic happens.
            setTimeout(() => this.trigger.emit(true), this.triggerAfter);
        }
    }

    public ngOnDestroy(): void {
        this.triggerDirective?.ngOnDestroy();
    }
}
