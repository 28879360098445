import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { TranslatedLabelPipe } from '@app/translation/pipes/translatedLabel.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Language } from '@smooved/core';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(cacheBuster: string) {
    const suffix = `.json?cb=${cacheBuster}`;

    function Factory(httpClient: HttpClient): MultiTranslateHttpLoader {
        return new MultiTranslateHttpLoader(httpClient, [
            {
                prefix: './assets/common/',
                suffix,
            },
            {
                prefix: './assets/ui/',
                suffix,
            },
            {
                prefix: './assets/companion/',
                suffix,
            },
            { prefix: './assets/i18n/', suffix },
            { prefix: './assets/widgets/wis/', suffix },
        ]);
    }

    return Factory;
}

@NgModule({
    declarations: [TranslatedLabelPipe],
    imports: [],
    providers: [
        TranslatedLabelPipe,
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: {
                locales: EnumUtils.values(Language),
            },
        },
    ],
    exports: [TranslateModule, TranslatedLabelPipe],
})
export class TranslationModule {}
