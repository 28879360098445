import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-moving-address-detail',
    template: `
        <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.USER.MOVING_ADDRESS' | translate }}</h6>
        <app-copy-to-clipboard *ngIf="!!move?.user?.movingAddress; else unknown" class="u-display-block">
            <app-address-output [address]="move?.user?.movingAddress"></app-address-output>
        </app-copy-to-clipboard>

        <ng-template #unknown>
            <span class="u-color-muted u-opacity-50">{{ 'UNKNOWN' | translate }}</span>
        </ng-template>
    `,
    styles: [':host {display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovingAddressDetailComponent {
    @Input() move: Move;
}
