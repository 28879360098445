import { Component } from '@angular/core';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-feature-detail-water-container',
    template: `
        <app-feature-detail
            [featureScope]="featureScope"
            [label]="'WATER.LABEL' | translate"
            [description]="'FEATURE_SCOPES.WATER.DESCRIPTION' | translate"
        >
        </app-feature-detail>
    `,
})
export class FeatureDetailWaterContainer {
    public featureScope = FeatureScope.Water;
}
