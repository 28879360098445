import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { columns, defaultSortProperty, statusOptions } from '@app/invoicing/components/billing-group-table/billing-group-table.constants';
import { InvoiceVersion } from '@app/invoicing/enums/invoice-version.enum';
import { RealEstateGroupInvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { AssetInputModal } from '@app/invoicing/modals/asset-input/asset-input.modal';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, SortDirection } from '@smooved/core';
import { ButtonAppearance, deleteConfirmation, ModalSandbox, SvgIllustration, UiContext, UiIconAppearance } from '@smooved/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-billing-group-table',
    templateUrl: './billing-group-table.component.html',
    styleUrls: ['./billing-group-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingGroupTableComponent extends CoreTableComponent implements OnInit {
    @Input() public invoicesCount: number;
    @Input() public invoices: Invoice[];

    @Output() public statusChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() public updated: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('download') download: ElementRef;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconAppearance = UiIconAppearance;
    public readonly svgIllustration = SvgIllustration;
    public readonly context = UiContext;
    public readonly defaultSortDirection = SortDirection.Desc;
    public readonly defaultSortProperty = defaultSortProperty;
    public readonly invoiceVersion = InvoiceVersion;
    public readonly statusOptions = statusOptions;
    public readonly columns = columns;

    public loading$: Observable<boolean>;

    private updateLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly invoicingSandbox: InvoicingSandbox,
        private readonly translationService: TranslateService,
        private readonly modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        private readonly cdr: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.loading$ = combineLatest(
            this.invoicingSandbox.invoicesLoading$,
            this.updateLoadingSubject.asObservable(),
            this.invoicingSandbox.loading$
        ).pipe(map(([dataSourceLoading, updateLoading, loading]) => !!dataSourceLoading || !!updateLoading || !!loading));
    }

    public onStatusChange(event, row: Invoice): void {
        const previousValue = row.status.value;
        this.updateLoadingSubject.next(true);
        this.invoicingSandbox.patch(row.id, { status: event }).subscribe(
            (_) => {
                this.updateLoadingSubject.next(false);
                this.statusChange.emit();
            },
            (_) => {
                row.status.value = previousValue;
                this.updateLoadingSubject.next(false);
            }
        );
    }

    public downloadFile(invoice: Invoice): void {
        this.invoicingSandbox.getAsset(invoice.id, RealEstateGroupInvoicingType.commission).subscribe((safeResourceUrl) => {
            const event = new MouseEvent('click');
            this.download.nativeElement.setAttribute('download', invoice?.downloadName);
            this.download.nativeElement.setAttribute('href', safeResourceUrl);
            this.download.nativeElement.dispatchEvent(event);
        });
    }

    public uploadSubscriptionInvoice(invoice: Invoice): void {
        const header = this.translate.instant('INVOICING.UPLOAD_INVOICE') as string;
        this.modalSandbox.openClosableModal({
            component: AssetInputModal,
            config: {
                header,
                data: {
                    showTermsAndConditions: false,
                },
            },
            afterClosed: (formData: FormData) => {
                if (formData) {
                    this.invoicingSandbox
                        .uploadAsset(invoice.id, formData, RealEstateGroupInvoicingType.subscription)
                        .subscribe((invoice) => {
                            this.updateInvoice(invoice);
                        });
                }
            },
        });
    }

    public delete(invoice: Invoice): void {
        const data = this.translationService.instant(deleteConfirmation) as string;
        this.modalSandbox.openConfirmModal({ data }, this.handleDelete(invoice));
    }

    private updateInvoice(invoice: Invoice): void {
        const invoiceIndex = this.invoices.findIndex((i) => i.id === invoice.id);
        let updatedInvoices = [...this.invoices];
        updatedInvoices[invoiceIndex] = invoice;
        this.invoices = updatedInvoices;
        this.cdr.detectChanges();
    }

    private handleDelete = (invoice: Invoice) => {
        return (result: boolean) => {
            if (!result) return;
            this.invoicingSandbox.delete(DbUtils.getStringId(invoice)).subscribe(() => {
                this.updated.emit();
            });
        };
    };
}
