import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { partnerRegisterContactDetailsRouting } from '@app/authentication/constants/routing.constants';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-partner-register-welcome-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-margin-bottom u-flex-column u-flex-align-items-center">
                    <img class="u-margin-bottom-double" width="220" src="assets/images/hero.png" alt="Smooved" />

                    <h3 class="u-margin-bottom-double">
                        {{
                            'AUTHENTICATION.PARTNER.REGISTER.WELCOME.TITLE'
                                | translate: { firstName: (userSandbox.firstNameInviteData$ | async) }
                        }}
                    </h3>

                    <p class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium">
                        {{ 'AUTHENTICATION.PARTNER.REGISTER.WELCOME.TEXT' | translate }}
                    </p>

                    <app-button [routerLink]="partnerRegisterContactDetailsRouting">{{
                        'AUTHENTICATION.PARTNER.REGISTER.WELCOME.CTA' | translate
                    }}</app-button>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class PartnerRegisterWelcomeContainer implements OnInit {
    public partnerRegisterContactDetailsRouting = AppNavigationSandbox.getAbsolute(partnerRegisterContactDetailsRouting);

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private navigationSandbox: AppNavigationSandbox,
        public userSandbox: UserSandbox
    ) {}

    public ngOnInit(): void {
        const confirmationToken = this.activatedRoute.snapshot.queryParams['ct'];
        if (confirmationToken) {
            this.userSandbox.getByConfirmationToken(confirmationToken).subscribe(
                (user) => {
                    this.userSandbox.setInviteData({ ...user });
                },
                (_) => {
                    this.navigationSandbox.goToHome();
                }
            );
        }
    }
}
