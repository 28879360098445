import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileMortgageOverviewModal } from '@app/mortgage/modals/mobile-mortgage-overview/mobile-mortgage-overview.modal';
import { MortgageDetailsModal } from '@app/mortgage/modals/mortgage-details/mortgage-details.modal';
import { MoveDetailsActions } from '@app/move/classes/move-detail-actions.class';
import { MoveState } from '@app/move/enums/move-state.enum';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { MortgageOrderDataSource } from '@app/order/data-sources/mortgage-order.data-source';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { DashboardFilterComponent } from '@app/real-estate-agent/components/dashboard-filter/dashboard-filter.component';
import { MortgageTableComponent } from '@app/real-estate-agent/components/mortgage-table/mortgage-table.component';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { Move } from '@app/move/interfaces/move';
import { HttpUtils, PaginationRequest } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-mortgage-overview-container',
    templateUrl: 'mortgage-overview.container.html',
})
export class MortgageOverviewContainer extends MoveDetailsActions implements OnInit {
    @ViewChild(DashboardFilterComponent, { static: true }) public mortgageFilter: DashboardFilterComponent;
    @ViewChild(MortgageTableComponent, { static: true }) public mortgageTable: MortgageTableComponent;

    public dataSource = new MortgageOrderDataSource(this.orderSandbox);

    constructor(
        private readonly orderSandbox: OrderSandbox,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        protected readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox,
        private readonly modalSandbox: ModalSandbox
    ) {
        super(realEstateAgentModalsSandbox);
    }

    public ngOnInit(): void {
        if (this.activatedRoute.snapshot.queryParams.id) {
            this.openMortgageDetail(this.activatedRoute.snapshot.queryParams.id);
        }
        this.onPaginationChange(); // trigger initial load
    }

    public goToOrderMortgage(): void {
        void this.router.navigate([Navigation.Mortgage]);
    }

    public onFilterChange(event: { filter: RealEstateAgentFilter; persist: boolean }): void {
        this.dataSource.loadMortageOrders(event.filter, this.getPaginationRequest());
    }

    public onPaginationChange(): void {
        const filter: RealEstateAgentFilter = {
            ...this.mortgageFilter.getFilterValue(),
        };

        this.dataSource.loadMortageOrders(filter, this.getPaginationRequest());
    }

    public onRowClick(move: Move): void {
        move.state === MoveState.Inactive ? this.openMortgageDetail(move._id) : this.openTransfereeDetail(move._id, this.onModalClose);
    }

    private openMortgageDetail(id: string): void {
        this.modalSandbox.openModal(
            MobileMortgageOverviewModal,
            { data: id },
            () => {},
            MortgageDetailsModal,
            { data: id },
            () => {}
        );
    }

    private getPaginationRequest(): PaginationRequest {
        return HttpUtils.buildPaginationRequestObject(this.mortgageTable.getPaginationOptions());
    }

    private onModalClose = (): void => {
        if (this.movePatchedSubject.value) {
            this.onPaginationChange();
            this.movePatchedSubject.next(false);
        }
    };
}
