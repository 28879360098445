import { Injectable } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { BivAgentFormModalData } from '@app/real-estate-group/modals/biv-agent-form/biv-agent-form.interface';
import { BivAgentFormModalComponent } from '@app/real-estate-group/modals/biv-agent-form/biv-agent-form.modal';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from '@smooved/core';
import { ModalSandbox, UiSize } from '@smooved/ui';
import { BivRealEstateAgent } from 'projects/core/src/lib/interfaces/biv-real-estate-agent.interface';
import { AdminProvidersModule } from '../admin-providers.module';
import { MoveDetailModalComponent } from '../modals/move-detail/move-detail/move-detail.modal';
import { bivAgentActionLabels } from '../modals/real-estate-group-detail/real-estate-group-detail.constants';

@Injectable({ providedIn: AdminProvidersModule })
export class AdminModalsSandbox {
    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}

    public openMoverDetail(moveId: string, closeCallback?: () => void): void {
        this.modalSandbox.openModal(
            null,
            null,
            null,
            MoveDetailModalComponent,
            { data: { id: moveId } },
            () => {
                this.moveSandbox.clearMoveState();
                closeCallback?.();
            },
            true
        );
    }

    public openInsertBivRealEstateAgent(): void {
        this.modalSandbox.openClosableModal<BivAgentFormModalData>({
            component: BivAgentFormModalComponent,
            config: {
                header: this.translate.instant(bivAgentActionLabels.addAgent) as string,
                size: UiSize.Md,
            },
        });
    }

    public openUpdateBivRealEstateAgent(agent: BivRealEstateAgent): void {
        this.modalSandbox.openClosableModal<BivAgentFormModalData>({
            component: BivAgentFormModalComponent,
            config: {
                header: this.translate.instant(bivAgentActionLabels.editAgent) as string,
                data: { agent },
                size: UiSize.Md,
            },
        });
    }

    public openDeleteBivRealEstateAgent(realEstateGroup: RealEstateGroup, agent: BivRealEstateAgent): void {
        const configForMobile = {
            submitLabel: 'COMMON.DELETE',
            question: this.translate.instant(bivAgentActionLabels.confirmDeleteAgent, {
                name: agent.name,
            }) as string,
        };

        this.modalSandbox.openConfirmModal(configForMobile, this.deleteBivRealEstateAgentCallback(realEstateGroup, agent));
    }

    private deleteBivRealEstateAgentCallback(realEstateGroup: RealEstateGroup, agent: BivRealEstateAgent) {
        return (confirmation: boolean): void => {
            if (!confirmation) return;
            this.realEstateGroupSandbox.deleteBivRealEstateAgents(realEstateGroup, ArrayUtils.toArray(agent));
        };
    }
}
