export const formGroupNames = {
    header: 'header',
    about: 'aboutSection',
    locations: 'locations',
    contact: 'contact',
};

export const formControlNames = {
    cover: 'cover',
    logo: 'logo',
    phoneNumber: 'phoneNumber',
    email: 'email',
    linkedIn: 'linkedIn',
    facebook: 'facebook',
    instagram: 'instagram',
    twitter: 'twitter',
    youtube: 'youtube',
    website: 'website',
    about: 'about',
    aboutDefaultLanguage: 'aboutDefaultLanguage',
};
