import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Address } from '@smooved/core';

export function addressRequired(control: AbstractControl): ValidationErrors | null {
    const address: Address = control.value;
    if (!control || !address || !address.street || !address.houseNumber || !address.zipCode || !address.city) {
        return {
            required: true,
        };
    }
    return null;
}
