<div class="u-flex-row u-flex-align-items-end">
    <button
        *ngIf="showPrevious"
        (click)="previous.emit()"
        type="button"
        tabindex="-1"
        class="__prev u-flex-no-shrink u-flex-row u-flex-align-items-center u-flex-justify-content-center"
        [disabled]="disabled"
    >
        <app-icon icon="chevron_left" size="xm"></app-icon>

        <span class="__prev__text u-margin-left-half">{{ 'UI.PREVIOUS' | translate }}</span>
    </button>
    <button
        app-button
        *ngIf="showCancel"
        class="__next u-margin-right-half"
        (click)="cancel.emit()"
        [context]="context || uiContext.Secondary"
        appearance="stroked"
        type="button"
        [disabled]="disabled"
    >
        {{ cancelTranslationLabel | translate }}
    </button>
    <button
        app-button
        *ngIf="showSubmit"
        class="__next"
        [context]="context || uiContext.Secondary"
        type="submit"
        [disabled]="submitDisabled || disabled"
        [isLoading]="loading"
        (click)="next.emit()"
    >
        {{ submitTranslationLabel | translate }}
    </button>
    <a
        app-button
        appearance="link"
        *ngIf="showSkipStep"
        tabindex="-1"
        (click)="!disabled && skipStep.emit()"
        class="u-flex-no-shrink __skip-step u-margin-left u-color-muted u-link-no-style"
        >{{ skipStepTranslationLabel | translate }}</a
    >
    <ng-content></ng-content>
</div>
