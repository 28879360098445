import { Component } from '@angular/core';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-feature-detail-gifts-container',
    template: `
        <app-feature-detail
            [featureScope]="featureScope"
            [label]="'GIFTS.LABEL_PLURAL' | translate"
            [description]="'FEATURE_SCOPES.GIFTS.DESCRIPTION' | translate"
        >
        </app-feature-detail>
    `,
})
export class FeatureDetailGiftsContainer {
    public featureScope = FeatureScope.Gift;
}
