import { Component, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigureExternalInfluencerModal } from '@app/external-influencer/modals/configure-external-influencer/configure-external-influencer.modal';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { FeatureDetailReviewsService } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.service';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-external-influencer-configure-container',
    template: ``,
})
export class ExternalInfluencerConfigureContainer implements OnInit {
    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly router: Router,
        private readonly featureDetailReviewService: FeatureDetailReviewsService
    ) {}

    public ngOnInit(): void {
        this.configure();
    }

    public configure(): void {
        const component = ConfigureExternalInfluencerModal;
        const configFactory: MatDialogConfig = {};
        const callbackFactory = () => {
            this.featureDetailReviewService.init();
            void this.router.navigateByUrl(
                `/${Navigation.RealEstateAgent}/${Navigation.Management}/${Navigation.Settings}/${Navigation.Reviews}`
            );
        };
        this.modalSandbox.openModal(component, configFactory, callbackFactory, component, configFactory, callbackFactory, false);
    }
}
