import { ElementRef } from '@angular/core';
import { smoothScrollBehavior } from '../constants/scroll';

export class ElementUtils {
    public static scrollIntoView(el: ElementRef, scrollBehavior?: { block?: string; behavior?: string }): void {
        el.nativeElement.scrollIntoView(scrollBehavior || smoothScrollBehavior);
    }

    public static elementScrollIntoView(htmlElement: Element, scrollBehavior?: { block?: string; behavior?: string }): void {
        htmlElement.scrollIntoView((scrollBehavior || smoothScrollBehavior) as ScrollIntoViewOptions);
    }

    public static getElementClassList(el: ElementRef | HTMLElement, matcher?: string | RegExp): string[] {
        if (!el) return [];
        const element = (el as any).nativeElement || el;
        const classes = (element.getAttribute('class') || '').split(' '); // get all classes
        return matcher ? classes.filter((cl) => cl.match(matcher)) : classes;
    }
}
