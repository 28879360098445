import { ChangeDetectionStrategy, Component, EventEmitter, Host, Input, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { messageFormControlName } from '@app/message/components/new-message-form/new-message-form.constants';
import { I18nKey } from '@app/message/constants/message.constants';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput, ButtonAppearance, ButtonSize, ModalSandbox, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-new-message-form',
    templateUrl: './new-message-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewMessageFormComponent extends BaseInput {
    @Input() public label: string;
    @Output() public send: EventEmitter<string> = new EventEmitter<string>();

    public readonly messageFormControlName = messageFormControlName;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;

    public form: UntypedFormGroup = this.formBuilder.group({
        [messageFormControlName]: [null, Validators.required],
    });

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox
    ) {
        super(controlContainer);
    }

    public onCancel(): void {
        this.clear();
    }

    public onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        const config = {
            data: this.translateService.instant(I18nKey.Confirmation) as string,
        };
        this.modalSandbox.openConfirmModal(config, this.confirmationHandler, config, this.confirmationHandler);
    }

    private confirmationHandler = (confirm: boolean): void => {
        if (confirm) {
            const value = this.form.get(messageFormControlName).value as string;
            this.send.emit(value);
            this.clear();
        }
    };

    private clear(): void {
        this.form.reset();
    }
}
