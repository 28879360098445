import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../../form/form.module';
import { ModalModule } from '../../modal/modal.module';
import { ProductsPrivacyModalComponent } from '../complete/components/products-privacy-modal/products-privacy-modal.component';
import { ProductsTermsAndConditionsModalComponent } from '../complete/components/products-terms-and-conditions-modal/products-terms-and-conditions-modal.component';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { RealEstateAgentComponent } from './components/real-estate-agent/real-estate-agent.component';
import { SmoovedTermsAndConditionsComponent } from './components/smooved-terms-and-conditions/smooved-terms-and-conditions.component';
import { SuppliersTermsAndConditionsComponent } from './components/suppliers-terms-and-conditions/suppliers-terms-and-conditions.component';
import { SepaModalComponent } from './modals/sepa-modal/sepa-modal.component';

@NgModule({
    declarations: [
        GdprComponent,
        RealEstateAgentComponent,
        ProductsTermsAndConditionsModalComponent,
        ProductsPrivacyModalComponent,
        SepaModalComponent,
        SmoovedTermsAndConditionsComponent,
        SuppliersTermsAndConditionsComponent,
    ],
    imports: [MatCheckboxModule, MatButtonModule, MatIconModule, CommonModule, TranslateModule, ModalModule, FormModule],
    exports: [GdprComponent, RealEstateAgentComponent, SmoovedTermsAndConditionsComponent, SuppliersTermsAndConditionsComponent],
})
export class LegalModule {}
