import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClusterLevelFilterType } from '@app/invoicing/enums/ots-confirmation-cluster-level.enum';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { CreatedBy } from '@app/real-estate-agent/enums/created-by.enum';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { CreatedByFlowEnum, ObjectUtils, RxjsComponent } from '@smooved/core';
import { merge } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, delay, distinctUntilChanged, mapTo, takeUntil } from 'rxjs/operators';
import * as constants from './dashboard-filter.constants';
import { DashboardFilterChangedEvent } from './dashboard-filter.interfaces';

@Component({
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.scss'],
})
export class DashboardFilterComponent extends RxjsComponent implements OnInit {
    @Output() public filterChange = new EventEmitter<DashboardFilterChangedEvent>();

    @Input() public showCreatedBy = true;
    @Input() public showCreatedByFlow = true;
    @Input() public showClusterLevel = true;
    @Input() public showScoreFilter = true;
    @Input() public showMoverRole = true;

    public searchForm = new UntypedFormGroup({
        search: new UntypedFormControl(null),
    });
    public otherForm = new UntypedFormGroup({
        [constants.OtherForm.CreatedBy]: new UntypedFormControl(null),
        [constants.OtherForm.CreatedByFlow]: new UntypedFormControl(null),
        [constants.OtherForm.MoverRole]: new UntypedFormControl(null),
        [constants.OtherForm.ClusterLevel]: new UntypedFormControl(null),
        [constants.OtherForm.ScoreFilter]: new UntypedFormControl(null),
    });

    public otherFormFields = constants.OtherForm;

    public createdByOptions = constants.createdByOptions;
    public createdByFlowOptions = constants.createdByFlowOptions;
    public moverRoleOptions = constants.moverRoleOptions;
    public clusterLevelOptions = constants.clusterLevelOptions;
    public scoreBucketsOptions = constants.scoreBucketsOptions;

    constructor(public uiSandbox: AppUiSandbox) {
        super();
    }

    public ngOnInit(): void {
        this.setupFilter().subscribe(this.handleFilterChanged);
    }

    public getFilterValue(): RealEstateAgentFilter {
        return {
            ...this.searchForm.value,
            ...this.otherForm.value,
        };
    }

    public triggerChanges(reset = false): void {
        // Timeout required when switching between filter types
        // e.g. switching between Moves and Gifts
        setTimeout(() => {
            if (reset) {
                this.searchForm.patchValue({}, { emitEvent: false });
                this.updateForm(false, constants.otherFormDefaultValues);
            }
            this.handleFilterChanged(false);
        }, 0);
    }

    public updateForm(emitEvent = false, values?: RealEstateAgentFilter): void {
        if (!this.otherForm) return;
        const currentValue = this.otherForm.value as RealEstateAgentFilter;
        const getPatchValue = <T>(condition: boolean, property: string, defaultValue: T): T =>
            condition ? values?.[property] || currentValue?.[property] || defaultValue : null;

        const patch: RealEstateAgentFilter = {
            [constants.OtherForm.CreatedBy]: getPatchValue<CreatedBy>(
                this.showCreatedBy,
                constants.OtherForm.CreatedBy,
                constants.createdByDefault
            ),
            [constants.OtherForm.CreatedByFlow]: getPatchValue<CreatedByFlowEnum>(
                this.showCreatedByFlow,
                constants.OtherForm.CreatedByFlow,
                constants.createdByFlowDefault
            ),
            [constants.OtherForm.MoverRole]: getPatchValue<MoverRole>(
                this.showMoverRole,
                constants.OtherForm.MoverRole,
                constants.moverRoleDefault
            ),
            [constants.OtherForm.ScoreFilter]: getPatchValue<number[]>(
                this.showScoreFilter,
                constants.OtherForm.ScoreFilter,
                constants.scoreBucketsOptionDefault
            ),
            [constants.OtherForm.ClusterLevel]: getPatchValue<ClusterLevelFilterType>(
                this.showClusterLevel,
                constants.OtherForm.ClusterLevel,
                constants.clusterLevelForFilterDefault
            ),
        };
        this.otherForm.patchValue(patch, { emitEvent });
    }

    private setupFilter = (): Observable<boolean> => {
        this.updateForm();

        return merge(
            this.searchForm.get('search').valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), mapTo(false)),
            this.otherForm.valueChanges.pipe(distinctUntilChanged(ObjectUtils.isEqual), mapTo(false))
        ).pipe(delay(0), takeUntil(this.destroy$));
    };

    private handleFilterChanged = (persist: boolean): void => {
        this.onFilterChange(this.getFilterValue(), persist);
    };

    private onFilterChange(filter: RealEstateAgentFilter, persist: boolean): void {
        this.filterChange.emit({ filter, persist });
    }
}
