import { AbstractControl } from '@angular/forms';

export function fileValidator(files?: File[], types?: string | string[], size?: number) {
    return function (control: AbstractControl) {
        if (!files || files.length < 1) return null;
        if (!!types && !(types instanceof Array)) types = [types];

        for (const file of files) {
            if (!!types && !types.includes(file.type)) return { 'ERRORS.INVALID_FILE_UPLOAD': true };
            if (!!size && file.size > size) return { 'ERRORS.INVALID_FILE_UPLOAD': true };
        }

        return null;
    };
}
