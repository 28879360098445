import { Component, Input } from '@angular/core';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-insurances-info-detail',
    template: `
        <app-move-detail-extra-info *ngIf="move?.insurancesProposal?.proposalUrl">
            <div ngProjectAs="content" class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double">
                <app-insurances-covered class="u-display-block u-margin-bottom" [move]="move"></app-insurances-covered>

                <app-insurances-proposal-url [move]="move"></app-insurances-proposal-url>
            </div>
        </app-move-detail-extra-info>
    `,
    styleUrls: ['./insurances-info-detail.component.scss'],
})
export class InsurancesInfoDetailComponent {
    @Input() public move: Move;

    public insurancesType = UiOfferType.Insurances;
}
