<app-loading-container [loading]="loading">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-end">
        <smvd-ui-dropdown-input
            [formControlName]="formControlNames.Value"
            [label]="'TYPE' | translate"
            [hasMargin]="true"
            [hasMarginDouble]="false"
            [options]="contactLogTypeOptions"
            width="100%"
        ></smvd-ui-dropdown-input>
        <smvd-ui-textarea-input
            [formControlName]="formControlNames.Remark"
            [label]="'MOVE.REMARK' | translate"
            [hasMargin]="true"
            [hasMarginDouble]="false"
            width="100%"
        ></smvd-ui-textarea-input>
        <div class="u-flex-row u-margin-bottom-double">
            <smvd-ui-dropdown-input
                [formControlName]="formControlNames.Escalation"
                [label]="'CONNECT_ESCALATION' | i18nKeyTranslate: i18nKeys.ContactLog"
                [options]="escalationOptions | translateProp"
                [hasMargin]="false"
            ></smvd-ui-dropdown-input>
            <smvd-ui-dropdown-input
                [formControlName]="formControlNames.State"
                [label]="'STATE.TITLE' | i18nKeyTranslate: i18nKeys.Escalation"
                [options]="stateOptions"
                [hasMargin]="false"
                class="u-margin-left"
            ></smvd-ui-dropdown-input>
        </div>
        <div class="u-flex-row u-flex-align-items-end">
            <button
                app-button
                [context]="uiContext.Secondary"
                class="u-margin-right-half"
                [appearance]="buttonAppearance.Stroked"
                (click)="close()"
            >
                {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button app-button [context]="uiContext.Secondary" type="submit">{{ 'COMMON.SEND' | translate }}</button>
        </div>
    </form>
</app-loading-container>
