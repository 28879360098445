import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivationStage, ActivationStageUtils, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '../../../button';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiSize } from '../../../ui.enums';
import { companyPageI18nKeyTypes } from '../../constants/i18n-key-type.map';
import { ModalsService } from '../../services';

@Component({
    selector: 'smvd-ui-real-estate-group-activation-stage',
    templateUrl: './real-estate-group-activation-stage.component.html',
    styleUrls: ['./real-estate-group-activation-stage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class RealEstateGroupActivationStageComponent implements OnChanges {
    @Input() public state: ActivationStage;
    @Input() public size: UiSize;

    public readonly companyPageI18nKeyTypes = companyPageI18nKeyTypes;

    public unclaimed: boolean;
    public buttonSize: ButtonSize;
    public iconSize: UiIconSize;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconAppearance = UiIconAppearance;

    constructor(private readonly modalsService: ModalsService) {}

    public ngOnChanges({ state, size }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(state)) {
            this.unclaimed = ActivationStageUtils.isUnclaimed(state.currentValue);
        }
        if (SimpleChangesUtils.hasChanged(size)) {
            this.buttonSize = this.size === UiSize.Sm ? ButtonSize.Sm : ButtonSize.Default;
            this.iconSize = this.size === UiSize.Sm ? UiIconSize.Sm : UiIconSize.Default;
        }
    }

    public openClaimBusiness(): void {
        this.modalsService.openClaimBusinessModal();
    }
}
