import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '@app/move/interfaces/user';
import { SimpleChangesUtils } from '@smooved/core';

@Component({
    selector: 'app-user-name',
    template: ` <div class="u-text-truncate u-white-space-no-wrap" [class.u-color-muted]="!user?.formattedName">{{ name }}</div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameComponent implements OnChanges {
    @Input() user: User;

    public name: string;

    public ngOnChanges({ user }: SimpleChanges) {
        if (SimpleChangesUtils.hasChanged(user)) {
            this.name = this.user?.formattedName || this.user?.email;
        }
    }
}
