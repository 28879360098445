import { Injectable } from '@angular/core';
import { MoveService } from '@app/move/services/move.service';
import { getMoves, getMovesFailed, getMovesSuccess } from '@app/real-estate-agent/state/real-estate-agent-dashboard.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RealEstateAgentDashboardEffects {
    constructor(private actions$: Actions, private moveService: MoveService) {}

    getMoves$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getMoves),
            mergeMap((action) => {
                return this.moveService
                    .getMovesForRealEstateAgent({
                        pagination: action.pagination,
                        filter: action.realEstateAgentFilter,
                    })
                    .pipe(
                        map((response) => getMovesSuccess({ response })),
                        catchError((_) => of(getMovesFailed()))
                    );
            })
        )
    );
}
