<app-dashboard-card
    [first]="true"
    height="100%"
    class="u-h100p"
    [title]="'REAL_ESTATE_AGENT.START.OTHER_SIMULATION_TOOLS.TITLE' | translate"
    [text]="'REAL_ESTATE_AGENT.START.OTHER_SIMULATION_TOOLS.TEXT' | translate"
    [hasBorderBottom]="true"
    imageName="laptop-meters.png"
    [last]="true"
>
    <ng-container up-to-and-including-phone-landscape>
        <div class="u-flex-column u-flex-align-items-stretch">
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-bottom-half' }"></ng-container>
        </div>
    </ng-container>

    <ng-container tablet-portrait-up>
        <div class="u-flex-row u-flex-align-items-center">
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-right' }"></ng-container>
        </div>
    </ng-container>

    <ng-template #actions let-class="class">
        <ui-dropdown-menu-button [label]="'ENERGY.LABEL' | translate" appearance="button">
            <button mat-menu-item uiDropdownMenuItem (click)="onEnergyOptimizationRoute()">
                {{ 'REAL_ESTATE_AGENT.START.ENERGY_OPTIMIZATION.ROUTE_LINK' | translate }}
            </button>
            <button mat-menu-item uiDropdownMenuItem (click)="onEnergyOptimizationCopy()">
                {{ 'REAL_ESTATE_AGENT.START.ENERGY_OPTIMIZATION.COPY_LINK' | translate }}
            </button>
        </ui-dropdown-menu-button>
    </ng-template>
</app-dashboard-card>
