<ng-content select="[content]"></ng-content>
<div>
    <ng-content select="[nps-reviews-header]"></ng-content>
    <app-loading-container [loading]="reviewsTemplateService.loading$ | async">
        <div *ngIf="totalReviewsCount === 0" class="u-padding-top-double u-padding-bottom-double">
            <p class="u-text-align-center u-color-muted">{{ 'COMMON.REVIEWS.EMPTY_SECTION' | translate }}</p>
        </div>

        <div
            *ngIf="{ colSize: gridColumns$ | async } as vm"
            [ngClass]="'nps-reviews-cols-' + vm.colSize"
            [style.grid-template-columns]="'repeat(' + vm.colSize + ', auto)'"
            class="__nps-reviews-template__grid u-grid"
        >
            <ng-template
                *ngFor="let item of reviews; trackBy: trackById; let first = first"
                [ngTemplateOutletContext]="{ $implicit: { review: item, showUpgradeBox: isSmooved && first && !item.showReviewContent } }"
                [ngTemplateOutlet]="reviewTemplate"
            ></ng-template>
        </div>

        <ng-container *ngIf="reviews?.length && hasNextPage() && !limit">
            <app-load-trigger
                (trigger)="loadNext()"
                *ngIf="templateConfig.infiniteScroll; else loadingButton"
                [triggerAfter]="triggerAfter"
                class="u-margin-top-double"
            ></app-load-trigger>

            <ng-template #loadingButton>
                <div
                    class="__load-more__container u-padding-top-double-tablet-portrait-up u-padding-bottom-double u-flex-row u-flex-justify-content-center"
                >
                    <button
                        (click)="loadNext()"
                        [appearance]="buttonAppearance.Stroked"
                        [context]="uiContext.Secondary"
                        app-button
                        class="__load-more__button"
                    >
                        {{ 'UI.LOAD_MORE' | translate }}
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </app-loading-container>
</div>

<ng-template #reviewTemplate let-item>
    <app-ui-card
        [border]="false"
        [hasPadding]="!templateConfig.removePadding"
        [mainClass]="templateConfig.colSize > 1 ? 'u-flex' : 'u-display-block u-padding-bottom-none'"
        [ngClass]="templateConfig.templateClasses"
        class="__review u-flex-row u-h100p"
        ngSkipHydration
    >
        <div class="u-position-relative u-flex-row">
            <ui-review [review]="item.review" [showUpgradeBox]="item.showUpgradeBox" class="u-display-block u-w100p">
                <button
                    *ngIf="!!npsShareTemplate && item.review.showReviewMeta"
                    [alignment]="tooltipAlignment.Center"
                    [appearance]="buttonAppearance.Icon"
                    [context]="uiContext.Primary"
                    [placement]="tooltipPlacement.Top"
                    [templateOutletContext]="{ $implicit: item.review }"
                    [template]="npsShareTemplate"
                    [trigger]="tooltipTrigger.Click"
                    app-button
                    appTooltip
                    class="__action"
                    icon="ios_share"
                    nps-share
                ></button>
                <div class="__review-footer u-font-size-small">
                    <ng-container
                        [ngTemplateOutletContext]="{ $implicit: item.review }"
                        [ngTemplateOutlet]="npsFooterTemplate"
                    ></ng-container>
                </div>
                <ng-container
                    [ngTemplateOutletContext]="{ $implicit: item.review }"
                    [ngTemplateOutlet]="npsActionsTemplate"
                    ngProjectAs="npsActions"
                ></ng-container>
            </ui-review>
        </div>
    </app-ui-card>
</ng-template>
