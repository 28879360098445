import { CheckInput, UiHeaderVariant } from '@smooved/ui';

export const optionGas: CheckInput<boolean> = {
    id: 'has-gas',
    labelResource: 'GAS',
    value: true,
    name: 'has-gas',
    variant: UiHeaderVariant.H6,
};

export const optionElectricity: CheckInput<boolean> = {
    id: 'has-electricity',
    labelResource: 'ELECTRICITY',
    value: true,
    name: 'has-electricity',
    variant: UiHeaderVariant.H6,
};
