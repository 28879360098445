import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { Asset, Role, SimpleChangesUtils } from '@smooved/core';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { ButtonAppearance, ElectricityMeterType, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-energy-stop-info-detail',
    templateUrl: './energy-stop-info-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyStopInfoDetailComponent implements OnInit, OnChanges {
    @Input() public move: Move;

    public linkedMove: Move;
    public hasGas: boolean;
    public hasElectricity: boolean;

    public electricityInvoices: Asset[] = [];
    public gasInvoices: Asset[] = [];

    public readonly i18nKeys = appI18nKeyTypes;
    public readonly meterTypes = ElectricityMeterType;
    public readonly roles = Role;
    public readonly moverRoles = MoverRole;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;

    constructor(
        private readonly adminModalService: AdminModalService,
        private readonly documentCenter: DocumentCenterSandbox
    ) {}

    public ngOnInit(): void {
        this.linkedMove = this.move.linkedMove as Move;
        this.hasGas = MoveUtils.hasGasEnergyType(this.move.energyStop?.energyType);
        this.hasElectricity = MoveUtils.hasElectricityEnergyType(this.move.energyStop?.energyType);

        this.electricityInvoices = [...(this.move.energyStop?.invoiceElectricityAssets || [])];
        this.gasInvoices = [...(this.move.energyStop?.invoiceGasAssets || [])];
    }

    public downloadAsset(asset: Asset): void {
        this.documentCenter.downloadAttachment(asset);
    }

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) this.ngOnInit();
    }

    public openMeterReadingAsset(file: FileInput): void {
        this.adminModalService.openEnergyMeterReadingAssetModal(file, this.move, this.onAssetClosed);
    }

    public openEnergyDocumentAsset(file: FileInput): void {
        this.adminModalService.openEnergyDocumentAssetModal(file, this.move, this.onAssetClosed);
    }

    private onAssetClosed = (move: Move): void => {
        if (move) this.move = move;
    };
    protected readonly UiContext = UiContext;
    protected readonly EnergyType = EnergyType;
    protected readonly ButtonAppearance = ButtonAppearance;
}
