import { TodoState } from '../../todo/todo-state/todo-state.enum';

export class TrackUtils {
    public static mapBinary(value: TodoState): TodoState.Checked | TodoState.Unchecked {
        return value === TodoState.Checked ? TodoState.Checked : TodoState.Unchecked;
    }

    public static checked(...todos: TodoState[]): boolean {
        return todos.every((value) => this.mapBinary(value));
    }

    public static lastModifiedOn(value: { lastModifiedOn?: Date; createdOn?: Date }): Date {
        if (!value) return null;
        return value.lastModifiedOn || value.createdOn;
    }
}
