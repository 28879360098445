export const authenticationRoutes = {
    root: 'authentication',
    user: {
        root: 'user',
        confirm: 'confirm',
    },
    realEstateAgent: {
        root: 'real-estate-agent',
        confirm: 'confirm',
        login: 'login',
        resetPassword: 'reset-password',
        resetPasswordNew: 'reset-password-new',
        register: {
            root: 'register',
            welcome: 'welcome',
            contactDetails: 'contact-details',
            otherDetails: 'other-details',
            password: 'password',
            success: 'success',
            complete: 'complete',
        },
    },
    admin: {
        root: 'admin',
        login: 'login',
        resetPassword: 'reset-password',
    },
    partner: {
        root: 'partner',
        register: {
            root: 'register',
            welcome: 'welcome',
            contactDetails: 'contact-details',
            password: 'password',
            complete: 'complete',
        },
    },
};

export const realEstateAgentLoginRouting = [
    authenticationRoutes.root,
    authenticationRoutes.realEstateAgent.root,
    authenticationRoutes.realEstateAgent.login,
];

export const realEstateAgentResetPasswordRouting = [
    authenticationRoutes.root,
    authenticationRoutes.realEstateAgent.root,
    authenticationRoutes.realEstateAgent.resetPassword,
];

export const partnerRegisterContactDetailsRouting = [
    authenticationRoutes.root,
    authenticationRoutes.partner.root,
    authenticationRoutes.partner.register.root,
    authenticationRoutes.partner.register.contactDetails,
];

export const partnerRegisterPasswordRouting = [
    authenticationRoutes.root,
    authenticationRoutes.partner.root,
    authenticationRoutes.partner.register.root,
    authenticationRoutes.partner.register.password,
];

export const partnerRegisterCompleteRouting = [
    authenticationRoutes.root,
    authenticationRoutes.partner.root,
    authenticationRoutes.partner.register.root,
    authenticationRoutes.partner.register.complete,
];

export const userResetPasswordRouting = [
    authenticationRoutes.root,
    authenticationRoutes.admin.root,
    authenticationRoutes.admin.resetPassword,
];
