import { OtsConfirmationClusterLevel } from '@app/invoicing/enums/ots-confirmation-cluster-level.enum';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { CreatedBy } from '@app/real-estate-agent/enums/created-by.enum';
import { DashboardMenu } from '../dashboard-menu/dashboard-menu.constants';

export const dashboardRoute = ['/', Navigation.RealEstateAgent, Navigation.RealEstateAgentDashboard];
export const financialReportRoute = ['/', Navigation.RealEstateAgent, Navigation.Management, Navigation.FinancialReport];
export const reviewsRoute = ['/', Navigation.RealEstateAgent, Navigation.Reviews, Navigation.Overview];

export const defaultQueryFilter = { createdBy: CreatedBy.Group };
export const giftsQueryParams = { ...defaultQueryFilter, menu: DashboardMenu.Gifts };
export const todosQueryParams = { ...defaultQueryFilter, menu: DashboardMenu.AllTodos };
export const unreachableQueryParams = {
    ...defaultQueryFilter,
    menu: DashboardMenu.EneryNotOrderedTodos,
    clusterLevel: OtsConfirmationClusterLevel.Three,
};
export const allTodosQueryParams = { menu: DashboardMenu.AllTodos };
