import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as routing from '@app/partner/constants/routing.constants';
import { LoginContainer } from '@app/partner/containers/login/login.container';
import { OrderListClosedContainer } from '@app/partner/containers/order-list-closed/order-list-closed.container';
import { OrderListOpenContainer } from '@app/partner/containers/order-list-open/order-list-open.container';
import { PartnerAgentAuthGuard } from '@app/partner/guards/partner-agent-auth.guard';
import { LoggedInRedirectGuard } from '@app/redirect/guards/logged-in-redirect.guard';
import { OrderListInProgressContainer } from './containers/order-list-in-progress/order-list-in-progress.container';

const routes: Routes = [
    {
        path: routing.loginRoute,
        canActivate: [LoggedInRedirectGuard],
        component: LoginContainer,
    },
    {
        path: routing.orderListRoute,
        canActivate: [PartnerAgentAuthGuard],
        children: [
            {
                path: `${routing.openRoute}/:id`,
                component: OrderListOpenContainer,
            },
            {
                path: routing.openRoute,
                component: OrderListOpenContainer,
            },
            {
                path: `${routing.inProgressRoute}/:id`,
                component: OrderListInProgressContainer,
            },
            {
                path: routing.inProgressRoute,
                component: OrderListInProgressContainer,
            },
            {
                path: `${routing.closedRoute}/:id`,
                component: OrderListClosedContainer,
            },
            {
                path: routing.closedRoute,
                component: OrderListClosedContainer,
            },
            {
                path: '**',
                redirectTo: routing.openRoute,
            },
        ],
    },
    {
        path: '**',
        redirectTo: routing.orderListRoute,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PartnerRoutingModule {}
