import { Component, OnInit } from '@angular/core';
import { DateUtils, isoYearWeek, weekNumber } from '@smooved/core';
import { SvgIllustration } from '@smooved/ui';
import { AnalyticsMoveCountRow } from './analytics-move-count-table.interfaces';
import { AnalyticsMoveCountTableService } from './analytics-move-count-table.service';

@Component({
    selector: 'app-analytics-move-count-table',
    templateUrl: './analytics-move-count-table.component.html',
    styleUrls: ['./analytics-move-count-table.component.scss'],
    providers: [AnalyticsMoveCountTableService],
})
export class AnalyticsMoveCountTableComponent implements OnInit {
    public dataSource = [];
    public columns = [];
    public weeklyColumns: string[];

    public readonly svgIllustration = SvgIllustration;
    public readonly isoYearWeek = isoYearWeek;
    public readonly weekNumber = weekNumber;

    constructor(private readonly analyticsMoveCountTableService: AnalyticsMoveCountTableService) {}

    public ngOnInit(): void {
        this.initColumns();
        this.initDataSource();
    }

    public onClickRow(row: AnalyticsMoveCountRow): void {
        if (row.isGroup) this.analyticsMoveCountTableService.toggleGroupVisibility(row, this.dataSource);
    }

    private initColumns(): void {
        this.weeklyColumns = this.analyticsMoveCountTableService.weeks.map((w) => DateUtils.tz(w).utc().toISOString());
        this.columns = ['label', ...this.weeklyColumns, 'total'];
    }

    private initDataSource(): void {
        this.analyticsMoveCountTableService.initDataSource().subscribe((res) => (this.dataSource = res));
    }
}
