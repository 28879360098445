<div class="u-margin-top-double u-text-align-center" *ngIf="{ isTabletPortraitUp: isTabletPortraitUp$ | async } as screen">
    <app-chart-container [legendDirection]="screen.isTabletPortraitUp ? direction.Horizontal : direction.Vertical">
        <app-arc-score [data]="data.data" [value]="data.score" size="lg" class="u-margin-bottom"></app-arc-score>
        <app-chart-legend-item
            *ngFor="let item of data.legend"
            [color]="item.color"
            [label]="item.label"
            [sub]="item.sub"
            [direction]="screen.isTabletPortraitUp ? direction.Vertical : direction.Horizontal"
        ></app-chart-legend-item>
    </app-chart-container>

    <h4 class="u-margin-bottom-half">
        {{ 'REVIEWS.SCORE.MODAL.INFLUENCER_SCORE' | translate: {score: data.influencerScore | number: noDecimals} }}
        <app-icon appTooltip icon="info" [template]="tooltipContent" [hidePointer]="true"></app-icon>
    </h4>
    <p [ngClass]="{'u-w75p u-margin-0-auto' : screen.isTabletPortraitUp}">{{ 'REVIEWS.SCORE.MODAL.DESCRIPTION' | translate }}</p>
</div>

<ng-template #tooltipContent>
    <div class="__influencer-score__tooltip-container u-font-size-default u-font-weight-normal">
        {{'REVIEWS.SCORE.MODAL.DESCRIPTION_TOOLTIP' | translate}}
    </div>
</ng-template>
