import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ButtonModule } from '../button/button.module';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { SliderBottomSheetComponent } from './slider-bottom-sheet/slider-bottom-sheet.component';

const components = [BottomSheetComponent, SliderBottomSheetComponent];

@NgModule({
    imports: [CommonModule, DragDropModule, MatBottomSheetModule, ButtonModule],
    declarations: [...components],
    exports: [...components],
})
export class BottomSheetModule {}
