import { Component, Input, OnInit } from '@angular/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { TelecomSandbox } from '@app/telecom/sandboxes/telecom.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { insurancesCheckList, telecomCheckList } from './confirm-energy-success-actions.constants';

@Component({
    selector: 'app-confirm-energy-success-actions',
    templateUrl: './confirm-energy-success-actions.component.html',
    styleUrls: ['./confirm-energy-success-actions.component.scss'],
})
export class ConfirmEnergySuccessActionsComponent extends RxjsComponent implements OnInit {
    @Input() public showInsurances: boolean;

    public telecomCheckList: string[] = [];
    public insurancesCheckList: string[] = [];

    constructor(
        public uiSandbox: AppUiSandbox,
        public navigationSandbox: AppNavigationSandbox,
        public telecomSandbox: TelecomSandbox,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.stream(telecomCheckList).pipe(takeUntil(this.destroy$)).subscribe(this.handleTelecomTranslation);

        this.translateService.stream(insurancesCheckList).pipe(takeUntil(this.destroy$)).subscribe(this.handleInsurancesTranslations);
    }

    private handleTelecomTranslation = (translations: { [key: string]: string }) => {
        this.telecomCheckList = Object.values(translations);
    };

    private handleInsurancesTranslations = (translations: { [key: string]: string }) => {
        this.insurancesCheckList = Object.values(translations);
    };
}
