import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderProvidersModule } from '../header-providers.module';

@Injectable({ providedIn: HeaderProvidersModule })
export class HeaderService {
    private readonly dispatchCloseMenu = new Subject<void>();
    private readonly forwardMenuSubject = new Subject<void>();
    public readonly dispatchCloseMenu$ = this.dispatchCloseMenu.asObservable();
    public readonly forwardMenu$ = this.forwardMenuSubject.asObservable();


    public dispatch(): void {
        this.dispatchCloseMenu.next();
    }

    public forwardMenu(): void {
        this.forwardMenuSubject.next();
    }
}
