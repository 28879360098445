import { SubscriptionType } from '@app/telecom/enums/subscription-type.enum';

export enum Provider {
    Telenet = 'Telenet',
    Proximus = 'Proximus',
    Scarlet = 'Scarlet',
    Voo = 'Voo',
    Orange = 'Orange',

    Any = '-',
    None = '',
}

export enum TelecomBundle {
    Internet = 'internet',
    DigitalTelevision = 'digitalTelevision',
    MobilePhone = 'mobilePhone',
    FixedLine = 'fixedLine',
}

export interface FixedLine {
    newNumber: boolean;
    number?: string;
    provider?: string;
}

export interface SimCard {
    newNumber: boolean;
    phoneNumber: string;
    provider: string;
    simCardNumber: string;
    subscriptionType: SubscriptionType;
}

export enum TelecomInstallationTimeSlot {
    slot1 = '08:00 - 13:00',
    slot2 = '10:00 - 14:30',
    slot3 = '12:30 - 18:00',
    slot4 = '17:00 - 20:00',
}

export interface TelecomInstallation {
    date: Date;
    timeSlot: TelecomInstallationTimeSlot;
}
