import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { MoveSandbox } from '../../move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '../../navigation/sandboxes/navigation.sandbox';
import { NotificationLabel } from '../../notification/enums/notification-label.enum';
import { RealEstateGroupSandbox } from '../../real-estate-group/sandboxes/real-estate-group.sandbox';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';
import { StorageSandbox } from '../../storage/sandboxes/storage.sandbox';
import { AppUiSandbox } from '../../ui/sandboxes/ui.sandbox';
import { AuthenticationSandbox } from '../sandboxes/authentication.sandbox';
import { AuthenticationService } from '../services/authentication.service';
import {
    AuthenticationActionTypes,
    Logout,
    LogoutFailure,
    LogoutSuccess,
    RealEstateAgentLogin,
    RealEstateAgentLoginFailure,
    RealEstateAgentLoginSuccess,
    RealEstateAgentResetPasswordAction,
    RealEstateAgentResetPasswordActionFailure,
    RealEstateAgentResetPasswordActionSuccess,
    RealEstateAgentResetPasswordNewAction,
    RealEstateAgentResetPasswordNewActionFailure,
    RealEstateAgentResetPasswordNewActionSuccess,
} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly storageSandbox: StorageSandbox,
        private readonly authenticationService: AuthenticationService,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly modalSandbox: ModalSandbox
    ) {}

    public realEstateAgentResetPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType<RealEstateAgentResetPasswordAction>(AuthenticationActionTypes.RealEstateAgentResetPassword),
            mergeMap((action) =>
                this.authenticationService.realEstateAgentResetPassword(action.payload.email).pipe(
                    map((_) => new RealEstateAgentResetPasswordActionSuccess({ email: action.payload.email })),
                    catchError((_) => of(new RealEstateAgentResetPasswordActionFailure()))
                )
            )
        )
    );

    public realEstateAgentResetPasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<RealEstateAgentResetPasswordActionSuccess>(AuthenticationActionTypes.RealEstateAgentResetPasswordSuccess),
                tap((action) => {
                    this.notificationSandbox.success(NotificationLabel.AuthenticationRealEstateAgentResetPasswordSuccess, {
                        email: action.payload.email,
                    });
                    this.router.navigate(realEstateAgentLoginRouting);
                })
            ),
        {
            dispatch: false,
        }
    );

    public realEstateAgentResetPasswordNew$ = createEffect(() =>
        this.actions$.pipe(
            ofType<RealEstateAgentResetPasswordNewAction>(AuthenticationActionTypes.RealEstateAgentResetPasswordNew),
            mergeMap((action) =>
                this.authenticationService
                    .realEstateAgentResetPasswordNew(action.payload.password, action.payload.token, action.payload.id)
                    .pipe(
                        map((response) => new RealEstateAgentResetPasswordNewActionSuccess()),
                        catchError((errorResponse) => of(new RealEstateAgentResetPasswordNewActionFailure()))
                    )
            )
        )
    );

    public realEstateAgentResetPasswordNewSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<RealEstateAgentResetPasswordNewActionSuccess>(AuthenticationActionTypes.RealEstateAgentResetPasswordNewSuccess),
                tap((action) => {
                    this.notificationSandbox.success(NotificationLabel.AuthenticationRealEstateAgentResetPasswordNewSuccess);
                    this.router.navigate(realEstateAgentLoginRouting);
                })
            ),
        {
            dispatch: false,
        }
    );

    public realEstateAgentLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType<RealEstateAgentLogin>(AuthenticationActionTypes.RealEstateAgentLogin),
            mergeMap((action) =>
                this.authenticationService.realEstateAgentLogin(action.payload.credentials).pipe(
                    mergeMap((_) => this.authenticationService.authorization()),
                    tap((_) => this.storageSandbox.deleteToken(LocalStorageKeys.Language)), //delete any cookie language storage in order to get logged in user language
                    tap((authorization) => {
                        this.moveSandbox.setMoveState({
                            realEstateAgent: authorization.user.realEstateAgentId,
                        });
                        this.authenticationSandbox.setAuthorization(authorization.user);
                    }),
                    map((_) => new RealEstateAgentLoginSuccess()),
                    catchError((_) => of(new RealEstateAgentLoginFailure()))
                )
            )
        )
    );

    public realEstateAgentLoginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<RealEstateAgentLoginSuccess>(AuthenticationActionTypes.RealEstateAgentLoginSuccess),
                concatMap((_) => this.realEstateGroupSandbox.getRealEstateGroupForMe()),
                tap((realEstateGroup) => {
                    if (realEstateGroup) {
                        this.realEstateGroupSandbox.setRealEstateGroup(realEstateGroup);
                    }
                    const { redirectUrl } = this.route.snapshot.queryParams;
                    if (redirectUrl) {
                        void this.router.navigateByUrl(decodeURIComponent(redirectUrl)).then();
                        return;
                    }
                    void this.navigationSandbox.goToRealEstateAgentStart().then();
                })
            ),
        {
            dispatch: false,
        }
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Logout>(AuthenticationActionTypes.Logout),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            mergeMap((action) =>
                this.authenticationService.logout().pipe(
                    map((response) => new LogoutSuccess({ redirect: true })),
                    catchError((errorResponse) => of(new LogoutFailure()))
                )
            )
        )
    );

    public logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSuccess>(AuthenticationActionTypes.LogoutSuccess),
                tap((action) => {
                    this.moveSandbox.clearMoveState(null, true);
                    this.storageSandbox.deleteToken(LocalStorageKeys.Token);
                    this.modalSandbox.closeAll();
                    if (action.payload?.redirect) this.navigationSandbox.goToHome().then();
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        {
            dispatch: false,
        }
    );

    public logoutFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutFailure>(AuthenticationActionTypes.LogoutFailure),
                tap((_) => {
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        {
            dispatch: false,
        }
    );
}
