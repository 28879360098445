import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-logo-button',
    template: `
        <app-button theme="no-style" [routerLink]="'/'" [showBackground]="false">
            <img class="__logo" src="assets/images/smooved-logo.png" alt="Smooved logo" />
        </app-button>
    `,
    styleUrls: ['./logo-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoButtonComponent {}
