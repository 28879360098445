import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';

@Component({
    selector: 'app-confirm-energy-success-container',
    template: `
        <div class="u-flex-row u-flex-justify-content-center u-padding-top-triple u-padding-right u-padding-bottom-triple u-padding-left">
            <div class="u-container">
                <app-card class="u-flex-row u-flex-justify-content-center" [ngSwitch]="moveSandbox.showTelecomCtaInConfirm$ | async">
                    <div class="u-flex-column u-flex-align-items-center u-container-small">
                        <img width="200" src="assets/images/smooved-energy-bulb.png" class="u-margin-bottom-double" />
                        <h3 *ngSwitchCase="false" class="u-margin-bottom-double u-text-align-center">
                            {{ 'CONFIRM_ENERGY.SUCCESS.TITLE' | translate }}
                        </h3>
                        <h3 *ngSwitchCase="true" class="u-margin-bottom-double u-text-align-center">
                            {{ 'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.TITLE' | translate }}
                        </h3>
                        <p *ngSwitchCase="false" class="u-margin-bottom-double u-font-size-medium u-text-align-center">
                            {{ 'CONFIRM_ENERGY.SUCCESS.TEXT' | translate }}
                        </p>
                        <p
                            *ngSwitchCase="true"
                            class="u-margin-bottom-double u-font-size-medium u-text-align-center"
                            [innerHTML]="'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.TEXT' | translate"
                        ></p>

                        <div class="u-flex-column" *ngSwitchCase="true">
                            <app-button class="u-margin-bottom-half" (onClick)="navigationSandbox.goToTelecomFlow()">{{
                                'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.CTA_MAIN' | translate
                            }}</app-button>

                            <a class="u-link" (click)="navigationSandbox.goToApp(true)">{{
                                'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.CTA_SECONDARY' | translate
                            }}</a>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEnergySuccessContainer {
    constructor(public navigationSandbox: AppNavigationSandbox, public moveSandbox: MoveSandbox) {}
}
