import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-go-to-contract',
    template: `
        <div *ngIf="!!(moveSandbox.shoppingCart$ | async)?.length" class="u-container u-margin-0-auto u-text-align-right u-margin-bottom">
            <a class="u-link-muted u-font-size-small" routerLink="/ots/contract">{{
                'REAL_ESTATE_AGENT.OTS.GO_TO_CONTRACT' | translate
            }}</a>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoToContractComponent {
    constructor(public moveSandbox: MoveSandbox) {}
}
