import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ClientPathnameInterceptor } from '@smooved/core';
import { NotificationModule } from '@smooved/ui';
import { BlobErrorHttpInterceptor } from 'projects/ui/src/lib/company-page/interceptors/blob-error.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-errors.incerceptor';
import { HttpInvalidAuthTokenInterceptor } from './interceptors/http-invalid-auth-token.interceptor';
import { NewAccessTokenInterceptor } from './interceptors/new-access-token.interceptor';

@NgModule({
    declarations: [],
    imports: [NotificationModule, HttpClientModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ClientPathnameInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NewAccessTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInvalidAuthTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlobErrorHttpInterceptor,
            multi: true,
        },
    ],
})
export class HttpModule {}
