<app-todo
    *ngIf="completed && label && !opened; else asHeader"
    [checked]="completed"
    [label]="label"
    [variant]="headerVariant.H5"
    class="__todo-list__header"
>
    <app-todo-template *todoStateChecked>
        <div class="u-position-right u-position-bottom u-position-top u-flex u-margin-none" todo-cta>
            <ng-content select="[todoListCta]"></ng-content>
        </div>
        <a
            (click)="toggle()"
            *ngIf="!hasCustomCta"
            [appearance]="buttonAppearance.Link"
            [size]="buttonSize.Sm"
            app-button
            class="__cta-open-close u-position-right u-position-bottom u-position-top u-display-inline-flex u-flex-align-items-center u-margin-none"
            >{{ 'UI.VIEW' | translate }}</a
        >
        <span *ngIf="sub || (completed && subIfComplete)">{{ completed ? subIfComplete || sub : sub }}</span>
    </app-todo-template>
</app-todo>

<ng-template #asHeader>
    <div *ngIf="label" class="__todo-list__header u-flex-row u-flex-justify-content-space-between">
        <h5 [innerHtml]="label" class="u-display-inline-block"></h5>
        <a
            (click)="toggle()"
            *ngIf="opened"
            [appearance]="buttonAppearance.Link"
            [size]="buttonSize.Sm"
            app-button
            class="__cta-open-close u-display-inline-flex u-flex-align-items-center"
            >{{ 'UI.HIDE' | translate }}</a
        >
        <ng-content select="[todo-list-header-right]"></ng-content>
    </div>
</ng-template>

<div *ngIf="!completed || opened || (completed && !label)" class="__todo-list__body">
    <ng-content select="app-todo, [todo], [todo-list-body]"></ng-content>
</div>

<div class="__todo-list__footer u-font-size-small">
    <ng-content select="[todo-list-footer]"></ng-content>
</div>
