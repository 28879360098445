import { Component, Input, OnInit, Optional, TemplateRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { UiDirection, UiLabelPosition } from '../../ui.enums';
import { FormStatus } from '../form-status.enum';
import { considerLabelAsParagraph } from './label-container.constants';

@Component({
    selector: 'app-label-container',
    templateUrl: './label-container.component.html',
    styleUrls: ['./label-container.component.scss'],
})
export class LabelContainerComponent extends RxjsComponent implements OnInit, CanDisable {
    @Input() public required: boolean;
    @Input() public showRequiredAsterisk: boolean;
    @Input() public id: string;
    @Input() public label: string;
    @Input() public labelTemplateRef: TemplateRef<any>;
    @Input() public muted: boolean;
    @Input() public bold: boolean;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public uiDirection = UiDirection.Vertical;
    @Input() public uiLabelPosition = UiLabelPosition.Before;
    @Input() public disabled: boolean;

    public readonly uiDirections = UiDirection;
    public readonly uiLabelPositions = UiLabelPosition;
    public readonly isLongText = considerLabelAsParagraph;

    constructor(@Optional() private controlContainer: ControlContainer) {
        super();
    }

    public ngOnInit(): void {
        this.controlContainer?.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status: FormStatus): void => {
            this.disabled = status === FormStatus.Disabled;
        });
    }
}
