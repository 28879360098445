import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { WidgetVersion } from '../../../../widget-influencer-score/src/bootstrap/bootstrap.constants';
import { UrlOptionsWidget } from '../interfaces';
import { ObjectUtils } from './object-utils';

export class UrlUtils {
    public static cacheBuster(src: string): string {
        if (!src) return src;
        const url = new URL(src);
        url.searchParams.append('cb', new Date().getTime().toString());
        return url.href;
    }

    public static buildEncodedUrl(baseUrl: string, params: object): string {
        const encoding = Object.entries(params)
            .map((x) => x.map(encodeURIComponent).join('='))
            .join('&');
        return `${baseUrl}?${encoding}`;
    }

    public static createUrlForWidgetVersion2(
        uri: string,
        id: string,
        { lang, bg, shadow, direction, size, color }: UrlOptionsWidget
    ): string {
        const url = new URL([uri, id, WidgetVersion.V2].join('/'));
        if (ObjectUtils.isDefined(lang)) url.searchParams.append('lang', lang);
        if (ObjectUtils.isDefined(bg)) url.searchParams.append('bg', bg);
        if (ObjectUtils.isDefined(shadow)) url.searchParams.append('shadow', shadow);
        if (ObjectUtils.isDefined(direction)) url.searchParams.append('direction', direction);
        if (ObjectUtils.isDefined(size)) url.searchParams.append('size', size);
        if (ObjectUtils.isDefined(color)) url.searchParams.append('color', color);
        return url.href;
    }

    public static buildUrl(...args): string {
        return args.join('/');
    }

    public static getPath(url: string): string {
        if (!url) return '';
        return url.split('/').pop();
    }

    public static getActivatedRoute(route: ActivatedRouteSnapshot): {
        fragments: string[];
        parentFragments: string[];
        queryParamMap: ParamMap;
    } {
        const fragments = route.pathFromRoot.flatMap((s) => s?.url ?? []).map((s) => s.path);
        return {
            fragments: [...fragments],
            parentFragments: [...fragments.slice(0, -1)],
            queryParamMap: route.queryParamMap,
        };
    }

    public static removeUrlQueryParameters(uri: string, removeParams: string[]): URL {
        const url = new URL(uri);
        removeParams.forEach((key) => url.searchParams.delete(key));
        return url;
    }

    public static replaceState(newUrl: string): void {
        window.history.replaceState({}, document.title, newUrl);
    }

    public static paramsToObject(location: Location): object {
        if (!location) return;
        const entries = (new URLSearchParams(location.path().split('?')?.[1]) as any).entries();
        const result = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    }

    public static getQueryParam(param: string): string {
        if (!window?.location) return null;
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    public static loadScript(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.onload = () => resolve();
            script.onerror = () => reject();
            document.head.appendChild(script);
        });
    }

    public static loadStyle(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.href = url;
            link.rel = 'stylesheet';
            link.onload = () => resolve();
            link.onerror = () => reject();
            document.head.appendChild(link);
        });
    }
}
