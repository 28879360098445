import { Pipe, PipeTransform } from '@angular/core';
import { CustomDatabaseValue, NpsReview, RealEstateAgency } from '@smooved/core';

@Pipe({
    name: 'formatLocation',
    pure: false, // to ensure rerender after language change
})
export class FormatLocationPipe implements PipeTransform {
    public transform(value: NpsReview): string {
        if (!value?.location) return '';
        if (value.location === CustomDatabaseValue.Other) return '';
        return (value.location as RealEstateAgency)?.name;
    }
}
