import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from '@app/authentication/interfaces/token-response';
import { User } from '@app/user/interfaces/user';
import * as sharedUri from '@shared/user/constants/uri.constants';
import { ArrayUtils, HttpUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';
import { UserGroups } from '../enums/user-groups.enum';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpClient: HttpClient) {}

    public getUsersList(userGroups: UserGroups[] = []): Observable<User[]> {
        return this.httpClient.get<User[]>(uri.endpointUri, {
            params: HttpUtils.buildQueryParameters(ArrayUtils.isEmpty(userGroups) ? {} : { userGroups }),
        });
    }

    public getByConfirmationToken(confirmationToken: string): Observable<User> {
        const httpParams: HttpParams = new HttpParams().set('confirmationToken', confirmationToken);
        return this.httpClient.get<User>(`${uri.endpointUri}/${sharedUri.byConfirmationTokenUri}`, {
            params: httpParams,
        });
    }

    public confirmInvite(inviteData: User): Observable<TokenResponse> {
        const httpParams: HttpParams = new HttpParams().set('confirmationToken', inviteData.confirmationToken);
        return this.httpClient.patch<TokenResponse>(`${uri.endpointUri}/${sharedUri.confirmInviteUri}`, inviteData, {
            params: httpParams,
        });
    }
}
