import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthUser } from '@smooved/core';
import { Order } from '@app/order/interfaces/order';
import { moveProperties } from '@shared/move/constants/properties.constants';

@Component({
    selector: 'app-check-item-telecom-ordered-by-admin',
    template: `
        <app-check-item [checked]="order.move.telecomOrderedByAdmin" align="top">
            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="unchecked">
                <p class="u-margin-bottom-half">{{ 'PRODUCT_ORDERED_BY' | translate: { by: authorization?.firstName } }}</p>
                <app-telenet-product-input [formControl]="telenetProductControl"></app-telenet-product-input>
                <app-button (onClick)="set(true)" [disabled]="!telenetProductControl.value">{{ 'CHECK' | translate }}</app-button>
            </div>

            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="checked">
                <p class="u-margin-bottom-half">{{ 'PRODUCT_ORDERED_BY' | translate: { by: authorization?.firstName } }}</p>
                <a (click)="set(false)" class="u-link">{{ 'UNCHECK' | translate }}</a>
            </div>
        </app-check-item>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemTelecomOrderedByAdminComponent {
    @Input() public order: Order;
    @Input() public authorization: AuthUser;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    public telenetProductControl: UntypedFormControl = this.formBuilder.control(null);

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    public set(value: boolean): void {
        const payload: any = {
            [moveProperties.telecomOrderedByAdmin]: value,
        };
        const telenetProduct = this.telenetProductControl.value;
        if (telenetProduct) {
            payload[moveProperties.telecomOffer] = {
                [moveProperties.telecomOfferSelection]: telenetProduct,
            };
        }
        this.patch.emit(payload);
    }
}
