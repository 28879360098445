import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    transform(value: string | number, args?: any): string {
        if (isString(value)) return value;
        if (!value) {
            return this.transformPrice(0);
        }
        return this.transformPrice(value, args);
    }

    constructor(private currencyPipe: CurrencyPipe) {}

    private transformPrice(value: string | number, digitsInfo?: string): string {
        return this.currencyPipe.transform(value, 'EUR', 'symbol', digitsInfo, 'nl-BE');
    }
}
