<ng-container *ngIf="move?.insurancesStates as states">
    <app-insurances-covered class="u-margin-bottom u-display-block" [move]="move"></app-insurances-covered>

    <ng-container *ngIf="move?.insurancesStates as states">
        <app-check-item class="u-margin-bottom-half" [checked]="!!states.selected?.value">
            <ng-container ngProjectAs="checked">
                <div class="u-flex-column">
                    <div>
                        <span>{{ 'IOTS.STATUS.IS_SELECTED' | translate: { partnerName: partner } }}</span>
                        <span *ngIf="states?.selected?.createdOn as createdOn"> ({{ createdOn | formatDate }} )</span>
                    </div>
                    <app-insurances-proposal-url *ngIf="!states?.contractPdf" [move]="move"></app-insurances-proposal-url>
                </div>
            </ng-container>
            <ng-container ngProjectAs="unchecked">{{ 'IOTS.STATUS.IS_SELECTED' | translate: { partnerName: partner } }}</ng-container>
        </app-check-item>

        <app-check-item *ngIf="!!states?.confirmed?.value" class="u-margin-bottom-half" [checked]="!!states?.confirmed?.value">
            <ng-container ngProjectAs="checked">
                <div class="u-flex-column">
                    <div>
                        {{
                            'IOTS.STATUS.IS_CONFIRMED'
                                | translate
                                    : {
                                          insurerName:
                                              move?.insurancesOffer?.insurancesOfferSelection?.insurer?.name ||
                                              ('INSURANCES.INSURER' | translate)
                                      }
                        }}
                        <ng-container *ngIf="states.confirmed?.createdOn as createdOn">({{ createdOn | formatDate }})</ng-container>
                    </div>
                    <app-insurances-contract-pdf [move]="move"></app-insurances-contract-pdf>
                </div>
            </ng-container>
            <ng-container ngProjectAs="unchecked">{{
                'IOTS.STATUS.IS_CONFIRMED'
                    | translate
                        : {
                              insurerName:
                                  move?.insurancesOffer?.insurancesOfferSelection?.insurer?.name || ('INSURANCES.INSURER' | translate)
                          }
            }}</ng-container>
        </app-check-item>

        <app-check-item *ngIf="!!states?.noInteractionByCustomer?.value" class="u-margin-bottom-half" [blocked]="true">
            <ng-container ngProjectAs="blocked">
                {{ 'IOTS.STATUS.NO_INTERACTION_BY_CUSTOMER' | translate: { name: move | userName } }}
                <ng-container *ngIf="states.noInteractionByCustomer?.createdOn as createdOn">({{ createdOn | formatDate }})</ng-container>
            </ng-container>
        </app-check-item>

        <app-check-item *ngIf="!!states?.notAcceptedByInsurer?.value" class="u-margin-bottom-half" [blocked]="true">
            <ng-container ngProjectAs="blocked">
                {{
                    'IOTS.STATUS.NOT_ACCEPTED_BY_INSURER'
                        | translate
                            : {
                                  insurerName:
                                      move.insurancesOffer?.insurancesOfferSelection?.insurer?.name || ('INSURANCES.INSURER' | translate)
                              }
                }}
                <ng-container *ngIf="states.notAcceptedByInsurer?.createdOn as createdOn">({{ createdOn | formatDate }})</ng-container>
            </ng-container>
        </app-check-item>

        <app-check-item *ngIf="!!states?.notAcceptedByCustomer?.value" class="u-margin-bottom-half" [blocked]="true">
            <ng-container ngProjectAs="blocked">
                {{ 'IOTS.STATUS.NOT_ACCEPTED_BY_CUSTOMER' | translate: { name: move | userName } }}

                <ng-container *ngIf="states.notAcceptedByCustomer?.createdOn as createdOn">({{ createdOn | formatDate }})</ng-container>
            </ng-container>
        </app-check-item>

        <app-check-item *ngIf="!!states?.cancelled?.value" class="u-margin-bottom-half" [blocked]="true">
            <ng-container ngProjectAs="blocked">
                {{ 'IOTS.STATUS.IS_CANCELLED' | translate }}

                <ng-container *ngIf="states.cancelled.createdOn as createdOn">({{ createdOn | formatDate }})</ng-container>
            </ng-container>
        </app-check-item>

        <div
            class="u-flex-row u-flex-align-items-center u-margin-bottom-half u-color-muted"
            *ngIf="
                !(
                    states?.noInteractionByCustomer?.value ||
                    states?.notAcceptedByInsurer?.value ||
                    states?.notAcceptedByCustomer?.value ||
                    states?.confirmed?.value ||
                    states?.cancelled?.value
                )
            "
        >
            <app-svg [name]="svg.Empty" width="24" height="24" class="u-margin-right-half u-inline-flex-row"></app-svg>
            <span>{{ 'IOTS.STATUS.WAITING_RESPONSE' | translate: { name: move | userName } }}</span>
        </div>
    </ng-container>
</ng-container>
