import { HomeDescriptionSize } from '@app/shared/state/shared.reducer';
import { HomeDescription, NumberUtils } from '@smooved/core';
import { SelectInput, Svg } from '@smooved/ui';

export enum formControlNames {
    HomeDescription = 'homeDescription',
    HomeDescriptionSize = 'homeDescriptionSize',
    HomeDescriptionSizeInput = 'homeDescriptionSizeInput',
    HomeDescriptionApartmentLevel = 'homeDescriptionApartmentLevel',
}

export const homeSizeStep = 25;

export const homeDescriptionOptions: SelectInput<HomeDescription>[] = [
    {
        id: 'home-description-detached',
        svg: Svg.Single,
        labelResource: 'UI.HOME_DESCRIPTION.DETACHED',
        value: HomeDescription.Detached,
        name: 'home-description',
    },
    {
        id: 'home-description-half-open',
        svg: Svg.Half,
        labelResource: 'UI.HOME_DESCRIPTION.HALF_OPEN',
        value: HomeDescription.HalfOpen,
        name: 'home-description',
    },
    {
        id: 'home-description-closed',
        svg: Svg.Row,
        labelResource: 'UI.HOME_DESCRIPTION.CLOSED',
        value: HomeDescription.Closed,
        name: 'home-description',
    },
    {
        id: 'home-description-apartment',
        svg: Svg.Apt,
        labelResource: 'UI.HOME_DESCRIPTION.APARTMENT',
        value: HomeDescription.Apartment,
        name: 'home-description',
    },
];

export const homeDescriptionSizeOptionsApartment: SelectInput<HomeDescriptionSize>[] = [
    {
        id: 'home-description-apartment-0-50',
        labelResource: 'HOME_DESCRIPTION.APARTMENT.0-50',
        value: HomeDescriptionSize.Size0to50,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-50-100',
        labelResource: 'HOME_DESCRIPTION.APARTMENT.50-100',
        value: HomeDescriptionSize.Size50to100,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-100-150',
        labelResource: 'HOME_DESCRIPTION.APARTMENT.100-150',
        value: HomeDescriptionSize.Size100to150,
        name: 'home-description-size',
    },
];

export const homeDescriptionSizeOptionsClosed: SelectInput<HomeDescriptionSize>[] = [
    {
        id: 'home-description-apartment-0-125',
        labelResource: 'HOME_DESCRIPTION.CLOSED.0-125',
        value: HomeDescriptionSize.Size0to125,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-125-200',
        labelResource: 'HOME_DESCRIPTION.CLOSED.125-200',
        value: HomeDescriptionSize.Size125to200,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-200-250',
        labelResource: 'HOME_DESCRIPTION.CLOSED.200-250',
        value: HomeDescriptionSize.Size200to250,
        name: 'home-description-size',
    },
];

export const homeDescriptionSizeOptionsDetached: SelectInput<HomeDescriptionSize>[] = [
    {
        id: 'home-description-apartment-0-200',
        labelResource: 'HOME_DESCRIPTION.DETACHED.0-200',
        value: HomeDescriptionSize.Size0to200,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-200-250',
        labelResource: 'HOME_DESCRIPTION.DETACHED.200-250',
        value: HomeDescriptionSize.Size200to250,
        name: 'home-description-size',
    },
    {
        id: 'home-description-apartment-250-300',
        labelResource: 'HOME_DESCRIPTION.DETACHED.250-300',
        value: HomeDescriptionSize.Size250to300,
        name: 'home-description-size',
    },
];

export const calculateHomeDescriptionSizeApartmentBucketFn = (size: number): HomeDescriptionSize => {
    if (size < 50) return HomeDescriptionSize.Size0to50;
    if (NumberUtils.isBetween(size, 50, 100)) return HomeDescriptionSize.Size50to100;
    return HomeDescriptionSize.Size100to150;
};

export const calculateHomeDescriptionSizeClosedBucketFn = (size: number): HomeDescriptionSize => {
    if (size < 125) return HomeDescriptionSize.Size0to125;
    if (NumberUtils.isBetween(size, 125, 200)) return HomeDescriptionSize.Size125to200;
    return HomeDescriptionSize.Size200to250;
};

export const calculateHomeDescriptionSizeDetachedBucketFn = (size: number): HomeDescriptionSize => {
    if (size < 200) return HomeDescriptionSize.Size0to200;
    if (NumberUtils.isBetween(size, 200, 250)) return HomeDescriptionSize.Size200to250;
    return HomeDescriptionSize.Size250to300;
};
