<app-two-panel-modal [loading]="rentalInspectionDetailService.loading$ | async">
    <ng-container modal-header>
        <h5>{{'ORDERS.ORDER_SCOPE.PRODUCT.RENTALINSPECTION' | translate }}</h5>
    </ng-container>

    <ng-container modal-left>
        <h5 class="u-margin-bottom">{{"DATA" | translate}}</h5>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'FOR' | translate }}</h6>
            <app-user-details [order]="order"></app-user-details>
        </div>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'ADDRESS' | translate }}</h6>
            <app-copy-to-clipboard [keepNewLines]="true">{{ order.deliveryAddress | formatAddress: '\n'}}</app-copy-to-clipboard>
        </div>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'RENTAL_INSPECTION.MOVING_DATE' | translate }}</h6>
            <app-copy-to-clipboard>{{ order.deliveryDate | formatDate}}</app-copy-to-clipboard>
        </div>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'REQUESTOR' | translate }}</h6>
            <app-real-estate-agent-details
                [hideLogo]="true"
                [agent]="order.realEstateAgent"
                [group]="order.realEstateGroup"
            ></app-real-estate-agent-details>
        </div>

        <app-rental-agreement-assets [order]="order"></app-rental-agreement-assets>
    </ng-container>

    <ng-container modal-right>
        <h5 class="u-margin-bottom">{{"STATE" | translate}}</h5>
        <app-check-item-created-on
            class="u-margin-bottom-double"
            [order]="order"
            [label]="'REQUESTED_BY' | translate: { by: order.realEstateGroup.name }"
        ></app-check-item-created-on>

        <app-check-item-rental-inspection-planned
            class="u-margin-bottom-double"
            [order]="order"
            (patch)="onPatch($event)"
            #planned
        ></app-check-item-rental-inspection-planned>

        <app-check-item-rental-inspection-asset
            class="u-margin-bottom-double u-padding-bottom-double u-bordered-bottom"
            [order]="order"
            [disabled]="!planned.checked"
            (deleted)="reset(!!$event)"
            (orderChanged)="reset($event)"
        ></app-check-item-rental-inspection-asset>

        <app-thread class="u-display-block u-margin-bottom-double" [thread]="order.thread"></app-thread>
        <app-new-message-form [label]="'MESSAGE.FOR_REAL_ESTATE_AGENT' | translate" (send)="onSend($event)"></app-new-message-form>
    </ng-container>
</app-two-panel-modal>
