<app-alert [context]="uiContext.Info" *ngIf="waterSupplier">
    <div class="u-flex-row u-flex-align-items-start u-flex-justify-content-space-between">
        <div class="u-flex-column u-flex-align-items-start u-margin-right-quadruple">
            <h4 class="u-font-weight-bold" *ngIf="waterSupplier.name">{{ waterSupplier.name }}</h4>
            <app-address-output class="u-margin-bottom" [address]="waterSupplier.address"></app-address-output>
            <a *ngIf="waterSupplier.website"
               target="_blank"
               [href]="waterSupplier.website">{{ waterSupplier.website | uriSchema: uriSchemaType.Website }}</a>
            <a *ngIf="waterSupplier.phoneNumber"
               [href]="waterSupplier.phoneNumber | uriSchema: uriSchemaType.Tel">{{ waterSupplier.phoneNumber }}</a>
            <div *ngIf="waterSupplier.transferDocuments?.length" class="u-flex-column u-flex-align-items-start u-margin-top">
                <h5 class="u-font-weight-bold u-margin-bottom-half">{{'WATER.DOCUMENTS.TITLE' | translate}}</h5>
                <p class="u-font-size-sm u-margin-bottom-half">{{ 'WATER.DOCUMENTS.TEXT1' | translate }}</p>
                <p class="u-font-size-sm u-margin-bottom">{{ 'WATER.DOCUMENTS.TEXT2' | translate }}</p>
                <a *ngFor="let doc of waterSupplier.transferDocuments; let last=last" [class.u-margin-bottom-half]="!last" class="u-link u-flex-row u-flex-align-items-center"
                   target="_blank"
                   [href]="doc.location" download
                >
                    <app-svg-illustration [svg]="svgIllustration.Download" style="width: 24px;" class="u-margin-right-half"></app-svg-illustration>
                    {{ doc.name }}</a
                >
            </div>
        </div>
        <img *ngIf="waterSupplier.logo?.location as location" [src]="location"
             [alt]="waterSupplier.logo?.name" width="100">
    </div>
</app-alert>
