import { BillingCycle } from '@app/real-estate-group/enums/billing-cycle.enum';
import { EnumUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';

export const billingCycleOptions: DropdownInput<BillingCycle>[] = EnumUtils.entries(BillingCycle).map(
    ([key, value]: [string, BillingCycle]) => ({
        name: 'billing-cycle',
        id: `billing-cycle-${key}`,
        labelResource: `REAL_ESTATE_GROUP.BILLING_SETTINGS.BILLING_CYCLE.${key.toUpperCase()}`,
        value,
    })
);

export enum BillingSettingsNames {
    BillingCycle = 'billingCycle',
    BillingStartDate = 'billingStartDate',
}
