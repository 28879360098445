import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { UiContext, UiHeaderVariant } from '../../ui.enums';
import { WizardConfig, WIZARD_CONFIG } from '../wizard.constants';
import { WizardService } from '../wizard.service';

@Component({
    selector: 'smvd-ui-wizard-step-card',
    templateUrl: './wizard-step-card.component.html',
    styleUrls: ['./wizard-step-card.component.scss'],
    exportAs: 'uiWizardStepCard',
})
export class WizardStepCardComponent extends RxjsComponent implements OnInit {
    @Input() public header = '';
    @Input() public formGroup: UntypedFormGroup;
    @Input() public hasPrevious: boolean;
    @Input() public canPrevious = true;
    @Input() public hasNext: boolean;
    @Input() public canNext = true;
    @Input() public submitTranslationLabel = 'UI.NEXT';
    @Input() public loading = false;
    @Input() public showStepIndication = true;
    @Input() public showStepNavigation = false;
    @Input() public canNavigate = true;
    @Input() public bodyClass: string;
    @Input() public maxWidth: string;

    @Output() public previous = new EventEmitter();
    @Output() public next = new EventEmitter();

    public current: number;
    public total: number;
    public submitButtonContext: UiContext;

    public readonly headerVariants = UiHeaderVariant;

    constructor(
        public readonly wizardService: WizardService,
        @Inject(WIZARD_CONFIG) public readonly wizardConfig: WizardConfig,
        private readonly cdr: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        this.total = this.wizardService.getStepsForStepper().length;
        this.current = this.wizardService.getCurrentStepperIndex() + 1;
        this.hasPrevious = this.canPrevious && (this.hasPrevious || this.wizardService.getCurrentStepIndex() > 0);
        this.hasNext = this.canNext && (this.hasNext || this.current < this.total);
        this.wizardService.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
            this.loading = loading;
            this.cdr.markForCheck();
        });
        this.wizardService.showNavigation(this.showStepNavigation);
        this.submitButtonContext = this.wizardConfig.submitButtonContext;
    }

    public onSubmit(event): void {
        this.next.emit(event);
    }

    public onPrevious(): void {
        this.wizardService.goToPrevious();
    }
}
