import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBuilder } from '@app/http/builders/request.builder';
import { noErrorNotificationHeader } from '@app/http/interceptors/http-interceptor.constants';
import { Thread } from '@app/message/interfaces/thread.interface';
import { Move } from '@app/move/interfaces/move';
import { CreateOrderGift } from '@app/order/interfaces/create-order-gift';
import { GetOrdersRequest } from '@app/order/interfaces/get-orders-request';
import { Order } from '@app/order/interfaces/order';
import { OrderEnergySupplierStatus } from '@app/order/interfaces/order-energy-supplier-status';
import { PaginationResponse, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';
import { CreateOrderMortgage } from '../interfaces/create-order-mortgage';
import { OrderGift } from '../interfaces/order-gift';
import { OrderMortgage } from '../interfaces/order-mortgage';
import { OrderRentalInspection } from '../interfaces/order-rental-inspection';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(private httpClient: HttpClient) {}

    public get(getOrdersRequest: GetOrdersRequest): Observable<PaginationResponse<Order>> {
        const requestBuilder = new RequestBuilder()
            .addPagination(getOrdersRequest)
            .addHttpParam('states[]', JSON.stringify(getOrdersRequest.states))
            .addHttpParam('search', getOrdersRequest.search)
            .addHttpParam('minCreatedOn', getOrdersRequest.minCreatedOn)
            .addHttpParam('group', getOrdersRequest.realEstateGroup?._id)
            .addHttpParam('scope', getOrdersRequest.scope);

        return this.httpClient.get<PaginationResponse<Order>>(uri.endpointUri, {
            params: requestBuilder.getHttpParams(),
        });
    }

    public getMortgages(getOrdersRequest: GetOrdersRequest): Observable<PaginationResponse<OrderMortgage>> {
        const requestBuilder = new RequestBuilder().addPagination(getOrdersRequest).addHttpParam('search', getOrdersRequest.search);

        return this.httpClient.get<PaginationResponse<OrderMortgage>>(uri.mortgagesUri, {
            params: requestBuilder.getHttpParams(),
        });
    }

    public findById<T extends Order>(id: string): Observable<T> {
        return this.httpClient.get<T>(uri.idUri.replace(':id', id));
    }

    public patch(id: string, payload: any): Observable<Order> {
        return this.httpClient.patch<Order>(uri.idUri.replace(':id', id), payload);
    }

    public patchGift(id: string, payload: any): Observable<OrderGift> {
        return this.httpClient.patch<OrderGift>(uri.giftsIdUri.replace(':id', id), payload);
    }

    public patchRentalInspection(id: string, payload: any): Observable<OrderRentalInspection> {
        return this.httpClient.patch<OrderRentalInspection>(uri.rentalInspectionIdUri.replace(':id', id), payload);
    }

    public patchMoveByOrder(id: string, payload: any, __t?: string): Observable<Order> {
        const requestBuilder = new RequestBuilder();
        if (__t) {
            requestBuilder.addHttpParam('__t', __t);
        }
        return this.httpClient.patch<Order>(uri.moveByOrderUri.replace(':id', id), payload, {
            params: requestBuilder.getHttpParams(),
        });
    }

    public createOrderGift(payload: CreateOrderGift): Observable<void> {
        return this.httpClient.post<void>(uri.giftUri, payload);
    }

    public createOrderMortgage(payload: CreateOrderMortgage): Observable<OrderMortgage> {
        return this.httpClient.post<OrderMortgage>(uri.orderMortgageUri, payload);
    }

    public patchMortgage(id: string, payload: any): Observable<OrderMortgage> {
        return this.httpClient.patch<OrderMortgage>(uri.orderMortgageIdUri.replace(':id', id), payload);
    }

    public getRentalInspectionAgreementDocument(src: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(src, { observe: 'response', responseType: 'blob' });
    }

    public createMessage(id: string, message: string): Observable<Thread> {
        return this.httpClient.post<Thread>(StringUtils.parseUri(uri.createMessageUri, { id }), { message });
    }

    public createOrderEnergy(moveId: string, opts?: { noErrorNotification?: boolean }): Observable<Move> {
        const headers = opts?.noErrorNotification ? { [noErrorNotificationHeader]: 'true' } : null;
        return this.httpClient.post<Move>(StringUtils.parseUri(uri.orderEnergySupplier, { id: moveId }), null, { headers });
    }

    public getOrderEnergySupplierStatus(moveId: string): Observable<OrderEnergySupplierStatus[]> {
        return this.httpClient.get<OrderEnergySupplierStatus[]>(StringUtils.parseUri(uri.orderEnergySupplierStatus, { id: moveId }));
    }
}
