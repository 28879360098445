<app-card-container>
    <div class="u-container-small u-margin-0-auto">
        <app-card>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-center">
                <h3 class="u-margin-bottom">{{'PARTNER.LOGIN.TITLE' | translate}}</h3>

                <app-email-input [formControlName]="formControlNames.email" [label]="'EMAIL' | translate"></app-email-input>

                <app-password-input
                    id="password"
                    [label]="'PASSWORD' | translate"
                    [formControlName]="formControlNames.password"
                ></app-password-input>

                <app-button type="submit">{{ 'LOGIN' | translate }}</app-button>
            </form>
        </app-card>
    </div>
</app-card-container>
