import { ReviewSource } from '@smooved/core';
import { ToggleGroupOption } from 'projects/ui/src/lib/button/toggle-group/toggle-group.component';

export const sourcesToggleGroupOptions: ToggleGroupOption<ReviewSource[]>[] = [
    {
        id: 'source-toggle-group',
        label: 'REVIEWS.VIA.SMOOVED',
        value: [ReviewSource.Smooved],
    },
    {
        id: 'source-toggle-group',
        label: 'REVIEWS.VIA.OTHER',
        value: [ReviewSource.Facebook, ReviewSource.Google],
    },
];
