import { Action } from '@ngrx/store';
import { Language } from '@smooved/core';

export enum TranslationActionTypes {
    SetLanguage = '[Translation] Set language',
    SetLanguageSuccess = '[Translation] Set language success',
    SetLanguageSuccessForMover = '[Translation] Set language success for mover',
    SetLanguageSuccessForRealEstateAgent = '[Translation] Set language success for real estate agent',
    SetLanguageSuccessForAdmin = '[Translation] Set language success for admin',
}

export class SetLanguage implements Action {
    public readonly type = TranslationActionTypes.SetLanguage;

    constructor(public payload: { language: Language }) {}
}

export class SetLanguageSuccess implements Action {
    public readonly type = TranslationActionTypes.SetLanguageSuccess;

    constructor(public payload: { language: Language }) {}
}

export class SetLanguageSuccessForMover implements Action {
    public readonly type = TranslationActionTypes.SetLanguageSuccessForMover;

    constructor(public payload: { language: Language }) {}
}

export class SetLanguageSuccessForRealEstateAgent implements Action {
    public readonly type = TranslationActionTypes.SetLanguageSuccessForRealEstateAgent;

    constructor(public payload: { language: Language }) {}
}

export class SetLanguageSuccessForAdmin implements Action {
    public readonly type = TranslationActionTypes.SetLanguageSuccessForAdmin;

    constructor(public payload: { language: Language }) {}
}

export type TranslationActions =
    | SetLanguage
    | SetLanguageSuccess
    | SetLanguageSuccessForMover
    | SetLanguageSuccessForRealEstateAgent
    | SetLanguageSuccessForAdmin;
