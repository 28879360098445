import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/authentication`;
export const loginUri = `${baseUri}/mover/login`;
export const loginByAccessTokenAndIdUri = `${baseUri}/mover/login-by-access-token-and-id`;
export const loginByLoginTokenUri = `${baseUri}/login-by-login-token`;
export const confirmUserLoginUri = `${baseUri}/mover/confirm`;
export const authorizationUri = `${baseUri}/authorization`;
export const realEstateAgentLoginUri = `${baseUri}/real-estate-agent/login`;
export const realEstateAgentResetPasswordUri = `${baseUri}/real-estate-agent/reset-password`;
export const realEstateAgentResetPasswordNewUri = `${baseUri}/real-estate-agent/reset-password-new`;
export const userLoginUri = `${baseUri}/user/login`;
export const impersonateRealEstateAgentUri = `${baseUri}/impersonate/real-estate-agent`;
export const logoutUri = `${baseUri}/logout`;
export const refreshToken = `${baseUri}/user/refresh`;
export const userResetPasswordUri = `${baseUri}/user/reset-password`;
