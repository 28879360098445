import { Component } from '@angular/core';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-feature-detail-insurances-container',
    template: `
        <app-feature-detail
            [featureScope]="featureScope"
            [label]="'INSURANCES.LABEL_PLURAL' | translate"
            [description]="'FEATURE_SCOPES.INSURANCES.DESCRIPTION' | translate"
        >
        </app-feature-detail>
    `,
})
export class FeatureDetailInsurancesContainer {
    public featureScope = FeatureScope.Insurances;
}
