import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';

@Component({
    selector: 'app-stop-impersonate',
    template: `
        <app-button (onClick)="closeImpersonate()">
            <div class="u-flex-row u-flex-align-items-center">
                <span class="u-padding-right-half">{{ 'IMPERSONATE.DEACTIVATE' | translate }}</span>
                <mat-icon>cancel</mat-icon>
            </div>
        </app-button>
    `,
    styleUrls: ['./stop-impersonate.component.scss'],
})
export class StopImpersonateComponent {
    constructor(private authenticationSandbox: AuthenticationSandbox, private router: Router) {}

    public closeImpersonate() {
        this.authenticationSandbox.impersonateRealEstateAgent(null).subscribe(() => {
            this.router.navigate(['admin', 'real-estate-agents', 'all']).then();
        });
    }
}
