<app-loading-container [loading]="loading$ | async">
    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start" (ngSubmit)="submit()">
        <ui-dropdown-input
            [options]="usageOfResidenceOptions"
            [label]="'USAGE_OF_RESIDENCE.LABEL' | translate"
            [formControlName]="formControlName.UsageOfResidence"
            width="100%"
        ></ui-dropdown-input>
        <ui-dropdown-input
            [options]="meterSituationOptions"
            [label]="'METER_SITUATION.LABEL' | translate"
            [formControlName]="formControlName.MeterSituation"
            width="100%"
        ></ui-dropdown-input>
        <smvd-ui-number-input
            *ngIf="isElectricityOrBoth"
            [currency]="currency.Eur"
            [formControlName]="formControlName.ElectricityAdvanceAmount"
            [label]="'ELECTRICITY_ADVANCE_AMOUNT.LABEL' | translate"
        ></smvd-ui-number-input>
        <smvd-ui-number-input
            *ngIf="isGasOrBoth"
            [currency]="currency.Eur"
            [formControlName]="formControlName.GasAdvanceAmount"
            [label]="'GAS_ADVANCE_AMOUNT.LABEL' | translate"
        ></smvd-ui-number-input>
        <app-select-input
            [options]="communicationChannelOptions"
            [formControlName]="formControlName.CommunicationChannel"
            [label]="'COMMUNICATION_CHANNEL.LABEL' | translate"
            [custom]="false"
            [hasMargin]="false"
            [hasMarginDouble]="true"
        ></app-select-input>
        <div class="u-text-align-right u-w100p">
            <button smvd-ui-button type="submit">{{ 'COMMON.ORDER' | translate }}</button>
        </div>
    </form>
</app-loading-container>
<app-alert *ngIf="errorMessage" [context]="uiContext.Danger" icon="error" class="u-margin-top">
    <h6 class="u-margin-bottom-half">{{ 'ENERGY_ORDER_INFORMATION.ERROR' | translate }}</h6>
    <app-copy-to-clipboard>{{ errorMessage }}</app-copy-to-clipboard>
    <p class="u-margin-top-double u-font-size-small" [innerHtml]="'ENERGY_ORDER_INFORMATION.TIP' | translate"></p>
</app-alert>
