import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-real-estate-agent-register-contact-details-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.CONTACT_DETAILS.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: 1,
                                          end: 3
                                      }
                        }}
                    </p>

                    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
                        <app-text-input
                            formControlName="firstName"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.CONTACT_DETAILS.FIRST_NAME' | translate"
                        ></app-text-input>

                        <app-text-input
                            formControlName="lastName"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.CONTACT_DETAILS.LAST_NAME' | translate"
                        ></app-text-input>

                        <app-text-input
                            formControlName="phoneNumber"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.CONTACT_DETAILS.PHONE_NUMBER' | translate"
                        ></app-text-input>

                        <app-email-input formControlName="email"></app-email-input>

                        <app-language-input
                            formControlName="language"
                            [label]="'LANGUAGE.LABEL' | translate"
                            [hasMargin]="false"
                            [hasMarginDouble]="true"
                        ></app-language-input>

                        <app-previous-submit [showPrevious]="false"></app-previous-submit>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
})
export class RealEstateAgentRegisterContactDetailsContainer implements OnInit, OnDestroy {
    public form: UntypedFormGroup = this.formBuilder.group({
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        language: [this.translateService.currentLang, [Validators.required]],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.realEstateAgentSandbox.realEstateAgentRegisterDataOnce$.pipe(filter((x) => !!x)).subscribe((registeredData) => {
            this.form.patchValue(registeredData);
            if (registeredData.confirmationToken) {
                this.emailFormControl().disable({ onlySelf: true });
            }
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.router
                .navigate(['../other-details'], {
                    relativeTo: this.route,
                })
                .then();
        }
    }

    public ngOnDestroy(): void {
        this.realEstateAgentSandbox.setInviteData(this.form.value);
    }

    private emailFormControl(): AbstractControl {
        return this.form.get('email');
    }
}
