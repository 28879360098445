import { Component } from '@angular/core';
import {
    aboutDefaultLanguageOptions,
    editorModules,
    languageOptions,
} from '@app/external-influencer/components/external-influencer-configure-about/external-influencer-configure-about.constants';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';

@Component({
    selector: 'app-external-influencer-configure-about',
    templateUrl: './external-influencer-configure-about.component.html',
})
export class ExternalInfluencerConfigureAboutComponent {
    public readonly maxAboutUsLength = 500;
    public formGroupNames = formGroupNames;
    public formControlNames = formControlNames;
    public languageOptions = languageOptions;
    public aboutDefaultLanguageOptions = aboutDefaultLanguageOptions;

    constructor(public readonly externalInfluencerService: ExternalInfluencerService) {}
}
