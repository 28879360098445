import { Pipe, PipeTransform } from '@angular/core';
import { ReviewsData } from '@smooved/core';
import { WidgetViewModel } from '../../widget-influencer-score';
import { ReviewsUtils } from '../reviews.utils';

@Pipe({
    name: 'arcScoreData',
})
export class ArcScoreDataPipe implements PipeTransform {
    transform(value: ReviewsData): WidgetViewModel {
        return {
            overall: value,
            arcScore: ReviewsUtils.mapArcScore(value),
            scoreScale: ReviewsUtils.mapScoreScale(value?.score),
            score: value?.score,
        };
    }
}
