import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MoveDetailTab } from '@app/real-estate-agent/modals/move-detail/move-detail.constants';
import { SocialSharingSandbox } from '@app/real-estate-agent/sandboxes/social-sharing.sandbox';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { InsightsSandbox } from '@app/real-estate-group/sandboxes/insight.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppNpsReviewsTemplateService } from '@app/reviews/components/nps-reviews-template/nps-reviews-template.service';
import { NpsReviewsDataSource } from '@app/reviews/data-sources/nps-reviews/nps-reviews.data-source';
import {
    AnalyticsEventsEnum,
    AnalyticsService,
    CoreUtils,
    FeatureScope,
    NpsReview,
    ObjectUtils,
    ReviewSource,
    RxjsComponent,
} from '@smooved/core';
import {
    Brand,
    ButtonAppearance,
    ButtonSize,
    DurationFilter,
    NPS_REVIEWS_TEMPLATE_CONFIG,
    NpsReviewBucket,
    NpsReviewsFilterService,
    NpsReviewsTemplateService,
    RealEstateGroupLocationsService,
    ReviewsDataSource,
    SvgIllustration,
    TimeValue,
    UiContext,
    UiIconSize,
} from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { analyticsReviewsTemplateConfig } from './analytics-reviews-template-config.constants';

@Component({
    selector: 'app-analytics-reviews',
    templateUrl: './analytics-reviews.container.html',
    styleUrls: ['./analytics-reviews.container.scss'],
    providers: [
        { provide: NPS_REVIEWS_TEMPLATE_CONFIG, useValue: analyticsReviewsTemplateConfig },
        {
            provide: NpsReviewsTemplateService,
            useClass: AppNpsReviewsTemplateService,
        },
    ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AnalyticsReviewsContainer extends RxjsComponent implements OnInit, OnDestroy {
    public sharingDisabled: boolean;
    public preSelectedBuckets: NpsReviewBucket[];
    public readonly uiContext = UiContext;
    public readonly uiIconSize = UiIconSize;
    public readonly featureScope = FeatureScope;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly brands = Brand;
    public readonly reviewSources = ReviewSource;
    public readonly svgIllustration = SvgIllustration;

    public total$: Observable<number>;
    public ninetyDaysTotal$: Observable<number>;
    public isOthersFilter$: Observable<boolean>;
    public loading$ = (this.npsReviewDataSource as NpsReviewsDataSource).loading$;
    public readonly reviewScores$ = this.insightsSandbox.reviewScores$;

    private movePatchedSubject = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly npsReviewsTemplateService: NpsReviewsTemplateService,
        public readonly insightsSandbox: InsightsSandbox,
        private readonly route: ActivatedRoute,
        private readonly socialSharingSandbox: SocialSharingSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox,
        private readonly npsReviewDataSource: ReviewsDataSource,
        private readonly realEstateGroupLocationsService: RealEstateGroupLocationsService,
        private readonly npsReviewsFilterService: NpsReviewsFilterService,
        private readonly analyticsService: AnalyticsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.init();
        this.socialSharingSandbox.sharing$.pipe(takeUntil(this.destroy$)).subscribe(this.handleWhileSharing);
    }

    public ngOnDestroy(): void {
        (this.npsReviewsTemplateService as AppNpsReviewsTemplateService).resetUnreadCount();
    }

    public openDetail(review: NpsReview): void {
        if (review.isAnonymous) {
            return;
        }

        const data = {
            id: review.moveId,
            patchedSubject: this.movePatchedSubject,
            options: { activeTab: MoveDetailTab.nps },
        };

        this.realEstateAgentModalsSandbox.openTransfereeDetail(review.moveId, data);
    }

    public download(review: NpsReview): void {
        this.socialSharingSandbox.download(review);
    }

    public onFilterSelection(filter: string, value: any): void {
        this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewsInsightsFilterChanged, { filter, value: JSON.stringify(value.name) });
    }

    private init(): void {
        this.total$ = (this.npsReviewDataSource as NpsReviewsDataSource).reviewsCount$;
        this.ninetyDaysTotal$ = (this.npsReviewDataSource as NpsReviewsDataSource).intervalCounts$.pipe(map(this.ninetyDayIntervalCount));
        this.isOthersFilter$ = this.npsReviewsFilterService.filter$.pipe(
            map(({ filter }) => !(CoreUtils.isEmpty(filter.source) || ObjectUtils.isEqual(filter.source, [ReviewSource.Smooved])))
        );

        this.insightsSandbox.getInsightsData();
        this.insightsSandbox.getScores();
        this.insightsSandbox.getHouseMatchScore();
        const { buckets } = this.route.snapshot.queryParams;
        this.preSelectedBuckets = buckets?.split(',') as NpsReviewBucket[];
        this.realEstateGroupSandbox.idOnce$.subscribe((id) => this.realEstateGroupLocationsService.getLocations(id));
    }

    private handleWhileSharing = (whileSharing: boolean): void => {
        this.sharingDisabled = whileSharing;
    };

    private ninetyDayIntervalCount = (intervals: TimeValue[]): number =>
        intervals.find((interval) => interval.interval === DurationFilter.LatestNinetyDays)?.value;
}
