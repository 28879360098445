import { Injectable } from '@angular/core';
import { RxjsService } from '@smooved/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collapsingAnimationTime } from './main-container-template.constants';

@Injectable({
    providedIn: 'root',
})
export class MainContainerTemplateService extends RxjsService {
    private secondaryNavigationCollapsedSubject = new BehaviorSubject<boolean>(false);
    private secondaryNavigationCollapsingSubject = new BehaviorSubject<boolean>(false);
    private secondaryNavigationCollapsedOpenSubject = new BehaviorSubject<boolean>(false);

    public secondaryNavigationCollapsed$ = this.secondaryNavigationCollapsedSubject.asObservable();
    public secondaryNavigationCollapsing$ = this.secondaryNavigationCollapsingSubject.asObservable();
    public secondaryNavigationCollapsedOpen$ = this.secondaryNavigationCollapsedOpenSubject.asObservable();

    public secondaryNavigationOpen$ = combineLatest([
        this.secondaryNavigationCollapsed$,
        this.secondaryNavigationCollapsing$,
        this.secondaryNavigationCollapsedOpen$,
    ]).pipe(map(([collapsed, collapsing, collapsedOpen]) => !(collapsed || collapsing) || collapsedOpen));

    constructor() {
        super();
    }

    public toggleSecondaryNavigationCollapseState(): void {
        const collapse = !this.secondaryNavigationCollapsedSubject.value;
        collapse ? this.collapseSecondaryNavigation() : this.expandSecondaryNavigation();
    }

    public expandSecondaryNavigation(): void {
        this.secondaryNavigationCollapsedSubject.next(false);
        this.secondaryNavigationCollapsingSubject.next(false);
    }

    public collapseSecondaryNavigation(): void {
        this.secondaryNavigationCollapsingSubject.next(true);

        /** A setTimeout is used to avoid reopening the sidebar by mouseenter while it is collapsing */
        setTimeout(() => {
            this.secondaryNavigationCollapsedSubject.next(true);
            this.secondaryNavigationCollapsingSubject.next(false);
        }, collapsingAnimationTime);
    }

    public setSecondaryNavigationCollapsedOpenState(open: boolean): void {
        if (this.secondaryNavigationCollapsedSubject.value) {
            this.secondaryNavigationCollapsedOpenSubject.next(open);
        } else {
            this.secondaryNavigationCollapsedOpenSubject.next(false);
        }
    }
}
