import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { FeatureDetailComponent } from './components/feature-detail/feature-detail.component';
import { FeatureScopeProvidersModule } from './feauture-scope-providers.module';
import { FeatureScopeUiModule } from '@smooved/core';

const components = [FeatureDetailComponent];

@NgModule({
    declarations: [...components],
    exports: [...components, FeatureScopeUiModule],
    imports: [CommonModule, SharedModule, FeatureScopeProvidersModule, FeatureScopeUiModule],
})
export class FeatureScopeModule {}
