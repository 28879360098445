<div class="__short-content u-margin-bottom" [class.__show-all]="!shortView" [style.max-height]="height">
    <ng-content></ng-content>
</div>
<button
    *ngIf="!!shortView"
    class="u-flex-align-self-center u-margin-bottom"
    smvd-ui-button
    [context]="uiContext.Secondary"
    [appearance]="buttonAppearance.Stroked"
    (click)="showAll()"
>
    {{ label | translate }}
</button>
