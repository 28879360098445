import { Component, forwardRef, Host, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { BaseInput } from '../../../form/base-input';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { gasControlNames, GasMetersForm, placeholders } from '../../constants/energy.constants';

@Component({
    selector: 'smvd-ui-meter-readings-gas',
    templateUrl: 'meter-readings-gas.component.html',
    styleUrls: ['meter-readings-gas.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeterReadingsGasComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MeterReadingsGasComponent),
            multi: true,
        },
    ],
})
export class MeterReadingsGasComponent extends BaseInput implements OnInit, ControlValueAccessor, Validator {
    public readonly fields = gasControlNames;
    public readonly svgIllustration = SvgIllustration;
    public readonly placeholders = placeholders;
    public gasForm: FormGroup = this.formBuilder.group({
        [gasControlNames.active]: true,
        [gasControlNames.eanCodeGas]: null,
        [gasControlNames.gasMeterReading]: null,
    });

    public activeControl: FormControl;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: FormBuilder
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.activeControl = this.gasForm.get(gasControlNames.active) as FormControl;
        this.gasForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormValueChange);
    }

    public writeValue(data: GasMetersForm): void {
        this.gasForm.patchValue(data, { emitEvent: false });
    }

    public validate(): ValidationErrors | null {
        return this.gasForm.invalid ? { gasMeters: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.gasForm.disable({ emitEvent: false }) : this.gasForm.enable({ emitEvent: false });
    }

    private handleFormValueChange = (): void => this.propagateChange(this.gasForm.getRawValue());
}
