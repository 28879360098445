import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateUtils } from '@smooved/core';
import { BaseInput } from '../base-input';

@Component({
    selector: 'app-date-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    type="text"
                    [id]="id"
                    matInput
                    [(ngModel)]="innerModel"
                    (ngModelChange)="onModelChange($event)"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    (blur)="onBlur($event)"
                    [min]="minDate"
                    [max]="maxDate"
                    [errorStateMatcher]="errorStateMatcher"
                    [matDatepickerFilter]="datepickerFilter"
                    [matDatepicker]="datePicker"
                    autocomplete="smooved"
                />
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
                <mat-datepicker-toggle [disableRipple]="true" matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./date-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        },
    ],
})
export class DateInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public datepickerFilter: (date: Date) => boolean;

    public innerModel: Date;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: Date): void {
        this.innerModel = value;
    }

    public onModelChange(value: Date): void {
        this.propagateChange(DateUtils.isValidDate(value) ? value : null);
    }
}
