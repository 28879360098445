import { createFeatureSelector } from '@ngrx/store';
import { AgreementActions } from './agreement.actions';

export interface AgreementState {}

export const initialState: AgreementState = {};

export function agreementReducer(state = initialState, action: AgreementActions): AgreementState {
    switch (action.type) {
        default:
            return state;
    }
}

export const getAgreementState = createFeatureSelector<AgreementState>('agreements');
