import { NpsReviewBucket } from '../../enums/nps-review-buckets.enum';

export const bucketsMapFilter: { [key in NpsReviewBucket]: number[] } = {
    [NpsReviewBucket.Excellent]: [10],
    [NpsReviewBucket.Great]: [8, 9],
    [NpsReviewBucket.Average]: [6, 7],
    [NpsReviewBucket.Poor]: [4, 5],
    [NpsReviewBucket.Bad]: [0, 1, 2, 3],
};

export const bucketsToStarsMap: { [key in NpsReviewBucket]: number } = {
    [NpsReviewBucket.Excellent]: 5,
    [NpsReviewBucket.Great]: 4,
    [NpsReviewBucket.Average]: 3,
    [NpsReviewBucket.Poor]: 2,
    [NpsReviewBucket.Bad]: 1,
};

export enum BucketsFormFields {
    Buckets = 'buckets',
}
