import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { UiDirection } from '../../../ui.enums';
import { ChartLegendItemComponent } from '../legend/chart-legend-item/chart-legend-item.component';

@Component({
    selector: 'app-chart-container',
    templateUrl: './chart-container.component.html',
    styleUrls: ['./chart-container.component.scss'],
})
export class ChartContainerComponent {
    @Input() public legendDirection: UiDirection;
    @ContentChildren(ChartLegendItemComponent) public legendItems: QueryList<ChartLegendItemComponent>;

    public hasLegend(): boolean {
        return !!this.legendItems.length;
    }

    public uiDirection = UiDirection;
}
