import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { TranslateService } from '@ngx-translate/core';
import { dateFormatDefault, DateUtils, DbUtils, MoveTransactionType, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import {
    CheckInput,
    ElectricityFormFields,
    ElectricityMeterType,
    electricityMeterTypeOptions,
    exclusiveNightOption,
    ModalSandbox,
    UiDirection,
} from '@smooved/ui';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import {
    AdminElectricityForm,
    AdminElectricityFormFields,
    automaticDigitalReadingOptionRental,
    automaticDigitalReadingOptionSale,
    electricityI18n,
    fieldContexts,
} from './meter-info-electricity.constants';

@Component({
    selector: 'app-meter-info-electricity',
    templateUrl: './meter-info-electricity.component.html',
    styleUrls: ['./meter-info-electricity.component.scss'],
})
export class MeterInfoElectricityComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public isEots: boolean;
    @Input() public move: Move;
    @Input() public showTitle = true;
    @Input() public layout = UiDirection.Horizontal;
    @Input() public disabled = false;
    @Input() public readingDisabled = false;
    // Optional: only to be used when moving date is handled outside this component.
    @Input() public movingDate: Date;
    @Input() public show: boolean;

    @Output() public automaticChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public formChange: EventEmitter<void> = new EventEmitter<void>();

    public showElectricity: boolean;
    public form: UntypedFormGroup;
    public controls = { ...ElectricityFormFields, ...AdminElectricityFormFields };

    public uiDirection = UiDirection;
    public meterType = ElectricityMeterType;

    public meterTypeOptions = electricityMeterTypeOptions;
    public exclusiveNightOption = exclusiveNightOption;
    public automaticDigitalReadingOption: CheckInput<boolean>;
    public fieldContexts = fieldContexts;
    public isAdmin = false;

    private moveTransactionType: MoveTransactionType;

    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly route: ActivatedRoute,
        private readonly cdr: ChangeDetectorRef,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService,
        private readonly authenticationSandbox: AuthenticationSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            this.isAdmin = isAdmin;

            this.form = this.formBuilder.group({
                [ElectricityFormFields.MeterType]: [{ value: ElectricityMeterType.Single, disabled: this.disabled }],
                [ElectricityFormFields.ExclusiveNight]: [{ value: false, disabled: this.disabled }],
                [ElectricityFormFields.EanCode]: [{ value: null, disabled: this.disabled }],
                [ElectricityFormFields.AutomaticDigitalReadings]: [{ value: false, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.MovingDate]: [{ value: null, disabled: true }],
                [ElectricityFormFields.SingleMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.DoubleDayMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.DoubleNightMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.ConsumptionDayMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.ConsumptionNightMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.InjectionDayMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.InjectionNightMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.ExclusiveNightMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
            });

            if (isAdmin) {
                this.form.addControl(
                    AdminElectricityFormFields.EanCodeByAdmin,
                    this.formBuilder.control({
                        value: null,
                        disabled: this.disabled,
                    })
                );
                this.form.addControl(
                    AdminElectricityFormFields.MeterNumberByAdmin,
                    this.formBuilder.control({
                        value: null,
                        disabled: this.disabled,
                    })
                );
            }

            if (this.move) {
                const energyType = this.move?.energyOffer?.energyType;
                const showElectricity =
                    this.show || !energyType || EnergyUtils.hasElectricity(energyType) || !!MoveUtils.energyTransferSelected(this.move);

                this.moveTransactionType = MoveUtils.getMoveTransactionType(this.move);
                this.init(this.move, showElectricity);
            } else {
                combineLatest([
                    this.moveSandbox.moveOnce$,
                    this.moveSandbox.energyTransferSelectedOnce$,
                    this.moveSandbox.energyTypeHasElectricity$.pipe(take(1)),
                    this.route.queryParams.pipe(take(1)),
                ])
                    .pipe(
                        concatMap(([moveState, energyTransferSelected, energyTypeHasElectricity, { id }]): Observable<any> => {
                            const moveId = id || DbUtils.getStringId(moveState);
                            if (!moveId) {
                                return of([moveState || null, energyTypeHasElectricity || !!energyTransferSelected]);
                            } else {
                                return this.moveSandbox.get(moveId).pipe(
                                    map((move) => {
                                        const energyType = move.energyOffer?.energyType;
                                        const showElectricity =
                                            !energyType || EnergyUtils.hasElectricity(energyType) || !!energyTransferSelected;
                                        return [move, showElectricity];
                                    }),
                                    catchError((e) => of([moveState || null, energyTypeHasElectricity || !!energyTransferSelected]))
                                );
                            }
                        })
                    )
                    .subscribe(([move, showElectricity]) => {
                        this.init(move, showElectricity);
                    });
            }
        });
    }

    public ngOnChanges({ movingDate, disabled, readingDisabled }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(disabled) || SimpleChangesUtils.hasChanged(readingDisabled)) {
            this.setFormControlDisabled(ElectricityFormFields.MeterType, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.ExclusiveNight, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.EanCode, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.AutomaticDigitalReadings, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.SingleMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.DoubleDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.DoubleNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ConsumptionDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ConsumptionNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.InjectionDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.InjectionNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ExclusiveNightMeterReading, !this.canEditMeterReading());
            if (this.isAdmin) {
                this.setFormControlDisabled(AdminElectricityFormFields.EanCodeByAdmin, this.disabled);
                this.setFormControlDisabled(AdminElectricityFormFields.MeterNumberByAdmin, this.disabled);
            }
        }

        if (SimpleChangesUtils.hasChanged(movingDate)) this.form?.get(ElectricityFormFields.MovingDate).setValue(this.movingDate);
    }

    /**
     * Function used in viewchild
     * @returns Move patch
     */
    public createPatch(): Move {
        let patch: Move = {};

        if (this.showElectricity) {
            patch.energyOffer = {
                meterType: this.meterTypeFormControl().value,
            };
        }

        this.buildPatchForExclusiveNight(patch, this.showElectricity);

        patch.eanCodeElectricity =
            !!this.eanCodeElectricityFormControl().value && this.showElectricity ? this.eanCodeElectricityFormControl().value : null;

        if (this.isAdmin) {
            patch.eanCodeElectricityByAdmin = this.showElectricity && this.form.get(AdminElectricityFormFields.EanCodeByAdmin).value;
            patch.meterNumberElectricityByAdmin = this.showElectricity && this.form.get(AdminElectricityFormFields.MeterNumberByAdmin).value;
        }

        switch (this.meterTypeFormControl().value) {
            case ElectricityMeterType.Single:
                this.buildPatchSingle(patch, this.showElectricity);
                break;
            case ElectricityMeterType.Digital:
                patch = { ...patch, ...this.buildPatchDigital(this.showElectricity) };
                break;
            case ElectricityMeterType.Double:
            case ElectricityMeterType.Unknown:
                this.buildPatchDouble(patch, this.showElectricity);
                break;
            default:
        }

        return patch;
    }

    private init(move: Move, showElectricity: boolean): void {
        this.showElectricity = showElectricity;
        this.cdr.detectChanges();
        if (!move || !showElectricity) {
            return;
        }

        this.automaticDigitalReadingOption =
            this.moveTransactionType === MoveTransactionType.Sale ? automaticDigitalReadingOptionSale : automaticDigitalReadingOptionRental;

        const meterType = move.energyOffer?.meterType
            ? move.energyOffer?.meterType
            : move.electricityDoubleDayMeterReading
              ? ElectricityMeterType.Double
              : ElectricityMeterType.Single;

        this.initMeterReadings(move, meterType);
        if (this.disabled) return;
        this.initListeners();
        this.handleAutomaticDigitalReadingsChange(move?.energyDigitalMeterReadings?.automatic);
    }

    private initMeterReadings(move: Move, meterType: ElectricityMeterType): void {
        this.meterTypeFormControl().patchValue(meterType);
        this.form.get(ElectricityFormFields.MovingDate).patchValue(this.movingDate || move.movingDate);
        if (meterType === ElectricityMeterType.Single) {
            this.electricitySingleMeterReadingFormControl().patchValue(move.electricitySingleMeterReading);
        } else if (meterType === ElectricityMeterType.Double) {
            this.electricityDoubleDayMeterReadingFormControl().patchValue(move.electricityDoubleDayMeterReading);
            this.electricityDoubleNightMeterReadingFormControl().patchValue(move.electricityDoubleNightMeterReading);
        } else if (meterType === ElectricityMeterType.Digital) {
            const { energyDigitalMeterReadings: digitalReadings } = move;
            this.form.get(ElectricityFormFields.AutomaticDigitalReadings).patchValue(digitalReadings?.automatic);
            this.form.get(ElectricityFormFields.ConsumptionDayMeterReading).patchValue(digitalReadings?.consumption?.day);
            this.form.get(ElectricityFormFields.ConsumptionNightMeterReading).patchValue(digitalReadings?.consumption?.night);
            this.form.get(ElectricityFormFields.InjectionDayMeterReading).patchValue(digitalReadings?.injection?.day);
            this.form.get(ElectricityFormFields.InjectionNightMeterReading).patchValue(digitalReadings?.injection?.night);
        }

        this.exclusiveNightFormControl().patchValue(move.electricityExclusiveNight);
        this.electricityExclusiveNightMeterReadingFormControl().patchValue(move.electricityDoubleExclusiveNightMeterReading);

        if (move.eanCodeElectricity) {
            this.eanCodeElectricityFormControl().patchValue(move.eanCodeElectricity);
        }

        if (this.isAdmin) {
            if (move.eanCodeElectricityByAdmin)
                this.form.get(AdminElectricityFormFields.EanCodeByAdmin).patchValue(move.eanCodeElectricityByAdmin);
            if (move.meterNumberElectricityByAdmin)
                this.form.get(AdminElectricityFormFields.MeterNumberByAdmin).patchValue(move.meterNumberElectricityByAdmin);
        }
    }

    private initListeners(): void {
        this.form
            .get(ElectricityFormFields.AutomaticDigitalReadings)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(this.confirmAutomaticDigitalReadingsChange);

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((x) => {
            this.formChange.emit();
        });
    }

    private buildPatchForExclusiveNight(patch: any, showElectricity: boolean): void {
        patch.electricityExclusiveNight = this.form.get(ElectricityFormFields.ExclusiveNight).value;
        patch.electricityDoubleExclusiveNightMeterReading =
            !!showElectricity && !!patch.electricityExclusiveNight ? this.form.get(ElectricityFormFields.ExclusiveNightMeterReading).value : null;
    }

    private buildPatchSingle(patch, showElectricity): void {
        patch.electricitySingleMeterReading =
            showElectricity && !!this.electricitySingleMeterReadingFormControl().value
                ? this.electricitySingleMeterReadingFormControl().value
                : null;
        patch.electricityDoubleDayMeterReading = null;
        patch.electricityDoubleNightMeterReading = null;
        patch.energyDigitalMeterReadings = null;
    }

    private buildPatchDouble(patch, showElectricity): void {
        patch.electricityDoubleDayMeterReading =
            showElectricity && !!this.electricityDoubleDayMeterReadingFormControl().value
                ? this.electricityDoubleDayMeterReadingFormControl().value
                : null;
        patch.electricityDoubleNightMeterReading = this.electricityDoubleNightMeterReadingFormControl().value
            ? this.electricityDoubleNightMeterReadingFormControl().value
            : null;
        patch.electricitySingleMeterReading = null;
        patch.energyDigitalMeterReadings = null;
    }

    private buildPatchDigital(showElectricity: boolean): Move {
        const {
            automaticDigitalReadings,
            consumptionDayMeterReading,
            consumptionNightMeterReading,
            injectionDayMeterReading,
            injectionNightMeterReading,
            movingDate,
        } = <AdminElectricityForm>this.form.getRawValue();

        return {
            energyDigitalMeterReadings: showElectricity
                ? {
                      automatic: automaticDigitalReadings,
                      consumption: !automaticDigitalReadings
                          ? {
                                day: consumptionDayMeterReading,
                                night: consumptionNightMeterReading,
                            }
                          : null,
                      injection: !automaticDigitalReadings
                          ? {
                                day: injectionDayMeterReading,
                                night: injectionNightMeterReading,
                            }
                          : null,
                  }
                : null,
            electricitySingleMeterReading: null,
            electricityDoubleDayMeterReading: null,
            electricityDoubleNightMeterReading: null,
            movingDate: automaticDigitalReadings ? DateUtils.tz(movingDate).toDate() : undefined,
        };
    }

    private electricitySingleMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.SingleMeterReading);
    }

    private electricityDoubleDayMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.DoubleDayMeterReading);
    }

    private electricityDoubleNightMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.DoubleNightMeterReading);
    }

    private electricityExclusiveNightMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.ExclusiveNightMeterReading);
    }

    private eanCodeElectricityFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.EanCode);
    }

    public exclusiveNightFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.ExclusiveNight);
    }

    public meterTypeFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.MeterType);
    }

    private confirmAutomaticDigitalReadingsChange = (value: boolean) => {
        if (!value) {
            this.handleAutomaticDigitalReadingsChange(value);
            return;
        }

        const data = {
            data: this.translateService.instant(electricityI18n.confirmAutomaticReadings, {
                movingDate: DateUtils.tz(this.form.get(ElectricityFormFields.MovingDate).value).format(dateFormatDefault),
            }),
        };
        const callback = (confirm: boolean) => {
            if (confirm) {
                this.handleAutomaticDigitalReadingsChange(value);
                return;
            }
            this.form.get(ElectricityFormFields.AutomaticDigitalReadings).patchValue(!value, { emitEvent: false });
        };
        this.modalSandbox.openConfirmModal(data, callback, data, callback);
    };

    private handleAutomaticDigitalReadingsChange(value: boolean): void {
        // When Input movingDate is set, the movingDate is handled outside this component.
        this.setFormControlDisabled(ElectricityFormFields.MovingDate, !value || !!this.movingDate || this.disabled);
        this.setFormControlDisabled(ElectricityFormFields.ConsumptionDayMeterReading, value || !this.canEditMeterReading());
        this.setFormControlDisabled(ElectricityFormFields.ConsumptionNightMeterReading, value || !this.canEditMeterReading());
        this.setFormControlDisabled(ElectricityFormFields.InjectionDayMeterReading, value || !this.canEditMeterReading());
        this.setFormControlDisabled(ElectricityFormFields.InjectionNightMeterReading, value || !this.canEditMeterReading());

        this.automaticChange.emit(value);
    }

    private setFormControlDisabled(formControlName: ElectricityFormFields | AdminElectricityFormFields, disabled: boolean): void {
        this.form?.get(formControlName)[disabled ? 'disable' : 'enable']();
    }

    private canEditMeterReading(): boolean {
        return !(this.disabled || this.readingDisabled);
    }
}
