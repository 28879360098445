import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ArrayUtils, FeatureScope, LogicalOperation } from '@smooved/core';
import { FeatureScopeSandbox } from '../sandboxes/feature-scope.sandbox';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasFeatureAccess]',
})
export class HasFeatureAccessDirective implements OnInit {
    private authorizedFeatureScopes: FeatureScope[];
    private featureScopes: FeatureScope[] = [];
    private logicalOperation: LogicalOperation = LogicalOperation.And;
    private isHidden = true;

    constructor(
        private readonly element: ElementRef,
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly featureScopeSandbox: FeatureScopeSandbox
    ) {}

    public ngOnInit(): void {
        this.featureScopeSandbox.featureScopesOnce$.subscribe((featureScopes) => {
            this.authorizedFeatureScopes = featureScopes;
            this.updateView();
        });
    }

    @Input()
    set hasFeatureAccess(featureScopes: FeatureScope | FeatureScope[]) {
        this.featureScopes = ArrayUtils.toArray(featureScopes);
        this.updateView();
    }

    @Input()
    set hasFeatureAccessOp(logicalOperation: LogicalOperation) {
        this.logicalOperation = logicalOperation;
        this.updateView();
    }

    private updateView(): void {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission(): boolean {
        if (!this.featureScopes.length) return true;
        if (!this.authorizedFeatureScopes?.length) return false;

        const hasFeature = (featureScope: FeatureScope): boolean =>
            this.authorizedFeatureScopes.some((f) => featureScope.startsWith(f) || f.startsWith(featureScope));
        return this.logicalOperation === LogicalOperation.And ? this.featureScopes.every(hasFeature) : this.featureScopes.some(hasFeature);
    }
}
