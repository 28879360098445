import { Component } from '@angular/core';
import { HeaderService } from '@app/header/services/header.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { MenuItemIconSize, SvgIllustration } from '@smooved/ui';

@Component({
    selector: 'app-metrics-menu',
    templateUrl: './metrics-menu.component.html',
    styles: [':host {display: block; padding: 1rem;}'],
})
export class MetricsMenuComponent {
    public readonly svgIllustration = SvgIllustration;
    public readonly menuItemIconSize = MenuItemIconSize;
    public readonly navigation = Navigation;

    constructor(private readonly headerService: HeaderService) {}

    public onMenuClick(): void {
        this.headerService.dispatch();
    }
}
