import { Component, Input } from '@angular/core';
import { User } from '@app/move/interfaces/user';

@Component({
    selector: 'app-person-extra-details',
    template: `
        <div class="u-flex u-w100p">
            <h6 class="u-margin-bottom-half u-w25p u-color-muted">{{ 'MOVE.USER.PASSPORT_NUMBER.SHORT' | translate }}</h6>
            <app-copy-to-clipboard *ngIf="!!user?.passportNumber; else empty">
                <p>{{ user.passportNumber }}</p>
            </app-copy-to-clipboard>
        </div>
        <div class="u-flex">
            <h6 class="u-margin-bottom-half u-w25p u-color-muted">{{ 'MOVE.USER.BTW_NUMBER.SHORT' | translate }}</h6>
            <app-copy-to-clipboard *ngIf="!!user?.btwNumber; else empty">
                <p>{{ user.btwNumber }}</p>
            </app-copy-to-clipboard>
        </div>
        <div class="u-flex">
            <h6 class="u-margin-bottom-half u-w25p u-color-muted">{{ 'MOVE.USER.ACCOUNT_NUMBER.SHORT' | translate }}</h6>
            <app-copy-to-clipboard *ngIf="!!user?.accountNumber; else empty">
                <p>{{ user.accountNumber }}</p>
            </app-copy-to-clipboard>
        </div>
        <div class="u-flex">
            <h6 class="u-margin-bottom-half u-w25p u-color-muted">{{ 'MOVE.USER.NATIONAL_NUMBER.SHORT' | translate }}</h6>
            <app-copy-to-clipboard *ngIf="!!user?.nationalNumber; else empty">
                <p>{{ user.nationalNumber }}</p>
            </app-copy-to-clipboard>
        </div>
        <ng-template #empty>-</ng-template>
    `,
})
export class PersonExtraDetailsComponent {
    @Input() user: User;
}
