import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Checked } from '@app/ui/components/checked/checked.enum';
import { UiIconAppearance, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-checked',
    template: `
        <div class="u-position-relative u-inline-flex-row u-flex-align-items-center u-flex-justify-content-center" [ngSwitch]="checked">
            <app-icon
                *ngSwitchCase="state.Ok"
                icon="check_circle"
                class="__check-ok"
                [size]="uiIconSize.Xm"
                [appearance]="uiIconAppearance.Default"
                class="u-color-success"
            ></app-icon>
            <app-icon
                *ngSwitchCase="state.Nok"
                icon="check_circle"
                class="__check-nok"
                [size]="uiIconSize.Xm"
                [appearance]="uiIconAppearance.Outlined"
                class="u-color-muted"
            ></app-icon>
            <app-icon
                *ngSwitchCase="state.Locked"
                icon="lock"
                class="__check-locked"
                [size]="uiIconSize.Xm"
                [appearance]="uiIconAppearance.Default"
                class="u-color-muted"
            ></app-icon>
        </div>
    `,
    styleUrls: ['./checked.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckedComponent {
    @Input() public checked: Checked = Checked.Nok;
    public state = Checked;
    public uiIconAppearance = UiIconAppearance;
    public uiIconSize = UiIconSize;
}
