<div
    [class.u-flex-column]="uiDirection === uiDirections.Vertical"
    [class.u-flex-reverse]="uiLabelPosition === uiLabelPositions.After"
    [class.u-flex-row]="uiDirection === uiDirections.Horizontal"
    [ngClass]="{ 'u-margin-bottom-double': hasMarginDouble, 'u-margin-bottom': hasMargin }"
    class="u-w100p"
>
    <label
        *ngIf="!!label"
        [class.u-display-none]="!!labelTemplateRef"
        [class.u-margin-top-xs]="uiDirection === uiDirections.Horizontal"
        [class.u-white-space-no-wrap]="label.length <= isLongText"
        [for]="id"
        [innerHTML]="label"
        [ngClass]="{ '__label--required': showRequiredAsterisk && required, 'u-color-muted': muted, 'u-font-weight-bold': bold }"
        class="__label u-margin-bottom-half"
    >
    </label>
    <ng-container *ngIf="!!labelTemplateRef" [ngTemplateOutlet]="labelTemplateRef"></ng-container>
    <ng-content></ng-content>
</div>
