import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectUtils } from '@smooved/core';
import { consentOption, FormControlNames } from './asset-input.constants';

@Component({
    selector: 'app-file-input-modal',
    templateUrl: './asset-input.modal.html',
})
export class AssetInputModal {
    public file: File;
    public showTermsAndConditions = true;

    public readonly form = this.formBuilder.group({
        consent: this.formBuilder.control(null),
    });

    public readonly consentOption = consentOption;
    public readonly formControlNames = FormControlNames;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly dialogRef: MatDialogRef<AssetInputModal>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (ObjectUtils.isDefined(data?.showTermsAndConditions)) {
            this.showTermsAndConditions = data.showTermsAndConditions;
        }
        if (this.showTermsAndConditions) {
            this.form.get('consent').setValidators([Validators.requiredTrue]);
        }
    }

    public onChange(event): void {
        const file: File = event.target.files[0];
        if (file) {
            this.file = file;
        }
    }

    public upload(): void {
        if (!this.file || !this.form.valid) return;
        const formData = new FormData();
        formData.append('file', this.file, this.file['name']);
        formData.append('consent', this.showTermsAndConditions ? this.form.get(FormControlNames.Consent).value.toString() : true);
        this.dialogRef.close(formData);
    }
}
