import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-contract-move-details-container',
    template: `
        <app-move-details stepEnd="3" [showPrevious]="!hidePreviousBtn" (next)="onSubmit()" (previous)="goToPrevious()"></app-move-details>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailsContainer implements OnInit {
    public hidePreviousBtn = false;

    constructor(private router: Router, private route: ActivatedRoute) {}

    public ngOnInit() {
        if (!this.route.snapshot.queryParams.at) return;
        //if access token is provided, no previous button should be shown
        this.hidePreviousBtn = true;
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../../energy-suggestions'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSubmit(): void {
        this.router
            .navigate(['../contact-details'], {
                relativeTo: this.route,
            })
            .then();
    }
}
