import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { providerNone, providerOptions } from '@app/telecom/constants/telecom.constants';
import { TelecomSuggestionsFilter } from '@app/telecom/interfaces/telecom-suggestions-filter';
import { TelecomOffer } from '@app/wizard/telecom/interfaces/telecom-offer';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { RxjsComponent, skipWhileNull } from '@smooved/core';
import { isBoolean, isNull, isNumber, isString } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { TelecomSandbox } from '../../sandboxes/telecom.sandbox';
import {
    ignorePromotionsOption,
    TelecomSuggestionsFilterForm,
    unlimitedInternetOption,
    unlimitedInternetVolume,
    unlimitedMobileMinutesOption,
} from './telecom-suggestions-filter.constants';

@Component({
    selector: 'app-telecom-suggestions-filter',
    templateUrl: 'telecom-suggestions-filter.component.html',
    styleUrls: ['./telecom-suggestions-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomSuggestionsFilterComponent extends RxjsComponent implements OnInit {
    @Output() public filterChange = new EventEmitter<TelecomSuggestionsFilter>();

    public form = this.formBuilder.group({
        [TelecomSuggestionsFilterForm.UnlimitedInternet]: [null],
        [TelecomSuggestionsFilterForm.WifiSpeed]: [null],
        [TelecomSuggestionsFilterForm.MobileNumberSims]: [null],
        [TelecomSuggestionsFilterForm.MobileData]: [null],
        [TelecomSuggestionsFilterForm.UnlimitedMobileMinutes]: [false],
        [TelecomSuggestionsFilterForm.OtherProvider]: [Provider.None],
        [TelecomSuggestionsFilterForm.IgnorePromotions]: [false],
    });

    public unlimitedInternetOption = unlimitedInternetOption;
    public unlimitedMobileMinutesOption = unlimitedMobileMinutesOption;
    public ignorePromotionsOption = ignorePromotionsOption;

    public wifiSpeedOptions$: Observable<DropdownInput<number>[]> = this.telecomSandbox.telecomSuggestionsFilterOptions$.pipe(
        skip(1),
        filter((telecomSuggestionsFilterOptions) => !!telecomSuggestionsFilterOptions?.wifiSpeed?.length),
        map((telecomSuggestionsFilterOptions) =>
            telecomSuggestionsFilterOptions.wifiSpeed.map((wifiSpeedOption) => {
                return {
                    id: `wifi-speed-${wifiSpeedOption.wifiSpeed}`,
                    value: wifiSpeedOption.wifiSpeed,
                    name: 'wifi-speed',
                    labels: wifiSpeedOption.wifiSpeedLabelLabels,
                };
            })
        )
    );

    public mobileNumberSimsOptions$: Observable<DropdownInput<number>[]> = this.telecomSandbox.telecomSuggestionsFilterOptions$.pipe(
        skip(1),
        filter((telecomSuggestionsFilterOptions) => !!telecomSuggestionsFilterOptions?.mobileNumberSims?.length),
        map((telecomSuggestionsFilterOptions) =>
            telecomSuggestionsFilterOptions.mobileNumberSims.map((mobileNumberSimOption) => ({
                id: `mobile-number-sims-${mobileNumberSimOption}`,
                value: mobileNumberSimOption,
                name: 'mobile-number-sims',
                label: mobileNumberSimOption.toString(),
            }))
        )
    );

    public mobileDataOptions$: Observable<DropdownInput<number>[]> = this.telecomSandbox.telecomSuggestionsFilterOptions$.pipe(
        skip(1),
        filter((telecomSuggestionsFilterOptions) => !!telecomSuggestionsFilterOptions?.mobileData?.length),
        map((telecomSuggestionsFilterOptions) =>
            telecomSuggestionsFilterOptions.mobileData.map((mobileDataOption) => ({
                id: `mobile-data-${mobileDataOption.mobileData}`,
                value: mobileDataOption.mobileData,
                name: 'mobile-data',
                labels: mobileDataOption.mobileDataLabelLabels,
            }))
        )
    );

    public otherProvidersOptions$: Observable<DropdownInput<Provider>[]> = this.moveSandbox.providerChoice$.pipe(
        skipWhileNull(),
        take(1),
        map((providerChoice) => {
            const options = providerOptions.filter((provider) => provider.value !== providerChoice);
            options.unshift(providerNone);
            return options;
        })
    );

    constructor(private formBuilder: UntypedFormBuilder, public moveSandbox: MoveSandbox, public telecomSandbox: TelecomSandbox) {
        super();
    }

    public ngOnInit(): void {
        this.form.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((formValue) => {
            const filter = {
                volume: formValue.unlimitedInternet ? 999 : null,
                otherProvider: formValue.otherProvider || Provider.None,
                wifiSpeed: formValue.wifiSpeed,
                unlimitedMobileMinutes: formValue.unlimitedMobileMinutes,
                mobileNumberSims: formValue.mobileNumberSims,
                mobileData: formValue.mobileData,
                ignorePromotions: formValue.ignorePromotions,
            };

            this.telecomSandbox.setTelecomSuggestionsFilter(filter);
            this.filterChange.emit(filter);
        });

        combineLatest(
            this.moveSandbox.providerChoice$,
            this.otherProviderFormControl().valueChanges.pipe(startWith(this.otherProviderFormControl().value), distinctUntilChanged())
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(([providerChoice, otherProvider]) => {
                this.telecomSandbox.getTelecomSuggestionsFilterOptions(
                    [providerChoice, otherProvider],
                    (telecomSuggestionsFilterOptions) => {
                        const patch: any = {
                            wifiSpeed:
                                !isNull(this.wifiSpeedFormControl()?.value) &&
                                telecomSuggestionsFilterOptions.wifiSpeed.find(
                                    (wifiSpeedOption) => wifiSpeedOption.wifiSpeed === this.wifiSpeedFormControl()?.value
                                )
                                    ? this.wifiSpeedFormControl()?.value
                                    : telecomSuggestionsFilterOptions.wifiSpeed[0]?.wifiSpeed,
                            mobileNumberSims:
                                !isNull(this.mobileNumberSimsFormControl()?.value) &&
                                telecomSuggestionsFilterOptions.mobileNumberSims.find(
                                    (mobileNumberSimsOption) => mobileNumberSimsOption === this.mobileNumberSimsFormControl()?.value
                                )
                                    ? this.mobileNumberSimsFormControl()?.value
                                    : telecomSuggestionsFilterOptions.mobileNumberSims[0],
                            mobileData:
                                !isNull(this.mobileDataFormControl()?.value) &&
                                telecomSuggestionsFilterOptions.mobileData.find(
                                    (mobileDataOption) => mobileDataOption.mobileData === this.mobileDataFormControl()?.value
                                )
                                    ? this.mobileDataFormControl()?.value
                                    : telecomSuggestionsFilterOptions.mobileData[0]?.mobileData,
                        };
                        this.form.patchValue({
                            ...this.form.value,
                            ...patch,
                        });
                    }
                );
            });

        this.moveSandbox.telecomOfferOnce$.subscribe((telecomOffer) => {
            const payload: TelecomOffer = {};
            if (isNumber(telecomOffer.volume)) {
                payload.volume = telecomOffer.volume;
            }
            if (isNumber(telecomOffer.wifiSpeed)) {
                payload.wifiSpeed = telecomOffer.wifiSpeed;
            }
            if (isNumber(telecomOffer.mobileNumberSims)) {
                payload.mobileNumberSims = telecomOffer.mobileNumberSims;
            }
            if (isNumber(telecomOffer.mobileData)) {
                payload.mobileData = telecomOffer.mobileData;
            }
            if (isBoolean(telecomOffer.unlimitedMobileMinutes)) {
                payload.unlimitedMobileMinutes = telecomOffer.unlimitedMobileMinutes;
            }
            if (isString(telecomOffer.otherProvider)) {
                payload.otherProvider = telecomOffer.otherProvider;
            }
            if (isBoolean(telecomOffer.ignorePromotions)) {
                payload.ignorePromotions = telecomOffer.ignorePromotions;
            }
            if (Object.keys(payload)?.length) {
                this.form.patchValue({
                    ...payload,
                    unlimitedInternet: payload.volume ? true : null,
                });
            }
        });
    }

    public ngOnDestroy(): void {
        const payload: TelecomOffer = {};

        if (isNumber(this.wifiSpeedFormControl()?.value)) {
            payload.wifiSpeed = this.wifiSpeedFormControl().value;
        }
        if (isNumber(this.mobileNumberSimsFormControl()?.value)) {
            payload.mobileNumberSims = this.mobileNumberSimsFormControl().value;
        }
        if (isNumber(this.mobileDataFormControl()?.value)) {
            payload.mobileData = this.mobileDataFormControl().value;
        }
        if (isBoolean(this.unlimitedMobileMinutesFormControl()?.value)) {
            payload.unlimitedMobileMinutes = this.unlimitedMobileMinutesFormControl().value;
        }
        if (isString(this.otherProviderFormControl()?.value) && !!this.otherProviderFormControl()?.value.length) {
            payload.otherProvider = this.otherProviderFormControl().value;
        }

        if (isBoolean(this.ignorePromotionsFormControl()?.value)) {
            payload.ignorePromotions = this.ignorePromotionsFormControl().value;
        }

        if (this.form.get(TelecomSuggestionsFilterForm.UnlimitedInternet)?.value) {
            payload.volume = unlimitedInternetVolume;
        }

        if (Object.keys(payload)?.length) {
            this.moveSandbox.patchProperty('telecomOffer', payload, true);
        }

        super.ngOnDestroy();
    }

    public wifiSpeedFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.WifiSpeed);
    }

    private mobileNumberSimsFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.MobileNumberSims);
    }

    private mobileDataFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.MobileData);
    }

    private unlimitedMobileMinutesFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.UnlimitedMobileMinutes);
    }

    private otherProviderFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.OtherProvider);
    }

    private ignorePromotionsFormControl(): AbstractControl {
        return this.form?.get(TelecomSuggestionsFilterForm.IgnorePromotions);
    }
}
