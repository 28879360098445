import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MeterReadingsOutputService } from '@app/meter-readings/components/meter-readings-output/meter-readings-output.service';
import { METER_READINGS_OUTPUT_CONFIG } from '@app/meter-readings/constants/meter-readings.constants';
import { MeterReadingsContainer } from '@app/meter-readings/interfaces/meter-readings-container';
import { MeterReadingsOutputConfig } from '@app/meter-readings/interfaces/meter-readings-output-config';
import { Move } from '@app/move/interfaces/move';
import { SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, Svg } from '@smooved/ui';

@Component({
    selector: 'app-meter-readings-output',
    templateUrl: './meter-readings-output.component.html',
    styleUrls: ['./meter-readings-output.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterReadingsOutputComponent implements OnChanges {
    @Input() public move: Move;

    public readonly svg = Svg;
    public readonly buttonAppearance = ButtonAppearance;

    public containers: MeterReadingsContainer[] = [];

    constructor(
        public readonly meterReadingsService: MeterReadingsOutputService,
        @Inject(METER_READINGS_OUTPUT_CONFIG) public config: MeterReadingsOutputConfig
    ) {}

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.containers = this.meterReadingsService.getContainers(this.config);
        }
    }
}
