<app-closable-modal-template #modal>
    <ng-container modal-header>
        <h5>{{ 'RENTAL_INSPECTION.TODO.DOCUMENT.CTA' | translate }}</h5>
    </ng-container>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="u-margin-bottom-triple">
            <app-asset-input width="auto" [hasMargin]="false" [formControlName]="formControlNames.rentalInspectionAsset"></app-asset-input>
        </div>
        <div class="u-text-align-right">
            <button class="u-margin-right-half" (click)="modal.close()" app-button appearance="link">{{ 'CANCEL' | translate }}</button>
            <button app-button [isLoading]="loading" [disabled]="loading" [context]="uiContext.Secondary" type="submit">
                {{ 'UPLOAD' | translate }}
            </button>
        </div>
    </form>
</app-closable-modal-template>
