<app-closable-modal-template maxWidth="none">
    <ng-container modal-header>
        <h5>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.EDIT.TITLE' | translate }}</h5>
    </ng-container>
    <app-loading-container [loading]="!move">
        <form (ngSubmit)="onSubmit()" *ngIf="move" [formGroup]="form">
            <div class="u-margin-bottom-double u-padding-bottom u-bordered-bottom">
                <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.USER.MOVING_ADDRESS' | translate }}</h6>
                <div class="__move-edit-modal__data">
                    <div class="u-flex-column">
                        <app-address
                            [formControlName]="formControlName.MovingAddress"
                            [optional]="true"
                            [showLabels]="true"
                            [showPlaceholders]="false"
                            [small]="true"
                            class="u-margin-bottom"
                        ></app-address>
                    </div>

                    <div class="u-flex-column">
                        <app-moving-date-input
                            [formControlName]="formControlName.MovingDate"
                            [label]="'MOVE.MOVING_DATE_SUSPECTED' | translate"
                        ></app-moving-date-input>

                        <app-late-moving-date-red-notification
                            [context]="uiContext.Text"
                            [createdOn]="move.createdOn"
                            [movingDate]="movingDate"
                        ></app-late-moving-date-red-notification>
                    </div>
                </div>
            </div>

            <div [class.u-bordered-bottom]="!!moveLeaver" class="u-margin-bottom-double u-padding-bottom">
                <h6 class="u-margin-bottom-half u-color-muted">{{ 'TRANSFEREE' | i18nKeyTranslate: i18nKeyType.MoverRole }}</h6>
                <div class="__move-edit-modal__data">
                    <div class="u-flex-column">
                        <div class="__move-edit-modal__form-group">
                            <app-text-input
                                [formControlName]="formControlName.FirstName"
                                [label]="'UI.FIRST_NAME.LABEL' | translate"
                                width="auto"
                            ></app-text-input>
                            <app-text-input
                                [formControlName]="formControlName.LastName"
                                [label]="'UI.LAST_NAME.LABEL' | translate"
                                width="auto"
                            ></app-text-input>
                        </div>
                        <div class="__move-edit-modal__form-group">
                            <app-email-input
                                [formControlName]="formControlName.Email"
                                [hasMarginDouble]="false"
                                [hasMargin]="false"
                                [label]="'EMAIL' | translate"
                                width="auto"
                            ></app-email-input>
                            <app-text-input
                                [formControlName]="formControlName.PhoneNumber"
                                [label]="'PHONE_NUMBER' | translate"
                                width="auto"
                            ></app-text-input>
                        </div>
                        <div class="__move-edit-modal__form-group">
                            <app-date-input
                                [formControlName]="formControlName.DateOfBirth"
                                [label]="'DATE_OF_BIRTH' | translate"
                                [maxDate]="maxDateOfBirth"
                                id="date-of-birth"
                                width="auto"
                            ></app-date-input>
                            <ui-dropdown-input
                                [formControlName]="formControlName.Gender"
                                [label]="'GENDER' | translate"
                                [options]="genderOptions"
                                id="gender"
                                width="auto"
                            ></ui-dropdown-input>
                        </div>
                        <div class="_fields">
                            <ui-dropdown-input
                                [formControlName]="formControlName.Language"
                                [hasMarginDouble]="false"
                                [hasMargin]="false"
                                [label]="'LANGUAGE.LABEL' | translate"
                                [options]="languageOptions"
                                width="auto"
                            ></ui-dropdown-input>
                        </div>
                    </div>
                    <div class="u-flex-column">
                        <div class="__move-edit-modal__form-group">
                            <app-national-number-input
                                [formControlName]="formControlName.NationalNumber"
                                width="auto"
                            ></app-national-number-input>
                            <app-account-number-input
                                [formControlName]="formControlName.AccountNumber"
                                [label]="'ACCOUNT_NUMBER' | translate"
                                id="account-number"
                                width="auto"
                            ></app-account-number-input>
                        </div>
                        <div class="__move-edit-modal__form-group">
                            <app-text-input
                                [formControlName]="formControlName.BtwNumber"
                                [label]="'MOVE.USER.BTW_NUMBER' | translate"
                                id="btw-number"
                                width="auto"
                            ></app-text-input>
                            <app-passport-number-input
                                [formControlName]="formControlName.PassportNumber"
                                width="auto"
                            ></app-passport-number-input>
                        </div>
                        <div *ngIf="!!move?.professional" class="__move-edit-modal__form-group">
                            <app-text-input
                                [formControlName]="formControlName.CompanyName"
                                [label]="'COMPANY_NAME' | i18nKeyTranslate: appI18nKeyType.CompanyDetails"
                                id="company-name"
                                width="auto"
                            ></app-text-input>
                            <app-text-input
                                [formControlName]="formControlName.VatNumber"
                                [label]="'COMPANY_NUMBER' | i18nKeyTranslate: appI18nKeyType.CompanyDetails"
                                id="vat-number"
                                width="auto"
                            ></app-text-input>
                            <ui-dropdown-input
                                [formControlName]="formControlName.LegalForm"
                                [label]="'LEGAL_FORM.LABEL' | i18nKeyTranslate: appI18nKeyType.CompanyDetails"
                                [options]="legalFormOptions"
                                id="legal-form"
                                width="auto"
                            ></ui-dropdown-input>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!!moveLeaver" class="u-margin-bottom-double">
                <h6 class="u-margin-bottom-half u-color-muted">{{ 'LEAVER' | i18nKeyTranslate: i18nKeyType.MoverRole }}</h6>
                <div class="__move-edit-modal__data">
                    <div class="u-flex-column">
                        <div class="__move-edit-modal__form-group">
                            <app-text-input
                                [formControlName]="formControlName.FirstNameLeaver"
                                [label]="'UI.FIRST_NAME.LABEL' | translate"
                                width="auto"
                            ></app-text-input>
                            <app-text-input
                                [formControlName]="formControlName.LastNameLeaver"
                                [label]="'UI.LAST_NAME.LABEL' | translate"
                                width="auto"
                            ></app-text-input>
                        </div>
                        <div class="_fields">
                            <ui-dropdown-input
                                [formControlName]="formControlName.LanguageLeaver"
                                [hasMarginDouble]="false"
                                [hasMargin]="false"
                                [label]="'LANGUAGE.LABEL' | translate"
                                [options]="languageOptions"
                                width="auto"
                            ></ui-dropdown-input>
                        </div>
                    </div>
                    <div class="u-flex-column">
                        <div class="__move-edit-modal__form-group">
                            <app-text-input
                                [formControlName]="formControlName.PhoneNumberLeaver"
                                [label]="'PHONE_NUMBER' | translate"
                                width="auto"
                            ></app-text-input>
                            <app-email-input
                                [formControlName]="formControlName.EmailLeaver"
                                [hasMarginDouble]="false"
                                [hasMargin]="false"
                                [label]="'EMAIL' | translate"
                                width="auto"
                            ></app-email-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="u-w100p u-text-align-right">
                <app-previous-submit
                    (cancel)="onCancel()"
                    [showCancel]="true"
                    [showPrevious]="false"
                    submitTranslationLabel="SAVE"
                ></app-previous-submit>
            </div>
        </form>
    </app-loading-container>
</app-closable-modal-template>
