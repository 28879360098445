import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { EventArg, EventName } from '@smooved/core';
import { AnimationUtils } from '../animations';
import { UiAlignment, UiContext, UiPlacement, UiSize } from '../ui.enums';
import { defaultScreenPadding } from './tooltip.constants';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    animations: [AnimationUtils.fade('fade', 200)],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit {
    @ViewChild('tooltipArrow') public tooltipArrow: ElementRef;
    @ViewChild('tooltipBody') public tooltipBody: ElementRef;
    @Input() public label: string;
    @Input() public sub: string;
    @Input() public context = UiContext.Success;
    @Input() public templateRef: TemplateRef<unknown>;
    @Input() public templateOutletContext: object;
    @Input() public placement = UiPlacement.Top;
    @Input() public alignment: UiAlignment;
    @Input() public hidePointer: boolean;
    @Input() public tooltipClasses: string;
    @Input() public paddingSize = UiSize.Md;
    @Input() public hasDistanceFromElement = true;

    @HostBinding('style.left.px') public positionX: number;
    @HostBinding('style.top.px') public positionY: number;

    @HostBinding('class') public get class(): string {
        const position = [this.placement, this.alignment].filter((x) => !!x);
        return `${position.join('-')} ${this.hidePointer ? 'no-pointer' : ''}`;
    }

    @HostListener(EventName.Click, [EventArg.$Event]) onClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    constructor(
        private readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly cdr: ChangeDetectorRef
    ) {}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.keepOnScreenX();
            this.fixArrowSeparation();
            this.cdr.detectChanges();
        });
    }

    /**
     * Reposition tooltip when overflowing screen left or right
     */
    private keepOnScreenX(): void {
        const tooltipRect = (this.el.nativeElement as HTMLElement).getBoundingClientRect();
        const tooltipRightX = +tooltipRect.x + +tooltipRect.width;
        const boundLeft = defaultScreenPadding;
        const boundRight = window.outerWidth - defaultScreenPadding;

        if (tooltipRect.x < boundLeft) {
            const overflowLeft = boundLeft - tooltipRect.x;
            this.positionX = this.positionX + overflowLeft;
            this.renderer.setStyle(this.el.nativeElement, 'left', `${this.positionX}px`);
            if (this.tooltipArrow) this.renderer.setStyle(this.tooltipArrow.nativeElement, 'transform', `translateX(-${overflowLeft}px)`);
        } else if (tooltipRightX > boundRight) {
            const overflowRight = tooltipRightX - boundRight;
            this.positionX = this.positionX - overflowRight;
            this.renderer.setStyle(this.el.nativeElement, 'left', `${this.positionX}px`);
            if (this.tooltipArrow) this.renderer.setStyle(this.tooltipArrow.nativeElement, 'transform', `translateX(${overflowRight}px)`);
        }
    }

    /**
     * Fix tooltip body height. Ensure height is an intiger value to avoid a small separation line between body and arrow
     */
    private fixArrowSeparation(): void {
        const tooltipRect = (this.el.nativeElement as HTMLElement).getBoundingClientRect();
        const fixedHeight = Math.round(tooltipRect.height);
        this.renderer.setStyle(this.tooltipBody.nativeElement, 'height.px', fixedHeight);
    }
}
