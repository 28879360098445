import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ElementUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { ApplicationMode } from '../../../application';
import { UiContext } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { SkeletonHeaderAppearance, skeletonHeaderClassPrefix } from './skeleton.constants';
import { SkeletonService } from './skeleton.service';

@Component({
    selector: 'ui-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent extends RxjsComponent implements OnChanges {
    @Input() public applicationMode: ApplicationMode = ApplicationMode.Default;
    @Input() public smoovedLogo = true;
    @Input() public headerAppearance = SkeletonHeaderAppearance.Default;

    public readonly uiContext = UiContext;
    public readonly skeletonHeaderAppearance = SkeletonHeaderAppearance;
    public readonly isTabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$;
    public readonly hasToolbar$ = this.skeletonService.hasToolbar$;

    public isDraft = false;

    private backgroundSheetElement: HTMLDivElement;

    constructor(
        private skeletonService: SkeletonService,
        private readonly el: ElementRef,
        public readonly uiSandbox: UiSandbox,
        private readonly renderer: Renderer2,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        super();
    }

    public ngOnChanges({ applicationMode, headerAppearance }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(applicationMode)) {
            this.isDraft = applicationMode?.currentValue === ApplicationMode.Draft;
        }

        if (SimpleChangesUtils.hasChanged(headerAppearance)) {
            const classToRemove = ElementUtils.getElementClassList(this.el, skeletonHeaderClassPrefix);
            classToRemove.forEach((c) => this.renderer.removeClass(this.el.nativeElement, c));
            this.renderer.addClass(this.el.nativeElement, `${skeletonHeaderClassPrefix}-${this.headerAppearance}`);
        }

        this.skeletonService.hasBackgroundSheet$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
            config ? this.createBackgroundSheet(config) : this.destroyBackgroundSheet();
        });
    }

    public onClose(): void {
        window.close();
    }

    private createBackgroundSheet(config?: { classNames: string[]; height: string }): void {
        if (this.backgroundSheetElement) this.destroyBackgroundSheet();
        this.backgroundSheetElement = this.renderer.createElement('div') as HTMLDivElement;
        this.backgroundSheetElement.classList.add(...config.classNames);
        this.renderer.setStyle(this.backgroundSheetElement, 'z-index', -1);
        this.renderer.setStyle(this.backgroundSheetElement, 'height', config.height);
        this.renderer.appendChild(this.document.body, this.backgroundSheetElement);
    }

    public destroyBackgroundSheet(): void {
        this.backgroundSheetElement?.remove();
    }
}
