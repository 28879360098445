import { Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-telecom-ordered-by-mover',
    template: `
        <app-check-item [checked]="!!moveSandbox.telecomOrderedSuccess(move)">
            <ng-container ngProjectAs="checked">
                <div>
                    {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.ORDERED' | translate: { name: move | userName } }}
                    <a
                        (click)="removeConfirmationModal()"
                        [class.u-disabled]="!!move?.telecomOrderedByAdmin"
                        class="u-link u-display-block"
                        >{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.REMOVE_ORDERED.LABEL' | translate }}</a
                    >
                </div>
            </ng-container>
            <ng-container ngProjectAs="unchecked">
                {{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.NOT_ORDERED' | translate }}
            </ng-container>
        </app-check-item>
    `,
})
export class TelecomOrderedByMoverComponent extends BaseServiceActionComponent {
    constructor(
        public readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        super(null, moveSandbox);
    }

    public removeConfirmationModal(): void {
        const data = {
            data: this.translateService.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.REMOVE_ORDERED.CONFIRM'),
        };
        this.modalSandbox.openConfirmModal(data, this.handleConfirmRemoveOrdered, data, this.handleConfirmRemoveOrdered);
    }

    private handleConfirmRemoveOrdered = (confirm: boolean) => {
        if (!confirm) return;

        this.moveSandbox.patchProperty(
            'telecomOrdered',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    };
}
