import { Injectable } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionKeys, failure, OrderCreateRequest, success } from './order-create.actions';
import { OrderCreateService } from './order-create.service';

@Injectable()
export class OrderCreateEffects {
    public orderCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionKeys.Request),
            switchMap((request: OrderCreateRequest) =>
                this.orderCreateService.execute(request.order).pipe(
                    map((data: Order) => success({ data })),
                    catchError((error) => of(failure({ error })))
                )
            )
        )
    );

    constructor(private readonly actions$: Actions, private readonly orderCreateService: OrderCreateService) {}
}
