import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-expected-moving-date-detail',
    template: `
        <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.USER.EXPECTED_MOVING_DATE' | translate }}</h6>
        <span *ngIf="!!move?.linkedMove?.formattedMovingDate; else noMovingDate">{{ move?.linkedMove?.formattedMovingDate }}</span>

        <ng-template #noMovingDate>
            <span class="u-color-muted u-opacity-50">{{ 'UNKNOWN' | translate }}</span>
        </ng-template>
    `,
    styles: [':host {display:block;}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpectedMovingDateDetailComponent {
    @Input() move: Move;
}
