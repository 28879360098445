<app-card-container class="u-flex-row u-flex-justify-content-center">
    <div class="u-container">
        <app-card class="u-flex-column u-flex-align-items-start">
            <h3>{{ 'CONTACT_DETAILS_LEAVER' | translate }}</h3>
            <p class="u-color-muted u-margin-bottom-triple">{{ 'STEP_INDICATION' | translate : {start: 2, end: 2} }}</p>

            <app-late-moving-date-red-notification
                [context]="uiContext.Danger"
                [createdOn]="today"
                [movingDate]="movingDate"
            ></app-late-moving-date-red-notification>

            <form (ngSubmit)="onSubmit()" [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p">
                <app-select-input
                    [custom]="false"
                    [formControlName]="leaverDetailsForm.LeaverType"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'LEAVER_TYPE.LABEL' | translate"
                    [options]="leaverTypeOptions"
                ></app-select-input>

                <app-email-input [formControlName]="leaverDetailsForm.Email"
                                 [label]="'EMAIL' | translate"></app-email-input>

                <app-text-input
                    [formControlName]="leaverDetailsForm.FirstName"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'UI.FIRST_NAME.LABEL' | translate"
                ></app-text-input>

                <app-text-input
                    [formControlName]="leaverDetailsForm.LastName"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'UI.LAST_NAME.LABEL' | translate"
                ></app-text-input>

                <app-phone-input
                    [formControlName]="leaverDetailsForm.PhoneNumber"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'PHONE_NUMBER' | translate"
                ></app-phone-input>

                <ui-dropdown-input
                    [formControlName]="leaverDetailsForm.Language"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'LANGUAGE.LABEL' | translate"
                    [options]="languageOptions"
                    id="language"
                ></ui-dropdown-input>

                <app-check-input
                    [custom]="false"
                    [formControlName]="leaverDetailsForm.RealEstateAgentConsent"
                    [hasMarginDouble]="false"
                    [hasMargin]="true"
                    [option]="realEstateAgentConsentOption"
                    class="u-mw100p"
                    width="auto"
                ></app-check-input>

                <smvd-app-takeover-information-consent
                    *ngIf="form.get(leaverDetailsForm.MeterReadingsTakeover)"
                    [context]="uiContext.None"
                    [formControlName]="leaverDetailsForm.MeterReadingsTakeover"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    class="u-display-block"
                    width="auto"
                ></smvd-app-takeover-information-consent>

                <a
                    (click)="openMeterInfo()"
                    *ngIf="form.get(leaverDetailsForm.MeterReadingsTakeover)"
                    [appearance]="buttonAppearance.Link"
                    [size]="buttonSize.Sm"
                    app-button
                    class="u-margin-bottom u-margin-left-half"
                >{{ 'VIEW_METER_READINGS' | translate }}</a
                >
                <app-previous-submit (previous)="goToPrevious()" (skipStep)="onSkipStep()"
                                     [showSkipStep]="true"></app-previous-submit>
            </form>
        </app-card>
    </div>
</app-card-container>
