// WARNING: if you change values this will have impact on tag manager configuration as well!!!!
export enum AnalyticsEventsEnum {
    ReviewsInsightsFilterChanged = 'reviews_insights_filter_changed',
    ReviewsInsightsLocationChanged = 'reviews_insights_location_changed',
    ReviewsInsightsAnalysisOpened = 'reviews_insights_analysis_opened',
    ReviewsInsightsAnalysisClosed = 'reviews_insights_analysis_closed',
    ReviewsInsightsHMCardtopicClicked = 'reviews_insights_hm_card_topic_clicked',
    ReviewDownloadClicked = 'review_download_clicked',
    ReaWaterAddExtraDataOpen = 'rea_water_add_extra_data_open',
    ReaWaterAddExtraDataSubmit = 'rea_water_add_extra_data_submit',
}
