import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MeterInfoBaseComponent } from '@app/energy/components/meter-info-base/meter-info-base.component';
import { MeterInfoElectricityComponent } from '@app/energy/components/meter-info-electricity/meter-info-electricity.component';
import { Move } from '@app/move/interfaces/move';
import { Movers } from '@app/move/interfaces/movers';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { ObjectUtils } from '@smooved/core';

@Component({
    selector: 'app-mobile-meter-info-electricity-modal',
    template: `
        <ng-container
            *ngIf="{ move: (move$ | async), isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async) } as vm"
        >
            <app-mobile-modal [mainHasPaddingTop]="false">
                <div mobile-modal-header>
                    <h6>{{ 'ELECTRICITY' | translate }}</h6>
                    <span class="u-color-muted u-font-size-small">{{ vm.move?.user?.formattedMovingAddressWithoutCountry }}</span>
                </div>
                <div mobile-modal-main class="u-padding u-background-white">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <app-meter-info-electricity
                            layout="vertical"
                            class="u-margin-bottom"
                            [showTitle]="false"
                            [disabled]="data.readOnly"
                            *ngIf="!!vm.move"
                            [move]="vm.move"
                            (formChange)="checkTakeOverInformation()"
                        ></app-meter-info-electricity>

                        <ng-container
                            *ngIf="{ meterReadingsTakeover: vm.move?.moveStates.meterReadingsTakeoverByRealEstateAgent } as vmodel"
                        >
                            <app-meter-readings-takeover-done
                                [meterReadingsTakeoverByRealEstateAgent]="vmodel.meterReadingsTakeover"
                                [leaver]="movers?.leaver"
                            ></app-meter-readings-takeover-done>
                            <smvd-app-takeover-information-consent
                                *ngIf="showTakeOverConsent$ | async"
                                width="auto"
                                formControlName="meterReadingsTakeover"
                                [movers]="movers"
                                [hide]="disableTakeOverConsent$ | async"
                                class="u-margin-bottom u-display-block"
                            ></smvd-app-takeover-information-consent>
                        </ng-container>

                        <button smvd-ui-button type="submit" *ngIf="!data.readOnly" class="u-display-block u-margin-bottom u-w100p">
                            {{ 'SAVE' | translate }}
                        </button>
                    </form>
                </div>
            </app-mobile-modal>
        </ng-container>
    `,
})
export class MobileMeterInfoElectricityComponent extends MeterInfoBaseComponent implements OnInit {
    @ViewChild(MeterInfoElectricityComponent) public meterInfoElectricityComponent: MeterInfoElectricityComponent;

    public movers: Movers;
    public form: UntypedFormGroup = this.formBuilder.group({
        meterReadingsTakeover: false,
    });

    protected meterReadingsTakeOverControl = this.form.get('meterReadingsTakeover');

    constructor(
        private readonly uiSandbox: AppUiSandbox,
        private readonly formBuilder: FormBuilder,
        protected readonly moveSandbox: MoveSandbox,
        public readonly dialogRef: MatDialogRef<any>,
        public readonly authenticationSandbox: AuthenticationSandbox,
        @Inject(MAT_DIALOG_DATA) public readonly data: ModalDataMove
    ) {
        super(moveSandbox, authenticationSandbox);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.updateMove(this.data.move);
        this.movers = MoveUtils.getMovers(this.data.move);
    }

    public onSubmit(): void {
        this.takeoverInformationConsentComponent?.markAllAsTouched();
        if (this.meterReadingsTakeOverControl.invalid) return;
        this.uiSandbox.showLoadingOverlay();
        if (!this.takeoverInformationConsentComponent) {
            this.patchMove();
        } else {
            this.takeoverInformationConsentComponent.persistInformation().subscribe(() => {
                this.patchMove();
            });
        }
    }

    public isDirty(): boolean {
        return this.meterReadingsTakeOverControl.dirty || this.meterInfoElectricityComponent?.form?.dirty;
    }

    /**
     * We will always return false here, since there is no way to upload assets via this 'page'
     * @protected
     */
    protected hasAssets(): boolean {
        return false;
    }

    protected dataTakeOverInformationFactory(): Partial<Move> {
        return ObjectUtils.merge2Objects(this.data.move, this.getPatchData());
    }

    protected getPatchData(): Partial<Move> {
        return { ...super.getPatchData(), ...(this.meterInfoElectricityComponent?.createPatch() || {}) };
    }

    private patchMove(): void {
        this.moveOnce$.subscribe((move) => {
            this.moveSandbox.patchProperty(
                '',
                this.getPatchData(),
                true,
                (updatedMove: Move) => {
                    this.uiSandbox.hideLoadingOverlay();
                    this.updateMove(updatedMove);
                    this.dialogRef.close(updatedMove);
                },
                false,
                move,
                true
            );
        });
    }
}
