<ng-container *ngIf="icon">
    <ng-container *ngIf="isEmojiIcon; else fontAwesomeIcon">
        <img [src]="'assets/images/' + icon + '.png'" [width]="width" [height]="height" />
    </ng-container>

    <ng-template #fontAwesomeIcon>
        <ng-container *ngIf="isFontAwesomeIcon; else matIcon">
            <i
                aria-hidden="true"
                [attr.aria-label]="icon"
                [class]="classNames"
                [class.icon-spinning]="spinning"
                [class.icon-round]="round"
            ></i>
        </ng-container>
    </ng-template>

    <ng-template #matIcon>
        <i
            aria-hidden="true"
            [attr.aria-label]="icon"
            [class]="classNames"
            [class.icon-spinning]="spinning || loading"
            [class.icon-round]="round"
            [attr.icon]="icon"
        ></i>
    </ng-template>
</ng-container>
