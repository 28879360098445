import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class SiblingValidators {
    public static equals(formControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.value) return null;
            const valid = control.value === control.parent.get(formControlName)?.value;
            return !valid ? { equals: true } : null;
        };
    }

    public static notEquals(formControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.value) return null;
            const valid = control.value !== control.parent.get(formControlName)?.value;
            return !valid ? { notEquals: { targetValue: control.parent.get(formControlName)?.value } } : null;
        };
    }

    public static requiredIfValidSibling(formControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            const sibling = control.parent?.get(formControlName);
            if (sibling?.invalid || !sibling?.value) return null;
            return !control.value ? { required: true } : null;
        };
    }
}
