import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryEnum } from '@shared/review/enums/category.enum';
import { SentimentEnum } from '@shared/review/enums/sentiment.enum';
import { TopicSentimentResult } from '@shared/review/interfaces/topic-sentiment';
import { SvgIllustration } from '../../../svg';

@Component({
    selector: 'ui-review-topic-sentiment-pill',
    templateUrl: './review-topic-sentiment-pill.component.html',
    styleUrls: ['./review-topic-sentiment-pill.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewTopicSentimentPillComponent {
    @Input() public topicSentiment: TopicSentimentResult;
    @Input() @HostBinding('class.__clickable') public clickable? = false;
    @Output() public pillClicked = new EventEmitter<{ topic: CategoryEnum; sentiment: SentimentEnum }>();
    public readonly svgs = SvgIllustration;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    public goToTopicInsights(topic: CategoryEnum, sentiment: SentimentEnum) {
        this.pillClicked.emit({ topic, sentiment });
        this.router
            .navigate(['../reviews/insights'], {
                queryParams: {
                    topic,
                    sentiment: sentiment === SentimentEnum.Positive ? 1 : 2,
                },
                relativeTo: this.activatedRoute,
            })
            .then();
    }
}
