import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { differentAddress } from '@app/energy-transfer/meter-readings/containers/meter-readings-current-supplier/meter-readings-current-supplier.constants';
import { EnergyStopSuppliersFormComponent } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.component';
import { EnergyStopForm } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.constants';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import { Address, ArrayUtils, DbUtils, HttpUtils, skipWhileEmpty } from '@smooved/core';
import { UiContext } from '@smooved/ui';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { formControlNames } from './update-leaver-suppliers.constants';

@Component({
    selector: 'app-update-leaver-suppliers-modal',
    templateUrl: './update-leaver-suppliers.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UpdateLeaverSuppliersModal implements OnInit {
    @ViewChild(EnergyStopSuppliersFormComponent) suppliersForm: EnergyStopSuppliersFormComponent;

    @Input() public leaver: Move;
    @Input() public transferee: Move;

    public form: UntypedFormGroup;
    public readonly formControlNames = formControlNames;
    public readonly i18nKeys = AppI18nKeyType;
    public readonly uiContext = UiContext;
    public readonly differentAddressControl = this.fb.control(null);
    public readonly differentAddress = differentAddress;
    public readonly buttonLoading$ = this.uiSandbox.patchLoading$;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly dialogRef: MatDialogRef<UpdateLeaverSuppliersModal>
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            [formControlNames.suppliers]: [
                { energyStop: this.leaver.energyStop, movingDate: this.transferee.movingDate },
                Validators.required,
            ],
            [formControlNames.contactAddress]: this.leaver.user?.contactAddress,
        });

        this.differentAddressControl.patchValue(!!this.form.get(formControlNames.contactAddress).value);
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            this.suppliersForm.form.markAllAsTouched();
            this.form.markAllAsTouched();
            return;
        }

        const { energyStop, movingDate } = this.form.get(formControlNames.suppliers).value as EnergyStopForm;
        const contactAddress = this.form.get(formControlNames.contactAddress).value as Address;

        this.uiSandbox.showLoadingOverlay();
        this.updateMovingDate(movingDate, () => {
            this.uploadInvoices(this.suppliersForm.files.electricity, this.suppliersForm.files.gas, () => {
                this.updateEnergyStop(energyStop, contactAddress, () => {
                    this.uiSandbox.hideLoadingOverlay();
                    this.onMovePatched();
                });
            });
        });
    }

    private updateMovingDate(movingDate: Date, callback?: () => void): void {
        if (movingDate === this.transferee.movingDate) {
            callback?.();
            return;
        }

        this.moveSandbox.patch({
            moveId: DbUtils.getStringId(this.transferee),
            payload: { movingDate },
            callback,
        });
    }

    private updateEnergyStop(energyStop: Partial<EnergyStop>, contactAddress: Address, callback?: () => void): void {
        this.moveSandbox.patchProperty(
            '',
            {
                energyStop,
                user: { contactAddress },
            },
            true,
            callback,
            false,
            this.leaver,
            true,
            false,
            true
        );
    }

    private uploadInvoices(electricityFiles: File[], gasFiles: File[], callback?: () => void): void {
        const httpCalls = [];

        if (!ArrayUtils.isEmpty(electricityFiles)) {
            httpCalls.push(
                this.moveSandbox.uploadInvoicesAssets(
                    DbUtils.getStringId(this.leaver),
                    EnergyType.Electricity,
                    HttpUtils.addFiles(electricityFiles)
                )
            );
        }

        if (!ArrayUtils.isEmpty(gasFiles)) {
            httpCalls.push(
                this.moveSandbox.uploadInvoicesAssets(DbUtils.getStringId(this.leaver), EnergyType.Gas, HttpUtils.addFiles(gasFiles))
            );
        }

        if (ArrayUtils.isEmpty(httpCalls)) {
            callback?.();
            return;
        }

        zip(...httpCalls).subscribe(() => {
            callback?.();
        });
    }

    private onMovePatched = (): void => {
        this.moveSandbox
            .get(DbUtils.getStringId(this.leaver))
            .pipe(skipWhileEmpty(), take(1))
            .subscribe((move) => this.dialogRef.close(move));
    };
}
