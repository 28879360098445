<app-move-detail-extra-info *ngIf="move.energyStop">
    <ng-container content>
        <app-info-block>
            <app-info-detail [label]="'ENERGY.METER_READING.DATE' | translate">{{
                linkedMove.meterReadingDate | formatDate
            }}</app-info-detail>

            <app-info-detail
                *ngIf="linkedMove.meterReadingDate"
                [label]="'DETAIL.ENERGY.METER_READING_BY' | i18nKeyTranslate: i18nKeys.AdminDashboard"
            >
                <app-copy-to-clipboard>
                    {{
                        linkedMove.meterReadingDateBy === roles.Mover
                            ? (moverRoles.Leaver | i18nKeyTranslate: i18nKeys.MoverRole)
                            : (move.meterReadingDateBy | i18nKeyTranslate: i18nKeys.UserRole)
                    }}
                </app-copy-to-clipboard>
            </app-info-detail>
        </app-info-block>

        <app-info-block [label]="'ELECTRICITY' | translate" *ngIf="hasElectricity">
            <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                <app-copy-to-clipboard>
                    {{ move.energyStop?.currentElectricitySupplier }}
                </app-copy-to-clipboard></app-info-detail
            >
            <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CLIENT_NUMBER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                <app-copy-to-clipboard>{{ move.energyStop?.clientNumberElectricity }}</app-copy-to-clipboard>
            </app-info-detail>

            <app-info-detail [label]="'MOVE.ENERGY.METER' | translate">
                <app-copy-to-clipboard>{{ linkedMove.eanCodeElectricity }}</app-copy-to-clipboard>
            </app-info-detail>

            <ng-container [ngSwitch]="linkedMove.energyOffer.meterType">
                <ng-container *ngSwitchCase="meterTypes.Double">
                    <app-info-detail [label]="'MOVE.ENERGY.READING.DAY' | translate">
                        <app-copy-to-clipboard>{{ linkedMove.electricityDoubleDayMeterReading }}</app-copy-to-clipboard>
                    </app-info-detail>

                    <app-info-detail [label]="'MOVE.ENERGY.READING.NIGHT' | translate">
                        <app-copy-to-clipboard>{{ linkedMove.electricityDoubleNightMeterReading }}</app-copy-to-clipboard>
                    </app-info-detail>
                </ng-container>

                <ng-container *ngSwitchCase="meterTypes.Digital">
                    <app-info-detail
                        *ngIf="linkedMove.energyDigitalMeterReadings?.automatic; else digitalMeterReadings"
                        [label]="'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING' | translate"
                    >
                        <app-copy-to-clipboard>{{ linkedMove.movingDate | formatDate }}</app-copy-to-clipboard>
                    </app-info-detail>
                    <ng-template #digitalMeterReadings>
                        <app-info-detail [label]="'MOVE.ENERGY.CONSUMPTION.DAY' | translate">
                            <app-copy-to-clipboard>{{ linkedMove.energyDigitalMeterReadings?.consumption?.day }}</app-copy-to-clipboard>
                        </app-info-detail>
                        <app-info-detail [label]="'MOVE.ENERGY.CONSUMPTION.NIGHT' | translate">
                            <app-copy-to-clipboard>{{ linkedMove.energyDigitalMeterReadings?.consumption?.night }}</app-copy-to-clipboard>
                        </app-info-detail>
                        <app-info-detail [label]="'ENERGY.INJECTION.DAY' | translate">
                            <app-copy-to-clipboard>{{ linkedMove.energyDigitalMeterReadings?.injection?.day }}</app-copy-to-clipboard>
                        </app-info-detail>
                        <app-info-detail [label]="'ENERGY.INJECTION.NIGHT' | translate">
                            <app-copy-to-clipboard>{{ linkedMove.energyDigitalMeterReadings?.injection?.night }}</app-copy-to-clipboard>
                        </app-info-detail>
                    </ng-template>
                </ng-container>

                <app-info-detail *ngSwitchDefault [label]="'MOVE.ENERGY.READING' | translate">
                    <app-copy-to-clipboard>{{ linkedMove.electricitySingleMeterReading }}</app-copy-to-clipboard>
                </app-info-detail>
            </ng-container>

            <app-info-detail
                *ngIf="!!linkedMove.electricityDoubleExclusiveNightMeterReading"
                [label]="'MOVE.ENERGY.CONSUMPTION.EXCLUSIVE_NIGHT' | translate"
            >
                <app-copy-to-clipboard>{{ linkedMove.electricityDoubleExclusiveNightMeterReading }}</app-copy-to-clipboard>
            </app-info-detail>

            <app-info-detail [label]="'INVOICING.ELECTRICITY.LABEL' | translate">
                <app-asset
                    *ngFor="let file of electricityInvoices"
                    [label]="file.name"
                    [file]="file"
                    (click)="downloadAsset(file)"
                    role="button"
                ></app-asset>
            </app-info-detail>
        </app-info-block>

        <app-info-block [label]="'GAS' | translate" *ngIf="hasGas">
            <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                <app-copy-to-clipboard>{{ move.energyStop?.currentGasSupplier }}</app-copy-to-clipboard>
            </app-info-detail>
            <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CLIENT_NUMBER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                <app-copy-to-clipboard>{{ move.energyStop?.clientNumberGas }}</app-copy-to-clipboard>
            </app-info-detail>

            <app-info-detail [label]="'MOVE.ENERGY.METER' | translate">
                <app-copy-to-clipboard>{{ linkedMove.eanCodeGas }}</app-copy-to-clipboard>
            </app-info-detail>

            <app-info-detail [label]="'MOVE.ENERGY.READING' | translate">
                <app-copy-to-clipboard>{{ linkedMove.gasMeterReading }}</app-copy-to-clipboard>
            </app-info-detail>

            <app-info-detail [label]="'INVOICING.GAS.LABEL' | translate">
                <app-asset
                    *ngFor="let file of gasInvoices"
                    [label]="file.name"
                    [file]="file"
                    (click)="downloadAsset(file)"
                    role="button"
                ></app-asset>
            </app-info-detail>
        </app-info-block>

        <app-info-block
            [label]="'DOCUMENTS' | translate"
            *ngIf="linkedMove.energyMeterReadingAssets?.length || linkedMove.energyDocumentAssets?.length"
        >
            <app-asset
                *ngFor="let file of linkedMove.energyMeterReadingAssets"
                [label]="'METER_READINGS_ENERGY' | translate"
                [file]="file"
                (click)="openMeterReadingAsset(file)"
                role="button"
            ></app-asset>
            <app-asset
                *ngFor="let file of linkedMove.energyDocumentAssets"
                [label]="'AGREEMENT.ENERGY' | translate"
                [file]="file"
                (click)="openEnergyDocumentAsset(file)"
                role="button"
            ></app-asset>
        </app-info-block>
    </ng-container>
</app-move-detail-extra-info>
