import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonAppearance } from '../../../button';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'smvd-ui-short-view',
    templateUrl: 'short-view.component.html',
    styleUrls: ['short-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortViewComponent {
    @Input() public label = 'UI.LOAD_MORE';
    @Input() public shortView = true;
    @Input() public height = '25rem';

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    public showAll(): void {
        this.shortView = false;
    }
}
