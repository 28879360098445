import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { ButtonAppearance } from '@smooved/ui';

@Component({
    selector: 'app-quarter-report-subscription-costs',
    templateUrl: './quarter-report-subscription-costs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterReportSubscriptionCostsComponent implements OnInit {
    @Input() public loading: boolean;
    @Input() public quarterlyInvoicing: QuarterlyInvoicing;
    @Input() public closed: boolean;

    @Output() public typeChange: EventEmitter<InvoicingType> = new EventEmitter<InvoicingType>();

    public readonly invoicingType = InvoicingType;
    public readonly buttonAppearance = ButtonAppearance;

    public ngOnInit(): void {
        this.typeChange.emit(InvoicingType.SubscriptionCosts);
    }
}
