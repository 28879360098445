import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserLoginRequest } from '@app/authentication/interfaces/user-login.request';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { formControlNames } from '@app/partner/constants/login-form.constants';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { switchMap, tap } from 'rxjs/operators';
import { LocalStorageKeys } from '../../../storage/sandboxes/storage.constants';

@Injectable({
    providedIn: 'root',
})
export class LoginSandbox {
    constructor(
        private authenticationSandbox: AuthenticationSandbox,
        private navigationSandbox: AppNavigationSandbox,
        private storageSandbox: StorageSandbox,
        private formBuilder: UntypedFormBuilder
    ) {}

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            [formControlNames.email]: [null, [Validators.required, Validators.email]],
            [formControlNames.password]: [null, [Validators.required]],
        });
    }

    public login(userLoginRequest: UserLoginRequest): void {
        this.authenticationSandbox
            .userLogin(userLoginRequest)
            .pipe(
                tap((_) => this.storageSandbox.deleteToken(LocalStorageKeys.Language)), //delete any cookie language storage in order to get logged in user language
                switchMap((_) => this.authenticationSandbox.authorization())
            )
            .subscribe((authorization) => {
                this.authenticationSandbox.setAuthorization(authorization);
                void this.navigationSandbox.goToPartnerAgentHome();
            });
    }
}
