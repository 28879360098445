import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { FileUtils } from '@smooved/core';

@Component({
    selector: 'app-rental-agreement-assets',
    templateUrl: './rental-agreement-assets.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentalAgreementAssetsComponent {
    @Input() public order: OrderRentalInspection;

    constructor(private readonly orderSandbox: OrderSandbox) {}

    public downloadFile(): void {
        this.order.agreementDocumentAssets.forEach((doc) =>
            this.orderSandbox.getAssetRentalInspectionAgreementDocument(doc.location).subscribe((response) =>
                FileUtils.openAsset({
                    response,
                    type: 'blob',
                })
            )
        );
    }
}
