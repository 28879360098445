import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    menuItemAll,
    menuItemNegative,
    menuItemPositive,
    ReviewsTopicSentimentFilterMenu,
} from '@app/reviews/components/reviews-topics-sentiment-filter/reviews-topics-sentiment-filter.constants';
import { SentimentEnum } from '@shared/review/enums/sentiment.enum';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { MenuItemAppearance, MenuItemSize, NpsReviewsFilterService } from '@smooved/ui';

@Component({
    selector: 'app-reviews-topics-sentiment-filter',
    templateUrl: 'reviews-topics-sentiment-filter.component.html',
})
export class ReviewsTopicsSentimentFilterComponent implements OnInit, OnDestroy {
    @Input() public topicAnalysis: TopicAnalysis;
    public active = ReviewsTopicSentimentFilterMenu.All;

    public readonly menuItemAll = menuItemAll;
    public readonly menuItemPositive = menuItemPositive;
    public readonly menuItemNegative = menuItemNegative;
    public readonly menuItemSizes = MenuItemSize;
    public readonly menuItemAppearance = MenuItemAppearance;

    private readonly queryParamSentiment = 'sentiment';

    constructor(
        private readonly npsReviewsFilterService: NpsReviewsFilterService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {}

    public ngOnInit(): void {
        const defaultSentiment: number = +this.activatedRoute.snapshot.queryParams[this.queryParamSentiment];
        if (defaultSentiment) {
            this.setActive(defaultSentiment);
        }
    }

    public ngOnDestroy(): void {
        this.clearQueryParam(this.queryParamSentiment);
    }

    public setActive(item: ReviewsTopicSentimentFilterMenu): void {
        this.active = item;

        switch (item) {
            case ReviewsTopicSentimentFilterMenu.Positive:
                this.npsReviewsFilterService.filterByTopicCategorySentiment(SentimentEnum.Positive);
                break;
            case ReviewsTopicSentimentFilterMenu.Negative:
                this.npsReviewsFilterService.filterByTopicCategorySentiment(SentimentEnum.Negative);
                break;
            default:
                this.npsReviewsFilterService.filterByTopicCategorySentiment(null);
                break;
        }
    }

    private clearQueryParam(param: string) {
        let queryParams: any = {};
        queryParams[param] = null;

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
