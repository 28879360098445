export const numberOfMonthsPerQuarter = 3;
export const numberOfDaysPerWeek = 7;

// Units of time
export enum TimeUnit {
    Milliseconds = 'ms',
    Seconds = 's',
    Minutes = 'm',
    Hours = 'h',
    Days = 'd',
    Weeks = 'w',
    Months = 'M',
    Quarters = 'Q',
    Years = 'y',
}
