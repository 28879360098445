import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-real-estate-agent-detail',
    template: `
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">{{ 'REAL_ESTATE_AGENT.LABEL' | translate }}</h6>
            <div class="u-flex-column">
                <span class="u-margin-right">{{ move?.realEstateGroup?.name }}</span>
                <app-fee [move]="move" [showPrice]="showPrice" [canEdit]="canEdit"></app-fee>
            </div>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateAgentDetailComponent {
    @Input() public move: Move;
    @Input() public showPrice: boolean;
    @Input() public canEdit: boolean;
}
