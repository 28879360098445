<ng-container *ngIf="{ isTabletPortraitUp: isTabletPortraitUp$ | async } as vm">
    <ng-container *ngIf="vm.isTabletPortraitUp; else mobile">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ng-container>

    <ng-template #mobile>
        <h6 *collapsible="content" class="u-color-primary">{{ office.name }}</h6>
    </ng-template>

    <ng-template #content>
        <div class="__content">
            <div class="__contact u-margin-left-double">
                <h6 *ngIf="vm.isTabletPortraitUp" class="u-margin-bottom-half">{{ office.name }}</h6>
                <section *ngIf="office.phoneNumber || phoneNumber as phoneNumber">
                    <app-icon class="u-margin-right-half" icon="phone" [context]="uiContext.Muted" [size]="iconSize.Md"></app-icon>
                    <a
                        app-button
                        [appearance]="buttonAppearance.Link"
                        [context]="uiContext.Primary"
                        [href]="phoneNumber | uriSchema: type.Tel"
                        class="u-h-auto u-padding-x-axis-none"
                        >{{ phoneNumber }}</a
                    >
                </section>
                <section *ngIf="!!office.address" [class.u-margin-top]="office.phoneNumber || phoneNumber">
                    <app-icon class="u-margin-right-half" icon="place" [context]="uiContext.Muted" [size]="iconSize.Xm"></app-icon>
                    <span class="u-display-inline-block u-vertical-align-top u-white-space-pre-wrap">{{
                        office.address | formatAddress: '\n':false
                    }}</span>
                </section>
            </div>
            <div class="__map u-margin-left-double u-margin-top-double u-margin-top-none-tablet-landscape-up">
                <google-map *ngIf="office.address?.geometry" [options]="mapOptions" width="100%" height="100%">
                    <map-marker [position]="office.address.geometry"></map-marker>
                </google-map>
            </div>
        </div>
    </ng-template>
</ng-container>
