import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { partnerRegisterPasswordRouting } from '@app/authentication/constants/routing.constants';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import * as partnerRegisterConstants from '../../constants/partner-register.constants';

@Component({
    selector: 'app-partner-register-contact-details-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'AUTHENTICATION.PARTNER.REGISTER.CONTACT_DETAILS.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: partnerRegisterConstants.contactDetailsStep,
                                          end: partnerRegisterConstants.stepsTotal
                                      }
                        }}
                    </p>

                    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
                        <app-text-input
                            formControlName="firstName"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.PARTNER.REGISTER.CONTACT_DETAILS.FIRST_NAME' | translate"
                        ></app-text-input>

                        <app-text-input
                            formControlName="lastName"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.PARTNER.REGISTER.CONTACT_DETAILS.LAST_NAME' | translate"
                        ></app-text-input>

                        <app-text-input
                            formControlName="phoneNumber"
                            [autoFocus]="true"
                            [label]="'AUTHENTICATION.PARTNER.REGISTER.CONTACT_DETAILS.PHONE_NUMBER' | translate"
                        ></app-text-input>

                        <app-email-input formControlName="email" [hasMargin]="false" [hasMarginDouble]="true"></app-email-input>

                        <app-previous-submit [showPrevious]="false"></app-previous-submit>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
})
export class PartnerRegisterContactDetailsContainer implements OnInit, OnDestroy {
    public partnerRegisterConstants = partnerRegisterConstants;

    public form: UntypedFormGroup = this.formBuilder.group({
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
    });

    constructor(private formBuilder: UntypedFormBuilder, private userSandbox: UserSandbox, private router: Router) {}

    public ngOnInit(): void {
        this.userSandbox.inviteDataOnceSet$.subscribe((inviteData) => {
            this.form.patchValue(inviteData);
            if (inviteData.confirmationToken) {
                this.form.get('email').disable({ onlySelf: true });
            }
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            void this.router.navigate(partnerRegisterPasswordRouting);
        }
    }

    public ngOnDestroy(): void {
        this.userSandbox.setInviteData(this.form.value);
    }
}
