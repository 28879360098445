import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MaskSandbox } from '@app/shared/sandboxes/mask.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-national-number-input',
    template: `
        <div [ngClass]="{ 'u-margin-bottom-double': hasMarginDouble, 'u-margin-bottom': hasMargin }">
            <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="true" [hasMarginDouble]="false">
                <mat-form-field floatLabel="never" appearance="outline">
                    <input
                        [id]="id"
                        type="text"
                        matInput
                        #input
                        [mask]="maskSandbox.nationalNumber"
                        [showMaskTyped]="true"
                        [clearIfNotMatch]="true"
                        [placeHolderCharacter]="'_'"
                        [(ngModel)]="innerModel"
                        (blur)="onBlur($event)"
                        (ngModelChange)="onModelChange()"
                        [disabled]="innerDisabled"
                        [placeholder]="placeholder"
                        [errorStateMatcher]="errorStateMatcher"
                        autocomplete="smooved"
                    />
                    <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
                </mat-form-field>
            </app-label-container>
        </div>
    `,
    styleUrls: ['./national-number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NationalNumberInputComponent),
            multi: true,
        },
    ],
})
export class NationalNumberInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id = 'national-number';
    @Input() public label: string = this.translateService.instant('MOVE.USER.NATIONAL_NUMBER');
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: string;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private translateService: TranslateService,
        public moveSandbox: MoveSandbox,
        public maskSandbox: MaskSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
