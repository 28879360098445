import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientCanceledContainer } from './containers/canceled/canceled.container';
import { ClientInitContainer } from './containers/init/init.container';
import { ClientLeadPassingContainer } from './containers/lead-passing/lead-passing.container';
import { ClientSuccessContainer } from './containers/success/success.container';

const routes: Routes = [
    {
        path: 'init',
        component: ClientInitContainer,
    },
    {
        path: 'lead-passing',
        component: ClientLeadPassingContainer,
    },
    {
        path: 'success',
        component: ClientSuccessContainer,
    },
    {
        path: 'canceled',
        component: ClientCanceledContainer,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClientRoutingModule {}
