import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnergyMetersInfoModal } from '@app/energy/modals/energy-meters-info/energy-meters-info.modal';
import { MoveTrackEnergyComponent } from '@app/move/components/move-track-energy/move-track-energy.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { MoveDetailWaterSection } from '@app/real-estate-agent/components/move-detail-water-section/move-detail-water-section';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MobileMoveGeneralDetailsComponent } from '@app/real-estate-agent/modals/mobile-move-general-details/mobile-move-general-details.component';
import { TranslateService } from '@ngx-translate/core';
import { I18nKeyService, RxjsComponent, skipWhileEmpty } from '@smooved/core';
import { MenuItemSize, ModalSandbox, UiContext } from '@smooved/ui';
import { ModalClosingData } from 'projects/ui/src/lib/modal/interfaces/modal-closing-data.interface';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MoveDetailInsurancesComponent } from '../move-detail-insurances/move-detail-insurances.component';
import { MoveDetailTelecomComponent } from '../move-detail-telecom/move-detail-telecom.component';
import { MobileMoveOverviewMenuItem } from './mobile-move-overview-menu-item.interface';
import { i18nKeys } from './mobile-move-overview-menu.constants';

@Component({
    selector: 'app-mobile-move-overview',
    templateUrl: './mobile-move-overview.component.html',
})
export class MobileMoveOverviewComponent extends RxjsComponent implements OnInit {
    public menuItemGeneralDetails: MobileMoveOverviewMenuItem;
    public availableServices: MobileMoveOverviewMenuItem[];

    public readonly move$ = this.moveSandbox.move$;
    public readonly columns = ['labels', 'detail'];
    public readonly uiContext = UiContext;
    public readonly menuItemSize = MenuItemSize;
    public readonly fetchMoveEvent = new EventEmitter<string>();
    public readonly menuSize = MenuItemSize;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox,
        public readonly dialogRef: MatDialogRef<any>,
        private readonly i18nKeyService: I18nKeyService,
        @Inject(MAT_DIALOG_DATA) public data: ModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        this.move$.pipe(skipWhileEmpty()).subscribe(this.setMenuItems);
        this.fetchMoveEvent.pipe(takeUntil(this.destroy$)).subscribe(this.fetchMove);
    }

    public openEnergy(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.modalSandbox.openModal(
                EnergyMetersInfoModal,
                {
                    data: {
                        move,
                        patchedSubject: this.data.patchedSubject,
                    },
                },
                this.onModalClose,
                null,
                null,
                null
            );
        });
    }

    public openWater(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.openWaterModal(move)();
        });
    }

    private openGeneralDetails = (): void => {
        const data: ModalData = {
            id: this.data.id,
            changeMoveSubject: this.data.changeMoveSubject,
        };

        this.modalSandbox.openModal(MobileMoveGeneralDetailsComponent, { data }, this.onGeneralDetailsModalClosed, null, null, null);
    };

    private openEnergyModal = (): void => {
        const header = this.translateService.instant(i18nKeys.energy) as string;
        this.modalSandbox.openClosableModal({ component: MoveTrackEnergyComponent, config: { header } });
    };

    private openTelecomModal = (move: Move) => {
        return (): void => {
            const input = { move };
            const header = this.translateService.instant(i18nKeys.telecom) as string;
            this.modalSandbox.openClosableModal({ component: MoveDetailTelecomComponent, config: { header, input } });
        };
    };

    private fetchMove = (moveId: string): void => {
        this.moveSandbox.fetch(moveId);
    };

    private openWaterModal = (move: Move) => {
        return (): void => {
            const input = {
                move,
                movePatchedSubject: this.data.patchedSubject,
            };
            const output = { reFetch: this.fetchMoveEvent };
            const header = this.translateService.instant(i18nKeys.water) as string;
            this.modalSandbox.openClosableModal({ component: MoveDetailWaterSection, config: { header, input, output } });
        };
    };

    private openInsurancesModal = (move: Move) => {
        return (): void => {
            const input = { move };
            const header = this.translateService.instant(i18nKeys.insurances) as string;
            this.modalSandbox.openClosableModal({ component: MoveDetailInsurancesComponent, config: { header, input } });
        };
    };

    private onGeneralDetailsModalClosed = (result: ModalClosingData): void => {
        if (result.openMove) this.dialogRef.close({ openMove: result.openMove });
    };

    private onModalClose = (): void => {
        this.data.patchedSubject
            ?.asObservable()
            .pipe(
                take(1),
                filter((x) => !!x)
            )
            .subscribe((_) => {
                this.moveSandbox.fetch(this.data.id);
            });
    };

    private setMenuItems = (move: Move): void => {
        const overviewDataLabel = this.mapAndTranslate('DETAIL.DATA', i18nKeys.RealEstateAgentDashboard);
        const movingDateLabel = this.mapAndTranslate('DETAIL.MOVING_DATE', i18nKeys.RealEstateAgentDashboard, {
            date: move.formattedMovingDate,
        });

        this.menuItemGeneralDetails = {
            label: overviewDataLabel,
            subLabel: move.formattedMovingDate ? movingDateLabel : '-',
            isVisible: true,
            navigate: this.openGeneralDetails,
            level: 0,
        };

        this.availableServices = [
            {
                label: this.translateService.instant(i18nKeys.energy) as string,
                navigate: this.openEnergyModal,
                isVisible: true,
                level: 1,
            },
            {
                label: this.translateService.instant(i18nKeys.telecom) as string,
                navigate: this.openTelecomModal(move),
                isVisible: !MoveUtils.isLeaver(move),
                level: 1,
            },
            {
                label: this.translateService.instant(i18nKeys.water) as string,
                navigate: this.openWaterModal(move),
                isVisible: true,
                level: 1,
            },
            {
                label: this.translateService.instant(i18nKeys.insurances) as string,
                navigate: this.openInsurancesModal(move),
                isVisible: move.realEstateGroupServices?.insurances && !!move.insurancesStates,
                level: 1,
            },
        ];
    };

    private mapAndTranslate(value: string, keyType: string, interpolateParams?: object): string {
        return this.translateService.instant(this.i18nKeyService.map(value, keyType), interpolateParams);
    }
}
