import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CardModule } from '@app/card/card.module';
import { BaseEnergyQuarterlyTableComponent } from '@app/invoicing/components/base-energy-quarterly-table/base-energy-quarterly-table.component';
import { BaseInsurancesQuarterlyTableComponent } from '@app/invoicing/components/base-insurances-quarterly-table/base-insurances-quarterly-table.component';
import { CommissionsNotificationUnavailableComponent } from '@app/invoicing/components/commissions-notification-unavailable/commissions-notification-unavailable.component';
import { CommissionsPaidComponent } from '@app/invoicing/components/commissions-paid/commissions-paid.component';
import { FinancialReportComponent } from '@app/invoicing/components/financial-report/financial-report.component';
import { InvoiceInfoComponent } from '@app/invoicing/components/invoice-info/invoice-info.component';
import { InvoiceNotAvailableComponent } from '@app/invoicing/components/invoice-not-available/invoice-not-available.component';
import { InvoiceComponent } from '@app/invoicing/components/invoice/invoice.component';
import { InvoicesComponent } from '@app/invoicing/components/invoices/invoices.component';
import { PayedInvoiceComponent } from '@app/invoicing/components/payed-invoice/payed-invoice.component';
import { QuarterReportGiftsComponent } from '@app/invoicing/components/quarter-report-gifts/quarter-report-gifts.component';
import { QuarterReportInsurancesComponent } from '@app/invoicing/components/quarter-report-insurances/quarter-report-insurances.component';
import { QuarterReportMortgagesComponent } from '@app/invoicing/components/quarter-report-mortgages/quarter-report-mortgages.component';
import { QuarterReportSubscriptionCostsComponent } from '@app/invoicing/components/quarter-report-subscription-costs/quarter-report-subscription-costs.component';
import { QuarterReportTelecomComponent } from '@app/invoicing/components/quarter-report-telecom/quarter-report-telecom.component';
import { QuarterReportComponent } from '@app/invoicing/components/quarter-report/quarter-report.component';
import { QuarterlyTableComponent } from '@app/invoicing/components/quarterly-table/quarterly-table.component';
import { TotalAmountComponent } from '@app/invoicing/components/total-amount/total-amount.component';
import { AssetInputModal } from '@app/invoicing/modals/asset-input/asset-input.modal';
import { InvoicingEffects } from '@app/invoicing/state/invoicing.effects';
import { invoicingReducer } from '@app/invoicing/state/invoicing.reducer';
import { MoveModule } from '@app/move/move.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslationModule } from '@app/translation/translation.module';
import { UiModule } from '@app/ui/ui.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PipeModule } from '@smooved/core';
import { AlertModule, ChartsModule, SvgModule } from '@smooved/ui';
import { BillingFilterComponent } from './components/billing-filter/billing-filter.component';
import { BillingGroupTableComponent } from './components/billing-group-table/billing-group-table.component';
import { BillingHeaderTotalsComponent } from './components/billing-header-totals/billing-header-totals.component';
import { CommissionsNotificationComponent } from './components/commissions-notification/commissions-notification.component';
import { InvoiceStatusComponent } from './components/invoice/invoice-status/invoice-status.component';
import { InvoicingTypeCellComponent } from './components/invoicing-type-cell/invoicing-type-cell.component';
import { QuarterReportEnergyComponent } from './components/quarter-report-energy/quarter-report-energy.component';
import { QuarterStatusListComponent } from './components/quarter-status-list/quarter-status-list.component';

const modals = [AssetInputModal];

const exportableComponents = [
    CommissionsNotificationComponent,
    CommissionsNotificationUnavailableComponent,
    QuarterStatusListComponent,
    BillingFilterComponent,
    BillingHeaderTotalsComponent,
    BillingGroupTableComponent,
    InvoiceComponent,
    InvoiceNotAvailableComponent,
    InvoicingTypeCellComponent,
    InvoiceInfoComponent,
    CommissionsPaidComponent,
    InvoicesComponent,
    FinancialReportComponent,
    QuarterReportComponent,
    PayedInvoiceComponent,
    TotalAmountComponent,
];

const components = [
    QuarterReportEnergyComponent,
    QuarterReportTelecomComponent,
    QuarterReportInsurancesComponent,
    QuarterReportMortgagesComponent,
    QuarterReportGiftsComponent,
    BaseEnergyQuarterlyTableComponent,
    BaseInsurancesQuarterlyTableComponent,
    QuarterlyTableComponent,
    QuarterReportSubscriptionCostsComponent,
    InvoiceStatusComponent,
];

@NgModule({
    imports: [
        SvgModule,
        TranslationModule,
        CardModule,
        SharedModule,
        MoveModule,
        UiModule,
        MatPaginatorModule,
        StoreModule.forFeature('invoicing', invoicingReducer),
        EffectsModule.forFeature([InvoicingEffects]),
        ChartsModule,
        AlertModule,
        PipeModule,
    ],
    declarations: [...modals, ...exportableComponents, ...components],
    exports: [...exportableComponents],
})
export class InvoicingModule {}
