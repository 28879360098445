import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { copyToClipboardSuccessLabel } from '@app/real-estate-agent/components/other-simulation-tools/other-simulation-tools.constants';
import { FeatureDetailReviewsService } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.service';
import { ConfigureSignatureModule } from '@app/real-estate-agent/modals/configure-signature/configure-signature.modal';
import { ConfigureWizardInfluencerScoreModal } from '@app/real-estate-agent/modals/configure-widget-influencer-score/configure-widget-influencer-score.modal';
import { FeatureScope, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, NotificationSandbox, UiContext, UiIconSize } from '@smooved/ui';
import { noop } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formControlNames, publishedOption } from './feature-detail-reviews.constants';

@Component({
    selector: 'app-feature-detail-reviews-container',
    templateUrl: './feature-detail-reviews.container.html',
    styleUrls: ['feature-detail-reviews.container.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FeatureDetailReviewsContainer extends RxjsComponent implements OnInit {
    public readonly featureScope = FeatureScope;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;
    public readonly iconSize = UiIconSize;
    public readonly navigation = Navigation;
    public readonly formControlNames = formControlNames;
    public readonly publishedOption = publishedOption;
    public readonly featureScopes = [FeatureScope.ExternalInfluencer];

    public houseMatchUri: string;

    constructor(
        public readonly featureDetailReviewsService: FeatureDetailReviewsService,
        private readonly modalSandbox: ModalSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly clipboard: Clipboard
    ) {
        super();
    }

    public ngOnInit(): void {
        this.featureDetailReviewsService.init();
        this.setUrls();
    }

    public openConfigureWidgetInfluencerScoreModal(): void {
        this.modalSandbox.openModal(ConfigureWizardInfluencerScoreModal, {}, noop, ConfigureWizardInfluencerScoreModal, {}, noop);
    }

    public openConfigureSignatureModal(): void {
        this.modalSandbox.openModal(ConfigureSignatureModule, {}, noop, ConfigureSignatureModule, {}, noop);
    }

    public copyLink(uri: string): void {
        this.clipboard.copy(uri);
        this.notificationSandbox.success(copyToClipboardSuccessLabel);
    }

    private setUrls(): void {
        this.featureDetailReviewsService.houseMatchUrl$
            .pipe(takeUntil(this.destroy$))
            .subscribe((houseMatchUrl) => (this.houseMatchUri = houseMatchUrl));
    }
}
