import { Injectable } from '@angular/core';
import { RealEstateGroupAnalytics } from '@app/real-estate-group/interfaces/real-estate-group-analytics';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { CoreUtils } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@Injectable()
export class AnalyticsDashboardService {
    private analyticsSubject = new BehaviorSubject<RealEstateGroupAnalytics>(null);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public analytics$ = this.analyticsSubject.asObservable();
    public loading$ = this.loadingSubject.asObservable();

    constructor(
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly realEstateGroupService: RealEstateGroupService
    ) {}

    public fetch(): void {
        this.loadingSubject.next(true);
        this.realEstateGroupSandbox.idOnce$
            .pipe(
                switchMap((id) => this.realEstateGroupService.getAnalytics(id)),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(this.setAnalytics);
    }

    private setAnalytics = (analytics: RealEstateGroupAnalytics): void => {
        if (!CoreUtils.isEmpty(analytics)) this.analyticsSubject.next(analytics);
    };
}
