import { FeatureScopesConfig } from '../interfaces/feature-scopes-config';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { FeatureScope } from '@smooved/core';
import { getFeatureScopesSuccess, setFeatureScopesConfig } from './feature-scope.actions';

export interface FeatureScopeState {
    featureScopes: FeatureScope[];
    featureScopesConfig: FeatureScopesConfig;
}

export const initialFeatureScopeState: FeatureScopeState = {
    featureScopes: [],
    featureScopesConfig: {},
};

const reducer = createReducer(
    initialFeatureScopeState,

    on(getFeatureScopesSuccess, (state, action) => ({
        ...state,
        featureScopes: action.featureScopes ?? [],
        featureScopesConfig: action.featureScopesConfig ?? {},
    })),
    on(setFeatureScopesConfig, (state, action) => ({
        ...state,
        featureScopesConfig: action.featureScopesConfig,
    }))
);

export function featureScopeReducer(state: FeatureScopeState | undefined, action: Action): FeatureScopeState {
    return reducer(state, action);
}

export const getFeatureScopeState = createFeatureSelector<FeatureScopeState>('feature-scope');

export const getFeatureScopesState = createSelector(getFeatureScopeState, (state) => state?.featureScopes);
export const getFeatureScopesConfigState = createSelector(getFeatureScopeState, (state) => state?.featureScopesConfig);
