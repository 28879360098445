import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Svg } from '../../../icon/icon.enums';

@Component({
    selector: 'app-check-with-background',
    template: `
        <div
            class="__icon-container u-color-white u-flex-row u-flex-align-items-center u-flex-justify-content-center"
            [ngStyle]="{ width: width + 'px', height: height + 'px' }"
        >
            <app-svg [name]="checkMarkSvg" [width]="width / 1.6 + 'px'" [height]="height / 1.6 + 'px'"></app-svg>
        </div>
    `,
    styleUrls: ['./check-with-background.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckWithBackgroundComponent {
    @Input() public height = 54;
    @Input() public width = 54;

    public checkMarkSvg = Svg.Checkmark;
}
