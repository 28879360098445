import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '../base-input';
import { restrictChars } from './meter-readings-input.constants';

@Component({
    selector: 'app-meter-reading-input, smvd-ui-meter-reading-input',
    template: `
        <app-label-container
            class="u-display-block"
            [class.__disabled]="innerDisabled"
            [required]="required"
            [id]="id"
            [label]="label"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
        >
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    [id]="id"
                    type="text"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    [uiRestrictInput]="restrictChars"
                    autocomplete="smooved"
                />
                <div class="__decimals u-color-white u-font-weight-bold" matTextSuffix>,000</div>
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./meter-reading-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeterReadingInputComponent),
            multi: true,
        },
    ],
})
export class MeterReadingInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: string;

    public readonly restrictChars = restrictChars;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
