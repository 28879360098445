import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { FormControlDirective, FormControlName, NgControl } from '@angular/forms';
import { emptyValue } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-update-property',
    templateUrl: './update-property.component.html',
})
export class UpdatePropertyComponent {
    @Input() public title: string;
    @Input() public value: string;
    @Output() public save: EventEmitter<void> = new EventEmitter<void>();

    @ContentChild(FormControlName) public formControlName: FormControlName;
    @ContentChild(FormControlDirective) public formControlDirective: FormControlDirective;

    public edit = false;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly emptyValue: string = emptyValue;

    public get innerControl(): NgControl {
        return this.formControlName || this.formControlDirective;
    }

    public showEdit(): void {
        this.edit = true;
    }

    public hideEdit(): void {
        const formControl = this.innerControl;
        if (formControl?.control) {
            formControl.control.reset();
        }
        this.edit = false;
    }
}
