import { I18nKeyUtils, Language } from '@smooved/core';
import { DropdownInput } from '../../form/dropdown-input/dropdown-input';
import { i18nKeyTypeMap, UiI18nKeyType } from '../../i18n';
import { MatchPropertyType } from '../enums/match-property-type';

export const availableMatchPropertyTypes = [MatchPropertyType.Apartment, MatchPropertyType.House, MatchPropertyType.Land];
export const availableBuildingPropertyTypes = [MatchPropertyType.Apartment, MatchPropertyType.House];

export const propertyTypeOptions: DropdownInput<string>[] = availableMatchPropertyTypes.map((value) => {
    return {
        id: value,
        value,
        labelResource: I18nKeyUtils.map(i18nKeyTypeMap, UiI18nKeyType.PropertyType, value),
        name: 'property-type-option',
    };
});

export const claimBusinessFormIds = {
    [Language.NL]: '4507208a-1ae8-464d-9486-eb576553cbc3',
    [Language.FR]: '8a1f2aea-6e54-433a-b833-2481c7ebc170',
    [Language.EN]: '837fafe2-6dbc-4901-a421-ac03e9ac1b53',
};
