import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RealEstateAgentFilter as AdminRealEstateAgentFilter } from '@app/admin/interfaces/real-estate-agent-filter.interface';
import { RealEstateGroupFilter } from '@app/admin/interfaces/real-estate-group-filter.interface';
import { EditableServices } from '@app/real-estate-agent/enums/editable-services.enum';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentOffice } from '@app/real-estate-agent/interfaces/real-estate-agent-office';
import { RealEstateAgentsGroupedByLocationDto } from '@app/real-estate-agent/interfaces/real-estate-agents-grouped-by-location';
import { OfficeRequest } from '@app/real-estate-group/interfaces/office-request';
import { RealEstateGroupRequest } from '@app/real-estate-group/interfaces/real-estate-group-request';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import {
    ArrayUtils,
    BivRealEstateAgent,
    CreateBivRealEstateAgent,
    FeatureScope,
    FeatureScopesConfig,
    HttpUtils,
    PaginationRequest,
    PaginationResponse,
    RealEstateAgency,
    StringUtils,
} from '@smooved/core';
import { BASE_URI_CONFIG, BaseUriConfig } from '@smooved/ui';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as uri from '../constants/uri.constants';
import { LogoResponse } from '../interfaces/logo-response.interface';
import { RealEstateGroupAnalytics } from '../interfaces/real-estate-group-analytics';
import { RealEstateGroup } from '../interfaces/real-estate-group.interface';
import { Theme } from '../interfaces/theme.interface';
import { setLocations } from '../state/real-estate-group.actions';
import { RealEstateGroupState } from '../state/real-estate-group.reducer';

@Injectable({
    providedIn: 'root',
})
export class RealEstateGroupService {
    public static readonly themeUri: string = `${environment.apiUri}/theme`;

    private resetLocations = tap((locations: RealEstateAgency[]) => this.store$.dispatch(setLocations({ locations })));

    constructor(
        private httpClient: HttpClient,
        private store$: Store<RealEstateGroupState>,
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig
    ) {}

    public create(realEstateGroupRequest: RealEstateGroupRequest): Observable<RealEstateGroup> {
        return this.httpClient.post<RealEstateGroup>(uri.baseUri, realEstateGroupRequest);
    }

    public update(id: string, payload): Observable<RealEstateGroup> {
        return this.httpClient.patch<RealEstateGroup>(`${uri.baseUri}/${id}`, payload);
    }

    public updateOffice(id: string, officeId: string, payload): Observable<RealEstateGroup> {
        const params = { id, officeId };
        return this.httpClient.patch<RealEstateGroup>(StringUtils.parseUri(uri.officeIdUri, params), payload);
    }

    public getAll(realEstateGroupFilter?: RealEstateGroupFilter): Observable<PaginationResponse<RealEstateGroup>> {
        return this.httpClient.get<PaginationResponse<RealEstateGroup>>(uri.baseUri, {
            params: this.buildRealEstateGroupFilter(realEstateGroupFilter),
        });
    }

    public getById(id: string): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.get<RealEstateGroup>(StringUtils.parseUri(uri.idUri, params));
    }

    public getLogo(realEstateAgentId: string): Observable<LogoResponse> {
        const httpParams: HttpParams = new HttpParams().set('realEstateAgentId', realEstateAgentId);
        return this.httpClient.get<LogoResponse>(uri.logoUri, {
            params: httpParams,
        });
    }

    /**
     *
     */
    public getTheme(query?: { accessToken?: string; id?: string; realEstateAgentId?: string; email?: string }): Observable<Theme> {
        return this.httpClient.get<Theme>(RealEstateGroupService.themeUri, {
            params: HttpUtils.buildQueryParameters(query),
        });
    }

    public getOffice(realEstateAgentId: string): Observable<RealEstateAgentOffice> {
        const httpParams: HttpParams = new HttpParams().set('realEstateAgentId', realEstateAgentId);
        return this.httpClient.get<RealEstateAgentOffice>(uri.officeUri, {
            params: httpParams,
        });
    }

    public getOffices(realEstateGroupFilter?: RealEstateGroupFilter): Observable<RealEstateAgentOffice[]> {
        return this.httpClient.get<RealEstateAgentOffice[]>(uri.officesUri, {
            params: this.buildRealEstateGroupFilter(realEstateGroupFilter),
        });
    }

    public createOffice(officeRequest: OfficeRequest): Observable<RealEstateGroup> {
        return this.httpClient.post<RealEstateGroup>(uri.officesUri, officeRequest);
    }

    public getRealEstateGroupForMe(): Observable<RealEstateGroup> {
        return this.httpClient.get<RealEstateGroup>(uri.meUri);
    }

    public getTeam(realEstateAgentFilter: RealEstateAgentFilter): Observable<RealEstateAgent[]> {
        let httpParams: HttpParams = new HttpParams();
        const filter: RealEstateAgentFilter = {};
        if (realEstateAgentFilter?.search) {
            filter.search = encodeURIComponent(realEstateAgentFilter?.search);
        }
        if (realEstateAgentFilter?.office) {
            filter.office = encodeURIComponent(realEstateAgentFilter?.office);
        }
        httpParams = httpParams.append('filter', JSON.stringify(filter));
        return this.httpClient.get<RealEstateAgent[]>(uri.teamUri, {
            params: httpParams,
        });
    }

    public findTeam(
        realEstateAgentFilter: AdminRealEstateAgentFilter,
        paginationRequest: PaginationRequest
    ): Observable<PaginationResponse<RealEstateAgent>> {
        let httpParams: HttpParams = HttpUtils.buildPaginationRequest(paginationRequest);
        const filter: AdminRealEstateAgentFilter = {};
        if (realEstateAgentFilter?.search) {
            filter.search = encodeURIComponent(realEstateAgentFilter?.search);
        }
        if (realEstateAgentFilter?.realEstateGroup) {
            filter.realEstateGroup = encodeURIComponent(realEstateAgentFilter?.realEstateGroup);
        }
        if (realEstateAgentFilter?.office) {
            filter.office = encodeURIComponent(realEstateAgentFilter?.office);
        }
        httpParams = httpParams.append('filter', JSON.stringify(filter));
        return this.httpClient.get<PaginationResponse<RealEstateAgent>>(uri.findTeamUri, {
            params: httpParams,
        });
    }

    public addRealEstateAgentToOffice(realEstateAgentId: string, officeId: string): Observable<RealEstateGroup> {
        return this.httpClient.post<RealEstateGroup>(uri.realEstateAgentsUri.replace(':officeId', officeId), {
            id: realEstateAgentId,
        });
    }

    public activateService(service: string): Observable<void> {
        return this.httpClient.post<void>(uri.activateServiceUri.replace(':service', service), null);
    }

    public deactivateService(service: string): Observable<void> {
        return this.httpClient.post<void>(uri.deactivateServiceUri.replace(':service', service), null);
    }

    public getLocations(realEstateGroupId: string): Observable<RealEstateAgency[]> {
        const params = { id: realEstateGroupId };
        return this.httpClient.get<RealEstateAgency[]>(StringUtils.parseUri(uri.locationsUri, params)).pipe(this.resetLocations);
    }

    public addLocation(realEstateGroupId: string, location: RealEstateAgency): Observable<RealEstateAgency[]> {
        const params = { id: realEstateGroupId };
        return this.httpClient.post<RealEstateAgency[]>(StringUtils.parseUri(uri.locationsUri, params), location).pipe(this.resetLocations);
    }

    public patchLocation(realEstateGroupId: string, locationId: string, location: RealEstateAgency): Observable<RealEstateAgency[]> {
        const params = { id: realEstateGroupId, locationId };
        return this.httpClient
            .patch<RealEstateAgency[]>(StringUtils.parseUri(uri.locationByIdUri, params), location)
            .pipe(this.resetLocations);
    }

    public deleteLocation(realEstateGroupId: string, locationId: string): Observable<RealEstateAgency[]> {
        const params = { id: realEstateGroupId, locationId };
        return this.httpClient.delete<RealEstateAgency[]>(StringUtils.parseUri(uri.locationByIdUri, params)).pipe(this.resetLocations);
    }

    public addFeatureScope(id: string, featureScope: FeatureScope | EditableServices): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.post<RealEstateGroup>(StringUtils.parseUri(uri.addFeatureScopesUri, params), { featureScope });
    }

    public deleteFeatureScope(id: string, featureScope: FeatureScope | EditableServices): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.post<RealEstateGroup>(StringUtils.parseUri(uri.deleteFeatureScopesUri, params), { featureScope });
    }

    public includeFeatureScope(id: string, featureScope: FeatureScope | EditableServices): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.post<RealEstateGroup>(StringUtils.parseUri(uri.includeFeatureScopesUri, params), { featureScope });
    }

    public excludeFeatureScope(id: string, featureScope: FeatureScope | EditableServices): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.post<RealEstateGroup>(StringUtils.parseUri(uri.excludeFeatureScopesUri, params), { featureScope });
    }

    public patchFeatureScopeConfig(id: string, config: FeatureScopesConfig): Observable<RealEstateGroup> {
        const params = { id };
        return this.httpClient.patch<RealEstateGroup>(StringUtils.parseUri(uri.featureScopeConfigUri, params), config);
    }

    public buildRealEstateGroupFilter(realEstateGroupFilter?: RealEstateGroupFilter): HttpParams {
        let httpParams: HttpParams = HttpUtils.buildPaginationRequest(realEstateGroupFilter);
        if (realEstateGroupFilter) {
            const { _id, search, accountManager } = realEstateGroupFilter;
            const filter: RealEstateGroupFilter = {};
            if (_id) {
                filter._id = _id;
            }
            if (search) {
                filter.search = search;
            }
            if (accountManager) {
                filter.accountManager = accountManager;
            }
            httpParams = httpParams.append('filter', JSON.stringify(filter));
        }
        return httpParams;
    }

    public getAnalytics(realEstateGroupId: string): Observable<RealEstateGroupAnalytics> {
        const params = { id: realEstateGroupId };
        return this.httpClient.get<RealEstateGroupAnalytics>(StringUtils.parseUri(uri.analyticsUri, params));
    }

    public getBivRealEstateAgents(realEstateGroupId: string): Observable<BivRealEstateAgent[]> {
        const params = { id: realEstateGroupId };
        return this.httpClient.get<BivRealEstateAgent[]>(StringUtils.parseUri(uri.realEstateGroupBivRealEstateAgentsUri, params));
    }

    public deleteBivRealEstateAgents(realEstateGroupId: string, agentIds: string[]): Observable<number> {
        const deletes = agentIds.map((agentId) => {
            const params = { id: realEstateGroupId, agentId };
            return this.httpClient.delete<{ deletedCount?: number }>(
                StringUtils.parseUri(uri.realEstateGroupBivRealEstateAgentUri, params)
            );
        });
        return forkJoin(deletes).pipe(map((counts) => ArrayUtils.sum(counts.map((count) => count.deletedCount))));
    }

    public createBivRealEstateAgent(realEstateGroupId: string, agent: CreateBivRealEstateAgent): Observable<BivRealEstateAgent> {
        const params = { id: realEstateGroupId };
        return this.httpClient.post<BivRealEstateAgent>(StringUtils.parseUri(uri.realEstateGroupBivRealEstateAgentsUri, params), agent);
    }

    public getEncodedId(id: string): Observable<string> {
        return this.httpClient.get<string>(StringUtils.parseUri(uri.encodedIdUri, { id }), { responseType: 'text' as any }); // TS typing fix
    }

    public getRealEstateAgentsGroupedByLocation(
        realEstateGroupId: string,
        locationId?: string
    ): Observable<RealEstateAgentsGroupedByLocationDto> {
        return this.httpClient.get<RealEstateAgentsGroupedByLocationDto>(
            `${this.baseUriConfig.apiUri}/real-estate-groups/${realEstateGroupId}/locations/real-estate-agents`,
            {
                params: HttpUtils.buildQueryParameters({ locationId }, true),
            }
        );
    }
}
