import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-admin-todo-leaver-regularization-bill-requested',
    templateUrl: './todo-leaver-regularization-bill-requested.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverRegularizationBillRequestedComponent extends BaseServiceActionComponent {
    @Input() public move: Move;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly i18nKeys = AppI18nKeyType;

    constructor(
        private readonly documentCenter: DocumentCenterSandbox,
        private readonly adminModalService: AdminModalService,
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public downloadEDS(): void {
        this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(this.move));
    }

    public requestBill(): void {
        this.adminModalService.openCustomMessageLeaverToSupplierModal(this.move, this.onBillRequested);
    }

    private onBillRequested = (move: Move): void => {
        if (move) {
            this.moveSandbox
                .get(DbUtils.getStringId(move))
                .pipe(take(1))
                .subscribe((move) => this.updateMove(move));
        }
    };
}
