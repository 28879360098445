<app-card [bordered]="false">
    <ng-container>
        <header class="u-margin-bottom-double">
            <div class="u-flex-row u-flex-align-items-center">
                <h3 class="u-margin-right">
                    {{
                        'INVOICING.INVOICE.TITLE'
                            | translate
                                : {
                                      quarter: invoice?.quarter,
                                      year: invoice?.year
                                  }
                    }}
                </h3>
                <smvd-ui-invoice-status [invoiceStatus]="invoice.status"></smvd-ui-invoice-status>
            </div>
            <span
                class="u-color-gray-dark"
                *ngIf="invoice.status.value === invoiceStatus.WaitingForInvoiceOrApproval; else paymentPlanned"
                >{{ 'INVOICING.INVOICE.UPLOAD_YOUR_INVOICE' | translate }}</span
            >
        </header>
        <main
            class="u-flex-responsive-desktop-up u-flex-align-items-stretch u-margin-bottom-up-to-and-including-tablet-landscape"
            *ngIf="{ desktopUp: desktopUp$ | async } as vm"
        >
            <div
                class="u-flex-1-1-0 u-margin-right-double-desktop-up u-padding-right-double-desktop-up u-margin-bottom-up-to-and-including-tablet-landscape u-padding-bottom-up-to-and-including-tablet-landscape"
                [class.u-border-right]="vm.desktopUp"
                [class.u-border-bottom]="!vm.desktopUp"
            >
                <h5>{{ 'INVOICING.INVOICE.COMMISSION.TITLE' | translate }}</h5>
                <p
                    class="u-color-gray-dark u-margin-bottom-md"
                    [innerHTML]="
                        'INVOICING.INVOICE.COMMISSION.DESCRIPTION'
                            | translate: { hasPackage: !!invoice.package?.name, package: invoice.package?.name }
                    "
                ></p>
                <p id="commission-amount" class="u-font-weight-semi-bold u-font-size-20">
                    {{ (invoice.version === version.v2 ? invoice.totalCommissions : invoice.feeTotal) | price }}
                </p>
                <p class="u-color-gray u-margin-bottom-md">{{ 'INVOICING.INVOICE.VAT' | translate: { excludeValue: false } }}</p>
                <p
                    id="commission-amount-text"
                    class="u-color-gray-dark u-margin-bottom"
                    [innerHTML]="
                        'INVOICING.INVOICE.COMMISSION.TEXT'
                            | translate
                                : {
                                      quarter: invoice.quarter,
                                      year: invoice.year,
                                      amount: (invoice.version === version.v2 ? invoice.totalCommissions : invoice.feeTotal) | price
                                  }
                    "
                ></p>
                <app-invoice-info class="u-margin-bottom u-display-block u-color-gray-dark"></app-invoice-info>
                <button smvd-ui-button (click)="openUploadInvoice()" [context]="UiContext.PrimaryDark" [disabled]="!!invoice.asset">
                    <div class="u-flex-row">
                        <app-svg-illustration
                            *ngIf="!!invoice.asset"
                            class="__upload-complete-icon"
                            [svg]="svgIllustration.UploadComplete"
                        ></app-svg-illustration>
                        {{ 'INVOICING.' + getCtaLabel(invoice) | translate }}
                    </div>
                </button>
            </div>
            <div class="u-flex-1-1-0" *ngIf="invoice.version === version.v2; else legacyInvoiceNotice">
                <ng-container *ngIf="invoice?.package?.key === PackageId.SmoovedPlus; else nonPayingPackageNotice">
                    <div id="j-subscription-section">
                        <h5>{{ 'INVOICING.INVOICE.SUBSCRIPTION.TITLE' | translate }}</h5>
                        <p
                            class="u-color-gray-dark u-margin-bottom-md"
                            [innerHTML]="
                                'INVOICING.INVOICE.SUBSCRIPTION.DESCRIPTION'
                                    | translate: { package: invoice.package?.name, officeCount: invoice.officeCount || 0 }
                            "
                        ></p>
                        <p class="u-font-weight-semi-bold u-font-size-20">{{ invoice.totalSubscriptionCostInclusiveDiscounts | price }}</p>
                        <p class="u-color-gray u-margin-bottom-md">{{ 'INVOICING.INVOICE.VAT' | translate: { excludeValue: false } }}</p>
                        <ng-container *ngIf="showDownloadInfo">
                            <p class="u-color-gray-dark u-margin-bottom-half">
                                {{ 'INVOICING.INVOICE.SUBSCRIPTION.DOWNLOAD_NOTICE_1' | translate }}
                            </p>
                            <p class="u-color-gray-dark">{{ 'INVOICING.INVOICE.SUBSCRIPTION.DOWNLOAD_NOTICE_2' | translate }}</p>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </main>
        <div class="u-flex-row u-flex-justify-content-end" *ngIf="invoice.version === version.v2">
            <app-invoice-total-amount [invoice]="invoice"></app-invoice-total-amount>
        </div>
    </ng-container>
</app-card>

<ng-template #legacyInvoiceNotice>
    <div class="u-flex-column u-flex-justify-content-space-between u-flex-1-1-0">
        <app-alert class="u-margin-bottom" [context]="uiContext.Info" [icon]="uiIcon.Info">
            {{ 'INVOICING.INVOICING_CHANGES_2024.LEGACY_INVOICE_ALERT' | translate }}
        </app-alert>
    </div>
</ng-template>

<ng-template #nonPayingPackageNotice>
    <div id="j-non-paying-package" class="u-flex-column u-flex-justify-content-space-between u-flex-1-1-0">
        <app-alert class="u-margin-bottom" [context]="uiContext.Info" [icon]="uiIcon.Info">
            {{ 'INVOICING.INVOICE.NO_SUBSCRIPTION.PART_1' | translate }}
            <span class="u-font-weight-semi-bold">{{ invoice?.package?.name }}</span>
            {{ 'INVOICING.INVOICE.NO_SUBSCRIPTION.PART_2' | translate }}
        </app-alert>
    </div>
</ng-template>

<ng-template #paymentPlanned>{{ 'INVOICING.INVOICE.PAYMENT_PLANNED' | translate }}</ng-template>
