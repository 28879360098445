import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, LinkedRealEstateAgent, RealEstateAgentUtils, StringUtils } from '@smooved/core';
import { NameUtils } from '@smooved/ui';

export enum ItemType {
    Other,
    Previous,
    ActiveRealEstateAgent,
}

export interface AgentFormat {
    label: string;
    sortOrder: number;
    type: ItemType;
}

@Pipe({
    name: 'formatLinkedRealEstateAgents',
    pure: false, // to ensure rerender after language change
})
export class FormatLinkedRealEstateAgentsPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(value: LinkedRealEstateAgent[], isPublic = true): string {
        if (!value || ArrayUtils.isEmpty(value)) return '';

        const uniqueRealEstateAgents = ArrayUtils.unique(value);
        const formattedRealEstateAgents = uniqueRealEstateAgents
            .map(this.linkedAgentLabel(isPublic))
            .filter((mappedAgent) => (isPublic ? mappedAgent.type !== ItemType.Other : true) && !!mappedAgent.label);

        /** removal of duplicated others or previous */
        const cleanedRealEstateAgents = ArrayUtils.uniqueByProperty(formattedRealEstateAgents, 'label');

        if (ArrayUtils.isEmpty(cleanedRealEstateAgents)) return '';

        if (ArrayUtils.getLength(cleanedRealEstateAgents) === 1)
            return StringUtils.capitalize(ArrayUtils.first(cleanedRealEstateAgents).label);
        const sortedRealEstateAgents = cleanedRealEstateAgents.sort((a, b) => a.sortOrder - b.sortOrder);

        return this.formatLabel(sortedRealEstateAgents, isPublic);
    }

    private formatLabel = (sortedRealEstateAgents: AgentFormat[], isPublic: boolean): string => {
        if (!isPublic) return sortedRealEstateAgents.map((agent) => agent.label).join(', ');

        const labels = sortedRealEstateAgents.map((agent) => agent.label);
        const isLastInactive = ArrayUtils.last(sortedRealEstateAgents).type === ItemType.Previous;
        return `${labels.slice(0, -1).join(', ')} ${this.translateService.instant('UI.AND')}${isLastInactive ? ` ${this.translateService.instant('UI.PRONOUN')}` : ''} ${labels[labels.length - 1]}`;
    };

    private linkedAgentLabel =
        (isPublic: boolean) =>
        (linkedRealEstateAgent: LinkedRealEstateAgent): AgentFormat => {
            if (linkedRealEstateAgent.isOther) {
                return {
                    label: this.translateService.instant('UI.REVIEWS.LINKED_REAL_ESTATE_AGENTS.OTHER'),
                    sortOrder: 3,
                    type: ItemType.Other,
                };
            } else if (RealEstateAgentUtils.isInactive(linkedRealEstateAgent)) {
                return {
                    label: isPublic
                        ? this.translateService.instant('UI.REVIEWS.LINKED_REAL_ESTATE_AGENTS.PREVIOUS')
                        : `${NameUtils.getName(linkedRealEstateAgent)} (${this.translateService.instant('UI.REVIEWS.LINKED_REAL_ESTATE_AGENTS.INACTIVE')})`,
                    sortOrder: 2,
                    type: ItemType.Previous,
                };
            }
            return {
                label: NameUtils.getName(linkedRealEstateAgent),
                sortOrder: 1,
                type: ItemType.ActiveRealEstateAgent,
            };
        };
}
