import { Injectable } from '@angular/core';
import { State } from '@app/store/state';
import { providers } from '@app/telecom/constants/telecom.constants';
import { TelecomService } from '@app/telecom/telecom.service';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { select, Store } from '@ngrx/store';
import { StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TelecomSuggestion } from '../interfaces/telecom-suggestion';
import { TelecomSuggestionsFilter } from '../interfaces/telecom-suggestions-filter';
import { TelecomSuggestionsFilterOptions } from '../interfaces/telecom-suggestions-filter-options';
import { clearTelecomSuggestions, getTelecomSuggestionsFilterOptions, setTelecomSuggestionsFilter } from '../state/telecom.actions';
import {
    getTelecomSuggestionsDataState,
    getTelecomSuggestionsFilterOptionsState,
    getTelecomSuggestionsFilterState,
} from '../state/telecom.reducer';

@Injectable({
    providedIn: 'root',
})
export class TelecomSandbox {
    public telecomSuggestionsFilter$: Observable<TelecomSuggestionsFilter> = this.store$.pipe(select(getTelecomSuggestionsFilterState));
    public telecomSuggestionsFilterOnce$: Observable<TelecomSuggestionsFilter> = this.telecomSuggestionsFilter$.pipe(take(1));
    public telecomSuggestions$: Observable<TelecomSuggestion[]> = this.store$.pipe(select(getTelecomSuggestionsDataState));
    public telecomSuggestionsFilterOptions$: Observable<TelecomSuggestionsFilterOptions> = this.store$.pipe(
        select(getTelecomSuggestionsFilterOptionsState)
    );
    public readonly providers: string[] = providers.map((x) => StringUtils.toLowerCase(x));

    constructor(private store$: Store<State>, private readonly telecomService: TelecomService) {}

    public getTelecomSuggestionsFilterOptions(
        providers: Provider[],
        callback?: (telecomSuggestionsFilterOptions: TelecomSuggestionsFilterOptions) => void
    ): void {
        this.store$.dispatch(getTelecomSuggestionsFilterOptions({ providers, callback }));
    }

    public clearTelecomSuggestions(): void {
        this.store$.dispatch(clearTelecomSuggestions());
    }

    public setTelecomSuggestionsFilter(telecomSuggestionsFilter: TelecomSuggestionsFilter): void {
        this.store$.dispatch(setTelecomSuggestionsFilter({ telecomSuggestionsFilter }));
    }

    public getTelenetProducts(): Observable<TelecomSuggestion[]> {
        return this.telecomService.getTelenetProducts();
    }
}
