<smvd-ui-review-arc-score
    *ngIf="showSmoovedScore && meta?.total && meta?.score"
    [meta]="meta"
    [arcSize]="uiSizes.Xxs"
    [fontSize]="uiSizes.Xs"
    [compact]="true"
></smvd-ui-review-arc-score>
<smvd-ui-review-star-score
    *ngIf="showExternalScore && meta?.externalScore && meta?.externalTotal"
    [meta]="meta"
    [fontSize]="uiSizes.Xs"
></smvd-ui-review-star-score>
