<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <ng-autocomplete
        [data]="options"
        [placeholder]="placeholder || ('SEARCH' | translate) + '...'"
        searchKeyword="label"
        [notFoundText]="'NOT_FOUND' | translate"
        (inputCleared)="inputClear($event)"
        (selected)="selected($event)"
        [(ngModel)]="innerModel"
        [name]="formControlName"
        [itemTemplate]="itemTemplateStatic"
        [isLoading]="loading"
    >
    </ng-autocomplete>

    <ng-template #itemTemplateStatic let-item>
        <span [innerHTML]="item?.label"></span>
    </ng-template>

    <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
</app-label-container>