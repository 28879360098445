import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnergySuggestion } from '../../../wizard/energy/interfaces/energy-suggestion';
import { EnergyPromoModal } from '../../modals/energy-promo/energy-promo.modal';

@Component({
    selector: 'app-show-promo-info',
    template: `
        <a class="u-link" (click)="showPromoInfo(energySuggestion)">{{ 'MOVE.ENERGY.SUGGESTION.SHOW_PROMO_INFO' | translate }}</a>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowPromoInfoComponent {
    @Input() public energySuggestion: EnergySuggestion;

    constructor(private dialog: MatDialog) {}

    public showPromoInfo(energySuggestion: EnergySuggestion): void {
        this.dialog.open(EnergyPromoModal, {
            data: energySuggestion,
        });
    }
}
