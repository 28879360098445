import { Component, Input, OnInit } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveService } from '@app/move/services/move.service';
import { InterviewResult } from '@app/surveys/interfaces/data/interview-result';
import { DbUtils, ObjectUtils } from '@smooved/core';
import { UiIcon } from '@smooved/ui';

@Component({
    selector: 'smvd-app-review-history',
    templateUrl: 'review-history.component.html',
})
export class ReviewHistoryComponent implements OnInit {
    @Input() public move: Move;

    public reviewHistory: InterviewResult;

    public readonly uiIcon = UiIcon;

    constructor(private readonly moveService: MoveService) {}

    public ngOnInit(): void {
        if (ObjectUtils.isEmpty(this.move.surveys?.nps?.answers)) {
            this.moveService.getReviewHistory(DbUtils.getStringId(this.move)).subscribe((review) => (this.reviewHistory = review));
        }
    }
}
