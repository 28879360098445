import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MeterReadingsOutputService } from '@app/meter-readings/components/meter-readings-output/meter-readings-output.service';
import { METER_READINGS_OUTPUT_CONFIG } from '@app/meter-readings/constants/meter-readings.constants';
import { Move } from '@app/move/interfaces/move';
import { meterReadingsOutputConfig } from '@app/partner/components/meter-readings/meter-readings.constants';
import { MeterReadingsService } from '@app/partner/components/meter-readings/meter-readings.service';
import { SimpleChangesUtils } from '@smooved/core';

@Component({
    selector: 'app-meter-readings',
    template: ` <app-meter-readings-output [move]="move"></app-meter-readings-output>`,
    providers: [
        {
            provide: MeterReadingsOutputService,
            useClass: MeterReadingsService,
        },
        {
            provide: METER_READINGS_OUTPUT_CONFIG,
            useValue: meterReadingsOutputConfig,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterReadingsComponent implements OnInit, OnChanges {
    @Input() public move: Move;
    @Output() public refetch = new EventEmitter<void>();

    constructor(private meterReadingsService: MeterReadingsOutputService) {}

    public ngOnInit(): void {
        (this.meterReadingsService as MeterReadingsService).refetch = this.refetch;
        this.resetState();
    }

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.resetState();
        }
    }

    private resetState() {
        (this.meterReadingsService as MeterReadingsService).resetMeterReadingsState(this.move);
    }
}
