import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { socialsUri, StringUtils } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { Brand, UiIconAppearance, UiIconSize } from '../../../icon';
import { UriSchemaType } from '../../../pipes';
import { SvgIllustration } from '../../../svg';
import { UiContext, UiDirection } from '../../../ui.enums';
import { ExternalInfluencerUriConfig, EXTERNAL_INFLUENCER_URI_CONFIG, SMOOVED_APPLICATION_SOURCE } from '../../configs';
import { CompanyPageNavigation as Navigation } from '../../constants';
import { SmoovedApplicationSource } from '../../enums';
import { ExternalInfluencerService } from '../../services/external-influencer.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'smvd-ui-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ContactInfoComponent implements OnInit {
    @Input() public showTitle = true;
    @Input() public direction = UiDirection.Vertical;

    public readonly iconAppearance = UiIconAppearance;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly type = UriSchemaType;
    public readonly brands = Brand;
    public readonly uiContext = UiContext;
    public readonly iconSize = UiIconSize;
    public readonly routes = Navigation;
    public readonly socialUri = socialsUri;
    public readonly uiDirection = UiDirection;
    public readonly svgIllustration = SvgIllustration;

    public contactRoute: string[];
    public houseMatchCompanyPageUri: string;

    constructor(
        public readonly externalInfluencerService: ExternalInfluencerService,
        private readonly navigationService: NavigationService,
        @Inject(EXTERNAL_INFLUENCER_URI_CONFIG) public readonly config: ExternalInfluencerUriConfig,
        @Optional() @Inject(SMOOVED_APPLICATION_SOURCE) private readonly smoovedApplicationSource: SmoovedApplicationSource
    ) {
        this.houseMatchCompanyPageUri =
            this.smoovedApplicationSource === SmoovedApplicationSource.ExternalInfluencer && config.houseMatchCompanyPageUri;
    }

    public ngOnInit(): void {
        this.contactRoute = this.navigationService.getContact();
    }

    protected readonly StringUtils = StringUtils;
}
