import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SendGiftModal } from '@app/gift/modals/send-gift/send-gift.modal';
import { Move } from '@app/move/interfaces/move';
import { ModalSandbox, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-send-gift-box',
    template: `
        <app-card *ngIf="{ firstName: (move | userName) } as vm">
            <header class="u-flex-row u-flex-align-items-center u-margin-bottom">
                <app-icon icon="redeem" class="u-margin-right-half" [size]="uiIconSize.Xl"></app-icon>
                <h6>{{ 'MOVE.GIFT.SEND_GIFT_BOX.TITLE' | translate: { firstName: vm.firstName } }}</h6>
            </header>
            <p class="u-margin-bottom-double">{{ 'MOVE.GIFT.SEND_GIFT_BOX.CONTENT' | translate: { firstName: vm.firstName } }}</p>
            <app-button (onClick)="onClick()">{{ 'MOVE.GIFT.SEND_GIFT_BOX.CTA' | translate }}</app-button>
        </app-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendGiftBoxComponent {
    @Input() public move: Move;
    @Output() public reload: EventEmitter<void> = new EventEmitter<void>();

    public uiIconSize = UiIconSize;

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public onClick(): void {
        const data = {
            data: this.move._id,
        };
        this.modalSandbox.openModal(SendGiftModal, data, this.handleModalClose, SendGiftModal, data, this.handleModalClose);
    }

    private handleModalClose = (reload: boolean): void => {
        if (reload) {
            this.reload.emit();
        }
    };
}
