import { getCurrencySymbol } from '@angular/common';
import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    SkipSelf,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { CoreUtils, Currency, NumberUtils, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '../../button/button.enums';
import { Svg } from '../../icon/icon.enums';
import { UiContext, UiSize } from '../../ui.enums';
import { BaseInput } from '../base-input';
import { FormFieldAppearanceEnum } from '../enums/mat-form-field-appearance.enum';
import { maxDecimals } from './number-input.constants';

@Component({
    selector: 'smvd-ui-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public min = 0;
    @Input() public max = 99999;
    @Input() public showButtons = true;
    @Input() public step = 1;
    @Input() public decimals = 2;
    @Input() public prefix: string;
    @Input() public currency: Currency;
    @Input() public size = UiSize.Medium;
    @Input() public ignoreFormat = false;
    @Input() public thousandSeparator = '.';
    @Input() public appearance: MatFormFieldAppearance = FormFieldAppearanceEnum.Outline;

    public svg = Svg;
    public buttonAppearance = ButtonAppearance;
    public buttonSize = ButtonSize;
    public context = UiContext;
    public innerModel: string;
    public currencySymbol: string;
    private innerValue: number;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, @Inject(LOCALE_ID) private readonly localeId) {
        super(controlContainer);
    }

    public get mask(): string {
        return `separator.${Math.min(this.decimals, maxDecimals)}`;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnChanges({ currency }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(currency)) {
            this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: number): void {
        this.innerValue = value;
        this.innerModel = this.preFormatInnerModel(value);
    }

    public onModelChange(value: number): void {
        this.innerValue = CoreUtils.isEmpty(value) ? null : Number(value);
        this.propagateChange(this.innerValue);
    }

    public decrease(): void {
        this.innerValue = Math.max(this.min, (this.innerValue ?? 0) - this.step);
        this.innerModel = this.preFormatInnerModel(this.innerValue);
        this.propagateChange(this.innerValue);
    }

    public increase(): void {
        this.innerValue = Math.min(this.max, (this.innerValue ?? 0) + this.step);
        this.innerModel = this.preFormatInnerModel(this.innerValue);
        this.propagateChange(this.innerValue);
    }

    private preFormatInnerModel(value: number): string {
        if (!NumberUtils.isNumber(value)) return;
        if (this.ignoreFormat) return value.toString();
        return NumberUtils.internationalFormat(value, this.localeId);
    }
}
