<app-label-container
    [hasMarginDouble]="hasMarginDouble"
    [hasMargin]="hasMargin"
    [id]="id"
    [label]="label"
    [required]="required"
    [showRequiredAsterisk]="showRequiredAsterisk"
>
    <mat-form-field
        [appearance]="FormFieldAppearanceEnum.Outline"
        [style.width]="width"
        class="ui-dropdown-input-appearance-outline"
    >
        <mat-select
            (openedChange)="onOpenChange(multiUserSearch)"
            (selectionChange)="onModelChange($event)"
            [(ngModel)]="innerModel"
            [multiple]="multiple"
            [panelClass]="'__custom-panel'"
            [placeholder]="placeholder || ('SEARCH' | translate)"
            [required]="required"
            [hideSingleSelectionIndicator]="true"
        >
            <mat-select-trigger>
                <div [innerHtml]="selectTriggerHtml"></div>
            </mat-select-trigger>

            <div class="u-padding-x-axis">
                <input
                    #multiUserSearch
                    (keydown)="$event.stopPropagation()"
                    (keyup)="onInputChange($event)"
                    class="__search_input"
                    autocomplete="off"
                    [placeholder]="placeholder || ('SEARCH' | translate)"
                    matInput
                    type="text"
                />
            </div>
            <ng-container *ngIf="isGrouped; else nonGrouped">
                <ng-container *ngIf="includeAllOption">
                    <mat-option [value]="null">{{ allOptionLabel | translate }}</mat-option>
                </ng-container>
                <mat-optgroup *ngFor="let group of options" [label]="group?.name" class="__grouped-options">
                    <mat-option
                        *ngFor="let option of group.groupedOptions; trackBy: trackById"
                        [appOptionDisplay]="option.label || (option.labelResource | translate)"
                        [searchInput]="searchInput"
                        [value]="option"
                    >
                        {{ option.label || (option.labelResource | translate) }}
                    </mat-option>
                </mat-optgroup>
                <mat-optgroup [label]="otherOptionsGroupName | translate"
                              *ngIf="includeOtherOption || !ArrayUtils.isEmpty(otherOptions)">
                    <mat-option
                        *ngFor="let otherOption of otherOptions; trackBy: trackById"
                        [value]="otherOption"
                        [appOptionDisplay]="otherOption.label || (otherOption.labelResource | translate)"
                        [searchInput]="searchInput"
                    >{{ otherOption.label || otherOption.labelResource | translate }}
                    </mat-option>
                    <mat-option *ngIf="includeOtherOption"
                                [value]="otherOption">{{ otherOption.labelResource | translate }}
                    </mat-option>
                </mat-optgroup>
            </ng-container>
            <ng-template #nonGrouped>
                <ng-container *ngIf="includeAllOption">
                    <mat-option [value]="null">{{ allOptionLabel | translate }}</mat-option>
                </ng-container>
                <mat-option
                    *ngFor="let option of options; trackBy: trackById"
                    [attr.data-testid]="option.id"
                    [appOptionDisplay]="option.label || (option.labelResource | translate)"
                    [searchInput]="searchInput"
                    [value]="option"
                >
                    {{ option.label || (option.labelResource | translate) }}
                </mat-option>
                <mat-option
                    *ngFor="let otherOption of otherOptions; trackBy: trackById"
                    [value]="otherOption"
                    [appOptionDisplay]="otherOption.label || (otherOption.labelResource | translate)"
                    [searchInput]="searchInput"
                >{{ otherOption.label || otherOption.labelResource | translate }}
                </mat-option>
                <mat-option *ngIf="includeOtherOption"
                            [value]="otherOption">{{ otherOption.labelResource | translate }}
                </mat-option>
            </ng-template>
        </mat-select>
        <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
    </mat-form-field>
</app-label-container>
