import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as authenticationUri from '@app/authentication/constants/uri.constants';
import { RealEstateAgentInviteRequest } from '@app/authentication/interfaces/real-estate-agent-invite.request';
import { RealEstateAgentRegisterRequest } from '@app/authentication/interfaces/real-estate-agent-register.request';
import { TokenResponse } from '@app/authentication/interfaces/token-response';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { environment } from '@environments/environment';
import { StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { ConfirmLoginRequest } from '../interfaces/confirm-login.request';
import { RealEstateAgent } from '../interfaces/real-estate-agent';

@Injectable({
    providedIn: 'root',
})
export class RealEstateAgentService {
    public static readonly baseUri: string = `${environment.apiUri}/real-estate-agents`;
    public static readonly byConfirmationTokenUri: string = `${RealEstateAgentService.baseUri}/by-confirmation-token`;
    public static readonly confirmInviteUri: string = `${RealEstateAgentService.baseUri}/confirm-invite`;
    public static readonly realEstateAgentUri: string = `${RealEstateAgentService.baseUri}/:realEstateAgentId`;
    public static readonly filterUri: string = `${RealEstateAgentService.baseUri}/filter`;

    public static readonly registerUri: string = `${authenticationUri.baseUri}/real-estate-agent/register`;
    public static readonly confirmUri: string = `${authenticationUri.baseUri}/real-estate-agent/register/confirm`;
    public static readonly inviteUri: string = `${authenticationUri.baseUri}/real-estate-agent/invite`;
    public static readonly leadsUri: string = `${RealEstateAgentService.baseUri}/leads`;
    public static readonly sendRegisterEmailUri: string = `${RealEstateAgentService.baseUri}/:id/send-register-email`;
    public static readonly reactivateUri: string = `${RealEstateAgentService.baseUri}/:id/reactivate`;
    public static readonly deactivateUri: string = `${RealEstateAgentService.baseUri}/:id/deactivate`;
    public static readonly linkToLocationsUri: string = `${RealEstateAgentService.baseUri}/:id/link-to-locations`;

    constructor(private httpClient: HttpClient) {}

    public get(realEstateAgentId: string): Observable<RealEstateAgent> {
        return this.httpClient.get<RealEstateAgent>(StringUtils.parseUri(RealEstateAgentService.realEstateAgentUri, { realEstateAgentId }));
    }

    public getAll(): Observable<RealEstateAgent[]> {
        return this.httpClient.get<RealEstateAgent[]>(RealEstateAgentService.baseUri);
    }

    public getFilter(): Observable<RealEstateAgentFilter> {
        return this.httpClient.get<RealEstateAgentFilter>(RealEstateAgentService.filterUri);
    }

    public linkToLocations(realEstateAgentId: string, locations: string[]): Observable<void> {
        return this.httpClient.post<void>(StringUtils.parseUri(RealEstateAgentService.linkToLocationsUri, { id: realEstateAgentId }), {
            locations,
        });
    }

    public getByConfirmationToken(confirmationToken: string): Observable<RealEstateAgent> {
        const httpParams: HttpParams = new HttpParams().set('confirmationToken', confirmationToken);
        return this.httpClient.get<RealEstateAgent>(RealEstateAgentService.byConfirmationTokenUri, {
            params: httpParams,
        });
    }

    public confirmInvite(
        confirmationToken: string,
        realEstateAgentRegisterRequest: RealEstateAgentRegisterRequest
    ): Observable<TokenResponse> {
        const httpParams: HttpParams = new HttpParams().set('confirmationToken', confirmationToken);
        return this.httpClient.patch<TokenResponse>(RealEstateAgentService.confirmInviteUri, realEstateAgentRegisterRequest, {
            params: httpParams,
        });
    }

    public getLeads(realEstateAgentFilter: RealEstateAgentFilter): Observable<RealEstateAgent[]> {
        let httpParams: HttpParams = new HttpParams();
        const filter: RealEstateAgentFilter = {};
        if (realEstateAgentFilter?.search) {
            filter.search = realEstateAgentFilter?.search;
        }
        httpParams = httpParams.append('filter', JSON.stringify(filter));
        return this.httpClient.get<RealEstateAgent[]>(RealEstateAgentService.leadsUri, {
            params: httpParams,
        });
    }

    public patch(realEstateAgentId: string, realEstateAgent: RealEstateAgent): Observable<RealEstateAgent> {
        return this.httpClient.patch<RealEstateAgent>(
            RealEstateAgentService.realEstateAgentUri.replace(':realEstateAgentId', realEstateAgentId),
            realEstateAgent
        );
    }

    public register(realEstateAgentRegisterRequest: RealEstateAgentRegisterRequest): Observable<void> {
        return this.httpClient.post<void>(RealEstateAgentService.registerUri, realEstateAgentRegisterRequest);
    }

    public confirmLogin(confirmLoginRequest: ConfirmLoginRequest): Observable<RealEstateAgent> {
        const httpParams: HttpParams = new HttpParams().set('confirmationToken', confirmLoginRequest.confirmationToken);
        return this.httpClient.post<RealEstateAgent>(RealEstateAgentService.confirmUri, null, {
            params: httpParams,
        });
    }

    public invite(realEstateAgentInvite: RealEstateAgentInviteRequest): Observable<void> {
        return this.httpClient.post<void>(RealEstateAgentService.inviteUri, realEstateAgentInvite);
    }

    public sendRegisterEmail(id: string): Observable<void> {
        return this.httpClient.get<void>(RealEstateAgentService.sendRegisterEmailUri.replace(':id', id));
    }

    public reactivate(id: string): Observable<void> {
        return this.httpClient.post<void>(StringUtils.parseUri(RealEstateAgentService.reactivateUri, { id }), null);
    }

    public deactivate(id: string): Observable<void> {
        return this.httpClient.post<void>(StringUtils.parseUri(RealEstateAgentService.deactivateUri, { id }), null);
    }
}
