import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DateModule } from '@app/date/date.module';
import { UserNamePipe } from '@app/move/pipes/user-name.pipe';
import { APP_DATE_FORMATS } from '@app/shared/adapters/date-adapter.constants';
import { AppDateAdapter } from '@app/shared/adapters/date.adapter';
import { TableColumnCreatedOnComponent } from '@app/shared/components/table-column-created-on/table-column-created-on.component';
import { UiModule } from '@app/ui/ui.module';
import { WizardModule } from '@app/ui/wizard/wizard.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, CardsModule, DocumentCenterModule as UiDocumentCenterModule, IconModule, PipeModule } from '@smooved/ui';
import { FormModule } from '../form/form.module';
import { IsNumberPipe } from '../move/pipes/is-number.pipe';
import { CheckboxSelectComponent } from '../wizard/components/checkbox-select/checkbox-select.component';
import { ContainerComponent } from '../wizard/components/container/container.component';
import { GenderInputComponent } from '../wizard/components/gender-input/gender-input.component';
import { NumberStepperComponent } from '../wizard/components/number-stepper/number-stepper.component';
import { RadioSelectComponent } from '../wizard/components/radio-select/radio-select.component';
import { SelectInputComponentDeprecated } from '../wizard/components/select-input-deprecated/select-input.component';
import { SubSelectInputComponent } from '../wizard/components/sub-select-input/sub-select-input.component';
import { WizardInputComponent } from '../wizard/components/wizard-input/wizard-input.component';
import { BeamerPluginComponent } from './components/beamer-plugin/beamer-plugin.component';
import { BusNumberComponent } from './components/bus-number/bus-number.component';
import { CancellationDateComponent } from './components/cancellation-date/cancellation-date.component';
import { CarouselSuggestionComponent } from './components/carousel-suggestion/carousel-suggestion.component';
import { CurrentSupplierComponent } from './components/current-supplier/current-supplier.component';
import { HomeConstructionYearComponent } from './components/home-construction-year/home-construction-year.component';
import { HouseNumberComponent } from './components/house-number/house-number.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import { SectionComponent } from './components/section/section.component';
import { SubMunicipalityComponent } from './components/sub-municipality/sub-municipality.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { WaterMeterReadingDetailsComponent } from './components/water-meter-reading-details/water-meter-reading-details.component';
import { FamilyMemberCountContainer } from './containers/family-member-count/family-member-count.container';
import { HomeDescriptionContainer } from './containers/home-description/home-description.container';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';

const materialModules = [
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
];

const modules = [
    CommonModule,
    NgxMaskDirective,
    RouterModule,
    FormModule,
    TranslateModule,
    UiModule,
    IconModule,
    CardsModule,
    PipeModule,
    ButtonModule,
    WizardModule,
    UiDocumentCenterModule,
];

const containers = [FamilyMemberCountContainer, HomeDescriptionContainer];

const components = [
    SelectInputComponentDeprecated,
    SubSelectInputComponent,
    CheckboxSelectComponent,
    WizardInputComponent,
    SuggestionComponent,
    CarouselSuggestionComponent,
    ContainerComponent,
    GenderInputComponent,
    NumberStepperComponent,
    HomeConstructionYearComponent,
    CancellationDateComponent,
    CurrentSupplierComponent,
    HouseNumberComponent,
    BusNumberComponent,
    SubMunicipalityComponent,
    RadioSelectComponent,
    TableColumnCreatedOnComponent,
    UserNameComponent,
    SectionComponent,
    RibbonComponent,
    BeamerPluginComponent,
    WaterMeterReadingDetailsComponent,
];

const pipes = [UserNamePipe, IsNumberPipe];

@NgModule({
    imports: [...materialModules, ...modules, DateModule],
    declarations: [...containers, ...components, ...pipes],
    exports: [...materialModules, ...modules, DateModule, ...containers, ...components, ...pipes],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'nl-BE' },
        CurrencyPipe,
        provideEnvironmentNgxMask(),
    ],
})
export class SharedModule {}
