<ng-container
    *ngIf="{ hasError: isErrorState() && (formControl.invalid || meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').invalid) } as vm">
    <smvd-ui-card
        [border]="context !== uiContext.None"
        [class.has-error]="vm.hasError"
        [context]="context"
        [hasPadding]="context !== uiContext.None"
        [ngClass]="'has-context-' + context"
    >
        <form [formGroup]="missingInformationForm">
            <div *ngIf="hasMissingLeaverInfo" class="u-margin-bottom">
                <h6>{{ 'LEAVER.TITLE' | i18nKeyTranslate : appI18nKeyType.TakeOverInformation }}</h6>
                <p class="u-margin-bottom">{{ 'LEAVER.LABEL' | i18nKeyTranslate : appI18nKeyType.TakeOverInformation }}</p>
                <div class="u-flex-responsive">
                    <smvd-ui-text-input
                        [formControlName]="fields.LeaverFirstName"
                        [label]="'UI.FIRST_NAME.LABEL' | translate"
                        class="u-margin-right"
                    >
                    </smvd-ui-text-input>
                    <smvd-ui-text-input [formControlName]="fields.LeaverLastName" [hasMargin]="false"
                                        [label]="'UI.LAST_NAME.LABEL' | translate">
                    </smvd-ui-text-input>
                </div>
            </div>
            <div *ngIf="hasMissingReaInfo" class="u-margin-bottom">
                <h6>{{ 'REAL_ESTATE_AGENT.TITLE' | i18nKeyTranslate : appI18nKeyType.TakeOverInformation }}</h6>
                <p
                    [innerHTML]="'REAL_ESTATE_AGENT.LABEL' | i18nKeyTranslate : appI18nKeyType.TakeOverInformation"
                    class="u-margin-bottom"
                ></p>
                <div class="u-flex-responsive">
                    <smvd-ui-text-input
                        [formControlName]="fields.RealEstateAgentFirstName"
                        [label]="'YOUR_FIRST_NAME' | translate"
                        class="u-margin-right"
                    >
                    </smvd-ui-text-input>
                    <smvd-ui-text-input
                        [formControlName]="fields.RealEstateAgentLastName"
                        [hasMargin]="false"
                        [label]="'YOUR_LAST_NAME' | translate"
                    >
                    </smvd-ui-text-input>
                </div>
            </div>
        </form>
        <ngContainer [formGroup]="meterReadingsTakeoverForm">
            <app-check-input
                [custom]="false"
                [formControlName]="'meterReadingsTakeoverFormControl'"
                [hasMarginDouble]="false"
                [hasMargin]="false"
                [id]="checkInputId"
                [option]="meterReadingsTakeoverOption"
                required
                width="auto"
            ></app-check-input>
            <mat-error *ngIf="vm.hasError" class="u-margin-top-half u-font-size-small">{{
                    'ERROR' | i18nKeyTranslate : appI18nKeyType.TakeOverInformation
                }}
            </mat-error>
        </ngContainer>
    </smvd-ui-card>
</ng-container>
