import { Component, Inject, Input, OnChanges, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExternalInfluencer, RxjsComponent, skipWhileNull } from '@smooved/core';
import { takeUntil, zip } from 'rxjs';
import { ApplicationMode } from '../../../application';
import { ReviewInsightsSandbox } from '../../../reviews/sandboxes/review-insights.sandbox';
import { UiSandbox } from '../../../ui.sandbox';
import { HeaderBoxData } from '../../components/header-box/header-box-data';
import { CompanyPageRouterOutlet } from '../../enums';
import { LocationProfile } from '../../interfaces';
import { COMPANY_PAGE_COVER_FALLBACK } from './company-page-template.constants';
import { CompanyPageTemplateService } from './company-page-template.service';
import { isPlatformBrowser } from '@angular/common';

const aspectRatio = 4;
const maxWidth = 1256;
const maxHeight = 314;

// Calculate width based on viewport size, constrained by maxWidth

@Component({
    selector: 'ui-company-page-template',
    templateUrl: './company-page-template.container.html',
    styleUrls: ['./company-page-template.container.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyPageTemplateContainer extends RxjsComponent implements OnInit, OnChanges {
    public applicationMode: ApplicationMode;
    public hasInfoSidebarTemplate = false;
    public hasInfoSidebarStickyTemplate = false;
    public isStickyHeaderMenu = false;
    public headerBoxData: HeaderBoxData;

    public height = 0;
    public width = 0;

    @Input() public locationProfile: LocationProfile;
    @Input() public externalInfluencer: ExternalInfluencer;

    public readonly isTabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$;
    public readonly contentAsideOutlet = CompanyPageRouterOutlet.ContentAside;

    public readonly hasContentAside$ = this.companyPageTemplateService.hasContentAside$;
    public readonly hasContentAsideSticky$ = this.companyPageTemplateService.hasContentAsideSticky$;
    public readonly hasFooter$ = this.companyPageTemplateService.hasFooter$;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly uiSandbox: UiSandbox,
        private readonly companyPageTemplateService: CompanyPageTemplateService,
        private readonly reviewInsightsSandbox: ReviewInsightsSandbox,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(COMPANY_PAGE_COVER_FALLBACK) @Optional() public readonly companyPageCoverFallback: string
    ) {
        super();
    }

    public ngOnInit(): void {
        const { queryParams } = this.activatedRoute.snapshot;
        const { draft } = queryParams;
        this.setApplicationMode(draft === 'true');

        const viewportWidth = isPlatformBrowser(this.platformId) ? window.innerWidth : 0;
        this.width = isPlatformBrowser(this.platformId) ? Math.min(viewportWidth, maxWidth) : 1256;
        this.height = isPlatformBrowser(this.platformId) ? Math.min(this.width / aspectRatio, maxHeight) : 314;
    }

    public ngOnChanges(): void {
        this.headerBoxData = new HeaderBoxData(this.externalInfluencer, this.locationProfile);

        if (!this.locationProfile?.reviewScore) {
            zip([this.reviewInsightsSandbox.houseMatchScore$, this.reviewInsightsSandbox.amountOfReviews$])
                .pipe(skipWhileNull(), takeUntil(this.destroy$))
                .subscribe(this.setReviewScore);
            this.reviewInsightsSandbox.getHouseMatchScore(this.externalInfluencer.realEstateGroup);
        }
    }

    public onHeaderBoxVisibleChanged(event: boolean): void {
        this.isStickyHeaderMenu = !event;
    }

    private setApplicationMode(isDraft: boolean): void {
        this.applicationMode = isDraft ? ApplicationMode.Draft : ApplicationMode.Default;
    }

    private setReviewScore = ([score, amountOfReviews]: [number, number]) => {
        if (amountOfReviews > 1) {
            this.headerBoxData = {
                ...this.headerBoxData,
                noReviews: false,
                personalScore: true,
                scores: {
                    ...this.headerBoxData.scores,
                    overall: {
                        ...this.headerBoxData.scores.overall,
                        score: score,
                        total: amountOfReviews,
                    },
                },
            };
        }
    };
}
