<app-tri-panel-modal *ngIf="{order: (giftDetailService.order$ | async)} as vm" [loading]="giftDetailService.loading$ | async">
    <ng-container modal-header>
        <h5>{{'AN_ORDER' | translate }} {{vm.order.orderId}}</h5>
    </ng-container>

    <ng-container modal-left>
        <h5 class="u-margin-bottom">{{"DELIVERY_DETAILS" | translate}}</h5>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'DELIVERY_DATE_FROM' | translate }}</h6>
            <app-copy-to-clipboard>{{ vm.order.deliveryDate | formatDate}}</app-copy-to-clipboard>
        </div>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'FOR' | translate }}</h6>
            <app-user-details class="u-display-block u-margin-bottom-double" [order]="vm.order"></app-user-details>
        </div>

        <div class="u-margin-bottom-double">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'DELIVERY_ADDRESS' | translate }}</h6>
            <app-copy-to-clipboard>{{ vm.order.deliveryAddress | formatAddress}}</app-copy-to-clipboard>
        </div>

        <div *ngIf="vm.order.realEstateAgent">
            <h6 class="u-color-muted u-margin-bottom-half">{{ 'PARTNER.GIFT.SENDER' | translate }}</h6>
            <app-real-estate-agent-details
                [agent]="data.order.realEstateAgent"
                [group]="data.order.realEstateGroup"
            ></app-real-estate-agent-details>
        </div>
    </ng-container>

    <ng-container modal-center>
        <h5 class="u-margin-bottom">{{'GIFT' | translate }}</h5>
        <app-gift-details [order]="vm.order"></app-gift-details>
    </ng-container>

    <ng-container modal-right>
        <h5 class="u-margin-bottom">{{"STATE" | translate}}</h5>

        <app-check-item-created-on class="u-display-block u-margin-bottom-double" [order]="vm.order"></app-check-item-created-on>

        <app-check-item-gift-prepared
            class="u-display-block u-margin-bottom-double"
            [order]="vm.order"
            (patch)="onPatch($event)"
        ></app-check-item-gift-prepared>

        <app-check-item-gift-sent
            class="u-display-block u-margin-bottom-double"
            [order]="vm.order"
            (patch)="onPatch($event)"
        ></app-check-item-gift-sent>

        <app-textarea-input
            width="100%"
            [label]="'PARTNER.ORDER_DETAIL.ORDER_NOTE.LABEL' | translate"
            [hasMarginDouble]="false"
            [hasMargin]="false"
            [rows]="5"
            [(ngModel)]="vm.order.noteForSmooved"
            (ngModelChange)="onOrderNoteChange($event)"
            [ngModelOptions]="{ updateOn: 'blur' }"
        ></app-textarea-input>
    </ng-container>
</app-tri-panel-modal>
