// Watch out for usages Object.values(ColorScale)
export enum ColorScale {
    Color1 = '#7cdbd5',
    Color2 = '#02c8a7',
    Color3 = '#00887a',
    Color4 = '#045056',
    Color5 = '#8a9a9b',
}

export enum Colors {
    Black = '#000000',
    White = '#FFFFFF',
    Warning = '#F9BE02',
    Danger = '#F53240',
    Transparent = 'transparent',
    Success = '#00887a',
    GrayLight = '#eef3f4',
}

export enum GrayScale {
    Lightest = '#ecf0f0',
    Light = '#c3cfd0',
    Medium = '#f8f8ff',
    Dark = '#435758',
}

export enum Opacity {
    Transparent = 0,
    SemiTransparent = 0.33,
    Half = 0.5,
    SemiOpaque = 0.66,
    Opaque = 1,
}
