<form [formGroup]="form">
    <div class="u-flex-column">
        <app-check-input
            *ngIf="options.mover as option"
            [option]="option"
            class="u-margin-right-half u-margin-bottom-half"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="contactFields.Mover"
        >
        </app-check-input>
        <app-check-input
            *ngIf="options.linkedMove as option"
            [option]="option"
            class="u-margin-right-half u-margin-bottom-half"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="contactFields.LinkedMove"
        >
        </app-check-input>
    </div>
</form>
