import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BaseInput } from '@smooved/ui';
import { ColorPickerDirective } from 'ngx-color-picker';

@Component({
    selector: 'app-color-input',
    template: `
        <app-label-container
            [required]="required"
            [id]="id"
            [label]="label"
            [ngSwitch]="!!matAutocomplete"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
        >
            <div class="__input-container u-bordered">
                <input
                    [(colorPicker)]="innerModel"
                    class="__input u-cursor-pointer"
                    [style.background]="innerModel"
                    [style.color]="innerModel"
                    [(ngModel)]="innerModel"
                    matInput
                    readonly
                    #input
                    (blur)="onBlur($event)"
                    [errorStateMatcher]="errorStateMatcher"
                    [disabled]="innerDisabled"
                    (cpSliderChange)="onSliderChange($event)"
                    (cpInputChange)="onInputChange($event)"
                />
            </div>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./color-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorInputComponent),
            multi: true,
        },
    ],
})
export class ColorInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public matAutocomplete: MatAutocomplete;

    @ViewChild(ColorPickerDirective, { static: true })
    public colorPicker: ColorPickerDirective;

    public innerModel: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }

    public onSliderChange(event: { slider: string; value: string | number; color: string }): void {
        this.propagateChange(this.innerModel);
    }

    public onInputChange(event: { input: string; value: number | string; color: string }): void {
        this.propagateChange(this.innerModel);
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }
}
