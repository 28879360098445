import { Pipe, PipeTransform } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Pipe({
    name: 'userName',
})
export class UserNamePipe implements PipeTransform {
    transform(value: Move, emptyAs = ''): string {
        if (!value?.user) return emptyAs;
        return value.user.formattedName || value.user.email || emptyAs;
    }
}
