import { Injectable } from '@angular/core';
import { AnalyticsSandbox } from '@app/analytics/analytics.sandbox';
import { defaultLevel } from '@app/analytics/constants/analytics.constants';
import { MoveCountByOtsConfirmationClusterLevel } from '@app/analytics/interfaces/move-count-by-ots-confirmation-cluster-level';
import { MovesByRealEstateAgentPerWeek } from '@app/move/interfaces/move-by-real-estate-agent-per-week-response.interface';
import { MoveCountByCreatedFlow } from '@app/move/interfaces/move-count-by-created-flow';
import { MoveCountByCreatedFlowPerQuarter } from '@app/move/interfaces/move-count-by-created-flow-per-quarter';
import { MovingDateVsCreatedOnGap } from '@app/move/interfaces/moving-date-vs-created-on-gap';
import { Interval } from '@app/shared/enums/interval.enum';
import { PerUnitOfTime } from '@app/shared/enums/per-unit-time.enum';
import { RealEstateLevel } from '@smooved/core';
import { ReviewBucketRequest, ReviewsFilter } from '@smooved/ui';
import { Observable } from 'rxjs';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';
import { SmoovedAnalyticsService } from '../services/smooved-analytics.service';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class SmoovedAnalyticsSandbox {
    constructor(private readonly analyticsSandbox: AnalyticsSandbox, private readonly smoovedAnalyticsService: SmoovedAnalyticsService) {}

    public getWeeklyMoveCounts(from: Date, until: Date): Observable<MovesByRealEstateAgentPerWeek[]> {
        return this.analyticsSandbox.getWeeklyMoveCounts(from, until);
    }

    public getMoveCountByCreatedFlow(
        perUnit: PerUnitOfTime,
        from: Date,
        until = new Date(),
        level = defaultLevel
    ): Observable<MoveCountByCreatedFlowPerQuarter[]> {
        return this.smoovedAnalyticsService.getMoveCountByCreatedFlow(from, until, perUnit, level);
    }

    public getConfirmationsAnalytics(
        interval?: Interval,
        level?: RealEstateLevel,
        from?: Date,
        until?: Date
    ): Observable<MoveCountByCreatedFlow[]> {
        return this.analyticsSandbox.getConfirmationsAnalytics(interval, level, from, until);
    }

    public getMovingDateVsCreatedOnGap(
        perUnit: PerUnitOfTime,
        from: Date,
        until = new Date(),
        level = defaultLevel
    ): Observable<MovingDateVsCreatedOnGap> {
        return this.smoovedAnalyticsService.getMovingDateVsCreatedOnGap(from, until, perUnit, level);
    }

    public getReviewBuckets(reviewFilter: ReviewsFilter): Observable<ReviewBucketRequest> {
        return this.analyticsSandbox.getReviewBuckets(reviewFilter);
    }

    public getOtsConfirmationClusterLevels(
        interval: Interval,
        level: RealEstateLevel = defaultLevel,
        from?: Date,
        until?: Date
    ): Observable<MoveCountByOtsConfirmationClusterLevel[]> {
        return this.analyticsSandbox.getOtsConfirmationClusterLevel(interval, level, from, until);
    }
}
