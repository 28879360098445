import { Component, forwardRef, Host, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {BaseInput, ButtonAppearance, ButtonSize, UiContext} from '@smooved/ui';

@Component({
    selector: 'app-file-input',
    template: `
        <button
            app-button
            [context]="context"
            [appearance]="buttonAppearance"
            [size]="buttonSize"
            [disabled]="disabled"
            (click)="input.click()"
        >
            {{ label }}
        </button>
        <input
            hidden
            type="file"
            [multiple]="multiple"
            [accept]="accept"
            #input
            [(ngModel)]="innerModel"
            (ngModelChange)="onModelChange()"
            [disabled]="disabled"
        />
        <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent extends BaseInput implements ControlValueAccessor {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public accept = '*/*';
    @Input() public formControlName: string;
    @Input() public multiple = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public disabled = false;
    @Input() public buttonAppearance = ButtonAppearance.Stroked;
    @Input() public buttonSize = ButtonSize.Default;
    @Input() public context = UiContext.Secondary;

    public innerModel: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
