import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { GetOrdersRequest } from '@app/order/interfaces/get-orders-request';
import { OrderListContainerService } from '@app/partner/services/order-list-container.service';
import { OrderState, RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';

@Component({
    template: '',
})
export class OrderListBaseContainer extends RxjsComponent implements OnInit {
    protected orderState: OrderState;
    protected tableComponent: CoreTableComponent;

    constructor(public readonly orderListContainerService: OrderListContainerService, public readonly activatedRoute: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.fetch();
        this.orderListContainerService
            .initSearchControl()
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => {
                this.fetch();
            });
        const { id } = this.activatedRoute.snapshot.params;
        if (id) this.orderListContainerService.openDetail(id, this.handleOrderPatched);
    }

    public fetch(): void {
        const { sortField, sortDirection, pageIndex, pageSize } = this.tableComponent.getPaginationOptions();
        const request: GetOrdersRequest = {
            states: [this.orderState],
            pageIndex,
            pageSize,
            sortField,
            sortDirection,
        };
        if (this.orderListContainerService.searchControl.value as string) {
            request.search = this.orderListContainerService.searchControl.value as string;
        }
        this.orderListContainerService.fetch(request);
    }

    public handleOrderPatched = (): void => {
        this.fetch();
    };
}
