import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthUser, Role } from '@smooved/core';
import { AuthenticationActions, AuthenticationActionTypes } from './authentication.actions';

export interface AuthenticationState {
    authorization: AuthUser;
}

export const initialAuthenticationState: AuthenticationState = {
    authorization: null,
};

export function authenticationReducer(state = initialAuthenticationState, action: AuthenticationActions): AuthenticationState {
    switch (action.type) {
        case AuthenticationActionTypes.SetAuthorization:
            return {
                ...state,
                ...{
                    authorization: action.payload,
                },
            };

        case AuthenticationActionTypes.LogoutSuccess:
            return {
                ...initialAuthenticationState,
            };

        default:
            return state;
    }
}

export const getAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');

export const getAuthorizationState = createSelector(getAuthenticationState, (authenticationState) => authenticationState.authorization);

export const getUserIdState = createSelector(getAuthorizationState, (authorizationState) => authorizationState?.id);

export const getEmailState = createSelector(getAuthorizationState, (authorizationState) => authorizationState?.email);

export const getRoleState = createSelector(getAuthorizationState, (authorizationState) => authorizationState?.role);

export const getIsRealEstateAgentOrAdmin = createSelector(
    getRoleState,
    (role) => role && (role === Role.Admin || role === Role.RealEstateAgent)
);

export const getRealEstateAgentIdState = createSelector(
    getAuthorizationState,
    (authorizationState) => authorizationState?.realEstateAgentId
);

export const getAdminIdState = createSelector(getAuthorizationState, (authorizationState) => authorizationState?.id);
