import { Component, OnInit } from '@angular/core';
import {
    HeaderPartnerAgentMenuConfig,
    HeaderPartnerAgentMenuItem,
} from '@app/header/interfaces/header-partner-agent-menu-config.interface';
import { MenuItemSize } from '@smooved/ui';
import { HeaderPartnerAgentMenuService } from './header-partner-agent-menu.service';

@Component({
    selector: 'app-header-partner-agent-menu',
    templateUrl: './header-partner-agent-menu.component.html',
})
export class HeaderPartnerAgentMenuComponent implements OnInit {
    constructor(private readonly headerService: HeaderPartnerAgentMenuService) {}

    public config: HeaderPartnerAgentMenuConfig;
    public items: HeaderPartnerAgentMenuItem[];
    public readonly menuSize = MenuItemSize;

    public ngOnInit(): void {
        this.headerService.headerChanged.subscribe((config) => {
            this.config = config;
            this.items = this.headerService.getMenuItems();
        });
    }
}
