<table
    mat-table
    [dataSource]="dataSource"
    [class.u-data-table-with-hover]="!tableDef.suppressRowHoverHighlight"
    *ngIf="dataSource && colsConfig?.length"
>
    <ng-container [matColumnDef]="col.id" *ngFor="let col of colsConfig; index as index; trackBy: trackByColId">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="u-text-align-{{ col.alignment }} u-vertical-align-{{ col.verticalAlignment }}"
            [style.width]="col.width"
        >
            {{ col.header | translate | titlecase }}
        </th>
        <td mat-cell *matCellDef="let row" class="u-text-align-{{ col.alignment }} u-vertical-align-{{ col.verticalAlignment }}">
            <div uiCellTemplateLoader *ngIf="col.template; else nonTemplate" [template]="col.template" [row]="row"></div>
            <ng-template #nonTemplate>
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="colTypes.Number">
                        {{ row[col.id] | number }}
                    </ng-container>
                    <ng-container *ngSwitchCase="colTypes.Date">
                        {{ row[col.id] | date }}
                    </ng-container>
                    <ng-container *ngSwitchCase="colTypes.Currency">
                        {{ row[col.id] | currency: col.currency }}
                    </ng-container>
                    <ng-container *ngSwitchCase="colTypes.Email">
                        <a
                            *ngIf="row[col.id] as email"
                            smvd-ui-button
                            [appearance]="buttonAppearance.Link"
                            [href]="email | uriSchema: uriSchemaType.MailTo"
                            class="u-h-auto u-padding-none"
                            >{{ email }}</a
                        >
                    </ng-container>
                    <ng-container *ngSwitchCase="colTypes.Phone">
                        <a
                            *ngIf="row[col.id] as phoneNumber"
                            smvd-ui-button
                            [appearance]="buttonAppearance.Link"
                            [href]="phoneNumber | uriSchema: uriSchemaType.Tel"
                            class="u-h-auto u-padding-none"
                            >{{ phoneNumber }}</a
                        >
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ row[col.id] }}
                    </ng-container>
                </ng-container>
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="colFixPaddingEndTable">
        <th mat-header-cell *matHeaderCellDef [style.width.px]="1"></th>
        <td mat-cell *matCellDef="let row"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: columns"
        [class]="row | tableRowClass"
        [class.__row-group]="row.isGroup"
        [class.__row--grouped]="!row.isGroup && row.group"
        [class.__row-group--open]="row.isGroup && row.visible"
        [class.u-font-weight-bold]="row.isTotal"
        (click)="onClickRow(row)"
    ></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="columns.length">{{ 'UI.TABLE.NO_DATA_FOUND_AFTER_FILTER' | translate }}</td>
    </tr>
</table>
<ng-container *ngIf="dataSource?.data && !dataSource.data.length">
    <p *ngIf="!tableDef.noDataTemplate; else noDataTemplate" class="__no-data u-font-size-small">
        {{ 'UI.TABLE.NO_DATA_FOUND' | translate }}
    </p>
    <ng-container #noDataTemplate *ngTemplateOutlet="tableDef.noDataTemplate"></ng-container>
</ng-container>
