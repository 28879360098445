import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { Services } from '@app/real-estate-group/interfaces/services';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { BivRealEstateAgent, RealEstateAgency, StringUtils } from '@smooved/core';
import { Theme } from '../interfaces/theme.interface';
import {
    createBivRealEstateAgent,
    fetchBivRealEstateAgents,
    resetRealEstateGroup,
    setBivRealEstateAgents,
    setLocations,
    setLogo,
    setRealEstateGroup,
    setTheme,
    updateBivRealEstateAgent,
} from './real-estate-group.actions';

export interface RealEstateGroupState extends RealEstateGroup {
    _id: string;
    name: string;
    logo: string;
    theme: Theme;
    services: Services;
    locations?: RealEstateAgency[];
    externalInfluencerUrl: string;
    bivRealEstateAgents: BivRealEstateAgent[];
    loading: boolean;
    officeId: string;
}

export const initialRealEstateGroupState: RealEstateGroupState = {
    _id: null,
    name: null,
    logo: null,
    theme: null,
    offices: null,
    services: null,
    externalInfluencerUrl: null,
    bivRealEstateAgents: null,
    loading: null,
    officeId: null,
};

const startLoading = (state): RealEstateGroupState => ({
    ...state,
    loading: true,
});

export const realEstateGroupReducer = createReducer(
    initialRealEstateGroupState,
    on(setRealEstateGroup, (state, { realEstateGroup }) => ({
        ...state,
        ...realEstateGroup,
    })),
    on(resetRealEstateGroup, () => ({
        ...initialRealEstateGroupState,
    })),
    on(setLogo, (state, { logo }) => ({
        ...state,
        logo,
    })),
    on(setTheme, (state, { theme }) => ({
        ...state,
        theme,
        logo: theme.logo,
    })),
    on(setLocations, (state, { locations }) => ({
        ...state,
        locations,
    })),
    on(fetchBivRealEstateAgents, startLoading),
    on(createBivRealEstateAgent, startLoading),
    on(updateBivRealEstateAgent, startLoading),
    on(setBivRealEstateAgents, (state, { agents: bivRealEstateAgents }) => ({
        ...state,
        bivRealEstateAgents,
        loading: false,
    }))
);

export const getRealEstateGroupState = createFeatureSelector<RealEstateGroupState>('real-estate-group');
export const getIdState = createSelector(getRealEstateGroupState, (state) => state._id);
export const getNameState = createSelector(getRealEstateGroupState, (state) => state.name);
export const getLogoState = createSelector(getRealEstateGroupState, (state) => state.logo);
export const getThemeState = createSelector(getRealEstateGroupState, (state) => state.theme);
export const getServicesState = createSelector(getRealEstateGroupState, (state) => state.services);
export const getRealEstateGroupLocations = createSelector(getRealEstateGroupState, (state) => state.locations);
export const getExternalInfluencerUrl = createSelector(
    getRealEstateGroupState,
    (state) => state.externalInfluencerUrl || StringUtils.slugify(state.name)
);
export const getBivRealEstateAgents = createSelector(getRealEstateGroupState, (state) => state.bivRealEstateAgents);
export const getRealEstateGroupStateLoading = createSelector(getRealEstateGroupState, (state) => state.loading);

export const getServiceInsurancesState = createSelector(getServicesState, (state) => state?.insurances);
export const getServiceIotsState = createSelector(getServicesState, (state) => state?.iots && state?.insurances);
export const getServiceLPState = createSelector(getServicesState, (state) => state?.lp);
export const getServiceInsurancesAndIotsState = createSelector(
    getServiceInsurancesState,
    getServiceIotsState,
    (serviceInsurancesState, serviceIotsState) => serviceInsurancesState && serviceIotsState
);
export const storeFeatureName = 'real-estate-group';
