import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getReviewInsightsHouseMatchScoreAction, getReviewInsightsOtherScoresAction } from '../state/actions';
import { getAmountOfReviews, getHouseMatchScore, getLoading, ReviewInsightsState } from '../state/reducer';

@Injectable({
    providedIn: 'root',
})
export class ReviewInsightsSandbox {
    public readonly loading$ = this.store$.pipe(select(getLoading));
    public readonly houseMatchScore$ = this.store$.pipe(select(getHouseMatchScore));
    public readonly amountOfReviews$ = this.store$.pipe(select(getAmountOfReviews));

    constructor(private readonly store$: Store<ReviewInsightsState>) {}

    public getHouseMatchScore(realEstateGroupId: string): void {
        this.store$.dispatch(getReviewInsightsHouseMatchScoreAction({ realEstateGroupId }));
    }

    public getOtherScores(realEstateGroupIds: string[]): void {
        this.store$.dispatch(getReviewInsightsOtherScoresAction({ realEstateGroupIds }));
    }
}
