import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RealEstateAgency, SimpleChangesUtils, StringUtils } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { UiIconSize } from '../../../icon';
import { UriSchemaType } from '../../../pipes';
import { UiContext } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { googleMapsDefaultOptions } from '../../constants/google.constants';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'smvd-ui-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeComponent implements OnInit, OnChanges {
    @Input() public office: RealEstateAgency;
    @Input() public phoneNumber: string;

    @HostBinding('id') public id: string;

    public mapOptions: google.maps.MapOptions;
    public name: string;

    public readonly isTabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly type = UriSchemaType;
    public readonly iconSize = UiIconSize;
    public contactRoute: string[];

    constructor(private readonly uiSandbox: UiSandbox, private readonly navigationService: NavigationService) {}

    public ngOnInit(): void {
        this.contactRoute = this.navigationService.getContact();
    }

    public ngOnChanges({ office }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(office)) {
            this.mapOptions = this.mapOptionsFactory(this.office);
            this.id = StringUtils.slugify(this.office.name);
            this.name = this.office.name;
        }
    }

    private mapOptionsFactory(office: RealEstateAgency): google.maps.MapOptions {
        return {
            center: office.address?.geometry,
            ...googleMapsDefaultOptions,
        };
    }
}
