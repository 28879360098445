<div class="u-flex-column u-flex-align-items-center u-flex-justify-content-center">
    <div>
        <span class="score u-font-size-28">{{ score }}</span>
        <span class="u-color-gray">/10</span>
    </div>
    <ui-star-score [score]="score / 2" [max]="5" [context]="uiContext.Terracota"></ui-star-score>
    <app-svg-illustration
        *ngIf="!smallVersion"
        class="u-margin-top"
        [svg]="svgIllustration.HouseMatchLogo"
        [context]="uiContext.Aubergine"
    ></app-svg-illustration>
</div>
