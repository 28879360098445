import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-name',
    template: `
        <!-- Name Definition -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="__cell __cell--name">
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.NAME' | translate }}
            </th>
            <td
                mat-cell
                *matCellDef="let row"
                class="__cell __cell--name u-text-truncate"
                [title]="row.user.formattedName || row.user.email"
            >
                <app-user-name [user]="row.user"></app-user-name>
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-name.component.scss'],
})
export class TableColumnNameComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
