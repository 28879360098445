import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, ExternalInfluencer, I18nKeyUtils, MatchSearchResult } from '@smooved/core';
import { ModalSandbox } from '../../modal';
import { defaultModalConfig } from '../../modal/modal.constants';
import { ReviewFiltersModalComponent } from '../../reviews/modals/review-filters-modal/review-filters-modal.component';
import { CompanyPageProvidersModule } from '../company-page-providers.module';
import { claimBusinessFormIds, CompanyPageI18nKeyType, companyPageI18nKeyTypeMap } from '../constants';
import { SmoovedApplicationSource } from '../enums';
import { InfluencerScoreModalData, RealEstateGroupAppointment } from '../interfaces';
import { AppointmentModalConfig } from '../interfaces/appointment-modal-config';
import { InfluencerScoreModal } from '../modals/influencer-score/influencer-score.modal';
import {
    messageDescriptionResourceAppointment,
    messageDescriptionResourceCallback,
    prefillAppointment,
    prefillCallback,
    titleResourceAppointment,
    titleResourceAppointmentMultiSelect,
    titleResourceCallback,
    titleResourceMessage,
} from '../modals/make-appointment/make-appointment.constants';
import { MakeAppointmentData } from '../modals/make-appointment/make-appointment.interfaces';
import { MakeAppointmentModal } from '../modals/make-appointment/make-appointment.modal';

@Injectable({ providedIn: CompanyPageProvidersModule })
export class ModalsService {
    constructor(private readonly modalSandbox: ModalSandbox, private readonly translate: TranslateService) {}

    public openReviewFilterModal(): void {
        this.modalSandbox.openDialog({
            componentForMobile: ReviewFiltersModalComponent,
            componentForTabletPortraitUp: ReviewFiltersModalComponent,
            fullScreenForMobile: false,
            configForMobile: {
                maxWidth: 'calc(100vw - 8px)',
                // maxWidth: 'none',
            },
        });
    }

    public influencerScoreModal(data: InfluencerScoreModalData): void {
        const header = this.translate.instant('REVIEWS.SCORE.MODAL.TITLE') as string;

        this.modalSandbox.openClosableModal({
            component: InfluencerScoreModal,
            config: { data, header, panelClass: [...defaultModalConfig.panelClass, 'u-background-white'] },
            componentForTabletPortraitUp: InfluencerScoreModal,
            configForTabletPortraitUp: { data, header },
            fullScreenForMobile: true,
        });
    }

    public openAppointment(
        externalInfluencer: ExternalInfluencer,
        source?: SmoovedApplicationSource,
        data?: { firstName?: string; lastName?: string; phoneNumber?: string; email?: string }
    ): void {
        this.openAppointmentModal({
            titleResource: titleResourceAppointment,
            externalInfluencer,
            source,
            messageDescriptionResource: messageDescriptionResourceAppointment,
            prefill: this.translate.instant(prefillAppointment),
            data,
        });
    }

    public openMultiSelect(
        selectedBrokers: MatchSearchResult[],
        afterClosed: (result: { source: SmoovedApplicationSource; realEstateGroupAppointment: RealEstateGroupAppointment }) => void
    ): void {
        this.openAppointmentModal({
            titleResource: this.translate.instant(titleResourceAppointmentMultiSelect, {
                numberOfSelectedBrokers: ArrayUtils.getLength(selectedBrokers),
            }),
            selectedBrokers,
            messageDescriptionResource: messageDescriptionResourceAppointment,
            afterClosed,
        });
    }

    public openCallback(externalInfluencer: ExternalInfluencer): void {
        this.openAppointmentModal({
            titleResource: titleResourceCallback,
            externalInfluencer,
            messageDescriptionResource: messageDescriptionResourceCallback,
            prefill: this.translate.instant(prefillCallback),
        });
    }

    public openMessage(externalInfluencer: ExternalInfluencer): void {
        this.openAppointmentModal({
            titleResource: titleResourceMessage,
            externalInfluencer,
        });
    }

    public openClaimBusinessModal(): void {
        this.modalSandbox.openHubspotModal(
            {
                formId: claimBusinessFormIds[this.translate.currentLang],
            },
            <string>this.translate.instant(I18nKeyUtils.map(companyPageI18nKeyTypeMap, CompanyPageI18nKeyType.Claim, 'CTA'))
        );
    }

    private openAppointmentModal({
        titleResource,
        externalInfluencer,
        selectedBrokers,
        messageDescriptionResource,
        prefill,
        afterClosed,
        source,
        data,
    }: AppointmentModalConfig): void {
        const appointmentData: MakeAppointmentData = {
            titleResource,
            externalInfluencer,
            selectedBrokers,
            messageDescriptionResource,
            prefill,
            source,
            data,
        };

        this.modalSandbox.openClosableModal({
            component: MakeAppointmentModal,
            config: {
                data: appointmentData,
                header: titleResource,
                panelClass: defaultModalConfig.panelClass,
                useMobileBackNavigation: false,
                hasActionButtons: true,
            },
            afterClosed,
            fullScreenForMobile: true,
        });
    }
}
