<label class="u-w60p" *ngIf="label">{{ label }}</label>
<div class="u-inline-flex-row u-flex-align-items-center u-w100p" [class.disabled]="disabled">
    <div class="__chart-bar u-w100p">
        <div
            appTooltip
            [label]="total"
            [context]="context"
            class="__fill u-background-{{ context }} u-h100p"
            [style.width.%]="value * 100"
            [tooltipDisabled]="!showTooltip"
        ></div>
    </div>
    <span *ngIf="showValue" class="__percentage">{{ value | percent }}</span>
</div>
