import { InjectionToken } from '@angular/core';
import { BaseUriConfig } from '../../configs';

export class AnalyticsUriConfig extends BaseUriConfig {
    public readonly reviewUri: string;
    public readonly reviewsUri: string;
    public readonly bucketsUri: string;
    private readonly baseUri: string;
    private readonly reviewsByRealEstateGroupId: string;
    private readonly reviewsByRealEstateGroupBaseEndpointUri: string;

    constructor(apiUri: string) {
        super(apiUri);
        this.baseUri = `${this.apiUri}/public/review`;
        this.reviewUri = `${this.baseUri}/:id`;
        this.reviewsByRealEstateGroupBaseEndpointUri = `${this.baseUri}/real-estate-groups`;
        this.reviewsByRealEstateGroupId = `${this.reviewsByRealEstateGroupBaseEndpointUri}/:realEstateGroupId`;
        this.reviewsUri = `${this.reviewsByRealEstateGroupId}`;
        this.bucketsUri = `${this.reviewsByRealEstateGroupId}/buckets`;
    }
}

export const ANALYTICS_URI_CONFIG = new InjectionToken<AnalyticsUriConfig>('ANALYTICS_URI_CONFIG');
