import { Component, Inject, OnInit, Optional } from '@angular/core';
import { StringUtils } from '@smooved/core';
import { MenuItemAppearance } from '../../menu/menu.enums';
import { UiAlignment, UiDirection } from '../../ui.enums';
import { WizardStep } from '../wizard-step.interface';
import { WizardConfig, WIZARD_CONFIG } from '../wizard.constants';
import { WizardService } from '../wizard.service';
import { WizardSideNavItem } from './wizard-side-nav-item.interface';

@Component({
    selector: 'smvd-ui-wizard-side-nav',
    templateUrl: './wizard-side-nav.component.html',
    styleUrls: ['./wizard-side-nav.component.scss'],
})
export class WizardSideNavComponent implements OnInit {
    public readonly direction = UiDirection;
    public readonly alignment = UiAlignment;
    public readonly menuItemAppearance = MenuItemAppearance;
    public wizardSideNavItems: WizardSideNavItem[];
    public i18nKey: string;

    constructor(
        @Optional() @Inject(WIZARD_CONFIG) private readonly wizardConfig: WizardConfig,
        public readonly wizardService: WizardService
    ) {}

    public ngOnInit(): void {
        this.wizardSideNavItems = this.wizardService.getStepsForStepper().map(this.wizardSideNavItemFactory);
    }

    private wizardSideNavItemFactory = (step: WizardStep): WizardSideNavItem => ({
        step,
        i18nKeyStep: `${this.wizardConfig.i18nKeyType || ''}${StringUtils.toUpperSnakeCase(step)}`,
    });
}
