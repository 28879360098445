import { Injectable } from '@angular/core';
import { AuthUser, DbUtils, Me, PostHogEventsEnum } from '@smooved/core';
import posthog from 'posthog-js';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { PosthogGroupTypes } from '@app/tracking/enums/posthog.enum';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';

@Injectable({ providedIn: 'root' })
export class PosthogService {
    constructor(private readonly realEstateGroupSandbox: RealEstateGroupSandbox) {}

    public identify(id: string, user: AuthUser): void {
        posthog.identify(id, { email: user.email, firstName: user.firstName, lastName: user.lastName, role: user.role });
    }

    public resetIdentity(): void {
        posthog.reset();
    }

    public groupByCompany(isAdmin: boolean, realEstateGroup?: RealEstateGroup): void {
        if (isAdmin) {
            this.group(PosthogGroupTypes.Company, 'admin');
            return;
        }
        this.group(PosthogGroupTypes.Company, DbUtils.getStringId(realEstateGroup), {
            name: realEstateGroup.name,
        });
    }

    public groupByOffice(officeId: string, realEstateGroup: RealEstateGroup): void {
        if (!officeId) return;
        const officeName = realEstateGroup.offices.find((office) => DbUtils.getStringId(office) === officeId)?.name;
        this.group(PosthogGroupTypes.Office, officeId, { name: officeName });
    }

    private group(type: PosthogGroupTypes, id: string, properties?: Object): void {
        posthog.group(type, id, properties);
    }

    public sendEventForTransaction(event: PostHogEventsEnum, transactionId: string): void {
        if (!event) return;
        this.realEstateGroupSandbox.realEstateGroupOnce$.subscribe((realEstateGroup) => {
            posthog.capture(event, {
                transactionId,
                realEstateGroupId: DbUtils.getStringId(realEstateGroup),
                officeId: (realEstateGroup as unknown as Me)?.officeId,
                realEstateAgentId: (realEstateGroup as unknown as Me)?.realEstateAgentId,
            });
        });
    }
}
