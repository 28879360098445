<button
    (click)="clickable && goToTopicInsights(topicSentiment.category, topicSentiment.sentiment)"
    class="__review-topic-sentiment u-border-radius-filter u-flex-row u-flex-align-items-center u-margin-bottom-half"
    [class.u-cursor-pointer]="clickable"
    [class.__review-topic-sentiment--positive]="topicSentiment.sentiment === 1"
    [class.__review-topic-sentiment--negative]="topicSentiment.sentiment === -1"
>
    <smvd-ui-svg-illustration
        class="__review-topic-sentiment__icon u-color-inherit u-margin-right-half"
        *ngIf="topicSentiment.sentiment === 1"
        [svg]="svgs.ThumbsUp"
    ></smvd-ui-svg-illustration>
    <smvd-ui-svg-illustration
        class="__review-topic-sentiment__icon u-color-inherit u-margin-right-half"
        *ngIf="topicSentiment.sentiment === -1"
        [svg]="svgs.ThumbsDown"
    ></smvd-ui-svg-illustration>
    {{ 'ANALYTICS.REVIEWS.TOPIC_SENTIMENT.CATEGORIES.' + topicSentiment.category | uppercase | translate }}
    <ng-container *ngIf="topicSentiment.count">({{ topicSentiment.count }})</ng-container>
</button>
