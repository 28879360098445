import { Injectable } from '@angular/core';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { State } from '@app/store/state';
import { SetLanguage } from '@app/translation/state/translation.actions';
import { getSelectedState } from '@app/translation/state/translation.reducer';
import { select, Store } from '@ngrx/store';
import { Language } from '@smooved/core';
import { take } from 'rxjs/operators';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';

@Injectable({
    providedIn: 'root',
})
export class TranslationSandbox {
    public selected$ = this.store$.pipe(select(getSelectedState));
    public selectedOnce$ = this.selected$.pipe(take(1));

    public readonly defaultLanguage: Language = Language.NL;

    constructor(
        private store$: Store<State>,
        private storageSandbox: StorageSandbox
    ) {}

    public setInitialLanguage(language?: Language): Language {
        const requestedLang = language || (this.storageSandbox.getToken(LocalStorageKeys.Language) as Language) || this.defaultLanguage;
        this.store$.dispatch(new SetLanguage({ language: requestedLang }));
        return requestedLang;
    }

    public setLanguage(language: Language): void {
        this.store$.dispatch(new SetLanguage({ language }));
    }
}
