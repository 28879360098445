import { Component } from '@angular/core';
import { Svg } from '@smooved/ui';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { Navigation } from '../../../navigation/enums/navigation.enum';

@Component({
    selector: 'app-telecom-offer-selected-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-flex-column u-flex-align-items-center">
                    <ng-container
                        [ngTemplateOutlet]="cardContent"
                        *ngIf="moveSandbox.telecomOfferSelected$ | async"
                        [ngTemplateOutletContext]="{ suggestion: (moveSandbox.telecomOfferSelection$ | async) }"
                    ></ng-container>
                </app-card>
            </div>
        </app-card-container>

        <ng-template #cardContent let-suggestion="suggestion">
            <img
                [src]="'assets/images/suppliers/' + suggestion.provider.toString().toLowerCase() + '-small.png'"
                height="80px"
                [alt]="'Logo ' + suggestion.provider"
                class="u-margin-bottom-double"
            />

            <div class="u-container-small u-flex-column u-flex-align-items-center">
                <div
                    class="u-flex-row u-flex-align-items-center u-margin-bottom-double u-padding-bottom-double u-bordered-bottom u-w100p u-flex-justify-content-center"
                >
                    <app-check-with-background [width]="22" [height]="22" class="u-margin-right-half"></app-check-with-background>
                    <h5>{{ suggestion.productNameLabels | translatedLabel | async }}</h5>
                </div>

                <ng-container
                    [ngTemplateOutlet]="content"
                    *ngIf="!suggestion.advice; else advice"
                    [ngTemplateOutletContext]="{ type: 'OFFER' }"
                ></ng-container>

                <ng-template #content let-type="type">
                    <h3 class="u-margin-bottom">{{ 'TELECOM.' + type + '_SELECTED.TITLE' | translate }}</h3>

                    <p class="u-font-size-medium u-margin-bottom-double u-text-align-center">
                        {{ 'TELECOM.' + type + '_SELECTED.TEXT' | translate }}
                    </p>

                    <app-button theme="secondary" [routerLink]="energyRoute" class="u-margin-bottom">{{
                        'TELECOM.' + type + '_SELECTED.CTA_MAIN.TEXT' | translate
                    }}</app-button>

                    <a [routerLink]="contractRoute" class="u-link">{{ 'TELECOM.' + type + '_SELECTED.CTA_SECONDARY.TEXT' | translate }}</a>
                </ng-template>

                <ng-template #advice>
                    <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ type: 'ADVICE' }"></ng-container>
                </ng-template>
            </div>
        </ng-template>
    `,
})
export class TelecomOfferSelectedContainer {
    public svg = Svg;
    public energyRoute = `/${Navigation.Energy}`;
    public contractRoute = `/${Navigation.Contract}`;

    constructor(public moveSandbox: MoveSandbox) {}
}
