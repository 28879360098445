import { Component, forwardRef, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { options } from '@app/form/components/language-input/language-input.constants';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { Language } from '@smooved/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-language-input',
    template: `
        <ui-dropdown-input
            [placeholder]="placeholder"
            [label]="label"
            [id]="id"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [width]="width"
            [options]="languageOptions"
            [(ngModel)]="innerModel"
            (ngModelChange)="onModelChange()"
            [required]="required"
            [showRequiredAsterisk]="showRequiredAsterisk"
        ></ui-dropdown-input>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LanguageInputComponent),
            multi: true,
        },
    ],
})
export class LanguageInputComponent extends BaseInput implements ControlValueAccessor {
    @Input() public id = 'language';
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public required: boolean;

    public languageOptions: DropdownInput<string>[] = options;

    public innerModel: Language;

    constructor(@Optional() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public writeValue(value: Language): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
        this.propagateTouched();
    }
}
