export enum UiContext {
    Accent = 'accent',
    CoBrand = 'co-brand-3',
    Danger = 'danger',
    Muted = 'muted',
    Info = 'info',
    Alt = 'alt',
    Primary = 'primary',
    PrimaryDark = 'primary-dark',
    Secondary = 'secondary',
    Success = 'success',
    SuccessDark = 'success-dark',
    Tertiary = 'tertiary',
    Warning = 'warning',
    Text = 'text',
    HouseMatchPrimary = 'housematch-primary',
    HouseMatchSecondary = 'housematch-secondary',
    None = 'default',
    Terracota = 'terracota',
    Aubergine = 'aubergine',
    LightGray = 'gray-light',
}

export enum BadgeAppearance {
    Default = 'default',
    Wide = 'wide',
}

export enum UiDirection {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

export enum UiLabelPosition {
    Before = 'before',
    After = 'after',
}

export enum UiPlacement {
    BottomEnd = 'bottom-end',
    BottomStart = 'bottom-start',
    Bottom = 'bottom',
    LeftEnd = 'left-end',
    LeftStart = 'left-start',
    Left = 'left',
    RightEnd = 'right-end',
    RightStart = 'right-start',
    Right = 'right',
    TopEnd = 'top-end',
    TopStart = 'top-start',
    Top = 'top',
}

export enum UiAlignment {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum UiVerticalAlignment {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

export enum UiSize {
    Default = 'default',
    Tiny = 'ty',
    ExtraExtraSmall = 'xxs',
    ExtraSmall = 'xs',
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    ExtraLarge = 'xl',

    Ty = 'ty',
    Xxs = 'xxs',
    Xs = 'xs',
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
    Xl = 'xl',
}

export enum ScreenSize {
    Phone = 'phone',
    Tablet = 'tablet',
    LargeTablet = 'largeTablet',
    TabletLandscape = 'tabletLandscape',
    Desktop = 'desktop',
    LargeDesktop = 'largeDesktop',
    BigDesktop = 'bigDesktop',
}

export enum UiHeaderVariant {
    Default = 'h4',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    Span = 'span',
    Label = 'label',
    Svg = 'svg',
}

export enum UiRotate {
    Default = '',
    Deg45 = '45',
    Deg90 = '90',
    Deg135 = '135',
    Deg180 = '180',
    Deg225 = '225',
    Deg270 = '270',
    Deg315 = '315',
}

export enum Cursor {
    Default = 'default',
    Pointer = 'pointer',
}

// assets basePath: /assets/images/
export enum Image {
    MatchHands = 'house-match-hands.svg',
}
