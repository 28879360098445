import { Injectable } from '@angular/core';
import { RealEstateGroupInvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { InvoicesRequest } from '@app/invoicing/interfaces/invoices-request';
import { InvoicingService } from '@app/invoicing/services/invoicing.service';
import {
    getBillableInvoices,
    getInvoices,
    getInvoicesSent,
    getLastBillableInvoice,
    getQuarter,
    getQuarterlyInvoicesTotals,
    getQuarterlyInvoicing,
    setQuarter,
} from '@app/invoicing/state/invoicing.actions';
import {
    getBillableInvoicesLoadingState,
    getBillableInvoicesState,
    getCommissionsPaidState,
    getInvoiceLoadingState,
    getInvoicesCountState,
    getInvoicesLoadingState,
    getInvoicesSentLoadingState,
    getInvoicesSentState,
    getInvoicesState,
    getInvoiceState,
    getLastBillableInvoiceAvailableFromPastState,
    getLastBillableInvoiceState,
    getQuarterDateState,
    getQuarterlyInvoicesTotalsLoadingState,
    getQuarterlyInvoicesTotalsState,
    getQuarterlyInvoicingLoadingState,
    getQuarterlyInvoicingState,
    getQuarterState,
} from '@app/invoicing/state/invoicing.reducer';
import { State } from '@app/store/state';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { select, Store } from '@ngrx/store';
import { NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { InvoiceStatus } from '../enums/invoice-status.enum';

@Injectable({
    providedIn: 'root',
})
export class InvoicingSandbox {
    public quarterDate$ = this.store$.pipe(select(getQuarterDateState));
    public quarter$ = this.store$.pipe(select(getQuarterState));

    public invoice$ = this.store$.pipe(select(getInvoiceState));
    public invoiceLoading$ = this.store$.pipe(select(getInvoiceLoadingState));

    public invoices$ = this.store$.pipe(select(getInvoicesState));
    public invoicesCount$ = this.store$.pipe(select(getInvoicesCountState));
    public invoicesLoading$ = this.store$.pipe(select(getInvoicesLoadingState));

    public quarterlyInvoicesTotals$ = this.store$.pipe(select(getQuarterlyInvoicesTotalsState));
    public quarterlyInvoicesTotalsLoading$ = this.store$.pipe(select(getQuarterlyInvoicesTotalsLoadingState));

    public quarterlyInvoicing$ = this.store$.pipe(select(getQuarterlyInvoicingState));
    public quarterlyInvoicingLoading$ = this.store$.pipe(select(getQuarterlyInvoicingLoadingState));

    public lastBillableInvoice$ = this.store$.pipe(select(getLastBillableInvoiceState));
    public lastBillableInvoiceAvailableFromPast$ = this.store$.pipe(select(getLastBillableInvoiceAvailableFromPastState));

    public billableInvoices$ = this.store$.pipe(select(getBillableInvoicesState));
    public billableInvoicesLoading$ = this.store$.pipe(select(getBillableInvoicesLoadingState));

    public invoicesSent$ = this.store$.pipe(select(getInvoicesSentState));
    public invoicesSentLoading$ = this.store$.pipe(select(getInvoicesSentLoadingState));

    public commissionsPaid$ = this.store$.pipe(select(getCommissionsPaidState));

    private loadingSubject = new BehaviorSubject(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(
        private readonly invoicingService: InvoicingService,
        private readonly uiSandbox: AppUiSandbox,
        private readonly store$: Store<State>,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public getQuarterly(realEstateGroupId: string): void {
        this.store$.dispatch(getQuarterlyInvoicing({ realEstateGroupId }));
    }

    public getQuarter(): void {
        this.store$.dispatch(getQuarter());
    }

    public getLastBillableInvoice(realEstateGroupId: string): void {
        this.store$.dispatch(getLastBillableInvoice({ realEstateGroupId }));
    }

    public getBillableInvoices(realEstateGroupId: string): void {
        this.store$.dispatch(getBillableInvoices({ realEstateGroupId }));
    }

    public getInvoices(invoicesRequest: InvoicesRequest): void {
        this.store$.dispatch(getInvoices({ invoicesRequest }));
    }

    public setQuarter(quarter: Date): void {
        this.store$.dispatch(setQuarter({ quarter }));
    }

    public getQuarterlyTotals(): void {
        this.store$.dispatch(getQuarterlyInvoicesTotals());
    }

    public getInvoicesSent(
        realEstateGroupId: string,
        status = [InvoiceStatus.NotPaidYet, InvoiceStatus.InvoicePaid],
        startDate?: Date
    ): void {
        this.store$.dispatch(getInvoicesSent({ realEstateGroupId, status, startDate }));
    }

    public patch(id: string, payload: any): Observable<Invoice> {
        return this.invoicingService.patch(id, payload);
    }

    public getAsset(invoiceId: string, invoiceType: RealEstateGroupInvoicingType): Observable<string> {
        const reader = new FileReader();
        return this.invoicingService.getAsset(invoiceId, invoiceType).pipe(
            mergeMap((response) => {
                return new Observable<string>((emit) => {
                    reader.onloadend = () => emit.next(reader.result as string);
                    reader.readAsDataURL(response.body);
                });
            })
        );
    }

    public uploadAsset(id: string, content: FormData, invoiceType: RealEstateGroupInvoicingType): Observable<Invoice> {
        this.uiSandbox.showLoadingOverlay();
        return this.invoicingService.uploadAsset(id, content, invoiceType).pipe(finalize(() => this.uiSandbox.hideLoadingOverlay()));
    }

    public delete(id: string): Observable<void> {
        this.loadingSubject.next(true);
        return this.invoicingService.delete(id).pipe(
            tap(() => {
                this.notificationSandbox.deletedSuccess();
            }),
            finalize(() => this.loadingSubject.next(false))
        );
    }
}
