import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RealEstateGroupAnalytics } from '@app/real-estate-group/interfaces/real-estate-group-analytics';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { CardContext, ChartItem, ChartLegend, ColorScale } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { financialReportRoute } from '../analytics-dashboard/analytics-dashboard.constants';

@Component({
    selector: 'app-analytics-services',
    templateUrl: 'analytics-services.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsServicesComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public analytics: RealEstateGroupAnalytics;

    public data: ChartItem[];
    public legend: ChartLegend[];
    public total: number;

    public cardContext = CardContext;
    public readonly financialReportRoute = financialReportRoute;

    constructor(private readonly translateService: TranslateService, private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.setData(this.analytics));
    }

    public ngOnChanges({ analytics }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(analytics)) {
            this.setData(analytics.currentValue);
        }
    }

    private setData(analytics: RealEstateGroupAnalytics): void {
        if (!analytics?.services) return;

        const [data, legend] = this.configFactory(Object.entries(analytics?.services));
        this.data = data;
        this.legend = legend;
        this.total = ArrayUtils.sum(Object.values(analytics.services));
        this.cdr.detectChanges();
    }

    private configFactory(items: [string, number][]) {
        const data = [];
        const legend = [];
        const colors = Object.values(ColorScale);

        items.map((item, index) => {
            data.push(this.chartItemFactory(item, colors[index]));
            legend.push(this.legendFactory(item, colors[index]));
        });

        return [data, legend];
    }

    private chartItemFactory = ([key, value]: [string, number], color: ColorScale): ChartItem => ({
        value,
        label: key,
        color,
    });

    private legendFactory = ([key, value]: [string, number], color: ColorScale): ChartLegend => ({
        label: `${value} ${this.translateService.instant(`SERVICES.${key.toUpperCase()}`)}`,
        color,
    });
}
