import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Attachment } from '@app/email/interfaces/attachment';
import { MoveUtils } from '@app/move/state/move.utils';
import { Asset } from '@smooved/core';
import { Move } from '@app/move/interfaces/move';

export class DocumentCenterUtils {
    public static getMoveAssetsByAttachmentId(attachmentId: AttachmentId, move: Move): Attachment[] {
        switch (attachmentId) {
            case AttachmentId.EnergyMeterReadingAsset:
                return this.mapAssetToAttachment(move.energyMeterReadingAssets, AttachmentId.EnergyMeterReadingAsset);
            case AttachmentId.EnergyDocumentAsset:
                return this.mapAssetToAttachment(move.energyDocumentAssets, AttachmentId.EnergyDocumentAsset);
            case AttachmentId.WaterMeterReadingAsset:
                return this.mapAssetToAttachment(move.waterMeterReadingAssets, AttachmentId.WaterMeterReadingAsset);
            case AttachmentId.WaterDocumentAsset:
                return this.mapAssetToAttachment(move.waterDocumentAssets, AttachmentId.WaterDocumentAsset);
            case AttachmentId.WaterIdCardAssets:
                return this.mapAssetToAttachment(move.waterIdCardAssets, AttachmentId.WaterIdCardAssets);
            case AttachmentId.RentalAgreementAsset:
                return this.mapAssetToAttachment(
                    MoveUtils.getRentalInspection(move)?.agreementDocumentAssets,
                    AttachmentId.RentalAgreementAsset
                );
            case AttachmentId.RentalInspectionAsset:
                return this.mapAssetToAttachment([move?.rentalInspectionAsset], AttachmentId.RentalInspectionAsset);
            default:
                return [];
        }
    }

    private static mapAssetToAttachment(assets: Asset[], attachmentId: AttachmentId): Attachment[] {
        return assets?.map((asset): Attachment => this.assetToAttachment(asset, attachmentId)) ?? [];
    }

    private static assetToAttachment({ key, name, location }: Asset, id: AttachmentId): Attachment {
        return { id, key, name, location };
    }
}
