import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-energy-home-construction-year-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'REAL_ESTATE_AGENT.EOTS.HOME_CONSTRUCTION_YEAR.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: 4,
                                          end: 6
                                      }
                        }}
                    </p>

                    <app-home-construction-year (previous)="goToPrevious()" (next)="onSubmit()"></app-home-construction-year>
                </app-card>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeConstructionYearContainer {
    constructor(private router: Router, private route: ActivatedRoute) {}

    public goToPrevious(): void {
        this.router
            .navigate(['../home-description'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSubmit(): void {
        this.router
            .navigate(['../energy-type'], {
                relativeTo: this.route,
            })
            .then();
    }
}
