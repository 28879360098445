import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../../dialogs/login/login.dialog';

@Component({
    selector: 'app-login-button',
    template: `
        <div class="u-flex-column u-flex-align-items-center">
            <span *ngIf="showText" class="u-margin-bottom-half">Heb je al een verhuisprofiel geregistreerd?</span>
            <app-button [theme]="extraClass" (onClick)="openLoginDialog()">Aanmelden</app-button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginButtonComponent {
    @Input() public showText = true;
    @Input() public extraClass: 'secondary' | 'secondary-outline' | 'danger' | 'no-style' | 'logo-button' | 'circle';

    constructor(private dialog: MatDialog) {}

    public openLoginDialog(): void {
        const dialog = this.dialog.open(LoginDialog);
    }
}
