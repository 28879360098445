import { Injectable } from '@angular/core';
import { LocalStorageKeys } from './storage.constants';

@Injectable({
    providedIn: 'root',
})
export class StorageSandbox {
    public deleteToken(token: LocalStorageKeys): void {
        localStorage.removeItem(`smooved.${token}`);
    }

    public setToken(token: LocalStorageKeys, value: string): void {
        if (value) {
            localStorage.setItem(`smooved.${token}`, value);
        }
    }

    public getToken(token: LocalStorageKeys): string {
        return localStorage.getItem(`smooved.${token}`);
    }

    /**
     * @param key Cookie name
     * @returns {any}
     */
    public getCookie(key: string): string | null {
        if (this.checkCookie(key)) {
            key = encodeURIComponent(key);
            const regExp: RegExp = this.getCookieRegExp(key);
            const result: RegExpExecArray = regExp.exec(document.cookie);
            return decodeURIComponent(result[1]);
        } else {
            return null;
        }
    }

    public deleteCookie(key: string): void {
        if (!this.checkCookie(key)) return;
        this.setCookie(key, '', -1, '/');
    }

    /**
     * @param name     Cookie name
     * @param value    Cookie value
     * @param expires  Number of days until the cookies expires or an actual `Date`
     * @param path     Cookie path
     * @param domain   Cookie domain
     * @param secure   Secure flag
     * @param sameSite OWASP samesite token `Lax` or `Strict`
     */
    private setCookie(
        name: string,
        value: string,
        expires?: number | Date,
        path?: string,
        domain?: string,
        secure?: boolean,
        sameSite?: 'Lax' | 'Strict'
    ): void {
        // Disable setting a cookie when cookie consent isn't accepted.
        // todo
        // if (name !== this.keyCookieConsent && this.cookieConsent !== 'true') {
        //     return;
        // }

        let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

        if (expires) {
            if (typeof expires === 'number') {
                const dateExpires: Date = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

                cookieString += 'expires=' + dateExpires.toUTCString() + ';';
            } else {
                cookieString += 'expires=' + expires.toUTCString() + ';';
            }
        }

        if (path) {
            cookieString += 'path=' + path + ';';
        }

        if (domain) {
            cookieString += 'domain=' + domain + ';';
        }

        if (secure) {
            cookieString += 'secure;';
        }

        if (sameSite) {
            cookieString += 'sameSite=' + sameSite + ';';
        }
        document.cookie = cookieString;
    }

    /**
     * @param key Cookie key
     * @returns {boolean}
     */
    private checkCookie(key: string): boolean {
        key = encodeURIComponent(key);
        const regExp: RegExp = this.getCookieRegExp(key);
        return regExp.test(document.cookie);
    }

    /**
     * @param name Cookie name
     * @returns {RegExp}
     */
    private getCookieRegExp(name: string): RegExp {
        const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }
}
