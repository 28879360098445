export const otsBaseRoute = 'ots';
export const otsContractRoute = 'contract';
export const otsContractMoveDetailsRoute = 'move-details';
export const otsContractContactDetailsRoute = 'contact-details';
export const otsContractPaymentDetailsRoute = 'payment-details';
export const otsOtherServicesRoute = 'other-services';
export const otsSuccessRoute = 'success';

export const otsAbsoluteContractRoute = `/${otsBaseRoute}/${otsContractRoute}`;
export const otsAbsoluteOtherServicesRoute = `/${otsBaseRoute}/${otsOtherServicesRoute}`;
