<ng-container *ngIf="titles.generatedSummary && titles.generatedSummary !== 'error' && showGeneratedSummary; else showTransactionTitle">
    <div class="review-smooved-header-title u-font-size-md u-font-weight-bold">
        <h4
            #generatedSummary
            [class.u-text-truncate]="enableCollapse && !isExpanded && (uiSandbox.tabletPortraitUp$ | async) && !isDownloadingScreenshot"
            class="u-padding-bottom-xs u-font-size-md u-font-weight-bold"
        >
            {{ titles.generatedSummary }}
        </h4>
        <span>
            <a
                (click)="toggleExpand($event)"
                *ngIf="enableCollapse && (showExpandButton || isExpanded) && (uiSandbox.tabletPortraitUp$ | async)"
                class="u-font-size-sm u-font-weight-normal u-color-gray"
                href="#"
                id="review-smooved-header-show-more"
                >{{ (isExpanded ? 'COMMON.SHOW_LESS' : 'COMMON.SHOW_MORE') | translate }}</a
            >
        </span>
    </div>
</ng-container>

<ng-template #showTransactionTitle>
    <ng-container *ngIf="source === reviewSource.Smooved; else provider">
        <h4 class="u-font-size-md u-font-weight-bold" id="review-smooved-header-title-smooved">
            {{
                'UI.REVIEWS.SIMPLE_VIEW.HEADER_TRANSACTION'
                    | translate
                        : {
                              houseType:
                                  titles?.transactionTitleAttributes?.houseType
                                  | houseType: { emptyAs: '' }
                                  | i18nKeyTranslate: i18nKey.HouseType,
                              transactionType:
                                  titles?.transactionTitleAttributes?.transactionType
                                  | empty: { emptyAs: '' }
                                  | i18nKeyTranslate: i18nKey.ReviewTransaction
                          }
                    | capitalize
            }}
            {{
                titles?.transactionTitleAttributes?.city
                    ? ('UI.REVIEWS.SIMPLE_VIEW.HEADER_CITY' | translate: { city: titles?.transactionTitleAttributes?.city })
                    : ''
            }}
        </h4>
    </ng-container>
    <ng-template #provider>
        <h4 class="u-font-size-md u-font-weight-bold" id="review-smooved-header-title-provider">
            {{ 'REVIEW_VIA' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate : { provider: label | translate } }}
        </h4>
    </ng-template>
</ng-template>
