import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { oneOrNoDecimals, ReviewsDataExternal } from '@smooved/core';
import { uiI18nKeyTypes } from '../../../i18n';
import { UiSize } from '../../../ui.enums';
import { maxStarScoreDefault } from '../star-score/star-score.constants';

@Component({
    selector: 'smvd-ui-review-star-score',
    templateUrl: './reviews-star-score.component.html',
    styleUrls: ['./reviews-star-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @Deprecated use MatchExternalScore from CompanyPage module instead
 */
export class ReviewStarScoreComponent {
    @Input() public meta: ReviewsDataExternal;
    @Input() public fontSize: UiSize;
    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly googleMaxScore = maxStarScoreDefault;
    public readonly uiSizes = UiSize;
    public readonly numberFormat = oneOrNoDecimals;
}
