import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    dataInputRoutes,
    dataInputRoutesVacancy,
    eotsAbsoluteRoute,
    eotsEnergySuggestionsRoute,
    eotsMeterTypeRouting,
    eotsOfferSelectedRoute,
} from '@app/eots/constants/eots.constants';
import { StepIndication } from '@app/eots/interfaces/step-indication';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EotsSandbox {
    private routeList$: Observable<string[]> = combineLatest(this.moveSandbox.vacancy$, this.moveSandbox.energyType$).pipe(
        map(([vacancy, energyType]) => {
            const routeList = this.getRouteList(vacancy);
            return energyType === EnergyType.Both || energyType === EnergyType.Electricity
                ? routeList
                : routeList.filter((route) => route !== eotsMeterTypeRouting.join('/'));
        })
    );

    private routeListOnce$: Observable<string[]> = this.routeList$.pipe(take(1));

    constructor(private moveSandbox: MoveSandbox, private router: Router) {}

    public goToFirst(): void {
        this.routeListOnce$.pipe(map((routeList) => routeList[0])).subscribe((firstRoute) => {
            this.navigate(firstRoute);
        });
    }

    public previous(route: string): void {
        this.getPreviousRoute$(route)
            .pipe(take(1))
            .subscribe((previousRoute) => {
                if (previousRoute) {
                    this.navigate(previousRoute);
                }
            });
    }

    public next(route: string): void {
        this.getNextRoute$(route)
            .pipe(take(1))
            .subscribe((nextRoute) => {
                if (nextRoute) {
                    this.navigate(nextRoute);
                }
            });
    }

    public goToOfferSelected(): void {
        this.navigate(eotsOfferSelectedRoute);
    }

    private navigate(route: string): void {
        this.router.navigate([eotsAbsoluteRoute, ...route.split('/')]);
    }

    public getStepIndication$(route: string): Observable<StepIndication> {
        return this.routeList$.pipe(
            map((routeList) => {
                // filter energy suggestions in step indication
                const filteredList = routeList.filter((route) => route !== eotsEnergySuggestionsRoute);
                return {
                    start: filteredList.indexOf(route) + 1,
                    end: filteredList.length,
                };
            })
        );
    }

    public getPreviousRoute$(route: string): Observable<string> {
        return this.routeListOnce$.pipe(
            map((routeList) => {
                const currentIndex = routeList.indexOf(route);
                if (currentIndex < 1) {
                    return null;
                }
                return routeList[currentIndex - 1];
            })
        );
    }

    public getNextRoute$(route: string): Observable<string> {
        return this.routeListOnce$.pipe(
            map((routeList) => {
                const currentIndex = routeList.indexOf(route);
                if (currentIndex + 1 === routeList.length) {
                    return null;
                }
                return routeList[currentIndex + 1];
            })
        );
    }

    private getRouteList(vacancy: boolean): string[] {
        return vacancy ? dataInputRoutesVacancy : dataInputRoutes;
    }
}
