import { InjectionToken } from '@angular/core';
import { TranslationLabels } from '@smooved/core';

export class UiUriConfig {
    public readonly smoovedUri: string;
    public readonly termsAndConditionsUrl: TranslationLabels;
    public readonly privacyPolicyUrl: TranslationLabels;

    constructor(public readonly env: { [key: string]: unknown }) {
        this.smoovedUri = env.smoovedUri as string;
        this.termsAndConditionsUrl = env.termsAndConditionsUrl as TranslationLabels;
        this.privacyPolicyUrl = env.privacyPolicyUrl as TranslationLabels;
    }
}

export const UI_URI_CONFIG = new InjectionToken<UiUriConfig>('UI_URI_CONFIG');
