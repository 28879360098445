import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ExternalInfluencer } from '../company-page';
import { Language } from '../enums';
import { NumberUtils, UrlUtils } from '../utils';
import { BaseMetaService } from './meta.service';

const languageMatcher = new RegExp(`\/(?<language>${Object.values(Language).join('|')})\/`);

@Injectable()
export class MetaGroupService extends BaseMetaService {
    constructor(
        protected readonly meta: Meta,
        protected readonly title: Title,
        protected readonly translateService: TranslateService,
        @Inject(DOCUMENT) protected document: Document,
        rendererFactory: RendererFactory2
    ) {
        super(meta, title, document, rendererFactory);
    }

    public addMetaData(
        externalInfluencer: ExternalInfluencer,
        language: Language,
        description: string,
        title: string,
        houseMatchUrl: string,
        isProduction: boolean
    ): void {
        const metaData = this.buildMeta(externalInfluencer, language, description, title, houseMatchUrl, isProduction);
        this.addMetaTags(metaData);
    }

    public addAlternateLanguageLinks(url: string, houseMatchUrl: string): void {
        this.cleanUpOldElements('link[rel="alternate"]');
        Object.values(Language).forEach((lang) => {
            const hreflang = lang;
            const href = `${houseMatchUrl}${url}`.replace(languageMatcher, `/${hreflang}/`);
            this.addAlternateLangLink(hreflang, href);
        });
    }

    protected buildMeta = (
        externalInfluencer: ExternalInfluencer,
        language: Language,
        description: string,
        title: string,
        houseMatchUrl: string,
        isProduction: boolean
    ): {
        [key: string]: string;
    } => {
        let meta = '';
        const url = externalInfluencer?.url;
        const completeUrl = this.getUrl(houseMatchUrl, externalInfluencer, language);

        const metaProps: object = {
            'og:description': description,
            'og:locale': language,
            'og:type': 'website',
            'twitter:description': description,
            ...(title ? { 'og:title': title, 'twitter:title': title } : {}),
            ...(url ? { 'og:site_name': completeUrl, 'og:url': completeUrl, 'twitter:url': completeUrl } : {}),
        };

        return {
            ...metaProps,
        };
    };

    public getUrl = (externalInfluencerUri: string, externalInfluencer: ExternalInfluencer, language?: Language): string => {
        return [externalInfluencerUri, language, externalInfluencer.url].filter((x) => x).join('/');
    };

    public getTitle = (externalInfluencer: ExternalInfluencer): string => {
        if (!externalInfluencer?.realEstateGroupName) return null;
        if (!NumberUtils.isNumber(externalInfluencer.reviews.overall?.score)) return 'DEFAULT_META.TITLE_REALTORS_WITHOUT_SCORE';
        return 'DEFAULT_META.TITLE_REALTORS_WITH_SCORE';
    };
}
