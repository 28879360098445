import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ButtonAppearance } from '../../button';
import { UiIconAppearance } from '../../icon';
import { UiContext } from '../../ui.enums';

@Component({
    selector: 'smvd-ui-bottom-sheet',
    templateUrl: 'bottom-sheet.component.html',
    styleUrls: ['bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent {
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconAppearance = UiIconAppearance;
    public readonly uiContext = UiContext;

    @Input() public showCloseBtn = true;
    @Input() public context: UiContext;

    constructor(private bottomSheetRef: MatBottomSheetRef<any>) {}

    public close(event?: any): void {
        this.bottomSheetRef.dismiss(event);
    }
}
