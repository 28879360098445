import { BaseReview, NumberUtils, ReviewScoreScale, ReviewsData, ReviewSource } from '@smooved/core';
import * as d3 from 'd3';
import { Colors, ColorScale, GrayScale, Opacity } from '../charts/enums/color-scale.enum';
import { ChartLegend } from '../charts/interfaces/chart-legend';
import { ArcScoreData } from '../widget-influencer-score/widget.interface';

const i18nKeyScoreType = 'REVIEWS.SCORE';

export class ReviewsUtils {
    public static mapArcScore(data: ReviewsData, monochrome?: string): ArcScoreData {
        if (!data?.total) {
            //create default arc
            return this.generateArcScoreData(
                {
                    detractors: 1,
                    promoters: 1,
                    neutrals: 1,
                    total: 3,
                    score: 0,
                },
                monochrome
            );
        }
        return this.generateArcScoreData(data, monochrome);
    }

    public static mapInactiveArcScore(): ArcScoreData {
        return this.generateArcScoreData({ promoters: 0, neutrals: 1, detractors: 0, total: 1, score: 0 }, GrayScale.Light);
    }

    public static mapArcScoreLegend(data: ReviewsData): ChartLegend[] {
        return [
            {
                color: Colors.Danger,
                label: `${i18nKeyScoreType}.DETRACTORS`,
                sub: this.setLegendLabel(data?.detractors),
            },
            {
                color: Colors.Warning,
                label: `${i18nKeyScoreType}.NEUTRALS`,
                sub: this.setLegendLabel(data?.neutrals),
            },
            {
                color: ColorScale.Color3,
                label: `${i18nKeyScoreType}.PROMOTERS`,
                sub: this.setLegendLabel(data?.promoters),
            },
        ];
    }

    private static setLegendLabel(value?: number): string | null {
        return value ? `${NumberUtils.toFixed(value * 100)}%` : null;
    }

    public static mapScoreScale(score: number): ReviewScoreScale {
        if (NumberUtils.isBetween(score, 9, 10)) return ReviewScoreScale.Excellent;
        if (NumberUtils.isBetween(score, 8.5, 9, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.VeryGood;
        if (NumberUtils.isBetween(score, 7.5, 8.5, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.Good;
        if (NumberUtils.isBetween(score, 6, 7.5, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.Fair;
        if (NumberUtils.isBetween(score, 5, 6, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.Poor;
        if (NumberUtils.isBetween(score, 3, 5, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.Bad;
        if (NumberUtils.isBetween(score, 0, 3, NumberUtils.inclusivity.includeStartExcludeEnd)) return ReviewScoreScale.VeryBad;

        return null;
    }

    public static isSmoovedReview(review: BaseReview): boolean {
        return review.source === ReviewSource.Smooved;
    }

    public static isGoogleReview(review: BaseReview): boolean {
        return review.source === ReviewSource.Google;
    }

    public static isFacebookReview(review: BaseReview): boolean {
        return review.source === ReviewSource.Facebook;
    }

    private static setArcColors(monochrome?: string): string[] {
        if (!monochrome) return [Colors.Danger, Colors.Warning, ColorScale.Color3];
        const rgb = d3.color(monochrome);
        return [
            rgb.copy({ opacity: Opacity.SemiTransparent }).toString(),
            rgb.copy({ opacity: Opacity.SemiOpaque }).toString(),
            rgb.toString(),
        ];
    }

    private static generateArcScoreData(data: ReviewsData, monochrome?: string): ArcScoreData {
        if (monochrome) {
            const color = d3.color(monochrome);
            return {
                detractors: [
                    { color: color.copy({ opacity: Opacity.SemiTransparent }).toString(), value: data.detractors },
                    { color: Colors.Transparent, value: 0 },
                ],
                neutrals: [
                    { color: Colors.Transparent, value: data.detractors },
                    {
                        color: color.copy({ opacity: Opacity.SemiTransparent }).toString(),
                        value: data.neutrals + data.promoters,
                    },
                    { color: Colors.Transparent, value: 0 },
                ],
                promoters: [
                    { color: Colors.Transparent, value: data.detractors + data.neutrals },
                    { color: color.toString(), value: data.promoters },
                ],
            };
        }

        const colors = this.setArcColors(monochrome);
        const total = data.detractors + data.neutrals + data.promoters;
        const margin = total * 0.05;

        return {
            detractors: [
                {
                    color: colors?.[0],
                    value: data.detractors ? data.detractors + margin : 0,
                },
                {
                    color: Colors.Transparent,
                    value: total - data.detractors - margin,
                },
            ],
            neutrals: [
                {
                    color: Colors.Transparent,
                    value: data.detractors,
                },
                {
                    color: colors?.[1],
                    value: data.neutrals ? data.neutrals + margin : 0,
                },
                {
                    color: Colors.Transparent,
                    value: total - data.neutrals - data.detractors - margin,
                },
            ],
            promoters: [
                {
                    color: Colors.Transparent,
                    value: data.neutrals + data.detractors,
                },
                {
                    color: colors?.[2],
                    value: data.promoters,
                },
            ],
        };
    }
}
