import { MoverRole } from '@app/move/enums/mover-role.enum';
import { PowerScale } from '@app/ui/power-bars/power-scale.enum';

export enum DashboardViews {
    Gifts = 'gifts',
}

export const dashboardTableTransfereeColumns = {
    desktop: ['todo', 'transaction', 'createdOn', 'movingDate', 'energyMetersState', 'waterMeterState', 'otsConfirmationClusterLevel'],
    tabletLandscape: ['todo', 'transaction', 'movingDate', 'energyMetersState', 'waterMeterState'],
    tablet: ['todo', 'transaction', 'energyMetersState', 'waterMeterState'],
    phone: ['todoMobile', 'transactionMobile', 'detail'],
};

export const dashboardTableLeaverColumns = {
    desktop: ['todo', 'transaction', 'createdOn', 'movingDate', 'energyMetersState', 'waterMeterState'],
    tabletLandscape: ['todo', 'transaction', 'movingDate', 'energyMetersState', 'waterMeterState'],
    tablet: ['todo', 'transaction', 'energyMetersState', 'waterMeterState'],
    phone: ['todoMobile', 'transactionMobile', 'detail'],
};

export const dashboardTableGiftsColumns = {
    tablet: ['transaction', 'createdOn', 'score', 'giftOptions'],
    phone: ['transaction', 'score', 'giftOptions'],
};

export const tableColumnsMap = {
    [MoverRole.Transferee]: {
        largeDesktop: dashboardTableTransfereeColumns.desktop,
        desktop: dashboardTableTransfereeColumns.desktop,
        tabletLandscape: dashboardTableTransfereeColumns.tabletLandscape,
        largeTablet: dashboardTableTransfereeColumns.tablet,
        tablet: dashboardTableTransfereeColumns.phone,
        phone: dashboardTableTransfereeColumns.phone,
    },
    [MoverRole.Leaver]: {
        largeDesktop: dashboardTableLeaverColumns.desktop,
        desktop: dashboardTableLeaverColumns.desktop,
        tabletLandscape: dashboardTableLeaverColumns.tabletLandscape,
        largeTablet: dashboardTableLeaverColumns.tablet,
        tablet: dashboardTableLeaverColumns.phone,
        phone: dashboardTableLeaverColumns.phone,
    },
    [DashboardViews.Gifts]: {
        largeDesktop: dashboardTableGiftsColumns.tablet,
        desktop: dashboardTableGiftsColumns.tablet,
        tabletLandscape: dashboardTableGiftsColumns.tablet,
        largeTablet: dashboardTableGiftsColumns.tablet,
        tablet: dashboardTableGiftsColumns.phone,
        phone: dashboardTableGiftsColumns.phone,
    },
};

export const powerBarsMap = [PowerScale.Low, PowerScale.Medium, PowerScale.High, PowerScale.High];
export const maxPower = 3;

export type TableViews = MoverRole | DashboardViews;

export const scoreIgnoreConfirmI18n = 'TABLE.SCORE.IGNORE.CONFIRM';
