import { cloneDeep, has, isArray, isDate, isEqual, isNull, isObject, isPlainObject, isUndefined, omitBy, transform } from 'lodash';

export class ObjectUtils {
    public static removeEmpty<T>(obj: T, keepNull?: boolean, removeEmptyString?: boolean): void {
        if (!obj) {
            return null;
        }
        Object.entries(obj).forEach(([key, val]) => {
            if (val && isObject(val) && !isDate(val) && !isArray(val)) {
                ObjectUtils.removeEmpty(val, keepNull);
                if (!Object.entries(val).length) {
                    delete obj[key];
                }
            } else if ((!keepNull && val === null) || val === undefined || (removeEmptyString && val === '')) {
                delete obj[key];
            }
        });
    }

    public static merge2Objects(obj1: object, obj2: object): object {
        // Check if either object is null or not an object
        if (obj1 == null || typeof obj1 !== 'object') return obj2;
        if (obj2 == null || typeof obj2 !== 'object') return obj1;

        // Initialize the merged object
        const merged: any = { ...obj1 };

        // Merge keys from both objects
        for (const key of Object.keys(obj2)) {
            if (!(key in obj1)) {
                merged[key] = obj2[key];
            } else {
                merged[key] = ObjectUtils.merge2Objects(obj1[key], obj2[key]);
            }
        }

        return merged;
    }

    public static buildPayload(obj): any {
        return omitBy(obj, (x) => isUndefined(x) || isNull(x) || x === '');
    }

    public static transformTimeStamps(obj): Object {
        if (!obj) {
            return null;
        }
        Object.entries(obj).forEach(([key, val]) => {
            if (val && isObject(val)) {
                if (val.hasOwnProperty('seconds') && val.hasOwnProperty('nanoseconds') && (<any>val).toDate) {
                    // firebase Timestamp
                    obj[key] = (<any>val).toDate();
                } else {
                    ObjectUtils.transformTimeStamps(val);
                }
            }
        });
        return obj;
    }

    public static difference(object, base) {
        function changes(object, base) {
            return transform(object, function (result, value, key) {
                if (!isEqual(value, base[key])) {
                    result[key] = isPlainObject(value) && isPlainObject(base[key]) ? changes(value, base[key]) : value;
                }
            });
        }

        const result: Object = base ? changes(object, base) : object;

        // remove empty objects
        for (const key in result) {
            if (isPlainObject(result[key])) {
                if (Object.keys(result[key]).length === 0) {
                    delete result[key];
                }
            }
        }

        return result;
    }

    public static omit<T>(object: object, ...keys: string[]): T {
        const obj = { ...object } as T;
        keys.forEach((key) => {
            if (ObjectUtils.has(obj, key)) delete obj[key];
        });
        return obj;
    }

    public static objectAssign<T>(target, ...sources): T {
        sources.forEach((source) => {
            Object.keys(source).forEach((key) => {
                const s_val = source[key];
                const t_val = cloneDeep(target[key]);
                target[key] =
                    t_val && s_val && isPlainObject(t_val) && isPlainObject(s_val) ? ObjectUtils.objectAssign(t_val, s_val) : s_val;
            });
        });
        return target;
    }

    public static isEqual(value: unknown, other: unknown): boolean {
        return JSON.stringify(value) === JSON.stringify(other);
    }

    public static isEmpty(value: unknown): boolean {
        if (value == null) return true;
        return ObjectUtils.isObject(value) && Object.keys(value).length === 0;
    }

    public static isObject(value: unknown): boolean {
        return typeof value === 'object';
    }

    /**
     * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place
     * @param object {any}: The object to query.
     * @param path {string}: The path of the property to get.
     * @param defaultValue {any}: The value returned for undefined resolved values.
     * @returns {any}: Returns the resolved value
     */
    public static get(object: any, path: string | any[], defaultValue: any = null): any {
        const parts = Array.isArray(path) ? path : path.split(/[,.\[\]]+?/).filter(Boolean);
        const result = parts.reduce((a: any, c: any) => (a && a[c] !== undefined ? a[c] : undefined), object);
        return result === undefined || result === object ? defaultValue : result;
    }

    /**
     * Sets the value at path of object. If a portion of path doesn't exist, it's created. Arrays are created for missing index properties while objects are created for all other missing properties.
     * @param object {any}: The object to modify.
     * @param path {string}: The path of the property to set.
     * @param value {any}: The value to set.
     * @returns {T}: The mutated object
     */
    public static set<T>(object: T, path: string | any[], value: any): T {
        const [head, ...tail] = Array.isArray(path) ? path : path.split(/[,.\[\]]+?/).filter(Boolean);
        if (object[head] === undefined) {
            object[head] = Number.isInteger(head) ? [] : {};
        }
        if (tail.length === 0) {
            object[head] = value;
            return object;
        }
        return ObjectUtils.set(object[head], tail.join('.'), value);
    }

    /**
     * Checks if path of object exists.
     * @param object {any}: The object to query.
     * @param path {string}: The path of the property to check
     *
     * todo - to check why example not working
     * example:
     * object = {'a.b': 1};
     * has(object, 'a.b') will return false without lodash
     */
    public static has(object: any, path: string | any[]): boolean {
        return has(object, path);
    }

    public static isDefined(value: any): boolean {
        return typeof value !== 'undefined' && value !== null;
    }

    public static isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }

    public static clone = (obj: object): object => ({ ...obj });
    public static cloneDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

    public static forEach<T>(obj: T, fn: (key: string, value: any) => any): T {
        if (!obj) {
            return null;
        }
        Object.entries(obj).forEach(([key, val]) => {
            if (val && isObject(val) && !isDate(val) && !isArray(val)) {
                ObjectUtils.forEach(val, fn);
                if (!Object.entries(val).length) {
                    obj[key] = fn(key, val);
                }
            } else if (isArray(val)) {
                obj[key] = val.map((x) => fn(key, x));
            } else {
                obj[key] = fn(key, val);
            }
        });
    }

    public static isBoolean(value: any): boolean {
        return typeof value === 'boolean';
    }

    /**
     * checks if target value object has any of the following properties
     * @param value
     * @param properties
     */
    public static hasAnyProperty(value: object, properties: string[]): boolean {
        if (!value || !properties) return false;
        return properties.some((property) => ObjectUtils.has(value, property));
    }

    public static toBoolean(value: any): boolean {
        return value === 'false' ? false : Boolean(value);
    }
}
