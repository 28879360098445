import { Pipe, PipeTransform } from '@angular/core';
import { isDate } from 'lodash';
import moment from 'moment';

@Pipe({
    name: 'quarterYear',
})
export class QuarterYearPipe implements PipeTransform {
    transform(value: Date, args?: any): string {
        if (!isDate(value) || !value) return null;
        return moment(value).format('[Q]Q Y');
    }
}
