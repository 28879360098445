import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ElementUtils, Location, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ButtonAppearance } from '../../../button';
import { UiContext } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { ExternalInfluencerService } from '../../services/external-influencer.service';
import { CompanyPageTemplateService } from '../company-page-template/company-page-template.service';

@Component({
    selector: 'smvd-influencer-offices-aside',
    templateUrl: './offices-aside.container.html',
})
export class OfficesAsideContainer implements OnInit {
    public offices: Location[];
    public isTabletPortraitUp$: Observable<boolean>;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        public readonly externalInfluencerSevice: ExternalInfluencerService,
        private readonly uiSandbox: UiSandbox,
        private readonly companyPageTemplateService: CompanyPageTemplateService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public ngOnInit(): void {
        this.isTabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$.pipe(tap(this.handleScreenSizeChanged));
        this.offices = this.externalInfluencerSevice.externalInfluencerSubject.value?.realEstateGroupLocations;
    }

    public trackBySlug(index: number, office: Location): string {
        return StringUtils.slugify(office.name);
    }

    public scrollIntoView(office: Location): void {
        const officeSlug = StringUtils.slugify(office.name);
        const officeEl = this.document.querySelector(`#${officeSlug}`);
        ElementUtils.scrollIntoView({ nativeElement: officeEl });
    }

    private handleScreenSizeChanged = (tabletPortraitUp: boolean): void => {
        this.companyPageTemplateService.showContentAside(tabletPortraitUp);
    };
}
