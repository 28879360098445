import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FeaturePermissionsSandbox } from '../../sandboxes/feature-permissions.sandbox';
import { FeatureScopeModalService } from '@app/feature-scope/services/feature-scope-modal.service';
import { Checked } from '@app/ui/components/checked/checked.enum';
import { FeatureScope, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, UiContext } from '@smooved/ui';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-feature-detail',
    templateUrl: './feature-detail.component.html',
    styleUrls: ['./feature-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureDetailComponent extends RxjsComponent implements OnInit {
    @Input() public activated: boolean;
    @Input() public label: string;
    @Input() public description: string;
    @Input() public featureScope: FeatureScope;
    @Input() public canDeactivate = true;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly checked = Checked;

    constructor(
        private readonly featurePermissionSandbox: FeaturePermissionsSandbox,
        private readonly featureScopeModalService: FeatureScopeModalService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.featureScope) {
            this.featurePermissionSandbox
                .featureGranted$(this.featureScope)
                .pipe(tap(this.handleFeatureGranted), takeUntil(this.destroy$))
                .subscribe();
        }
    }

    public activate(): void {
        this.featureScopeModalService.activate(this.label);
    }

    public deactivate(): void {
        this.featureScopeModalService.deactivate(this.label);
    }

    private handleFeatureGranted = (granted: boolean): void => {
        this.activated = granted;
    };
}
