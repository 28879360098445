import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveType } from '@app/move/enums/move-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { UrlUtils } from '@smooved/core';
import { moveTypeOptions } from './base.init.constants';

export class BaseInitContainer {
    constructor(
        protected readonly formBuilder: UntypedFormBuilder,
        protected readonly moveSandbox: MoveSandbox,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router
    ) {}

    public form: UntypedFormGroup = this.formBuilder.group({
        moveType: [MoveType.Eots, Validators.required],
    });

    public options = moveTypeOptions;

    public onSubmit(): void {
        if (this.form.invalid) return;
        const moveType = this.form.value.moveType as MoveType;

        const data = UrlUtils.getQueryParam('data');

        if (data) {
            const move = {
                ...(JSON.parse(atob(data)) as Move),
            } as Move;
            move.energyOffer = {
                ...move.energyOffer,
                vacancy: moveType === MoveType.Vacancy,
            }
            this.moveSandbox.patchProperty('', { ...move }, false);
        }

        switch (moveType) {
            case MoveType.Eots:
                void this.router.navigate(['/', Navigation.OTS, Navigation.EOTS]).then();
                break;
            case MoveType.Lead:
                void this.router
                    .navigate([`../lead-passing`], {
                        relativeTo: this.route,
                    })
                    .then();
                break;
            case MoveType.Vacancy:
                void this.router.navigate(['/', Navigation.OTS, Navigation.EOTS, Navigation.Data, Navigation.ZipCode]).then();
                break;
        }
    }
}
