<div class="u-text-align-center __rating-modal" [ngSwitch]="thumbsRating">
    <ng-container *ngSwitchCase="thumbsRatings.Up">
        <app-icon [icon]="uiIcon.ThumbUp" [size]="uiIconSize.Xxxl" [context]="uiContext.Primary" class="u-margin-bottom"></app-icon>
        <h6 class="u-margin-bottom u-color-secondary">{{ 'UI.SIMULATION.RATING.UP.TITLE' | translate }}</h6>
        <p class="u-margin-bottom u-color-secondary-dark">{{ 'UI.SIMULATION.RATING.UP.TEXT' | translate }}</p>
        <a
            *ngIf="!!houseMatchMapUrl"
            [href]="houseMatchMapUrl"
            target="_blank"
            smvd-ui-button
            class="u-w100p u-h100p"
            (click)="closeModal()"
            >{{ 'UI.SIMULATION.RATING.UP.BUTTONS.PROFESSIONAL_ESTIMATION' | translate }}</a
        >
        <button
            smvd-ui-button
            *ngIf="false"
            [appearance]="buttonAppearance.Stroked"
            class="u-w100p u-h100p u-margin-top"
            (click)="closeModal()"
        >
            {{ 'UI.SIMULATION.RATING.UP.BUTTONS.REFINE_ESTIMATION' | translate }}
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="thumbsRatings.Down">
        <app-icon [icon]="uiIcon.ThumbDown" [size]="uiIconSize.Xxxl" [context]="uiContext.Primary" class="u-margin-bottom"></app-icon>
        <h6 class="u-margin-bottom u-color-secondary">{{ 'UI.SIMULATION.RATING.DOWN.TITLE' | translate }}</h6>
        <p class="u-margin-bottom u-color-secondary-dark">{{ 'UI.SIMULATION.RATING.DOWN.TEXT' | translate }}</p>
        <a
            *ngIf="!!houseMatchMapUrl"
            [href]="houseMatchMapUrl"
            target="_blank"
            smvd-ui-button
            class="u-w100p u-h100p"
            (click)="closeModal()"
            >{{ 'UI.SIMULATION.RATING.DOWN.BUTTONS.PROFESSIONAL_ESTIMATION' | translate }}</a
        >
        <button
            smvd-ui-button
            *ngIf="false"
            [appearance]="buttonAppearance.Stroked"
            class="u-w100p u-h100p u-margin-top"
            (click)="closeModal()"
        >
            {{ 'UI.SIMULATION.RATING.DOWN.BUTTONS.REFINE_ESTIMATION' | translate }}
        </button>
    </ng-container>
</div>
