export enum TodoType {
    meterInfoNotComplete = 'meterInfoNotComplete',
    energyNotOrdered = 'energyNotOrdered',
    energyNotOrderedBySmooved = 'energyNotOrderedBySmooved',
    energyOptimizationNotOrdered = 'energyOptimizationNotOrdered',
    waterDocumentsMovingAddressByRealEstateAgent = 'waterDocumentsMovingAddressByRealEstateAgent',
}

export enum TodoFilter {
    None = '',
    All = 'all',
}

export type TodoTypeFilter = TodoType | TodoFilter;
