import { Injectable } from '@angular/core';
import { RxjsService, skipWhileEmpty } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReviewsUtils } from '../../../reviews/reviews.utils';
import { WidgetProviderService } from '../../widget-provider.service';
import { WidgetDocument, WidgetViewModel } from '../../widget.interface';

@Injectable()
export class WidgetService extends RxjsService {
    public widgetDataSubject = new BehaviorSubject<WidgetViewModel>(null);
    public externalInfluencerSubject = this.widgetProviderService.externalInfluencerSubject;
    public externalInfluencer$ = this.externalInfluencerSubject.asObservable();

    constructor(private readonly widgetProviderService: WidgetProviderService) {
        super();
    }

    public bootstrap(id: string, monochrome?: string): void {
        this.widgetProviderService.getInfluencer(id).subscribe();
        this.externalInfluencer$.pipe(skipWhileEmpty(), takeUntil(this.destroy$)).subscribe((data) => {
            this.widgetDataSubject.next(this.widgetDataFactory(data, monochrome));
        });
    }

    public setColors(monochrome?: string): void {
        if (!this.widgetDataSubject.value) return;
        this.widgetDataSubject.next(this.widgetDataFactory(this.widgetDataSubject.value, monochrome));
    }

    private widgetDataFactory(data: WidgetDocument, monochrome?: string): WidgetViewModel {
        const arcScore = ReviewsUtils.mapArcScore(data.overall, monochrome);
        const scoreScale = ReviewsUtils.mapScoreScale(data.score);
        return { ...data, arcScore, scoreScale };
    }
}
