<ng-container [ngSwitch]="!!move?.surveys?.nps?.answers">
    <h6
        *ngIf="!!move?.surveys?.nps?.answers?.isAnonymous?.value"
        class="u-flex-row u-flex-align-items-center u-color-warning u-margin-bottom"
    >
        <app-icon icon="error" size="md" class="u-color-warning u-margin-right-half"></app-icon>
        {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.IS_ANONYMOUS' | translate }}
    </h6>
    <app-move-nps-output
        *ngSwitchCase="true"
        [nps]="move?.surveys?.nps"
        [review]="move.surveys.review"
    ></app-move-nps-output>
    <div class="u-color-muted" *ngSwitchCase="false">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.NOT_AVAILABLE' | translate }}</div>
</ng-container>
