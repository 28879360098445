<h6 class="u-color-muted u-margin-bottom-half">{{ 'REVIEWS.LABEL' | translate }}</h6>
<app-check-input
    width="auto"
    [hasMargin]="false"
    [hasMarginDouble]="false"
    [option]="onlyVerifiedOption"
    [formControl]="control"
    [custom]="false"
    [checkBoxMarginTop]="'2px'"
    [label]="onlyVerifiedLabel | translate"
    direction="horizontal"
    labelPosition="after"
></app-check-input>
