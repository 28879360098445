import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DateUtils, SimpleChangesUtils } from '@smooved/core';
import * as d3 from 'd3';
import { Colors } from '../../enums/color-scale.enum';
import { ChartItem } from '../../interfaces/chart-item';
import { ChartMargins } from '../../interfaces/chart-margins';

@Component({
    selector: 'smvd-ui-line-chart',
    templateUrl: 'line-chart.component.html',
    styleUrls: ['line-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LineChartComponent implements OnChanges {
    @Input() public data: ChartItem[];
    @Input() public options: { lineColor?: string; hasDots?: boolean; dotFillColor?: string };
    @Input() public width = 600;
    @Input() public height = 300;
    @Input() public margins: ChartMargins = { top: 10, right: 15, bottom: 20, left: 50 };

    private svg: any;
    private chart: any;
    private line: d3.Line<ChartItem>;

    private x: d3.ScaleTime<number, number, never>; // X scales;
    private y: d3.ScaleLinear<number, number, never>; // Y scales;
    private xAxis;
    private yAxis;

    private yInterval = 0;

    constructor(private ref: ElementRef) {}

    public ngOnChanges({ data }: SimpleChanges) {
        if (SimpleChangesUtils.hasChanged(data) && this.data) {
            this.updateChart();
        }
    }

    private updateChart(): void {
        if (this.svg) d3.select(this.ref.nativeElement).selectAll('g').remove();
        if (!this.data) return;
        this.createChart();
    }

    private createChart(): void {
        this.setData();
        this.setBoard();
        this.setScales();
        this.drawChart();
    }

    private setBoard(): void {
        this.svg = d3.select(this.ref.nativeElement).select('svg');
        this.svg.attr('width', `${this.width}px`);
        this.svg.attr('height', `${this.height}px`);
        this.svg.attr('viewBox', `0 0 ${this.width} ${this.height}`);
        this.chart = this.svg.append('g').attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);
    }

    private setScales(): void {
        const yMin = d3.min(this.data, (d) => d.value);
        const yMax = d3.max(this.data, (d) => d.value);
        const yInterval = (yMax - yMin) / 5;

        this.x = d3
            .scaleTime()
            .domain(d3.extent(this.data, (d) => DateUtils.toDate(d.label)))
            .range([0, this.width - this.margins.left - this.margins.right]);

        this.y = d3
            .scaleLinear()
            .domain([yMax + yInterval, yMin - yInterval])
            .range([0, this.height - this.margins.top - this.margins.bottom]);

        this.xAxis = this.chart
            .append('g')
            .classed('x-axis', true)
            .attr('transform', `translate(0, ${this.height - this.margins.bottom - this.margins.top})`)
            .call(d3.axisBottom(this.x));

        this.yAxis = this.chart.append('g').classed('y-axis', true).call(d3.axisRight(this.y));
    }

    private setAxisDomains(): void {
        const yMin = d3.min(this.data, (d) => d.value);
        const yMax = d3.max(this.data, (d) => d.value);
        this.yInterval = (yMax - yMin) / 10;

        this.x.domain(d3.extent(this.data, (d) => DateUtils.toDate(d.label)));
        this.y.domain([yMax + this.yInterval, yMin - this.yInterval]);

        const axisBottom = d3
            .axisBottom(this.x)
            .tickValues(this.data.map((d) => DateUtils.toDate(d.label)))
            .tickFormat(d3.timeFormat('%Y'));

        this.xAxis.call(axisBottom);

        const axisLeft = d3
            .axisLeft(this.y)
            .ticks(5)
            .tickFormat((d: number) => `€ ${Math.round(d / 1000)}K`);

        this.yAxis.call(axisLeft);

        this.yAxis
            .selectAll('g.y-axis g.tick')
            .append('line')
            .attr('class', 'gridline')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', this.width)
            .attr('y2', 0);
    }

    private setData(): void {
        this.line = d3
            .line<ChartItem>()
            .x((d) => this.x(DateUtils.toDate(d.label)))
            .y((d) => this.y(d.value));
    }

    private drawChart(): void {
        this.setAxisDomains();

        // draw line
        this.chart
            .append('path')
            .attr('d', this.line(this.data))
            .attr('stroke', this.options.lineColor || Colors.Black)
            .attr('stroke-width', 2)
            .attr('fill', 'none');

        if (!this.options.hasDots) return;
        this.chart
            .selectAll('.circle')
            .data(this.data)
            .enter()
            .append('circle')
            .attr('class', 'circle')
            .attr('stroke', this.options.lineColor || Colors.Black)
            .attr('stroke-width', 2)
            .attr('fill', this.options.dotFillColor || 'none')
            .attr('r', 4.5)
            .attr('cx', (d) => this.x(DateUtils.toDate(d.label)))
            .attr('cy', (d) => this.y(d.value));

        const labelGroup = this.chart.append('g').attr('class', 'label-group');
        labelGroup
            .selectAll('text')
            .data(this.data.slice(1)) // slice(1) since we don't need to show first element
            .enter()
            .append('text')
            .attr('class', 'label')
            .attr('x', (d) => this.x(this.halfTime(d)))
            .attr('y', (d) => this.y(this.getAvgValue(d)))
            .attr('text-anchor', 'middle')
            .text((d) => d.subLabel ?? '')
            .append('tspan')
            .attr('dx', -40)
            .attr('dy', 12)
            .text((d) => d.extras['label'] ?? '');
    }

    private getAvgValue(d: ChartItem): number {
        const index = this.data.indexOf(d);
        const prev = this.data[Math.max(index - 1, 0)];
        return (d.value + prev.value) / 2 + this.yInterval; // instead of 2 in order to move up label a little bit
    }

    private halfTime(d: ChartItem): Date {
        const index = this.data.indexOf(d);
        const prev = this.data[Math.max(index - 1, 0)];
        const prevDate = DateUtils.tz(prev.label);
        const currDate = DateUtils.tz(d.label);
        const diff = currDate.diff(prevDate);
        return prevDate
            .clone()
            .add(diff / 2, 'ms')
            .toDate();
    }
}
