import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule as CorePipeModule } from '@smooved/core';
import { ButtonModule } from '../button';
import { PipeModule } from '../pipes/pipe.module';
import { CellTemplateLoaderDirective } from './cell-template-loader.directive';
import { TableRowClassPipe } from './table-row-class.pipe';
import { TableComponent } from './table.component';

@NgModule({
    declarations: [TableComponent, TableRowClassPipe, CellTemplateLoaderDirective],
    imports: [CommonModule, MatTableModule, TranslateModule, CorePipeModule, PipeModule, ButtonModule],
    exports: [TableComponent, CellTemplateLoaderDirective],
})
export class TableModule {}
