import { MoverRole } from '@app/move/enums/mover-role.enum';
import { MoveUtils } from '@app/move/state/move.utils';
import { CheckInput } from '@smooved/ui';

export enum ContactFields {
    Mover = 'mover',
    LinkedMove = 'linkedMove',
}

export interface ContactOptions {
    [ContactFields.Mover]?: CheckInput<boolean>;
    [ContactFields.LinkedMove]?: CheckInput<boolean>;
}

export interface ContactsConfig {
    role: MoverRole;
    contactMover: string;
    contactLinkedMove?: string;
}

const createMoverOption = (contact: string): CheckInput<boolean> => {
    return {
        id: 'mover',
        labelResource: 'MOVER_EMAIL',
        labelParams: { email: contact },
        value: true,
        name: 'mover',
    };
};

const createLinkedMoveOption = (contact: string, role: MoverRole): CheckInput<boolean> => {
    return {
        id: 'linkedMove',
        labelResource: MoveUtils.isLeaver({ user: { role } }) ? 'MOVE.MOVER_ROLE.TRANSFEREE_EMAIL' : 'MOVE.MOVER_ROLE.LEAVER_EMAIL',
        labelParams: { email: contact },
        value: true,
        name: 'linkedMove',
    };
};

export const createContactOptions = (config: ContactsConfig): ContactOptions => {
    return {
        [ContactFields.Mover]: createMoverOption(config.contactMover),
        [ContactFields.LinkedMove]: config.contactLinkedMove ? createLinkedMoveOption(config.contactLinkedMove, config.role) : null,
    };
};
