import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    SkipSelf,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { ArrayUtils, SimpleChangesUtils } from '@smooved/core';
import { UiIconSize } from '../../icon';
import { UiDirection } from '../../ui.enums';
import { BaseInput } from '../base-input';
import { SelectInput, SelectInputVariant } from './select-input';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true,
        },
    ],
})
export class SelectInputComponent extends BaseInput implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public labelResource: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public formControl: UntypedFormControl;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public hasPaddingHalf = false;
    @Input() public hasPadding = true;
    @Input() public options: SelectInput<any>[];
    @Input() public custom = true;
    @Input() public gridContainer = false;
    @Input() public ignoreMinWidth = false;
    @Input() public direction: UiDirection;
    @Input() public templateRef: TemplateRef<unknown>;
    @Input() public templateData: any;
    @Input() public variant = SelectInputVariant.Default;

    @Input()
    @HostBinding('style.width')
    public width: string = this.custom ? '100%' : 'auto';

    public innerModel: any;
    public variants = SelectInputVariant;
    public innerOptions: SelectInput<any>[];

    public uiIconSize = UiIconSize;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnChanges({ options }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(options)) {
            this.innerOptions = ArrayUtils.toArray(this.options);
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: any): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel);
    }
}
