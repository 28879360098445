import { ApplicationSource } from '../enums/application-source.enum';

export class ApplicationUtils {
    public static isSmoovedApplication(app: ApplicationSource): boolean {
        return app === ApplicationSource.SmoovedApp;
    }

    public static isHouseMatchApplication(app: ApplicationSource): boolean {
        return app === ApplicationSource.HouseMatchApp;
    }
}
