import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { UiContext } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';

@Component({
    selector: 'app-dashboard-card',
    template: `
        <div
            class="__container u-padding u-bordered-no-radius u-background-white"
            [style.minHeight]="minHeight"
            [style.height]="height"
            [ngClass]="{
                '__container--first': first,
                '__container--last': last,
                '__container--no-border-bottom': !hasBorderBottom && !last
            }"
        >
            <div class="u-flex-responsive u-flex-no-shrink" [class.u-divider-tablet-portrait-up]="hasBottomPanel">
                <div
                    *ngIf="hasAside"
                    class="__aside u-margin-right-tablet-portrait-up u-flex-no-shrink u-text-align-center u-w100p-up-to-and-including-phone-landscape"
                    [class.u-hide-up-to-and-including-phone-landscape]="!sideAlwaysVisible"
                >
                    <img
                        *ngIf="!!imageName"
                        [src]="'assets/images/' + imageName"
                        class="__image"
                        [style.width]="imageWidth"
                        [style.height]="imageHeight"
                    />
                    <ng-content select="[aside]"></ng-content>
                </div>
                <div class="u-flex-row u-w100p">
                    <div class="u-flex-column u-flex-align-items-start u-flex-1">
                        <h4>
                            <app-svg-illustration
                                *ngIf="svgTitle"
                                [svg]="svgTitle"
                                [context]="svgUiContext"
                                class="__icon u-margin-right-half"
                            ></app-svg-illustration
                            ><span>{{ title }}</span>
                        </h4>
                        <p *ngIf="text" class="u-color-muted u-margin-bottom">{{ text }}</p>
                        <div *ngIf="uiSandbox.tabletPortraitUp$ | async">
                            <ng-content select="[tablet-portrait-up]"></ng-content>
                        </div>
                    </div>
                    <ng-content class="u-flex-no-shrink" select="[right]"></ng-content>
                </div>
            </div>
            <div *ngIf="uiSandbox.upToAndIncludingPhoneLandscape$ | async">
                <ng-content select="[up-to-and-including-phone-landscape]"></ng-content>
            </div>
            <div
                *ngIf="hasBottomPanel"
                [ngClass]="{
                    'card__bottom-panel u-hide-up-to-and-including-phone-landscape u-flex-column u-flex-align-items-start': hasBottomPanel
                }"
            >
                <ng-content select="[bottomPanel]"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./dashboard-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardComponent {
    @Input() public hasAside = true;
    @Input() public imageName: string;
    @Input() public imageHeight = '120px';
    @Input() public imageWidth = '120px';
    @Input() public title: string;
    @Input() public text: string;
    @Input() public first: boolean;
    @Input() public last: boolean;
    @Input() public hasBorderBottom = false;
    @Input() public minHeight = '150px';
    @Input() public height = 'auto';
    @Input() public svgTitle: SvgIllustration;
    @Input() public svgUiContext: UiContext = UiContext.Text;
    @Input() public hasBottomPanel = false;
    @Input() public sideAlwaysVisible = false;

    constructor(public uiSandbox: UiSandbox) {}
}
