import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-ribbon',
    template: `
        <div class="__ribbon__content u-flex-row u-flex-align-items-center u-flex-justify-content-center u-shadow-ribbon u-font-size-small">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./ribbon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonComponent {}
