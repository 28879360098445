import { DataSource } from '@angular/cdk/table';
import { RealEstateAgentFilter } from '@app/admin/interfaces/real-estate-agent-filter.interface';
import { Move } from '@app/move/interfaces/move';
import { PaginationRequest, PaginationResponse } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderMortgage } from '../interfaces/order-mortgage';
import { OrderSandbox } from '../sandboxes/order.sandbox';

export class MortgageOrderDataSource implements DataSource<Move> {
    private mortgageOrdersSubject = new BehaviorSubject<OrderMortgage[]>([]);
    private countSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public count$ = this.countSubject.asObservable();
    public loading$ = this.loadingSubject.asObservable();

    constructor(private orderSandbox: OrderSandbox) {}

    public connect(): Observable<Move[] | ReadonlyArray<Move>> {
        return this.mortgageOrdersSubject.asObservable().pipe(map((orders) => orders.map((order) => order.move)));
    }

    public loadMortageOrders(filter: RealEstateAgentFilter, pagination: PaginationRequest): void {
        this.loadingSubject.next(true);
        this.orderSandbox.getMortgages({ ...pagination, search: filter.search || null }).subscribe(this.populateData);
    }

    public disconnect(): void {
        this.mortgageOrdersSubject.complete();
        this.countSubject.complete();
        this.loadingSubject.complete();
    }

    private populateData = (response: PaginationResponse<OrderMortgage>) => {
        const { data, count } = response;
        this.mortgageOrdersSubject.next(data);
        this.countSubject.next(count);
        this.loadingSubject.next(false);
    };
}
