import { Component } from '@angular/core';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';

@Component({
    selector: 'app-external-influencer-configure-contact',
    templateUrl: './external-influencer-configure-contact.component.html',
})
export class ExternalInfluencerConfigureContactComponent {
    public formControlNames = formControlNames;
    public formGroupNames = formGroupNames;

    constructor(public readonly externalInfluencerService: ExternalInfluencerService) {}
}
