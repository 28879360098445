import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TodoState } from '@smooved/ui';

@Component({
    selector: 'smvd-app-todo-leaver-not-interested',
    templateUrl: 'todo-leaver-not-interested.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverNotInterestedComponent {
    @Input() public move: Move;

    public readonly todoState = TodoState;
    public readonly i18nKeys = AppI18nKeyType;
}
