import { Injectable } from '@angular/core';
import { AdminDashboardSandbox } from '@app/admin/sandboxes/admin-dashboard.sandbox';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import {
    SetLanguage,
    SetLanguageSuccess,
    SetLanguageSuccessForAdmin,
    SetLanguageSuccessForMover,
    SetLanguageSuccessForRealEstateAgent,
    TranslationActionTypes,
} from '@app/translation/state/translation.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Language, NoopAction } from '@smooved/core';
import { of, zip } from 'rxjs';
import { concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { LocalStorageKeys } from '../../storage/sandboxes/storage.constants';

@Injectable()
export class TranslationEffects {
    private readonly availableLanguages = Object.values(Language);

    constructor(
        private readonly actions$: Actions,
        private readonly storageSandbox: StorageSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        private readonly adminDashboardSandbox: AdminDashboardSandbox,
        private readonly translateService: TranslateService
    ) {}

    public setLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SetLanguage>(TranslationActionTypes.SetLanguage),
            map((action) => {
                const language =
                    action?.payload?.language ||
                    <Language>this.storageSandbox.getToken(LocalStorageKeys.Language) ||
                    (this.translateService.defaultLang as Language);

                if (!this.availableLanguages.includes(language) || this.translateService.currentLang === language) {
                    return new NoopAction();
                }

                if (this.availableLanguages.includes(language) && this.translateService.currentLang !== language) {
                    this.translateService.use(language);
                    this.storageSandbox.setToken(LocalStorageKeys.Language, language);
                    return new SetLanguageSuccess({ language });
                }
            })
        )
    );

    public setLanguageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SetLanguageSuccess>(TranslationActionTypes.SetLanguageSuccess),
            withLatestFrom(this.authenticationSandbox.isLoggedIn$),
            filter(([action, isLoggedIn]) => !!isLoggedIn),
            concatMap(([action, isLoggedIn]) => {
                return zip(
                    of(action),
                    this.authenticationSandbox.isMoverOnce$,
                    this.authenticationSandbox.isRealEstateAgentOnce$,
                    this.authenticationSandbox.isAdminOnce$
                );
            }),
            map(([action, isMover, isRealEstateAgent, isAdmin]) => {
                if (isMover) {
                    return new SetLanguageSuccessForMover({ language: action?.payload?.language });
                } else if (isRealEstateAgent) {
                    return new SetLanguageSuccessForRealEstateAgent({ language: action?.payload?.language });
                } else if (isAdmin) {
                    return new SetLanguageSuccessForAdmin({ language: action?.payload?.language });
                }
            })
        )
    );

    public setLanguageSuccessForMover$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetLanguageSuccessForMover>(TranslationActionTypes.SetLanguageSuccessForMover),
                withLatestFrom(this.moveSandbox.move$),
                tap(([action, move]) => {
                    this.moveSandbox.patchMove(move._id, { user: { language: action?.payload?.language } }, false);
                })
            ),
        { dispatch: false }
    );

    public SetLanguageSuccessForRealEstateAgent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetLanguageSuccessForRealEstateAgent>(TranslationActionTypes.SetLanguageSuccessForRealEstateAgent),
                withLatestFrom(this.authenticationSandbox.realEstateAgentId$),
                tap(([action, realEstateAgentId]) => {
                    this.realEstateAgentSandbox.patch(realEstateAgentId, { language: action?.payload?.language });
                })
            ),
        { dispatch: false }
    );

    public SetLanguageSuccessForAdmin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetLanguageSuccessForAdmin>(TranslationActionTypes.SetLanguageSuccessForAdmin),
                withLatestFrom(this.authenticationSandbox.adminId$),
                tap(([action, adminId]) => {
                    this.adminDashboardSandbox.patch(adminId, { language: action.payload.language }).subscribe();
                })
            ),
        { dispatch: false }
    );
}
