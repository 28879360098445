import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLabels } from '../interfaces/translation-labels.interface';
import { StringUtils } from '../utils/string.utils';
export interface TranslateLabelPipeInput {
    fallback?: { object: object; label: string };
    interpolation: any;
}

@Pipe({
    name: 'translateLabel',
})
export class TranslateLabelPipe implements PipeTransform {
    protected presetInterpolation: { [key: string]: string };

    transform(value: TranslationLabels, args?: TranslateLabelPipeInput): string {
        if (!value) {
            // try fallback
            const fallback = (args || {})['fallback'];
            const fallbackObject = fallback?.object;
            const fallbackLabel = fallback?.label;
            if (!fallbackLabel || !fallbackObject || !fallbackObject[fallbackLabel]) {
                return null;
            }
            return fallbackObject[fallbackLabel];
        }

        const lang = this.translateService.currentLang || this.translateService.defaultLang;
        return this.setInterpolation(value[lang], args?.interpolation);
    }

    constructor(protected translateService: TranslateService) {}

    private setInterpolation(translatedLabel: string, interpolation: any = {}): string {
        const params = { ...this.presetInterpolation, ...interpolation };
        const broker =
            (params.broker as string) ||
            (params.realEstateGroup as string) ||
            (this.translateService.instant('SHARED.SURVEYS.YOUR_BROKER') as string);
        return StringUtils.parse(translatedLabel, { ...params, broker });
    }
}
