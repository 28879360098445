import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExternalInfluencerService } from '../../services/external-influencer.service';

@Component({
    selector: 'smvd-ui-about-short',
    templateUrl: './about-short.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [':host { display: block;}'],
})
export class AboutShortComponent {
    constructor(public readonly externalInfluencerService: ExternalInfluencerService) {}
}
