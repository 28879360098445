import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@shared/move/utils/move.utils';

@Component({
    selector: 'app-telecom-installation-date-output',
    template: `<p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'TELECOM.INSTALLATION_DATE' | translate }}</p>
        <p [ngClass]="{ 'u-text-line-through': installationDateDifferent }">
            {{ move?.telecomOffer?.telecomInstallation?.date | formatDate }} {{ move?.telecomOffer?.telecomInstallation?.timeSlot }}
        </p>
        <p *ngIf="installationDateDifferent">
            {{ move.telecomInstallationScheduleByAdmin.date | formatDate }} {{ move.telecomInstallationScheduleByAdmin.timeSlot }}
        </p>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomInstallationDateOutputComponent implements OnChanges {
    @Input() public move: Move;

    public installationDateDifferent = false;

    public ngOnChanges(changes: SimpleChanges): void {
        this.installationDateDifferent = MoveUtils.installationDateDifferent(this.move);
    }
}
