<div class="u-flex u-flex-align-items-center u-flex-justify-content-space-between">
    <div class="u-flex-row">
        <smvd-ui-review-title-generated-summary
            [source]="source"
            [titles]="{
                generatedSummary: item.generatedSummary,
                transactionTitleAttributes: {
                    houseType: item.houseType,
                    transactionType: item.assessedTo,
                    city: item.city
                }
            }"
        >
        </smvd-ui-review-title-generated-summary>
        <smvd-ui-review-verification-badge
            [locationOrGroupName]="location?.name || item.group"
            [reviewSource]="item.source"
            class="u-margin-left-half u-line-height-normal"
        ></smvd-ui-review-verification-badge>
        <ng-content selector="[item-chip-list]"></ng-content>
    </div>
</div>
<div *ngIf="!templateConfig.hideAuthor"
     class="u-flex u-flex-wrap u-w100p u-margin-bottom-sm-md u-flex-align-items-center">
    <div class="__info">
        <app-icon
            [appearance]="iconAppearance.Filled"
            [context]="item.recommended ? uiContext.Success : uiContext.Danger"
            [icon]="item.recommended ? 'thumb_up' : 'thumb_down'"
            [size]="iconSize.Md"
        ></app-icon>
        <time [dateTime]="item.createdOn | formatDate: pubDateformat" class="u-color-muted"
              pubdate>{{ item.createdOn | formatDate }}
        </time>
    </div>
    <div class="__info">
        <app-icon [size]="iconSize.Md" aria-hidden="true" class="u-color-muted" icon="person_outline"
                  tabindex="-1"></app-icon>
        <span class="u-color-muted">{{ item.createdBy | authorCaption }}</span>
    </div>
</div>
