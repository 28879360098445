import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { CoreUtils, ObjectUtils, ReviewSource } from '@smooved/core';
import {
    EmittableReviewsFilter,
    NpsReviewBucket,
    NpsReviewsFilterService,
    ReviewBucketRequest,
    ReviewBucketsType,
    UiContext,
} from '@smooved/ui';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-nps-review-buckets',
    templateUrl: './nps-review-buckets.component.html',
})
export class NpsReviewBucketsComponent {
    public data$: Observable<ReviewBucketRequest> = this.filterService.filter$.pipe(this.filterGroupExistance(), this.getReviewsFactory());
    public bucketsType$ = this.filterService.filter$.pipe(this.getReviewBucketsType());

    @Input()
    public preSelectedBuckets: NpsReviewBucket[];

    @Input()
    public context = UiContext.Primary;

    @Output()
    public selected = new EventEmitter<NpsReviewBucket[]>();

    constructor(
        private readonly analyticsSandbox: SmoovedAnalyticsSandbox,
        private readonly filterService: NpsReviewsFilterService,
        private readonly authenticationSandbox: AuthenticationSandbox
    ) {}

    public onFilterChange(filter: NpsReviewBucket[]): void {
        this.filterService.filterByBucket(filter);
        this.selected.emit(filter);
    }

    private getReviewsFactory(): OperatorFunction<EmittableReviewsFilter, ReviewBucketRequest> {
        return switchMap(({ filter }: EmittableReviewsFilter) => this.analyticsSandbox.getReviewBuckets(filter));
    }

    public getReviewBucketsType(): OperatorFunction<EmittableReviewsFilter, ReviewBucketsType> {
        return map(({ filter }) =>
            CoreUtils.isEmpty(filter.source) || ObjectUtils.isEqual(filter.source, [ReviewSource.Smooved])
                ? ReviewBucketsType.Default
                : ReviewBucketsType.Stars
        );
    }

    private filterGroupExistance(): OperatorFunction<EmittableReviewsFilter, EmittableReviewsFilter> {
        return switchMap((emittablefilter: EmittableReviewsFilter) =>
            this.authenticationSandbox.isRealEstateAgent$.pipe(
                filter((isRealEstateAgent) => isRealEstateAgent || !!emittablefilter.filter.group),
                map(() => emittablefilter)
            )
        );
    }
}
