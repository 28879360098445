import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RealEstateGroupSandbox } from '../../../real-estate-group/sandboxes/real-estate-group.sandbox';

@Component({
    selector: 'app-powered-by',
    template: `
        <div *ngIf="!!logo" class="u-flex-column u-flex-align-items-start">
            <h6 *ngIf="showText" class="u-margin-bottom-half">Met dank aan jouw makelaar!</h6>
            <img [attr.src]="logo" [height]="imageHeight" />
        </div>
    `,
})
export class PoweredByComponent implements OnInit, OnDestroy {
    @Input() public showText = true;
    @Input() public imageHeight = 80;

    public logo: string;
    private subscription: Subscription;

    constructor(private realEstateGroupSandbox: RealEstateGroupSandbox, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.subscription = this.realEstateGroupSandbox.logo$.subscribe((logo) => {
            this.logo = logo;
            this.cdr.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
