import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/admin`;
export const realEstateGroupsUri = `${baseUri}/real-estate-groups`;
export const exportMovesLightUri = `${baseUri}/moves-light/export`;
export const exportMovesUri = `${baseUri}/moves/export`;
export const downloadMovesExportUri = `${baseUri}/moves/download`;
export const exportRealEstateAgentsUri = `${baseUri}/real-estate-agents/export`;
export const exportExternalInfluencersUri = `${baseUri}/external-influencers/export`;
export const exportReviews = `${baseUri}/reviews/export`;
export const exportOfficesUri = `${baseUri}/offices/export`;
export const exportRealEstateGroupsUri = `${baseUri}/real-estate-groups/export`;
export const exportOperationsUri = `${baseUri}/operations/export`;
export const exportEnergyPaymentsUri = `${baseUri}/energy-payments/export`;
export const exportOpsActionsUri = `${baseUri}/ops-actions/export`;
export const exportOpsBookingsUri = `${baseUri}/ops-bookings/export`;
export const exportEscalationsUri = `${baseUri}/escalations/export`;
export const exportConversionsUri = `${baseUri}/conversions/export`;
export const importEnergyUri = `${baseUri}/imports/energy`;
export const importTelecomUri = `${baseUri}/imports/telecom`;
export const importPropertyRatesUri = `${baseUri}/imports/property-rates`;
export const emailMovesRenderUri = `${environment.apiUri}/email/moves/:moveId/:emailTemplate/render`;
export const healthCheckUri = `${environment.apiUri}/health-checks`;
