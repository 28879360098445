<h3 class="u-margin-bottom">{{ 'AUTHENTICATION.RESET_PASSWORD.TITLE' | translate }}</h3>
<p *ngIf="expirationDays" [innerHtml]="'AUTHENTICATION.RESET_PASSWORD.NOTE_PASSWORD_EXPIRED' | translate: { days: expirationDays }"></p>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-margin-top-double">
    <app-password-input
        [label]="'AUTHENTICATION.RESET_PASSWORD.CURRENT_PASSWORD' | translate"
        [formControlName]="fields.CurrentPassword"
    ></app-password-input>

    <app-password-input
        [labelTemplateRef]="newPasswordLabel"
        [formControlName]="fields.NewPassword"
        [errorMessages]="errorMessages"
    ></app-password-input>

    <app-password-input
        [label]="'AUTHENTICATION.RESET_PASSWORD.CONFIRM_PASSWORD' | translate"
        [formControlName]="fields.ConfirmPassword"
    ></app-password-input>

    <div>
        <button smvd-ui-button [disabled]="form.invalid" class="u-margin-bottom" type="submit">
            {{ 'AUTHENTICATION.RESET_PASSWORD.CHANGE_PASSWORD' | translate }}
        </button>
    </div>
</form>

<ng-template #newPasswordLabel>
    <div class="u-margin-bottom-half">
        <span>{{ 'AUTHENTICATION.RESET_PASSWORD.NEW_PASSWORD' | translate }}</span>
        <app-icon icon="info" class="u-display-inline-block u-margin-left-half" appTooltip [template]="requirementsTemplate"></app-icon>
    </div>
</ng-template>

<ng-template #requirementsTemplate>
    <div
        class="__reset-password-tooltip-requirements u-font-size-small"
        [innerHtml]="'AUTHENTICATION.RESET_PASSWORD.REQUIREMENTS' | translate"
    ></div>
</ng-template>
