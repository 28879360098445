import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNumber as isNumberLodash } from 'lodash';
import { EnergySuggestion } from '../../interfaces/energy-suggestion';

@Component({
    selector: 'app-electricity-gas-price-details-modal',
    template: `
        <app-closable-modal-template>
            <h2 class="u-margin-bottom">{{ 'MOVE.ENERGY.SUGGESTION.ELECTRICITY_GAS_PRICE.DETAILS.TITLE' | translate }}</h2>

            <app-electricity-details [details]="data.electricityPricesDetails"></app-electricity-details>

            <p *ngIf="isNumber(data.electricityPromoConsumption)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_CONSUMPTION' | translate }} {{ data.electricityPromoConsumption }}
            </p>
            <p *ngIf="isNumber(data.electricityPromoDiscountFixed)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_DISCOUNT_FIXED' | translate }}
                {{ data.electricityPromoDiscountFixed | price }}
            </p>
            <p *ngIf="isNumber(data.electricityPromoDiscountVariableCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_DISCOUNT_VARIABLE_COST' | translate }}
                {{ data.electricityPromoDiscountVariableCost | price }}
            </p>
            <p *ngIf="isNumber(data.electricityPromoPercentageVariableCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.ELECTRICITY_PROMO_PERCENTAGE_VARIABLE_COST' | translate }}
                {{ data.electricityPromoPercentageVariableCost | price }}
            </p>

            <p class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_FIXED_FEE' | translate }} {{ data.gasPricesDetails.fixedFee | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.gas)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS.USAGE' | translate }} {{ data.gasPricesDetails.gas | kwh }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.gasCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PRICE' | translate }}
                {{ data.gasPricesDetails.gasCost | pricePerKwh }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.distribution)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_DISTRIBUTION_PRICE' | translate }} {{ data.gasPricesDetails.distribution | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.transmission)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_TRANSMISSION_PRICE' | translate }} {{ data.gasPricesDetails.transmission | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.charges)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_CHARGES_PRICE' | translate }} {{ data.gasPricesDetails.charges | price }}
            </p>
            <p *ngIf="data.gasPromoConsumption" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_CONSUMPTION' | translate }} {{ data.gasPromoConsumption }}
            </p>
            <p *ngIf="data.gasPromoDiscountFixed" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_DISCOUNT_FIXED' | translate }} {{ data.gasPromoDiscountFixed | price }}
            </p>
            <p *ngIf="data.gasPromoDiscountVariableCost" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_DISCOUNT_VARIABLE_COST' | translate }}
                {{ data.gasPromoDiscountVariableCost | price }}
            </p>
            <p *ngIf="data.gasPromoPercentageVariableCost" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_PERCENTAGE_VARIABLE_COST' | translate }}
                {{ data.gasPromoPercentageVariableCost | percent }}
            </p>
            <div class="u-flex-row u-flex-align-items-center">
                <a
                    *ngIf="data.electricityTariffCard"
                    [href]="data?.electricityTariffCard"
                    download
                    target="_blank"
                    class="u-link u-margin-right"
                    >{{ 'MOVE.ENERGY.ELECTRICITY_RATE_CARD' | translate }}</a
                >
                <a
                    *ngIf="data.electricityInjectionTariffCard"
                    [href]="data.electricityInjectionTariffCard"
                    download
                    target="_blank"
                    class="u-link u-margin-right"
                    >{{ 'MOVE.ENERGY.ELECTRICITY_INJECTION_RATE_CARD' | translate }}</a
                >
                <a *ngIf="data.gasTariffCard" [href]="data.gasTariffCard" download target="_blank" class="u-link">{{
                    'MOVE.ENERGY.GAS_RATE_CARD' | translate
                }}</a>
            </div>
        </app-closable-modal-template>
    `,
    styleUrls: ['./electricity-gas-price-details-modal.component.scss'],
})
export class ElectricityGasPriceDetailsModalComponent {
    public isNumber = isNumberLodash;

    constructor(
        public dialogRef: MatDialogRef<ElectricityGasPriceDetailsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EnergySuggestion
    ) {}
}
