import { AfterViewInit, Component, Input } from '@angular/core';
import { hubspotDivId, portalId, region } from './hubspot-modal.constants';

declare const hbspt;

export interface HubspotModalConfig {
    formId: string;
}

@Component({
    selector: 'app-hubspot-modal',
    templateUrl: './hubspot-modal.component.html',
})
export class HubspotModal implements AfterViewInit {
    @Input() public config: HubspotModalConfig;

    public readonly id = hubspotDivId;

    public ngAfterViewInit(): void {
        if (!this.config) return;
        hbspt.forms.create({
            region: region,
            portalId: portalId,
            formId: this.config.formId,
            target: `#${hubspotDivId}`,
        });
    }
}
