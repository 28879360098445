import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelContainerComponent } from './label-container.component';

@NgModule({
    declarations: [LabelContainerComponent],
    exports: [LabelContainerComponent],
    imports: [CommonModule],
})
export class LabelContainerModule {}
