import { I18nKeyUtils } from '@smooved/core';

const detailI18n = 'DETAIL';
const meterReadingsI18nBase = I18nKeyUtils.join(detailI18n, 'METER_READINGS');

const transferCompleteI18n = I18nKeyUtils.join(detailI18n, 'STATUS.TRANSFER_COMPLETE');
const meterReadingsNotCompleteI18n = I18nKeyUtils.join(detailI18n, 'STATUS.METER_READINGS_NOT_COMPLETE');
const meterReadingsCompleteI18n = I18nKeyUtils.join(detailI18n, 'STATUS.METER_READINGS_COMPLETE');
const energyNoInterestI18n = I18nKeyUtils.join(meterReadingsCompleteI18n, 'ENERGY_NO_INTEREST');
const energyOpenInterestI18n = I18nKeyUtils.join(meterReadingsCompleteI18n, 'ENERGY_OPEN_INTEREST');
const energyConfirmedI18n = I18nKeyUtils.join(meterReadingsCompleteI18n, 'ENERGY_CONFIRMED');
const noLeaverI18n = 'NO_LEAVER';
const noSuppliersI18n = 'NO_SUPPLIERS';
const withSuppliersI18n = 'WITH_SUPPLIERS';
const energyOptimizationFlowI18n = 'IS_ENERGY_OPTIMIZATION';
const progressI18n = 'IN_PROGRESS';
const requestedI18n = 'REQUESTED';
const labelI18n = 'LABEL';

export const overalStatesI18n = {
    transferComplete: transferCompleteI18n,
    meterReadingsNotComplete: I18nKeyUtils.join(meterReadingsNotCompleteI18n, labelI18n),
    meterReadingsComplete: {
        energyNoInterest: {
            noLeaver: I18nKeyUtils.join(energyNoInterestI18n, noLeaverI18n, labelI18n),
            noSuppliers: I18nKeyUtils.join(energyNoInterestI18n, noSuppliersI18n, labelI18n),
            withSuppliers: byProgress(I18nKeyUtils.join(energyNoInterestI18n, withSuppliersI18n)),
            isEnergyOptimization: I18nKeyUtils.join(energyNoInterestI18n, energyOptimizationFlowI18n, labelI18n),
        },
        energyOpenInterest: {
            noLeaver: I18nKeyUtils.join(energyOpenInterestI18n, noLeaverI18n, labelI18n),
            noSuppliers: I18nKeyUtils.join(energyOpenInterestI18n, noSuppliersI18n, labelI18n),
            withSuppliers: byProgress(I18nKeyUtils.join(energyOpenInterestI18n, withSuppliersI18n)),
            isEnergyOptimization: I18nKeyUtils.join(energyOpenInterestI18n, energyOptimizationFlowI18n, labelI18n),
        },
        energyConfirmed: {
            noLeaver: byProgress(I18nKeyUtils.join(energyConfirmedI18n, noLeaverI18n)),
            noSuppliers: byProgress(I18nKeyUtils.join(energyConfirmedI18n, noSuppliersI18n)),
            withSuppliers: byProgress(I18nKeyUtils.join(energyConfirmedI18n, withSuppliersI18n)),
            isEnergyOptimization: byProgress(I18nKeyUtils.join(energyConfirmedI18n, energyOptimizationFlowI18n)),
        },
    },
};

function byProgress(base: string): { progress: string; requested: string } {
    return {
        progress: I18nKeyUtils.join(base, progressI18n, labelI18n),
        requested: I18nKeyUtils.join(base, requestedI18n, labelI18n),
    };
}
