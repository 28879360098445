<div class="u-container">
    <div *ngIf="!!data.externalInfluencer; else showMultiSelect">
        <ui-header-box [externalInfluencer]="data.externalInfluencer" class="u-display-block u-margin-bottom"></ui-header-box>
        <p class="u-margin-bottom-double">
            {{ 'CONTACT.FORM.DESCRIPTION' | i18nKeyTranslate:companyPageI18nKeyTypes.CtaBox:{name: data.externalInfluencer.name} }}
        </p>
    </div>
    <ng-template #showMultiSelect>
        <ui-header-box-multi-select
            [selectedBrokers]="data.selectedBrokers"
            class="u-display-block u-margin-bottom"
        ></ui-header-box-multi-select>
        <p class="u-margin-bottom-double">
            {{ 'CONTACT.FORM.DESCRIPTION_MULTI_SELECT' | i18nKeyTranslate:companyPageI18nKeyTypes.CtaBox }}
        </p>
    </ng-template>
    <ui-make-appointment-form
        (send)="onSubmit($event)"
        [realEstateGroups]="realEstateGroups"
        [messageDescription]="data.messageDescriptionResource | translate"
        [isFromMultiSelectFlow]="!!data.selectedBrokers"
        [loading]="loading"
    ></ui-make-appointment-form>
</div>
