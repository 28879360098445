import { Component, forwardRef, Host, Inject, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { Asset, DateUtils, DbUtils, I18N_KEY_TYPE_MAP, I18nKeyTypeMap, I18nKeyUtils, MoveTransactionType } from '@smooved/core';
import { BaseInput, ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { finalize, startWith, takeUntil } from 'rxjs/operators';
import {
    defaultEnergyStop,
    EnergyStopForm,
    formControlNames,
    optionElectricity,
    optionGas,
    optionSameSupplier,
} from './energy-stop-suppliers-form.constants';

@Component({
    selector: 'app-energy-stop-suppliers-form',
    templateUrl: './energy-stop-suppliers-form.component.html',
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnergyStopSuppliersFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EnergyStopSuppliersFormComponent),
            multi: true,
        },
    ],
})
export class EnergyStopSuppliersFormComponent extends BaseInput implements OnInit, Validator {
    @Input() public readonly move: Move;
    @Input() public readonly showRelocationDate: boolean = true;

    public readonly optionElectricity = optionElectricity;
    public readonly optionGas = optionGas;
    public readonly optionSameSupplier = optionSameSupplier;
    public readonly formControlNames = formControlNames;
    public readonly energyType = EnergyType;
    public readonly today = DateUtils.today();
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly context = UiContext;

    public form: UntypedFormGroup;
    public energyStopForm: UntypedFormGroup;
    public hasElectricity = new UntypedFormControl(true);
    public hasGas = new UntypedFormControl(true);
    public hasSameSupplier = new UntypedFormControl(null);
    public prefixLabels = '';
    public loading = false;

    public files = { [EnergyType.Electricity]: null, [EnergyType.Gas]: null };
    public electricityAssets: Asset[] = [];
    public gasAssets: Asset[] = [];
    public movingDateLabelResource: string;

    private controlForm: UntypedFormGroup;

    constructor(
        @Optional() @Host() @SkipSelf() public readonly controlContainer: ControlContainer,
        private readonly fb: UntypedFormBuilder,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly documentCenter: DocumentCenterSandbox,
        private readonly moveSandbox: MoveSandbox,
        @Inject(I18N_KEY_TYPE_MAP) private readonly i18nKeyTypeMap: I18nKeyTypeMap
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.width = 'auto';
        this.movingDateLabelResource =
            MoveUtils.getMoveTransactionType(this.move) === MoveTransactionType.Sale ? 'MOVE.DEED_DATE' : 'MOVE.MOVING_DATE';
        this.prefixLabels = this.navigationSandbox.isAdminRoute()
            ? `${I18nKeyUtils.map(this.i18nKeyTypeMap, AppI18nKeyType.AdminDashboard, 'DETAIL')}.`
            : '';

        this.form = this.fb.group({
            [formControlNames.movingDate]: [null, this.showRelocationDate ? Validators.required : null],
            [formControlNames.energyStop]: this.fb.group({
                [formControlNames.energyType]: [defaultEnergyStop.energyStop.energyType, Validators.required],
                [formControlNames.electricityCurrentSupplier]: [null, Validators.required],
                [formControlNames.gasCurrentSupplier]: [null, Validators.required],
                [formControlNames.hasSameSupplier]: null,
            }),
        });
        this.controlForm = this.fb.group({
            [formControlNames.hasElectricity]: true,
            [formControlNames.hasGas]: true,
        });
        this.energyStopForm = this.form.get(formControlNames.energyStop) as UntypedFormGroup;

        this.hasElectricity = this.controlForm.get(formControlNames.hasElectricity) as UntypedFormControl;
        this.hasGas = this.controlForm.get(formControlNames.hasGas) as UntypedFormControl;
        this.hasSameSupplier = this.energyStopForm.get(formControlNames.hasSameSupplier) as UntypedFormControl;

        if (this.form.enabled) {
            this.hasSameSupplier.valueChanges
                .pipe(startWith(this.hasSameSupplier.value), takeUntil(this.destroy$))
                .subscribe(this.setSameSupplier);
            this.hasElectricity.valueChanges
                .pipe(startWith(this.hasElectricity.value), takeUntil(this.destroy$))
                .subscribe(this.resetElectricity);
            this.hasGas.valueChanges.pipe(startWith(this.hasGas.value), takeUntil(this.destroy$)).subscribe(this.resetGas);

            this.energyStopForm
                .get(formControlNames.electricityCurrentSupplier)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.handleElectricityChanged);
        }

        this.electricityAssets = [...(this.move.energyStop?.invoiceElectricityAssets || [])];
        this.gasAssets = [...(this.move.energyStop?.invoiceGasAssets || [])];

        this.formControl.markAsTouched = (): void => {
            this.form.markAllAsTouched();
        };

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleFormValueChanged(this.form.getRawValue()));
    }

    public onFilesChange(event: Event, energyType: EnergyType): void {
        this.files[energyType] = (event.target as HTMLInputElement).files;
    }

    public removeItem(asset: Asset, energyType: EnergyType): void {
        this.loading = true;
        this.moveSandbox
            .deleteInvoicesAsset(DbUtils.getStringId(this.move), encodeURIComponent(asset.key), energyType)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((move) => {
                this.electricityAssets = [...(move.energyStop.invoiceElectricityAssets || [])];
                this.gasAssets = [...(move.energyStop.invoiceGasAssets || [])];
            });
    }

    public openDetail(asset: Asset): void {
        this.documentCenter.downloadAttachment(asset);
    }

    public writeValue(energyStopForm: EnergyStopForm): void {
        if (!energyStopForm) return;
        const input = {
            ...defaultEnergyStop,
            ...energyStopForm,
            energyStop: { ...defaultEnergyStop.energyStop, ...energyStopForm.energyStop },
        };

        const { energyStop: value } = input;
        this.hasElectricity.setValue(MoveUtils.isElectricityEnergyType(value.energyType));
        this.hasGas.setValue(MoveUtils.isGasEnergyType(value.energyType));
        this.setEnergyType();
        this.hasSameSupplier.setValue(value.hasSameSupplier);

        this.form.patchValue(input);
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
        isDisabled ? this.controlForm.disable({ emitEvent: false }) : this.controlForm.enable({ emitEvent: false });
    }

    public validate(): ValidationErrors | null {
        return this.form.invalid ? { suppliers: true } : null;
    }

    private setSameSupplier = (sameSupplier: boolean): void => {
        let currentSupplier: EnergySupplier = null;

        if (sameSupplier) {
            currentSupplier = this.energyStopForm.get(formControlNames.electricityCurrentSupplier).value as EnergySupplier;

            this.energyStopForm.patchValue({
                [formControlNames.gasCurrentSupplier]: currentSupplier,
            });
        }

        this.form.updateValueAndValidity();
    };

    private resetElectricity = (isSet): void => {
        if (!isSet) {
            this.clearElectricity();
            this.hasSameSupplier.setValue(false);
            this.hasGas.disable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.electricityCurrentSupplier).disable();
        } else {
            this.hasGas.enable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.electricityCurrentSupplier).enable();
        }
        this.setEnergyType();
        this.form.updateValueAndValidity();
    };

    private resetGas = (isSet): void => {
        if (!isSet) {
            this.clearGas();
            this.hasSameSupplier.setValue(false);
            this.hasElectricity.disable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.gasCurrentSupplier).disable();
        } else {
            this.hasElectricity.enable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.gasCurrentSupplier).enable();
        }
        this.setEnergyType();
        this.form.updateValueAndValidity();
    };

    private clearGas(): void {
        this.energyStopForm.patchValue({
            [formControlNames.gasCurrentSupplier]: null,
        });
    }

    private clearElectricity(): void {
        this.energyStopForm.patchValue({
            [formControlNames.electricityCurrentSupplier]: null,
        });
    }

    private setEnergyType(): void {
        let energyType: EnergyType;
        if (this.hasElectricity.value && this.hasGas.value) energyType = EnergyType.Both;
        else if (this.hasElectricity.value) energyType = EnergyType.Electricity;
        else if (this.hasGas.value) energyType = EnergyType.Gas;
        this.energyStopForm.get(formControlNames.energyType).setValue(energyType);
    }

    private handleElectricityChanged = (_: any): void => {
        const currentSupplier = this.energyStopForm.get(formControlNames.electricityCurrentSupplier).value as string;

        if (this.hasSameSupplier.value) {
            this.energyStopForm.patchValue({
                [formControlNames.gasCurrentSupplier]: currentSupplier,
            });
        }
    };

    private handleFormValueChanged = (value: { energyStop: EnergyStop; movingDate: Date }): void => this.propagateChange(value);
}
