import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    SkipSelf,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, SimpleChangesUtils } from '@smooved/core';
import { FormFieldAppearanceEnum } from '@smooved/ui';
import { GroupedSearchableDropdownInputOptions, SearchableDropdownInputOption } from './searchable-dropdown-input-option';
import { BaseInput } from '../base-input';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { otherOption } from './searchable-dropdown-input.constants';

@Component({
    selector: 'ui-searchable-dropdown-input',
    templateUrl: 'searchable-dropdown-input.component.html',
    styleUrls: ['./searchable-dropdown-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchableDropdownInputComponent),
            multi: true,
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: '__searchable-overlay-pane' },
        },
    ],
})
export class SearchableDropdownInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public hasMargin = true;
    @Input() public showOverflowValuesAsCount = false;
    @Input() public multiple = false;
    @Input() public options: SearchableDropdownInputOption[] | GroupedSearchableDropdownInputOptions[];
    @Input() public includeOtherOption: boolean;
    @Input() public otherOptionsGroupName: string = 'UI.OTHER';
    @Input() public otherOptionsDefaultLabel: string = 'UI.OTHER';
    @Input() public otherOptions: SearchableDropdownInputOption[] = [];
    @Input() public includeAllOption = false;
    @Input() public allOptionLabel: string = 'UI.ALL';

    @Output() public onChange = new EventEmitter();
    public searchInput: string;
    public selectTriggerHtml: string;
    public isGrouped: boolean;
    public innerModel: SearchableDropdownInputOption | SearchableDropdownInputOption[];
    public previousModel: SearchableDropdownInputOption | SearchableDropdownInputOption[];

    public readonly FormFieldAppearanceEnum = FormFieldAppearanceEnum;
    public otherOption: SearchableDropdownInputOption;

    private valueDuringWrite: string[] | string;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly translateService: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.getSelectedOption(this.innerModel);
        this.otherOption = {
            ...otherOption,
            labelResource: this.otherOptionsDefaultLabel,
        };
    }

    public onOpenChange(searchInput: HTMLInputElement) {
        this.searchInput = '';
        searchInput.value = '';
        if ((Array.isArray(this.innerModel) && ArrayUtils.isEmpty(this.innerModel)) || !this.innerModel) {
            searchInput.focus();
        }
    }

    public onInputChange(event: any) {
        this.searchInput = event.target.value.toLowerCase();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngOnChanges({ options }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(options)) {
            this.getSelectedOption(this.innerModel);
            if (this.valueDuringWrite) this.setInnerModel(this.valueDuringWrite);
            this.setIsGrouped();
        }
    }

    public writeValue(value: string[] | string): void {
        this.valueDuringWrite = value;

        this.setIsGrouped();
        if (Array.isArray(value)) {
            value = this.updateListForMultipleOccurrences(value);
            value = ArrayUtils.unique(value);
        }

        this.setInnerModel(value);
    }

    public onModelChange($event: MatSelectChange): void {
        this.handleModelChange($event);
        this.propagateChanges($event);
    }

    private handleModelChange($event: MatSelectChange): void {
        if (Array.isArray($event.value)) {
            let eventToValues: string[] = ($event.value as SearchableDropdownInputOption[]).map((option) => option.value);
            eventToValues = this.updateListForMultipleOccurrences(eventToValues);
            this.setInnerModel(ArrayUtils.unique(eventToValues));
        } else {
            this.innerModel = $event.value;
            this.getSelectedOption(this.innerModel);
        }
    }

    public trackById(index: number, option: SearchableDropdownInputOption): string | number {
        return option.id || index;
    }

    private updateListForMultipleOccurrences(eventToValues: string[]): string[] {
        return eventToValues.filter((value) => {
            if (value === otherOption.value) return true;
            const allOccurrencesCount = this.getAllOptions().filter((o) => o.value === value).length;
            const eventOccurrencesCount = eventToValues.filter((eventId) => eventId === value).length;
            const presentInPreviousModel = !!(this.previousModel as SearchableDropdownInputOption[])?.find(
                (previousModelOption) => previousModelOption.value === value
            );
            return allOccurrencesCount === eventOccurrencesCount || !presentInPreviousModel;
        });
    }

    private propagateChanges($event: MatSelectChange): void {
        const valueToPropagate = !this.innerModel
            ? this.innerModel
            : this.multiple
              ? ArrayUtils.unique(((this.innerModel as SearchableDropdownInputOption[]) || []).map((option) => option.value))
              : (this.innerModel as SearchableDropdownInputOption).value;
        this.propagateChange(valueToPropagate);
        this.propagateTouched();
        this.onChange.emit($event.value);
    }

    private getAllOptions(): SearchableDropdownInputOption[] {
        let options: SearchableDropdownInputOption[] = [];

        if (this.isGrouped) {
            options = this.options ? this.flattenGroupedOptions(this.options as GroupedSearchableDropdownInputOptions[]) : [];
        } else {
            options = this.options ? [...(this.options as SearchableDropdownInputOption[])] : [];
        }

        if (!ArrayUtils.isEmpty(this.otherOptions)) {
            options = options.concat(this.otherOptions);
        }

        if (this.includeOtherOption) {
            options = options.concat([this.otherOption]);
        }

        return options;
    }

    private flattenGroupedOptions(options: GroupedSearchableDropdownInputOptions[]): SearchableDropdownInputOption[] {
        return options.reduce((acc, curr) => acc.concat(curr.groupedOptions), []);
    }

    private setInnerModel(value: string | string[]): void {
        if (this.multiple) {
            this.innerModel = this.getAllOptions().filter((option) => ArrayUtils.includes((value as string[]) || [], option.value));
        } else {
            this.innerModel = this.getAllOptions().find((option) => (value as string) === option.value);
        }
        this.getSelectedOption(this.innerModel);
        this.previousModel = this.innerModel;
    }

    private getSelectedOption(model: SearchableDropdownInputOption | SearchableDropdownInputOption[]): void {
        if (ArrayUtils.isEmpty(this.getAllOptions())) return;
        if (Array.isArray(model)) {
            const uniqueModel = ArrayUtils.uniqueByProperty(model, 'id');
            if (this.showOverflowValuesAsCount) {
                const modelLength = ArrayUtils.getLength(uniqueModel);
                const label = this.setVisibleLabel(ArrayUtils.first(uniqueModel));
                const itemRest = modelLength > 1 ? `, +${modelLength - 1}` : '';
                this.selectTriggerHtml = `${label}${itemRest}`;
                return;
            } else {
                this.selectTriggerHtml = uniqueModel
                    .map((item) => this.setVisibleLabel(item))
                    .join('<span class="u-white-space-pre-wrap">, </span>');
                return;
            }
        }

        this.selectTriggerHtml = this.setVisibleLabel(model);
    }

    private setVisibleLabel(model: SearchableDropdownInputOption): string {
        if (model?.labelResource) return this.translateService.instant(model.labelResource);
        return model?.label ?? this.placeholder;
    }

    public setIsGrouped(): void {
        this.isGrouped = this.options?.some((item) => item.hasOwnProperty('groupedOptions'));
    }

    protected readonly ArrayUtils = ArrayUtils;
}
