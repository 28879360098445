import { AbstractControl } from '@angular/forms';

const errorLabelResource = 'VALIDATORS.WEBSITE_URL';

export function websiteUrlOptionalValidator(control: AbstractControl) {
    const websiteUrl: string = control.value;
    if (!control?.value) {
        return null;
    }
    try {
        new URL(websiteUrl);
    } catch (error) {
        return {
            [errorLabelResource]: true,
        };
    }
    return null;
}
