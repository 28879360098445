<ng-container
    *ngIf="{
        isLoggedIn: authenticationSandbox.isLoggedIn$ | async,
        isRealEstateAgent: authenticationSandbox.isRealEstateAgent$ | async,
        menuLevel: menuLevel$ | async
    } as vm"
>
    <ng-container *ngIf="vm.isRealEstateAgent">
        <div *ngIf="vm.menuLevel === 0" class="u-margin-top-half">
            <app-menu-item
                appearance="button"
                goTo="real-estate-agent/start"
                (click)="onItemClick.emit()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.Dashboard"
                class="u-margin-x-axis"
            >
                {{ 'HEADER.MENU.REAL_ESTATE_AGENT.START' | translate }}
            </app-menu-item>
            <app-menu-item
                appearance="button"
                *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"
                goTo="real-estate-agent/dashboard"
                (click)="advanceMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.Transactions"
                hasSubMenu="true"
                class="u-margin-x-axis"
            >
                {{ 'HEADER.MENU.REAL_ESTATE_AGENT.TRANSACTIONS' | translate }}</app-menu-item
            >
            <app-menu-item
                appearance="button"
                goTo="real-estate-agent/reviews"
                (click)="advanceMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.Review"
                hasSubMenu="true"
                class="u-margin-x-axis"
            >
                {{ 'HEADER.MENU.REAL_ESTATE_AGENT.REVIEWS' | translate }}</app-menu-item
            >
            <app-menu-item
                appearance="button"
                *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"
                goTo="real-estate-agent/metrics"
                (click)="advanceMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.Leaderboard"
                hasSubMenu="true"
                class="u-margin-x-axis"
            >
                {{ 'HEADER.MENU.REAL_ESTATE_AGENT.METRICS' | translate }}</app-menu-item
            >
            <app-menu-item
                appearance="button"
                goTo="real-estate-agent/management"
                (click)="advanceMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.Settings"
                hasSubMenu="true"
                class="u-margin-x-axis"
            >
                {{ 'HEADER.MENU.REAL_ESTATE_AGENT.MANAGEMENT' | translate }}</app-menu-item
            >
        </div>
        <!-- If submenu is active -->
        <div *ngIf="vm.menuLevel === 1">
            <app-menu-item
                appearance="button"
                (click)="backMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.ChevronLeft"
                class="u-margin-x-axis"
            >
                {{ 'COMMON.BACK' | translate }}</app-menu-item
            >
            <router-outlet name="secondaryNavigation"></router-outlet>
        </div>
        <!-- If submenu is active -->
        <div *ngIf="vm.menuLevel === 2">
            <app-menu-item
                appearance="button"
                (click)="backMenu()"
                [size]="menuItemSize.Small"
                [svg]="illustrationEnum.ChevronLeft"
                class="u-margin-x-axis"
            >
                {{ 'COMMON.BACK' | translate }}</app-menu-item
            >
            <router-outlet name="tertiaryNavigation"></router-outlet>
        </div>
    </ng-container>

    <ul class="u-flex-column" [ngSwitch]="vm.isLoggedIn">
        <li *ngSwitchCase="false" class="u-bordered-bottom">
            <a
                [href]="faqUri"
                (click)="onItemClick.emit()"
                class="u-color-text u-font-weight-semi-bold u-text-decoration-none u-padding u-w100p u-h100p u-display-block"
                target="_blank"
                >{{ 'HEADER.MENU.FAQ' | translate }}</a
            >
        </li>
        <li *ngSwitchCase="false" class="u-bordered-bottom">
            <a
                (click)="login()"
                class="u-color-text u-font-weight-semi-bold u-text-decoration-none u-padding u-w100p u-h100p u-display-block"
                >{{ 'HEADER.MENU.LOGIN' | translate }}</a
            >
        </li>
        <li *ngIf="authenticationSandbox.isAdmin$ | async" class="u-bordered-bottom">
            <a
                class="u-color-accent u-font-weight-bold u-text-decoration-none u-padding u-w100p u-h100p u-display-block"
                [routerLink]="adminUri"
                (click)="onItemClick.emit()"
                >{{ 'HEADER.MENU.ADMIN' | translate }}</a
            >
        </li>
    </ul>

    <div
        class="u-flex-row u-position-fixed-bottom u-w100p u-flex-justify-content-space-around u-flex-align-items-center"
        [ngSwitch]="vm.isLoggedIn"
    >
        <div>
            <a
                [href]="'HEADER.MENU.REAL_ESTATE_AGENT.HELP_URL' | translate"
                (click)="onItemClick.emit()"
                class="u-link-muted u-padding u-w100p u-h100p u-display-block"
                target="_blank"
                >{{ 'HEADER.MENU.REAL_ESTATE_AGENT.HELP' | translate }}</a
            >
        </div>
        <div *ngIf="!vm.isLoggedIn">
            <a
                [routerLink]="realEstateAgentLoginRouting"
                (click)="onItemClick.emit()"
                class="u-link-muted u-padding u-w100p u-h100p u-display-block"
                >{{ 'HEADER.MENU.FOR_REAL_ESTATE_AGENT' | translate }}</a
            >
        </div>
        <div *ngSwitchCase="true">
            <a (click)="logout()" class="u-link-muted u-padding u-w100p u-h100p u-display-block">{{ 'HEADER.MENU.LOGOUT' | translate }}</a>
        </div>
        <div class="u-flex-row u-flex-align-items-center">
            <app-select-language width="60px" class="u-link-muted u-padding-x-axis u-display-block"></app-select-language>
        </div>
    </div>
</ng-container>
