import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';

@Component({
    selector: 'app-insurances-suggestion-list',
    template: `
        <div class="u-scroll-container-horizontal __container" #list [ngClass]="{ '__container--visible': !hidden }">
            <div *ngFor="let suggestion of suggestions" class="u-padding-right u-padding-top-half">
                <app-insurances-suggestion
                    [suggestion]="suggestion"
                    type="fire"
                    [minHeight]="minHeight"
                    (select)="select.emit(suggestion)"
                ></app-insurances-suggestion>
            </div>
        </div>
    `,
    styleUrls: ['./insurances-suggestion-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancesSuggestionListComponent implements OnChanges {
    public minHeight = '300px';
    public hidden = true;

    @ViewChild('list') public list: ElementRef;

    @Input() public suggestions: InsurancesSuggestion[];
    @Output() public select: EventEmitter<InsurancesSuggestion> = new EventEmitter<InsurancesSuggestion>();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.suggestions.currentValue?.length) {
            setTimeout(() => {
                const items = this.list?.nativeElement?.querySelectorAll('app-insurances-suggestion app-card .js-content');
                if (items?.length) {
                    this.minHeight = `${Math.max(...Array.prototype.slice.call(items).map((x) => x.clientHeight))}px`;
                }
                this.hidden = false;
            }, 0);
        }
    }
}
