import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingUrlQuery } from '@app/energy/interfaces/booking-url-query';
import { EnergySuggestionsQuery } from '@app/energy/interfaces/energy-suggestions-query';
import { environment } from '@environments/environment';
import { HttpUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { EnergySuggestion } from '../interfaces/energy-suggestion';

@Injectable({
    providedIn: 'root',
})
export class EnergyService {
    public static readonly baseUri: string = `${environment.apiUri}/energy`;
    public static readonly suggestionsUri: string = `${EnergyService.baseUri}/suggestions`;
    public static readonly groupedSuggestionsUri: string = `${EnergyService.baseUri}/grouped-suggestions`;
    public static readonly groupedSuggestionsByConsumptionProfileUri: string = `${EnergyService.baseUri}/grouped-suggestions-by-consumption-profile`;
    public static readonly energySuppliersUri: string = `${EnergyService.baseUri}/energy-suppliers`;
    public static readonly bookingUrlUri: string = `${EnergyService.baseUri}/booking-url`;

    constructor(private httpClient: HttpClient) {}

    public getAllEnergySuppliers(): Observable<string[]> {
        return this.httpClient.get<string[]>(EnergyService.energySuppliersUri);
    }

    public getBookingUrl(bookingUrlQuery: BookingUrlQuery): Observable<{ redirectUrl: string }> {
        return this.httpClient.get<{ redirectUrl: string }>(EnergyService.bookingUrlUri, {
            params: HttpUtils.buildQueryParameters(bookingUrlQuery),
        });
    }

    public suggestions(energySuggestionQuery: EnergySuggestionsQuery): Observable<EnergySuggestion[]> {
        let httpParams: HttpParams = new HttpParams()
            .set('energyType', energySuggestionQuery.energyType || null)
            .set('familySize', (energySuggestionQuery.familySize && energySuggestionQuery.familySize.toString()) || null)
            .set('homeDescription', energySuggestionQuery.homeDescription || null)
            .set('homeDescriptionSize', energySuggestionQuery.homeDescriptionSize || null)
            .set('homeConstructionYear', energySuggestionQuery.homeConstructionYear || null)
            .set('meterType', energySuggestionQuery.meterType || null)
            .set('fixedTariff', (energySuggestionQuery.fixedTariff && energySuggestionQuery.fixedTariff.toString()) || null)
            .set('professional', energySuggestionQuery.professional.toString());
        if (energySuggestionQuery.zipCode) {
            httpParams = httpParams.append('zipCode', energySuggestionQuery.zipCode || null);
        }
        return this.httpClient.get<EnergySuggestion[]>(EnergyService.suggestionsUri, {
            params: httpParams,
        });
    }

    public groupedSuggestions(energySuggestionQuery: EnergySuggestionsQuery): Observable<EnergySuggestion[]> {
        let httpParams: HttpParams = new HttpParams();
        for (const key in energySuggestionQuery) {
            if (energySuggestionQuery.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, energySuggestionQuery[key]);
            }
        }
        return this.httpClient.get<EnergySuggestion[]>(EnergyService.groupedSuggestionsUri, {
            params: httpParams,
        });
    }

    public groupedSuggestionsByConsumptionProfile(energySuggestionQuery: EnergySuggestionsQuery): Observable<EnergySuggestion[][]> {
        let httpParams: HttpParams = new HttpParams();
        for (const key in energySuggestionQuery) {
            if (energySuggestionQuery.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, energySuggestionQuery[key]);
            }
        }
        return this.httpClient.get<EnergySuggestion[][]>(EnergyService.groupedSuggestionsByConsumptionProfileUri, {
            params: httpParams,
        });
    }
}
