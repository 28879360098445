import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { InterviewResult } from '@app/surveys/interfaces/data/interview-result';
import { NpsReview } from '@smooved/core';

@Component({
    selector: 'app-move-nps-output',
    templateUrl: './move-nps-output.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveNpsOutputComponent {
    @Input() public nps: InterviewResult;
    @Input() public review: NpsReview;
    public readonly showActions$ = this.authenticationSandbox.isAdmin$;

    constructor(private readonly moveSandbox: MoveSandbox, private readonly authenticationSandbox: AuthenticationSandbox) {}

    public reviewUpdated(): void {
        this.moveSandbox.updatedMoveTrigger.next();
    }
}
