import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils/string.utils';

@Pipe({
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    transform(value: string, opts?: { trim?: boolean }): string {
        return StringUtils.capitalize(value, opts?.trim);
    }
}
