import { DecimalPipe } from '@angular/common';
import { oneDecimal } from '../constants/number-format';
import { defaultLocale, Locale } from '../enums/locales.enum';
import { ArrayUtils } from './array.utils';
import { CoreUtils } from './core.utils';

export class NumberUtils {
    public static inclusivity = {
        includeStartEnd: '[]',
        excludeStartIncludeEnd: '(]',
        includeStartExcludeEnd: '[)',
        excludeStartEnd: '()',
    };

    public static toFixed(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    public static percentage(value: number, total: number, fixed: boolean = true): number {
        if (!value || !total) return 0;
        const result = value / total;
        return fixed ? NumberUtils.toFixed(result) : result;
    }

    public static percentageDiff(baseValue: number, value: number): number {
        if (!NumberUtils.isNumber(baseValue) || !NumberUtils.isNumber(value)) return null;
        return NumberUtils.decimals(((value - baseValue) / baseValue) * 100);
    }

    public static percentageToDecimal(value: number): number {
        return value / 100;
    }

    public static isOdd(value: number): boolean {
        return value % 2 !== 0;
    }

    public static decimals(value: number, decimals = 2): number {
        if (!NumberUtils.isNumber(value)) return value;
        return Number(value.toFixed(decimals));
    }

    public static isBetween(value, min: number, max: number, inclusivity = NumberUtils.inclusivity.includeStartEnd): boolean {
        switch (inclusivity) {
            case NumberUtils.inclusivity.includeStartExcludeEnd:
                return value >= min && value < max;
            case NumberUtils.inclusivity.excludeStartIncludeEnd:
                return value > min && value <= max;
            case NumberUtils.inclusivity.excludeStartEnd:
                return value > min && value < max;
            default:
                return value >= min && value <= max;
        }
    }

    public static isEmpty(value: number): boolean {
        return Number.isNaN(value);
    }

    public static isNumber(value: number): boolean {
        return Number.isFinite(value);
    }

    public static isGreatherThan(value: number, min: number): boolean {
        return NumberUtils.isNumber(value) && NumberUtils.isNumber(min) && value > min;
    }

    /**
     * Returns a list with sorted numbers. E.g: numbersList(2,4) returns [2,3,4];
     * @param startAt number
     * @param endWith number
     */
    public static numbersList(startAt: number, endWith: number): number[] {
        if (!NumberUtils.isNumber(startAt) || !NumberUtils.isNumber(endWith)) return;
        const count = endWith - startAt + 1;
        return Array.from(Array(count), (_, i) => i + startAt);
    }

    /**
     * returns a random number between min (including) and max(including)
     * @param min: number
     * @param max: number
     */
    public static random(min: number, max: number): number {
        return Math.floor(Math.random() * (max + 1 - min)) + min;
    }

    public static clamp(value, min: number, max: number): number {
        return Math.min(Math.max(min, value || 0), max);
    }

    /**
     * return half rounded number of input value (with .5 decimals)
     * (e.g: 4.25 -> 4.5;  3.2 -> 3)
     * @param value input
     * @returns number
     */
    public static roundHalf(value: number): number {
        if (!NumberUtils.isNumber(value)) return null;
        return Number((Math.round(value * 2) / 2).toFixed(1));
    }

    /**
     * checks if value has decimal values
     * (e.g: 4.5 -> true, 3 -> false)
     * @param value number
     * @returns boolean
     */
    public static hasDecimals(value: number): boolean {
        if (!NumberUtils.isNumber(value)) return null;
        return value % 1 > 0;
    }

    public static internationalFormat(value: number, locale: Locale = defaultLocale): string {
        if (CoreUtils.isEmpty(value) && value !== 0) return null;
        return new Intl.NumberFormat(locale).format(value);
    }

    public static average(...values: number[]): number {
        const filteredValues = ArrayUtils.clean(values);
        return filteredValues.reduce((previous, current) => previous + current, 0) / filteredValues.length;
    }

    public static closest(goal: number, ...args: number[]): number {
        return args.reduce(function (prev, curr) {
            return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
        });
    }

    // Custom number pipe.
    // Default: Show 1 decimal, dot as separator ( en-US locale )
    public static decimalLabel(value: number): string {
        if (!NumberUtils.isNumber(value)) return null;
        const decimal = new DecimalPipe(Locale.EnUs);
        return decimal.transform(value, oneDecimal);
    }

    public static roundValue(value: number, amountOfDecimals: number): string {
        if (!NumberUtils.isNumber(value)) return null;
        const multiplier = Math.pow(10, amountOfDecimals);
        const roundedValue = Math.round(value * multiplier) / multiplier;
        return roundedValue.toFixed(amountOfDecimals);
    }
}
