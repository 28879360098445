import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { RxjsComponent } from '@smooved/core';

@Component({
    selector: 'app-energy-suggestion-small',
    templateUrl: './energy-suggestion-small.component.html',
    styleUrls: ['./energy-suggestion-small.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergySuggestionSmallComponent extends RxjsComponent {
    @Input() public groupedSuggestion: EnergySuggestion[];
    @Input() public label: string;

    width = 'auto';

    public termForm: UntypedFormGroup = this.formBuilder.group({
        term: [0],
    });

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    public termFormControl(): AbstractControl {
        return this.termForm.get('term');
    }
}
