// Properties in dot notation
export const moveProperties = {
    telecomOrderedByAdmin: 'telecomOrderedByAdmin',
    telecomInstallationScheduleByAdmin: 'telecomInstallationScheduleByAdmin',
    telecomPhoneTransferByAdmin: 'telecomPhoneTransferByAdmin',
    telecomOffer: 'telecomOffer',
    telecomOfferSelection: 'telecomOfferSelection',
};

export const telecomOfferSelectionPath = `${moveProperties.telecomOffer}.${moveProperties.telecomOfferSelection}`;
