import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorStatePipe } from '../error-state/error-state.pipe';

@NgModule({
    declarations: [ErrorStatePipe],
    exports: [ErrorStatePipe],
    imports: [CommonModule],
})
export class ErrorStateModule {}
