import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SentryEffects } from './state/sentry.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forFeature([SentryEffects])],
})
export class SentryModule {}
