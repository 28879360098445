import { Service } from '@app/escalation/enums/service';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { EscalationTag } from '@app/escalation/enums/tag.enum';
import { EscalationCategory } from '@app/escalation/interfaces/category';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { i18nEscalation } from '@app/move/constants/move.constants';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { DbUtils, EnumUtils } from '@smooved/core';
import { CheckInput, DropdownInput, dropdownInputOptionAll, SvgIllustration } from '@smooved/ui';
import { EscalationAction } from '../interfaces/action';

export const modalTitleResource = i18nEscalation('TITLE_SINGLE');
export const logActionTitleResource = i18nEscalation('LOG_ACTION');

export type LogAction = ContactLog | EscalationAction;

export const tagSvgMap = new Map([
    [EscalationTag.Tag1, SvgIllustration.Tag1],
    [EscalationTag.Tag2, SvgIllustration.Tag2],
    [EscalationTag.Tag3, SvgIllustration.Tag3],
]);
export const tagOptions: DropdownInput<EscalationTag>[] = [
    dropdownInputOptionAll('tag'),
    ...EnumUtils.entries(EscalationTag).map(([key, value]: [string, EscalationTag]) => ({
        id: key,
        name: 'tag',
        svg: {
            path: tagSvgMap.get(value),
            width: '24px',
            height: '24px',
        },
        value,
    })),
];

export const stateOptions: DropdownInput<EscalationState>[] = [
    ...EnumUtils.entries(EscalationState).map(([key, value]: [string, EscalationState]) => ({
        id: key,
        name: 'state',
        labelResource: i18nEscalation(`STATE.${value.toUpperCase()}`),
        value,
    })),
];
export const notifyAccountManagerOption: CheckInput<boolean> = {
    id: 'notify-account-manager',
    name: 'notify-account-manager',
    value: true,
    labelResource: i18nEscalation('NOTIFY_ACCOUNT_MANAGER.LABEL'),
};

export const serviceOptions: DropdownInput<Service>[] = [
    dropdownInputOptionAll('service'),
    ...EnumUtils.entries(Service).map(([key, value]: [string, Service]) => ({
        id: key,
        name: 'service',
        labelResource: `SERVICES.${value.toUpperCase()}`,
        value,
    })),
];

export const createEscalationOptions: (escalations: Escalation[]) => DropdownInput<string>[] = (escalations: Escalation[]) => {
    if (!escalations) return [];
    return escalations.map((escalation) => ({
        id: DbUtils.getStringId(escalation),
        name: 'escalation',
        labels: (escalation.category as EscalationCategory).labels,
        value: DbUtils.getStringId(escalation),
    }));
};
