import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-mail-meter-readings',
    template: `
        <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
            <h3 class="u-margin-bottom">{{ 'ADMIN.METER_READINGS.TITLE' | translate }}</h3>

            <quill-editor formControlName="content" [modules]="editorModules" class="u-display-block u-margin-bottom"></quill-editor>

            <div class="u-flex-row u-flex-justify-content-center">
                <app-button type="submit">{{ 'ADMIN.METER_READINGS.CTA' | translate }}</app-button>
            </div>
        </form>
    `,
    styleUrls: ['./mail-meter-readings.modal.scss'],
})
export class MailMeterReadingsModal implements OnInit {
    @Input() public content: string;
    @Input() public moveId: string;

    public editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image'],
        ],
    };

    public form: UntypedFormGroup = this.formBuilder.group({
        content: [null, [Validators.required]],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly dialogRef: MatDialogRef<any>
    ) {}

    public ngOnInit(): void {
        this.contentFormControl()?.patchValue(this.content);
    }

    public onSubmit(): void {
        if (this.form.valid) {
            // quill editor changes div tags to p tags. We want to use div tags in the email.
            const content = this.contentFormControl().value.replace('<p>', '<div>').replace('</p>', '</div>');
            this.moveSandbox.postMeterReadings(this.moveId, content).subscribe((move) => {
                this.dialogRef.close(move);
                this.notificationSandbox.success('ADMIN.METER_READINGS.SUCCESS');
            });
        }
    }

    private contentFormControl(): AbstractControl {
        return this.form?.get('content');
    }
}
