import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeaturePermissionsSandbox } from '../../../feature-scope/sandboxes/feature-permissions.sandbox';
import { QuarterlyTableComponent } from '@app/invoicing/components/quarterly-table/quarterly-table.component';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { MoveState } from '@app/move/enums/move-state.enum';
import { Move } from '@app/move/interfaces/move';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { DbUtils } from '@smooved/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-quarter-report-container',
    template: `
        <app-quarter-report
            [realEstateGroupId]="realEstateGroupSandbox.id$ | async"
            (openDetail)="openDetail($event)"
            [quarterDate]="invoicingSandbox.quarterDate$ | async"
            [lastBillableInvoiceAvailableFromPast]="invoicingSandbox.lastBillableInvoiceAvailableFromPast$ | async"
            [lastBillableInvoice]="invoicingSandbox.lastBillableInvoice$ | async"
            [quarterlyInvoicingLoading]="invoicingSandbox.quarterlyInvoicingLoading$ | async"
            [quarter]="invoicingSandbox.quarter$ | async"
            [quarterlyInvoicing]="invoicingSandbox.quarterlyInvoicing$ | async"
        ></app-quarter-report>
    `,
})
export class QuarterReportContainer implements OnInit, OnDestroy {
    @ViewChild(QuarterlyTableComponent) private quarterlyTableComponent: QuarterlyTableComponent;

    private subscriptions: Subscription[] = [];
    private movePatchedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        public readonly invoicingSandbox: InvoicingSandbox,
        public readonly featurePermissionsSandbox: FeaturePermissionsSandbox,
        private readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox
    ) {}

    public ngOnInit(): void {
        this.realEstateGroupSandbox.idOnce$.subscribe((realEstateGroupId) => {
            this.invoicingSandbox.getLastBillableInvoice(realEstateGroupId);
            this.invoicingSandbox.getQuarterly(realEstateGroupId);
        });

        this.subscriptions.push(
            this.invoicingSandbox.quarterDate$
                .pipe(mergeMap(() => this.realEstateGroupSandbox.id$))
                .subscribe((realEstateGroupId) => this.invoicingSandbox.getQuarterly(realEstateGroupId))
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    public openMoveDetail(move: Move): void {
        const data = {
            id: DbUtils.getStringId(move),
            patchedSubject: this.movePatchedSubject,
        };

        this.realEstateAgentModalsSandbox.openTransfereeDetail(DbUtils.getStringId(move), data, this.onModalClose);
    }

    public openDetail(move: Move): void {
        move.state === MoveState.Inactive ? this.openMortgageDetail(move) : this.openMoveDetail(move);
    }

    private openMortgageDetail(move: Move): void {
        this.realEstateAgentModalsSandbox.openMortgageDetail(DbUtils.getStringId(move));
    }

    private onModalClose = (): void => {
        this.movePatchedSubject
            .asObservable()
            .pipe(
                take(1),
                filter((x) => !!x)
            )
            .subscribe((_) => {
                this.quarterlyTableComponent.load();
                this.movePatchedSubject.next(false);
            });
    };
}
