import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ObjectUtils } from '../utils';
import { FEATURE_TOGGLE_CONFIG, FeatureToggle, FeatureToggleConfig } from '../feature-toggle';

interface FeatureToggleOptions {
    featureToggle: FeatureToggle;
    alwaysVisible?: boolean;
}

@Directive({ selector: '[featureToggle]' })
export class FeatureToggleDirective {
    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Inject(FEATURE_TOGGLE_CONFIG) private readonly featureToggleConfig: FeatureToggleConfig
    ) {}

    @Input() set featureToggle(param: FeatureToggle | FeatureToggleOptions) {
        let featureToggle: FeatureToggle;
        let alwaysVisible = false;

        if (ObjectUtils.isObject(param)) {
            if ((param as FeatureToggleOptions).alwaysVisible) alwaysVisible = true;
            featureToggle = (param as FeatureToggleOptions).featureToggle;
        } else {
            featureToggle = param as FeatureToggle;
        }

        const condition = alwaysVisible || this.featureToggleConfig[featureToggle];
        if (condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
