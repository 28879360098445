import { LeadPassingEnergyType } from '@app/move/components/lead-passing-form/lead-passing-form.enum';
import { Gender } from '@app/move/enums/gender.enum';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { providerAny, providerOptions } from '@app/telecom/constants/telecom.constants';
import { environment } from '@environments/environment';
import { EnumUtils, I18nKeyType, i18nKeyTypeMap, I18nKeyUtils, Language } from '@smooved/core';
import { CheckInput, DropdownInput, SelectInput } from '@smooved/ui';
import { LeaverContactInvoice } from '../../enums/leaver-contact-invoice.enum';

export const leaverTypeOptions: DropdownInput<Leaver>[] = [
    {
        labelResource: 'LEAVER.TYPE.OWNER_LANDLORD',
        name: 'leaver-type',
        value: Leaver.OwnerLandlord,
        id: Leaver.OwnerLandlord,
    },
    {
        labelResource: 'LEAVER.TYPE.OWNER_LEAVER',
        name: 'leaver-type',
        value: Leaver.OwnerLeaver,
        id: Leaver.OwnerLeaver,
    },
    {
        labelResource: 'LEAVER.TYPE.TENANT_LEAVER',
        name: 'leaver-type',
        value: Leaver.TenantLeaver,
        id: Leaver.TenantLeaver,
    },
];

const realEstateAgentConsentOptionBase: CheckInput<boolean> = {
    id: 'real-estate-agent-consent',
    name: 'real-estate-agent-consent',
    value: true,
};

export const realEstateAgentConsentOptionWithoutLeaver: CheckInput<boolean> = {
    ...realEstateAgentConsentOptionBase,
    labelResource: 'LEAD_PASSING.CONSENT.WITHOUT_LEAVER',
};

export const realEstateAgentConsentOptionWithLeaver: CheckInput<boolean> = {
    ...realEstateAgentConsentOptionBase,
    labelResource: 'LEAD_PASSING.CONSENT.WITH_LEAVER',
};

export const transfereeTypeOptions: SelectInput<Transferee>[] = [
    {
        labelResource: 'MOVE.TRANSFEREE.TENANT_TRANSFEREE.LABEL',
        name: 'transferee-type',
        value: Transferee.TenantTransferee,
        id: `transferee-type-${Transferee.TenantTransferee}`,
    },
    {
        labelResource: 'MOVE.TRANSFEREE.OWNER_TRANSFEREE.LABEL',
        name: 'transferee-type',
        value: Transferee.OwnerTransferee,
        id: `transferee-type-${Transferee.OwnerTransferee}`,
    },
];

export enum EnergyOption {
    Best = 'best',
    ByPreference = 'by-preference',
    LandAgent = 'land-agent',
}

export const energyOptions: SelectInput<EnergyOption>[] = EnumUtils.entries(EnergyOption).map(([key, value]: [string, EnergyOption]) => ({
    id: key,
    name: 'energy-option',
    labelResource: `LP.ENERGY_OPTION.${key.toUpperCase()}`,
    value,
}));

export const transfereeTypeTenantOptions: SelectInput<Transferee>[] = [
    {
        labelResource: 'MOVE.TRANSFEREE.TENANT.LABEL',
        name: 'transferee-tentant-type',
        value: Transferee.TenantTransferee,
        id: `transferee-tentant-type-${Transferee.TenantTransferee}`,
    },
    {
        labelResource: 'MOVE.TRANSFEREE.LANDOWNER.LABEL',
        name: 'transferee-tentant-type',
        value: Transferee.OwnerLandlord,
        id: `transferee-tentant-type-${Transferee.OwnerLandlord}`,
    },
];

export const languageOptions: DropdownInput<Language>[] = Object.keys(Language).map((key) => {
    return {
        id: Language[key] as string,
        labelResource: `LANGUAGE.${key}.LABEL`,
        name: 'Language',
        value: Language[key] as Language,
    };
});

export const genderOptions: SelectInput<Gender>[] = EnumUtils.entries<Gender>(Gender).map(([key, value]) => ({
    id: `gender-${key}`,
    name: 'gender',
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, I18nKeyType.Gender, key),
    value,
}));

export const telecomOptions: SelectInput<boolean>[] = [
    {
        id: 'telecom-not-interested',
        labelResource: 'TELECOM.INTERESTED.NO',
        name: 'telecom',
        value: false,
    },
    {
        id: 'telecom-interested',
        labelResource: 'TELECOM.INTERESTED.YES',
        name: 'telecom',
        value: true,
    },
];

export const addLeaverOptions: SelectInput<boolean>[] = [
    {
        id: 'add-leaver-option-yes',
        labelResource: 'LEAD_PASSING.LEAVER.ADD.YES',
        name: 'add-leaver',
        value: true,
    },
    {
        id: 'add-leaver-option-no',
        labelResource: 'LEAD_PASSING.LEAVER.ADD.NO',
        name: 'add-leaver',
        value: false,
    },
];

export const fixedTariffOptions: SelectInput<boolean>[] = [
    {
        id: 'fixed-tariff-no',
        labelResource: 'MOVE.ENERGY.SUGGESTIONS.VARIABLE_TARIFF_SHORT',
        name: 'fixed-tariff',
        value: false,
    },
    {
        id: 'fixed-tariff-interested',
        labelResource: 'MOVE.ENERGY.SUGGESTIONS.FIXED_TARIFF_SHORT',
        name: 'fixed-tariff',
        value: true,
    },
];

export const insurancesOptions: SelectInput<boolean>[] = [
    {
        id: 'insurances-not-interested',
        labelResource: 'INSURANCES.INTERESTED.NO',
        name: 'insurances',
        value: false,
    },
    {
        id: 'insurances-interested',
        labelResource: 'INSURANCES.INTERESTED.YES',
        name: 'insurances',
        value: true,
    },
];

export const telecomProviderOptions = [...providerOptions, providerAny];

export const energyTypeOptions: DropdownInput<LeadPassingEnergyType>[] = [
    ...Object.keys(LeadPassingEnergyType).map((key) => ({
        id: `energy-type-${key}`,
        labelResource: `ENERGY_TYPE.${key.toUpperCase()}`,
        name: 'EnergyType',
        value: LeadPassingEnergyType[key],
    })),
    {
        id: 'energy-type-unknown',
        labelResource: 'ENERGY_TYPE.UNKNOWN',
        name: 'EnergyType',
        value: LeadPassingEnergyType.Both,
    },
];

export const vacancyOption: CheckInput<boolean> = {
    id: 'vacancy-option',
    name: 'vacancy-option',
    value: true,
    labelResource: 'CONSUMPTION_PROFILE.VACANCY.LABEL',
};

export const solarPanelOption: CheckInput<boolean> = {
    id: 'solar-panel-option',
    name: 'solar-panel-option',
    value: true,
    labelResource: 'ENERGY.SOLAR_PANEL.LABEL',
};

export const insurancesLogoUri = `https://assets.smooved.be/supplier/insurances/${environment.insurancesPartner.toLowerCase()}.png`;

export const leaverFinalInvoiceOptions: SelectInput<boolean>[] = [
    {
        id: `leaver-final-invoice-${true}`,
        name: 'leaverFinalInvoice',
        labelResource: 'LEAD_PASSING.INVOICES.FINAL_INVOICE.BY_REA',
        value: true,
    },
    {
        id: `leaver-final-invoice-${false}`,
        name: 'leaverFinalInvoice',
        labelResource: 'LEAD_PASSING.INVOICES.FINAL_INVOICE.BY_MOVER',
        value: false,
    },
];

export const leaverContactInvoiceOptions: SelectInput<LeaverContactInvoice>[] = [
    {
        id: `leaver-contact-invoice-${LeaverContactInvoice.Digital}`,
        name: 'leaverContactInvoice',
        labelResource: 'LEAD_PASSING.INVOICES.FINAL_INVOICE.CONTACT.DIGITAL',
        value: LeaverContactInvoice.Digital,
    },
    {
        id: `leaver-contact-invoice-${LeaverContactInvoice.ByPost}`,
        name: 'leaverContactInvoice',
        labelResource: 'LEAD_PASSING.INVOICES.FINAL_INVOICE.CONTACT.BY_POST',
        value: LeaverContactInvoice.ByPost,
    },
];
