import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MovesFilter as MovesFilterForAdmin } from '@app/admin/interfaces/moves-filter.interface';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { ClusterLevelFilter } from '@app/invoicing/enums/ots-confirmation-cluster-level.enum';
import { Period } from '@app/invoicing/enums/period.enum';
import { GetMovesForAdminRequest } from '@app/move/interfaces/get-moves-for-admin-request.interface';
import { Move } from '@app/move/interfaces/move';
import { QuarterlyForRealEstateGroupRequest } from '@app/move/interfaces/quarterly-for-real-estate-group-request';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { InterviewResult } from '@app/surveys/interfaces/data/interview-result';
import { Water } from '@app/water/interfaces/water';
import { MoveForLeaver } from '@app/wizard/move/interfaces/move-for-leaver';
import { HttpUtils, ObjectUtils, PaginationRequest, PaginationResponse, StringUtils } from '@smooved/core';
import { Observable, throwError } from 'rxjs';
import * as moveUri from '../constants/uri.constants';
import { ContactLogType } from '../enums/contact-log-type.enum';
import { MilestoneLogType } from '../enums/milestone-log-type.enum';
import { ConfirmRequest } from '../interfaces/confirm-request';
import { DeleteMoveOptionsRequest } from '../interfaces/delete-move-options.request';
import { GetMovesForRealEstateAgentRequest } from '../interfaces/get-moves-for-real-estate-agent-request.interface';
import { Log } from '../interfaces/log';
import { PatchMoveRequest } from '../interfaces/patch-move.request';
import { SendConfirmationRequest } from '../interfaces/send-confirmation-request';
import { TodoCount } from '../interfaces/todo-count';

@Injectable({
    providedIn: 'root',
})
export class MoveService {
    constructor(private httpClient: HttpClient) {}

    public get(id: string): Observable<Move> {
        return this.httpClient.get<Move>(StringUtils.parseUri(moveUri.byIdUri, { id }));
    }

    public getLocationSuggestion(id: string): Observable<{ locationId: string }> {
        return this.httpClient.get<{
            locationId: string;
        }>(StringUtils.parseUri(moveUri.getLocationSuggestionUri, { id }));
    }

    public getCount(): Observable<{ count: number }> {
        return this.httpClient.get<{ count: number }>(moveUri.countUri);
    }

    public getAll(paginationRequest: PaginationRequest): Observable<Move[]> {
        return this.httpClient.get<Move[]>(moveUri.baseUri, {
            params: HttpUtils.buildPaginationRequest(paginationRequest),
        });
    }

    public getNewestDraft(): Observable<Move> {
        return this.httpClient.get<Move>(moveUri.draftUri);
    }

    public getByAccessTokenAndId(accessToken: string, id: string): Observable<Move> {
        let httpParams: HttpParams = new HttpParams().set('id', id);
        if (accessToken) {
            httpParams = httpParams.set('accessToken', accessToken);
        }
        return this.httpClient.get<Move>(moveUri.byAccessTokenAndIdUri, {
            params: httpParams,
        });
    }

    public getByEmail(email: string): Observable<Move> {
        const httpParams: HttpParams = new HttpParams().set('email', encodeURIComponent(email));
        return this.httpClient.get<Move>(moveUri.byEmailUri, {
            params: httpParams,
        });
    }

    /**
     * @param registerRequest
     */
    public register(registerRequest: Move): Observable<{ id: string }> {
        return this.httpClient.post<{ id: string }>(moveUri.registerUri, registerRequest);
    }

    /**
     *
     * @param registerRequest
     */
    public registerSingle(registerRequest: Move): Observable<{ id: string }> {
        const httpParams: HttpParams = new HttpParams().set('mode', 'single');
        return this.httpClient.post<{ id: string }>(moveUri.registerUri, registerRequest, {
            params: httpParams,
        });
    }

    public registerForTransferee(registerRequest: Move): Observable<{ id: string }> {
        return this.httpClient.post<{ id: string }>(moveUri.transfereeUri, registerRequest);
    }

    public updateForTransferee(move: Move): Observable<Move> {
        const { _id, user, legal, meta, energyOffer, movingDate, energyPaymentType, requestWithoutMover, interested } = move;
        const payload = {
            _id,
            user,
            legal,
            meta,
            energyOffer,
            movingDate,
            energyPaymentType,
            requestWithoutMover,
            interested,
        };

        return this.httpClient.patch<Move>(moveUri.transfereeUri, payload);
    }

    public registerForLeaver(payload: MoveForLeaver): Observable<{ id: string }> {
        return this.httpClient.post<{ id: string }>(moveUri.registerForLeaverUri, payload);
    }

    public sendConfirmationEmail(moveId: string, accessToken?: string): Observable<Move> {
        let httpParams: HttpParams = new HttpParams();
        if (accessToken) {
            httpParams = httpParams.set('accessToken', accessToken);
        }
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.emailConfirmUri, { id: moveId }), null, {
            params: httpParams,
        });
    }

    public registerAndConfirmOrders(registerRequest: Move, submit?: boolean): Observable<{ id: string }> {
        let httpParams: HttpParams = new HttpParams().set('mode', 'single');
        if (submit) {
            httpParams = httpParams.set('submit', 'true');
        }

        return this.httpClient.post<{ id: string }>(moveUri.registerUri, registerRequest, {
            params: httpParams,
        });
    }

    public confirm(confirmRequest: ConfirmRequest): Observable<{ move: Move; token: string }> {
        if (confirmRequest?.accessToken) {
            const httpParams: HttpParams = new HttpParams().set('accessToken', confirmRequest.accessToken);
            return this.httpClient.patch<{ move: Move; token: string }>(
                StringUtils.parseUri(moveUri.confirmUri, { id: confirmRequest.moveId }),
                {},
                {
                    params: httpParams,
                }
            );
        } else {
            return this.httpClient.patch<{ move: Move; token: string }>(
                StringUtils.parseUri(moveUri.confirmUri, { id: confirmRequest.moveId }),
                {}
            );
        }
    }

    public submit(id: string): Observable<void> {
        return this.httpClient.patch<void>(StringUtils.parseUri(moveUri.submitUri, { id }), null);
    }

    public confirmOffers(id: string, accessToken?: string): Observable<void> {
        if (accessToken) {
            const httpParams: HttpParams = new HttpParams().set('accessToken', accessToken);
            return this.httpClient.patch<void>(StringUtils.parseUri(moveUri.confirmOffersUri, { id }), null, {
                params: httpParams,
            });
        } else {
            return this.httpClient.patch<void>(StringUtils.parseUri(moveUri.confirmOffersUri, { id }), null);
        }
    }

    public confirmEnergy(accessToken: string, id: string): Observable<void> {
        let httpParams: HttpParams = new HttpParams();
        if (accessToken) {
            httpParams = httpParams.set('accessToken', accessToken);
        }
        return this.httpClient.post<void>(StringUtils.parseUri(moveUri.confirmEnergyUri, { id }), null, {
            params: httpParams,
        });
    }

    public sendConfirmation(sendConfirmationRequest: SendConfirmationRequest): Observable<void> {
        return this.httpClient.post<void>(StringUtils.parseUri(moveUri.sendConfirmation, { id: sendConfirmationRequest?.id }), {
            email: sendConfirmationRequest?.email,
            location: sendConfirmationRequest?.location,
        });
    }

    public patch(moveId: string, patchMoveRequest: PatchMoveRequest, bypassIsDraft = false, accessToken?: string): Observable<any> {
        let options;

        if (!moveId) throwError('No moveId found.');
        if (!patchMoveRequest) throwError('Nothing to patch.');

        // todo - this is hack for updating move when guest ( EOTS confirm )
        if (accessToken) {
            const params = new HttpParams().set('accessToken', accessToken).set('id', moveId);
            return this.httpClient.patch<any>(moveUri.byAccessTokenAndIdUri, patchMoveRequest, { params });
        } else {
            if (bypassIsDraft) {
                const params = new HttpParams().set('bypassIsDraft', JSON.stringify(bypassIsDraft));
                options = { params };
            }
            return this.httpClient.patch<any>(StringUtils.parseUri(moveUri.byIdUri, { id: moveId }), patchMoveRequest, options);
        }
    }

    public expire(moveId: string): Observable<Move> {
        const params = { id: moveId };
        return this.httpClient.patch<Move>(StringUtils.parseUri(moveUri.expireMoveUri, params), {});
    }

    public activate(moveId: string): Observable<Move> {
        const params = { id: moveId };
        return this.httpClient.patch<Move>(StringUtils.parseUri(moveUri.activateMoveUri, params), {});
    }

    public redoContactLog(id: string, contactLogId: string): Observable<void> {
        const params = { id, contactLogId };
        return this.httpClient.post<void>(StringUtils.parseUri(moveUri.redoContactLogUri, params), {});
    }

    public delete(moveId: string, options: DeleteMoveOptionsRequest): Observable<void> {
        const params = { id: moveId };
        return this.httpClient.post<void>(StringUtils.parseUri(moveUri.deleteMoveUri, params), ObjectUtils.buildPayload(options));
    }

    public getRealEstateAgentUrgentTodoCount(): Observable<TodoCount> {
        return this.httpClient.get<TodoCount>(moveUri.realEstateAgentUrgentTodoCountUri);
    }

    public getMovesForRealEstateAgent(
        getMovesForRealEstateAgentRequest: GetMovesForRealEstateAgentRequest
    ): Observable<PaginationResponse<Move>> {
        let httpParams: HttpParams = new HttpParams();
        if (getMovesForRealEstateAgentRequest?.filter) {
            const filter: RealEstateAgentFilter = {
                ...getMovesForRealEstateAgentRequest.filter,
                clusterLevel:
                    getMovesForRealEstateAgentRequest.filter.clusterLevel === ClusterLevelFilter.All
                        ? null
                        : getMovesForRealEstateAgentRequest.filter.clusterLevel,
                todos: getMovesForRealEstateAgentRequest.filter.todos?.length ? getMovesForRealEstateAgentRequest.filter.todos : null,
            };

            ObjectUtils.removeEmpty(filter);

            httpParams = httpParams.append('filter', JSON.stringify(filter));
        }

        if (getMovesForRealEstateAgentRequest?.pagination) {
            httpParams = httpParams.append(
                'pagination',
                JSON.stringify(HttpUtils.buildPaginationRequestObject(getMovesForRealEstateAgentRequest.pagination))
            );
        }

        return this.httpClient.get<PaginationResponse<Move>>(moveUri.realEstateAgentUri, {
            params: httpParams,
        });
    }

    public getMovesForAdmin(getMovesForAdminRequest: GetMovesForAdminRequest): Observable<PaginationResponse<Move>> {
        let httpParams: HttpParams = new HttpParams();
        if (getMovesForAdminRequest?.filter) {
            const filter: MovesFilterForAdmin = {};
            if (getMovesForAdminRequest?.filter?.search) {
                filter.search = encodeURIComponent(getMovesForAdminRequest.filter.search);
            }
            if (getMovesForAdminRequest?.filter?.minMovingDate) {
                filter.minMovingDate = getMovesForAdminRequest.filter.minMovingDate;
            }
            if (getMovesForAdminRequest?.filter?.status) {
                filter.status = getMovesForAdminRequest.filter.status;
            }
            if (getMovesForAdminRequest?.filter?.createdByFlow) {
                filter.createdByFlow = getMovesForAdminRequest.filter.createdByFlow;
            }
            if (getMovesForAdminRequest?.filter?.service) {
                filter.service = getMovesForAdminRequest.filter.service;
            }
            if (getMovesForAdminRequest?.filter?.moverRole) {
                filter.moverRole = getMovesForAdminRequest.filter.moverRole;
            }
            if (getMovesForAdminRequest?.filter?.toBookNextAction) {
                filter.toBookNextAction = getMovesForAdminRequest.filter.toBookNextAction;
            }
            if (getMovesForAdminRequest?.filter?.toTransferNextAction) {
                filter.toTransferNextAction = getMovesForAdminRequest.filter.toTransferNextAction;
            }
            if (getMovesForAdminRequest?.filter?.realEstateGroup) {
                filter.realEstateGroup = getMovesForAdminRequest.filter.realEstateGroup;
            }
            if (getMovesForAdminRequest?.filter?.servicedBy) {
                filter.servicedBy = getMovesForAdminRequest.filter.servicedBy;
            }
            if (getMovesForAdminRequest?.filter?.moverType) {
                filter.moverType = getMovesForAdminRequest.filter.moverType;
            }
            if (getMovesForAdminRequest?.filter?.interviewStatus) {
                filter.interviewStatus = getMovesForAdminRequest.filter.interviewStatus;
            }

            httpParams = httpParams.append('filter', JSON.stringify(filter));
        }

        if (getMovesForAdminRequest?.pagination) {
            httpParams = httpParams.append(
                'pagination',
                JSON.stringify(HttpUtils.buildPaginationRequestObject(getMovesForAdminRequest.pagination))
            );
        }

        return this.httpClient.get<PaginationResponse<Move>>(moveUri.adminUri, {
            params: httpParams,
        });
    }

    public getMailAdvanceAmount(moveId: string): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.httpClient.get<string>(StringUtils.parseUri(moveUri.mailAdvanceAmountUri, { id: moveId }), {
            headers,
            responseType: 'text' as any, // TS typing fix
        });
    }

    public getSmsConfirmEnergy(moveId: string): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.httpClient.get<string>(StringUtils.parseUri(moveUri.smsConfirmEnergyUri, { id: moveId }), {
            headers,
            responseType: 'text' as any, // TS typing fix
        });
    }

    public sendMailAdvanceAmount(moveId: string, content: string): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.mailAdvanceAmountUri, { id: moveId }), {
            content,
        });
    }

    public getMailMeterReadings(moveId: string): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.httpClient.get<string>(StringUtils.parseUri(moveUri.mailMeterReadingsUri, { id: moveId }), {
            headers,
            responseType: 'text' as any, // TS typing fix
        });
    }

    public getMailLeaverReadingAssets(moveId: string): Observable<{ subject: string; content: string }> {
        return this.httpClient.get<{ subject: string; content: string }>(
            StringUtils.parseUri(moveUri.mailLeaverReadingAssetsUri, { id: moveId })
        );
    }

    public postMeterReadings(moveId: string, content: string): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.meterReadingsUri, { id: moveId }), {
            content,
        });
    }

    public uploadEnergyMeterReadingAsset(moveId: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.energyMeterReadingAssetsUri, { id: moveId }), content);
    }

    public deleteEnergyMeterReadingAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.energyMeterReadingAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public uploadEnergyDocumentAsset(moveId: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.energyDocumentAssetsUri, { id: moveId }), content);
    }

    public deleteEnergyDocumentAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.energyDocumentAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public deleteEnergyAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.energyAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public uploadWaterMeterReadingAsset(moveId: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.waterMeterReadingAssetsUri, { id: moveId }), content);
    }

    public deleteWaterMeterReadingAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.waterMeterReadingAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public uploadWaterDocumentAsset(moveId: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.waterDocumentAssetsUri, { id: moveId }), content);
    }

    public deleteWaterDocumentAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.waterDocumentAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public uploadWaterIdCardAsset(moveId: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.waterIdCardAssetsUri, { id: moveId }), content);
    }

    public deleteWaterIdCardAsset(moveId: string, key: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.waterIdCardAssetsUri, { id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    public createContactLogForAddressees(moveId: string, formData: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.contactLogForAddresseesUri, { id: moveId }), formData);
    }

    public createContactLog(moveId: string, formData: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.contactLogUri, { id: moveId }), formData);
    }

    public updateContactLog(moveId: string, contactLogId: string, remark?: string): Observable<Move> {
        return this.httpClient.patch<Move>(`${StringUtils.parseUri(moveUri.contactLogUri, { id: moveId })}/${contactLogId}`, {
            remark,
        });
    }

    public deleteContactLog(moveId: string, contactLogId: string): Observable<Move> {
        return this.httpClient.delete<Move>(`${StringUtils.parseUri(moveUri.contactLogUri, { id: moveId })}/${contactLogId}`);
    }

    /**
     * Convert old active move into new one
     */
    public convertMove(conversionToken: string, accessToken?: string): Observable<void> {
        return this.httpClient.patch<void>(moveUri.conversionUri, { conversionToken, accessToken });
    }

    public getQuarterlyMovesForRealEstateGroup(
        quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest,
        paginationRequest: PaginationRequest,
        invoicingType: InvoicingType,
        period: Period
    ): Observable<PaginationResponse<Move>> {
        const { realEstateGroup, year, quarter } = quarterlyForRealEstateGroupRequest;
        const params = { realEstateGroup, year: year.toString(), quarter: quarter.toString() };

        return this.httpClient.get<PaginationResponse<Move>>(StringUtils.parseUri(moveUri.quarterlyForRealEstateGroupUri, params), {
            params: HttpUtils.buildPaginationRequest(paginationRequest).set('invoicingType', invoicingType).set('period', period),
        });
    }

    public getAsset(src: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(src, { observe: 'response', responseType: 'blob' });
    }

    public editReviewSuggestion(moveId: string, suggestion: string): Observable<Move> {
        return this.httpClient.patch<Move>(StringUtils.parseUri(moveUri.editReviewSuggestionUri, { id: moveId }), { suggestion });
    }

    public editWaterMeterReadings(moveId: string, waterMeterReadings: Water): Observable<Move> {
        return this.httpClient.patch<Move>(StringUtils.parseUri(moveUri.editWaterMeterReadingsUri, { id: moveId }), waterMeterReadings);
    }

    public getAllLogs(moveId: string): Observable<Log<ContactLogType | MilestoneLogType>[]> {
        return this.httpClient.get<Log<ContactLogType | MilestoneLogType>[]>(StringUtils.parseUri(moveUri.getAllLogsUri, { id: moveId }));
    }

    public uploadElectricityInvoices(id: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.electricityInvoiceUri, { id }), content);
    }

    public deleteElectricityInvoice(id: string, assetId: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.electricityInvoiceIdUri, { id, assetId }));
    }

    public uploadGasInvoices(id: string, content: FormData): Observable<Move> {
        return this.httpClient.post<Move>(StringUtils.parseUri(moveUri.gasInvoiceUri, { id }), content);
    }

    public deleteGasInvoice(id: string, assetId: string): Observable<Move> {
        return this.httpClient.delete<Move>(StringUtils.parseUri(moveUri.gasInvoiceIdUri, { id, assetId }));
    }

    public getReviewHistory(id: string): Observable<InterviewResult> {
        return this.httpClient.get<InterviewResult>(StringUtils.parseUri(moveUri.reviewHistoryUri, { id }));
    }
}
