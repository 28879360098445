import { InjectionToken } from '@angular/core';
import { BaseUriConfig } from '../../configs';

export class WidgetsUriConfig extends BaseUriConfig {
    public readonly byUrlUri: string;
    private readonly widgetBaseEndpointUri: string;

    constructor(apiUri: string) {
        super(apiUri);
        this.widgetBaseEndpointUri = `${this.apiUri}/widget`;
        this.byUrlUri = `${this.widgetBaseEndpointUri}/:id/influencer-score`;
    }
}

export const WIDGETS_URI_CONFIG = new InjectionToken<WidgetsUriConfig>('WIDGETS_URI_CONFIG');
