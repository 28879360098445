import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { ObjectUtils, RxjsComponent } from '@smooved/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as constants from './dashboard-actions.constants';
import { DashboardActionsChangedEvent } from './dashboard-actions.interfaces';

@Component({
    selector: 'app-dashboard-actions',
    templateUrl: './dashboard-actions.component.html',
})
export class DashboardActionsComponent extends RxjsComponent implements OnInit {
    @Output() public actionsChange = new EventEmitter<DashboardActionsChangedEvent>();

    public moverRoleOptions = constants.moverRoleOptions;
    public actionsForm = this.formBuilder.group({
        role: [constants.moverRoleDefault],
    });

    public get moverRole(): MoverRole {
        return this.actionsForm.get('role').value;
    }

    constructor(private readonly formBuilder: UntypedFormBuilder) {
        super();
    }

    public ngOnInit(): void {
        this.setupActions().subscribe(this.handleActionsChanged);
    }

    private setupActions = () => {
        return this.actionsForm.valueChanges.pipe(distinctUntilChanged(ObjectUtils.isEqual), takeUntil(this.destroy$));
    };

    private handleActionsChanged = () => {
        this.actionsChange.emit(this.actionsForm.value);
    };
}
