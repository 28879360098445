import { AfterViewInit, Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInput } from '@app/form/interfaces/file-input';
import { Asset, documentTypes } from '@smooved/core';
import { BaseInput, ButtonAppearance, ButtonSize, ModalSandbox, UiContext } from '@smooved/ui';
import { merge } from 'lodash';

@Component({
    selector: 'app-assets-input',
    templateUrl: 'assets-input.component.html',
    styleUrls: ['./assets-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AssetsInputComponent),
            multi: true,
        },
    ],
})
export class AssetsInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public formControlName: string;
    @Input() public assets: Asset[];
    @Input() public readOnly = false;
    @Input() public acceptTypes = documentTypes;
    @Input() public label: string;
    @Input() public placeholder = 'NO_ASSET';
    @Input() public isAssetsRemovable = true;
    @Input() public showPreview = true;
    @Input() public showFileName = false;
    @Input() public buttonLabel = 'SELECT';
    @Input() public buttonAppearance: ButtonAppearance;
    @Input() public buttonSize: ButtonSize;
    @Input() public context: UiContext;
    @Input() public minified = true;
    @Input() public hint: string;

    @Output() public preview: EventEmitter<void> = new EventEmitter<void>();
    @Output() public detail: EventEmitter<FileInput> = new EventEmitter<FileInput>();
    @Output() public remove: EventEmitter<Asset> = new EventEmitter<Asset>();

    public previews: FileInput[];
    public files: File[] = [];

    public innerModel: any;

    constructor(
        @Optional() @Host() @SkipSelf() public readonly controlContainer: ControlContainer,
        private readonly modalSandbox: ModalSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public confirmDelete(asset: Asset): void {
        this.modalSandbox.onDeleteConfirm({ afterClosed: () => this.remove.emit(asset) });
    }

    public onChange(event): void {
        if (event.target.files) {
            this.files = <File[]>event.target.files;
            this.previews = [];
            Array.from(event.target.files).forEach((file: File) => {
                const reader = new FileReader();
                reader.onload = (_) =>
                    this.previews.push(
                        merge(file, {
                            value: reader.result,
                        })
                    );
                reader.readAsDataURL(file);
            });
        }
        this.onModelChange();
    }

    public trackByAssetKey(index, item: Asset | FileInput) {
        return item.key;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
