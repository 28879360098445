export enum SmoovedApplicationSource {
    CompanyPage = 'companyPage',
    ExternalInfluencer = 'externalInfluencer',
    Match = 'match',
    MapPage = 'mapPage',
    Upsell = 'upsell',
    DetailedEstimation = 'detailedEstimation',
    MatchHighIntent = 'matchHighIntent',
    LandingSuggestions = 'landingSuggestions',
    Report = 'report',
    Comparison = 'comparison',
}
