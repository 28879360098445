import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { showBillingSettings } from '@app/admin/modals/real-estate-group-detail/real-estate-group-detail.constants';
import { Package } from '@app/package/interfaces/package';
import { PackageService } from '@app/package/services/package.service';
import { EditPackageModalData, formControlNames } from '@app/real-estate-group/modals/edit-package/edit-package.constants';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { AuditUtils, DbUtils, ObjectUtils, RxjsComponent } from '@smooved/core';
import { ClosableModalTemplateComponent, DropdownInput, ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { finalize, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-edit-package-modal',
    templateUrl: './edit-package.modal.html',
})
export class EditPackageModal extends RxjsComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public readonly formControlNames = formControlNames;
    public loading = false;
    public showBillingSettings = true;

    public form = this.formBuilder.group({
        [formControlNames.package]: [null, [Validators.required]],
        [formControlNames.billingSettings]: null,
    });

    public options: DropdownInput<string>[];
    public showBillingSettings$ = this.form.get(formControlNames.package).valueChanges.pipe(
        takeUntil(this.destroy$),
        map((value: string) => showBillingSettings(this.packages.find((pack) => DbUtils.getStringId(pack) === value)?.key))
    );

    private packages: Package[];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly packageService: PackageService,
        private readonly modalSandbox: ModalSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: EditPackageModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        this.packageService.getAll().subscribe((packages) => {
            this.packages = packages;
            this.options = packages.map((x) => this.optionsFactory(x));
            this.form.patchValue({
                [formControlNames.package]: DbUtils.getStringId(this.data.realEstateGroup.package.value),
                [formControlNames.billingSettings]: this.data.realEstateGroup.billingSettings,
            });
        });
    }

    private optionsFactory(p: Package): DropdownInput<string> {
        const { name } = p;
        return {
            id: name,
            label: name,
            value: p._id,
            name: 'package',
        };
    }

    public onSubmit(): void {
        if (this.form.valid) {
            const config: MatDialogConfig = {
                data: this.translateService.instant('UPDATE.CONFIRM'),
            };
            this.modalSandbox.openConfirmModal(config, this.handleOnUpdate(), config, this.handleOnUpdate());
        }
    }

    private handleOnUpdate() {
        return (confirm: boolean) => {
            if (confirm) {
                this.loading = true;
                const update = ObjectUtils.difference(
                    { ...this.form.value },
                    {
                        package: DbUtils.getStringId(AuditUtils.getValue(this.data.realEstateGroup.package)),
                        billingSettings: this.data.realEstateGroup.billingSettings,
                    }
                );
                this.realEstateGroupSandbox
                    .update(DbUtils.getStringId(this.data.realEstateGroup), update)
                    .pipe(finalize(() => (this.loading = false)))
                    .subscribe((response) => {
                        this.data.onUpdateHandler(response);
                        this.notificationSandbox.updatedSuccess();
                        this.closableModalComponent.close(true);
                    });
            }
        };
    }
}
