import { TodoState } from '@smooved/ui';

export const serviceInvitedI18nKeys = {
    Todo: 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.INVITED',
    Resend: 'RESEND',
    Confirm: 'RESEND_INVITE.CONFIRM',
    Success: 'RESEND_INVITE.SUCCESS',
};

export const completedTodoStates = [TodoState.Checked, TodoState.Blocked];
