import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-suggestion-selection-energy-transfer',
    template: `
        <table>
            <tr>
                <td class="u-vertical-align-middle u-padding-right-half u-text-align-right" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                    <img src="assets/images/smooved-energy-bulb.png" width="50px" class="__image u-display-block" />
                </td>

                <td class="u-vertical-align-middle u-w100p">
                    <div class="u-flex-column u-flex-align-items-start">
                        <h5>{{ 'ENERGY_TRANSFER' | translate }}</h5>
                        <p class="u-color-muted">
                            {{ (moveSandbox.energyTransfer$ | async)?.currentSupplier }} -
                            {{ (moveSandbox.energyTransfer$ | async)?.clientNumber }}
                        </p>
                    </div>
                </td>
            </tr>
        </table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionEnergyTransferComponent {
    constructor(public moveSandbox: MoveSandbox, public uiSandbox: AppUiSandbox) {}
}
