<app-tri-panel-modal
    *ngIf="{order: (telecomDetailService.order$ | async), isTransfer: (telecomDetailService.isTelecomTransfer$ | async)} as vm"
    [loading]="telecomDetailService.loading$ | async"
>
    <ng-container modal-header>
        <h5 *ngIf="vm.isTransfer; else newOfferHeader">{{'A_TRANSFER' | translate }} {{vm.order.orderId}}</h5>
        <ng-template #newOfferHeader><h5>{{'AN_ORDER' | translate }} {{vm.order.orderId}}</h5></ng-template>
    </ng-container>

    <ng-container modal-left>
        <h5 class="u-margin-bottom">{{"DATA" | translate}}</h5>

        <ui-info [label]="'FOR' | translate" [variant]="uiHeaderVariant.H6">
            <app-user-details [order]="vm.order"></app-user-details>
        </ui-info>

        <ui-info [label]="'DATE_OF_BIRTH' | translate" [variant]="uiHeaderVariant.H6">
            <p>{{ vm.order.move.user.dateOfBirth | formatDate }}</p>
        </ui-info>

        <ui-info [label]="'MOVE.USER.PASSPORT_NUMBER' | translate" [variant]="uiHeaderVariant.H6">
            <app-copy-to-clipboard *ngIf="vm.order.move.user.passportNumber as passportNumber; else empty"
                >{{ passportNumber }}</app-copy-to-clipboard
            >
        </ui-info>

        <ui-info
            *ngIf="!!vm.order.move.user.btwNumber && vm.order.move.telecomPaymentType === paymentType.Domiciliation"
            [label]="'MOVE.USER.BTW_NUMBER' | translate"
            [variant]="uiHeaderVariant.H6"
        >
            <app-copy-to-clipboard>{{ vm.order.move.user.btwNumber }}</app-copy-to-clipboard>
        </ui-info>

        <ui-info [label]="'MOVE.USER.MOVING_ADDRESS' | translate" [variant]="uiHeaderVariant.H6">
            <p>{{ vm.order.move.user.movingAddress | formatAddress}}</p>
        </ui-info>

        <ui-info [label]="'MOVE.USER.CURRENT_ADDRESS' | translate" [variant]="uiHeaderVariant.H6">
            <p>{{ vm.order.move.user.currentAddress | formatAddress}}</p>
        </ui-info>

        <ui-info [label]="'MOVE.USER.CONTACT_ADDRESS' | translate" [variant]="uiHeaderVariant.H6">
            <p>{{ vm.order.move.user.contactAddress | formatAddress }}</p>
        </ui-info>

        <ui-info [label]="'HOME_CONSTRUCTION_YEAR.LABEL_SHORT' | translate" [variant]="uiHeaderVariant.H6">
            <p>
                {{ vm.order.move.meta?.homeConstructionYear ? ('HOME_CONSTRUCTION_YEAR.' +
                vm.order.move.meta.homeConstructionYear.toUpperCase() | translate) : '-' }}
            </p>
        </ui-info>

        <ui-info
            *ngIf="vm.order.move.companyName as companyName"
            [label]="'COMPANY_DETAILS.COMPANY_NAME' | translate"
            [variant]="uiHeaderVariant.H6"
        >
            <app-copy-to-clipboard>{{ companyName }}</app-copy-to-clipboard>
        </ui-info>

        <ui-info
            *ngIf="vm.order.move.vatNumber as vatNumber"
            [label]="'COMPANY_DETAILS.COMPANY_NUMBER' | translate"
            [variant]="uiHeaderVariant.H6"
        >
            <app-copy-to-clipboard>{{ vatNumber }}</app-copy-to-clipboard>
        </ui-info>
    </ng-container>

    <ng-container modal-center>
        <h5 class="u-margin-bottom" *ngIf="vm.isTransfer; else newOffer">{{'A_TRANSFER' | translate }}</h5>
        <ng-template #newOffer><h5 class="u-margin-bottom">{{'AN_ORDER' | translate }}</h5></ng-template>
        <app-telecom-details [move]="vm.order.move" [isTransfer]="vm.isTransfer"></app-telecom-details>
    </ng-container>

    <ng-container modal-right>
        <h5 class="u-margin-bottom">{{"STATE" | translate}}</h5>
        <app-telecom-order-details
            [order]="vm.order"
            [authorization]="authenticationSandbox.authorization$ | async"
            (orderNoteChange)="onOrderNoteChange($event)"
            (patch)="patchMoveByOrder($event)"
        ></app-telecom-order-details>
    </ng-container>
</app-tri-panel-modal>

<ng-template #empty>-</ng-template>
