import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-telecom-mobile-bundle-output',
    template: `<p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE' | translate }}</p>
        <div
            [ngClass]="{ 'u-margin-bottom-half': !last }"
            *ngFor="let bundle of move?.telecomOffer?.mobileBundle; let i = index; let last = last"
        >
            <p>{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SIM_CARD' | translate }}: #{{ i + 1 }}</p>
            <p>
                {{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.NEW_NUMBER' | translate }}:
                {{ bundle.newNumber?.toString().toUpperCase() | translate }}
            </p>
            <div *ngIf="!bundle.newNumber">
                <p>{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SIM_CARD_NUMBER' | translate }}: {{ bundle.simCardNumber }}</p>
                <p>{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.PHONE_NUMBER' | translate }}: {{ bundle.phoneNumber }}</p>
                <p>
                    {{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SUBSCRIPTION_TYPE' | translate }}:
                    {{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SUBSCRIPTION_TYPE.' + bundle.subscriptionType?.toUpperCase() | translate }}
                </p>
            </div>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomMobileBundleOutputComponent {
    @Input() public move: Move;
}
