import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/invoicing`;
export const byIdUri = `${baseUri}/:id`;
export const quarterUri = `${baseUri}/quarters/:year/:quarter`;
export const invoiceUri = `${baseUri}/:realEstateGroupId/:year/:quarter`;
export const invoicesUri = `${baseUri}/:year/:quarter`;
export const quarterlyUri = `${baseUri}/quarterly/:realEstateGroupId/:year/:quarter`;
export const quarterlyFeesUri = `${baseUri}/fees/:year/:quarter/:realEstateGroupId`;
export const quarterlyTotalsUri = `${baseUri}/quarterly/:year/:quarter/totals`;
export const lastBillableInvoiceUri = `${baseUri}/:realEstateGroupId/last-billable`;
export const billableInvoicesUri = `${baseUri}/:realEstateGroupId/billable`;
export const assetUri = `${baseUri}/:id/asset/:invoiceType`;
export const downloadAssetUri = `${baseUri}/download/:id/asset/:invoiceType`;
