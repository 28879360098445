import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { Move } from '@app/move/interfaces/move';
import { SimpleChangesUtils } from '@smooved/core';
import { ChartItem, ColorScale } from '@smooved/ui';

@Component({
    selector: 'app-quarter-report-insurances',
    template: `
        <app-loading-container [loading]="loading" [ngSwitch]="closed">
            <div class="u-flex-row u-flex-align-items-center">
                <div class="u-padding-top u-padding-right-double u-padding-bottom u-padding-left-double">
                    <app-pie-chart [width]="160" [height]="160" [id]="'overall'" [data]="pieData"></app-pie-chart>
                </div>
                <table class="u-w100p u-padding-right">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="u-text-align-right u-color-muted u-font-weight-normal">
                                {{ 'INVOICING.OVERALL.MOVES' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="u-cursor-pointer __row"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.InsurancesConfirmed }"
                            (click)="typeChange.emit(invoicingType.InsurancesConfirmed)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.INSURANCES.CONFIRMED' | translate"
                                    [order]="1"
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right">{{ quarterlyInvoicing?.insurances.confirmedCount }}</td>
                        </tr>
                        <tr
                            class="u-cursor-pointer __row"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.InsurancesPending }"
                            (click)="typeChange.emit(invoicingType.InsurancesPending)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.INSURANCES.PENDING' | translate"
                                    [order]="3"
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">{{ quarterlyInvoicing?.insurances.pendingCount }}</td>
                        </tr>
                        <tr
                            class="u-cursor-pointer __row"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.InsurancesLost }"
                            (click)="typeChange.emit(invoicingType.InsurancesLost)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.INSURANCES.LOST' | translate"
                                    [order]="4"
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">{{ quarterlyInvoicing?.insurances.lostCount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-loading-container>
    `,
})
export class QuarterReportInsurancesComponent implements OnInit, OnChanges {
    @Input() public loading: boolean;
    @Input() public type: InvoicingType;
    @Input() public quarterlyInvoicing: QuarterlyInvoicing;
    @Input() public closed: boolean;
    @Input() public realEstateGroupId: string;
    @Input() public quarterDate: Date;

    @Output() public typeChange: EventEmitter<InvoicingType> = new EventEmitter<InvoicingType>();
    @Output() public openDetail: EventEmitter<Move> = new EventEmitter<Move>();

    public invoicingType = InvoicingType;
    public pieData: ChartItem[];

    public ngOnInit(): void {
        this.typeChange.emit(InvoicingType.InsurancesConfirmed);
    }

    public ngOnChanges({ quarterlyInvoicing }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(quarterlyInvoicing)) {
            this.createChartData(quarterlyInvoicing?.currentValue);
        }
    }

    private createChartData(invoice: QuarterlyInvoicing): void {
        if (!invoice) return;
        this.pieData = [
            {
                label: 'INVOICING.INSURANCES.CONFIRMED',
                value: invoice?.insurances.confirmedCount || 0,
                color: ColorScale.Color1,
            },
            {
                label: 'INVOICING.INSURANCES.PENDING',
                value: invoice?.insurances.pendingCount || 0,
                color: ColorScale.Color3,
            },
            {
                label: 'INVOICING.INSURANCES.LOST',
                value: invoice?.insurances.lostCount || 0,
                color: ColorScale.Color4,
            },
        ];
    }
}
