import { ActivationStage } from '../enums';

export class ActivationStageUtils {
    public static isUnclaimed(state: ActivationStage): boolean {
        return state === ActivationStage.UnclaimedByBroker;
    }

    public static isVerifiedOrActive(state: ActivationStage): boolean {
        if (!state) return false;
        return [ActivationStage.VerifiedBySmooved, ActivationStage.ActivatedInSmooved].includes(state);
    }

    public static hasVerifiedBadge(stage: ActivationStage): boolean {
        return this.isVerifiedOrActiveOrInactive(stage);
    }

    public static isRelevant(stage: ActivationStage): boolean {
        return this.isVerifiedOrActiveOrInactive(stage);
    }

    private static isVerifiedOrActiveOrInactive(stage: ActivationStage): boolean {
        return [ActivationStage.VerifiedBySmooved, ActivationStage.ActivatedInSmooved, ActivationStage.InactivatedInSmooved].includes(
            stage
        );
    }
}
