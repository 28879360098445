import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ArrayUtils, FeatureScope } from '@smooved/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FeatureScopeState, getFeatureScopesConfigState, getFeatureScopesState } from '../state/feature-scope.reducer';
import { FeatureScopeService } from '../services/feature-scope.service';
import { getFeatureScopesSuccess } from '../state/feature-scope.actions';

@Injectable({
    providedIn: 'root',
})
export class FeatureScopeSandbox {
    public featureScopes$ = this.store$.pipe(select(getFeatureScopesState));
    public featureScopesOnce$ = this.featureScopes$.pipe(take(1));
    public featureScopesConfig$ = this.store$.pipe(select(getFeatureScopesConfigState));

    constructor(
        private readonly featureScopeService: FeatureScopeService,
        private readonly store$: Store<{
            loadingOverlay: boolean;
            moveLoading: boolean;
            patchLoading: boolean;
        }>
    ) {}

    public get(): void {
        this.featureScopeService.get().subscribe((result: FeatureScopeState) => {
            this.dispatch(result);
        });
    }

    public dispatch(state: FeatureScopeState): void {
        this.store$.dispatch(getFeatureScopesSuccess(state));
    }

    public hasAccess(featureScope: FeatureScope): Observable<boolean> {
        return this.featureScopesOnce$.pipe(map((featureScopes: FeatureScope[]) => ArrayUtils.contains(featureScopes, featureScope)));
    }
}
