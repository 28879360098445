import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TelecomInstallation, TelecomInstallationTimeSlot } from '@app/wizard/telecom/telecom.interfaces';
import { ClosableModalTemplateComponent, SelectInput } from '@smooved/ui';

@Component({
    selector: 'app-telecom-installation-modal',
    template: `
        <app-closable-modal-template maxWidth="none">
            <div
                class="u-margin-left-double-negative u-margin-right-double-negative u-padding-bottom u-margin-bottom-double u-bordered-bottom u-text-align-center"
            >
                <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                    <h5 class="__title">{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.INSTALLATION_DATE_SCHEDULE' | translate }}</h5>
                </div>
            </div>
            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <app-date-input
                    [id]="'telecom-installation-date'"
                    [label]="'COMPLETE.TELECOM_INSTALLATION.DATE.LABEL' | translate"
                    placeholder="dd/mm/jjjj"
                    [minDate]="minDate"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [datepickerFilter]="noWeekendFilter"
                    formControlName="date"
                ></app-date-input>

                <app-select-input
                    [label]="'COMPLETE.TELECOM_INSTALLATION.TIME_SLOT.LABEL' | translate"
                    [custom]="false"
                    [options]="timeSlotOptions"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    formControlName="timeSlot"
                ></app-select-input>

                <div class="u-flex-row u-flex-justify-content-center">
                    <app-button type="submit">{{ 'SUBMIT' | translate }}</app-button>
                </div>
            </form>
        </app-closable-modal-template>
    `,
    styleUrls: ['./telecom-installation.modal.scss'],
})
export class TelecomInstallationModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public minDate: Date = new Date();

    public form: UntypedFormGroup = this.formBuilder.group({
        date: [null, [Validators.required]],
        timeSlot: [null, [Validators.required]],
    });

    public timeSlotOptions: SelectInput<TelecomInstallationTimeSlot>[] = [
        {
            id: 'time-1',
            value: TelecomInstallationTimeSlot.slot1,
            name: 'time',
            label: TelecomInstallationTimeSlot.slot1,
        },
        {
            id: 'time-2',
            value: TelecomInstallationTimeSlot.slot2,
            name: 'time',
            label: TelecomInstallationTimeSlot.slot2,
        },
        {
            id: 'time-3',
            value: TelecomInstallationTimeSlot.slot3,
            name: 'time',
            label: TelecomInstallationTimeSlot.slot3,
        },
        {
            id: 'time-4',
            value: TelecomInstallationTimeSlot.slot4,
            name: 'time',
            label: TelecomInstallationTimeSlot.slot4,
        },
    ];

    constructor(private formBuilder: UntypedFormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: TelecomInstallation) {}

    public ngOnInit() {
        if (!this.data) return;
        this.form.patchValue(this.data);
    }

    public onSubmit() {
        if (!this.form.valid) return;
        this.closeDialog(this.form.value);
    }

    public noWeekendFilter(date: Date): boolean {
        return date.getDay() !== 6 && date.getDay() !== 0;
    }

    private closeDialog(payload?: any): void {
        this.closableModalComponent.close(payload);
    }
}
