import { EnergyType } from '../enums/energy-type.enum';

export class MoveUtils {
    public static installationDateDifferent(move): boolean {
        if (!move) return false;
        return (
            !!move.telecomInstallationScheduleByAdmin &&
            !!move.telecomOffer.telecomInstallation &&
            (move.telecomInstallationScheduleByAdmin.date !== move.telecomOffer.telecomInstallation.date ||
                move.telecomInstallationScheduleByAdmin.timeSlot !== move.telecomOffer.telecomInstallation.timeSlot)
        );
    }

    public static requiresPhoneTransfer(move): boolean {
        if (!move) return false;
        const { telecomBundle } = move?.telecomOffer;
        return telecomBundle?.mobilePhone || telecomBundle?.fixedLine;
    }

    public static fixedLineNewNumber(move): boolean {
        if (!move) return false;
        return move.telecomOffer?.fixedLine?.newNumber;
    }

    public static isTelecomTransfer(move): boolean {
        if (!move) return false;
        return !!move.telecomTransfer?.currentSupplier && !!move.telecomTransfer?.clientNumber;
    }

    public static telecomTotalPrice(move, inclusivePromotions: boolean = false): number {
        if (!move) return 0;
        const { telecomOfferSelection, tvBundles } = move.telecomOffer;
        if (!telecomOfferSelection) return 0;
        const { discount, price } = telecomOfferSelection;
        const startPrice = inclusivePromotions ? discount || price : price;
        if (!tvBundles) {
            return startPrice;
        }
        return startPrice + tvBundles.reduce((total, currentValue) => total + currentValue.price, 0);
    }

    public static isVacancy(move): boolean {
        return !!move?.energyOffer?.energyOfferSelection?.vacancy;
    }

    public static eanCount(move): number {
        switch (move?.energyOffer?.energyType) {
            case EnergyType.Both:
                return 2;
            case EnergyType.Electricity:
            case EnergyType.Gas:
                return 1;
            default:
                return 0;
        }
    }

    public static findOfficeByRealEstateAgent(move) {
        if (!move?.realEstateGroup?.offices || !move?.realEstateAgent) return null;
        return move.realEstateGroup.offices.find((x) => x.realEstateAgents.includes(move.realEstateAgent._id));
    }

    public static hasElectricityEnergyType(energyType: EnergyType): boolean {
        return [EnergyType.Both, EnergyType.Electricity].includes(energyType);
    }

    public static hasGasEnergyType(energyType: EnergyType): boolean {
        return [EnergyType.Both, EnergyType.Gas].includes(energyType);
    }
}
