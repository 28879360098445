import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'empty',
})
export class EmptyPipe implements PipeTransform {
    transform(value: string, opts?: { emptyAs?: string }): string {
        if (!(value && value.length)) return opts?.emptyAs ?? value;
        return value;
    }
}
