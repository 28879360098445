import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { ModalSandbox, TextModalComponent } from '@smooved/ui';

@Component({
    selector: 'app-note-for-smooved',
    template: `
        <div class="u-inline-flex-row u-margin-left-half">
            <span>(#{{ order.orderId }})</span>
            <span
                *ngIf="!!order.noteForSmooved"
                class="material-icons u-color-error u-cursor-pointer u-margin-left-half"
                (click)="showTextModal(order.noteForSmooved)"
                >info</span
            >
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteForSmoovedComponent {
    @Input() public order: Order;

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public showTextModal(text: string): void {
        this.modalSandbox.openInfoModal(TextModalComponent, text);
    }
}
