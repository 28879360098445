import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Language } from '../enums';

/**
 * Force Translate pipe
 * Use this pipe in case an other language than the current language is required
 * */
@Pipe({
    name: 'ftranslate',
})
export class ForceTranslatePipe implements PipeTransform {
    constructor(private readonly translate: TranslateService) {}

    public transform(key: string, language: Language, interpolateParams?: object): Observable<string> {
        return this.translate.getTranslation(language).pipe(
            switchMap((translations) => {
                const compiled = this.translate.compiler.compileTranslations(translations, language) as string;
                const parsedString = this.translate.getParsedResult(compiled, key, interpolateParams) as string;
                return of(parsedString);
            })
        );
    }
}
