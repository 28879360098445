import { Transferee } from '@app/move/enums/transferee.enum';
import { SelectInput, Svg } from '@smooved/ui';

export const transfereeTypeOptions: SelectInput<Transferee>[] = [
    {
        id: 'transferee-type-tenant-transferee',
        svg: Svg.Single,
        labelResource: 'MOVE.TRANSFEREE.TENANT_TRANSFEREE.LABEL',
        value: Transferee.TenantTransferee,
        name: 'transferee-type',
    },
    {
        id: 'transferee-type-owner-transferee',
        svg: Svg.Post2000,
        labelResource: 'MOVE.TRANSFEREE.OWNER_TRANSFEREE.LABEL',
        value: Transferee.OwnerTransferee,
        name: 'transferee-type',
    },
];

export const transfereeTypeFormControlPath = 'transfereeType';
