import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '@smooved/core';

@Pipe({
    name: 'arcScoreValue',
})
export class ArcScoreValuePipe implements PipeTransform {
    public transform(value: any, max = 10): string {
        if (!NumberUtils.isNumber(value) || value === max) return value;
        return NumberUtils.roundValue(value, 1);
    }
}
