<app-todo *ngIf="move" [label]="i18nKeys.Todo | translate" [state]="move.track.transferee.energyOfferSent">
    <app-todo-template *todoStateChecked>
        <a
            app-button
            *ngIf="move.track.transferee.energyOfferConfirmed !== todoState.Checked"
            (click)="resendConfirm()"
            [appearance]="buttonAppearance.Link"
            [size]="buttonSize.Sm"
            >{{ i18nKeys.Resend | translate }}</a
        >
        <span>{{ move.track.lastModifiedOn.energyOfferSent | formatDate }}</span>
    </app-todo-template>
</app-todo>
