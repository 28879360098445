import { Pipe, PipeTransform } from '@angular/core';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentUtils } from '@smooved/core';

@Pipe({
    name: 'isInactiveRealEstateAgent',
})
export class IsInactiveRealEstateAgentPipe implements PipeTransform {
    public transform(value: Partial<RealEstateAgent>): boolean {
        return RealEstateAgentUtils.isInactive(value);
    }
}
