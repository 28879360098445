import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noDecimals } from '@smooved/core';
import { UiDirection } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { InfluencerScoreModalData } from '../../interfaces';

@Component({
    selector: 'app-influencer-score',
    templateUrl: 'influencer-score.modal.html',
    styleUrls: ['./influencer-score.modal.scss'],
})
export class InfluencerScoreModal {
    public readonly noDecimals = noDecimals;
    public readonly direction = UiDirection;
    public isTabletPortraitUp$ = this.uiSandbox.tabletPortraitUp$;

    constructor(private readonly uiSandbox: UiSandbox, @Inject(MAT_DIALOG_DATA) public data: InfluencerScoreModalData) {}
}
