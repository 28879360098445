import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserLoginRequest } from '../../interfaces/user-login.request';

@Component({
    selector: 'app-admin-login',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column">
            <h3 class="u-margin-bottom">Inloggen als admin</h3>

            <app-email-input
                [id]="'email'"
                [label]="'Wat is jouw e-mailadres?'"
                [placeholder]="'E-mail'"
                [formControlName]="'email'"
            ></app-email-input>

            <app-password-input
                [id]="'password'"
                [label]="'Geef jouw wachtwoord'"
                [placeholder]="'Wachtwoord'"
                [formControlName]="'password'"
            ></app-password-input>

            <div class="u-flex-column u-flex-align-items-center">
                <app-button type="submit" [disabled]="form.invalid" class="u-w100p">Inloggen</app-button>
            </div>
        </form>
    `,
})
export class AdminLoginComponent {
    @Output() public formSubmit: EventEmitter<UserLoginRequest> = new EventEmitter();

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required]],
        password: [null, [Validators.required]],
    });

    constructor(private formBuilder: UntypedFormBuilder) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.formSubmit.emit({
                email: this.form.value.email,
                password: this.form.value.password,
            });
        }
    }
}
