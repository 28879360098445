import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as adminRouting from '@app/admin/constants/routing.constants';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { LoginDialog } from '@app/authentication/dialogs/login/login.dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { HeaderService } from '@app/header/services/header.service';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { environment } from '@environments/environment';
import { FeatureScope, FeatureScopeSandbox, RxjsComponent } from '@smooved/core';
import { MenuItemSize, SvgIllustration, UiSandbox } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header-menu-mobile',
    templateUrl: './header-menu-mobile.component.html',
    styleUrls: ['./header-menu-mobile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderMenuMobileComponent extends RxjsComponent implements OnInit {
    @Output() public onItemClick: EventEmitter<void> = new EventEmitter<void>();

    public faqUri = environment.faqUri;
    public adminUri = adminRouting.adminBaseRoute;
    public realEstateAgentLoginRouting = AppNavigationSandbox.getAbsolute(realEstateAgentLoginRouting);
    public readonly menuItemSize = MenuItemSize;
    public readonly illustrationEnum = SvgIllustration;

    private menuLevelSubject = new BehaviorSubject<number>(0);
    public menuLevel$ = this.menuLevelSubject.asObservable();

    constructor(
        public readonly authenticationSandbox: AuthenticationSandbox,
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly dialog: MatDialog,
        public readonly uiSandbox: UiSandbox,
        private readonly headerService: HeaderService
    ) {
        super();
    }

    public ngOnInit() {
        this.headerService.dispatchCloseMenu$.pipe(takeUntil(this.destroy$)).subscribe(() => this.onItemClick.emit());
        this.headerService.forwardMenu$.pipe(takeUntil(this.destroy$)).subscribe(() => this.advanceMenu());
    }

    public advanceMenu(): void {
        this.menuLevelSubject.next(this.menuLevelSubject.value + 1);
    }

    public backMenu(): void {
        this.menuLevelSubject.next(this.menuLevelSubject.value - 1);
    }

    public logout(): void {
        this.authenticationSandbox.logout();
        this.onItemClick.emit();
    }

    public login(): void {
        this.dialog.open(LoginDialog);
        this.onItemClick.emit();
    }

    protected readonly featureScope = FeatureScope;
}
