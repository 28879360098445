import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FeatureScope, NpsReview, PaginationResponse, RxjsComponent, SocialSharingService } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { ButtonAppearance } from '../../../button';
import { Brand, UiIconSize } from '../../../icon';
import {
    NPS_REVIEWS_TEMPLATE_CONFIG,
    NpsReviewsFilterService,
    NpsReviewsTemplateConfig,
    NpsReviewsTemplateService,
} from '../../../reviews';
import { UiContext } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { ModalsService } from '../../services';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.container.html',
    styleUrls: ['./reviews.container.scss'],
    providers: [NpsReviewsFilterService],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ReviewsContainer extends RxjsComponent implements OnInit {
    public sharingDisabled = false;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public readonly brands = Brand;
    public preloadedReviews: PaginationResponse<NpsReview>;
    public readonly featureScope = FeatureScope;

    constructor(
        public readonly reviewsTemplateService: NpsReviewsTemplateService,
        public readonly uiSandbox: UiSandbox,
        private readonly socialSharingService: SocialSharingService,
        private readonly modalsService: ModalsService,
        @Optional() @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public readonly templateConfig: NpsReviewsTemplateConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.preloadedReviews = this.reviewsTemplateService.getPreload();
        this.socialSharingService.sharing$.pipe(takeUntil(this.destroy$)).subscribe(this.handleWhileSharing);
    }

    public shareFacebook(review: NpsReview): void {
        this.socialSharingService.shareOnFacebook(review);
    }

    public shareLinkedIn(review: NpsReview): void {
        this.socialSharingService.shareOnLinkedIn(review);
    }

    public shareInstagram(review: NpsReview): void {
        this.socialSharingService.shareOnInstagram(review);
    }

    public download(review: NpsReview): void {
        this.socialSharingService.download(review);
    }

    public copyToClipboard(review: NpsReview): void {
        this.socialSharingService.copyToClipboard(review);
    }

    public openFilterModal(): void {
        this.modalsService.openReviewFilterModal();
    }

    private handleWhileSharing = (whileSharing: boolean): void => {
        this.sharingDisabled = whileSharing;
    };
}
