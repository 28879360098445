import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-card-container',
    template: ` <ng-content></ng-content> `,
    styleUrls: ['./card-container.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContainerContainer {
    @Input()
    @HostBinding('class.has-padding-up-to-and-including-phone-landscape')
    public hasPaddingUpToAndIncludingPhoneLandscape = true;

    @Input()
    @HostBinding('class.has-padding-tablet-portrait-up')
    public hasPaddingTabletPortraitUp = true;

    @Input()
    @HostBinding('class.has-padding-tablet-landscape-up')
    public hasPaddingTabletLandscapeUp = true;
}
