import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Move } from '@app/move/interfaces/move';
import { HomeDescription } from '@smooved/core';
import { isNumber } from 'lodash';

@Component({
    selector: 'app-extra-info-modal',
    template: `
        <app-closable-modal-template maxWidth="none" width="50vw">
            <div
                [ngSwitch]="!!data?.user?.formattedName"
                class="u-margin-left-double-negative u-margin-right-double-negative u-padding-bottom u-margin-bottom-double u-bordered-bottom u-text-align-center"
            >
                <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                    <h5 class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.LABEL' | translate }}</h5>
                    <h5 class="__title" *ngSwitchCase="true">{{ data?.user?.formattedName }}</h5>
                    <h5 *ngSwitchCase="false" class="u-color-muted __title">{{ data?.user?.email }}</h5>
                </div>
            </div>
            <div class="u-margin-bottom-double u-flex-column">
                <h6 class="u-margin-bottom-half">{{ 'LANGUAGE.LABEL' | translate }}</h6>
                <app-user-language *ngIf="!!data?.user?.language; else noInfo" [language]="data.user.language"></app-user-language>

                <h6 class="u-margin-top u-margin-bottom-half">{{ 'MOVE.USER.NATIONAL_NUMBER' | translate }}</h6>
                <span *ngIf="!!data?.user?.nationalNumber; else noInfo">{{ data.user.nationalNumber }}</span>
                <ng-template #noInfo>
                    <span class="u-color-muted u-opacity-50">{{ 'UNKNOWN' | translate }}</span>
                </ng-template>
            </div>
            <div class="u-margin-bottom-double u-flex-column">
                <h6 class="u-margin-bottom-half">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.FAMILY' | translate }}</h6>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.CHILDREN' | translate }}</h6>
                    <p>{{ data?.meta?.familyMemberCount?.childrenCount || '-' }}</p>
                </div>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.TEENAGERS' | translate }}</h6>
                    <p>{{ data?.meta?.familyMemberCount?.teenagersCount || '-' }}</p>
                </div>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.ADULTS' | translate }}</h6>
                    <p>{{ data?.meta?.familyMemberCount?.adultsCount || '-' }}</p>
                </div>
            </div>

            <div class="u-margin-bottom-double u-flex-column">
                <h6 class="u-margin-bottom-half">{{ 'LEAVER.TYPE.LAND_AGENT' | translate }}</h6>
                <p>{{ (!!data?.user?.landAgent ? 'YES' : 'NO') | translate }}</p>
            </div>

            <div class="u-margin-bottom-double u-flex-column" *ngIf="!!data?.meta">
                <h6 class="u-margin-bottom-half">{{ 'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.RESIDENCE' | translate }}</h6>
                <p>
                    {{ 'HOME_DESCRIPTION.' + data?.meta?.homeDescription?.toUpperCase() | translate }} ({{
                        data?.meta?.homeDescriptionSize
                    }})
                </p>
                <p
                    *ngIf="showHomeDescriptionApartmentLevel"
                    [innerHTML]="
                        'ADMIN.DASHBOARD.DETAIL.EXTRA_INFO.N_FLOOR' | translate: { floor: data?.meta?.homeDescriptionApartmentLevel }
                    "
                ></p>
                <p *ngIf="data?.meta?.homeConstructionYear as homeConstructionYear">
                    {{ 'HOME_CONSTRUCTION_YEAR.' + homeConstructionYear?.toUpperCase() | translate }}
                </p>
            </div>

            <div class="u-margin-bottom-double u-flex-column" *ngIf="!!data?.realEstateAgent">
                <h6 class="u-margin-bottom-half">{{ 'REAL_ESTATE_AGENT.LABEL' | translate }}</h6>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'NAME' | translate }}</h6>
                    <p>{{ data?.realEstateAgent?.firstName }} {{ data?.realEstateAgent?.lastName }}</p>
                </div>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'PHONE_NUMBER' | translate }}</h6>
                    <p>{{ data?.realEstateAgent?.phoneNumber }}</p>
                </div>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'EMAIL' | translate }}</h6>
                    <p>{{ data?.realEstateAgent?.email }}</p>
                </div>
                <div class="u-flex">
                    <h6 class="u-w25p u-color-muted">{{ 'ADMIN.OFFICES.OFFICE_NAME' | translate }}</h6>
                    <p>{{ data?.realEstateAgent?.office }}</p>
                </div>
            </div>
        </app-closable-modal-template>
    `,
    styleUrls: ['./extra-info.modal.scss'],
})
export class ExtraInfoModal implements OnInit {
    public office: string;

    public showHomeDescriptionApartmentLevel = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Move) {}

    public ngOnInit(): void {
        this.showHomeDescriptionApartmentLevel =
            this.data.meta?.homeDescription === HomeDescription.Apartment && isNumber(this.data.meta?.homeDescriptionApartmentLevel);
    }
}
