import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { UiVerticalAlignment } from '../ui.enums';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[badgeVerticalAlign]',
})
export class BadgeVerticalAlignDirective {
    @Input() set badgeVerticalAlign(value: UiVerticalAlignment) {
        if (!value) this.renderer.addClass(this.el.nativeElement, 'badge-vertical-middle');
        if (value === UiVerticalAlignment.Top) this.renderer.addClass(this.el.nativeElement, 'badge-vertical-top');
        if (value === UiVerticalAlignment.Middle) this.renderer.addClass(this.el.nativeElement, 'badge-vertical-middle');
    }

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}
}
