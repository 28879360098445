import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from '@smooved/core';
import { ElectricityMeterType, electricityMeterTypeOptions, meterTypeUnknownOption } from '@smooved/ui';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-meter-type',
    template: `
        <p class="u-color-muted u-margin-bottom-triple">
            {{
                'STEP_INDICATION'
                    | translate
                        : {
                              start: stepStart,
                              end: stepEnd
                          }
            }}
        </p>

        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-select-input
                [label]="'MAKE_A_CHOICE.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [options]="meterTypeOptions"
                [formControlName]="'meterType'"
            ></app-select-input>

            <app-previous-submit (previous)="onPrevious()"></app-previous-submit>
        </form>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
})
export class MeterTypeComponent implements OnInit {
    @Input() public showPrevious: boolean;
    @Input() public stepStart = 6;
    @Input() public stepEnd = 6;
    @Input() public withUnknown = true;

    @Output() public previous: EventEmitter<ElectricityMeterType> = new EventEmitter<ElectricityMeterType>();
    @Output() public next: EventEmitter<ElectricityMeterType> = new EventEmitter<ElectricityMeterType>();

    public form: UntypedFormGroup = this.formBuilder.group({
        meterType: [null, Validators.required],
    });

    public meterTypeOptions = ArrayUtils.clone(electricityMeterTypeOptions);

    constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        if (this.withUnknown) {
            this.meterTypeOptions.push(meterTypeUnknownOption);
        }
        this.moveSandbox.meterTypeOnce$.pipe(filter((meterType) => !!meterType)).subscribe((meterType) => {
            this.meterTypeFormControl().patchValue(meterType);
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.next.emit(this.meterTypeFormControl().value);
        }
    }

    public onPrevious(): void {
        this.previous.emit(this.meterTypeFormControl().value);
    }

    private meterTypeFormControl(): AbstractControl {
        return this.form.get('meterType');
    }
}
