import {
    AddressUtils,
    ArrayUtils,
    ExternalInfluencer,
    ExternalInfluencerReviews,
    RealEstateGroupMeta,
    RealEstateLevel,
} from '@smooved/core';
import { LocationProfile } from '../../interfaces';

export class HeaderBoxData {
    logo: string;
    logoAlt?: string;
    name: string;
    breadcrumb?: { label: string; url?: string }[];
    address?: string;
    meta: RealEstateGroupMeta;
    scores: ExternalInfluencerReviews = { overall: { total: 0, score: 0 } };
    personalScore = true;
    noReviews: boolean;

    constructor(
        { logo, meta, name, realEstateGroupName, reviews, url, realEstateGroupLocations }: ExternalInfluencer,
        location?: LocationProfile
    ) {
        this.logo = logo;
        this.logoAlt = name;
        this.meta = meta;
        this.name = location?.name || realEstateGroupName;
        this.scores = {
            overall: {
                total: 0,
                score: 0,
                ...reviews?.overall,
            },
            categories: reviews.categories,
        };
        this.noReviews = !reviews?.overall?.total && !reviews?.overall?.externalTotal;

        if (!location) return;

        this.address = AddressUtils.buildFormattedAddress(location.location?.address);
        if (ArrayUtils.getLength(realEstateGroupLocations) > 1) {
            this.breadcrumb = [{ label: realEstateGroupName, url: `/${url}` }, { label: location.name }];
        }

        if (location.reviewScore) {
            const { external, amountOfReviews, score, noReviews, scoreType } = location.reviewScore;

            if (!noReviews) {
                this.scores.overall = !external
                    ? {
                          total: amountOfReviews,
                          score,
                      }
                    : {
                          externalTotal: amountOfReviews,
                          externalScore: score,
                      };
            }
            this.personalScore = !noReviews && scoreType === RealEstateLevel.Location;
            this.noReviews = noReviews && this.noReviews;
        } else {
            this.personalScore = false;
        }
    }
}
