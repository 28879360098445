<ng-container *ngIf="{ isPhone: uiSandbox.upToAndIncludingPhoneLandscape$ | async } as vm">
    <div class="u-flex-row u-flex-align-items-center u-w100p" [class.u-margin-bottom-double]="vm.isPhone" *ngIf="influencer">
        <div class="__image-container u-padding u-margin-right"><img [src]="influencer.logo" alt="" /></div>
        <div class="u-flex-row u-flex-justify-content-space-between u-flex-grow-1">
            <div [class.u-margin-right-double]="!vm.isPhone">
                <div class="u-flex-align-items-center-tablet-portrait-up u-flex-row">
                    <h1 class="u-font-size-xm u-margin-right-xs u-margin-right-tablet-portrait-up">{{ influencer.realEstateGroupName }}</h1>
                    <smvd-ui-real-estate-group-activation-stage
                        [state]="influencer.meta.activationStage"
                        [size]="vm.isPhone ? uiSize.Sm : uiSize.Default"
                    ></smvd-ui-real-estate-group-activation-stage>
                </div>
                <ui-real-estate-group-kpi
                    [meta]="influencer.meta"
                    [cols]="vm.isPhone ? 2 : 0"
                    class="u-position-absolute"
                ></ui-real-estate-group-kpi>
            </div>
            <ng-container *ngIf="!vm.isPhone" [ngTemplateOutlet]="arc"></ng-container>
        </div>
    </div>
</ng-container>
