import { NgModule } from '@angular/core';
import { FEATURE_TOGGLE_CONFIG, defaultFeatureToggleConfig } from './feature-toggle.config';

@NgModule({
    providers: [
        {
            provide: FEATURE_TOGGLE_CONFIG,
            useValue: defaultFeatureToggleConfig,
        },
    ],
})
export class FeatureToggleModule {}
