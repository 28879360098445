<ng-container *ngIf="{ tabletPortraitUp: tabletPortraitUp$ | async, isVertical: direction === directions.Vertical } as vm">
    <ng-container *ngIf="activated; else isInactive">
        <ng-container *ngIf="!minified; else arcScore">
            <ng-container *ngIf="!!meta?.total; else noReviews">
                <ng-container [ngTemplateOutlet]="arcScore"></ng-container>
                <div [class.u-text-align-center]="vm.isVertical">
                    <p class="u-font-weight-medium u-font-size-{{ fontSize }}-larger">
                        {{ scoreScale | i18nKeyTranslate : i18nKeyType.ReviewScore }}
                    </p>
                    <ng-container *ngIf="compact; else defaultBody">
                        <p class="u-color-muted u-font-size-{{ fontSize }} u-white-space-no-wrap">
                            {{
                                (vm.tabletPortraitUp ? 'COUNT' : 'COUNT_SHORT')
                                    | i18nKeyTranslate : i18nKeyType.Score : { count: meta?.total || 0 }
                            }}
                        </p>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #defaultBody>
        <p class="u-color-muted u-font-size-{{ fontSize }}">
            {{ 'SCORE.TOTAL' | i18nKeyTranslate : i18nKeyType.Score : { total: meta.total } }}
        </p>
        <p class="u-color-muted u-font-size-{{ fontSize }}">
            {{ 'SCORE.INFLUENCER_SCORE' | i18nKeyTranslate : i18nKeyType.Score : { score: meta.influencerScore | number : noDecimals } }}
            <ng-content select="[influencer-score-suffix]"></ng-content>
        </p>
    </ng-template>

    <ng-template #isInactive>
        <app-arc-score
            [size]="arcSize"
            [data]="arcScoreData"
            [scoreSize]="uiSize.Md"
            [context]="uiContext.Muted"
            value="?"
            class="u-margin-right"
        ></app-arc-score>
        <div>
            <p class="u-font-weight-bold u-font-size-{{ fontSize }}">
                <ng-container *ngIf="title; else noTitle">
                    <span [innerHtml]="title | translate"></span>
                </ng-container>
                <ng-template #noTitle>
                    <span [innerHtml]="'INACTIVE.TITLE' | i18nKeyTranslate : i18nKeyType.Score"></span>
                </ng-template>
            </p>
            <p class="u-font-size-{{ fontSize }} u-color-muted">
                <ng-container *ngIf="description; else noDescription">
                    <span [innerHtml]="description | translate"></span>
                </ng-container>
                <ng-template #noDescription>
                    <span [innerHtml]="'INACTIVE.DESCRIPTION' | i18nKeyTranslate : i18nKeyType.Score"></span>
                </ng-template>
            </p>
        </div>
    </ng-template>

    <ng-template #arcScore>
        <app-arc-score
            [size]="arcSize"
            [data]="arcScoreData"
            [class.u-margin-right]="!vm.isVertical"
            [value]="meta.score"
            [houseMatchScore]="meta.houseMatchScore"
        ></app-arc-score>
    </ng-template>

    <ng-template #noReviews>
        <app-arc-score [size]="arcSize" [data]="arcScoreData" value="-" [class.u-margin-right]="!vm.isVertical"></app-arc-score>
        <div [class.u-text-align-center]="vm.isVertical">
            <p class="u-font-weight-bold u-font-size-{{ fontSize }}">
                <ng-container *ngIf="title; else noTitle">
                    {{ title | translate }}
                </ng-container>
                <ng-template #noTitle>
                    {{ 'NO_REVIEWS.TITLE' | i18nKeyTranslate : i18nKeyType.Score }}
                </ng-template>
            </p>

            <p class="u-font-size-{{ fontSize }}">
                <ng-container *ngIf="description; else noDescription">
                    <span [innerHtml]="description | translate"></span>
                </ng-container>
                <ng-template #noDescription>
                    <span [innerHtml]="'NO_REVIEWS.DESCRIPTION' | i18nKeyTranslate : i18nKeyType.Score"></span>
                </ng-template>
            </p>
        </div>
    </ng-template>
</ng-container>
