import { Action } from '@ngrx/store';
import { AuthUser, RealEstateAgentLoginRequest } from '@smooved/core';

export enum AuthenticationActionTypes {
    SetAuthorization = '[Authentication] Set authorization',
    RealEstateAgentResetPassword = '[Authentication] Real estate agent reset password',
    RealEstateAgentResetPasswordSuccess = '[Authentication] Real estate agent reset password success',
    RealEstateAgentResetPasswordFailure = '[Authentication] Real estate agent reset password failure',
    RealEstateAgentResetPasswordNew = '[Authentication] Real estate agent reset password new',
    RealEstateAgentResetPasswordNewSuccess = '[Authentication] Real estate agent reset password new success',
    RealEstateAgentResetPasswordNewFailure = '[Authentication] Real estate agent reset password new failure',
    RealEstateAgentLogin = '[Authentication] Real estate agent login',
    RealEstateAgentLoginSuccess = '[Authentication] Real estate agent login success',
    RealEstateAgentLoginFailure = '[Authentication] Real estate agent login failure',
    LoginSuccess = '[Authentication] Admin login success',
    Logout = '[Authentication] Logout',
    LogoutSuccess = '[Authentication] Logout success',
    LogoutFailure = '[Authentication] Logout failure',
}

export class SetAuthorizationAction implements Action {
    public readonly type = AuthenticationActionTypes.SetAuthorization;

    constructor(public payload: AuthUser) {}
}

export class RealEstateAgentResetPasswordAction implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPassword;

    constructor(public payload: { email: string }) {}
}

export class RealEstateAgentResetPasswordActionSuccess implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPasswordSuccess;

    constructor(public payload: { email: string }) {}
}

export class RealEstateAgentResetPasswordActionFailure implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPasswordFailure;
}

export class RealEstateAgentResetPasswordNewAction implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPasswordNew;

    constructor(public payload: { password: string; token: string; id: string }) {}
}

export class RealEstateAgentResetPasswordNewActionSuccess implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPasswordNewSuccess;
}

export class RealEstateAgentResetPasswordNewActionFailure implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentResetPasswordNewFailure;
}

export class RealEstateAgentLogin implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentLogin;

    constructor(public payload: { credentials: RealEstateAgentLoginRequest }) {}
}

export class RealEstateAgentLoginSuccess implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentLoginSuccess;
}

export class RealEstateAgentLoginFailure implements Action {
    public readonly type = AuthenticationActionTypes.RealEstateAgentLoginFailure;
}

export class LoginSuccess implements Action {
    public readonly type = AuthenticationActionTypes.LoginSuccess;
}

export class Logout implements Action {
    public readonly type = AuthenticationActionTypes.Logout;
}

export class LogoutSuccess implements Action {
    public readonly type = AuthenticationActionTypes.LogoutSuccess;

    constructor(public payload: { redirect: boolean }) {}
}

export class LogoutFailure implements Action {
    public readonly type = AuthenticationActionTypes.LogoutFailure;
}

export type AuthenticationActions =
    | SetAuthorizationAction
    | RealEstateAgentResetPasswordAction
    | RealEstateAgentResetPasswordActionSuccess
    | RealEstateAgentResetPasswordActionFailure
    | RealEstateAgentResetPasswordNewAction
    | RealEstateAgentResetPasswordNewActionSuccess
    | RealEstateAgentResetPasswordNewActionFailure
    | RealEstateAgentLogin
    | RealEstateAgentLoginSuccess
    | RealEstateAgentLoginFailure
    | LoginSuccess
    | Logout
    | LogoutSuccess
    | LogoutFailure;
