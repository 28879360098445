import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SupplierGroup } from '@app/supplier/interfaces/supplier-group';
import { SupplierService } from '@app/supplier/supplier/supplier.service';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, DbUtils, StringUtils } from '@smooved/core';
import { BaseAutocompleteInput, createConfirmation, ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { Subject, zip } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
    selector: 'app-supplier-group-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="suppliersGroups$ | async"
                searchKeyword="name"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear()"
                (selected)="selected($event)"
                (closed)="onBlur()"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.name"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./supplier-group-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SupplierGroupInputComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SupplierGroupInputComponent extends BaseAutocompleteInput implements ControlValueAccessor, OnInit {
    @Input() public label: string = <string>this.translateService.instant('SUPPLIER');

    public innerModel: SupplierGroup | string = undefined;

    public suppliersGroupsSubject: Subject<SupplierGroup[]> = new Subject<SupplierGroup[]>();
    public suppliersGroups$ = this.suppliersGroupsSubject.asObservable();

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly supplierService: SupplierService,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService,
        private readonly notificationSandbox: NotificationSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.getOptions();
    }

    public getOptions(): void {
        this.loadingSubject.next(true);
        this.supplierService
            .getAllGroups()
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe((response) => {
                this.suppliersGroupsSubject.next(response);
            });
    }

    public writeValue(supplierGroupId: string): void {
        if (!StringUtils.hasValue(supplierGroupId)) {
            this.innerModel = undefined;
            return;
        }
        zip(this.suppliersGroups$)
            .pipe(take(1))
            .subscribe(([supplierGroups]) => {
                this.innerModel = ArrayUtils.isEmpty(supplierGroups)
                    ? undefined
                    : supplierGroups.find((supplierGroup) => DbUtils.getStringId(supplierGroup) === supplierGroupId);
            });
    }

    public onBlur(): void {
        if (StringUtils.hasValue(this.innerModel as string)) {
            // this means, no existing supplier group is selected
            const data = this.translateService.instant(createConfirmation) as string;
            this.modalSandbox.openConfirmModal({ data }, this.handleConfirmationClose);
        }
    }

    private handleConfirmationClose = (result: boolean): void => {
        if (result) {
            this.supplierService.createSupplierGroup({ name: this.innerModel as string }).subscribe((result) => {
                this.notificationSandbox.createdSuccess();
                this.selected(result);
                this.getOptions();
            });
        } else {
            this.innerModel = undefined;
            this.inputClear();
        }
    };

    public selected(supplierGroup: SupplierGroup): void {
        this.propagateChange(DbUtils.getStringId(supplierGroup));
    }
}
