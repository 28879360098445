import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SocialSharingService } from '@smooved/core';
import { ApplicationSource } from '@smooved/ui';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class SocialSharingSandbox extends SocialSharingService {
    protected apiUri = environment.apiUri;
    protected appSource: ApplicationSource = ApplicationSource.SmoovedApp;
}
