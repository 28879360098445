import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipeModule } from '../pipes';
import { InfoComponent } from './components/info/info.component';

@NgModule({
    declarations: [InfoComponent],
    imports: [CommonModule, PipeModule],
    exports: [InfoComponent],
})
export class InfoModule {}
