import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiDirection } from '../../../../ui.enums';

@Component({
    selector: 'app-chart-legend-item',
    templateUrl: './chart-legend-item.component.html',
    styleUrls: ['./chart-legend-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendItemComponent {
    @Input() public color: string;
    @Input() public label: string;
    @Input() public sub: string;
    @Input() public direction = UiDirection.Vertical;

    public readonly directions = UiDirection;
}
