import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlaceResult } from '@smooved/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[googlePlacesAutocomplete]',
})
export class GooglePlacesAutocompleteDirective implements OnInit {
    private autocomplete: google.maps.places.Autocomplete;

    @Input()
    public types: string[];

    @Output()
    public autocompleteSelected = new EventEmitter<PlaceResult>();

    constructor(private elRef: ElementRef) {}

    public ngOnInit(): void {
        this.autocomplete = new google.maps.places.Autocomplete(this.elRef.nativeElement, {
            types: this.types,
            componentRestrictions: { country: ['be'] },
        });

        this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    }

    private onPlaceChanged = (): void => {
        const place = this.autocomplete.getPlace();
        this.autocompleteSelected.emit(place);
    };
}
