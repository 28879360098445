import { Component } from '@angular/core';
import { NotificationSandbox } from '@smooved/ui';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';
import { NotificationLabel } from '../../../notification/enums/notification-label.enum';
import { RealEstateAgentSandbox } from '../../../real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { AppUiSandbox } from '../../../ui/sandboxes/ui.sandbox';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

@Component({
    selector: 'app-real-estate-agent-register-container',
    template: `
        <div class="u-padding-top-double u-flex-row u-flex-justify-content-center">
            <app-real-estate-agent-register
                [loading]="registerLoading"
                (formSubmit)="realEstateAgentRegister($event)"
                (goToLogin)="navigationSandbox.goToRealEstateAgentLogin().then()"
            ></app-real-estate-agent-register>
        </div>
    `,
    styles: [],
})
export class RealEstateAgentRegisterContainer {
    public registerLoading = false;

    constructor(
        public navigationSandbox: AppNavigationSandbox,
        private uiSandbox: AppUiSandbox,
        private authenticationSandbox: AuthenticationSandbox,
        private realEstateAgentSandbox: RealEstateAgentSandbox,
        private notificationSandbox: NotificationSandbox
    ) {}

    public realEstateAgentRegister(event): void {
        this.registerLoading = true;

        this.realEstateAgentSandbox.register(event).subscribe(
            (response) => {
                this.notificationSandbox.success(NotificationLabel.AuthenticationRealEstateAgentRegisterSuccess, {
                    email: event.email,
                });
                this.navigationSandbox.goToRealEstateAgentLogin().then();
                this.registerLoading = false;
            },
            (errorResponse) => {
                this.registerLoading = false;
            }
        );
    }
}
