import { Pipe, PipeTransform } from '@angular/core';
import { SortDirection } from '../enums';
import { SortUtils } from '../utils/sort.utils';

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    transform<T>(value: T[], byProperty: string, direction = SortDirection.Asc): T[] {
        if (!(value && value.length)) return value;
        return [...value].sort(SortUtils.sortBy(byProperty, direction));
    }
}
