import { Mention } from '@app/user/interfaces/mention';

export const defaultValue: Mention = {
    value: '',
    inputValue: '',
    mentions: [],
};

export const tagifyOptions = {
    mode: 'mix',
    pattern: /@/,
    tagTextProp: 'text',
    whitelist: [],
    dropdown: {
        enabled: 1,
        position: 'text',
        mapValueTo: 'text',
        highlightFirst: true,
    },
    enforceWhitelist: true,
};
