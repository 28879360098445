import { Injectable } from '@angular/core';
import { uiLoading } from '@app/escalation/state/ui/ui.actions';
import { getUiLoading } from '@app/escalation/state/ui/ui.selectors';
import { select, Store } from '@ngrx/store';
import { ArrayUtils, DbUtils } from '@smooved/core';
import { dropdownInputOptionAll } from '@smooved/ui';
import { filter, map } from 'rxjs/operators';
import { EscalationProvidersModule } from '../escalation-providers.module';
import { getCategories } from '../state/category/category.actions';
import { getList, getLoading } from '../state/category/category.selectors';
import { EscalationState } from '../state/escalation.combiner';

@Injectable({ providedIn: EscalationProvidersModule })
export class EscalationSandbox {
    public readonly uiLoading$ = this.store$.pipe(select(getUiLoading));
    public readonly categories$ = this.store$.pipe(select(getList));
    public readonly categoryOptions$ = this.categories$.pipe(
        filter((categories) => !ArrayUtils.isEmpty(categories)),
        map((categories) => {
            return [
                dropdownInputOptionAll<string>('escalation-category'),
                ...categories.map((category) => {
                    const id = DbUtils.getStringId(category);
                    return {
                        id: `escalation-category-${id}`,
                        labels: category.labels,
                        name: 'escalation-category',
                        value: id,
                    };
                }),
            ];
        })
    );

    public readonly categoriesLoading$ = this.store$.pipe(select(getLoading));

    constructor(private readonly store$: Store<EscalationState>) {}

    public getEscalationCategories(): void {
        this.store$.dispatch(getCategories());
    }

    public uiLoading(loading: boolean): void {
        this.store$.dispatch(uiLoading({ loading }));
    }
}
