import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance } from '../button/button.enums';
import { UriSchemaType } from '../pipes';
import { colFixPaddingEndTable, ColType } from './table.constants';
import { ColConf, Filter, RowDef, TableDef } from './table.interfaces';
import { TableService } from './table.service';

@Component({
    selector: 'smvd-ui-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [TableService],
})
export class TableComponent<T> implements OnChanges {
    @Input() public items: T[];
    @Input() public filter: Filter<T>;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('def') public tableDef: TableDef<T>;

    public columns = [];
    public colsConfig: ColConf<T>[];
    public dataSource: MatTableDataSource<RowDef<T>>;
    public readonly colTypes = ColType;
    public readonly colFixPaddingEndTable = colFixPaddingEndTable;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uriSchemaType = UriSchemaType;

    constructor(private readonly tableService: TableService<T>, private readonly cdr: ChangeDetectorRef) {}

    public applyFilter(value: string): void {
        this.dataSource.filter = (value || '').trim().toLowerCase();
    }

    public ngOnChanges({ items, tableDef }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(tableDef)) {
            this.setColumns();
        }

        if (SimpleChangesUtils.hasChanged(tableDef, items)) {
            this.setDataSource();
        }
    }

    public onClickRow(event): void {}

    public trackByColId(index, col: ColConf<T>): string {
        return col.id || index;
    }

    private setColumns(): void {
        [this.colsConfig, this.columns] = this.tableService.handleColumnsChanged(this.tableDef);
    }

    private setDataSource(): void {
        this.dataSource = this.tableService.dataSourceFactory(this.items);
        this.cdr.detectChanges();
    }
}
