import { AbstractControl, ValidationErrors } from '@angular/forms';
import { VatNumberUtils } from '@shared/utils/vat-number.utils';

export class VatNumberValidators {
    public static isValid({ value }: AbstractControl): ValidationErrors {
        if (!value) return null;
        const validation = VatNumberUtils.validate(value);
        return !validation.isValid ? { isVatNumber: true } : null;
    }
}
