import { Component, Inject, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ClosableModalTemplateComponent, NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-create-office-modal',
    template: `
        <app-closable-modal-template maxWidth="none">
            <div
                class="u-margin-left-double-negative u-margin-right-double-negative u-padding-bottom u-margin-bottom-double u-bordered-bottom u-text-align-center"
            >
                <h5>{{ 'REAL_ESTATE_AGENT.CREATE_OFFICE.TITLE' | translate }}</h5>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-end">
                <app-text-input
                    formControlName="name"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [label]="'REAL_ESTATE_AGENT.CREATE_OFFICE.NAME' | translate"
                ></app-text-input>
                <app-button type="submit">{{ 'CREATE.LABEL' | translate }}</app-button>
            </form>
        </app-closable-modal-template>
    `,
})
export class CreateOfficeModal {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public form = this.formBuilder.group({
        name: [null, [Validators.required]],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private realEstateGroupSandbox: RealEstateGroupSandbox,
        private notificationSandbox: NotificationSandbox,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.realEstateGroupSandbox
                .createOffice({
                    name: this.nameFormControl().value,
                })
                .subscribe((_) => {
                    this.notificationSandbox.success('REAL_ESTATE_AGENT.CREATE_OFFICE.SUCCESS');
                    this.closableModalComponent.close(true);
                });
        }
    }

    private nameFormControl(): AbstractControl {
        return this.form?.get('name');
    }
}
