import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthUser } from '@smooved/core';
import { Order } from '@app/order/interfaces/order';
import { moveProperties } from '@shared/move/constants/properties.constants';

@Component({
    selector: 'app-check-item-telecom-phone-transfer-by-admin',
    template: `
        <app-check-item [checked]="!!order.move.telecomPhoneTransferByAdmin" class="u-margin-bottom-double" align="top">
            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="unchecked">
                <p class="u-margin-bottom-half">{{ 'PHONE_TRANSFER_REQUESTED_BY' | translate: { by: authorization?.firstName } }}</p>
                <app-button (onClick)="set(true)">{{ 'CHECK' | translate }}</app-button>
            </div>

            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="checked">
                <p class="u-margin-bottom-half">{{ 'PHONE_TRANSFER_REQUESTED_BY' | translate: { by: authorization?.firstName } }}</p>
                <a (click)="set(false)" class="u-link">{{ 'UNCHECK' | translate }}</a>
            </div>
        </app-check-item>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemTelecomPhoneTransferByAdminComponent {
    @Input() public order: Order;
    @Input() public authorization: AuthUser;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    public set(value: boolean): void {
        this.patch.emit({
            [moveProperties.telecomPhoneTransferByAdmin]: value,
        });
    }
}
