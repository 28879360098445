export enum NpsScoreCategory {
    Excellent = 'excellent',
    Good = 'good',
    AverageGood = 'averageGood',
    Average = 'average',
    AverageBad = 'averageBad',
    Bad = 'bad',
    Low = 'low',
}

export enum NpsScoreCategoryValue {
    Excellent = 10,
    Good = 9,
    AverageGood = 8,
    Average = 7,
    AverageBad = 6,
    Bad = 5,
    Low = 1,
}
