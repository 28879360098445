<app-closable-modal-template #dialog maxWidth="50rem">
    <h6 modal-header>{{ (isEdit ? 'REAL_ESTATE_AGENT.LOCATIONS.UPDATE' : 'REAL_ESTATE_AGENT.LOCATIONS.CREATE') | translate }}</h6>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <smvd-ui-text-input [formControlName]="formControlNames.name" [label]="'NAME' | translate"></smvd-ui-text-input>
        <div class="u-flex-row">
            <smvd-ui-email-input
                [formControlName]="formControlNames.email"
                [label]="'EMAIL' | translate"
                class="u-margin-right"
            ></smvd-ui-email-input>
            <smvd-ui-phone-input
                [formControlName]="formControlNames.phoneNumber"
                [label]="'PHONE_NUMBER' | translate"
            ></smvd-ui-phone-input>
        </div>
        <smvd-ui-address-input
            class="u-display-block u-margin-bottom-double"
            [label]="'ADDRESS' | translate"
            [formControlName]="formControlNames.address"
        ></smvd-ui-address-input>

        <div>
            <button smvd-ui-button [appearance]="buttonAppearance.Link" [context]="context.Muted" (click)="dialog.close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button smvd-ui-button type="submit" [appearance]="buttonAppearance.Default" [context]="context.Secondary">
                {{ (isEdit ? 'UPDATE.LABEL' : 'ADD') | translate }}
            </button>
        </div>
    </form>
</app-closable-modal-template>
