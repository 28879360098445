export const dateFormatAngularDefault = 'dd/MM/yyyy'; // used for angular date pipes. todo - refactor to formatDatePipe
export const dateFormatDefault = 'DD/MM/YYYY';
export const dateFormatFileNameDefault = 'DD-MM-YYYY';
export const dateTimeFormatDefault = 'DD/MM/YYYY HH:mm';
export const isoYearWeek = 'GGGG-W'; //Mongo YearWeak format
export const isoDate = 'YYYY-MM-DD'; //ISO_8601
export const quarterYearIntervalFormat = '[Q]Q YYYY';
export const dateFormatPubDate = 'YYYY-MM-DD HH:mm:ss';
export const writtenDateFormat = 'DD [of] MMMM HH:mm';
export const fullYear = 'YYYY';
export const weekNumber = 'WW';

// Use when in need of a max value
// Use when in need of an undefined replacement for expressions
export const forever = '29991231';
