import { ContactLogType } from '../enums/contact-log-type.enum';

export type LeaverJourneyContactLogType =
    | ContactLogType.EmailLeaverInvite
    | ContactLogType.SmsLeaver
    | ContactLogType.EmailMeterCollection
    | ContactLogType.SmsMeterCollection
    | ContactLogType.EmailMeterCollectionReminder
    | ContactLogType.EmailMeterCollectionFinalReminder;

export const leaverJourneyEmailContactLogTypes: LeaverJourneyContactLogType[] = [
    ContactLogType.EmailLeaverInvite,
    ContactLogType.EmailMeterCollection,
    ContactLogType.EmailMeterCollectionReminder,
    ContactLogType.EmailMeterCollectionFinalReminder,
];

export const redoableContactLogs: ContactLogType[] = [
    ContactLogType.EmailSettingTheScene,
    ContactLogType.EmailProvisionalEnergyProposal,
    ContactLogType.EmailEnergyProposalLandAgent,
    ContactLogType.EmailEnergyProposalVacancy,
    ContactLogType.EmailEnergyProposal,
    ContactLogType.EmailEnergyProposalWithContext,
    ContactLogType.EmailEnergyOrdered,
    ContactLogType.EmailEnergyNotInterested,
    ContactLogType.EmailMeterReadingsEnergy,
    ContactLogType.EmailMeterReadingsWater,
    ContactLogType.EmailEnergyInstalled,
    ContactLogType.EmailRealEstateAgentEnteredLeaverAfter15DaysLate,
    ContactLogType.EmailRealEstateAgentEnteredLeaverAfter40DaysLate,
    ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter15DaysLate,
    ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter40DaysLate,
    ContactLogType.EmailTransfereeNewWaterContract,
    ContactLogType.EmailLeaverClosingWaterContract,
    ContactLogType.EmailLeaverFollowUpClosingWaterContract,
    ContactLogType.EmailTransfereeWaterTransferDocumentNotUploadedReminder,
    ContactLogType.EmailLeaverWaterTransferDocumentNotUploadedReminder,
];
