import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergyOffer } from '@app/wizard/energy/interfaces/energy-offer';

@Component({
    selector: 'app-energy-type-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'REAL_ESTATE_AGENT.EOTS.ENERGY_TYPE.TITLE' | translate }}</h3>
                    <app-energy-type
                        (previous)="goToPrevious($event)"
                        [showPrevious]="true"
                        [stepStart]="step"
                        [stepEnd]="6"
                        (next)="onSubmit($event)"
                        (energyTypeChanges)="onChanges($event)"
                    ></app-energy-type>
                </app-card>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyTypeContainer {
    public step = 5;

    constructor(private router: Router, private moveSandbox: MoveSandbox, private route: ActivatedRoute) {}

    public onChanges(event: EnergyType) {
        if (event === EnergyType.Gas) {
            this.step = 6;
        } else {
            this.step = 5;
        }
    }

    public onSubmit(event: EnergyOffer): void {
        this.moveSandbox.patchProperty('energyOffer', event, true, (_) => {
            let path: string;
            if (event?.energyType === EnergyType.Gas) {
                path = '../../energy-suggestions';
            } else {
                path = '../meter-type';
            }
            this.router
                .navigate([path], {
                    relativeTo: this.route,
                })
                .then();
        });
    }

    public goToPrevious(event: EnergyOffer): void {
        this.moveSandbox.patchProperty('energyOffer', event, true, (_) => {
            this.router
                .navigate(['../home-construction-year'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }
}
