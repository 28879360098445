import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ObjectUtils, RealEstateAgency, StringUtils } from '@smooved/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { BaseUriConfig, BASE_URI_CONFIG } from '../../configs';
import { locationsUri } from '../real-estate-group.constants';
import { getLocations, getLocationsFailure, getLocationsSuccess } from './actions';
import { RealEstateGroupState } from './reducer';
import { selectLocations } from './selectors';

@Injectable()
export class RealEstateGroupEffects {
    public getLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getLocations),
            withLatestFrom(this.store$.pipe(select(selectLocations))),
            mergeMap(([action, locations]) => {
                return ObjectUtils.isDefined(locations)
                    ? of(getLocationsSuccess({ data: locations }))
                    : this.httpClient
                          .get<RealEstateAgency[]>(
                              StringUtils.parseUri(locationsUri, {
                                  apiUri: this.baseUriConfig.apiUri,
                                  realEstateGroupId: action.realEstateGroupId,
                              })
                          )
                          .pipe(
                              map((data) => getLocationsSuccess({ data })),
                              catchError((error) => of(getLocationsFailure()))
                          );
            })
        )
    );
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<RealEstateGroupState>,
        private readonly httpClient: HttpClient,
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig
    ) {}
}
