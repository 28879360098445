import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { DateUtils } from '@smooved/core';

@Component({
    selector: 'app-financial-report',
    template: ` <app-card-container><router-outlet></router-outlet></app-card-container> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialReportComponent {
    constructor(private invoicingSandbox: InvoicingSandbox) {
        this.invoicingSandbox.setQuarter(DateUtils.tz().startOf('quarter').toDate());
    }
}
