<div [class.u-flex-responsive]="legendDirection === uiDirection.Vertical">
    <ng-content></ng-content>
    <ng-container *ngIf="hasLegend">
        <app-chart-legend
            [direction]="legendDirection"
            [class.u-margin-left]="legendDirection === uiDirection.Vertical"
            class="u-padding-bottom u-flex-justify-content-center"
        >
            <ng-content select="app-chart-legend-item"></ng-content>
        </app-chart-legend>
    </ng-container>
</div>
