import { DbUtils, RealEstateAgency } from '@smooved/core';
import { DropdownInput } from '../../../form';

export const locationDefault = {
    id: 'all',
    name: 'location',
    labelResource: `UI.LOCATIONS.ALL`,
    value: { _id: '', name: 'all', realEstateGroup: null },
};

export const buildLocationsOptions = (locations: RealEstateAgency[]): DropdownInput<RealEstateAgency>[] => {
    return [
        locationDefault,
        ...(locations || []).map((location) => ({
            id: DbUtils.getStringId(location),
            label: location.name,
            value: location,
            name: 'location',
        })),
    ];
};
