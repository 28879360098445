import { MoveType } from '@app/move/enums/move-type.enum';
import { SelectInput } from '@smooved/ui';

export const moveTypeOptions: SelectInput<MoveType>[] = [
    {
        id: MoveType.Eots,
        labelResource: 'CLIENT.INIT.EOTS',
        value: MoveType.Eots,
        name: 'init',
        imageUrl: 'assets/images/smooved-energy-bulb.png',
    },
    {
        id: MoveType.Lead,
        labelResource: 'CLIENT.INIT.LEAD',
        value: MoveType.Lead,
        name: 'init',
        imageUrl: 'assets/images/smooved-with-van.png',
    },
    {
        id: MoveType.Vacancy,
        labelResource: 'CLIENT.INIT.VACANCY',
        value: MoveType.Vacancy,
        name: 'init',
        imageUrl: 'assets/images/smooved-energy.png',
    },
];
