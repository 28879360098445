import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { I18nKeyType, ReviewSource } from '@smooved/core';
import { ApplicationUtils } from '../../../application';
import { uiI18nKeyTypes } from '../../../i18n';
import { UiSandbox } from '../../../ui.sandbox';
import { downloadUserAgent, facebookI18n, googleI18n, NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { NpsReviewsTemplateConfig } from '../../interfaces';

export interface TransactionTitleAttributes {
    houseType?: string;
    transactionType: string;
    city: string;
}

export interface GeneratedSummaryAndTransactionTitleAttributes {
    generatedSummary?: string;
    transactionTitleAttributes?: TransactionTitleAttributes;
}

@Component({
    selector: 'smvd-ui-review-title-generated-summary',
    templateUrl: './review-title-generated-summary.component.html',
    styleUrls: ['review-title-generated-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewTitleGeneratedSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public titles: GeneratedSummaryAndTransactionTitleAttributes;
    @Input() public source: ReviewSource;
    @Input() public showReviewMeta?: boolean = true;
    @Input() public enableCollapse: boolean = true;

    @ViewChild('generatedSummary') generatedSummary: ElementRef;

    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly i18nKey = I18nKeyType;
    public readonly reviewSource = ReviewSource;

    public isExpanded = false;
    public label: string;
    public showExpandButton = false;
    public isDownloadingScreenshot = false;
    public showGeneratedSummary = false;

    private resizeObserver: ResizeObserver;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly uiSandbox: UiSandbox,
        @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig
    ) {}

    public ngOnInit(): void {
        this.label = this.source === ReviewSource.Google ? googleI18n : this.source === ReviewSource.Facebook ? facebookI18n : 'smooved';
        this.showGeneratedSummary =
            this.showReviewMeta || (!this.showReviewMeta && ApplicationUtils.isSmoovedApplication(this.templateConfig.application));
        if (typeof window === 'undefined') {
            return;
        }
        this.isDownloadingScreenshot = window.navigator.userAgent === downloadUserAgent;
    }

    public ngAfterViewInit(): void {
        if (this.titles.generatedSummary) {
            this.setShowExpandButton();
            if (this.uiSandbox.isBrowser()) {
                this.listenOnTitleResize();
            }
        }
    }

    public ngOnDestroy(): void {
        this.resizeObserver?.disconnect();
    }

    public toggleExpand(event: Event): void {
        event.preventDefault();
        this.isExpanded = !this.isExpanded;
        this.cdr.detectChanges();
    }

    public checkOverflow(): boolean {
        if (!this.generatedSummary?.nativeElement) return false;
        if (this.generatedSummary.nativeElement) {
            return this.generatedSummary.nativeElement.scrollWidth > this.generatedSummary.nativeElement.clientWidth;
        }
    }

    private listenOnTitleResize(): void {
        this.resizeObserver = new ResizeObserver((entries) => {
            this.setShowExpandButton();
        });
        if (this.generatedSummary?.nativeElement) {
            this.resizeObserver.observe(this.generatedSummary?.nativeElement);
        }
    }

    private setShowExpandButton(): void {
        const newValue = this.checkOverflow();
        if (newValue === this.showExpandButton) return;
        this.showExpandButton = newValue;
        this.cdr.detectChanges();
    }
}
