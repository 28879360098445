import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gift } from '@app/gift/interfaces/gift.interface';
import { Observable } from 'rxjs';
import * as giftUri from '../constants/uri.constants';

@Injectable({
    providedIn: 'root',
})
export class GiftService {
    constructor(private readonly httpClient: HttpClient) {}

    public get(): Observable<Gift[]> {
        return this.httpClient.get<Gift[]>(giftUri.baseUri);
    }
}
