import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@app/translation/translation.module';
import { LoadingModule } from '@app/ui/loading/loading.module';
import { ButtonModule, CardsModule } from '@smooved/ui';
import { WizardStepCardComponent } from './wizard-step-card/wizard-step-card.component';

@NgModule({
    imports: [CommonModule, CardsModule, ButtonModule, TranslationModule, FormsModule, ReactiveFormsModule, LoadingModule],
    declarations: [WizardStepCardComponent],
    exports: [WizardStepCardComponent],
})
export class WizardModule {}
