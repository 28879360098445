import { Action, combineReducers } from '@ngrx/store';
import * as Category from './category/category.reducer';
import * as Ui from './ui/ui.reducer';

export const featureKey = 'escalation';

export interface State {
    [Category.featureKey]: Category.CategoryState;
    [Ui.featureKey]: Ui.UiState;
}

export interface EscalationState {
    [featureKey]: State;
}

export const combiner = (state: State, action: Action): State => {
    return combineReducers({
        [Category.featureKey]: Category.escalationCategoryReducer,
        [Ui.featureKey]: Ui.escalationUiReducer,
    })(state, action);
};
