import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TelecomInstallationModal } from '@app/admin/modals/telecom-installation/telecom-installation.modal';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { DbUtils } from '@smooved/core';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-telecom-installation-date-by-admin',
    template: `
        <div [ngSwitch]="!!move?.telecomInstallationDateByAdmin">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.telecomInstallationDateByAdmin }">
                <div *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.INSTALLATION_DATE_BY_ADMIN' | translate }}</span>
                    <a (click)="showIndicateModal()" class="u-link">{{ 'EDIT_OR_VIEW' | translate }}</a>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.INSTALLATION_DATE_BY_ADMIN' | translate }}</span>
                    </div>
                    <div class="u-margin-top-half" *ngIf="move?.telecomOrdered">
                        <app-button (onClick)="showIndicateModal()" theme="secondary-outline">{{ 'INDICATE' | translate }}</app-button>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomInstallationDateByAdminComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly dialog: MatDialog,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public showIndicateModal(): void {
        const modal = this.dialog.open(TelecomInstallationModal, {
            data: this.move.telecomInstallationScheduleByAdmin,
        });
        modal
            .afterClosed()
            .pipe(take(1))
            .subscribe((installationDate) => {
                if (!installationDate) return;
                const move: Move = {
                    telecomInstallationScheduleByAdmin: installationDate,
                };
                this.moveSandbox.patchMove(DbUtils.getStringId(this.move), move, false, null, (updatedMove) => {
                    this.updateMove(updatedMove);
                });
            });
    }
}
