import { Language } from '@smooved/core';
import { SelectInput } from '@smooved/ui';

export const croppedArcImageProperty = 'croppedArcImage';

export const formFields = {
    profile: 'profile',
    info: 'info',
    language: 'language',
};

export const languageOptions: SelectInput<Language>[] = [
    ...Object.entries(Language).map(([key, value]: [string, Language]) => {
        return {
            id: `language-${key}`,
            labelResource: `LANGUAGE.${key.toUpperCase()}.LABEL`,
            value,
            name: 'language',
        };
    }),
];
