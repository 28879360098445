import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isArray, isNull, isUndefined } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TelecomSuggestion } from './interfaces/telecom-suggestion';
import { TelecomSuggestionsFilter } from './interfaces/telecom-suggestions-filter';
import { TelecomSuggestionsFilterOptions } from './interfaces/telecom-suggestions-filter-options';
import { TelecomSuggestionsFilterOptionsRequest } from './interfaces/telecom-suggestions-filter-options-request';

@Injectable({
    providedIn: 'root',
})
export class TelecomService {
    public static readonly baseUri = `${environment.apiUri}/telecom`;
    public static readonly suggestionsUri = `${TelecomService.baseUri}/suggestions`;
    public static readonly suggestionsFilterUri = `${TelecomService.baseUri}/suggestions-filter`;
    public static readonly telenetProductsUri = `${TelecomService.baseUri}/products/telenet`;

    constructor(private httpClient: HttpClient) {}

    public findSuggestionsFilter(
        telecomSuggestionsFilterOptionsRequest: TelecomSuggestionsFilterOptionsRequest
    ): Observable<TelecomSuggestionsFilterOptions> {
        let httpParams: HttpParams = new HttpParams();

        for (const key in telecomSuggestionsFilterOptionsRequest) {
            if (
                telecomSuggestionsFilterOptionsRequest.hasOwnProperty(key) &&
                !isUndefined(telecomSuggestionsFilterOptionsRequest[key]) &&
                !isNull(telecomSuggestionsFilterOptionsRequest[key])
            ) {
                if (isArray(telecomSuggestionsFilterOptionsRequest[key])) {
                    if (telecomSuggestionsFilterOptionsRequest[key].length) {
                        httpParams = httpParams.append(
                            `${key}[]`,
                            telecomSuggestionsFilterOptionsRequest[key].filter((x) => !!x)
                        );
                    }
                } else {
                    httpParams = httpParams.append(key, telecomSuggestionsFilterOptionsRequest[key]);
                }
            }
        }

        return this.httpClient.get<TelecomSuggestionsFilterOptions>(TelecomService.suggestionsFilterUri, {
            params: httpParams,
        });
    }

    public findSuggestions(telecomSuggestionsFilter: TelecomSuggestionsFilter): Observable<TelecomSuggestion[]> {
        let httpParams: HttpParams = new HttpParams();

        for (const key in telecomSuggestionsFilter) {
            if (
                telecomSuggestionsFilter.hasOwnProperty(key) &&
                !isUndefined(telecomSuggestionsFilter[key]) &&
                !isNull(telecomSuggestionsFilter[key])
            ) {
                if (isArray(telecomSuggestionsFilter[key])) {
                    if (telecomSuggestionsFilter[key].length) {
                        httpParams = httpParams.append(`${key}[]`, telecomSuggestionsFilter[key]);
                    }
                } else {
                    httpParams = httpParams.append(key, telecomSuggestionsFilter[key]);
                }
            }
        }

        return this.httpClient.get<TelecomSuggestion[]>(TelecomService.suggestionsUri, {
            params: httpParams,
        });
    }

    public getTelenetProducts(): Observable<TelecomSuggestion[]> {
        return this.httpClient.get<TelecomSuggestion[]>(TelecomService.telenetProductsUri);
    }
}
