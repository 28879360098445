import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminModalsSandbox } from '@app/admin/services/admin-modals.sandbox';
import { EditReviewModal } from '@app/reviews/modals/edit-review/edit-review.modal';
import { ReviewsService } from '@app/reviews/services/reviews.service';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { DbUtils, NpsReview, ReviewSource, UpdateNpsReview } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, UiContext } from '@smooved/ui';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-nps-actions',
    templateUrl: './nps-actions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsActionsComponent {
    @Input() public review: NpsReview;
    @Output() public updated: EventEmitter<NpsReview> = new EventEmitter();
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly reviewSources = ReviewSource;

    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly adminModalsSandbox: AdminModalsSandbox,
        public readonly reviewsService: ReviewsService,
        private readonly uiSandbox: AppUiSandbox
    ) {}

    public openEditReview(review: NpsReview): void {
        this.modalSandbox.openModal(null, null, null, EditReviewModal, { data: review }, this.editSuggestion(DbUtils.getStringId(review)));
    }

    public openDetail(review: NpsReview): void {
        this.adminModalsSandbox.openMoverDetail(review.moveId);
    }

    private editSuggestion(reviewId: string) {
        return (review: UpdateNpsReview): void => {
            if (!review) return;
            if (review.deleted) {
                this.updated.emit();
                return;
            }
            this.uiSandbox.moveLoading(true);
            this.reviewsService
                .updateReview(reviewId, review)
                .pipe(finalize(() => this.uiSandbox.moveLoading(false)))
                .subscribe(() => this.updated.emit());
        };
    }
}
