<ng-container>
    <button
        class="__more-actions u-color-gray-darkest"
        app-button
        [appearance]="buttonAppearance.Icon"
        icon="more_vert"
        [iconSize]="uiIconSize.Md"
        [matMenuTriggerFor]="menu"
        [context]="uiContext.Muted"
    ></button>
    <mat-menu #menu="matMenu" [xPosition]="menuPositions.Before">
        <button mat-menu-item (click)="openCreateNote(review, true)" [disabled]="review.isAnonymous || (featureReviewNote$ | async)">
            <app-icon icon="reply" [size]="uiIconSize.Md"></app-icon>
            <span>{{ 'ANALYTICS.REVIEWS.ACTIONS.OPEN_NEW_PUBLIC_NOTE' | translate }}</span>
        </button>
        <button mat-menu-item (click)="openCreateNote(review, false)" [disabled]="featureReviewNote$ | async">
            <app-icon icon="sticky_note_2" [size]="uiIconSize.Md"></app-icon>
            <span>{{ 'ANALYTICS.REVIEWS.ACTIONS.OPEN_NEW_NOTE' | translate }}</span>
        </button>
        <button
            mat-menu-item
            (click)="openDetail(review)"
            [disabled]="review.isAnonymous || !review.moveId"
            *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"
        >
            <app-icon icon="info" [size]="uiIconSize.Md"></app-icon>
            <span>{{ 'ANALYTICS.REVIEWS.ACTIONS.OPEN_MOVE_DETAIL' | translate }}</span>
        </button>

        <ng-container *hasFeatureAccess="[featureScope.Gift]">
            <button
                mat-menu-item
                *ngIf="!review.hasOrderGift; else hasOrderGift"
                (click)="openSendGift(review)"
                [disabled]="review.isAnonymous || !review.moveId"
            >
                <app-icon icon="redeem" [size]="uiIconSize.Md"></app-icon>
                <span>{{ 'MOVE.GIFT.SEND_GIFT_BOX.CTA' | translate }}</span>
            </button>

            <ng-template #hasOrderGift>
                <button mat-menu-item (click)="openDetail(review)" [disabled]="review.isAnonymous">
                    <app-icon icon="redeem" [size]="uiIconSize.Md"></app-icon>
                    <span>{{ 'MOVE.GIFT.SEND_GIFT_BOX.SHOW' | translate }}</span>
                </button>
            </ng-template>
        </ng-container>

        <ng-container *hasFeatureAccess="[featureScope.ExternalInfluencer]">
            <button mat-menu-item [matMenuTriggerFor]="shareMenu" [disabled]="featureReviewShare$ | async">
                <app-icon icon="share" [size]="uiIconSize.Md"></app-icon>
                <span>{{ 'COMMON.SHARE' | translate }}</span>
            </button>
        </ng-container>
    </mat-menu>

    <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="true">
        <button
            mat-menu-item
            class="__share-button"
            (click)="$event.stopPropagation(); shareLinkedIn(review)"
            [disabled]="(featureReviewShare$ | async) || (!review.pendingLinkedIn && sharingDisabled)"
        >
            <app-icon [brand]="brands.LinkedIn" [size]="uiIconSize.Md" [loading]="review.pendingLinkedIn"></app-icon>
            <span>{{ 'COMMON.SOCIALS.LINKEDIN.LABEL' | translate }}</span>
        </button>
        <button
            class="__share-button"
            mat-menu-item
            (click)="$event.stopPropagation(); shareFacebook(review)"
            [disabled]="(featureReviewShare$ | async) || (!review.pendingFacebook && sharingDisabled)"
        >
            <app-icon [brand]="brands.Facebook" [size]="uiIconSize.Md" [loading]="review.pendingFacebook"></app-icon>
            <span>{{ 'COMMON.SOCIALS.FACEBOOK.LABEL' | translate }}</span>
        </button>
        <button
            class="__share-button"
            mat-menu-item
            (click)="$event.stopPropagation(); shareInstagram(review)"
            [disabled]="(featureReviewShare$ | async) || (!review.pendingInstagram && sharingDisabled)"
        >
            <app-icon [brand]="brands.Instagram" [size]="uiIconSize.Md" [loading]="review.pendingInstagram"></app-icon>
            <span>{{ 'COMMON.SOCIALS.INSTAGRAM.LABEL' | translate }}</span>
        </button>
        <button
            class="__share-button"
            mat-menu-item
            (click)="$event.stopPropagation(); download(review)"
            [disabled]="(featureReviewShare$ | async) || (!review.pendingDownload && sharingDisabled)"
        >
            <app-icon icon="download" [size]="uiIconSize.Md" [loading]="review.pendingDownload"></app-icon>
            <span>{{ 'COMMON.DOWNLOAD.LABEL' | translate }}</span>
        </button>
    </mat-menu>
</ng-container>
