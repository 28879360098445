import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { TodoState } from '@smooved/ui';
import { serviceInvitedI18nKeys } from './todo-setting-the-scene.constants';

@Component({
    selector: 'smvd-app-todo-setting-the-scene',
    templateUrl: './todo-setting-the-scene.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoSettingTheSceneComponent {
    @Input() public move: Move;

    public readonly i18nKeys = serviceInvitedI18nKeys;
    public readonly todoState = TodoState;
}
