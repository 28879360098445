<div
    class="__main u-flex-responsive u-flex-align-items-center u-flex-justify-content-space-between u-h100p u-w100p"
    [class.u-padding-double-phone-landscape-up]="!cropped"
>
    <div class="u-flex-responsive" [class.u-margin-none]="cropped || minified" [class.u-w100p]="cropped">
        <div
            class="__image-container u-padding-bottom u-padding-bottom-none-phone-landscape-up u-margin-right-phone-landscape-up u-padding-right-phone-landscape-up"
            [class.u-flex-align-items-start]="cropped"
            *ngIf="!minified"
        >
            <img class="__image" [src]="externalInfluencer.logo" alt="" />
        </div>
        <smvd-ui-review-arc-score
            [meta]="externalInfluencer.reviews?.overall"
            [arcSize]="uiSize.Sm"
            [fontSize]="uiSize.Sm"
            [direction]="'vertical'"
            class="u-margin-top-up-to-and-including-phone-landscape"
            [class.u-margin-none]="minified"
            [minified]="minified"
        ></smvd-ui-review-arc-score>
    </div>
    <ng-content select="[footer]"></ng-content>
</div>
