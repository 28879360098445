<app-loading-container [loading]="insightsSandbox.loading$ | async">
    <app-card-container class="u-container-big">
        <app-ui-card [border]="false">
            <header class="u-flex-row u-flex-align-items-center u-margin-bottom u-flex-justify-content-space-between">
                <div class="u-flex-column">
                    <h4>{{ "INSIGHTS.DASHBOARD.TITLE" | translate }}</h4>
                    <div class="sub-header u-flex-row">
                        <span>{{ (insightsSandbox.insightsData$ | async)?.total }} {{ "INSIGHTS.DASHBOARD.REVIEWS" | translate }}</span>
                        <span class="spacer">&#183;</span>
                        <span
                            >{{ (insightsSandbox.insightsData$ | async)?.ninetyDaysTotal }} {{ "INSIGHTS.DASHBOARD.LAST_90_DAYS" | translate
                            }}</span
                        >
                    </div>
                </div>

                <ui-dropdown-input
                    *ngIf="uiSandbox.tabletPortraitUp$ | async"
                    [formControl]="locationFormControl"
                    [id]="'office-selector'"
                    [hasMargin]="false"
                    [options]="locationDropdownOptions | async"
                    (ngModelChange)="locationChanged(locationFormControl.value)"
                    width="auto"
                ></ui-dropdown-input>
            </header>

            <app-separator></app-separator>

            <div *ngIf="hasInsights$ | async; else upgradeToSmoovedPlus">
                <div *ngIf="(insightsSandbox.insightsData$ | async)?.total > 0; else noReviews">
                    <h5>{{ "INSIGHTS.DASHBOARD.GRAPHS.TITLE" | translate }}</h5>
                    <div class="sub-title u-color-gray-dark">{{ "INSIGHTS.DASHBOARD.GRAPHS.SUB_TITLE" | translate }}</div>

                    <div class="u-grid visuals-container">
                        <div>
                            <div class="visual u-flex-column u-flex-align-items-center u-flex-justify-content-center">
                                <app-insights-house-match-score
                                    [score]="insightsSandbox.houseMatchScore$ | async"
                                ></app-insights-house-match-score>
                            </div>
                            <div class="info u-flex-column u-flex-justify-content-center">
                                <app-insights-house-match-score-info
                                    [insightsData]="insightsSandbox.insightsData$ | async"
                                ></app-insights-house-match-score-info>
                            </div>
                        </div>
                        <div id="reviews-per-month-chart" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                            <div class="visual reviews-per-month u-flex-column u-flex-align-items-center u-flex-justify-content-center">
                                <app-insights-reviews-per-month-chart
                                    [data]="insightsSandbox.reviewsYearOverview$ | async"
                                ></app-insights-reviews-per-month-chart>
                            </div>
                            <div class="info u-flex-column u-flex-justify-content-center">
                                <app-insights-reviews-per-month-legend></app-insights-reviews-per-month-legend>
                            </div>
                        </div>
                        <div id="client-sources-chart" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                            <div class="visual u-flex-column u-flex-align-items-center u-flex-justify-content-center">
                                <app-insights-client-source-chart
                                    [data]="insightsSandbox.clientSources$ | async"
                                ></app-insights-client-source-chart>
                            </div>
                            <div class="info u-flex-justify-content-center">
                                <app-insights-clients-source-legend></app-insights-clients-source-legend>
                            </div>
                        </div>
                    </div>

                    <app-alert
                        [context]="uiContext.Info"
                        [icon]="uiIcon.Info"
                        [border]="false"
                        class="u-margin-bottom-double"
                        *ngIf="uiSandbox.upToAndIncludingPhoneLandscape$ | async"
                    >
                        <p>{{ "INSIGHTS.DASHBOARD.BIGGER_SCREEN_INFO" | translate }}</p>
                    </app-alert>

                    <app-topic-analysis-results
                        class="u-display-block"
                        [topicAnalysisResults]="insightsSandbox.topicsAnalysis$ | async"
                        [location]="selectedLocation"
                    ></app-topic-analysis-results>
                </div>

                <ng-template #noReviews>
                    <div class="no-reviews-message u-text-align-center">{{ "INSIGHTS.DASHBOARD.NO_REVIEWS" | translate }}</div>
                </ng-template>
            </div>

            <ng-template #upgradeToSmoovedPlus>
                <smvd-ui-review-upgrade-package></smvd-ui-review-upgrade-package>
                <app-insights-upgrade-info></app-insights-upgrade-info>
            </ng-template>
        </app-ui-card>
    </app-card-container>
</app-loading-container>
