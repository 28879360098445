import { Component, forwardRef, Host, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ArrayUtils, DbUtils, Location } from '@smooved/core';
import { BaseAutocompleteInput } from '@smooved/ui';
import { first } from 'rxjs/operators';

@Component({
    selector: 'smvd-app-locations-input',
    templateUrl: 'locations-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocationsInputComponent),
            multi: true,
        },
    ],
})
export class LocationsInputComponent extends BaseAutocompleteInput implements ControlValueAccessor, OnInit {
    public innerModel: string;
    public locations$ = this.realEstateGroupSandbox.locations$;
    public loading$ = this.realEstateGroupSandbox.loading$;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public writeValue(location: string): void {
        this.locations$.pipe(first((values) => !ArrayUtils.isEmpty(values))).subscribe((values) => {
            const match = values.find((value) => DbUtils.getStringId(value) === location);
            this.innerModel = match ? match.name : '';
        });
    }

    public selected(location: Location): void {
        this.propagateChange(DbUtils.getStringId(location));
    }
}
