import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Pipe({
    name: 'authImage',
})
export class AuthImagePipe implements PipeTransform {
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

    public transform(src: string, isBackgroundImage = false): Observable<SafeResourceUrl> {
        const reader = new FileReader();
        return this.http.get(src, { responseType: 'blob' }).pipe(
            mergeMap((imageBlob) => {
                return new Observable<SafeResourceUrl>((emit) => {
                    reader.onloadend = (): void =>
                        emit.next(
                            isBackgroundImage
                                ? this.sanitizer.bypassSecurityTrustStyle(`url(${reader.result as string})`)
                                : this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string)
                        );
                    reader.readAsDataURL(imageBlob);
                });
            })
        );
    }
}
