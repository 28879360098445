import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AddressComponent } from '@app/form/components/address/address.component';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { Gender } from '@app/move/enums/gender.enum';
import { Move } from '@app/move/interfaces/move';
import { User } from '@app/move/interfaces/user';
import { MoveConversionSandbox } from '@app/move/sandboxes/move-conversion.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { Address, Language, RxjsComponent } from '@smooved/core';
import { CheckInput, ModalSandbox, VatNumberValidators } from '@smooved/ui';
import { combineLatest, Observable, zip } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { FormControlNames } from './contact-details.constants';

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent extends RxjsComponent implements OnInit, OnDestroy {
    @Input() public stepStart = 2;
    @Input() public stepEnd = 4;

    @Input() public showPassportNumber = false;
    @Input() public showNationalNumber = false;

    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(AddressComponent) public addressComponent: AddressComponent;

    public formControlNames = FormControlNames;

    public form: UntypedFormGroup = this.formBuilder.group({
        [FormControlNames.FirstName]: [null, Validators.required],
        [FormControlNames.Gender]: [Gender.Male],
        [FormControlNames.LastName]: [null, Validators.required],
        [FormControlNames.DateOfBirth]: [null, Validators.required],
        [FormControlNames.Phone]: [null, [Validators.required]],
        [FormControlNames.Email]: [null, { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
        [FormControlNames.Address]: [null],
        [FormControlNames.OtherAddress]: [false],
        [FormControlNames.Language]: [null],
    });

    public maxDateOfBirth: Date = new Date();

    public editValues = false;

    public landAgentOption: CheckInput<boolean> = {
        id: 'lang-agent',
        name: 'lang-agent',
        value: true,
        label: this.translateService.instant('REAL_ESTATE_AGENT.EOTS.OTHER_SERVICES.LAND_AGENT'),
    };

    public otherContactAddressOption: CheckInput<boolean> = {
        id: 'other-contact-address',
        name: 'other-contact-address',
        value: true,
        label: this.translateService.instant('REAL_ESTATE_AGENT.EOTS.CONTACT_DETAILS.OTHER_ADDRESS'),
    };

    public genderOptions: DropdownInput<Gender>[] = [
        {
            id: 'male',
            name: 'male',
            label: this.translateService.instant('MALE'),
            value: Gender.Male,
        },
        {
            id: 'female',
            name: 'female',
            label: this.translateService.instant('FEMALE'),
            value: Gender.Female,
        },
    ];

    public languageOptions: DropdownInput<Language>[] = Object.keys(Language).map((key) => {
        return {
            id: Language[key],
            label: this.translateService.instant(`LANGUAGE.${key}.LABEL`),
            name: 'Language',
            value: Language[key],
        };
    });

    public prefilledContactAddress$: Observable<Address> = this.moveSandbox.user$.pipe(
        map((user) => user && (user.contactAddress || user.movingAddress))
    );

    public showLandAgent$ = combineLatest([
        this.authenticationSandbox.isRealEstateAgentOrImpersonated$,
        this.moveSandbox.energyOfferSelected$,
    ]).pipe(map(([isRealEstateAgentOrAdmin, energyOfferSelected]) => !!isRealEstateAgentOrAdmin && !!energyOfferSelected));

    public showLandAgentOnce$ = zip(
        this.authenticationSandbox.isRealEstateAgentOrImpersonated$,
        this.moveSandbox.energyOfferSelectedOnce$
    ).pipe(
        take(1),
        map(([isRealEstateAgentOrAdmin, energyOfferSelected]) => !!isRealEstateAgentOrAdmin && !!energyOfferSelected)
    );

    constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        public authenticationSandbox: AuthenticationSandbox,
        public moveSandbox: MoveSandbox,
        private moveConversionSandbox: MoveConversionSandbox,
        private modalSandbox: ModalSandbox,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        zip(this.showLandAgentOnce$, this.authenticationSandbox.isRealEstateAgentOrImpersonated$)
            .pipe(take(1))
            .subscribe(([showLandAgent, isRealEstateAgentOrAdmin]) => {
                if (showLandAgent) {
                    const landAgentControl = this.formBuilder.control(false);
                    this.form.addControl(FormControlNames.LandAgent, landAgentControl);
                    landAgentControl.valueChanges
                        .pipe(
                            filter((value) => !!value),
                            takeUntil(this.destroy$)
                        )
                        .subscribe(this.confirmLandAgent);
                }

                if (isRealEstateAgentOrAdmin) {
                    this.handleEmailChanges();
                }

                if (this.showPassportNumber) {
                    this.form.addControl(FormControlNames.PassportNumber, this.formBuilder.control(null, [Validators.required]));
                }

                if (this.showNationalNumber) {
                    this.form.addControl(FormControlNames.NationalNumber, this.formBuilder.control(null, [Validators.required]));
                }

                this.moveSandbox.moveOnce$.pipe(filter((move) => !!move.professional)).subscribe((move) => {
                    this.form.addControl(FormControlNames.CompanyName, this.formBuilder.control(move.companyName, [Validators.required]));
                    this.form.addControl(
                        FormControlNames.VatNumber,
                        this.formBuilder.control(move.vatNumber, [Validators.required, VatNumberValidators.isValid])
                    );
                });

                this.moveSandbox.moveOnce$.pipe(filter((x) => !!x)).subscribe((move) => {
                    const { user, _id } = move;
                    if (_id) {
                        this.changeDetails({ emitEvent: false });
                    }
                    this.populateUser(user, showLandAgent);
                });
                this.form.setValidators(this.contactAddressValidator());
            });
    }

    public onSubmit(): void {
        this.addressComponent?.markAsSubmitted();
        if (this.form.valid) {
            this.next.emit();
        }
    }

    public ngOnDestroy(): void {
        this.showLandAgentOnce$.subscribe((showLandAgent) => {
            const patch: Move = {
                user: {
                    [FormControlNames.FirstName]: this.firstNameFormControl().value,
                    [FormControlNames.LastName]: this.lastNameFormControl().value,
                    [FormControlNames.DateOfBirth]: this.dateOfBirthFormControl().value,
                    [FormControlNames.Gender]: this.genderFormControl().value,
                    [FormControlNames.Phone]: this.phoneNumberFormControl().value,
                    [FormControlNames.Email]: this.emailFormControl().value,
                    [FormControlNames.Language]: this.languageFormControl().value,
                },
            };

            if (this.showPassportNumber) {
                patch.user.passportNumber = this.passportNumberFormControl().value;
            }

            if (this.showNationalNumber) {
                patch.user.nationalNumber = this.nationalNumberFormControl().value;
            }

            if (!!this.companyNameFormControl() && !!this.vatNumberFormControl()) {
                patch.companyName = this.companyNameFormControl().value;
                patch.vatNumber = this.vatNumberFormControl().value;
            }

            if (!!showLandAgent && !!this.landAgentFormControl()?.value) {
                patch.user.landAgent = true;
            } else {
                patch.user.landAgent = null;
            }

            if (!this.otherContactAddressFormControl().value) {
                this.moveSandbox.userOnce$.subscribe((user) => {
                    patch.user.contactAddress = user?.contactAddress ? { ...user?.contactAddress } : { ...user?.movingAddress };
                    this.moveSandbox.patchProperty('', patch);
                });
            } else {
                patch.user.contactAddress = this.contactAddressFormControl().value;
                this.moveSandbox.patchProperty('', patch);
            }
        });
    }

    public changeDetails(opts?: { emitEvent: boolean }): void {
        this.editValues = true;
        this.emailFormControl().disable(opts);
    }

    public otherContactAddressFormControl(): AbstractControl {
        return this.form?.get('otherContactAddress');
    }

    private handleEmailChanges(): void {
        this.emailFormControl()
            .valueChanges.pipe(
                filter((value) => !!value),
                takeUntil(this.destroy$)
            )
            .subscribe(this.checkActiveMove);
    }

    private populateUser(user: User, showLandAgent: boolean): void {
        if (!this.firstNameFormControl().value) this.firstNameFormControl().patchValue(user.firstName);
        if (!this.lastNameFormControl().value) this.lastNameFormControl().patchValue(user.lastName);
        if (!this.dateOfBirthFormControl().value) this.dateOfBirthFormControl().patchValue(user.dateOfBirth);
        if (!this.genderFormControl().value) this.genderFormControl().patchValue(user.gender);
        if (!this.phoneNumberFormControl().value) this.phoneNumberFormControl().patchValue(user.phoneNumber);
        if (!this.emailFormControl().value) this.emailFormControl().patchValue(user.email, { emitEvent: false });
        if (!this.languageFormControl().value) this.languageFormControl().patchValue(user.language || this.translateService.currentLang);
        if (!!showLandAgent && !this.landAgentFormControl()?.value) {
            this.landAgentFormControl().patchValue(user.landAgent);
        }
        if (this.showPassportNumber && !this.passportNumberFormControl()?.value) {
            this.passportNumberFormControl().patchValue(user.passportNumber);
        }
        if (this.showNationalNumber && !this.nationalNumberFormControl()?.value) {
            this.nationalNumberFormControl().patchValue(user.nationalNumber);
        }
    }

    private populateProfessional(move: Move): void {
        if (!this.companyNameFormControl().value) this.companyNameFormControl().patchValue(move.companyName);
        if (!this.vatNumberFormControl().value) this.vatNumberFormControl().patchValue(move.vatNumber);
    }

    private firstNameFormControl(): AbstractControl {
        return this.form?.get('firstName');
    }

    private lastNameFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.LastName);
    }

    private dateOfBirthFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.DateOfBirth);
    }

    private genderFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.Gender);
    }

    private phoneNumberFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.Phone);
    }

    private emailFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.Email);
    }

    private languageFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.Language);
    }

    private passportNumberFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.PassportNumber);
    }

    private nationalNumberFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.NationalNumber);
    }

    private contactAddressFormControl(): AbstractControl {
        return this.form?.get('contactAddress');
    }

    private companyNameFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.CompanyName);
    }

    private vatNumberFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.VatNumber);
    }

    private landAgentFormControl(): AbstractControl {
        return this.form?.get(FormControlNames.LandAgent);
    }

    private contactAddressValidator(): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors => {
            const otherContactAddressFormControl = group.controls[FormControlNames.OtherAddress];
            const contactAddressFormControl = group.controls[FormControlNames.Address];

            if (!otherContactAddressFormControl || !contactAddressFormControl) {
                contactAddressFormControl.setErrors(null);
            } else if (otherContactAddressFormControl.value && !contactAddressFormControl.value) {
                contactAddressFormControl.setErrors({ required: true });
            } else {
                contactAddressFormControl.setErrors(null);
            }
            return;
        };
    }

    private checkActiveMove = (value: string) => {
        this.moveSandbox.getActiveMoveByEmail(value).subscribe(this.handleActiveMove);
    };

    private handleActiveMove = (activeMove: Move): void => {
        this.moveConversionSandbox.showPrefillMove(activeMove, this.populateData);
    };

    private populateData = (activeMove: Move) => {
        this.populateUser(activeMove.user, true);
        if (activeMove.professional) this.populateProfessional(activeMove);
        this.cdr.detectChanges();
    };

    private confirmLandAgent = () => {
        const data = { data: this.translateService.instant('MOVE.USER.LANDAGENT_CONFIRM') };
        const handleConfirmation = (result: boolean) => {
            if (result) return;
            this.landAgentFormControl().patchValue(false); //if not confirmed
            this.cdr.detectChanges();
        };

        this.modalSandbox.openConfirmModal(data, handleConfirmation, data, handleConfirmation);
    };
}
