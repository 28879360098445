<app-two-panel-modal [loading]="loading">
    <ng-container modal-header>
        <h5>{{ 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.LABEL' | translate }}</h5>
    </ng-container>
    <ng-container modal-left>
        <app-menu class="u-margin-bottom-double">
            <app-menu-item *ngFor="let item of menuItems" [goTo]="item.goTo" appearance="button" size="sm"
                >{{ item.labelResource | translate }}</app-menu-item
            >
        </app-menu>
    </ng-container>
    <ng-container modal-left-actions>
        <button (click)="onPublish()" app-button class="u-w100p" icon="done">{{ 'PUBLISH' | translate }}</button>
    </ng-container>
    <ng-container modal-right>
        <router-outlet name="externalInfluencerConfigureModal"></router-outlet>
    </ng-container>
</app-two-panel-modal>
