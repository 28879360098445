export enum I18nKeyType {
    Interviewee = 'interviewee',
    MoverRole = 'moverRole',
    Log = 'log',
    Unit = 'unit',
    HouseType = 'houseType',
    ReviewBucket = 'reviewBucket',
    ReviewTransaction = 'reviewTransaction',
    Socials = 'socials',
    MoveTransaction = 'moveTransaction',
    Salutation = 'salutation',
    RealEstateGroupActivationStage = 'realEstateGroupActivationStage',
    Province = 'province',
    Gender = 'gender',
    LeaverFinalInvoice = 'leaverFinalInvoice',
}
