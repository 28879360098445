<ng-container *ngIf="review">
    <article [attr.data-review-id]="review | getId" class="u-flex-responsive u-w100p">
        <div *ngIf="!isMobile" class="u-margin-right">
            <ng-container *ngTemplateOutlet="illustrationBySource"></ng-container>
        </div>
        <main class="u-flex-grow-1">
            <header
                class="__header"
                [class.__has-content]="showContent && ((review.suggestionProcessed | highlightSentiment) || review.suggestion)"
            >
                <div *ngIf="isMobile" class="u-flex-row u-flex-justify-content-space-between u-margin-bottom-half">
                    <div class="u-flex-row u-flex-align-items-center">
                        <ng-container *ngTemplateOutlet="illustrationBySource"></ng-container>
                        <div *ngIf="!templateConfig.hideAuthor" class="u-margin-left-half">
                            <ng-container *ngTemplateOutlet="externalScore"></ng-container>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="actions"></ng-container>
                </div>
                <div
                    [class.__extern]="review.source !== ReviewSource.Smooved"
                    class="u-flex-row u-flex-align-items-start u-margin-bottom __title-container"
                >
                    <div class="u-flex-grow-1">
                        <ng-container *ngIf="!isMobile && !templateConfig.hideAuthor">
                            <ng-container *ngTemplateOutlet="externalScore"></ng-container>
                        </ng-container>

                        <smvd-ui-review-title-generated-summary
                            [enableCollapse]="false"
                            [showReviewMeta]="review.showReviewMeta"
                            [source]="review.source"
                            [titles]="{
                                generatedSummary: review.generatedSummary,
                                transactionTitleAttributes: {
                                    houseType: review.houseType,
                                    transactionType: review.assessedTo,
                                    city: review.city
                                }
                            }"
                        >
                        </smvd-ui-review-title-generated-summary>
                    </div>
                    <ng-container *ngIf="!isMobile">
                        <ng-container *ngTemplateOutlet="actions"></ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="showMeta; else metaDisabled">
                    <div *ngIf="!templateConfig.hideAuthor">
                        <div
                            *ngIf="review.showReviewMeta || review.source !== reviewSources.Smooved; else metaBlurred"
                            [ngSwitch]="review.source"
                            class="u-w100p __meta-container"
                        >
                            <div class="u-flex-column u-flex-align-items-start">
                                <div
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipCreatedOn"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start u-margin-bottom-xs"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.Date"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>
                                    <ng-container>
                                        <time [dateTime]="review.createdOn | formatDate: pubDateformat" pubdate
                                            >{{ review.createdOn | formatDate }}
                                        </time>
                                    </ng-container>
                                </div>

                                <div
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipReviewAuthor"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start u-margin-bottom-xs"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.User"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>

                                    <ng-container>
                                        <span
                                            >{{ review.createdBy | authorCaption
                                            }}{{ (review.createdBy | authorCaption) && assesorI18n ? ' , ' : ''
                                            }}{{ assesorI18n | translate }}</span
                                        >
                                    </ng-container>
                                </div>
                                <div
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipProperty"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start u-margin-bottom-xs"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.Property"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>
                                    <ng-container *ngIf="houseTypeI18n || review.city; else propertyUnknown">
                                        <span
                                            >{{ houseTypeI18n | translate | capitalize
                                            }}{{ review.city ? ' ' + ('UI.IN' | translate) + ' ' + review.city : '' }}</span
                                        >
                                    </ng-container>
                                    <ng-template #propertyUnknown
                                        ><span class="u-color-text-muted">{{ 'UI.REVIEWS.PROPERTY.UNKNOWN' | translate }}</span>
                                    </ng-template>
                                </div>

                                <div
                                    *ngIf="templateConfig.showReviewVia"
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipSource"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start u-margin-bottom-xs-up-to-and-including-phone-landscape"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.Web"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>
                                    <ng-container>
                                        <span *ngSwitchCase="reviewSources.Smooved">{{ reviewViaI18n | translate | capitalize }}</span>
                                        <span *ngSwitchCase="reviewSources.Google">{{ 'UI.REVIEWS.GOOGLE.VIA' | translate }}</span>
                                        <span *ngSwitchCase="reviewSources.Facebook">{{ 'UI.REVIEWS.FACEBOOK.VIA' | translate }}</span>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="showVerified">
                                    <div
                                        [hasDistanceFromElement]="false"
                                        [paddingSize]="uiSize.Xs"
                                        [template]="tooltipVerified"
                                        [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                        appTooltip
                                        class="u-flex-row u-flex-align-items-start u-margin-bottom-xs-up-to-and-including-phone-landscape"
                                    >
                                        <ng-container *ngIf="isVerified; else reviewUnverified">
                                            <smvd-ui-svg-illustration
                                                [svg]="svgIllustration.ReviewVerified"
                                                aria-hidden="true"
                                                class="__icon u-margin-right-half"
                                            ></smvd-ui-svg-illustration>
                                            <span>{{ 'UI.REVIEWS.VERIFIED.LABEL' | translate }}</span>
                                        </ng-container>
                                    </div>

                                    <ng-container></ng-container>
                                    <ng-template #reviewUnverified>
                                        <smvd-ui-svg-illustration
                                            [svg]="svgIllustration.ReviewUnverified"
                                            aria-hidden="true"
                                            class="__icon u-margin-right-half"
                                        ></smvd-ui-svg-illustration>
                                        <span>{{ 'UI.REVIEWS.UNVERIFIED.LABEL' | translate }}</span>
                                    </ng-template>
                                </ng-container>
                            </div>

                            <div class="u-flex-column u-flex-align-items-start">
                                <div
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipBrokerLocation"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start u-margin-bottom-xs"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.Office"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>
                                    <ng-container *ngIf="review | formatLocation as location; else locationUnknown">
                                        <span>{{ location }}</span>
                                    </ng-container>
                                    <ng-template #locationUnknown
                                        ><span class="u-color-text-muted">{{ 'UI.REVIEWS.LINKED_LOCATION.UNKNOWN' | translate }}</span>
                                    </ng-template>
                                </div>

                                <div
                                    [hasDistanceFromElement]="false"
                                    [paddingSize]="uiSize.Xs"
                                    [template]="tooltipLinkedRealEstateAgents"
                                    [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                                    appTooltip
                                    class="u-flex-row u-flex-align-items-start"
                                >
                                    <smvd-ui-svg-illustration
                                        [svg]="svgIllustration.RealEstateAgent"
                                        aria-hidden="true"
                                        class="__icon u-margin-right-half"
                                    ></smvd-ui-svg-illustration>
                                    <ng-container
                                        *ngIf="
                                            review.linkedRealEstateAgents
                                                | formatLinkedRealEstateAgents: isAppPublic as linkedRealEstateAgents;
                                            else linkedRealEstateAgentsUnknown
                                        "
                                    >
                                        <span>{{ linkedRealEstateAgents }}</span>
                                    </ng-container>
                                    <ng-template #linkedRealEstateAgentsUnknown
                                        ><span class="u-color-text-muted">{{
                                            'UI.REVIEWS.LINKED_REAL_ESTATE_AGENTS.UNKNOWN' | translate
                                        }}</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </header>

            <div *ngIf="templateConfig.includeTopicsAnalysis" class="u-flex-row u-flex-align-items-center u-margin-bottom u-flex-wrap">
                <ui-review-topic-sentiment-pill
                    *ngFor="let topic of review.topics; let last = last"
                    [class.u-margin-right-sm-md]="!last"
                    [topicSentiment]="topic"
                ></ui-review-topic-sentiment-pill>
            </div>

            <ng-container *ngIf="showContent">
                <p
                    [class.u-background-blurred]="!review.showReviewContent"
                    [innerHTML]="
                        templateConfig.includeTopicsAnalysis ? (review.suggestionProcessed | highlightSentiment) : review.suggestion
                    "
                    class="__nps-content u-font-size-default"
                ></p>

                <p *ngIf="review.missingChars" class="u-font-size-sm u-color-muted u-margin-top-half">
                    {{ 'MISSING_CHARS' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate : { count: review.missingChars } }}
                </p>

                <smvd-ui-review-upgrade-package
                    *ngIf="showUpgradeBox"
                    class="u-display-block u-margin-top"
                ></smvd-ui-review-upgrade-package>
            </ng-container>

            <div *ngIf="!templateConfig.hideNotes && review.notes?.length" class="u-margin-top">
                <app-note
                    (edit)="onEdit(review, note)"
                    *ngFor="let note of review.notes"
                    [access]="note.access"
                    [authorInterpolationParams]="getAuthorInterpolationParams(note) | async"
                    [author]="note.lastModifiedBy || note.createdBy | authorCaption"
                    [canEdit]="note.canEdit"
                    [createdOn]="note.lastModifiedOn || note.createdOn"
                    [id]="note._id"
                    class="__note"
                >
                    <p class="__note">{{ note.value }}</p>
                </app-note>
            </div>
        </main>
    </article>
</ng-container>

<ng-template #externalScore>
    <div [ngSwitch]="review.source">
        <ui-star-score
            *ngSwitchCase="reviewSources.Google"
            [max]="googleMaxScore"
            [score]="review.stars"
            class="u-display-block u-margin-bottom-half"
        ></ui-star-score>

        <div *ngSwitchCase="reviewSources.Facebook" class="u-flex-row u-flex-align-items-center u-margin-bottom-half">
            <app-icon
                [appearance]="iconAppearance.Filled"
                [context]="review.recommended ? uiContext.Success : uiContext.Danger"
                [height]="18"
                [icon]="review.recommended ? 'thumb_up' : 'thumb_down'"
                [width]="18"
                class="u-margin-right-xs"
            ></app-icon>
            <span class="u-color-gray-dark">{{
                (review.recommended ? 'UI.REVIEWS.FACEBOOK.RECOMMENDED' : 'UI.REVIEWS.FACEBOOK.NOT_RECOMMENDED') | translate
            }}</span>
        </div>
    </div>
</ng-template>

<ng-template #illustrationBySource>
    <aside [ngSwitch]="review.source" class="u-flex-no-shrink">
        <app-svg-illustration
            *ngSwitchCase="reviewSources.Google"
            [style.height]="'32px'"
            [style.width]="'32px'"
            [svg]="svgs.Google"
        ></app-svg-illustration>
        <app-svg-illustration
            *ngSwitchCase="reviewSources.Facebook"
            [style.height]="'32px'"
            [style.width]="'32px'"
            [svg]="svgs.Facebook"
        ></app-svg-illustration>
        <app-nps-score *ngSwitchCase="reviewSources.Smooved" [score]="review.score"></app-nps-score>
    </aside>
</ng-template>

<ng-template #actions>
    <div class="__actions u-flex-no-shrink u-flex-row u-flex-align-items-center u-margin-left">
        <mat-chip-listbox *ngIf="review.unread" class="u-margin-right-half">
            <mat-chip-option class="mat-chip-size-xs __new-chip">{{ 'NEW' | translate }}</mat-chip-option>
        </mat-chip-listbox>
        <ng-content select="npsActions"></ng-content>
        <ng-content select="[nps-share]"></ng-content>
    </div>
</ng-template>

<ng-template #metaBlurred>
    <div>
        <span class="u-text-blurred u-margin-right">{{ 'BLURRED_META.USER' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
        <span class="u-text-blurred u-margin-right">{{ 'BLURRED_META.CITY' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
        <span class="u-text-blurred">{{ 'BLURRED_META.TRANSACTION' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
    </div>
</ng-template>

<ng-template #metaDisabled>
    <div class="u-color-muted u-w100p">
        <div class="__info">
            <time [dateTime]="review.createdOn | formatDate: pubDateformat" class="u-color-muted" pubdate
                >{{ review.createdOn | formatDate }}
            </time>
        </div>
        <p class="__info">
            <span class="u-margin-right-xs">{{ 'UI.REVIEWS.SIMPLE_VIEW.LABEL' | translate }}</span>
        </p>
    </div>
</ng-template>

<ng-template #tooltipCreatedOn>
    <p class="u-padding-half">{{ 'UI.REVIEWS.CREATED_ON.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipReviewAuthor>
    <p class="u-padding-half">{{ 'UI.REVIEWS.AUTHOR.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipProperty>
    <p class="u-padding-half">{{ 'UI.REVIEWS.PROPERTY.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipSource>
    <p class="u-padding-half">{{ 'UI.REVIEWS.SOURCE.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipBrokerLocation>
    <p class="u-padding-half">{{ 'UI.REVIEWS.LINKED_LOCATION.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipLinkedRealEstateAgents>
    <p class="u-padding-half">{{ 'UI.REVIEWS.LINKED_REAL_ESTATE_AGENTS.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipVerified>
    <p class="u-padding-half">{{ 'UI.REVIEWS.VERIFIED.TOOLTIP' | translate }}</p>
</ng-template>
