import { captureException, Scope } from '@sentry/angular';
import { ErrorResponse } from '@smooved/core';

export class Sentry {
    private scope: Scope;

    constructor(
        private readonly title: string,
        private readonly description?: string
    ) {
        this.scope = new Scope();
    }

    public forError(error: ErrorResponse): this {
        this.scope.setExtra('error', JSON.stringify(error));
        return this;
    }

    public forTransaction(transactionId: string): this {
        this.scope.setContext('transaction', {
            id: transactionId,
        });
        this.scope.setTag('transaction_id', transactionId);
        return this;
    }

    public forRequest(request: any): this {
        this.scope.setExtra('request', JSON.stringify(request));
        return this;
    }

    public withExtraInfo(label: string, data: any): this {
        this.scope.setExtra(label, data);
        return this;
    }

    public send(): this {
        const error = new Error();
        error.name = this.title;
        error.message = this.description;
        captureException(error, this.scope);
        return this;
    }
}
