import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Language } from '@smooved/core';

@Component({
    selector: 'app-user-language',
    template: ` <span>{{ 'LANGUAGE.' + language?.toUpperCase() + '.LABEL' | translate }}</span> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLanguageComponent {
    @Input() public language: Language;
}
