import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    HouseTypePipe,
    I18nKeyService,
    I18nKeyType,
    Note,
    NpsReview,
    RecommendationReview,
    ReviewSource,
    RxjsComponent,
    SimpleChangesUtils,
    StarReview,
    dateFormatPubDate,
} from '@smooved/core';
import { UiContext, UiSandbox, UiSize } from '@smooved/ui';
import { SurveyUtils } from 'projects/shared/src/lib/survey/utils/survey.utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationSource, ApplicationUtils } from '../../../application';
import { ButtonAppearance, ButtonSize } from '../../../button';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { UiIconAppearance, UiIconSize } from '../../../icon/icon.enums';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { NPS_REVIEWS_TEMPLATE_CONFIG, translationPathReviewVia } from '../../constants/reviews.constants';
import { AuthorInterpolationParams } from '../../interfaces/author-interpolation-params';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';
import { googleMaxScore } from '../nps-review-google-header/nps-review-google-header.constants';
import { NpsReviewsTemplateService } from '../nps-reviews-template/nps-reviews-template.service';

@Component({
    selector: 'ui-review',
    templateUrl: 'review.component.html',
    styleUrls: ['./review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public review: NpsReview | StarReview | RecommendationReview;
    @Input() public showUpgradeBox: boolean;

    public isMobile: boolean;

    public showContent: boolean;
    public showMeta: boolean;
    public showVerified: boolean;
    public isVerified: boolean;
    public reviewViaI18n: string;
    public houseTypeI18n: string;
    public assesorI18n: string;
    public isAppPublic = this.templateConfig.application !== ApplicationSource.SmoovedApp;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly iconSize = UiIconSize;
    public readonly reviewSources = ReviewSource;
    public readonly svgs = SvgIllustration;
    public readonly i18nKeyTypes = uiI18nKeyTypes;

    protected readonly pubDateformat = dateFormatPubDate;
    protected readonly uiSize = UiSize;
    protected readonly uiContext = UiContext;
    protected readonly svgIllustration = SvgIllustration;
    protected readonly googleMaxScore = googleMaxScore;
    protected readonly iconAppearance = UiIconAppearance;

    constructor(
        private readonly npsReviewTemplateService: NpsReviewsTemplateService,
        private readonly i18nKeyService: I18nKeyService,
        private readonly houseTypePipe: HouseTypePipe,
        private readonly uiSandbox: UiSandbox,
        private readonly cdr: ChangeDetectorRef,
        @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.uiSandbox.upToAndIncludingPhoneLandscape$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
            this.isMobile = isMobile;
            this.cdr.detectChanges();
        });
    }

    public ngOnChanges({ review }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(review)) {
            this.isVerified = this.review.source === ReviewSource.Smooved;
            this.showVerified = ApplicationUtils.isHouseMatchApplication(this.templateConfig.application);
            this.showContent =
                this.review?.showReviewContent ||
                (!this.review?.showReviewContent && ApplicationUtils.isSmoovedApplication(this.templateConfig.application));
            this.showMeta =
                this.review.showReviewMeta ||
                !this.isVerified ||
                (!this.review.showReviewMeta && ApplicationUtils.isSmoovedApplication(this.templateConfig.application));
            this.reviewViaI18n = this.isVerified ? translationPathReviewVia + SurveyUtils.interviewVia(this.review.initiatedBy) : null;
            this.houseTypeI18n = this.i18nKeyService.map(this.houseTypePipe.transform(this.review.houseType), I18nKeyType.HouseType);
            this.assesorI18n = this.i18nKeyService.map(this.review.assessedTo, I18nKeyType.Interviewee);
        }
    }

    public onEdit(review: NpsReview, note: Note): void {
        this.npsReviewTemplateService.onEdit(review, note);
    }

    public getAuthorInterpolationParams(note: Note): Observable<AuthorInterpolationParams> {
        return this.npsReviewTemplateService.getAuthorInterpolationParams(note, this.review as NpsReview);
    }

    protected readonly ReviewSource = ReviewSource;
}
