<label *ngIf="labelResource || label" class="u-display-block u-margin-bottom-half __label">{{
    label || (labelResource | translate)
}}</label>

<form [formGroup]="form" #formElement="ngForm">
    <div class="__form" [ngClass]="{ '__form--small': small }">
        <ui-address-street-input
            [formControlName]="formControlNames.Street"
            (addressSelected)="onAddressSelected($event)"
            width="auto"
            [label]="'STREET' | i18nKeyTranslate: i18nKeys.Address"
            [placeholder]="'STREET' | i18nKeyTranslate: i18nKeys.Address"
            [showLabel]="showLabels"
            [showPlaceholder]="showPlaceholders"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            class="__street"
        ></ui-address-street-input>

        <app-text-input
            [formControlName]="formControlNames.HouseNumber"
            class="__house-number"
            width="auto"
            [placeholder]="showPlaceholders ? ('HOUSE_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('HOUSE_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>

        <app-text-input
            [formControlName]="formControlNames.BusNumber"
            width="auto"
            class="__bus-number"
            [placeholder]="showPlaceholders ? ('BUS_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('BUS_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>

        <app-text-input
            [formControlName]="formControlNames.ZipCode"
            width="auto"
            class="__zip-code"
            [placeholder]="showPlaceholders ? ('ZIP_CODE' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('ZIP_CODE' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>

        <app-text-input
            [formControlName]="formControlNames.City"
            width="auto"
            class="__city"
            [placeholder]="showPlaceholders ? ('CITY' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('CITY' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>
    </div>
</form>
