import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';

@Component({
    selector: 'app-invoices',
    templateUrl: 'invoices.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesComponent {
    @Output() public reload: EventEmitter<void> = new EventEmitter<void>();

    public readonly invoiceStatus = InvoiceStatus;

    constructor(public invoicingSandbox: InvoicingSandbox) {}
}
