import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuAlignment, MenuDirection } from './menu.enums';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterContentInit, OnChanges {
    @Input() public label: string;
    @Input() public direction: MenuDirection = MenuDirection.Vertical;
    @Input() public alignment: MenuAlignment;
    @Input() public actionTemplate: TemplateRef<unknown>;

    public classList = '';

    @ContentChildren(MenuItemComponent) private children: QueryList<MenuItemComponent>;

    public menuItems: MenuItemComponent[] = [];

    public ngOnChanges({ direction, alignment }: SimpleChanges): void {
        if (direction?.currentValue || alignment?.currentValue) {
            this.setClassList();
        }
    }

    private setClassList(): void {
        this.classList += ` __menu-group-${this.direction}`;
        if (this.direction === MenuDirection.Horizontal) {
            this.classList +=
                ' u-flex-row u-padding-left-up-to-and-including-tablet-portrait u-padding-right-up-to-and-including-tablet-portrait';
            switch (this.alignment) {
                case MenuAlignment.Left:
                    this.classList += ' u-flex-justify-content-flex-start';
                    break;
                case MenuAlignment.Center:
                    this.classList += ' u-flex-justify-content-center-phone-landscape-up';
                    break;
                case MenuAlignment.Right:
                    this.classList += ' u-flex-justify-content-flex-start';
                    break;
                default:
                    break;
            }
        } else if (this.direction === MenuDirection.Vertical) {
            this.classList += ' u-flex-column';
            switch (this.alignment) {
                case MenuAlignment.Left:
                    this.classList += ' u-flex-align-items-start';
                    break;
                case MenuAlignment.Center:
                    this.classList += ' u-flex-align-items-center';
                    break;
                case MenuAlignment.Right:
                    this.classList += ' u-flex-align-items-end';
                    break;
                default:
                    break;
            }
        }
    }

    public ngAfterContentInit(): void {
        this.menuItems = this.children.map((item) => item);
    }
}
