import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';

export const energySuppliersDomiciliationRequired = [EnergySupplier.TotalEnergies];

export const energySuppliersLabels = {
    [EnergySupplier.Eneco]: 'Eneco',
    [EnergySupplier.Elegant]: 'Elegant',
    [EnergySupplier.EnergiePuntBe]: 'Energie.Be',
    [EnergySupplier.Engie]: 'Engie',
    [EnergySupplier.TotalEnergies]: 'Total Energies',
};
