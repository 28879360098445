<div class="__container">
    <aside class="__aside u-background-white">
        <app-telecom-suggestions-filter></app-telecom-suggestions-filter>
    </aside>
    <main class="__main u-overflow-hidden u-flex-1">
        <h3 class="u-margin-bottom-double">{{ 'TELECOM.SUGGESTIONS.MAIN.TITLE' | translate }}</h3>
        <div class="u-scroll-container-horizontal u-margin-bottom" id="suggestions">
            <div
                class="u-padding-right"
                *ngFor="let telecomSuggestion of suggestions$ | async; let last = last; trackBy: telecomSuggestionTrackByFn"
            >
                <app-telecom-suggestion
                    [provider]="telecomSuggestion.provider"
                    (select)="onSelect($event)"
                    [suggestion]="telecomSuggestion.suggestion"
                ></app-telecom-suggestion>
            </div>
        </div>
        <app-previous-submit [showSubmit]="false" [ignoreTranslateXStyle]="true" (previous)="goToPrevious()"></app-previous-submit>
    </main>
</div>
