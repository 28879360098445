import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeModule } from '../badge';
import { IconModule } from '../icon';
import { SvgModule } from '../svg';
import { MenuItemRoutePipe } from './menu-item/menu-item-route.pipe';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [MenuComponent, MenuItemComponent, MenuItemRoutePipe],
    exports: [MenuComponent, MenuItemComponent, MenuItemRoutePipe],
    imports: [CommonModule, BadgeModule, RouterModule, IconModule, SvgModule],
})
export class MenuModule {}
