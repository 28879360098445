import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[appOptionDisplay]',
})
export class OptionDisplayDirective {
    @Input() appOptionDisplay: string;
    @Input() searchInput: string;

    @HostBinding('class.u-display-none')
    get isHidden(): boolean {
        return this.shouldHide(this.appOptionDisplay, this.searchInput);
    }

    @HostBinding('class.__group-visible')
    get isVisible(): boolean {
        return !this.isHidden;
    }

    private shouldHide(value: string, input: string): boolean {
        if (!input) return false;
        return !this.searchString(value, input);
    }

    private searchString(text: string, pattern: string): boolean {
        const regex = new RegExp(pattern, 'i');
        return regex.test(text);
    }
}
