import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { WaterDetailsModalComponent } from '@app/admin/modals/water-details/water-details.modal';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { AddressUtils } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, UiContext } from '@smooved/ui';

@Component({
    selector: 'smvd-app-water-meter-readings-by-admin',
    templateUrl: 'water-meter-readings-by-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterMeterReadingsByAdminComponent extends BaseServiceActionComponent {
    @Input()
    public move: Move;

    @Output() public moveUpdated: EventEmitter<Move> = new EventEmitter<Move>();

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        super(cdr, moveSandbox);
    }

    public openMeterReadings(): void {
        const input = { move: this.move };
        const header = this.translateService.instant('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.LABEL') as string;
        this.modalSandbox
            .openClosableModal({
                component: WaterDetailsModalComponent,
                config: { input, header, subheader: AddressUtils.buildFormattedAddress(this.move.user.movingAddress) },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveUpdated.emit(move);
            });
    }
}
