import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils, StringUtils } from '@smooved/core';

@Pipe({
    name: 'cacheBuster',
})
export class CacheBusterPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value || !StringUtils.isString(value) || !value.startsWith('http')) return value;
        return `${value.toString()}?cb=${DateUtils.now().getTime()}`;
    }
}
