import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { SelectInput, Svg } from '@smooved/ui';

const formName = 'provider';

export const supplierAssetsBasePath = 'assets/images/suppliers';

export const providers: Provider[] = Object.values(Provider).filter((provider) => provider !== Provider.None && provider !== Provider.Any);

export const providerOptions: SelectInput<Provider>[] = providers.map((provider) => ({
    id: provider.toLowerCase(),
    value: provider,
    labelResource: `TELECOM.PROVIDER.${provider.toUpperCase()}`,
    name: formName,
    imageUrl: `assets/images/suppliers/${provider.toLowerCase()}-small.png`,
}));

export const providerAny: SelectInput<Provider> = {
    id: Provider.Any.toLowerCase(),
    value: Provider.Any,
    labelResource: 'TELECOM.PROVIDER.ANY',
    name: formName,
    svg: Svg.Unknown,
};

export const providerNone: SelectInput<Provider> = {
    id: Provider.None.toLowerCase(),
    value: Provider.None,
    label: '--',
    name: formName,
};
