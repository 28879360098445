import { DataSource } from '@angular/cdk/table';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Period } from '@app/invoicing/enums/period.enum';
import { BaseMovesDataSource } from '@app/move/datasources/base-moves/base-moves.data-source';
import { Move } from '@app/move/interfaces/move';
import { QuarterlyForRealEstateGroupRequest } from '@app/move/interfaces/quarterly-for-real-estate-group-request';
import { MoveService } from '@app/move/services/move.service';
import { PaginationRequest } from '@smooved/core';

export class QuarterlyDataSource extends BaseMovesDataSource implements DataSource<Move> {
    constructor(private moveService: MoveService, public readonly invoicingType: InvoicingType, public readonly period: Period) {
        super();
    }

    public load(quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest, paginationRequest?: PaginationRequest) {
        super.fetch(
            this.moveService.getQuarterlyMovesForRealEstateGroup(
                quarterlyForRealEstateGroupRequest,
                paginationRequest,
                this.invoicingType,
                this.period
            )
        );
    }
}
