import { Role } from '@smooved/core';
import { SelectInput } from '@smooved/ui';
import { ToggleGroupOption } from 'projects/ui/src/lib/button/toggle-group/toggle-group.component';

const name = 'meter-input';

export enum MeterInfoInputType {
    Upload,
    Manual,
}

export const meterInfoInputOptions: ToggleGroupOption<MeterInfoInputType>[] = [
    {
        id: 'toggle-manual',
        label: 'ENERGY.INPUT.MANUAL',
        value: MeterInfoInputType.Manual,
    },
    {
        id: 'toggle-upload',
        label: 'ENERGY.INPUT.UPLOAD',
        value: MeterInfoInputType.Upload,
    },
];

export const meterInputRoleOptions = (tooEarlyToEdit: boolean): SelectInput<Role>[] => {
    return [
        {
            label: 'ENERGY.INPUT.BY_REA.LABEL',
            name,
            value: Role.RealEstateAgent,
            id: 'meter-type-1',
            description: 'ENERGY.INPUT.BY_REA.DESC',
        },
        {
            label: 'ENERGY.INPUT.SELF.LABEL',
            name,
            value: Role.Mover,
            id: 'meter-type-2',
            description: tooEarlyToEdit ? 'ENERGY.INPUT.SELF.DESC_ALT' : 'ENERGY.INPUT.SELF.DESC',
        },
    ];
};

export const defaultMeterInputRole = Role.RealEstateAgent;
export const mostChosenI18nKey = 'MOST_CHOSEN';

export enum MeterInfoAlertType {
    None,
    Default,
    UpdateDeedDate,
    Disabled,
}
