<table>
    <tr>
        <td
            class="u-vertical-align-middle u-padding-right-half u-text-align-right"
            *ngIf="uiSandbox.tabletPortraitUp$ | async"
            [ngClass]="{ 'u-padding-bottom': !minimal && !minimalWithoutPrice }"
        >
            <img
                [src]="'assets/images/suppliers/' + suggestion?.provider + '-small.png'"
                [alt]="'Logo' + suggestion?.provider"
                width="50px"
                class="__image u-display-block"
            />
        </td>
        <td *ngIf="!minimal && !minimalWithoutPrice" class="u-vertical-align-middle u-w100p u-padding-bottom">
            <h5>{{ suggestion?.provider }}</h5>
            <p>{{ suggestion?.productNameLabels | translatedLabel | async }}</p>
        </td>
        <td *ngIf="minimal" class="u-vertical-align-middle u-w100p">
            <h6>
                <span>{{ suggestion?.provider }} </span
                ><span class="u-font-weight-normal">{{ suggestion?.productNameLabels | translatedLabel | async }}</span>
            </h6>
        </td>

        <td *ngIf="minimalWithoutPrice" class="u-vertical-align-middle u-w100p">
            <div class="u-flex-column u-flex-align-items-start">
                <h5>{{ suggestion?.provider }}</h5>
                <p class="u-color-muted">{{ suggestion?.productNameLabels | translatedLabel | async }}</p>
            </div>
        </td>
    </tr>
    <tr *ngIf="!minimal && !minimalWithoutPrice && !suggestion?.advice">
        <td class="u-padding-bottom u-vertical-align-top u-color-muted u-padding-right u-text-align-right">
            {{ 'MOVE.SUGGESTION.TOTAL' | translate }}
        </td>
        <td class="u-padding-bottom u-vertical-align-top u-w100p">
            <div class="u-flex-column">
                <span [ngClass]="{ 'u-color-muted u-text-line-through': hasDiscount() }">{{ suggestion?.price | price }}</span>
                <h3 class="u-color-accent" *ngIf="hasDiscount()">{{ suggestion?.discount | price }}</h3>
                <span class="u-font-size-small u-color-muted">{{ 'MOVE.ENERGY.SUGGESTION.PER_MONTH' | translate }}</span>
            </div>
        </td>
    </tr>
    <tr *ngIf="!minimal && !minimalWithoutPrice && !suggestion?.advice">
        <td *ngIf="showPaymentType && !!paymentType" class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right">
            {{ 'PAYMENT_TYPE.LABEL' | translate }}
        </td>
        <td *ngIf="showPaymentType && !!paymentType" class="u-vertical-align-middle u-w100p">
            {{ paymentType?.toString().toUpperCase() | translate }}
        </td>
    </tr>
    <tr *ngIf="!minimal && !minimalWithoutPrice">
        <td *ngIf="showEdit"></td>
        <td>
            <div class="u-flex-row u-flex-align-items-center u-margin-top">
                <a class="u-link" *ngIf="showEdit" [routerLink]="suggestionsRoute">{{ 'EDIT' | translate }}</a>
            </div>
        </td>
    </tr>
</table>
