import { Pipe, PipeTransform } from '@angular/core';
import { AddressUtils } from '../utils/address.utils';

@Pipe({
    name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
    transform(value: any, separator?: string, withCountry = true): string {
        if (!value) return '-';
        return AddressUtils.buildFormattedAddress(value, separator, withCountry);
    }
}
