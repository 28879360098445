import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '@app/contract/guards/confirm.guard';
import { ConfirmContainer } from './containers/confirm/confirm.container';
import { ContactDetailsContainer } from './containers/contact-details/contact-details.container';
import { ContractContainerContainer } from './containers/contract-container/contract-container.container';
import { ContractsCheckContainer } from './containers/contracts-check/contracts-check.container';
import { ContractsPreparedContainer } from './containers/contracts-prepared/contracts-prepared.container';
import { ContractsRequestedContainer } from './containers/contracts-requested/contracts-requested.container';
import { MoveDetailsContainer } from './containers/move-details/move-details.container';
import { PaymentDetailsContainer } from './containers/payment-details/payment-details.container';
import { RegisterSuccessContainer } from './containers/register-success/register-success.container';
import { CheckGuard } from './guards/check.guard';

const routes: Routes = [
    {
        path: 'confirm/:id',
        canActivate: [ConfirmGuard],
        component: ConfirmContainer,
    },
    {
        path: 'register-success',
        component: RegisterSuccessContainer,
    },
    {
        path: 'check',
        canActivate: [CheckGuard],
        component: ContractsCheckContainer,
    },
    {
        path: 'prepared',
        component: ContractsPreparedContainer,
    },
    {
        path: 'requested',
        component: ContractsRequestedContainer,
    },
    {
        path: '',
        canActivate: [ConfirmGuard],
        component: ContractContainerContainer,
        children: [
            {
                path: 'move-details',
                component: MoveDetailsContainer,
            },
            {
                path: 'contact-details',
                component: ContactDetailsContainer,
            },
            {
                path: 'payment-details',
                component: PaymentDetailsContainer,
            },
            {
                path: '**',
                redirectTo: 'move-details',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContractRoutingModule {}
