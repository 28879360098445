import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIllustration, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-insights-house-match-score',
    templateUrl: 'insights-house-match-score.component.html',
    styleUrls: ['insights-house-match-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsHouseMatchScoreComponent {
    public uiContext = UiContext;
    public svgIllustration = SvgIllustration;

    @Input() public score: number;
    @Input() public smallVersion = false;

    constructor() {}
}
