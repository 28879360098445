import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

@Component({
    selector: 'app-real-estate-agent-reset-password',
    template: `
        <div class="u-padding-top-double u-flex-row u-flex-justify-content-center">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column">
                <h3 class="u-margin-bottom">{{ 'AUTHENTICATION.FORGOT_PASSWORD.TITLE' | translate }}</h3>

                <app-email-input [formControlName]="'email'"></app-email-input>

                <div class="u-flex-column u-flex-align-items-center">
                    <app-button [disabled]="form.invalid" type="submit" class="u-margin-bottom u-w100p">
                        {{ 'AUTHENTICATION.FORGOT_PASSWORD.RESET.TEXT' | translate }}
                    </app-button>
                    <a class="u-link" [routerLink]="realEstateAgentLoginRouting">{{ 'CANCEL' | translate }}</a>
                </div>
            </form>
        </div>
    `,
})
export class RealEstateAgentResetPasswordComponent {
    public realEstateAgentLoginRouting = AppNavigationSandbox.getAbsolute(realEstateAgentLoginRouting);

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
    });

    constructor(private formBuilder: UntypedFormBuilder, private authenticationSandbox: AuthenticationSandbox) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.authenticationSandbox.realEstateAgentResetPassword(this.form.value.email);
        }
    }
}
