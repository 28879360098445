import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon';
import { ButtonComponent } from './button.component';
import { DropdownMenuItemDirective } from './dropdown-menu/dropdown-menu-item.directive';
import { DropdownMenuButtonComponent } from './dropdown-menu/dropdown-menu.component';
import { EdgeButtonDirective } from './edge-button.directive';
import { PreviousSubmitComponent } from './previous-submit/previous-submit.component';
import { ToggleGroupComponent } from './toggle-group/toggle-group.component';

const declarations = [
    ButtonComponent,
    PreviousSubmitComponent,
    DropdownMenuButtonComponent,
    DropdownMenuItemDirective,
    ToggleGroupComponent,
    EdgeButtonDirective,
];

@NgModule({
    imports: [CommonModule, IconModule, TranslateModule, MatMenuModule, MatButtonToggleModule],
    declarations: [...declarations],
    exports: [...declarations],
})
export class ButtonModule {}
