import { Component, Input } from '@angular/core';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { Move } from '@app/move/interfaces/move';
import { TelecomSandbox } from '@app/wizard/telecom/sandboxes/telecom.sandbox';

@Component({
    selector: 'app-telecom-info-detail',
    templateUrl: 'telecom-info-detail.component.html',
    styleUrls: ['./telecom-info-detail.component.scss'],
})
export class TelecomInfoDetailComponent {
    @Input() public move: Move;
    public collapsed = true;

    public appI18nKeyType = AppI18nKeyType;

    constructor(public telecomSandbox: TelecomSandbox) {}
}
