import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Navigation } from '../../../navigation/enums/navigation.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { DbUtils } from '@smooved/core';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-client-success-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-row u-flex-justify-content-center">
                    <div class="u-flex-column u-flex-align-items-center u-container-small">
                        <img width="300" src="assets/images/smooved-hero.png" class="u-margin-bottom-double" />
                        <h3 class="u-margin-bottom-double u-text-align-center">{{ 'CLIENT.SUCCESS.TITLE' | translate }}</h3>
                        <p class="u-margin-bottom-double u-font-size-medium u-text-align-center">{{ 'CLIENT.SUCCESS.TEXT' | translate }}</p>
                        <div class="u-flex-row u-flex-align-items-center">
                            <app-button
                                *ngIf="(authenticationSandbox.clientId$ | async) !== 'omnicasa'"
                                theme="secondary-outline"
                                class="u-margin-right"
                                (onClick)="goClientClick()"
                                >{{
                                    'CLIENT.SUCCESS.CTA.CLIENT' | translate: { client: authenticationSandbox.clientId$ | async | titlecase }
                                }}</app-button
                            >
                            <app-button [routerLink]="dashboardRoute$ | async">{{ 'CLIENT.SUCCESS.CTA.METER_READING' | translate }}</app-button>
                        </div>
                    </div>
                </app-card>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSuccessContainer {
    public dashboardRoute$ = this.moveSandbox.move$.pipe(map((move) => `/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentDashboard}/${DbUtils.getStringId(move) ?? '' }`));

    constructor(public authenticationSandbox: AuthenticationSandbox, private moveSandbox: MoveSandbox) {}

    public goClientClick(): void {
        window.close();
    }
}
