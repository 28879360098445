<div class="__container" [style.--empty-state-button-width.px]="cta?.offsetWidth">
    <div class="__illustration">
        <ng-content select="app-svg-illustration, img"></ng-content>
    </div>
    <div class="__icon">
        <ng-content select="app-icon, app-svg"></ng-content>
    </div>
    <div class="__content">
        <div class="__caption">
            <ng-content select="h1, h2, h3, h4, h5, h6, [empty-state-caption]"></ng-content>
        </div>
        <div class="__info">
            <ng-content select="[empty-state-info]"></ng-content>
        </div>
    </div>
    <div class="__cta" [hidden]="hideCta">
        <div #cta class="__cta-group u-display-inline-block">
            <ng-content select="[app-button], [empty-state-cta]"></ng-content>
        </div>
    </div>
</div>
