<ng-container *ngIf="{ tabletPortraitUp: isTabletPortraitUp$ | async, hasToolbar: hasToolbar$ | async } as vm">
    <header
        *ngIf="isDraft; else default"
        class="
            __skeleton__header
            __header--draft __header-main
            u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-padding-left u-padding-right
        "
    >
        <h3>{{ 'PREVIEW' | translate }}</h3>
        <button app-button (click)="onClose()" [context]="uiContext.Secondary">{{ 'COMMON.CLOSE' | translate }}</button>
    </header>

    <ng-template #default>
        <header class="__skeleton__header __header--default u-flex-row u-flex-align-items-center u-padding-left u-padding-right">
            <div
                class="u-w100p u-flex-row u-container-lg u-margin-0-auto"
                *ngIf="headerAppearance === skeletonHeaderAppearance.Brand; else defaultHeader"
            >
                <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
            </div>
            <ng-template #defaultHeader>
                <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
            </ng-template>

            <ng-template #headerContent>
                <div class="__header-left u-flex-row u-flex-align-items-center">
                    <picture *ngIf="smoovedLogo; else customLogo">
                        <source srcset="assets/images/smooved-logo-light.png" media="(min-width: 600px)" />
                        <source srcset="assets/images/logo-small-light-green.png" media="(max-width: 599px)" />
                        <img src="assets/images/smooved-logo-light.png" class="__logo" loading="lazy" decoding="async" />
                    </picture>
                    <ng-content select="[header-left]"></ng-content>
                </div>
                <div class="__header-center u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                    <ng-content select="[header-center]"></ng-content>
                </div>

                <div class="__header-right u-flex-row u-flex-justify-content-flex-end">
                    <ng-content select="[header-right]"></ng-content>
                </div>
            </ng-template>
        </header>
    </ng-template>

    <header class="__skeleton__header __header-toolbar" *ngIf="vm.hasToolbar">
        <ng-content select="[header-toolbar]"></ng-content>
    </header>

    <div>
        <ng-content></ng-content>
    </div>

    <ng-template #customLogo>
        <div class="__header-logo--custom">
            <ng-content select="[header-logo]"></ng-content>
        </div>
    </ng-template>
</ng-container>
