import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { ElectricityGasPriceDetailsModalComponent } from '@app/wizard/energy/components/electricity-gas-price-details-modal/electricity-gas-price-details-modal.component';
import { ElectricityPriceDetailsModalComponent } from '@app/wizard/energy/components/electricity-price-details-modal/electricity-price-details-modal.component';
import { GasPriceDetailsModalComponent } from '@app/wizard/energy/components/gas-price-details-modal/gas-price-details-modal.component';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { RxjsComponent } from '@smooved/core';
import { ModalSandbox, Svg } from '@smooved/ui';
import { map, takeUntil } from 'rxjs/operators';
import { perMonthDefault, perMonthOption } from './suggestion-selection-energy.constants';

@Component({
    selector: 'app-suggestion-selection-energy-offer',
    templateUrl: 'suggestion-selection-energy-offer.component.html',
    styleUrls: ['suggestion-selection-energy-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionEnergyOfferComponent extends RxjsComponent implements OnInit {
    @Input() public suggestion: EnergySuggestion;
    @Input() public paymentType: PaymentType;
    @Input() public showEdit = false;
    @Input() public showPaymentType = false;
    @Input() public minimal = false;
    @Input() public minimalWithoutPrice = false;
    @Input() public extraInfo = false;
    @Input() public suggestionsRoute: string;

    public svg = Svg;
    public energyType = EnergyType;

    public perMonth = perMonthDefault;
    public perMonthInput = new UntypedFormControl(perMonthDefault);
    public perMonthOption = perMonthOption;

    public initFlowRoute = ['', Navigation.Energy].join('/');

    public readonly energyOffer$ = this.moveSandbox.move$.pipe(map((move) => move.energyOffer));

    constructor(
        public readonly uiSandbox: AppUiSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.perMonthInput.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => (this.perMonth = value));
    }

    public showMoreInfo(): void {
        if (!this.suggestion) {
            return;
        }
        let componentToOpen;

        switch (this.suggestion.type) {
            case EnergyType.Electricity:
                componentToOpen = ElectricityPriceDetailsModalComponent;
                break;
            case EnergyType.Gas:
                componentToOpen = GasPriceDetailsModalComponent;
                break;
            case EnergyType.Both:
                componentToOpen = ElectricityGasPriceDetailsModalComponent;
                break;
            default:
        }
        this.modalSandbox.openModal(
            componentToOpen,
            { data: this.suggestion },
            () => {},
            componentToOpen,
            { data: this.suggestion },
            () => {}
        );
    }
}
