export const socialsUri = {
    facebook: 'https://facebook.com',
    instagram: 'https://instagram.com',
    linkedIn: 'https://linkedin.com',
    twitter: 'https://twitter.com',
    youtube: 'https://youtube.com',
};

export const socialsUriSmooved = {
    facebook: 'https://www.facebook.com/JustSmooved/',
    linkedIn: 'https://www.linkedin.com/company/smooved/',
    twitter: 'https://twitter.com/Smooved6',
};

export const socialShareUri = {
    linkedInShareUri: 'https://www.linkedin.com/sharing/share-offsite/?url=:articleUrl',
    facebookShareUri: 'http://www.facebook.com/sharer.php?u=:articleUrl',
};
