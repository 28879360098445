import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-energy-family-size-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-loading-container [loading]="uiSandbox.moveLoading$ | async">
                    <app-card class="u-flex-column u-flex-align-items-start">
                        <h3>{{ 'REAL_ESTATE_AGENT.EOTS.FAMILY_SIZE.TITLE' | translate }}</h3>
                        <p class="u-color-muted u-margin-bottom-triple">
                            {{
                                'STEP_INDICATION'
                                    | translate
                                        : {
                                              start: 2,
                                              end: 6
                                          }
                            }}
                        </p>

                        <app-family-size (previous)="goToPrevious()" (next)="onSubmit()"></app-family-size>
                    </app-card>
                </app-loading-container>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FamilySizeContainer {
    constructor(private readonly router: Router, private readonly route: ActivatedRoute, public readonly uiSandbox: AppUiSandbox) {}

    public goToPrevious(): void {
        this.router
            .navigate(['../zip-code'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSubmit(): void {
        this.router
            .navigate(['../home-description'], {
                relativeTo: this.route,
            })
            .then();
    }
}
