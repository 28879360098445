import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { invoiceStatusMap } from '@app/invoicing/constants/invoice.constants';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { ActionAudit, AuditUtils } from '@smooved/core';

@Component({
    selector: 'smvd-ui-invoice-status',
    templateUrl: 'invoice-status.component.html',
    styleUrls: ['invoice-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceStatusComponent implements OnInit {
    @Input() public invoiceStatus: ActionAudit<InvoiceStatus>;

    public statusDate: Date;
    public status: string;
    public icon: string;

    public ngOnInit(): void {
        this.status = invoiceStatusMap.get(AuditUtils.getValue(this.invoiceStatus));
        this.statusDate = AuditUtils.getLastModifiedOn(this.invoiceStatus);
        this.icon =
            AuditUtils.getValue(this.invoiceStatus) === InvoiceStatus.InvoicePaid
                ? 'check_circle'
                : AuditUtils.getValue(this.invoiceStatus) === InvoiceStatus.NotPaidYet
                ? 'schedule'
                : null;
    }
}
