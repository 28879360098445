import { InjectionToken } from '@angular/core';
import { BaseUriConfig } from '../../configs';

export class ExternalInfluencerUriConfig extends BaseUriConfig {
    public readonly byUrlUri: string;
    public readonly byRealEstateGroupId: string;
    public readonly sendMessage: string;
    public readonly externalInfluencerBaseEndpointUri: string;
    public readonly houseMatchCompanyPageUri: string;

    constructor(apiUri: string, houseMatchCompanyPageUri: string) {
        super(apiUri);
        this.externalInfluencerBaseEndpointUri = `${this.apiUri}/external-influencer`;
        this.byUrlUri = `${this.externalInfluencerBaseEndpointUri}/:url`;
        this.byRealEstateGroupId = `${this.externalInfluencerBaseEndpointUri}/:id`;
        this.sendMessage = `${this.sendMessage}/message`;
        this.houseMatchCompanyPageUri = houseMatchCompanyPageUri;
    }
}

export const EXTERNAL_INFLUENCER_URI_CONFIG = new InjectionToken<ExternalInfluencerUriConfig>('EXTERNAL_INFLUENCER_URI_CONFIG');
