export const adminBaseRoute = 'admin';
export const adminPartnersRoute = 'partners';
export const adminMoversRoute = 'movers';
export const adminMoversAllRoute = 'all';
export const adminMoversBookRoute = 'book';
export const adminMoversInterviewRoute = 'interview';
export const adminMoversTransferRoute = 'transfer';
export const adminEscalationsRoute = 'escalations';
export const adminRealEstateAgentsRoute = 'real-estate-agents';
export const adminRealEstateAgentsAllRoute = 'all';
export const adminRealEstateAgentsOfficesRoute = 'offices';
export const adminRealEstateAgentsRealEstateGroupsRoute = 'real-estate-groups';
export const adminRealEstateAgentsLeadsRoute = 'leads';
export const adminRealEstateAgentsBillingRoute = 'billing';
export const adminRealEstateAgentsFinancialReportRoute = 'financial-report/:realEstateGroupId';
export const adminRealEstateAgentsFinancialReportQuarterReportRoute = 'quarter-report';
export const adminRealEstateAgentsFinancialReportInvoicesRoute = 'invoices';
export const adminOrdersRoute = 'orders';
export const adminAppointmentsRoute = 'appointments';
export const adminReviewsRoute = 'reviews';
export const adminExportsRoute = 'exports';
export const adminImportsRoute = 'imports';
export const healthChecksRoute = 'health-checks';
export const leaderboardRoute = 'leaderboard';

export const adminBaseRouting = [adminBaseRoute];
export const adminPartnersRouting = [adminBaseRoute, adminPartnersRoute];
export const adminMoversRouting = [adminBaseRoute, adminMoversRoute];
export const adminMoversAllRouting = [adminBaseRoute, adminMoversRoute, adminMoversAllRoute];
export const adminRealEstateAgentsRouting = [adminBaseRoute, adminRealEstateAgentsRoute];
export const adminOrdersRouting = [adminBaseRoute, adminOrdersRoute];
export const adminAppointmentsRouting = [adminBaseRoute, adminAppointmentsRoute];
export const adminReviewsRouting = [adminBaseRoute, adminReviewsRoute];
export const adminExportsRouting = [adminBaseRoute, adminExportsRoute];
export const adminImportsRouting = [adminBaseRoute, adminImportsRoute];
export const adminHealthCheckRouting = [adminBaseRoute, healthChecksRoute];
export const leaderboardRouting = [leaderboardRoute];
