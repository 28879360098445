import { I18nKeyType, i18nKeyTypeMap as coreI18nMap, I18nKeyTypeMap } from '@smooved/core';
import { i18nKeyTypeMap as uiI18nMap } from '@smooved/ui';

export enum AppI18nKeyType {
    TelecomBundle = 'telecomBundle',
    UserRole = 'userRole',
    ContactLog = 'contactLog',
    ServiceAction = 'serviceAction',
    RealEstateAgentDashboard = 'realEstateAgentDashboard',
    RealEstateAgentTodo = 'realEstateAgentTodo',
    RealEstateAgentSurveyInvite = 'realEstateAgentSurveyInvite',
    AdminDashboard = 'adminDashboard',
    AdminCustomMessage = 'adminCustomMessage',
    AdminMortgage = 'adminMortgage',
    Review = 'review',
    ReviewActions = 'reviewActions',
    ReviewTopicSentiment = 'reviewTopicSentiment',
    ReviewTopicSentimentCategories = 'reviewTopicSentimentCategories',
    ReviewTopicSentimentBeta = 'reviewTopicSentimentBeta',
    BivAgents = 'bivAgents',
    CompanyDetails = 'companyDetails',
    Escalation = 'escalation',
    Service = 'service',
    TakeOverInformation = 'takeOverInformation',
    ReviewPendingEdition = 'reviewPendingEdition',
}

export const appI18nKeyTypes = { ...I18nKeyType, ...AppI18nKeyType };

export const i18nKeyTypeMap: I18nKeyTypeMap = {
    ...coreI18nMap,
    ...uiI18nMap,
    [I18nKeyType.Interviewee]: 'SURVEYS.INTERVIEW.INTERVIEWEE',
    [I18nKeyType.MoverRole]: 'MOVE.MOVER_ROLE',
    [I18nKeyType.Log]: 'MOVE.CONTACT_LOGS.ON',
    [I18nKeyType.Unit]: 'UNIT',
    [AppI18nKeyType.TelecomBundle]: 'TELECOM.TELECOM_BUNDLE',
    [AppI18nKeyType.UserRole]: 'MOVE.USER.CREATED_BY_ROLE',
    [AppI18nKeyType.ContactLog]: 'MOVE.CONTACT_LOGS',
    [AppI18nKeyType.ServiceAction]: 'MOVE.SERVICE_ACTIONS',
    [AppI18nKeyType.RealEstateAgentDashboard]: 'REAL_ESTATE_AGENT.DASHBOARD',
    [AppI18nKeyType.RealEstateAgentTodo]: 'REAL_ESTATE_AGENT.TODO',
    [AppI18nKeyType.RealEstateAgentSurveyInvite]: 'REAL_ESTATE_AGENT.SURVEY_INVITE',
    [AppI18nKeyType.AdminDashboard]: 'ADMIN.DASHBOARD',
    [AppI18nKeyType.AdminCustomMessage]: 'ADMIN.DASHBOARD.DETAIL.CUSTOM_MESSAGE',
    [AppI18nKeyType.AdminMortgage]: 'ADMIN.DASHBOARD.DETAIL.MORTGAGE',
    [AppI18nKeyType.Review]: 'ANALYTICS.REVIEWS',
    [AppI18nKeyType.ReviewActions]: 'ANALYTICS.REVIEWS.ACTIONS',
    [AppI18nKeyType.ReviewTopicSentiment]: 'ANALYTICS.REVIEWS.TOPIC_SENTIMENT',
    [AppI18nKeyType.ReviewTopicSentimentCategories]: 'ANALYTICS.REVIEWS.TOPIC_SENTIMENT.CATEGORIES',
    [AppI18nKeyType.ReviewTopicSentimentBeta]: 'ANALYTICS.REVIEWS.TOPIC_SENTIMENT.BETA',
    [AppI18nKeyType.BivAgents]: 'REAL_ESTATE_GROUP.BIV_AGENT',
    [AppI18nKeyType.CompanyDetails]: 'COMPANY_DETAILS',
    [AppI18nKeyType.Escalation]: 'MOVE.ESCALATION',
    [AppI18nKeyType.Service]: 'MOVE.SERVICE',
    [AppI18nKeyType.TakeOverInformation]: 'ENERGY.TAKE_OVER_INFORMATION',
    [AppI18nKeyType.ReviewPendingEdition]: 'REVIEWS.PENDING.EDIT',
};
