import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectUtils } from '@smooved/core';

export interface ToggleGroupOption<T> {
    id: string;
    label: string;
    value: T;
    callback?: (value: T) => void;
}

@Component({
    selector: 'ui-toggle-group',
    templateUrl: './toggle-group.component.html',
    styleUrls: ['./toggle-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleGroupComponent<T> {
    @Input() public options: ToggleGroupOption<T>[];
    @Input() public value: T;
    @Output() public valueChange = new EventEmitter<T>();

    public setValue(value: T): void {
        this.value = value;
        this.valueChange.emit(this.value);
    }

    public activate(option: ToggleGroupOption<T>): void {
        this.setValue(option.value);
    }

    public trackBy(_index: number, option: ToggleGroupOption<T>): string {
        return option.id;
    }

    public isChecked(option: ToggleGroupOption<T>): boolean {
        return ObjectUtils.isEqual(option.value, this.value);
    }
}
