<app-loading-container [loading]="!loaded">
    <div class="__values" *ngIf="data$ | async as data">
        <ng-template
            *ngFor="let row of rows; trackBy: TrackByProperty"
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ row: row }"
        ></ng-template>

        <ng-template #rowTemplate let-row="row">
            <app-number-card class="u-flex-row" [value]="data[row.property]" [label]="row.label | translate"></app-number-card>
        </ng-template>
    </div>
</app-loading-container>
