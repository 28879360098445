import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Transferee } from '@app/move/enums/transferee.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { TelecomSandbox } from '@app/telecom/sandboxes/telecom.sandbox';
import { environment } from '@environments/environment';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-confirm-energy-eots-success-container',
    template: `
        <div class="u-flex-row u-flex-justify-content-center u-padding-top-triple u-padding-right u-padding-bottom-triple u-padding-left">
            <div class="u-container">
                <app-card class="u-flex-row u-flex-justify-content-center">
                    <div class="u-flex-column u-flex-align-items-center u-container-small">
                        <img width="150" src="assets/images/smooved-energy-bulb.png" />

                        <ng-container *ngIf="moveSandbox.showTelecomCtaInConfirm$ | async; else hideTelecomCta">
                            <h3 class="u-margin-bottom u-text-align-center">
                                {{ 'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.TITLE' | translate }}
                            </h3>

                            <p
                                class="u-margin-bottom u-text-align-center"
                                [innerHTML]="'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.TEXT' | translate"
                            ></p>

                            <app-confirm-energy-success-actions [showInsurances]="showInsurances$ | async">
                            </app-confirm-energy-success-actions>

                            <a class="u-link u-text-center" (click)="navigationSandbox.goToApp(true)">{{
                                'CONFIRM_ENERGY.SUCCESS_WITHOUT_TELECOM.CTA_SECONDARY' | translate
                            }}</a>
                        </ng-container>

                        <ng-template #hideTelecomCta>
                            <h3 class="u-margin-bottom u-text-align-center">
                                {{ 'CONFIRM_ENERGY.SUCCESS.TITLE' | translate }}
                            </h3>
                            <p class="u-margin-bottom u-text-align-center">
                                {{ 'CONFIRM_ENERGY.SUCCESS.TEXT' | translate }}
                            </p>

                            <app-button (onClick)="navigationSandbox.goToApp(true)">{{
                                'CONFIRM_ENERGY.SUCCESS.CTA' | translate
                            }}</app-button>
                        </ng-template>
                    </div>
                </app-card>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEnergyEotsSuccessContainer {
    public showInsurances$ = combineLatest(
        this.moveSandbox.realEstateGroupServicesInsurancesDisabled$,
        this.moveSandbox.insurancesProposalUrl$,
        this.moveSandbox.transfereeType$
    ).pipe(
        map(
            ([realEstateGroupServicesInsurancesDisabled, insurancesProposal, transfereeType]) =>
                !realEstateGroupServicesInsurancesDisabled &&
                !insurancesProposal &&
                transfereeType !== Transferee.OwnerTransferee &&
                environment.services.iots
        )
    );

    constructor(public navigationSandbox: AppNavigationSandbox, public telecomSandbox: TelecomSandbox, public moveSandbox: MoveSandbox) {}
}
