import * as adminRouting from '@app/admin/constants/routing.constants';
import { adminEscalationsRoute } from '@app/admin/constants/routing.constants';
import { i18nEscalation } from '@app/move/constants/move.constants';
import { MoveStatus } from '@app/move/enums/move-status.enum';
import { Navigation } from '@app/navigation/enums/navigation.enum';

const tabs = [MoveStatus.All, MoveStatus.Book, MoveStatus.Transfer, MoveStatus.Interview];
export const navlinks: { path: string; label: string }[] = tabs.map((value) => {
    return {
        path: `${adminRouting.adminBaseRoute}/${Navigation.Movers}/${value}`,
        label: `HEADER.MOVE.FILTERS.${value.toUpperCase()}`,
    };
});

export const escalationsLink = {
    path: `${adminRouting.adminBaseRoute}/${Navigation.Movers}/${adminEscalationsRoute}`,
    label: i18nEscalation('TITLE_PLURAL'),
};
