import { Component, ViewChild } from '@angular/core';
import { Language } from '@smooved/core';
import { ClosableModalTemplateComponent } from '@smooved/ui';

@Component({
    selector: 'app-select-language',
    template: `
        <app-closable-modal-template>
            <h3 class="u-margin-bottom">{{ 'LANGUAGE.LABEL' | translate }}</h3>
            <p>{{ 'ADMIN.DASHBOARD.DETAIL.SELECT_LANGUAGE.LABEL' | translate }}:</p>
            <div class="u-margin u-flex-row u-flex-justify-content-center">
                <app-button theme="secondary-outline" (click)="selectLanguage(language.NL)" class="u-padding-half">{{
                    'LANGUAGE.NL.LABEL' | translate
                }}</app-button>
                <app-button theme="secondary-outline" (click)="selectLanguage(language.FR)" class="u-padding-half">{{
                    'LANGUAGE.FR.LABEL' | translate
                }}</app-button>
            </div>
        </app-closable-modal-template>
    `,
    styleUrls: [],
})
export class SelectLanguageModal {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public language = Language;

    constructor() {}

    public selectLanguage(lang: Language): void {
        this.closableModalComponent.close(lang);
    }
}
