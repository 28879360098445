import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-table-with-filter',
    template: `
        <app-card-container
            [hasPaddingUpToAndIncludingPhoneLandscape]="hasPaddingUpToAndIncludingPhoneLandscape"
            [hasPaddingTabletLandscapeUp]="hasPaddingTabletLandscapeUp"
            [hasPaddingTabletPortraitUp]="hasPaddingTabletPortraitUp"
        >
            <div class="__actions u-flex-responsive u-flex-align-items-center u-flex-justify-content-space-between">
                <ng-content select="[actions]"></ng-content>
            </div>
            <div class="__container">
                <div class="__filter">
                    <ng-content select="[filter]"></ng-content>
                </div>
                <ng-content select="[table]"></ng-content>
            </div>
        </app-card-container>
    `,
    styleUrls: ['./card-table-with-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTableWithFilter {
    @Input() public hasPaddingUpToAndIncludingPhoneLandscape = false;
    @Input() public hasPaddingTabletLandscapeUp = false;
    @Input() public hasPaddingTabletPortraitUp = false;
}
