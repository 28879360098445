import { ElectricityForm, GasMetersForm } from '@smooved/ui';

export enum meterInfoFields {
    MovingDate = 'movingDate',
    ElectricityMeters = 'electricityMeters',
    GasMeters = 'gasMeters',
    MeterReadingsTakeover = 'meterReadingsTakeover',
}

export interface EnergyMetersInfoForm {
    [meterInfoFields.MovingDate]?: Date;
    [meterInfoFields.ElectricityMeters]?: ElectricityForm;
    [meterInfoFields.GasMeters]?: GasMetersForm;
    [meterInfoFields.MeterReadingsTakeover]?: boolean;
}
