import { GrayScale } from './enums/color-scale.enum';

export const barGapMap = [1, 0.2, 0.15, 0.1];
export const waterfallStack = 'previousTotal';
export const totalStack = 'total';

export const emptyPieData = [
    {
        value: 1,
        color: GrayScale.Light,
    },
];
