import { SvgIllustration } from '@smooved/ui';

export enum ReviewsTopicSentimentFilterMenu {
    All,
    Positive,
    Negative,
}

const menuLabelPreset = 'ANALYTICS.REVIEWS.TOPIC_SENTIMENT.FILTER';

export const menuItemAll = { labelResource: `${menuLabelPreset}.ALL`, goTo: ReviewsTopicSentimentFilterMenu.All };
export const menuItemPositive = {
    labelResource: `${menuLabelPreset}.POSITIVE`,
    goTo: ReviewsTopicSentimentFilterMenu.Positive,
    svg: SvgIllustration.ThumbsUp,
};
export const menuItemNegative = {
    labelResource: `${menuLabelPreset}.NEGATIVE`,
    goTo: ReviewsTopicSentimentFilterMenu.Negative,
    svg: SvgIllustration.ThumbsDown,
};
