import { MenuItemAppearance } from './menu.enums';

export const menuItemBaseClass = '__menu-item';

export const maxBadgeValue = 99;

export const menuItemClasses = {
    [MenuItemAppearance.Button]:
        'u-color-text u-text-decoration-none u-flex-row u-flex-align-items-center u-padding-top-half u-padding-bottom-half',
    [MenuItemAppearance.Link]: '',
    highBadge: 'mat-badge-content--high',
};
