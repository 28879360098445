<app-card-container
    [hasPaddingTabletPortraitUp]="false"
    [hasPaddingTabletLandscapeUp]="false"
    [hasPaddingUpToAndIncludingPhoneLandscape]="false"
>
    <div class="u-container-big __container">
        <app-loading-container class="u-display-block u-w100p __invoices" [loading]="invoicingSandbox.invoicesSentLoading$ | async">
            <ng-container *ngFor="let invoice of invoicingSandbox.invoicesSent$ | async">
                <ng-container *ngIf="invoice.availableFromPast">
                    <app-invoice
                        *ngIf="invoice.status.value !== invoiceStatus.InvoicePaid; else payedInvoice"
                        class="u-margin-bottom-double u-display-block"
                        (upload)="reload.emit()"
                        [invoice]="invoice"
                    ></app-invoice>
                    <ng-template #payedInvoice>
                        <app-payed-invoice [invoice]="invoice" class="u-margin-bottom-double u-display-block"></app-payed-invoice>
                    </ng-template>
                </ng-container>
                <app-invoice-not-available
                    class="u-margin-bottom-half u-display-block"
                    *ngIf="!invoice.availableFromPast"
                    [invoice]="invoice"
                ></app-invoice-not-available>
            </ng-container>
        </app-loading-container>
    </div>
</app-card-container>
