import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { combineLatest, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { TelecomSuggestionsFilterComponent } from '../../components/telecom-suggestions-filter/telecom-suggestions-filter.component';
import { TelecomSuggestion } from '../../interfaces/telecom-suggestion';
import { TelecomSandbox } from '../../sandboxes/telecom.sandbox';

@Component({
    selector: 'app-telecom-suggestions-container',
    templateUrl: 'telecom-suggestions.container.html',
    styleUrls: ['./telecom-suggestions.container.scss'],
})
export class TelecomSuggestionsContainer implements OnInit, OnDestroy {
    @ViewChild(TelecomSuggestionsFilterComponent, { static: true })
    public telecomSuggestionsFilterComponent: TelecomSuggestionsFilterComponent;

    public suggestions$: Observable<{ provider: Provider; suggestion: TelecomSuggestion }[]>;

    constructor(public telecomSandbox: TelecomSandbox, private router: Router, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        this.suggestions$ = combineLatest(
            this.telecomSuggestionsFilterComponent.filterChange.asObservable(),
            this.moveSandbox.providerChoice$,
            this.telecomSandbox.telecomSuggestions$
        ).pipe(
            map(([filter, providerChoice]) => {
                return [providerChoice, filter.otherProvider].filter((x) => !!x);
            }),
            withLatestFrom(this.telecomSandbox.telecomSuggestions$),
            map(([providers, telecomSuggestions]) => {
                return providers.map((provider) => ({
                    provider,
                    suggestion: telecomSuggestions.find(
                        (telecomSuggestion) => telecomSuggestion.provider?.toLowerCase() === provider?.toLowerCase()
                    ),
                }));
            })
        );
    }

    public onSelect(telecomSuggestion: TelecomSuggestion): void {
        if (telecomSuggestion) {
            const payload: Move = {
                telecomSelected: true,
                telecomOffer: {
                    telecomOfferSelection: {
                        discount: null,
                        discountTerm2: null,
                        ...(telecomSuggestion as any),
                    },
                },
            };
            this.moveSandbox.patchProperty('', payload, true, () => {
                this.moveSandbox.energyOfferOrTransferSelectedOnce$.subscribe((energyOfferOrTransferSelected) => {
                    if (energyOfferOrTransferSelected) {
                        this.router.navigateByUrl(`/${Navigation.Contract}/check`).then();
                    } else {
                        this.router.navigateByUrl(`/${Navigation.Telecom}/offer-selected`).then();
                    }
                });
            });
        }
    }

    public goToPrevious(): void {
        this.router.navigateByUrl(`/${Navigation.Telecom}/data/provider`).then();
    }

    public telecomSuggestionTrackByFn(index, item: { provider: Provider; suggestion: TelecomSuggestion }) {
        return item.provider;
    }

    public ngOnDestroy(): void {
        this.telecomSandbox.clearTelecomSuggestions();
    }
}
