import { Injectable } from '@angular/core';
import { ClientSourceDto, InsightsData, ReviewYearOverview } from '@app/real-estate-agent/interfaces/insights';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupInsightsService } from '@app/real-estate-group/services/real-estate-group-insights.service';
import {
    getClientSourceSuccess,
    getHouseMatchScoreSuccess,
    getInsightsDataSuccess,
    getReviewScoresSuccess,
    getReviewsYearOverviewSuccess,
    getTopicsAnalysisFailed,
    getTopicsAnalysisSuccess,
    InsightsActionTypes,
} from '@app/real-estate-group/state/insights.actions';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { NoopAction, RealEstateLevel } from '@smooved/core';
import { DurationFilter } from '@smooved/ui';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class InsightsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly realEstateGroupInsightsService: RealEstateGroupInsightsService,
        private readonly surveysService: SurveysService
    ) {}

    public getInsightsData = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetInsightsData),
            withLatestFrom(this.realEstateGroupSandbox.id$),
            switchMap(([{ locationId }, id]) => {
                return this.realEstateGroupInsightsService.getInsightsData(id, locationId).pipe(
                    map((insightsData: InsightsData) =>
                        getInsightsDataSuccess({
                            insightsData: insightsData,
                        })
                    ),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    public getHouseMatchScore = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetHouseMatchScore),
            switchMap(({ locationId }) => {
                const level: RealEstateLevel = locationId ? RealEstateLevel.Location : RealEstateLevel.Group;
                return this.surveysService.npsScore(level, DurationFilter.AllTime, locationId, false).pipe(
                    map((npsScore) => getHouseMatchScoreSuccess({ houseMatchScore: npsScore.score })),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    public getReviewsScore = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetReviewScores),
            withLatestFrom(this.realEstateGroupSandbox.id$),
            switchMap(([_, id]) => {
                return this.realEstateGroupInsightsService.getReviewScores(id).pipe(
                    map((scores) => getReviewScoresSuccess({ scores })),
                    catchError(() => of(new NoopAction()))
                );
            })
        )
    );

    public getReviewsYearOverview = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetReviewsYearOverview),
            switchMap(({ realEstateGroupId, locationId }) => {
                return this.realEstateGroupInsightsService.getYearOverviewPerMonth(realEstateGroupId, locationId).pipe(
                    map((data: ReviewYearOverview) =>
                        getReviewsYearOverviewSuccess({
                            data: data.reviewsPerMonth,
                        })
                    ),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    public getClientSources = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetClientSource),
            switchMap(({ realEstateGroupId, locationId }) => {
                return this.realEstateGroupInsightsService.getClientSources(realEstateGroupId, locationId).pipe(
                    map((data: ClientSourceDto) =>
                        getClientSourceSuccess({
                            data: data.sources,
                        })
                    ),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    public getTopicsAnalysis = createEffect(() =>
        this.actions$.pipe(
            ofType(InsightsActionTypes.GetTopicsAnalysis),
            withLatestFrom(this.realEstateGroupSandbox.id$),
            switchMap(([{ locationId }, id]) => {
                return this.realEstateGroupInsightsService.getTopicsAnalysis(id, locationId).pipe(
                    map((data: TopicAnalysis[]) => getTopicsAnalysisSuccess({ data })),
                    catchError((_) => of(getTopicsAnalysisFailed()))
                );
            })
        )
    );
}
