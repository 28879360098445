export enum BivFormControlName {
    BivCode = 'bivCode',
    Name = 'name',
    AgentType = 'agentType',
    Syndic = 'syndic',
    Address = 'address',
    FixedLine = 'fixedLine',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    WebsiteUrl = 'websiteUrl',
    BivUrl = 'bivUrl',
}

export const bivUrl = 'https://www.biv.be/vastgoedmakelaars/';
