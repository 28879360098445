import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BivRealEstateAgent, StringUtils, UpdateBivRealEstateAgent } from '@smooved/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/biv-real-estate-agents-uri.constants';
import { RealEstateGroupProvidersModule } from '../real-estate-group-providers.module';

@Injectable({
    providedIn: RealEstateGroupProvidersModule,
})
export class BivRealEstateAgentService {
    constructor(private readonly httpClient: HttpClient) {}

    public patch(id: string, payload: UpdateBivRealEstateAgent): Observable<BivRealEstateAgent> {
        return this.httpClient.patch<BivRealEstateAgent>(StringUtils.parseUri(uri.bivRealEstateAgentUri, { id }), payload);
    }
}
