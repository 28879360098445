<form
    (ngSubmit)="validateForm()"
    *ngIf="{
        showFixedTariffNoOtherSuppliers: showFixedTariffNoOtherSuppliers$ | async,
        showInsurances: showInsurances$ | async,
        energyGroupedSuggestions: energyGroupedSuggestions$ | async,
        isEnergyOptionLandAgent: form.get(leadPassingFormControlsNames.EnergyOption).value === energyOption.LandAgent,
        showLeaverSection: form.get(leadPassingFormControlsNames.AddLeaver).value
    } as vm"
    [formGroup]="form"
    class="u-flex-column u-container"
    novalidate
>
    <app-card class="u-flex-column u-flex-align-items-start u-margin-bottom-double">
        <h3 class="u-w100p u-divider">{{ 'LEAD_PASSING.TRANSACTION_DETAILS.TITLE' | translate }}</h3>
        <div class="u-flex-responsive u-divider u-w100p">
            <h5 class="__tab-header u-margin-bottom">{{ 'GENERAL' | translate }}</h5>
            <div class="__tab-content u-margin-top-half">
                <app-select-input
                    [custom]="false"
                    [direction]="direction.Horizontal"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.TransfereeType"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'MOVE.TRANSACTION_TYPE' | translate"
                    [options]="transfereeTypeOptions"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-select-input>

                <app-date-input
                    [attr.data-testid]="leadPassingFormControlsNames.MovingDate"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.MovingDate"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'MOVE.USER.MOVING_DATE.LABEL' | translate"
                    [placeholder]="'DATE.PLACEHOLDER' | translate"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-date-input>

                <app-late-moving-date-red-notification
                    [context]="uiContext.Danger"
                    [createdOn]="today"
                    [movingDate]="movingDate"
                ></app-late-moving-date-red-notification>

                <h6 class="u-color-muted u-margin-bottom">{{ 'COMMON.ADDRESS' | translate }}</h6>
                <app-address
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.MovingAddress"
                    [showLabels]="true"
                    [showPlaceholders]="false"
                    class="u-margin-bottom-double"
                ></app-address>

                <app-textarea-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.AdditionalNotes"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [label]="'ADDITIONAL_NOTES.LABEL' | translate"
                    [placeholder]="'ADDITIONAL_NOTES.PLACEHOLDER' | translate"
                    class="u-w100p"
                    ngDefaultControl
                ></app-textarea-input>
            </div>
        </div>

        <div class="u-flex-responsive u-w100p">
            <h5 class="__tab-header u-margin-bottom">
                {{ 'HOME_DESCRIPTION.LABEL' | translate }}
            </h5>
            <div class="__tab-content u-margin-top-half">
                <ui-dropdown-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.HomeDescription"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'TYPE' | translate"
                    [options]="homeDescriptionOptions"
                    [placeholder]="'ENERGY_TYPE.SELECT' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></ui-dropdown-input>

                <app-number-input
                    [attr.data-testid]="leadPassingFormControlsNames.HomeDescriptionSizeInput"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.HomeDescriptionSizeInput"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'HOME_DESCRIPTION.HOME_DESCRIPTION_SIZE_WITH_UNIT' | translate"
                    [placeholder]="'HOME_DESCRIPTION.SURFACE.PLACEHOLDER' | translate"
                    [step]="homeSizeStep"
                    class="u-display-block"
                ></app-number-input>

                <app-number-input
                    *ngIf="showHomeDescriptionApartmentLevel"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'HOME_DESCRIPTION.APARTMENT_LEVEL' | translate"
                    class="u-display-block"
                    formControlName="homeDescriptionApartmentLevel"
                ></app-number-input>

                <ui-dropdown-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.EnergyType"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'ENERGY_TYPE.LABEL' | translate"
                    [options]="energyTypeOptions"
                    [placeholder]="'ENERGY_TYPE.SELECT' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></ui-dropdown-input>

                <app-check-input
                    [custom]="false"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.Vacancy"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [option]="vacancyOption"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></app-check-input>

                <app-check-input
                    [custom]="false"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.HasSolarPanels"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [option]="solarPanelOption"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></app-check-input>
            </div>
        </div>
    </app-card>

    <app-card class="u-flex-column u-flex-align-items-start u-margin-bottom-double">
        <h3 class="u-w100p u-divider">{{ 'MOVE.MOVER_ROLE.TRANSFEREE' | translate }}</h3>
        <div class="u-flex-responsive u-divider u-w100p">
            <h5 class="__tab-header u-margin-bottom">{{ 'DATA' | translate }}</h5>
            <div class="__tab-content u-margin-top-half">
                <ui-dropdown-input
                    [attr.data-testid]="leadPassingFormControlsNames.Professional"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.Professional"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'MOVE.CONTRACT_TYPE.LABEL' | translate"
                    [options]="professionalOptions"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></ui-dropdown-input>

                <ng-container *ngIf="form.get(leadPassingFormControlsNames.Professional).value">
                    <h6 class="u-color-muted u-margin-bottom">{{ 'COMPANY_DETAILS.TITLE' | translate }}</h6>

                    <div class="u-flex-column u-flex-align-items-start u-margin-bottom">
                        <app-text-input
                            ngDefaultControl
                            [formControlName]="leadPassingFormControlsNames.CompanyName"
                            [label]="'COMPANY_DETAILS.COMPANY_NAME' | translate"
                            id="company-name"
                        ></app-text-input>

                        <app-text-input
                            ngDefaultControl
                            [formControlName]="leadPassingFormControlsNames.VatNumber"
                            [hasMargin]="false"
                            [keepMask]="true"
                            [label]="'COMPANY_DETAILS.COMPANY_NUMBER' | translate"
                            [uiMask]="maskVatNumber"
                            class="u-margin-bottom-half"
                            id="vat-number"
                        ></app-text-input>
                        <p class="u-color-muted u-font-size-sm u-margin-bottom">
                            {{ 'COMPANY_DETAILS.COMPANY_NUMBER_EXAMPLE' | translate }}
                        </p>
                    </div>
                </ng-container>

                <app-email-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.EmailTransferee"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'FORM.EMAIL_TRANSFEREE.LABEL' | translate"
                    [placeholder]="'FORM.EMAIL_TRANSFEREE.PLACEHOLDER' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                >
                </app-email-input>

                <app-text-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.FirstNameTransferee"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'UI.FIRST_NAME.LABEL' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></app-text-input>

                <app-text-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.LastNameTransferee"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'UI.LAST_NAME.LABEL' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></app-text-input>

                <h6 class="u-color-muted u-margin-bottom">{{ 'CONTACT' | translate }}</h6>

                <app-phone-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.PhoneNumberTransferee"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'FORM.PHONE_NUMBER_TRANSFEREE.LABEL' | translate"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></app-phone-input>

                <ui-dropdown-input
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.LanguageTransferee"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'LANGUAGE.LABEL' | translate"
                    [options]="languageOptions"
                    class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                ></ui-dropdown-input>

                <app-select-input
                    *ngIf="isRental()"
                    [custom]="false"
                    [direction]="direction.Horizontal"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.TransfereeRentalType"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [label]="'MOVE.TRANSFEREE.TRANSFEREE_TYPE.LABEL' | translate"
                    [options]="transfereeTypeTenantOptions"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-select-input>
            </div>
        </div>

        <div class="u-w100p u-divider">
            <div class="u-flex-responsive u-w100p">
                <h5 class="__tab-header u-margin-bottom">
                    {{ 'ENERGY.LABEL' | translate }}
                </h5>
                <div class="__tab-content u-margin-top-half">
                    <app-select-input
                        [custom]="false"
                        [direction]="direction.Vertical"
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.EnergyOption"
                        [hasMarginDouble]="false"
                        [hasMargin]="false"
                        [options]="energyOptions"
                        class="u-w100p-up-to-and-including-phone-landscape"
                    ></app-select-input>
                </div>
            </div>
            <ng-container *ngIf="showEnergySuggestions$ | async">
                <div class="u-w100p u-bordered u-border-radius u-padding-double u-margin-top">
                    <app-loading-container
                        *ngIf="allRequiredFieldsFilledInForEnergySuggestions; else missingFields"
                        [loading]="energyGroupedSuggestionsLoading$ | async"
                    >
                        <div class="u-flex-responsive u-flex-align-items-center">
                            <app-select-input
                                *ngIf="!form.get(leadPassingFormControlsNames.Vacancy).value"
                                [custom]="false"
                                [direction]="direction.Horizontal"
                                ngDefaultControl
                                [formControlName]="leadPassingFormControlsNames.FixedTariff"
                                [hasMarginDouble]="true"
                                [hasMargin]="false"
                                [label]="'ENERGY.CONTRACT_TYPE' | translate"
                                [options]="fixedTariffOptions"
                                class="u-w100p-up-to-and-including-phone-landscape"
                            ></app-select-input>
                            <app-alert
                                *ngIf="vm.isEnergyOptionLandAgent"
                                [context]="uiContext.Danger"
                                class="u-white-space-no-wrap u-font-size-small"
                                >{{ 'MOVE.ENERGY.ONLY_AS_LAND_AGENT' | translate }}
                            </app-alert>
                        </div>
                        <div
                            [class.__energy-suggestions-container--show-fixed-tariff-no-other-suppliers]="
                                vm.showFixedTariffNoOtherSuppliers
                            "
                            class="__energy-suggestion-container"
                        >
                            <label
                                *ngFor="let groupedSuggestion of vm.energyGroupedSuggestions; let first = first; let last = last"
                                [class.u-margin-bottom-up-to-and-including-phone-landscape]="!last"
                                class="u-cursor-pointer"
                            >
                                <input
                                    ngDefaultControl
                                    [formControlName]="leadPassingFormControlsNames.EnergySuggestion"
                                    [value]="groupedSuggestion"
                                    class="u-label-select-input"
                                    type="radio"
                                />
                                <app-energy-suggestion-small
                                    [groupedSuggestion]="groupedSuggestion"
                                    [label]="getEnergySuggestionLabelResource(groupedSuggestion, vm.energyGroupedSuggestions) | translate"
                                    class="u-label-select-container u-padding u-h100p"
                                ></app-energy-suggestion-small>
                            </label>

                            <div
                                *ngIf="vm.showFixedTariffNoOtherSuppliers"
                                class="u-bordered u-border-radius-large u-flex-row u-flex-align-items-center u-flex-justify-content-center u-padding-double"
                            >
                                <span class="u-color-muted u-font-size-small u-container-extra-extra-small u-text-align-center">{{
                                    'MOVE.ENERGY.SUGGESTIONS.FIXED_TARIFF.NO_OTHER_SUPPLIERS' | translate
                                }}</span>
                            </div>
                        </div>

                        <div *ngIf="vm.isEnergyOptionLandAgent" class="u-margin-top-double">
                            <app-date-input
                                ngDefaultControl
                                [formControlName]="leadPassingFormControlsNames.DateOfBirth"
                                [label]="'LEAD_PASSING.TRANSFEREE_DETAILS.DATE_OF_BIRTH' | translate"
                                [placeholder]="'DATE.PLACEHOLDER' | translate"
                                class="u-w100p-up-to-and-including-phone-landscape"
                            ></app-date-input>

                            <app-select-input
                                [custom]="false"
                                [direction]="direction.Horizontal"
                                ngDefaultControl
                                [formControlName]="leadPassingFormControlsNames.Gender"
                                [hasMarginDouble]="false"
                                [hasMargin]="false"
                                [label]="'LEAD_PASSING.TRANSFEREE_DETAILS.GENDER' | translate"
                                [options]="genderOptions"
                                class="u-w100p-up-to-and-including-phone-landscape"
                            ></app-select-input>
                        </div>
                    </app-loading-container>
                    <ng-template #missingFields>{{ 'LEAD_PASSING.ENERGY_SUGGESTIONS.MISSING_FIELDS' | translate }} </ng-template>
                </div>
            </ng-container>
        </div>

        <div [class.u-divider]="vm.showInsurances" class="u-flex-responsive u-divider u-w100p">
            <h5 class="__tab-header u-margin-bottom">{{ 'TELECOM.LABEL' | translate }}</h5>
            <div class="__tab-content u-margin-top-half">
                <app-select-input
                    [custom]="false"
                    [direction]="direction.Vertical"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.ServiceTelecom"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [options]="telecomOptions"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-select-input>
            </div>
        </div>
        <app-card *ngIf="telecomSelected$ | async" class="u-flex-responsive u-w100p u-border-gray-light u-border-radius">
            <app-telecom-bundle-selection
                ngDefaultControl
                [formControlName]="leadPassingFormControlsNames.TelecomBundle"
                class="u-display-block u-w100p u-margin-bottom-double"
                label="TELECOM.INTERESTED.PRODUCTS"
            ></app-telecom-bundle-selection>
            <app-select-input
                ngDefaultControl
                [formControlName]="leadPassingFormControlsNames.TelecomProvider"
                [gridContainer]="true"
                [hasMarginDouble]="false"
                [hasMargin]="false"
                [label]="'TELECOM.INTERESTED.PROVIDER' | translate"
                [options]="telecomProviderOptions"
            ></app-select-input>
        </app-card>
        <div *ngIf="vm.showInsurances" class="u-flex-responsive u-w100p">
            <h5 class="__tab-header u-margin-bottom">{{ 'INSURANCES.LABEL' | translate }}</h5>
            <div class="__tab-content u-margin-top-half">
                <app-select-input
                    [custom]="false"
                    [direction]="direction.Vertical"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.ServiceInsurances"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [options]="insurancesOptions"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-select-input>
                <div *ngIf="form.get(leadPassingFormControlsNames.ServiceInsurances).value" class="u-flex-row u-flex-align-items-center">
                    <p class="u-color-muted u-font-size-small u-margin-right-double">{{ 'INSURANCES.INTERESTED.SUPPLIER' | translate }}</p>
                    <img [alt]="insurancesSupplierName" [src]="insurancesLogoUri" />
                </div>
            </div>
        </div>
    </app-card>

    <app-card class="u-flex-column u-flex-align-items-start u-margin-bottom-double">
        <h3 class="u-w100p u-divider">
            <span>{{ 'MOVE.MOVER_ROLE.LEAVER' | translate }}</span>
            <span class="u-color-muted"> ({{ 'OPTIONAL' | translate }})</span>
        </h3>

        <div [class.u-divider]="vm.showLeaverSection" class="u-flex-responsive u-w100p">
            <h5 class="__tab-header u-margin-bottom">{{ 'MOVE.MOVER_ROLE.LEAVER' | translate }}</h5>
            <div class="__tab-content u-margin-top-half">
                <app-select-input
                    [custom]="false"
                    [direction]="direction.Vertical"
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.AddLeaver"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    [options]="addLeaverOptions"
                    class="u-w100p-up-to-and-including-phone-landscape"
                ></app-select-input>
            </div>
        </div>

        <ng-container *ngIf="vm.showLeaverSection">
            <div class="u-flex-responsive u-divider u-w100p">
                <h5 class="__tab-header u-margin-bottom">{{ 'DATA' | translate }}</h5>
                <div class="__tab-content u-margin-top-half">
                    <app-email-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.EmailLeaver"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'FORM.EMAIL_LEAVER.LABEL' | translate"
                        [placeholder]="'FORM.EMAIL_LEAVER.PLACEHOLDER' | translate"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    >
                    </app-email-input>

                    <app-text-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.FirstNameLeaver"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'UI.FIRST_NAME.LABEL' | translate"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    ></app-text-input>

                    <app-text-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LastNameLeaver"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'UI.LAST_NAME.LABEL' | translate"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    ></app-text-input>

                    <h6 class="u-w100p u-margin-bottom-double u-margin-top-quadruple">
                        <span class="u-color-muted"> {{ 'CONTACT' | translate }}</span>
                    </h6>
                    <app-phone-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.PhoneNumberLeaver"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'FORM.PHONE_NUMBER_LEAVER.LABEL' | translate"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    ></app-phone-input>

                    <ui-dropdown-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LanguageLeaver"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'LANGUAGE.LABEL' | translate"
                        [options]="languageOptions"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    ></ui-dropdown-input>

                    <ui-dropdown-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LeaverType"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'LEAVER_TYPE.LABEL' | translate"
                        [options]="leaverTypeOptions"
                        class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                    ></ui-dropdown-input>
                </div>
            </div>
            <div class="u-flex-responsive u-w100p">
                <h5 class="__tab-header u-margin-bottom">{{ 'SUPPLIER' | translate }}</h5>
                <div class="__tab-content u-margin-top-half">
                    <app-select-input
                        [custom]="false"
                        [direction]="direction.Vertical"
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LeaverFinalInvoice"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [options]="LeaverFinalInvoiceOptions"
                        class="u-w100p-up-to-and-including-phone-landscape"
                    ></app-select-input>
                </div>
            </div>
            <app-card *ngIf="showFinalInvoiceForm" class="u-flex-responsive u-w100p u-border-gray-light u-border-radius">
                <app-energy-stop-suppliers-form
                    ngDefaultControl
                    [formControlName]="leadPassingFormControlsNames.LeaverSuppliers"
                    [move]="leaverPlaceholder"
                    [showRelocationDate]="false"
                ></app-energy-stop-suppliers-form>
                <div class="u-flex-responsive u-w100p">
                    <app-select-input
                        [custom]="false"
                        [direction]="direction.Horizontal"
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LeaverContact"
                        [hasMarginDouble]="false"
                        [hasMargin]="false"
                        [options]="leaverContactInvoiceOption"
                        class="u-w100p-up-to-and-including-phone-landscape"
                    ></app-select-input>
                </div>
                <div *ngIf="showLeaverContactAddressForm" class="u-flex-responsive u-w100p u-padding-top-double">
                    <smvd-ui-address-input
                        ngDefaultControl
                        [formControlName]="leadPassingFormControlsNames.LeaverContactAddress"
                        [label]="'ENERGY.ADDRESS_FINAL_INVOICE' | translate"
                    ></smvd-ui-address-input>
                </div>
            </app-card>
        </ng-container>
    </app-card>

    <app-card class="u-flex-column u-flex-align-items-start">
        <app-check-input
            [attr.data-testid]="leadPassingFormControlsNames.RealEstateAgentConsent"
            [custom]="false"
            ngDefaultControl
            [formControlName]="leadPassingFormControlsNames.RealEstateAgentConsent"
            [option]="realEstateAgentConsentOption$ | async"
            class="u-display-block"
            width="auto"
        ></app-check-input>
        <app-gdpr
            [attr.data-testid]="leadPassingFormControlsNames.SmoovedTermsAndConditions"
            ngDefaultControl
            [formControlName]="leadPassingFormControlsNames.SmoovedTermsAndConditions"
            class="u-display-block"
            width="auto"
        ></app-gdpr>

        <button [isLoading]="uiSandbox.moveLoading$ | async" data-testid="submit-button" app-button class="u-margin-top" type="submit">
            {{ 'MOVE.REGISTER.NEW' | translate }}
        </button>
        <p *ngIf="form.invalid && form.touched" data-testid="error-message" class="u-margin-top-half u-font-size-small u-color-error">
            {{ 'ERRORS.REQUIRED_FIELDS' | translate }}
        </p>
    </app-card>
</form>

<div *ngIf="mockDataWithLeaver || mockDataWithoutLeaver" class="u-position-fixed-bottom-right u-flex-column">
    <button
        (click)="prefillMockDataWithoutLeaver()"
        *ngIf="mockDataWithoutLeaver"
        [appearance]="buttonAppearance.Raised"
        [context]="uiContext.Accent"
        [size]="buttonSize.Sm"
        class="u-margin-bottom-half"
        smvd-ui-button
    >
        Mock Data without leaver
    </button>
    <button
        (click)="prefillMockDataWithLeaver()"
        *ngIf="mockDataWithoutLeaver"
        [appearance]="buttonAppearance.Raised"
        [context]="uiContext.Accent"
        [size]="buttonSize.Sm"
        class="u-margin-bottom-half"
        smvd-ui-button
    >
        Mock Data with leaver
    </button>
</div>
