import { Injectable } from '@angular/core';
import { GetFamilialSuggestionRequest } from '@app/iots/interfaces/get-familial-suggestion-request';
import { GetInsurancesSuggestionsRequest } from '@app/iots/interfaces/get-insurances-suggestions-request';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { InsurancesService } from '@app/iots/services/insurances.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InsurancesSandbox {
    constructor(private insurancesService: InsurancesService) {}

    public getSuggestions(getInsurancesSuggestionsRequest: GetInsurancesSuggestionsRequest): Observable<InsurancesSuggestion[]> {
        return this.insurancesService.getSuggestions(getInsurancesSuggestionsRequest);
    }

    public getFamilial(getFamilialSuggestionRequest: GetFamilialSuggestionRequest): Observable<InsurancesSuggestion> {
        return this.insurancesService.getFamilial(getFamilialSuggestionRequest);
    }

    public getContractUrl(quoteId: string): void {
        this.insurancesService.getContractUrl(quoteId).subscribe(({ downloadUrl }) => window.open(downloadUrl, '_blank'));
    }
}
