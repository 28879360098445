import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@shared/move/utils/move.utils';

@Component({
    selector: 'app-telecom-fixed-line-output',
    template: `<p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE' | translate }}</p>
        <p>
            {{ 'MOVE.TELECOM_OFFER.FIXED_LINE.NEW_NUMBER' | translate }}:
            {{ move?.telecomOffer?.fixedLine?.newNumber?.toString().toUpperCase() | translate }}
        </p>
        <p *ngIf="!newNumber">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE.NUMBER' | translate }}: {{ move?.telecomOffer?.fixedLine?.number }}</p> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomFixedLineOutputComponent implements OnChanges {
    @Input() public move: Move;

    public newNumber = false;

    public ngOnChanges(changes: SimpleChanges): void {
        this.newNumber = MoveUtils.fixedLineNewNumber(this.move);
    }
}
