<form [formGroup]="form" class="u-margin-bottom-double">
    <app-text-input [formControlName]="formControlNames.name" [label]="'NAME' | translate"></app-text-input>
    <app-address class="u-margin-bottom" [formControlName]="formControlNames.address"
                 [label]="'ADDRESS' | translate"></app-address>
    <app-text-input [formControlName]="formControlNames.vatNumber" [label]="'VAT_NUMBER' | translate"></app-text-input>
    <smvd-app-locations-input [formControlName]="formControlNames.location"></smvd-app-locations-input>
</form>
<button app-button [appearance]="buttonAppearance.Link" [context]="context.Muted"
        (click)="close()">{{ 'CANCEL' | translate }}</button>
<button app-button [appearance]="buttonAppearance.Default" [context]="context.Secondary" (click)="onSubmit()">
    {{ (!!office ? 'UPDATE.LABEL' : 'ADD') | translate }}
</button>
