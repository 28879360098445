import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { ClosableModalTemplateComponent } from '../closable-modal-template/closable-modal-template.component';

@Component({
    selector: 'app-tri-panel-modal',
    templateUrl: './tri-panel.modal.html',
    styleUrls: ['./tri-panel.modal.scss'],
})
export class TriPanelModalComponent {
    @ViewChild(ClosableModalTemplateComponent, { static: false })
    public closableModalComponent: ClosableModalTemplateComponent;

    @Input() public loading = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalData) {}

    public close(dailogResult?: any): void {
        this.closableModalComponent.close(dailogResult);
    }
}
