import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationErrorComponent } from './components/notification-error/notification-error.component';
import { NotificationSuccessComponent } from './components/notification-success/notification-success.component';
import { NotificationWarningComponent } from './components/notification-warning/notification-warning.component';
import { labelResources } from './notification.constants';

@Injectable()
export class NotificationSandbox {
    private readonly defaultDuration: number = 7000;

    constructor(private snackBar: MatSnackBar) {}

    public success(labelResource: string, translateParams: Object = {}): void {
        this.snackBar.openFromComponent(NotificationSuccessComponent, {
            data: {
                labelResource: labelResource,
                translateParams: translateParams,
            },
            duration: this.defaultDuration,
            panelClass: ['m-notification', 'm-notification--success'],
        });
    }

    public warning(labelResource: string, translateParams: Object = {}): void {
        this.snackBar.openFromComponent(NotificationWarningComponent, {
            data: {
                labelResource: labelResource,
                translateParams: translateParams,
            },
            duration: this.defaultDuration,
            panelClass: ['m-notification', 'm-notification--warning'],
        });
    }

    public error(labelResource: string, translateParams: Object = {}): void {
        this.snackBar.openFromComponent(NotificationErrorComponent, {
            data: {
                labelResource: labelResource,
                translateParams: translateParams,
            },
            duration: this.defaultDuration,
            panelClass: ['m-notification', 'm-notification--error'],
        });
    }

    public errorAlwaysVisible(labelResource: string, translateParams: Object = {}): void {
        this.snackBar.openFromComponent(NotificationErrorComponent, {
            data: {
                labelResource: labelResource,
                translateParams: translateParams,
            },
            duration: 0,
            panelClass: ['m-notification', 'm-notification--error'],
        });
    }

    public deletedSuccess(): void {
        this.success(labelResources.deletedSuccess);
    }

    public updatedSuccess(): void {
        this.success(labelResources.updatedSuccess);
    }

    public createdSuccess(): void {
        this.success(labelResources.createdSuccess);
    }

    public sendSuccess(): void {
        this.success(labelResources.sendSuccess);
    }

    public importSuccess(): void {
        this.success(labelResources.importSuccess);
    }
}
