import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-user-login',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column">
            <h3 class="u-margin-bottom">Inloggen als verhuizer</h3>

            <label>Wat is jouw email?</label>
            <mat-form-field floatLabel="never" class="u-margin-bottom-double" appearance="outline">
                <input formControlName="email" matInput placeholder="Email" autocomplete="smooved" />
            </mat-form-field>

            <label>Wat is jouw wachtwoord?</label>
            <mat-form-field floatLabel="never" class="u-margin-bottom-double" appearance="outline">
                <input formControlName="password" matInput placeholder="Wachtwoord" type="password" autocomplete="smooved" />
            </mat-form-field>

            <app-button type="submit" [disabled]="form.invalid">Inloggen</app-button>
        </form>
    `,
})
export class UserLoginComponent {
    @Output() public formSubmit: EventEmitter<{ email: string; password: string }> = new EventEmitter();

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required]],
    });

    constructor(private formBuilder: UntypedFormBuilder) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.formSubmit.emit({
                email: this.form.value.email,
                password: this.form.value.password,
            });
        }
    }
}
