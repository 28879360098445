import { Pipe, PipeTransform } from '@angular/core';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentUtils } from '../../../../../projects/core/src/lib/utils/real-estate-agent.utils';

@Pipe({
    name: 'isNotActiveRealEstateAgent',
})
export class IsNotActiveRealEstateAgentPipe implements PipeTransform {
    public transform(value: Partial<RealEstateAgent>): boolean {
        return !RealEstateAgentUtils.isActive(value);
    }
}
