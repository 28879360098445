import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    transfereeTypeFormControlPath,
    transfereeTypeOptions,
} from '@app/move-wizard/components/transferee-type/transferee-type.constants';
import { Transferee } from '@app/move/enums/transferee.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-transferee-type',
    template: `
        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit()">
            <app-select-input
                [options]="transfereeTypeOptions"
                [label]="'MAKE_A_CHOICE.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                formControlName="transfereeType"
            ></app-select-input>

            <app-previous-submit [showPrevious]="false"></app-previous-submit>
        </form>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransfereeTypeComponent implements OnInit {
    @Output() public next: EventEmitter<Transferee> = new EventEmitter<Transferee>();

    public form: UntypedFormGroup = this.formBuilder.group({
        [transfereeTypeFormControlPath]: [null, Validators.required],
    });

    public transfereeTypeOptions = transfereeTypeOptions;

    constructor(private formBuilder: UntypedFormBuilder, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        this.moveSandbox.transfereeTypeOnce$.subscribe((transfereeType) => {
            this.form.get(transfereeTypeFormControlPath).patchValue(transfereeType);
        });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.next.emit(this.form.get(transfereeTypeFormControlPath).value);
        }
    }
}
