export const googleMapsDefaultZoom = 16;

export const googleMapsDefaultOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoom: googleMapsDefaultZoom,
    // Hide all points of interest
    styles: [
        {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
        },
    ],
};
