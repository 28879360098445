import { TelecomBundle } from '@app/wizard/telecom/telecom.interfaces';
import { CheckInput, SelectInput, Svg } from '@smooved/ui';

export const defaultLabel = 'TELECOM.TELECOM_BUNDLE.LABEL';

export const telecomBundleOptions: CheckInput<boolean>[] = [
    {
        id: 'telecom-bundle-internet',
        svg: Svg.Internet,
        value: true,
        name: TelecomBundle.Internet,
        labelResource: 'TELECOM.TELECOM_BUNDLE.INTERNET',
    },
    {
        id: 'telecom-bundle-television',
        svg: Svg.DigitalTelevision,
        value: true,
        name: TelecomBundle.DigitalTelevision,
        labelResource: 'TELECOM.TELECOM_BUNDLE.DIGITALTELEVISION',
    },
    {
        id: 'telecom-bundle-mobile-phone',
        svg: Svg.MobilePhone,
        value: true,
        name: TelecomBundle.MobilePhone,
        labelResource: 'TELECOM.TELECOM_BUNDLE.MOBILEPHONE',
    },
    {
        id: 'telecom-bundle-fixed-line',
        svg: Svg.FixedLine,
        value: true,
        name: TelecomBundle.FixedLine,
        labelResource: 'TELECOM.TELECOM_BUNDLE.FIXEDLINE',
    },
];

export const digitalStreamTelevisionOptions: SelectInput<boolean>[] = [
    {
        id: 'digital-steam-television-false',
        value: false,
        name: 'digital-stream-television',
        labelResource: 'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.VIA_DECODER.LABEL',
        descriptionResource: 'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.VIA_DECODER.DESCRIPTION',
    },
    {
        id: 'digital-steam-television-true',
        value: true,
        name: 'digital-stream-television',
        labelResource: 'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.VIA_STREAMING.LABEL',
        descriptionResource: 'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.VIA_STREAMING.DESCRIPTION',
    },
];

export enum TelecomBundleForm {
    TelecomBundle = 'telecomBundle',
    DigitalStreamTelevision = 'digitalStreamTelevision',
}
