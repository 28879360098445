import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';

@Injectable({
    providedIn: 'root',
})
export class ActivityLogService {
    constructor(private httpClient: HttpClient) {}

    public sendHeartbeat(): Observable<void> {
        return this.httpClient.post<void>(uri.uriHeartbeat, null);
    }

    public sendPageView(route: string): Observable<void> {
        return this.httpClient.post<void>(uri.uriPageview, { path: route });
    }
}
