import { Language } from '@smooved/core';

const calendlyUrlNl = 'https://calendly.com/smooved/60min';
const calendlyUrlFr = 'https://calendly.com/smooved/introduction-et-demo-agent-immobilier';
const calendlyUrlEn = 'https://calendly.com/smooved/60min';

export const defaultCalendlyUrl = calendlyUrlNl;

export const calendlyUrls = {
    [Language.NL]: calendlyUrlNl,
    [Language.EN]: calendlyUrlEn,
    [Language.FR]: calendlyUrlFr,
};
