import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { UiContext } from '../../ui.enums';

@Component({
    selector: 'app-previous-submit',
    templateUrl: './previous-submit.component.html',
    styleUrls: ['./previous-submit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviousSubmitComponent implements CanDisable {
    @Input()
    @HostBinding('class.show-previous')
    public showPrevious = true;
    @Input() public submitTranslationLabel = 'UI.NEXT';
    @Input() public showSubmit = true;
    @Input() public showSkipStep = false;
    @Input() public skipStepTranslationLabel = 'UI.SKIP_STEP';
    @Input() public cancelTranslationLabel = 'CANCEL';
    @Input() public showCancel = false;
    @Input()
    @HostBinding('class.ignore-translate-x-style')
    public ignoreTranslateXStyle = false;
    @Input() public submitDisabled = false;
    @Input() public disabled: boolean;
    @Input() public loading: boolean;
    @Input() public context: UiContext;

    @Output() public previous = new EventEmitter<void>();
    @Output() public skipStep = new EventEmitter<void>();
    @Output() public cancel = new EventEmitter<void>();
    @Output() public next = new EventEmitter<void>();

    public uiContext = UiContext;
}
