import { I18nKeyType, i18nKeyTypeMap as CoreI18nKeyTypeMap } from '@smooved/core';

export enum CompanyPageI18nKeyType {
    CtaBox = 'ctaBox',
    Results = 'results',
    ScoreScale = 'scoreScale',
    Claim = 'claim',
    LandingResults = 'landingResults',
}

export const companyPageI18nKeyTypes = { ...I18nKeyType, ...CompanyPageI18nKeyType };

export const companyPageI18nKeyTypeMap = {
    ...CoreI18nKeyTypeMap,
    [CompanyPageI18nKeyType.Results]: 'UI.COMPANY_PAGE.RESULTS',
    [CompanyPageI18nKeyType.CtaBox]: 'UI.COMPANY_PAGE.CTA_BOX',
    [CompanyPageI18nKeyType.Claim]: 'UI.COMPANY_PAGE.CLAIM',
    [CompanyPageI18nKeyType.LandingResults]: 'UI.COMPANY_PAGE.LANDING_RESULTS',
};

export const matchI18nKeyTypeMap = {
    ...CoreI18nKeyTypeMap,
};
