import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryCode, EnumUtils, PhoneNumberUtils } from '@smooved/core';

export class PhoneNumberValidators {
    public static isValidPhoneNumber(...countries: CountryCode[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.value) return null;
            const countriesToValidate = countries?.length ? countries : EnumUtils.values<CountryCode>(CountryCode);
            const valid = countriesToValidate.some((code) => PhoneNumberUtils.isValidPhoneNumber(control.value, code));
            return !valid ? { phoneNumber: true } : null;
        };
    }
}
