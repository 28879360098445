import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BadgeModule, ButtonModule, IconModule, PipeModule } from '@smooved/ui';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
    imports: [CommonModule, RouterModule, MatButtonModule, IconModule, PipeModule, BadgeModule, MatMenuModule, ButtonModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
})
export class NavigationModule {}
