import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-telecom-phone-transfer-by-admin',
    template: `
        <div [ngSwitch]="!!move?.telecomPhoneTransferByAdmin">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.telecomPhoneTransferByAdmin }">
                <div *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.PHONE_TRANSFER_BY_ADMIN' | translate }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.TELECOM.PHONE_TRANSFER_BY_ADMIN' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half" *ngIf="move?.telecomOrdered">
                <app-button *ngSwitchCase="false" (onClick)="check()" theme="secondary-outline">{{ 'CHECK' | translate }}</app-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomPhoneTransferByAdminComponent extends BaseServiceActionComponent {
    constructor(protected readonly moveSandbox: MoveSandbox, protected readonly cdr: ChangeDetectorRef) {
        super(cdr, moveSandbox);
    }

    public check(): void {
        this.moveSandbox.patchProperty(
            'telecomPhoneTransferByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'telecomPhoneTransferByAdmin',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }
}
