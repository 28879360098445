import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { Order } from '@app/order/interfaces/order';
import { OrderUtils } from '@app/order/utils/order-utils';
import { Move } from '@app/move/interfaces/move';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { OrderState, PaginationResponse, SimpleChangesUtils } from '@smooved/core';
import { Svg } from '@smooved/ui';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as tableConstants from './order-list-closed-table.constants';

@Component({
    selector: 'app-order-list-closed-table',
    template: `
        <table
            matSort
            [matSortDirection]="defaultSortDirection"
            [matSortActive]="defaultSortColumn"
            matSortDisableClear
            mat-table
            [dataSource]="dataSource.data"
            class="u-w100p"
        >
            <ng-container [matColumnDef]="tableConstants.columnOrderId">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'PARTNER.ORDER_ID' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.orderId }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnDeliveryDate">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'DELIVERY_DATE' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.deliveryDate | formatDate }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnScheduledDate">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef mat-sort-header>{{ 'SCHEDULED_DATE' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.planned?.value | formatDate }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnDeliveryAddress">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
                    {{ (flagDeliveryAddressAsAddress ? 'ADDRESS' : 'DELIVERY_ADDRESS') | translate }}
                </th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.deliveryAddress | formatAddress }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnGift">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
                    {{ 'PARTNER.GIFT.PRODUCT' | translate }}
                </th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.gift.nameLabels | translatedLabel | async }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnRealEstateAgent">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
                    {{ 'PARTNER.GIFT.SENDER' | translate }}
                </th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.realEstateAgent | name }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnRealEstateGroup">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
                    {{ 'PARTNER.SENDER' | translate }}
                </th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.realEstateGroup.name }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnCreatedOn">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'CREATED_ON' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.formattedCreatedOn }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'NAME' | translate }}</th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [title]="row.move.user.formattedName || row.move.user.email"
                    class="u-padding-left u-padding-right"
                >
                    <app-user-name [user]="row.move.user"></app-user-name>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnEmail">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'EMAIL' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.move.user.email }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnMovingDate">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'MOVE.USER.MOVING_DATE' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.move.formattedMovingDate }}</td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnIcons">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef></th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">
                    <app-svg-checked
                        class="u-margin-right-half"
                        [checked]="row.move.telecomOrderedByAdmin"
                        [name]="svg.Internet"
                    ></app-svg-checked>

                    <app-svg-checked
                        [checked]="row.move.telecomInstallationDateByAdmin"
                        class="u-margin-right-half"
                        [name]="svg.Calendar"
                    ></app-svg-checked>

                    <app-svg-checked
                        [ngClass]="{ 'u-hidden': !showPhone(row.move) }"
                        [checked]="row.move.telecomPhoneTransferByAdmin"
                        [name]="svg.Phone"
                    ></app-svg-checked>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="tableConstants.columnIconsRentalInspection">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef></th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">
                    <app-svg-checked
                        [checked]="row.move.moveStates.meterElectricityComplete"
                        class="u-margin-right-half"
                        [name]="svg.Elect"
                        [title]="'RENTAL_INSPECTION.STATUS.ELECTRICITY' | translate"
                        *ngIf="hasElectricity(row.move.energyOffer?.energyType)"
                    ></app-svg-checked>

                    <app-svg-checked
                        [checked]="row.move.moveStates.meterGasComplete"
                        [name]="svg.Gas"
                        class="u-margin-right-half"
                        [title]="'RENTAL_INSPECTION.STATUS.GAS' | translate"
                        *ngIf="hasGas(row.move.energyOffer?.energyType)"
                    ></app-svg-checked>

                    <app-svg-checked
                        [checked]="row.move.track.order?.waterDocumentsMovingAddress"
                        [name]="svg.Water"
                        class="u-margin-right-half"
                        [title]="'RENTAL_INSPECTION.STATUS.WATER' | translate"
                    ></app-svg-checked>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: columns"
                (click)="rowClick.emit(row)"
                [class.u-disabled]="row.state === orderState.Cancelled"
            ></tr>
        </table>
        <mat-paginator
            [length]="dataSource.count"
            [pageSize]="pageSizeOptions[0]"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="pageSizeOptions"
        ></mat-paginator>
    `,
    styleUrls: ['./order-list-closed-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListClosedTableComponent extends CoreTableComponent implements OnInit, OnChanges {
    @Input() public dataSource: PaginationResponse<Order>;
    @Output() public rowClick: EventEmitter<Order> = new EventEmitter<Order>();
    public tableConstants = tableConstants;
    public svg = Svg;
    public columns;
    public energyTypes = EnergyType;

    public flagDeliveryAddressAsAddress: boolean;
    public defaultSortColumn = tableConstants.columnDeliveryDate;
    public orderState = OrderState;

    public ngOnInit(): void {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => {
                const { sortDirection: direction, sortField: active } = this.getPaginationOptions();
                this.sortChange.emit({
                    sort: {
                        direction,
                        active,
                    },
                });
            });
    }

    public ngOnChanges({ dataSource }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(dataSource)) {
            this.setColumns(dataSource.currentValue?.data?.length && dataSource.currentValue?.data[0].scope);
        }
    }

    private setColumns(scope: string): void {
        if (OrderUtils.isOrderScopePlant(scope)) {
            this.columns = tableConstants.columnsGift;
            return;
        }

        if (OrderUtils.isOrderScopeTelenet(scope)) {
            this.columns = tableConstants.columnsTelecom;
            return;
        }

        if (OrderUtils.isOrderScopeRentalInspection(scope)) {
            this.columns = tableConstants.columnsRentalInspection;
            this.flagDeliveryAddressAsAddress = true;
            return;
        }

        this.columns = [];
    }

    public showPhone(move: Move): boolean {
        if (!move) return false;
        return MoveUtils.requiresPhoneTransfer(move);
    }

    public hasElectricity(energyType: EnergyType): boolean {
        return !energyType || MoveUtils.hasElectricityEnergyType(energyType);
    }

    public hasGas(energyType: EnergyType): boolean {
        return !energyType || MoveUtils.hasGasEnergyType(energyType);
    }
}
