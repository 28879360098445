import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '../../button';
import { UiHeaderVariant } from '../../ui.enums';
import { TodoListCtaDirective } from './todo-list-cta.directive';
import { classTodoListCompleted } from './todo-list.constants';

@Component({
    selector: 'app-todo-list',
    templateUrl: './todo-list.component.html',
    styleUrls: ['todo-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TodoListComponent implements OnChanges, AfterContentInit {
    @ContentChildren(TodoListCtaDirective) public todoCtaList: QueryList<TodoListCtaDirective>;
    @Input() public completed: boolean;
    @Input() public label: string;
    @Input() public sub: string;
    @Input() public subIfComplete: string;

    public hasCustomCta: boolean;
    public opened: boolean;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly headerVariant = UiHeaderVariant;

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    public ngOnChanges({ completed }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(completed)) {
            this.completed
                ? this.renderer.addClass(this.el.nativeElement, classTodoListCompleted)
                : this.renderer.removeClass(this.el.nativeElement, classTodoListCompleted);
        }
    }

    public ngAfterContentInit(): void {
        this.hasCustomCta = !!this.todoCtaList?.length;
    }

    public toggle(): void {
        this.opened = !this.opened;
    }
}
