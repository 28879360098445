import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, share, startWith } from 'rxjs/operators';
import { navLinks } from './header-admin-menu.constants';

@Component({
    selector: 'app-header-admin-menu',
    template: `
        <button mat-button [matMenuTriggerFor]="adminMenu" [disableRipple]="true">
            {{ showAdminMenu$ | async | translate }}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #adminMenu="matMenu">
            <ng-container *ngFor="let link of navLinks">
                <ng-container *hasFeatureAccess="link.features" hasFeatureAccessOp="OR">
                    <a mat-menu-item [routerLink]="link.routing">
                        {{ link.labelResource | translate }}
                    </a>
                </ng-container>
            </ng-container>
        </mat-menu>
    `,
    styleUrls: ['./header-admin-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAdminMenuComponent {
    private routeNavigationEndEvent$: Observable<NavigationEnd> = this.router.events.pipe(
        startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
        filter((event) => event instanceof NavigationEnd),
        map((event) => <NavigationEnd>event),
        share()
    );

    public navLinks = navLinks;

    public showAdminMenu$: Observable<string> = this.routeNavigationEndEvent$.pipe(
        map((event) => {
            return this.navLinks.find((link) => event.urlAfterRedirects.includes(link.routing[1]))?.labelResource;
        })
    );

    constructor(private router: Router) {}
}
