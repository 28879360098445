<app-ui-card class="u-padding-y-axis">
    <div class="u-flex-row u-flex-align-items-center" card-header>
        <img *ngIf="image" [src]="imagesLibPath + image" />
        <app-icon
            *ngIf="icon"
            [icon]="icon"
            class="u-margin-right-half {{ iconClass }}"
            [size]="iconSize"
            [appearance]="iconAppearance"
        ></app-icon>
        <h6 [innerHtml]="label"></h6>
    </div>
    <div *ngIf="(uiSandbox.tabletLandscapeUp$ | async) || showContentMobile">
        <ng-content></ng-content>
    </div>
    <ng-container card-footer>
        <ng-content select="[app-button]"></ng-content>
    </ng-container>
</app-ui-card>
