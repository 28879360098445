import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nKeyModule, I18nKeyPipe } from '@smooved/core';
import { CommaPipe } from './comma.pipe';
import { CurrencySystemUnitPipe } from './currency-system-unit.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { KwhPipe } from './kwh.pipe';
import { LastModifiedByPipe } from './last-modified-by.pipe';
import { LastModifiedOnPipe } from './last-modified-on.pipe';
import { NamePipe } from './name.pipe';
import { NumberAbsolutePipe } from './number-absolute.pipe';
import { PerSquareMeterPipe } from './per-square-meter.pipe';
import { PerYearPipe } from './per-year.pipe';
import { PricePerKwhPipe } from './price-per-kwh.pipe';
import { PricePipe } from './price.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { TimeIntervalPipe } from './time-interval.pipe';
import { UriSchemaPipe } from './uri-schema.pipe';
import { AssetsByMimeTypesPipe } from './assets-by-mime-types.pipe';
import { CacheBusterPipe } from './cache-buster.pipe';

const declarations = [
    FormatDatePipe,
    TimeIntervalPipe,
    KwhPipe,
    PricePerKwhPipe,
    PricePipe,
    CurrencySystemUnitPipe,
    SanitizePipe,
    UriSchemaPipe,
    NamePipe,
    LastModifiedOnPipe,
    LastModifiedByPipe,
    PerSquareMeterPipe,
    NumberAbsolutePipe,
    CommaPipe,
    PerYearPipe,
    AssetsByMimeTypesPipe,
    CacheBusterPipe,
];

@NgModule({
    imports: [CommonModule],
    declarations,
    providers: [PricePipe, CurrencySystemUnitPipe, I18nKeyPipe, FormatDatePipe, NamePipe],
    exports: [...declarations, I18nKeyModule],
})
export class PipeModule {}
