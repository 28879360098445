import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LookupToolContainer } from '@app/water/containers/lookup-tool/lookup-tool.container';

const routes: Routes = [
    {
        path: 'addresses',
        component: LookupToolContainer,
    },
    {
        path: 'lookup-tool',
        component: LookupToolContainer,
    },
    {
        path: '**',
        redirectTo: 'lookup-tool',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class WaterRoutingModule {}
