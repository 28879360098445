<ng-container *ngIf="!appendIcon" [ngTemplateOutlet]="iconTemplate"></ng-container>
<span
    *ngIf="![buttonAppearance.Icon, buttonAppearance.StrokedIcon, buttonAppearance.FlatIcon].includes(appearance) || iconWithLabel"
    [class.u-hide-up-to-and-including-tablet-portrait]="appearance === buttonAppearance.MobileIcon"
    class="u-display-inline-block"
    ><ng-content></ng-content
></span>
<ng-container *ngIf="appendIcon" [ngTemplateOutlet]="iconTemplate"></ng-container>

<ng-template #iconTemplate>
    <app-icon
        *ngIf="brand || icon || isLoading"
        [icon]="icon"
        [spinning]="isLoading"
        [size]="iconSize"
        [rotate]="iconRotate"
        [appearance]="iconAppearance"
        [context]="iconContext"
        [loading]="isLoading"
        [brand]="brand"
    ></app-icon>
    <ng-content select="app-svg-checked"></ng-content>
</ng-template>
