import { Component, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MoveSandbox } from '../../../../move/sandboxes/move.sandbox';
import { EnergySandbox } from '../../../energy/sandboxes/energy.sandbox';
import { TelecomSandbox } from '../../../telecom/sandboxes/telecom.sandbox';

@Component({
    selector: 'app-products-privacy-modal',
    template: `
        <app-closable-modal-template>
            <p *ngIf="!energyPrivacyUrls.length && !telecomPrivacyUrls.length">Er zijn nog geen producten geselecteerd.</p>

            <div *ngIf="energyPrivacyUrls.length" class="u-margin-bottom-double">
                <h3 class="u-margin-bottom-half">Privacy energie</h3>
                <ul>
                    <li *ngFor="let energyPrivacyUrl of energyPrivacyUrls" class="u-margin-bottom-half">
                        <a [href]="energyPrivacyUrl" target="_blank" class="u-link">{{ energyPrivacyUrl }}</a>
                    </li>
                </ul>
            </div>

            <div *ngIf="telecomPrivacyUrls.length">
                <h3 class="u-margin-bottom-half">Privacy telecom</h3>
                <ul>
                    <li *ngFor="let telecomPrivacyUrl of telecomPrivacyUrls" class="u-margin-bottom-half">
                        <a [href]="telecomPrivacyUrl" download target="_blank" class="u-link">{{ telecomPrivacyUrl }}</a>
                    </li>
                </ul>
            </div>
        </app-closable-modal-template>
    `,
})
export class ProductsPrivacyModalComponent implements OnInit {
    public energyPrivacyUrls: string[] = [];
    public telecomPrivacyUrls: string[] = [];

    constructor(private energySandbox: EnergySandbox, private telecomSandbox: TelecomSandbox, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        zip(
            this.moveSandbox.energySelectedOnce$,
            this.moveSandbox.telecomSelectedOnce$,
            this.moveSandbox.energyOfferSelection$.pipe(take(1)),
            this.moveSandbox.telecomOfferSelection$.pipe(take(1))
        )
            .pipe(
                tap(([energySelected, telecomSelected, energySuggestion, telecomSuggestion]) => {
                    if (energySelected) {
                        this.energyPrivacyUrls = energySuggestion.urlPrivacy.split(',').filter((x) => !!x);
                    }
                    if (telecomSelected) {
                        this.telecomPrivacyUrls = telecomSuggestion.urlPrivacy.split(',').filter((x) => !!x);
                    }
                })
            )
            .subscribe();
    }
}
