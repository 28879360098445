import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { SimpleChangesUtils } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { Svg } from '../../icon/icon.enums';
import { UiHeaderVariant } from '../../ui.enums';
import { TodoListComponent } from '../todo-list/todo-list.component';
import { TodoVerticalAlignment } from './enums/todo-vertical-alignment.enum';
import { TodoStateBlockedDirective } from './todo-state/todo-state-blocked.directive';
import { TodoStateCheckedDirective } from './todo-state/todo-state-checked.directive';
import { TodoStateDisabledDirective } from './todo-state/todo-state-disabled.directive';
import { TodoStateProcessingDirective } from './todo-state/todo-state-processing.directive';
import { TodoStateReadonlyDirective } from './todo-state/todo-state-readonly.directive';
import { TodoStateUncheckedDirective } from './todo-state/todo-state-unchecked.directive';
import { TodoStateWarningDirective } from './todo-state/todo-state-warning.directive';
import { TodoState } from './todo-state/todo-state.enum';

@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoComponent implements OnInit, OnChanges, CanDisable {
    @Input() public label: string;
    @Input() public checked: boolean;
    @Input() public readonly: boolean;
    @Input() public disabled: boolean;
    @Input() public blocked: boolean;
    @Input() public processing: boolean;
    @Input() public warning: boolean;
    @Input() public muted: boolean;
    @Input() public align = TodoVerticalAlignment.Top;
    @Input() public variant = UiHeaderVariant.Span;
    @Input() public state: TodoState;

    @ContentChild(TodoStateCheckedDirective, { read: TemplateRef }) public checkedTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateUncheckedDirective, { read: TemplateRef }) public uncheckedTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateReadonlyDirective, { read: TemplateRef }) public readonlyTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateBlockedDirective, { read: TemplateRef }) public blockedTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateProcessingDirective, { read: TemplateRef }) public processingTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateDisabledDirective, { read: TemplateRef }) public disabledTemplate: TemplateRef<unknown>;
    @ContentChild(TodoStateWarningDirective, { read: TemplateRef }) public warningTemplate: TemplateRef<unknown>;

    public readonly states = TodoState;
    public readonly svg = Svg;

    public labelElement: string;
    public labelChanged$ = new BehaviorSubject<boolean>(undefined);
    public inList = false;
    public currentState: TodoState;

    constructor(@Optional() private readonly todoList: TodoListComponent) {}

    public ngOnInit(): void {
        this.inList = !!this.todoList;
    }

    public ngOnChanges({ label, variant, checked, readonly, blocked, disabled, processing, warning, state }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(state)) {
            this.currentState = this.state;
        }

        if (SimpleChangesUtils.hasChanged(checked, readonly, blocked, disabled, processing, warning)) {
            switch (true) {
                case this.disabled:
                    this.currentState = TodoState.Disabled;
                    break;
                case this.blocked:
                    this.currentState = TodoState.Blocked;
                    break;
                case this.readonly:
                    this.currentState = TodoState.Readonly;
                    break;
                case this.warning:
                    this.currentState = TodoState.Warning;
                    break;
                case this.processing:
                    this.currentState = TodoState.Processing;
                    break;
                case this.checked:
                    this.currentState = TodoState.Checked;
                    break;
                default:
                    this.currentState = this.state ?? TodoState.Unchecked;
            }
        }

        if (SimpleChangesUtils.hasChanged(label, variant)) {
            this.labelChanged$.next(true);
        }
    }
}
