<!-- Name / Address Definition -->
<ng-container matColumnDef="nameAddress">
    <th mat-header-cell class="__cell u-text-truncate u-padding-right-half u-padding-left-half" *matHeaderCellDef>
        {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.MOVING_ADDRESS' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" class="__cell">
        <ng-container *ngIf="!!row.user?.movingAddress; else empty">
            <div class="__container--address">
                <app-user-name [user]="row.user" class="u-font-size-small"></app-user-name>
                <app-address-output [address]="row.user.movingAddress" [title]="row.user.formattedMovingAddress"> </app-address-output>
            </div>
        </ng-container>
    </td>
</ng-container>

<ng-template #empty>
    <div [title]="">-</div>
</ng-template>
