<div class="u-flex-row u-flex-justify-content-space-between">
    <div class="u-flex-row">
        <smvd-ui-review-title-generated-summary
            [titles]="{
                generatedSummary: item.generatedSummary,
                transactionTitleAttributes: {
                    houseType: item.houseType,
                    transactionType: item.assessedTo,
                    city: item.city
                }
            }"
            [source]="source"
            [showReviewMeta]="item.showReviewMeta"
        >
        </smvd-ui-review-title-generated-summary>
        <ng-content selector="[item-chip-list]"></ng-content>
    </div>
</div>
<app-nps-review-author *ngIf="!templateConfig.hideAuthor" [review]="item" class="u-margin-bottom-sm-md"></app-nps-review-author>
