<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <ng-autocomplete
        [data]="categories$ | async"
        [placeholder]="placeholder"
        searchKeyword="name"
        [notFoundText]="'NOT_FOUND' | translate"
        (inputCleared)="inputClear()"
        (selected)="selected($event)"
        [isLoading]="loading$ | async"
        [(ngModel)]="innerModel"
        [itemTemplate]="itemTemplateStatic"
    >
    </ng-autocomplete>

    <ng-template #itemTemplateStatic let-item>
        <span [innerHTML]="item?.name"></span>
    </ng-template>

    <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
</app-label-container>
