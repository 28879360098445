import { Component } from '@angular/core';
import { MessageComponent } from '../message/message.component';

@Component({
    template: ``,
})
export abstract class MessageTemplateComponent {
    constructor(protected readonly message: MessageComponent) {}

    public close(): void {
        this.message.remove();
    }
}
