import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Inject,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DomBuilderService, TranslateCutPipe } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { UiUriConfig, UI_URI_CONFIG } from '../../configs';
import { BaseInput } from '../base-input';
import { CheckInput } from '../check-input/check-input';
import { consentField, termsAndConditionsOption } from './terms-and-conditions-input.constants';

@Component({
    selector: 'smvd-ui-terms-and-conditions-input',
    templateUrl: 'terms-and-conditions-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TermsAndConditionsInputComponent),
            multi: true,
        },
    ],
})
export class TermsAndConditionsInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @ViewChild('formElement', { static: true }) public formGroupDirective: FormGroupDirective;

    @Input() public id: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public custom = false;
    @Input() public width: string;

    public readonly termsAndConditionsOption = this.consentOptionFactory();
    public readonly consentField = consentField;

    public form: UntypedFormGroup;

    public get checked(): boolean {
        return !!this.innerModel;
    }

    public get value(): boolean {
        return this.innerModel;
    }
    public innerModel: boolean;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly translateService: TranslateService,
        private readonly domBuilderService: DomBuilderService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        @Inject(UI_URI_CONFIG) private readonly config: UiUriConfig
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.form = this.formBuilder.group({
            [consentField]: [null, Validators.requiredTrue],
        });
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: object) => this.propagateChange(value[consentField]));
        this.controlContainer.control.markAllAsTouched = (): void => this.markAllAsTouched();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: object): void {
        this.form.patchValue(value ?? {});
    }

    public markAllAsTouched(): void {
        this.form.markAllAsTouched();
        this.cdr.detectChanges();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
        this.form.updateValueAndValidity();
    }

    private consentOptionFactory(): CheckInput<boolean> {
        const option = termsAndConditionsOption;
        option.label = this.translateService.instant(termsAndConditionsOption.labelResource) as string;
        const cutter = new TranslateCutPipe();
        const lang = this.translateService.currentLang;

        const anchorTermsConditions = this.domBuilderService.createAnchor({
            url: this.config.termsAndConditionsUrl[lang],
            label: cutter.transform(option.label, 1),
        });
        const anchorPrivacyPolicy = this.domBuilderService.createAnchor({
            url: this.config.privacyPolicyUrl[lang],
            label: cutter.transform(option.label, 3),
        });

        anchorTermsConditions.className = 'u-color-primary';
        anchorPrivacyPolicy.className = 'u-color-primary';

        const label = `${cutter.transform(option.label, 0)} ${anchorTermsConditions.outerHTML} ${cutter.transform(option.label, 2)} ${
            anchorPrivacyPolicy.outerHTML
        }`;

        return {
            ...option,
            label,
        };
    }
}
