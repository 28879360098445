<div class="u-flex-row" *ngIf="{ showActions: showActions$ | async } as vm">
    <div class="u-flex-column u-margin-right-double">
        <h6 class="u-margin-bottom-half u-color-muted">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.SCORE' | translate }}</h6>
        <h1 class="u-line-height-1">{{ review?.score }}</h1>
    </div>
    <div class="u-flex-column">
        <div class="u-flex-column u-margin-bottom">
            <h6 class="u-margin-bottom-half u-color-muted">
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.MOST_LEAST_VALUED' | translate }}
            </h6>
            <div *ngIf="!!nps?.answers?.mostValuedAspect?.value" class="u-flex-row u-flex-align-items-center">
                <app-icon icon="arrow_drop_up" size="md" class="u-color-success"></app-icon>
                <span>{{ 'REAL_ESTATE_AGENT.NPS.VALUED_MOST.' + nps?.answers?.mostValuedAspect?.value?.toUpperCase() | translate }}</span>
            </div>
            <div *ngIf="!!nps?.answers?.leastValuedAspect?.value" class="u-flex-row u-flex-align-items-center">
                <app-icon icon="arrow_drop_down" size="md" class="u-color-danger"></app-icon>
                <span>{{ 'REAL_ESTATE_AGENT.NPS.VALUED_LEAST.' + nps?.answers?.leastValuedAspect?.value?.toUpperCase() | translate }}</span>
            </div>
        </div>
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.SUGGESTION' | translate }}
            </h6>
            <div [innerHTML]="review?.suggestion"></div>
        </div>
    </div>

    <app-nps-actions *ngIf="vm.showActions && review" [review]="review" (updated)="reviewUpdated()"></app-nps-actions>
</div>
