import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';
import { ColorScale } from '../charts/enums/color-scale.enum';

export class AnimationUtils {
    public static fade(triggerName: string, duration: string | number, onEnter = true, onLeave = true): AnimationTriggerMetadata {
        return trigger(
            triggerName,
            [
                onEnter ? transition(':enter', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]) : undefined,
                onLeave ? transition(':leave', [style({ opacity: 1 }), animate(duration, style({ opacity: 0 }))]) : undefined,
            ].filter((x) => x)
        );
    }

    public static fadeByState(triggerName: string, duration: string | number): AnimationTriggerMetadata {
        return trigger(triggerName, [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('false <=> true', animate(duration)),
        ]);
    }

    public static fadeBackground(triggerName: string, duration: string | number, initialColor: ColorScale): AnimationTriggerMetadata {
        return trigger(triggerName, [
            transition(':enter', [
                style({ opacity: 0, backgroundColor: initialColor }), // initial
                animate(duration, style({ opacity: 1, backgroundColor: 'initial' })), // final
            ]),
        ]);
    }
}
