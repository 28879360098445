import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URI_CONFIG, BaseUriConfig, FeatureScope, HttpUtils, LogicalOperation, StringUtils, SuccessfulResponse } from '@smooved/core';
import * as uri from '../constants/uri.constants';
import { map } from 'rxjs/operators';
import { FeatureScopeProvidersUiModule } from '../feauture-scope-providers.module';
import { FeatureScopeState } from '../state/feature-scope.reducer';

@Injectable({
    providedIn: FeatureScopeProvidersUiModule,
})
export class FeatureScopeService {
    private readonly baseUrl = `${this.baseUriConfig.apiUri}/${uri.baseUri}`;
    private readonly hasAccessToFeatureScopesUrl = `${this.baseUrl}/real-estate-agents/:realEstateAgentId/access`;

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig
    ) {}

    public get(): Observable<FeatureScopeState> {
        return this.httpClient.get<FeatureScopeState>(`${this.baseUrl}`);
    }

    public hasAccessToFeatureScopes(
        realEstateAgentId: string,
        featureScopes: FeatureScope[],
        operator: LogicalOperation
    ): Observable<boolean> {
        return this.httpClient
            .get<SuccessfulResponse<boolean>>(StringUtils.parseUri(this.hasAccessToFeatureScopesUrl, { realEstateAgentId }), {
                params: HttpUtils.buildQueryParameters({
                    featureScopes,
                    operator,
                }),
            })
            .pipe(map((response) => response.data));
    }
}
