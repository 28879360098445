import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxjsService } from '@smooved/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroupCluster } from '../form/from-group-cluster';
import { WizardStep } from './wizard-step.interface';
import { WizardConfig } from './wizard.constants';

@Injectable()
export abstract class WizardService extends RxjsService {
    protected readonly showNavigationSubject = new BehaviorSubject<boolean>(false);
    protected readonly submittedSubject = new BehaviorSubject<boolean>(false);

    public steps: WizardStep[] = [];
    public currentStep: WizardStep;
    public activatedSteps: WizardStep[] = [];

    public form: UntypedFormGroup;
    public baseRouting: string[];
    public currentFormGroupCluster: FormGroupCluster;

    public stepsToExcludeFromStepper: WizardStep[] = [];

    public abstract readonly loading$: Observable<boolean>;
    public readonly showNavigation$ = this.showNavigationSubject.asObservable();
    public readonly submitted$ = this.submittedSubject.asObservable();

    constructor(protected readonly router: Router, protected location: Location) {
        super();
    }

    public setConfig(config: WizardConfig): void {
        this.setBaseRoute(config.baseRoute);
        this.setSteps(config.steps);
        this.setStepsToExcludeFromStepper(config.stepsToExcludeFromStepper);
        this.activatedSteps.push(config.steps[0]); // activate start step
    }

    public setCurrentStep(step: WizardStep, formGroupsToValidate: string[] = []): void {
        if (this.steps.includes(step)) {
            this.currentStep = step;
            this.activateStep(step);
        }
        this.currentFormGroupCluster = new FormGroupCluster();
        formGroupsToValidate?.forEach((group): void => this.currentFormGroupCluster.add(group, this.form.get(group)));
    }

    public getStepIndex(wizardStep: WizardStep): number {
        return this.steps.findIndex((step) => step === wizardStep);
    }

    public getStepAt(index: number): WizardStep {
        return this.steps[index];
    }

    public setSteps(steps: WizardStep[], start?: WizardStep): void {
        this.steps = steps;
        this.currentStep = start ?? steps[0];
    }

    public setStepsToExcludeFromStepper(steps: WizardStep[]): void {
        this.stepsToExcludeFromStepper = steps || [];
    }

    public setBaseRoute(route: string[]): void {
        this.baseRouting = route;
    }

    public getStepsForStepper(): WizardStep[] {
        return this.steps.filter((step) => !this.stepsToExcludeFromStepper.includes(step));
    }

    public getCurrentStepperIndex(): number {
        return this.getStepsForStepper().findIndex((step) => step === this.currentStep);
    }

    public getCurrentStepIndex(): number {
        return this.getStepIndex(this.currentStep);
    }

    public getPageAt(index: number): string[] {
        return [...this.baseRouting, this.steps[Math.max(0, index)]];
    }

    public getPage(wizardStep: WizardStep): string[] {
        const index = this.steps.findIndex((step) => step === wizardStep);
        return this.getPageAt(index);
    }

    public getNextPage(steps = 1): string[] {
        return this.getPageAt(this.getCurrentStepIndex() + steps);
    }

    public getPreviousPage(steps = 1): string[] {
        return this.getPageAt(this.getCurrentStepIndex() - steps);
    }

    public getNextStep(steps = 1): string {
        return this.getStepAt(this.getCurrentStepIndex() + steps);
    }

    public getPreviousStep(steps = 1): string {
        return this.getStepAt(this.getCurrentStepIndex() - steps);
    }

    public skip(steps = 1, forward: boolean = true): void {
        forward ? this.goToNext(steps + 1) : this.goToPrevious(steps + 1);
    }

    public goToNext(steps = 1): void {
        this.currentFormGroupCluster.markAllAsTouched();
        if (this.currentFormGroupCluster.invalid || this.currentFormGroupCluster.disabled) return;
        this.goToStep(this.getNextStep(steps));
    }

    public goToPrevious(steps = 1): void {
        this.goToStep(this.getPreviousStep(steps));
    }

    public goToStep(wizardStep: WizardStep): void {
        this.activateStep(wizardStep);
        void this.router.navigate(this.getPage(wizardStep));
    }

    public isStepDisabled(wizardStep: WizardStep): boolean {
        return !this.isActivatedStep(wizardStep);
    }

    // Placeholder function for wizardService submit logic
    public onSubmit(param: any = null): void | Observable<void | boolean> {
        this.submittedSubject.next(true);
        /** */
    }

    // Placeholder function for wizardService initiation logic
    public initialize(): void {
        /** */
    }

    // Placeholder function for wizardService reset logic
    public reset(): void {
        /** */
    }

    // Placeholder function for canActivate
    public canActivate(): Observable<boolean> {
        return of(true);
    }

    public showNavigation(show = true): void {
        this.showNavigationSubject.next(show);
    }

    public getData<T>(): T {
        return this.form.value;
    }

    public isActivatedStep(wizardStep: WizardStep): boolean {
        return this.activatedSteps.some((step) => step === wizardStep);
    }

    public isSubmitted(): boolean {
        return this.submittedSubject.value;
    }

    private activateStep(wizardStep: WizardStep): void {
        if (!this.isActivatedStep(wizardStep)) this.activatedSteps.push(wizardStep);
    }
}
