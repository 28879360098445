import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReviewSource } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'smvd-ui-review-verification-badge',
    templateUrl: 'review-verification-badge.component.html',
    styleUrls: ['review-verification-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewVerificationBadgeComponent implements OnInit {
    @Input() isVerified = true;
    @Input() reviewSource?: ReviewSource;
    @Input() locationOrGroupName: string;

    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly iconAppearance = UiIconAppearance;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly iconSize = UiIconSize;
    public icon: string;
    public context: string;
    public appearance: string;

    ngOnInit(): void {
        this.icon = this.checkSmoovedSource() ? 'verified' : 'gpp_maybe';
        this.context = this.checkSmoovedSource() ? this.uiContext.Success : this.uiContext.LightGray;
        this.appearance = this.isVerified ? this.iconAppearance.Filled : this.iconAppearance.Outlined;
    }

    checkSmoovedSource(): boolean {
        return this.reviewSource === ReviewSource.Smooved;
    }
}
