import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-wizard',
    template: ``,
    styles: [],
})
export class WizardComponent {
    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<void> = new EventEmitter<void>();
}
