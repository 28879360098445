<app-closable-modal-template maxWidth="none">
    <ng-container modal-header>{{ 'MOVE.GIFT.SEND_GIFT_MODAL.TITLE' | translate }}</ng-container>

    <app-loading-container [loading]="uiSandbox.moveLoading$ | async">
        <header class="u-bordered-bottom u-margin-bottom-double u-padding-bottom-double">
            <h6 class="u-color-muted u-margin-bottom">{{ 'MOVE.GIFT.SEND_GIFT_MODAL.NPS.TITLE' | translate }}</h6>
            <app-move-nps-output *ngIf="!!move?.surveys?.nps; else empty"
                                 [nps]="move?.surveys?.nps"></app-move-nps-output>
            <ng-template #empty>-</ng-template>
        </header>

        <form (ngSubmit)="onSubmit()" [formGroup]="form" class="u-flex-row u-flex-align-items-start">
            <app-gift-input
                [formControlName]="formControlNames.gift"
                [label]="'GIFT' | translate"
                [move]="move"
                class="u-margin-right-quadruple"
            ></app-gift-input>
            <div class="u-container-small u-flex-column">
                <div class="u-flex">
                    <app-text-input
                        [formControlName]="formControlNames.firstName"
                        [label]="'UI.FIRST_NAME.LABEL' | translate"
                        class="u-margin-right"
                        width="100%"
                    ></app-text-input>
                    <app-text-input
                        [formControlName]="formControlNames.lastName"
                        [hasMarginDouble]="true"
                        [hasMargin]="false"
                        [label]="'UI.LAST_NAME.LABEL' | translate"
                        width="100%"
                    ></app-text-input>
                </div>
                <app-address
                    [formControlName]="formControlNames.deliveryAddress"
                    class="u-margin-bottom-double"
                    labelResource="DELIVERY_ADDRESS"
                ></app-address>
                <div class="u-margin-bottom-double">
                    <app-date-input
                        [formControlName]="formControlNames.deliveryDate"
                        [hasMarginDouble]="false"
                        [hasMargin]="false"
                        [label]="'DELIVERY_DATE_FROM' | translate"
                        [minDate]="minimumDeliveryDate"
                        class="u-margin-bottom-half"
                    ></app-date-input>
                    <p class="u-color-muted u-font-size-small">{{ 'MOVE.GIFT.SEND_GIFT_MODAL.FORM.DELIVERY_DATE.EXTRA' | translate }}</p>
                </div>
                <div class="u-margin-bottom-double">
                    <app-textarea-input
                        [formControlName]="formControlNames.note"
                        [hasMarginDouble]="false"
                        [hasMargin]="false"
                        [label]="'PARTNER.GIFT.NOTE' | translate"
                        [rows]="8"
                        width="100%"
                    ></app-textarea-input>
                    <p class="u-color-muted u-font-size-small">{{ 'MOVE.GIFT.SEND_GIFT_MODAL.FORM.NOTE.EXTRA' | translate }}</p>
                </div>
                <div class="u-text-align-right">
                    <app-button type="submit">{{ 'MOVE.GIFT.SEND_GIFT_MODAL.FORM.CTA' | translate }}</app-button>
                </div>
            </div>
        </form>
    </app-loading-container>
</app-closable-modal-template>
