<div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between" [formGroup]="billingForm">
    <smvd-ui-dropdown-input
        [options]="billingCycleOptions"
        class="u-margin-right"
        [formControlName]="billingSettingsNames.BillingCycle"
        [label]="'REAL_ESTATE_GROUP.BILLING_SETTINGS.LABEL' | translate"
    ></smvd-ui-dropdown-input>
    <app-date-input
        [formControlName]="billingSettingsNames.BillingStartDate"
        [label]="'REAL_ESTATE_GROUP.BILLING_SETTINGS.START_DATE' | translate"
    ></app-date-input>
</div>
