import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RealEstateGroupAnalytics } from '@app/real-estate-group/interfaces/real-estate-group-analytics';
import { CardContext } from '@smooved/ui';
import {
    dashboardRoute,
    financialReportRoute,
    todosQueryParams,
    unreachableQueryParams,
} from '../analytics-dashboard/analytics-dashboard.constants';

@Component({
    selector: 'app-analytics-attention-required',
    templateUrl: 'analytics-attention-required.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsAttentionRequiredComponent {
    @Input() public analytics: RealEstateGroupAnalytics;

    public readonly cardContext = CardContext;
    public readonly dashboardRoute = dashboardRoute;
    public readonly financialReportRoute = financialReportRoute;
    public readonly todosQueryParams = todosQueryParams;
    public readonly unreachableQueryParams = unreachableQueryParams;
}
