<app-card-container>
    <div class="u-container-small u-margin-0-auto">
        <app-ui-card [header]="'REAL_ESTATE_AGENT.OFFICES_OVERVIEW.TITLE' | translate">
            <ng-container card-header-right>
                <app-button (onClick)="onCreate()">{{ 'ADD' | translate }}</app-button>
            </ng-container>
            <table matSort matSortDirection="desc" matSortDisableClear mat-table [dataSource]="dataSource" class="u-w100p">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'NAME' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                </ng-container>

                <ng-container matColumnDef="realEstateAgents">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MEMBERS' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{ row.realEstateAgents }}</td>
                </ng-container>

                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>
        </app-ui-card>
    </div>
</app-card-container>
