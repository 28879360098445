import { Injectable } from '@angular/core';
import { FeatureExecutionScopeUtils } from '@app/feature-scope/sandboxes/feature-execution-scope.utils';
import { FeaturePermission } from '@app/feature-scope/sandboxes/feature-permission.interface';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { FeatureScope, FeatureScopeSandbox, RxjsService } from '@smooved/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeaturePermissionsSandbox extends RxjsService {
    constructor(
        private readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {
        super();
    }

    public getPermissions$(featureScope: FeatureScope, move?: Move): Observable<FeaturePermission> {
        return this.moveScopes$(!move ? this.moveSandbox.moveOnce$ : of(move)).pipe(map(this.validatePermission(featureScope)));
    }

    public featureAvailable$(featureScope: FeatureScope, move?: Move): Observable<boolean> {
        return this.getPermissions$(featureScope, move).pipe(map(({ hasPermission, canExecute }) => hasPermission && canExecute));
    }

    public featureGranted$(featureScope: FeatureScope, move?: Move): Observable<boolean> {
        return this.getPermissions$(featureScope, move).pipe(map(({ hasPermission }) => hasPermission));
    }

    public featureBlocked$(featureScope: FeatureScope, move?: Move): Observable<boolean> {
        return this.featureGranted$(featureScope, move).pipe(map((hasPermission) => !hasPermission));
    }

    private validatePermission(featureScope: string) {
        return ([featureScopes, featureExecutionScopes]: [FeatureScope[], FeatureScope[]]): FeaturePermission => {
            const hasPermission = featureScopes.some((f) => f.startsWith(featureScope));
            const canExecute = hasPermission && featureExecutionScopes.some((f) => f.startsWith(featureScope));

            return featureScope === FeatureScope.Eots
                ? this.permissionFactory(true, canExecute)
                : this.permissionFactory(hasPermission, canExecute);
        };
    }

    private moveScopes$(move$: Observable<Move>): Observable<[FeatureScope[], FeatureScope[]]> {
        return combineLatest([this.featureScopeSandbox.featureScopes$, move$, this.featureScopeSandbox.featureScopesConfig$]).pipe(
            filter(([x, y, z]) => !!x && !!y && !!z),
            map(this.getScopes),
            take(1)
        );
    }

    private permissionFactory(hasPermission: boolean, canExecute: boolean): FeaturePermission {
        return { hasPermission, canExecute };
    }

    private getScopes = ([featureScope, move, featureScopeConfig]): [FeatureScope[], FeatureScope[]] => {
        return [featureScope, FeatureExecutionScopeUtils.getFeatureExecutionScope(move, featureScopeConfig)];
    };
}
