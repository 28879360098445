import { Injectable } from '@angular/core';
import { fromDate } from '@app/analytics/constants/analytics.constants';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { PerUnitOfTime } from '@app/shared/enums/per-unit-time.enum';
import { DateUtils, quarterYearIntervalFormat } from '@smooved/core';
import { ChartItem } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as constants from './analytics-moves-count-by-created-flow-chart.constants';

@Injectable()
export class AnalyticsMovesCountByCreatedFlowChartService {
    constructor(private smoovedAnalytics: SmoovedAnalyticsSandbox) {}

    public getData(): Observable<ChartItem[]> {
        return this.smoovedAnalytics.getMoveCountByCreatedFlow(PerUnitOfTime.Quarter, fromDate).pipe(
            map((movesByCreatedFlowPerQuarter) =>
                movesByCreatedFlowPerQuarter.map((item) => ({
                    label: DateUtils.tz(item.date).format(quarterYearIntervalFormat),
                    value: item.ots + item.lp,
                    stacks: {
                        ots: { label: constants.labels.onTheSpot, value: item.ots },
                        lp: { label: constants.labels.leadPassing, value: item.lp },
                    },
                }))
            )
        );
    }
}
