import { Language } from '@smooved/core';
import { merge } from 'lodash';

export interface Translations<T> {
    [Language.EN]: T;
    [Language.FR]: T;
    [Language.NL]: T;
}

export function translationsFactory<T>(en: any, fr: any, nl: any): Translations<T> {
    return {
        [Language.EN]: en,
        [Language.FR]: fr,
        [Language.NL]: nl,
    };
}

export function mergeTranslations(...translations: any[]): any {
    return merge({}, ...translations);
}
