<div class="__caption u-margin-bottom-half">
    <div class="__title">
        <ng-container *ngIf="access">
            <ng-container *ngIf="access === accessLevels.Internal; else public">
                <span class="__access">{{ 'COMMON.NOTES.INTERNAL' | translate }}</span>
            </ng-container>
            <ng-template #public>
                <span class="__access">{{ 'COMMON.NOTES.PUBLIC' | translate }}</span>
            </ng-template>
            <button
                app-button
                *ngIf="canEdit"
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Icon"
                icon="edit"
                class="u-display-inline-block u-padding-none u-font-size-small edit u-color-gray-darkest"
                (click)="onEdit()"
            >
                {{ 'EDIT' | translate }}
            </button>
        </ng-container>
    </div>
    <div>
        <ng-container *ngIf="access">
            <span class="__createdOn">{{ createdOn | formatDate : dateTimeFormat }}</span>
        </ng-container>
        <app-icon icon="person_outline" *ngIf="!!author" [size]="iconSize.Md"></app-icon>
        <span class="__createdBy">
            <ng-container *ngIf="!!author">{{
                'NOTES_AUTHOR' | translate : innerAuthorInterpolationParams | authorCaption : author
            }}</ng-container>
        </span>
    </div>
</div>
<ng-content></ng-content>
