import { Component } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { HeaderService } from '@app/header/services/header.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { MenuItemIconSize, SvgIllustration } from '@smooved/ui';

@Component({
    selector: 'app-reviews-menu',
    templateUrl: './reviews-menu.component.html',
    styles: [':host {display: block; padding: 1rem;}'],
})
export class ReviewsMenuComponent {
    public readonly svgIllustration = SvgIllustration;
    public readonly menuItemIconSize = MenuItemIconSize;
    public navigation = Navigation;

    constructor(
        public readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        private readonly headerService: HeaderService,
        public readonly authenticationSandbox: AuthenticationSandbox
    ) {}

    public onMenuClick(): void {
        this.headerService.dispatch();
    }
}
