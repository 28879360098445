<div *ngIf="surveyUri">
    <div [innerHtml]="'COPY_LINK.DESCRIPTION' | i18nKeyTranslate: i18nKeys.RealEstateAgentSurveyInvite"></div>
    <div class="u-flex-responsive u-flex-align-items-center u-margin-top">
        <smvd-ui-text-input
            [hasMargin]="false"
            [(ngModel)]="surveyUri"
            [disabled]="true"
            class="u-margin-bottom-up-to-and-including-tablet-portrait"
        ></smvd-ui-text-input>
        <button smvd-ui-button (click)="copyLink()" class="u-margin-left-half-tablet-portrait-up">
            {{ 'COPY_LINK.CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentSurveyInvite }}
        </button>
    </div>
</div>
