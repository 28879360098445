import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EscalationProvidersModule } from '@app/escalation/escalation-providers.module';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { EscalationModal } from '@app/escalation/modals/escalation/escalation.modal';
import { EscalationService } from '@app/escalation/services/escalation.service';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils } from '@smooved/core';
import { deleteConfirmation, ModalSandbox } from '@smooved/ui';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { logActionTitleResource, modalTitleResource } from '../constants/escalation.constants';
import { LogActionModal } from '../modals/log-action/log-action.modal';

@Injectable({ providedIn: EscalationProvidersModule })
export class EscalationModalService {
    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly translateService: TranslateService,
        private readonly moveSandbox: MoveSandbox,
        private readonly escalationService: EscalationService
    ) {}

    public create(): void {
        zip(this.moveSandbox.moveOnce$, this.authenticationSandbox.userIdOnce$)
            .pipe(take(1))
            .subscribe(([move, userId]) => {
                this.modalSandbox.openClosableModal({
                    component: EscalationModal,
                    config: {
                        header: <string>this.translateService.instant(modalTitleResource),
                        input: {
                            move,
                            userId,
                        },
                    },
                    afterClosed: this.afterClosed,
                });
            });
    }

    public update(escalation: Escalation): void {
        this.modalSandbox.openClosableModal({
            component: EscalationModal,
            config: {
                header: <string>this.translateService.instant(modalTitleResource),
                input: {
                    escalation,
                },
                onDelete: (modalRef: MatDialogRef<EscalationModal>) => this.deleteEscalation(modalRef, escalation),
            },
            afterClosed: this.afterClosed,
        });
    }

    public openLogAction(escalation: Escalation): void {
        this.modalSandbox.openClosableModal({
            component: LogActionModal,
            config: {
                header: <string>this.translateService.instant(logActionTitleResource),
                input: {
                    escalation,
                },
            },
            afterClosed: this.afterClosed,
        });
    }

    private deleteEscalation = (modalRef: MatDialogRef<EscalationModal>, escalation: Escalation): void => {
        const data = <string>this.translateService.instant(deleteConfirmation);
        this.modalSandbox.openConfirmModal(
            { data },
            this.deleteHandler(modalRef, escalation),
            { data },
            this.deleteHandler(modalRef, escalation)
        );
    };

    private deleteHandler = (modalRef: MatDialogRef<EscalationModal>, escalation: Escalation) => {
        return (confirmation: boolean): void => {
            if (!confirmation) return;
            this.escalationService.deleteEscalation(DbUtils.getStringId(escalation)).subscribe(() => {
                modalRef.close(true);
            });
        };
    };

    private afterClosed = (updated: boolean): void => {
        if (!updated) return;
        this.moveSandbox.updatedMoveTrigger.next();
    };
}
