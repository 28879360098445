import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { OrderUtils } from '@app/order/utils/order-utils';
import { planned } from '@shared/order/constants/properties.constants';
import { dateTimeFormatDefault, SimpleChangesUtils } from '@smooved/core';
import { TodoUtils, TrackUtils } from '@smooved/ui';

@Component({
    selector: 'app-check-item-rental-inspection-planned',
    templateUrl: './check-item-rental-inspection-planned.component.html',
    styleUrls: ['./check-item-rental-inspection-planned.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemRentalInspectionPlannedComponent implements OnChanges, CanDisable {
    @Input() public order: OrderRentalInspection;
    @Input() public readonly: boolean;
    @Input() public disabled: boolean;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    public checked: boolean;
    public orderCompleted: boolean;
    public lastModified: Date;

    public dateTimeFormat = dateTimeFormatDefault;
    public input = new UntypedFormControl(null, Validators.required);

    public set(value: Date): void {
        if (value) {
            this.patch.emit({
                [planned]: value,
            });
        }
    }

    public ngOnChanges({ order }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(order)) {
            this.checked = TodoUtils.isChecked(this.order.track.planned.state);
            this.orderCompleted = OrderUtils.isCompleted(this.order);
            this.lastModified = TrackUtils.lastModifiedOn(this.order.track.planned);
        }
    }

    public reset(): void {
        this.checked = false;
    }
}
