import { MailFields } from '@app/admin/enums/mail-fields.enum';
import { AddresseesPreData } from '@app/email/interfaces/addressees-pre-data';
import { EscalationState } from '@app/escalation/enums/state.enum';

export const editorModules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
    ],
};

export interface CustomMailForm {
    [MailFields.to]: AddresseesPreData;
    [MailFields.cc]?: AddresseesPreData;
    [MailFields.bcc]?: AddresseesPreData;
    [MailFields.content]: string;
    [MailFields.subject]: string;
    [MailFields.escalation]?: string;
    [MailFields.state]?: EscalationState;
}
