import { Clipboard } from '@angular/cdk/clipboard';
import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-copy-to-clipboard',
    template: `
        <div (click)="copy($event)" class="__container u-position-relative u-padding-right-triple" [class.nocopy]="nocopyInnerModel">
            <div #content class="__content" [class.u-white-space-pre-wrap]="keepNewLines" (cdkObserveContent)="onContentChange()">
                <ng-content></ng-content>
            </div>
            <mat-icon class="__icon u-flex-align-items-center u-flex-justify-content-center"> content_copy</mat-icon>
        </div>
    `,
    styleUrls: ['./copy-to-clipboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent implements AfterContentInit {
    @ViewChild('content', { static: true }) public content: ElementRef;
    @Input() public keepNewLines = false;
    @Input() public export: string;
    @Input() public keepRichText = false;
    @Input() public nocopy: boolean;

    public nocopyInnerModel: boolean;

    constructor(private notificationSandbox: NotificationSandbox, private clipboard: Clipboard) {}

    public ngAfterContentInit(): void {
        this.setCopyState();
    }

    public copy(event: MouseEvent): void {
        if (this.nocopyInnerModel) {
            return;
        }
        this.copyToClipboard(event);
    }

    public copyToClipboard(event: MouseEvent, asRichText?: boolean): void {
        this.notificationSandbox.success('COPIED_TO_CLIPBOARD');
        asRichText || this.keepRichText ? this.getRichTextToCopy(event) : this.clipboard.copy(this.getContentToCopy());
    }

    private getContentToCopy(): string {
        return this.export || this.content?.nativeElement.innerText.replace(/\s+/g, ' ');
    }

    private getRichTextToCopy(e): void {
        const str = this.content?.nativeElement.innerHTML as string;

        function listener(e): void {
            e.clipboardData.setData('text/html', str);
            e.clipboardData.setData('text/plain', str);
            e.preventDefault();
        }

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }

    public onContentChange(): void {
        this.setCopyState();
    }

    private setCopyState(): void {
        this.nocopyInnerModel = this.nocopy ?? !this.content?.nativeElement.innerText.trim();
    }
}
