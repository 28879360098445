import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';

export const formControlNames = {
    package: 'package',
    billingSettings: 'billingSettings',
};

export interface EditPackageModalData {
    realEstateGroup: RealEstateGroup;
    onUpdateHandler: (realEstateGroup: RealEstateGroup) => void;
}
