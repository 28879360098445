import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ClosableModalTemplateComponent, ModalSandbox } from '@smooved/ui';
import { deleteConfirmationQuestionI18nKey, FormField } from './note-modal.constants';
import { NoteModalData, NoteModalDataOutput } from './note-modal.interfaces';

@Component({
    selector: 'app-note-modal',
    templateUrl: './note-modal.component.html',
})
export class NoteModalComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true }) public closableModalComponent: ClosableModalTemplateComponent;

    public form: UntypedFormGroup;
    public readonly formFields = FormField;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: NoteModalData
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            [FormField.Id]: this.data.note?._id,
            [FormField.Note]: [this.data.note?.value, Validators.required],
            [FormField.IsPublic]: this.data.isPublic,
        });
    }

    public submit(): void {
        if (this.form.valid) {
            this.closableModalComponent.close(this.outputFactory());
        } else {
            this.form.markAllAsTouched();
        }
    }

    public delete(): void {
        const data = this.translate.instant(deleteConfirmationQuestionI18nKey) as string;
        this.modalSandbox.openConfirmModal({ data }, this.handleDeleteConfirmation, { data }, this.handleDeleteConfirmation);
    }

    private outputFactory(): NoteModalDataOutput {
        return {
            noteId: this.data.note?._id,
            isPublic: this.form.get(FormField.IsPublic).value as boolean,
            noteValue: this.form.get(FormField.Note).value as string,
        };
    }

    private handleDeleteConfirmation = (confirmed: boolean) => {
        if (confirmed) {
            this.closableModalComponent.close({ ...this.outputFactory(), isDelete: true });
        }
    };
}
