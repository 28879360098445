import { veryLargeNumber } from '../constants';
import { NumberUtils } from './number.utils';

export class RegexUtils {
    public static matchAtLeastOneLowerCase(str: string): boolean {
        return !!str.match(/^(?=.*?[a-z]).{1,}$/)?.length;
    }

    public static matchAtLeastOneUpperCase(str: string): boolean {
        return !!str.match(/^(?=.*?[A-Z]).{1,}$/)?.length;
    }

    public static matchAtLeastOneDecimal(str: string): boolean {
        return !!str.match(/^(?=.*?[0-9]).{1,}$/)?.length;
    }

    public static matchAtLeastSpecialChar(str: string): boolean {
        return !!str.match(/^(?=.*?[#?!@$%^&*-]).{1,}$/)?.length;
    }

    public static matchMinMaxChars(str: string, min = 1, max?: number): boolean {
        return NumberUtils.isBetween(str.length, min, max || veryLargeNumber);
    }

    public static isValidEmail(str: string): boolean {
        if (!str) return false;
        return !!str.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    }
}
