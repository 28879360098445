import { Component, ViewChild } from '@angular/core';
import { ClosableModalTemplateComponent } from '@smooved/ui';

@Component({
    selector: 'app-info-modal',
    template: `
        <app-closable-modal-template maxWidth="auto">
            <ng-container modal-header>
                <ng-content select="[modal-header]"></ng-content>
            </ng-container>
            <ng-content></ng-content>
        </app-closable-modal-template>
    `,
    styleUrls: ['./info.modal.scss'],
})
export class InfoModalComponent {
    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;
}
