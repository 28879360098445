<app-todo
    *ngIf="move"
    [label]="
        'SUPPLIERS_ADDED'
            | i18nKeyTranslate
                : i18nKeys.ServiceAction
                : { name: (moverRole.Leaver | i18nKeyTranslate: i18nKeys.MoverRole).toLocaleLowerCase() }
    "
    [state]="move.track.leaver.suppliersInfoReceived"
>
    <app-todo-template *todoStateUnchecked>
        <ng-container todo-cta *ngIf="move.track.leaver.created">
            <ui-dropdown-menu-button
                [label]="'ADD' | translate"
                [size]="buttonSize.Sm"
                [appearance]="buttonAppearance.Stroked"
                [context]="uiContext.Secondary"
            >
                <button mat-menu-item uiDropdownMenuItem (click)="updateSuppliers()">
                    {{ 'ADD_BY_YOURSELF' | translate }}
                </button>
                <button mat-menu-item uiDropdownMenuItem (click)="resendInvite()">
                    {{ 'RESEND_INVITE' | translate }}
                </button>
            </ui-dropdown-menu-button>
        </ng-container>
    </app-todo-template>
</app-todo>
