import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { RealEstateAgentOffice } from '@app/real-estate-agent/interfaces/real-estate-agent-office';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { DbUtils, Role } from '@smooved/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-energy-offer-suggestion',
    template: `
        <app-check-item [checked]="!!move?.energyOffer?.energyOfferSelection">
            <div ngProjectAs="checked">
                <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.EOTS_BY_REAL_ESTATE_AGENT' | translate }}</span>
                <span *ngIf="triggeredByRealEstateAgent()"
                    >&nbsp;{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.BY' | translate }} {{ (office$ | async)?.name }}</span
                >
                <div class="u-color-muted u-font-size-small">
                    ({{
                        (!!move?.requestWithoutMover
                            ? 'ADMIN.DASHBOARD.DETAIL.SIMULATION.SOLO'
                            : 'ADMIN.DASHBOARD.DETAIL.SIMULATION.SIDE_BY_SIDE'
                        ) | translate
                    }})
                </div>
            </div>
            <div ngProjectAs="unchecked">
                <span>{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.SELECTION_NOT_FOUND' | translate }}</span>
                <div class="u-margin-top-half">
                    <app-button [theme]="'secondary'" (onClick)="impersonate()">{{
                        'ADMIN.DASHBOARD.DETAIL.ENERGY.CREATE' | translate
                    }}</app-button>
                </div>
            </div>
        </app-check-item>
    `,
})
export class EnergyOfferSuggestionComponent extends BaseServiceActionComponent implements OnChanges {
    public office$: Observable<RealEstateAgentOffice>;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        public readonly dialogRef: MatDialogRef<any>
    ) {
        super(null, moveSandbox);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (
            !changes.move ||
            !this.triggeredByRealEstateAgent() ||
            changes.move.previousValue?.realEstateAgent === changes.move.currentValue?.realEstateAgent ||
            !this.move?.realEstateAgent
        )
            return;
        this.office$ = this.realEstateGroupSandbox.getOffice(DbUtils.getStringId(this.move.realEstateAgent));
    }

    public impersonate() {
        this.moveSandbox.impersonate(DbUtils.getStringId(this.move), this.dialogRef).subscribe(() => {
            this.navigationSandbox.goToEnergyFlow();
        });
    }

    public triggeredByRealEstateAgent(): boolean {
        return !!this.move?.createdByFlow?.eots && this.move?.createdByRole === Role.RealEstateAgent && !!this.move?.realEstateAgent;
    }
}
