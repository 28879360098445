import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationErrorComponent } from './components/notification-error/notification-error.component';
import { NotificationSuccessComponent } from './components/notification-success/notification-success.component';
import { NotificationWarningComponent } from './components/notification-warning/notification-warning.component';
import { NotificationSandbox } from './notification.sandbox';

@NgModule({
    declarations: [NotificationSuccessComponent, NotificationErrorComponent, NotificationWarningComponent],
    imports: [CommonModule, MatSnackBarModule, TranslateModule],
    providers: [NotificationSandbox],
})
export class NotificationModule {}
