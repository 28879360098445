import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReviewsData } from '@smooved/core';
import { SvgIllustration } from '../../../svg';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'smvd-ui-match-star-score',
    templateUrl: 'match-star-score.component.html',
    styleUrls: ['match-star-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchStarScoreComponent {
    @Input() public meta: ReviewsData;

    public readonly uiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;
}
