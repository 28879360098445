import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { energyOrderSupplierSuccess } from '@app/admin/components/energy-order/energy-order.constants';
import { EnergyOrderInformationComponent } from '@app/energy/components/energy-order-information/energy-order-information.component';
import { titleResource } from '@app/energy/components/energy-order-information/energy-order-information.constants';
import { Move } from '@app/move/interfaces/move';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox, NotificationSandbox, UiSize } from '@smooved/ui';

@Component({
    selector: 'app-energy-order',
    template: ` <app-button (onClick)="onOrder()" theme="secondary">{{ 'ORDER' | translate }}</app-button>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyOrderComponent {
    @Input() public move: Move;
    @Output() public updateMove: EventEmitter<Move> = new EventEmitter();

    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly translateService: TranslateService,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public onOrder(): void {
        const config = {
            header: this.translateService.instant(titleResource) as string,
            input: {
                move: this.move,
            },
            size: UiSize.Md,
        };
        this.modalSandbox.openClosableModal({
            component: EnergyOrderInformationComponent,
            config,
            afterClosed: this.afterPatch,
        });
    }

    private afterPatch = (move: Move): void => {
        if (!move) return;
        this.uiSandbox.moveLoading(false);
        this.notificationSandbox.success(energyOrderSupplierSuccess);
        this.updateMove.emit(move);
    };
}
