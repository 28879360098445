import { Injectable } from '@angular/core';

@Injectable()
export class EnumUtils {
    public static map<T>(enumeration: any): Map<string, T> {
        const map = new Map<string, T>();
        Object.keys(enumeration).forEach((key) => {
            if (!isNaN(Number(key))) return;
            const val = enumeration[key] as T;
            map.set(key, val);
        });
        return map;
    }

    public static values<T>(enumerator: any): T[] {
        return Array.from(EnumUtils.map<T>(enumerator).values());
    }

    public static keys(enumerator: any): string[] {
        return Array.from(EnumUtils.map<string>(enumerator).keys());
    }

    public static entries<T>(enumerator: any): [string, T][] {
        return Array.from(EnumUtils.map<T>(enumerator).entries());
    }
}
