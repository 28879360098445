import { Injectable } from '@angular/core';
import { ObjectUtils, ReviewSource } from '@smooved/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ReviewBucketRequest } from '../../analytics';
import { ExternalInfluencerService } from '../../company-page/services/external-influencer.service';
import { ReviewBucketsType } from '../components/reviews-buckets/reviews-buckets.enum';
import { ReviewsProvidersModule } from '../reviews-providers.module';
import { AnalyticsService, NpsReviewsFilterService } from '../services';

@Injectable({
    providedIn: ReviewsProvidersModule,
})
export class ReviewsSandbox {
    constructor(
        private readonly analyticsService: AnalyticsService,
        private readonly externalInfluencerService: ExternalInfluencerService,
        private readonly filterService: NpsReviewsFilterService
    ) {}

    public getReviewBuckets(): Observable<ReviewBucketRequest> {
        const { realEstateGroup } = this.externalInfluencerService.externalInfluencerSubject.value;
        return this.filterService.filter$.pipe(switchMap(({ filter }) => this.analyticsService.getReviewBuckets(realEstateGroup, filter)));
    }

    public getReviewBucketsType(): Observable<ReviewBucketsType> {
        return this.filterService.filter$.pipe(
            filter(({ emit }) => !!emit),
            map(({ filter }) =>
                ObjectUtils.isEqual(filter.source, [ReviewSource.Smooved]) ? ReviewBucketsType.Default : ReviewBucketsType.Stars
            )
        );
    }
}
