import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderListClosedTableComponent } from '@app/partner/components/order-list-closed-table/order-list-closed-table.component';
import { OrderListBaseContainer } from '@app/partner/containers/order-list-base/order-list-base.container';
import { OrderListContainerService } from '@app/partner/services/order-list-container.service';
import { OrderState } from '@smooved/core';

@Component({
    selector: 'app-order-list-closed-container',
    template: `
        <app-card-table-with-filter [hasPaddingTabletLandscapeUp]="false" [hasPaddingTabletPortraitUp]="false">
            <ng-container filter>
                <app-text-input
                    [placeholder]="'SEARCH' | translate"
                    [formControl]="orderListContainerService.searchControl"
                ></app-text-input>
            </ng-container>

            <ng-container table>
                <app-loading-container [loading]="orderListContainerService.loading$ | async">
                    <app-order-list-closed-table
                        [dataSource]="orderListContainerService.orders$ | async"
                        (rowClick)="orderListContainerService.onRowClick($event, handleOrderPatched)"
                        (pageEventChange)="fetch()"
                        (sortChange)="fetch()"
                    ></app-order-list-closed-table>
                </app-loading-container>
            </ng-container>
        </app-card-table-with-filter>
    `,
    providers: [OrderListContainerService],
})
export class OrderListClosedContainer extends OrderListBaseContainer {
    @ViewChild(OrderListClosedTableComponent, {
        static: true,
    })
    public tableComponent: OrderListClosedTableComponent;

    protected orderState = OrderState.Closed;

    constructor(public readonly orderListContainerService: OrderListContainerService, public readonly activatedRoute: ActivatedRoute) {
        super(orderListContainerService, activatedRoute);
    }
}
