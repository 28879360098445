import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-service-ordered-by-admin',
    template: `
        <div class="u-flex-row u-flex-align-items-center u-margin-bottom" [ngClass]="{ 'u-color-muted': !checked }" [ngSwitch]="checked">
            <app-check-with-background class="u-margin-right-half" *ngSwitchCase="true" width="20" height="20"></app-check-with-background>

            <app-svg [name]="emptySvg" *ngSwitchCase="false" width="24" height="24" class="u-margin-right-half"></app-svg>

            <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.ORDERED_BY_ADMIN' | translate }}</span>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceOrderedByAdminComponent {
    @Input() public checked: boolean;

    public emptySvg = Svg.Empty;
}
