<h6 class="u-margin-bottom-half u-color-muted" [innerHTML]="label" *ngIf="label"></h6>

<div class="u-margin-bottom u-flex-row u-flex-wrap u-flex-align-items-center" *ngIf="minified; else detailed">
    <ng-container *ngIf="showPreview">
        <app-thumbnail
            *ngFor="let file of assets; trackBy: trackByAssetKey"
            [file]="file"
            class="u-margin-right"
            (detail)="detail.emit($event)"
        >
            <app-icon icon="cancel" *ngIf="isAssetsRemovable" (click)="remove.emit(file)"
                      class="__remove-item u-cursor-pointer"></app-icon>
        </app-thumbnail>

        <app-thumbnail
            *ngFor="let preview of previews; trackBy: trackByAssetKey"
            [isPreview]="true"
            class="u-margin-right"
            [file]="preview"
        ></app-thumbnail>
    </ng-container>

    <ng-container *ngIf="showFileName">
        <span *ngFor="let file of assets; trackBy: trackByAssetKey">{{ file.name }}</span>
    </ng-container>

    <p *ngIf="!assets?.length && !previews?.length"
       class="u-text-truncate u-white-space-no-wrap u-color-muted u-font-size-small">
        {{ placeholder | translate }}
    </p>
</div>

<ng-template #detailed>
    <ng-container *ngIf="showPreview">
        <div *ngFor="let file of assets; trackBy: trackByAssetKey" class="u-flex-row u-margin-bottom">
            <app-thumbnail [file]="file" class="u-margin-right" (detail)="detail.emit($event)"
                           [detailed]="true"></app-thumbnail>
            <div class="u-margin-top">
                <p class="u-font-size-small u-color-muted">{{ 'COMMON.FILE' | translate }}</p>
                <p class="u-margin-bottom-half">{{ file.name }}</p>
                <button
                    smvd-ui-button
                    *ngIf="isAssetsRemovable"
                    appearance="link"
                    icon-size="'md'"
                    icon="delete"
                    (click)="remove.emit(file)"
                    class="u-padding-none"
                >
                    {{ 'COMMON.DELETE' | translate }}
                </button>
            </div>
        </div>
        <div *ngFor="let preview of previews; trackBy: trackByAssetKey" class="u-flex-row u-margin-bottom">
            <app-thumbnail [file]="preview" class="u-margin-right" (detail)="detail.emit($event)" [detailed]="true"
                           [isPreview]="true">
            </app-thumbnail>
            <div class="u-margin-top">
                <p class="u-font-size-small u-color-muted">{{ 'COMMON.FILE' | translate }}</p>
                <p class="u-margin-bottom-half">{{ preview.name }}</p>
            </div>
        </div>
    </ng-container>
</ng-template>

<app-file-input
    id="image"
    [(ngModel)]="innerModel"
    multiple="true"
    *ngIf="!readOnly"
    [accept]="acceptTypes"
    [label]="buttonLabel | translate"
    (change)="onChange($event)"
    [context]="context"
    [buttonAppearance]="buttonAppearance"
    [buttonSize]="buttonSize"
></app-file-input>

<p *ngIf="hint" class="u-margin-top u-color-muted u-font-size-sm">{{hint}}</p>
