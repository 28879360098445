export enum NavigationData {
    HeaderIsTransparent = 'headerIsTransparent',
    HasContentBottom = 'hasContentBottom',
    ShowBeamer = 'showBeamer',
    CoBranding = 'coBranding',
    TrackPageView = 'track',
    Breadcrumb = 'breadcrumb',
    Empty = 'empty',
    FeatureScopeConfig = 'featureScopeConfig',
    ShowSideBar = 'showSideBar',
}
