import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getLocations } from '../state/actions';
import { RealEstateGroupState } from '../state/reducer';
import { selectLocations } from '../state/selectors';

@Injectable()
export class RealEstateGroupLocationsService {
    public locations$ = this.store$.pipe(select(selectLocations));

    constructor(private readonly httpClient: HttpClient, private readonly store$: Store<RealEstateGroupState>) {}

    public getLocations(realEstateGroupId: string) {
        return this.store$.dispatch(getLocations({ realEstateGroupId }));
    }
}
