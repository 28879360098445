import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AuthUser, SimpleChangesUtils } from '@smooved/core';
import { Order } from '@app/order/interfaces/order';
import { MoveUtils } from '@shared/move/utils/move.utils';

@Component({
    selector: 'app-telecom-order-details',
    template: `
        <app-check-item-telecom-ordered class="u-display-block u-margin-bottom-double" [order]="order"></app-check-item-telecom-ordered>

        <app-check-item-telecom-ordered-by-admin
            class="u-display-block u-margin-bottom-double"
            [order]="order"
            [authorization]="authorization"
            (patch)="patch.emit($event)"
        ></app-check-item-telecom-ordered-by-admin>

        <app-check-item-telecom-installation-date-by-admin
            class="u-display-block u-margin-bottom-double"
            [order]="order"
            (patch)="patch.emit($event)"
        ></app-check-item-telecom-installation-date-by-admin>
        <app-check-item-telecom-phone-transfer-by-admin
            class="u-display-block u-margin-bottom-double"
            *ngIf="showTelecomPhoneTransferByAdmin"
            [authorization]="authorization"
            [order]="order"
            (patch)="patch.emit($event)"
        ></app-check-item-telecom-phone-transfer-by-admin>

        <app-textarea-input
            [label]="'PARTNER.ORDER_DETAIL.ORDER_NOTE.LABEL' | translate"
            [hasMarginDouble]="false"
            [hasMargin]="false"
            [rows]="5"
            [(ngModel)]="order.noteForSmooved"
            (ngModelChange)="orderNoteChange.emit($event)"
            [ngModelOptions]="{ updateOn: 'blur' }"
        ></app-textarea-input>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomOrderDetailsComponent implements OnChanges {
    @Input() public order: Order;
    @Input() public authorization: AuthUser;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();
    @Output() public orderNoteChange: EventEmitter<string> = new EventEmitter<string>();

    public showTelecomPhoneTransferByAdmin = false;

    public ngOnChanges({ order }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(order)) {
            this.showTelecomPhoneTransferByAdmin = MoveUtils.requiresPhoneTransfer(this.order.move);
        }
    }
}
