export enum ColType {
    Number = 'number',
    Text = 'string',
    Date = 'date',
    Currency = 'currency',
    Email = 'email',
    Phone = 'phone',
}

export const colFixPaddingEndTable = 'last-cell';
export const defaultColumnWidth = 'auto';
