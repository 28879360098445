import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewMessageFormComponent } from '@app/message/components/new-message-form/new-message-form.component';
import { ThreadComponent } from '@app/message/components/thread/thread.component';
import { TranslationModule } from '@app/translation/translation.module';
import { PipeModule as CorePipeModule } from '@smooved/core';
import { ButtonModule, PipeModule as UiPipeModule, UiFormModule, UiModalModule } from '@smooved/ui';
import { MessageComponent } from './components/message/message.component';

const components = [MessageComponent, ThreadComponent, NewMessageFormComponent];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, TranslationModule, ButtonModule, UiPipeModule, UiModalModule, UiFormModule, CorePipeModule],
    exports: [...components],
})
export class MessageModule {}
