import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { Order } from '@app/order/interfaces/order';
import { OrderUtils } from '@app/order/utils/order-utils';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { PaginationResponse, SimpleChangesUtils } from '@smooved/core';
import { Svg } from '@smooved/ui';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as tableConstants from './order-list-in-progress-table.constants';

@Component({
    selector: 'app-order-list-in-progress-table',
    templateUrl: './order-list-in-progress-table.component.html',
    styleUrls: ['./order-list-in-progress-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListInProgressTableComponent extends CoreTableComponent implements OnInit, OnChanges {
    @Input() public dataSource: PaginationResponse<Order>;
    @Output() public rowClick: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() public reload: EventEmitter<Order> = new EventEmitter<Order>();
    public tableConstants = tableConstants;
    public columns;
    public svg = Svg;
    public energyTypes = EnergyType;

    public flagDeliveryAddressAsAddress: boolean;
    public defaultSortColumn = tableConstants.columnDeliveryDate;

    public ngOnInit(): void {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => {
                const { sortDirection: direction, sortField: active } = this.getPaginationOptions();
                this.sortChange.emit({
                    sort: {
                        direction,
                        active,
                    },
                });
            });
    }

    public ngOnChanges({ dataSource }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(dataSource)) {
            this.setIndication(this.dataSource);
            this.setColumns(this.dataSource?.data?.length && this.dataSource?.data[0].scope);
        }
    }

    private setColumns(scope: string): void {
        if (OrderUtils.isOrderScopePlant(scope)) {
            this.columns = tableConstants.columnsGift;
            return;
        }

        if (OrderUtils.isOrderScopeTelenet(scope)) {
            this.columns = tableConstants.columnsTelecom;
            return;
        }

        if (OrderUtils.isOrderScopeRentalInspection(scope)) {
            this.columns = tableConstants.columnsRentalInspection;
            this.flagDeliveryAddressAsAddress = true;
            return;
        }

        this.columns = [];
    }

    private setIndication(datasource: PaginationResponse<Order>): void {
        if (datasource?.data?.length) datasource.data.forEach((row: any) => (row.indication = OrderUtils.hasWarnings(row)));
    }

    public showPhone(move: Move): boolean {
        if (!move) return false;
        return MoveUtils.requiresPhoneTransfer(move);
    }
}
