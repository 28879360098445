import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RealEstateGroupInvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { InvoicesRequest } from '@app/invoicing/interfaces/invoices-request';
import { Quarter } from '@app/invoicing/interfaces/quarter';
import { QuarterlyFees } from '@app/invoicing/interfaces/quarterly-fees';
import { QuarterlyInvoicesTotals } from '@app/invoicing/interfaces/quarterly-invoices-totals';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { QuarterlyRequest } from '@app/invoicing/interfaces/quarterly-request';
import { QuarterlyForRealEstateGroupRequest } from '@app/move/interfaces/quarterly-for-real-estate-group-request';
import { HttpUtils, ObjectUtils, PaginationResponse, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';
import { SearchParams } from '../interfaces/search-params';

@Injectable({
    providedIn: 'root',
})
export class InvoicingService {
    constructor(private readonly httpClient: HttpClient) {}

    public patch(id: string, payload: any): Observable<Invoice> {
        const params = { id };
        return this.httpClient.patch<Invoice>(StringUtils.parseUri(uri.byIdUri, params), payload);
    }

    public delete(id: string): Observable<void> {
        const params = { id };
        return this.httpClient.delete<void>(StringUtils.parseUri(uri.byIdUri, params));
    }

    public searchInvoices(params: SearchParams): Observable<Invoice[]> {
        ObjectUtils.removeEmpty(params);
        return this.httpClient.get<Invoice[]>(uri.baseUri, {
            params: {
                filter: JSON.stringify(params),
            },
        });
    }

    public getQuarter(quarterlyRequest: QuarterlyRequest): Observable<Quarter> {
        const params = { year: quarterlyRequest?.year?.toString(), quarter: quarterlyRequest?.quarter?.toString() };
        return this.httpClient.get<Quarter>(StringUtils.parseUri(uri.quarterUri, params));
    }

    public getQuarterly(quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest): Observable<QuarterlyInvoicing> {
        const { realEstateGroup, year, quarter } = quarterlyForRealEstateGroupRequest;
        const params = { realEstateGroupId: realEstateGroup, year: year?.toString(), quarter: quarter?.toString() };
        return this.httpClient.get<QuarterlyInvoicing>(StringUtils.parseUri(uri.quarterlyUri, params));
    }

    public getQuarterlyFees(quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest): Observable<QuarterlyFees> {
        const { realEstateGroup, year, quarter } = quarterlyForRealEstateGroupRequest;
        const params = { realEstateGroupId: realEstateGroup, year: year?.toString(), quarter: quarter?.toString() };
        return this.httpClient.get<QuarterlyFees>(StringUtils.parseUri(uri.quarterlyFeesUri, params));
    }

    public getLastBillableInvoice(realEstateGroupId: string): Observable<Invoice> {
        const params = { realEstateGroupId };
        return this.httpClient.get<Invoice>(StringUtils.parseUri(uri.lastBillableInvoiceUri, params));
    }

    public getBillableInvoices(realEstateGroupId: string): Observable<Invoice[]> {
        const params = { realEstateGroupId };
        return this.httpClient.get<Invoice[]>(StringUtils.parseUri(uri.billableInvoicesUri, params));
    }

    public getQuarterlyTotals(quarterlyRequest: QuarterlyRequest): Observable<QuarterlyInvoicesTotals> {
        const params = { year: quarterlyRequest?.year?.toString(), quarter: quarterlyRequest?.quarter?.toString() };
        return this.httpClient.get<QuarterlyInvoicesTotals>(StringUtils.parseUri(uri.quarterlyTotalsUri, params));
    }

    public getInvoices(quarterlyRequest: QuarterlyRequest, invoicesRequest: InvoicesRequest): Observable<PaginationResponse<Invoice>> {
        let httpParams = HttpUtils.buildPaginationRequest(invoicesRequest);

        if (invoicesRequest.status) {
            httpParams = httpParams.set('status', invoicesRequest.status);
        }

        if (invoicesRequest.realEstateGroup) {
            httpParams = httpParams.set('realEstateGroup', invoicesRequest.realEstateGroup);
        }

        const params = { year: quarterlyRequest?.year?.toString(), quarter: quarterlyRequest?.quarter?.toString() };
        return this.httpClient.get<PaginationResponse<Invoice>>(StringUtils.parseUri(uri.invoicesUri, params), {
            params: httpParams,
        });
    }

    public getInvoice(quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest): Observable<Invoice> {
        const { realEstateGroup, year, quarter } = quarterlyForRealEstateGroupRequest;
        const params = { realEstateGroupId: realEstateGroup, year: year?.toString(), quarter: quarter?.toString() };
        return this.httpClient.get<Invoice>(StringUtils.parseUri(uri.invoiceUri, params));
    }

    public getAsset(id: string, invoiceType: RealEstateGroupInvoicingType): Observable<HttpResponse<Blob>> {
        const params = { id, invoiceType };
        return this.httpClient.get(StringUtils.parseUri(uri.downloadAssetUri, params), {
            observe: 'response',
            responseType: 'blob',
        });
    }

    public uploadAsset(id: string, content: FormData, invoiceType: RealEstateGroupInvoicingType): Observable<Invoice> {
        const params = { id, invoiceType };
        return this.httpClient.post<Invoice>(StringUtils.parseUri(uri.assetUri, params), content);
    }
}
