import { Component, OnInit, ViewChild } from '@angular/core';
import { OfficeFilterComponent } from '@app/real-estate-agent/components/office-filter/office-filter.component';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { AgentFormModalComponent } from '@app/real-estate-agent/modals/agent-form/agent-form.modal';
import { MobileAgentFormModalComponent } from '@app/real-estate-agent/modals/mobile-agent-form/mobile-agent-form.modal';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { DropdownInput, ModalSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-office-container',
    template: `
        <app-card-table-with-filter>
            <ng-container filter>
                <app-office-filter
                    [officesOptions]="officesOptions"
                    (filterChange)="onFilterChange($event)"
                    class="u-flex-1-1-0"
                ></app-office-filter>
            </ng-container>

            <ng-container actions>
                <app-button data-testid="invite-member" (click)="openInviteMember()">{{
                    'REAL_ESTATE_AGENT.OFFICE.INVITE' | translate
                }}</app-button>
            </ng-container>

            <ng-container table>
                <app-loading-container [loading]="loading$ | async">
                    <app-office-table ngProjectAs="table" [dataSource]="realEstateAgents$ | async"></app-office-table>
                </app-loading-container>
            </ng-container>
        </app-card-table-with-filter>
    `,
})
export class OfficeContainer implements OnInit {
    @ViewChild(OfficeFilterComponent, { static: true }) private officeFilterComponent: OfficeFilterComponent;

    private realEstateAgentsSubject = new BehaviorSubject<RealEstateAgent[]>(null);
    public realEstateAgents$: Observable<RealEstateAgent[]> = this.realEstateAgentsSubject.asObservable();
    public officesOptions: DropdownInput<string>[] = [];
    public realEstateGroup: RealEstateGroup;
    private loadingSubject = new BehaviorSubject(false);
    public loading$: Observable<boolean> = this.loadingSubject.asObservable();

    constructor(
        private realEstateGroupSandbox: RealEstateGroupSandbox,
        private realEstateGroupService: RealEstateGroupService,
        private modalSandbox: ModalSandbox,
        private realEstateAgentSandbox: RealEstateAgentSandbox
    ) {}

    public ngOnInit(): void {
        this.realEstateGroupSandbox.getRealEstateGroupForMe().subscribe((realEstateGroup) => {
            this.realEstateGroup = realEstateGroup;
            this.officesOptions = realEstateGroup.offices.map((office) => {
                return {
                    id: `office-${office.name?.toLowerCase().replace(' ', '-')}`,
                    name: 'office',
                    value: office.name,
                    label: office.name,
                };
            });
        });
    }

    public openInviteMember(): void {
        const data = {
            realEstateGroup: this.realEstateGroup,
            invite: true,
        };

        const callback = (refresh: boolean): void => {
            if (refresh) {
                this.fetchTeam(this.officeFilterComponent.getFilterValue());
            }
        };

        this.modalSandbox.openModal(MobileAgentFormModalComponent, { data }, callback, AgentFormModalComponent, { data }, callback);
    }

    public onFilterChange(event: { filter: RealEstateAgentFilter; persist: boolean }): void {
        this.realEstateAgentSandbox.setFilter(event.filter, event.persist, () => {
            this.loadingSubject.next(true);
            this.fetchTeam(event.filter);
        });
    }

    private fetchTeam(filter: RealEstateAgentFilter): void {
        this.realEstateGroupService
            .getTeam(filter)
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe((realEstateAgents) => {
                this.realEstateAgentsSubject.next(realEstateAgents);
            });
    }
}
