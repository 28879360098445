import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
    public getRoot(): string[] {
        /** */
        return null;
    }

    public getContact(): string[] {
        /** */
        return null;
    }
}
