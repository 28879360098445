<ng-container
    *ngIf="{
        move: (move$ | async),
        isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async),
        meterReadingsTakeover: (move$ | async).moveStates?.meterReadingsTakeoverByRealEstateAgent
    } as vm"
>
    <app-mobile-modal>
        <div mobile-modal-header>
            <h6>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.METER_READINGS.ENERGY' | translate }}</h6>
            <span class="u-color-muted u-font-size-small">{{ vm.move?.user?.formattedMovingAddressWithoutCountry }}</span>
        </div>
        <div mobile-modal-main>
            <form [formGroup]="form" (ngSubmit)="submit()" class="u-flex-column u-flex-justify-content-space-between u-h100p">
                <div class="u-background-white" [ngSwitch]="moveTransactionType">
                    <div class="u-padding">
                        <smvd-app-meter-info-state [move]="vm.move" class="u-margin-bottom"></smvd-app-meter-info-state>
                        <app-alert *ngSwitchCase="moveTransactionTypes.Sale" context="danger" icon="warning" class="__alert">
                            <h6 class="u-margin-bottom">{{ 'ENERGY.CONFIRM_DEED_DATE_ALERT.TITLE' | translate }}</h6>
                            <p>{{ 'ENERGY.CONFIRM_DEED_DATE_ALERT.INFO' | translate }}</p>
                        </app-alert>
                        <app-alert *ngSwitchDefault context="danger" icon="warning" class="__alert">
                            <h6 class="u-margin-bottom">{{ 'ENERGY.CONFIRM_TRANSFER_DATE_ALERT.TITLE' | translate }}</h6>
                            <p>{{ 'ENERGY.CONFIRM_TRANSFER_DATE_ALERT.INFO' | translate }}</p>
                        </app-alert>

                        <h6 class="u-color-muted u-margin-bottom u-margin-top">{{ 'MOVE.MOVING_DATE' | translate }}</h6>
                        <app-moving-date-input
                            [formControlName]="mobileEnergyMetersForm.MovingDate"
                            ngDefaultControl
                            [label]="
                                (moveTransactionType === moveTransactionTypes.Sale ? 'MOVE.MOVING_DATE_SALE' : 'MOVE.MOVING_DATE_RENTAL')
                                    | translate
                            "
                            class="u-display-block"
                        ></app-moving-date-input>
                    </div>

                    <table mat-table [dataSource]="dataSource" class="u-w100p u-flex-no-shrink">
                        <ng-container matColumnDef="labels">
                            <th mat-header-cell *matHeaderCellDef class="u-padding-left"></th>
                            <td mat-cell *matCellDef="let row" class="__cell u-padding-left">
                                <span class="u-display-block">{{ row.label }}</span>
                            </td>
                        </ng-container>

                        <app-table-column-detail-arrow></app-table-column-detail-arrow>

                        <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
                        <tr mat-row *matRowDef="let row; columns: columns" (click)="row.navigate()"></tr>
                    </table>

                    <div class="u-padding">
                        <app-energy-meter-reading-assets-input
                            class="u-display-block u-margin-bottom-double"
                            [move]="vm.move"
                            [readOnly]="isDisabled && !vm.isRealEstateAgentOrAdmin"
                            (detail)="onEnergyMeterReadingDetail($event)"
                            (preview)="onEnergyMeterReadingAssetPreview()"
                            (change)="checkTakeOverInformation()"
                            [formControlName]="mobileEnergyMetersForm.MeterReadingAssets"
                            ngDefaultControl
                        ></app-energy-meter-reading-assets-input>

                        <app-energy-document-assets-input
                            [move]="vm.move"
                            [readOnly]="isDisabled && !vm.isRealEstateAgentOrAdmin"
                            (detail)="onEnergyDocumentDetail($event)"
                            (preview)="onEnergyDocumentAssetPreview()"
                            (change)="checkTakeOverInformation()"
                            [formControlName]="mobileEnergyMetersForm.MeterReadingAssets"
                            ngDefaultControl
                        ></app-energy-document-assets-input>
                    </div>

                    <div class="u-padding">
                        <app-meter-readings-takeover-done
                            [meterReadingsTakeoverByRealEstateAgent]="vm.meterReadingsTakeover"
                            [leaver]="movers?.leaver"
                        ></app-meter-readings-takeover-done>
                        <smvd-app-takeover-information-consent
                            *ngIf="showTakeOverConsent$ | async"
                            width="auto"
                            [formControlName]="mobileEnergyMetersForm.MeterReadingsTakeover"
                            ngDefaultControl
                            [movers]="movers"
                            [hide]="disableTakeOverConsent$ | async"
                        ></smvd-app-takeover-information-consent>
                    </div>
                </div>

                <div class="u-border-radius-top-extra-large u-flex-no-shrink u-background-white u-padding">
                    <button
                        smvd-ui-button
                        type="submit"
                        class="u-display-block u-w100p"
                        [disabled]="isDisabled || !vm.isRealEstateAgentOrAdmin"
                    >
                        {{ 'SAVE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </app-mobile-modal>
</ng-container>
