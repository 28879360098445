import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as adminRouting from '@app/admin/constants/routing.constants';
import { adminBaseRouting } from '@app/admin/constants/routing.constants';
import { SendConfirmationModalComponent } from '@app/admin/modals/send-confirmation/send-confirmation.modal';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { CoBrandingSandbox } from '@app/co-branding/sandboxes/co-branding.sandbox';
import { eotsDataRouting, eotsEnergySuggestionsRouting, eotsOfferSelectedRouting } from '@app/eots/constants/eots.constants';
import { Breadcrumb } from '@app/header/interfaces/breadcrumb';
import { iotsDataRouting, iotsFamilialRouting, iotsOfferSelectedRouting, iotsSuggestionsRouting } from '@app/iots/constants/iots.constants';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { otsAbsoluteContractRoute, otsAbsoluteOtherServicesRoute, otsBaseRoute } from '@app/ots/constants/ots.constants';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { NavigationData } from '@smooved/core';
import { MenuItemSize, SvgIllustration } from '@smooved/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-header-container',
    template: `
        <ng-container
            *ngIf="{
                tabletPortraitUp: uiSandbox.tabletPortraitUp$ | async,
                showMenuDashboard: showMenuDashboard$ | async,
                hasContentBottom: hasContentBottom$ | async,
                showRealEstateAgentLogo: showRealEstateAgentLogo$ | async
            } as vm"
        >
            <header class="__container" [class.header--is-mobile-opened]="headerOpened$ | async">
                <div
                    class="__header u-flex-no-shrink u-flex-row u-border-bottom"
                    [ngClass]="{
                        '__header--transparent': isTransparent$ | async,
                        'u-margin-bottom-half': vm.hasContentBottom && vm.tabletPortraitUp
                    }"
                >
                    <div class="u-flex-row u-flex-align-items-center u-margin-right">
                        <app-real-estate-agent-logo-button
                            *ngIf="vm.showRealEstateAgentLogo; else smoovedLogo"
                        ></app-real-estate-agent-logo-button>
                        <ng-template #smoovedLogo>
                            <app-logo-button></app-logo-button>
                        </ng-template>
                    </div>

                    <div class="u-flex-row u-flex-align-items-center u-flex-1">
                        <app-breadcrumb *ngIf="breadCrumb$ | async as breadcrumb" [breadcrumb]="breadcrumb"></app-breadcrumb>
                        <app-header-offer *ngIf="showOffer$ | async"></app-header-offer>
                        <app-header-energy-contract *ngIf="showEnergyContract$ | async"></app-header-energy-contract>
                        <app-header-move-filters *ngIf="showMoveFilters$ | async"></app-header-move-filters>
                        <app-header-real-estate-agent-filters
                            *ngIf="showRealEstateAgentFilters$ | async"
                        ></app-header-real-estate-agent-filters>

                        <app-header-partner-agent-menu
                            *ngIf="authenticationSandbox.isPartnerAgent$ | async"
                        ></app-header-partner-agent-menu>

                        <!-- TODO: Search bar -->
                    </div>

                    <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-flex-end u-margin-left">
                        <app-header-menu *ngIf="!vm.showMenuDashboard && vm.tabletPortraitUp"></app-header-menu>
                        <app-header-menu-dashboard *ngIf="vm.showMenuDashboard && vm.tabletPortraitUp"></app-header-menu-dashboard>

                        <app-button (onClick)="sendConfirmation()" *ngIf="showConfirmation$ | async"
                            >{{ 'ADMIN.SEND_CONFIRMATION.TITLE' | translate }}
                        </app-button>

                        <div *ngIf="uiSandbox.upToAndIncludingPhoneLandscape$ | async" [ngSwitch]="headerOpened$ | async">
                            <a class="u-link-muted" *ngSwitchCase="false" (click)="headerOpenedSubject.next(true)">
                                <app-svg-illustration
                                    class="__illustration u-flex u-flex-justify-content-center"
                                    [svg]="illustrationEnum.Menu"
                                ></app-svg-illustration>
                            </a>
                            <a class="u-link-muted" *ngSwitchCase="true" (click)="headerOpenedSubject.next(false)">
                                <app-svg-illustration
                                    class="__illustration u-flex u-flex-justify-content-center"
                                    [svg]="illustrationEnum.Close"
                                ></app-svg-illustration>
                            </a>
                        </div>

                        <app-header-admin-menu *ngIf="showAdminMenu$ | async"></app-header-admin-menu>
                    </div>
                </div>

                <app-header-dashboard-extra
                    *ngIf="vm.hasContentBottom"
                    [showText]="vm.tabletPortraitUp || (headerOpened$ | async)"
                ></app-header-dashboard-extra>

                <app-header-menu-mobile
                    class="u-display-block"
                    (onItemClick)="headerOpenedSubject.next(false)"
                    *ngIf="headerOpened$ | async"
                ></app-header-menu-mobile>
            </header>
        </ng-container>
    `,
    styleUrls: ['./header.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderContainer {
    public headerOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly menuItemSize = MenuItemSize;
    public readonly illustrationEnum = SvgIllustration;

    public headerOpened$: Observable<boolean> = combineLatest([
        this.uiSandbox.upToAndIncludingPhoneLandscape$,
        this.headerOpenedSubject.asObservable(),
    ]).pipe(
        map(([upToAndIncludingPhoneLandscape, headerOpenedState]) => {
            if (!upToAndIncludingPhoneLandscape) {
                return false;
            }
            return !!headerOpenedState;
        })
    );

    private data$: Observable<{ data: any; empty: boolean }> = this.navigationSandbox.routeNavigationEndData$.pipe(
        map((data): { data: any; empty: boolean } => ({
            data,
            empty: !!data[NavigationData.Empty],
        }))
    );

    public isTransparent$: Observable<boolean> = this.navigationSandbox.routeNavigationEndData$.pipe(
        map((data): boolean => data[NavigationData.HeaderIsTransparent])
    );
    public hasContentBottom$: Observable<boolean> = this.navigationSandbox.routeNavigationEndData$.pipe(
        map((data): boolean => data[NavigationData.HasContentBottom])
    );

    public breadCrumb$: Observable<Breadcrumb> = this.data$.pipe(map((result) => !result.empty && result.data[NavigationData.Breadcrumb]));

    public showMoveFilters$: Observable<boolean> = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return event.urlAfterRedirects.startsWith(`/${adminRouting.adminBaseRoute}/${Navigation.Movers}`);
        })
    );

    public showRealEstateAgentFilters$: Observable<boolean> = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return (
                event.urlAfterRedirects.startsWith(`/${adminRouting.adminBaseRoute}/leads`) ||
                event.urlAfterRedirects.startsWith(`/${adminRouting.adminBaseRoute}/real-estate-agents`) ||
                event.urlAfterRedirects.startsWith(`/${adminRouting.adminBaseRoute}/offices`) ||
                event.urlAfterRedirects.startsWith(`/${adminRouting.adminBaseRoute}/real-estate-groups`)
            );
        })
    );

    public showAdminMenu$: Observable<boolean> = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return event.urlAfterRedirects.startsWith(`/${adminBaseRouting.join('/')}`);
        })
    );

    public inOtsFlow$ = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return event.urlAfterRedirects.startsWith(`/${otsBaseRoute}`);
        })
    );

    public inConfirmEnergyFlow$ = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return event.urlAfterRedirects.startsWith(`/${Navigation.Moves}/${Navigation.ConfirmEnergy}`);
        })
    );

    /**
     * don't show menu when a breadcrumb is present, or when menu os turned off
     */
    public showMenu$: Observable<boolean> = combineLatest([
        this.inOtsFlow$,
        this.inConfirmEnergyFlow$,
        this.authenticationSandbox.isRealEstateAgent$,
        this.breadCrumb$,
        this.data$,
    ]).pipe(
        map(
            ([inOtsFlow, inConfirmEnergyFlow, isRealEstateAgent, breadcrumb, data]) =>
                !data.empty && !inOtsFlow && !inConfirmEnergyFlow && isRealEstateAgent && !breadcrumb
        )
    );

    public showConfirmation$: Observable<boolean> = combineLatest([this.authenticationSandbox.isAdmin$, this.moveSandbox.move$]).pipe(
        map(([isAdmin, move]) => isAdmin && !!move._id)
    );

    public onDashboard$ = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => event.urlAfterRedirects.startsWith(`/${Navigation.Dashboard}`))
    );

    public showMenuDashboard$: Observable<boolean> = combineLatest([
        this.authenticationSandbox.isMover$,
        this.authenticationSandbox.isGuest$,
        this.authenticationSandbox.isAdmin$,
        this.onDashboard$,
    ]).pipe(
        map(([isMover, isGuest, isAdmin, onDashboard]) => {
            return (isMover || isGuest || isAdmin) && !onDashboard;
        })
    );

    public showRealEstateAgentLogo$: Observable<boolean> = combineLatest([
        this.coBrandingSandbox.hasCoBranding$,
        this.realEstateGroupSandbox.logo$,
    ]).pipe(map(([hasCoBranding, logo]) => (!logo ? false : hasCoBranding)));

    public showOffer$: Observable<boolean> = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return (
                event.urlAfterRedirects.startsWith(`/${Navigation.Energy}/data`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Energy}/energy-suggestions`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Telecom}/data`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Telecom}/suggestions`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/move-details`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/contact-details`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/payment-details`) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsDataRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsEnergySuggestionsRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsOfferSelectedRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsDataRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsSuggestionsRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsOfferSelectedRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsFamilialRouting)) ||
                event.urlAfterRedirects.startsWith(otsAbsoluteContractRoute) ||
                event.urlAfterRedirects.startsWith(otsAbsoluteOtherServicesRoute)
            );
        })
    );

    public showEnergyContract$: Observable<boolean> = this.navigationSandbox.routeNavigationEndEvent$.pipe(
        map((event) => {
            return event.urlAfterRedirects.startsWith(`/${Navigation.EnergyContract}`);
        })
    );

    constructor(
        private router: Router,
        private readonly route: ActivatedRoute,
        public authenticationSandbox: AuthenticationSandbox,
        private realEstateGroupSandbox: RealEstateGroupSandbox,
        public uiSandbox: AppUiSandbox,
        private dialog: MatDialog,
        private moveSandbox: MoveSandbox,
        private navigationSandbox: AppNavigationSandbox,
        private coBrandingSandbox: CoBrandingSandbox
    ) {}

    public sendConfirmation() {
        this.dialog.open(SendConfirmationModalComponent);
    }
}
