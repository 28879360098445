<div class="__stars u-flex u-flex-align-items-center">
    <smvd-ui-svg-illustration
        *ngFor="let star of vm.activeStars"
        [svg]="svgIllustration.HouseMatchStar"
        [context]="context"
        [style.width]="width"
        class="__active-star"
    ></smvd-ui-svg-illustration>
    <smvd-ui-svg-illustration
        *ngIf="vm.hasHalfStar"
        [svg]="svgIllustration.HouseMatchHalfStar"
        [context]="context"
        [style.width]="width"
        class="__half-star"
    ></smvd-ui-svg-illustration>
    <smvd-ui-svg-illustration
        *ngFor="let star of vm.inactiveStars"
        [svg]="svgIllustration.HouseMatchEmptyStar"
        [context]="context"
        [style.width]="width"
        class="__inactive-star"
    ></smvd-ui-svg-illustration>
</div>
