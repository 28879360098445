import { Injectable } from '@angular/core';
import { EnergyMetersInfoModal } from '@app/energy/modals/energy-meters-info/energy-meters-info.modal';
import { MobileMortgageOverviewModal } from '@app/mortgage/modals/mobile-mortgage-overview/mobile-mortgage-overview.modal';
import { MortgageDetailsModal } from '@app/mortgage/modals/mortgage-details/mortgage-details.modal';
import { Service } from '@app/move/enums/service.enum';
import { Move } from '@app/move/interfaces/move';
import { UpdateLeaverSuppliersModal } from '@app/move/modals/update-leaver-suppliers/update-leaver-suppliers.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { MoveDetailModalComponent } from '@app/real-estate-agent/modals/move-detail/move-detail.modal';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, I18nKeyUtils } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { AppI18nKeyType, i18nKeyTypeMap } from '../../shared/constants/i18n-key-type-map';
import { ModalDataMove } from '../interfaces/modal-data-move.interfaces';
import { MobileMoveWaterComponent } from '../modals/mobile-move-water/mobile-move-water.component';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class RealEstateAgentModalsService {
    constructor(
        private modalSandbox: ModalSandbox,
        private moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService
    ) {}

    public openMeterInfoModal(closeCallback?: (_?: any) => void): void {
        this.modalSandbox.openDialog({
            componentForMobile: EnergyMetersInfoModal,
            callbackForMobile: closeCallback,
            componentForTabletPortraitUp: EnergyMetersInfoModal,
            callbackForTabletPortraitUp: closeCallback,
        });
    }

    public openWaterDocumentModal(data: ModalDataMove, closeCallback?: (_?: any) => void): void {
        this.moveSandbox.setStateFor(DbUtils.getStringId(data.move)).subscribe(() => {
            this.modalSandbox.openModal(
                MobileMoveWaterComponent,
                { data },
                closeCallback,
                MoveDetailModalComponent,
                {
                    data: { ...data, id: DbUtils.getStringId(data.move), options: { activeTab: Service.water } },
                },
                closeCallback
            );
        });
    }

    public openMortgageDetail(moveId: string, closeCallback?: (_?: any) => void): void {
        const data = moveId;

        this.modalSandbox.openDialog({
            componentForMobile: MobileMortgageOverviewModal,
            configForMobile: { data },
            callbackForMobile: closeCallback,
            componentForTabletPortraitUp: MortgageDetailsModal,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: closeCallback,
        });
    }

    public onCloseClearMoveState(callback: () => void) {
        return (): void => {
            this.moveSandbox.clearMoveState();
            callback();
        };
    }

    public openUpdateLeaverSuppliersModal(move: Move, callback?: (move: Move) => void): void {
        const { transferee, leaver } = MoveUtils.getMovers(move);

        this.modalSandbox.openClosableModal({
            component: UpdateLeaverSuppliersModal,
            config: {
                header: this.translateService.instant(
                    I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.AdminDashboard, 'DETAIL.ENERGY_STOP.EDIT_SUPPLIERS')
                ) as string,
                subheader: transferee.user.formattedMovingAddress,
                input: { leaver, transferee },
            },
            afterClosed: callback,
        });
    }
}
