import { NgModule } from '@angular/core';
import { RentalInspectionAssetComponent } from '@app/document-center/components/rental-inspection-asset/rental-inspection-asset.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule, ButtonModule, DocumentCenterModule as UiDocumentCenterModule } from '@smooved/ui';
import { DocumentCenterProvidersModule } from './document-center-providers.module';
import { SearchableDropdownInputModule } from '../../../projects/ui/src/lib/form/searchable-dropdown-input';
import { FormsModule } from '@angular/forms';
import { GenerateTransactionTemplateComponent } from '@app/document-center/components/generate-transaction-template/generate-transaction-template.component';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';

const components = [GenerateTransactionTemplateComponent, RentalInspectionAssetComponent];

@NgModule({
    declarations: [...components],
    imports: [
        DocumentCenterProvidersModule,
        UiDocumentCenterModule,
        TranslateModule,
        SearchableDropdownInputModule,
        ButtonModule,
        FormsModule,
        JsonPipe,
        NgIf,
        AlertModule,
        NgForOf,
    ],
    exports: [...components],
})
export class DocumentCenterModule {}
