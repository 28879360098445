import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';

@Component({
    selector: 'app-start-container',
    template: ` <app-mover-start-container></app-mover-start-container> `,
})
export class StartContainer implements OnInit {
    constructor(
        public authenticationSandbox: AuthenticationSandbox,
        private moveSandbox: MoveSandbox,
        private realEstateGroupSandbox: RealEstateGroupSandbox,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        // todo - move this to referrer sandbox
        // bivCode is deprecated
        const realEstateAgent: string =
            this.activatedRoute.snapshot.queryParams['realEstateAgent'] || this.activatedRoute.snapshot.queryParams['bivCode'];
        if (realEstateAgent) {
            this.moveSandbox.patchRealEstateAgent(realEstateAgent);
        }
    }
}
