import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-detail',
    templateUrl: './info-detail.component.html',
    styleUrls: ['./info-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDetailComponent {
    @Input() public label: string;
}
