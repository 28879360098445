import { HttpResponse } from '@angular/common/http';
import { Mimetypes } from '@smooved/core';

export interface OpenAssetOptions {
    response: HttpResponse<Blob | ArrayBuffer>;
    type: 'blob' | 'arraybuffer';
    mimeType?: Mimetypes;
    fileName?: string;
}

export class FileUtils {
    public static mimetypeFilter(...mimetypes: string[]) {
        return (_, file, callback): any => {
            const mimeTypes = mimetypes.map((m) => m.toLowerCase());

            if (!mimeTypes.includes(file.mimetype)) {
                return callback(new Error('ERROR.INVALID_FILE_FORMAT'), false);
            }

            callback(null, true);
        };
    }

    /**
     * This method will get asset from server and open it to user
     */
    public static openAsset({ response, mimeType, type, fileName }: OpenAssetOptions): void {
        const blob = new Blob([response.body], { type: mimeType });
        FileUtils.downloadAsset(blob, fileName, response);
    }

    public static downloadAsset(blob: Blob, fileName: string, response?: HttpResponse<Blob | ArrayBuffer>): void {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = fileName || response.headers.get('Filename');
        anchor.target = '_blank';
        anchor.click();
    }

    public static convertBytesToMegaBytes(bytes: number, decimalNumbers: number = 2): string {
        return (bytes / (1024 * 1024)).toFixed(decimalNumbers);
    }

    public static convertMegaBytesToBytes(megaBytes: number): number {
        return megaBytes * (1024 * 1024);
    }
}
