import { DueTime, EnumUtils, HomeDescription, I18nKeyUtils } from '@smooved/core';
import { i18nKeyTypeMap, uiI18nKeyTypes } from '../i18n';
import { DropdownInput } from './dropdown-input/dropdown-input';

export function dropdownInputOptionAll<T>(name: string): DropdownInput<T> {
    return {
        id: `${name}-all`,
        name,
        labelResource: 'ALL',
        value: null,
    };
}

const homeDescriptionLabelsMap = {
    [HomeDescription.Detached]: 'DETACHED',
    [HomeDescription.HalfOpen]: 'HALF_OPEN',
    [HomeDescription.Closed]: 'CLOSED',
    [HomeDescription.Apartment]: 'APARTMENT',
};
export const homeDescriptionOptions: DropdownInput<HomeDescription>[] = Object.keys(HomeDescription).map((key) => ({
    id: `home-description-${key}`,
    labelResource: `UI.HOME_DESCRIPTION.${homeDescriptionLabelsMap[HomeDescription[key]]}`,
    name: 'home-description',
    value: HomeDescription[key],
}));

export const baseInputWidth = '300px';
export const inputFullWidth = '100%';
export const smallInputWidth = '75px';

export const transactionDueTimeOptions = EnumUtils.entries(DueTime).map(([key, value]) => ({
    id: key,
    name: 'transactionDueTime',
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, uiI18nKeyTypes.TransactionDueTime, `OPTIONS.${key}`),
    value,
}));

export const maskVatNumber = 'SS0000.000.000';
