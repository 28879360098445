import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { RealEstateAgency, ReviewSource, StarReview } from '@smooved/core';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';
import { googleMaxScore } from './nps-review-google-header.constants';

@Component({
    selector: 'app-nps-review-google-header',
    templateUrl: './nps-review-google-header.component.html',
    styleUrls: ['./nps-review-google-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsReviewGoogleHeaderComponent {
    @Input() public item: StarReview;

    public readonly googleMaxScore = googleMaxScore;
    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly source = ReviewSource.Google;

    constructor(@Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig) {}

    public get location(): RealEstateAgency {
        return this.item?.location as RealEstateAgency;
    }
}
