<ng-container [formGroup]="form" #formElement="ngForm">
    <app-check-input
        [custom]="custom"
        [option]="termsAndConditionsOption"
        [hasMargin]="hasMargin"
        [hasMarginDouble]="hasMarginDouble"
        [width]="width"
        [formControlName]="consentField"
    ></app-check-input>
</ng-container>
