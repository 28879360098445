import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { bundleField } from './telecom-bundle.constants';

@Injectable()
export class TelecomBundleService {
    public form = this.formBuilder.group({
        [bundleField]: [null, Validators.required],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {}

    public init(): void {
        this.moveSandbox.telecomOfferOnce$.subscribe((telecomOffer) => {
            this.form.get(bundleField).patchValue(telecomOffer);
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            const { bundle } = this.form.getRawValue();
            this.moveSandbox.patchProperty('telecomOffer', bundle, true, this.navigateToProvider);
        }
    }

    private navigateToProvider =  (): void => {
        void this.router.navigate(['../', 'provider'], {
            relativeTo: this.route,
        });
    }
}
