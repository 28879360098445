import { ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-base-service-action',
    template: ``,
})
export class BaseServiceActionComponent {
    @Input() move: Move;

    public emptySvg = Svg.Empty;

    constructor(@Optional() protected readonly cdr: ChangeDetectorRef, protected readonly moveSandbox: MoveSandbox) {}

    protected updateMove(move?: Move): void {
        if (!move) return;
        this.moveSandbox.updatedMoveTrigger.next();
        if (!this.cdr) return;
        this.cdr.detectChanges();
    }
}
