import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewSource } from '@smooved/core';
import { MenuItemAppearance, MenuItemSize, NpsReviewsFilterService } from '@smooved/ui';
import { menuFilters, ReviewsFilterMenu } from './nps-reviews-filter.constants';

@Component({
    selector: 'app-nps-reviews-filter',
    templateUrl: 'nps-reviews-filter.component.html',
    styleUrls: ['nps-reviews-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NpsReviewsFilterComponent implements OnInit {
    public active = ReviewsFilterMenu.All;

    public readonly filters = menuFilters;
    public readonly menuItemSizes = MenuItemSize;
    public readonly menuItemAppearance = MenuItemAppearance;

    @Output()
    public selected = new EventEmitter<string>();

    constructor(private readonly route: ActivatedRoute, private readonly npsReviewsFilterService: NpsReviewsFilterService) {}

    public ngOnInit(): void {
        const { unanswered } = this.route.snapshot.queryParams;
        this.active = unanswered ? ReviewsFilterMenu.Unanswered : ReviewsFilterMenu.All;
    }

    public setActive(item: ReviewsFilterMenu): void {
        this.active = item;
        this.selected.emit(ReviewsFilterMenu[item]);

        switch (item) {
            case ReviewsFilterMenu.Unanswered:
                this.npsReviewsFilterService.clear(false);
                this.npsReviewsFilterService.filterByUnanswered(true);
                break;
            case ReviewsFilterMenu.SmoovedOnly:
                this.npsReviewsFilterService.clear(false);
                this.npsReviewsFilterService.filterBySource(ReviewSource.Smooved);
                break;
            case ReviewsFilterMenu.Other:
                this.npsReviewsFilterService.clear(false);
                this.npsReviewsFilterService.filterBySource(
                    Object.values(ReviewSource).filter((source) => source !== ReviewSource.Smooved)
                );
                break;
            default:
                this.npsReviewsFilterService.clear();
                break;
        }
    }
}
