import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { UiHeaderVariant } from '../../../ui.enums';

@Component({
    selector: 'ui-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
    @Input() public label: string;
    @Input() public variant = UiHeaderVariant.Label;

    public labelText: string;

    public ngOnChanges({ label }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(label)) {
            this.labelText = `<${this.variant}${this.getClassListString()}>${this.getLabel()}</${this.variant}>`;
        }
    }

    private getLabel(): string {
        return this.variant === UiHeaderVariant.Label ? `${this.label}: ` : this.label;
    }

    private getClassListString(): string {
        let classList = '';
        switch (this.variant) {
            case UiHeaderVariant.Label:
                classList += 'u-font-weight-semi-bold';
                break;
            case UiHeaderVariant.H6:
                classList += 'u-color-muted u-margin-bottom-half';
                break;
        }
        return classList ? ` class="${classList}"` : '';
    }
}
