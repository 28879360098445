<app-feature-detail
    [featureScope]="featureScope.Reviews"
    [label]="'REVIEWS.LABEL_PLURAL' | translate"
    [description]="'FEATURE_SCOPES.REVIEWS.DESCRIPTION' | translate"
    [canDeactivate]="false"
>
    <ng-container *hasFeatureAccess="featureScopes">
        <h6 class="u-margin-bottom-half">{{ 'REVIEWS.HOUSE_MATCH.LABEL' | translate }}</h6>
        <p [innerHtml]="'REVIEWS.HOUSE_MATCH.DESCRIPTION' | translate" class="u-margin-bottom"></p>
        <div class="__copy-link-input">
            <smvd-ui-text-input [hasMargin]="false" [hasMarginDouble]="true" [(ngModel)]="houseMatchUri" [disabled]="true">
                <div suffix class="u-flex-row u-flex-align-items-center">
                    <button
                        smvd-ui-button
                        [context]="context.Secondary"
                        [appearance]="buttonAppearance.Link"
                        [iconSize]="iconSize.Md"
                        icon="content_copy"
                        class="u-margin-right-half u-padding-none"
                        (click)="copyLink(houseMatchUri)"
                    ></button>
                    <a
                        smvd-ui-button
                        [context]="context.Secondary"
                        [appearance]="buttonAppearance.Link"
                        [iconSize]="iconSize.Md"
                        icon="open_in_new"
                        [href]="houseMatchUri"
                        target="_blank"
                        class="u-margin-none u-padding-none u-h-auto"
                    ></a>
                </div>
            </smvd-ui-text-input>
        </div>

        <form
            [formGroup]="featureDetailReviewsService.form"
            (ngSubmit)="featureDetailReviewsService.submit()"
            class="u-flex-column u-flex-align-items-start u-margin-bottom-double"
        >
            <ng-container *hasFeatureAccess="featureScope.ReviewNotification">
                <smvd-ui-text-input
                    [formControlName]="formControlNames.reviewsNotifications"
                    [label]="'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.REVIEW_NOTIFICATIONS.LABEL' | translate"
                >
                </smvd-ui-text-input>
                <p class="u-font-size-small u-color-muted u-margin-bottom-double">
                    {{'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.REVIEW_NOTIFICATIONS.NOTE' | translate}}
                </p>
            </ng-container>
            <button app-button type="submit">{{'SUBMIT' | translate}}</button>
        </form>

        <button
            app-button
            [appearance]="buttonAppearance.Link"
            [routerLink]="navigation.Configure"
            icon="chevron_right"
            class="u-padding-none"
        >
            {{ 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.LABEL' | translate }}
        </button>
        <div appTooltip [template]="tooltip_wis" class="u-display-block">
            <button
                app-button
                [appearance]="buttonAppearance.Link"
                (click)="openConfigureWidgetInfluencerScoreModal()"
                icon="chevron_right"
                class="u-padding-none"
            >
                {{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.LABEL' | translate }}
            </button>
        </div>
        <div appTooltip [template]="tooltip_signature" class="u-display-block">
            <button
                app-button
                [appearance]="buttonAppearance.Link"
                (click)="openConfigureSignatureModal()"
                icon="chevron_right"
                class="u-padding-none"
            >
                {{ 'REVIEWS.SIGNATURE.CONFIGURE.LABEL' | translate }}
            </button>
        </div>
    </ng-container>
</app-feature-detail>
<router-outlet></router-outlet>

<ng-template #tooltip_wis>
    <span>{{'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.DISABLED_TOOLTIP' | translate }}</span>
</ng-template>

<ng-template #tooltip_signature>
    <span>{{'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.DISABLED_TOOLTIP' | translate }}</span>
</ng-template>
