import { Injectable } from '@angular/core';
import { TelecomSuggestionsFilter } from '@app/telecom/interfaces/telecom-suggestions-filter';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isBoolean } from 'lodash';
import { of, zip } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { MoveSandbox } from '../../move/sandboxes/move.sandbox';
import { AppUiSandbox } from '../../ui/sandboxes/ui.sandbox';
import { TelecomBundle } from '../../wizard/telecom/telecom.interfaces';
import { TelecomSuggestionsFilterOptionsRequest } from '../interfaces/telecom-suggestions-filter-options-request';
import { TelecomSandbox } from '../sandboxes/telecom.sandbox';
import { TelecomService } from '../telecom.service';
import {
    getTelecomSuggestionsFailed,
    getTelecomSuggestionsFilterOptions,
    getTelecomSuggestionsFilterOptionsFailed,
    getTelecomSuggestionsFilterOptionsSuccess,
    getTelecomSuggestionsSuccess,
    setTelecomSuggestionsFilter,
} from './telecom.actions';

@Injectable()
export class TelecomEffects {
    constructor(
        private actions$: Actions,
        private telecomSandbox: TelecomSandbox,
        private uiSandbox: AppUiSandbox,
        private moveSandbox: MoveSandbox,
        private telecomService: TelecomService
    ) {}

    public getTelecomSuggestionsFilterOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getTelecomSuggestionsFilterOptions),
            mergeMap((action) => zip(this.moveSandbox.telecomOfferOnce$, of(action)).pipe(take(1))),
            mergeMap(([telecomOffer, action]) => {
                const query: TelecomSuggestionsFilterOptionsRequest = {
                    providers: action.providers,
                };

                const { telecomBundle, digitalStreamTelevision } = telecomOffer;

                if (isBoolean(telecomBundle[TelecomBundle.Internet]) && telecomBundle[TelecomBundle.Internet]) {
                    query[TelecomBundle.Internet] = telecomBundle[TelecomBundle.Internet];
                }

                if (isBoolean(telecomBundle[TelecomBundle.DigitalTelevision]) && telecomBundle[TelecomBundle.DigitalTelevision]) {
                    if (digitalStreamTelevision) {
                        query['digitalStreamTelevision'] = digitalStreamTelevision;
                    } else {
                        query[TelecomBundle.DigitalTelevision] = telecomBundle[TelecomBundle.DigitalTelevision];
                    }
                }

                if (isBoolean(telecomBundle[TelecomBundle.MobilePhone]) && telecomBundle[TelecomBundle.MobilePhone]) {
                    query[TelecomBundle.MobilePhone] = telecomBundle[TelecomBundle.MobilePhone];
                }

                if (isBoolean(telecomBundle[TelecomBundle.FixedLine]) && telecomBundle[TelecomBundle.FixedLine]) {
                    query[TelecomBundle.FixedLine] = telecomBundle[TelecomBundle.FixedLine];
                }

                return this.telecomService.findSuggestionsFilter(query).pipe(
                    map((telecomSuggestionsFilterOptions) =>
                        getTelecomSuggestionsFilterOptionsSuccess({
                            telecomSuggestionsFilterOptions,
                            callback: action.callback,
                        })
                    ),
                    catchError((errorResponse) => of(getTelecomSuggestionsFilterOptionsFailed()))
                );
            })
        )
    );

    public getTelecomSuggestionsFilterOptionsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getTelecomSuggestionsFilterOptionsSuccess),
                tap((action) => {
                    if (action.callback) {
                        action.callback(action.telecomSuggestionsFilterOptions);
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    public setTelecomSuggestionsFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setTelecomSuggestionsFilter),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            mergeMap((action) =>
                zip(
                    this.moveSandbox.telecomBundleOnce$,
                    this.moveSandbox.digitalStreamTelevisionOnce$,
                    this.moveSandbox.providerChoiceOnce$,
                    of(action)
                ).pipe(take(1))
            ),
            mergeMap(([telecomBundle, digitalStreamTelevision, provider, action]) => {
                const telecomSuggestionsFilter: TelecomSuggestionsFilter = {
                    providers: [provider].concat([action.telecomSuggestionsFilter.otherProvider]).filter((x) => !!x),
                    volume: action.telecomSuggestionsFilter.volume,
                    ignorePromotions: action.telecomSuggestionsFilter.ignorePromotions,
                };

                if (this.isSelected(telecomBundle, TelecomBundle.Internet)) {
                    telecomSuggestionsFilter[TelecomBundle.Internet] = true;
                    telecomSuggestionsFilter.wifiSpeed = action.telecomSuggestionsFilter.wifiSpeed;
                }

                if (this.isSelected(telecomBundle, TelecomBundle.DigitalTelevision)) {
                    if (this.isSelected(telecomBundle, TelecomBundle.MobilePhone) && !!digitalStreamTelevision) {
                        telecomSuggestionsFilter.digitalStreamTelevision = true;
                    } else {
                        telecomSuggestionsFilter[TelecomBundle.DigitalTelevision] = true;
                    }
                }

                if (this.isSelected(telecomBundle, TelecomBundle.MobilePhone)) {
                    telecomSuggestionsFilter[TelecomBundle.MobilePhone] = true;
                    telecomSuggestionsFilter.mobileNumberSims = action.telecomSuggestionsFilter.mobileNumberSims;
                    telecomSuggestionsFilter.mobileData = action.telecomSuggestionsFilter.mobileData;
                    telecomSuggestionsFilter.unlimitedMobileMinutes = action.telecomSuggestionsFilter.unlimitedMobileMinutes;
                }

                if (this.isSelected(telecomBundle, TelecomBundle.FixedLine)) {
                    telecomSuggestionsFilter[TelecomBundle.FixedLine] = true;
                }

                return this.telecomService.findSuggestions(telecomSuggestionsFilter).pipe(
                    map((telecomSuggestions) =>
                        getTelecomSuggestionsSuccess({
                            telecomSuggestions,
                        })
                    ),
                    catchError((errorResponse) => of(getTelecomSuggestionsFailed()))
                );
            })
        )
    );

    private isSelected(telecomBundle, TelecomBundle: TelecomBundle): boolean {
        return isBoolean(telecomBundle[TelecomBundle]) && telecomBundle[TelecomBundle];
    }

    getTelecomSuggestionsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getTelecomSuggestionsSuccess),
                tap((_) => this.uiSandbox.hideLoadingOverlay())
            ),
        {
            dispatch: false,
        }
    );

    getTelecomSuggestionsFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getTelecomSuggestionsFailed),
                tap((_) => this.uiSandbox.hideLoadingOverlay())
            ),
        {
            dispatch: false,
        }
    );
}
