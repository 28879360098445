import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivityLogSandbox } from '@app/activity-log/sandboxes/activity-log.sandbox';
import { interval, Subscription } from 'rxjs';
import { flatMap, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-activity-log-heartbeat',
    template: ``,
})
export class ActivityLogHeartbeatComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    constructor(private activityLogSandbox: ActivityLogSandbox) {}

    public ngOnInit() {
        this.subscription = interval(1 * 60 * 60 * 1000) //hours * minutes * seconds * milliseconds
            .pipe(
                startWith(0),
                flatMap(() => this.activityLogSandbox.sendHeartbeat())
            )
            .subscribe();
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
