import { Injectable } from '@angular/core';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Move } from '@app/move/interfaces/move';
import { MoveForLeaver } from '@app/wizard/move/interfaces/move-for-leaver';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, StringUtils } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ActiveMoveModal } from '../modals/active-move/active-move.modal';
import { MoveSandbox } from './move.sandbox';

@Injectable({
    providedIn: 'root',
})
export class MoveConversionSandbox {
    constructor(
        private moveSandbox: MoveSandbox,
        private uiSandbox: AppUiSandbox,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService
    ) {}

    /**
     * This method wil open confirmation modal to convert the moves
     * @param currentMove
     * @param noConversion
     */
    public confirmConversion(currentMove: Move, noConversion?: boolean): Observable<{ change: boolean; move: Move }> {
        let activeMove: Move;
        return this.moveSandbox.getActiveMoveByEmail(currentMove?.user?.email).pipe(
            mergeMap((oldMove) => {
                activeMove = oldMove;
                if (!oldMove) return of(false);
                return this.showActiveMoveModal(currentMove, oldMove);
            }),
            mergeMap((change) => {
                this.uiSandbox.showLoadingOverlay();
                if (noConversion) return of({ change, move: null });
                return this.convertActiveMove(change, activeMove);
            })
        );
    }

    public confirmLeaverConversion(leaverMove: MoveForLeaver | Move): Observable<{ change: boolean; move: Move }> {
        let activeMove: Move;
        return this.moveSandbox.getActiveMoveByEmail(leaverMove?.emailLeaver).pipe(
            tap((oldMove) => (activeMove = oldMove)),
            switchMap(() => this.moveSandbox.get(DbUtils.getStringId(activeMove.linkedMove))),
            switchMap((currentMove) =>
                this.showActiveMoveModal(leaverMove.hasOwnProperty('user') ? leaverMove : currentMove, activeMove, MoverRole.Leaver)
            ),
            switchMap((change) => this.convertActiveMove(change, activeMove))
        );
    }

    public showActiveMoveModal(currentMove: Move, activeMove?: Move, role?: MoverRole): Observable<boolean> {
        return new Observable<boolean>((emit) => {
            this.modalSandbox.openModal(
                ActiveMoveModal,
                {
                    data: {
                        currentMove,
                        activeMove,
                        role,
                    },
                },
                (result) => emit.next(result),
                ActiveMoveModal,
                {
                    data: {
                        currentMove,
                        activeMove,
                        role,
                    },
                },
                (result) => emit.next(result)
            );
        });
    }

    public showPrefillMove(activeMove: Move, callback?: (activeMove: Move) => any, moverRole?: MoverRole) {
        if (!activeMove) return;
        const role = moverRole ? (this.translateService.instant(`MOVE.USER.ROLE.${moverRole.toUpperCase()}`) as string).toLowerCase() : null;
        const data = role ? this.translateService.instant('MOVE.USER.PREFILL_CONFIRM_WITH_ROLE', { email: activeMove.user.email, role }) : this.translateService.instant('MOVE.USER.PREFILL_CONFIRM', { email: activeMove.user.email });
        const handleConfirmation = (result: boolean) => {
            if (!result) return;
            callback(activeMove);
        };

        this.modalSandbox.openConfirmModal({ data }, handleConfirmation, { data }, handleConfirmation);
    }

    private convertActiveMove(toChange: boolean, activeMove: Move): Observable<{ change: boolean; move: Move }> {
        if (!toChange) return of({ change: toChange, move: activeMove });
        return this.moveSandbox.expireMove(activeMove._id).pipe(
            map(() => {
                return { change: toChange, move: activeMove };
            })
        );
    }
}
