import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '@smooved/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-real-estate-agent-register-welcome-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-margin-bottom u-flex-column u-flex-align-items-center">
                    <img class="u-margin-bottom-double" width="220" src="assets/images/hero.png" alt="Smooved" />

                    <h3 class="u-margin-bottom-double">
                        {{
                            'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.WELCOME.TITLE'
                                | translate: { firstName: (firstNameInvited$ | async) }
                        }}
                    </h3>

                    <p class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium">
                        {{
                            'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.WELCOME.TEXT'
                                | translate: { firstName: (firstNameReferrer$ | async) }
                        }}
                    </p>

                    <app-button routerLink="/authentication/real-estate-agent/register/contact-details">{{
                        'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.WELCOME.CTA' | translate
                    }}</app-button>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class RealEstateAgentRegisterWelcomeContainer implements OnInit {
    public firstNameInvited$: Observable<string> = this.realEstateAgentSandbox.realEstateAgentRegisterData$.pipe(
        map((invitedData) => (invitedData?.firstName ? invitedData.firstName : ''))
    );
    public firstNameReferrer$: Observable<string> = this.realEstateAgentSandbox.realEstateAgentRegisterData$.pipe(
        mergeMap((invitedData) => {
            if (invitedData?.createdByRole === Role.Admin) {
                return this.translateService.stream(
                    'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.WELCOME.SMOOVED'
                ) as Observable<string>;
            } else {
                return invitedData?.createdByRealEstateAgent?.firstName
                    ? of(invitedData.createdByRealEstateAgent.firstName)
                    : (this.translateService.stream(
                          'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.WELCOME.YOUR_COLLEAGUE'
                      ) as Observable<string>);
            }
        })
    );

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private navigationSandbox: AppNavigationSandbox,
        private realEstateAgentSandbox: RealEstateAgentSandbox
    ) {}

    public ngOnInit(): void {
        const confirmationToken: string = this.activatedRoute.snapshot.queryParams['ct'] as string;
        if (confirmationToken) {
            this.realEstateAgentSandbox.getByConfirmationToken(confirmationToken).subscribe(
                (realEstateAgent) => {
                    this.realEstateAgentSandbox.setInviteData({
                        confirmationToken,
                        ...realEstateAgent,
                    });
                },
                (_) => {
                    void this.navigationSandbox.goToHome();
                }
            );
        }
    }
}
