<app-card-container
    class="u-flex-row u-flex-justify-content-center u-padding-none-up-to-and-including-phone-landscape"
    [ngStyle]="{ 'max-width': !!maxWidth ? maxWidth : 'none' }"
>
    <div class="u-container">
        <app-loading-container [loading]="loading">
            <app-ui-card [header]="header" [variant]="headerVariants.H3" [stretchOnMobile]="true" class="__wizard-step-card">
                <ng-container card-header-top><ng-content select="[card-header-top]"></ng-content></ng-container>
                <p *ngIf="(!!current || !!total) && showStepIndication" class="u-color-muted" card-header>
                    {{
                        'UI.STEP_INDICATION'
                            | translate
                                : {
                                      start: current,
                                      end: total
                                  }
                    }}
                </p>
                <ng-container card-header-right><ng-content select="[card-header-right]"></ng-content></ng-container>
                <ng-container card-header><ng-content select="[card-header]"></ng-content></ng-container>
                <form class="u-flex-column u-w100p" [class.u-margin-top-triple]="showStepIndication" [ngClass]="bodyClass">
                    <ng-content></ng-content>
                    <app-previous-submit
                        *ngIf="canNavigate"
                        [submitTranslationLabel]="submitTranslationLabel"
                        [showPrevious]="hasPrevious"
                        [showSubmit]="hasNext"
                        (previous)="onPrevious()"
                        [disabled]="loading"
                        [loading]="loading"
                        [context]="submitButtonContext"
                        (next)="onSubmit(form?.value)"
                    ></app-previous-submit>
                </form>
            </app-ui-card>
        </app-loading-container>
    </div>
</app-card-container>
