<div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center u-margin-y-axis">
    <h4>{{ 'ANALYTICS.DASHBOARD.INFLUENCERS.LABEL' | translate }}</h4>
    <a app-button appearance="link" [routerLink]="reviewsRoute" [appendIcon]="true" icon="chevron_right">{{
        'COMMON.DETAILS' | translate
    }}</a>
</div>
<div class="__influencers-container">
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.INFLUENCERS.TOOLTIP.SMOOVED_ARC' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="__score u-h100p u-flex-column u-flex-justify-content-center"
    >
        <div class="u-text-align-right u-margin-x-axis-double"></div>
        <div class="u-flex u-flex-align-items-center" *ngIf="widgetData$ | async as widgetData">
            <ng-container *ngIf="widgetData?.score; else noReviews">
                <app-arc-score [data]="widgetData.arcScore" [value]="widgetData.score"></app-arc-score>
                <div class="u-margin-left-double">
                    <h3>{{ widgetData.scoreScale | i18nKey: i18nKeyType.ReviewScore | translate }}</h3>
                    <p class="u-color-muted">
                        {{ widgetData.overall?.total }} {{ 'ANALYTICS.DASHBOARD.INFLUENCERS.REVIEWS' | translate | lowercase }}
                    </p>
                </div>
            </ng-container>
            <ng-template #noReviews>
                <app-arc-score [data]="widgetData?.arcScore" value="-"></app-arc-score>
                <div class="u-margin-left-double">
                    <h5>{{ 'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.TITLE' | translate }}</h5>
                    <p
                        class="u-color-muted u-font-size-small"
                        [innerHtml]="'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.DESCRIPTION' | translate"
                    ></p>
                </div>
            </ng-template>
        </div>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.INFLUENCERS.TOOLTIP.BAD_REVIEWS' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="__bad-reviews u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.INFLUENCERS.BAD_REVIEWS',
                    value: analytics.influencers.badReviews,
                    route: reviewsRoute,
                    queryParams: filterBuckets
                }
            }"
        ></ng-container>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.INFLUENCERS.TOOLTIP.REVIEWS' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="__total u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.INFLUENCERS.REVIEWS',
                    value: analytics.influencers.total
                }
            }"
        ></ng-container>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.INFLUENCERS.TOOLTIP.GIFTS' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="__gifts u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.INFLUENCERS.GIFTS',
                    value: analytics.influencers.gifts,
                    route: dashboardRoute,
                    queryParams: giftsQueryParams
                }
            }"
        ></ng-container>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.INFLUENCERS.TOOLTIP.UNANSWERED' }"
        [border]="false"
        [context]="cardContext.Danger"
        class="__unanswered u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.INFLUENCERS.UNANSWERED',
                    value: analytics.influencers.unanswered,
                    route: reviewsRoute,
                    queryParams: filterUnanswered
                }
            }"
        ></ng-container>
    </app-context-card>
</div>

<ng-template #kpi let-item>
    <h3>{{ item.value }}</h3>
    <p class="u-font-size-small">{{ item.label | translate }}</p>
    <a
        app-button
        *ngIf="item.route"
        [routerLink]="item.route"
        [queryParams]="item.queryParams"
        size="sm"
        appearance="link"
        [appendIcon]="true"
        icon="chevron_right"
        >{{ 'COMMON.SHOW' | translate }}</a
    >
</ng-template>

<ng-template #tooltipTemplate let-item>
    <p class="u-font-size-default u-font-weight-normal u-w25vw">{{ item | translate }}</p>
</ng-template>
