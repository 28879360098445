import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TelecomSuggestion } from '@app/telecom/interfaces/telecom-suggestion';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-suggestion-selection',
    templateUrl: 'suggestion-selection.component.html',
    styleUrls: ['./suggestion-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionComponent {
    @Input() public type: UiOfferType;
    @Input() public suggestion: EnergySuggestion | TelecomSuggestion | InsurancesSuggestion;
    @Input() public bordered = false;
    @Input() public unselectable = false;
    @Input() public paymentType: PaymentType;
    @Input() public showEdit = false;
    @Input() public showPaymentType = false;
    @Input() public minimal = false;
    @Input() public minimalWithoutPrice = false;
    @Input() public suggestionsRoute: string;

    @Input() public extraInfoEnergy = false;
    @Input() public showExtraInfoInsurances: boolean;

    public svg = Svg;
    public uiOffer = UiOfferType;

    constructor(public moveSandbox: MoveSandbox, public uiSandbox: AppUiSandbox) {}

    public unselect(): void {
        switch (this.type) {
            case UiOfferType.Energy:
                const payloadEnergy: Move = {
                    energySelected: false,
                    energyOffer: {
                        energyOfferSelection: null,
                    },
                };
                this.moveSandbox.patchProperty('', payloadEnergy);
                break;

            case UiOfferType.EnergyTransfer:
                const payloadEnergyTransfer: Move = {
                    energySelected: false,
                    energyTransfer: null,
                };
                this.moveSandbox.patchProperty('', payloadEnergyTransfer);
                break;

            case UiOfferType.Telecom:
                const payloadTelecom: Move = {
                    telecomSelected: false,
                    telecomOffer: {
                        telecomOfferSelection: null,
                    },
                };
                this.moveSandbox.patchProperty('', payloadTelecom);
                break;

            case UiOfferType.TelecomTransfer:
                const payloadTelecomTransfer: Move = {
                    telecomSelected: false,
                    telecomTransfer: null,
                };
                this.moveSandbox.patchProperty('', payloadTelecomTransfer);
                break;

            case UiOfferType.Insurances:
                const payloadInsurances: Move = {
                    insurancesSelected: false,
                    insurancesOffer: {
                        insurancesOfferSelection: null,
                    },
                };
                this.moveSandbox.patchProperty('', payloadInsurances);
                break;

            default:
        }
    }
}
