import * as analyticsUri from '@app/analytics/constants/uri.constants';
import { externalInfluencerExceptions } from '@app/external-influencer/constants/exception.constants';
import * as movesUri from '@app/move/constants/uri.constants';

export const MOVE_ALREADY_CONFIRMED = 'MOVE_ALREADY_CONFIRMED';
export const MOVE_DOES_NOT_EXIST = 'MOVE_DOES_NOT_EXIST';

export const errorsWithoutNotification: string[] = [MOVE_DOES_NOT_EXIST, MOVE_ALREADY_CONFIRMED, externalInfluencerExceptions.notFound];

export const endpointsWithoutNotification: string[] = [analyticsUri.npsUnreadCount, movesUri.realEstateAgentUrgentTodoCountUri];

export const noErrorNotificationHeader = 'Smvd-No-Error-Notification';
