export interface FamilyMemberCount {
    childrenCount: number;
    teenagersCount: number;
    adultsCount: number;
}

export enum HomeDescriptionSize {
    Size0to50 = '0-50',
    Size0to125 = '0-125',
    Size0to200 = '0-200',
    Size50to100 = '50-100',
    Size100to150 = '100-150',
    Size125to200 = '125-200',
    Size200to250 = '200-250',
    Size250to300 = '250-300',
}

export enum HomeConstructionYear {
    Before2000BuiltOrRenovated = 'before2000BuiltOrRenovated',
    After2000BuiltOrRenovated = 'after2000BuiltOrRenovated',
    NewConstruction = 'newConstruction',
}
