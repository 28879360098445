import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Move } from '@app/move/interfaces/move';
import { ExtraInfoModal } from '@app/move/modals/extra-info/extra-info.modal';
import { MoveEditModalComponent } from '@app/real-estate-agent/modals/move-edit/move-edit.modal';
import { ModalSandbox, UriSchemaType } from '@smooved/ui';
import { MoveUtils } from '@app/move/state/move.utils';
import {MoveTransactionType} from "@smooved/core";

@Component({
    selector: 'app-transferee-detail',
    template: `
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">
                {{ label | translate }}
                <span *ngIf="moveTransactionType">
                    ({{ moveTransactionType | titlecase }})
                </span>
                <button
                    app-button
                    icon="edit"
                    appearance="icon"
                    (click)="openEdit(move._id)"
                    *ngIf="canEdit(move)"
                    context="muted"
                    class="__edit-button"
                ></button>
            </h6>
            <span *ngIf="!!move?.user?.formattedName">{{ move.user.formattedName }}</span>
            <span *ngIf="!!move?.user?.phoneNumber">{{ move.user.phoneNumber }}</span>
            <a class="u-link" [href]="move?.user?.email | uriSchema: mailTo">{{ move?.user?.email }}</a>
            <span *ngIf="!!move?.user?.formattedDateOfBirth">{{ move.user.formattedDateOfBirth }}</span>
            <span *ngIf="!!move?.user?.gender">{{ move.user.gender.toString().toUpperCase() | translate }}</span>
            <app-user-language *ngIf="!!move?.user?.language" [language]="move.user.language"></app-user-language>

            <a class="u-link" (click)="showMoreInfo()">{{ 'COMMON.MORE_INFO' | translate }}</a>

            <h6 class="u-margin-top-double u-margin-bottom-half u-color-muted">{{ 'HOME_DESCRIPTION.LABEL' | translate }}</h6>
            <span *ngIf="move?.meta?.homeDescription">{{
                'HOME_DESCRIPTION.' + (move.meta.homeDescription | uppercase) + '.LABEL' | translate
            }}</span>
            <span *ngIf="move?.meta?.homeConstructionYear">{{
                'HOME_CONSTRUCTION_YEAR.' + (move.meta.homeConstructionYear | uppercase) | translate
            }}</span>

            <h6 *ngIf="!!move?.companyName || !!move?.vatNumber" class="u-margin-top-double u-margin-bottom-half u-color-muted">
                {{ 'MOVE.COMPANY_DETAILS' | translate }}
            </h6>
            <span *ngIf="!!move?.companyName">{{ move.companyName }}</span>
            <span *ngIf="!!move?.vatNumber">{{ move.vatNumber }}</span>
        </div>
    `,
    styleUrls: ['./transferee-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransfereeDetailComponent implements OnInit {
    @Input() public move: Move;
    @Input() public label = 'MOVE.MOVER_ROLE.TRANSFEREE';
    @Input() public readOnly = false;

    @Output() public moveChange: EventEmitter<Move> = new EventEmitter<Move>();

    public transferee = Transferee;
    public mailTo = UriSchemaType.MailTo;
    public moveTransactionType: MoveTransactionType;

    constructor(private modalSandbox: ModalSandbox) {}

    public ngOnInit(): void {
        this.moveTransactionType = MoveUtils.getMoveTransactionType(this.move);
    };

    public showMoreInfo() {
        this.modalSandbox.openModal(
            ExtraInfoModal,
            {
                data: this.move,
            },
            null,
            ExtraInfoModal,
            {
                data: this.move,
            },
            null
        );
    }

    public canEdit(move: Move): boolean {
        return !this.readOnly && !move?.energyOrderedByAdmin && !move?.telecomOrderedByAdmin;
    }

    public openEdit(moveId: string): void {
        this.modalSandbox.openModal(null, null, null, MoveEditModalComponent, { data: moveId }, (move) => {
            if (!move) return;
            this.moveChange.emit(move);
        });
    }
}
