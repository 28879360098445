import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginRequest } from '@app/authentication/interfaces/login-request';
import { Move } from '@app/move/interfaces/move';
import { Observable } from 'rxjs';
import { ResetPasswordForm } from '../components/reset-password/reset-password.constants';
import * as authenticationUri from '../constants/uri.constants';
import { Authorization } from '../interfaces/authorization';
import { ConfirmUserLoginRequest } from '../interfaces/confirm-user-login.request';
import { RealEstateAgentLoginRequest } from '../../../../projects/core/src/lib/authentication/interfaces/real-estate-agent-login.request';
import { TokenResponse } from '../interfaces/token-response';
import { UserLoginRequest } from '../interfaces/user-login.request';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private httpClient: HttpClient) {}

    public authorization(): Observable<Authorization> {
        return this.httpClient.get<Authorization>(authenticationUri.authorizationUri);
    }

    public login(loginRequest: LoginRequest): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.loginUri, loginRequest);
    }

    public loginByAccessTokenAndId(accessToken: string, id: string): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.loginByAccessTokenAndIdUri, { accessToken, id });
    }

    public loginByLoginToken(loginToken: string): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.loginByLoginTokenUri, { loginToken });
    }

    public realEstateAgentLogin(realEstateAgentLoginRequest: RealEstateAgentLoginRequest): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.realEstateAgentLoginUri, realEstateAgentLoginRequest);
    }

    public realEstateAgentResetPassword(email: string): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.realEstateAgentResetPasswordUri, { email });
    }

    public realEstateAgentResetPasswordNew(password: string, token: string, id: string): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.realEstateAgentResetPasswordNewUri, {
            password,
            token,
            id,
        });
    }

    public userLogin(loginRequest: UserLoginRequest): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.userLoginUri, loginRequest);
    }

    public userResetPassword(form: ResetPasswordForm): Observable<void> {
        return this.httpClient.post<void>(authenticationUri.userResetPasswordUri, form);
    }

    public impersonateRealEstateAgent(realEstateAgentId: string): Observable<any> {
        return this.httpClient.post<any>(authenticationUri.impersonateRealEstateAgentUri, { realEstateAgentId });
    }

    public confirmUserLogin(confirmUserLoginRequest: ConfirmUserLoginRequest): Observable<{ move: Move; token: string }> {
        const httpParams: HttpParams = new HttpParams().set('accessToken', confirmUserLoginRequest.accessToken);
        return this.httpClient.post<{ move: Move; token: string }>(authenticationUri.confirmUserLoginUri, null, {
            params: httpParams,
        });
    }

    public logout(): Observable<void> {
        return this.httpClient.get<void>(authenticationUri.logoutUri);
    }

    public refreshToken(): Observable<TokenResponse> {
        return this.httpClient.get<TokenResponse>(authenticationUri.refreshToken);
    }
}
