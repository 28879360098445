import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-water-documents-moving-address-by-admin',
    template: `
        <div [ngSwitch]="!!move?.moveStates?.waterDocumentsMovingAddressByAdmin">
            <div
                class="u-flex-row u-flex-align-items-center"
                [ngClass]="{ 'u-color-muted': !move?.moveStates?.waterDocumentsMovingAddressByAdmin }"
            >
                <div *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{
                        'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.LABEL' | translate
                    }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.LABEL' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <app-button
                    id="check-button"
                    *ngSwitchCase="false"
                    [disabled]="
                        !move?.moveStates?.waterMetersProcessedByAdmin || !move?.moveStates?.waterDocumentsMovingAddressByRealEstateAgent
                    "
                    (click)="check()"
                    theme="secondary-outline"
                    >{{ 'CHECK' | translate }}</app-button
                >
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterDocumentsMovingAddressByAdminComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        super(cdr, moveSandbox);
    }

    public check(): void {
        const config = {
            header: this.translateService.instant(
                'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.CONFIRM_MODAL.TITLE'
            ) as string,
            question: this.translateService.instant(
                'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.CONFIRM_MODAL.TEXT'
            ) as string,
        };
        this.modalSandbox.openConfirmModal(config, this.confirmCheck, config, this.confirmCheck);
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            this.move.waterDocumentsMovingAddressByAdmin ? 'waterDocumentsMovingAddressByAdmin' : 'waterCollectiveMeter',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    private confirmCheck = (confirm: boolean): void => {
        if (!confirm) return;
        this.moveSandbox.patchProperty(
            'waterDocumentsMovingAddressByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    };
}
