<app-card-container [hasPaddingUpToAndIncludingPhoneLandscape]="false" class="__card__container">
    <app-loading-container
        [loading]="vm.loading"
        *ngIf="{ npsScore: reviewScores$ | async, total: total$ | async, ninetyDaysTotal: ninetyDaysTotal$ | async, loading: loading$ | async, isOthers: isOthersFilter$ | async } as vm"
    >
        <div class="__content__container __content__container--has-aside">
            <div class="__grid-area--header u-padding-md-phone-landscape-up u-padding u-background-white u-h100p">
                <h4>{{ 'ANALYTICS.MENU.REVIEWS' | translate }}</h4>
                <p class="u-color-muted">
                    <span class="u-dot-after">{{ vm.total }} {{ "INSIGHTS.DASHBOARD.REVIEWS" | translate }}</span>
                    <span>{{ vm.ninetyDaysTotal }} {{ "INSIGHTS.DASHBOARD.LAST_90_DAYS" | translate }}</span>
                </p>
            </div>
            <div
                class="__grid-area--tabs u-background-white u-padding-left-md-tablet-landscape-up u-padding-right-md-tablet-landscape-up u-h100p"
            >
                <app-nps-reviews-filter
                    class="u-display-block u-padding-bottom-md-phone-landscape-up"
                    (selected)="onFilterSelection('tab', $event)"
                ></app-nps-reviews-filter>
                <div class="u-bordered-bottom-ultralight u-hide-up-to-and-including-tablet-portrait"></div>
            </div>
            <app-nps-reviews-template
                class="__grid-area--reviews u-background-white"
                #reviewsTemplate
                [npsActionsTemplate]="npsActions"
                [buckets]="preSelectedBuckets"
            >
            </app-nps-reviews-template>
            <div
                class="u-padding-top-md-phone-landscape-up u-padding-x-axis u-padding-left-md-phone-landscape-up u-background-white __grid-area--scores"
            >
                <ui-star-score
                    [context]="uiContext.HouseMatchPrimary"
                    [score]="(insightsSandbox.houseMatchScore$ | async) | starsScore"
                    width="24px"
                ></ui-star-score>
                <div class="u-flex-row u-flex-align-items-baseline u-margin-y-axis-half">
                    <smvd-ui-svg-illustration
                        [svg]="svgIllustration.HouseMatchIcon"
                        [context]="uiContext.HouseMatchPrimary"
                        [style.width]="'22px'"
                        class="u-margin-right"
                    ></smvd-ui-svg-illustration>
                    <h3 class="u-color-housematch-secondary u-font-family-cera-pro">{{ insightsSandbox.houseMatchScore$ | async }}</h3>
                    <p class="u-color-muted u-font-family-cera-pro">
                        <span class="u-dot-after"> / 10 </span> {{ (insightsSandbox.insightsData$ | async)?.total || 0 }} {{
                        'ANALYTICS.MENU.REVIEWS' | translate | lowercase }}
                    </p>
                </div>

                <ui-star-score [score]="vm.npsScore?.overall?.externalScore"></ui-star-score>
                <div class="u-flex-row u-flex-align-items-baseline u-margin-top-half u-bordered-bottom-lightest u-padding-bottom">
                    <div class="u-position-relative">
                        <smvd-ui-svg-illustration
                            [svg]="svgIllustration.Google"
                            [style.width]="'16px'"
                            class="u-margin-right"
                        ></smvd-ui-svg-illustration>
                        <smvd-ui-svg-illustration
                            [svg]="svgIllustration.FacebookScore"
                            [style.width]="'24px'"
                            [style.height]="'24px'"
                            class="__external-icon-overlap"
                        ></smvd-ui-svg-illustration>
                    </div>
                    <p class="u-font-weight-semi-bold">{{ (vm.npsScore?.overall?.externalScore | arcScoreValue:5) || 0 }}</p>
                    <p class="u-color-muted u-font-size-small">
                        <span class="u-dot-after"> / 5 </span> {{ vm.npsScore?.overall?.externalTotal || 0 }} {{ 'ANALYTICS.MENU.REVIEWS' |
                        translate | lowercase }}
                    </p>
                </div>
                <div class="u-bordered-bottom-ultralight u-hide-up-to-and-including-tablet-portrait"></div>
            </div>
            <div class="__grid-area--filters u-padding u-padding-top-md u-background-white">
                <smvd-ui-reviews-by-location
                    class="u-display-block u-w100p u-margin-bottom-half"
                    (selected)="onFilterSelection('location', $event)"
                    width="100%"
                ></smvd-ui-reviews-by-location>
                <div class="u-bordered-bottom-ultralight u-hide-tablet-portrait-up"></div>
                <div class="u-hide-up-to-and-including-tablet-portrait u-margin-bottom-md">
                    <h6 class="u-color-muted u-margin-bottom-half">{{ 'ANALYTICS.REVIEWS.SCORES' | translate }}</h6>
                    <app-nps-review-buckets
                        [context]="uiContext.CoBrand"
                        [preSelectedBuckets]="preSelectedBuckets"
                        (selected)="onFilterSelection('buckets', $event)"
                    ></app-nps-review-buckets>
                </div>
                <smvd-ui-reviews-segments
                    class="u-hide-up-to-and-including-tablet-portrait"
                    [reviews]="vm.npsScore"
                    [title]="'ANALYTICS.REVIEWS.AUTHOR'"
                    [showArcScore]="false"
                    [disabled]="vm.isOthers"
                    (selected)="onFilterSelection('buckets', $event)"
                    *hasFeatureAccess="[featureScope.ReviewInsights]"
                ></smvd-ui-reviews-segments>
            </div>
        </div>
    </app-loading-container>
</app-card-container>

<ng-template #npsActions let-item>
    <app-nps-actions-full [review]="item" [npsReviewsTemplateService]="npsReviewsTemplateService"></app-nps-actions-full>
</ng-template>
