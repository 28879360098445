import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { WizardComponent } from '@app/move/components/wizard/wizard.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-date-of-birth',
    template: `
        <form novalidate [formGroup]="form" (ngSubmit)="onSubmit($event)" class="u-flex-column">
            <app-date-input
                id="date-of-birth"
                [label]="'DATE_OF_BIRTH' | translate"
                [placeholder]="'DATE_OF_BIRTH.PLACEHOLDER' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [maxDate]="maxDateOfBirth"
                formControlName="dateOfBirth"
            ></app-date-input>

            <app-previous-submit [showPrevious]="false"></app-previous-submit>
        </form>
    `,
    styles: [],
})
export class DateOfBirthComponent extends WizardComponent implements OnInit {
    public maxDateOfBirth: Date = new Date();

    public form = this.formBuilder.group({
        dateOfBirth: [null, Validators.required],
    });

    constructor(private formBuilder: UntypedFormBuilder, private moveSandbox: MoveSandbox) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.userOnce$.subscribe((user) => {
            this.dateOfBirthFormControl().patchValue(user?.dateOfBirth);
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.next.emit();
        }
    }

    private dateOfBirthFormControl(): AbstractControl {
        return this.form.get('dateOfBirth');
    }

    public ngOnDestroy(): void {
        this.moveSandbox.patchProperty('user.dateOfBirth', this.dateOfBirthFormControl().value);
    }
}
