import { Injectable } from '@angular/core';
import { fromDate } from '@app/analytics/constants/analytics.constants';
import { MovingDateVsCreatedOnGap } from '@app/move/interfaces/moving-date-vs-created-on-gap';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { PerUnitOfTime } from '@app/shared/enums/per-unit-time.enum';
import { NumberUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MovingDateVsCreatedOnGapService {
    constructor(private readonly smoovedAnalyticsSandbox: SmoovedAnalyticsSandbox) {}

    fetchData(): Observable<MovingDateVsCreatedOnGap> {
        return this.smoovedAnalyticsSandbox.getMovingDateVsCreatedOnGap(PerUnitOfTime.Quarter, fromDate).pipe(
            map((gap) => ({
                ...gap,
                priorPercent: `${Math.round(NumberUtils.percentage(gap.prior, gap.prior + gap.after, true) * 100)}%`,
                afterPercent: `${Math.round(NumberUtils.percentage(gap.after, gap.prior + gap.after, true) * 100)}%`,
            }))
        );
    }
}
