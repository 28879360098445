import { Component, OnInit } from '@angular/core';
import { StringUtils } from '@smooved/core';
import { BaseDashboardCardComponent } from '@smooved/ui';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-highway-info-box',
    template: `
        <app-dashboard-card
            [first]="true"
            [title]="'REAL_ESTATE_AGENT.START.HIGHWAY.INFO_BOX.TITLE' | translate"
            [last]="true"
            [minHeight]="'10px'"
        >
            <ng-container up-to-and-including-phone-landscape>
                <div class="u-flex-column u-flex-align-items-stretch">
                    <div class="u-margin-bottom-none u-margin-top-xs u-font-size-16 u-flex u-flex-wrap">
                        <p class="u-margin-right-xs">{{ 'REAL_ESTATE_AGENT.START.HIGHWAY.INFO_BOX.TEXT' | translate }}</p>
                        <p class="u-margin-right-xs u-margin-top-half">
                            {{ 'REAL_ESTATE_AGENT.START.HIGHWAY.INFO_BOX.ACCESS' | translate }}
                            <a class="u-link u-text-truncate" [href]="companionLink" target="_blank">{{ companionLinkText }}</a>
                        </p>
                    </div>
                </div>
            </ng-container>
        </app-dashboard-card>
    `,
})
export class HighwayInfoBoxComponent extends BaseDashboardCardComponent implements OnInit {
    public companionLink: string;
    public companionLinkText: string;
    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.companionLink = `${environment.appUri}/companion`;
        this.companionLinkText = StringUtils.stripHttpProtocolWithoutTrailing(this.companionLink);
    }
}
