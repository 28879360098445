import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-moving-date-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    [id]="id"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    (blur)="onBlur($event)"
                    [errorStateMatcher]="errorStateMatcher"
                    [matDatepicker]="datePicker"
                    autocomplete="smooved"
                />
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </app-label-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MovingDateInputComponent),
            multi: true,
        },
    ],
})
export class MovingDateInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id = 'moving-date';
    @Input() public label: string = this.translateService.instant('FORM.MOVING_DATE.LABEL');
    @Input() public placeholder: string = this.translateService.instant('FORM.MOVING_DATE.PLACEHOLDER');
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public innerDisabled: boolean = false;

    public innerModel: Date;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private translateService: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: Date): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
