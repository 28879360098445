import { Component, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClosableModalTemplateComponent } from '@smooved/ui';
import { EmojiIcon } from '../../../wizard/icon/models/emoji-icon.enum';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

@Component({
    selector: 'app-login-dialog',
    template: `
        <app-closable-modal-template>
            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <h3 class="u-margin-bottom">{{ 'AUTHENTICATION.USER.LOGIN.TITLE' | translate }}</h3>
                <p class="u-margin-bottom" [innerHTML]="'AUTHENTICATION.USER.LOGIN.TEXT' | translate"></p>
                <app-email-input [autoFocus]="true" width="100%" formControlName="email"></app-email-input>
                <div class="u-flex-row u-flex-justify-content-center">
                    <app-button type="submit" [disabled]="form.invalid">{{ 'AUTHENTICATION.USER.LOGIN.CTA' | translate }}</app-button>
                </div>
            </form>
        </app-closable-modal-template>
    `,
    styles: [],
})
export class LoginDialog {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public emojiIcon = EmojiIcon;

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
    });

    constructor(private formBuilder: UntypedFormBuilder, private authenticationSandbox: AuthenticationSandbox) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.authenticationSandbox
                .login({
                    email: this.emailFormControl().value,
                })
                .subscribe((_) => {
                    this.closeDialog();
                });
        } else {
            this.closeDialog();
        }
    }

    private closeDialog(): void {
        this.closableModalComponent.close();
    }

    private emailFormControl(): AbstractControl {
        return this.form.get('email');
    }
}
