import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { CanColor, CanDisable, ThemePalette } from '@angular/material/core';
import { ElementUtils, EventArg, EventName, SimpleChangesUtils, StringUtils } from '@smooved/core';
import { Brand, UiIconAppearance, UiIconSize } from '../icon';
import { UiContext, UiRotate } from '../ui.enums';
import { ButtonAppearance, ButtonSize } from './button.enums';

const defaultButtonClassName = 'mat-mdc-button-base ui-button';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[app-button], a[app-button], button[smvd-ui-button], a[smvd-ui-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    exportAs: 'appButton',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements CanDisable, CanColor, OnInit, OnChanges {
    @Input() public appearance = ButtonAppearance.Default;
    @Input() public appendIcon = false;
    @Input() public context = UiContext.Primary;
    @Input() public iconContext;
    @Input() public icon: string;
    @Input() public iconWithLabel: boolean;
    @Input() public isLoading = false;
    @Input() public iconAppearance = UiIconAppearance.Outlined;
    @Input() public iconSize = UiIconSize.Xm;
    @Input() public iconRotate = UiRotate.Default;
    @Input() public size = ButtonSize.Default;
    @Input() public brand: Brand;

    public color: ThemePalette;
    public defaultColor: ThemePalette;
    public disabled: boolean;
    public buttonAppearance = ButtonAppearance;

    public resetClassNames(): void {
        const currentClasses = ElementUtils.getElementClassList(this.el, /ui-button|mat-.*-button/);
        currentClasses.forEach((c) => this.renderer.removeClass(this.el.nativeElement, c));

        const baseClassName = defaultButtonClassName;
        const appearanceClass = this.getAppearanceClass(this.appearance);
        const contextClassName = this.getContextClass(this.context);
        const buttonSizeClass = this.getSizeClass(this.size);

        const classList = StringUtils.join([baseClassName, buttonSizeClass, appearanceClass, contextClassName]).split(' ');
        classList.forEach((c) => this.renderer.addClass(this.el.nativeElement, c));
    }

    @HostBinding('attr.type')
    public get type(): string {
        if (this.el.nativeElement.tagName === 'BUTTON') {
            return this.el.nativeElement?.getAttribute('type') || 'button';
        }
    }

    @HostListener(EventName.Click, [EventArg.$Event]) onClick(event: MouseEvent): void {
        if (this.isLoading) event.preventDefault();
    }

    constructor(private el: ElementRef, private readonly renderer: Renderer2) {}

    public ngOnInit(): void {
        this.resetClassNames();
    }

    public ngOnChanges({ appearance, context, size }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(appearance, context, size)) {
            this.resetClassNames();
        }
    }

    private getAppearanceClass = (appearance: ButtonAppearance): string => {
        switch (appearance) {
            case ButtonAppearance.Raised:
                return 'mat-mdc-raised-button';
            case ButtonAppearance.Stroked:
                return 'mat-mdc-outlined-button';
            case ButtonAppearance.Flat:
                return 'mat-mdc-unelevated-button';
            case ButtonAppearance.FlatTransparent:
                return 'mat-mdc-flat-transparent-button';
            case ButtonAppearance.Icon:
                return 'mat-mdc-icon-button';
            case ButtonAppearance.FlatIcon:
                return 'mat-mdc-icon-button mat-mdc-unelevated-button';
            case ButtonAppearance.StrokedIcon:
                return 'mat-mdc-icon-button mat-mdc-outlined-button';
            case ButtonAppearance.Link:
                return 'mat-mdc-link-button';
            case ButtonAppearance.LinkReversed:
                return 'mat-mdc-link-reversed-button';
            case ButtonAppearance.MobileIcon:
                return 'mat-mdc-unelevated-button mat-mobile-icon-button';
            default:
                return `mat-mdc-${ButtonAppearance.Default}-button`;
        }
    };

    private getContextClass(context): string {
        return `ui-button-${context || UiContext.Primary}`;
    }

    private getSizeClass(size = ButtonSize.Default): string {
        return size ? `ui-button-${size}` : `ui-button-${ButtonSize.Default}`;
    }
}
