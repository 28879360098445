import { Component, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MoveSandbox } from '../../../../move/sandboxes/move.sandbox';
import { EnergySandbox } from '../../../energy/sandboxes/energy.sandbox';
import { TelecomSandbox } from '../../../telecom/sandboxes/telecom.sandbox';

@Component({
    selector: 'app-products-terms-and-conditions-modal',
    template: `
        <app-closable-modal-template>
            <p *ngIf="!energyConditionsUrls.length && !telecomConditionsUrls.length">Er zijn nog geen producten geselecteerd.</p>

            <div *ngIf="energyConditionsUrls.length" class="u-margin-bottom-double">
                <h3 class="u-margin-bottom-half">Algemene voorwaarden energie</h3>
                <ul>
                    <li *ngFor="let energyConditionsUrl of energyConditionsUrls" class="u-margin-bottom-half">
                        <a [href]="energyConditionsUrl" target="_blank" class="u-link">{{ energyConditionsUrl }}</a>
                    </li>
                </ul>
            </div>

            <div *ngIf="telecomConditionsUrls.length">
                <h3 class="u-margin-bottom-half">Algemene voorwaarden telecom</h3>
                <ul>
                    <li *ngFor="let telecomConditionsUrl of telecomConditionsUrls" class="u-margin-bottom-half">
                        <a [href]="telecomConditionsUrl" download target="_blank" class="u-link">{{ telecomConditionsUrl }}</a>
                    </li>
                </ul>
            </div>
        </app-closable-modal-template>
    `,
})
export class ProductsTermsAndConditionsModalComponent implements OnInit {
    public energyConditionsUrls: string[] = [];
    public telecomConditionsUrls: string[] = [];

    constructor(private energySandbox: EnergySandbox, private telecomSandbox: TelecomSandbox, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        zip(
            this.moveSandbox.energySelectedOnce$,
            this.moveSandbox.telecomSelectedOnce$,
            this.moveSandbox.energyOfferSelection$.pipe(take(1)),
            this.moveSandbox.telecomOfferSelection$.pipe(take(1))
        )
            .pipe(
                take(1),
                tap(([energySelected, telecomSelected, energySuggestion, telecomSuggestion]) => {
                    if (energySelected) {
                        this.energyConditionsUrls = energySuggestion.urlConditions.split(',').filter((x) => !!x);
                    }
                    if (telecomSelected) {
                        this.telecomConditionsUrls = telecomSuggestion.urlConditions.split(',').filter((x) => !!x);
                    }
                })
            )
            .subscribe();
    }
}
