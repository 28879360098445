import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'distance',
})
export class DistancePipe implements PipeTransform {
    transform(value: number): string {
        if (!value) return '-';
        if (value < 1000) return (Math.round(value / 100) * 100).toString() + ' m';
        return (Math.round(value / 100) / 10).toString() + ' km';
    }
}
