import { CheckInput } from '@smooved/ui';

export enum EnergySuggestionsFields {
    FixedTariff = 'fixedTariff',
    PricesPerMonth = 'pricesPerMonth',
    EnergySuggestion = 'energySuggestion',
}

export const suggestionIndex = 0; // Index of element selected suggestion inside variableSuggestions (array of arrays (suggestion[][])).
export const maxSuppliersVisible = 5;

export const pricesPerMonthOption: CheckInput<boolean> = {
    id: 'prices-per-month',
    name: 'prices-per-month',
    value: true,
    labelResource: 'MOVE.ENERGY.SUGGESTIONS.SHOW_PRICES_PER_MONTH',
};
