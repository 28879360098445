import { ChangeDetectionStrategy, Component, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { allLocationsOption, ALL_LOCATIONS_VALUE } from '@app/real-estate-agent/constants/insights.constants';
import { InsightsSandbox } from '@app/real-estate-group/sandboxes/insight.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { SurveysSandbox } from '@app/surveys/sandboxes/surveys.sandbox';
import { AnalyticsEventsEnum, AnalyticsService, DbUtils, FeatureScope, FeatureScopeSandbox, RealEstateAgency } from '@smooved/core';
import { DropdownInput, UiContext, UiIcon, UiSandbox } from '@smooved/ui';
import { Observable, zip } from 'rxjs';
import { map, pluck, shareReplay, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-insights-container',
    templateUrl: './insights.container.html',
    styleUrls: ['./insights.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsContainer implements OnInit {
    public locations$: Observable<RealEstateAgency[]> = this.realEstateGroupSandbox
        .getRealEstateGroupForMe()
        .pipe(pluck('locations'), shareReplay(1));
    public locationFormControl = new UntypedFormControl(ALL_LOCATIONS_VALUE);
    public locationDropdownOptions: Observable<DropdownInput<string>[]>;

    public hasInsights$: Observable<boolean> = this.featureScopeSandbox.hasAccess(FeatureScope.ReviewInsights);

    public ALL_LOCATIONS_OPTION = allLocationsOption;
    public readonly uiContext = UiContext;
    public readonly uiIcon = UiIcon;
    public selectedLocation?: string;

    constructor(
        public readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        public uiSandbox: UiSandbox,
        public insightsSandbox: InsightsSandbox,
        private authSandbox: AuthenticationSandbox,
        private surveySandbox: SurveysSandbox,
        private featureScopeSandbox: FeatureScopeSandbox,
        private readonly analyticsService: AnalyticsService
    ) {}

    public ngOnInit(): void {
        this.fetchData();
        this.realEstateGroupSandbox.idOnce$.subscribe((id) => {
            this.insightsSandbox.getReviewYearOverview(id);
            this.insightsSandbox.getClientSource(id);
        });

        this.insightsSandbox.getHouseMatchScore();
        this.parseLocations();
    }

    public locationChanged(locationId: string) {
        this.selectedLocation = DbUtils.getStringId(locationId);
        this.sendAnalytics(locationId);
        this.fetchData(locationId === ALL_LOCATIONS_VALUE ? null : locationId);
        this.realEstateGroupSandbox.idOnce$.subscribe((id) => {
            this.insightsSandbox.getReviewYearOverview(id, locationId === ALL_LOCATIONS_VALUE ? null : locationId);
            this.insightsSandbox.getClientSource(id, locationId === ALL_LOCATIONS_VALUE ? null : locationId);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.parseLocations();
    }

    private fetchData(locationId?: string): void {
        this.insightsSandbox.getInsightsData(locationId);
        this.insightsSandbox.getTopicsAnalysis(locationId);
        this.insightsSandbox.getHouseMatchScore(locationId);
    }

    private parseLocations() {
        this.locationDropdownOptions = this.locations$.pipe(
            map((locations: RealEstateAgency[]) => [this.ALL_LOCATIONS_OPTION, ...(locations?.map(this.mapOfficeToDropdownInput) || [])])
        );
    }

    private mapOfficeToDropdownInput = (location: RealEstateAgency): DropdownInput<string> => {
        return {
            id: DbUtils.getStringId(location),
            value: location._id,
            label: location.name,
        } as DropdownInput<string>;
    };

    private sendAnalytics(locationId: string) {
        zip(this.realEstateGroupSandbox.name$, this.authSandbox.userId$, this.locations$)
            .pipe(
                take(1),
                tap(([regName, userId, locations]) => {
                    const locationName = locations.find((location) => DbUtils.getStringId(location) === locationId)?.name;
                    this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewsInsightsLocationChanged, {
                        regName,
                        userId,
                        locationName,
                    });
                })
            )
            .subscribe();
    }
}
