<div class="__group-kpi u-color-muted __group-kpi--cols-{{ cols }}">
    <div class="__group-kpi-item u-display-inline-block" *ngIf="meta?.distance">
        <app-icon icon="near_me" [appearance]="iconAppearance.Outlined" [size]="iconSizes.Sm" class="u-margin-right-xs"></app-icon>
        <span>{{ 'DISTANCE' | i18nKeyTranslate : i18nKeyType.Results : { distance: meta.distance | distance } }}</span>
    </div>
    <div class="__group-kpi-item u-display-inline-block">
        <app-icon icon="pin_drop" [size]="iconSizes.Sm" class="u-margin-right-xs"></app-icon>
        <span class="u-text-truncate">{{
            'LOCATIONS' | i18nKeyTranslate : i18nKeyType.Results : { count: meta?.locationCount || fallbackValue }
        }}</span>
    </div>
    <div class="__group-kpi-item u-display-inline-block" *ngIf="meta?.realEstateAgentCount">
        <app-icon icon="person_outline" [size]="iconSizes.Sm" class="u-margin-right-xs"></app-icon>
        <span class="u-text-truncate">{{
            'AGENTS'
                | i18nKeyTranslate
                    : i18nKeyType.Results
                    : { count: meta.realEstateAgentCount, value: meta.realEstateAgentCount | realEstateAgentCluster }
        }}</span>
    </div>
</div>
