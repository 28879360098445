import { CommunicationChannel } from '@app/energy/enums/communication-channel';
import { MeterSituation } from '@app/energy/enums/meter-situation';
import { UsageOfResidence } from '@app/energy/enums/usage-of-residence';
import { DropdownInput, SelectInput } from '@smooved/ui';

export const titleResource = 'ENERGY_ORDER_INFORMATION.LABEL';

export enum FormControlName {
    UsageOfResidence = 'usageOfResidence',
    MeterSituation = 'meterSituation',
    ElectricityAdvanceAmount = 'electricityAdvanceAmount',
    GasAdvanceAmount = 'gasAdvanceAmount',
    CommunicationChannel = 'communicationChannel',
}

export interface EnergyOrderInformationFormData {
    [FormControlName.UsageOfResidence]: UsageOfResidence;
    [FormControlName.MeterSituation]: MeterSituation;
    [FormControlName.ElectricityAdvanceAmount]: number;
    [FormControlName.GasAdvanceAmount]: number;
    [FormControlName.CommunicationChannel]: CommunicationChannel;
}

export const usageOfResidenceOptions: DropdownInput<UsageOfResidence>[] = [
    ...Object.entries(UsageOfResidence).map(([key, value]: [string, UsageOfResidence]) => {
        return {
            id: `usage-of-residence-${key}`,
            labelResource: `USAGE_OF_RESIDENCE.${key.toUpperCase()}`,
            value,
            name: 'usageOfResidence',
        };
    }),
];

export const meterSituationOptions: DropdownInput<MeterSituation>[] = [
    ...Object.entries(MeterSituation).map(([key, value]: [string, MeterSituation]) => {
        return {
            id: `meter-situation-${key}`,
            labelResource: `METER_SITUATION.${key.toUpperCase()}`,
            value,
            name: 'meterSituation',
        };
    }),
];

export const communicationChannelOptions: SelectInput<CommunicationChannel>[] = [
    ...Object.entries(CommunicationChannel).map(([key, value]: [string, CommunicationChannel]) => {
        return {
            id: `communication-channel-${key}`,
            labelResource: `COMMUNICATION_CHANNEL.${key.toUpperCase()}`,
            value,
            name: 'communicationChannel',
        };
    }),
];
