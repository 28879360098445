import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScaleGroup } from '@app/surveys/interfaces/meta/questions/scale-group';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@smooved/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { BaseInput } from '../base-input';

@Component({
    selector: 'smvd-ui-score-input',
    templateUrl: './score-input.component.html',
    styleUrls: ['./score-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ScoreInputComponent),
            multi: true,
        },
    ],
})
export class ScoreInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public formControlName: string;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public groups: ScaleGroup[];

    public innerModel: number;
    public currentLang: Language;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, private readonly translateService: TranslateService) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.translateService.onLangChange
            .pipe(startWith({ lang: this.translateService.currentLang }), takeUntil(this.destroy$))
            .subscribe(({ lang }) => (this.currentLang = lang as Language));
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: number): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel);
    }
}
