<div
    appTooltip
    class="
        __external-score
        u-flex-row
        u-flex-align-items-center
        u-flex-justify-content-space-around
        u-font-size-xs
        u-background-gray-medium
        u-border-radius-large
    "
    *ngIf="meta.externalScore as externalScore"
    [template]="externalScoreContent"
    [templateOutletContext]="{ $implicit: meta }"
>
    <span>{{ externalScore | roundHalf }}</span>
    <app-icon icon="star" [size]="uiSizes.Sm" class="u-color-attention"></app-icon>
</div>
<p class="u-color-muted u-font-size-xs u-margin-left-half" *ngIf="meta.externalTotal as externalTotal">
    {{ 'SCORE.EXTERNAL_REVIEWS' | i18nKeyTranslate: i18nKeyTypes.Score:{ count: externalTotal } }}
</p>

<ng-template #externalScoreContent let-overall>
    <div class="__stars-rating u-flex-column u-flex-align-items-center u-margin-half">
        <ui-star-score [max]="googleMaxScore" [score]="overall.externalScore" class="u-margin-bottom-half"></ui-star-score>
        <p class="u-color-muted u-font-size-{{ fontSize }}">{{ 'RATING_STARS' | i18nKeyTranslate: i18nKeyTypes.Score }}</p>
    </div>
</ng-template>
