import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveService } from '@app/move/services/move.service';
import { WaterDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/water-document-asset-detail/water-document-asset-detail.component';
import { WaterSandbox } from '@app/water/sandboxes/water.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, UiContext } from '@smooved/ui';
import { map, take } from 'rxjs/operators';
import { placeholders, requiredLabel, WaterDetailFields, waterSupplierOptionsFactory, waterSupplierURI } from './water-details.constants';

@Component({
    selector: 'smvd-app-water-details-modal',
    templateUrl: 'water-details.modal.html',
})
export class WaterDetailsModalComponent implements OnInit {
    public loading = false;
    @Input() public move: Move;

    public readonly form = this.fb.group({
        [WaterDetailFields.WaterSupplier]: [null, Validators.required],
        [WaterDetailFields.NationalRegistrationNumber]: null,
        [WaterDetailFields.ClientNumber]: null,
        [WaterDetailFields.MeterNumber]: [null, Validators.required],
        [WaterDetailFields.MeterReading]: [null, Validators.required],
    });
    public readonly requiredLabel = requiredLabel;
    public readonly placeholders = placeholders;
    public readonly waterSupplierURI = waterSupplierURI[this.translateService.currentLang] as string;
    public readonly waterDetailFields = WaterDetailFields;
    public readonly waterSupplierOptions$ = this.waterSandbox.waterSuppliers$.pipe(
        map((suppliers) => suppliers?.map(waterSupplierOptionsFactory))
    );

    public readonly context = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        private readonly dialogRef: MatDialogRef<any>,
        private readonly modalSandbox: ModalSandbox,
        private readonly fb: UntypedFormBuilder,
        private readonly waterSandbox: WaterSandbox,
        private readonly translateService: TranslateService,
        private readonly moveService: MoveService
    ) {}

    public ngOnInit(): void {
        if (this.move?.water) {
            const { waterSupplier, nationalRegistrationNumber, clientNumber, meterNumber, meterReading } = this.move.water;
            this.form.patchValue({
                waterSupplier: DbUtils.getStringId(waterSupplier),
                nationalRegistrationNumber,
                clientNumber,
                meterNumber,
                meterReading,
            });
        }
        this.waterSandbox.getWaterSuppliers();
    }

    public saveWaterDetails(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;
        this.loading = true;
        this.moveService
            .editWaterMeterReadings(DbUtils.getStringId(this.move), this.form.value)
            .pipe(take(1))
            .subscribe((move) => {
                this.loading = false;
                this.dialogRef.close(move);
            });
    }

    public onWaterDocumentAssetDetail(file: FileInput): void {
        const data = {
            moveId: DbUtils.getStringId(this.move),
            file,
        };

        this.modalSandbox.openModal(
            WaterDocumentAssetDetailComponent,
            { data },
            this.afterAssetDetailClosed,
            WaterDocumentAssetDetailComponent,
            { data },
            this.afterAssetDetailClosed
        );
    }

    private afterAssetDetailClosed = (move: Move) => {
        if (!move) return;
        this.move = move;
    };
}
