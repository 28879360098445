import { SlicePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limit' })
export class LimitPipe implements PipeTransform {
    public transform(value: string, start: number, end?: number, limitConfig = { prefix: '...', suffix: '...' }): string {
        if (!value || (start === 0 && !end)) return value;
        const slicer = new SlicePipe();
        const slice = slicer.transform(value, start, end);
        const prefix = start > 0 ? limitConfig.prefix : '';
        const suffix = value.length > end ? limitConfig.suffix : '';
        return `${prefix}${slice}${suffix}`;
    }
}
