<h6 class="u-margin-bottom">{{ 'MESSAGE.TITLE_PLURAL' | translate }}</h6>
<ng-container *ngIf="messages.length; else noMessages">
    <app-message *ngFor="let message of messages | slice: 0:maxForSlice" [message]="message" class="u-margin-bottom"></app-message>
    <div class="u-text-align-center" *ngIf="messages.length > maxItems">
        <a (click)="toggleShowItems()" app-button [appearance]="buttonAppearance.Link">{{
            (allItemsShown ? 'COMMON.SHOW_LESS' : 'COMMON.SHOW_MORE') | translate
        }}</a>
    </div>
</ng-container>
<ng-template #noMessages>
    <span class="u-color-muted">{{ 'MESSAGE.NO_MESSAGES' | translate }}</span>
</ng-template>
