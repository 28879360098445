<form [formGroup]="externalInfluencerService.form.get(formGroupNames.header)">
    <app-label-container [label]="'COVER' | translate" [hasMargin]="false" [hasMarginDouble]="true">
        <div>
            <img *ngIf="getImagePreview()" class="u-mw100p u-margin-bottom-half"
                 [src]="getImagePreview() | cacheBuster | sanitize: 'url'"/>
            <button app-button [appearance]="buttonAppearance.Stroked" [context]="uiContext.Secondary"
                    (click)="openImagePicker()">
                {{ 'SELECT_IMAGE' | translate }}
            </button>
        </div>
    </app-label-container>

    <app-label-container
        [label]="'LOGO' | translate"
        [hasMargin]="false"
        [hasMarginDouble]="false"
        class="u-display-block u-margin-bottom-triple"
    >
        <p class="u-color-gray">
            {{ 'REAL_ESTATE_AGENT.SETTINGS.LOGO_INFORMATION' | translate }}
        </p>
    </app-label-container>
</form>
