import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-contract-register-success-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-row u-flex-justify-content-center">
                    <div class="u-flex-column u-flex-align-items-center u-container-small">
                        <img width="200" src="assets/images/smooved-post-email.png" class="u-margin-bottom-double" />
                        <h3 class="u-margin-bottom-double u-text-align-center">{{ 'CONTRACT.REGISTER.SUCCESS.TITLE' | translate }}</h3>
                        <p class="u-font-size-medium u-text-align-center">{{ 'CONTRACT.REGISTER.SUCCESS.TEXT' | translate }}</p>
                    </div>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterSuccessContainer {}
