import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingDirective } from '@app/ui/loading/loading.directive';
import { LoadingModule as UiLoadingModule, PaginationModule } from '@smooved/ui';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

@NgModule({
    imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule, UiLoadingModule, PaginationModule],
    declarations: [LoadingOverlayComponent, LoadingDirective],
    exports: [LoadingOverlayComponent, LoadingDirective, UiLoadingModule],
})
export class LoadingModule {}
