import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-text-search-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="options"
                [placeholder]="placeholder || ('SEARCH' | translate) + '...'"
                searchKeyword="label"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                [(ngModel)]="innerModel"
                [name]="formControlName"
                [itemTemplate]="itemTemplateStatic"
                [isLoading]="loading"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.label"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./text-search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextSearchInputComponent),
            multi: true,
        },
    ],
})
export class TextSearchInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public loading = false;
    @Input() public options: DropdownInput<any>[] = [];

    public innerModel: any = '';

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        // first time we need to set the innerModel as the query ( name ). Otherwise the lib will throw an error?
        this.innerModel = value ?? '';
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.propagateChange(this.innerModel?.value);
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
