<label *ngIf="label" class="u-display-block u-margin-bottom-half">{{ label }}</label>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <app-textarea-input
        class="u-display-block"
        [formControlName]="messageFormControlName"
        [placeholder]="'MESSAGE.PLACEHOLDER' | translate"
    ></app-textarea-input>
    <div>
        <a app-button [appearance]="buttonAppearance.Link" [size]="buttonSize.Sm" (click)="onCancel()" class="u-margin-right-half">{{
            'CANCEL' | translate
        }}</a>
        <button app-button type="submit" [context]="uiContext.Secondary" [size]="buttonSize.Sm">{{ 'SEND' | translate }}</button>
    </div>
</form>
