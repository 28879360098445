import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { CreateScreenshotRequest } from '@app/document-center/interfaces/create-screenshot-request';
import { FeatureDetailReviewsService } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.service';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ExternalInfluencer, Language, RxjsComponent, UrlUtils } from '@smooved/core';
import { ButtonAppearance, UiContext, UiDirection, UiSize, WidgetV2Component } from '@smooved/ui';
import { finalize, startWith, takeUntil } from 'rxjs/operators';
import {
    colorDefault,
    darkMode,
    directionDefault,
    directionOptions,
    FormFields,
    languageOptions,
    lightMode,
    screenshotHeight,
    screenshotWidth,
    sizeDefault,
    sizeOptions,
} from './configure-widget-influencer-score.constants';

@Component({
    selector: 'app-configure-external-influencer-score-modal',
    templateUrl: './configure-widget-influencer-score.modal.html',
    styleUrls: ['./configure-widget-influencer-score.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfigureWizardInfluencerScoreModal extends RxjsComponent implements OnInit {
    @ViewChild('widget') widget: WidgetV2Component;
    public buttonAppearance = ButtonAppearance;
    public uiContext = UiContext;
    public loading = false;
    public externalInfluencer: ExternalInfluencer;
    public form: UntypedFormGroup;
    public darkTheme = false;
    public toggleThemeIcon = darkMode;

    public readonly direction = UiDirection;
    public readonly formFields = FormFields;
    public readonly languageOptions = languageOptions;
    public readonly directionOptions = directionOptions;
    public readonly sizeOptions = sizeOptions;

    public scriptTag: string;
    public bodyTag: string;

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        private readonly featureDetailReviewsService: FeatureDetailReviewsService,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly translateService: TranslateService,
        private readonly fb: UntypedFormBuilder,
        public readonly authenticationSandbox: AuthenticationSandbox,
        private readonly documentCenterSandbox: DocumentCenterSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.externalInfluencer = this.featureDetailReviewsService.externalInfluencerSubject.value;
        this.form = this.fb.group({
            [FormFields.Direction]: directionDefault,
            [FormFields.Size]: sizeDefault,
            [FormFields.Language]: this.translateService.currentLang || Language.NL,
            [FormFields.HasShadow]: true,
            [FormFields.HasBackground]: true,
            [FormFields.IsMonochrome]: false,
            [FormFields.Color]: colorDefault,
        });

        this.realEstateGroupSandbox.realEstateGroupOnce$.subscribe((realEstateGroup) => {
            this.form.patchValue({ [FormFields.Color]: realEstateGroup.theme.accentBackground }, { emitEvent: false });
        });

        this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.destroy$)).subscribe(this.setTags);
    }

    public toggleTheme(): void {
        this.darkTheme = !this.darkTheme;
        this.toggleThemeIcon = this.darkTheme ? lightMode : darkMode;
    }

    public screenshot(): void {
        this.loading = true;
        const url = UrlUtils.createUrlForWidgetVersion2(environment.widgetUri, this.externalInfluencer.key, {
            lang: this.form.get(FormFields.Language).value,
            bg: this.form.get(FormFields.HasBackground).value,
            shadow: this.form.get(FormFields.HasShadow).value,
            direction: this.form.get(FormFields.Direction).value,
            color: this.form.get(FormFields.IsMonochrome).value ? this.form.get(FormFields.Color).value : null,
            size: this.form.get(FormFields.Size).value,
        });
        const payload: CreateScreenshotRequest = {
            url,
            width: screenshotWidth,
            height: screenshotHeight,
        };

        this.documentCenterSandbox
            .screenshot(payload)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe();
    }

    private getScriptTag(): string {
        return `<!-- Smooved Widget Script -->
<script data-main="${environment.widgetBootstrapName}" src="${environment.widgetBootstrapScript}" defer></script>
<!-- End Smooved Widget Script -->`;
    }

    private getBodyTag(): string {
        const direction = this.form.get(FormFields.Direction).value as UiDirection;
        const size = this.form.get(FormFields.Size).value as UiSize;
        const params = {
            id: this.externalInfluencer.key,
            name: this.externalInfluencer.url,
            lang: this.form.get(FormFields.Language).value as string,
            ...(direction !== directionDefault ? { direction } : {}),
            ...(size !== sizeDefault ? { size } : {}),
            ...(this.form.get(FormFields.HasShadow).value === false ? { shadow: false } : {}),
            ...(this.form.get(FormFields.HasBackground).value === false ? { bg: false } : {}),
            ...(this.form.get(FormFields.IsMonochrome).value ? { color: this.form.get(FormFields.Color).value } : {}),
        };

        return `<!-- Smooved Widget -->
<div id="smooved-influencer-score" ${this.parseConfig(params)}></div>
<!-- End Smooved Widget -->`;
    }

    private parseConfig(params: object): string {
        return Object.entries(params).reduce((paramsText: string, [key, value]) => {
            return `${paramsText}data-smooved-${key}="${value}" `;
        }, '');
    }

    private setTags = () => {
        this.scriptTag = this.getScriptTag();
        this.bodyTag = this.getBodyTag();
    };
}
