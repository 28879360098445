import { Pipe, PipeTransform } from '@angular/core';
import { DbUtils } from '../utils';

@Pipe({
    name: 'getId',
})
export class IdPipe implements PipeTransform {
    transform(value: { _id?: unknown; id?: unknown }): string {
        if (!value) return '';
        return DbUtils.getStringId(value);
    }
}
