import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isBrowser, RxjsService } from '@smooved/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { ScreenSize } from './ui.enums';
import { UiLibModule } from './ui.module';

@Injectable({
    providedIn: UiLibModule,
})
export class UiSandbox extends RxjsService {
    public screenSize$ = new BehaviorSubject<ScreenSize>(ScreenSize.Phone);

    public upToAndIncludingPhoneLandscape$ = this.breakpointObserver.observe('(max-width: 599px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );
    public upToAndIncludingPhoneLandscapeOnce$ = this.upToAndIncludingPhoneLandscape$.pipe(take(1));
    public upToAndIncludingLargeTabletPortrait$ = this.breakpointObserver.observe('(max-width: 799px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );
    public upToAndIncludingTabletPortrait$ = this.breakpointObserver.observe('(max-width: 1023px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );

    public phoneLandscapeUp$ = this.breakpointObserver.observe('(min-width: 480px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );
    public tabletPortraitUp$ = this.breakpointObserver.observe('(min-width: 600px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );
    public largeTabletPortraitUp$ = this.breakpointObserver.observe('(min-width: 800px)').pipe(
        isBrowser(this.platformId),
        map((state) => state.matches)
    );
    public tabletLandscapeUp$ = this.breakpointObserver.observe('(min-width: 1024px)').pipe(map((state) => state.matches));
    public desktopUp$ = this.breakpointObserver.observe('(min-width: 1200px)').pipe(map((state) => state.matches));
    public largeDesktopUp$ = this.breakpointObserver.observe('(min-width: 1400px)').pipe(map((state) => state.matches));

    constructor(
        protected breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        super();
        combineLatest([
            this.upToAndIncludingPhoneLandscape$,
            this.tabletPortraitUp$,
            this.largeTabletPortraitUp$,
            this.tabletLandscapeUp$,
            this.desktopUp$,
            this.largeDesktopUp$,
        ])
            .pipe(
                tap(
                    ([phone, tablet, largeTablet, tabletLandscape, desktop, largeDesktop]: [
                        boolean,
                        boolean,
                        boolean,
                        boolean,
                        boolean,
                        boolean,
                    ]) => {
                        if (phone) this.screenSize$.next(ScreenSize.Phone);
                        if (tablet) this.screenSize$.next(ScreenSize.Tablet);
                        if (largeTablet) this.screenSize$.next(ScreenSize.LargeTablet);
                        if (tabletLandscape) this.screenSize$.next(ScreenSize.TabletLandscape);
                        if (desktop) this.screenSize$.next(ScreenSize.Desktop);
                        if (largeDesktop) this.screenSize$.next(ScreenSize.LargeDesktop);
                    }
                ),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    public get instantScreenSize(): ScreenSize {
        return this.screenSize$.value;
    }

    public get isMobile(): boolean {
        return this.instantScreenSize === ScreenSize.Phone;
    }

    public get isTablet(): boolean {
        return this.instantScreenSize === ScreenSize.Tablet;
    }

    public get isLargeTablet(): boolean {
        return this.instantScreenSize === ScreenSize.LargeTablet;
    }

    public get isTabletPortraitUp(): boolean {
        return this.isTablet || this.isTabletLandscapeUp;
    }

    public get isTabletLandscape(): boolean {
        return this.instantScreenSize === ScreenSize.TabletLandscape;
    }

    public get isTabletLandscapeUp(): boolean {
        return this.isTabletLandscape || this.isDesktopUp;
    }

    public get isDesktop(): boolean {
        return this.instantScreenSize === ScreenSize.Desktop;
    }

    public get isDesktopUp(): boolean {
        return this.isDesktop || this.isLargeDesktop;
    }

    public get isLargeDesktop(): boolean {
        return this.instantScreenSize === ScreenSize.LargeDesktop;
    }

    public isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public isServer(): boolean {
        return isPlatformServer(this.platformId);
    }
}
