import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormControlNames } from '@app/admin/components/real-estate-group-appointments-filter/real-estate-group-appointments-filter.constants';
import { placeholders, WaterDetailFields, waterSupplierOptionsFactory } from '@app/admin/modals/water-details/water-details.constants';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveService } from '@app/move/services/move.service';
import { Water } from '@app/water/interfaces/water';
import { WaterSandbox } from '@app/water/sandboxes/water.sandbox';
import { AnalyticsEventsEnum, AnalyticsService, DbUtils, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, ClosableModalTemplateComponent, UiContext } from '@smooved/ui';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-move-detail-complete-water-data',
    templateUrl: './move-detail-complete-water-data.modal.html',
    styleUrls: ['./move-detail-complete-water-data.modal.scss'],
})
export class MoveDetailCompleteWaterDataModal extends RxjsComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly waterDetailFields = WaterDetailFields;
    public readonly placeholders = placeholders;
    public readonly context = UiContext;
    public readonly formControlNames = FormControlNames;
    public loading = false;

    public form;

    public readonly waterSupplierOptions$ = this.waterSandbox.waterSuppliers$.pipe(
        map((suppliers) => suppliers?.map(waterSupplierOptionsFactory))
    );

    constructor(
        public moveSandbox: MoveSandbox,
        private readonly waterSandbox: WaterSandbox,
        private readonly moveService: MoveService,
        private fb: UntypedFormBuilder,
        private readonly analyticsService: AnalyticsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.waterSandbox.getWaterSuppliers();
        this.moveSandbox.moveOnce$
            .pipe(
                tap((move) => {
                    this.form = this.fb.group({
                        [WaterDetailFields.MovingDate]: [move.movingDate, Validators.required],
                        [WaterDetailFields.NationalRegistrationNumber]: [move.water?.nationalRegistrationNumber],
                        [WaterDetailFields.WaterSupplier]: [DbUtils.getStringId(move.water?.waterSupplier)],
                        [WaterDetailFields.MeterNumber]: [move.water?.meterNumber],
                        [WaterDetailFields.MeterReading]: [move.water?.meterReading],
                    });
                })
            )
            .subscribe();
    }

    public completeWaterData(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;
        this.loading = true;

        const data = this.form.value;

        this.moveSandbox.idOnce$
            .pipe(
                map((moveId) => {
                    this.moveSandbox.patch({
                        moveId: DbUtils.getStringId(moveId),
                        payload: {
                            movingDate: data.movingDate,
                            water: {
                                nationalRegistrationNumber: data.nationalRegistrationNumber,
                                waterSupplier: data.waterSupplier,
                                meterReading: data.meterReading || 0,
                                meterNumber: data.meterNumber,
                            } as Water,
                        },
                        withNotification: false,
                        dialogIdToClose: null,
                        callback: (move) => {
                            this.analyticsService.sendEvent(AnalyticsEventsEnum.ReaWaterAddExtraDataSubmit, {
                                moveId,
                            });
                            this.moveSandbox.setMoveState(move);
                            this.loading = false;
                            this.closableModalComponent.close();
                        },
                    });
                })
            )
            .subscribe();
    }
}
