<div [ngSwitch]="!!move?.moveStates?.waterMetersProcessedByAdmin">
    <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.moveStates?.waterMetersProcessedByAdmin }">
        <div *ngSwitchCase="true">
            <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
            <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_METER_READINGS_BY_ADMIN.OK' | translate }}</span>
            <button smvd-ui-button class="u-no-padding-left" [appearance]="buttonAppearance.Link" (click)="openMeterReadings()">
                {{ 'COMMON.DETAILS' | translate }}
            </button>
        </div>
        <div *ngSwitchCase="false">
            <div class="u-flex-row u-flex-align-items-center">
                <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'ADMIN.DASHBOARD.DETAIL.WATER_METER_READINGS_BY_ADMIN.NOK' | translate }}</span>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <button
                    smvd-ui-button
                    [appearance]="buttonAppearance.Stroked"
                    [context]="uiContext.Secondary"
                    (click)="openMeterReadings()"
                >
                    {{ 'ADMIN.DASHBOARD.DETAIL.WATER_METER_READINGS_BY_ADMIN.CTA' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
