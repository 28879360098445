import { ChangeDetectorRef, Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { ArrayUtils, DbUtils, Note, NpsReview, NumberUtils, PaginationResponse, RxjsComponent } from '@smooved/core';
import { map } from 'rxjs/operators';
import { ApplicationUtils } from '../../../application';
import { ButtonAppearance } from '../../../button';
import { UiIconSize } from '../../../icon';
import { PaginationService } from '../../../pagination';
import { TooltipTrigger } from '../../../tooltip';
import { UiAlignment, UiContext, UiPlacement } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { NpsReviewBucket } from '../../enums/nps-review-buckets.enum';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';
import { NpsReviewsFilterService } from '../../services';
import { NpsReviewsTemplateService } from './nps-reviews-template.service';

@Component({
    selector: 'app-nps-reviews-template',
    templateUrl: 'nps-reviews-template.component.html',
    styleUrls: ['nps-reviews-template.component.scss'],
})
export class NpsReviewsTemplateComponent extends RxjsComponent implements OnInit {
    @Input() public npsActionsTemplate: TemplateRef<unknown>;
    @Input() public npsFooterTemplate: TemplateRef<unknown>;
    @Input() public npsShareTemplate: TemplateRef<unknown>;
    @Input() public npsStatusTemplate: TemplateRef<unknown>;
    @Input() public triggerAfter; //ms
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('reviews') public preloadedReviews: PaginationResponse<NpsReview>;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('buckets') public preloadedBuckets: NpsReviewBucket[];

    public reviews: NpsReview[] | ReadonlyArray<NpsReview>;
    public totalReviewsCount: number;
    public isPublicMode: boolean;
    public isInitialLoad = true;
    public limit = null;
    public gridColumns$ = this.uiSandbox.upToAndIncludingTabletPortrait$.pipe(
        map((isMobile) => {
            let colSize = 1;
            if (isMobile && NumberUtils.isNumber(this.templateConfig.colSizeMobile)) {
                colSize = this.templateConfig.colSizeMobile;
            } else if (NumberUtils.isNumber(this.templateConfig.colSize)) {
                colSize = this.templateConfig.colSize;
            }
            return colSize;
        })
    );

    public readonly tooltipAlignment = UiAlignment;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly iconSize = UiIconSize;
    public readonly tooltipPlacement = UiPlacement;
    public readonly tooltipTrigger = TooltipTrigger;
    public readonly isSmooved = ApplicationUtils.isSmoovedApplication(this.templateConfig.application);

    constructor(
        public readonly reviewsTemplateService: NpsReviewsTemplateService,
        private readonly filterService: NpsReviewsFilterService,
        private readonly paginationService: PaginationService,
        private readonly uiSandbox: UiSandbox,
        @Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig,
        private readonly cdr: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isPublicMode = this.reviewsTemplateService.isPublicMode;

        if (this.preloadedReviews?.count) {
            this.reviewsTemplateService.preset(this.preloadedReviews.data, this.preloadedReviews.count);
        }

        if (!ArrayUtils.isEmpty(this.preloadedBuckets)) {
            this.filterService.filterByBucket(this.preloadedBuckets);
        }

        this.reviewsTemplateService.dataSource?.connect().subscribe((items) => {
            this.reviews = items;
            this.cdr.detectChanges();
        });
        this.reviewsTemplateService.dataSource?.reviewsCount$.subscribe((count) => {
            this.totalReviewsCount = count;
        });

        // Set the initial limit to fetch the right amount of reviews
        this.setLimit();
        this.reviewsTemplateService.setFirstPage();
    }

    public ngOnDestroy(): void {
        this.filterService.clear(false);
        super.ngOnDestroy();
    }

    public onEdit(review: NpsReview, note: Note): void {
        this.reviewsTemplateService.onEdit(review, note);
    }

    public trackById(_: number, nps: NpsReview): string {
        return DbUtils.getStringId(nps);
    }

    public loadNext(): void {
        this.isInitialLoad = false;
        if (this.reviewsTemplateService.loadingSubject.value) return;
        this.reviewsTemplateService.loadNext();
    }

    public hasNextPage(): boolean {
        return this.paginationService.hasNextPage();
    }

    private setLimit(): void {
        if (this.uiSandbox.isMobile && NumberUtils.isNumber(this.templateConfig.limitMobile)) {
            this.limit = this.templateConfig.limitMobile;
        } else if (NumberUtils.isNumber(this.templateConfig.limit)) {
            this.limit = this.templateConfig.limit;
        }
        if (NumberUtils.isNumber(this.limit)) {
            this.paginationService.pageSize$.next(this.limit);
        } else if (this.uiSandbox.isMobile && NumberUtils.isNumber(this.templateConfig.initialLimitMobile)) {
            this.paginationService.pageSize$.next(this.templateConfig.initialLimitMobile);
        } else if (!NumberUtils.isNumber(this.limit) && this.templateConfig.initialLimit) {
            this.paginationService.pageSize$.next(this.templateConfig.initialLimit);
        }
    }
}
