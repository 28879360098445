import { SkeletonBackgroundSheetConfig } from './skeleton-config.interface';

export enum SkeletonHeaderAppearance {
    Default = 'shadow',
    NoShadow = 'no-shadow',
    Transparent = 'transparent',
    None = 'none',
    Brand = 'brand',
}

export const defaultHeightBackgroundSheet = '30rem';
export const defaultClassesBackgroundSheet = [
    '__background-sheet',
    'u-position-fixed-top',
    'u-position-fixed-left',
    'u-background-primary-light',
    'u-hide-up-to-and-including-phone-landscape',
    'u-w100p',
];

export const skeletonHeaderClassPrefix = 'ui-skeleton-header';

export const defaultBackgroundSheetConfig: SkeletonBackgroundSheetConfig = {
    classNames: defaultClassesBackgroundSheet,
    height: defaultHeightBackgroundSheet,
};
