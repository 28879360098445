import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { WaterDocumentAssetsInputComponent } from '@app/form/components/water-document-assets-input/water-document-assets-input.component';
import { WaterIdCardAssetsInputComponent } from '@app/form/components/water-id-card-assets-input/water-id-card-assets-input.component';
import { WaterMeterReadingAssetsInputComponent } from '@app/form/components/water-meter-reading-assets-input/water-meter-reading-assets-input.component';
import { FileInput } from '@app/form/interfaces/file-input';
import { fileValidator } from '@app/form/validators/file-type.validator';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { MobileWaterDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-water-document-asset-detail/mobile-water-document-asset-detail.component';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { CheckInput, MobileModalComponent, ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { concat } from 'rxjs';
import { last } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-move-water-modal',
    template: `
        <app-mobile-modal
            *ngIf="{ isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async), move: (move$ | async) } as vm"
        >
            <div mobile-modal-header>
                <h6>{{ 'WATER.LABEL' | translate }}</h6>
                <span class="u-color-muted u-font-size-small">{{ vm.move?.user?.formattedMovingAddressWithoutCountry }}</span>
            </div>
            <div mobile-modal-main>
                <form [formGroup]="form" (ngSubmit)="submit()" class="u-flex-column u-flex-justify-content-space-between u-h100p">
                    <div class="u-background-white">
                        <div class="u-padding">
                            <app-water-document-assets-input
                                formControlName="waterDocumentAssets"
                                [move]="vm.move"
                                [readOnly]="data.readOnly && !vm.isRealEstateAgentOrAdmin"
                                (detail)="onWaterDocumentDetail($event)"
                                (preview)="onWaterDocumentAssetPreview()"
                            ></app-water-document-assets-input>

                            <app-water-meter-reading-assets-input
                                [move]="vm.move"
                                class="u-margin-top-double"
                                [readOnly]="data.readOnly && !vm.isRealEstateAgentOrAdmin"
                                (detail)="onWaterMeterReadingDetail($event)"
                                formControlName="meters"
                            ></app-water-meter-reading-assets-input>

                            <app-water-id-card-assets-input
                                [move]="vm.move"
                                class="u-margin-top u-margin-bottom"
                                *ngIf="!data.hideIdCards"
                                [readOnly]="data.readOnly && !vm.isRealEstateAgentOrAdmin"
                                (detail)="onWaterIdCardReadingDetail($event)"
                                formControlName="cards"
                            ></app-water-id-card-assets-input>

                            <app-check-input
                                [custom]="false"
                                [option]="waterCollectiveMeterOption"
                                formControlName="waterCollectiveMeter"
                            ></app-check-input>

                            <div *ngIf="data.move?.rentalInspectionAsset" class="u-margin-top">
                                <h6 class="u-margin-bottom-half u-color-muted">{{ 'OTHER' | translate }}</h6>
                                <app-rental-inspection-asset class="u-display-block" [move]="data.move"></app-rental-inspection-asset>
                            </div>
                        </div>
                    </div>

                    <div class="u-border-radius-top-extra-large u-flex-no-shrink u-background-white u-padding">
                        <app-button type="submit" *ngIf="!data.readOnly || vm.isRealEstateAgentOrAdmin" class="u-display-block u-w100p">{{
                            'SAVE' | translate
                        }}</app-button>
                    </div>
                </form>
            </div>
        </app-mobile-modal>
    `,
    styleUrls: ['./mobile-move-water.component.scss'],
})
export class MobileMoveWaterComponent extends MoveData implements OnInit {
    @ViewChild(MobileModalComponent) public detail: MobileModalComponent;
    @ViewChild(WaterDocumentAssetsInputComponent)
    public waterDocumentAssetsInputComponent: WaterDocumentAssetsInputComponent;
    @ViewChild(WaterMeterReadingAssetsInputComponent)
    public waterMeterReadingAssetsInputComponent: WaterMeterReadingAssetsInputComponent;
    @ViewChild(WaterIdCardAssetsInputComponent) public waterIdCardAssetsInputComponent: WaterIdCardAssetsInputComponent;

    public form: UntypedFormGroup;

    public waterCollectiveMeterOption: CheckInput<boolean> = {
        id: 'water-collective-meter',
        name: 'water-collective-meter',
        value: true,
        label: this.translateService.instant('WATER.DOCUMENT.MODAL.COLLECTIVE_METER'),
    };

    constructor(
        protected moveSandbox: MoveSandbox,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox,
        private fb: UntypedFormBuilder,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService,
        public authenticationSandbox: AuthenticationSandbox,
        @Inject(MAT_DIALOG_DATA) public data: ModalDataMove
    ) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit() {
        this.updateMove(this.data.move);
        this.form = this.fb.group({
            waterDocumentAssets: [null, [fileValidator(this.waterDocumentAssetsInputComponent?.files, null, 10 * 1000 * 1000)]],
            meters: [null],
            cards: [null],
            waterCollectiveMeter: [{ value: this.data.move?.waterCollectiveMeter, disabled: !!this.data.readOnly }],
        });
    }

    public onWaterDocumentDetail(file: FileInput) {
        const data: FileModalData = {
            moveId: this.data.move?._id,
            file,
            type: 'Document',
            readOnly: this.data.readOnly,
        };
        this.modalSandbox
            .openMobileModal(MobileWaterDocumentAssetDetailComponent, { data })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveSubject.next(move);
            });
    }

    public onWaterMeterReadingDetail(file: FileInput) {
        const data: FileModalData = {
            moveId: this.data.move?._id,
            file,
            type: 'Meter-Reading',
            readOnly: this.data.readOnly,
        };
        this.modalSandbox
            .openMobileModal(MobileWaterDocumentAssetDetailComponent, { data })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveSubject.next(move);
            });
    }

    public onWaterIdCardReadingDetail(file: FileInput) {
        const data: FileModalData = {
            moveId: this.data.move?._id,
            file,
            type: 'Id-Card',
            readOnly: this.data.readOnly,
        };
        this.modalSandbox
            .openMobileModal(MobileWaterDocumentAssetDetailComponent, { data })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveSubject.next(move);
            });
    }

    public onWaterDocumentAssetPreview() {
        this.form
            .get('waterDocumentAssets')
            .setValidators(fileValidator(this.waterDocumentAssetsInputComponent.files, null, 10 * 1000 * 1000));
        this.form.get('waterDocumentAssets').updateValueAndValidity();
    }

    public submit() {
        if (this.form.invalid) return false;

        this.uiSandbox.showLoadingOverlay();
        this.moveOnce$.subscribe((move) => {
            this.moveSandbox.patchProperty(
                'waterCollectiveMeter',
                this.form.get('waterCollectiveMeter').value,
                true,
                (move) => {
                    const httpCalls = [];
                    if (this.waterDocumentAssetsInputComponent.files?.length) {
                        const formData = new FormData();
                        for (const file of this.waterDocumentAssetsInputComponent.files) {
                            formData.append('files', file, file['name']);
                        }

                        httpCalls.push(this.moveSandbox.uploadWaterDocumentAsset(this.data.move?._id, formData));
                    }

                    if (this.waterMeterReadingAssetsInputComponent.files?.length) {
                        const formData = new FormData();
                        for (const file of this.waterMeterReadingAssetsInputComponent.files) {
                            formData.append('files', file, file['name']);
                        }

                        httpCalls.push(this.moveSandbox.uploadWaterMeterReadingAsset(this.data.move?._id, formData));
                    }

                    if (this.waterIdCardAssetsInputComponent.files?.length) {
                        const formData = new FormData();
                        for (const file of this.waterIdCardAssetsInputComponent.files) {
                            formData.append('files', file, file['name']);
                        }

                        httpCalls.push(this.moveSandbox.uploadWaterIdCardAsset(this.data.move?._id, formData));
                    }

                    if (httpCalls.length) {
                        this.uiSandbox.showLoadingOverlay();
                        concat(...httpCalls)
                            .pipe(last())
                            .subscribe(
                                (patchedMove) => {
                                    this.uiSandbox.hideLoadingOverlay();
                                    this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
                                    this.updateMove(patchedMove, true);
                                    this.form.reset();
                                    this.waterDocumentAssetsInputComponent.files = [];
                                    this.waterDocumentAssetsInputComponent.previews = null;
                                    this.waterMeterReadingAssetsInputComponent.files = [];
                                    this.waterMeterReadingAssetsInputComponent.previews = null;
                                    this.waterIdCardAssetsInputComponent.files = [];
                                    this.waterIdCardAssetsInputComponent.previews = null;
                                    this.detail.dialogRef.close(true);
                                },
                                (_) => {
                                    this.uiSandbox.hideLoadingOverlay();
                                }
                            );
                    } else {
                        this.uiSandbox.hideLoadingOverlay();
                        this.updateMove(move, true);
                        this.detail.dialogRef.close(true);
                    }
                },
                false,
                move,
                true
            );
        });
    }
}
