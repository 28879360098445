import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MoveUtils } from '@app/move/state/move.utils';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { Partner } from '@app/partner/interfaces/partner';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { Move } from '@app/move/interfaces/move';
import { DbUtils, SimpleChangesUtils } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-rental-inspection-details',
    templateUrl: './rental-inspection-details.component.html',
    styles: [':host {display: block}'],
})
export class RentalInspectionDetailsComponent implements OnChanges {
    @Input() public move: Move;
    @Input() public movePatchedSubject: BehaviorSubject<boolean>;

    @Output() public reFetch: EventEmitter<string> = new EventEmitter<string>();

    public moverRole = MoverRole;
    public order: OrderRentalInspection;
    public partner: Partner;

    constructor(private readonly orderSandbox: OrderSandbox) {}

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.fetchById();
        }
    }

    public sendMessage(value: string): void {
        this.orderSandbox.createMessage(DbUtils.getStringId(this.order), value).subscribe(this.sendMessageSuccessHandler);
    }

    private sendMessageSuccessHandler = () => {
        this.fetchById();
    };

    private fetchById(): void {
        const orderId = DbUtils.getStringId(MoveUtils.getRentalInspection(this.move));
        if (!orderId) return;
        this.orderSandbox.findById(orderId).subscribe(this.handleOrderLoaded);
    }

    private handleOrderLoaded = (order: OrderRentalInspection): void => {
        this.order = order;
        this.partner = this.order.partner;
    };
}
