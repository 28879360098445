import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@smooved/ui';
import { MaskSandbox } from '../../../shared/sandboxes/mask.sandbox';

@Component({
    selector: 'app-account-number-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    [id]="id"
                    mask="{{ maskSandbox.accountNumber }}"
                    prefix="BE"
                    [showMaskTyped]="true"
                    type="text"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [disabled]="innerDisabled"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    autocomplete="smooved"
                />
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstError() | translate }}</mat-error>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./account-number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountNumberInputComponent),
            multi: true,
        },
    ],
})
export class AccountNumberInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public required = false;

    public innerModel: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, public maskSandbox: MaskSandbox) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
