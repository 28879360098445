import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { BivRealEstateAgent, CreateBivRealEstateAgent, DbUtils, UpdateBivRealEstateAgent } from '@smooved/core';
import { bivCodeMask, PhoneNumberValidators, TextValidators } from '@smooved/ui';
import { take } from 'rxjs/operators';
import { BivFormControlName, bivUrl } from './biv-agent-form.constants';
import { BivAgentFormData, BivAgentFormModalData } from './biv-agent-form.interface';

@Component({
    selector: 'app-biv-agent-form-modal',
    templateUrl: './biv-agent-form.modal.html',
})
export class BivAgentFormModalComponent implements OnInit {
    public form: UntypedFormGroup;
    public formControlNames = BivFormControlName;
    public bivCodeMask = bivCodeMask;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data?: BivAgentFormModalData
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            [BivFormControlName.BivCode]: [null, [Validators.required, TextValidators.isBivCode]],
            [BivFormControlName.Name]: [null, Validators.required],
            [BivFormControlName.FixedLine]: [null, PhoneNumberValidators.isValidPhoneNumber],
            [BivFormControlName.PhoneNumber]: [null, PhoneNumberValidators.isValidPhoneNumber],
            [BivFormControlName.Email]: [null, [Validators.required, Validators.email]],
            [BivFormControlName.BivUrl]: [null, { validators: [Validators.required, TextValidators.startsWith(bivUrl)], updateOn: 'blur' }],
        });

        if (this.data?.agent) {
            this.form.patchValue(this.parseAsFormData(this.data?.agent));
            this.form.controls.bivCode.disable();
        }
    }

    public onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) return;

        const bivAgent = this.parseFormData(this.form.value as BivAgentFormData);
        this.realEstateGroupSandbox.createOrPatchBivRealEstateAgentSuccess$.pipe(take(1)).subscribe(() => this.dialogRef.close());
        this.realEstateGroupSandbox.createOrUpdateBivRealEstateAgent(bivAgent);
    }

    private parseFormData({
        bivCode,
        name,
        agentType,
        syndic,
        address,
        fixedLine,
        phoneNumber,
        email,
        websiteUrl,
        bivUrl,
    }: BivAgentFormData): CreateBivRealEstateAgent | UpdateBivRealEstateAgent {
        return {
            ...(this.data?.agent ? { _id: DbUtils.getStringId(this.data.agent) } : {}),
            bivCode,
            name,
            agentType,
            syndic,
            address,
            fixedLine,
            phoneNumber,
            email,
            websiteUrl,
            bivUrl,
        };
    }

    private parseAsFormData({
        bivCode,
        name,
        agentType,
        syndic,
        address,
        fixedLine,
        phoneNumber,
        email,
        websiteUrl,
        bivUrl,
    }: BivRealEstateAgent): BivAgentFormData {
        return {
            bivCode,
            name,
            agentType,
            syndic,
            address,
            fixedLine,
            phoneNumber,
            email,
            websiteUrl,
            bivUrl,
        };
    }
}
