<app-todo
    *ngIf="move"
    [label]="'DETAIL.ENERGY_STOP.METER_READINGS_COMPLETED' | i18nKeyTranslate: i18nKeys.AdminDashboard"
    [state]="move.track.leaverAdmin.energyStopMeterReadingsCompleted"
>
    <app-todo-template *todoStateChecked>
        <ng-container todo-cta *ngTemplateOutlet="openMeterInfoCta"></ng-container>
        <ng-container *ngTemplateOutlet="meterReadingDateLabel"></ng-container>
    </app-todo-template>
    <app-todo-template *todoStateWarning>
        <ng-container todo-cta *ngTemplateOutlet="openMeterInfoCta"></ng-container>
        <ng-container *ngTemplateOutlet="meterReadingDateLabel"></ng-container>
        <span class="u-font-size-small u-color-muted u-mw300 u-display-block">{{
            move.track.leaverAdmin.energyStopMeterReadingsCompletedReason | translate
        }}</span>
    </app-todo-template>

    <app-todo-template *todoStateUnchecked [label]="'DETAIL.METER_READINGS.NOK' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard">
        <a app-button [context]="uiContext.Secondary" [size]="buttonSize.Sm" (click)="openMeterInfo()"> {{ 'ADD' | translate }} </a>
        <span class="u-font-size-small u-color-muted u-mw300 u-display-block">{{
            move.track.leaverAdmin.energyStopMeterReadingsCompletedReason | translate
        }}</span>
    </app-todo-template>
</app-todo>

<ng-template #openMeterInfoCta>
    <a app-button [appearance]="buttonAppearance.Link" [size]="buttonSize.Sm" (click)="openMeterInfo()">{{ 'VIEW' | translate }}</a>
</ng-template>

<ng-template #meterReadingDateLabel>
    <span *ngIf="move.linkedMove.meterReadingDate as meterReadingDate">{{ meterReadingDate | formatDate }}</span>
</ng-template>
