import { Directive } from '@angular/core';
import { TodoState } from './todo-state.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[todoStateWarning]',
})
export class TodoStateWarningDirective {
    public readonly state = TodoState.Warning;
}
