import { Injectable } from '@angular/core';
import {
    getClientSourceAction,
    getHouseMatchScoreAction,
    getInsightsDataAction,
    getReviewScoresAction,
    getReviewsYearOverviewAction,
    getTopicsAnalysisAction,
} from '@app/real-estate-group/state/insights.actions';
import {
    getClientSourcesData,
    getHouseMatchScore,
    getInsightsData,
    getLoading,
    getReviewScores,
    getReviewsYearOverviewData,
    getTopicsAnalysis,
    getTopicsAnalysisTopNegativeResults,
    getTopicsAnalysisTopPositiveResults,
    getTopicsAvailable,
    InsightsState,
} from '@app/real-estate-group/state/insights.reducer';
import { select, Store } from '@ngrx/store';
import { TopicSentimentResult } from '@shared/review/interfaces/topic-sentiment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InsightsSandbox {
    public readonly loading$ = this.store$.pipe(select(getLoading));
    public readonly insightsData$ = this.store$.pipe(select(getInsightsData));
    public readonly reviewScores$ = this.store$.pipe(select(getReviewScores));
    public readonly reviewsYearOverview$ = this.store$.pipe(select(getReviewsYearOverviewData));
    public readonly clientSources$ = this.store$.pipe(select(getClientSourcesData));
    public readonly topicsAnalysis$ = this.store$.pipe(select(getTopicsAnalysis));
    public readonly houseMatchScore$ = this.store$.pipe(select(getHouseMatchScore));
    public readonly topicsAnalysisTopPositiveResults$: Observable<TopicSentimentResult[]> = this.store$.pipe(
        select(getTopicsAnalysisTopPositiveResults)
    );
    public readonly topicsAnalysisTopNegativeResults$: Observable<TopicSentimentResult[]> = this.store$.pipe(
        select(getTopicsAnalysisTopNegativeResults)
    );

    public readonly topicsAvailable$: Observable<boolean> = this.store$.pipe(select(getTopicsAvailable));

    constructor(private readonly store$: Store<InsightsState>) {}

    public getInsightsData(locationId?: string): void {
        this.store$.dispatch(getInsightsDataAction({ locationId }));
    }
    public getHouseMatchScore(locationId?: string): void {
        this.store$.dispatch(getHouseMatchScoreAction({ locationId }));
    }

    public getScores(): void {
        this.store$.dispatch(getReviewScoresAction());
    }

    public getReviewYearOverview(realEstateGroupId: string, locationId?: string): void {
        this.store$.dispatch(getReviewsYearOverviewAction({ realEstateGroupId, locationId }));
    }

    public getClientSource(realEstateGroupId: string, locationId?: string): void {
        this.store$.dispatch(getClientSourceAction({ realEstateGroupId, locationId }));
    }

    public getTopicsAnalysis(locationId?: string): void {
        this.store$.dispatch(getTopicsAnalysisAction({ locationId }));
    }
}
