import { Component, OnInit } from '@angular/core';
import { TelecomBundleService } from '@app/telecom/containers/telecom-bundle/telecom-bundle.service';
import { TelecomBundle } from '@app/wizard/telecom/telecom.interfaces';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-telecom-bundle',
    templateUrl: './telecom-bundle.component.html',
    providers: [TelecomBundleService],
})
export class TelecomBundleContainer implements OnInit {
    public telecomBundle = TelecomBundle;

    constructor(
        public readonly telecomBundleService: TelecomBundleService,
        public readonly appUiSandbox: AppUiSandbox
    ) {}

    public ngOnInit(): void {
        this.telecomBundleService.init();
    }
}
