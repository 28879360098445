import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailFormComponent } from '@app/admin/components/mail-form/mail-form.component';
import { MailFields } from '@app/admin/enums/mail-fields.enum';
import { MailData } from '@app/admin/interfaces/mail-data';
import { AdminService } from '@app/admin/services/admin.service';
import { EmailTemplate } from '@app/email/enums/email-template.enum';
import { EmailContent } from '@app/email/interfaces/email-content';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { CreateContactLogAddresseesRequest } from '@app/move/interfaces/create-contact-log-addressees-request';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ClosableModalTemplateComponent, NotificationSandbox } from '@smooved/ui';
import { zip } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-mail-leaver-to-supplier-modal',
    templateUrl: './mail-leaver-to-supplier.modal.html',
})
export class MailLeaverToSupplierModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;
    @ViewChild(MailFormComponent) public mailForm: MailFormComponent;

    public loading = false;
    public mailData: MailData;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private readonly http: HttpClient,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly adminService: AdminService,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.loading = true;
        zip(this.adminService.renderMoveEmail(this.data, EmailTemplate.LeaverToSupplier), this.moveSandbox.get(this.data))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(([response, move]) => {
                this.setMailData(response, move);
            });
    }

    private setMailData = ({ content, attachments, subject }: EmailContent, move: Move): void => {
        this.mailData = {
            preData: {
                [MailFields.to]: {
                    supplierChecked: true,
                },
                [MailFields.cc]: {
                    mover: true,
                },
                [MailFields.bcc]: {
                    realEstateAgent: true,
                },
                [MailFields.content]: content,
                [MailFields.subject]: subject,
            },
            move,
            assets: (attachments || []).map((x) => ({
                location: x.location,
                name: x.name,
                id: x.id,
            })),
        };
    };

    public onSubmit(): void {
        if (this.mailForm.form.valid) {
            this.loading = true;

            const { content, subject, to, cc, bcc } = this.mailForm.form.getRawValue();

            // quill editor changes div tags to p tags. We want to use div tags in the email.
            const payload: CreateContactLogAddresseesRequest = {
                content: content.replace('<p>', '<div>').replace('</p>', '</div>'),
                subject,
                value: ContactLogType.EmailEnergyStopLeaver,
                to,
                cc,
                bcc,
            };

            if (this.mailForm.assets?.length) {
                payload.attachments = this.mailForm.assets.map((x) => ({
                    attachmentId: x.id,
                    name: x.name,
                }));
            }

            this.moveSandbox
                .createContactLogAddressees(this.data, payload)
                .pipe(finalize(() => (this.loading = false)))
                .subscribe((response) => {
                    this.notificationSandbox.sendSuccess();
                    this.closeDialog(response);
                });
        }
    }

    private closeDialog(result?: any): void {
        this.closableModalComponent.close(result);
    }
}
