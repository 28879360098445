import { Component, OnInit } from '@angular/core';
import { MovingDateVsCreatedOnGap } from '@app/move/interfaces/moving-date-vs-created-on-gap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { rows } from './moving-date-vs-created-on-gap.constants';
import { MovingDateVsCreatedOnGapService } from './moving-date-vs-created-on-gap.service';

@Component({
    selector: 'app-moving-date-vs-created-on-gap',
    templateUrl: './moving-date-vs-created-on-gap.component.html',
    styleUrls: ['./moving-date-vs-created-on-gap.component.scss'],
    providers: [MovingDateVsCreatedOnGapService],
})
export class MovingDateVsCreatedOnGapComponent implements OnInit {
    public loaded = false;
    public data$: Observable<MovingDateVsCreatedOnGap>;
    public rows = rows;

    constructor(private readonly movingDateVsCreatedOnGapService: MovingDateVsCreatedOnGapService) {}

    public ngOnInit() {
        this.data$ = this.movingDateVsCreatedOnGapService.fetchData().pipe(tap(() => (this.loaded = true)));
    }

    public trackByProperty(row): string {
        return row.property;
    }
}
