import { MenuItem } from '../../../menu/menu.interfaces';

export enum Menu {
    Detail,
}

export const menuItems: MenuItem[] = [
    {
        labelResource: 'COMMON.DETAILS',
        goTo: Menu.Detail,
    },
];
