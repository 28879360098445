import { Component } from '@angular/core';
import { HeaderService } from '@app/header/services/header.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { MenuItemIconSize, SvgIllustration } from '@smooved/ui';
import { FeatureScope, FeatureScopeSandbox } from '@smooved/core';

@Component({
    selector: 'app-management-menu',
    templateUrl: './management-menu.component.html',
    styles: [':host {display: block; padding: 1rem;}'],
})
export class ManagementMenuComponent {
    public readonly svgIllustration = SvgIllustration;
    public readonly menuItemIconSize = MenuItemIconSize;
    public readonly navigation = Navigation;
    public readonly featureScope = FeatureScope;

    constructor(
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly headerService: HeaderService
    ) {}

    public onMenuClick(): void {
        this.headerService.dispatch();
    }

    public forwardMenu(): void {
        this.headerService.forwardMenu();
    }
}
