import { Injectable } from '@angular/core';
import { defaultInterval, defaultLevel } from '@app/analytics/constants/analytics.constants';
import { EnergyOrderedConfirmation } from '@app/analytics/enums/energy-ordered-confirmation.enum';
import { CreatedByFlowBucket } from '@app/move/enums/created-by-flow-bucket.enum';
import { MoveCountByCreatedFlow } from '@app/move/interfaces/move-count-by-created-flow';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { ArrayUtils, CreatedByFlowEnum } from '@smooved/core';
import { ChartItem, StackedBarChartUtils, totalStack } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { otsTypes } from '../confirmations-long-period-chart/confirmatons-long-period-chart.constants';
import { last90days } from './confirmatons-short-period-chart.constants';

@Injectable()
export class ConfirmationsShortPeriodChartService {
    private activeCreatedByFlow = CreatedByFlowBucket.Ots;

    constructor(private smoovedAnalyticsSandbox: SmoovedAnalyticsSandbox) {}

    public mapToChart = map((items: MoveCountByCreatedFlow[]): ChartItem[] => {
        const data = this.prepareData(items);
        const chartItems = StackedBarChartUtils.prepareData<MoveCountByCreatedFlow>(
            data,
            (item: any) => item.type,
            (item: any) => item.type
        );

        const sortedChartItems = [
            chartItems.find(this.findbyLabel(EnergyOrderedConfirmation.Ots)) || this.createEmptyChartItem(EnergyOrderedConfirmation.Ots),
            chartItems.find(this.findbyLabel(EnergyOrderedConfirmation.Assisted)) ||
                this.createEmptyChartItem(EnergyOrderedConfirmation.Assisted),
            chartItems.find(this.findbyLabel(EnergyOrderedConfirmation.Pending)) ||
                this.createEmptyChartItem(EnergyOrderedConfirmation.Pending),
            chartItems.find(this.findbyLabel(EnergyOrderedConfirmation.Lost)) || this.createEmptyChartItem(EnergyOrderedConfirmation.Lost),
            chartItems.find(this.findbyLabel(totalStack)) || this.createEmptyChartItem(totalStack),
        ] as ChartItem[];

        StackedBarChartUtils.createWaterfall(sortedChartItems);

        return sortedChartItems;
    });

    private createEmptyChartItem(label: string): ChartItem {
        return {
            label,
            value: 0,
            stacks: {},
        };
    }

    public getData(): Observable<ChartItem[]> {
        return this.smoovedAnalyticsSandbox.getConfirmationsAnalytics(defaultInterval, defaultLevel, last90days).pipe(this.mapToChart);
    }

    private findbyLabel(label: string) {
        return (i: ChartItem): boolean => i.label === label;
    }

    private mapCreatedByFlow(flow: CreatedByFlowEnum): CreatedByFlowBucket {
        return otsTypes.includes(flow) ? CreatedByFlowBucket.Ots : CreatedByFlowBucket.Lp;
    }

    private prepareData(items: MoveCountByCreatedFlow[]): MoveCountByCreatedFlow[] {
        const preparedItems = items.filter((item) => this.mapCreatedByFlow(item.createdByFlow) === this.activeCreatedByFlow);
        const totalItem = {
            type: totalStack,
            createdByFlow: undefined,
            count: ArrayUtils.sum(preparedItems.map((i) => i.count)),
            interval: defaultInterval,
        };

        return [...preparedItems, totalItem];
    }
}
