import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { dateFormatPubDate, RealEstateAgency, RecommendationReview, ReviewSource } from '@smooved/core';
import { uiI18nKeyTypes } from '../../../i18n/i18n-key-type-map.constants';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiContext } from '../../../ui.enums';
import { NPS_REVIEWS_TEMPLATE_CONFIG } from '../../constants/reviews.constants';
import { NpsReviewsTemplateConfig } from '../../interfaces/nps-reviews-template-config';

@Component({
    selector: 'app-nps-review-facebook-header',
    templateUrl: './nps-review-facebook-header.component.html',
    styleUrls: ['./nps-review-facebook-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsReviewFacebookHeaderComponent {
    @Input() public item: RecommendationReview;

    public readonly iconSize = UiIconSize;
    public readonly iconAppearance = UiIconAppearance;
    public readonly uiContext = UiContext;
    public readonly i18nKeyTypes = uiI18nKeyTypes;
    public readonly source = ReviewSource.Facebook;
    public readonly pubDateformat = dateFormatPubDate;

    constructor(@Inject(NPS_REVIEWS_TEMPLATE_CONFIG) public templateConfig: NpsReviewsTemplateConfig) {}

    public get location(): RealEstateAgency {
        return this.item?.location as RealEstateAgency;
    }
}
