import { TodoState } from './todo/todo-state/todo-state.enum';

export class TodoUtils {
    public static isChecked(value: TodoState): boolean {
        return value === TodoState.Checked;
    }

    public static isUnchecked(value: TodoState): boolean {
        return value === TodoState.Unchecked;
    }

    public static isBlocked(value: TodoState): boolean {
        return value === TodoState.Blocked;
    }
}
