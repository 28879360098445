import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ArrayUtils, MatchSearchResult } from '@smooved/core';
import { UiSandbox } from '../../../ui.sandbox';
import { maxDisplayLogoSelectedBrokersCount } from './header-box-multi-select.constant';

@Component({
    selector: 'ui-header-box-multi-select',
    templateUrl: './header-box-multi-select.component.html',
    styleUrls: ['./header-box-multi-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBoxMultiSelectComponent implements OnInit {
    @Input() public selectedBrokers: MatchSearchResult[];
    public moreBrokersCount: number;
    public maxDisplayLogoSelectedBrokersSlice = maxDisplayLogoSelectedBrokersCount;

    constructor(public readonly uiSandbox: UiSandbox) {
    }

    public ngOnInit(): void {
        this.moreBrokersCount = Math.max(0, ArrayUtils.getLength(this.selectedBrokers) - maxDisplayLogoSelectedBrokersCount);
    }
}
