import { ArrayUtils } from './array.utils';
import { ObjectUtils } from './object-utils';

export class CoreUtils {
    public static isEmpty(value: any): boolean {
        return value == null || value.length === 0 || ArrayUtils.isEmpty(value) || ObjectUtils.isEmpty(value);
    }

    public static isFunction(val: unknown): boolean {
        return typeof val === 'function';
    }

    public static noop(): void {
        // This function does nothing
    }
}
