import { Component } from '@angular/core';

@Component({
    selector: 'app-sepa-modal',
    template: `
        <app-closable-modal-template>
            <p>
                Door ondertekening van dit mandaatformulier geef je toestemming aan (A) Smooved bvba om de geselecteerde leveranciers
                opdrachten te laten sturen naar jouw bank om de bedragen, horende bij de gekozen offertes van je rekening te debiteren en
                (B) aan jouw bank om een bedrag van je rekening te debiteren overeenkomstig de opdrachten zoals doorgegeven door de gekozen
                leveranciers. Je kan een Europese domiciliëring laten terugbetalen. Vraag jouw eigen bank naar de voorwaarden. Een verzoek
                tot terugbetaling moet binnen 8 weken na de datum van debitering van het bedrag van jouw rekening worden ingediend. De
                domiciliëring is van kracht vanaf de ontvangst door de gekozen leveranciers van de aanvraagformulieren. Eventuele
                openstaande facturen zullen bijgevolg automatisch aan jouw bank ter debitering worden aangeboden op de vervaldatum. Het
                meest recente bericht van domiciliëring wordt dus gebruikt om mijn factu(u)r(en) onder al mijn leveringscontracten met de
                gekozen leveranciers te debiteren.
            </p>
        </app-closable-modal-template>
    `,
})
export class SepaModalComponent {}
