export enum UiIcon {
    AccessTime = 'access_time',
    Add = 'add',
    AddCircleOutline = 'add_circle_outline',
    Analytics = 'analytics',
    ArrowDropDown = 'arrow_drop_down',
    ArrowDropUp = 'arrow_drop_up',
    CalendarToday = 'calendar_today',
    Call = 'call',
    Cancel = 'cancel',
    CardGiftcard = 'card_giftcard',
    Chat = 'chat',
    Check = 'check',
    CheckCircle = 'check_circle',
    CheckCircleOutline = 'check_circle_outline',
    ChevronLeft = 'chevron_left',
    ChevronRight = 'chevron_right',
    Close = 'close',
    ContentCopy = 'content_copy',
    Dangerous = 'dangerous',
    Delete = 'delete',
    Done = 'done',
    Download = 'download',
    Edit = 'edit',
    Email = 'email',
    Error = 'error',
    Euro = 'euro',
    ExpandMore = 'expand_more',
    ForwardToInbox = 'forward_to_inbox',
    GppMaybe = 'gpp_maybe',
    HowToReg = 'how_to_reg',
    Info = 'info',
    IosShare = 'ios_share',
    Language = 'language',
    Launch = 'launch',
    Leaderboard = 'leaderboard',
    Link = 'link',
    Lock = 'lock',
    LockOpen = 'lock_open',
    Mail = 'mail',
    MoreHoriz = 'more_horiz',
    MoreVert = 'more_vert',
    NearMe = 'near_me',
    OpenInNew = 'open_in_new',
    Office = 'office',
    Paid = 'Paid',
    Pending = 'pending',
    PersonOutline = 'person_outline',
    Phone = 'phone',
    PhoneCallback = 'phone_callback',
    PieChart = 'pier_chart',
    PinDrop = 'pin_drop',
    Place = 'place',
    RadioButtonUnchecked = 'radio_button_unchecked',
    Redeem = 'redeem',
    Reply = 'reply',
    Remove = 'remove',
    Search = 'search',
    Settings = 'settings',
    Share = 'share',
    Shortcut = 'shortcut',
    SimCardDownload = 'sim_card_download',
    Speed = 'speed',
    Star = 'star',
    StickyNote_2 = 'sticky_note_2',
    Tune = 'tune',
    Verified = 'verified',
    Visibility = 'visibility',
    VisibilityOff = 'visibility_off',
    ZoomIn = 'zoom_in',
    ZoomOut = 'zoom_out',
    Warning = 'warning',
    WaterDrop = 'water_drop',
    ThumbUp = 'thumb_up',
    ThumbDown = 'thumb_down',
}

export enum UiIconSize {
    Xs = 'xs',
    Sm = 'sm',
    Default = 'default',
    Md = 'md',
    Xm = 'Xm',
    Lg = 'lg',
    Xl = 'xl',
    Xxl = 'xxl',
    Xxxl = 'xxxl',
    Inherit = 'inherit',
}

export enum UiIconAppearance {
    Default = 'outlined',
    Filled = '',
    Outlined = 'outlined',
    Round = 'round',
    Sharp = 'sharp',
    TwoTone = 'two-tone',
    Brand = 'brand',
}

export enum Svg {
    Close = 'close',
    Checkmark = 'checkmark',
    Empty = 'empty',
    Alert = 'alert',
    Telecom = 'telecom',
    GasAndElect = 'gas-and-elect',
    Elect = 'elect',
    Gas = 'gas',
    Pre2000 = 'pre2000',
    Post2000 = 'post2000',
    New = 'new',
    Single = 'single',
    Half = 'half',
    Row = 'row',
    Apt = 'apt',
    Day = 'day',
    DayNight = 'day-night',
    Unknown = 'unknown',
    Ins = 'ins',
    Water = 'water',
    WaterAlt = 'water-alt',
    Box = 'box',
    Mail = 'mail',
    Plus = 'plus',
    Min = 'min',
    Tune = 'tune',
    File = 'file',
    ChevronRight = 'chevron-right',
    ChevronFilledUp = 'chevron-filled-up',
    ChevronFilledDown = 'chevron-filled-down',
    CheckCircle = 'check-circle',
    FixedLine = 'fixed-line',
    MobilePhone = 'mobile-phone',
    DigitalTelevision = 'digital-television',
    Internet = 'internet',
    Ok = 'ok',
    Nok = 'nok',
    NpsGauge = 'nps-gauge',
    NpsNeedle = 'nps-needle',
    Calendar = 'calendar',
    Phone = 'phone',
    Shield = 'shield',
    Umbrella = 'umbrella',
    Pencil = 'pencil',
    Remove = 'remove',
    Thumb = 'thumb',
    Info = 'info',
    Alone = 'alone',
    SideBySide = 'side-by-side',
    Blocked = 'blocked',
    Client = 'client',
}

export enum Brand {
    Facebook = 'facebook',
    LinkedIn = 'linkedin-in',
    Twitter = 'twitter',
    Instagram = 'instagram',
    Youtube = 'youtube',
}
