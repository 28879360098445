import { Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { LocationFormConfig } from '@app/real-estate-group/modals/location-form/location-form.interface';
import { LocationFormModal } from '@app/real-estate-group/modals/location-form/location-form.modal';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { getRealEstateGroupLocations, RealEstateGroupState } from '@app/real-estate-group/state/real-estate-group.reducer';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RealEstateAgency, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, deleteConfirmation, ModalSandbox, UiContext, UiIconAppearance, UiIconSize } from '@smooved/ui';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-external-influencer-configure-locations',
    templateUrl: './external-influencer-configure-locations.component.html',
    styleUrls: ['./external-influencer-configure-locations.component.scss'],
})
export class ExternalInfluencerConfigureLocationsComponent extends RxjsComponent {
    public locations$ = this.store$.select(getRealEstateGroupLocations).pipe(distinctUntilChanged(), takeUntil(this.destroy$));

    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconAppearance = UiIconAppearance;
    public readonly context = UiContext;
    public readonly iconSize = UiIconSize;

    constructor(
        private readonly store$: Store<RealEstateGroupState>,
        private readonly modalSandbox: ModalSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly translateService: TranslateService
    ) {
        super();
    }

    public onDelete(location: RealEstateAgency): void {
        const data = {
            data: this.translateService.instant(deleteConfirmation) as string,
        };

        this.modalSandbox.openConfirmModal(data, this.handleDeleteLocation(location), data, this.handleDeleteLocation(location));
    }

    public onEdit(location: RealEstateAgency): void {
        const data: LocationFormConfig = { location };
        this.openLocationModule({ data }, this.handleEditLocation);
    }

    public onAdd(): void {
        this.openLocationModule(null, this.handleAddLocation);
    }

    private openLocationModule(config: MatDialogConfig<LocationFormConfig>, callback: (result: RealEstateAgency) => void): void {
        this.modalSandbox.openModal(LocationFormModal, config, callback, LocationFormModal, config, callback);
    }

    private handleAddLocation = (result: RealEstateAgency): void => {
        if (result) this.realEstateGroupSandbox.addLocation(result).subscribe();
    };

    private handleEditLocation = (result: RealEstateAgency): void => {
        if (result) this.realEstateGroupSandbox.editLocation(result).subscribe();
    };

    private handleDeleteLocation(location: RealEstateAgency) {
        return (result: boolean): void => {
            if (result) this.realEstateGroupSandbox.deleteLocation(location).subscribe();
        };
    }
}
