import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { State, StringUtils } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WidgetsUriConfig, WIDGETS_URI_CONFIG } from './configs/widgets-uri-config';
import { WidgetProvidersModule } from './widget-providers.module';
import { WidgetDocument } from './widget.interface';

@Injectable({ providedIn: WidgetProvidersModule })
export class WidgetProviderService {
    public externalInfluencerSubject = new BehaviorSubject(null);

    constructor(private readonly httpClient: HttpClient, @Inject(WIDGETS_URI_CONFIG) private readonly config: WidgetsUriConfig) {}

    public getInfluencer(id: string): Observable<WidgetDocument> {
        const params = new HttpParams().set('state', State.Published.toString());
        const url = StringUtils.parseUri(this.config.byUrlUri, { id: encodeURIComponent(id) });
        return this.httpClient.get<WidgetDocument>(url, { params }).pipe(
            tap((response) => {
                this.externalInfluencerSubject.next(response);
            })
        );
    }
}
