<div class="u-text-align-center u-w40vw">
    <p
        class="u-margin"
        [innerHTML]="
            'MOVE.USER.MOVING_DATE_TOO_LATE.MODAL.ALERT.TEXT' | translate: {
                createdOn: createdOn | formatDate,
                movingDate: movingDate | formatDate,
                daysBetweenCreatedOnAndMovingDate: daysBetweenCreatedOnAndMovingDate }
        "
    ></p>
</div>
