import { Pipe, PipeTransform } from '@angular/core';
import { dateFormatDefault, DateUtils } from '@smooved/core';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    transform(value: string | Date, format: string = dateFormatDefault, emptyAs = '-'): string {
        if (!value) return emptyAs;
        const date = DateUtils.toDate(value);
        if (DateUtils.isDate(date)) {
            return DateUtils.format(date, format);
        }
    }
}
