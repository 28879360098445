<div
    class="u-flex-responsive u-flex-align-items-start __item-container"
    [ngClass]="{
        '__item-container--tablet-portrait-up': uiSandbox.tabletPortraitUp$ | async,
        '__item-container--up-to-and-including-phone-landscape': uiSandbox.upToAndIncludingPhoneLandscape$ | async
    }"
>
    <div class="u-margin-half u-bordered u-padding u-flex-column __item-service" *ngIf="!!showInsurances">
        <app-ribbon>{{ 'CONFIRM_ENERGY.LEGALLY_REQUIRED' | translate }}</app-ribbon>

        <img src="assets/images/smooved-insurance.png" class="__image u-display-block u-margin-0-auto" />
        <h5>{{ 'DASHBOARD.INSURANCES.TITLE' | translate }}</h5>
        <div class="u-flex-column u-margin-top-half __item-details">
            <app-check-list [content]="insurancesCheckList"></app-check-list>
        </div>
        <app-button class="u-margin-top u-margin-bottom" (onClick)="navigationSandbox.goToInsurancesFlow()">{{
            'CONFIRM_ENERGY.VIEW_OFFER' | translate
        }}</app-button>
    </div>

    <div class="u-margin-half u-bordered u-padding u-flex-column __item-service">
        <img src="assets/images/smooved-telecom.png" class="__image u-display-block u-margin-0-auto" />
        <h5>{{ 'DASHBOARD.TELECOM.TITLE' | translate }}</h5>
        <div class="u-flex-column u-margin-top-half __item-details">
            <app-check-list [content]="telecomCheckList"></app-check-list>
            <div class="u-flex-row u-flex-justify-content-center">
                <img
                    *ngFor="let provider of telecomSandbox.providers; let last = last"
                    class="u-margin-right-half u-margin-left-half"
                    [src]="'assets/images/suppliers/' + provider.toString().toLowerCase() + '-small.png'"
                    height="28px"
                    [alt]="provider"
                />
            </div>
        </div>
        <app-button class="u-margin-top u-margin-bottom" (onClick)="navigationSandbox.goToTelecomFlow()">{{
            'CONFIRM_ENERGY.VIEW_OFFER' | translate
        }}</app-button>
    </div>
</div>
