import { DateUtils } from '@smooved/core';
import { ChartLegend, Colors, ColorScale } from '@smooved/ui';

const i18n = 'SMOOVED_ANALYTICS.DETAILS.CONFIRMATION.CLUSTER_LEVELS';
export const colors = [ColorScale.Color3, Colors.Warning, Colors.Danger];

export const stacks = ['0', '1', '3'];
export const last3Q = DateUtils.tz().subtract(3, 'Q').startOf('Q').toDate();
export const excludeCurrentQuarter = DateUtils.tz().subtract(1, 'Q').endOf('Q').toDate();

export const labelKeys = {
    title: `${i18n}.TITLE`,

    level0: `${i18n}.NO_INTERVENTION.LABEL`,
    level1: `${i18n}.WITH_INTERVENTION.LABEL`,
    level3: `${i18n}.WITH_INTERVENTION_IMPACT.LABEL`,

    level0Sub: `${i18n}.NO_INTERVENTION.SUB`,
    level1Sub: `${i18n}.WITH_INTERVENTION.SUB`,
    level3Sub: `${i18n}.WITH_INTERVENTION_IMPACT.SUB`,
};

export const mapLevelToLabel = {
    '0': labelKeys.level0,
    '1': labelKeys.level1,
    '3': labelKeys.level3,
};

export const mapLevelToSubLabel = {
    '0': labelKeys.level0Sub,
    '1': labelKeys.level1Sub,
    '3': labelKeys.level3Sub,
};

export const legend: ChartLegend[] = stacks.map((type, i) => ({
    label: mapLevelToLabel[type],
    color: colors[i],
    sub: mapLevelToSubLabel[type],
}));
