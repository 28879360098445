<app-closable-modal-template #modal class="u-container-big" maxWidth="auto">
    <app-empty-state [direction]="uiDirection.Horizontal">
        <app-svg-illustration [svg]="illustrations.EnergyBulb" [context]="uiContext.Accent"></app-svg-illustration>
        <ng-container empty-state-caption [ngSwitch]="state">
            <h5 *ngSwitchCase="successStates.Completed">{{ 'ITS_A_WRAP' | translate }}</h5>
            <h5 *ngSwitchDefault>{{ 'ALMOST_THERE' | translate }}</h5>
        </ng-container>
        <ng-container empty-state-info [ngSwitch]="state">
            <app-todo
                [label]="'DETAIL.METER_READINGS.SUCCESS.COMPLETE' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                [state]="todoStates.Checked"
            >
            </app-todo>

            <app-todo
                *ngSwitchDefault
                [label]="'DETAIL.METER_READINGS.SUCCESS.NO_LEAVER' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                [state]="todoStates.Unchecked"
            >
                <app-todo-template *todoStateUnchecked>
                    <ng-container todoTemplateLabel>
                        <span>
                            <a
                                app-button
                                [appearance]="buttonAppearance.Link"
                                [routerLink]="createLeaverRoute"
                                class="u-display-inline-block u-h-auto"
                                >{{
                                    'DETAIL.METER_READINGS.SUCCESS.NO_LEAVER'
                                        | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard
                                        | translateCut: 0
                                }}</a
                            >
                            {{
                                'DETAIL.METER_READINGS.SUCCESS.NO_LEAVER'
                                    | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard
                                    | translateCut: 1
                            }}
                        </span>
                    </ng-container>
                </app-todo-template>
            </app-todo>
            <app-todo
                *ngSwitchCase="successStates.LeaverNotComplete"
                [label]="'DETAIL.METER_READINGS.SUCCESS.LEAVER_NOK' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                [state]="todoStates.Unchecked"
            >
            </app-todo>
            <app-todo
                *ngSwitchCase="successStates.Completed"
                [label]="'DETAIL.METER_READINGS.SUCCESS.LEAVER_OK' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                [state]="todoStates.Checked"
            >
            </app-todo>
        </ng-container>
        <button app-button (click)="modal.close()">
            {{ 'VIEW_TRANSACTION' | translate }}
        </button>
    </app-empty-state>
</app-closable-modal-template>
