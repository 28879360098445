<form [formGroup]="form" (ngSubmit)="upload()">
    <app-basic-file-input
        id="file-input"
        width="auto"
        [label]="'INVOICING.LABEL' | translate"
        (change)="onChange($event)"
    ></app-basic-file-input>
    <app-check-input
        *ngIf="showTermsAndConditions"
        width="auto"
        [formControlName]="formControlNames.Consent"
        [custom]="false"
        [option]="consentOption"
        [hasMargin]="false"
        [hasMarginDouble]="true"
    ></app-check-input>
    <div class="u-text-align-right">
        <button type="submit" app-button [disabled]="!file">{{ 'SEND' | translate }}</button>
    </div>
</form>
