import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { Move } from '@app/move/interfaces/move';
import { createAction, props } from '@ngrx/store';
import { PaginationRequest, PaginationResponse } from '@smooved/core';

export const getMoves = createAction(
    '[RealEstateAgentDashboard] Get moves',
    props<{ pagination: PaginationRequest; realEstateAgentFilter: RealEstateAgentFilter }>()
);
export const getMovesSuccess = createAction(
    '[RealEstateAgentDashboard] Get moves success',
    props<{ response: PaginationResponse<Move> }>()
);
export const getMovesFailed = createAction('[RealEstateAgentDashboard] Get moves failed');
