import { Injectable } from '@angular/core';
import { Thread } from '@app/message/interfaces/thread.interface';
import { Order } from '@app/order/interfaces/order';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class OrderDetailService {
    protected loadingBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingBehaviorSubject.asObservable();

    protected orderBehaviorSubject: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
    public order$ = this.orderBehaviorSubject.asObservable();

    constructor(protected readonly orderSandbox: OrderSandbox, protected readonly notificationSandbox: NotificationSandbox) {}

    public setOrder(order: Order): void {
        this.orderBehaviorSubject.next({ ...order });
    }

    public findOrder<T extends Order>(id: string): Observable<T> {
        return this.orderSandbox.findById<T>(id);
    }

    public patchOrder(id: string, payload: object, cb: () => void): void {
        this.patch(this.orderSandbox.patch(id, payload), cb);
    }

    public patchGift(id: string, payload: object, cb: () => void): void {
        this.patch(this.orderSandbox.patchGift(id, payload), cb);
    }

    public patchMoveByOrder(id: string, payload: object, cb: () => void): void {
        this.patch(this.orderSandbox.patchMoveByOrder(id, payload), cb);
    }

    public onOrderNoteChange(id: string, orderNote: string, cb: () => void): void {
        this.patchOrder(
            id,
            {
                noteForSmooved: orderNote || null,
            },
            cb
        );
    }

    public createMessage(id: string, message: string): Observable<Thread> {
        return this.orderSandbox.createMessage(id, message);
    }

    protected patch<T extends Order>(call: Observable<T>, cb: (order: T) => void): void {
        this.loadingBehaviorSubject.next(true);
        call.pipe(finalize(() => this.loadingBehaviorSubject.next(false))).subscribe((order) => {
            cb(order);
            this.setOrder(order);
        });
    }
}
