import { isEqual } from 'lodash';
import { PaginationRequest } from '../interfaces';

export class ArrayUtils {
    public static sum(values: number[]): number {
        return values.reduce((sum: number, count: number): number => sum + (count ?? 0), 0);
    }

    public static equal(a: any[], b: any[]): boolean {
        return isEqual(a, b);
    }

    public static flatMap<T>(map: [unknown, T[]][]): T[] {
        return [...map].reduce((list: T[], [, values]) => {
            return [...list, ...values.map((v: T) => v)];
        }, []);
    }

    public static clone<T>(array: T[]): T[] {
        return JSON.parse(JSON.stringify(array)) as T[];
    }

    public static first<T>(values: T[]): T {
        if (!Array.isArray(values) || ArrayUtils.isEmpty(values)) return null;
        return values[0];
    }

    public static last<T>(values: T[]): T {
        if (!Array.isArray(values) || ArrayUtils.isEmpty(values)) return null;
        return values[values.length - 1];
    }

    public static groupBy<T, Tres>(
        list: T[],
        keyGetter: (_: T) => string | number,
        valueGetter: (_: T) => unknown = (_): T => _
    ): { [key: string]: Tres[] } {
        return list.reduce((grouped, item) => {
            const groupId = keyGetter(item);
            if (!grouped[groupId]) grouped[groupId] = [];
            grouped[groupId].push(valueGetter(item) as Tres);
            return grouped;
        }, {});
    }

    public static mapBy<T, Tres>(
        list: T[],
        keyGetter: (_: T) => string | number,
        valueGetter: (_: T) => unknown = (_): T => _
    ): Map<string | number, Tres[]> {
        const map = new Map<string | number, Tres[]>();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [valueGetter(item) as Tres]);
            } else {
                collection.push(valueGetter(item) as Tres);
            }
        });
        return map;
    }

    public static toArray<T>(value: T | T[]): T[] {
        if (!value) return [];
        return Array.isArray(value) ? value : [value];
    }

    public static clean<T>(value: T[]): T[] {
        return value?.filter((v): boolean => !!v);
    }

    public static trim(value: string[]): string[] {
        return value?.map((v): string => v?.trim());
    }

    public static contains<T>(array: T[], value: T): boolean {
        return array?.indexOf(value) > -1;
    }

    public static isEmpty<T>(value: T[]): boolean {
        if (value == null) return true;
        return Array.isArray(value) && value.filter((x) => x != null).length === 0;
    }

    public static getLength = <T>(value: T[]): number => {
        return value?.length ?? 0;
    };

    public static isLength = <T>(value: T[], condition: number): boolean => {
        return ArrayUtils.getLength(value) === condition;
    };

    public static paginate<T>(value: T[], { pageSize, pageIndex }: PaginationRequest): T[] {
        return value.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }

    public static create(length: number): undefined[] {
        return new Array(length).fill(undefined);
    }

    public static deleteItems<T>(value: T[], fromIndex: number, deleteCount = 1): void {
        value.splice(fromIndex, deleteCount);
    }

    public static unique<T>(value: T[]): T[] {
        if (!value) return [];
        return [...new Set(value)];
    }

    public static uniqueByProperty<T, K extends keyof T>(arr: T[], key: K): T[] {
        const uniqueMap = new Map<T[K], T>();

        arr.forEach((item) => {
            if (!uniqueMap.has(item[key])) {
                uniqueMap.set(item[key], item);
            }
        });

        return Array.from(uniqueMap.values());
    }

    public static includes<T>(list: T[], value: T): boolean {
        if (!list || ArrayUtils.isEmpty(list)) return false;
        return list.includes(value);
    }

    public static allTheSame<T>(value: T[]): boolean {
        const length = ArrayUtils.getLength(value);
        if (length === 0) {
            return true; // Empty array is considered as having all items the same
        }

        const firstItem = value[0];

        for (let i = 1; i < length; i++) {
            if (value[i] !== firstItem) {
                return false;
            }
        }

        return true;
    }

    public static getLowest<T>(value: T[]): T {
        const length = ArrayUtils.getLength(value);
        if (length === 0) {
            return undefined; // Return undefined for an empty array
        }

        let lowest = value[0];

        for (let i = 1; i < length; i++) {
            if (value[i] < lowest) {
                lowest = value[i];
            }
        }

        return lowest;
    }

    public static getAdditions<T>(originalArray: T[], newArray: T[]): T[] {
        const set1 = new Set(originalArray);
        return [...newArray.filter((x) => !set1.has(x))];
    }

    public static getDifference<T>(array1: T[], array2: T[]): T[] {
        const set1 = new Set(array1);
        const set2 = new Set(array2);
        return [...array1.filter((x) => !set2.has(x)), ...array2.filter((x) => !set1.has(x))];
    }

    public static hasDifference<T>(array1: T[], array2: T[]): boolean {
        return !ArrayUtils.isEmpty(ArrayUtils.getDifference(array1, array2));
    }

    public static getFirstItems<T>(array: T[], amount: number): T[] {
        return amount === 1 ? [ArrayUtils.first(array)] : array.slice(0, Math.min(amount, ArrayUtils.getLength(array)));
    }
}
