import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { prepared } from '@shared/order/constants/properties.constants';
import { dateTimeFormatDefault } from '@smooved/core';

@Component({
    selector: 'app-check-item-gift-prepared',
    template: `
        <app-check-item [checked]="!!order.prepared.value" align="top">
            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="unchecked">
                <p class="u-margin-bottom-half">{{ 'PARTNER.GIFT.PREPARED' | translate }}</p>
                <app-button *ngIf="!readonly" (onClick)="set(true)">{{ 'CHECK' | translate }}</app-button>
            </div>

            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="checked">
                <p class="u-margin-bottom-half">{{ 'PARTNER.GIFT.PREPARED' | translate }}</p>
                <p class="u-color-muted u-font-size-small">
                    {{ order.prepared.lastModifiedOn || order.prepared.createdOn | formatDate: dateTimeFormat }}
                </p>
                <a *ngIf="!readonly" (click)="set(false)" class="u-link">{{ 'UNCHECK' | translate }}</a>
            </div>
        </app-check-item>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemGiftPreparedComponent {
    @Input() public order: Order;
    @Input() public readonly: boolean;
    @Output() public patch: EventEmitter<object> = new EventEmitter<object>();

    public dateTimeFormat = dateTimeFormatDefault;

    public set(value: boolean): void {
        this.patch.emit({
            [prepared]: value,
        });
    }
}
