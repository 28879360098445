import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '../../../move/interfaces/move';

@Component({
    selector: 'app-todo-energy-ordered',
    templateUrl: './todo-energy-ordered.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEnergyOrderedComponent {
    @Input() public move: Move;
}
