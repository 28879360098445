export enum ButtonTheme {
    Secondary = 'secondary',
    SecondaryOutline = 'secondary-outline',
    Danger = 'danger',
    None = 'no-style',
    Logo = 'logo-button',
    Circle = 'circle',
    IconCircle = 'icon-circle',
    IconCircleGray = 'icon-circle-gray',
    Tertiary = 'tertiary',
}

export enum ButtonType {
    Default = 'button',
    Submit = 'submit',
    Link = 'link',
}
