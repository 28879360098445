import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { UiI18nKeyType } from '../../../i18n/i18n-key-type-map.constants';
import { MenuItem, MenuItemAppearance, MenuItemSize } from '../../../menu';
import { UriSchemaType } from '../../../pipes';
import { RealEstateGroupAppointment } from '../../interfaces';
import { RealEstateGroupAppointmentService } from '../../services/real-estate-group-appointment.service';
import { Menu, menuItems } from './real-estate-group-appointment-detail.constants';

@Component({
    selector: 'app-real-estate-group-appointment-detail',
    templateUrl: './real-estate-group-appointment-detail.modal.html',
})
export class RealEstateGroupAppointmentDetailModal implements OnInit {
    public realEstateGroupAppointment: RealEstateGroupAppointment;
    public loading = false;
    public active = Menu.Detail;

    public readonly menuItems = menuItems;
    public readonly menu = Menu;
    public readonly menuItemAppearance = MenuItemAppearance;
    public readonly menuItemSize = MenuItemSize;
    public readonly uiI18nKeyType = UiI18nKeyType;
    public readonly uriSchemaType = UriSchemaType;

    constructor(
        private readonly realEstateGroupAppointmentService: RealEstateGroupAppointmentService,
        @Inject(MAT_DIALOG_DATA) public readonly data: string
    ) {}

    public ngOnInit(): void {
        this.loading = true;
        this.realEstateGroupAppointmentService
            .getById(this.data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response) => (this.realEstateGroupAppointment = response));
    }

    public setActive(menuItem: MenuItem): void {
        this.active = menuItem.goTo;
    }
}
