import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-select-language',
    template: `
        <form [formGroup]="form">
            <ui-dropdown-input
                formControlName="language"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                appearance="fill"
                class="u-w-auto"
                [options]="languageOptions"
            ></ui-dropdown-input>
        </form>
    `,
    styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    @Input() width: string;

    public languageOptions: DropdownInput<string>[] = [
        {
            id: 'language-en',
            label: 'EN',
            name: 'language',
            value: 'en',
        },
        {
            id: 'language-fr',
            label: 'FR',
            name: 'language',
            value: 'fr',
        },
        {
            id: 'language-nl',
            label: 'NL',
            name: 'language',
            value: 'nl',
        },
    ];

    public form: UntypedFormGroup = this.fb.group({
        language: [this.translateService.currentLang],
    });

    constructor(
        private fb: UntypedFormBuilder,
        private translateService: TranslateService,
        private translationSandbox: TranslationSandbox,
        private cdr: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.subs.push(
            this.form.get('language').valueChanges.subscribe((value) => {
                this.translationSandbox.setLanguage(value);
            })
        );

        this.subs.push(
            this.translateService.onLangChange.subscribe((event) => {
                if (event.lang === this.form.get('language').value) return;
                this.form.get('language').setValue(event.lang);
                this.cdr.detectChanges();
            })
        );
    }

    public ngOnDestroy() {
        this.subs.forEach((sub) => sub.unsubscribe());
    }
}
