import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { startFromQuarter, startFromQuarterForAdmin } from '@app/form/components/quarter-input/quarter-input.constants';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { PerUnitOfTime } from '@app/shared/enums/per-unit-time.enum';
import { DateUtils } from '@smooved/core';
import { BaseInput, DropdownInput } from '@smooved/ui';

@Component({
    selector: 'app-quarter-input',
    template: `
        <ui-dropdown-input
            [options]="quarterOptions"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [label]="label"
            [placeholder]="placeholder"
            [id]="id"
            [autoFocus]="autoFocus"
            [(ngModel)]="innerModel"
            (ngModelChange)="onModelChange()"
        ></ui-dropdown-input>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuarterInputComponent),
            multi: true,
        },
    ],
})
export class QuarterInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id = 'quarter';
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: number;

    public quarterOptions: DropdownInput<number>[];

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly invoicingSandbox: InvoicingSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            const startFrom = (isAdmin ? startFromQuarterForAdmin : startFromQuarter).clone();
            const quarterOptions: DropdownInput<number>[] = [];

            while (startFrom.toDate() <= DateUtils.now()) {
                quarterOptions.push({
                    id: startFrom.toDate().getTime()?.toString(),
                    name: 'quarter',
                    label: startFrom.format('[Q]Q Y'),
                    value: DateUtils.tz(startFrom).toDate().getTime(),
                });
                startFrom.add(1, PerUnitOfTime.Quarter);
            }

            this.quarterOptions = quarterOptions;
        });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: number): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
