import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import {
    copyToClipboardSuccessLabel,
    energyOptimizationLink,
} from '@app/real-estate-agent/components/other-simulation-tools/other-simulation-tools.constants';
import { BaseDashboardCardComponent, NotificationSandbox } from '@smooved/ui';
import { filter, map, take } from 'rxjs/operators';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-other-simulation-tools',
    templateUrl: './other-simulation-tools.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherSimulationToolsComponent extends BaseDashboardCardComponent {
    public energyOptimizationLink$ = this.authenticationSandbox.realEstateAgentId$.pipe(
        map((realEstateAgentId) => {
            if (!realEstateAgentId) return null;
            return energyOptimizationLink.replace(':realEstateAgentId:', realEstateAgentId);
        })
    );

    public featureScope = FeatureScope;

    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly clipboard: Clipboard,
        private readonly router: Router
    ) {
        super();
    }

    public onEnergyOptimizationCopy(): void {
        this.energyOptimizationLink$
            .pipe(
                take(1),
                filter((link) => !!link)
            )
            .subscribe((link) => {
                this.notificationSandbox.success(copyToClipboardSuccessLabel);
                this.clipboard.copy(link);
            });
    }

    public onEnergyOptimizationRoute(): void {
        this.energyOptimizationLink$
            .pipe(
                take(1),
                filter((link) => !!link)
            )
            .subscribe((link) => {
                window.open(link, '_blank');
            });
    }
}
