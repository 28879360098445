import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { maxItems } from '@app/message/components/thread/thread.constants';
import { Message } from '@app/message/interfaces/message.interface';
import { Thread } from '@app/message/interfaces/thread.interface';
import { SimpleChangesUtils, SortDirection } from '@smooved/core';
import { ButtonAppearance } from '@smooved/ui';

@Component({
    selector: 'app-thread',
    templateUrl: './thread.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadComponent implements OnChanges {
    @Input() public thread: Thread;
    @Input() public sortDirection = SortDirection.Desc;
    @Input() public maxItems = maxItems;

    public readonly buttonAppearance = ButtonAppearance;

    public allItemsShown = false;
    public maxForSlice: number;
    public messages: Message[] = [];

    constructor() {
        this.setMax();
    }

    public ngOnChanges({ thread, sortDirection }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(thread, sortDirection)) {
            this.messages = this.sortDirection === SortDirection.Desc ? [...this.thread.messages.reverse()] : [...this.thread.messages];
        }
    }

    public toggleShowItems(): void {
        this.allItemsShown = !this.allItemsShown;
        this.setMax();
    }

    private setMax(): void {
        this.maxForSlice = this.allItemsShown ? undefined : this.maxItems;
    }
}
