export class StringUtils {
    public static empty = '';

    /**
     * Method changes the first letter into uppercase
     * @param {string} The string to be transformed
     * @returns {string} transformed string
     */
    public static capitalize(string: string, trim = false): string {
        if (!string) return '';
        const result = trim ? string.trim() : string;
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    /**
     * Method parses 'true' as true, every other value will return false
     * @param {string} value: the value to parse
     * @returns {boolean}: Whether or not the given value is 'true'
     */
    public static parseBoolean(value: string): boolean {
        return value?.toLowerCase() === 'true';
    }

    /**
     * Method parses 'false' as false, every other value will return true
     * @param {string} value: the value to parse
     * @returns {boolean}: Whether or not the given value is 'false'
     */
    public static parseBooleanReverse(value: string): boolean {
        return value?.toLowerCase() !== 'false';
    }

    /**
     * Method matches the given value with the escaped regexp and replaces it with the given replacement
     * @param {string} value: String to replace
     * @param {string} pattern: Pattern to escape in the regexp to match the given value
     * @param {string} replacement: String to replace the matched part in the given value
     * @returns {string} the replaced value
     */
    public static matchByPatternAndReplace(value: string, pattern: string, replacement: string = ''): string {
        const regExp = new RegExp(`${pattern}([^\\s]+)?`, 'gi');
        return value.replace(regExp, replacement).trim();
    }

    /**
     * Method checks whether or not the given value is of type String
     * @param value {any}: the value to check
     * @returns {boolean} whether or not the value is a string.
     */
    public static isString(value: any): boolean {
        return typeof value === 'string';
    }

    /**
     * Method checks whether or not the given value is a string containing at least one character
     * @param {string} value: the value to check
     * @returns {boolean} whether or not the value, which has been checked, is a string containing at least one character
     */
    public static hasValue(value: string): boolean {
        return StringUtils.isString(value) && value.length > 0;
    }

    /**
     * Method returns a string separated by the given separator.
     * @param {Array} values: Array to be joined
     * @param {string} separator: String to be used as separator in between the items
     * @returns {string} the joined string
     */
    public static join(values: string[], separator: string = ' '): string {
        return Array.isArray(values)
            ? values
                  .filter((value: string) => StringUtils.hasValue(value))
                  .map((value: string) => value.trim())
                  .join(separator)
            : '';
    }

    /**
     * Method returns a string transformed into a url-valid/readable slug
     * @param {string} separator: String to be tranformed
     * @returns {string} the transformed string / slug
     */
    public static slugify(string: string): string {
        string = string.replace(/^\s+|\s+$/g, ''); // trim
        string = string.toLowerCase();

        const from = 'ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;';
        const to = 'aaaaaeeeeeiiiiooooouuuunc------';
        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        string = string
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return string;
    }

    /**
     * Method returns an interpolated string
     * @param {string} original: String to parse
     * @param {RegExp} interpolationRegExpFn: factory that defines the interpolation RegExp
     * @param {object} interpolateParams: parameters that need to be replaced
     * @returns {string} interpolated string
     */
    public static parseByRegExp(original: string, interpolationRegExpFn: (key: string) => RegExp, interpolateParams: object): string {
        if (!(original && interpolateParams)) return original;
        return Object.entries(interpolateParams).reduce((newValue: string, [key, value]: [string, string]) => {
            return newValue.replace(interpolationRegExpFn(key), value);
        }, original);
    }

    /**
     * Method returns an interpolated string, interpolation template --> {key}
     * @param {string} original: String to parse
     * @param {object} interpolateParams: parameters that need to be replaced
     * @returns {string} interpolated string
     */
    public static parse(original: string, interpolateParams: object): string {
        return StringUtils.parseByRegExp(original, (key) => new RegExp(`{\s*${key}\s*}`, 'g'), interpolateParams);
    }

    /**
     * Method returns an interpolated string, interpolation template --> :key
     * @param {string} original: String to parse
     * @param {object} interpolateParams: parameters that need to be replaced
     * @returns {string} interpolated string
     */
    public static parseUri(original: string, interpolateParams: object): string {
        return StringUtils.parseByRegExp(original, (key) => new RegExp(`\:${key}`, 'g'), interpolateParams);
    }

    public static toLowerCase(value): string {
        return value?.toString().toLocaleLowerCase() ?? '';
    }

    /**
     * Transforms target value into a string in UPPER_SNAKE_CASE
     * @param {string} value to be transformed
     * @returns {string} transformed value
     */
    public static toUpperSnakeCase(value: string): string {
        return (value ?? '').toUpperCase().replace(/-/gi, '_');
    }

    /**
     * Example: Parse urls from ; separated string https://www.google.com;https://www.smooved.be
     */
    public static splitBy(value: string, separator = ';'): string[] {
        if (!value) return null;
        return value
            .split(separator)
            .map((v) => v.trim())
            .filter((v) => !!v);
    }

    public static getRandomString(endSubstring?: number): string {
        return Math.random().toString(36).substring(2, endSubstring);
    }

    public static stripHttpProtocol(url: string): string {
        return url.replace(/^(http:\/\/|https:\/\/)?(www\.)?/, '').replace(/\/.*/, '');
    }

    public static stripHttpProtocolWithoutTrailing(url: string): string {
        return url.replace(/^(http:\/\/|https:\/\/)?(www\.)?/, '').replace(/\.*/, '');
    }

    public static removeMultipleSpaces(value: string): string {
        return value.replace(/\s+/g, ' ');
    }
}
