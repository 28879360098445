import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    AddresseesOptionsConfig,
    createAddresseesOptions,
    formControlNames,
} from '@app/admin/components/mail-form/addressees/addressees.constants';
import { Addressees } from '@app/email/interfaces/addressees';
import { Move } from '@app/move/interfaces/move';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { BaseInput } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-addressees',
    templateUrl: './addressees.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddresseesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddresseesComponent),
            multi: true,
        },
    ],
})
export class AddresseesComponent extends BaseInput implements ControlValueAccessor, OnInit, Validator {
    @Input() public move: Move;
    @Input() public prefix: string;

    public readonly formControlNames = formControlNames;
    public readonly i18nKeyType = AppI18nKeyType;

    public options;
    public form = this.formBuilder.group({
        [formControlNames.mover]: null,
        [formControlNames.realEstateAgent]: null,
        [formControlNames.linkedMove]: null,
        [formControlNames.supplierChecked]: null,
        [formControlNames.supplier]: null,
        [formControlNames.otherChecked]: null,
        [formControlNames.other]: null,
    });

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: FormBuilder
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        const config: AddresseesOptionsConfig = {
            prefix: this.prefix,
            moverRole: this.move.user.role,
            emailMover: this.move.user.email,
            emailLinkedMove: (this.move.linkedMove as Move)?.user.email,
            emailRealEstateAgent: (this.move.realEstateAgent as RealEstateAgent)?.email,
        };
        this.options = createAddresseesOptions(config);
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.form.invalid) {
                this.propagateChange(null);
            } else {
                this.propagateChange(this.resultFactory(this.form.getRawValue()));
            }
        });
    }

    private resultFactory(value): Addressees {
        const { mover, realEstateAgent, linkedMove, supplier, other } = value;
        return {
            mover,
            realEstateAgent,
            linkedMove,
            supplier,
            other,
        };
    }

    public validate(): ValidationErrors | null {
        return this.form.invalid ? { addresses: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form?.disable();
        } else {
            this.form?.enable();
        }
        this.form?.updateValueAndValidity();
    }

    public writeValue(addressees: Addressees): void {
        const payload: any = { ...addressees };
        if (payload.supplier) {
            payload.supplierChecked = true;
        }
        if (payload.other) {
            payload.otherChecked = true;
        }
        this.form.patchValue(payload);
    }

    public disableMover(revert?: boolean): void {
        this.setDisabledPropertyState(formControlNames.mover, !revert);
    }

    public disableRealEstateAgent(revert?: boolean): void {
        this.setDisabledPropertyState(formControlNames.realEstateAgent, !revert);
    }

    public disableLinkedMove(revert?: boolean): void {
        this.setDisabledPropertyState(formControlNames.linkedMove, !revert);
    }

    public disableSuplier(revert?: boolean): void {
        this.setDisabledPropertyState(formControlNames.supplierChecked, !revert);
    }

    public disableOther(revert?: boolean): void {
        this.setDisabledPropertyState(formControlNames.otherChecked, !revert);
    }

    private setDisabledPropertyState(property: string, isDisabled = true): void {
        isDisabled ? this.form.get(property).disable() : this.form.get(property).enable();
    }
}
