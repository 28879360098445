import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EanResult } from '@app/energy/interfaces/ean-result';
import { eanInfoTableDef } from '@app/energy/modals/ean-info/ean-info.constants';

@Component({
    selector: 'app-ean-info-modal',
    templateUrl: './ean-info.modal.html',
})
export class EanInfoModal {
    public readonly tableDef = eanInfoTableDef;

    constructor(@Inject(MAT_DIALOG_DATA) public data: EanResult[]) {}
}
