import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FileInput } from '@app/form/interfaces/file-input';
import { getFileExtension } from '@app/form/utils/file-extension.util';
import { isImage } from '@app/form/utils/is-image.util';
import { UiSize } from '@smooved/ui';

@Component({
    selector: 'app-thumbnail',
    template: `
        <div class="u-position-relative u-w100p u-h100p" [ngSwitch]="isImage()">
            <div *ngSwitchCase="true" class="u-flex-column u-flex-justify-content-center u-flex-align-items-center u-w100p u-h100p">
                <ng-container *ngIf="!isPreview">
                    <ng-container *ngIf="{ location: file.location | authImage : detailed | async } as vm">
                        <app-icon *ngIf="!vm.location" icon="refresh" [spinning]="true" [size]="size.Xl" context="muted"></app-icon>
                        <img *ngIf="!detailed" [src]="vm.location" (click)="detail.emit(file)" class="__thumbnail u-cursor-pointer" />
                        <div
                            *ngIf="detailed"
                            class="__preview-cover u-cursor-pointer"
                            (click)="detail.emit(file)"
                            [style.background-image]="vm.location"
                        ></div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isPreview">
                    <img *ngIf="!detailed" [src]="file.value" class="__thumbnail __thumbnail--preview" />
                    <div
                        *ngIf="detailed"
                        class="__preview-cover __cover--preview"
                        [style.background-image]="'url(' + file.value + ')'"
                    ></div>
                </ng-container>
            </div>

            <div *ngSwitchCase="false">
                <div
                    *ngIf="!isPreview"
                    (click)="detail.emit(file)"
                    [title]="file.name"
                    class="__thumbnail __thumbnail--file u-flex-column u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
                >
                    <app-icon class="__file-icon" icon="description" [size]="detailed ? size.Xl : size.Md"></app-icon>
                    <span class="u-font-size-small u-font-weight-bold" *ngIf="!detailed">{{ getExtension() }}</span>
                </div>

                <div
                    *ngIf="isPreview"
                    [title]="file.name"
                    class="__thumbnail __thumbnail--file u-flex-column u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
                >
                    <app-icon class="__file-icon" icon="description" [size]="detailed ? size.Xl : size.Md"></app-icon>
                    <span class="u-font-size-small u-font-weight-bold" *ngIf="!detailed">{{ getExtension() }}</span>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent {
    @Input() public isPreview = false;
    @Input() public file: FileInput;

    @HostBinding('class.__detailed')
    @Input()
    public detailed = false;

    public readonly size = UiSize;

    @Output() public detail: EventEmitter<FileInput> = new EventEmitter<FileInput>();

    public isImage(): boolean {
        return isImage(this.file);
    }

    public getExtension(): string {
        return getFileExtension(this.file);
    }
}
