<div
    class="u-padding u-background-gray-medium u-font-size-small"
    *ngIf="!!move?.telecomOffer?.providerChoice || !!move?.telecomTransfer"
    [ngSwitch]="collapsed"
>
    <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between" [ngClass]="{ 'u-margin-bottom': !collapsed }">
        <span *ngIf="move.telecomOffer?.telecomOfferSelection as selection"
            >{{ selection.provider }} -
            {{
                selection.productNameLabels
                    | translatedLabel
                        : {
                              fallback: {
                                  object: selection,
                                  label: 'productName'
                              }
                          }
                    | async
            }}</span
        >
        <span *ngIf="!!move.telecomTransfer">{{ 'MOVE.TELECOM_TRANSFER' | translate }} - {{ move.telecomTransfer.currentSupplier }}</span>
        <a class="u-link" *ngSwitchCase="true" (click)="collapsed = false">{{ 'ADMIN.DASHBOARD.DETAIL.SHOW_DETAILS' | translate }}</a>
        <a class="u-link" *ngSwitchCase="false" (click)="collapsed = true">{{ 'ADMIN.DASHBOARD.DETAIL.HIDE_DETAILS' | translate }}</a>
    </div>
    <div *ngIf="!collapsed">
        <ng-container *ngIf="!!move.telecomTransfer">
            <div class="u-flex-row u-margin-bottom">
                <div class="u-w40p u-color-muted u-font-weight-semi-bold">{{ 'MOVE.CURRENT_SUPPLIER' | translate }}</div>
                <div class="u-w60p">{{ move.telecomTransfer.currentSupplier }}</div>
            </div>
            <div class="u-flex-row u-margin-bottom">
                <div class="u-w40p u-color-muted u-font-weight-semi-bold">{{ 'MOVE.CLIENT_NUMBER' | translate }}</div>
                <div class="u-w60p">{{ move.telecomTransfer.clientNumber }}</div>
            </div>
            <div class="u-flex-row u-margin-bottom">
                <div class="u-w40p u-color-muted u-font-weight-semi-bold">{{ 'MOVE.FIXED_LINE' | translate }}</div>
                <div class="u-w60p">{{ move.telecomTransfer.fixedLine }}</div>
            </div>
        </ng-container>

        <div class="u-flex-row u-margin-bottom" *ngIf="move.telecomOffer?.telecomBundle as bundle">
            <span class="u-w40p u-color-muted">{{ 'INTERESTS' | translate }}</span>
            <div class="u-w60p">
                <ul class="u-list-style-initial">
                    <ng-container *ngFor="let item of bundle | keyvalue">
                        <li *ngIf="item.value">
                            {{ item.key | i18nKey: appI18nKeyType.TelecomBundle | translate }}
                        </li>
                    </ng-container>
                </ul>
                <div *ngIf="bundle.digitalTelevision && bundle.mobilePhone">
                    ({{
                        'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.' +
                            (bundle.digitalStreamTelevision ? 'VIA_STREAMING' : 'VIA_DECODER') +
                            '.LABEL' | translate
                    }})
                </div>
            </div>
        </div>

        <div class="u-flex-row u-margin-bottom" *ngIf="move.telecomOffer?.providerChoice">
            <span class="u-w40p u-color-muted">{{ 'MOVE.TELECOM_OFFER.PROVIDER' | translate }}</span>
            <div class="u-w60p">
                <span
                    class="u-text-line-through u-margin-right-half"
                    *ngIf="
                        move.telecomOffer.telecomOfferSelection?.provider &&
                        move.telecomOffer.providerChoice.toLowerCase() !== move.telecomOffer.telecomOfferSelection?.provider?.toLowerCase()
                    "
                    >{{ move.telecomOffer.providerChoice }}</span
                >
                <span>
                    {{
                        move.telecomOffer.providerChoice === '-'
                            ? ('TELECOM.PROVIDER.NO_PREFERENCE' | translate)
                            : move.telecomOffer.telecomOfferSelection?.provider || move.telecomOffer.providerChoice
                    }}
                </span>
            </div>
        </div>

        <div class="u-flex-row u-margin-bottom" *ngIf="!!move.telecomOffer?.fixedLine">
            <span class="u-w40p u-color-muted">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE' | translate }}</span>
            <div class="u-w60p">
                <div class="u-flex-row">
                    <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE.NEW_NUMBER' | translate }} :</span>
                    <span>{{ move?.telecomOffer?.fixedLine.newNumber?.toString() | uppercase | translate }}</span>
                </div>
                <ng-container *ngIf="!move.telecomOffer.fixedLine.newNumber">
                    <div class="u-flex-row">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE.PROVIDER' | translate }} :</span>
                        <span>{{ move.telecomOffer.fixedLine.provider | translate }}</span>
                    </div>
                    <div class="u-flex-row">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.FIXED_LINE.NUMBER' | translate }} :</span>
                        <app-copy-to-clipboard>{{ move.telecomOffer.fixedLine.number | translate }} </app-copy-to-clipboard>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="u-flex-row u-margin-bottom" *ngIf="!!move.telecomOffer?.mobileBundle?.length">
            <span class="u-w40p u-color-muted">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE' | translate }}</span>
            <div class="u-w60p">
                <div
                    *ngFor="let mobileBundle of move.telecomOffer.mobileBundle; let last = last"
                    [ngClass]="{ 'u-bordered-bottom u-margin-bottom-half u-padding-bottom-half': !last }"
                >
                    <div class="u-flex-row">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.NEW_NUMBER' | translate }} :</span>
                        <span>{{ mobileBundle.newNumber?.toString() | uppercase | translate }}</span>
                    </div>
                    <div class="u-flex-row" *ngIf="!mobileBundle.newNumber">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.PHONE_NUMBER' | translate }} :</span>
                        <app-copy-to-clipboard>{{ mobileBundle.phoneNumber }}</app-copy-to-clipboard>
                    </div>
                    <div class="u-flex-row" *ngIf="!mobileBundle.newNumber">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SIM_CARD_NUMBER' | translate }} :</span>
                        <app-copy-to-clipboard>{{ mobileBundle.simCardNumber }}</app-copy-to-clipboard>
                    </div>
                    <div class="u-flex-row" *ngIf="!mobileBundle.newNumber">
                        <span class="u-margin-right-half">{{ 'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SUBSCRIPTION_TYPE' | translate }} :</span>
                        <span>{{
                            'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SUBSCRIPTION_TYPE.' + mobileBundle.subscriptionType?.toString()
                                | uppercase
                                | translate
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="u-margin-bottom" *ngIf="!!move.telecomOffer?.telecomInstallation">
            <div class="u-flex-row">
                <span class="u-color-muted u-w40p">{{ 'MOVE.TELECOM_OFFER.INSTALLATION' | translate }}</span>
                <span [ngClass]="{ 'u-text-line-through': !!telecomSandbox.validateNewTelecomSchedule(move) }" class="u-w60p"
                    >{{ move.telecomOffer.telecomInstallation.date | formatDate }}
                    {{ move.telecomOffer.telecomInstallation.timeSlot }}</span
                >
            </div>
            <div class="u-flex-row" *ngIf="!!telecomSandbox.validateNewTelecomSchedule(move)">
                <span class="u-w40p"></span>
                <span class="u-w60p"
                    >{{ move.telecomInstallationScheduleByAdmin?.date | formatDate }}
                    {{ move.telecomInstallationScheduleByAdmin?.timeSlot }}</span
                >
            </div>
        </div>
        <ng-container *ngIf="!!move.telecomOffer?.telecomOfferSelection">
            <div class="u-flex-row u-margin-bottom">
                <span class="u-color-muted u-w40p">{{ 'MOVE.PAYMENT_TYPE' | translate }}</span>
                <span class="u-w60p">{{ move.telecomPaymentType?.toString() | uppercase | translate }}</span>
            </div>
            <div class="u-flex-row">
                <span class="u-color-muted u-w40p">{{ 'MOVE.TOTAL_PRICE' | translate }}</span>
                <span class="u-w60p">{{ move.telecomOffer.telecomOfferSelection.price | price }}</span>
            </div>
            <div class="u-flex-row" *ngIf="move.telecomOffer.telecomOfferSelection.discount">
                <span class="u-color-muted u-w40p">{{ 'MOVE.TOTAL_PRICE_INCLUSIVE_PROMOTIONS' | translate }}</span>
                <span class="u-w60p">{{ move.telecomOffer.telecomOfferSelection.discount | price }}</span>
            </div>
        </ng-container>
    </div>
</div>
