import { Injectable } from '@angular/core';
import { LoginRequest } from '@app/authentication/interfaces/login-request';
import { Move } from '@app/move/interfaces/move';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { State } from '@app/store/state';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { select, Store } from '@ngrx/store';
import { AuthUser, FeatureScopeSandbox, RealEstateAgentLoginRequest, Role } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthUtils } from '../auth.utils';
import { ResetPasswordForm } from '../components/reset-password/reset-password.constants';
import { ConfirmUserLoginRequest } from '../interfaces/confirm-user-login.request';
import { ResetPasswordResponseData } from '../interfaces/reset-password-response-data.interface';
import { TokenResponse } from '../interfaces/token-response';
import { UserLoginRequest } from '../interfaces/user-login.request';
import { AuthenticationService } from '../services/authentication.service';
import {
    LoginSuccess,
    Logout,
    RealEstateAgentLogin,
    RealEstateAgentResetPasswordAction,
    RealEstateAgentResetPasswordNewAction,
    SetAuthorizationAction,
} from '../state/authentication.actions';
import {
    getAdminIdState,
    getAuthorizationState,
    getIsRealEstateAgentOrAdmin,
    getRealEstateAgentIdState,
    getRoleState,
    getUserIdState,
} from '../state/authentication.reducer';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationSandbox {
    private resetTokenSubject = new BehaviorSubject<string>(null);
    public resetToken$ = this.resetTokenSubject.asObservable();
    public resetData: ResetPasswordResponseData;

    public role$: Observable<Role> = this.store$.pipe(select(getRoleState));
    public roleOnce$ = this.role$.pipe(take(1));

    public authorization$ = this.store$.pipe(select(getAuthorizationState));
    public authorizationOnce$ = this.authorization$.pipe(take(1));

    public userId$ = this.store$.pipe(select(getUserIdState));
    public userIdOnce$ = this.userId$.pipe(take(1));

    public isLoggedIn$: Observable<boolean> = this.store$.pipe(
        select(getRoleState),
        map((role) => !!role && role !== Role.Guest)
    );

    public isLoggedInOnce$ = this.isLoggedIn$.pipe(take(1));

    public isMover$: Observable<boolean> = this.store$.pipe(
        select(getRoleState),
        map((role) => role === Role.Mover)
    );

    public isMoverOnce$ = this.isMover$.pipe(take(1));

    public isGuest$: Observable<boolean> = this.store$.pipe(
        select(getRoleState),
        map((role) => role === Role.Guest || !role)
    );

    public isRealEstateAgent$ = this.authorization$.pipe(map(AuthUtils.isRealEstateAgent));
    public isRealEstateAgentOnce$ = this.isRealEstateAgent$.pipe(take(1));

    public isAdmin$ = this.authorization$.pipe(map((authorization) => AuthUtils.isAdmin(authorization)));
    public isAdminOnce$ = this.isAdmin$.pipe(take(1));

    public isRealEstateAgentOrAdmin$ = this.store$.pipe(select(getIsRealEstateAgentOrAdmin));
    public isRealEstateAgentOrAdminOnce$ = this.isRealEstateAgentOrAdmin$.pipe(take(1));

    public realEstateAgentId$ = this.store$.pipe(select(getRealEstateAgentIdState));

    public isImpersonatedRealEstateAgent$ = this.authorization$.pipe(map(AuthUtils.isImpersonatedRealEstateAgent));

    public isRealEstateAgentOrImpersonated$ = this.authorization$.pipe(map(AuthUtils.isRealEstateAgentOrImpersonated));

    public clientId$ = this.authorization$.pipe(map((authorization) => authorization.clientId));

    public adminId$ = this.store$.pipe(select(getAdminIdState));

    public isPartnerAgent$ = this.authorization$.pipe(map((authorization) => authorization?.role === Role.PartnerAgent));

    constructor(
        private store$: Store<State>,
        private authenticationService: AuthenticationService,
        private notificationSandbox: NotificationSandbox,
        private translationSandbox: TranslationSandbox,
        private readonly featureScopeSandbox: FeatureScopeSandbox
    ) {}

    public set resetToken(token: string) {
        this.resetTokenSubject.next(token);
    }

    public setAuthorization(authorization: AuthUser | null): void {
        this.store$.dispatch(new SetAuthorizationAction(authorization));
    }

    public authorization(): Observable<AuthUser> {
        return this.authenticationService.authorization().pipe(
            tap((auth) => {
                this.featureScopeSandbox.dispatch(auth?.scopes);
                this.translationSandbox.setLanguage(auth?.user.language);
            }),
            map((auth) => auth?.user)
        );
    }

    public login(loginRequest: LoginRequest): Observable<any> {
        return this.authenticationService.login(loginRequest).pipe(
            tap((_) =>
                this.notificationSandbox.success(NotificationLabel.AuthenticationUserLoginSuccess, {
                    email: loginRequest.email,
                })
            )
        );
    }

    public adminLoginSuccess(): void {
        this.store$.dispatch(new LoginSuccess());
    }

    public loginByAccessTokenAndId(accessToken: string, id: string): Observable<void> {
        return this.authenticationService.loginByAccessTokenAndId(accessToken, id);
    }

    public loginByLoginToken(loginToken: string): Observable<void> {
        return this.authenticationService.loginByLoginToken(loginToken);
    }

    public realEstateAgentLogin(realEstateAgentLoginRequest: RealEstateAgentLoginRequest): void {
        this.store$.dispatch(new RealEstateAgentLogin({ credentials: realEstateAgentLoginRequest }));
    }

    public userLogin(loginRequest: UserLoginRequest): Observable<any> {
        return this.authenticationService.userLogin(loginRequest);
    }

    public userResetPassword(resetForm: ResetPasswordForm): Observable<void> {
        return this.authenticationService.userResetPassword(resetForm);
    }

    public impersonateRealEstateAgent(realEstateAgentId: string): Observable<any> {
        return this.authenticationService.impersonateRealEstateAgent(realEstateAgentId);
    }

    public confirmUserLogin(confirmUserLoginRequest: ConfirmUserLoginRequest): Observable<{
        move: Move;
        token: string;
    }> {
        return this.authenticationService.confirmUserLogin(confirmUserLoginRequest);
    }

    public logout(): void {
        this.store$.dispatch(new Logout());
    }

    public realEstateAgentResetPassword(email: string): void {
        this.store$.dispatch(new RealEstateAgentResetPasswordAction({ email }));
    }

    public realEstateAgentResetPasswordNew(password: string, token: string, id: string): void {
        this.store$.dispatch(new RealEstateAgentResetPasswordNewAction({ password, token, id }));
    }

    public refreshToken(): Observable<TokenResponse> {
        return this.authenticationService.refreshToken();
    }
}
