import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-energy-zip-code-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-loading-container [loading]="uiSandbox.moveLoading$ | async">
                    <app-card class="u-flex-column u-flex-align-items-start">
                        <h3>{{ 'REAL_ESTATE_AGENT.EOTS.ZIP_CODE.TITLE' | translate }}</h3>
                        <app-zip-code [showPrevious]="false" [stepStart]="1" [stepEnd]="6" (next)="onSubmit($event)"></app-zip-code>
                    </app-card>
                </app-loading-container>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZipCodeContainer {
    constructor(private readonly router: Router, private readonly route: ActivatedRoute, public readonly uiSandbox: AppUiSandbox) {}

    public onSubmit(event): void {
        this.router
            .navigate(['../family-size'], {
                relativeTo: this.route,
            })
            .then();
    }
}
