import { Injectable } from '@angular/core';
import { RxjsService, skipWhileEmpty } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReviewsUtils } from '../../../reviews';
import { WidgetProviderService } from '../../widget-provider.service';
import { WidgetDocument, WidgetViewModel } from '../../widget.interface';

@Injectable()
export class WidgetService extends RxjsService {
    public widgetDataSubject = new BehaviorSubject<WidgetViewModel>(null);
    public externalInfluencerSubject = this.widgetProviderService.externalInfluencerSubject;
    public externalInfluencer$ = this.externalInfluencerSubject.asObservable();

    constructor(private readonly widgetProviderService: WidgetProviderService) {
        super();
    }

    public bootstrap(id: string): void {
        this.widgetProviderService.getInfluencer(id).subscribe();
        this.externalInfluencer$.pipe(skipWhileEmpty(), takeUntil(this.destroy$)).subscribe((data) => {
            this.widgetDataSubject.next(this.widgetDataFactory(data));
        });
    }

    private widgetDataFactory(data: WidgetDocument): WidgetViewModel {
        const arcScore = ReviewsUtils.mapArcScore(data.overall);
        const scoreScale = ReviewsUtils.mapScoreScale(data.score);
        return { ...data, arcScore, scoreScale };
    }
}
