import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { I18nKeyType, I18nKeyUtils } from '@smooved/core';

export const moverRoleOptions: DropdownInput<MoverRole>[] = EnumUtils.keys(MoverRole).map((key) => ({
    id: key,
    name: 'moverRole',
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, I18nKeyType.MoverRole, key),
    value: MoverRole[key],
}));

export const moverRoleDefault: MoverRole = MoverRole.Transferee;
export const moverRoleOnlyTodosDefault: MoverRole = MoverRole.Transferee;
