<ng-container [ngSwitch]="appearance">
    <button
        app-button
        *ngSwitchDefault
        class="u-w100p u-flex-justify-content-center"
        (click)="noPropagation($event)"
        [appendIcon]="true"
        [appearance]="appearance"
        [context]="context"
        [disabled]="disabled"
        [icon]="icon"
        [matMenuTriggerFor]="menu"
        [size]="size"
    >
        {{ label }}
    </button>
    <button
        app-button
        *ngSwitchCase="buttonAppearance.Icon"
        (click)="noPropagation($event)"
        [appearance]="buttonAppearance.Icon"
        [context]="context"
        [disabled]="disabled"
        [icon]="icon"
        [iconSize]="uiIconSize.Xm"
        [matMenuTriggerFor]="menu"
    >
        {{ label }}
    </button>
</ng-container>
<mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
    <ng-content select="[uiDropdownMenuItem]"></ng-content>
</mat-menu>
