import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { DbUtils, ObjectUtils, RealEstateAgentState } from '@smooved/core';
import {
    RealEstateAgentPartial,
    RealEstateAgentsGroupedByLocation,
} from '@app/real-estate-agent/interfaces/real-estate-agents-grouped-by-location';
import { GroupedSearchableDropdownInputOptions, SearchableDropdownInputOption } from '@smooved/ui';

export class RealEstateAgentUtils {
    public static isActive(realEstateAgent: Partial<RealEstateAgent>): boolean {
        return realEstateAgent?.state === RealEstateAgentState.Active;
    }

    public static isDraft(realEstateAgent: Partial<RealEstateAgent>): boolean {
        return realEstateAgent?.state === RealEstateAgentState.Draft;
    }

    public static isInactive(realEstateAgent: Partial<RealEstateAgent>): boolean {
        return realEstateAgent?.state === RealEstateAgentState.Inactive;
    }

    public static persistFilterFactory(filter: Partial<RealEstateAgentFilter>): Partial<RealEstateAgentFilter> {
        return ObjectUtils.omit(filter, 'search');
    }

    public static mapGroupedRealEstateAgentsToGroupedOptions(
        groupedAgents: RealEstateAgentsGroupedByLocation
    ): GroupedSearchableDropdownInputOptions {
        return {
            name: groupedAgents.location.name,
            groupedOptions: groupedAgents.realEstateAgents.map(RealEstateAgentUtils.mapRealEstateAgentToOption),
        };
    }

    public static mapRealEstateAgentToOption(realEstateAgent: RealEstateAgentPartial): SearchableDropdownInputOption {
        return {
            value: DbUtils.getStringId(realEstateAgent),
            id: DbUtils.getStringId(realEstateAgent),
            label:
                realEstateAgent.firstName && realEstateAgent.lastName
                    ? `${realEstateAgent.firstName} ${realEstateAgent.lastName}`
                    : realEstateAgent.email,
        };
    }
}
