import { Component, Input, OnInit } from '@angular/core';
import { reviewsTemplateConfig } from '@app/real-estate-agent/components/topic-analysis-results-reviews/topic-analysis-results-reviews.constants';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppNpsReviewsTemplateService } from '@app/reviews/components/nps-reviews-template/nps-reviews-template.service';
import { NpsReviewsDataSource } from '@app/reviews/data-sources/nps-reviews/nps-reviews.data-source';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import {
    DurationFilter,
    NpsReviewsFilterService,
    NpsReviewsTemplateService,
    NPS_REVIEWS_TEMPLATE_CONFIG,
    ReviewsDataSource,
} from '@smooved/ui';

@Component({
    selector: 'app-topic-analysis-results-reviews',
    templateUrl: './topic-analysis-results-reviews.component.html',
    providers: [
        {
            provide: NPS_REVIEWS_TEMPLATE_CONFIG,
            useValue: reviewsTemplateConfig,
        },
        {
            provide: NpsReviewsFilterService,
            useClass: NpsReviewsFilterService,
        },
        {
            provide: ReviewsDataSource,
            useClass: NpsReviewsDataSource,
        },
        { provide: NpsReviewsTemplateService, useClass: AppNpsReviewsTemplateService },
    ],
})
export class TopicAnalysisResultsReviewsComponent implements OnInit {
    @Input() public topicAnalysis: TopicAnalysis;
    @Input() public location?: string;

    constructor(
        public readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        public readonly npsReviewsTemplateService: NpsReviewsTemplateService,
        public readonly npsReviewsFilterService: NpsReviewsFilterService
    ) {}

    public ngOnInit(): void {
        if (this.location) {
            this.npsReviewsFilterService.filterByLocation(this.location, false);
        }
        this.npsReviewsFilterService.filterByDuration(DurationFilter.LatestYear, false);
        this.npsReviewsFilterService.filterBySource(null, false, reviewsTemplateConfig.limitFillUp);
        this.npsReviewsFilterService.filterByTopicCategory(this.topicAnalysis.category, false);
    }
}
