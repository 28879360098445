export enum AttachmentId {
    EnergyMeterReadingAsset = 'energy-meter-reading-asset',
    EnergyDocumentAsset = 'energy-document-asset',
    WaterMeterReadingAsset = 'water-meter-reading-asset',
    WaterDocumentAsset = 'water-document-asset',
    WaterIdCardAssets = 'water-id-card-asset',
    EnergyDocumentSummary = 'energy-document-summary',
    RentalAgreementAsset = 'rental-agreement-asset',
    RentalInspectionAsset = 'rental-inspection-asset',
}
