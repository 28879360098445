import { StringUtils } from '../utils';
import { I18nKeyTypeMap } from './i18n-key-type-map';

export class I18nKeyUtils {
    public static map(keyTypeMap: I18nKeyTypeMap, keyType: string, key: string): string {
        return `${keyTypeMap[keyType]}.${StringUtils.toUpperSnakeCase(key)}`;
    }

    public static join(...keys: string[]): string {
        return StringUtils.join(keys, '.');
    }

    public static type(keyTypeMap: I18nKeyTypeMap, keyType: string): string {
        return `${keyTypeMap[keyType]}.`;
    }
}
