<div class="u-flex u-flex-row u-flex-align-items-center" [class.u-display-none]="data.length <= 0">
    <svg #chart id="client-sources-chart" width="60" height="168"></svg>
    <div class="u-flex u-flex-column u-flex-justify-content-center u-color-gray-dark">
        <div *ngFor="let line of data" class="__legend-line u-flex-row u-font-size-14 u-margin-bottom-half">
            <div class="__graph-legend u-margin-right-half" [style.background-color]="line.color"></div>
            <span class="__graph-legend-value u-margin-right-half">{{ line.value | number : '1.0-0' }}%</span>
            <span>{{ 'INSIGHTS.DASHBOARD.CLIENT_SOURCE.' + line.label | translate }}</span>
        </div>
    </div>
</div>

<div
    *ngIf="data.length <= 0"
    class="no-data u-flex u-flex-column u-flex-align-items-center u-flex-justify-content-center u-color-gray-dark"
>
    {{ 'INSIGHTS.DASHBOARD.NO_DATA' | translate }}
</div>
