import { Injectable } from '@angular/core';
import { Gift } from '@app/gift/interfaces/gift.interface';
import { GiftService } from '@app/gift/services/gift.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GiftSandbox {
    constructor(private readonly giftService: GiftService) {}

    public get(): Observable<Gift[]> {
        return this.giftService.get();
    }
}
