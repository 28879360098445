import { Pipe, PipeTransform } from '@angular/core';
import { ArrayUtils, Asset, Mimetypes } from '@smooved/core';

@Pipe({
    name: 'assetsByMimeTypes',
})
export class AssetsByMimeTypesPipe implements PipeTransform {
    transform(assets: Asset[], mimeTypes: Mimetypes[]): Asset[] {
        if (!assets || !mimeTypes || ArrayUtils.isEmpty(mimeTypes)) return assets;
        return assets.filter((asset) => mimeTypes.includes(asset.mime));
    }
}
