import { Injectable } from '@angular/core';
import { MovesFilter } from '@app/admin/interfaces/moves-filter.interface';
import { SetFilterAndPagination } from '@app/admin/state/admin-dashboard.actions';
import { AdminDashboardState, getCount, getFilter, getMovesState } from '@app/admin/state/admin-dashboard.reducer';
import { User } from '@app/user/interfaces/user';
import { UserService } from '@app/user/services/user.service';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Admin } from '../interfaces/admin.interface';
import { AdminService } from '../services/admin.service';

@Injectable({
    providedIn: 'root',
})
export class AdminDashboardSandbox {
    public moves$ = this.store$.pipe(select(getMovesState));
    public count$ = this.store$.pipe(select(getCount));
    public filter$ = this.store$.pipe(select(getFilter));
    public filterOnce$ = this.filter$.pipe(take(1));
    public loadingSubject = new BehaviorSubject(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private store$: Store<AdminDashboardState>, private userService: UserService, private adminService: AdminService) {}

    public setFilterAndPagination(filter: MovesFilter, callback?: () => void): void {
        this.store$.dispatch(new SetFilterAndPagination({ filter, pagination: filter?.pagination, callback }));
    }

    public patch(id: string, admin: Admin): Observable<Admin> {
        return this.adminService.patch(id, admin);
    }

    public getUsersList(): Observable<User[]> {
        return this.userService.getUsersList();
    }
}
