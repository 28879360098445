// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { cacheBusterHash } from '@smooved/core';

export const environment = {
    production: false,
    environmentName: 'local',
    reCaptcha: {
        siteKey: '6Lcisf4bAAAAAElwJZZF0d4tNqsKNugTJOLTMgWW',
    },
    externalInfluencerUri: 'https://localhost:4200',
    apiUri: 'http://localhost:3001/api/v1',
    npsOgImageBaseUri: 'https://external-influencer-test.s3.eu-west-3.amazonaws.com/reviews/:filename',
    sitemapUri: 'https://smooved-public.s3.eu-west-3.amazonaws.com/external-influencer/sitemap.xml',
    smoovedUri: 'https://smooved.be',
    termsAndConditionsUrl: {
        nl: 'https://smooved.be/algemene-voorwaarden/',
        fr: 'https://smooved.be/fr/conditions-generales/',
        en: 'https://smooved.be/algemene-voorwaarden/',
    },
    privacyPolicyUrl: {
        nl: 'https://smooved.be/privacy/',
        fr: 'https://smooved.be/fr/vie-privee/',
        en: 'https://smooved.be/privacy/',
    },
    cacheBuster: cacheBusterHash,
    houseMatchCompanyPageUri: 'http://localhost:4300',
};

// Working key localhost v2 Code&Grafix - use solo for testing purposes   '6Lcj7PMbAAAAAHMUmdVGDosof-TBkxK7Kl4os4dv'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
