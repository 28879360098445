<app-todo *ngIf="move" [label]="'LEAVER_ADDED' | i18nKeyTranslate: i18nKeys.ServiceAction" [state]="move.track.leaver.created">
    <app-todo-template *todoStateUnchecked>
        <a
            app-button
            (click)="addLeaver()"
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Stroked"
            [size]="buttonSize.Sm"
            >{{ 'ADD' | translate }}</a
        >
    </app-todo-template>

    <app-todo-template *todoStateChecked>
        <span>{{ move.track.lastModifiedOn.leaverCreated | formatDate }}</span>
    </app-todo-template>
</app-todo>
