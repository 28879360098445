import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-insurances-covered',
    template: `
        <p *ngIf="!!move?.insurancesStates?.coveredFrom && !!move?.insurancesStates?.confirmed?.value; else notCovered">
            {{ 'INSURANCES.STATUS.COVERED_FROM' | translate: { date: move?.insurancesStates?.coveredFrom | formatDate } }}
        </p>
        <ng-template #notCovered>
            <p class="u-color-muted">{{ 'INSURANCES.STATUS.NOT_COVERED_FROM' | translate }}</p>
        </ng-template>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancesCoveredComponent {
    @Input() public move: Move;
}
