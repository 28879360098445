import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/real-estate-agent/services/analytics.service';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { AnalyticsEventsEnum, ArrayUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { TooltipTrigger } from '@smooved/ui';

@Component({
    selector: 'app-topic-analysis-results',
    templateUrl: './topic-analysis-results.component.html',
    styleUrls: ['./topic-analysis-results.component.scss'],
})
export class TopicAnalysisResultsComponent extends RxjsComponent implements AfterViewInit, OnChanges {
    @Input() public topicAnalysisResults: TopicAnalysis[] = [];
    @Input() public location: string;
    public readonly appI18nKeyType = AppI18nKeyType;
    public readonly tooltipTrigger = TooltipTrigger;

    public maxValue = 0;
    public collapsibleId: string;

    constructor(
        private readonly analyticsService: AnalyticsService,
        private readonly activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private readonly document
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.collapsibleId = this.activatedRoute.snapshot.queryParams['topic'];
        this.document.getElementById(this.collapsibleId)?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    public ngOnChanges({ topicAnalysisResults }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(topicAnalysisResults) && !ArrayUtils.isEmpty(this.topicAnalysisResults)) {
            this.setMaxValue();
        }
    }

    public categoryOpened(topicAnalysis: TopicAnalysis): void {
        this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewsInsightsAnalysisOpened, {
            category: topicAnalysis.category,
        });
    }

    public categoryClosed(topicAnalysis: TopicAnalysis): void {
        this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewsInsightsAnalysisClosed, {
            category: topicAnalysis.category,
        });
    }

    private calculateMaxValue(): number {
        const firstResult = this.topicAnalysisResults[0];
        if (!firstResult) return 0;
        return this.topicAnalysisResults.reduce(
            (max, result) => Math.max(result.sentimentPositive, result.sentimentNegative, max),
            Math.max(firstResult.sentimentPositive, firstResult.sentimentNegative) // Initialize max with the first element's sentiment values
        );
    }

    private setMaxValue(): void {
        const newMaxValue = this.calculateMaxValue();
        if (newMaxValue !== this.maxValue) {
            this.maxValue = newMaxValue;
        }
    }
}
