import { Pipe, PipeTransform } from '@angular/core';
import { ProductCondition, ProductConditionAction } from '@app/energy/enums/product-condition';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { ArrayUtils } from '@smooved/core';

@Pipe({ name: 'productCondition' })
export class ProductConditionPipe implements PipeTransform {
    public transform(
        value: EnergySuggestion[][],
        condition: ProductCondition,
        action = ProductConditionAction.Include
    ): EnergySuggestion[][] {
        if (ArrayUtils.isEmpty(value)) return value;
        return value
            .map((v) =>
                v.filter((suggestion) =>
                    action === ProductConditionAction.Exclude
                        ? suggestion.productCondition !== condition
                        : suggestion.productCondition === condition
                )
            )
            .filter((v) => !ArrayUtils.isEmpty(v));
    }
}
