import { AfterContentInit, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TelecomBundle } from '@app/wizard/telecom/telecom.interfaces';
import { ObjectUtils, RxjsComponent } from '@smooved/core';
import { CheckInput } from '@smooved/ui';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import {
    defaultLabel,
    digitalStreamTelevisionOptions,
    TelecomBundleForm,
    telecomBundleOptions,
} from './telecom-bundle-selection.constants';

@Component({
    selector: 'app-telecom-bundle-selection',
    templateUrl: 'telecom-bundle-selection.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TelecomBundleSelectionComponent),
            multi: true,
        },
    ],
})
export class TelecomBundleSelectionComponent extends RxjsComponent implements ControlValueAccessor, AfterContentInit {
    @Input() public label = defaultLabel;

    public telecomBundle = TelecomBundle;
    public telecomBundleForm = TelecomBundleForm;
    public telecomBundleOptions = telecomBundleOptions;
    public digitalStreamTelevisionOptions = digitalStreamTelevisionOptions;
    public form = this.formBuilder.group({
        [TelecomBundleForm.TelecomBundle]: this.formBuilder.group({
            [TelecomBundle.Internet]: { value: true, disabled: true },
            [TelecomBundle.DigitalTelevision]: false,
            [TelecomBundle.MobilePhone]: false,
            [TelecomBundle.FixedLine]: false,
        }),
        [TelecomBundleForm.DigitalStreamTelevision]: null,
    });

    public showDigitalTV$: Observable<boolean>;

    public get telecomBundleField(): UntypedFormGroup {
        return this.form.get(TelecomBundleForm.TelecomBundle) as UntypedFormGroup;
    }

    constructor(private readonly formBuilder: UntypedFormBuilder) {
        super();
    }

    public ngAfterContentInit(): void {
        this.showDigitalTV$ = this.form.get(TelecomBundleForm.TelecomBundle).valueChanges.pipe(
            takeUntil(this.destroy$),
            startWith(this.form.get(TelecomBundleForm.TelecomBundle).value),
            map((value) => !!value[TelecomBundle.DigitalTelevision] && !!value[TelecomBundle.MobilePhone]),
            distinctUntilChanged(ObjectUtils.isEqual),
            tap(this.resetDigitalTvValue)
        );
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.propagateChange(this.form.getRawValue()));
    }

    public trackByOptionId(_index: number, option: CheckInput<boolean>): string {
        return option.id;
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    public writeValue(bundle: any): void {
        this.form.patchValue(bundle || {});
    }

    public propagateChange = (_: any) => {};

    public propagateTouched = (_: any) => {};

    private resetDigitalTvValue = (toShow: boolean): void => {
        if (toShow) {
            this.form.get(TelecomBundleForm.DigitalStreamTelevision).patchValue(false, { emitEvent: false });
        } else {
            this.form.get(TelecomBundleForm.DigitalStreamTelevision).reset({}, { emitEvent: false });
        }
    };
}
