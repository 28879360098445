import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Contacts } from '@app/email/interfaces/contacts';
import { Move } from '@app/move/interfaces/move';
import { BaseInput } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { ContactFields, ContactOptions, ContactsConfig, createContactOptions } from './contacts.constants';

@Component({
    selector: 'app-contacts',
    templateUrl: 'contacts.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactsComponent),
            multi: true,
        },
    ],
})
export class ContactsComponent extends BaseInput implements ControlValueAccessor, OnInit {
    @Input() public move: Move;

    public readonly contactFields = ContactFields;

    public options: ContactOptions;
    public form = this.fb.group({
        [ContactFields.Mover]: null,
        [ContactFields.LinkedMove]: null,
    });

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, private readonly fb: UntypedFormBuilder) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (!this.move) return;
        const { user } = this.move;
        const config: ContactsConfig = {
            role: user.role,
            contactMover: user.phoneNumber,
            contactLinkedMove: (this.move.linkedMove as Move)?.user.phoneNumber,
        };
        this.options = createContactOptions(config);
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.propagateChange(value));
    }

    public writeValue(contacts: Contacts): void {
        this.form.patchValue({ ...contacts });
    }
}
