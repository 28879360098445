<app-card-container>
    <div class="__container">
        <app-card [bordered]="false" class="u-h100p u-margin-bottom">
            <app-analytics-move-count-table class="u-display-block"></app-analytics-move-count-table>
        </app-card>
        <app-card [bordered]="false" class="u-h100p">
            <app-analytics-moves-count-by-created-flow-chart class="u-display-block"></app-analytics-moves-count-by-created-flow-chart>
            <app-moving-date-vs-created-on-gap class="u-display-block u-margin-top-double"></app-moving-date-vs-created-on-gap>
        </app-card>
    </div>
</app-card-container>
