import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-header-dashboard-extra',
    template: `
        <div *ngIf="showText" class="__container">
            <p class="u-font-size-small">
                {{ 'HEADER.TEXT' | translate }}
            </p>
        </div>
    `,
    styleUrls: ['./header-dashboard-extra.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDashboardExtraComponent {
    @Input() public showText: boolean;
}
