<app-info-modal>
    <ng-container modal-header>
        <h6>{{ file.name }}</h6>
        <p class="u-color-muted u-font-size-small">{{ file.createdOn | formatDate: dateTimeFormat }}</p>
    </ng-container>

    <div class="__container u-flex-row u-flex-align-items-center u-flex-justify-content-center">
        <app-thumbnail [isPreview]="isPreview" (detail)="download()" [file]="file"></app-thumbnail>
    </div>
    <p *ngIf="isPreview" class="u-color-muted u-font-size-small u-margin-top-half">{{ 'ENERGY.INPUT.FILE_PREVIEW.NOTE' | translate }}</p>
</app-info-modal>
