import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomMessageFilterComponent } from '@app/admin/components/custom-message-filter/custom-message-filter.component';
import { CustomMessageFilter, messageTypeDefault } from '@app/admin/components/custom-message-filter/custom-message-filter.constants';
import { MailFormComponent } from '@app/admin/components/mail-form/mail-form.component';
import { CustomMailForm } from '@app/admin/components/mail-form/mail-form.constants';
import { SmsCustomFormComponent } from '@app/admin/components/sms-custom-form/sms-custom-form.component';
import { SmsCustomForm } from '@app/admin/components/sms-custom-form/sms-custom-form.constants';
import { MailFields } from '@app/admin/enums/mail-fields.enum';
import { MailData, MailFormAlert } from '@app/admin/interfaces/mail-data';
import { CustomMessageTemplateReceiversConfig } from '@app/communication/interfaces/custom-message-template';
import { CommunicationSandbox } from '@app/communication/sandboxes/communication.sandbox';
import { DocumentCenterUtils } from '@app/document-center/utils/document-center.utils';
import { AddresseesPreData } from '@app/email/interfaces/addressees-pre-data';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { EscalationService } from '@app/escalation/services/escalation.service';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { CreateContactLogAddresseesRequest } from '@app/move/interfaces/create-contact-log-addressees-request';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import {
    DateUtils,
    DbUtils,
    LeaverMeterCollectionScope,
    MoveTransactionType,
    REA_METER_ALERT_CONTENT,
    REA_METER_ALERT_DAYS,
    REA_METER_ALERT_TITLE,
    RxjsComponent,
} from '@smooved/core';
import { NotificationSandbox, TwoPanelModalComponent, TwoPanelType } from '@smooved/ui';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-custom-message-modal',
    templateUrl: 'custom-message.modal.html',
    styleUrls: ['custom-message.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomMessageModal extends RxjsComponent implements OnInit {
    @Input() move: Move;
    @Input() escalation: Escalation;

    @ViewChild(TwoPanelModalComponent, { static: true }) public readonly modal: TwoPanelModalComponent;
    @ViewChild(CustomMessageFilterComponent, { static: true }) public readonly filter: CustomMessageFilterComponent;
    @ViewChild(MailFormComponent) public readonly mailForm: MailFormComponent;
    @ViewChild(SmsCustomFormComponent) public readonly smsCustomForm: SmsCustomFormComponent;

    public loading = false;
    public content: MailData;
    public messageType = messageTypeDefault;
    public smsControl = new UntypedFormControl(null, Validators.required);
    public mailFormREAMeterAlert: MailFormAlert;

    public readonly twoPanelType = TwoPanelType;
    public readonly i18nKeyType = appI18nKeyTypes;
    public readonly contactLogType = ContactLogType;

    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly communicationSandbox: CommunicationSandbox,
        private readonly escalationService: EscalationService,
        private readonly translate: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.content = {
            move: this.move,
            preData: {
                escalation: this.escalation,
            },
        };
        this.mailFormREAMeterAlert = this.checkREAMeterAlert();
        this.communicationSandbox.loading$
            .pipe(
                takeUntil(this.destroy$),
                tap((loading) => (this.loading = loading))
            )
            .subscribe();
    }

    public onFilterChange({ template, language, type }: CustomMessageFilter): void {
        const { subject, body, receivers: receiversConfig, attachments } = template || {};
        this.messageType = type;
        this.content = {
            ...this.content,
            preData: {
                ...this.content.preData,
                subject: subject?.[language],
                content: body?.[language],
                to: this.setPreSelected(receiversConfig?.to, this.move),
                cc: this.setPreSelected(receiversConfig?.cc, this.move),
                bcc: this.setPreSelected(receiversConfig?.bcc, this.move),
            },
            receiversConfig,
            assets: attachments?.reduce(
                (list, attach) => [...list, ...DocumentCenterUtils.getMoveAssetsByAttachmentId(attach, this.move)],
                []
            ),
        };
    }

    public onMailSubmit(): void {
        if (!this.mailForm?.form.valid) return;
        const { content, subject, to, cc, bcc, escalation: escalationId, state } = <CustomMailForm>this.mailForm.form.getRawValue();
        const { language } = this.filter.configForm.value;

        // quill editor changes div tags to p tags. We want to use div tags in the email.
        const payload: CreateContactLogAddresseesRequest = {
            content: content.replace('<p>', '<div>').replace('</p>', '</div>'),
            subject,
            value: ContactLogType.EmailCustomMover,
            to,
            cc,
            bcc,
            language,
            escalationId,
        };

        if (this.mailForm.assets?.length) {
            payload.attachments = this.mailForm.assets.map(({ id: attachmentId, name }) => ({ attachmentId, name }));
        }

        if (this.mailForm.files?.length) {
            payload.assets = [...this.mailForm.files];
        }

        this.submitRequests(payload, this.mailForm.form.get(MailFields.state).dirty ? state : null);
    }

    public onSmsSubmit(): void {
        this.smsControl.markAsTouched();
        this.smsControl.markAsDirty();
        if (!this.smsControl?.valid) return;
        const { content, to, escalation: escalationId, state } = <SmsCustomForm>this.smsControl.value;
        const payload: CreateContactLogAddresseesRequest = {
            value: ContactLogType.SmsCustomMover,
            to,
            content,
            escalationId,
        };

        this.submitRequests(payload, this.smsCustomForm?.form.get(MailFields.state).dirty ? state : null);
    }

    private submitRequests(payload: CreateContactLogAddresseesRequest, state: EscalationState): void {
        this.loading = true;
        this.moveSandbox
            .createContactLogAddressees(DbUtils.getStringId(this.move), payload)
            .pipe(
                switchMap(() => this.updateEscalationState(payload.escalationId, state)),
                finalize(() => (this.loading = false))
            )
            .subscribe((response) => {
                this.notificationSandbox.sendSuccess();
                this.closeDialog(response);
            });
    }

    private setPreSelected(config: CustomMessageTemplateReceiversConfig, move: Move): AddresseesPreData {
        return {
            mover: config?.mover?.selected,
            linkedMove: MoveUtils.isTransferee(move) ? !!move.linkedMove && config?.leaver?.selected : config?.transferee?.selected,
            realEstateAgent: config?.realEstateAgent?.selected,
            supplierChecked: config?.supplier?.selected,
            otherChecked: config?.other?.selected,
        };
    }

    private closeDialog(result?: Move | boolean): void {
        this.modal.close(result);
    }

    private calculateREAMeterAlertDate(date: Date): Date {
        return DateUtils.addDaysToDate(REA_METER_ALERT_DAYS, date);
    }

    private checkREAMeterAlert(): MailFormAlert {
        const leaverMeterCollectionScope = this.move.realEstateGroup.featureScopesConfig.leaverMeterCollectionScope;
        const moveTransactionType = MoveUtils.getMoveTransactionType(this.move);

        if (
            this.leaverMeterCollectionAlignsWithTransactionType(leaverMeterCollectionScope, moveTransactionType) &&
            new Date() <= this.calculateREAMeterAlertDate(this.move.movingDate)
        ) {
            return {
                title: this.translate.instant(REA_METER_ALERT_TITLE),
                content: this.translate.instant(REA_METER_ALERT_CONTENT, {
                    date: DateUtils.format(this.calculateREAMeterAlertDate(this.move.movingDate)),
                }),
            };
        }
    }

    private leaverMeterCollectionAlignsWithTransactionType(
        leaverMeterCollectionScope: LeaverMeterCollectionScope,
        moveTransactionType: MoveTransactionType
    ): boolean {
        return (
            leaverMeterCollectionScope === LeaverMeterCollectionScope.All ||
            (leaverMeterCollectionScope === LeaverMeterCollectionScope.OnlySales && moveTransactionType === MoveTransactionType.Sale) ||
            (leaverMeterCollectionScope === LeaverMeterCollectionScope.OnlyRental && moveTransactionType === MoveTransactionType.Rental)
        );
    }

    private updateEscalationState(escalationId?: string, state?: EscalationState): Observable<boolean> {
        if (!state || !escalationId) return of(true);
        return this.escalationService.updateEscalation(escalationId, { state }).pipe(
            map(() => {
                return true;
            })
        );
    }
}
