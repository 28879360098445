import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { emptyValue, RealEstateGroupMeta } from '@smooved/core';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiDirection } from '../../../ui.enums';
import { CompanyPageI18nKeyType } from '../../constants';

@Component({
    selector: 'ui-real-estate-group-kpi',
    templateUrl: './real-estate-group-kpi.component.html',
    styleUrls: ['./real-estate-group-kpi.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateGroupKpiComponent {
    @Input() public meta: RealEstateGroupMeta;
    @Input() public direction: UiDirection = UiDirection.Horizontal;
    @Input() public cols = 0;

    public readonly uiDirection = UiDirection;
    public readonly i18nKeyType = CompanyPageI18nKeyType;
    public readonly fallbackValue = emptyValue;
    public readonly iconSizes = UiIconSize;
    public readonly iconAppearance = UiIconAppearance;
}
