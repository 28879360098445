import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as authenticationRouting from '@app/authentication/constants/routing.constants';
import { RealEstateAgentLoginRequest } from '../../../../../projects/core/src/lib/authentication/interfaces/real-estate-agent-login.request';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';

@Component({
    selector: 'app-real-estate-agent-login',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-center">
            <h3 class="u-margin-bottom-double">{{ 'AUTHENTICATION.REAL_ESTATE_AGENT.LOGIN.TITLE' | translate }}</h3>

            <app-email-input formControlName="email" [label]="'FORM.EMAIL.LABEL' | translate"></app-email-input>

            <app-password-input
                id="password"
                [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.PASSWORD.LABEL' | translate"
                [placeholder]="'AUTHENTICATION.REAL_ESTATE_AGENT.PASSWORD.PLACEHOLDER' | translate"
                formControlName="password"
            ></app-password-input>

            <a [routerLink]="resetPasswordRouting" class="u-link u-margin-bottom-double u-font-size-14 u-text-align-center">{{
                'AUTHENTICATION.FORGOT_PASSWORD.TITLE' | translate
            }}</a>

            <div class="u-flex-column u-flex-align-items-center">
                <button app-button class="u-margin-bottom __submit u-flex-justify-content-center" type="submit" [disabled]="form.invalid">
                    {{ 'AUTHENTICATION.LOGIN' | translate }}
                </button>
                <a (click)="goToRegister.emit()" class="u-link">{{ 'AUTHENTICATION.REGISTER' | translate }}</a>
            </div>
        </form>
    `,
    styles: [
        `
                    .__submit {
                        min-width: 15rem;
                    }
                `,
    ],
})
export class RealEstateAgentLoginComponent {
    @Output() public formSubmit: EventEmitter<RealEstateAgentLoginRequest> = new EventEmitter();
    @Output() public goToRegister: EventEmitter<void> = new EventEmitter();
    public resetPasswordRouting = AppNavigationSandbox.getAbsolute(authenticationRouting.realEstateAgentResetPasswordRouting);

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required]],
    });

    constructor(private formBuilder: UntypedFormBuilder) {}

    public onSubmit(): void {
        if (this.form.valid) {
            this.formSubmit.emit({
                email: this.form.value.email,
                password: this.form.value.password,
            });
        }
    }
}
