<h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.USER.MOVING_DATE' | translate }}</h6>
<span *ngIf="!!move?.movingDate; else unknown">{{ move?.movingDate | formatDate }}</span>

<ng-template #unknown>
    <span class="u-color-muted u-opacity-50">{{ 'UNKNOWN' | translate }}</span>
</ng-template>

<app-late-moving-date-red-notification
    [createdOn]="move?.createdOn"
    [movingDate]="move?.movingDate"
    [context]="uiContext.Text"
></app-late-moving-date-red-notification>
