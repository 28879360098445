import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ExternalInfluencer, houseMatch404, State } from '@smooved/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UiSandbox } from '../../ui.sandbox';
import { ExternalInfluencerService } from '../services';

@Injectable()
export class CompanyPageResolver {
    constructor(
        private readonly externalInfluencerService: ExternalInfluencerService,
        private readonly uiSandbox: UiSandbox
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<ExternalInfluencer> {
        const { params } = route;
        const { company } = params;
        return this.getPublished(company);
    }

    protected getPublished(company: string, altState?: State): Observable<ExternalInfluencer> {
        return this.externalInfluencerService.get({ url: company, state: State.Published, altState }).pipe(
            tap((response: ExternalInfluencer) => {
                this.externalInfluencerService.externalInfluencerSubject.next(response);
            }),
            catchError((error: any) => {
                if (this.uiSandbox.isBrowser()) {
                    window.location.href = houseMatch404;
                }
                return of(null);
            })
        );
    }
}
