<ng-container *ngIf="agent">
    <div>{{ agent.firstName }} {{ agent.lastName }}</div>
    <div>
        <a class="u-link" *ngIf="agent.phoneNumber" href="tel:{{ agent.phoneNumber }}">
            {{ agent.phoneNumber }}
        </a>
    </div>
    <div class="u-margin-bottom">{{ group.name }}</div>
    <img *ngIf="!hideLogo" [src]="group.logo" width="200" />
</ng-container>
