import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-contract-container-component',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card [paddingDouble]="false" [padding]="false">
                    <div class="__container">
                        <main class="__main u-flex-1 u-mw100p u-padding-double">
                            <router-outlet></router-outlet>
                        </main>
                        <aside class="__aside u-flex-no-shrink u-padding-double">
                            <ng-content></ng-content>
                        </aside>
                    </div>
                </app-card>
            </div>
        </app-card-container>
    `,
    styleUrls: ['./contract-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractContainerComponent {
    @Input() public energySuggestionsRoute: string;
}
