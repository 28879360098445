import { ComponentType } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Input,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectUtils } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { UiIcon, UiIconSize } from '../../../icon';
import { UiContext } from '../../../ui.enums';
import { BaseModalComponent } from '../base.modal';

@Component({
    selector: 'app-closable-modal',
    templateUrl: './closable-modal.component.html',
})
export class ClosableModalComponent extends BaseModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('ref', { read: ViewContainerRef }) public vcRef: ViewContainerRef;

    @Input() public maxWidth: string | number;
    @Input() public width: string | number;
    @Input() public hideCloseButton = false;

    constructor(
        private resolver: ComponentFactoryResolver,
        protected dialogRef: MatDialogRef<any>,
        protected cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(cdr, dialogRef, data);
    }

    public component: ComponentType<any>;
    public header: string;
    public headerClass: string;
    public subheader: string;

    public input: object = {};
    public output: object = {};

    public ref: ComponentRef<any>;

    public delete?: (modalRef: MatDialogRef<any>) => void;

    public readonly uiIcon = UiIcon;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;

    public ngAfterViewInit(): void {
        const comp = this.resolver.resolveComponentFactory(this.component);
        this.ref = this.vcRef.createComponent(comp);

        if (ObjectUtils.isObject(this.ref.instance)) {
            Object.assign(this.ref.instance as object, { ...this.input, ...this.output });
        }

        this.cdr.detectChanges();
    }

    public onDelete(): void {
        this.delete(this.dialogRef);
    }

    public ngOnDestroy(): void {
        this.ref.destroy();
    }
}
