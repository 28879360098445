import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';

@Component({
    selector: 'app-agent-form-modal',
    template: `
        <app-closable-modal-template maxWidth="none" class="__modal-md">
            <ng-container modal-header>
                <h5>
                    {{ (data?.invite ? 'REAL_ESTATE_AGENT.OFFICE.INVITE' : 'REAL_ESTATE_AGENT.OFFICE.ADD_AGENT') | translate }}
                </h5>
            </ng-container>
            <app-agent-form
                [realEstateGroup]="data.realEstateGroup"
                [submitLabel]="(data?.invite ? 'REAL_ESTATE_AGENT.OFFICE.INVITE' : 'ADD') | translate"
            ></app-agent-form>
        </app-closable-modal-template>
    `,
})
export class AgentFormModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { realEstateGroup: RealEstateGroup; invite: boolean }) {}
}
