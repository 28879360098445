import { Component, OnInit } from '@angular/core';
import { RedirectSandbox } from '@app/redirect/sandbox/redirect.sandbox';

@Component({
    selector: 'app-redirect',
    template: ``,
})
export class RedirectComponent implements OnInit {
    constructor(private readonly redirectSandbox: RedirectSandbox) {}

    public ngOnInit(): void {
        this.redirectSandbox.toDashboard().subscribe();
    }
}
