import { HomeDescriptionSize } from '@app/shared/state/shared.reducer';
import { HomeDescription } from '@smooved/core';
import {
    calculateHomeDescriptionSizeApartmentBucketFn,
    calculateHomeDescriptionSizeClosedBucketFn,
    calculateHomeDescriptionSizeDetachedBucketFn,
} from './home-description.constants';

export class HomeDescriptionUtils {
    public static calculateHomeDescriptionSizeBucket(value: number, homeDescription: HomeDescription): HomeDescriptionSize {
        switch (homeDescription) {
            case HomeDescription.Apartment: {
                return calculateHomeDescriptionSizeApartmentBucketFn(value);
            }
            case HomeDescription.Closed:
            case HomeDescription.HalfOpen: {
                return calculateHomeDescriptionSizeClosedBucketFn(value);
            }
            case HomeDescription.Detached: {
                return calculateHomeDescriptionSizeDetachedBucketFn(value);
            }
            default:
                return null;
        }
    }
}
