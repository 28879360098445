import { CheckInput } from '@smooved/ui';

export const formControlNames = {
    reviewsNotifications: 'reviewsNotifications',
};

export const publishedOption: CheckInput<boolean> = {
    id: 'published',
    labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.FORM.PUBLISHED.LABEL',
    value: true,
    name: 'published',
};
