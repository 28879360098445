import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DateUtils } from '@smooved/core';
import { MessageBoardService } from '@smooved/ui';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { defaultVersionCheckString, hashCheckDefaultInterval, hashDefaultString } from './app.constants';
import { VersionCheckMessageComponent } from './shared/components/version-check-message/version-check-message.component';

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private readonly currentHash = hashDefaultString;
    private toValidate = true;

    constructor(private readonly httpClient: HttpClient, private readonly messageBoardService: MessageBoardService) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 15 minutes
     */
    public initVersionCheck(frequency = hashCheckDefaultInterval): void {
        interval(frequency)
            .pipe(takeWhile(() => this.toValidate))
            .subscribe(() => this.checkVersion());
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(): void {
        // timestamp these requests to invalidate caches
        this.httpClient.get(`${environment.appUri}/version.json?t=${DateUtils.now().getTime()}`).subscribe(
            ({ hash }: { hash: string; version: string }) => {
                const hashChanged = this.hasHashChanged(this.currentHash, hash);

                // If new version, do something
                if (hashChanged) {
                    this.messageBoardService.show({
                        component: VersionCheckMessageComponent,
                        override: { compact: true, toastClass: 'u-background-brand-3-light' },
                    });
                    this.toValidate = false;
                }
            },
            (err) => {
                console.error(err, 'Could not get version');
                this.toValidate = false;
            }
        );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash): boolean {
        if (!currentHash || currentHash === defaultVersionCheckString) {
            this.toValidate = false;
            return false;
        }

        return currentHash !== newHash;
    }
}
