export enum LeadPassingForm {
    MovingDate = 'movingDate',
    MovingAddress = 'movingAddress',
    FirstNameTransferee = 'firstNameTransferee',
    LastNameTransferee = 'lastNameTransferee',
    Gender = 'gender',
    EmailTransferee = 'emailTransferee',
    PhoneNumberTransferee = 'phoneNumberTransferee',
    LanguageTransferee = 'languageTransferee',
    Professional = 'professional',
    CompanyName = 'companyName',
    VatNumber = 'vatNumber',
    FirstNameLeaver = 'firstNameLeaver',
    LastNameLeaver = 'lastNameLeaver',
    EmailLeaver = 'emailLeaver',
    PhoneNumberLeaver = 'phoneNumberLeaver',
    LanguageLeaver = 'languageLeaver',
    LeaverType = 'leaverType',
    RealEstateAgentConsent = 'realEstateAgentConsent',
    MeterReadingGas = 'meterReadingGas',
    SmoovedTermsAndConditions = 'smoovedTermsAndConditions',
    MeterReadingElectricity = 'meterReadingElectricity',
    TransfereeType = 'transfereeType',
    EnergyOption = 'energyOption',
    EnergySuggestion = 'energySuggestion',
    TransfereeRentalType = 'transfereeRentalType',
    AdditionalNotes = 'additionalNotes',
    HomeDescription = 'homeDescription',
    HomeDescriptionSizeInput = 'homeDescriptionSizeInput',
    HomeDescriptionApartmentLevel = 'homeDescriptionApartmentLevel',
    EnergyType = 'energyType',
    FixedTariff = 'fixedTariff',
    Vacancy = 'vacancy',
    HasSolarPanels = 'hasSolarPanels',
    ServiceInsurances = 'serviceInsurances',
    ServiceTelecom = 'serviceTelecom',
    AddLeaver = 'addLeaver',
    TelecomBundle = 'telecomBundle',
    TelecomProvider = 'telecomProvider',
    DateOfBirth = 'dateOfBirth',
    LeaverFinalInvoice = 'leaverFinalInvoice',
    LeaverElectricityInvoice = 'leaverElectricityInvoice',
    LeaverSuppliers = 'leaverSuppliers',
    LeaverGasInvoice = 'leaverGasInvoice',
    LeaverContact = 'leaverContact',
    LeaverContactAddress = 'leaverContactAddress',
}

export enum LeadPassingEnergyType {
    Both = 'both',
    Electricity = 'electricity',
    Gas = 'gas',
}
