import { Injectable } from '@angular/core';
import { RealEstateAgentInviteRequest } from '@app/authentication/interfaces/real-estate-agent-invite.request';
import { RealEstateAgentRegisterRequest } from '@app/authentication/interfaces/real-estate-agent-register.request';
import { TokenResponse } from '@app/authentication/interfaces/token-response';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentRegisterData } from '@app/real-estate-agent/interfaces/real-estate-agent-register-data';
import { select, Store } from '@ngrx/store';
import { NumberUtils, ObjectUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmLoginRequest } from '../interfaces/confirm-login.request';
import { RealEstateAgent } from '../interfaces/real-estate-agent';
import { RealEstateAgentService } from '../services/real-estate-agent.service';
import { getAll, getDetails, patch, resetBadgeCountsUnreadReviews, setFilter, setInviteData } from '../state/real-estate-agents.actions';
import {
    getRealEstateAgentBadgeCounts,
    getRealEstateAgentDetails,
    getRealEstateAgentFilterState,
    getRealEstateAgentRegisterData,
    getRealEstateAgentsState,
    RealEstateAgentState,
} from '../state/real-estate-agents.reducer';

@Injectable({
    providedIn: 'root',
})
export class RealEstateAgentSandbox {
    public realEstateAgent$ = this.store$.pipe(select(getRealEstateAgentDetails));
    public realEstateAgents$ = this.store$.pipe(select(getRealEstateAgentsState));

    public realEstateAgentFilter$ = this.store$.pipe(select(getRealEstateAgentFilterState));

    public realEstateAgentRegisterData$ = this.store$.pipe(select(getRealEstateAgentRegisterData));
    public realEstateAgentRegisterDataOnce$ = this.realEstateAgentRegisterData$.pipe(take(1));
    public unreadReviewCount$ = this.store$.pipe(
        select(getRealEstateAgentBadgeCounts),
        map((badgeCounts) => badgeCounts.unreadReviews)
    );

    public unreadReviewBadge$ = this.unreadReviewCount$.pipe(
        map((count) => (NumberUtils.isNumber(count) && count ? count.toString() : null))
    );

    constructor(private realEstateAgentService: RealEstateAgentService, private store$: Store<RealEstateAgentState>) {}

    public getByConfirmationToken(confirmationToken: string): Observable<RealEstateAgent> {
        return this.realEstateAgentService.getByConfirmationToken(confirmationToken);
    }

    public confirmInvite(
        confirmationToken: string,
        realEstateAgentRegisterRequest: RealEstateAgentRegisterRequest
    ): Observable<TokenResponse> {
        return this.realEstateAgentService.confirmInvite(confirmationToken, realEstateAgentRegisterRequest);
    }

    public getAll(): void {
        this.store$.dispatch(getAll());
    }

    public get(id: string): void {
        this.store$.dispatch(getDetails({ id }));
    }

    public patch(realEstateAgentId: string, realEstateAgent: RealEstateAgent, callback?: () => void): void {
        this.store$.dispatch(patch({ realEstateAgentId, realEstateAgent, callback }));
    }

    public register(realEstateAgentRegisterRequest: RealEstateAgentRegisterRequest): Observable<void> {
        return this.realEstateAgentService.register(realEstateAgentRegisterRequest);
    }

    public confirm(confirmLoginRequest: ConfirmLoginRequest): Observable<RealEstateAgent> {
        return this.realEstateAgentService.confirmLogin(confirmLoginRequest);
    }

    public invite(realEstateAgentInvite: RealEstateAgentInviteRequest): Observable<void> {
        ObjectUtils.removeEmpty(realEstateAgentInvite);
        return this.realEstateAgentService.invite(realEstateAgentInvite);
    }

    public setFilter(filter: RealEstateAgentFilter, persist: boolean = false, callback?: () => void): void {
        this.store$.dispatch(setFilter({ filter, persist, callback }));
    }

    public getFilter(): Observable<RealEstateAgentFilter> {
        return this.realEstateAgentService.getFilter();
    }

    public setInviteData(inviteData: RealEstateAgentRegisterData): void {
        return this.store$.dispatch(setInviteData({ inviteData }));
    }

    public getLeads(filter: RealEstateAgentFilter): Observable<RealEstateAgent[]> {
        return this.realEstateAgentService.getLeads(filter);
    }

    public sendRegisterEmail(id: string): Observable<void> {
        return this.realEstateAgentService.sendRegisterEmail(id);
    }

    public resetUnreadReviewsCount(): void {
        this.store$.dispatch(resetBadgeCountsUnreadReviews());
    }
}
