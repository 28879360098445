<section class="u-margin-bottom-double">
    <h6 class="u-margin-bottom-half">{{ 'TO' | translate }}</h6>
    <ng-container *ngIf="contactLog.to?.length || contactLog.toEmail || contactLog.toPhoneNumber; else empty">
        <p *ngIf="contactLog.toEmail" class="u-margin-none">
            <app-copy-to-clipboard>{{ contactLog.toEmail }}</app-copy-to-clipboard>
        </p>
        <p *ngIf="contactLog.toPhoneNumber" class="u-margin-none">
            <app-copy-to-clipboard>{{ contactLog.toPhoneNumber }}</app-copy-to-clipboard>
        </p>
        <p class="u-margin-none" *ngFor="let to of contactLog.to">
            <app-copy-to-clipboard>{{ to }}</app-copy-to-clipboard>
        </p>
    </ng-container>
</section>

<section class="u-margin-bottom-double" *ngIf="!isSms">
    <h6 class="u-margin-bottom-half">{{ 'CC' | translate }}</h6>
    <ng-container *ngIf="contactLog.cc?.length; else empty">
        <p class="u-margin-none" *ngFor="let cc of contactLog.cc">
            <app-copy-to-clipboard>{{ cc }}</app-copy-to-clipboard>
        </p>
    </ng-container>
</section>

<section class="u-margin-bottom-double" *ngIf="!isSms">
    <h6 class="u-margin-bottom-half">{{ 'BCC' | translate }}</h6>
    <ng-container *ngIf="contactLog.bcc?.length; else empty">
        <p class="u-margin-none" *ngFor="let bcc of contactLog.bcc">
            <app-copy-to-clipboard>{{ bcc }}</app-copy-to-clipboard>
        </p>
    </ng-container>
</section>

<section class="u-margin-bottom-double" *ngIf="!isSms">
    <h6 class="u-margin-bottom-half">{{ 'SUBJECT' | translate }}</h6>
    <p class="u-margin-none" *ngIf="contactLog.subject; else empty">{{ contactLog.subject }}</p>
</section>

<section class="__content">
    <h6 class="u-margin-bottom-half">{{ 'BODY' | translate }}</h6>
    <div *ngIf="contactLog.content; else empty" [innerHTML]="contactLog.content | sanitize: 'html'"></div>
</section>

<section class="u-margin-top-double" *ngIf="!isSms">
    <h6 class="u-margin-bottom-half">{{ 'ATTACHMENTS' | translate }}</h6>
    <ng-container *ngIf="contactLog.assets?.length; else empty">
        <p class="u-margin-none" *ngFor="let asset of contactLog.assets">{{ asset.name }}</p>
    </ng-container>
</section>

<ng-template #empty>
    <div>-</div>
</ng-template>
