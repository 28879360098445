import { HttpErrorResponse } from '@angular/common/http';
import { Order } from '@app/order/interfaces/order';
import { Action, createReducer, on } from '@ngrx/store';
import { failure, init, request, success } from './order-create.actions';

export const featureKey = 'orderCreate';

export interface State {
    data: Order | null;
    error: HttpErrorResponse | null;
    loading: boolean;
}

export const initialState: State = {
    data: null,
    error: null,
    loading: false,
};

export function reducer(state: State, action: Action): State {
    return createReducer(
        initialState,
        on(failure, (state, { error }) => ({
            ...state,
            data: null,
            error,
            loading: false,
        })),
        on(request, (state) => ({ ...state, data: null, error: null, loading: true })),
        on(success, (state, { data }) => ({
            ...state,
            data,
            error: null,
            loading: false,
        })),
        on(init, () => ({ ...initialState }))
    )(state, action);
}
