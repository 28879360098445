import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleChangesUtils } from '@smooved/core';
import * as d3 from 'd3';
import { emptyPieData } from '../../charts.constants';
import { ColorScale } from '../../enums/color-scale.enum';
import { ChartItem } from '../../interfaces/chart-item';

@Component({
    selector: 'app-pie-chart',
    template: `
        <div class="__pie-container">
            <svg class="__pie"></svg>
            <h2 class="__spot-value u-font-weight-bold" *ngIf="!!spotValue">{{ spotValue }}</h2>
        </div>
        <div *ngIf="showTooltip" class="__tooltip u-padding-half">
            <h6 class="__title">Header</h6>
            <p class="__value u-font-size-small u-color-muted">value</p>
        </div>
    `,
    styleUrls: ['./pie-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartComponent implements OnChanges {
    @Input() public data: ChartItem[];
    @Input() public width = 150;
    @Input() public height = 150;
    @Input() public margins: { top: number; right: number; bottom: number; left: number } = { top: 0, right: 0, bottom: 0, left: 0 };
    @Input() public showLabels = false;
    @Input() public showTooltip = true;
    @Input() public startAngle = 0;
    @Input() public endAngle = Math.PI * 2;
    @Input() public donutThick = 0;
    @Input() public spotValue: any;
    @Input() public roundCorners = 0;

    @HostBinding('class.__chart-overlap')
    @Input()
    public overlap = false;

    private svg; // Top level SVG element
    private g; // SVG Group element
    private arc; // D3 Arc generator
    private labelsArc; // Arc for labels
    private radius; // Chart radius
    private slices; // Chart slice elements
    private labels; // SVG data label elements
    private total: number; // Total of chart values

    private rawData: ChartItem[]; // Raw chart values array
    private colorScale; // D3 color provider
    private pieData: any; // Arc segment parameters for current data set
    private pieDataPrevious: any; // Arc segment parameters for previous data set - used for transitions

    //Pie function - transforms raw data to arc segment parameters
    private pie;

    constructor(private elRef: ElementRef, private translateService: TranslateService, private cdr: ChangeDetectorRef) {}

    public ngOnChanges({ data, width, height }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(data) || SimpleChangesUtils.hasChanged(width) || SimpleChangesUtils.hasChanged(height)) {
            if (!this.data) return;
            this.updateChart(this.data);
            this.cdr.detectChanges();
        }
    }

    private updateChart(data: ChartItem[]) {
        if (!this.svg) {
            this.createChart(data);
            return;
        }
        // d3.selectAll('svg > *').remove();
        this.processPieData(data, false);
        this.setChartDimensions();
        this.updateGraphicsElement();
        this.updateArcGenerator();
        this.updateSlices();
        if (this.showLabels) this.updateLabels();
        if (this.showTooltip) this.setTooltip();
    }

    private createChart(data: ChartItem[]) {
        this.preparePieChart();
        this.processPieData(data);
        this.setChartDimensions();
        this.setColorScale();
        this.addGraphicsElement();
        this.setupArcGenerator();
        this.addSlices();
        if (this.showTooltip) this.setTooltip();
        if (this.showLabels) this.addLabels();
    }

    private preparePieChart(): void {
        this.pie = d3
            .pie<ChartItem>()
            .sort(null)
            .value((d) => d.value)
            .startAngle(this.startAngle)
            .endAngle(this.endAngle);
    }

    private processPieData(data: ChartItem[], initial = true) {
        this.rawData = data;
        this.total = this.rawData.reduce((sum, next) => sum + next.value, 0);

        this.pieData = this.pie(this.total ? data : emptyPieData);
        if (initial) {
            this.pieDataPrevious = this.pieData;
        }
    }

    private removeExistingChartFromParent() {
        d3.select(this.elRef.nativeElement).select('svg').remove();
    }

    private setChartDimensions() {
        this.radius = Math.min(this.width, this.height) / 2;
        this.svg = d3
            .select(this.elRef.nativeElement)
            .select('svg')
            .attr('width', `${this.width + this.margins.right + this.margins.left}px`)
            .attr('height', `${this.height + this.margins.top + this.margins.bottom}px`)
            .attr(
                'viewBox',
                '0 0 ' +
                    (this.width + this.margins.right + this.margins.left) +
                    ' ' +
                    (this.height + this.margins.top + this.margins.bottom)
            );
    }

    private setColorScale() {
        this.colorScale = d3
            .scaleOrdinal()
            .domain(this.rawData.map((d, i) => i.toString()))
            .range(Object.values(ColorScale));
    }

    private addGraphicsElement() {
        this.g = this.svg
            .append('g')
            .attr(
                'transform',
                `translate(${(this.width + this.margins.right + this.margins.left) / 2},${
                    (this.height + this.margins.top + this.margins.bottom) / 2
                })`
            );
    }

    private updateGraphicsElement() {
        this.g.attr(
            'transform',
            `translate(${(this.width + this.margins.right + this.margins.left) / 2},${
                (this.height + this.margins.top + this.margins.bottom) / 2
            })`
        );
    }

    private setupArcGenerator() {
        const innerRadius = this.donutThick ? this.radius - this.donutThick : 0;
        this.arc = d3.arc().cornerRadius(this.roundCorners).innerRadius(innerRadius).outerRadius(this.radius);
        this.labelsArc = d3
            .arc()
            .innerRadius(this.radius)
            .outerRadius(this.radius * 1.1);
    }

    private updateArcGenerator() {
        const innerRadius = this.donutThick ? this.radius - this.donutThick : 0;
        this.arc.innerRadius(innerRadius).outerRadius(this.radius);
        this.labelsArc.innerRadius(this.radius).outerRadius(this.radius * 1.1);
    }

    private addSlices() {
        this.slices = this.g
            .selectAll('allSlices')
            .data(this.pieData)
            .enter()
            .append('path')
            .attr('d', this.arc)
            .attr('fill', (datum, index) => {
                return datum.data.color || this.colorScale(index);
            });
    }

    private updateSlices() {
        this.slices
            .data(this.pieData)
            .join('path')
            .attr('d', this.arc)
            .attr('fill', (datum, index) => {
                return datum.data.color || this.colorScale(index);
            });
    }

    private addLabels() {
        this.labels = this.g
            .selectAll('allLabels')
            .data(this.pieData)
            .enter()
            .append('text')
            .text(this.labelValueGetter)
            .attr('transform', (datum) => {
                return 'translate(' + this.labelsArc.centroid(datum) + ')';
            })
            .style('font-size', '12px')
            .style('text-anchor', 'middle');
    }

    private updateLabels() {
        this.labels.data(this.pieData);
        this.labels.each((datum, index, n) => {
            d3.select(n[index]).text(this.labelValueFn(this.rawData[index]));
        });
        this.labels.transition().duration(500).attrTween('transform', this.labelTween);
    }

    private setTooltip() {
        this.slices
            .on('mouseover', (_, datum) => {
                const centroid = this.arc.centroid(datum);
                d3.select(this.elRef.nativeElement)
                    .select('.__tooltip')
                    .style('opacity', 1)
                    .style('transform', `translate(${centroid[0] + 20}px,${centroid[1] + 20}px)`);

                d3.select(this.elRef.nativeElement).select('.__title').text(this.translateService.instant(datum.data?.label));

                d3.select(this.elRef.nativeElement)
                    .select('.__value')
                    .text(`${((datum.value / this.total) * 100).toFixed(2)}%`);
            })
            .on('mouseout', () => {
                d3.select(this.elRef.nativeElement).select('.__tooltip').style('opacity', 0);
            });
    }

    /**
     * Creates an "interpolator" for animated transition for arc labels
     * given previous and new label positions,
     * generates a series of arc states (be)tween start and end state
     */
    private labelTween = (datum, index) => {
        const interpolation = d3.interpolate(this.pieDataPrevious[index], datum);
        this.pieDataPrevious[index] = interpolation(0);
        return (t) => {
            return 'translate(' + this.labelsArc.centroid(interpolation(t)) + ')';
        };
    };

    private labelValueGetter = (datum, index) => {
        return this.labelValueFn(this.rawData[index]);
    };

    private labelValueFn(val) {
        return val.label;
    }
}
