<app-label-container
    [required]="required"
    [id]="id"
    [label]="'LOCATION.LABEL_SINGLE' | translate"
    [hasMargin]="hasMargin"
    [hasMarginDouble]="hasMarginDouble"
>
    <ng-autocomplete
        [data]="locations$ | async"
        searchKeyword="name"
        [placeholder]="'SEARCH' | translate"
        [notFoundText]="'NOT_FOUND' | translate"
        (inputCleared)="inputClear()"
        (selected)="selected($event)"
        [isLoading]="loading$ | async"
        [(ngModel)]="innerModel"
        [itemTemplate]="itemTemplateStatic"
    >
    </ng-autocomplete>

    <ng-template #itemTemplateStatic let-item>
        <span [innerHTML]="item?.name"></span>
    </ng-template>

    <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
</app-label-container>
