import { SortDirection } from '../enums';

interface SortConfig {
    nullsLast: boolean;
}

export class SortUtils {
    public static sortByDate<T>(
        sortDirection: SortDirection = SortDirection.Asc,
        getter: (item: unknown) => Date = (x: Date): Date => x
    ): (a, b) => number {
        return (a: T, b: T): number => SortUtils.sort(sortDirection)(getter(a).getTime(), getter(b).getTime());
    }

    public static sort(sortDirection: SortDirection, getter: (item: unknown) => number = (val: number): number => val): (a, b) => number {
        return (x: unknown, y: unknown): number => {
            const a = getter(x);
            const b = getter(y);
            return sortDirection === SortDirection.Asc ? a - b : b - a;
        };
    }

    public static sortBy(property: string, sortDirection = SortDirection.Asc, config?: SortConfig): (a, b) => number {
        function evaluate(a, b): number {
            return a < b ? -1 : a > b ? 1 : 0;
        }
        const sortOrder = sortDirection === SortDirection.Asc ? 1 : -1;

        return (a, b): number => {
            let result;
            if (config?.nullsLast && a[property] == null) return 1;
            if (config?.nullsLast && b[property] == null) return -1;

            if (a[property] instanceof Date || b[property] instanceof Date) {
                result = evaluate(a[property].getTime(), b[property].getTime());
            } else {
                result = evaluate(a[property], b[property]);
            }
            return result * sortOrder;
        };
    }
}
