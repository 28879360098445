import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { gift } from '@shared/order/constants/order-scope.constants';

@Pipe({
    name: 'orderGift',
})
export class OrderGiftPipe implements PipeTransform {
    transform(orders: Order[]): Order {
        if (!orders?.length) return null;
        return orders.find((order) => order.scope.startsWith(gift));
    }
}
