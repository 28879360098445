import { createAction, props } from '@ngrx/store';
import { Interviewee } from '@smooved/core';
import { InterviewInitiatedByType } from '../enums/interview-initiated-by-type.enum';
import { Interview } from '../interfaces/meta/interview';

export enum SurveyActionTypes {
    GetInterview = '[Survey] Get interview',
    GetInterviewSuccess = '[Survey] Get interview success',
    SetHasExistingSurvey = '[Survey] Set has existing survey',
    SetPresetScore = '[Survey] Set preset score',
    setInitiatedBy = '[Survey] Set initiated by',
}

export type GetInterview = { interviewee: Interviewee; initiatedBy: InterviewInitiatedByType; callback?: (interview: Interview) => any };
export type GetInterviewSuccess = { interview: Interview };
export type SetHasExistingSurvey = { hasExistingSurvey: boolean };
export type SetPresetScore = { score: number };
export type SetInitiatedBy = { initiatedBy: InterviewInitiatedByType };

export const getInterview = createAction(SurveyActionTypes.GetInterview, props<GetInterview>());
export const getInterviewSuccess = createAction(SurveyActionTypes.GetInterviewSuccess, props<GetInterviewSuccess>());
export const setHasExistingSurvey = createAction(SurveyActionTypes.SetHasExistingSurvey, props<SetHasExistingSurvey>());
export const setPresetScore = createAction(SurveyActionTypes.SetPresetScore, props<SetPresetScore>());
export const setInitiatedBy = createAction(SurveyActionTypes.setInitiatedBy, props<SetInitiatedBy>());
