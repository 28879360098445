import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopicAnalysisSentiment } from '@app/real-estate-agent/components/topic-analysis-sentiment/topic-analysis-sentiment.interfaces';
import { Colors, SvgIllustration, UiContext, UiIconSize } from '@smooved/ui';

@Component({
    selector: 'app-topic-analysis-sentiment',
    templateUrl: './topic-analysis-sentiment.component.html',
    styleUrls: ['./topic-analysis-sentiment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicAnalysisSentimentComponent {
    @Input() public topicAnalysisSentiment: TopicAnalysisSentiment;
    public readonly iconSize = UiIconSize;
    public readonly context = UiContext;
    public readonly svgIllustration = SvgIllustration;
    public readonly colors = Colors;
}
