import { Invoice } from '@app/invoicing/interfaces/invoice';
import { InvoicesRequest } from '@app/invoicing/interfaces/invoices-request';
import { Quarter } from '@app/invoicing/interfaces/quarter';
import { QuarterlyInvoicesTotals } from '@app/invoicing/interfaces/quarterly-invoices-totals';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { createAction, props } from '@ngrx/store';
import { InvoiceStatus } from '../enums/invoice-status.enum';

export const setQuarter = createAction('[Invoicing] set quarter', props<{ quarter: Date }>());

export const getQuarterlyInvoicesTotals = createAction('[Invoicing] get quarterly invoices totals');
export const getQuarterlyInvoicesTotalsSuccess = createAction(
    '[Invoicing] get quarterly invoices totals success',
    props<{ quarterlyInvoicesTotals: QuarterlyInvoicesTotals }>()
);
export const getQuarterlyInvoicesTotalsFailed = createAction('[Invoicing] get quarterly invoices totals failed');

export const getInvoices = createAction('[Invoicing] get invoices', props<{ invoicesRequest: InvoicesRequest }>());
export const getInvoicesSuccess = createAction('[Invoicing] get invoices success', props<{ invoices: Invoice[]; count: number }>());
export const getInvoicesFailed = createAction('[Invoicing] get invoices failed');

export const getInvoice = createAction('[Invoicing] get invoice', props<{ realEstateGroupId: string }>());
export const getInvoiceSuccess = createAction('[Invoicing] get invoice success', props<{ invoice: Invoice }>());
export const getInvoiceFailed = createAction('[Invoicing] get invoice failed');

export const getQuarter = createAction('[Invoicing] get quarter');
export const getQuarterSuccess = createAction('[Invoicing] get quarter success', props<{ quarter: Quarter }>());
export const getQuarterFailed = createAction('[Invoicing] get quarter failed');

export const getQuarterlyInvoicing = createAction('[Invoicing] get quarterly invoicing', props<{ realEstateGroupId: string }>());
export const getQuarterlyInvoicingSuccess = createAction(
    '[Invoicing] get quarterly invoicing success',
    props<{ quarterlyInvoicing: QuarterlyInvoicing }>()
);
export const getQuarterlyInvoicingFailed = createAction('[Invoicing] get quarterly invoicing failed');

export const getLastBillableInvoice = createAction('[Invoicing] get last billable invoice', props<{ realEstateGroupId: string }>());
export const getLastBillableInvoiceSuccess = createAction('[Invoicing] get last billable invoice success', props<{ invoice: Invoice }>());
export const getLastBillableInvoiceFailed = createAction('[Invoicing] get last billable invoice failed');

export const getBillableInvoices = createAction('[Invoicing] get billable invoices', props<{ realEstateGroupId: string }>());
export const getBillableInvoicesSuccess = createAction('[Invoicing] get billable invoices success', props<{ invoices: Invoice[] }>());
export const getBillableInvoicesFailed = createAction('[Invoicing] get billable invoices failed');

export const getInvoicesSent = createAction(
    '[Invoicing] get invoices sent',
    props<{ realEstateGroupId: string; status: InvoiceStatus[]; startDate?: Date }>()
);
export const getInvoicesSentSuccess = createAction('[Invoicing] get invoices sent success', props<{ invoices: Invoice[] }>());
export const getInvoicesSentFailed = createAction('[Invoicing] get invoices sent failed');
