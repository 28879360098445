import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formControlNames } from '@app/gift/modals/send-gift/send-gift.constants';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Move } from '@app/move/interfaces/move';
import { DateUtils } from '@smooved/core';
import { ClosableModalTemplateComponent, NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-send-gift-modal',
    templateUrl: './send-gift.modal.html',
})
export class SendGiftModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;

    public formControlNames = formControlNames;
    public move: Move;
    public minimumDeliveryDate: Date;

    public form = this.formBuilder.group({
        [formControlNames.gift]: [null, [Validators.required]],
        [formControlNames.firstName]: [null, [Validators.required]],
        [formControlNames.lastName]: [null, [Validators.required]],
        [formControlNames.deliveryAddress]: [null, [Validators.required]],
        [formControlNames.deliveryDate]: [null, [Validators.required]],
        [formControlNames.note]: [null],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly orderSandbox: OrderSandbox,
        private readonly moveSandbox: MoveSandbox,
        public readonly uiSandbox: AppUiSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.get(this.data).subscribe((response) => {
            this.move = response;
            this.initFormData(response);
        });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.orderSandbox
                .createOrderGift({
                    gift: this.form.get(formControlNames.gift).value?._id,
                    deliveryDate: this.form.get(formControlNames.deliveryDate).value,
                    firstName: this.form.get(formControlNames.firstName).value,
                    lastName: this.form.get(formControlNames.lastName).value,
                    note: this.form.get(formControlNames.note).value,
                    deliveryAddress: this.form.get(formControlNames.deliveryAddress).value,
                    move: this.data,
                })
                .subscribe((_) => {
                    this.notificationSandbox.success('MOVE.GIFT.SEND_GIFT_MODAL.SUCCESS');
                    this.closableModalComponent.close(true);
                });
        }
    }

    private initFormData(move: Move): void {
        this.minimumDeliveryDate = this.getInitialDeliveryDate(move);
        this.form.patchValue({
            [formControlNames.firstName]: move.user?.firstName,
            [formControlNames.lastName]: move.user?.lastName,
            [formControlNames.deliveryAddress]: { ...move.user.movingAddress },
            [formControlNames.deliveryDate]: this.minimumDeliveryDate,
        });
    }

    private getInitialDeliveryDate(move: Move): Date {
        const dateByMovingDate = DateUtils.tz(move.movingDate).add(5, 'days');
        return DateUtils.max([dateByMovingDate, new Date()]).toDate();
    }
}
