import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SearchableDropdownInputOption, UiContext, UiIcon } from '@smooved/ui';
import { DocumentCenterService } from '@app/document-center/services/document-center.service';
import { TemplateTag } from '@app/document-center/enums/template-tag';
import { ArrayUtils, FileUtils, SimpleChangesUtils } from '@smooved/core';
import { finalize, tap } from 'rxjs/operators';
import { GeneratedFileDataIssue } from '@app/document-center/interfaces/template-mapping';

@Component({
    selector: 'app-generate-transaction-template',
    template: `<p class="u-margin-bottom-xs">{{ label }}</p>
        <div class="u-flex-row u-flex-align-items-center">
            <ui-searchable-dropdown-input
                class="u-margin-right-double"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [options]="options"
                [(ngModel)]="templateMappingId"
            ></ui-searchable-dropdown-input>
            <button
                (click)="generateTransactionTemplate()"
                [context]="uiContext.Tertiary"
                [isLoading]="loading"
                [disabled]="!templateMappingId || !transactionId || loading"
                smvd-ui-button
            >
                {{ 'DOCUMENT_CENTER.GENERATE_DOCUMENT' | translate }}
            </button>
        </div>
        <app-alert *ngIf="!ArrayUtils.isEmpty(formattingIssues)" [context]="uiContext.Warning" [icon]="uiIcon.Warning" class="u-margin-top">
            <h5>{{ 'EXCEPTION.DOCUMENT_CENTER.PDF_GENERATION.TITLE' | translate }}</h5>
            <p *ngFor="let formattingIssue of formattingIssues">
                {{ formattingIssue.errorLabel | translate }}: <strong>{{ formattingIssue.data }}</strong>
            </p>
        </app-alert>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateTransactionTemplateComponent implements OnChanges {
    @Input() label: string;
    @Input() tag: TemplateTag;
    @Input() transactionId: string;

    public options: SearchableDropdownInputOption[] = [];
    public formattingIssues: GeneratedFileDataIssue[] = [];
    public templateMappingId: string;
    public loading: boolean;

    protected readonly uiContext = UiContext;
    protected readonly ArrayUtils = ArrayUtils;
    protected readonly uiIcon = UiIcon;

    constructor(
        private readonly documentCenterService: DocumentCenterService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    public ngOnChanges({ tag }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(tag)) {
            this.getOptions(tag.currentValue);
        }
    }

    public generateTransactionTemplate(): void {
        this.loading = true;
        this.formattingIssues = [];
        this.documentCenterService
            .generateTransactionTemplate(this.transactionId, this.templateMappingId)
            .pipe(
                tap((response) => {
                    this.formattingIssues = response.headers.get('Formatting-Issues')
                        ? JSON.parse(response.headers.get('Formatting-Issues'))
                        : [];
                }),
                tap((response) => {
                    FileUtils.openAsset({
                        response,
                        type: 'blob',
                    });
                }),
                finalize(() => {
                    this.loading = false;
                    this.cdr.detectChanges();
                })
            )
            .subscribe();
    }

    private getOptions(tag: TemplateTag): void {
        this.documentCenterService.getTemplateMappings(tag).subscribe((response) => {
            this.options = (response.templateMappings || []).map((templateMapping) => ({
                id: templateMapping.id,
                value: templateMapping.id,
                label: templateMapping.name,
            }));
        });
    }
}
