<ng-container
    *ngIf="{tabletLandscapeUp: uiSandbox.tabletLandscapeUp$ | async, upToAndIncludingPhoneLandscape: uiSandbox.upToAndIncludingPhoneLandscape$ | async} as vm"
>
    <ng-container *hasFeatureAccess="featureScope.ReviewCollect">
        <div
            *ngIf="!!templateConfig.writeReviewRoute && !vm.tabletLandscapeUp"
            class="__write-review-button-mobile-container u-flex-row u-flex-justify-content-center u-padding-top-half"
        >
            <ng-container *ngTemplateOutlet="newReviewButton"></ng-container>
        </div>
    </ng-container>

    <div
        class="u-position-relative u-flex-row u-flex-justify-content-space-between u-flex-align-items-center u-bordered-bottom u-padding-bottom u-padding-top-up-to-and-including-tablet-portrait"
    >
        <span class="u-font-weight-semi-bold"
            >{{ 'REVIEWS.COUNT' | translate: {count: reviewsTemplateService.dataSource?.reviewsCount$ | async} }}</span
        >
        <ng-container *hasFeatureAccess="featureScope.ReviewCollect">
            <ng-container
                *ngIf="!!templateConfig.writeReviewRoute && vm.tabletLandscapeUp"
                [ngTemplateOutlet]="newReviewButton"
            ></ng-container>
        </ng-container>
        <div *ngIf="vm.upToAndIncludingPhoneLandscape">
            <button
                app-button
                icon="expand_more"
                [appendIcon]="true"
                [context]="uiContext.Secondary"
                [appearance]="buttonAppearance.Stroked"
                (click)="openFilterModal()"
            >
                {{ 'UI.FILTER' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<app-loading-container [loading]="reviewsTemplateService.loading$ | async">
    <app-nps-reviews-template [triggerAfter]="0" [npsShareTemplate]="npsActions" [reviews]="preloadedReviews"></app-nps-reviews-template>
</app-loading-container>

<ng-template #npsActions let-item>
    <div class="__share-button-group u-padding-y-axis-half u-font-size-default ui-button-group-vertical u-text-align-left">
        <button
            class="__share-button u-padding-left-half u-padding-right-half"
            app-button
            [brand]="brands.LinkedIn"
            (click)="shareLinkedIn(item)"
            [iconSize]="iconSize.Default"
            [iconContext]="uiContext.Muted"
            [isLoading]="item.pendingLinkedIn"
            [disabled]="!item.pendingLinkedIn && sharingDisabled"
            [appearance]="buttonAppearance.Link"
            [context]="uiContext.Primary"
        >
            <ng-container>{{ 'COMMON.SOCIALS.LINKEDIN.LABEL' | translate }}</ng-container>
        </button>
        <button
            class="__share-button u-padding-left-half u-padding-right-half"
            app-button
            [brand]="brands.Facebook"
            [iconSize]="iconSize.Default"
            [iconContext]="uiContext.Muted"
            (click)="shareFacebook(item)"
            [isLoading]="item.pendingFacebook"
            [disabled]="!item.pendingFacebook && sharingDisabled"
            [appearance]="buttonAppearance.Link"
            [context]="uiContext.Primary"
        >
            <ng-container>{{ 'COMMON.SOCIALS.FACEBOOK.LABEL' | translate }}</ng-container>
        </button>
        <button
            class="__share-button u-padding-left-half u-padding-right-half"
            app-button
            [brand]="brands.Instagram"
            [iconSize]="iconSize.Default"
            [iconContext]="uiContext.Muted"
            (click)="shareInstagram(item)"
            [isLoading]="item.pendingInstagram"
            [disabled]="!item.pendingInstagram && sharingDisabled"
            [appearance]="buttonAppearance.Link"
            [context]="uiContext.Primary"
        >
            <ng-container>{{ 'COMMON.SOCIALS.INSTAGRAM.LABEL' | translate }}</ng-container>
        </button>
        <button
            class="__share-button u-padding-left-half u-padding-right-half"
            app-button
            icon="download"
            [iconSize]="iconSize.Md"
            [iconContext]="uiContext.Muted"
            (click)="download(item)"
            [isLoading]="item.pendingDownload"
            [disabled]="!item.pendingDownload && sharingDisabled"
            [appearance]="buttonAppearance.Link"
            [context]="uiContext.Primary"
        >
            <ng-container>{{ 'COMMON.DOWNLOAD.LABEL' | translate }}</ng-container>
        </button>
        <button
            class="__share-button u-padding-left-half u-padding-right-half"
            app-button
            icon="language"
            [iconSize]="iconSize.Md"
            [iconContext]="uiContext.Muted"
            (click)="copyToClipboard(item)"
            [disabled]="sharingDisabled"
            [appearance]="buttonAppearance.Link"
            [context]="uiContext.Primary"
        >
            <ng-container>{{ 'COMMON.COPY_LINK.LABEL' | translate }}</ng-container>
        </button>
    </div>
</ng-template>

<ng-template #newReviewButton>
    <a smvd-ui-button [routerLink]="templateConfig.writeReviewRoute" [context]="uiContext.Primary" [appearance]="buttonAppearance.Stroked">
        {{ 'REVIEWS.WRITE_NEW_REVIEW' | translate }}
    </a>
</ng-template>
