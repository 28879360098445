import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { MessageConfig, MessageIndividualConfig } from './message/message-config.interface';
import { MessageComponent } from './message/message.component';
import { MessagesProvidersModule } from './messages-providers.module';

@Injectable({ providedIn: MessagesProvidersModule })
export class MessageBoardService {
    constructor(private toastr: ToastrService, @Inject(PLATFORM_ID) public platformId: string) {}

    public show(config: Partial<MessageConfig>): ActiveToast<any> {
        return this.addMessage(config);
    }

    private addMessage(config: Partial<MessageConfig>): ActiveToast<any> {
        if (config.component) {
            config.message = config.component.name; // set message for toastr duplication test
        }
        return this.toastr.show(config.message, config.title, {
            toastComponent: MessageComponent,
            templateComponent: config.component,
            closeButton: true,
            disableTimeOut: true,
            toastClass: `ui-message-${config.context} u-background-${config.context}`,
            ...config.override,
        } as MessageIndividualConfig);
    }
}
