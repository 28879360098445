import { Component, OnInit } from '@angular/core';
import { BarGap } from '@smooved/ui';
import { tap } from 'rxjs/operators';
import * as constants from './analytics-moves-count-by-created-flow-chart.constants';
import { AnalyticsMovesCountByCreatedFlowChartService } from './analytics-moves-count-by-created-flow-chart.service';

@Component({
    selector: 'app-analytics-moves-count-by-created-flow-chart',
    templateUrl: './analytics-moves-count-by-created-flow-chart.component.html',
    providers: [AnalyticsMovesCountByCreatedFlowChartService],
})
export class AnalyticsMovesCountByCreatedFlowChartComponent implements OnInit {
    public loaded = false;
    public stackNames = constants.stackNames;
    public data;
    public colors = constants.colors;
    public barGap = BarGap.Small;
    public legend = constants.legend;
    public chartWidth = constants.chartWidth;

    constructor(private analyticsMovesCountByCreatedFlowChartService: AnalyticsMovesCountByCreatedFlowChartService) {}

    public ngOnInit(): void {
        this.initChart();
    }

    private initChart() {
        this.analyticsMovesCountByCreatedFlowChartService
            .getData()
            .pipe(tap((_) => (this.loaded = true)))
            .subscribe((data) => (this.data = data));
    }
}
