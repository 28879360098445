import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RealEstateAgency, RxjsComponent, StringUtils } from '@smooved/core';
import { ButtonAppearance } from '../../../button';
import { UiContext } from '../../../ui.enums';
import { OfficeComponent } from '../../components/office/office.component';
import { ExternalInfluencerService } from '../../services/external-influencer.service';

@Component({
    selector: 'smvd-ui-offices',
    templateUrl: './offices.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OfficesContainer extends RxjsComponent implements OnInit {
    public locations: RealEstateAgency[];
    public fallbackPhoneNumber: string;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(public readonly CompanyPageServer: ExternalInfluencerService) {
        super();
    }

    public ngOnInit(): void {
        const externalInfluencer = this.CompanyPageServer.externalInfluencerSubject.value;
        this.fallbackPhoneNumber = externalInfluencer.phoneNumber;
        this.locations = externalInfluencer.realEstateGroupLocations;
    }

    public trackBySlug(_: number, office: OfficeComponent): string {
        return StringUtils.slugify(office.name);
    }
}
