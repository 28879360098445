import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-todo-leaver-created',
    templateUrl: './todo-leaver-created.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverCreatedComponent {
    @Input() public move: Move;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly i18nKeys = AppI18nKeyType;

    constructor(private readonly router: Router) {}

    public addLeaver(): void {
        void this.router.navigate([Navigation.OTS, Navigation.LeaverDetails]);
    }
}
