import { otsBaseRoute } from '@app/ots/constants/ots.constants';

export const iotsBaseRoute = 'iots';
export const iotsDataRoute = 'data';
export const iotsDateOfBirthRoute = 'date-of-birth';
export const iotsMovingAddressRoute = 'moving-address';
export const iotsHomeDescriptionRoute = 'home-description';
export const iotsRoomDivisionRoute = 'room-division';
export const iotsSuggestionsRoute = 'suggestions';
export const iotsOfferSelectedRoute = 'offer-selected';
export const iotsFamilialRoute = 'familial';

export const iotsBaseRouting = [iotsBaseRoute];
export const iotsDataRouting = [iotsDataRoute];
export const iotsDateOfBirthRouting = [iotsDataRoute, iotsDateOfBirthRoute];
export const iotsSuggestionsRouting = [iotsSuggestionsRoute];
export const iotsOfferSelectedRouting = [iotsOfferSelectedRoute];
export const iotsFamilialRouting = [iotsFamilialRoute];

export const iotsAbsoluteRoute = `/${otsBaseRoute}/${iotsBaseRoute}`;
