import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { PipeModule } from '../pipes/pipe.module';
import { AuthorCaptionPipe } from './author-caption.pipe';
import { NoteComponent } from './note.component';

@NgModule({
    imports: [CommonModule, IconModule, TranslateModule, PipeModule, ButtonModule, TranslateModule],
    declarations: [NoteComponent, AuthorCaptionPipe],
    exports: [NoteComponent, AuthorCaptionPipe],
})
export class NoteModule {}
