import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-move-nps-detail',
    templateUrl: './move-nps-detail.component.html',
})
export class MoveNpsDetailComponent {
    @Input() public move: Move;
}
