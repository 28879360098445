<ng-container *ngIf="item">
    <article class="__nps-template" [attr.data-review-id]="item | getId" [ngSwitch]="item.source">
        <aside class="__nps-score">
            <app-svg-illustration *ngSwitchCase="reviewSources.Google" [svg]="svgs.Google" class="__logo-google"></app-svg-illustration>
            <app-icon *ngSwitchCase="reviewSources.Facebook" [brand]="'facebook'" size="lg" class="__logo-facebook"></app-icon>
            <app-nps-score *ngSwitchDefault [score]="item.score"></app-nps-score>
        </aside>
        <section [ngClass]="templateConfig.contentClasses" class="__nps-body u-overflow-hidden">
            <app-nps-review-google-header *ngSwitchCase="reviewSources.Google" [item]="item" class="u-w100p">
                <ng-container item-status *ngTemplateOutlet="npsStatus"></ng-container>
                <ng-container item-chip-list *ngTemplateOutlet="chipList"></ng-container>
            </app-nps-review-google-header>
            <app-nps-review-facebook-header *ngSwitchCase="reviewSources.Facebook" [item]="item" class="u-w100p">
                <ng-container item-status *ngTemplateOutlet="npsStatus"></ng-container>
                <ng-container item-chip-list *ngTemplateOutlet="chipList"></ng-container>
            </app-nps-review-facebook-header>
            <app-nps-review-smooved-header *ngSwitchDefault [item]="item">
                <ng-container item-status *ngTemplateOutlet="npsStatus"></ng-container>
                <ng-container item-chip-list *ngTemplateOutlet="chipList"></ng-container>
            </app-nps-review-smooved-header>
            <div *ngIf="templateConfig.includeTopicsAnalysis" class="u-flex-row u-flex-align-items-center u-margin-bottom u-flex-wrap">
                <ui-review-topic-sentiment-pill
                    *ngFor="let topic of item.topics; let last = last"
                    [topicSentiment]="topic"
                    [class.u-margin-right-sm-md]="!last"
                ></ui-review-topic-sentiment-pill>
            </div>
            <ng-container *ngIf="showContent">
                <p
                    *ngIf="maxChars; else fullContent"
                    [class.u-background-blurred]="!item.showReviewContent"
                    class="__nps-content u-font-size-default"
                    smvd-ui-text-limit
                    [chars]="maxChars"
                    [value]="templateConfig.includeTopicsAnalysis ? (item.suggestionProcessed | highlightSentiment) : item.suggestion"
                ></p>

                <p class="u-font-size-sm u-color-muted u-margin-top-half" *ngIf="item.missingChars">
                    {{ 'MISSING_CHARS' | i18nKeyTranslate : i18nKeyTypes.ReviewTemplate : { count: item.missingChars } }}
                </p>

                <smvd-ui-review-upgrade-package
                    *ngIf="showUpgradeBox"
                    class="u-display-block u-margin-top"
                ></smvd-ui-review-upgrade-package>

                <div class="__nps-share-button">
                    <ng-content select="[nps-share]"></ng-content>
                </div>
                <app-nps-review-author
                    *ngIf="templateConfig.showFooterAuthor"
                    [review]="item"
                    class="u-margin-top u-font-size-xs"
                ></app-nps-review-author>
            </ng-container>
        </section>
        <aside class="__nps-note u-margin-top" *ngIf="!templateConfig.hideNotes && item.notes?.length">
            <app-note
                *ngFor="let note of item.notes"
                class="__note"
                [id]="note._id"
                [author]="note.lastModifiedBy || note.createdBy | authorCaption"
                [authorInterpolationParams]="getAuthorInterpolationParams(note) | async"
                [createdOn]="note.lastModifiedOn || note.createdOn"
                [access]="note.access"
                [canEdit]="note.canEdit"
                (edit)="onEdit(item, note)"
            >
                <p smvd-ui-text-limit [chars]="maxChars" [value]="note.value"></p>
            </app-note>
        </aside>
        <div class="__nps-actions">
            <ng-content></ng-content>
        </div>
    </article>
</ng-container>

<ng-template #fullContent>
    <p
        [class.u-background-blurred]="!item.showReviewContent"
        class="__nps-content u-font-size-default"
        [innerHTML]="templateConfig.includeTopicsAnalysis ? (item.suggestionProcessed | highlightSentiment) : item.suggestion"
    ></p>
</ng-template>

<ng-template #chipList>
    <mat-chip-listbox *ngIf="item.unread" class="u-display-inline-flex">
        <mat-chip-option color="accent" class="mat-chip-size-xs">{{ 'NEW' | translate }}</mat-chip-option>
    </mat-chip-listbox>
</ng-template>

<ng-template #npsStatus>
    <ng-content select="[nps-status]"></ng-content>
</ng-template>
