export const columnOrderId = 'orderId';
export const columnCreatedOn = 'createdOn';
export const columnName = 'name';
export const columnEmail = 'email';
export const columnMovingDate = 'movingDate';
export const columnIcons = 'icons';
export const columnDeliveryDate = 'deliveryDate';
export const columnDeliveryAddress = 'deliveryAddress';
export const columnGift = 'gift';
export const columnRealEstateAgent = 'realEstateAgent';
export const columnRealEstateGroup = 'realEstateGroup';
export const columnScheduledDate = 'planned.value';
export const columnIconsRentalInspection = 'iconsRentalInspection';
export const columnIndication = 'indication';

export const columnsTelecom = [columnOrderId, columnCreatedOn, columnName, columnEmail, columnMovingDate, columnIcons];
export const columnsGift = [columnOrderId, columnDeliveryDate, columnDeliveryAddress, columnGift, columnRealEstateAgent];

export const columnsRentalInspection = [
    columnOrderId,
    columnCreatedOn,
    columnScheduledDate,
    columnDeliveryAddress,
    columnRealEstateGroup,
    columnIconsRentalInspection,
];
