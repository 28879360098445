<div class="u-flex-column u-flex-align-items-start">
    <h3>{{ 'REAL_ESTATE_AGENT.EOTS.MOVE_DETAILS.TITLE' | translate }}</h3>
    <p class="u-color-muted u-margin-bottom-triple">
        {{
            'STEP_INDICATION'
                | translate
                    : {
                          start: stepStart,
                          end: stepEnd
                      }
        }}
    </p>

    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
        <app-date-input
            [id]="'moving-date'"
            [label]="'MOVE.USER.MOVING_DATE.LABEL' | translate"
            [placeholder]="'DATE.PLACEHOLDER' | translate"
            formControlName="movingDate"
        ></app-date-input>

        <app-late-moving-date-red-notification
            [createdOn]="today"
            [movingDate]="movingDate"
            [context]="uiContext.Danger"
        ></app-late-moving-date-red-notification>

        <app-address formControlName="movingAddress" class="u-margin-bottom-double" labelResource="MOVE.USER.MOVING_ADDRESS"></app-address>

        <app-previous-submit [showPrevious]="showPrevious" (previous)="previous.emit()"></app-previous-submit>
    </form>
</div>
