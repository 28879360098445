<app-todo
    [label]="'RENTAL_INSPECTION.TODO.PLANNING.LABEL' | translate"
    [checked]="checked"
    [readonly]="readonly"
    [disabled]="disabled"
    *ngIf="order"
>
    <app-todo-template
        *todoStateChecked
        [label]="'RENTAL_INSPECTION.TODO.PLANNING.PLANNED_LABEL' | translate: { date: order.planned?.value | formatDate: dateTimeFormat }"
    >
        <div class="u-color-muted u-font-size-small">
            {{ lastModified | formatDate: dateTimeFormat }}
        </div>
        <a app-button appearance="link" context="muted" (click)="reset()" class="u-padding-none" *ngIf="!orderCompleted">
            {{ 'RENTAL_INSPECTION.TODO.PLANNING.REDO_CTA' | translate }}
        </a>
    </app-todo-template>

    <app-todo-template
        *todoStateReadonly
        [label]="
            (checked ? 'RENTAL_INSPECTION.TODO.PLANNING.PLANNED_LABEL' : 'RENTAL_INSPECTION.TODO.PLANNING.LABEL')
                | translate: { date: order.planned?.value | formatDate: dateTimeFormat }
        "
    >
        <div class="u-color-muted u-font-size-small">
            {{ lastModified | formatDate: dateTimeFormat }}
        </div>
    </app-todo-template>

    <app-todo-template *todoStateUnchecked>
        <ng-container *ngTemplateOutlet="book"></ng-container>
    </app-todo-template>
</app-todo>

<ng-template #book>
    <form class="u-margin-top-half">
        <app-date-input
            [label]="'RENTAL_INSPECTION.TODO.PLANNING.INPUT_LABEL' | translate"
            [formControl]="input"
            [placeholder]="'DATE.PLACEHOLDER' | translate"
        ></app-date-input>

        <button app-button (click)="set(input.value)" context="secondary" [disabled]="input.invalid">
            {{ 'RENTAL_INSPECTION.TODO.PLANNING.CTA' | translate }}
        </button>
    </form>
</ng-template>
