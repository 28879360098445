import { FileInput } from '@app/form/interfaces/file-input';

export const getFileExtension = (file: FileInput): string => {
    if (!file) {
        return null;
    }

    const name = file.name;
    if (!name) {
        return null;
    }

    const lastDot = name.lastIndexOf('.');
    return name.substring(lastDot + 1);
};
