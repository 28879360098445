<button
    *ngIf="unclaimed; else verified"
    (click)="openClaimBusiness()"
    app-button
    [appearance]="buttonAppearance.Link"
    [size]="buttonSize"
    icon="verified"
    class="u-padding-none u-h-auto"
    [iconSize]="iconSize"
    [iconAppearance]="iconAppearance.Outlined"
>
    {{ 'CTA' | i18nKeyTranslate : companyPageI18nKeyTypes.Claim }}
</button>

<ng-template #verified>
    <smvd-ui-verified-badge></smvd-ui-verified-badge>
</ng-template>
