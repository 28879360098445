import { Injectable } from '@angular/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { ActivityLogService } from '../services/activity-log.service';
import { Store } from '@ngrx/store';
import { HeartBeatAction } from '@app/activity-log/activity.actions';

@Injectable({
    providedIn: 'root',
})
export class ActivityLogSandbox {
    constructor(
        private activityLogService: ActivityLogService,
        private navigationSandbox: AppNavigationSandbox,
        private store$: Store
    ) {}

    public sendHeartbeat(): Observable<void> {
        this.store$.dispatch(new HeartBeatAction());
        return this.activityLogService.sendHeartbeat();
    }

    public trackPageView(): void {
        const route = this.navigationSandbox.getCurrentPath();
        this.activityLogService.sendPageView(route).subscribe();
    }
}
