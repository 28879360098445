import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox, NotificationSandbox, Svg } from '@smooved/ui';
import { Move } from '../../../move/interfaces/move';
import { ServiceOrderedI18n, ServiceOrderedI18nKeys } from './service-ordered.constants';

@Component({
    selector: 'app-service-ordered',
    templateUrl: './service-ordered.component.html',
})
export class ServiceOrderedComponent {
    @Input() public move: Move;
    @Input() public checked: boolean;
    @Input() public isEnergy = false;

    @Output() public onMoveChange: EventEmitter<Move> = new EventEmitter<Move>();

    public emptySvg = Svg.Empty;
    public i18nKeys: ServiceOrderedI18n = ServiceOrderedI18nKeys;

    constructor(
        private moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService,
        private notificationSandbox: NotificationSandbox
    ) {}

    public resendConfirm(): void {
        this.modalSandbox.openConfirmModal(
            { data: this.translateService.instant(this.i18nKeys.Confirm) },
            (result) => {
                if (!result) return;
                this.resend();
            },
            { data: this.translateService.instant(this.i18nKeys.Confirm) },
            (result) => {
                if (!result) return;
                this.resend();
            }
        );
    }

    private resend(): void {
        this.moveSandbox.sendConfirmationEmail(this.move._id).subscribe((move) => {
            this.onMoveChange.emit(move);
            this.notificationSandbox.success(this.i18nKeys.Success);
        });
    }
}
