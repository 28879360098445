import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { OtherScores } from '../constants/reviews.constants';
import { getReviewInsightsHouseMatchScoreSuccess, getReviewInsightsOtherScoresSuccess } from './actions';

export interface ReviewInsightsState {
    loading: boolean;
    houseMatchScore: number;
    numberOfReviews: number;
    otherScores: OtherScores;
}

export const initialReviewInsightsState: ReviewInsightsState = {
    loading: null,
    houseMatchScore: null,
    numberOfReviews: null,
    otherScores: [] as unknown as OtherScores,
};

export const reviewInsightsReducer = createReducer(
    initialReviewInsightsState,
    on(getReviewInsightsHouseMatchScoreSuccess, (state, { houseMatchScore }) => ({
        ...state,
        houseMatchScore: houseMatchScore.score,
        numberOfReviews: houseMatchScore.count,
    })),
    on(getReviewInsightsOtherScoresSuccess, (state, { otherScores }) => ({
        ...state,
        otherScores,
    }))
);

export const reviewInsightsStoreFeatureName = 'review-insights';
export const getReviewInsightsState = createFeatureSelector<ReviewInsightsState>(reviewInsightsStoreFeatureName);
export const getHouseMatchScore = createSelector(getReviewInsightsState, (state) => state?.houseMatchScore);
export const getAmountOfReviews = createSelector(getReviewInsightsState, (state) => state?.numberOfReviews);

export const getLoading = createSelector(getReviewInsightsState, (state) => state?.loading);
