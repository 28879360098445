import { otsBaseRoute } from '@app/ots/constants/ots.constants';

export const eotsBaseRoute = 'eots';
export const eotsDataRoute = 'data';
export const eotsTransfereeTypeRoute = 'transferee-type';
export const eotsZipCodeRoute = 'zip-code';
export const eotsFamilySizeRoute = 'family-size';
export const eotsHomeDescriptionRoute = 'home-description';
export const eotsHomeConstructionYearRoute = 'home-construction-year';
export const eotsEnergyTypeRoute = 'energy-type';
export const eotsMeterTypeRoute = 'meter-type';
export const eotsEnergySuggestionsRoute = 'energy-suggestions';
export const eotsOfferSelectedRoute = 'offer-selected';
export const eotsMeterReadingsRoute = 'meter-readings';
export const eotsContactDetailsLeaverRoute = 'contact-details-leaver';
export const eotsContractSuccessRoute = 'contract-success';

export const eotsBaseRouting = [eotsBaseRoute];
export const eotsDataRouting = [eotsDataRoute];
export const eotsTransfereeTypeRouting = [eotsDataRoute, eotsTransfereeTypeRoute];
export const eotsZipCodeRouting = [eotsDataRoute, eotsZipCodeRoute];
export const eotsFamilySizeRouting = [eotsDataRoute, eotsFamilySizeRoute];
export const eotsHomeDescriptionRouting = [eotsDataRoute, eotsHomeDescriptionRoute];
export const eotsHomeConstructionYearRouting = [eotsDataRoute, eotsHomeConstructionYearRoute];
export const eotsEnergyTypeRouting = [eotsDataRoute, eotsEnergyTypeRoute];
export const eotsMeterTypeRouting = [eotsDataRoute, eotsMeterTypeRoute];
export const eotsEnergySuggestionsRouting = [eotsEnergySuggestionsRoute];
export const eotsOfferSelectedRouting = [eotsOfferSelectedRoute];
export const eotsMeterReadingsRouting = [eotsMeterReadingsRoute];
export const eotsContactDetailsLeaverRouting = [eotsContactDetailsLeaverRoute];
export const eotsContractSuccessRouting = [eotsContractSuccessRoute];

export const eotsAbsoluteRoute = `/${otsBaseRoute}/${eotsBaseRoute}`;

// The place of the routes in this array is important. This will be used to handle the navigation
export const dataInputRoutes: string[] = [
    eotsTransfereeTypeRouting.join('/'),
    eotsZipCodeRouting.join('/'),
    eotsFamilySizeRouting.join('/'),
    eotsHomeDescriptionRouting.join('/'),
    eotsHomeConstructionYearRouting.join('/'),
    eotsEnergyTypeRouting.join('/'),
    eotsMeterTypeRouting.join('/'), // conditional
    eotsEnergySuggestionsRouting.join('/'),
];

export const dataInputRoutesVacancy: string[] = [
    eotsZipCodeRouting.join('/'),
    eotsEnergyTypeRouting.join('/'),
    eotsEnergySuggestionsRouting.join('/'),
];
