import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Language, skipWhileEmpty, StringUtils } from '@smooved/core';
import { take } from 'rxjs/operators';
import { WidgetI18nKeyType } from '../../../i18n/i18n-key-type-map.constants';
import { SvgIllustration } from '../../../svg';
import { WidgetQueryParams, WidgetViewModel } from '../../widget.interface';
import { WidgetService } from './widget.service';

@Component({
    selector: 'wis-widget-v1',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
    providers: [WidgetService],
})
export class WidgetV1Component implements OnInit {
    @Input() public key: string;
    @Input() public language = Language.NL;
    @Input() public background = true;
    @Input() public shadow = true;

    public widgetData: WidgetViewModel;
    public hasLogo = false;
    public logo = SvgIllustration.HouseMatchIconDefault;

    public readonly i18nKeyType = WidgetI18nKeyType;

    constructor(private widgetService: WidgetService, private route: ActivatedRoute) {}

    public ngOnInit(): void {
        const { id } = this.route.snapshot.params;
        const { lang, shadow, bg }: WidgetQueryParams = this.route.snapshot.queryParams;

        this.language = (Language[lang?.toUpperCase()] as Language) || this.language;
        this.background = bg ? StringUtils.parseBoolean(bg) : this.background;
        this.shadow = shadow ? StringUtils.parseBoolean(shadow) : this.shadow;
        this.widgetService.bootstrap(this.key || id);
        this.widgetService.widgetDataSubject.pipe(skipWhileEmpty(), take(1)).subscribe((data) => (this.widgetData = data));
    }
}
