import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { RentalInspectionAssetModal } from '@app/move/modals/rental-inspection-asset/rental-inspection-asset.component';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { Move } from '@app/move/interfaces/move';
import { dateTimeFormatDefault, SimpleChangesUtils } from '@smooved/core';
import { ModalSandbox, TodoUtils, TrackUtils } from '@smooved/ui';

@Component({
    selector: 'app-check-item-rental-inspection-asset',
    templateUrl: './check-item-rental-inspection-asset.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemRentalInspectionAssetComponent implements OnChanges, CanDisable {
    @Input() public order: OrderRentalInspection;
    @Input() public disabled: boolean;
    @Input() public readonly: boolean;
    @Output() public orderChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public deleted: EventEmitter<Move> = new EventEmitter<Move>();

    public checked: boolean;
    public lastModified: Date;
    public dateTimeFormat = dateTimeFormatDefault;

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public ngOnChanges({ order }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(order)) {
            this.checked = TodoUtils.isChecked(this.order.track.hasRentalInspectionAsset.state);
            this.lastModified = TrackUtils.lastModifiedOn(this.order.track.hasRentalInspectionAsset);
        }
    }

    public handleModalClose = (move): void => {
        this.orderChanged.emit(!!move);
    };

    public openRentalInspectionModal(): void {
        const data = {
            move: this.order.move,
        };
        this.modalSandbox.openDialog({
            componentForMobile: RentalInspectionAssetModal,
            configForMobile: { data },
            callbackForMobile: this.handleModalClose,
            componentForTabletPortraitUp: RentalInspectionAssetModal,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: this.handleModalClose,
        });
    }
}
