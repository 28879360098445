<app-menu [direction]="direction.Vertical" [alignment]="alignment.Left" class="__match-flow-container__menu">
    <app-menu-item
        [appearance]="menuItemAppearance.Button"
        *ngFor="let nav of wizardSideNavItems"
        [goTo]="nav.step"
        class="u-w100p"
        [disabled]="wizardService.isStepDisabled(nav.step)"
        >{{ nav.i18nKeyStep | translate }}</app-menu-item
    >
</app-menu>
