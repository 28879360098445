import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Move } from '@app/move/interfaces/move';
import { QuarterlyForRealEstateGroupRequest } from '@app/move/interfaces/quarterly-for-real-estate-group-request';
import { PaginationRequest } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class BaseMovesDataSource implements DataSource<Move> {
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private movesSubject: BehaviorSubject<Move[]> = new BehaviorSubject<Move[]>([]);
    private movesCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    public loading$: Observable<boolean> = this.loadingSubject.asObservable();
    public moves$: Observable<Move[]> = this.movesSubject.asObservable();
    public movesCount$: Observable<number> = this.movesCountSubject.asObservable();

    constructor() {}

    protected fetch(request: Observable<any>) {
        this.loadingSubject.next(true);
        request.pipe(finalize(() => this.loadingSubject.next(false))).subscribe((response) => {
            this.movesSubject.next(response?.data);
            this.movesCountSubject.next(response?.count);
        });
    }

    public load(quarterlyForRealEstateGroupRequest: QuarterlyForRealEstateGroupRequest, paginationRequest?: PaginationRequest) {}

    public connect(collectionViewer: CollectionViewer): Observable<Move[] | ReadonlyArray<Move>> {
        return this.movesSubject.asObservable();
    }

    public disconnect(collectionViewer: CollectionViewer): void {
        this.movesSubject.complete();
        this.movesCountSubject.complete();
    }
}
