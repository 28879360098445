import { ClientSource, InsightsData, ReviewsForMonth } from '@app/real-estate-agent/interfaces/insights';
import { createAction, props } from '@ngrx/store';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { ExternalInfluencerReviews } from '@smooved/core';

export enum InsightsActionTypes {
    GetInsightsData = '[Insights] Get insights data',
    GetInsightsDataSuccess = '[Insights] Get insights data success',
    GetHouseMatchScore = '[Insights] Get nps score',
    GetHouseMatchScoreSuccess = '[Insights] Get nps score success',
    GetReviewScores = '[Insights] Get review scores',
    GetReviewScoresSuccess = '[Insights] Get review scores success',
    GetReviewsYearOverview = '[Insights] Get reviews year overview',
    GetReviewsYearOverviewSuccess = '[Insights] Get reviews year overview success',
    GetClientSource = '[Insights] Get client sources',
    GetClientSourceSuccess = '[Insights] Get client sources success',
    GetTopicsAnalysis = '[Insights] Get topics analysis',
    GetTopicsAnalysisSuccess = '[Insights] Get topics analysis success',
    GetTopicsAnalysisFailed = '[Insights] Get topics analysis failed',
}

export type GetInsightsData = { locationId?: string };
export type GetInsightsDataSuccess = { insightsData: InsightsData };
export type GetHouseMatchScore = { locationId?: string };
export type GetHouseMatchScoreSuccess = { houseMatchScore: number };
export type GetReviewScoresSuccess = { scores: ExternalInfluencerReviews };
export type GetReviewsYearOverview = { realEstateGroupId?: string; locationId?: string };
export type GetReviewsYearOverviewSuccess = { data?: ReviewsForMonth[] };
export type GetClientSource = { realEstateGroupId?: string; locationId?: string };
export type GetClientSourceSuccess = { data?: ClientSource[] };
export type GetTopicsAnalysis = { locationId?: string };
export type GetTopicsAnalysisSuccess = { data: TopicAnalysis[] };

export const getInsightsDataAction = createAction(InsightsActionTypes.GetInsightsData, props<GetInsightsData>());
export const getInsightsDataSuccess = createAction(InsightsActionTypes.GetInsightsDataSuccess, props<GetInsightsDataSuccess>());
export const getHouseMatchScoreAction = createAction(InsightsActionTypes.GetHouseMatchScore, props<GetHouseMatchScore>());
export const getHouseMatchScoreSuccess = createAction(InsightsActionTypes.GetHouseMatchScoreSuccess, props<GetHouseMatchScoreSuccess>());
export const getReviewsYearOverviewAction = createAction(InsightsActionTypes.GetReviewsYearOverview, props<GetReviewsYearOverview>());
export const getReviewsYearOverviewSuccess = createAction(
    InsightsActionTypes.GetReviewsYearOverviewSuccess,
    props<GetReviewsYearOverviewSuccess>()
);
export const getClientSourceAction = createAction(InsightsActionTypes.GetClientSource, props<GetClientSource>());
export const getClientSourceSuccess = createAction(InsightsActionTypes.GetClientSourceSuccess, props<GetClientSourceSuccess>());

export const getReviewScoresAction = createAction(InsightsActionTypes.GetReviewScores);
export const getReviewScoresSuccess = createAction(InsightsActionTypes.GetReviewScoresSuccess, props<GetReviewScoresSuccess>());

export const getTopicsAnalysisAction = createAction(InsightsActionTypes.GetTopicsAnalysis, props<GetTopicsAnalysis>());
export const getTopicsAnalysisSuccess = createAction(InsightsActionTypes.GetTopicsAnalysisSuccess, props<GetTopicsAnalysisSuccess>());

export const getTopicsAnalysisFailed = createAction(InsightsActionTypes.GetTopicsAnalysisFailed);
