<app-icon
    appTooltip
    icon="verified"
    [appearance]="iconAppearance.Filled"
    [context]="uiContext.Success"
    [hidePointer]="true"
    [template]="verifiedTooltip"
></app-icon>

<ng-template #verifiedTooltip>
    <div class="__verified-tooltip">
        <p class="u-font-size-small">{{ 'UI.COMPANY_PAGE.VERIFIED_AGENT' | translate }}</p>
    </div>
</ng-template>
