<div
    *ngIf="widgetData"
    class="__main u-padding u-display-block"
    [class.wis-default]="true"
    [class.wis-shadow]="shadow"
    [class.wis-transparant]="!background"
>
    <div class="u-flex">
        <div *ngIf="hasLogo"
             class="__image-container u-padding-bottom u-padding-bottom-none u-margin-right u-padding-right">
            <img [src]="widgetData.logo" alt=""/>
        </div>
        <div class="u-flex u-flex-align-items-center">
            <ng-container *ngIf="widgetData?.score; else noReviews">
                <app-arc-score [data]="widgetData.arcScore" [value]="widgetData.score"></app-arc-score>
                <div class="u-margin-left-double">
                    <h5>{{ widgetData.scoreScale | i18nKey: i18nKeyType.ReviewScore | ftranslate: language | async }}</h5>
                    <p class="u-color-muted u-font-size-small">
                        {{ 'UI.WIDGET_INFLUENCER_SCORE.SCORE.TOTAL' | ftranslate: language:{total: widgetData.overall.total} | async }}
                    </p>
                </div>
            </ng-container>
        </div>
    </div>
    <app-svg-illustration [svg]="logo" class="__logo-smooved"></app-svg-illustration>
</div>

<ng-template #noReviews>
    <app-arc-score [data]="widgetData?.arcScore" value="-"></app-arc-score>
    <div class="u-margin-left-double">
        <h5>{{ 'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.TITLE' | ftranslate: language | async }}</h5>
        <p class="u-color-muted u-font-size-small">
            {{ 'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.DESCRIPTION' | ftranslate: language | async }}
        </p>
    </div>
</ng-template>
