import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-late-moving-date-read-more-modal',
    templateUrl: './late-moving-date-read-more.modal.html',
})
export class LateMovingDateReadMoreModalComponent {
    @Input() public createdOn: Date;
    @Input() public movingDate: Date;
    @Input() public daysBetweenCreatedOnAndMovingDate: number;
}
