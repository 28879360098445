<div class="u-padding-x-axis-half">
    <app-menu>
        <app-menu-item
            *ngFor="let item of items; trackBy: trackByFeatureScope"
            [goTo]="item.goTo"
            [class.u-display-none]="item.hide && !(featureScopeSandbox.hasAccess(item.hide) | async)"
            (click)="onMenuClick()"
            appearance="button"
            size="sm"
        >
            <div class="u-flex-row u-flex-align-items-center">
                <app-checked
                    [checked]="(featureGranted$(item.featureScope) | async) ? checked.Ok : checked.Nok"
                    class="u-margin-right u-flex"
                ></app-checked>
                <span>{{ item.labelResource | translate }}</span>
            </div>
        </app-menu-item>
    </app-menu>
</div>
