<app-menu>
    <app-menu-item
        [goTo]="['..', 'management', 'team']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Users"
        data-testid="management-menu-team-button"
    >
        {{ 'ANALYTICS.MENU.TEAM' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'management', 'office']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Office"
        *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"
    >
        {{ 'ANALYTICS.MENU.OFFICES' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'management', 'modules']"
        appearance="button"
        (click)="forwardMenu()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Toggle"
    >
        {{ 'ANALYTICS.MENU.MODULES' | translate }}
    </app-menu-item>
</app-menu>
<app-menu [label]="'INVOICING.MENU.TITLE' | translate" *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async">
    <app-menu-item
        [goTo]="['..', 'management', 'financial-report', 'quarter-report']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.QuarterReport"
    >
        {{ 'INVOICING.MENU.QUARTER_REPORT' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'management', 'financial-report', 'invoices']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Euro"
    >
        {{ 'INVOICING.MENU.INVOICES' | translate }}
    </app-menu-item>
</app-menu>
