import { Directive } from '@angular/core';
import { TodoState } from './todo-state.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[todoStateChecked]',
})
export class TodoStateCheckedDirective {
    public readonly state = TodoState.Checked;
}
