import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@app/move/state/move.utils';
import { RealEstateAgentContactLogService } from '@app/real-estate-agent/services/real-estate-agent-contact-log.service';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-todo-leaver-suppliers-received',
    templateUrl: './todo-leaver-suppliers-received.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverSuppliersReceivedComponent implements OnChanges {
    @Input() public move: Move;

    public readonly i18nKeys = appI18nKeyTypes;
    public readonly moverRole = MoverRole;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;

    private leaver: Move;

    constructor(
        private readonly realEstateAgentContactLogService: RealEstateAgentContactLogService,
        private readonly realEstateAgentModalSandbox: RealEstateAgentModalsSandbox
    ) {}

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            const { leaver } = MoveUtils.getMovers(this.move);
            this.leaver = leaver;
        }
    }

    public resendInvite(): void {
        this.realEstateAgentContactLogService.resendLeaverCollectionInvite(this.move).subscribe();
    }

    public updateSuppliers(): void {
        this.realEstateAgentModalSandbox.openUpdateLeaverSuppliersModal(this.move);
    }
}
