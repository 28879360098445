<app-card-container>
    <div class="u-container u-margin-0-auto">
        <app-loading-container [loading]="appUiSandbox.moveLoading$ | async">
            <app-card class="u-flex-column u-flex-align-items-start">
                <h3>{{ 'TELECOM.TELECOM_BUNDLE.TITLE' | translate }}</h3>
                <p class="u-color-muted u-margin-bottom-triple">{{ 'STEP_INDICATION' | translate: { start: 1, end: 2 } }}</p>
                <form [formGroup]="telecomBundleService.form" class="u-w100p" (ngSubmit)="telecomBundleService.onSubmit($event)">
                    <app-telecom-bundle-selection formControlName="bundle"></app-telecom-bundle-selection>
                    <app-previous-submit [showPrevious]="false"></app-previous-submit>
                </form>
            </app-card>
        </app-loading-container>
    </div>
</app-card-container>
