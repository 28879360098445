import { Component, Input } from '@angular/core';
import { RxjsComponent } from '@smooved/core';

@Component({
    template: '',
})
export class BaseDashboardCardComponent extends RxjsComponent {
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() hasBorderBottom: boolean;
}
