import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '../../../button';

@Component({
    selector: '[smvd-ui-text-limit]',
    templateUrl: './text-limit.component.html',
})
export class TextLimitComponent implements OnChanges {
    @Input() chars = 0;
    @Input() value: string;

    public maxChars: number;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;

    public ngOnChanges({ chars }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(chars)) this.maxChars = this.chars;
    }

    public expand(): void {
        this.maxChars = 0;
    }
}
