<app-todo [label]="'RENTAL_INSPECTION.TODO.DOCUMENT.CTA' | translate" [checked]="checked" [disabled]="disabled"
          [readonly]="readonly">
    <app-todo-template *todoStateUnchecked>
        <button app-button context="secondary" (click)="openRentalInspectionModal()">{{ 'UPLOAD' | translate }}</button>
    </app-todo-template>

    <app-todo-template *todoStateChecked>
        <div class="u-color-muted u-font-size-small">
            {{ lastModified | formatDate: dateTimeFormat }}
        </div>
        <app-rental-inspection-asset
            class="u-margin-top u-display-block u-font-size-default u-color-text"
            *ngIf="order?.move?.rentalInspectionAsset"
            (deleted)="deleted.emit($event)"
            [move]="order.move"
        ></app-rental-inspection-asset>
    </app-todo-template>
</app-todo>
