import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewBucketRequest } from '../../../analytics';
import { NpsReviewBucket } from '../../enums/nps-review-buckets.enum';
import { ReviewsSandbox } from '../../sandboxes/reviews.sandbox';
import { NpsReviewsFilterService } from '../../services/nps-reviews-filter.service';
import { ReviewBucketsType } from '../reviews-buckets/reviews-buckets.enum';

@Component({
    selector: 'smvd-ui-filter-reviews-buckets',
    template: `
        <h6 class="u-color-muted u-margin-bottom-half">{{ 'REVIEWS.BUCKETS.TITLE' | translate }}</h6>
        <app-reviews-buckets
            class="u-display-block"
            [data]="data$ | async"
            [type]="reviewBucketsType.Default"
            (filterChange)="onFilterChange($event)"
        ></app-reviews-buckets>
    `,
    styles: [':host { display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterReviewBucketsComponent {
    public readonly data$: Observable<ReviewBucketRequest> = this.reviewsSandbox.getReviewBuckets();
    public readonly reviewBucketsType = ReviewBucketsType;

    constructor(private readonly filterService: NpsReviewsFilterService, private readonly reviewsSandbox: ReviewsSandbox) {}

    public onFilterChange(filter: NpsReviewBucket[]): void {
        this.filterService.filterByBucket(filter);
    }
}
