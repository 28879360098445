import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@smooved/core';

@Pipe({
    name: 'timeInterval',
})
export class TimeIntervalPipe implements PipeTransform {
    transform(date: Date, compare?: Date): string {
        const { value, unit } = DateUtils.dynamicTimeInterval(date, compare);
        return `${value}${unit}`;
    }
}
