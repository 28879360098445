import { Language, ObjectUtils, StringUtils } from '@smooved/core';
import { Translations } from '../interfaces/translations.interface';

export class SsrTranslator {
    public [Language.EN]: any;
    public [Language.NL]: any;
    public [Language.FR]: any;

    constructor(translations: Translations<{ [key: string]: any }>) {
        this.nl = translations.nl;
        this.fr = translations.fr;
        this.en = translations.en;
    }

    public translate(language: Language, path: string, interpolateParams?: object): string {
        const translation = ObjectUtils.get(this[language], path) as string;
        return StringUtils.parse(translation, interpolateParams);
    }

    public translateRoute(language: Language, segment: string): string {
        return this.translate(language, `LOCALIZED_ROUTES.${segment}`);
    }

    public translateRoutesSegment(segment: string, currentLanguage, nextLanguage): string {
        if (!currentLanguage) return segment;
        const currentI18n: any = this[currentLanguage];
        const nextI18n: any = this[nextLanguage];

        const key = Object.entries(currentI18n.LOCALIZED_ROUTES).find(([_, value]) => value === segment)?.[0];
        return key ? nextI18n.LOCALIZED_ROUTES[key] : segment;
    }
}
