import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { telecomBundleTranslationsLabels } from '@app/partner/components/telecom-details/telecom-details.constants';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { Move } from '@app/move/interfaces/move';
import { TelecomBundle } from '@app/wizard/telecom/telecom.interfaces';
import { UiHeaderVariant } from '@smooved/ui';

@Component({
    selector: 'app-telecom-details',
    template: `
        <div *ngIf="isTransfer; else newOffer">
            <ui-info [label]="'MOVE.CLIENT_NUMBER' | translate" [variant]="uiHeaderVariant.H6">
                <app-copy-to-clipboard>{{ move?.telecomTransfer?.clientNumber }}</app-copy-to-clipboard>
            </ui-info>

            <ui-info [label]="'MOVE.FIXED_LINE' | translate" [variant]="uiHeaderVariant.H6">
                <app-copy-to-clipboard>{{ move?.telecomTransfer?.fixedLine }}</app-copy-to-clipboard>
            </ui-info>

            <app-telecom-installation-date-output
                *ngIf="!!move.telecomOffer?.telecomInstallation"
                class="u-margin-bottom-double u-display-block"
                [move]="move"
            ></app-telecom-installation-date-output>
        </div>

        <ng-template #newOffer>
            <div class="u-margin-bottom-double" *ngIf="!!move.telecomOffer?.telecomOfferSelection">
                <p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'PRODUCT' | translate }}</p>
                <p *ngIf="move.telecomOffer.telecomOfferSelection?.productNameLabels">
                    {{ move.telecomOffer.telecomOfferSelection?.productNameLabels | translatedLabel | async }}
                </p>
            </div>

            <app-telecom-installation-date-output
                *ngIf="!!move.telecomOffer?.telecomInstallation"
                class="u-margin-bottom-double u-display-block"
                [move]="move"
            ></app-telecom-installation-date-output>

            <app-telecom-fixed-line-output
                *ngIf="!!move.telecomOffer?.telecomBundle?.fixedLine && !!move.telecomOffer?.fixedLine"
                [move]="move"
                class="u-margin-bottom-double u-display-block"
            ></app-telecom-fixed-line-output>

            <app-telecom-mobile-bundle-output
                class="u-margin-bottom-double u-display-block"
                *ngIf="!!move.telecomOffer?.telecomBundle?.mobilePhone && !!move.telecomOffer?.mobileBundle?.length"
                [move]="move"
            ></app-telecom-mobile-bundle-output>

            <ui-info [label]="'INTERESTS' | translate" [variant]="uiHeaderVariant.H6">
                <ul class="u-list-style-initial">
                    <li *ngFor="let interest of getInterests()">{{ interest | translate }}</li>
                </ul>
            </ui-info>

            <ui-info *ngIf="!!move.telecomPaymentType" [label]="'MOVE.PAYMENT_TYPE' | translate" [variant]="uiHeaderVariant.H6">
                <p>{{ 'MOVE.PAYMENT_TYPE.' + move.telecomPaymentType?.toUpperCase() | translate }}</p>
            </ui-info>

            <ui-info
                *ngIf="move.telecomPaymentType === paymentType.Domiciliation && !!move.user?.accountNumber"
                [variant]="uiHeaderVariant.H6"
                [label]="'MOVE.USER.ACCOUNT_NUMBER.SHORT' | translate"
            >
                <app-copy-to-clipboard>{{ move.user.accountNumber }}</app-copy-to-clipboard>
            </ui-info>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomDetailsComponent {
    @Input() public move: Move;
    @Input() public isTransfer: boolean;

    public paymentType = PaymentType;
    public uiHeaderVariant = UiHeaderVariant;

    public getInterests(): string[] {
        const { telecomBundle } = this.move?.telecomOffer;
        if (!telecomBundle) return null;
        return Object.entries(telecomBundle)
            .filter(([key, value]) => !!value)
            .map(([key]) => {
                switch (key) {
                    case TelecomBundle.Internet:
                        return telecomBundleTranslationsLabels.internet;
                    case TelecomBundle.DigitalTelevision:
                        return telecomBundleTranslationsLabels.television;
                    case TelecomBundle.MobilePhone:
                        return telecomBundleTranslationsLabels.mobilePhone;
                    case TelecomBundle.FixedLine:
                        return telecomBundleTranslationsLabels.fixedLine;
                }
            });
    }
}
