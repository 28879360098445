import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveModalSandbox } from '@app/move/sandboxes/move-modal.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ButtonAppearance, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-contact-logs-multi-channel',
    template: `
        <h6 class="u-margin-bottom-half">{{ 'MOVE.CONTACT_LOGS.MULTI_CHANNEL.TITLE' | translate }}</h6>
        <div class="u-flex-row u-flex-align-items-center">
            <button
                app-button
                [context]="context.Secondary"
                [appearance]="appearance.Stroked"
                (click)="openCustomEmail()"
                data-testid="write-custom-message-button"
            >
                {{ 'ADMIN.DASHBOARD.DETAIL.CUSTOM_MESSAGE.LABEL' | translate }}
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactLogsMultiChannelComponent {
    @Input() public move: Move;

    public readonly context = UiContext;
    public readonly appearance = ButtonAppearance;

    constructor(
        private readonly moveModalSandbox: MoveModalSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public openCustomEmail(): void {
        const input = { move: this.move };
        this.moveModalSandbox.showCustomMessage(input, this.handleCustomMessageClosed);
    }

    private handleCustomMessageClosed = (result: boolean): void => {
        if (!result) return;
        this.moveSandbox.updatedMoveTrigger.next();
    };
}
