import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { vat } from '@app/invoicing/components/invoice/invoice.constants';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { InvoiceVersion } from '@app/invoicing/enums/invoice-version.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { UiContext, UiIcon } from '@smooved/ui';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-invoice-total-amount',
    templateUrl: './total-amount.component.html',
    styleUrls: ['./total-amount.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalAmountComponent {
    @Input() public invoice: Invoice;

    public readonly uiContext = UiContext;
    public readonly featureScope = FeatureScope;
    public readonly version = InvoiceVersion;
    public readonly uiIcon = UiIcon;
    public readonly invoiceStatus = InvoiceStatus;

    public includingVAT = (value: number): number => value * vat;
}
