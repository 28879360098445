<app-alert *ngIf="meterReadingsTakeoverDone" [context]="uiContext.Info">
    <span>{{ meterReadingsTakeoverDone }}</span>
    <a
        *ngIf="leaver?.moveStates?.energyStopLeaverSent"
        class="u-padding-none u-margin-left u-h-auto"
        app-button
        [appearance]="buttonAppearance.Link"
        [size]="buttonSize.Sm"
        (click)="downloadEDS()"
        >{{ 'DOWNLOAD' | translate }}</a
    >
</app-alert>
