import { Pipe, PipeTransform } from '@angular/core';
import { FormatDatePipe } from './format-date.pipe';

type dateType = { lastModifiedOn?: string | Date; createdOn?: string | Date };

@Pipe({
    name: 'lastModifiedOn',
})
export class LastModifiedOnPipe implements PipeTransform {
    constructor(private readonly formatDatePipe: FormatDatePipe) {}

    public transform({ lastModifiedOn, createdOn }: dateType, format?: string, emptyAs?: string): string {
        return this.formatDatePipe.transform(lastModifiedOn || createdOn, format, emptyAs);
    }
}
