import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DbUtils, RealEstateAgentUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { delay, tap } from 'rxjs/operators';
import { baseInputWidth, GroupedSearchableDropdownInputOptions, SearchableDropdownInputOption } from '../../../form';
import { NpsReviewsFilterService } from '../../services';
import { RealEstateAgentsGroupedByLocationDto } from '@app/real-estate-agent/interfaces/real-estate-agents-grouped-by-location';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
    selector: 'smvd-ui-reviews-by-real-estate-agent',
    templateUrl: './reviews-by-real-estate-agent.component.html',
})
export class ReviewsByRealEstateAgentComponent extends RxjsComponent implements OnInit, OnChanges {
    public readonly control = this.formBuilder.control(null);

    public agentOptions: GroupedSearchableDropdownInputOptions[] = [];
    public agentOptionsOthers: SearchableDropdownInputOption[] = [];

    @Input()
    public width = baseInputWidth;

    @Input()
    public realEstateGroupId: string;

    @Input()
    public locationId: string;

    @Output()
    public selected = new EventEmitter<string>();

    constructor(
        public readonly filterService: NpsReviewsFilterService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateGroupService: RealEstateGroupService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly cdr: ChangeDetectorRef
    ) {
        super();
    }

    public async ngOnInit() {
        await firstValueFrom(this.setRealEstateAgentOptions(this.locationId));
        const { realEstateAgent } = this.activatedRoute.snapshot.queryParams;
        if (realEstateAgent) {
            const existingREA = this.agentOptions.some((group) => group.groupedOptions.find((rea) => rea.id === realEstateAgent));
            const existingOtherREA = this.agentOptionsOthers.some((otherRea) => otherRea.id === realEstateAgent);
            if (existingREA || existingOtherREA) {
                this.filterService.filterByRealEstateAgent(realEstateAgent);
                this.control.setValue(realEstateAgent);
                this.cdr.detectChanges();
            } else {
                this.updateRealEstateAgentParam(null);
            }
        }
    }

    public async ngOnChanges({ locationId }: SimpleChanges) {
        if (SimpleChangesUtils.hasChanged(locationId)) {
            await firstValueFrom(this.setRealEstateAgentOptions(locationId.currentValue));
        }
    }

    public realEstateAgentSelected($event: SearchableDropdownInputOption): void {
        this.updateRealEstateAgentParam($event?.value ?? null);
        this.filterService.filterByRealEstateAgent($event?.value ?? null);
    }

    private setRealEstateAgentOptions(locationId?: string): Observable<RealEstateAgentsGroupedByLocationDto> {
        const selectedValue = this.control.value;
        return this.realEstateGroupService
            .getRealEstateAgentsGroupedByLocation(DbUtils.getStringId(this.realEstateGroupId), locationId)
            .pipe(
                tap((agentsGroupedByLocations: RealEstateAgentsGroupedByLocationDto) => {
                    this.agentOptionsOthers = agentsGroupedByLocations.otherRealEstateAgents.map(
                        RealEstateAgentUtils.mapRealEstateAgentToOption
                    );
                    this.agentOptions = agentsGroupedByLocations.groupedByLocationRealEstateAgents.map(
                        RealEstateAgentUtils.mapGroupedRealEstateAgentsToGroupedOptions
                    );
                }),
                delay(0),
                tap(() => {
                    if (selectedValue) this.control.setValue(selectedValue);
                    this.cdr.detectChanges();
                })
            );
    }

    private updateRealEstateAgentParam(realEstateAgentId: string): void {
        const queryParams = this.activatedRoute.snapshot.queryParams;

        const newUrl = this.router
            .createUrlTree([], {
                relativeTo: this.activatedRoute,
                queryParams: { ...queryParams, realEstateAgent: realEstateAgentId },
                queryParamsHandling: 'merge',
            })
            .toString();

        history.pushState(null, '', newUrl);
    }
}
