export enum ReviewFilterBy {
    Group = 'group',
    Source = 'source',
    Score = 'scoreFilter',
    Segment = 'segments',
    Id = 'ids',
    Unanswered = 'unanswered',
    Bucket = 'bucket',
    ExtendWithOtherSources = 'extendWithOtherSources',
    Location = 'location',
    RealEstateAgentId = 'realEstateAgentId',
    IncludeAllExternal = 'includeAllExternal',
    IntervalCounts = 'intervalCounts',
    TopicCategory = 'topicCategory',
    TopicCategorySentiment = 'topicCategorySentiment',
    DurationFilter = 'durationFilter',
}
