import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { GetInsurancesSuggestionsRequest } from '@app/iots/interfaces/get-insurances-suggestions-request';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { InsurancesSandbox } from '@app/iots/sandboxes/insurances.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Role } from '@smooved/core';
import { of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-insurances-suggestions',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center u-no-padding-right u-no-padding-left" [ngSwitch]="loaded">
            <app-insurances-suggestions-loading
                *ngSwitchCase="false"
                [loading]="!suggestions?.length"
                (next)="loaded = true"
            ></app-insurances-suggestions-loading>

            <app-insurances-suggestions-content
                *ngSwitchCase="true"
                [suggestions]="suggestions"
                class="u-display-block u-w100p"
            ></app-insurances-suggestions-content>
        </app-card-container>
    `,
    styles: [],
})
export class InsurancesSuggestionsComponent implements OnInit {
    @Output() public select: EventEmitter<InsurancesSuggestion> = new EventEmitter<InsurancesSuggestion>();

    public suggestions: InsurancesSuggestion[];

    public loaded = false;

    constructor(
        private insurancesSandbox: InsurancesSandbox,
        private location: Location,
        private moveSandbox: MoveSandbox,
        private authenticationSandbox: AuthenticationSandbox
    ) {}

    public ngOnInit(): void {
        zip(this.moveSandbox.moveOnce$, this.authenticationSandbox.isRealEstateAgentOnce$)
            .pipe(
                switchMap(([move, isRealEstateAgent]) => {
                    const { user, meta, createdByRole } = move;
                    if (!user?.dateOfBirth || !user.movingAddress || !meta?.homeDescription) {
                        return of(null);
                    }
                    const payload: GetInsurancesSuggestionsRequest = {
                        dateOfBirth: user?.dateOfBirth,
                        movingAddress: user?.movingAddress,
                        homeDescription: meta?.homeDescription,
                        homeDescriptionApartmentLevel: meta?.homeDescriptionApartmentLevel,
                        roomDivision: meta?.roomDivision,
                        createdByRole: createdByRole || (isRealEstateAgent ? Role.RealEstateAgent : Role.Guest),
                    };
                    return this.insurancesSandbox.getSuggestions(payload);
                })
            )
            .subscribe(
                (suggestions) => {
                    this.suggestions = suggestions;
                },
                (error) => {
                    this.location.back();
                }
            );
    }
}
