import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { checkboxRequired } from '@app/form/validators/checkbox-required.validator';
import { Move } from '@app/move/interfaces/move';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { SepaModalComponent } from '@app/wizard/legal/modals/sepa-modal/sepa-modal.component';
import { CheckInput } from '@smooved/ui';
import { filter, take } from 'rxjs/operators';
import { MoveSandbox } from '../../sandboxes/move.sandbox';

@Component({
    selector: 'app-confirm-energy-container',
    template: `
        <div class="u-flex-row u-flex-justify-content-center u-padding-top-triple u-padding-right u-padding-bottom-triple u-padding-left">
            <div class="u-container" *ngIf="!loading">
                <app-card *ngIf="!!move; else noAccess" [ngSwitch]="!!move?.energyConfirmed">
                    <form
                        *ngSwitchCase="false"
                        [formGroup]="form"
                        class="u-w100p u-flex-column u-flex-align-items-start"
                        (ngSubmit)="onSubmit($event)"
                    >
                        <h3>{{ 'CONFIRMATION' | translate }}</h3>
                        <p class="u-color-muted u-margin-bottom-triple">{{ 'STEP_LAST' | translate }}</p>
                        <div
                            class="u-flex-column u-flex-align-items-end u-w100p-up-to-and-including-phone-landscape u-bordered u-padding-top u-padding-right-double u-padding-bottom u-padding-left u-margin-bottom-double"
                            *ngIf="!!(moveSandbox.energySuggestionSelection$ | async)"
                        >
                            <a
                                class="u-link-muted u-font-size-small u-margin-bottom-half"
                                *ngIf="!(moveSandbox.landAgent$ | async)"
                                routerLink="/energy/energy-suggestions"
                                >{{ 'EDIT_CHOICE' | translate }}</a
                            >

                            <app-suggestion-selection
                                [showPaymentType]="true"
                                type="energy"
                                [paymentType]="moveSandbox.energyPaymentType$ | async"
                                [suggestion]="moveSandbox.energySuggestionSelection$ | async"
                            ></app-suggestion-selection>
                        </div>
                        <app-gdpr [formControlName]="'legal'" class="u-display-block u-margin-bottom"></app-gdpr>

                        <app-check-input
                            [formControlName]="'sepa'"
                            [width]="'auto'"
                            *ngIf="sepaRequired"
                            class="u-display-block u-margin-bottom-double"
                            [custom]="false"
                            [option]="sepaOption"
                        >
                            <span
                                >{{ 'SEPA' | translate }}
                                <a (click)="showMore($event)" class="u-link u-color-muted">{{ 'COMMON.MORE_INFO' | translate }}</a></span
                            >
                        </app-check-input>

                        <app-button type="submit" class="u-w100p-up-to-and-including-phone-landscape">{{
                            'SUBMIT' | translate
                        }}</app-button>
                    </form>
                    <div *ngSwitchCase="true">
                        <p class="u-margin-bottom">{{ 'CONFIRM_ENERGY.ENERGY_ORDERED' | translate }}</p>
                        <app-button class="u-w100p-up-to-and-including-phone-landscape" [routerLink]="'/'">{{
                            'TO_DASHBOARD' | translate
                        }}</app-button>
                    </div>
                </app-card>

                <ng-template #noAccess>
                    <div class="u-flex-column u-flex-align-items-center">
                        <img class="__image u-margin-bottom-double" src="assets/images/smooved-hero.png" />
                        <div class="u-container-small u-flex-column u-flex-align-items-center">
                            <h3 class="u-margin-bottom u-text-align-center">
                                {{ 'ENERGY_CONFIRM.ACCESS_TOKEN_EXPIRED.TITLE' | translate }}
                            </h3>
                            <p
                                class="u-font-size-medium u-text-align-center"
                                [innerHTML]="'ENERGY_CONFIRM.ACCESS_TOKEN_EXPIRED.TEXT' | translate"
                            ></p>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    styleUrls: ['./confirm-energy.container.scss'],
})
export class ConfirmEnergyContainer implements OnInit {
    public loading = true;

    public form: UntypedFormGroup = this.formBuilder.group({
        legal: [null, checkboxRequired],
        sepa: [null],
    });

    @ViewChild('sepaTemplate') public sepaTemplate: TemplateRef<any>;

    public sepaOption: CheckInput<boolean> = {
        id: 'sepa',
        name: 'sepa',
        value: true,
    };

    public sepaRequired: boolean;

    public move: Move;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authenticationSandbox: AuthenticationSandbox,
        private dialog: MatDialog,
        public moveSandbox: MoveSandbox,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.move$
            .pipe(
                filter((x) => !!x?._id),
                take(1)
            )
            .subscribe((move) => {
                this.validateEnergySuggestionExistance(move.energyOffer?.energyOfferSelection);
                this.move = move;
                const sepaRequired: boolean = move.energyPaymentType && move.energyPaymentType === PaymentType.Domiciliation;
                this.sepaRequired = sepaRequired;
                if (sepaRequired) {
                    this.sepaFormControl().setValidators(checkboxRequired);
                }
            });

        // fix for ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            const { accessToken, id } = this.route.snapshot.queryParams;
            this.authenticationSandbox.isGuest$.pipe(take(1)).subscribe((isGuest) => {
                if (isGuest) {
                    this.loading = false;
                    // send confirmation email again when guest
                    this.moveSandbox.sendConfirmationEmail(id, accessToken).subscribe();
                } else {
                    this.moveSandbox.getByAccessTokenAndId(null, id, true, () => {
                        this.loading = false;
                    });
                }
            });
        }, 0);
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            const { accessToken, id } = this.route.snapshot.queryParams;
            this.moveSandbox.patchProperty('legal.sepa', true, true, () => {
                this.moveSandbox.confirmEnergy(accessToken, id, () => {
                    this.authenticationSandbox.isRealEstateAgentOrAdminOnce$.subscribe((isRealEstateAgentOrAdmin) => {
                        const extras: NavigationExtras = {};
                        let path: string[];
                        if (this.move.createdByFlow?.eots) {
                            if (this.move.interested?.telecom && isRealEstateAgentOrAdmin) {
                                path = ['/', Navigation.OTS, Navigation.Tots, Navigation.TelecomInterested];
                            } else if (this.move.user?.landAgent) {
                                path = ['/', Navigation.OTS, Navigation.EOTS, Navigation.MeterReadings];
                                extras.queryParams = {
                                    eots: true,
                                };
                            } else {
                                path = ['/', Navigation.Moves, Navigation.ConfirmEnergy, Navigation.EnergySuccess];
                            }
                        } else {
                            path = ['/', Navigation.Moves, Navigation.ConfirmEnergy, Navigation.Success];
                        }
                        void this.router.navigate(path, extras);
                    });
                });
            });
        }
    }

    public showMore(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(SepaModalComponent);
    }

    private sepaFormControl(): AbstractControl {
        return this.form && this.form.get('sepa');
    }

    private validateEnergySuggestionExistance = (energySuggestion: EnergySuggestion): void => {
        if (energySuggestion) return;
        void this.router.navigate(['/']); //if doesn't exists, just return to homepage so user can start new process
    };
}
