import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { copyToClipboardSuccessLabel } from '@app/real-estate-agent/components/other-simulation-tools/other-simulation-tools.constants';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupState } from '@app/real-estate-group/state/real-estate-group.reducer';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { map } from 'rxjs/operators';
import { houseMatchSurveyUri, writeReviewLocalizedRouteLabel } from './survey-invite.constants';

@Component({
    selector: 'app-survey-invite-modal',
    templateUrl: 'survey-invite.modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SurveyInviteModal implements OnInit {
    public readonly i18nKeys = appI18nKeyTypes;
    public surveyUri: string;

    constructor(
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly translateService: TranslateService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly clipboard: Clipboard,
        private readonly cdr: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.realEstateGroupSandbox.realEstateGroupOnce$.pipe(map(this.mapUrl)).subscribe((url) => {
            this.surveyUri = url;
            this.cdr.markForCheck();
        });
    }

    public copyLink(): void {
        this.clipboard.copy(this.surveyUri);
        this.notificationSandbox.success(copyToClipboardSuccessLabel);
    }

    private mapUrl = ({ externalInfluencerUrl: slug }: RealEstateGroupState): string =>
        StringUtils.parseUri(houseMatchSurveyUri, {
            lang: this.translateService.currentLang,
            slug,
            review: this.translateService.instant(writeReviewLocalizedRouteLabel) as string,
        });
}
