import { Injectable } from '@angular/core';
import { defaultLevel } from '@app/analytics/constants/analytics.constants';
import { MoveCountByOtsConfirmationClusterLevel } from '@app/analytics/interfaces/move-count-by-ots-confirmation-cluster-level';
import { last3Q } from '@app/real-estate-agent/components/confirmations-long-period-chart/confirmatons-long-period-chart.constants';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { Interval } from '@app/shared/enums/interval.enum';
import { quarterYearIntervalFormat } from '@smooved/core';
import { ChartItem, StackedBarChartUtils } from '@smooved/ui';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapLevelToLabel, mapLevelToSubLabel } from './confirmations-cluster-levels-chart.constants';

@Injectable()
export class ConfirmationsClusterLevelsChartService {
    constructor(private smoovedAnalyticsSandbox: SmoovedAnalyticsSandbox) {}

    public getData(): Observable<ChartItem[]> {
        return this.smoovedAnalyticsSandbox.getOtsConfirmationClusterLevels(Interval.Quarter, defaultLevel, last3Q).pipe(this.mapToChart);
    }

    private mapToChart = map((items: MoveCountByOtsConfirmationClusterLevel[]): ChartItem[] => {
        const data = this.prepareData(items);
        return this.sortItems(
            this.mapLabels(
                StackedBarChartUtils.prepareData(
                    data,
                    (item: any) => item.interval,
                    (item: any) => item.level
                )
            )
        );
    });

    private mapLabels(items: ChartItem[]): ChartItem[] {
        return items.map((item) => {
            return {
                ...item,
                stacks: {
                    ...Object.entries(item.stacks).reduce((stack, [key, item]) => {
                        const label = mapLevelToLabel[item.label] as string;
                        const sub = mapLevelToSubLabel[item.label] as string;
                        return { ...stack, [key]: { ...item, label, sub } };
                    }, {}),
                },
            };
        });
    }

    private sortItems(items: ChartItem[]): ChartItem[] {
        return items.sort((first: ChartItem, second: ChartItem) =>
            moment(first.label, quarterYearIntervalFormat).diff(moment(second.label, quarterYearIntervalFormat))
        );
    }

    private prepareData(items: MoveCountByOtsConfirmationClusterLevel[]): MoveCountByOtsConfirmationClusterLevel[] {
        return items.map((item) => ({
            ...item,
            level: this.getLevelGroup(item.level),
        }));
    }

    private getLevelGroup(level: number): number {
        const levelGroups = [{ min: 0, max: 0 }, { min: 1, max: 2 }, { min: 3 }];
        return levelGroups.find((group) => group.min <= level && (group.max >= level || group.max === undefined))?.min ?? 0;
    }
}
