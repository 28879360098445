import { Component, Input } from '@angular/core';
import { ClosableModalComponent } from './closable-modal.component';

@Component({
    selector: 'app-closable-mobile-modal',
    templateUrl: './closable-mobile-modal.component.html',
    styleUrls: ['./closable-mobile-modal.component.scss'],
})
export class ClosableMobileModalComponent extends ClosableModalComponent {
    @Input() public useBackNavigation = true;
}
