<ng-container
    *ngIf="{
        tabletPortraitUp: uiSandbox.tabletPortraitUp$ | async,
        secondaryCollapsed: secondaryNavigationCollapsed$ | async,
        secondaryCollapsedOpen: secondaryNavigationCollapsedOpen$ | async,
        secondaryCollapsing: secondaryNavigationCollapsing$ | async,
        showSecondaryNavigation: showSecondaryNavigation$ | async,
        showTertiaryNavigation: showTertiaryNavigation$ | async,
        showSideBar: showSideBar$ | async
    } as vm"
>
    <app-header-container [class.__body--scrolled]="scrolled"></app-header-container>
    <app-sidebar class="__menu u-border-right" *ngIf="vm.tabletPortraitUp" [hidden]="!vm.showSideBar">
        <div class="__menu-container">
            <app-menu-item appearance="button" goTo="real-estate-agent/start" [size]="menuItemSize.Small">
                <app-svg-illustration [svg]="illustrationEnum.Dashboard"></app-svg-illustration>
                <span class="__menu-description-label u-font-size-xs u-text-align-center u-margin-top-half">{{
                    'HEADER.MENU.REAL_ESTATE_AGENT.START' | translate
                }}</span>
            </app-menu-item>
        </div>
        <div class="__menu-container" *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async">
            <app-menu-item appearance="button" goTo="real-estate-agent/dashboard" [size]="menuItemSize.Small">
                <app-svg-illustration [svg]="illustrationEnum.Transactions"></app-svg-illustration>
                <span class="__menu-description-label u-font-size-xs u-text-align-center u-margin-top-half">{{
                    'HEADER.MENU.REAL_ESTATE_AGENT.TRANSACTIONS' | translate
                }}</span></app-menu-item
            >
        </div>
        <div class="__menu-container">
            <app-menu-item
                appearance="button"
                goTo="real-estate-agent/reviews"
                [size]="menuItemSize.Small"
                [badge]="realEstateAgentSandbox.unreadReviewBadge$ | async"
                [badgeAlignment]="verticalAlignment.Top"
            >
                <app-svg-illustration class="u-flex-justify-content-center" [svg]="illustrationEnum.Review"></app-svg-illustration>
                <span class="__menu-description-label u-font-size-xs u-text-align-center u-margin-top-half">{{
                    'HEADER.MENU.REAL_ESTATE_AGENT.REVIEWS' | translate
                }}</span></app-menu-item
            >
        </div>
        <div class="__menu-container" *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async">
            <app-menu-item appearance="button" goTo="real-estate-agent/metrics" [size]="menuItemSize.Small">
                <app-svg-illustration [svg]="illustrationEnum.Leaderboard"></app-svg-illustration>
                <span class="__menu-description-label u-font-size-xs u-text-align-center u-margin-top-half">{{
                    'HEADER.MENU.REAL_ESTATE_AGENT.METRICS' | translate
                }}</span></app-menu-item
            >
        </div>
        <div class="__menu-container" data-testid="main-container-menu-management">
            <app-menu-item appearance="button" goTo="real-estate-agent/management" [size]="menuItemSize.Small">
                <app-svg-illustration [svg]="illustrationEnum.Settings"></app-svg-illustration>
                <span class="__menu-description-label u-font-size-xs u-text-align-center u-margin-top-half">{{
                    'HEADER.MENU.REAL_ESTATE_AGENT.MANAGEMENT' | translate
                }}</span>
            </app-menu-item>
        </div>
    </app-sidebar>

    <app-sidebar
        [hidden]="vm.showSecondaryNavigation !== true || !vm.showSideBar"
        *ngIf="vm.tabletPortraitUp"
        class="u-padding-top-double u-padding u-border-right"
        [class.collapsed]="!vm.secondaryCollapsing && vm.secondaryCollapsed"
        [class.collapsed--open]="!vm.secondaryCollapsing && vm.secondaryCollapsedOpen"
        [class.collapsing]="vm.secondaryCollapsing"
        (mouseenter)="openCollapsedHover()"
        (mouseleave)="onMouseOutSecondary()"
    >
        <button
            *ngIf="vm.secondaryCollapsed; else openSidebar"
            app-button
            appearance="icon"
            [icon]="vm.secondaryCollapsedOpen ? 'push_pin' : 'keyboard_double_arrow_right'"
            [iconSize]="vm.secondaryCollapsedOpen ? 'xm' : 'xm'"
            class="__collapse-toggle u-position-absolute u-color-muted"
            (click)="toggleCollapsed()"
        ></button>
        <ng-template #openSidebar>
            <button
                app-button
                appearance="icon"
                [icon]="'keyboard_double_arrow_left'"
                iconSize="xm"
                class="__collapse-toggle u-position-absolute u-color-muted"
                (click)="toggleCollapsed()"
            ></button>
        </ng-template>
        <router-outlet
            name="secondaryNavigation"
            (activate)="secondaryNavigationOnActivate()"
            (deactivate)="secondaryNavigationOnDeactivate()"
        ></router-outlet>
    </app-sidebar>
    <app-sidebar
        [hidden]="vm.showTertiaryNavigation !== true || !vm.showSideBar"
        class="u-padding-top-double u-border-right"
        *ngIf="vm.tabletPortraitUp"
        (mouseleave)="onMouseOutTertiary()"
    >
        <router-outlet
            name="tertiaryNavigation"
            (activate)="tertiaryNavigationOnActivate()"
            (deactivate)="tertiaryNavigationOnDeactivate()"
        ></router-outlet>
    </app-sidebar>
    <div class="main-container-template-body">
        <router-outlet></router-outlet>
    </div>
</ng-container>
