import { Component } from '@angular/core';
import { CellTemplateComponent } from '@smooved/ui';

@Component({
    templateUrl: './ean-info-table-result.column.html',
})
export class EanInfoTableResultColumnComponent extends CellTemplateComponent {
    constructor() {
        super();
    }
}
