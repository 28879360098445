import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { eotsDataRouting, eotsEnergySuggestionsRouting, eotsOfferSelectedRouting } from '@app/eots/constants/eots.constants';
import { iotsDataRouting, iotsFamilialRouting, iotsOfferSelectedRouting, iotsSuggestionsRouting } from '@app/iots/constants/iots.constants';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { otsAbsoluteContractRoute, otsAbsoluteOtherServicesRoute } from '@app/ots/constants/ots.constants';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-header-offer',
    template: `
        <h6 class="__title">{{ 'HEADER.OFFER.TITLE' | translate }}</h6>
        <mat-button-toggle-group
            [value]="headerCondition$ | async"
            *ngIf="uiSandbox.tabletLandscapeUp$ | async"
            class="__button-group"
            #group="matButtonToggleGroup"
        >
            <mat-button-toggle class="u-font-size-small" [disableRipple]="true" value="data">
                {{ 'HEADER.OFFER.DATA' | translate }}
            </mat-button-toggle>
            <mat-button-toggle class="u-font-size-small" [disableRipple]="true" value="suggestions">
                {{ 'HEADER.OFFER.SUGGESTIONS' | translate }}
            </mat-button-toggle>
            <mat-button-toggle class="u-font-size-small" [disableRipple]="true" value="contract">
                {{ 'HEADER.OFFER.CONTRACT' | translate }}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div
            *ngIf="uiSandbox.upToAndIncludingTabletPortrait$ | async"
            class="u-font-size-small u-color-muted"
            [ngSwitch]="headerCondition$ | async"
        >
            <span *ngSwitchCase="'data'">{{ 'HEADER.OFFER.DATA' | translate }}</span>
            <span *ngSwitchCase="'energy-suggestions'">{{ 'HEADER.OFFER.SUGGESTIONS' | translate }}</span>
            <span *ngSwitchCase="'contract'">{{ 'HEADER.OFFER.CONTRACT' | translate }}</span>
        </div>
    `,
    styleUrls: ['./header-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderOfferComponent {
    public headerCondition$: Observable<string> = this.router.events.pipe(
        startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
            if (
                event.urlAfterRedirects.startsWith(`/${Navigation.Telecom}/data`) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsDataRouting)) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Energy}/data`) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsDataRouting))
            ) {
                return 'data';
            } else if (
                event.urlAfterRedirects.startsWith(`/${Navigation.Telecom}/suggestions`) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsEnergySuggestionsRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getEotsAbsoluteNavigationUrl(eotsOfferSelectedRouting)) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Energy}/energy-suggestions`) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsSuggestionsRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsFamilialRouting)) ||
                event.urlAfterRedirects.startsWith(AppNavigationSandbox.getIotsAbsoluteNavigationUrl(iotsOfferSelectedRouting))
            ) {
                return 'suggestions';
            } else if (
                event.urlAfterRedirects.startsWith(otsAbsoluteContractRoute) ||
                event.urlAfterRedirects.startsWith(otsAbsoluteOtherServicesRoute) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/move-details`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/contact-details`) ||
                event.urlAfterRedirects.startsWith(`/${Navigation.Contract}/payment-details`)
            ) {
                return 'contract';
            } else {
                return '';
            }
        })
    );

    constructor(private router: Router, public uiSandbox: AppUiSandbox) {}
}
