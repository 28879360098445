import { Injectable } from '@angular/core';
import { CategoryEnum } from '@shared/review/enums/category.enum';
import { SentimentEnum } from '@shared/review/enums/sentiment.enum';
import { ArrayUtils, Interviewee, ReviewFilterBy, ReviewSource } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { defaultFilter } from '../constants/reviews-filter.constants';
import { DurationFilter } from '../enums/duration-filter.enum';
import { NpsReviewBucket } from '../enums/nps-review-buckets.enum';
import { EmittableReviewsFilter, ReviewsFilter } from '../interfaces/reviews-filter';

@Injectable()
export class NpsReviewsFilterService {
    public filterSubject = new BehaviorSubject<EmittableReviewsFilter>({ filter: defaultFilter });
    public filter$: Observable<EmittableReviewsFilter> = this.filterSubject.asObservable();

    public get filter(): ReviewsFilter {
        return this.filterSubject.value.filter;
    }

    public set filter(filter: ReviewsFilter) {
        this.filterSubject.next({ filter, emit: true });
    }

    public filterByGroup(groupId: string, emit = true): void {
        if (!groupId) this.clearBuckets(false);
        this.setFilter(ReviewFilterBy.Group, groupId, emit);
    }

    public filterByScore(scores: number[], emit = true): void {
        this.setFilter(ReviewFilterBy.Score, scores, emit);
    }

    public filterBySegment(segments: Interviewee[], emit = true): void {
        this.setFilter(ReviewFilterBy.Segment, segments, emit);
    }

    public filterByIds(ids: string[], emit = true): void {
        this.setFilter(ReviewFilterBy.Id, ids, emit);
    }

    public filterByUnanswered(value: boolean): void {
        this.filterBySource(ReviewSource.Smooved, false);
        this.setFilter(ReviewFilterBy.Unanswered, value);
    }

    public filterOnlyVerified(value: boolean): void {
        this.setFilter(ReviewFilterBy.Source, value ? ArrayUtils.toArray(ReviewSource.Smooved) : []);
    }

    public filterByLocation(value: string, emit = true): void {
        this.setFilter(ReviewFilterBy.Location, value, emit);
    }

    public filterByRealEstateAgent(value: string, emit = true): void {
        this.setFilter(ReviewFilterBy.RealEstateAgentId, value, emit);
    }

    public filterByIncludeAllExternal(value: boolean, emit = true): void {
        this.setFilter(ReviewFilterBy.IncludeAllExternal, value, emit);
    }

    public filterByTopicCategory(value: CategoryEnum, emit = true): void {
        this.setFilter(ReviewFilterBy.TopicCategory, value, emit);
    }

    public filterByTopicCategorySentiment(value: SentimentEnum, emit = true): void {
        this.setFilter(ReviewFilterBy.TopicCategorySentiment, value, emit);
    }

    public filterByDuration(value: DurationFilter, emit = true): void {
        this.setFilter(ReviewFilterBy.DurationFilter, value, emit);
    }

    public filterBySource(source: ReviewSource | ReviewSource[], emit = true, limitFillUp?: boolean): void {
        this.setFilter(ReviewFilterBy.Source, ArrayUtils.toArray(source), emit);
        this.setFilter(ReviewFilterBy.ExtendWithOtherSources, limitFillUp);
    }

    public filterByBucket(bucket: NpsReviewBucket | NpsReviewBucket[], emit = true): void {
        this.setFilter(
            ReviewFilterBy.Bucket,
            ArrayUtils.toArray(bucket).filter((x) => x),
            emit
        );
    }

    public clear(emit = true): void {
        this.filterSubject.next({ filter: defaultFilter, emit });
    }

    public clearBuckets(emit = true): void {
        this.filterByBucket(undefined, emit);
    }

    private setFilter(key: string, value: unknown, emit = true): void {
        this.filterSubject.next({
            filter: {
                ...this.filterSubject.value.filter,
                [key]: value,
            },
            emit,
        });
    }
}
