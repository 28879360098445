import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { PaginationRequest, RxjsComponent, SortDirection } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-core-table',
    template: ``,
    styles: [],
})
export class CoreTableComponent extends RxjsComponent implements OnInit, OnDestroy {
    @Output() public sortChange: EventEmitter<{ sort: Sort }> = new EventEmitter<{ sort: Sort }>();
    @Output() public pageEventChange: EventEmitter<{ pageEvent: PageEvent }> = new EventEmitter<{ pageEvent: PageEvent }>();

    @ViewChild(MatSort, { static: true }) public sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;

    public pageSizeOptions: number[] = [12, 24, 48];
    public defaultPageSize: number = this.pageSizeOptions[0];
    public defaultPageIndex = 0;
    public defaultSortDirection: SortDirection = SortDirection.Desc;
    public defaultSortProperty: string;

    public resetPageIndex(): void {
        this.paginator.pageIndex = this.defaultPageIndex;
    }

    public currentPageSize(): number {
        return this.paginator?.pageSize || this.defaultPageSize;
    }

    public currentPageIndex(): number {
        return this.paginator?.pageIndex || this.defaultPageIndex;
    }

    public currentSortDirection(): SortDirection {
        return (this.sort?.direction as SortDirection) || this.defaultSortDirection;
    }

    public getPaginationOptions(): PaginationRequest {
        return {
            pageSize: this.currentPageSize(),
            pageIndex: this.currentPageIndex(),
            sortDirection: this.currentSortDirection(),
            sortField: this.sort?.active,
        };
    }

    public ngOnInit(): void {
        if (this.paginator) {
            this.paginator.pageIndex = this.defaultPageIndex;
            this.paginator.pageSize = this.defaultPageSize;
        }

        if (this.sort) {
            this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((sort) => this.sortChange.emit({ sort }));
        }

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe((pageEvent) => this.pageEventChange.emit({ pageEvent }));
        }
    }
}
