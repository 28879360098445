import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FileInput } from '@app/form/interfaces/file-input';
import { TranslateService } from '@ngx-translate/core';
import { Mimetypes, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '../../button';
import { UiIconSize } from '../../icon';
import { deleteConfirmation, ModalSandbox } from '../../modal';
import { UiContext } from '../../ui.enums';
import { assetIcons } from './asset.constants';

@Component({
    selector: 'app-asset',
    templateUrl: './asset.component.html',
    styleUrls: ['./asset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetComponent implements OnChanges {
    @Input() label: string;
    @Input() file: FileInput;
    @Input() showDelete: false;

    @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

    public icon: string;
    public readonly iconSize = UiIconSize;
    public readonly buttonSize = ButtonSize;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonContext = UiContext;

    constructor(
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox
    ) {}

    public ngOnChanges({ file }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(file)) {
            this.icon = this.getIcon();
        }
    }

    public onDelete(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        const data = this.translateService.instant(deleteConfirmation);
        this.modalSandbox.openConfirmModal({ data }, this.handleOnRemoveClose, { data }, this.handleOnRemoveClose);
    }

    private handleOnRemoveClose = (result): void => {
        if (result) {
            this.delete.emit();
        }
    };

    private getIcon(): string {
        switch (this.file?.mime) {
            case Mimetypes.Jpeg:
            case Mimetypes.Png:
                return assetIcons.image;
            default:
                return assetIcons.file;
        }
    }
}
