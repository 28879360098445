import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { maxAmountOfChars } from '@app/message/components/message/message.constants';
import { Message } from '@app/message/interfaces/message.interface';
import { dateTimeFormatDefault } from '@smooved/core';
import { ButtonAppearance, ModalSandbox } from '@smooved/ui';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
    @Input() public message: Message;

    public readonly dateTimeFormat = dateTimeFormatDefault;
    public readonly maxAmountOfChars = maxAmountOfChars;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public show(): void {
        this.modalSandbox.showTextModal(this.message.content);
    }
}
