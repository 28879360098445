import { Directive, HostListener, Input } from '@angular/core';
import { EventArg, EventName, Mimetypes } from '@smooved/core';

@Directive({
    selector: '[uiRestrictInput]',
})
export class RestrictInputDirective {
    @Input() public uiRestrictInput: string;

    @HostListener(EventName.KeyPress, [EventArg.$Event])
    public onKeyPress(event: KeyboardEvent): boolean {
        if (!this.uiRestrictInput) return true;
        return new RegExp(`[^${this.uiRestrictInput}@]+`).test(event.key);
    }

    @HostListener(EventName.Paste, [EventArg.$Event])
    public onPaste(event): void {
        if (!this.uiRestrictInput) return;
        this.validate(event);
    }

    private validate(event): void {
        event.preventDefault();
        const data = event.clipboardData.getData(Mimetypes.Txt).replace(new RegExp(`[${this.uiRestrictInput}@]`, 'g'), '');
        document.execCommand('insertHTML', false, data);
    }
}
