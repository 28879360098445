<app-closable-modal-template maxWidth="none" width="680px">
    <h6 modal-header>{{ 'PACKAGE.LABEL' | translate }}</h6>
    <app-loading-container [loading]="loading">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-end">
            <div class="u-w100p">
                <ui-dropdown-input
                    [formControlName]="formControlNames.package"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    width="auto"
                    [options]="options"
                ></ui-dropdown-input>
                <smvd-app-billing-settings
                    *ngIf="showBillingSettings$ | async"
                    [formControlName]="formControlNames.billingSettings"
                ></smvd-app-billing-settings>
            </div>

            <button app-button type="submit">{{ 'EDIT' | translate }}</button>
        </form>
    </app-loading-container>
</app-closable-modal-template>
