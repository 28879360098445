import { Injectable } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ContactLogService } from '@app/move/services/contact-log.service';
import { NotificationSandbox } from '@smooved/ui';
import { AdminProvidersModule } from '../admin-providers.module';
@Injectable({
    providedIn: AdminProvidersModule,
})
export class AdminContactLogService extends ContactLogService {
    constructor(protected readonly moveSandbox: MoveSandbox, protected readonly notificationSandbox: NotificationSandbox) {
        super(moveSandbox, notificationSandbox);
    }
}
