import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { OrderGift } from '@app/order/interfaces/order-gift';
import { ModalData } from '@app/partner/interfaces/modal-data';
import { GiftDetailService } from '@app/partner/modals/gift-detail/gift-detail.service';
import { ClosableModalTemplateComponent } from '@smooved/ui';

@Component({
    selector: 'app-gift-detail-modal',
    templateUrl: './gift-detail.modal.html',
    providers: [GiftDetailService],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GiftDetailModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalData<OrderGift>,
        public readonly giftDetailService: GiftDetailService,
        public readonly authenticationSandbox: AuthenticationSandbox
    ) {}

    public ngOnInit(): void {
        this.giftDetailService.setOrder(this.data.order);
    }

    public onPatch(payload: object): void {
        this.giftDetailService.patchGift(this.data.order._id, payload, this.onPatchHandler);
    }

    public onOrderNoteChange(orderNote: string): void {
        this.giftDetailService.onOrderNoteChange(this.data.order._id, orderNote, this.onPatchHandler);
    }

    private onPatchHandler = () => {
        this.data.orderUpdated.next(true);
    };
}
