import { Injectable } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { DbUtils, RxjsService } from '@smooved/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, pairwise, share, takeUntil } from 'rxjs/operators';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';

@Injectable({ providedIn: RealEstateAgentProvidersModule })
export class RealEstateAgentDashboardService extends RxjsService {
    private reloadSubject = new Subject<boolean>();
    public onReload$ = this.reloadSubject.asObservable().pipe(share());

    constructor(private readonly moveSandbox: MoveSandbox) {
        super();
        this.moveSandbox.move$.pipe(distinctUntilChanged(), pairwise(), takeUntil(this.destroy$)).subscribe(this.handleMoveStateUpdate);
    }

    public reload(): void {
        this.reloadSubject.next(true);
    }

    private handleMoveStateUpdate = ([previous, current]: [Move, Move]): void => {
        if (DbUtils.getStringId(previous) === DbUtils.getStringId(current)) {
            this.reload();
        }
    };
}
