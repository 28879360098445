import { I18nKeyType } from './i18n-key-type.enum';

export type I18nKeyTypeMap = {
    [key: string]: string;
};

export const i18nKeyTypeMap: I18nKeyTypeMap = {
    [I18nKeyType.HouseType]: 'COMMON.HOUSE_TYPE',
    [I18nKeyType.ReviewBucket]: 'COMMON.REVIEWS.BUCKETS',
    [I18nKeyType.ReviewTransaction]: 'COMMON.REVIEWS.TRANSACTIONS',
    [I18nKeyType.Interviewee]: 'COMMON.INTERVIEWEE',
    [I18nKeyType.Socials]: 'COMMON.SOCIALS',
    [I18nKeyType.MoveTransaction]: 'COMMON.TRANSACTIONS',
    [I18nKeyType.Salutation]: 'COMMON.SALUTATIONS',
    [I18nKeyType.RealEstateGroupActivationStage]: 'COMMON.REAL_ESTATE_GROUP.ACTIVATION_STAGE',
    [I18nKeyType.Province]: 'COMMON.PROVINCE',
    [I18nKeyType.Gender]: 'COMMON.GENDER',
};
