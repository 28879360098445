import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { FEATURE_TOGGLE_CONFIG, FeatureScope, FeatureScopeSandbox, FeatureToggle, FeatureToggleConfig } from '@smooved/core';

@Component({
    selector: 'app-start-container',
    template: `
        <app-card-container>
            <div class="u-container-big u-margin-0-auto u-margin-bottom-double">
                <div
                    class="__container u-flex-responsive-tablet-landscape-up"
                    [ngClass]="{ '__container--has-iots': realEstateGroupSandbox.serviceIots$ | async }"
                >
                    <div class="__left-column u-flex-column u-flex-1-1-0">
                        <app-highway-info-box
                            *ngIf="
                                (featureScopeSandbox.hasAccess(featureScope.SmoovedCompanion) | async) &&
                                (uiSandbox.upToAndIncludingPhoneLandscape$ | async)
                            "
                        ></app-highway-info-box>
                        <h6 class="u-color-muted __title-input" *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async">
                            {{ 'REAL_ESTATE_AGENT.START.INPUT' | translate }}
                        </h6>
                        <app-energy *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"></app-energy>
                        <app-iots *ngIf="realEstateGroupSandbox.serviceIots$ | async"></app-iots>
                        <app-lead-passing *ngIf="realEstateGroupSandbox.serviceLP$ | async"></app-lead-passing>
                        <div
                            *ngIf="
                                (featureScopeSandbox.hasAccess(featureScope.Transactions) | async) && (uiSandbox.tabletLandscapeUp$ | async)
                            "
                        >
                            <ng-container *ngTemplateOutlet="otherTools"></ng-container>
                        </div>
                    </div>

                    <div class="u-flex-column u-flex-1-1-0">
                        <h6
                            *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"
                            class="u-color-muted __title-follow-up"
                        >
                            {{ 'REAL_ESTATE_AGENT.START.FOLLOW_UP' | translate }}
                        </h6>
                        <app-dashboard *ngIf="featureScopeSandbox.hasAccess(featureScope.Transactions) | async"></app-dashboard>
                        <app-nps></app-nps>
                    </div>
                </div>
                <div
                    *ngIf="
                        (featureScopeSandbox.hasAccess(featureScope.Transactions) | async) &&
                        (uiSandbox.upToAndIncludingPhoneLandscape$ | async)
                    "
                >
                    <ng-container *ngTemplateOutlet="otherTools"></ng-container>
                </div>
            </div>
        </app-card-container>

        <ng-template #otherTools>
            <div *ngIf="!!(authenticationSandbox.realEstateAgentId$ | async)" class="__other--tools u-margin-top-double">
                <h6 class="u-color-muted __title-input u-margin-bottom">{{ 'REAL_ESTATE_AGENT.OTHER_TOOLS' | translate }}</h6>
                <app-other-simulation-tools class="u-display-block"></app-other-simulation-tools>
            </div>
        </ng-template>
    `,
    styleUrls: ['./start.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartContainer {
    public featureScope = FeatureScope;
    public featureToggle = FeatureToggle;

    constructor(
        public authenticationSandbox: AuthenticationSandbox,
        public realEstateGroupSandbox: RealEstateGroupSandbox,
        public uiSandbox: AppUiSandbox,
        public featureScopeSandbox: FeatureScopeSandbox,
        @Inject(FEATURE_TOGGLE_CONFIG) public readonly config: FeatureToggleConfig
    ) {}
}
