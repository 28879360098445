import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductCondition, ProductConditionAction } from '@app/energy/enums/product-condition';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { suppliersPath } from '@app/shared/constants/assets';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { EnergySandbox } from '@app/wizard/energy/sandboxes/energy.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { RxjsComponent } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { filter, take, takeUntil } from 'rxjs/operators';
import { EnergySuggestionsFields, maxSuppliersVisible, pricesPerMonthOption, suggestionIndex } from './energy-suggestions.constants';

@Component({
    selector: 'app-energy-suggestions',
    templateUrl: 'energy-suggestions.component.html',
    styleUrls: ['energy-suggestions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergySuggestionsComponent extends RxjsComponent implements OnInit, OnDestroy {
    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public energySuggestionChange: EventEmitter<EnergySuggestion> = new EventEmitter<EnergySuggestion>();

    public form: UntypedFormGroup = this.formBuilder.group({
        energySuggestion: [null, Validators.required],
        pricesPerMonth: [false, [Validators.required]],
        fixedTariff: [false, [Validators.required]],
    });

    public loading = false;
    public vacancyShowAll = false;
    public isVacancy = false;

    public readonly pricesPerMonthOption = pricesPerMonthOption;
    public readonly productCondition = ProductCondition;
    public readonly productConditionAction = ProductConditionAction;
    public readonly fields = EnergySuggestionsFields;
    public readonly suggestionIndex = suggestionIndex;
    public readonly suppliersPath = suppliersPath;
    public readonly maxSuppliersVisible = maxSuppliersVisible;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        public readonly energySandbox: EnergySandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.vacancyOnce$.subscribe((vacancy) => {
            this.isVacancy = vacancy;
            if (vacancy) {
                this.form.get(EnergySuggestionsFields.PricesPerMonth).patchValue(true);
            }
        });

        this.energySandbox.getGroupedEnergySuggestions(this.form.value[EnergySuggestionsFields.FixedTariff]);

        this.form
            .get(EnergySuggestionsFields.EnergySuggestion)
            .valueChanges.pipe(
                takeUntil(this.destroy$),
                filter((x) => !!x)
            )
            .subscribe((energySuggestion) => {
                this.energySuggestionChange.emit(energySuggestion);
            });

        this.energySandbox.fixedEnergySuggestions$
            .pipe(
                takeUntil(this.destroy$),
                filter((energySuggestion) => energySuggestion?.length === 0 && !!this.form.value[EnergySuggestionsFields.FixedTariff]),
                take(1)
            )
            .subscribe(() => {
                this.form.get(EnergySuggestionsFields.FixedTariff).patchValue(false); // set variable tariff
            });
    }

    public doShowAll(): void {
        this.vacancyShowAll = true;
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.energySandbox.clearGroupedSuggestions();
    }

    public toggleTariff(): void {
        const value = this.form.value[EnergySuggestionsFields.FixedTariff] as boolean;
        this.form.get(EnergySuggestionsFields.FixedTariff).patchValue(!value);
    }

    public showTariffDiff(): void {
        const data = this.translateService.instant('MOVE.ENERGY.SUGGESTIONS.TARIFF_DIFFERENCE.DESCRIPTION') as string;
        this.modalSandbox.showTextModal(data);
    }
}
