import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ExternalInfluencer, Language, State, StringUtils } from '@smooved/core';
import { websiteUrlOptionalValidator } from '@smooved/ui';
import { isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { concatMap, switchMap, take } from 'rxjs/operators';
import * as externalInfluencerUri from '../constants/uri.constants';

@Injectable()
export class ExternalInfluencerService {
    public form = this.formBuilder.group({
        [formGroupNames.header]: this.createHeaderForm(),
        [formGroupNames.about]: this.createAboutForm(),
        [formGroupNames.contact]: this.createContactForm(),
    });

    constructor(
        private readonly httpClient: HttpClient,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}

    public createHeaderForm(): UntypedFormGroup {
        return this.formBuilder.group({
            [formControlNames.cover]: [null],
            [formControlNames.logo]: [null],
        });
    }

    public createAboutForm(): UntypedFormGroup {
        return this.formBuilder.group({
            [formControlNames.about]: this.formBuilder.group({
                [Language.NL]: [null],
                [Language.FR]: [null],
                [Language.EN]: [null],
            }),
            [formControlNames.aboutDefaultLanguage]: [null],
        });
    }

    public createContactForm(): UntypedFormGroup {
        return this.formBuilder.group({
            [formControlNames.phoneNumber]: [null],
            [formControlNames.email]: [null, [Validators.email, Validators.required]],
            [formControlNames.linkedIn]: [null],
            [formControlNames.facebook]: [null],
            [formControlNames.instagram]: [null],
            [formControlNames.youtube]: [null],
            [formControlNames.twitter]: [null],
            [formControlNames.website]: [null, [websiteUrlOptionalValidator]],
        });
    }

    public publishByFormData(realEstateGroupId: string): Observable<ExternalInfluencer> {
        return this.publish(realEstateGroupId, this.payloadFactory());
    }

    public publish(realEstateGroupId: string, payload: ExternalInfluencer): Observable<ExternalInfluencer> {
        return this.httpClient.post<ExternalInfluencer>(
            externalInfluencerUri.publishUri.replace(':realEstateGroupId', realEstateGroupId),
            payload
        );
    }

    public updateCover(realEstateGroupId: string, content: FormData): Observable<ExternalInfluencer> {
        return this.httpClient.post<ExternalInfluencer>(
            StringUtils.parseUri(externalInfluencerUri.updateCoverUri, { realEstateGroupId }),
            content
        );
    }

    public getDraft(): Observable<ExternalInfluencer> {
        return this.realEstateGroupSandbox.externalInfluencerUrl$.pipe(
            switchMap((slug) => this.get(slug, State.Draft)),
            take(1)
        );
    }

    public get(url: string, state: State, altState?: State): Observable<ExternalInfluencer> {
        let httpParams = new HttpParams().set('state', state.toString());
        if (isNumber(altState)) {
            httpParams = httpParams.set('altState', altState.toString());
        }
        return this.httpClient.get<ExternalInfluencer>(externalInfluencerUri.byUrlUri.replace(':url', url), {
            params: httpParams,
        });
    }

    public updateFeatureScopesConfig(featureScopesConfig): Observable<void> {
        return this.realEstateGroupSandbox.realEstateGroupOnce$.pipe(
            concatMap((realEstateGroup) =>
                this.httpClient.post<void>(
                    externalInfluencerUri.featureScopesConfigUri.replace(':realEstateGroupId', realEstateGroup._id.toString()),
                    featureScopesConfig
                )
            )
        );
    }

    public patchForm(externalInfluencer: ExternalInfluencer): void {
        const { cover, logo, phoneNumber, email, linkedIn, facebook, instagram, youtube, twitter, website, about, aboutDefaultLanguage } =
            externalInfluencer;
        this.form.patchValue({
            [formGroupNames.header]: {
                [formControlNames.cover]: cover || null,
                [formControlNames.logo]: logo || null,
            },
            [formGroupNames.about]: {
                [formControlNames.about]: about || {},
                [formControlNames.aboutDefaultLanguage]: aboutDefaultLanguage || null,
            },
            [formGroupNames.contact]: {
                [formControlNames.phoneNumber]: phoneNumber || null,
                [formControlNames.email]: email || null,
                [formControlNames.linkedIn]: linkedIn || null,
                [formControlNames.facebook]: facebook || null,
                [formControlNames.instagram]: instagram || null,
                [formControlNames.youtube]: youtube || null,
                [formControlNames.twitter]: twitter || null,
                [formControlNames.website]: website || null,
            },
        });
    }

    private payloadFactory(): ExternalInfluencer {
        return {
            ...this.form.get(formGroupNames.contact).value,
            ...this.form.get(formGroupNames.about).value,
        };
    }
}
