import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Language, RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { DropdownInput } from '../../form/dropdown-input/dropdown-input';
import { languageOptions } from './language-menu.constants';

@Component({
    selector: 'ui-language-menu',
    template: `
        <ui-dropdown-input
            [formControl]="language"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            appearance="fill"
            [width]="width"
            [options]="languageOptions"
        ></ui-dropdown-input>
    `,
    styleUrls: ['./language-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LanguageMenuComponent extends RxjsComponent implements OnInit {
    @Input() width: string;
    @Output() languageChanged = new EventEmitter<Language>();

    public readonly languageOptions: DropdownInput<string>[] = languageOptions;
    public language: UntypedFormControl;

    public onLanguageChange = (_language: Language): void => {
        /** */
    };

    public registerOnLanguageChange(fn: (_: Language) => void): void {
        this.onLanguageChange = fn;
    }

    constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.language = new UntypedFormControl(this.translateService.currentLang);
        this.language.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            this.onLanguageChange(value);
            this.languageChanged.emit(value);
        });

        this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event.lang === this.language.value) return;
            this.language.setValue(event.lang);
            this.cdr.detectChanges();
        });
    }
}
