import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-move-detail-telecom',
    template: `
        <h3 class="u-margin-bottom">{{ 'TELECOM.LABEL' | translate }}</h3>

        <app-service-ordered
            [move]="move"
            class="u-margin-bottom u-display-block"
            [checked]="moveSandbox.telecomOrderedSuccess(move)"
        ></app-service-ordered>

        <app-service-ordered-by-admin
            class="u-margin-bottom u-display-block"
            *ngIf="moveSandbox.telecomOrderedSuccess(move)"
            [checked]="moveSandbox.telecomOrderedByAdminSuccess(move)"
        ></app-service-ordered-by-admin>
    `,
    styles: [':host {display: block}'],
})
export class MoveDetailTelecomComponent {
    @Input() public move: Move;

    constructor(public moveSandbox: MoveSandbox) {}
}
