import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Supplier } from '@app/supplier/interfaces/supplier';
import { SupplierService } from '@app/supplier/supplier/supplier.service';
import { BaseInput } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-supplier-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="suppliers"
                [placeholder]="'SUPPLIER' | translate"
                searchKeyword="email"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                (inputChanged)="inputChanged($event)"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                name="supplier"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.email"></span>
            </ng-template>

            <mat-error *ngIf="getAbstractControl()?.invalid && isErrorState()">{{ getFirstErrorState() | errorState }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./supplier-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SupplierInputComponent),
            multi: true,
        },
    ],
})
export class SupplierInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: any = '';

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    public suppliers: Supplier[];

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private supplierService: SupplierService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.loadingSubject.next(true);
        this.supplierService.getAll().subscribe(
            (response) => {
                this.suppliers = response;
            },
            (_) => {},
            () => this.loadingSubject.next(false)
        );
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(supplier: Supplier): void {
        this.innerModel = supplier?.email || '';
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
        }, 0);
    }

    public selected(supplier: Supplier) {
        setTimeout(() => {
            this.propagateChange(supplier?.email);
            this.getAbstractControl().markAsTouched();
        }, 0);
    }

    public inputChanged(input: string) {
        setTimeout(() => {
            this.propagateChange(input);
            this.getAbstractControl().markAsTouched();
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }

    public onBlur(event: any): void {
        this.getAbstractControl().markAsTouched();
    }
}
