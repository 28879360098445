import { DetailTab, ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MoveDetailTab } from '@app/real-estate-agent/modals/move-detail/move-detail.constants';
import { MoveLeaverDetailTab } from '@app/real-estate-agent/modals/move-leaver-detail/move-leaver-detail.constants';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { RxjsComponent } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';

export class MoveDetailsActions extends RxjsComponent {
    protected movePatchedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(protected readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox) {
        super();
    }

    public openTransfereeDetail(id: string, callback?: () => void, activeTab?: DetailTab): void {
        this.realEstateAgentModalsSandbox.openTransfereeDetail(id, this.modalDataFactory(id, activeTab), callback);
    }

    public openLeaverDetail(id: string, callback?: () => void, activeTab?: DetailTab): void {
        this.realEstateAgentModalsSandbox.openLeaverDetail(id, this.modalDataFactory(id, activeTab), callback);
    }

    private modalDataFactory(id: string, activeTab?: DetailTab): ModalData {
        const modalData: ModalData = {
            id,
            options: {},
        };
        if (activeTab && [...Object.values(MoveDetailTab), ...Object.values(MoveLeaverDetailTab)].includes(activeTab)) {
            modalData.options.activeTab = activeTab;
        }
        return modalData;
    }
}
