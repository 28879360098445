import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { QuarterlyInvoicing } from '@app/invoicing/interfaces/quarterly-invoicing';
import { Move } from '@app/move/interfaces/move';
import { SimpleChangesUtils } from '@smooved/core';
import { ChartItem, ColorScale } from '@smooved/ui';

@Component({
    selector: 'app-quarter-report-energy',
    template: `
        <app-loading-container [loading]="loading" [ngSwitch]="closed">
            <div class="u-flex-row u-flex-align-items-center">
                <div class="u-padding-top u-padding-right-double u-padding-bottom u-padding-left-double">
                    <app-pie-chart [width]="160" [height]="160" [id]="'overall'" [data]="pieData"></app-pie-chart>
                </div>
                <table class="u-w100p u-padding-right">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="u-text-align-right u-color-muted u-font-weight-normal">
                                {{ 'INVOICING.OVERALL.MOVES' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="u-cursor-pointer __row"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.EnergyOrdered }"
                            (click)="typeChange.emit(invoicingType.EnergyOrdered)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.ORDERED' | translate"
                                    [order]="1"
                                    [label]="
                                        'INVOICING.OVERALL.FROM_PREVIOUS_QUARTER'
                                            | translate: { value: quarterlyInvoicing?.energy.orderedCarriedOverPreviousQuarterCount }
                                    "
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right">{{ quarterlyInvoicing?.energy.orderedTotalCount }}</td>
                        </tr>
                        <tr
                            class="u-cursor-pointer __row"
                            *ngSwitchCase="false"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.EnergyToOrderBySmooved }"
                            (click)="typeChange.emit(invoicingType.EnergyToOrderBySmooved)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.TO_ORDER_BY_SMOOVED' | translate"
                                    [order]="2"
                                    [label]="
                                        'INVOICING.OVERALL.FROM_PREVIOUS_QUARTER'
                                            | translate
                                                : { value: quarterlyInvoicing?.energy.toOrderBySmoovedCarriedOverPreviousQuarterCount }
                                    "
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">{{ quarterlyInvoicing?.energy.toOrderBySmoovedTotalCount }}</td>
                        </tr>
                        <tr
                            class="u-cursor-pointer __row"
                            *ngSwitchCase="false"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.EnergyToOrder }"
                            (click)="typeChange.emit(invoicingType.EnergyToOrder)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [title]="'INVOICING.TO_ORDER' | translate"
                                    [order]="3"
                                    [label]="
                                        'INVOICING.OVERALL.FROM_PREVIOUS_QUARTER'
                                            | translate: { value: quarterlyInvoicing?.energy.toOrderCarriedOverPreviousQuarterCount }
                                    "
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">{{ quarterlyInvoicing?.energy.toOrderTotalCount }}</td>
                        </tr>
                        <tr
                            class="u-cursor-pointer __row"
                            *ngSwitchCase="true"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.EnergyCarriedOverToNextQuarter }"
                            (click)="typeChange.emit(invoicingType.EnergyCarriedOverToNextQuarter)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [order]="3"
                                    [title]="'INVOICING.CARRIED_OVER_TO_NEXT_QUARTER' | translate"
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">
                                {{ quarterlyInvoicing?.energy.carriedOverToNextQuarterTotalCount }}
                            </td>
                        </tr>

                        <tr
                            class="u-cursor-pointer __row"
                            [ngClass]="{ 'u-background-gray-medium': type === invoicingType.EnergyNotInterested }"
                            (click)="typeChange.emit(invoicingType.EnergyNotInterested)"
                        >
                            <td>
                                <app-invoicing-type-cell
                                    [order]="4"
                                    [title]="'INVOICING.NOT_INTERESTED' | translate"
                                ></app-invoicing-type-cell>
                            </td>
                            <td class="u-text-align-right u-color-muted">{{ quarterlyInvoicing?.energy.notInterestedCount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-loading-container>
    `,
})
export class QuarterReportEnergyComponent implements OnChanges, OnInit {
    @Input() public loading: boolean;
    @Input() public type: InvoicingType;
    @Input() public quarterlyInvoicing: QuarterlyInvoicing;
    @Input() public closed: boolean;
    @Input() public realEstateGroupId: string;
    @Input() public quarterDate: Date;

    @Output() public typeChange: EventEmitter<InvoicingType> = new EventEmitter<InvoicingType>();
    @Output() public openDetail: EventEmitter<Move> = new EventEmitter<Move>();

    public invoicingType = InvoicingType;
    public pieData: ChartItem[];

    public ngOnInit(): void {
        this.typeChange.emit(InvoicingType.EnergyOrdered);
    }

    public ngOnChanges({ quarterlyInvoicing }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(quarterlyInvoicing)) {
            this.createChartData(quarterlyInvoicing?.currentValue);
        }
    }

    private createChartData(invoice: QuarterlyInvoicing): void {
        if (!invoice) return;
        let data = [
            {
                label: 'INVOICING.ORDERED',
                value: invoice?.energy.orderedTotalCount || 0,
                color: ColorScale.Color1,
            },
        ];

        if (invoice.closed) {
            data = data.concat([
                {
                    label: 'INVOICING.CARRIED_OVER_TO_NEXT_QUARTER',
                    value: invoice?.energy.carriedOverToNextQuarterTotalCount || 0,
                    color: ColorScale.Color3,
                },
            ]);
        } else {
            data = data.concat([
                {
                    label: 'INVOICING.TO_ORDER_BY_SMOOVED',
                    value: invoice?.energy.toOrderBySmoovedTotalCount || 0,
                    color: ColorScale.Color2,
                },
                {
                    label: 'INVOICING.TO_ORDER',
                    value: invoice?.energy.toOrderTotalCount || 0,
                    color: ColorScale.Color3,
                },
            ]);
        }

        data.push({
            label: 'INVOICING.NOT_INTERESTED',
            value: invoice?.energy.notInterestedCount || 0,
            color: ColorScale.Color4,
        });

        this.pieData = data;
    }
}
