import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSource } from '@smooved/ui';
import { BehaviorSubject, firstValueFrom, Observable, of, switchMapTo } from 'rxjs';
import { finalize, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { socialShareUri } from '../constants';
import { AnalyticsEventsEnum, Language } from '../enums';
import { NpsOg } from '../interfaces';
import { AnalyticsService } from '../services';
import { DbUtils, StringUtils, WindowUtils } from '../utils';
import { npsOgUri, popup } from './social-sharing.constants';
import { NpsReviewWithSharingIndicator } from './social-sharing.interfaces';

@Injectable()
export abstract class SocialSharingService {
    protected abstract apiUri: string;
    protected abstract appSource: ApplicationSource;
    public sharing$ = new BehaviorSubject<boolean>(undefined);

    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly translateService: TranslateService,
        protected readonly clipboard: Clipboard,
        protected readonly analyticsService: AnalyticsService
    ) {}

    public shareOnLinkedIn(review: NpsReviewWithSharingIndicator): Observable<boolean> {
        review.pendingLinkedIn = true;
        const shareOnSocial = this.getNpsOg(DbUtils.getStringId(review), this.translateService.currentLang as Language).pipe(
            tap(this.handleShareLinkedIn),
            mapTo(true),
            finalize(() => (review.pendingLinkedIn = false)),
            take(1)
        );
        return this.share(shareOnSocial);
    }

    public shareOnFacebook(review: NpsReviewWithSharingIndicator): Observable<boolean> {
        review.pendingFacebook = true;
        const shareOnSocial = this.getNpsOg(DbUtils.getStringId(review), this.translateService.currentLang as Language).pipe(
            tap(this.handleShareFacebook),
            mapTo(true),
            finalize(() => (review.pendingFacebook = false)),
            take(1)
        );
        return this.share(shareOnSocial);
    }

    public shareOnInstagram(review: NpsReviewWithSharingIndicator): Observable<boolean> {
        review.pendingInstagram = true;
        const shareOnSocial = this.getNpsOg(DbUtils.getStringId(review), this.translateService.currentLang as Language).pipe(
            switchMap(this.handleDownload),
            finalize(() => (review.pendingInstagram = false))
        );
        return this.share(shareOnSocial);
    }

    public download(review: NpsReviewWithSharingIndicator): Observable<boolean> {
        review.pendingDownload = true;
        const shareOnSocial = this.getNpsOg(DbUtils.getStringId(review), this.translateService.currentLang as Language).pipe(
            switchMap(this.handleDownload),
            finalize(() => (review.pendingDownload = false))
        );
        this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewDownloadClicked, {
            reviewId: DbUtils.getStringId(review),
            application: this.appSource,
        });
        return this.share(shareOnSocial);
    }

    public copyToClipboard(review: NpsReviewWithSharingIndicator, cb?: () => void): Observable<boolean> {
        this.getNpsOg(DbUtils.getStringId(review), this.translateService.currentLang as Language).subscribe();
        const copy$ = of(() => this.clipboard.copy(review.reviewUrl)).pipe(
            tap((fn: () => void) => fn()),
            mapTo(true),
            finalize(() => cb?.())
        );
        return this.share(copy$);
    }

    public getNpsOg(reviewId: string, language: Language): Observable<NpsOg> {
        return this.httpClient.get<NpsOg>(StringUtils.parseUri(npsOgUri, { reviewId, apiUri: this.apiUri }), {
            params: { language },
        });
    }

    private handleShareLinkedIn = (og: NpsOg): void => {
        this.handleShare(socialShareUri.linkedInShareUri, og);
    };

    private handleShareFacebook = (og: NpsOg): void => {
        this.handleShare(socialShareUri.facebookShareUri, og);
    };

    private handleDownload = (og: NpsOg): Observable<boolean> => {
        return WindowUtils.download(og.ogImageUrl, `review.png`);
    };

    private handleShare(shareUri: string, og: NpsOg): void {
        const shareParams = { articleUrl: encodeURIComponent(og.website) };
        const url = StringUtils.parseUri(shareUri, shareParams);
        WindowUtils.openWindow(url, popup.width, popup.height);
    }

    private share(sharer$: Observable<boolean>): Observable<boolean> {
        this.sharing$.next(true);
        sharer$.pipe(finalize(() => this.sharing$.next(false))).subscribe();
        return sharer$;
    }
}
