import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { imagesLibPath } from '@app/ui/constants/common.constants';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiSandbox } from '../../../ui.sandbox';

@Component({
    selector: 'app-cta-card',
    templateUrl: './cta-card.component.html',
    styleUrls: ['./cta-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallToActionCardComponent {
    @Input() public icon: string;
    @Input() public iconSize = UiIconSize.Xl;
    @Input() public iconAppearance = UiIconAppearance.Default;
    @Input() public iconClass: string;
    @Input() public label: string;
    @Input() public image: string;
    @Input() public showContentMobile = false;

    public uiIconSize = UiIconSize;
    public imagesLibPath = imagesLibPath;
    constructor(public readonly uiSandbox: UiSandbox) {}
}
