import { I18nKeyUtils } from '@smooved/core';
import { companyPageI18nKeyTypeMap, companyPageI18nKeyTypes } from '../../constants';

export const titleResourceAppointment = I18nKeyUtils.map(
    companyPageI18nKeyTypeMap,
    companyPageI18nKeyTypes.CtaBox,
    'CONTACT.APPOINTMENT.TITLE'
);

export const titleResourceAppointmentMultiSelect = I18nKeyUtils.map(
    companyPageI18nKeyTypeMap,
    companyPageI18nKeyTypes.CtaBox,
    'CONTACT.APPOINTMENT.TITLE_MULTI_SELECT'
);

export const titleResourceCallback = I18nKeyUtils.map(companyPageI18nKeyTypeMap, companyPageI18nKeyTypes.CtaBox, 'CONTACT.CALLBACK.TITLE');
export const titleResourceMessage = I18nKeyUtils.map(companyPageI18nKeyTypeMap, companyPageI18nKeyTypes.CtaBox, 'CONTACT.MESSAGE.TITLE');

export const messageDescriptionResourceAppointment = I18nKeyUtils.map(
    companyPageI18nKeyTypeMap,
    companyPageI18nKeyTypes.CtaBox,
    'CONTACT.APPOINTMENT.MESSAGE_DESCRIPTION'
);
export const messageDescriptionResourceCallback = I18nKeyUtils.map(
    companyPageI18nKeyTypeMap,
    companyPageI18nKeyTypes.CtaBox,
    'CONTACT.CALLBACK.MESSAGE_DESCRIPTION'
);

export const prefillAppointment = I18nKeyUtils.map(
    companyPageI18nKeyTypeMap,
    companyPageI18nKeyTypes.CtaBox,
    'CONTACT.APPOINTMENT.PREFILL'
);
export const prefillCallback = I18nKeyUtils.map(companyPageI18nKeyTypeMap, companyPageI18nKeyTypes.CtaBox, 'CONTACT.CALLBACK.PREFILL');
