import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from '@app/header/interfaces/breadcrumb';
import { BreadcrumbItem } from '@app/header/interfaces/breadcrumb-item';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    template: `
        <div>
            <h6 class="__title">{{ breadcrumb.titleResource | translate }}</h6>

            <ng-container *ngIf="activeItem$ | async as activeItem">
                <div
                    *ngIf="uiSandbox.upToAndIncludingTabletPortrait$ | async; else tabletLandscapeUp"
                    class="u-font-size-small u-color-muted"
                >
                    <span>{{ activeItem.labelResource | translate }}</span>
                </div>

                <ng-template #tabletLandscapeUp>
                    <mat-button-toggle-group
                        *ngIf="uiSandbox.tabletLandscapeUp$ | async"
                        class="__button-group"
                        #group="matButtonToggleGroup"
                    >
                        <mat-button-toggle
                            *ngFor="let item of breadcrumb?.items"
                            class="u-font-size-small"
                            [checked]="activeItem.id === item.id"
                        >
                            {{ item.labelResource | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </ng-template>
            </ng-container>
        </div>
    `,
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
    @Input() public breadcrumb: Breadcrumb;

    public activeItem$: Observable<BreadcrumbItem | null> = this.router.events.pipe(
        startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
            if (!this.breadcrumb?.items) return null;
            return this.breadcrumb.items.find((item) => item.routes.some((route) => route === event.urlAfterRedirects));
        })
    );

    constructor(private readonly router: Router, public readonly uiSandbox: AppUiSandbox) {}
}
