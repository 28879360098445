import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { NpsScore } from '@app/move/interfaces/nps-score';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { select, Store } from '@ngrx/store';
import { Interviewee, ObjectUtils, RealEstateLevel } from '@smooved/core';
import { DurationFilter, NotificationSandbox } from '@smooved/ui';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { InterviewInitiatedByType } from '../enums/interview-initiated-by-type.enum';
import { Interview } from '../interfaces/meta/interview';
import { SelectionOption } from '../interfaces/meta/questions/selection-option';
import { NpsSurvey } from '../interfaces/nps-survey.interface';
import { SurveysService } from '../services/surveys.service';
import { setHasExistingSurvey, setInitiatedBy, setPresetScore, getInterview as surveysGetInterview } from '../state/surveys.actions';
import { getInterviewState, getSurveyState, SurveyState } from '../state/surveys.reducer';

@Injectable({
    providedIn: 'root',
})
export class SurveysSandbox {
    public survey$ = this.store$.pipe(select(getSurveyState));
    public interview$ = this.store$.pipe(select(getInterviewState));
    public interviewOnce$ = this.interview$.pipe(take(1));

    private readonly npsDetractorMaxValue: number = 5;
    private readonly npsPassiveMaxValue: number = 8;

    constructor(
        private surveysService: SurveysService,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox,
        private store$: Store<SurveyState>
    ) {}

    public registerNpsSurvey(moveId: string, survey: NpsSurvey): Observable<void> {
        this.uiSandbox.showLoadingOverlay();
        const payload: NpsSurvey = ObjectUtils.buildPayload(survey) as NpsSurvey;
        return this.surveysService.registerNpsSurvey(moveId, payload).pipe(
            tap(() => {
                this.uiSandbox.hideLoadingOverlay();
                this.notificationSandbox.success('SURVEYS.GENERAL.SUCCESS');
            }),
            catchError((error) => {
                this.uiSandbox.hideLoadingOverlay();
                return throwError(error);
            })
        );
    }

    /**
     * Get nps score for real estate office
     */
    public npsScore(
        interval: DurationFilter = DurationFilter.LatestMonth,
        level: RealEstateLevel = RealEstateLevel.Group,
        marketAverage = false,
        locationId?: string
    ): Observable<NpsScore> {
        return this.surveysService.npsScore(level, interval, locationId, marketAverage);
    }

    public isDetractor(score: number): boolean {
        return score <= this.npsDetractorMaxValue;
    }

    public isPassive(score: number): boolean {
        return score > this.npsDetractorMaxValue && score <= this.npsPassiveMaxValue;
    }

    public getInterview(interviewee: Interviewee, initiatedBy: InterviewInitiatedByType, callback?: (interview: Interview) => any): void {
        this.store$.dispatch(surveysGetInterview({ interviewee, initiatedBy, callback }));
    }

    public hasChildren(options: SelectionOption[]): boolean {
        return options?.some((option): boolean => !!option.children);
    }

    public sendSurveyRequestEmail(move: Move): Observable<Move> {
        return this.surveysService.sendSurveyRequestEmail(move).pipe(
            tap(() => this.notificationSandbox.success('SURVEYS.SEND_REQUEST_EMAIL.SUCCESS')),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    public setHasExistingSurvey(hasExistingSurvey: boolean): void {
        this.store$.dispatch(setHasExistingSurvey({ hasExistingSurvey }));
    }

    public presetScore(score: number): void {
        this.store$.dispatch(setPresetScore({ score }));
    }

    public setInitiatedBy(initiatedBy: InterviewInitiatedByType): void {
        this.store$.dispatch(setInitiatedBy({ initiatedBy }));
    }
}
