import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-move-detail-extra-info',
    template: `
        <div class="u-padding u-background-gray-medium u-font-size-small" [ngSwitch]="collapsed">
            <div
                class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between"
                [ngClass]="{ 'u-margin-bottom': !collapsed }"
            >
                <ng-content select="header, [header]"></ng-content>
                <a class="u-link" *ngSwitchCase="true" (click)="collapsed = false">{{
                    'ADMIN.DASHBOARD.DETAIL.SHOW_DETAILS' | translate
                }}</a>
                <a class="u-link" *ngSwitchCase="false" (click)="collapsed = true">{{
                    'ADMIN.DASHBOARD.DETAIL.HIDE_DETAILS' | translate
                }}</a>
            </div>
            <div *ngIf="!collapsed">
                <ng-content select="content, [content]"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./move-detail-extra-info.component.scss'],
})
export class MoveDetailExtraInfoComponent {
    @Input() public collapsed = true;
}
