import { Component, Input } from '@angular/core';
import { InsurancesSandbox } from '@app/iots/sandboxes/insurances.sandbox';
import { Move } from '@app/move/interfaces/move';
import { ButtonAppearance } from '@smooved/ui';

@Component({
    selector: 'app-insurances-contract-pdf',
    template: `
        <ng-container *ngIf="move?.insurancesStates as insurancesStates">
            <a
                *ngIf="insurancesStates?.contractPdf; else requestBtn"
                app-button
                [appearance]="buttonAppearance.Link"
                target="_blank"
                class="u-padding-none u-h-auto"
                [ngClass]="{ 'u-disabled': !insurancesStates?.contractPdf }"
                [href]="insurancesStates?.contractPdf"
                >{{ 'INSURANCES.OPEN_CONTRACT_PDF' | translate }}</a
            >

            <ng-template #requestBtn>
                <button app-button [appearance]="buttonAppearance.Link" class="u-padding-none u-h-auto" (click)="requestContract()">
                    {{ 'INSURANCES.OPEN_CONTRACT_PDF' | translate }}
                </button>
            </ng-template>
        </ng-container>
    `,
})
export class InsurancesContractPdfComponent {
    @Input() public move: Move;

    public readonly buttonAppearance = ButtonAppearance;

    constructor(private readonly insurancesSandbox: InsurancesSandbox) {}

    public requestContract(): void {
        this.insurancesSandbox.getContractUrl(this.move?.insurancesProposal?.quoteId);
    }
}
