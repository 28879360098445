import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@shared/move/utils/move.utils';

@Component({
    selector: 'app-table-column-ean-count',
    template: `
        <ng-container matColumnDef="eanCount">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef># {{ 'EAN.LABEL' | translate }}</th>
            <td mat-cell *matCellDef="let row" class="__cell u-padding-left u-padding-right">
                <div class="u-white-space-no-wrap">{{ eanCount(row) }}</div>
            </td>
        </ng-container>
    `,
    styles: [
        `
                    .__cell {
                        width: 150px;
                    }
                `,
    ],
})
export class TableColumnEanCountComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }

    public eanCount(move: Move): number {
        return MoveUtils.eanCount(move);
    }
}
