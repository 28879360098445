import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PowerBarsComponent } from './power-bars.component';

@NgModule({
    declarations: [PowerBarsComponent],
    imports: [CommonModule],
    exports: [PowerBarsComponent],
})
export class PowerBarsModule {}
