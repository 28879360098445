import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button';
import { IconModule } from '../icon/icon.module';
import { AssetComponent } from './asset/asset.component';

@NgModule({
    declarations: [AssetComponent],
    imports: [CommonModule, IconModule, ButtonModule],
    exports: [AssetComponent],
})
export class DocumentCenterModule {}
