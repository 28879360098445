import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { dateFormatDefault, DateUtils, MoveTransactionType, TransactionType } from '@smooved/core';
import {
    automaticDigitalReadingOptionFn,
    CheckInput,
    ElectricityForm,
    ElectricityFormFields,
    electricityMeterTypeOptions,
    energyI18n,
    exclusiveNightOptionFn,
    ModalSandbox,
    placeholders,
    solarPanelsOption,
    SvgIllustration,
    UiAlignment,
    UiDirection,
} from '@smooved/ui';
import { takeUntil } from 'rxjs';
import { BaseInput } from '../../../form/base-input';
import { ElectricityMeterType } from '../../enums/electricity-meter-type.enum';

@Component({
    selector: 'smvd-ui-meter-readings-electricity',
    templateUrl: 'meter-readings-electricity.component.html',
    styleUrls: ['meter-readings-electricity.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeterReadingsElectricityComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MeterReadingsElectricityComponent),
            multi: true,
        },
    ],
})
export class MeterReadingsElectricityComponent extends BaseInput implements OnInit, ControlValueAccessor, Validator {
    @Input() public transactionType: MoveTransactionType | TransactionType = MoveTransactionType.Sale;

    public readonly fields = ElectricityFormFields;
    public readonly svgIllustration = SvgIllustration;
    public readonly uiDirection = UiDirection;
    public readonly meterType = ElectricityMeterType;
    public readonly placeholders = placeholders;
    public readonly alignment = UiAlignment;
    public readonly solarPanelsOption = solarPanelsOption;

    public readonly electricityForm: FormGroup = this.formBuilder.group({
        [ElectricityFormFields.Active]: true,
        [ElectricityFormFields.MeterType]: ElectricityMeterType.Single,
        [ElectricityFormFields.ExclusiveNight]: false,
        [ElectricityFormFields.EanCode]: null,
        [ElectricityFormFields.AutomaticDigitalReadings]: false,
        [ElectricityFormFields.MovingDate]: null,
        [ElectricityFormFields.SingleMeterReading]: null,
        [ElectricityFormFields.DoubleDayMeterReading]: null,
        [ElectricityFormFields.DoubleNightMeterReading]: null,
        [ElectricityFormFields.ConsumptionDayMeterReading]: null,
        [ElectricityFormFields.ConsumptionNightMeterReading]: null,
        [ElectricityFormFields.SolarPanels]: null,
        [ElectricityFormFields.InjectionDayMeterReading]: null,
        [ElectricityFormFields.InjectionNightMeterReading]: null,
        [ElectricityFormFields.ExclusiveNightMeterReading]: null,
    });

    public activeControl: FormControl<boolean>;
    public meterTypeControl: FormControl<ElectricityMeterType>;
    public solarPanelsControl: FormControl<boolean>;
    public exclusiveNightControl: FormControl<boolean>;

    public exclusiveNightOption: CheckInput<boolean>;
    public automaticDigitalReadingOption: CheckInput<boolean>;
    public meterTypeOptions = electricityMeterTypeOptions;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly modalSandbox: ModalSandbox,
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.exclusiveNightOption = exclusiveNightOptionFn(`${this.translate.instant(energyI18n.exclusiveNight)}`);
        this.automaticDigitalReadingOption = automaticDigitalReadingOptionFn(this.transactionType as MoveTransactionType);

        this.activeControl = this.electricityForm.get(ElectricityFormFields.Active) as FormControl;
        this.meterTypeControl = this.electricityForm.get(ElectricityFormFields.MeterType) as FormControl;
        this.exclusiveNightControl = this.electricityForm.get(ElectricityFormFields.ExclusiveNight) as FormControl;
        this.solarPanelsControl = this.electricityForm.get(ElectricityFormFields.SolarPanels) as FormControl;

        this.electricityForm
            .get(ElectricityFormFields.AutomaticDigitalReadings)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(this.confirmAutomaticDigitalReadingsChange);

        this.electricityForm
            .get(ElectricityFormFields.SolarPanels)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(this.handleSolarPanelsChange);

        this.electricityForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormValueChange);
    }

    public writeValue(data: ElectricityForm): void {
        this.electricityForm.patchValue(data, { emitEvent: false });
        this.handleAutomaticDigitalReadingsChange(data?.automaticDigitalReadings);
    }

    public validate(): ValidationErrors | null {
        return this.electricityForm.invalid ? { electricityMeters: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.electricityForm.disable({ emitEvent: false }) : this.electricityForm.enable({ emitEvent: false });
    }

    private confirmAutomaticDigitalReadingsChange = (value: boolean) => {
        if (!value) {
            this.handleAutomaticDigitalReadingsChange(value);
            return;
        }

        const data = {
            data: this.translate.instant(energyI18n.confirmAutomaticReadings, {
                movingDate: DateUtils.tz(this.electricityForm.get(ElectricityFormFields.MovingDate).value).format(dateFormatDefault),
            }),
        };
        const callback = (confirm: boolean) => {
            if (confirm) {
                this.handleAutomaticDigitalReadingsChange(value);
                return;
            }
            this.electricityForm.get(ElectricityFormFields.AutomaticDigitalReadings).patchValue(!value, { emitEvent: false });
        };
        this.modalSandbox.openConfirmModal(data, callback, data, callback);
    };

    private handleAutomaticDigitalReadingsChange(value: boolean): void {
        // When Input movingDate is set, the movingDate is handled outside this component.
        this.setFormControlDisabled(ElectricityFormFields.MovingDate, !value);
        this.setFormControlDisabled(ElectricityFormFields.ConsumptionDayMeterReading, value);
        this.setFormControlDisabled(ElectricityFormFields.ConsumptionNightMeterReading, value);
        this.setFormControlDisabled(ElectricityFormFields.SolarPanels, value);
        this.setFormControlDisabled(ElectricityFormFields.InjectionDayMeterReading, value);
        this.setFormControlDisabled(ElectricityFormFields.InjectionNightMeterReading, value);
    }

    private handleSolarPanelsChange = (hasSolarPanels: boolean): void => {
        const { injectionDayMeterReading, injectionNightMeterReading } = this.electricityForm.value as ElectricityForm;
        if (!hasSolarPanels) {
            if (!injectionDayMeterReading)
                this.electricityForm.get(ElectricityFormFields.InjectionDayMeterReading).patchValue('0', { emitEvent: false });
            if (!injectionNightMeterReading)
                this.electricityForm.get(ElectricityFormFields.InjectionNightMeterReading).patchValue('0', { emitEvent: false });
        } else {
            if (injectionDayMeterReading === '0')
                this.electricityForm.get(ElectricityFormFields.InjectionDayMeterReading).patchValue(null, { emitEvent: false });
            if (injectionNightMeterReading === '0')
                this.electricityForm.get(ElectricityFormFields.InjectionNightMeterReading).patchValue(null, { emitEvent: false });
        }
    };

    private setFormControlDisabled(formControlName: ElectricityFormFields, disabled: boolean): void {
        this.electricityForm?.get(formControlName)[disabled ? 'disable' : 'enable']({ emitEvent: false });
    }

    private handleFormValueChange = (): void => this.propagateChange(this.electricityForm.getRawValue());
}
