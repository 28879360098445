import { Injectable } from '@angular/core';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { DbUtils, SortDirection, SortUtils } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { leaverJourneyEmailContactLogTypes } from '../constants/contact-log.constants';
import { MoveUtils } from '../state/move.utils';

@Injectable()
export class ContactLogService {
    constructor(protected readonly moveSandbox: MoveSandbox, protected readonly notificationSandbox: NotificationSandbox) {}

    public getAllContactLogs(move: Move): ContactLog[] {
        const contactLogs = move?.contactLogs?.length ? [...move?.contactLogs] : [];
        const requiredLogs = [
            ContactLogType.EmailEnergyUrgency,
            ContactLogType.EmailPitch,
            ContactLogType.EmailMover,
            ContactLogType.EmailRealEstateAgent,
        ];
        requiredLogs.forEach((logType) => this.pushIfNotExists(contactLogs, logType));
        return contactLogs;
    }

    public resendLeaverCollectionInvite(move: Move): Observable<Move> {
        const { leaver } = MoveUtils.getMovers(move);
        const contactLogType =
            this.getLastContactLogTypeOf(leaver, ...leaverJourneyEmailContactLogTypes) || ContactLogType.EmailLeaverInvite;
        const afterSend$ = this.moveSandbox.createContactLog(DbUtils.getStringId(leaver), { value: contactLogType });
        return afterSend$.pipe(take(1), finalize(this.sendSuccess));
    }

    private pushIfNotExists(contactLogs: ContactLog[], type: ContactLogType): void {
        if (contactLogs.every((contactLog) => contactLog.value !== type)) {
            contactLogs.push({ value: type });
        }
    }

    private sendSuccess = (): void => {
        this.notificationSandbox.sendSuccess();
    };

    private getLastIndexOf(move: Move, ...contactLogTypes: ContactLogType[]): number {
        const logs = this.getAllContactLogs(move).map((log) => log.value);
        return contactLogTypes.map((type: ContactLogType) => logs.lastIndexOf(type)).sort(SortUtils.sort(SortDirection.Desc))[0];
    }

    private getLastContactLogTypeOf(move: Move, ...contactLogTypes: ContactLogType[]): ContactLogType {
        const logs = this.getAllContactLogs(move).map((log) => log.value);
        const lastIndex = this.getLastIndexOf(move, ...contactLogTypes);
        return logs[lastIndex];
    }
}
