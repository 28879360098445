import { Pipe, PipeTransform } from '@angular/core';
import { ArrayUtils } from '../utils';

@Pipe({
    name: 'length',
})
export class LengthPipe implements PipeTransform {
    public transform<T>(value: T[]): number {
        return ArrayUtils.getLength(value);
    }
}
