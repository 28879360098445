import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationRoutes } from '@app/authentication/constants/routing.constants';
import { AdminLoginContainer } from '@app/authentication/containers/admin-login/admin-login.container';
import { PartnerRegisterCompleteContainer } from '@app/authentication/containers/partner-register-complete/partner-register-complete.container';
import { PartnerRegisterContactDetailsContainer } from '@app/authentication/containers/partner-register-contact-details/partner-register-contact-details.container';
import { PartnerRegisterPasswordContainer } from '@app/authentication/containers/partner-register-password/partner-register-password.container';
import { PartnerRegisterWelcomeContainer } from '@app/authentication/containers/partner-register-welcome/partner-register-welcome.container';
import { RealEstateAgentRegisterContactDetailsContainer } from '@app/authentication/containers/real-estate-agent-register-contact-details/real-estate-agent-register-contact-details.container';
import { RealEstateAgentRegisterOtherDetailsContainer } from '@app/authentication/containers/real-estate-agent-register-other-details/real-estate-agent-register-other-details.container';
import { RealEstateAgentRegisterPasswordContainer } from '@app/authentication/containers/real-estate-agent-register-password/real-estate-agent-register-password.container';
import { RealEstateAgentRegisterSuccessContainer } from '@app/authentication/containers/real-estate-agent-register-success/real-estate-agent-register-success.container';
import { RealEstateAgentRegisterWelcomeContainer } from '@app/authentication/containers/real-estate-agent-register-welcome/real-estate-agent-register-welcome.container';
import { NoRealEstateAgentGuard } from '@app/authentication/guards/no-real-estate-agent/no-real-estate-agent.guard';
import { LoggedInRedirectGuard } from '@app/redirect/guards/logged-in-redirect.guard';
import { RealEstateAgentResetPasswordNewComponent } from './components/real-estate-agent-reset-password-new/real-estate-agent-reset-password-new.component';
import { RealEstateAgentResetPasswordComponent } from './components/real-estate-agent-reset-password/real-estate-agent-reset-password.component';
import { ConfirmRealEstateAgentRegisterContainer } from './containers/confirm-real-estate-agent-register/confirm-real-estate-agent-register.container';
import { ConfirmUserLoginContainer } from './containers/confirm-user-login/confirm-user-login.container';
import { RealEstateAgentLoginContainer } from './containers/real-estate-agent-login/real-estate-agent-login.container';
import { RealEstateAgentRegisterCompleteContainer } from './containers/real-estate-agent-register-complete/real-estate-agent-register-complete.container';
import { RealEstateAgentRegisterContainer } from './containers/real-estate-agent-register/real-estate-agent-register.container';
import { ResetPasswordContainer } from './containers/reset-password/reset-password.container';

const routes: Routes = [
    {
        path: authenticationRoutes.root,
        children: [
            {
                path: authenticationRoutes.user.root,
                children: [
                    {
                        path: authenticationRoutes.user.confirm,
                        component: ConfirmUserLoginContainer,
                    },
                ],
            },
            {
                path: authenticationRoutes.realEstateAgent.root,
                children: [
                    {
                        path: authenticationRoutes.realEstateAgent.confirm,
                        component: ConfirmRealEstateAgentRegisterContainer,
                    },
                    {
                        path: authenticationRoutes.realEstateAgent.login,
                        canActivate: [NoRealEstateAgentGuard, LoggedInRedirectGuard],
                        component: RealEstateAgentLoginContainer,
                    },
                    {
                        path: authenticationRoutes.realEstateAgent.resetPassword,
                        component: RealEstateAgentResetPasswordComponent,
                    },
                    {
                        path: authenticationRoutes.realEstateAgent.resetPasswordNew,
                        component: RealEstateAgentResetPasswordNewComponent,
                    },
                    {
                        path: authenticationRoutes.realEstateAgent.register.root,
                        children: [
                            {
                                path: authenticationRoutes.realEstateAgent.register.welcome,
                                component: RealEstateAgentRegisterWelcomeContainer,
                            },
                            {
                                path: authenticationRoutes.realEstateAgent.register.contactDetails,
                                component: RealEstateAgentRegisterContactDetailsContainer,
                            },
                            {
                                path: authenticationRoutes.realEstateAgent.register.otherDetails,
                                component: RealEstateAgentRegisterOtherDetailsContainer,
                            },
                            {
                                path: authenticationRoutes.realEstateAgent.register.password,
                                component: RealEstateAgentRegisterPasswordContainer,
                            },
                            {
                                path: authenticationRoutes.realEstateAgent.register.success,
                                component: RealEstateAgentRegisterSuccessContainer,
                            },
                            {
                                path: authenticationRoutes.realEstateAgent.register.complete,
                                component: RealEstateAgentRegisterCompleteContainer,
                            },
                            {
                                path: '',
                                component: RealEstateAgentRegisterContainer,
                            },
                        ],
                    },
                ],
            },
            {
                path: authenticationRoutes.admin.root,
                children: [
                    {
                        path: authenticationRoutes.admin.login,
                        canActivate: [LoggedInRedirectGuard],
                        component: AdminLoginContainer,
                    },
                    {
                        path: authenticationRoutes.admin.resetPassword,
                        component: ResetPasswordContainer,
                    },
                ],
            },
            {
                path: authenticationRoutes.partner.root,
                children: [
                    {
                        path: authenticationRoutes.partner.register.root,
                        children: [
                            {
                                path: authenticationRoutes.partner.register.welcome,
                                component: PartnerRegisterWelcomeContainer,
                            },
                            {
                                path: authenticationRoutes.partner.register.contactDetails,
                                component: PartnerRegisterContactDetailsContainer,
                            },
                            {
                                path: authenticationRoutes.partner.register.password,
                                component: PartnerRegisterPasswordContainer,
                            },
                            {
                                path: authenticationRoutes.partner.register.complete,
                                component: PartnerRegisterCompleteContainer,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
