import { Injectable } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox } from '@smooved/ui';
import { noop, Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class ConfirmService {
    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService
    ) {}

    public validateImpersonate(): Observable<void> {
        return zip(this.authenticationSandbox.isAdminOnce$, this.moveSandbox.moveOnce$).pipe(
            mergeMap(this.confirmContact),
            mergeMap(this.updateContactLog)
        );
    }

    private isAdminAndEnergyFlow(isAdmin, move) {
        return isAdmin && (move.createdByFlow?.eots || move.createdByFlow?.lp);
    }

    private confirmContact = ([isAdmin, move]: [boolean, Move]): Observable<[boolean, string]> => {
        if (!this.isAdminAndEnergyFlow(isAdmin, move)) return of([false, undefined]);
        const data = { data: this.translateService.instant('ADMIN.IMPERSONATE.SEND_CONTACT_LOG') };
        return new Observable((observer) => {
            const callback = (confirm) => observer.next([confirm, move._id]);
            this.modalSandbox.openConfirmModal(data, callback, data, callback);
        });
    };

    private updateContactLog = ([confirmed, id]: [boolean, string]): Observable<void> => {
        if (!confirmed) return of(noop());
        return this.moveSandbox.createContactLog(id, { value: ContactLogType.Call }).pipe(map(() => noop()));
    };
}
