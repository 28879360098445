import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@app/move/interfaces/meta';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-energy-home-description-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-loading-container [loading]="uiSandbox.moveLoading$ | async">
                    <app-card class="u-flex-column u-flex-align-items-start">
                        <h3>{{ 'REAL_ESTATE_AGENT.EOTS.HOME_DESCRIPTION.TITLE' | translate }}</h3>
                        <p class="u-color-muted u-margin-bottom-triple">
                            {{
                                'STEP_INDICATION'
                                    | translate
                                        : {
                                              start: 3,
                                              end: 6
                                          }
                                    | translate
                            }}
                        </p>

                        <app-home-description (next)="onSubmit($event)" (previous)="goToPrevious($event)"></app-home-description>
                    </app-card>
                </app-loading-container>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeDescriptionContainer {
    constructor(
        private readonly router: Router,
        private readonly moveSandbox: MoveSandbox,
        private readonly route: ActivatedRoute,
        public readonly uiSandbox: AppUiSandbox
    ) {}

    public goToPrevious(event: Meta): void {
        this.moveSandbox.patchProperty('meta', event, true, (_) => {
            this.router
                .navigate(['../family-size'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }

    public onSubmit(event: Meta): void {
        this.moveSandbox.patchProperty('meta', event, true, (_) => {
            this.router
                .navigate(['../home-construction-year'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }
}
