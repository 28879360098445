import { Injectable } from '@angular/core';
import { WaterService } from '@app/water/services/water.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { getWaterSuppliersFailure, getWaterSuppliersSuccess, WaterActionTypes } from './water.actions';

@Injectable()
export class WaterEffects {
    constructor(private actions$: Actions, private waterService: WaterService) {}

    public getWaterSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WaterActionTypes.GetWaterSuppliers),
            exhaustMap(() =>
                this.waterService.getWaterSuppliers().pipe(
                    map((waterSuppliers) => getWaterSuppliersSuccess({ waterSuppliers })),
                    catchError(() => of(getWaterSuppliersFailure()))
                )
            )
        )
    );
}
