import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProviderContainer } from './containers/provider/provider.container';
import { TelecomBundleContainer } from './containers/telecom-bundle/telecom-bundle.container';
import { TelecomContainer } from './containers/telecom-container/telecom-container.container';
import { TelecomOfferSelectedContainer } from './containers/telecom-offer-selected/telecom-offer-selected.container';
import { TelecomSuggestionsContainer } from './containers/telecom-suggestions/telecom-suggestions.container';

const routes: Routes = [
    {
        path: '',
        component: TelecomContainer,
        children: [
            {
                path: 'data/telecom-bundle',
                component: TelecomBundleContainer,
            },
            {
                path: 'data/provider',
                component: ProviderContainer,
            },
            {
                path: 'suggestions',
                component: TelecomSuggestionsContainer,
            },
            {
                path: 'offer-selected',
                component: TelecomOfferSelectedContainer,
            },
            {
                path: '**',
                redirectTo: 'data/telecom-bundle',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TelecomRoutingModule {}
