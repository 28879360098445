import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Move } from '@app/move/interfaces/move';
import { DbUtils, RxjsComponent } from '@smooved/core';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-rental-inspection-asset',
    templateUrl: './rental-inspection-asset.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentalInspectionAssetComponent extends RxjsComponent implements OnInit {
    @Input() public move: Move;
    @Output() public deleted: EventEmitter<Move> = new EventEmitter<Move>();

    public showDelete: boolean;

    constructor(
        private readonly documentCenterSandbox: DocumentCenterSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        combineLatest(this.authenticationSandbox.isPartnerAgent$, this.authenticationSandbox.isAdmin$)
            .pipe(
                map(([isPartnerAgent, isAdmin]) => isPartnerAgent || isAdmin),
                takeUntil(this.destroy$)
            )
            .subscribe((result) => (this.showDelete = result));
    }

    public openRentalInspection(): void {
        this.documentCenterSandbox.downloadMoveAsset(DbUtils.getStringId(this.move), AttachmentId.RentalInspectionAsset);
    }

    public onDelete(): void {
        this.documentCenterSandbox.deleteMoveFile(DbUtils.getStringId(this.move), AttachmentId.RentalInspectionAsset).subscribe((move) => {
            this.deleted.emit(move);
        });
    }
}
