import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils, StringUtils } from '@smooved/core';
import { TagMatcherUtils } from '../utils/tag-matcher.utils';

// Regular expression to match the %positive%...%/positive% and %negative%...%/negative% patterns
const regex = /%(positive|negative)%(.+?)%\/\1%/g;

enum HighLightClass {
    Positive = 'positive',
    Negative = 'negative',
}

@Pipe({
    name: 'highlightSentiment',
})
export class HighlightSentimentPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) return '';

        if (NumberUtils.isOdd(TagMatcherUtils.countPositiveTags(value))) {
            value = TagMatcherUtils.removePositiveTags(value);
        }

        if (NumberUtils.isOdd(TagMatcherUtils.countNegativeTags(value))) {
            value = TagMatcherUtils.removeNegativeTags(value);
        }

        if (TagMatcherUtils.countPositiveOpeningTags(value) !== TagMatcherUtils.countPositiveClosingTags(value)) {
            value = TagMatcherUtils.removePositiveTags(value);
        }

        if (TagMatcherUtils.countNegativeOpeningTags(value) !== TagMatcherUtils.countNegativeClosingTags(value)) {
            value = TagMatcherUtils.removeNegativeTags(value);
        }

        value = StringUtils.removeMultipleSpaces(value);

        return value.replace(regex, (match, type, content) => {
            if (type === HighLightClass.Positive) {
                return `<span class="m-highlight-sentiment m-highlight-sentiment--positive">${content}</span>`;
            } else if (type === HighLightClass.Negative) {
                return `<span class="m-highlight-sentiment m-highlight-sentiment--negative">${content}</span>`;
            } else {
                return content;
            }
        });
    }
}
