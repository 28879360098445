import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UiIconSize } from '../../icon/icon.enums';
import { UiContext } from '../../ui.enums';
import { ButtonAppearance, ButtonSize } from '../button.enums';

@Component({
    selector: 'ui-dropdown-menu-button',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuButtonComponent {
    @Input() public label: string;
    @Input() public disabled: boolean;
    @Input() public icon = 'arrow_drop_down';
    @Input() public xPosition: 'before' | 'after' = 'before';
    @Input() public yPosition: 'below' | 'above' = 'below';
    @Input() public appearance: ButtonAppearance = ButtonAppearance.Default;
    @Input() public size: ButtonSize;
    @Input() public context: UiContext;

    @ViewChild(MatMenuTrigger) private trigger: MatMenuTrigger;

    public readonly uiIconSize = UiIconSize;
    public readonly buttonAppearance = ButtonAppearance;

    public openMenu(): void {
        this.trigger.openMenu();
    }

    public closeMenu(): void {
        this.trigger.closeMenu();
    }

    public toggleMenu(): void {
        this.trigger.toggleMenu();
    }

    public noPropagation(e: MouseEvent): void {
        e.stopImmediatePropagation();
    }
}
