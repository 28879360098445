import { NgModule } from '@angular/core';
import { AddressModule } from '@app/address/address.module';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { LookupToolContainer } from '@app/water/containers/lookup-tool/lookup-tool.container';
import { WaterEffects } from '@app/water/state/water.effects';
import { waterReducer } from '@app/water/state/water.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AlertModule, InfoModule, SvgModule } from '@smooved/ui';
import { CardModule } from '../card/card.module';
import { ModalModule } from '../modal/modal.module';
import { SharedModule } from '../shared/shared.module';
import { WaterDocumentComponent } from './components/water-document/water-document.component';
import { WaterSupplierComponent } from './components/water-supplier/water-supplier.component';
import { WaterDocumentMovingAddressModal } from './modals/water-document-moving-address/water-document-moving-address.modal';
import { WaterRoutingModule } from './water-routing.module';

@NgModule({
    declarations: [LookupToolContainer, WaterDocumentComponent, WaterDocumentMovingAddressModal, WaterSupplierComponent],
    imports: [
        CardModule,
        WaterRoutingModule,
        SharedModule,
        ModalModule,
        SvgModule,
        DocumentCenterModule,
        StoreModule.forFeature('water', waterReducer),
        EffectsModule.forFeature([WaterEffects]),
        InfoModule,
        AlertModule,
        AddressModule,
    ],
    exports: [WaterDocumentComponent, WaterDocumentMovingAddressModal],
})
export class WaterModule {}
