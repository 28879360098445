export enum FormControlNames {
    FirstName = 'firstName',
    Gender = 'gender',
    LastName = 'lastName',
    DateOfBirth = 'dateOfBirth',
    Phone = 'phoneNumber',
    Address = 'contactAddress',
    OtherAddress = 'otherContactAddress',
    Language = 'language',
    Email = 'email',
    LandAgent = 'langdAgent',
    PassportNumber = 'passportNumber',
    NationalNumber = 'nationalNumber',
    CompanyName = 'companyName',
    VatNumber = 'vatNumber',
}
