import { modalRouterOutlet } from '@app/external-influencer/constants/routing.constants';
import { MenuItem } from '@smooved/ui';

export const menuItems: MenuItem[] = [
    {
        labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.MENU.HEADER',
        goTo: [{ outlets: { [modalRouterOutlet]: ['header'] } }],
    },
    {
        labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.MENU.ABOUT',
        goTo: [{ outlets: { [modalRouterOutlet]: ['about'] } }],
    },
    {
        labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.MENU.LOCATIONS',
        goTo: [{ outlets: { [modalRouterOutlet]: ['locations'] } }],
    },
    {
        labelResource: 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.MENU.CONTACT',
        goTo: [{ outlets: { [modalRouterOutlet]: ['contact'] } }],
    },
];
