import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { InvoiceVersion } from '@app/invoicing/enums/invoice-version.enum';
import { RealEstateGroupInvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox, SvgIllustration, UiContext, UiIcon, UiSandbox } from '@smooved/ui';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-payed-invoice',
    templateUrl: './payed-invoice.component.html',
    styleUrls: ['./payed-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PayedInvoiceComponent {
    @Input() public invoice: Invoice;
    @ViewChild('download') download: ElementRef;

    public readonly uiContext = UiContext;
    public readonly featureScope = FeatureScope;
    public readonly version = InvoiceVersion;
    public readonly uiIcon = UiIcon;
    public readonly upToAndIncludingPhoneLandscape$ = this.uiSandbox.upToAndIncludingPhoneLandscape$;
    public readonly invoiceVersion = InvoiceVersion;
    public readonly svgs = SvgIllustration;
    public readonly regInvoicingType = RealEstateGroupInvoicingType;

    constructor(
        private readonly uiSandbox: UiSandbox,
        private readonly invoicingSandbox: InvoicingSandbox,
        private readonly modalSandbox: ModalSandbox,
        public readonly featurePermissionsSandbox: FeaturePermissionsSandbox,
        private readonly translate: TranslateService
    ) {}

    public downloadInvoice(invoiceType: RealEstateGroupInvoicingType) {
        this.invoicingSandbox.getAsset(this.invoice.id, invoiceType).subscribe((safeResourceUrl) => {
            const event = new MouseEvent('click');
            this.download.nativeElement.setAttribute('download', this.invoice?.downloadName);
            this.download.nativeElement.setAttribute('href', safeResourceUrl);
            this.download.nativeElement.dispatchEvent(event);
        });
    }

    public disableDownloadLink(invoiceType: RealEstateGroupInvoicingType): boolean {
        if (invoiceType === RealEstateGroupInvoicingType.subscription) {
            return !this.invoice.subscriptionAsset;
        } else {
            return !this.invoice.asset;
        }
    }
}
