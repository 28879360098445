import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';
import { formControlNames } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.constants';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ExternalInfluencer, RxjsService, skipWhileNull } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable, combineLatest, zip } from 'rxjs';
import { concatMap, filter, map, startWith, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeatureDetailReviewsService extends RxjsService {
    public readonly form = this.formBuilder.group({
        [formControlNames.reviewsNotifications]: null,
    });
    public externalInfluencerSubject: BehaviorSubject<ExternalInfluencer> = new BehaviorSubject<ExternalInfluencer>(null);
    public externalInfluencer$: Observable<ExternalInfluencer> = this.externalInfluencerSubject.asObservable();

    public houseMatchUrl$ = combineLatest([
        this.externalInfluencer$.pipe(skipWhileNull()),
        this.translateService.onLangChange.pipe(
            startWith({ lang: this.translateService.currentLang }),
            map(({ lang }) => lang)
        ),
    ]).pipe(map(this.formatUrl(environment.houseMatchUri)));

    constructor(
        private readonly externalInfluencerService: ExternalInfluencerService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly translateService: TranslateService
    ) {
        super();
    }

    public init(): void {
        zip(this.externalInfluencerService.getDraft(), this.realEstateGroupSandbox.realEstateGroupOnce$).subscribe(this.onFetch);
    }

    private onFetch = ([externalInfluencer, realEstateGroup]): void => {
        this.externalInfluencerSubject.next(externalInfluencer);
        this.patchForm(realEstateGroup);
    };

    private patchForm({ featureScopesConfig }: RealEstateGroup): void {
        const { reviewsNotifications } = featureScopesConfig || {};
        const payload = {
            [formControlNames.reviewsNotifications]: reviewsNotifications?.join('; '),
        };
        this.form.patchValue(payload);
    }

    public submit(): void {
        const reviewsNotifications = this.form.getRawValue() as string[];

        this.externalInfluencerService
            .updateFeatureScopesConfig(reviewsNotifications)
            .pipe(concatMap(this.reloadConfigs))
            .subscribe(() => {
                this.notificationSandbox.updatedSuccess();
            });
    }

    private formatUrl(baseUrl: string): ([infl, lang]: [ExternalInfluencer, string]) => string {
        return ([influencer, lang]) => `${baseUrl}/${lang}/${influencer.url}`;
    }

    private reloadConfigs = (): Observable<RealEstateGroup> =>
        this.realEstateGroupSandbox.getRealEstateGroupForMe().pipe(
            filter((x) => !!x),
            tap((realEstateGroup) => this.realEstateGroupSandbox.setRealEstateGroup(realEstateGroup))
        );
}
