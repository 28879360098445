import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { CheckInput } from '@smooved/ui';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-solar-panel',
    template: `
        <form [formGroup]="form" #formElement="ngForm" class="u-flex-column u-flex-align-items-start u-w100p">
            <app-unit-input
                width="auto"
                [label]="'ENERGY.SOLAR_PANEL.POWER_INVERTER.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [unit]="'ENERGY.SOLAR_PANEL.POWER_INVERTER.SUFFIX' | translate"
                class="u-margin-bottom-half"
                formControlName="convertPowerSolarPanels"
            ></app-unit-input>
            <p class="u-font-size-small u-color-muted u-margin-bottom">{{ 'ENERGY.SOLAR_PANEL.POWER_INVERTER.SCALE' | translate }}</p>

            <app-check-input formControlName="convertPowerSolarPanelsUnknown" [custom]="false" [option]="option"></app-check-input>

            <p class="u-margin-bottom-double u-color-muted u-font-size-small u-font-style-italic">
                {{ 'ENERGY.SOLAR_PANEL.DISCLOSURE' | translate }}
            </p>
        </form>
    `,
})
export class SolarPanelComponent implements OnInit, OnDestroy {
    @ViewChild('formElement', { static: true }) public formGroupDirective: FormGroupDirective;

    public option: CheckInput<boolean> = {
        id: 'power-inverter-unknown',
        label: this.translateService.instant('ENERGY.SOLAR_PANEL.POWER_INVERTER_UNKNOWN'),
        name: 'power-inverter-unknown',
        value: true,
    };

    public form: UntypedFormGroup = this.formBuilder.group({
        convertPowerSolarPanels: [null, [Validators.min(1), Validators.max(50)]],
        convertPowerSolarPanelsUnknown: [true],
    });

    private subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private changeDetectionRef: ChangeDetectorRef,
        private moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.energyOfferOnce$.subscribe((energyOffer) => {
            if (energyOffer?.hasOwnProperty('convertPowerSolarPanels')) {
                this.convertPowerSolarPanelsFormControl().patchValue(energyOffer?.convertPowerSolarPanels);
            }
            if (energyOffer?.hasOwnProperty('convertPowerSolarPanelsUnknown')) {
                this.convertPowerSolarPanelsUnknownFormControl().patchValue(energyOffer?.convertPowerSolarPanelsUnknown);
            }
        });

        this.subscriptions.push(
            this.convertPowerSolarPanelsFormControl().valueChanges.subscribe((value) => {
                if (value?.toString()?.length) {
                    this.convertPowerSolarPanelsUnknownFormControl().patchValue(false);
                }
            }),
            this.convertPowerSolarPanelsUnknownFormControl().valueChanges.subscribe((value) => {
                if (value) {
                    this.convertPowerSolarPanelsFormControl().setValidators([Validators.min(1), Validators.max(50)]);
                    this.convertPowerSolarPanelsFormControl().patchValue(null);
                } else {
                    this.convertPowerSolarPanelsFormControl().setValidators([Validators.min(1), Validators.max(50), Validators.required]);
                }
                this.convertPowerSolarPanelsFormControl().updateValueAndValidity({
                    emitEvent: false,
                });
            })
        );
    }

    public markAsSubmitted(): void {
        this.formGroupDirective.onSubmit(null);
    }

    public getFormValue(): any {
        return this.form.value;
    }

    private convertPowerSolarPanelsFormControl(): AbstractControl {
        return this.form?.get('convertPowerSolarPanels');
    }

    private convertPowerSolarPanelsUnknownFormControl(): AbstractControl {
        return this.form?.get('convertPowerSolarPanelsUnknown');
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }
}
