import { Component } from '@angular/core';
import { BaseDashboardCardComponent } from '@smooved/ui';

@Component({
    selector: 'app-iots',
    template: `
        <app-dashboard-card
            [first]="true"
            height="100%"
            class="u-h100p"
            [title]="'REAL_ESTATE_AGENT.START.IOTS.TITLE' | translate"
            [text]="'REAL_ESTATE_AGENT.START.IOTS.TEXT' | translate"
            [hasBorderBottom]="true"
            imageName="smooved-insurance.png"
            [last]="true"
        >
            <ng-container up-to-and-including-phone-landscape>
                <div class="u-flex-column u-flex-align-items-stretch">
                    <ng-container [ngTemplateOutlet]="actions"></ng-container>
                </div>
            </ng-container>
            <ng-container tablet-portrait-up>
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </ng-container>
        </app-dashboard-card>

        <ng-template #actions>
            <app-button theme="secondary" [routerLink]="'/ots/iots'">{{ 'REAL_ESTATE_AGENT.START.IOTS.NEW' | translate }}</app-button>
        </ng-template>
    `,
})
export class IotsComponent extends BaseDashboardCardComponent {}
