<div class="__todo-template-header u-flex-responsive u-flex-space-between u-w100p u-flex-justify-content-space-between">
    <div *ngIf="labelElement" [outerHTML]="labelElement | sanitize: 'html'"></div>
    <div><ng-content select="[todoTemplateLabel]"></ng-content></div>
    <div class="__todo-template-cta">
        <ng-content select="a[app-button], [todo-cta]"></ng-content>
    </div>
</div>
<div class="u-color-muted u-font-size-small">
    <ng-content></ng-content>
</div>
