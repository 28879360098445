import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LogAction, tagSvgMap } from '@app/escalation/constants/escalation.constants';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { EscalationUtils } from '@app/escalation/escalation.utils';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { EscalationModalService } from '@app/escalation/services/escalation-modal.service';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { MoveModalSandbox } from '@app/move/sandboxes/move-modal.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { AuditUtils, dateTimeFormatDefault } from '@smooved/core';
import { ButtonAppearance, UiContext, UiPlacement } from '@smooved/ui';
import { maxContentChars } from './escalation-detail.constants';

@Component({
    selector: 'app-escalation-detail',
    templateUrl: 'escalation-detail.component.html',
    styleUrls: ['escalation-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EscalationDetailComponent implements OnInit {
    private _escalation: Escalation;
    @Input()
    public set escalation(value: Escalation) {
        this._escalation = value;
        this.logActions = EscalationUtils.mapLogActions(value);
    }

    public get escalation(): Escalation {
        return this._escalation;
    }

    public collapsed = false;
    public logActions: LogAction[] = [];

    public readonly escalationState = EscalationState;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly tagSvgMap = tagSvgMap;
    public readonly uiPlacement = UiPlacement;
    public readonly contactLogType = ContactLogType;
    public readonly dateTimeFormatDefault = dateTimeFormatDefault;
    public readonly maxContentChars = maxContentChars;

    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly escalationModalService: EscalationModalService,
        private readonly moveModalSandbox: MoveModalSandbox
    ) {}

    public ngOnInit(): void {
        this.collapsed = AuditUtils.getValue(this.escalation.state) === EscalationState.Closed;
    }

    public openEditModal(): void {
        this.escalationModalService.update(this.escalation);
    }

    public openCustomEmail(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            const input = {
                move,
                escalation: this.escalation,
            };
            this.moveModalSandbox.showCustomMessage(input, this.handleCustomMessageClosed);
        });
    }

    public toggleDetails(): void {
        this.collapsed = !this.collapsed;
    }

    public openLogAction(): void {
        this.escalationModalService.openLogAction(this.escalation);
    }

    public showContactLogInfo(contactLog: ContactLog): void {
        this.moveModalSandbox.showContactLogInfo(contactLog);
    }

    private handleCustomMessageClosed = (result: boolean): void => {
        if (!result) return;
        this.moveSandbox.updatedMoveTrigger.next();
    };
}
