import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { ButtonAppearance, SvgIllustration, TodoState, UiContext, UiDirection } from '@smooved/ui';
import { MeterInfoSuccessState } from './meter-info-success.constants';

@Component({
    selector: 'app-meter-info-success',
    templateUrl: './meter-info-success.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterInfoSuccessComponent {
    public readonly illustrations = SvgIllustration;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
    public readonly uiDirection = UiDirection;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly todoStates = TodoState;
    public readonly successStates = MeterInfoSuccessState;

    public state: MeterInfoSuccessState;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { state: MeterInfoSuccessState }, private readonly navigation: AppNavigationSandbox) {
        this.state = this.data.state;
    }

    public createLeaverRoute = this.navigation.getCreateLeaverRoute();
}
