<app-card [bordered]="false" [paddingDouble]="false">
    <app-loading-container [loading]="loading$ | async">
        <table
            mat-table
            matSort
            [matSortActive]="defaultSortProperty"
            [matSortDirection]="defaultSortDirection"
            matSortDisableClear
            [dataSource]="invoices"
            class="u-w100p __table"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'ADMIN.BILLING.TABLE.NAME' | translate }}</th>
                <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row?.realEstateGroup?.name }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell class="u-padding-left __column-status" *matHeaderCellDef>
                    {{ 'ADMIN.BILLING.TABLE.STATUS' | translate }}
                </th>
                <td mat-cell class="u-padding-left __column-status" *matCellDef="let row">
                    <!-- ngModelChange before ngModel! Otherwise we don't now the previous value -->
                    <mat-select [disableRipple]="true" (ngModelChange)="onStatusChange($event, row)" [(ngModel)]="row.status.value">
                        <mat-option *ngFor="let status of statusOptions" [value]="status.value">{{
                            status.labelResource | translate
                        }}</mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="comission">
                <th mat-header-cell class="u-padding-left __column-amount" *matHeaderCellDef>
                    {{ 'ADMIN.BILLING.TABLE.COMMISSION' | translate }}
                </th>
                <td mat-cell class="u-padding-left __column-amount" *matCellDef="let row">
                    {{ row?.version === invoiceVersion.v2 ? (row?.totalCommissions | price) : '' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="subscription">
                <th mat-header-cell class="u-padding-left __column-amount" *matHeaderCellDef>
                    {{ 'ADMIN.BILLING.TABLE.SUBSCRIPTION' | translate }}
                </th>
                <td mat-cell class="u-padding-left __column-amount __j-subscription-cost" *matCellDef="let row">
                    {{ row?.version === invoiceVersion.v2 ? (row?.totalSubscriptionCostInclusiveDiscounts | price) : '' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="feeTotal">
                <th mat-header-cell mat-sort-header class="u-padding-left __column-amount" *matHeaderCellDef>
                    {{ 'ADMIN.BILLING.TABLE.AMOUNT' | translate }}
                </th>
                <td mat-cell class="u-padding-left __column-amount" *matCellDef="let row">{{ row?.feeTotal | price }}</td>
            </ng-container>

            <ng-container matColumnDef="asset.createdOn">
                <th mat-header-cell class="u-padding-left __column-amount" *matHeaderCellDef>
                    {{ 'ADMIN.BILLING.TABLE.UPLOADED_ON' | translate }}
                </th>
                <td mat-cell class="u-padding-left __column-amount" *matCellDef="let row">
                    {{ row?.asset?.createdOn | formatDate }}
                </td>
            </ng-container>

            <ng-container matColumnDef="options">
                <th mat-header-cell class="u-padding-left __column-options" *matHeaderCellDef></th>
                <td mat-cell class="u-padding-left __column-options" *matCellDef="let row">
                    <div class="u-flex-row u-flex-align-items-center">
                        <a
                            class="u-display-inline-flex u-flex-align-items-center u-flex-justify-content-center"
                            app-button
                            [appearance]="buttonAppearance.Icon"
                            [context]="context.Info"
                            [title]="'ADMIN.BILLING.TABLE.OPTIONS.DETAILS' | translate : { realtor: row?.realEstateGroup?.name }"
                            icon="info"
                            [routerLink]="'../financial-report/' + row?.realEstateGroup?._id + '/quarter-report'"
                        ></a>

                        <div class="__icon-wrapper u-display-inline-flex u-flex-align-items-center u-flex-justify-content-center">
                            <app-svg-illustration
                                *ngIf="row.asset"
                                [svg]="svgIllustration.Download"
                                class="__download-icon u-cursor-pointer"
                                [title]="'ADMIN.BILLING.TABLE.OPTIONS.COMMISSION' | translate : { realtor: row?.realEstateGroup?.name }"
                                (click)="downloadFile(row)"
                            >
                                <a #download class="u-display-none" download></a>
                            </app-svg-illustration>
                        </div>

                        <div
                            class="__icon-wrapper u-display-inline-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
                        >
                            <app-svg-illustration
                                *ngIf="!row.subscriptionAsset"
                                [svg]="svgIllustration.Upload"
                                class="__upload-icon"
                                [title]="
                                    'ADMIN.BILLING.TABLE.OPTIONS.SUBSCRIPTION.CTA' | translate : { realtor: row?.realEstateGroup?.name }
                                "
                                (click)="uploadSubscriptionInvoice(row)"
                            ></app-svg-illustration>
                            <app-svg-illustration
                                *ngIf="row.subscriptionAsset"
                                [svg]="svgIllustration.UploadComplete"
                                [context]="context.CoBrand"
                                [title]="
                                    'ADMIN.BILLING.TABLE.OPTIONS.SUBSCRIPTION.COMPLETE'
                                        | translate : { realtor: row.realEstateGroup?.name, fileName: row.subscriptionAsset.name }
                                "
                                class="__upload-complete-icon"
                            ></app-svg-illustration>
                        </div>

                        <button
                            *ngIf="row?.asset"
                            app-button
                            [appearance]="buttonAppearance.Icon"
                            [context]="context.Warning"
                            icon="delete"
                            [title]="'ADMIN.BILLING.TABLE.OPTIONS.RESET' | translate : { realtor: row?.realEstateGroup?.name }"
                            (click)="delete(row)"
                        ></button>
                    </div>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
    </app-loading-container>
    <mat-paginator
        [length]="invoicesCount"
        [pageSize]="pageSizeOptions[0]"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="pageSizeOptions"
    ></mat-paginator>
</app-card>
