import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as adminRouting from '@app/admin/constants/routing.constants';
import { realEstateAgentLoginRouting } from '@app/authentication/constants/routing.constants';
import { LoginDialog } from '@app/authentication/dialogs/login/login.dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { environment } from '@environments/environment';
import { ButtonAppearance, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent {
    public faqUri = environment.faqUri;
    public adminUri = adminRouting.adminBaseRoute;
    public realEstateAgentLoginRouting = AppNavigationSandbox.getAbsolute(realEstateAgentLoginRouting);

    @Input() fontSizeSmall: boolean;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;

    constructor(public readonly authenticationSandbox: AuthenticationSandbox, private readonly dialog: MatDialog) {}

    public login(): void {
        this.dialog.open(LoginDialog);
    }
}
