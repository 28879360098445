import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-text-modal',
    template: `
        <app-closable-modal-template width="480px">
            <div class="__content" [innerHTML]="data"></div>
        </app-closable-modal-template>
    `,
    styles: [
        `
                    .__content {
                        white-space: pre-wrap;
                        word-break: break-word;
                    }
                `,
    ],
})
export class TextModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
