import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-consent-modal',
    template: `
        <app-closable-modal-template>
            <h3 class="u-margin-bottom u-padding-right-double">
                {{ 'CONSENT_MODAL.TITLE' | translate }}
            </h3>
            <p [innerHTML]="'CONSENT_MODAL.TEXT' | translate: { uri: privacyUri }"></p>
        </app-closable-modal-template>
    `,
})
export class ConsentModalComponent {
    public privacyUri: string;

    constructor(public dialogRef: MatDialogRef<ConsentModalComponent>, private translationSandbox: TranslationSandbox) {
        this.translationSandbox.selectedOnce$.subscribe((lang) => (this.privacyUri = environment.privacyUri[lang]));
    }
}
