import { PackageId } from '@app/package/enums/package-id.enum';
import { AppI18nKeyType, i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { ActivationStage, I18nKeyType, I18nKeyUtils } from '@smooved/core';
import { DropdownInput, MenuItem } from '@smooved/ui';

export enum Menu {
    Account,
    Activity,
    Features,
    Invoices,
    HouseMatch,
    Widgets,
}

export const activationStageOptions: DropdownInput<ActivationStage>[] = EnumUtils.keys(ActivationStage).map((key) => ({
    id: key,
    name: 'activationStage',
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, I18nKeyType.RealEstateGroupActivationStage, key),
    value: ActivationStage[key],
}));

export const menuItems: MenuItem[] = [
    {
        labelResource: 'REAL_ESTATE_GROUP.ACCOUNT.LABEL',
        goTo: Menu.Account,
    },
    {
        labelResource: 'REAL_ESTATE_GROUP.ACTIVITY.LABEL',
        goTo: Menu.Activity,
    },
    {
        labelResource: 'REAL_ESTATE_GROUP.FEATURES.LABEL',
        goTo: Menu.Features,
    },
    {
        labelResource: 'REAL_ESTATE_GROUP.INVOICES.LABEL',
        goTo: Menu.Invoices,
    },
    {
        labelResource: 'REAL_ESTATE_GROUP.HOUSEMATCH.LABEL',
        goTo: Menu.HouseMatch,
    },
    {
        labelResource: 'ADMIN.REAL_ESTATE_GROUPS.WIDGETS.LABEL',
        goTo: Menu.Widgets,
    },
];

export enum FormControlNames {
    ActivationStage = 'activationStage',
    AccountManager = 'accountManager',
}

export const bivAgentActionLabels = {
    addAgent: I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.BivAgents, 'ADD_AGENT'),
    editAgent: I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.BivAgents, 'EDIT_AGENT'),
    confirmDeleteAgent: I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.BivAgents, 'DELETE_AGENT.CONFIRMATION'),
};

export const showBillingSettings = (pack: PackageId): boolean => [PackageId.SmoovedPlus, PackageId.HouseMatchVerified].includes(pack);
