import { Injectable, OnDestroy } from '@angular/core';
import {
    CoreUtils,
    DbUtils,
    DomService,
    Note,
    NpsReview,
    npsReviewsElementId,
    PaginationRequest,
    PaginationResponse,
    RxjsService,
} from '@smooved/core';
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { exhaustMap, filter, skipUntil, skipWhile, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PaginationService } from '../../../pagination/services/pagination.service';
import { defaultFilter } from '../../constants/reviews-filter.constants';
import { ReviewsDataSource } from '../../datasources/reviews.data-source';
import { AuthorInterpolationParams } from '../../interfaces/author-interpolation-params';
import { EmittableReviewsFilter, ReviewsFilter } from '../../interfaces/reviews-filter';
import { NpsReviewsFilterService } from '../../services/nps-reviews-filter.service';

@Injectable()
export abstract class NpsReviewsTemplateService extends RxjsService implements OnDestroy {
    public filterLoadingSubject = new BehaviorSubject<boolean>(false);
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public filterLoading$ = this.filterLoadingSubject.asObservable();
    public start$ = new Subject<boolean>();
    public requestInitialLoad = true;

    private setPaginationTotal = tap((x: number) => (this.paginationService.totalItems = x));
    public abstract isPublicMode: boolean;

    public handleReload = exhaustMap(() => this.refresh());

    private resetFirstPage = tap(() => this.paginationService.firstPage());
    private filterLoading = tap<EmittableReviewsFilter>(() => this.filterLoadingSubject.next(true));

    constructor(
        protected readonly filterService: NpsReviewsFilterService,
        protected readonly paginationService: PaginationService,
        protected readonly domService: DomService,
        public readonly dataSource: ReviewsDataSource
    ) {
        super();
        this.init();
    }

    private init(): void {
        const pageChanged$ = this.paginationService.page$.pipe(skipUntil(this.start$), this.onChange());
        const totalChanged$ = this.dataSource?.reviewsCount$.pipe(this.setPaginationTotal);
        const filterChanged$ = this.filterService.filter$.pipe(
            startWith({ filter: this.filterService.filter, emit: true }),
            skipWhile((filter) => filter.filter === defaultFilter),
            filter((filter) => filter.emit),
            this.filterLoading,
            this.resetFirstPage
        );
        combineLatest([pageChanged$, filterChanged$, totalChanged$]).pipe(takeUntil(this.destroy$)).subscribe();
    }

    public setFirstPage(): void {
        if (this.requestInitialLoad) {
            this.start$.next(true);
            this.paginationService.firstPage();
        } else {
            this.paginationService.firstPage();
            this.start$.next(true);
        }
    }

    public loadNext(): void {
        this.paginationService.nextPage();
    }

    /**
     * Hook function which is called by the edit button of the note.component
     * @param {NpsReview} review - the review containing the note.
     * @param {Note} note - the review note to be edited.
     */
    public onEdit(_review: NpsReview, _note: Note): void {
        /** */
    }

    /**
     * Hook function which is called by the template to capture the author interpolation parameters
     */
    public getAuthorInterpolationParams(_note: Note, _review: NpsReview): Observable<AuthorInterpolationParams> {
        return of(undefined);
    }

    public preset(reviews: NpsReview[], count?: number): void {
        this.requestInitialLoad = false;
        this.dataSource.preset(reviews, count);
    }

    public getPreload(reviewId?: string): PaginationResponse<NpsReview> {
        const preloaded = this.domService.getDataElement<PaginationResponse<NpsReview>>(npsReviewsElementId);
        if (!!preloaded && !CoreUtils.isEmpty(reviewId)) {
            return preloaded.data?.length === 1 && DbUtils.getStringId(preloaded.data[0]) === reviewId ? preloaded : undefined;
        }
        return preloaded;
    }

    public load(filter: ReviewsFilter, paginationOptions: PaginationRequest, extend: boolean): Observable<void> {
        this.loadingSubject.next(true);
        return this.dataSource?.load(filter, paginationOptions, extend).pipe(tap(this.completeLoading));
    }

    public refresh(): Observable<void> {
        const reloaded = new Subject<void>();
        this.load(this.filterService.filter, this.paginationService.getReloadPaginationOptions(), false)
            .pipe(take(1))
            .subscribe((value) => reloaded.next(value));
        return reloaded.asObservable();
    }

    private onChange(): OperatorFunction<unknown, void> {
        return switchMap(() => {
            const extend = this.paginationService.currentPageIndex() > 0;
            return this.load(this.filterService.filter, this.paginationService.getPaginationOptions(), extend);
        });
    }

    private completeLoading = (): void => {
        this.filterLoadingSubject.next(false);
        this.loadingSubject.next(false);
    };

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.start$.complete();
    }
}

@Injectable()
export class AuthenticatedNpsReviewsTemplateService extends NpsReviewsTemplateService {
    public isPublicMode = false;
}

@Injectable()
export class PublicNpsReviewsTemplateService extends NpsReviewsTemplateService {
    public isPublicMode = true;
}
