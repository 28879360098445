import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';

@Component({
    selector: 'app-invoice-not-available',
    template: `
        <app-card [bordered]="false">
            <header class="u-flex-row u-flex-align-items-center u-margin-bottom-double u-flex-justify-content-space-between">
                <div>
                    <h4>
                        {{
                            'INVOICING.INVOICE.TITLE'
                                | translate
                                    : {
                                          quarter: invoice?.quarter,
                                          year: invoice?.year
                                      }
                        }}
                    </h4>
                    <span class="u-color-muted u-font-size-small">{{
                        'INVOICING.INVOICE.AVAILABLE_FROM' | translate: { date: invoice.availableFrom | formatDate }
                    }}</span>
                </div>

                <app-button [disabled]="true">{{ 'INVOICING.UPLOAD_INVOICE' | translate }}</app-button>
            </header>

            <main class="u-text-align-center u-margin-bottom">
                <p class="u-font-size-large u-margin-bottom-half">{{ 'INVOICING.INVOICE.NOT_AVAILABLE.TITLE' | translate }}</p>
                <p class="u-color-muted">{{ 'INVOICING.INVOICE.NOT_AVAILABLE.TEXT' | translate }}</p>
            </main>
        </app-card>
    `,
    styleUrls: ['./invoice-not-available.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceNotAvailableComponent {
    @Input() public invoice: Invoice;

    public invoiceStatus = InvoiceStatus;
}
