import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Fee } from '@app/move/enums/fee.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { Move } from '@app/move/interfaces/move';
import { TranslateService } from '@ngx-translate/core';
import { DropdownInput } from '@smooved/ui';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-fee',
    template: `
        <div class="u-flex-row u-flex-align-items-center" *ngIf="!!move?.fee; else empty">
            <div class="u-background-circle-{{ move?.fee?.toLowerCase() }} u-margin-right-half"></div>
            <span [class.u-text-line-through]="!!move?.feeByAdmin && canEdit">{{
                'MOVE.FEE.' + move?.fee?.toUpperCase() + '.LABEL' | translate
            }}</span>
            <span *ngIf="showPrice$ | async" [class.u-text-line-through]="!!move?.feeByAdmin && canEdit" class="u-margin-left-half"
                >({{ 'MOVE.FEE.' + move?.fee?.toUpperCase() + '.PRICE' | translate }})</span
            >
        </div>
        <div class="u-flex-row u-flex-align-items-center" *ngIf="!!move?.feeByAdmin && canEdit">
            <div class="u-background-circle-{{ move?.feeByAdmin?.toLowerCase() }} u-margin-right-half"></div>
            <span>{{ 'MOVE.FEE.' + move?.feeByAdmin?.toUpperCase() + '.LABEL' | translate }}</span>
            <span *ngIf="showPrice$ | async" class="u-margin-left-half"
                >({{ 'MOVE.FEE.' + move?.feeByAdmin?.toUpperCase() + '.PRICE' | translate }} )</span
            >
        </div>

        <div *ngIf="!!move?.fee && canEdit" [ngSwitch]="editMode">
            <form [formGroup]="form" class="u-margin-top-half" *ngSwitchCase="true">
                <ui-dropdown-input [options]="feeOptions" formControlName="feeByAdmin"></ui-dropdown-input>
            </form>

            <a (click)="setEditMode()" *ngSwitchCase="false" class="u-link-muted u-font-size-small">{{ 'EDIT' | translate }}</a>

            <div class="u-flex-row u-flex-align-items-center" *ngSwitchCase="true">
                <app-button (onClick)="setEditMode()" class="u-margin-right-half u-font-size-small">{{ 'UPDATE.LABEL' | translate }}</app-button>
                <app-button
                    (onClick)="removeFee()"
                    theme="danger"
                    *ngIf="!!move?.feeByAdmin"
                    class="u-margin-right-half u-font-size-small"
                    >{{ 'COMMON.DELETE' | translate }}</app-button
                >
                <a (click)="editMode = false" class="u-link-muted u-font-size-small">{{ 'CANCEL' | translate }}</a>
            </div>
        </div>

        <ng-template #empty>
            <div [title]="">-</div>
        </ng-template>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeeComponent {
    @Input() public move: Move;
    @Input() public showPrice: boolean;
    @Input() public canEdit: boolean;

    public editMode = false;

    public showPrice$ = this.realEstateGroupSandbox.name$.pipe(map((name) => this.showPrice && name !== 'Mi-Concept'));

    public feeOptions: DropdownInput<Fee>[] = [
        {
            id: 'fee-none',
            label: this.translateService.instant('MOVE.FEE.NONE.LABEL'),
            name: 'fee',
            value: Fee.None,
        },
        {
            id: 'fee-bronze',
            label: this.translateService.instant('MOVE.FEE.BRONZE.LABEL'),
            name: 'fee',
            value: Fee.Bronze,
        },
        {
            id: 'fee-silver',
            label: this.translateService.instant('MOVE.FEE.SILVER.LABEL'),
            name: 'fee',
            value: Fee.Silver,
        },
        {
            id: 'fee-gold',
            label: this.translateService.instant('MOVE.FEE.GOLD.LABEL'),
            name: 'fee',
            value: Fee.Gold,
        },
    ];

    public form: UntypedFormGroup = this.formBuilder.group({
        feeByAdmin: [null],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private realEstateGroupSandbox: RealEstateGroupSandbox,
        private translateService: TranslateService,
        private moveSandbox: MoveSandbox,
        private cdr: ChangeDetectorRef
    ) {}

    public setEditMode() {
        this.editMode = !this.editMode;
        if (this.editMode) {
            this.form.get('feeByAdmin').patchValue(this.move.feeByAdmin);
        } else {
            this.moveSandbox.patchMove(this.move?._id, { feeByAdmin: this.form.get('feeByAdmin').value }, true, '', (move) => {
                this.move = move;
                this.cdr.detectChanges();
            });
        }
    }

    public removeFee() {
        this.editMode = false;
        this.form.get('feeByAdmin').patchValue(null);
        this.moveSandbox.patchProperty(
            'feeByAdmin',
            null,
            true,
            (move) => {
                this.move = move;
                this.cdr.detectChanges();
            },
            false,
            this.move,
            true
        );
    }
}
