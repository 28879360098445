import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-suggestion-selection-telecom-transfer',
    template: `
        <table>
            <tr>
                <td class="u-vertical-align-middle u-padding-right-half u-text-align-right" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                    <img src="assets/images/smooved-telecom.png" width="50px" class="__image u-display-block" />
                </td>

                <td class="u-vertical-align-middle u-w100p">
                    <div class="u-flex-column u-flex-align-items-start">
                        <h5>{{ 'TELECOM_TRANSFER' | translate }}</h5>
                        <p class="u-color-muted">
                            {{ (moveSandbox.telecomTransfer$ | async)?.currentSupplier }} -
                            {{ (moveSandbox.telecomTransfer$ | async)?.clientNumber }} -
                            {{ (moveSandbox.telecomTransfer$ | async)?.fixedLine }}
                        </p>
                    </div>
                </td>
            </tr>
        </table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionTelecomTransferComponent {
    constructor(public moveSandbox: MoveSandbox, public uiSandbox: AppUiSandbox) {}
}
