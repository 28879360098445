import { Injectable } from '@angular/core';
import { AnalyticsSandbox } from '@app/analytics/analytics.sandbox';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NoopAction, ObjectUtils, RealEstateAgentUtils } from '@smooved/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RealEstateAgentService } from '../services/real-estate-agent.service';
import {
    getAllSuccess,
    getBadgeCountsSuccess,
    GetDetails,
    getDetailsFailure,
    getDetailsSuccess,
    Patch,
    patchFailure,
    patchSuccess,
    PatchSuccess,
    RealEstateAgentActionTypes,
    SetFilter,
    setFilterSuccess,
    SetFilterSuccess,
} from './real-estate-agents.actions';


@Injectable()
export class RealEstateAgentsEffects {

    public getDetails$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.Get),
        mergeMap(({ id }: GetDetails) =>
            this.realEstateAgentService.get(id).pipe(
                map((realEstateAgent) => getDetailsSuccess({ realEstateAgent })),
                catchError(() => of(getDetailsFailure()))
            )
        )
    ));

    public getAll$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.GetAll),
        mergeMap((_) => this.realEstateAgentService.getAll().pipe(map((response) => getAllSuccess({ realEstateAgents: response }))))
    ));

    public patch$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.Patch),
        mergeMap((action: Patch) => {
            const patch = { ...action.realEstateAgent };
            ObjectUtils.removeEmpty(patch);
            return this.realEstateAgentService.patch(action.realEstateAgentId, patch).pipe(
                map((realEstateAgent) => patchSuccess({ realEstateAgent, callback: action.callback })),
                catchError((error) => of(patchFailure()))
            );
        })
    ));

    public patchSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.PatchSuccess),
        tap((action: PatchSuccess) => {
            if (action.callback) {
                action.callback();
            }
        })
    ), { dispatch: false });

    public setFilterAndPersist$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.SetFilter),
        withLatestFrom(this.authenticationSandbox.realEstateAgentId$),
        mergeMap(([action, realEstateAgentId]: [SetFilter, string]) => {
            if (action?.persist) {
                return this.realEstateAgentService
                    .patch(realEstateAgentId, {
                        filter: RealEstateAgentUtils.persistFilterFactory(action.filter),
                    })
                    .pipe(
                        map((_) => setFilterSuccess({ callback: action.callback })),
                        catchError((_) => of(new NoopAction()))
                    );
            } else {
                return of(setFilterSuccess({ callback: action.callback }));
            }
        })
    ));

    public setFilterSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.SetFilterSuccess),
        tap((action: SetFilterSuccess) => {
            if (action.callback) {
                action.callback();
            }
        })
    ), { dispatch: false });

    public getBadgeCounts$ = createEffect(() => this.actions$.pipe(
        ofType(RealEstateAgentActionTypes.GetBadgeCounts),
        switchMap((_) => {
            return this.analyticsSandbox.getNpsUnreadCount().pipe(
                map((npsUnreadReviews) =>
                    getBadgeCountsSuccess({
                        unreadReviews: npsUnreadReviews,
                    })
                ),
                catchError((_) => of(new NoopAction()))
            );
        })
    ));

    constructor(
        private actions$: Actions,
        private authenticationSandbox: AuthenticationSandbox,
        private realEstateAgentService: RealEstateAgentService,
        private analyticsSandbox: AnalyticsSandbox
    ) {}
}
