import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { HeaderPartnerAgentMenuService } from '@app/header/components/header-partner-agent-menu/header-partner-agent-menu.service';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { filter, first, mapTo, switchMap, tap } from 'rxjs/operators';
import { FeatureScopeSandbox } from '@smooved/core';

@Injectable({
    providedIn: 'root',
})
export class PartnerAgentAuthGuard {
    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly headerService: HeaderPartnerAgentMenuService,
        private readonly featureScopeSandbox: FeatureScopeSandbox
    ) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationSandbox.isPartnerAgent$.pipe(
            tap((isPartnerAgent) => {
                if (!isPartnerAgent) {
                    void this.navigationSandbox.goToPartnerAgentLogin();
                }
            }),
            switchMap(() => this.featureScopeSandbox.featureScopes$),
            filter((featureScopes) => !!featureScopes.length),
            first(),
            tap((featureScopes) => this.headerService.setupByScope(featureScopes[0])),
            mapTo(true)
        );
    }
}
