import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { WaterDocumentMovingAddressModal } from '@app/water/modals/water-document-moving-address/water-document-moving-address.modal';
import { ModalSandbox, Svg } from '@smooved/ui';

@Component({
    selector: 'app-water-transfer-documents',
    template: `
        <div [ngSwitch]="!!move?.moveStates?.waterDocumentsMovingAddressByRealEstateAgent">
            <div
                class="u-flex-row u-flex-align-items-center"
                [ngClass]="{ 'u-color-muted': !move?.moveStates?.waterDocumentsMovingAddressByRealEstateAgent }"
            >
                <div *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{
                        'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_REAL_ESTATE_AGENT.OK' | translate
                    }}</span>
                </div>
                <div *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{
                            'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_REAL_ESTATE_AGENT.NOK' | translate
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <app-button (onClick)="showWaterDocuments()" theme="secondary-outline">{{
                    'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_REAL_ESTATE_AGENT.NOK.CTA' | translate
                }}</app-button>
            </div>
        </div>
    `,
})
export class WaterTransferDocumentsComponent extends BaseServiceActionComponent {
    @Input() public move: Move;
    public emptySvg = Svg.Empty;

    constructor(
        private readonly modalSandbox: ModalSandbox,
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public showWaterDocuments(): void {
        const data: ModalDataMove = {
            move: this.move,
        };

        this.modalSandbox.openModal(null, null, null, WaterDocumentMovingAddressModal, { data }, () => {});
    }
}
