import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    SkipSelf,
    ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthUtils } from '@app/authentication/auth.utils';
import { AuthUser, DbUtils, SimpleChangesUtils, StringUtils } from '@smooved/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Movers } from '@app/move/interfaces/movers';
import { MoveService } from '@app/move/services/move.service';
import { RealEstateAgentService } from '@app/real-estate-agent/services/real-estate-agent.service';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { BaseInput, UiContext } from '@smooved/ui';
import { BehaviorSubject, combineLatest, noop, Observable, of, zip } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { meterReadingsTakeoverOption, TakeOverFormControlNames, TakeOverInformationForm } from './takeover-information-consent.contants';
import { RealEstateAgentPartial } from '@app/real-estate-agent/interfaces/real-estate-agents-grouped-by-location';

@Component({
    selector: 'smvd-app-takeover-information-consent',
    templateUrl: 'takeover-information-consent.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TakeoverInformationConsentComponent),
            multi: true,
        },
    ],
    styleUrls: ['./takeover-information-consent.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TakeoverInformationConsentComponent extends BaseInput implements OnInit, ControlValueAccessor, OnChanges {
    private agentSubject = new BehaviorSubject<RealEstateAgentPartial>(null);

    @Input() public readonly movers: Movers;
    @Input() public readonly context: UiContext = UiContext.Muted;
    @Input() @HostBinding('class.u-opacity-30') public readonly hide: boolean;

    public readonly fields = TakeOverFormControlNames;
    public readonly uiContext = UiContext;
    public readonly missingInformationForm = this.formBuilder.group({});
    public readonly meterReadingsTakeoverForm = this.formBuilder.group({
        meterReadingsTakeoverFormControl: [false],
    });
    public readonly appI18nKeyType = AppI18nKeyType;
    public hasMissingReaInfo: boolean;
    public hasMissingLeaverInfo: boolean;

    public meterReadingsTakeoverOption = meterReadingsTakeoverOption;

    public checkInputId = StringUtils.getRandomString();

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateAgentService: RealEstateAgentService,
        private readonly moveService: MoveService,
        private readonly changeDetectionRef: ChangeDetectorRef
    ) {
        super(controlContainer);
    }

    public ngOnChanges({ hide }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(hide)) {
            this.checkShowState();
        }
    }

    private checkShowState(): void {
        this.hide ? this.disable() : this.enable();
    }

    private enable(): void {
        this.missingInformationForm.enable();
        this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').enable();
    }

    private disable(): void {
        this.missingInformationForm.disable();
        this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').patchValue(false);
        this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').disable();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        combineLatest(
            this.missingInformationForm.valueChanges,
            this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').valueChanges
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.propagateChange(
                    this.missingInformationForm.valid && this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').value
                );
            });
        this.initSectionForLeaver();
        this.initSectionForRea();

        if (this.controlContainer) this.controlContainer.control.markAllAsTouched = (): void => this.markAllAsTouched();
        else this.formControl.markAllAsTouched = (): void => this.markAllAsTouched();
    }

    private initSectionForLeaver(): void {
        const { firstName, lastName } = this.movers?.leaver?.user || {};
        this.hasMissingLeaverInfo = this.movers?.leaver && (!firstName || !lastName);
        if (this.hasMissingLeaverInfo) {
            this.missingInformationForm.addControl(
                TakeOverFormControlNames.LeaverFirstName,
                this.formBuilder.control(firstName, Validators.required)
            );
            this.missingInformationForm.addControl(
                TakeOverFormControlNames.LeaverLastName,
                this.formBuilder.control(lastName, Validators.required)
            );
        }
    }

    private initSectionForRea(): void {
        this.authenticationSandbox.authorizationOnce$
            .pipe(
                mergeMap(this.getRealEstateAgent),
                tap((agent) => this.agentSubject.next(agent))
            )
            .subscribe(({ firstName, lastName }: RealEstateAgentPartial) => {
                if (!firstName || !lastName) {
                    this.hasMissingReaInfo = true;
                    this.missingInformationForm.addControl(
                        TakeOverFormControlNames.RealEstateAgentFirstName,
                        this.formBuilder.control(firstName, Validators.required)
                    );
                    this.missingInformationForm.addControl(
                        TakeOverFormControlNames.RealEstateAgentLastName,
                        this.formBuilder.control(lastName, Validators.required)
                    );
                }
                this.checkShowState();
            });
    }

    public markAllAsTouched(): void {
        this.missingInformationForm.markAllAsTouched();
        this.meterReadingsTakeoverForm.get('meterReadingsTakeoverFormControl').markAsTouched();
        this.changeDetectionRef.detectChanges();
    }

    public writeValue(value: boolean): void {}

    public persistInformation(): Observable<void> {
        return zip(this.persistRealEstateAgent(), this.persistLeaver()).pipe(map(() => noop()));
    }

    private persistRealEstateAgent(): Observable<void | string> {
        if (this.hide || !this.hasMissingReaInfo) return of('');
        const { realEstateAgentFirstName: firstName, realEstateAgentLastName: lastName } = this.missingInformationForm
            .value as TakeOverInformationForm;
        return this.agentSubject.asObservable().pipe(
            mergeMap((agent) =>
                this.realEstateAgentService
                    .patch(DbUtils.getStringId(agent), {
                        firstName,
                        lastName,
                    })
                    .pipe(map(() => noop()))
            )
        );
    }

    private persistLeaver(): Observable<void | string> {
        if (this.hide || !this.hasMissingLeaverInfo) return of('');
        const { leaverFirstName: firstName, leaverLastName: lastName } = this.missingInformationForm.value as TakeOverInformationForm;
        return this.moveService.patch(DbUtils.getStringId(this.movers?.leaver), {
            user: {
                firstName,
                lastName,
            },
        } as unknown);
    }

    private getRealEstateAgent = (authUser: AuthUser): Observable<RealEstateAgentPartial> => {
        if (AuthUtils.isAdmin(authUser) || AuthUtils.isImpersonatedRealEstateAgent(authUser)) {
            const realEstateAgentId = authUser.realEstateAgentId || DbUtils.getStringId(this.movers?.transferee?.realEstateAgent);
            if (!realEstateAgentId) return null;
            return this.realEstateAgentService.get(realEstateAgentId);
        } else if (AuthUtils.isRealEstateAgent(authUser)) {
            return of({ ...authUser, _id: authUser.realEstateAgentId });
        } else {
            return of(null);
        }
    };
}
