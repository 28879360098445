export enum MatchPropertyType {
    AgriculturalArea = 'AGRICULTURAL_AREA',
    Apartment = 'APARTMENT',
    ApartmentBlock = 'APARTMENT_BLOCK',
    ApartmentGroup = 'APARTMENT_GROUP',
    Barge = 'BARGE',
    BedAndBreakFast = 'BED_N_BREAKFAST',
    Building = 'BUILDING',
    BuildingLand = 'BUILDING_LAND',
    Bungalow = 'BUNGALOW',
    Business = 'BUSINESS',
    Campsite = 'CAMPSITE',
    Castle = 'CASTLE',
    Chalet = 'CHALET',
    CommercialPremises = 'COMMERCIAL_PREMISES',
    CommercialVilla = 'COMMERCIAL_VILLA',
    CountryCotage = 'COUNTRY_COTTAGE',
    CoveredParkingSpace = 'COVERED_PARKING_SPACE',
    Duplex = 'DUPLEX',
    ExceptionalProperty = 'EXCEPTIONAL_PROPERTY',
    FarmHouse = 'FARMHOUSE',
    Fields = 'FIELDS',
    FlatStudio = 'FLAT_STUDIO',
    Forest = 'FOREST',
    Gites = 'GITES',
    GroundFloor = 'GROUND_FLOOR',
    Hotel = 'HOTEL',
    HotelRestaurantCafe = 'HOTEL_RESTAURANT_CAFE',
    House = 'HOUSE',
    HouseGroup = 'HOUSE_GROUP',
    IndustryPremises = 'INDUSTRIAL_PREMISES',
    Kot = 'KOT',
    Land = 'LAND',
    LargeTownHouse = 'LARGE_TOWN_HOUSE',
    LockUpGarage = 'LOCK_UP_GARAGE',
    LockUpParking = 'LOCK_UP_PARKING',
    Loft = 'LOFT',
    ManorHouse = 'MANOR_HOUSE',
    Mansion = 'MANSION',
    Meadow = 'MEADOW',
    MixedUseBuilding = 'MIXED_USE_BUILDING',
    MixeduseBuildingCommercial = 'MIXED_USE_BUILDING_COMMERCIAL',
    MixedUseBuildingIndustries = 'MIXED_USE_BUILDING_INDUSTRIES',
    MixedUseBuildingOffices = 'MIXED_USE_BUILDING_OFFICES',
    OfficeBlock = 'OFFICE_BLOCK',
    Offices = 'OFFICES',
    Orchard = 'ORCHARD',
    Other = 'OTHER',
    OtherProperty = 'OTHER_PROPERTY',
    OutdoorParkingSpace = 'OUTDOOR_PARKING_SPACE',
    Pavilion = 'PAVILION',
    Penthouse = 'PENTHOUSE',
    RecreationalArea = 'RECREATIONAL_AREA',
    ServiceFlat = 'SERVICE_FLAT',
    StaticCaravan = 'STATIC_CARAVAN',
    TownHouse = 'TOWN_HOUSE',
    Triplex = 'TRIPLEX',
    UnbuildableLand = 'UNBUILDABLE_LAND',
    Villa = 'VILLA',
    WareHouse = 'WAREHOUSE',
}
