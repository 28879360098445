<app-loading-container [loading]="loading$ | async">
    <div class="u-container-small">
        <form [formGroup]="form" class="__form" (ngSubmit)="onSubmit()">
            <smvd-app-escalation-categories-input
                class="__category"
                width="auto"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [formControlName]="formControl.Category"
                [placeholder]="'COMMON.PLACEHOLDER.SELECT' | translate"
                [label]="'CATEGORY.TITLE' | i18nKeyTranslate: i18nKeys.Escalation"
            ></smvd-app-escalation-categories-input>

            <ui-dropdown-input
                class="__tag"
                width="auto"
                [options]="tagOptions"
                [variant]="uiHeaderVariant.Svg"
                [formControlName]="formControl.Tag"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [label]="'TAG.TITLE' | i18nKeyTranslate: i18nKeys.Escalation"
            ></ui-dropdown-input>

            <ui-dropdown-input
                class="__state"
                width="auto"
                [options]="stateOptions"
                [formControlName]="formControl.State"
                [label]="'STATE.TITLE' | i18nKeyTranslate: i18nKeys.Escalation"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></ui-dropdown-input>

            <ui-dropdown-input
                class="__service"
                width="auto"
                [options]="serviceOptions"
                [formControlName]="formControl.Service"
                [label]="'SERVICES.LABEL_SINGLE' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></ui-dropdown-input>

            <app-supplier-group-input
                class="__supplier-group"
                width="auto"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [formControlName]="formControl.SupplierGroup"
            ></app-supplier-group-input>

            <ui-dropdown-input
                class="__ops"
                width="auto"
                [options]="servicedByOptions"
                [formControlName]="formControl.ServicedBy"
                [label]="'OPS_ADMIN' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></ui-dropdown-input>

            <div class="__account-manager">
                <ui-dropdown-input
                    width="auto"
                    [options]="accountManagersOptions"
                    [formControlName]="formControl.AccountManager"
                    [label]="'ACCOUNT_MANAGER' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-bottom-half u-display-block"
                ></ui-dropdown-input>

                <app-check-input
                    width="auto"
                    [custom]="false"
                    [option]="notifyAccountManagerOption"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [formControlName]="formControl.NotifyAccountManager"
                ></app-check-input>
            </div>

            <div class="__external-urls">
                <app-text-input
                    width="auto"
                    [formControlName]="formControl.ExternalUrls"
                    [label]="'EXTERNAL_URLS.LABEL' | i18nKeyTranslate: i18nKeys.Escalation"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    class="u-margin-bottom-half u-display-block"
                ></app-text-input>
                <p class="u-color-muted">{{'EXTERNAL_URLS.HELP' | i18nKeyTranslate: i18nKeys.Escalation}}</p>
            </div>

            <app-textarea-input
                class="__description"
                width="auto"
                [formControlName]="formControl.Description"
                [label]="'DESCRIPTION.LABEL' | i18nKeyTranslate: i18nKeys.Escalation"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></app-textarea-input>

            <div class="__cta u-text-align-right">
                <button smvd-ui-button
                        type="submit">{{(escalation ? 'UPDATE.LABEL' : 'CREATE.LABEL') | translate}}</button>
            </div>
        </form>
    </div>
</app-loading-container>
