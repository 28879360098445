import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils';

@Pipe({ name: 'upperSnakeCase' })
export class UpperSnakeCasePipe implements PipeTransform {
    /**
     * Transforms target value into a string in UPPER_SNAKE_CASE
     * @param {string} value to be transformed
     * @returns {string} transformed value
     */
    public transform(value: string) {
        return StringUtils.toUpperSnakeCase(value);
    }
}
