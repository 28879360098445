import { EnergyOrderedConfirmation } from '@app/analytics/enums/energy-ordered-confirmation.enum';
import { CreatedByFlowEnum, DateUtils } from '@smooved/core';
import { ChartLegend, Colors, ColorScale, totalStack, waterfallStack } from '@smooved/ui';

const i18n = 'SMOOVED_ANALYTICS.DETAILS.CONFIRMATION';
const invisibleBucket = '';
const colors = [ColorScale.Color3, ColorScale.Color2, ColorScale.Color1, Colors.Danger, ColorScale.Color4];

export const stackColors = [invisibleBucket, ...colors];

export const otsTypes = [CreatedByFlowEnum.Eots, CreatedByFlowEnum.Iots];
export const dataStacks = Object.values(EnergyOrderedConfirmation);
export const stacks = [waterfallStack, ...dataStacks, totalStack];
export const last90days = DateUtils.tz().subtract(90, 'days').startOf('D').toDate();

export const labelTranslateKeys = {
    title: `${i18n}.SHORT_PERIOD_TITLE`,
    ots: `${i18n}.OTS`,
    assisted: `${i18n}.ASSISTED`,
    pending: `${i18n}.PENDING`,
    lost: `${i18n}.LOST`,
    total: `${i18n}.TOTAL`,

    otsSub: `${i18n}.OTS_SUB`,
    assistedSub: `${i18n}.ASSISTED_SUB`,
    pendingSub: `${i18n}.PENDING_SUB`,
    lostSub: `${i18n}.LOST_SUB`,
};

export const mapTypeToLabel = {
    [EnergyOrderedConfirmation.Ots]: labelTranslateKeys.ots,
    [EnergyOrderedConfirmation.Assisted]: labelTranslateKeys.assisted,
    [EnergyOrderedConfirmation.Pending]: labelTranslateKeys.pending,
    [EnergyOrderedConfirmation.Lost]: labelTranslateKeys.lost,
    [totalStack]: labelTranslateKeys.total,
};

export const mapLabelToSubLabel = {
    [labelTranslateKeys.ots]: labelTranslateKeys.otsSub,
    [labelTranslateKeys.assisted]: labelTranslateKeys.assistedSub,
    [labelTranslateKeys.pending]: labelTranslateKeys.pendingSub,
    [labelTranslateKeys.lost]: labelTranslateKeys.lostSub,
    [labelTranslateKeys.total]: null,
};

export const legend: ChartLegend[] = Object.values(EnergyOrderedConfirmation).map((type, i) => ({
    label: mapTypeToLabel[type],
    color: colors[i],
    sub: mapLabelToSubLabel[mapTypeToLabel[type]],
}));
