import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureScope, FeatureScopeConfig, LogicalOperation } from '@smooved/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureScopeSandbox } from '../feature-scope';

@Injectable({
    providedIn: 'root',
})
export class FeatureScopeGuard {
    constructor(
        protected readonly featureScopeSandbox: FeatureScopeSandbox,
        protected readonly router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.checkAccess(route);
    }

    private checkAccess(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const { scopes = [], operator = LogicalOperation.And } = (route.data.featureScopeConfig as FeatureScopeConfig) || {};
        if (![...scopes]?.length) return true;

        return this.featureScopeSandbox.featureScopesOnce$.pipe(
            map((authorizedFeatureScopes) => {
                if (!authorizedFeatureScopes?.length) {
                    this.router.navigateByUrl(`/real-estate-agent/start`);
                    return false;
                }

                const hasFeature = (featureScope: FeatureScope): boolean => authorizedFeatureScopes.some((f) => featureScope.startsWith(f));
                const result = operator === LogicalOperation.And ? [...scopes].every(hasFeature) : [...scopes].some(hasFeature);

                if (!result) {
                    this.router.navigateByUrl(`/real-estate-agent/start`);
                    return false;
                }
                return true;
            })
        );
    }
}
