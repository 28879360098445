import {
    adminAppointmentsRouting,
    adminExportsRouting,
    adminHealthCheckRouting,
    adminImportsRouting,
    adminMoversAllRouting,
    adminOrdersRouting,
    adminPartnersRouting,
    adminRealEstateAgentsRouting,
    adminReviewsRouting,
    leaderboardRouting,
} from '@app/admin/constants/routing.constants';
import { exportsFeatures } from '@smooved/core';

const i18n = 'ADMIN.NAVIGATION';
export const labelResources = {
    movers: `${i18n}.MOVERS`,
    realEstateAgents: `${i18n}.REAL_ESTATE_AGENTS`,
    orders: `${i18n}.ORDERS`,
    appointments: `${i18n}.APPOINTMENTS`,
    reviews: `${i18n}.REVIEWS`,
    partners: `${i18n}.PARTNERS`,
    exports: `${i18n}.EXPORTS`,
    imports: `${i18n}.IMPORTS`,
    healthCheck: `${i18n}.HEALTH_CHECK`,
    leaderboard: `${i18n}.LEADERBOARD`,
};

export const navLinks = [
    {
        routing: adminMoversAllRouting,
        labelResource: labelResources.movers,
    },
    {
        routing: adminRealEstateAgentsRouting,
        labelResource: labelResources.realEstateAgents,
    },
    {
        routing: adminOrdersRouting,
        labelResource: labelResources.orders,
    },
    {
        routing: adminAppointmentsRouting,
        labelResource: labelResources.appointments,
    },
    {
        routing: adminReviewsRouting,
        labelResource: labelResources.reviews,
    },
    {
        routing: adminPartnersRouting,
        labelResource: labelResources.partners,
    },
    {
        routing: adminExportsRouting,
        labelResource: labelResources.exports,
        features: exportsFeatures,
    },
    {
        routing: adminImportsRouting,
        labelResource: labelResources.imports,
    },
    {
        routing: adminHealthCheckRouting,
        labelResource: labelResources.healthCheck,
    },
    {
        routing: leaderboardRouting,
        labelResource: labelResources.leaderboard,
    },
];
