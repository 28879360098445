import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { CardsModule } from '../cards/cards.module';
import { LoadingModule } from '../loading/loading.module';
import { MenuModule } from '../menu/menu.module';
import { SkeletonModule } from '../skeleton';
import { WizardContainer } from './wizard-container/wizard.container';
import { WizardSideNavComponent } from './wizard-side-nav/wizard-side-nav.component';
import { WizardStepCardComponent } from './wizard-step-card/wizard-step-card.component';

@NgModule({
    imports: [
        CommonModule,
        CardsModule,
        ButtonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        MenuModule,
        SkeletonModule,
    ],
    declarations: [WizardStepCardComponent, WizardContainer, WizardSideNavComponent],
    exports: [WizardStepCardComponent, WizardContainer, WizardSideNavComponent],
})
export class WizardModule {}
