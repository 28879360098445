import { InjectionToken } from '@angular/core';
import { UiContext } from '../ui.enums';
import { WizardStep } from './wizard-step.interface';

export interface WizardConfig {
    baseRoute: string[];
    steps: WizardStep[];
    stepsToExcludeFromStepper?: WizardStep[];
    hasSideNav?: boolean;
    hasTopNav?: boolean; // Not yet implemented;
    i18nKeyType?: string;
    useRouterSteps?: boolean; // define current step in the route data
    submitButtonContext?: UiContext;
}

export const WIZARD_CONFIG = new InjectionToken<WizardConfig>('WIZARD_CONFIG');
