import { Component } from '@angular/core';
import { RowDef } from './table.interfaces';

@Component({
    template: '',
})
export class CellTemplateComponent {
    public data: any;
    public row: any;
    public uiInit(row: RowDef<any>): void {
        this.row = row;
        this.data = row.data;
    }
}
