import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@smooved/core';

export enum CommaPosition {
    Before = 'before',
    After = 'after',
}

@Pipe({
    name: 'comma',
})
export class CommaPipe implements PipeTransform {
    transform(value: string, position = CommaPosition.After): string {
        if (!StringUtils.hasValue(value)) return value;
        if (position === CommaPosition.After) return `${value}, `;
        if (position === CommaPosition.Before) return `, ${value}`;
        return value;
    }
}
