import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Address } from '@smooved/core';

export function addressOptional(control: AbstractControl): ValidationErrors | null {
    const address: Address = control?.value;
    const requiredValues = [address?.street, address?.houseNumber, address?.zipCode, address?.city];

    if (requiredValues.some((value) => !!value)) {
        const allFilled = requiredValues.every((value) => !!value);
        return allFilled
            ? null
            : {
                  required: true,
              };
    }

    return null;
}
