import { Injectable } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AnalyticsEventsEnum, AnalyticsService as CoreAnalyticsService } from '@smooved/core';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class AnalyticsService {
    constructor(
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly coreAnalyticsService: CoreAnalyticsService
    ) {}

    public sendEvent(event: AnalyticsEventsEnum, extraArgs: object = {}): void {
        zip(this.realEstateGroupSandbox.name$, this.authenticationSandbox.userId$)
            .pipe(take(1))
            .subscribe(([realEstateGroup, userId]) => {
                this.coreAnalyticsService.sendEvent(event, {
                    realEstateGroup,
                    userId,
                    ...extraArgs,
                });
            });
    }
}
