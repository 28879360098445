import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-created-on',
    template: `
        <ng-container matColumnDef="createdOn">
            <th mat-header-cell mat-sort-header class="__cell u-padding-left u-padding-right" *matHeaderCellDef>
                {{ 'ADMIN.DASHBOARD.TABLE.CREATED_ON' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="__cell u-padding-left u-padding-right">
                <div class="u-white-space-no-wrap">{{ row.createdOn | formatDate }}</div>
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-created-on.component.scss'],
})
export class TableColumnCreatedOnComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
