import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-client-lead-passing-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <h3 class="u-margin-bottom-triple">{{ 'MOVE.REGISTER.TITLE' | translate }}</h3>
                <app-lead-passing-form
                    (previous)="onPrevious()"
                    (submitSuccess)="onSuccess($event)"
                    [showPrevious]="true"
                    [initialData]="true"
                ></app-lead-passing-form>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class ClientLeadPassingContainer {
    constructor(private router: Router, private route: ActivatedRoute, private moveSandbox: MoveSandbox) {}

    public onSuccess(move: Move): void {
        this.moveSandbox.setMoveState(move);
        void this.router
            .navigate(['../success'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onPrevious(): void {
        void this.router
            .navigate(['../init'], {
                relativeTo: this.route,
            })
            .then();
    }
}
