import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'app-chart-percentage',
    templateUrl: './chart-percentage.component.html',
    styleUrls: ['./chart-percentage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPercentageComponent implements OnInit, CanDisable {
    @Input() public value = 0;
    @Input() public total = 0;
    @Input() public context = UiContext.Secondary;
    @Input() public label: string;
    @Input() public showValue = true;
    @Input() public disabled: boolean;
    @Input() public showTooltip: boolean;

    public ngOnInit(): void {
        if (!this.context) {
            throw new Error('Context is required.');
        }
    }
}
