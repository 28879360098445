<ui-star-score
    [context]="uiContext.Primary"
    [score]="meta?.score | starsScore"
    width="22px"
    class="__star-score u-display-block u-margin-bottom-half"
></ui-star-score>
<div class="u-flex-row u-flex-align-items-end">
    <smvd-ui-svg-illustration
        [svg]="svgIllustration.HouseMatchIcon"
        [context]="uiContext.Primary"
        [style.width]="'22px'"
        class="__hm-logo u-margin-right-half"
    ></smvd-ui-svg-illustration>
    <label class="__score-label"
        ><span id="score" class="__score u-font-size-lg u-font-weight-semi-bold u-color-housematch-secondary">{{
            !!meta?.total ? (meta?.score | arcScoreValue) : '?'
        }}</span
        ><span id="max-score" class="__dot u-margin-right-half u-color-muted"> / 10 </span
        ><span class="__reviews u-color-muted">{{ meta?.total ?? 0 }} {{ 'REVIEWS.LABEL' | translate | lowercase }}</span></label
    >
</div>
