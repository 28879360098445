<app-card-table-with-filter>
    <app-dashboard-actions
        actions
        *ngIf="uiSandbox.upToAndIncludingPhoneLandscape$ | async"
        (actionsChange)="onActionsChange($event)"
    ></app-dashboard-actions>
    <app-dashboard-filter
        filter
        (filterChange)="onFilterChange($event)"
        [showClusterLevel]="showClusterLevel"
        [showScoreFilter]="showScoreFilter"
        [showCreatedByFlow]="showCreatedByFlow"
        [showMoverRole]="showMoverRole"
        class="u-flex-1-1-0"
    ></app-dashboard-filter>
    <app-loading-container [loading]="realEstateAgentDashboardSandbox.loading$ | async" class="u-display-block" table>
        <app-dashboard-table
            (pageEventChange)="onPaginationChange()"
            (sortChange)="onPaginationChange()"
            (reload)="reload()"
            (rowClick)="openDetail($event._id, $event.user?.role)"
            [dataSource]="dataSource"
            [view]="activeView"
        ></app-dashboard-table>
    </app-loading-container>
</app-card-table-with-filter>
