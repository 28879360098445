import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { AssetInputComponent } from '@app/form/components/asset-input/asset-input.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { DbUtils } from '@smooved/core';
import { ClosableModalTemplateComponent, UiContext } from '@smooved/ui';
import { finalize } from 'rxjs/operators';
import { formControlNames } from './rental-inspection-asset.constants';

@Component({
    selector: 'app-rental-inspection-asset-modal',
    templateUrl: './rental-inspection-asset.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RentalInspectionAssetModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent) public readonly modal: ClosableModalTemplateComponent;
    @ViewChild(AssetInputComponent) public readonly assetInput: AssetInputComponent;
    public form: UntypedFormGroup;
    public readonly formControlNames = formControlNames;
    public readonly uiContext = UiContext;
    public loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: { move: Move },
        private readonly fb: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly documentCenterSandbox: DocumentCenterSandbox
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            [formControlNames.rentalInspectionAsset]: [this.data.move.rentalInspectionAsset, Validators.required],
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.loading = true;
        this.documentCenterSandbox
            .saveMoveFiles(DbUtils.getStringId(this.data.move), AttachmentId.RentalInspectionAsset, [this.assetInput.file])
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(this.onMoveUpdated);
    }

    private onMoveUpdated = (updatedMove: Move): void => {
        this.modal.close(updatedMove);
    };
}
