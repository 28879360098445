import { Component, OnInit } from '@angular/core';
import { NavigationData, RxjsComponent } from '@smooved/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { environment } from '@environments/environment';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-beamer-plugin',
    template: '',
})
export class BeamerPluginComponent extends RxjsComponent implements OnInit {
    constructor(private navigationSandbox: AppNavigationSandbox) {
        super();
    }

    public ngOnInit(): void {
        this.navigationSandbox.routeNavigationEndData$
            .pipe(
                map((data): boolean => data[NavigationData.ShowBeamer]),
                takeUntil(this.destroy$)
            )
            .subscribe((showBeamer) => {
                const Beamer = window['Beamer'];

                if (!Beamer || !environment.showBeamer) return;
                showBeamer ? Beamer.init() : Beamer.destroy();
            });
    }
}
