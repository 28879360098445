import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MoveDetailModalComponent } from '@app/admin/modals/move-detail/move-detail/move-detail.modal';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { Move } from '@app/move/interfaces/move';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { DbUtils } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-base-table',
    template: ``,
})
export class BaseTableComponent extends CoreTableComponent implements OnInit, OnDestroy {
    @Output() public movePatched: EventEmitter<void> = new EventEmitter<void>();

    private movePatchedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private changeMoveSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(protected dialog: MatDialog) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public openDetail(move: Move): void {
        this.openModal(DbUtils.getStringId(move));
    }

    public openModal(id: string): void {
        const data: ModalData = {
            id,
            patchedSubject: this.movePatchedSubject,
            changeMoveSubject: this.changeMoveSubject,
        };

        this.dialog
            .open(MoveDetailModalComponent, { data })
            .afterClosed()
            .subscribe((_) => {
                const movePatched = this.movePatchedSubject.value;
                if (movePatched) {
                    this.movePatched.emit();
                    this.movePatchedSubject.next(false);
                }

                const changeMoveId = this.changeMoveSubject.value;
                if (changeMoveId) {
                    this.changeMoveSubject.next(null);
                    this.openModal(changeMoveId);
                }
            });
    }
}
