import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-client-canceled-container',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCanceledContainer {
    constructor() {
        window.close();
    }
}
