const regexPositiveTag = /%(positive|\/positive)%/g;
const regexNegativeTag = /%(negative|\/negative)%/g;
const regexPositiveOpeningTag = /%(positive)%/g;
const regexPositiveClosingTag = /%(\/positive)%/g;
const regexNegativeOpeningTag = /%(negative)%/g;
const regexNegativeClosingTag = /%(\/negative)%/g;

export class TagMatcherUtils {
    public static countPositiveTags(input: string): number {
        const matches = input.match(regexPositiveTag);
        return matches ? matches.length : 0;
    }

    public static countNegativeTags(input: string): number {
        const matches = input.match(regexNegativeTag);
        return matches ? matches.length : 0;
    }

    public static countPositiveOpeningTags(input: string): number {
        const matches = input.match(regexPositiveOpeningTag);
        return matches ? matches.length : 0;
    }

    public static countPositiveClosingTags(input: string): number {
        const matches = input.match(regexPositiveClosingTag);
        return matches ? matches.length : 0;
    }

    public static countNegativeClosingTags(input: string): number {
        const matches = input.match(regexNegativeClosingTag);
        return matches ? matches.length : 0;
    }

    public static countNegativeOpeningTags(input: string): number {
        const matches = input.match(regexNegativeOpeningTag);
        return matches ? matches.length : 0;
    }

    public static removePositiveTags(input: string): string {
        return input.replace(regexPositiveTag, '');
    }

    public static removeNegativeTags(input: string): string {
        return input.replace(regexNegativeTag, '');
    }
}
