import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, ExternalInfluencer, isoDate, StringUtils } from '@smooved/core';
import { Language } from '../enums';
import { NpsReview } from '../interfaces';
import { DateUtils, NumberUtils } from '../utils';
import { translator } from '../../../../smooved-match/src/i18n/server';
import { BaseMetaService } from './meta.service';

@Injectable()
export class MetaReviewService extends BaseMetaService {
    constructor(
        protected readonly meta: Meta,
        protected readonly title: Title,
        protected readonly translateService: TranslateService,
        @Inject(DOCUMENT) protected document: Document,
        rendererFactory: RendererFactory2
    ) {
        super(meta, title, document, rendererFactory);
    }

    public addMetaData(
        externalInfluencer: ExternalInfluencer,
        review: NpsReview,
        language: Language,
        description: string,
        title: string,
        houseMatchUrl: string,
        isProduction: boolean,
        npsOgImageBaseUri: string
    ): void {
        const metaData = this.buildMeta(
            externalInfluencer,
            review,
            language,
            description,
            title,
            houseMatchUrl,
            isProduction,
            npsOgImageBaseUri
        );
        this.addMetaTags(metaData);
    }

    public addAlternateLanguageLinks(houseMatchUrl: string, regSlug: string, reviewId: string): void {
        this.cleanUpOldElements('link[rel="alternate"]');
        Object.values(Language).forEach((lang) => {
            const href = this.getUrl(houseMatchUrl, regSlug, lang, reviewId);
            this.addAlternateLangLink(lang, href);
        });
    }

    public getTitleSingleReview(externalInfluencer: ExternalInfluencer, npsReview: NpsReview): string {
        if (!externalInfluencer?.realEstateGroupName || !NumberUtils.isNumber(npsReview?.score)) return null;
        return this.translateService.instant('META.TITLE_REVIEW', {
            realEstateGroup: externalInfluencer?.realEstateGroupName,
            score: npsReview?.score,
        });
    }

    protected buildMeta = (
        externalInfluencer: ExternalInfluencer,
        review: NpsReview,
        language: Language,
        description: string,
        title: string,
        houseMatchUrl: string,
        isProduction: boolean,
        npsOgImageBaseUri: string
    ): {
        [key: string]: string;
    } => {
        const url = externalInfluencer?.url;
        const completeUrl = this.getUrl(houseMatchUrl, externalInfluencer.url, language, DbUtils.getStringId(review));

        const ogImageLanguageAddition = language !== 'nl' ? `_${language}` : '';

        const metaProps: object = {
            'og:description': description,
            'og:locale': language,
            'og:type': 'article',
            'og:image': StringUtils.parseUri(npsOgImageBaseUri, {
                filename: `${DbUtils.getStringId(review)}${ogImageLanguageAddition}.png`,
            }),
            'article:published_time': DateUtils.tz(review.createdOn).format(isoDate),
            'article:author': review.createdBy ? `${review.createdBy.firstName} ${review.createdBy.lastName}` : '',
            'twitter:description': description,
            ...(title ? { 'og:title': title, 'twitter:title': title } : {}),
            ...(url ? { 'og:site_name': completeUrl, 'og:url': completeUrl, 'twitter:url': completeUrl } : {}),
        };

        return {
            ...metaProps,
        };
    };

    private getUrl = (externalInfluencerUri: string, regSlug: string, language: Language, reviewId: string): string => {
        return [externalInfluencerUri, language, regSlug, translator.translateRoute(language, 'reviews'), reviewId]
            .filter((x) => x)
            .join('/');
    };
}
