<div
    *ngIf="widgetData"
    class="__main u-display-block"
    [class.wis-default]="true"
    [class.wis-shadow]="shadow"
    [class.wis-transparant]="!background"
    [class.wis-vertical]="direction === uiDirection.Vertical"
    [class.wis-small]="size === uiSize.Small"
>
    <div class="u-flex">
        <div *ngIf="hasLogo"
             class="__image-container u-padding-bottom u-padding-bottom-none u-margin-right u-padding-right">
            <img [src]="widgetData.logo" [alt]="('LOGO' | translate) + ' ' + widgetData.name"/>
        </div>
        <div class="u-flex u-flex-align-items-center __flex-direction" [style.color]="color">
            <ng-container *ngIf="widgetData?.score; else noReviews">
                <div class="u-flex-column u-flex-align-items-center">
                    <app-arc-score
                        [size]="size === uiSize.Small && direction === uiDirection.Horizontal ? uiSize.ExtraSmall : size"
                        [data]="widgetData.arcScore"
                        [value]="widgetData.score"
                    ></app-arc-score>
                    <app-svg-illustration [svg]="logo" class="__logo-smooved"
                                          [style.color]="color"></app-svg-illustration>
                </div>
                <div class="__wis-text">
                    <h5>
                        {{ widgetData.scoreScale | i18nKey: i18nKeyType.ReviewScore | ftranslate: language | async }}
                    </h5>
                    <p class="u-font-size-small __wis-desc" [style.color]="color">
                        {{ 'UI.WIDGET_INFLUENCER_SCORE.V2.SCORE.TOTAL' | ftranslate: language:{total: widgetData.overall.total} | async }}
                    </p>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noReviews>
    <div class="u-flex-column u-flex-align-items-center">
        <app-arc-score [data]="widgetData?.arcScore" value="-"></app-arc-score>
        <app-svg-illustration [svg]="logo" class="__logo-smooved" [style.color]="color"></app-svg-illustration>
    </div>
    <div class="__wis-text">
        <h5 [style.color]="color">{{ 'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.TITLE' | ftranslate: language | async }}</h5>
        <p
            class="u-font-size-small"
            [style.color]="color"
            [innerHtml]="'UI.WIDGET_INFLUENCER_SCORE.NO_REVIEWS.DESCRIPTION' | ftranslate: language | async"
        ></p>
    </div>
</ng-template>
