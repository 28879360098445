import { CustomMessageType } from '@app/communication/enums/custom-message-type.enum';
import { MessageContext } from '@app/communication/enums/message-context.enum';
import { CustomMessageTemplate } from '@app/communication/interfaces/custom-message-template';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { AppI18nKeyType, i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { I18nKeyUtils, Language } from '@smooved/core';
import { SelectInput, Svg } from '@smooved/ui';

export enum CustomMessageFilterEnum {
    Type = 'type',
    Context = 'context',
    Template = 'template',
    Language = 'language',
}

export interface CustomMessageFilter {
    [CustomMessageFilterEnum.Type]: CustomMessageType;
    [CustomMessageFilterEnum.Context]: MessageContext;
    [CustomMessageFilterEnum.Template]?: CustomMessageTemplate;
    [CustomMessageFilterEnum.Language]: Language;
}

export const messageTypeOptions: SelectInput<CustomMessageType>[] = [
    {
        id: 'custom-message-type-mail',
        svg: Svg.Mail,
        labelResource: 'MAIL',
        value: ContactLogType.EmailCustomMover,
        name: 'custom-message-type',
    },
    {
        id: 'custom-message-type-sms',
        svg: Svg.MobilePhone,
        labelResource: 'SMS',
        value: ContactLogType.SmsCustomMover,
        name: 'custom-message-type-sms',
    },
];

export const senderOptions: DropdownInput<string>[] = [
    {
        id: 'sender-moving-nl',
        label: 'verhuizen@smooved.be',
        value: 'verhuizen@smooved.be',
        name: 'sender',
    },
    {
        id: 'sender-moving-fr',
        label: 'demenager@smooved.be',
        value: 'demenager@smooved.be',
        name: 'sender',
    },
];

export const contextOptions: DropdownInput<MessageContext>[] = EnumUtils.values<MessageContext>(MessageContext).map((value) => ({
    id: `custom-message-context-${value}`,
    labelResource: I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.AdminCustomMessage, `MESSAGE_CONTEXT.${value}`),
    value,
    name: 'custom-message-context',
}));

export const languageOptions: DropdownInput<Language>[] = EnumUtils.entries<Language>(Language).map(([key, value]) => ({
    id: `language-${key}`,
    labelResource: `LANGUAGE.${key.toUpperCase()}.LABEL`,
    value,
    name: 'language',
}));

export const messageTypeDefault = ContactLogType.EmailCustomMover;
