import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentRegisterData } from '@app/real-estate-agent/interfaces/real-estate-agent-register-data';
import { createAction, props } from '@ngrx/store';
import { RealEstateAgent } from '../interfaces/real-estate-agent';

export enum RealEstateAgentActionTypes {
    Loading = '[RealEstateAgent] Loading',
    Get = '[RealEstateAgent] Get details',
    GetSuccess = '[RealEstateAgent] Get details success',
    GetFailure = '[RealEstateAgent] Get details failure',
    GetAll = '[RealEstateAgent] Get all',
    GetAllSuccess = '[RealEstateAgent] Get all success',
    Patch = '[RealEstateAgent] Patch real estate agent',
    PatchSuccess = '[RealEstateAgent] Patch real estate agent success',
    PatchFailure = '[RealEstateAgent] Patch real estate agent failure',
    SetFilter = '[RealEstateAgentDashboard] Set filter',
    SetFilterSuccess = '[RealEstateAgentDashboard] Set filter success',
    SetInviteData = '[RealEstateAgent] Set invite data',
    GetBadgeCounts = '[RealEstateAgent] Get Badge counts',
    GetBadgeCountsSuccess = '[RealEstateAgent] Get Badge counts success',
    ResetBadgeCountsUnreadReviews = '[RealEstateAgent] Reset badge counts for reviews',
}

export type GetDetails = { id: string };
export type GetDetailssuccess = { realEstateAgent: RealEstateAgent };
export type GetAllsuccess = { realEstateAgents: RealEstateAgent[] };
export type Patch = { realEstateAgentId: string; realEstateAgent: RealEstateAgent; callback?: () => void };
export type PatchSuccess = { realEstateAgent: RealEstateAgent; callback?: () => void };
export type SetFilter = { filter: RealEstateAgentFilter; persist?: boolean; callback?: () => void };
export type SetFilterSuccess = { callback?: () => void };
export type SetInviteData = { inviteData: RealEstateAgentRegisterData };
export type GetBadgeCountsSuccess = { unreadReviews: number };

export const loading = createAction(RealEstateAgentActionTypes.Loading);
export const getDetails = createAction(RealEstateAgentActionTypes.Get, props<GetDetails>());
export const getDetailsSuccess = createAction(RealEstateAgentActionTypes.GetSuccess, props<GetDetailssuccess>());
export const getDetailsFailure = createAction(RealEstateAgentActionTypes.GetFailure);
export const getAll = createAction(RealEstateAgentActionTypes.GetAll);
export const getAllSuccess = createAction(RealEstateAgentActionTypes.GetAllSuccess, props<GetAllsuccess>());
export const patch = createAction(RealEstateAgentActionTypes.Patch, props<Patch>());
export const patchSuccess = createAction(RealEstateAgentActionTypes.PatchSuccess, props<PatchSuccess>());
export const patchFailure = createAction(RealEstateAgentActionTypes.PatchFailure);
export const setFilter = createAction(RealEstateAgentActionTypes.SetFilter, props<SetFilter>());
export const setFilterSuccess = createAction(RealEstateAgentActionTypes.SetFilterSuccess, props<SetFilterSuccess>());
export const setInviteData = createAction(RealEstateAgentActionTypes.SetInviteData, props<SetInviteData>());
export const getBadgeCounts = createAction(RealEstateAgentActionTypes.GetBadgeCounts);
export const getBadgeCountsSuccess = createAction(RealEstateAgentActionTypes.GetBadgeCountsSuccess, props<GetBadgeCountsSuccess>());
export const resetBadgeCountsUnreadReviews = createAction(RealEstateAgentActionTypes.ResetBadgeCountsUnreadReviews);
