import { Navigation } from './enums/navigation.enum';

export const realEstateAgent = Navigation.RealEstateAgent;
export const realEstateAgentManagement = [realEstateAgent, Navigation.Management];
export const realEstateAgentReviewsOverview = [realEstateAgent, Navigation.Reviews, Navigation.Overview];
export const realEstateAgentReviewsInsights = [realEstateAgent, Navigation.Reviews, Navigation.Insights];
export const realEstateAgentManagementModules = [...realEstateAgentManagement, Navigation.Modules];
export const realEstateAgentManagementModulesReviews = [...realEstateAgentManagementModules, Navigation.Reviews];

export const surveys = Navigation.Surveys;
export const moverDashboard = ['/', Navigation.Dashboard];
