import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { OrderDetailService } from '@app/partner/services/order-detail.service';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';

@Injectable()
export class RentalInspectionDetailService extends OrderDetailService {
    constructor(readonly orderSandbox: OrderSandbox, readonly notificationSandbox: NotificationSandbox) {
        super(orderSandbox, notificationSandbox);
    }

    public patchOrder(id: string, payload: object, cb: (order: OrderRentalInspection) => void): void {
        this.patch<OrderRentalInspection>(this.orderSandbox.patchRentalInspection(id, payload), cb);
    }

    public getOrder(id: string): Observable<OrderRentalInspection> {
        return this.findOrder<OrderRentalInspection>(id);
    }

    public getAsset(src: string): Observable<HttpResponse<Blob>> {
        return this.orderSandbox.getAssetRentalInspectionAgreementDocument(src);
    }
}
