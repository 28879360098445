import { Injectable } from '@angular/core';
import { ImageModule } from '@app/ui/image/image.module';
import { ImagePickerModal } from '@app/ui/image/modals/image-picker/image-picker.modal';
import { ModalSandbox } from '@smooved/ui';

@Injectable({
    providedIn: ImageModule,
})
export class ImageSandbox {
    constructor(private readonly modalSandbox: ModalSandbox) {}

    public openImagePicker(callback?: (image: string) => void): void {
        const modal = ImagePickerModal;
        const dataFactory = {};
        const callbackFactory = (croppedImage?: string) => {
            if (croppedImage && callback) {
                callback(croppedImage);
            }
        };
        this.modalSandbox.openModal(modal, dataFactory, callbackFactory, modal, dataFactory, callbackFactory);
    }
}
