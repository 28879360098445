<app-closable-modal-template maxWidth="auto">
    <ng-container modal-header>
        <h3>{{ 'MOVE.MAIL_LEAVER_TO_SUPPLIER.TITLE' | translate }}</h3>
    </ng-container>

    <app-loading-container [loading]="loading">
        <app-mail-form
            *ngIf="mailData"
            [data]="mailData"
            (submit)="onSubmit()"
            [assetsReadOnly]="true"
            [showBcc]="true"
            [showCc]="true"
        ></app-mail-form>
    </app-loading-container>
</app-closable-modal-template>
