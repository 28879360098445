<app-label-container [required]="required" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <form [formGroup]="form" class="__form" [ngClass]="{ '__form--small': small }" #formElement="ngForm">
        <ui-address-street-input
            *ngIf="!this.fields || this.fields.street"
            [formControlName]="addressNames.Street"
            (addressSelected)="onAddressSelected($event)"
            width="auto"
            [label]="'STREET' | i18nKeyTranslate: i18nKeys.Address"
            [placeholder]="'STREET' | i18nKeyTranslate: i18nKeys.Address"
            [showLabel]="showLabels"
            [showPlaceholder]="showPlaceholders"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            class="__street"
        ></ui-address-street-input>

        <app-text-input
            *ngIf="!this.fields || this.fields.houseNumber"
            [formControlName]="addressNames.HouseNumber"
            class="__house-number"
            width="auto"
            [placeholder]="showPlaceholders ? ('HOUSE_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('HOUSE_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>

        <app-text-input
            *ngIf="!this.fields || this.fields.busNumber"
            [formControlName]="addressNames.BusNumber"
            width="auto"
            class="__bus-number"
            [placeholder]="showPlaceholders ? ('BUS_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [label]="showLabels ? ('BUS_NUMBER' | i18nKeyTranslate: i18nKeys.Address) : null"
            [hasMargin]="false"
            [hasMarginDouble]="false"
        ></app-text-input>

        <ui-address-street-input
            *ngIf="!this.fields || this.fields.zipCode"
            [formControlName]="addressNames.ZipCode"
            (addressSelected)="onAddressSelected($event)"
            [types]="['(regions)']"
            width="auto"
            [label]="'ZIP_CODE' | i18nKeyTranslate: i18nKeys.Address"
            [placeholder]="'ZIP_CODE' | i18nKeyTranslate: i18nKeys.Address"
            [showLabel]="showLabels"
            [showPlaceholder]="showPlaceholders"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            class="__zip-code"
        ></ui-address-street-input>

        <ui-address-street-input
            *ngIf="!this.fields || this.fields.city"
            [formControlName]="addressNames.City"
            (addressSelected)="onAddressSelected($event)"
            [types]="['(regions)']"
            width="auto"
            [label]="'CITY' | i18nKeyTranslate: i18nKeys.Address"
            [placeholder]="'CITY' | i18nKeyTranslate: i18nKeys.Address"
            [showLabel]="showLabels"
            [showPlaceholder]="showPlaceholders"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            class="__city"
        ></ui-address-street-input>
    </form>
</app-label-container>
