import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-suggestion-selection-insurances-offer',
    template: `
        <table>
            <tr>
                <td
                    class="u-vertical-align-middle u-padding-right-half u-text-align-right"
                    *ngIf="uiSandbox.tabletPortraitUp$ | async"
                    [ngClass]="{ 'u-padding-bottom': !minimal && !minimalWithoutPrice }"
                >
                    <img
                        [src]="suggestion?.insurer?.logourl"
                        [alt]="'Logo' + suggestion?.insurer?.name"
                        width="50px"
                        class="__image u-display-block"
                    />
                </td>
                <td *ngIf="!minimal && !minimalWithoutPrice" class="u-vertical-align-middle u-w100p u-padding-bottom">
                    <h5>{{ suggestion?.insurer?.name }}</h5>
                    <p>{{ suggestion?.product?.commercialnl?.name }}</p>
                </td>
                <td *ngIf="minimal" class="u-vertical-align-middle u-w100p">
                    <h6>
                        <span>{{ suggestion?.insurer?.name }} </span
                        ><span class="u-font-weight-normal">{{ suggestion?.product?.commercialnl?.name }}</span>
                    </h6>
                </td>

                <td *ngIf="minimalWithoutPrice" class="u-vertical-align-middle u-w100p">
                    <div class="u-flex-column u-flex-align-items-start">
                        <h5>{{ suggestion?.insurer?.name }}</h5>
                        <p class="u-color-muted">{{ suggestion?.product?.commercialnl?.name }}</p>
                    </div>
                </td>
            </tr>
            <tr *ngIf="showExtraInfoInsurances">
                <td></td>
                <td>
                    <div class="u-flex-column">
                        <p class="u-color-muted u-font-size-small">{{ 'INSURANCES.SELECTION.MANDATORY' | translate }}</p>
                        <p class="u-color-muted u-font-size-small">{{ 'INSURANCES.SELECTION.OPTIONAL' | translate }}</p>
                        <p *ngIf="isFamily(suggestion)" class="u-color-muted u-font-size-small">
                            {{ 'INSURANCES.SELECTION.EXTRA' | translate }}
                        </p>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!minimal && !minimalWithoutPrice">
                <td class="u-padding-bottom u-vertical-align-top u-color-muted u-padding-right u-text-align-right">
                    {{ 'MOVE.SUGGESTION.MANDATORY' | translate }}
                </td>
                <td class="u-padding-bottom u-vertical-align-top u-w100p">
                    <div class="u-flex-column">
                        <span>{{ 'IOTS.SUGGESTIONS.FIRE_INSURANCE' | translate }}</span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!minimal && !minimalWithoutPrice">
                <td class="u-padding-bottom u-vertical-align-top u-color-muted u-padding-right u-text-align-right">
                    {{ 'MOVE.SUGGESTION.OPTIONAL' | translate }}
                </td>
                <td class="u-padding-bottom u-vertical-align-top u-w100p">
                    <div class="u-flex-column">
                        <span>{{ 'IOTS.SUGGESTIONS.THEFT' | translate }}</span>
                        <span>{{ 'IOTS.SUGGESTIONS.HOUSEHOLD_EFFECTS' | translate }}</span>
                        <span>{{ 'IOTS.SUGGESTIONS.LEGAL_COUNSEL' | translate }}</span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!minimal && !minimalWithoutPrice">
                <td
                    *ngIf="isFamily(suggestion)"
                    class="u-padding-bottom u-vertical-align-top u-color-muted u-padding-right u-text-align-right"
                >
                    {{ 'MOVE.SUGGESTION.EXTRA' | translate }}
                </td>
                <td *ngIf="isFamily(suggestion)" class="u-padding-bottom u-vertical-align-top u-w100p">
                    <div class="u-flex-column">
                        <span>{{ 'IOTS.SUGGESTIONS.FAMILY_INSURANCE' | translate }}</span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!minimal && !minimalWithoutPrice">
                <td *ngIf="showEdit"></td>
                <td>
                    <div class="u-flex-row u-flex-align-items-center u-margin-top">
                        <a class="u-link" *ngIf="showEdit" [routerLink]="suggestionsRoute">{{ 'EDIT' | translate }}</a>
                    </div>
                </td>
            </tr>
        </table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionInsurancesOfferComponent {
    @Input() public suggestion: InsurancesSuggestion;
    @Input() public paymentType: PaymentType;
    @Input() public showEdit = false;
    @Input() public showPaymentType = false;
    @Input() public minimal = false;
    @Input() public minimalWithoutPrice = false;
    @Input() public suggestionsRoute: string;

    @Input() public showExtraInfoInsurances: boolean;

    public svg = Svg;

    constructor(public uiSandbox: AppUiSandbox) {}

    public isFamily(suggestion: InsurancesSuggestion): boolean {
        return suggestion.product.code === 'PCK-FIRE-FAM';
    }
}
