import { Pipe, PipeTransform } from '@angular/core';
import { I18nKeyType } from './i18n-key-type.enum';
import { I18nKeyService } from './i18n-key.service';

@Pipe({
    name: 'i18nKey',
})
export class I18nKeyPipe implements PipeTransform {
    constructor(private readonly i18nKeyService: I18nKeyService) {}

    transform(value: string, keyType: I18nKeyType, emptyAs: string = ''): string {
        if (!value || !keyType) return emptyAs;
        return this.i18nKeyService.map(value, keyType) || emptyAs;
    }
}
