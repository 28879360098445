<app-card-container>
    <div class="u-container-small u-margin-0-auto">
        <app-ui-card>
            <ng-container card-header>
                <div class="u-flex-row u-flex-align-items-center">
                    <app-checked class="u-margin-right-half" [checked]="!!activated ? checked.Ok : checked.Nok"></app-checked>
                    <h3 class="u-margin-right-half">{{ label | translate }}</h3>
                    <span class="u-color-muted">{{ (!!activated ? 'ACTIVE' : 'INACTIVE') | translate }}</span>
                </div>
            </ng-container>
            <ng-container card-header-right *ngIf="canDeactivate">
                <button
                    *ngIf="!!activated; else activateCta"
                    app-button
                    [appearance]="buttonAppearance.Stroked"
                    (click)="deactivate()"
                    [context]="uiContext.Accent"
                >
                    {{ 'DEACTIVATE' | translate }}
                </button>
                <ng-template #activateCta>
                    <button app-button (click)="activate()" [context]="uiContext.Accent">
                        {{ 'ACTIVATE' | translate }}
                    </button>
                </ng-template>
            </ng-container>
            <p>{{ description | translate }}</p>
            <div class="__feature-detail-body" *ngIf="!!activated">
                <ng-content></ng-content>
            </div>
        </app-ui-card>
    </div>
</app-card-container>
