import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInput } from '@app/form/interfaces/file-input';
import { BaseInput } from '@smooved/ui';
import { merge } from 'lodash';

@Component({
    selector: 'app-asset-input',
    template: `
        <app-label-container
            [required]="required"
            [label]="label"
            [hasMargin]="hasMargin"
            [id]="'image'"
            [hasMarginDouble]="hasMarginDouble"
        >
            <div class="u-flex-column">
                <div *ngIf="placeholder && !assetPreview" class="u-text-truncate u-white-space-no-wrap u-color-muted u-margin-bottom">
                    {{ placeholder }}
                </div>
                <app-thumbnail
                    [isPreview]="true"
                    class="u-margin-bottom"
                    *ngIf="showPreview && !!assetPreview"
                    [file]="assetPreview"
                ></app-thumbnail>

                <div *ngIf="showFileName && file" class="u-text-truncate u-white-space-no-wrap u-margin-bottom">{{ file.name }}</div>

                <app-file-input
                    id="image"
                    [label]="buttonLabel || ('SELECT' | translate)"
                    [hasMargin]="hasMargin"
                    [hasMarginDouble]="hasMarginDouble"
                    (change)="onChange($event)"
                ></app-file-input>
            </div>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./asset-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AssetInputComponent),
            multi: true,
        },
    ],
})
export class AssetInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public formControlName: string;
    @Input() public label: string;
    @Input() public buttonLabel: string;
    @Input() public placeholder: string;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public showPreview = true;
    @Input() public showFileName = false;

    @Output() public preview: EventEmitter<void> = new EventEmitter<void>();
    @Output() public detail: EventEmitter<FileInput> = new EventEmitter<FileInput>();

    public assetPreview: FileInput;
    public file: File = null;

    public innerModel: string; // base64 string

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: any): void {
        this.innerModel = value;
        if (value) {
            this.assetPreview = { value };
            this.file = value as File;
        }
    }

    public onChange(event): void {
        if (event.target.files) {
            this.file = <File>event.target.files[0];
            this.assetPreview = null;
            const reader = new FileReader();
            reader.onload = (_) => {
                this.assetPreview = merge(this.file, {
                    value: reader.result,
                });
                this.propagateChange(reader.result);
            };
            reader.readAsDataURL(this.file);
        }
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
