import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-insurances-suggestions-content',
    template: `
        <h3 class="u-margin-bottom u-padding-left-container">{{ 'IOTS.SUGGESTIONS.TITLE' | translate }}</h3>

        <form class="u-padding-left-container">
            <app-insurances-suggestion-list
                class="u-display-block u-margin-bottom"
                [suggestions]="suggestions"
                (select)="onSelect($event)"
            ></app-insurances-suggestion-list>
            <p class="u-font-size-small">(*) {{ 'IOTS.SUGGESTIONS.INFO1' | translate }}</p>
            <p class="u-font-size-small u-margin-bottom">(**) {{ 'IOTS.SUGGESTIONS.INFO2' | translate }}</p>
            <app-previous-submit [showSubmit]="false" [ignoreTranslateXStyle]="true" (previous)="goToPrevious()"></app-previous-submit>
        </form>
    `,
    styles: [],
})
export class InsurancesSuggestionsContentComponent {
    @Input() public suggestions: InsurancesSuggestion[];

    constructor(private router: Router, private route: ActivatedRoute, private moveSandbox: MoveSandbox) {}

    public goToPrevious(): void {
        this.router
            .navigate(['../data/room-division'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSelect(suggestion): void {
        const payload: Move = {
            insurancesSelected: true,
            insurancesOffer: {
                insurancesOfferSelection: suggestion,
            },
        };
        this.moveSandbox.patchProperty('', payload, true, (_) => {
            this.router
                .navigate(['../familial'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }
}
