import { Directive, Host, HostBinding, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseInput } from './base-input';

@Directive()
export class BaseAutocompleteInput extends BaseInput {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public autoFocus = false;

    @HostBinding('class') private class = 'm-autocomplete-input';

    protected loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public inputClear(): void {
        this.propagateChange(null);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
