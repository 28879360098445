import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { I18nKeyType, ObjectUtils, RxjsComponent } from '@smooved/core';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { ReviewBucketRequest } from '../../../analytics';
import { UiContext } from '../../../ui.enums';
import { NpsReviewBucket } from '../../enums/nps-review-buckets.enum';
import { EmittableReviewsFilter } from '../../interfaces/reviews-filter';
import { NpsReviewsFilterService } from '../../services/nps-reviews-filter.service';
import { Bucket } from './bucket.interface';
import { BucketsFormFields, bucketsToStarsMap } from './reviews-buckets.constants';
import { ReviewBucketsType } from './reviews-buckets.enum';

// used to create unique DOM id's for inputs
let nextReviewBucketId = 0;

@Component({
    selector: 'app-reviews-buckets',
    templateUrl: 'reviews-buckets.component.html',
    styleUrls: ['reviews-buckets.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsBucketsComponent extends RxjsComponent implements OnInit, AfterViewInit {
    @Input() public set data(request: ReviewBucketRequest) {
        this.handleBuckets(request);
    }

    @Input() public preSelectedBuckets: NpsReviewBucket[];
    @Input() public type = ReviewBucketsType.Default;
    @Input() public context = UiContext.Primary;

    @Output() public readonly filterChange = new EventEmitter<NpsReviewBucket[]>();

    public filter: NpsReviewBucket[] = [];
    public bucketList: Bucket[];

    public readonly i18nKeyType = I18nKeyType;
    public readonly reviewBucketsTypes = ReviewBucketsType;
    public readonly bucketsToStarsMap = bucketsToStarsMap;
    public readonly form = new UntypedFormGroup({
        [BucketsFormFields.Buckets]: new UntypedFormArray([]),
    });

    constructor(private readonly filterService: NpsReviewsFilterService, private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.reset();
        this.filterService.filter$.pipe(takeUntil(this.destroy$)).subscribe(this.handleFilterChanged);
    }

    public ngAfterViewInit(): void {
        this.bucketsFormArray.valueChanges
            .pipe(skipWhile(this.isBucketsEmpty), takeUntil(this.destroy$))
            .subscribe(this.handleInnerFilterChanged);
    }

    public get bucketsFormArray(): UntypedFormArray {
        return this.form.controls[BucketsFormFields.Buckets] as UntypedFormArray;
    }

    public trackByBucket(_index: number, bucket: Bucket): string {
        return bucket.label;
    }

    private format(data: ReviewBucketRequest): Bucket[] {
        return [
            this.viewModelFactory(NpsReviewBucket.Excellent, data),
            this.viewModelFactory(NpsReviewBucket.Great, data),
            this.viewModelFactory(NpsReviewBucket.Average, data),
            this.viewModelFactory(NpsReviewBucket.Poor, data),
            this.viewModelFactory(NpsReviewBucket.Bad, data),
        ];
    }

    private viewModelFactory(bucket: NpsReviewBucket, data): Bucket {
        const value = data[bucket]?.count as number;
        const percentage = data[bucket]?.percentage as number;
        const label = bucket.toUpperCase();
        const option = {
            id: `${bucket}-${nextReviewBucketId++}`,
            value: bucket,
            name: bucket,
        };
        return { label, value, percentage, option };
    }

    private setBucketFormControls(list: Bucket[]): Bucket[] {
        if (!this.bucketsFormArray.length) {
            list.forEach(() => this.addBucketFormControl());
            this.bucketsFormArray.setValue(
                list.map((bucket) =>
                    this.preSelectedBuckets?.includes(bucket.option.name as NpsReviewBucket) ? bucket.option.name : null
                ),
                { emitEvent: false }
            );
        }
        return list;
    }

    private addBucketFormControl(): void {
        this.bucketsFormArray.push(new UntypedFormControl(null));
    }

    private handleFilterChanged = (value: EmittableReviewsFilter): void => {
        if (value.emit && !ObjectUtils.isEqual(this.filter, value.filter.bucket || [])) this.reset();
    };

    private handleInnerFilterChanged = (value: NpsReviewBucket[]): void => {
        this.filter = value.filter((x) => x); //Remove null values of unselected form controls
        this.filterChange.emit(this.filter);
    };

    private handleBuckets = (res: ReviewBucketRequest): void => {
        if (!res) return;
        this.bucketList = this.setBucketFormControls(this.format(res));
    };

    /**
     * isBucketsEmpty will validate values inside bucketsFormArray are Empty (or null) when subscribing to valuesChanges on bucketsFormArray.
     * It'll be used to avoid chain of useless httpCalls while FormControls are created and added into bucketsFormArray.
     */
    public isBucketsEmpty = (values: NpsReviewBucket[]): boolean => {
        return values.every((val) => !val);
    };

    private reset(): void {
        this.filter = this.filterService.filter.bucket || [];
        const parsedFilter = Object.values(NpsReviewBucket).filter((bucket) => this.filter.includes(bucket));
        const parsedFilterValue = Object.values(NpsReviewBucket).map((bucket) => this.filter.includes(bucket));
        this.preSelectedBuckets = this.preSelectedBuckets || parsedFilter;
        this.bucketsFormArray.patchValue(parsedFilterValue);
        this.cdr.markForCheck();
    }
}
