<ng-container *ngIf="externalInfluencerService.externalInfluencer$ | async as externalInfluencer">
    <div class="__info-direction--{{ direction }}">
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.website">
            <div class="u-margin-right-half">
                <app-icon icon="link" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="externalInfluencer.website + '?utm_source=HouseMatch&utm_medium=profilepage&utm_campaign=visibility'"
                target="_blank"
                class="u-h-auto"
                >{{ StringUtils.stripHttpProtocol(externalInfluencer.website) }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.phoneNumber">
            <div class="u-margin-right-half">
                <app-icon icon="phone" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="externalInfluencer.phoneNumber | uriSchema : type.Tel"
                class="u-h-auto"
                >{{ externalInfluencer.phoneNumber }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.instagram">
            <div class="u-margin-right-half">
                <app-icon [brand]="brands.Instagram" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="socialUri.instagram + '/' + externalInfluencer.instagram"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.SOCIALS.INSTAGRAM.LABEL' | translate }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.facebook">
            <div class="u-margin-right-half">
                <app-icon [brand]="brands.Facebook" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="socialUri.facebook + '/' + externalInfluencer.facebook"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.SOCIALS.FACEBOOK.LABEL' | translate }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.linkedIn">
            <div class="u-margin-right-half">
                <app-icon [brand]="brands.LinkedIn" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="socialUri.linkedIn + '/' + externalInfluencer.linkedIn"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.SOCIALS.LINKEDIN.LABEL' | translate }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.twitter">
            <div class="u-margin-right-half">
                <app-svg-illustration class="x-logo" [svg]="svgIllustration.xLogo"></app-svg-illustration>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="socialUri.twitter + '/' + externalInfluencer.twitter"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.SOCIALS.TWITTER.LABEL' | translate }}</a
            >
        </section>
        <section class="u-flex-row u-flex-align-items-center" *ngIf="externalInfluencer.youtube">
            <div class="u-margin-right-half">
                <app-icon [brand]="brands.Youtube" [context]="uiContext.Muted"></app-icon>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="socialUri.youtube + '/' + externalInfluencer.youtube"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.SOCIALS.YOUTUBE.LABEL' | translate }}</a
            >
        </section>
        <section *ngIf="houseMatchCompanyPageUri" class="u-flex-row u-flex-align-items-center">
            <div class="u-margin-right-half">
                <app-svg-illustration [svg]="svgIllustration.HouseMatchIcon" [context]="uiContext.Muted"></app-svg-illustration>
            </div>
            <a
                app-button
                [context]="uiContext.Primary"
                [appearance]="buttonAppearance.Link"
                [href]="houseMatchCompanyPageUri + '/' + externalInfluencer.url"
                target="_blank"
                class="u-h-auto"
                >{{ 'COMMON.HOUSEMATCH.LABEL' | translate }}</a
            >
        </section>
    </div>
</ng-container>
