import { Injectable } from '@angular/core';

import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { iif, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { RedirectSandbox } from '../sandbox/redirect.sandbox';

@Injectable({
    providedIn: 'root',
})
export class LoggedInRedirectGuard {
    constructor(
        private readonly authenticationSandobx: AuthenticationSandbox,
        private readonly redirectSandbox: RedirectSandbox
    ) {}

    public canActivate(): Observable<boolean> | boolean {
        return this.authenticationSandobx.isLoggedIn$.pipe(
            take(1),
            switchMap((isLoggedIn) => iif(() => isLoggedIn, this.redirectSandbox.toDashboard(), of(true)))
        );
    }
}
