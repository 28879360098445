import { MenuItem } from '@smooved/ui';

export enum ReviewsFilterMenu {
    All,
    SmoovedOnly,
    Other,
    Unanswered,
}

const menuLabelPreset = 'ANALYTICS.REVIEWS.FILTER';

export const menuFilters: MenuItem[] = [
    { labelResource: `${menuLabelPreset}.ALL`, goTo: ReviewsFilterMenu.All },
    { labelResource: `${menuLabelPreset}.SMOOVED`, goTo: ReviewsFilterMenu.SmoovedOnly },
    { labelResource: `${menuLabelPreset}.OTHER`, goTo: ReviewsFilterMenu.Other },
    { labelResource: `${menuLabelPreset}.UNANSWERED`, goTo: ReviewsFilterMenu.Unanswered },
];
