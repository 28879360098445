import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AccessLevel, dateTimeFormatDefault, SimpleChangesUtils } from '@smooved/core';
import { ButtonAppearance } from '../button/button.enums';
import { UiIconSize } from '../icon';
import { UiContext } from '../ui.enums';

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent implements OnChanges {
    @Input() public id: string;
    @Input() public author: string;
    @Input() public access = AccessLevel.Internal;
    @Input() public createdOn: Date;
    @Input() public canEdit: boolean;
    @Input() public authorInterpolationParams: object;

    @Output() public edit = new EventEmitter<string>();

    public readonly accessLevels = AccessLevel;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconSize = UiIconSize;
    public readonly dateTimeFormat = dateTimeFormatDefault;

    public innerAuthorInterpolationParams: object;

    @HostBinding('class') public classes: string;

    public ngOnChanges({ access, author, authorInterpolationParams }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(access)) {
            this.classes = `__note-${this.access}`;
        }

        if (SimpleChangesUtils.hasChanged(author, authorInterpolationParams)) {
            this.innerAuthorInterpolationParams = { author: this.author, ...this.authorInterpolationParams };
        }
    }

    public onEdit(): void {
        this.edit.emit(this.id);
    }
}
