import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObjectUtils } from '@smooved/core';

@Directive({
    selector: '[unknownIfEmpty]',
})
export class UnknownIfEmptyDirective implements AfterViewInit, OnChanges {
    @Input() unknownIfEmpty: string;
    @Input() suffix: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService
    ) {}

    ngAfterViewInit() {
        this.checkContent();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.unknownIfEmpty) {
            this.checkContent();
        }
    }

    private checkContent() {
        if (!ObjectUtils.isDefined(this.unknownIfEmpty)) {
            this.renderer.setProperty(this.el.nativeElement, 'textContent', this.translateService.instant('COMPANION.GENERAL.UNKNOWN'));
            this.renderer.addClass(this.el.nativeElement, 'u-color-gray-light');
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'textContent', this.unknownIfEmpty + (this.suffix ? ` ${this.suffix}` : ''));
            this.renderer.removeClass(this.el.nativeElement, 'u-color-gray-light');
        }
    }
}
