<div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center u-margin-y-axis">
    <h4>{{ 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.LABEL' | translate }}</h4>
    <a
        app-button
        appearance="link"
        [routerLink]="dashboardRoute"
        [queryParams]="todosQueryParams"
        [appendIcon]="true"
        icon="chevron_right"
        >{{ 'COMMON.DETAILS' | translate }}</a
    >
</div>
<div class="u-grid u-grid-col-2-repeat">
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.TOOLTIP.TODOS' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.TODOS',
                    value: analytics.warnings.todos,
                    route: dashboardRoute,
                    queryParams: todosQueryParams
                }
            }"
        ></ng-container>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.TOOLTIP.UNREACHABLES' }"
        [border]="false"
        [context]="cardContext.Danger"
        class="u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.NO_LIVES',
                    value: analytics.warnings.unreachables,
                    route: dashboardRoute,
                    queryParams: unreachableQueryParams
                }
            }"
        ></ng-container>
    </app-context-card>
    <app-context-card
        appTooltip
        [template]="tooltipTemplate"
        [templateOutletContext]="{ $implicit: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.TOOLTIP.LOST_INTEREST' }"
        [border]="false"
        [context]="cardContext.Muted"
        class="u-text-align-center"
    >
        <ng-container
            [ngTemplateOutlet]="kpi"
            [ngTemplateOutletContext]="{
                $implicit: {
                    label: 'ANALYTICS.DASHBOARD.ATTENTION_REQUIRED.LOST_INTEREST',
                    value: analytics.warnings.lostInterest,
                    route: financialReportRoute
                }
            }"
        ></ng-container>
    </app-context-card>
</div>

<ng-template #kpi let-item>
    <h3>{{ item.value }}</h3>
    <p class="u-font-size-small">{{ item.label | translate }}</p>
    <a
        app-button
        *ngIf="item.route"
        size="sm"
        appearance="link"
        [routerLink]="item.route"
        [queryParams]="item.queryParams"
        [appendIcon]="true"
        icon="chevron_right"
        >{{ 'COMMON.SHOW' | translate }}</a
    >
</ng-template>

<ng-template #tooltipTemplate let-item>
    <p class="u-font-size-default u-font-weight-normal u-w25vw">{{ item | translate }}</p>
</ng-template>
