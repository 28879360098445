import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ComponentRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    PLATFORM_ID,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { BoundingClientRect, DomService, EventArg, EventName, Keyboard, RxjsDirective } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cursor, UiAlignment, UiContext, UiPlacement, UiSize } from '../ui.enums';
import { Offset } from './tooltip-offset.interface';
import { TooltipComponent } from './tooltip.component';
import { distanceFromElement } from './tooltip.constants';
import { TooltipTrigger } from './tooltip.enums';
import { TooltipService } from './tooltip.service';

let tooltipUid = 0;

@Directive({ selector: '[appTooltip]', exportAs: 'Tooltip' })
export class TooltipDirective extends RxjsDirective implements OnDestroy, CanDisable {
    @Input() public label: string;
    @Input() public sub: string;
    @Input() public context = UiContext.Success;
    @Input() public template: TemplateRef<unknown>;
    @Input() public templateOutletContext: object;
    @Input() public placement = UiPlacement.Top;
    @Input() public alignment: UiAlignment;
    @Input() public hidePointer: boolean;
    @Input() public trigger: TooltipTrigger = TooltipTrigger.Hover;
    @Input() public tooltipClasses: string;
    @Input() public boundsRef: HTMLElement;
    @Input() public customPosition: Offset;
    @Input() public paddingSize = UiSize.Md;
    @Input() public hasDistanceFromElement = true;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('tooltipDisabled') public disabled: boolean;

    public readonly uid = tooltipUid++;
    private openedSubject = new BehaviorSubject<boolean>(false);
    private tooltipRef: ComponentRef<TooltipComponent>;
    private unlistenClickout: () => void;
    private unlistenKeyEsc: () => void;
    private unlistenClickMouseEnter: () => void;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private el: ElementRef,
        private renderer: Renderer2,
        private tooltipService: TooltipService,
        private domService: DomService,
        @Inject(DOCUMENT) private readonly doc: Document,
        @Inject(PLATFORM_ID) private readonly platformId: Object
    ) {
        super();
        this.openedSubject.pipe(takeUntil(this.destroy$)).subscribe((open) => {
            open ? this.openTooltip() : this.closeTooltip();
        });

        this.tooltipService.closeAll$.pipe(takeUntil(this.destroy$)).subscribe(this.handleCloseOther);
    }

    @HostBinding('style.cursor') public get hasPointerCursor(): string {
        return !this.disabled ? Cursor.Pointer : Cursor.Default;
    }

    // Bugfix: Mobile alternative for hover
    @HostListener(EventName.TouchStart, [EventArg.$Event]) onTouch(event: TouchEvent): void {
        if (this.trigger !== TooltipTrigger.Hover) return;
        this.handleToggle(event);
    }

    @HostListener(EventName.MouseEnter, [EventArg.$Event]) onMouseEnter(event: MouseEvent): void {
        if (this.trigger !== TooltipTrigger.Hover) return;
        this.unlistenClickMouseEnter = this.renderer.listen(this.el.nativeElement, 'click', this.preventEmit);
        this.handleOpen(event);
    }

    @HostListener(EventName.MouseLeave, [EventArg.$Event]) onMouseLeave(event: MouseEvent): void {
        if (this.trigger !== TooltipTrigger.Hover) return;
        this.handleClose(event);
    }

    @HostListener(EventName.Click, [EventArg.$Event]) onClick(event: MouseEvent): void {
        if (this.trigger !== TooltipTrigger.Click) return;
        this.handleToggle(event);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.closeTooltip(); // make sure that tooltip component is closed when directive is destroyed (stopPropagation and preventDefault can prevent from closing tooltip even when click out of tooltip)
    }

    public open(): void {
        if (!this.openedSubject.value) this.openedSubject.next(true);
    }

    public close(): void {
        this.openedSubject.next(false);
    }

    public toggle(): void {
        this.openedSubject.next(!this.openedSubject.value);
    }

    private openTooltip(): void {
        if (!this.isBrowser()) return;
        if (this.disabled) return;
        this.tooltipService.closeAll(this.uid);
        this.tooltipRef = this.domService.createAndAttach<TooltipComponent>(TooltipComponent);
        this.tooltipRef.instance.label = this.label;
        this.tooltipRef.instance.sub = this.sub;
        this.tooltipRef.instance.context = this.context;
        this.tooltipRef.instance.placement = this.placement;
        this.tooltipRef.instance.alignment = this.alignment;
        this.tooltipRef.instance.hidePointer = this.hidePointer;
        this.tooltipRef.instance.tooltipClasses = this.tooltipClasses;

        const [x, y] = this.calculatePlacement();
        this.tooltipRef.instance.positionX = x;
        this.tooltipRef.instance.positionY = y;
        this.tooltipRef.instance.templateRef = this.template;
        this.tooltipRef.instance.templateOutletContext = this.templateOutletContext;

        if (this.trigger !== TooltipTrigger.Click) return;
        setTimeout(() => {
            this.unlistenClickout = this.renderer.listen(this.doc, 'click', this.handleClose);
            this.unlistenKeyEsc = this.renderer.listen(this.doc, 'keydown', this.handleKeydown);
            window.addEventListener('scroll', this.handleScroll, true);
        }, 0);
    }

    private closeTooltip(): void {
        if (!this.isBrowser()) return;
        if (this.viewContainerRef) {
            this.tooltipRef?.destroy();
        }
        this.unlistenClickout?.();
        this.unlistenKeyEsc?.();
        this.unlistenClickMouseEnter?.();
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    private getOffset(el: ElementRef): Offset {
        const { left, right, top, bottom } = el.nativeElement.getBoundingClientRect() as BoundingClientRect;
        return {
            offsetLeft: left,
            offsetTop: top,
            offsetWidth: right - left,
            offsetHeight: bottom - top,
        };
    }

    private calculatePlacement(): [number, number] {
        const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = this.customPosition || this.getOffset(this.el);

        const posLeft = offsetLeft + (!this.hasDistanceFromElement && distanceFromElement / 2);
        const posRight = offsetLeft + offsetWidth + (!this.hasDistanceFromElement && distanceFromElement);
        const posCenterX = offsetLeft + offsetWidth / 2;

        const posTop = offsetTop + (!this.hasDistanceFromElement && distanceFromElement / 2);
        const posBottom = offsetTop + offsetHeight + (!this.hasDistanceFromElement && distanceFromElement);
        const posCenterY = offsetTop + offsetHeight / 2;

        let posAlignmentX;

        switch (this.alignment) {
            case UiAlignment.Left: {
                posAlignmentX = posLeft;
                break;
            }
            case UiAlignment.Right: {
                posAlignmentX = posRight;
                break;
            }
            default: {
                posAlignmentX = posCenterX;
            }
        }

        switch (this.placement) {
            case UiPlacement.Left: {
                return [posLeft, posCenterY];
            }
            case UiPlacement.Right: {
                return [posRight, posCenterY];
            }
            case UiPlacement.Bottom: {
                return [posAlignmentX, posBottom];
            }
            case UiPlacement.LeftStart: {
                return [posLeft, posTop];
            }
            case UiPlacement.LeftEnd: {
                return [posLeft, posBottom];
            }
            default: {
                return [posAlignmentX, posTop];
            }
        }
    }

    private handleKeydown = (event: KeyboardEvent): void => {
        if (event.key === Keyboard.Escape) this.handleClose(event);
    };

    private handleClose = (event?: TouchEvent | MouseEvent | KeyboardEvent | Event): void => {
        this.preventEmit(event);
        this.close();
    };

    private handleScroll = (event: Event): void => {
        if (event) this.handleClose(event);
    };

    private handleOpen = (event?: TouchEvent | MouseEvent): void => {
        this.preventEmit(event);
        this.open();
    };

    private handleToggle = (event?: TouchEvent | MouseEvent): void => {
        this.preventEmit(event);
        this.toggle();
    };

    private handleCloseOther = (uid?: number): void => {
        if (uid !== this.uid) this.close();
    };

    private preventEmit = (event: TouchEvent | MouseEvent | KeyboardEvent | Event): void => {
        event?.preventDefault();
        event?.stopImmediatePropagation();
    };

    private isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}
