<app-menu>
    <app-menu-item
        [goTo]="['..', 'reviews', navigation.Overview]"
        appearance="button"
        [svg]="svgIllustration.Review"
        [svgSize]="menuItemIconSize.Md"
        (click)="onMenuClick()"
        [badge]="realEstateAgentSandbox.unreadReviewBadge$ | async"
    >
        {{ 'REVIEWS.MENU.ALL_REVIEWS' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'reviews', navigation.Insights]"
        appearance="button"
        [svg]="svgIllustration.Insights"
        [svgSize]="menuItemIconSize.Md"
        (click)="onMenuClick()"
    >
        {{ 'REVIEWS.MENU.INSIGHTS' | translate }}
    </app-menu-item>
</app-menu>
