<app-loading-container [loading]="loading">
    <app-card-container class="u-flex-row u-flex-justify-content-center" *ngIf="{ movers: movers$ | async } as vm">
        <div class="u-container">
            <app-card class="u-flex-column u-flex-align-items-start">
                <h3 class="u-margin-bottom">{{ 'ENERGY_STOP.NOT_INTERESTED.TITLE' | translate }}</h3>
                <div class="u-white-space-pre-wrap u-margin-bottom-double">
                    <p>{{ 'COMMON.ADDRESS' | translate }}</p>
                    <h5>{{ vm.movers?.transferee.user.movingAddress | formatAddress: '\n':false }}</h5>
                </div>
                <h4>{{ 'ENERGY_STOP.NOT_INTERESTED.HEADER' | translate }}</h4>
                <p class="u-margin-bottom-double">{{ 'ENERGY_STOP.NOT_INTERESTED.BODY' | translate }}</p>
                <div class="u-flex-row">
                    <button smvd-ui-button [context]="uiContext.Secondary" class="u-margin-right-half" (click)="confirmNotInterested()">
                        {{ 'ENERGY_STOP.NOT_INTERESTED.CTA.CONFIRM' | translate }}
                    </button>
                    <a smvd-ui-button [context]="uiContext.Secondary" [appearance]="buttonAppearance.Stroked" [routerLink]="readingsRoute">
                        {{ 'ENERGY_STOP.NOT_INTERESTED.CTA.CANCEL' | translate }}
                    </a>
                </div>
            </app-card>
        </div>
    </app-card-container>
</app-loading-container>
