import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DbUtils, Location, RealEstateAgency, RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { baseInputWidth } from '../../../form';
import { locationDefault } from '../../../real-estate-group/components/locations-input/locations-input.constants';
import { NpsReviewsFilterService } from '../../services';

@Component({
    selector: 'smvd-ui-reviews-by-location',
    templateUrl: './reviews-by-location.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsByLocationComponent extends RxjsComponent implements OnInit {
    public readonly control = this.formBuilder.control(locationDefault.value);

    @Input()
    public width = baseInputWidth;

    @Output()
    public selected: EventEmitter<RealEstateAgency> = new EventEmitter<RealEstateAgency>();

    constructor(
        public readonly filterService: NpsReviewsFilterService,
        private readonly formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((location: RealEstateAgency) => {
            this.filterService.filterByLocation(DbUtils.getStringId(location));
            this.selected.emit(location);
        });
    }
}
