import { Component } from '@angular/core';
import { HeaderService } from '@app/header/services/header.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';

@Component({
    selector: 'app-real-estate-agent-logo-button',
    template: `
        <app-button theme="logo-button" [routerLink]="home" (click)="dispatchMenu()">
            <app-powered-by [showText]="false"></app-powered-by>
        </app-button>
    `,
    styleUrls: ['./real-estate-agent-logo-button.component.scss'],
})
export class RealEstateAgentLogoButtonComponent {
    public home = `/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentStart}`;

    constructor(private readonly headerService: HeaderService) {}

    public dispatchMenu(): void {
        this.headerService.dispatch();
    }
}
