import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { environment } from '@environments/environment';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-move-detail-insurances',
    templateUrl: 'move-detail-insurances.component.html',
    styles: [':host {display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailInsurancesComponent {
    public readonly svg = Svg;
    public readonly partner = environment.insurancesPartner;

    @Input() public move: Move;
}
