import { Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-expansion-panel',
    template: `
        <mat-accordion>
            <mat-expansion-panel (opened)="openState = true" (closed)="openState = false" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="u-flex-row">
                            <app-svg
                                [name]="chevronRight"
                                [ngClass]="{ '__chevron--open': openState }"
                                class="__chevron u-color-muted u-margin-right-half"
                            ></app-svg>
                            <ng-content select="[title]"></ng-content>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        <ng-content select="[description]"></ng-content>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-content></ng-content>
            </mat-expansion-panel>
        </mat-accordion>
    `,
    styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
    @Input() public openState = false;

    public chevronRight = Svg.ChevronRight;
}
