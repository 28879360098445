import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { UriSchemaType } from '@smooved/ui';

@Component({
    selector: 'app-user-detail',
    template: `
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">{{ label | translate }}</h6>
            <ng-container *ngIf="move?.user as user; else noUser">
                <span *ngIf="user.formattedName as name">{{ name }}</span>
                <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between" *ngIf="user.phoneNumber as phone">
                    <a class="u-link" [href]="phone | uriSchema: tel">{{ phone }}</a>
                    <app-icon icon="call" size="md" class="u-color-accent" appearance=""></app-icon>
                </div>
                <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between" *ngIf="user.email as email">
                    <a class="u-link" [href]="email | uriSchema: mailTo">{{ email }}</a>
                    <app-icon icon="mail" size="md" class="u-color-accent" appearance=""></app-icon>
                </div>
                <span *ngIf="user.formattedDateOfBirth as birthDate">{{ birthDate }}</span>
            </ng-container>
        </div>
        <ng-template #noUser>
            <span class="u-color-muted u-opacity-50">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.LEAVER_UNKNOWN' | translate }}</span>
        </ng-template>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailComponent {
    @Input() public move: Move;
    @Input() public label = 'MOVE.MOVER_ROLE.TRANSFEREE';

    public mailTo = UriSchemaType.MailTo;
    public tel = UriSchemaType.Tel;
}
