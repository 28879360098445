import { Component, OnInit } from '@angular/core';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { DateUtils } from '@smooved/core';

@Component({
    selector: 'app-invoices-container',
    templateUrl: 'invoices.container.html',
})
export class InvoicesContainer implements OnInit {
    public realEstateGroupId: string;

    constructor(public realEstateGroupSandbox: RealEstateGroupSandbox, public invoicingSandbox: InvoicingSandbox) {}

    public ngOnInit(): void {
        this.reload();
    }

    public reload(): void {
        this.realEstateGroupSandbox.idOnce$.subscribe((realEstateGroup) => {
            this.invoicingSandbox.getInvoicesSent(realEstateGroup, null, DateUtils.tz().startOf('Q').subtract(3, 'Q').toDate());
        });
    }
}
