export class UiUtils {
    public static hexToRgb(hex): { r: number; g: number; b: number } {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }

    public static createVariant(variant: string, label: string, classes?: string): string {
        return `<${variant} class="${classes}">${label}</${variant}>`;
    }

    public static vhToPixels(vh = 1): number {
        return Math.round(window.innerHeight / (100 / vh));
    }
}
