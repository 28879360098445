import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseTableComponent } from '@app/admin/components/base-table/base-table.component';
import {
    energyColumns,
    giftsColumns,
    insurancesColumns,
    mortgagesColumns,
    telecomColumns,
} from '@app/invoicing/components/quarterly-table/quarterly-table.constants';
import { InvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Period } from '@app/invoicing/enums/period.enum';
import { BaseMovesDataSource } from '@app/move/datasources/base-moves/base-moves.data-source';
import { QuarterlyDataSource } from '@app/move/datasources/quarterly/quarterly.data-source';
import { Move } from '@app/move/interfaces/move';
import { MoveService } from '@app/move/services/move.service';
import { DateUtils, SimpleChangesUtils } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-quarterly-table',
    template: `
        <app-card [bordered]="false" [paddingDouble]="false">
            <h6 class="u-padding u-bordered-bottom">{{ title | translate }}</h6>
            <app-loading-container [loading]="dataSource?.loading$ | async">
                <table mat-table [dataSource]="dataSource" class="u-w100p u-data-table-with-hover">
                    <app-table-column-formatted-name></app-table-column-formatted-name>

                    <app-table-column-moving-address></app-table-column-moving-address>

                    <app-table-column-moving-date [withSort]="false"></app-table-column-moving-date>

                    <app-table-column-energy-flow-type></app-table-column-energy-flow-type>

                    <app-table-column-insurances-flow-type></app-table-column-insurances-flow-type>

                    <app-table-column-fee></app-table-column-fee>

                    <app-table-column-fee-insurances></app-table-column-fee-insurances>

                    <app-table-column-ean-count></app-table-column-ean-count>

                    <app-table-column-detail-arrow></app-table-column-detail-arrow>

                    <!-- Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns" (click)="clickDetail.emit(row)"></tr>
                </table>
                <mat-paginator
                    [length]="dataSource?.movesCount$ | async"
                    [pageSize]="pageSizeOptions[0]"
                    [showFirstLastButtons]="true"
                    [pageSizeOptions]="pageSizeOptions"
                ></mat-paginator>
            </app-loading-container>
        </app-card>
    `,
})
export class QuarterlyTableComponent extends BaseTableComponent implements OnInit, OnChanges {
    @Input() public type: InvoicingType;
    @Input() public realEstateGroupId: string;
    @Input() public quarterDate: Date;

    @Output() public clickDetail: EventEmitter<Move> = new EventEmitter<Move>();

    public title: string;
    public dataSource: BaseMovesDataSource;
    public columns;

    constructor(protected readonly dialog: MatDialog, private readonly moveService: MoveService) {
        super(dialog);
    }

    public ngOnChanges({ quarterDate, type }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(type) || SimpleChangesUtils.hasChanged(quarterDate)) {
            this.setDataAndLoad();
        }
    }

    private setDataAndLoad(): void {
        switch (this.type) {
            case InvoicingType.EnergyOrdered:
                this.columns = energyColumns;
                this.title = 'INVOICING.ORDERED';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.EnergyOrdered,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.EnergyToOrderBySmooved:
                this.columns = energyColumns;
                this.title = 'INVOICING.TO_ORDER_BY_SMOOVED';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.EnergyToOrderBySmooved,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.EnergyToOrder:
                this.columns = energyColumns;
                this.title = 'INVOICING.TO_ORDER';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.EnergyToOrder,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.EnergyCarriedOverToNextQuarter:
                this.columns = energyColumns;
                this.title = 'INVOICING.CARRIED_OVER_TO_NEXT_QUARTER';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.EnergyToOrderBySmooved, Period.CurrentQuarter);
                break;

            case InvoicingType.EnergyNotInterested:
                this.columns = energyColumns;
                this.title = 'INVOICING.NOT_INTERESTED';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.EnergyNotInterested, null);
                break;

            case InvoicingType.InsurancesConfirmed:
                this.columns = insurancesColumns;
                this.title = 'INVOICING.INSURANCES.CONFIRMED';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.InsurancesConfirmed, null);
                break;

            case InvoicingType.InsurancesPending:
                this.columns = insurancesColumns;
                this.title = 'INVOICING.INSURANCES.PENDING';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.InsurancesPending, null);
                break;

            case InvoicingType.InsurancesLost:
                this.columns = insurancesColumns;
                this.title = 'INVOICING.INSURANCES.LOST';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.InsurancesLost, null);
                break;

            case InvoicingType.TelecomOrdered:
                this.columns = telecomColumns;
                this.title = 'INVOICING.ORDERED';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.TelecomOrdered,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.TelecomToOrderBySmooved:
                this.columns = telecomColumns;
                this.title = 'INVOICING.TO_ORDER_BY_SMOOVED';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.TelecomToOrderBySmooved,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.TelecomToOrder:
                this.columns = telecomColumns;
                this.title = 'INVOICING.TO_ORDER';
                this.dataSource = new QuarterlyDataSource(
                    this.moveService,
                    InvoicingType.TelecomToOrder,
                    Period.CarriedOverPreviousQuarterOrCurrentQuarter
                );
                break;

            case InvoicingType.TelecomCarriedOverToNextQuarter:
                this.columns = telecomColumns;
                this.title = 'INVOICING.CARRIED_OVER_TO_NEXT_QUARTER';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.TelecomToOrderBySmooved, Period.CurrentQuarter);
                break;

            case InvoicingType.TelecomNotInterested:
                this.columns = telecomColumns;
                this.title = 'INVOICING.NOT_INTERESTED';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.TelecomNotInterested, null);
                break;

            case InvoicingType.MortgagesConfirmed:
                this.columns = mortgagesColumns;
                this.title = 'INVOICING.MORTGAGES.CONFIRMED';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.MortgagesConfirmed, null);
                break;

            case InvoicingType.MortgagesPending:
                this.columns = mortgagesColumns;
                this.title = 'INVOICING.MORTGAGES.PENDING';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.MortgagesPending, null);
                break;

            case InvoicingType.GiftsOrdered:
                this.columns = giftsColumns;
                this.title = 'INVOICING.GIFTS.ORDERED';
                this.dataSource = new QuarterlyDataSource(this.moveService, InvoicingType.GiftsOrdered, null);
                break;

            default:
                this.title = null;
                this.dataSource = null;
        }

        if (this.quarterDate && this.dataSource) {
            this.load();
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe((_) => this.load());
    }

    public load(): void {
        this.dataSource.load(
            {
                realEstateGroup: this.realEstateGroupId,
                year: DateUtils.tz(this.quarterDate).year(),
                quarter: DateUtils.tz(this.quarterDate).quarter(),
            },
            {
                pageSize: this.currentPageSize(),
                pageIndex: this.currentPageIndex(),
            }
        );
    }
}
