<ng-container *ngIf="{ hasHeader: !!header || !!subheader } as vm">
    <app-mobile-modal [showBackNavigation]="useBackNavigation" [class.u-background-white]="!useBackNavigation" [showHeader]="vm.hasHeader">
        <button
            *ngIf="!useBackNavigation && !hideCloseButton"
            mobile-modal-extra-action
            app-button
            appearance="icon"
            icon="close"
            iconSize="md"
            class="__close"
            mat-dialog-close
            context="muted"
        ></button>
        <div mobile-modal-header *ngIf="vm.hasHeader">
            <h6 *ngIf="!!header">{{ header | translate }}</h6>
            <span *ngIf="!!subheader">{{ subheader | translate }}</span>
        </div>
        <div mobile-modal-main>
            <ng-template #ref></ng-template>
        </div>
    </app-mobile-modal>
</ng-container>
