<table
    matSort
    [matSortActive]="defaultSortField"
    [matSortDirection]="defaultSortDirection"
    matSortDisableClear
    mat-table
    [dataSource]="dataSource"
    class="u-w100p u-data-table-with-hover"
>
    <app-table-column-name></app-table-column-name>

    <app-table-column-moving-address></app-table-column-moving-address>

    <app-table-column-name-address></app-table-column-name-address>

    <app-table-column-created-on></app-table-column-created-on>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.STATUS' | translate }}</th>
        <td mat-cell *matCellDef="let row" class="__mortgage-status">
            <p *ngIf="row.state === moveState.Active; else inactive">{{ 'MORTGAGE.OVERVIEW.MOVE_ACTIVE' | translate }}</p>
        </td>
        <ng-template #inactive>
            <p class="u-color-muted">-</p>
        </ng-template>
    </ng-container>

    <app-table-column-detail-arrow></app-table-column-detail-arrow>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
    <tr mat-row *matRowDef="let row; columns: columns" (click)="rowClick.emit(row)"></tr>
</table>

<mat-paginator [length]="count" [hidePageSize]="true" [pageSize]="defaultPageSize" [showFirstLastButtons]="true"></mat-paginator>
