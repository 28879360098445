import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CardModule } from '@app/card/card.module';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { MessageModule } from '@app/message/message.module';
import { MeterReadingsModule } from '@app/meter-readings/meter-readings.module';
import { MoveWizardModule } from '@app/move-wizard/move-wizard.module';
import { OrderModule } from '@app/order/order.module';
import { GiftDetailsComponent } from '@app/partner/components/gift-details/gift-details.component';
import { MeterReadingsComponent } from '@app/partner/components/meter-readings/meter-readings.component';
import { OrderListClosedTableComponent } from '@app/partner/components/order-list-closed-table/order-list-closed-table.component';
import { OrderListOpenTableComponent } from '@app/partner/components/order-list-open-table/order-list-open-table.component';
import { TelecomDetailsComponent } from '@app/partner/components/telecom-details/telecom-details.component';
import { TelecomOrderDetailsComponent } from '@app/partner/components/telecom-order-details/telecom-order-details.component';
import { LoginContainer } from '@app/partner/containers/login/login.container';
import { OrderListBaseContainer } from '@app/partner/containers/order-list-base/order-list-base.container';
import { OrderListClosedContainer } from '@app/partner/containers/order-list-closed/order-list-closed.container';
import { OrderListOpenContainer } from '@app/partner/containers/order-list-open/order-list-open.container';
import { OrderListContainer } from '@app/partner/containers/order-list/order-list.container';
import { GiftDetailModal } from '@app/partner/modals/gift-detail/gift-detail.modal';
import { TelecomDetailModal } from '@app/partner/modals/telecom-detail/telecom-detail.modal';
import { PartnerRoutingModule } from '@app/partner/partner-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslationModule } from '@app/translation/translation.module';
import { CheckItemModule } from '@app/ui/check-item/check-item.module';
import { WaterModule } from '@app/water/water.module';
import { EnergyModule } from '@app/wizard/energy/energy.module';
import { PipeModule as CorePipeModule } from '@smooved/core';
import { InfoModule, SvgModule, TrackAndTraceModule, UiModalModule } from '@smooved/ui';
import { OrderListInProgressTableComponent } from './components/order-list-in-progress-table/order-list-in-progress-table.component';
import { RealEstateAgentDetailsComponent } from './components/real-estate-agent-details/real-estate-agent-details.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { OrderListInProgressContainer } from './containers/order-list-in-progress/order-list-in-progress.container';
import { RentalInspectionDetailModal } from './modals/rental-inspection-detail/rental-inspection-detail.modal';

@NgModule({
    declarations: [
        LoginContainer,
        OrderListContainer,
        OrderListBaseContainer,
        OrderListOpenContainer,
        OrderListInProgressContainer,
        OrderListClosedContainer,
        OrderListOpenTableComponent,
        OrderListClosedTableComponent,
        OrderListInProgressTableComponent,
        TelecomDetailModal,
        GiftDetailModal,
        RentalInspectionDetailModal,
        UserDetailsComponent,
        RealEstateAgentDetailsComponent,
        TelecomOrderDetailsComponent,
        TelecomDetailsComponent,
        GiftDetailsComponent,
        MeterReadingsComponent,
    ],
    imports: [
        SharedModule,
        TranslationModule,
        PartnerRoutingModule,
        CardModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MoveWizardModule,
        SvgModule,
        CheckItemModule,
        TrackAndTraceModule,
        OrderModule,
        UiModalModule,
        EnergyModule,
        WaterModule,
        CorePipeModule,
        InfoModule,
        DocumentCenterModule,
        MessageModule,
        MeterReadingsModule,
    ],
})
export class PartnerModule {}
