<app-two-panel-modal [type]="twoPanelType.LeftMaxRightFr" class="__custom-panel" [hasClosableModal]="false" [loading]="loading">
    <ng-container modal-left>
        <app-custom-message-filter [move]="move" (filterChange)="onFilterChange($event)"></app-custom-message-filter>
    </ng-container>
    <ng-container modal-right>
        <app-mail-form
            *ngIf="messageType === contactLogType.EmailCustomMover; else smsForm"
            [data]="content"
            [showCc]="true"
            [showBcc]="true"
            [alert]="mailFormREAMeterAlert"
            (submit)="onMailSubmit()"
        ></app-mail-form>
        <ng-template #smsForm>
            <app-sms-custom-form [data]="content" [formControl]="smsControl"></app-sms-custom-form>
            <div class="u-flex-row u-flex-justify-content-flex-end">
                <app-button (click)="onSmsSubmit()">{{ 'SEND' | translate }}</app-button>
            </div>
        </ng-template>
    </ng-container>
</app-two-panel-modal>
