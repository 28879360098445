<app-loading-container [loading]="loading">
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="u-flex-column u-flex-align-items-end">
        <smvd-ui-dropdown-input
            [formControlName]="logActionsControlNames.Type"
            [label]="'ACTION.TYPE.LABEL' | i18nKeyTranslate: i18nKeys.Escalation "
            [hasMargin]="false"
            width="30rem"
            [hasMarginDouble]="true"
            [options]="contactLogTypeOptions"
        ></smvd-ui-dropdown-input>
        <smvd-app-mention-input
            width="30rem"
            [label]="'ACTION.REMARK' | i18nKeyTranslate: i18nKeys.Escalation"
            [formControlName]="logActionsControlNames.Remark"
            [hasMargin]="false"
            [hasMarginDouble]="true"
            [userGroups]="mentionUserGroups"
        ></smvd-app-mention-input>
        <smvd-ui-dropdown-input
            [formControlName]="logActionsControlNames.State"
            [label]="'STATE.TITLE' | i18nKeyTranslate: i18nKeys.Escalation"
            [options]="stateOptions"
            [hasMargin]="false"
            [hasMarginDouble]="true"
            width="100%"
        ></smvd-ui-dropdown-input>
        <div class="u-flex-row u-flex-align-items-end">
            <button
                app-button
                [context]="uiContext.Secondary"
                class="u-margin-right-half"
                [appearance]="buttonAppearance.Stroked"
                (click)="cancel()"
            >
                {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button app-button [context]="uiContext.Secondary" type="submit">{{ 'COMMON.SEND' | translate }}</button>
        </div>
    </form>
</app-loading-container>
