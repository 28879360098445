import { Injectable } from '@angular/core';
import { PaginationRequest, RxjsService } from '@smooved/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { defaultPaginationOptions } from '../constants/pagination.constants';
import { PaginationOptions } from '../interfaces/pagination-options.interface';

@Injectable()
export class PaginationService extends RxjsService {
    public defaultOptions = defaultPaginationOptions;
    public totalItems: number = null;

    public pageIndex$ = new BehaviorSubject<number>(this.defaultOptions.pageIndex);
    public pageSize$ = new BehaviorSubject<number>(this.defaultOptions.pageSize);
    public page$: Observable<PaginationOptions>;

    constructor() {
        super();
        this.page$ = combineLatest([this.pageIndex$.asObservable(), this.pageSize$.asObservable()]).pipe(
            map(([pageIndex, pageSize]) => ({ pageIndex, pageSize })),
            takeUntil(this.destroy$)
        );
    }

    public set(defaultOptions?: PaginationOptions): void {
        this.setDefaults(defaultOptions);
    }

    public currentPageSize(): number {
        return this.pageSize$.value ?? this.defaultOptions.pageSize;
    }

    public currentPageIndex(): number {
        return this.pageIndex$.value ?? this.defaultOptions.pageIndex;
    }

    public getPaginationOptions(): PaginationRequest {
        return {
            pageSize: this.currentPageSize(),
            pageIndex: this.currentPageIndex(),
        };
    }

    public getReloadPaginationOptions(): PaginationRequest {
        return {
            pageSize: this.currentPageSize() * (this.currentPageIndex() + 1),
            pageIndex: 0,
        };
    }

    public firstPage(): void {
        this.pageIndex$.next(this.defaultOptions.pageIndex);
    }

    public nextPage(): void {
        this.pageIndex$.next(this.pageIndex$.value + 1);
    }

    public hasNextPage(): boolean {
        // When totalItems is unknown, this function is not relevant.
        if (this.totalItems == null) return true;
        return this.totalItems - (this.pageIndex$.value + 1) * this.pageSize$.value > 0;
    }

    public previousPage(): void {
        this.pageIndex$.next(this.pageIndex$.value - 1);
    }

    public init(): void {
        this.pageIndex$.next(this.defaultOptions.pageIndex);
        this.pageSize$.next(this.defaultOptions.pageSize);
    }

    private setDefaults(defaultOptions: PaginationOptions): void {
        if (defaultOptions) {
            this.defaultOptions.pageSize = defaultOptions.pageSize ?? this.defaultOptions.pageSize;
            this.defaultOptions.pageIndex = defaultOptions.pageIndex ?? this.defaultOptions.pageIndex;
        }

        this.init();
    }
}
