import {
    ClusterLevelFilter,
    ClusterLevelFilterType,
    OtsConfirmationClusterLevel,
} from '@app/invoicing/enums/ots-confirmation-cluster-level.enum';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { TodoFilter, TodoType, TodoTypeFilter } from '@app/move/enums/todo-type.enum';
import { CreatedBy } from '@app/real-estate-agent/enums/created-by.enum';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { CreatedByFlowEnum, NumberUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { NpsScoreCategoryValue } from 'projects/ui/src/lib/reviews/enums/nps-reviews-category.enum';

export const createdByDefault: CreatedBy = CreatedBy.Office;
export const createdByOnlyTodosDefault: CreatedBy = CreatedBy.Office;

export const createdByFlowDefault: CreatedByFlowEnum = CreatedByFlowEnum.All;
export const createdByFlowOnlyTodosDefault: CreatedByFlowEnum = CreatedByFlowEnum.All;

export const clusterLevelForFilterDefault = ClusterLevelFilter.All;
export const moverRoleDefault = MoverRole.Transferee;

export enum OtherForm {
    CreatedBy = 'createdBy',
    CreatedByFlow = 'createdByFlow',
    MoverRole = 'moverRole',
    ClusterLevel = 'clusterLevel',
    ScoreFilter = 'scoreFilter',
}

export const createdByOptions: DropdownInput<CreatedBy>[] = [
    {
        id: 'created-by-my-self',
        value: CreatedBy.Self,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.SELF',
        name: 'created-by',
    },
    {
        id: 'created-by-my-office',
        value: CreatedBy.Office,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.OFFICE',
        name: 'created-by',
    },
    {
        id: 'created-by-my-group',
        value: CreatedBy.Group,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.GROUP',
        name: 'created-by',
    },
];

const createdByFlowEnumValuesForFilter = [
    CreatedByFlowEnum.All,
    CreatedByFlowEnum.EnergyOptimization,
    CreatedByFlowEnum.Eots,
    CreatedByFlowEnum.Iots,
    CreatedByFlowEnum.Lp,
];

const createdByFlowEnumKeysForFilter = EnumUtils.entries(CreatedByFlowEnum)
    .filter(([_, value]: [string, CreatedByFlowEnum]) => createdByFlowEnumValuesForFilter.includes(value))
    .map(([key, _]: [string, CreatedByFlowEnum]) => key);

export const createdByFlowOptions: DropdownInput<CreatedByFlowEnum>[] = createdByFlowEnumKeysForFilter.map((key) => ({
    id: key,
    name: 'createdByFlow',
    labelResource: `MOVE.CREATED_BY_FLOW.${key.toUpperCase()}.EXTENSION`,
    value: CreatedByFlowEnum[key],
}));

export const moverRoleOptions: DropdownInput<MoverRole>[] = [
    ...EnumUtils.keys(MoverRole).map((key) => ({
        id: key,
        name: 'moverRole',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.MOVER_ROLE.${key.toUpperCase()}`,
        value: MoverRole[key],
    })),
];

const clusterLevelForFilter = [
    OtsConfirmationClusterLevel.Zero,
    OtsConfirmationClusterLevel.One,
    OtsConfirmationClusterLevel.Two,
    OtsConfirmationClusterLevel.Three,
];

const clusterLevelKeysForFilter = EnumUtils.entries(OtsConfirmationClusterLevel)
    .filter(([, value]: [string, OtsConfirmationClusterLevel]) => clusterLevelForFilter.includes(value))
    .map(([key]: [string, OtsConfirmationClusterLevel]) => key);

export const clusterLevelOptions: DropdownInput<ClusterLevelFilterType>[] = [
    {
        id: 'all',
        name: 'otsConfirmationClusterLevel',
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CLUSTER_LEVEL.ALL',
        value: ClusterLevelFilter.All,
    },
    ...clusterLevelKeysForFilter.map((key) => ({
        id: key,
        name: 'otsConfirmationClusterLevel',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.CLUSTER_LEVEL.${key.toUpperCase()}`,
        value: OtsConfirmationClusterLevel[key],
    })),
];

export const todosFactory = (list: string[]): DropdownInput<TodoTypeFilter>[] => {
    return list.map((key) => ({
        id: key,
        name: 'todoOptions',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.${key.toUpperCase()}`,
        value: TodoType[key] || TodoFilter[key],
    }));
};

export const todosForFilterMap = {
    [TodoType.energyNotOrdered]: [TodoType.energyNotOrdered, TodoType.energyOptimizationNotOrdered],
};

export const scoreBucketsOptions: DropdownInput<number[]>[] = [
    {
        id: 'scoreBuckets-good',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.GREATER_THAN_AVERAGE`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.Good, NpsScoreCategoryValue.Excellent),
    },
    {
        id: 'scoreBuckets-average',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.GREATER_THAN_BAD`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.AverageBad, NpsScoreCategoryValue.Excellent),
    },
    {
        id: 'scoreBuckets-all',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.ALL`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.Low, NpsScoreCategoryValue.Excellent),
    },
];

export const scoreBucketsOptionDefault = scoreBucketsOptions[0].value;

export const otherFormDefaultValues = {
    [OtherForm.CreatedBy]: createdByDefault,
    [OtherForm.CreatedByFlow]: createdByFlowDefault,
    [OtherForm.MoverRole]: moverRoleDefault,
    [OtherForm.ScoreFilter]: scoreBucketsOptionDefault,
    [OtherForm.ClusterLevel]: clusterLevelForFilterDefault,
};
