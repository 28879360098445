import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { TwoPanelType } from './two-panel.constants';

@Component({
    selector: 'app-two-panel-modal',
    templateUrl: './two-panel.modal.html',
    styleUrls: ['./two-panel.modal.scss'],
})
export class TwoPanelModalComponent {
    @Input() public hasSidePanel = true;
    @Input() public loading = false;
    @Input() public type: TwoPanelType;
    @Input() public hasClosableModal = true;

    public readonly twoPanelType = TwoPanelType;

    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ModalData, private readonly dialogRef: MatDialogRef<any>) {}

    public openSidePanel(show = true): void {
        this.hasSidePanel = show;
    }

    public toggleSidePanel(): void {
        this.hasSidePanel = !this.hasSidePanel;
    }

    public close(result?: unknown): void {
        this.dialogRef.close(result);
    }
}
