import { UiSize } from '../../../ui.enums';

export const arcScoreSizeMap = {
    [UiSize.Tiny]: 22,
    [UiSize.ExtraExtraSmall]: 30,
    [UiSize.ExtraSmall]: 40,
    [UiSize.Small]: 60,
    [UiSize.Default]: 80,
    [UiSize.Medium]: 80,
    [UiSize.Large]: 150,
    [UiSize.ExtraLarge]: 300,
};

export const arcScoreThicknessMap = {
    [UiSize.Tiny]: 3,
    [UiSize.ExtraExtraSmall]: 4,
    [UiSize.ExtraSmall]: 4,
    [UiSize.Small]: 6,
    [UiSize.Default]: 8,
    [UiSize.Medium]: 8,
    [UiSize.Large]: 12,
    [UiSize.ExtraLarge]: 20,
};

export const thicknessDefault = arcScoreThicknessMap.default;
export const startAngleDefault = Math.PI * -0.75;
export const endAngleDefault = Math.PI * 0.75;
