import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CreateScreenshotRequest } from '@app/document-center/interfaces/create-screenshot-request';
import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Attachment } from '@app/email/interfaces/attachment';
import { FileUtils } from '../../../projects/core/src/lib/utils/file-utils';
import { Move } from '@app/move/interfaces/move';
import { HttpUtils, Mimetypes } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocumentCenterProvidersModule } from './document-center-providers.module';
import { DocumentCenterService } from './services/document-center.service';

interface GetDocument {
    moveId: string;
    documentId: AttachmentId;
    type: Mimetypes;
}

@Injectable({ providedIn: DocumentCenterProvidersModule })
export class DocumentCenterSandbox {
    constructor(
        private readonly documentCenterService: DocumentCenterService,
        @Inject(DOCUMENT) private readonly document,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public downloadEnergyDocumentSummary(moveId: string): void {
        this.openDocument({ moveId, documentId: AttachmentId.EnergyDocumentSummary, type: Mimetypes.Pdf });
    }

    public downloadMoveAsset(moveId: string, documentId: AttachmentId, mimeType = Mimetypes.Pdf): void {
        this.openDocument({ moveId, documentId, type: mimeType });
    }

    public downloadAttachment(attachment: Attachment): void {
        this.documentCenterService.downloadFileLocation(attachment.location).subscribe((response) => {
            FileUtils.openAsset({
                response,
                type: 'arraybuffer',
                fileName: attachment.name,
            });
        });
    }

    public screenshot(payload: CreateScreenshotRequest): Observable<HttpResponse<Blob>> {
        return this.documentCenterService.createScreenshot(payload).pipe(
            tap((response) =>
                FileUtils.openAsset({
                    response,
                    type: 'blob',
                })
            )
        );
    }

    public saveMoveFiles(moveId: string, attachmentId: AttachmentId, files: File[]): Observable<Move> {
        const formData = HttpUtils.addFiles(files);
        return this.documentCenterService.saveMoveFiles(moveId, attachmentId, formData);
    }

    public deleteMoveFile(moveId: string, attachmentId: AttachmentId): Observable<Move> {
        return this.documentCenterService.deleteMoveFile(moveId, attachmentId).pipe(tap(() => this.notificationSandbox.deletedSuccess()));
    }

    private getDocument({ moveId, documentId }: GetDocument): Observable<HttpResponse<ArrayBuffer>> {
        return this.documentCenterService.downloadMoveFile(moveId, documentId);
    }

    private openDocument(option: GetDocument): void {
        this.getDocument(option).subscribe((response) => {
            const { type } = option;
            FileUtils.openAsset({
                response,
                type: 'arraybuffer',
                mimeType: type,
            });
        });
    }
}
