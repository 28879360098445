<app-loading-container [loading]="!move">
    <app-mobile-modal [mainHasPaddingTop]="false">
        <div mobile-modal-header>
            <h6>{{ move?.user?.formattedName }}</h6>
            <span class="u-color-muted u-font-size-small">{{ move?.user?.formattedMovingAddressWithoutCountry }}</span>
        </div>
        <div mobile-modal-main class="u-padding u-background-white">
            <app-user-detail [move]="move" class="u-display-block u-margin-bottom-double"></app-user-detail>
            <app-moving-address-detail [move]="move" class="u-display-block u-margin-bottom-double"> </app-moving-address-detail>
            <app-cta-card-inactive-move [move]="move"></app-cta-card-inactive-move>
        </div>
    </app-mobile-modal>
</app-loading-container>
