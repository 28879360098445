import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuarterYearPipe } from '@app/date/pipes/quarter-year.pipe';
import { QuarterPipe } from '@app/date/pipes/quarter.pipe';

@NgModule({
    declarations: [QuarterYearPipe, QuarterPipe],
    imports: [CommonModule],
    exports: [QuarterYearPipe, QuarterPipe],
})
export class DateModule {}
