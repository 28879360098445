<ui-star-score [score]="meta.externalScore" width="22px" class="__star-score"></ui-star-score>
<div class="u-flex-row u-flex-align-items-end u-margin-top-half">
    <div class="u-position-relative">
        <smvd-ui-svg-illustration [svg]="svgIllustration.Google" [style.width]="'16px'" class="u-margin-right"></smvd-ui-svg-illustration>
        <smvd-ui-svg-illustration
            [svg]="svgIllustration.FacebookScore"
            [style.width]="'24px'"
            [style.height]="'24px'"
            class="__external-icon-overlap"
        ></smvd-ui-svg-illustration>
    </div>
    <label class="__score-label"
        ><span class="u-margin-left-xs u-font-weight-semi-bold u-font-size-18">{{ meta.externalScore | arcScoreValue: 5 }}</span
        ><span id="max-score" class="__dot u-margin-right-half u-color-muted"> / 5 </span
        ><span class="u-color-muted __reviews">{{ meta.externalTotal }} {{ 'REVIEWS.LABEL' | translate | lowercase }}</span></label
    >
</div>
