import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Move } from '@app/move/interfaces/move';
import { MobileModalComponent } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-mortgage-overview',
    templateUrl: 'mobile-mortgage-overview.modal.html',
    styleUrls: [],
})
export class MobileMortgageOverviewModal extends MoveData implements OnInit {
    @ViewChild(MobileModalComponent, { static: true }) modal: MobileModalComponent;

    public move: Move;

    constructor(protected moveSandbox: MoveSandbox, @Inject(MAT_DIALOG_DATA) public data: string) {
        super(moveSandbox, undefined);
    }

    public ngOnInit(): void {
        this.fetch(this.data);
        this.move$.pipe(takeUntil(this.destroy$)).subscribe((move) => (this.move = move));
    }
}
