import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';
import { LoadTriggerComponent } from './components/loading-trigger/load-trigger.component';
import { InViewTriggerDirective } from './directives/in-view-trigger.directive';

@NgModule({
    declarations: [LoadTriggerComponent, InViewTriggerDirective, LoadingContainerComponent, LoadingBarComponent],
    imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
    exports: [LoadTriggerComponent, InViewTriggerDirective, LoadingContainerComponent, LoadingBarComponent],
})
export class LoadingModule {}
