import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { smsContactLogTypes } from '@app/move/enums/contact-log-type.enum';
import { ContactLog } from '@app/move/interfaces/contact-log';

@Component({
    selector: 'app-contact-log-modal',
    templateUrl: './contact-log-modal.component.html',
    styleUrls: ['./contact-log-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContactLogModalComponent implements OnInit {
    @Input() public contactLog: ContactLog;

    public isSms: boolean;

    public ngOnInit(): void {
        this.isSms = smsContactLogTypes.includes(this.contactLog.value);
    }
}
