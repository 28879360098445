import { EanResult } from '@app/energy/interfaces/ean-result';
import { EanInfoTableActionsColumnComponent } from '@app/energy/modals/ean-info/ean-info-table-actions/ean-info-table-actions.column';
import { EanInfoTableResultColumnComponent } from '@app/energy/modals/ean-info/ean-info-table-result/ean-info-table-result.column';
import { ColType, TableDef, UiAlignment, UiVerticalAlignment } from '@smooved/ui';

export const eanInfoTableDef: TableDef<EanResult> = {
    columns: [
        {
            id: 'numberAndBox',
            header: 'EAN.TABLE.NUMBER_AND_BOX',
            type: ColType.Text,
            field: 'numberAndBox',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
        {
            id: 'result',
            template: EanInfoTableResultColumnComponent,
            header: 'EAN.TABLE.INFO',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
        {
            id: 'actions',
            alignment: UiAlignment.Right,
            template: EanInfoTableActionsColumnComponent,
            header: 'EAN.TABLE.ACTIONS',
            verticalAlignment: UiVerticalAlignment.Middle,
        },
    ],
};
