import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CopyToClipboardComponent } from '@app/form/components/copy-to-clipboard/copy-to-clipboard.component';
import { FeatureDetailReviewsService } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.service';
import { getThemeState, RealEstateGroupState } from '@app/real-estate-group/state/real-estate-group.reducer';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ExternalInfluencer, Language, RxjsComponent, socialsUri } from '@smooved/core';
import { ButtonAppearance, Colors, GrayScale, UiDirection, UriSchemaType } from '@smooved/ui';
import { take, takeUntil } from 'rxjs/operators';
import { croppedArcImageProperty, formFields, languageOptions } from './configure-signature.constants';

@Component({
    selector: 'app-configure-signature-modal',
    templateUrl: './configure-signature.modal.html',
    styleUrls: ['./configure-signature.modal.scss'],
})
export class ConfigureSignatureModule extends RxjsComponent implements OnInit {
    @ViewChild(CopyToClipboardComponent) public signature: CopyToClipboardComponent;

    public externalInfluencer: ExternalInfluencer;
    public form: UntypedFormGroup;
    public signatureSource: string;
    public externalInfluencerUrl: string;
    public profileImageUrl: string;
    public hasSidePanel = false;

    public readonly formFields = formFields;
    public readonly theme$ = this.store$.select(getThemeState).pipe(take(1));
    public readonly borderColor = GrayScale.Lightest;
    public readonly transparent = Colors.Transparent;
    public readonly uriSchemaType = UriSchemaType;
    public readonly socialsUri = socialsUri;
    public readonly languageOptions = languageOptions;
    public readonly direction = UiDirection;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        private readonly featureDetailReviewsService: FeatureDetailReviewsService,
        private readonly translateService: TranslateService,
        private fb: UntypedFormBuilder,
        private store$: Store<RealEstateGroupState>
    ) {
        super();
    }

    public ngOnInit(): void {
        this.externalInfluencer = this.featureDetailReviewsService.externalInfluencerSubject.value;
        this.form = this.fb.group({
            [formFields.profile]: true,
            [formFields.info]: true,
            [formFields.language]: this.translateService.currentLang || Language.NL,
        });
        this.signatureSource = this.getSignatureSource(this.translateService.currentLang);
        this.externalInfluencerUrl = `${environment.houseMatchCompanyPageUri}/${this.externalInfluencer.url}`;
        this.profileImageUrl = `${environment.appUri}/assets/images/sjef-profile.png`;

        this.form.controls[formFields.language].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((language) => {
            this.signatureSource = this.getSignatureSource(language);
        });
    }

    public onCopy(event: MouseEvent): void {
        this.signature.copyToClipboard(event, true);
    }

    private getSignatureSource(language): string {
        return this.externalInfluencer.siteMeta[croppedArcImageProperty][language];
    }

    public showHowto(): void {
        this.hasSidePanel = true;
    }
}
