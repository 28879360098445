import { EnergySuggestionsQuery } from '@app/energy/interfaces/energy-suggestions-query';
import { createAction, props } from '@ngrx/store';

export const getGroupedEnergySuggestions = createAction(
    '[Energy] Get grouped energy suggestions',
    props<{
        fixedTariff: string;
    }>()
);
export const getGroupedEnergySuggestionsByConsumptionProfile = createAction(
    '[Energy] Get grouped energy suggestions by consumption profile',
    props<{
        query: EnergySuggestionsQuery;
    }>()
);
export const getGroupedEnergySuggestionsSuccess = createAction(
    '[Energy] Get grouped energy suggestions success',
    props<{ groupedEnergySuggestions: any[] }>()
);
export const getGroupedEnergySuggestionsFailed = createAction('[Energy] Get grouped energy suggestions failed');
