import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ElectricityPricesDetails } from '@app/wizard/energy/interfaces/electricity-prices-details';

@Component({
    selector: 'app-electricity-details',
    templateUrl: 'electricity-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElectricityDetailsComponent {
    @Input() public details: ElectricityPricesDetails;
}
