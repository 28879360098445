<div class="u-flex u-flex-align-items-center u-margin-bottom u-color-muted">
    <app-icon icon="info" [size]="iconSize.Xm"></app-icon>
    <span class="u-margin-left">{{ 'REVIEWS.EXTERNAL_INFLUENCER.CONFIGURE.LOCATIONS_SIDE_NOTE' | translate }}</span>
</div>
<div
    *ngFor="let location of locations$ | async; last as isLast"
    class="
        __location
        u-flex u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-padding-y-axis-half u-bordered-bottom
    "
    [class.u-margin-bottom]="isLast"
>
    <div>{{ location.name }}</div>
    <div>
        <button
            app-button
            icon="edit"
            [context]="context.Primary"
            [appearance]="buttonAppearance.Icon"
            (click)="onEdit(location)"
            [title]="'EDIT' | translate"
        ></button>
        <button
            app-button
            icon="delete"
            [context]="context.Primary"
            [appearance]="buttonAppearance.Icon"
            [iconAppearance]="iconAppearance.Filled"
            (click)="onDelete(location)"
            [title]="'COMMON.DELETE' | translate"
        ></button>
    </div>
</div>
<div>
    <button app-button [appearance]="buttonAppearance.Stroked" [context]="context.Secondary" (click)="onAdd()">
        {{ 'ADD' | translate }}
    </button>
</div>
