import { AbstractControl } from '@angular/forms';

export class FormGroupCluster {
    controls: Map<string, AbstractControl> = new Map();

    constructor(cluster?: { [key: string]: AbstractControl }) {
        if (cluster) {
            Object.entries(cluster).forEach(([key, control]) => this.controls.set(key, control));
        }
    }

    public add(name: string, control: AbstractControl): void {
        this.controls.set(name, control);
    }

    public get valid(): boolean {
        return this.some((control) => control.invalid);
    }

    public get invalid(): boolean {
        return !this.valid;
    }

    public get enabled(): boolean {
        return this.some((control) => control.enabled);
    }

    public get disabled(): boolean {
        return !this.enabled;
    }

    public markAllAsTouched(): void {
        this.controls.forEach((control) => control.markAllAsTouched());
    }

    public markAllAsDirty(): void {
        this.do((control) => control.markAsDirty());
    }

    private do(fn: (control: AbstractControl) => void): void {
        this.controls.forEach(fn);
    }

    private some(fn: (control: AbstractControl) => void): boolean {
        return !Array.from(this.controls).some(([, control]) => fn(control));
    }
}
