import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { checkboxRequired } from '@app/form/validators/checkbox-required.validator';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { PaymentSandbox } from '@app/payment/sandboxes/payment.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { SepaModalComponent } from '@app/wizard/legal/modals/sepa-modal/sepa-modal.component';
import { ObjectUtils, RxjsComponent } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { BehaviorSubject, combineLatest, noop, Observable, zip } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import {
    differentPaymentMethodsOption,
    energyPaymentTypeOptions,
    generalPaymentTypeOptions,
    hopalaOptInPartnerOption,
    insurancesPaymentTypeOptions,
    IPaymentFields,
    paymentFields,
    sepaOption,
    telecomPaymentTypeOptions,
} from './payment-details.constants';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent extends RxjsComponent implements OnInit {
    @Input() public stepStart = 3;
    @Input() public stepEnd = 4;

    @Input() showSmoovedTermsAndConditions: boolean;
    @Input() showSuppliersTermsAndConditions = false;
    @Input() energyDomiciliationPaymentMandatory = false;

    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<string> = new EventEmitter<string>();

    public showHopalaOptInPartner$ = combineLatest([
        this.moveSandbox.insurancesOfferSelected$,
        this.authenticationSandbox.isRealEstateAgent$,
        this.moveSandbox.legalHopalaOptInPartnerState$,
    ]).pipe(
        map(
            ([insurancesOfferSelected, isRealEstateAgent, legalHopalaOptInPartner]) =>
                !!insurancesOfferSelected && !isRealEstateAgent && !legalHopalaOptInPartner
        )
    );

    public showHopalaOptInPartnerOnce$ = zip(
        this.moveSandbox.insurancesOfferSelected$,
        this.authenticationSandbox.isRealEstateAgent$,
        this.moveSandbox.legalHopalaOptInPartnerState$
    ).pipe(
        take(1),
        map(
            ([insurancesOfferSelected, isRealEstateAgent, legalHopalaOptInPartner]) =>
                !!insurancesOfferSelected && !isRealEstateAgent && !legalHopalaOptInPartner
        )
    );

    public readonly differentPaymentMethodsOption = differentPaymentMethodsOption;
    public readonly hopalaOptInPartnerOption = hopalaOptInPartnerOption;
    public readonly generalPaymentTypeOptions = generalPaymentTypeOptions;
    public readonly energyPaymentTypeOptions = energyPaymentTypeOptions;
    public readonly telecomPaymentTypeOptions = telecomPaymentTypeOptions;
    public readonly insurancesPaymentTypeOptions = insurancesPaymentTypeOptions;
    public readonly sepaOption = sepaOption;
    public readonly paymentFields = paymentFields;

    public form: UntypedFormGroup = this.formBuilder.group({
        [paymentFields.generalPaymentType]: null,
        [paymentFields.energyPaymentType]: null,
        [paymentFields.telecomPaymentType]: null,
        [paymentFields.insurancesPaymentType]: null,
        [paymentFields.accountNumber]: null,
        [paymentFields.differentPaymentMethods]: false,
        [paymentFields.suppliersTermsAndConditions]: false,
        [paymentFields.termsAndConditions]: false,
        [paymentFields.sepa]: false,
    });

    public showGeneralPaymentType$ = combineLatest(
        this.moveSandbox.payableShoppingCartWithoutInsurancesLength$,
        this.differentPaymentMethodsFormControl().valueChanges?.pipe(startWith(this.differentPaymentMethodsFormControl().value))
    ).pipe(
        map(
            ([payableShoppingCartWithoutInsurancesLength, differentPaymentMethods]) =>
                payableShoppingCartWithoutInsurancesLength > 1 && !differentPaymentMethods
        )
    );

    private showSepaSubject = new BehaviorSubject<boolean>(false);
    public showSepa$: Observable<boolean>;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly modalSandbox: ModalSandbox,
        public readonly moveSandbox: MoveSandbox,
        public readonly uiSandbox: AppUiSandbox
    ) {
        super();
        this.showSepa$ = combineLatest([
            this.moveSandbox.move$,
            this.authenticationSandbox.isRealEstateAgent$,
            this.showSepaSubject.asObservable(),
        ]).pipe(
            map(([move, isRealEstateAgent, showSepa]) => !!move.realEstateAgent && !isRealEstateAgent && showSepa),
            tap(this.setSepaValidators)
        );
    }

    public ngOnInit(): void {
        this.showHopalaOptInPartnerOnce$.pipe(filter((x) => !!x)).subscribe((_) => {
            this.form.addControl(paymentFields.hopalaOptInPartner, this.formBuilder.control(false, checkboxRequired));
        });

        this.energyPaymentTypeOptions.find((opt) => opt.value === PaymentType.Transfer).disabled = this.energyDomiciliationPaymentMandatory;
        if (this.energyDomiciliationPaymentMandatory) {
            this.energyPaymentTypeFormControl().setValue(PaymentType.Domiciliation);
        }

        if (this.showSmoovedTermsAndConditions) {
            this.smoovedTermsAndConditionsFormControl().setValidators([checkboxRequired]);
        } else {
            this.smoovedTermsAndConditionsFormControl().clearValidators();
        }

        if (this.showSuppliersTermsAndConditions) {
            this.form.get(paymentFields.suppliersTermsAndConditions).setValidators([checkboxRequired]);
        }

        combineLatest([
            zip(
                this.showGeneralPaymentType$,
                this.moveSandbox.payableShoppingCartWithoutInsurancesLength$,
                this.moveSandbox.payableShoppingCartHasEnergy$,
                this.moveSandbox.payableShoppingCartHasTelecom$,
                this.moveSandbox.payableShoppingCartHasInsurances$
            ).pipe(take(1)),
            this.differentPaymentMethodsFormControl().valueChanges,
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ([
                    [showGeneralPaymentType, payableShoppingCartWithoutInsurancesLength, hasEnergy, hasTelecom, hasInsurances],
                    differentPaymentMethods,
                ]) => {
                    if (showGeneralPaymentType) {
                        if (differentPaymentMethods) {
                            this.generalPaymentTypeFormControl().clearValidators();
                            this.generalPaymentTypeFormControl().patchValue(null);
                            if (hasEnergy) {
                                this.energyPaymentTypeFormControl().setValidators([Validators.required]);
                            }
                            if (hasTelecom) {
                                this.telecomPaymentTypeFormControl().setValidators([Validators.required]);
                            }
                        } else {
                            this.generalPaymentTypeFormControl().setValidators([Validators.required]);

                            this.energyPaymentTypeFormControl().clearValidators();
                            this.telecomPaymentTypeFormControl().clearValidators();
                            this.energyPaymentTypeFormControl().patchValue(null);
                            this.telecomPaymentTypeFormControl().patchValue(null);
                        }
                    } else {
                        this.generalPaymentTypeFormControl().clearValidators();
                        if (hasEnergy) {
                            this.energyPaymentTypeFormControl().setValidators([Validators.required]);
                        }
                        if (hasTelecom) {
                            this.telecomPaymentTypeFormControl().setValidators([Validators.required]);
                        }
                    }

                    if (hasInsurances) {
                        this.insurancesPaymentTypeFormControl().setValidators([Validators.required]);
                    } else {
                        this.insurancesPaymentTypeFormControl().patchValue(null);
                        this.insurancesPaymentTypeFormControl().clearValidators();
                    }

                    this.generalPaymentTypeFormControl().updateValueAndValidity();
                    this.energyPaymentTypeFormControl().updateValueAndValidity();
                    this.telecomPaymentTypeFormControl().updateValueAndValidity();
                    this.insurancesPaymentTypeFormControl().updateValueAndValidity();
                }
            );

        this.form.setValidators(this.accountNumberValidator());

        zip(
            this.moveSandbox.moveOnce$,
            this.showGeneralPaymentType$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartLength$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartHasEnergy$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartHasTelecom$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartHasInsurances$.pipe(take(1))
        )
            .pipe(filter((x) => !!x))
            .subscribe(([move, showGeneralPaymentType, shoppingCartLength, hasEnergy, hasTelecom, hasInsurances]) => {
                this.differentPaymentMethodsFormControl().patchValue(!!move.differentPaymentType);

                if (showGeneralPaymentType) {
                    if (move.differentPaymentType) {
                        this.energyPaymentTypeFormControl().patchValue(move.energyPaymentType);
                        this.telecomPaymentTypeFormControl().patchValue(move.telecomPaymentType);
                    } else {
                        if (hasEnergy) this.generalPaymentTypeFormControl().patchValue(move?.energyPaymentType);
                        if (hasTelecom) this.generalPaymentTypeFormControl().patchValue(move?.telecomPaymentType);
                    }
                } else {
                    this.energyPaymentTypeFormControl().patchValue(move.energyPaymentType);
                    this.telecomPaymentTypeFormControl().patchValue(move.telecomPaymentType);
                }

                if (hasInsurances) this.insurancesPaymentTypeFormControl().patchValue(move.insurancesPaymentType);

                this.accountNumberFormControl().patchValue(move.user?.accountNumber);

                if (this.energyDomiciliationPaymentMandatory) {
                    this.energyPaymentTypeFormControl().setValue(PaymentType.Domiciliation);
                }

                this.hasDomiciliation([
                    move?.energyPaymentType || move?.telecomPaymentType,
                    move?.energyPaymentType,
                    move?.telecomPaymentType,
                ]);
            });

        combineLatest([
            this.form.get(paymentFields.generalPaymentType).valueChanges.pipe(startWith(null)),
            this.form.get(paymentFields.energyPaymentType).valueChanges.pipe(startWith(null)),
            this.form.get(paymentFields.telecomPaymentType).valueChanges.pipe(startWith(null)),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(this.hasDomiciliation);
    }

    public showAccountNumberFormControl(): boolean {
        return (
            this.energyPaymentTypeFormControl()?.value === PaymentType.Domiciliation ||
            this.telecomPaymentTypeFormControl()?.value === PaymentType.Domiciliation ||
            this.generalPaymentTypeFormControl()?.value === PaymentType.Domiciliation
        );
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.patchState(() => {
                this.next.emit();
            });
        }
    }

    public goToPrevious(): void {
        this.previous.emit();
    }

    public showSepaInfo(): void {
        this.modalSandbox.openModal(SepaModalComponent, {}, noop, SepaModalComponent, {}, noop);
    }

    private patchState(callback: () => void): void {
        let patch: Move = {};

        zip(
            this.moveSandbox.payableShoppingCartWithoutInsurancesLength$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartHasEnergy$.pipe(take(1)),
            this.moveSandbox.payableShoppingCartHasTelecom$.pipe(take(1))
        )
            .pipe(take(1))
            .subscribe(([shoppingCartWithoutInsurancesLength, hasEnergy, hasTelecom]) => {
                const {
                    differentPaymentMethods,
                    generalPaymentType,
                    energyPaymentType,
                    telecomPaymentType,
                    insurancesPaymentType,
                    suppliersTermsAndConditions,
                    termsAndConditions: smoovedTermsAndConditions,
                    accountNumber,
                    hopalaOptInPartner,
                    sepa,
                } = this.form.value as IPaymentFields;

                if (shoppingCartWithoutInsurancesLength > 1) {
                    patch.differentPaymentType = differentPaymentMethods;

                    if (patch.differentPaymentType) {
                        if (hasEnergy) patch.energyPaymentType = energyPaymentType;
                        if (hasTelecom) patch.telecomPaymentType = telecomPaymentType;
                    } else {
                        if (hasEnergy) patch.energyPaymentType = generalPaymentType;
                        if (hasTelecom) patch.telecomPaymentType = generalPaymentType;
                    }
                } else {
                    if (hasEnergy) patch.energyPaymentType = energyPaymentType;
                    if (hasTelecom) patch.telecomPaymentType = telecomPaymentType;
                }

                patch = {
                    ...patch,
                    insurancesPaymentType,
                    user: {
                        accountNumber,
                    },
                    legal: {
                        smoovedTermsAndConditions,
                        suppliersTermsAndConditions,
                        hopalaOptInPartner,
                        sepa,
                    },
                };

                this.moveSandbox.patchProperty('', ObjectUtils.buildPayload(patch), true, callback, null, null, true, true);
            });
    }

    private generalPaymentTypeFormControl(): AbstractControl {
        return this.form?.get('generalPaymentType');
    }

    private energyPaymentTypeFormControl(): AbstractControl {
        return this.form?.get('energyPaymentType');
    }

    private telecomPaymentTypeFormControl(): AbstractControl {
        return this.form?.get('telecomPaymentType');
    }

    private insurancesPaymentTypeFormControl(): AbstractControl {
        return this.form?.get('insurancesPaymentType');
    }

    private accountNumberFormControl(): AbstractControl {
        return this.form?.get('accountNumber');
    }

    public differentPaymentMethodsFormControl(): AbstractControl {
        return this.form?.get('differentPaymentMethods');
    }

    private smoovedTermsAndConditionsFormControl(): AbstractControl {
        return this.form?.get('termsAndConditions');
    }

    private accountNumberValidator(): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors => {
            const energyPaymentTypeFormControl = group.controls['energyPaymentType'];
            const telecomPaymentTypeFormControl = group.controls['telecomPaymentType'];
            const generalPaymentTypeFormControl = group.controls['generalPaymentType'];
            const accountNumberFormControl = group.controls['accountNumber'];

            if (
                (!energyPaymentTypeFormControl && !telecomPaymentTypeFormControl && !generalPaymentTypeFormControl) ||
                !accountNumberFormControl
            ) {
                accountNumberFormControl.setErrors(null);
            } else if (this.showAccountNumberFormControl()) {
                if (!accountNumberFormControl.value) {
                    accountNumberFormControl.setErrors({ required: true });
                } else if (!PaymentSandbox.isValidAccountNumber(accountNumberFormControl.value)) {
                    accountNumberFormControl.setErrors({ accountNumberNotValid: true });
                } else {
                    accountNumberFormControl.setErrors(null);
                }
            } else {
                accountNumberFormControl.setErrors(null);
            }
            return;
        };
    }

    private setSepaValidators = (showSepa: boolean): void => {
        this.form.get(paymentFields.sepa).setValidators(showSepa ? checkboxRequired : null);
        this.form.get(paymentFields.sepa).updateValueAndValidity();
    };
    private hasDomiciliation = (paymentTypes: PaymentType[]): void => {
        this.showSepaSubject.next(paymentTypes.some((type) => type === PaymentType.Domiciliation));
    };
}
