import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-todo-energy-ordered-by-admin',
    templateUrl: './todo-energy-ordered-by-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEnergyOrderedByAdminComponent {
    @Input() public move: Move;
}
