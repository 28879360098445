import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { NpsReview, PaginationRequest, skipWhileNull } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReviewsFilter } from '../interfaces/reviews-filter';

@Injectable()
export class ReviewsDataSource implements DataSource<NpsReview> {
    protected suggestionsSubject: BehaviorSubject<NpsReview[]> = new BehaviorSubject<NpsReview[]>([]);
    protected suggestionsCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    public reviewsCount$: Observable<number> = this.suggestionsCountSubject.pipe(skipWhileNull());

    public load(filter: ReviewsFilter, paginationRequest?: PaginationRequest, extend?: boolean): Observable<void> {
        return;
    }

    public connect(): Observable<NpsReview[] | ReadonlyArray<NpsReview>> {
        return this.suggestionsSubject.asObservable();
    }

    public preset(reviews: NpsReview[], count?: number): void {
        this.suggestionsSubject.next(reviews);
        this.suggestionsCountSubject.next(count ?? reviews.length);
    }

    public disconnect(): void {
        this.suggestionsSubject.complete();
        this.suggestionsCountSubject.complete();
    }
}
