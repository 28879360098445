<app-menu direction="horizontal">
    <app-menu-item
        class="u-margin-right-half"
        [active]="active === menuItemAll.goTo"
        (click)="setActive(menuItemAll.goTo)"
        [appearance]="menuItemAppearance.Filter"
        [size]="menuItemSizes.Default"
        >{{ menuItemAll.labelResource | translate }} ({{ topicAnalysis.sentimentAll }})
    </app-menu-item>
    <app-menu-item
        class="u-margin-right-half"
        [active]="active === menuItemPositive.goTo"
        (click)="setActive(menuItemPositive.goTo)"
        [appearance]="menuItemAppearance.Filter"
        [size]="menuItemSizes.Default"
        [svg]="menuItemPositive.svg"
        >{{ menuItemPositive.labelResource | translate }} ({{ topicAnalysis.sentimentPositive }})
    </app-menu-item>
    <app-menu-item
        [active]="active === menuItemNegative.goTo"
        (click)="setActive(menuItemNegative.goTo)"
        [appearance]="menuItemAppearance.Filter"
        [size]="menuItemSizes.Default"
        [svg]="menuItemNegative.svg"
        >{{ menuItemNegative.labelResource | translate }} ({{ topicAnalysis.sentimentNegative }})
    </app-menu-item>
</app-menu>
