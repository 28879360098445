import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { validateZipCode } from '@app/form/validators/zip-code.validator';
import { professionalOptions, vacancyOption } from '@app/move/constants/move.constants';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ObjectUtils } from '@smooved/core';
import { zip } from 'rxjs';

@Component({
    selector: 'app-zip-code',
    template: `
        <p class="u-color-muted u-margin-bottom-triple">
            {{
                'STEP_INDICATION'
                    | translate
                        : {
                              start: stepStart,
                              end: stepEnd
                          }
            }}
        </p>

        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-text-input
                [formControlName]="'zipCode'"
                [autoFocus]="true"
                [label]="'MOVE.USER.ADDRESS.ZIP_CODE' | translate"
                [placeholder]="'MOVE.USER.ADDRESS.ZIP_CODE.PLACEHOLDER' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
            ></app-text-input>
            <ui-dropdown-input
                [options]="professionalOptions"
                [label]="'MOVE.CONTRACT_TYPE.LABEL' | translate"
                class="u-display-block u-w100p-up-to-and-including-phone-landscape"
                formControlName="professional"
                [hasMarginDouble]="true"
                [hasMargin]="false"
            ></ui-dropdown-input>

            <app-check-input
                width="auto"
                *ngIf="authenticationSandbox.isAdmin$ | async"
                [custom]="false"
                class="u-display-block"
                [option]="vacancyOption"
                formControlName="vacancy"
            ></app-check-input>

            <app-previous-submit [showPrevious]="showPrevious" (previous)="previous.emit()"></app-previous-submit>
        </form>
    `,
    styles: [
        `
                    :host {
                        display: block;
                        width: 100%;
                    }
                `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZipCodeComponent implements OnInit, OnDestroy {
    @Input() public stepStart: number;
    @Input() public stepEnd: number;
    @Input() public showPrevious: boolean;

    @Output() public next: EventEmitter<{ zipCode: string; professional?: boolean }> = new EventEmitter<{
        zipCode: string;
        professional?: boolean;
    }>();
    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();

    public readonly professionalOptions = professionalOptions;
    public readonly vacancyOption = vacancyOption;

    public form: UntypedFormGroup = this.formBuilder.group({
        zipCode: [null, [validateZipCode]],
        professional: [null, [Validators.required]],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        public readonly authenticationSandbox: AuthenticationSandbox,
        private readonly translateService: TranslateService,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        zip(this.authenticationSandbox.isAdminOnce$, this.moveSandbox.moveOnce$).subscribe(([isAdmin, move]) => {
            if (move?.user?.movingAddress?.zipCode) {
                this.zipCodeFormControl().patchValue(move?.user?.movingAddress?.zipCode);
            }
            if (ObjectUtils.isBoolean(move.professional)) {
                this.professionalFormControl().patchValue(move.professional);
            }
            if (isAdmin) {
                this.form.addControl('vacancy', this.formBuilder.control(!!move?.energyOffer?.vacancy));
            }
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.next.emit(this.form.value);
        }
    }

    public ngOnDestroy(): void {
        const payload: any = {
            professional: this.professionalFormControl().value,
            user: {
                movingAddress: {
                    zipCode: this.zipCodeFormControl().value,
                },
            },
        };
        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            if (isAdmin) {
                payload.energyOffer = {
                    vacancy: this.vacancyFormControl().value,
                };
            }
        });
        this.moveSandbox.patchProperty('', payload);
    }

    private zipCodeFormControl(): AbstractControl {
        return this.form.get('zipCode');
    }

    private professionalFormControl(): AbstractControl {
        return this.form.get('professional');
    }

    private vacancyFormControl(): AbstractControl {
        return this.form.get('vacancy');
    }
}
