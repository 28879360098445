import { Injectable } from '@angular/core';
import { InvoicingService } from '@app/invoicing/services/invoicing.service';
import {
    getBillableInvoices,
    getBillableInvoicesFailed,
    getBillableInvoicesSuccess,
    getInvoice,
    getInvoiceFailed,
    getInvoices,
    getInvoicesFailed,
    getInvoicesSent,
    getInvoicesSentFailed,
    getInvoicesSentSuccess,
    getInvoicesSuccess,
    getInvoiceSuccess,
    getLastBillableInvoice,
    getLastBillableInvoiceFailed,
    getLastBillableInvoiceSuccess,
    getQuarterFailed,
    getQuarterlyInvoicesTotals,
    getQuarterlyInvoicesTotalsFailed,
    getQuarterlyInvoicesTotalsSuccess,
    getQuarterlyInvoicing,
    getQuarterlyInvoicingFailed,
    getQuarterlyInvoicingSuccess,
    getQuarterSuccess,
    setQuarter,
} from '@app/invoicing/state/invoicing.actions';
import { getQuarterMomentState } from '@app/invoicing/state/invoicing.reducer';
import { State } from '@app/store/state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DateUtils } from '@smooved/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class InvoicingEffects {
    getQuarter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setQuarter),
            mergeMap((action) =>
                this.invoicingService
                    .getQuarter({
                        year: DateUtils.tz(action.quarter)?.year(),
                        quarter: DateUtils.tz(action.quarter)?.quarter(),
                    })
                    .pipe(
                        map((quarter) => getQuarterSuccess({ quarter })),
                        catchError((errorResponse) => of(getQuarterFailed()))
                    )
            )
        )
    );

    getQuarterlyTotals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getQuarterlyInvoicesTotals),
            withLatestFrom(this.store$.select(getQuarterMomentState)),
            mergeMap(([action, quarter]) =>
                this.invoicingService
                    .getQuarterlyTotals({
                        year: quarter?.year(),
                        quarter: quarter?.quarter(),
                    })
                    .pipe(
                        map((quarterlyInvoicesTotals) => getQuarterlyInvoicesTotalsSuccess({ quarterlyInvoicesTotals })),
                        catchError((errorResponse) => of(getQuarterlyInvoicesTotalsFailed()))
                    )
            )
        )
    );

    getInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getInvoices),
            withLatestFrom(this.store$.select(getQuarterMomentState)),
            mergeMap(([action, quarter]) =>
                this.invoicingService
                    .getInvoices(
                        {
                            year: quarter?.year(),
                            quarter: quarter?.quarter(),
                        },
                        {
                            pageSize: action?.invoicesRequest?.pageSize,
                            pageIndex: action?.invoicesRequest?.pageIndex,
                            sortField: action?.invoicesRequest?.sortField,
                            sortDirection: action?.invoicesRequest?.sortDirection,
                            realEstateGroup: action?.invoicesRequest?.realEstateGroup,
                            status: action?.invoicesRequest?.status,
                        }
                    )
                    .pipe(
                        map((invoicesResponse) =>
                            getInvoicesSuccess({
                                invoices: invoicesResponse?.data,
                                count: invoicesResponse?.count,
                            })
                        ),
                        catchError((errorResponse) => of(getInvoicesFailed()))
                    )
            )
        )
    );

    getQuarterlyInvoicing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getQuarterlyInvoicing),
            withLatestFrom(this.store$.select(getQuarterMomentState)),
            mergeMap(([action, quarter]) =>
                this.invoicingService
                    .getQuarterly({
                        realEstateGroup: action.realEstateGroupId,
                        year: quarter?.year(),
                        quarter: quarter?.quarter(),
                    })
                    .pipe(
                        map((quarterlyInvoicing) =>
                            getQuarterlyInvoicingSuccess({
                                quarterlyInvoicing,
                            })
                        ),
                        catchError((errorResponse) => of(getQuarterlyInvoicingFailed()))
                    )
            )
        )
    );

    getInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getInvoice),
            withLatestFrom(this.store$.select(getQuarterMomentState)),
            mergeMap(([action, quarter]) =>
                this.invoicingService
                    .getInvoice({
                        realEstateGroup: action.realEstateGroupId,
                        year: quarter?.year(),
                        quarter: quarter?.quarter(),
                    })
                    .pipe(
                        map((invoice) =>
                            getInvoiceSuccess({
                                invoice,
                            })
                        ),
                        catchError((errorResponse) => of(getInvoiceFailed()))
                    )
            )
        )
    );

    getLastBillableInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getLastBillableInvoice),
            mergeMap((action) =>
                this.invoicingService.getLastBillableInvoice(action.realEstateGroupId).pipe(
                    map((invoice) =>
                        getLastBillableInvoiceSuccess({
                            invoice,
                        })
                    ),
                    catchError((errorResponse) => of(getLastBillableInvoiceFailed()))
                )
            )
        )
    );

    getBillableInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillableInvoices),
            mergeMap((action) =>
                this.invoicingService.getBillableInvoices(action.realEstateGroupId).pipe(
                    map((invoices) =>
                        getBillableInvoicesSuccess({
                            invoices,
                        })
                    ),
                    catchError((errorResponse) => of(getBillableInvoicesFailed()))
                )
            )
        )
    );

    getInvoicesSent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getInvoicesSent),
            mergeMap((action) =>
                this.invoicingService
                    .searchInvoices({
                        realEstateGroupId: action.realEstateGroupId,
                        status: action.status,
                        startDate: action.startDate,
                    })
                    .pipe(
                        map((invoices) => getInvoicesSentSuccess({ invoices })),
                        catchError((_) => of(getInvoicesSentFailed()))
                    )
            )
        )
    );

    constructor(private actions$: Actions, private store$: Store<State>, private invoicingService: InvoicingService) {}
}
