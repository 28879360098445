<h5 class="u-margin-bottom-double">{{ 'TELECOM.SUGGESTIONS.ASIDE.TITLE' | translate }}</h5>
<form [formGroup]="form">
    <div *ngIf="moveSandbox.hasInternet$ | async">
        <h6 class="u-margin-bottom">{{ 'TELECOM.SUGGESTIONS.ASIDE.INTERNET' | translate }}</h6>
        <app-check-input [custom]="false" [option]="unlimitedInternetOption" formControlName="unlimitedInternet"></app-check-input>
        <ui-dropdown-input
            *ngIf="wifiSpeedOptions$ | async as wifiSpeedOptions"
            [options]="wifiSpeedOptions | translateProp"
            [id]="'wifi-speed-options'"
            [label]="'TELECOM.SUGGESTIONS.ASIDE.INTERNET.MINIMUM' | translate"
            [hasMargin]="true"
            [labelMuted]="true"
            [hasMarginDouble]="false"
            class="__filter-internet"
            formControlName="wifiSpeed"
        ></ui-dropdown-input>
        <p class="u-color-muted u-font-size-small u-margin-bottom-double">
            <ng-container *ngIf="wifiSpeedFormControl()?.value < 100">
                {{ 'TELECOM.SUGGESTIONS.ASIDE.INTERNET.BASIC.DESCRIPTION' | translate }}
            </ng-container>
            <ng-container *ngIf="wifiSpeedFormControl()?.value >= 100 && wifiSpeedFormControl()?.value < 300">
                {{ 'TELECOM.SUGGESTIONS.ASIDE.INTERNET.NORMAL.DESCRIPTION' | translate }}
            </ng-container>
            <ng-container *ngIf="wifiSpeedFormControl()?.value >= 300">
                {{ 'TELECOM.SUGGESTIONS.ASIDE.INTERNET.FAST.DESCRIPTION' | translate }}
            </ng-container>
        </p>
    </div>

    <ng-container *ngIf="moveSandbox.hasMobilePhone$ | async">
        <h6 class="u-margin-bottom">{{ 'TELECOM.SUGGESTIONS.ASIDE.SIM_CARDS.LABEL' | translate }}</h6>
        <ui-dropdown-input
            *ngIf="mobileNumberSimsOptions$ | async as mobileNumberSimsOptions"
            [id]="'sim-cards'"
            [hasMargin]="false"
            [label]="'TELECOM.SUGGESTIONS.ASIDE.SIM_CARDS.MINIMUM' | translate"
            [labelMuted]="true"
            formControlName="mobileNumberSims"
            [hasMarginDouble]="true"
            [options]="mobileNumberSimsOptions"
        ></ui-dropdown-input>

        <h6 class="u-margin-bottom">{{ 'TELECOM.SUGGESTIONS.ASIDE.MOBILE_DATA.LABEL' | translate }}</h6>
        <ui-dropdown-input
            *ngIf="mobileDataOptions$ | async as mobileDataOptions"
            [id]="'mobile-data'"
            formControlName="mobileData"
            [label]="'TELECOM.SUGGESTIONS.ASIDE.MOBILE_DATA.MINIMUM' | translate"
            [labelMuted]="true"
            [hasMargin]="false"
            [hasMarginDouble]="true"
            [options]="mobileDataOptions | translateProp"
        ></ui-dropdown-input>
        <div class="u-margin-bottom">
            <app-check-input
                [custom]="false"
                [option]="unlimitedMobileMinutesOption"
                formControlName="unlimitedMobileMinutes"
            ></app-check-input>
        </div>
    </ng-container>

    <div class="u-margin-bottom-double">
        <h6 class="u-margin-bottom">{{ 'TELECOM.SUGGESTIONS.ASIDE.OTHER_PROVIDERS.LABEL' | translate }}</h6>
        <ui-dropdown-input
            *ngIf="otherProvidersOptions$ | async as otherProvidersOptions"
            [id]="'other-provider'"
            formControlName="otherProvider"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [options]="otherProvidersOptions"
        ></ui-dropdown-input>
    </div>

    <div>
        <app-check-input [custom]="false" [option]="ignorePromotionsOption" formControlName="ignorePromotions"></app-check-input>
    </div>
</form>
