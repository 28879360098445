import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { dateTimeFormatDefault, FileUtils, ObjectUtils } from '@smooved/core';
import { FileItem } from 'ng2-file-upload';
import { take } from 'rxjs';

@Component({
    selector: 'smvd-app-file-preview',
    templateUrl: 'file-preview.modal.html',
    styleUrls: ['file-preview.modal.scss'],
})
export class FilePreviewModal implements OnInit {
    public readonly dateTimeFormat = dateTimeFormatDefault;
    public file: FileInput;
    public isPreview = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FileItem,
        private readonly http: HttpClient
    ) {}

    public ngOnInit(): void {
        if (!this.data) return;

        this.file = ObjectUtils.cloneDeep(this.data.file.rawFile as unknown as FileInput);

        if (ObjectUtils.isEmpty(this.file)) {
            this.isPreview = true;
            const reader = new FileReader();
            reader.onload = (_) => (this.file = { name: this.data.file.name, value: reader.result });
            reader.readAsDataURL(this.data.file.rawFile as File);
        }
    }

    public download(): void {
        this.http
            .get(this.file.location, { responseType: 'blob' })
            .pipe(take(1))
            .subscribe((blob) => FileUtils.downloadAsset(blob, this.file.name));
    }
}
