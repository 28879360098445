import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { Language, LegalForm } from '@smooved/core';

export const languageOptions: DropdownInput<Language>[] = Object.keys(Language).map((key) => {
    return {
        id: Language[key],
        labelResource: `LANGUAGE.${key}.LABEL`,
        name: 'Language',
        value: Language[key],
    };
});

export const legalFormOptions: DropdownInput<LegalForm>[] = [
    ...Object.entries(LegalForm).map(([key, value]: [string, LegalForm]) => {
        return {
            id: `legal-form-${key}`,
            labelResource: `COMPANY_DETAILS.LEGAL_FORM.${key.toUpperCase()}`,
            value,
            name: 'legalForm',
        };
    }),
];

export enum FormControlName {
    MovingAddress = 'movingAddress',
    MovingDate = 'movingDate',
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Language = 'language',
    DateOfBirth = 'dateOfBirth',
    Gender = 'gender',
    BtwNumber = 'btwNumber',
    AccountNumber = 'accountNumber',
    NationalNumber = 'nationalNumber',
    PassportNumber = 'passportNumber',
    FirstNameLeaver = 'firstNameLeaver',
    LastNameLeaver = 'lastNameLeaver',
    PhoneNumberLeaver = 'phoneNumberLeaver',
    EmailLeaver = 'emailLeaver',
    LanguageLeaver = 'languageLeaver',
    CompanyName = 'companyName',
    VatNumber = 'vatNumber',
    LegalForm = 'legalForm',
}

export interface MoveEditFormData {}
