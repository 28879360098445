import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MailLeaverToSupplierModal } from '@app/admin/modals/mail-leaver-to-supplier/mail-leaver-to-supplier.modal';
import { AdminContactLogService } from '@app/admin/services/admin-contact-log.service';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { leaverJourneyEmailContactLogTypes } from '@app/move/constants/contact-log.constants';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { ArrayUtils, DbUtils, I18nKeyType, ObjectUtils, Role, SortDirection, SortUtils } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, NotificationSandbox, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-contact-logs',
    template: `
        <app-check-item-contact-log
            *ngFor="let contactLog of getContactLogs(); index as index; trackBy: trackContactLog"
            [remarkable]="remarkable(contactLog)"
            [removable]="removable(contactLog)"
            [contactLog]="contactLog"
            [move]="move"
            (remark)="onRemark($event, contactLog)"
            (remove)="onRemove(contactLog)"
            [checked]="isChecked(contactLog)"
            class="u-display-block u-margin-bottom-half"
        >
            <div ngProjectAs="checked">
                <span>{{
                    moveSandbox.getContactLogWithCreatedByCheckedTranslationLabel(contactLog)
                        | translate
                            : {
                                  firstName: contactLog.createdBy?.firstName,
                                  createdOn: contactLog.formattedCreatedOn,
                                  linkedMove: move.linkedMove?.user?.role | i18nKey : i18nKeyTypes.MoverRole | translate | lowercase
                              }
                }}</span>
            </div>

            <div *ngIf="showUnchecked(contactLog)" ngProjectAs="unchecked">
                <span>{{ getTranslationLabelUnchecked(contactLog) | translate }}</span>
            </div>

            <div ngProjectAs="cta">
                <ng-container
                    *ngIf="
                        contactLog.value === contactLogType.EmailEnergyStopLeaver &&
                        isLastOccurence(index, contactLogType.EmailEnergyStopLeaver)
                    "
                >
                    <button app-button [appearance]="appearance.Link" class="u-padding-none u-display-inline" (click)="downloadEDS()">
                        {{ 'DOWNLOAD' | translate }}
                    </button>

                    <button app-button [appearance]="appearance.Link" class="u-padding-none u-display-inline" (click)="resendSupplier()">
                        {{ 'RESEND' | translate }}
                    </button>
                </ng-container>

                <button
                    app-button
                    [appearance]="appearance.Stroked"
                    [context]="context.Secondary"
                    class="u-margin-top-half"
                    *ngIf="contactLog.value === contactLogType.EmailPitch && canSendEmailPitch()"
                    (click)="onEmailPitchSubmit()"
                >
                    {{ 'SEND' | translate }}
                </button>

                <button
                    app-button
                    [appearance]="appearance.Stroked"
                    [context]="context.Secondary"
                    class="u-margin-top-half"
                    *ngIf="contactLog.value === contactLogType.EmailEnergyUrgency && canSendEmailEnergyUrgency()"
                    (click)="onEmailEnergyUrgencySubmit()"
                >
                    {{ 'SEND' | translate }}
                </button>

                <button
                    app-button
                    [appearance]="appearance.Stroked"
                    [context]="context.Secondary"
                    class="u-margin-top-half"
                    *ngIf="contactLog.value === contactLogType.EmailMover && canSendEmailMover()"
                    (click)="onEmailMoverSubmit()"
                >
                    {{ 'SEND' | translate }}
                </button>

                <button
                    app-button
                    [appearance]="appearance.Stroked"
                    [context]="context.Secondary"
                    class="u-margin-top-half"
                    *ngIf="contactLog.value === contactLogType.EmailRealEstateAgent && canSendEmailRealEstateAgent()"
                    (click)="onEmailRealEstateAgentSubmit()"
                >
                    {{ 'SEND' | translate }}
                </button>

                <button
                    app-button
                    [appearance]="appearance.Link"
                    *ngIf="
                        leaverJourneyEmailContactLogTypes.includes(contactLog.value) &&
                        isLastOccurence(index, leaverJourneyEmailContactLogTypes)
                    "
                    (click)="resendLeaverCollection()"
                >
                    {{ 'RESEND' | translate }}
                </button>
            </div>
        </app-check-item-contact-log>

        <app-contact-logs-multi-channel
            class="u-display-block u-margin-top-double u-margin-bottom-half"
            [move]="move"
        ></app-contact-logs-multi-channel>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactLogsComponent {
    @Input() public move: Move;

    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    public readonly contactLogType = ContactLogType;
    public readonly i18nKeyTypes = I18nKeyType;
    public readonly context = UiContext;
    public readonly appearance = ButtonAppearance;
    public readonly leaverJourneyEmailContactLogTypes = leaverJourneyEmailContactLogTypes;

    constructor(
        private readonly notificationSandbox: NotificationSandbox,
        public readonly moveSandbox: MoveSandbox,
        private readonly documentCenter: DocumentCenterSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly adminContactLogService: AdminContactLogService
    ) {}

    public getContactLogs(): ContactLog[] {
        return this.adminContactLogService.getAllContactLogs(this.move).filter((contactLog) => {
            switch (contactLog.value) {
                case ContactLogType.Call:
                case ContactLogType.Voicemail:
                case ContactLogType.EmailCustomMover:
                case ContactLogType.EmailCustomRealEstateAgent:
                case ContactLogType.EmailCustomLinkedMove:
                case ContactLogType.EmailCustomSupplier:
                case ContactLogType.EmailCustomOther:
                case ContactLogType.EmailConfirm:
                case ContactLogType.EmailMeterTransferForLeaver:
                case ContactLogType.EmailMeterCollection:
                case ContactLogType.EmailMeterCollectionReminder:
                case ContactLogType.EmailMeterCollectionFinalReminder:
                case ContactLogType.SmsCustomMover:
                case ContactLogType.EmailLeaverInvite:
                case ContactLogType.SmsLeaver:
                case ContactLogType.SmsEotsRequestWithoutMover:
                case ContactLogType.SmsEotsRequestWithMover:
                case ContactLogType.SmsMeterCollection:
                case ContactLogType.EmailEnergyStopLeaver:
                case ContactLogType.SmsEnergyStopLeaverExpectations:
                case ContactLogType.EmailEnergyStopLeaverExpectations:
                case ContactLogType.EmailSettingTheScene:
                case ContactLogType.EmailProvisionalEnergyProposal:
                case ContactLogType.EmailEnergyProposalReminder:
                case ContactLogType.EmailEnergyProposal:
                case ContactLogType.EmailEnergyProposalWithContext:
                case ContactLogType.EmailEnergyProposalLandAgent:
                case ContactLogType.EmailEnergyProposalVacancy:
                case ContactLogType.SmsEnergyProposal:
                case ContactLogType.EmailEnergyOrdered:
                case ContactLogType.EmailEnergyNotInterested:
                case ContactLogType.EmailMeterReadingsEnergy:
                case ContactLogType.EmailMeterReadingsWater:
                case ContactLogType.EmailEnergyInstalled:
                case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter15DaysLate:
                case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter40DaysLate:
                case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter15DaysLate:
                case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter40DaysLate:
                case ContactLogType.EmailEnergyStopNotInterested:
                case ContactLogType.EmailEnergyStopSupplierComplete:
                case ContactLogType.EmailLeaverMeterReadingsEnergy:
                case ContactLogType.EmailLeaverMeterReadingsWater:
                case ContactLogType.EmailTransfereeNewWaterContract:
                case ContactLogType.EmailLeaverClosingWaterContract:
                case ContactLogType.EmailLeaverFollowUpClosingWaterContract:
                case ContactLogType.EmailTransfereeWaterTransferDocumentNotUploadedReminder:
                case ContactLogType.EmailLeaverWaterTransferDocumentNotUploadedReminder:
                    return true;
                case ContactLogType.EmailPitch:
                    return this.showEmailPitch();
                case ContactLogType.EmailEnergyUrgency:
                    return this.showEmailEnergyUrgency();
                case ContactLogType.EmailMover:
                    return this.showEmailMover();
                case ContactLogType.EmailRealEstateAgent:
                    return this.showEmailRealEstateAgent();
                default:
                    return false;
            }
        });
    }

    public remarkable(contactLog: ContactLog): boolean {
        switch (contactLog.value) {
            case ContactLogType.Call:
            case ContactLogType.Voicemail:
                return true;
            case ContactLogType.EmailEnergyUrgency:
            case ContactLogType.EmailPitch:
            case ContactLogType.EmailMover:
            case ContactLogType.EmailRealEstateAgent:
                return false;
            default:
                return false;
        }
    }

    public removable(contactLog: ContactLog): boolean {
        switch (contactLog.value) {
            case ContactLogType.Call:
            case ContactLogType.Voicemail:
                return true;
            case ContactLogType.EmailEnergyUrgency:
            case ContactLogType.EmailPitch:
            case ContactLogType.EmailMover:
            case ContactLogType.EmailRealEstateAgent:
                return false;
            default:
                return false;
        }
    }

    private showEmailEnergyUrgency(): boolean {
        return !!this.secondCall() || !!this.secondVoicemail() || (!!this.firstCall() && !!this.firstVoicemail() && !!this.emailPitch());
    }

    public canSendEmailEnergyUrgency(): boolean {
        if (this.move?.energyOrdered) return false;
        return !this.emailEnergyUrgency() && this.showEmailEnergyUrgency();
    }

    private showEmailPitch(): boolean {
        return !!this.firstVoicemail();
    }

    public canSendEmailPitch(): boolean {
        if (this.move?.energyOrdered) return false;
        return !this.emailPitch() && this.showEmailPitch();
    }

    private showEmailRealEstateAgent(): boolean {
        return !!this.emailEnergyUrgency() && this.move?.createdByRole === Role.RealEstateAgent && !!this.move?.realEstateAgent;
    }

    public canSendEmailRealEstateAgent(): boolean {
        if (this.move?.energyOrdered) return false;
        return !this.emailRealEstateAgent() && this.showEmailRealEstateAgent();
    }

    private showEmailMover(): boolean {
        return !!this.emailEnergyUrgency();
    }

    public canSendEmailMover(): boolean {
        if (this.move?.energyOrdered) return false;
        return !this.emailMover() && this.showEmailMover();
    }

    public firstVoicemail(): ContactLog {
        return MoveUtils.getFirstVoicemailInContactLogs(this.move?.contactLogs);
    }

    public secondVoicemail(): ContactLog {
        return MoveUtils.getSecondVoicemailInContactLogs(this.move?.contactLogs);
    }

    public firstCall(): ContactLog {
        return MoveUtils.getFirstCallInContactLogs(this.move?.contactLogs);
    }

    public secondCall(): ContactLog {
        return MoveUtils.getSecondCallInContactLogs(this.move?.contactLogs);
    }

    public emailPitch(): ContactLog {
        return MoveUtils.getEmailPitchInContactLogs(this.move?.contactLogs);
    }

    public emailEnergyUrgency(): ContactLog {
        return MoveUtils.getEmailEnergyUrgencyContactLogs(this.move?.contactLogs);
    }

    public emailMover(): ContactLog {
        return MoveUtils.getEmailMoverContactLogs(this.move?.contactLogs);
    }

    public emailRealEstateAgent(): ContactLog {
        return MoveUtils.getEmailRealEstateAgentContactLogs(this.move?.contactLogs);
    }

    public onEmailPitchSubmit(): void {
        this.moveSandbox
            .createContactLog(DbUtils.getStringId(this.move), {
                value: ContactLogType.EmailPitch,
            })
            .subscribe(this.sendSuccess);
    }

    public onEmailEnergyUrgencySubmit(): void {
        this.moveSandbox
            .createContactLog(DbUtils.getStringId(this.move), {
                value: ContactLogType.EmailEnergyUrgency,
            })
            .subscribe(this.sendSuccess);
    }

    public onEmailMoverSubmit(): void {
        this.moveSandbox
            .createContactLog(DbUtils.getStringId(this.move), {
                value: ContactLogType.EmailMover,
            })
            .subscribe(this.sendSuccess);
    }

    public onEmailRealEstateAgentSubmit(): void {
        this.moveSandbox
            .createContactLog(DbUtils.getStringId(this.move), {
                value: ContactLogType.EmailRealEstateAgent,
            })
            .subscribe(this.sendSuccess);
    }

    public onRemark($event: string, contactLog: ContactLog): void {
        this.moveSandbox
            .updateContactLog(DbUtils.getStringId(this.move), DbUtils.getStringId(contactLog), $event)
            .subscribe(this.updateSuccess);
    }

    public onRemove(contactLog: ContactLog): void {
        this.moveSandbox.deleteContactLog(DbUtils.getStringId(this.move), DbUtils.getStringId(contactLog)).subscribe(this.deleteSuccess);
    }

    public getTranslationLabelUnchecked(contactLog: ContactLog): string {
        switch (contactLog.value) {
            case ContactLogType.EmailEnergyUrgency:
                return 'MOVE.CONTACT_LOGS.EMAIL_ENERGY_URGENCY.UNCHECKED';
            case ContactLogType.EmailPitch:
                return 'MOVE.CONTACT_LOGS.EMAIL_PITCH.UNCHECKED';
            case ContactLogType.EmailMover:
                return 'MOVE.CONTACT_LOGS.EMAIL_MOVER.UNCHECKED';
            case ContactLogType.EmailRealEstateAgent:
                return 'MOVE.CONTACT_LOGS.EMAIL_REAL_ESTATE_AGENT.UNCHECKED';
            default:
                return '';
        }
    }

    public showUnchecked(contactLog: ContactLog): boolean {
        switch (contactLog.value) {
            case ContactLogType.EmailPitch:
            case ContactLogType.EmailEnergyUrgency:
            case ContactLogType.EmailMover:
            case ContactLogType.EmailRealEstateAgent:
                return true;
            default:
                return false;
        }
    }

    public isChecked(contactLog: ContactLog): boolean {
        switch (contactLog.value) {
            case ContactLogType.Call:
            case ContactLogType.Voicemail:
            case ContactLogType.EmailCustomMover:
            case ContactLogType.EmailCustomRealEstateAgent:
            case ContactLogType.EmailCustomLinkedMove:
            case ContactLogType.EmailCustomSupplier:
            case ContactLogType.EmailCustomOther:
            case ContactLogType.EmailLeaverInvite:
            case ContactLogType.EmailMeterCollection:
            case ContactLogType.EmailMeterCollectionReminder:
            case ContactLogType.EmailMeterCollectionFinalReminder:
            case ContactLogType.EmailEnergyStopLeaver:
            case ContactLogType.SmsCustomMover:
            case ContactLogType.EmailConfirm:
            case ContactLogType.EmailMeterTransferForLeaver:
            case ContactLogType.SmsLeaver:
            case ContactLogType.SmsEotsRequestWithoutMover:
            case ContactLogType.SmsEotsRequestWithMover:
            case ContactLogType.SmsMeterCollection:
            case ContactLogType.SmsEnergyStopLeaverExpectations:
            case ContactLogType.EmailEnergyStopLeaverExpectations:
            case ContactLogType.EmailSettingTheScene:
            case ContactLogType.EmailProvisionalEnergyProposal:
            case ContactLogType.EmailEnergyProposalReminder:
            case ContactLogType.EmailEnergyProposal:
            case ContactLogType.EmailEnergyProposalWithContext:
            case ContactLogType.SmsEnergyProposal:
            case ContactLogType.EmailEnergyProposalLandAgent:
            case ContactLogType.EmailEnergyProposalVacancy:
            case ContactLogType.EmailEnergyOrdered:
            case ContactLogType.EmailEnergyNotInterested:
            case ContactLogType.EmailMeterReadingsEnergy:
            case ContactLogType.EmailMeterReadingsWater:
            case ContactLogType.EmailEnergyInstalled:
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter15DaysLate:
            case ContactLogType.EmailRealEstateAgentEnteredLeaverAfter40DaysLate:
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter15DaysLate:
            case ContactLogType.EmailRealEstateAgentEnteredTransfereeAfter40DaysLate:
            case ContactLogType.EmailEnergyStopNotInterested:
            case ContactLogType.EmailEnergyStopSupplierComplete:
            case ContactLogType.EmailLeaverMeterReadingsEnergy:
            case ContactLogType.EmailLeaverMeterReadingsWater:
            case ContactLogType.EmailTransfereeNewWaterContract:
            case ContactLogType.EmailLeaverClosingWaterContract:
            case ContactLogType.EmailLeaverFollowUpClosingWaterContract:
            case ContactLogType.EmailTransfereeWaterTransferDocumentNotUploadedReminder:
            case ContactLogType.EmailLeaverWaterTransferDocumentNotUploadedReminder:
                return true;
            case ContactLogType.EmailEnergyUrgency:
                return !!this.emailEnergyUrgency();
            case ContactLogType.EmailPitch:
                return !!this.emailPitch();
            case ContactLogType.EmailMover:
                return !!this.emailMover();
            case ContactLogType.EmailRealEstateAgent:
                return !!this.emailRealEstateAgent();
            default:
                return false;
        }
    }

    public trackContactLog(index: number, log: ContactLog): string {
        return log._id;
    }

    public downloadEDS(): void {
        this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(this.move));
    }

    public resendSupplier(): void {
        const config = {
            data: DbUtils.getStringId(this.move),
        };

        this.modalSandbox.openModal(
            MailLeaverToSupplierModal,
            config,
            this.handleModalClose,
            MailLeaverToSupplierModal,
            config,
            this.handleModalClose
        );
    }

    public resendLeaverCollection(): void {
        this.adminContactLogService.resendLeaverCollectionInvite(this.move).subscribe(() => this.moveSandbox.updatedMoveTrigger.next());
    }

    public isLastOccurence(index: number, types: ContactLogType | ContactLogType[]): boolean {
        const logs = this.adminContactLogService
            .getAllContactLogs(this.move)
            .filter((log) => this.isChecked(log))
            .map((log) => log.value);
        const lastIndex = ArrayUtils.toArray(types)
            .map((type: ContactLogType) => logs.lastIndexOf(type))
            .sort(SortUtils.sort(SortDirection.Desc))[0];
        return lastIndex === index;
    }

    private sendSuccess = (): void => {
        this.notificationSandbox.sendSuccess();
        this.moveSandbox.updatedMoveTrigger.next();
    };

    private updateSuccess = (): void => {
        this.notificationSandbox.updatedSuccess();
        this.moveSandbox.updatedMoveTrigger.next();
    };

    private deleteSuccess = (response: Move): void => {
        this.notificationSandbox.deletedSuccess();
        this.moveSandbox.updatedMoveTrigger.next();
    };

    private handleModalClose = (move: Move): void => {
        if (!!move && !ObjectUtils.isEqual(move, this.move)) this.moveSandbox.updatedMoveTrigger.next();
    };
}
