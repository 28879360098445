import { Injectable } from '@angular/core';
import { MovesByRealEstateAgentPerWeek } from '@app/move/interfaces/move-by-real-estate-agent-per-week-response.interface';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { SmoovedAnalyticsSandbox } from '@app/real-estate-agent/sandboxes/smooved-analytics.sandbox';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { DataModelUtils } from '@app/shared/utils/data-model-utils';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, DateUtils, IsoWeekDays, RealEstateAgentUtils } from '@smooved/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { tableConfig, unknownRealEstateAgent, unknownRealEstateAgentLabel, weekCount } from './analytics-move-count-table.constants';
import { AnalyticsMoveCountRow, Row } from './analytics-move-count-table.interfaces';
import { TableDataSource } from './table-data-source';

@Injectable()
export class AnalyticsMoveCountTableService {
    public weeks: Date[];
    public from: Date;
    public until: Date;

    constructor(
        private readonly translate: TranslateService,
        private readonly analyticsSandbox: SmoovedAnalyticsSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox
    ) {
        this.weeks = this.generateWeeks(weekCount);
        this.from = DateUtils.tz(this.weeks[0]).utc().isoWeekday(IsoWeekDays.Monday).toDate();
        this.until = DateUtils.tz(this.weeks[this.weeks.length - 1])
            .utc()
            .endOf('d')
            .toDate();
    }

    public analyticsMoveCountRowFactory = (): AnalyticsMoveCountRow => {
        const row: Row = {
            label: '',
            total: 0,
            group: undefined,
            isGroup: false,
            visible: true,
        };

        return this.getWeeks().reduce(this.addProperty, row) as AnalyticsMoveCountRow;
    };

    public initDataSource(): Observable<AnalyticsMoveCountRow[]> {
        const realEstateGroup$ = this.realEstateGroupSandbox.realEstateGroup$ as Observable<RealEstateGroup>;
        const moveCounts$ = this.analyticsSandbox.getWeeklyMoveCounts(this.from, this.until);
        return zip(realEstateGroup$, moveCounts$).pipe(map(this.parseDataSource));
    }

    public toggleGroupVisibility(groupRow: AnalyticsMoveCountRow, data: AnalyticsMoveCountRow[]): void {
        data.filter((row) => row.group === groupRow.group).forEach((row) => (row.visible = !row.visible));
    }

    private addProperty = (obj: Object, prop: string): Object => ({ ...obj, [prop]: 0 });

    private addRealEstateAgentToList(
        list: AnalyticsMoveCountRow[],
        movesByRealEstateAgentPerWeek: MovesByRealEstateAgentPerWeek[],
        group?: string
    ) {
        return (realEstateAgent: string | RealEstateAgent): void => {
            const search = DataModelUtils.getId(realEstateAgent);
            const forRea = movesByRealEstateAgentPerWeek.find(this.findRealEstateAgentMoves(search));
            const weeklyNumbers = this.getWeeklyTotals(realEstateAgent, forRea);
            const label = this.realEstateAgentToString(realEstateAgent);
            const total = ArrayUtils.sum(Object.values(weeklyNumbers));
            const agentIsActive = this.isActive(forRea, realEstateAgent);
            if (total > 0 || agentIsActive) {
                list.push({
                    ...this.analyticsMoveCountRowFactory(),
                    label,
                    isActive: agentIsActive,
                    ...weeklyNumbers,
                    ...(group ? { group } : {}),
                    total,
                });
            }
        };
    }

    private isActive(forRea: MovesByRealEstateAgentPerWeek, rea: string | RealEstateAgent): any {
        return forRea ? forRea.isActive : rea instanceof Object ? RealEstateAgentUtils.isActive(rea) : undefined;
    }

    private findRealEstateAgentMoves(id: string) {
        return (item): boolean => item.realEstateAgent === id || (!item.realEstateAgent && !id);
    }

    private findWeeklyMoves(week: string) {
        return (item): boolean => item.week === week;
    }

    private generateWeeks = (count: number): Date[] => {
        const indexedCount = count - 1;
        return [...new Array<unknown>(count)].map((_, index): Date => {
            return DateUtils.tz()
                .utc(true)
                .startOf('d')
                .isoWeekday(IsoWeekDays.Sunday)
                .subtract(indexedCount - index, 'week')
                .toDate();
        });
    };

    private getWeeks(): string[] {
        return this.weeks.map(DateUtils.getYearWeek);
    }

    private getWeeklyTotals(rea: string | RealEstateAgent, forRea: MovesByRealEstateAgentPerWeek): Object {
        const entries: [string, number][] = this.getWeeks().map((week) => {
            const forWeek = forRea?.weeks.find(this.findWeeklyMoves(week));
            return [week, forWeek?.count || 0];
        });
        return Object.fromEntries(new Map(entries)) as Object;
    }

    private parseDataSource = ([realEstateGroup, movesByRealEstateAgentPerWeek]: [
        RealEstateGroup,
        MovesByRealEstateAgentPerWeek[],
    ]): AnalyticsMoveCountRow[] => {
        const dataSource: AnalyticsMoveCountRow[] = [];
        realEstateGroup.offices.forEach((office): void => {
            office.realEstateAgents.forEach(this.addRealEstateAgentToList(dataSource, movesByRealEstateAgentPerWeek, office.name));
        });

        // unknown REA bucket (when REA was deleted)
        this.addRealEstateAgentToList(
            dataSource,
            movesByRealEstateAgentPerWeek
        )({ ...unknownRealEstateAgent, firstName: this.translate.instant(unknownRealEstateAgentLabel) as string });

        return new TableDataSource<AnalyticsMoveCountRow>(this.translate)
            .set(dataSource, this.analyticsMoveCountRowFactory, tableConfig)
            .sortGroups(TableDataSource.sortByGroupTotalsDesc)
            .sortChildren(TableDataSource.sortByTotalsDesc)
            .ungroup();
    };

    private realEstateAgentToString(rea: string | RealEstateAgent): string {
        if (rea instanceof Object) {
            return [rea.firstName, rea.lastName].filter((v) => v).join(' ') || rea.email;
        }
        return rea;
    }
}
