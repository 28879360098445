import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ColorBulletModule } from '../color-bullet';
import { TooltipModule } from '../tooltip';
import { ArcScoreComponent } from './components/arc-score/arc-score.component';
import { ChartContainerComponent } from './components/chart-container/chart-container.component';
import { ChartPercentageComponent } from './components/chart-percentage/chart-percentage.component';
import { ChartLegendItemComponent } from './components/legend/chart-legend-item/chart-legend-item.component';
import { ChartLegendComponent } from './components/legend/chart-legend.component';
import { LineChartComponent } from './components/line/line-chart.component';
import { PieChartComponent } from './components/pie/pie-chart.component';
import { StackedBarChartComponent } from './components/stacked-bar/stacked-bar-chart.component';
import { ArcScoreValuePipe } from './pipes/arc-score-value/arc-score-value.pipe';

const components = [
    PieChartComponent,
    StackedBarChartComponent,
    ChartContainerComponent,
    ChartLegendItemComponent,
    ChartPercentageComponent,
    ArcScoreComponent,
    LineChartComponent,
];

const pipes = [ArcScoreValuePipe];

@NgModule({
    imports: [CommonModule, TranslateModule, ColorBulletModule, TooltipModule],
    declarations: [...components, ...pipes, ChartLegendComponent],
    exports: [...components, ...pipes],
})
export class ChartsModule {}
