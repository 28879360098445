import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { inject } from '@smooved/core';
import { from, Observable } from 'rxjs';

@Injectable()
export class NavigationSandbox {
    private router = inject(Router);

    public goToNotFound(): Observable<boolean> {
        return from(this.router.navigateByUrl(`/nl/404`));
    }
}
