import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { FormModule } from '@app/form/form.module';
import { MessageModule } from '@app/message/message.module';
import { CheckItemCreatedOnComponent } from '@app/order/components/check-item-created-on/check-item-created-on.component';
import { CheckItemGiftPreparedComponent } from '@app/order/components/check-item-gift-prepared/check-item-gift-prepared.component';
import { CheckItemGiftSentComponent } from '@app/order/components/check-item-gift-sent/check-item-gift-sent.component';
import { CheckItemRentalInspectionAssetComponent } from '@app/order/components/check-item-rental-inspection-asset/check-item-rental-inspection-asset.component';
import { CheckItemTelecomInstallationDateByAdminComponent } from '@app/order/components/check-item-telecom-installation-date-by-admin/check-item-telecom-installation-date-by-admin.component';
import { CheckItemTelecomOrderedByAdminComponent } from '@app/order/components/check-item-telecom-ordered-by-admin/check-item-telecom-ordered-by-admin.component';
import { CheckItemTelecomOrderedComponent } from '@app/order/components/check-item-telecom-ordered/check-item-telecom-ordered.component';
import { CheckItemTelecomPhoneTransferByAdminComponent } from '@app/order/components/check-item-telecom-phone-transfer-by-admin/check-item-telecom-phone-transfer-by-admin.component';
import { RentalAgreementAssetsComponent } from '@app/order/components/rental-agreement-assets/rental-agreement-assets.component';
import { RentalInspectionDetailsComponent } from '@app/order/components/rental-inspection-details/rental-inspection-details.component';
import { TelecomModule } from '@app/telecom/telecom.module';
import { TranslationModule } from '@app/translation/translation.module';
import { CheckItemModule } from '@app/ui/check-item/check-item.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ButtonModule, DocumentCenterModule as UiDocumentCenterModule, PipeModule, TrackAndTraceModule } from '@smooved/ui';
import { CheckItemRentalInspectionPlannedComponent } from './components/check-item-rental-inspection-planned/check-item-rental-inspection-planned.component';
import { OrderCreateEffects } from './state/order-create/order-create.effects';
import { OrderCreateService } from './state/order-create/order-create.service';
import { combiner, featureKey } from './state/order.combiner';

const components = [
    CheckItemTelecomOrderedComponent,
    CheckItemTelecomOrderedByAdminComponent,
    CheckItemTelecomInstallationDateByAdminComponent,
    CheckItemTelecomPhoneTransferByAdminComponent,
    CheckItemCreatedOnComponent,
    CheckItemGiftPreparedComponent,
    CheckItemGiftSentComponent,
    CheckItemRentalInspectionPlannedComponent,
    RentalInspectionDetailsComponent,
    CheckItemRentalInspectionAssetComponent,
    RentalAgreementAssetsComponent,
];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [
        CommonModule,
        TranslationModule,
        FormModule,
        PipeModule,
        CheckItemModule,
        TrackAndTraceModule,
        ButtonModule,
        TelecomModule,
        StoreModule.forFeature(featureKey, combiner),
        EffectsModule.forFeature([OrderCreateEffects]),
        UiDocumentCenterModule,
        DocumentCenterModule,
        MessageModule,
    ],
    providers: [OrderCreateService],
})
export class OrderModule {}
