<ng-container
    *ngIf="{
        isRealEstateAgent: authenticationSandbox.isRealEstateAgent$ | async,
        isMover: authenticationSandbox.isMover$ | async,
        isAdmin: authenticationSandbox.isAdmin$ | async,
        isLoggedIn: authenticationSandbox.isLoggedIn$ | async,
    } as vm"
>
    <ul class="u-flex-row u-flex-align-items-center" [ngSwitch]="vm.isLoggedIn">
        <li *ngIf="vm.isRealEstateAgent">
            <a
                smvd-ui-button
                [context]="uiContext.Muted"
                [appearance]="buttonAppearance.Link"
                target="_blank"
                [href]="'HEADER.MENU.REAL_ESTATE_AGENT.HELP_URL' | translate"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                >{{ 'HEADER.MENU.REAL_ESTATE_AGENT.HELP' | translate }}</a
            >
        </li>
        <li *ngIf="!vm.isRealEstateAgent">
            <a
                smvd-ui-button
                [context]="uiContext.Muted"
                [appearance]="buttonAppearance.Link"
                [href]="faqUri"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                target="_blank"
                >{{ 'HEADER.MENU.FAQ' | translate }}</a
            >
        </li>
        <li *ngSwitchCase="false">
            <a
                smvd-ui-button
                [context]="uiContext.Muted"
                [appearance]="buttonAppearance.Link"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                (click)="login()"
                >{{ 'HEADER.MENU.LOGIN' | translate }}</a
            >
        </li>
        <li *ngIf="!vm.isLoggedIn">
            <a
                [routerLink]="realEstateAgentLoginRouting"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                smvd-ui-button
                [context]="uiContext.Muted"
                [appearance]="buttonAppearance.Link"
                >{{ 'HEADER.MENU.FOR_REAL_ESTATE_AGENT' | translate }}</a
            >
        </li>
        <li *ngSwitchCase="true">
            <a
                smvd-ui-button
                [context]="uiContext.Muted"
                [appearance]="buttonAppearance.Link"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                (click)="authenticationSandbox.logout()"
                data-testid="header-menu-logout-button"
                >{{ 'HEADER.MENU.LOGOUT' | translate }}</a
            >
        </li>
        <li *ngIf="vm.isAdmin">
            <a
                smvd-ui-button
                [context]="uiContext.Accent"
                [appearance]="buttonAppearance.Link"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
                [routerLink]="adminUri"
                data-testid="header-menu-admin-dashboard-button"
                >{{ 'HEADER.MENU.ADMIN' | translate }}</a
            >
        </li>
        <li class="u-margin-left u-color-gray">
            <app-select-language
                data-testid="language-switcher"
                width="40px"
                [ngClass]="{ 'u-font-size-small': fontSizeSmall }"
            ></app-select-language>
        </li>
    </ul>
</ng-container>
