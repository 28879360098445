import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { UiContext } from '@smooved/ui';

@Component({
    selector: 'app-moving-date-detail',
    templateUrl: './moving-date-detail.component.html',
    styles: [':host {display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovingDateDetailComponent {
    @Input() public move: Move;
    public readonly uiContext = UiContext;

    constructor() { }
}
