import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '../base-input';
import { DropdownInput } from '../dropdown-input/dropdown-input';

@Component({
    selector: 'smvd-ui-text-search-input',
    templateUrl: './text-search-input.component.html',
    styleUrls: ['./text-search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextSearchInputComponent),
            multi: true,
        },
    ],
})
export class TextSearchInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public loading = false;
    @Input() public options: DropdownInput<any>[] = [];

    public innerModel: any = '';

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }
    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        // first time we need to set the innerModel as the query ( name ). Otherwise the lib will throw an error?
        this.innerModel = value ?? '';
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.propagateChange(this.innerModel?.value);
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
