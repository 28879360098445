import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';

@Directive({
    selector: '[appColorBullet]',
})
export class ColorBulletDirective implements OnInit, OnChanges {
    @Input() public color: string;

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    public ngOnInit(): void {
        this.renderer.addClass(this.el.nativeElement, 'm-color-bullet');
    }

    public ngOnChanges({ color }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(color)) {
            this.el.nativeElement.setAttribute('style', `--bullet-color: ${this.color}`);
        }
    }
}
