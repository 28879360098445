<div class="u-position-relative u-flex u-flex-justify-content-center u-flex-align-items-center" [class.disabled]="disabled">
    <div
        [style.width.px]="arcScoreSizeMap[size]"
        class="u-position-relative u-flex u-flex-justify-content-center u-flex-align-items-center"
    >
        <app-pie-chart
            *ngFor="let item of data | keyvalue; let first = first"
            [width]="arcScoreSizeMap[size]"
            [height]="arcScoreSizeMap[size]"
            [data]="item.value"
            [showTooltip]="false"
            [donutThick]="arcScoreThicknessMap[size]"
            [startAngle]="startAngle"
            [endAngle]="endAngle"
            [showLabels]="showLabels"
            roundCorners="5"
            [overlap]="!first"
        ></app-pie-chart>
        <h3 id="score" class="__score __score-{{ scoreSize || size }} __score-color-{{ context }}">
            {{ (houseMatchScore ? houseMatchScore : value) | arcScoreValue }}
        </h3>
    </div>
</div>
