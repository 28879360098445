<div class="u-flex-row u-flex-align-items-center u-position-relative">
    <app-icon [icon]="icon" [size]="iconSize.Md"></app-icon>
    <div class="u-flex-column u-margin-left-half">
        <label>{{ label }}</label>
        <span class="u-font-size-extra-small u-color-muted">{{ file.name }}</span>
    </div>
    <button
        *ngIf="showDelete"
        (click)="onDelete($event)"
        app-button
        [context]="buttonContext.Warning"
        [size]="buttonSize.Xs"
        [iconSize]="iconSize.Md"
        [appearance]="buttonAppearance.FlatIcon"
        icon="close"
        class="__delete"
    ></button>
</div>
