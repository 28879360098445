export enum EscalationFormControls {
    Search = 'search',
    Category = 'category',
    Tag = 'tag',
    State = 'state',
    Service = 'service',
    SupplierGroup = 'supplierGroup',
    ServicedBy = 'servicedBy',
    AccountManager = 'accountManager',
    NotifyAccountManager = 'notifyAccountManager',
    ExternalUrls = 'externalUrls',
    Description = 'description',
    RealEstateGroup = 'realEstateGroup',
}
