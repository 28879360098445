import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-check-list',
    template: `
        <div class="u-display-table">
            <div *ngFor="let item of content" class="u-display-table-row">
                <div class="u-display-table-cell u-vertical-align-top u-padding-right-half u-padding-top-quarter">
                    <app-svg width="14px" height="14px" class="u-color-success" [name]="checkmark"></app-svg>
                </div>
                <div class="u-display-table-cell u-vertical-align-top">
                    <span>{{ item }}</span>
                </div>
            </div>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckListComponent {
    @Input() public content: string[];
    public checkmark = Svg.Checkmark;
}
