import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveDetailCompleteWaterDataModal } from '@app/real-estate-agent/modals/move-detail-complete-water-data/move-detail-complete-water-data.modal';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { AnalyticsEventsEnum, AnalyticsService, ArrayUtils, Asset, DbUtils, HttpUtils } from '@smooved/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ButtonAppearance, ConfirmModalComponent, ModalSandbox, Svg, SvgIllustration, UiContext, UiIcon, UriSchemaType } from '@smooved/ui';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { MoveUtils } from '@app/move/state/move.utils';
import { TodoType } from '@app/move/enums/todo-type.enum';

@Component({
    selector: 'app-move-detail-water-section',
    templateUrl: 'move-detail-water-section.html',
    styleUrls: ['move-detail-water-section.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MoveDetailWaterSection {
    @ViewChild('uploadInput', { static: true })
    public input: ElementRef;

    @Output() public moveUpdate = new EventEmitter();

    public move$ = this.moveSandbox.move$;
    public hasCollectiveWaterMeter$ = this.move$.pipe(map((move) => move.waterCollectiveMeter === true));
    public waterTransferLocked$ = this.move$.pipe(
        map((move: Move) => {
            return move?.waterDocumentsMovingAddressByAdmin;
        })
    );

    public loading = false;
    public svg = Svg;
    public readonly uiContext = UiContext;
    public readonly uiIcon = UiIcon;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly svgIllustration = SvgIllustration;
    public readonly ArrayUtils = ArrayUtils;
    public readonly svgs = SvgIllustration;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uriSchemaType = UriSchemaType;
    public readonly mailTo = environment.mailTo[this.translate.currentLang] as string;
    protected readonly moveUtils = MoveUtils;

    public innerModel: string;

    constructor(
        public moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        private readonly analyticsService: AnalyticsService
    ) {}

    public toggleWaterTransferNeeded(value: boolean): void {
        this.loading = true;
        this.moveSandbox.patchProperty('waterCollectiveMeter', !value, true, (move) => {
            this.moveSandbox.setLatestMoveState(move);
            this.loading = false;
        });
    }

    public openCompleteWaterDataModal(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.modalSandbox.openModal(
                MoveDetailCompleteWaterDataModal,
                {},
                () => {
                    this.moveUpdate.emit();
                },
                MoveDetailCompleteWaterDataModal,
                null,
                () => {
                    this.moveUpdate.emit();
                }
            );
            this.analyticsService.sendEvent(AnalyticsEventsEnum.ReaWaterAddExtraDataOpen, {
                moveId: DbUtils.getStringId(move),
            });
        });
    }

    public downloadFile(asset: Asset): void {
        this.moveSandbox.openAsset(asset);
    }

    public onModelChange(event) {
        this.loading = true;
        const file = event.target.files[0];
        const formData = HttpUtils.addFiles([file]);
        this.moveSandbox.idOnce$
            .pipe(
                switchMap((moveId) => this.moveSandbox.uploadWaterDocumentAsset(DbUtils.getStringId(moveId), formData)),
                finalize(() => (this.loading = false))
            )
            .subscribe();
    }

    public showRemoveModal(asset: Asset): void {
        this.modalSandbox
            .openInfoModal(
                ConfirmModalComponent,
                this.translate.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.CONFIRM_DELETE')
            )
            .afterClosed()
            .subscribe((response) => this.remove(response, asset));
    }

    private remove(confirm: boolean, asset: Asset): void {
        if (!confirm) return;
        this.loading = true;

        this.move$
            .pipe(
                take(1),
                switchMap((move) => this.moveSandbox.deleteWaterDocumentAsset(DbUtils.getStringId(move), asset.key)),
                finalize(() => (this.loading = false))
            )
            .subscribe();
    }

    protected readonly TodoType = TodoType;
}
