import { FileUtils } from './file-utils';
import { from, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { StringUtils } from './string.utils';

export class WindowUtils {
    public static openWindow(url: string, width: number, height: number): void {
        const popupConfig =
            'resizable=yes, scrollbars=yes location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no, width={width}, height={height}, top={top}, left={left}';
        const left = window.screenX + window.screen.width / 2 - (width / 2 + 10);
        const top = window.screen.height / 2 - (height / 2 + 50);
        const configParams = { width, height, left, top };

        const element = document.createElement('a');
        element.style.display = 'none';
        element.addEventListener('click', () => {
            window.open(url, '_blank', StringUtils.parse(popupConfig, configParams));
        });
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    // Issue on Chrome iOS: file (image) opens in new tab.
    // Status Unresolved (21 oct 2021): https://github.com/eligrey/FileSaver.js/issues/686
    public static download(url: string, name: string): Observable<boolean> {
        const promise = fetch(url)
            .then((response) => response.blob())
            .then((blob) => FileUtils.downloadAsset(blob, name));

        return from(promise).pipe(mapTo(true));
    }
}
