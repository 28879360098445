import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { TakeoverInformationConsentComponent } from '@app/energy/components/takeover-information-consent/takeover-information-consent.component';
import { MoveData } from '@app/move/classes/move-data.class';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { AuditUtils, ObjectUtils } from '@smooved/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-meter-info-base',
    template: ``,
})
export abstract class MeterInfoBaseComponent extends MoveData implements OnInit {
    @ViewChild(TakeoverInformationConsentComponent)
    public takeoverInformationConsentComponent: TakeoverInformationConsentComponent;

    protected data: Partial<ModalDataMove>;

    protected showTakeOverInformationSubject = new BehaviorSubject<boolean>(false);

    protected showTakeOverConsent$: Observable<boolean>;
    protected disableTakeOverConsent$: Observable<boolean>;

    protected abstract meterReadingsTakeOverControl: AbstractControl;

    constructor(
        protected moveSandbox: MoveSandbox,
        protected authenticationSandbox: AuthenticationSandbox
    ) {
        super(moveSandbox, null);
    }

    public ngOnInit(): void {
        this.updateMove(this.data.move);
        this.patchedSubject = this.data.patchedSubject;
        this.showTakeOverConsent$ = combineLatest([
            this.authenticationSandbox.isRealEstateAgentOrAdmin$,
            this.move$.pipe(map((move) => AuditUtils.getValue(move?.moveStates?.meterReadingsTakeoverByRealEstateAgent))),
        ]).pipe(
            map(
                ([isRealEstateAgentOrAdmin, meterReadingsTakeoverByRealEstateAgent]) =>
                    isRealEstateAgentOrAdmin && !meterReadingsTakeoverByRealEstateAgent
            ),
            distinctUntilChanged()
        );

        this.disableTakeOverConsent$ = this.showTakeOverInformationSubject.asObservable().pipe(
            map((showTakeOverInformation) => !showTakeOverInformation),
            distinctUntilChanged()
        );

        combineLatest([this.showTakeOverConsent$, this.disableTakeOverConsent$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([showTakeOverConsent, disableTakeOverConsent]) => {
                const control =
                    this.takeoverInformationConsentComponent?.meterReadingsTakeoverForm?.get('meterReadingsTakeoverFormControl');
                if (!control) return;
                if (!showTakeOverConsent || disableTakeOverConsent) {
                    control.clearValidators();
                    return;
                }
                control.setValidators(Validators.requiredTrue);
                control.updateValueAndValidity({
                    emitEvent: false,
                });
            });
    }

    protected abstract dataTakeOverInformationFactory(): Partial<Move>;

    protected checkTakeOverInformation(): void {
        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            const data = this.dataTakeOverInformationFactory();

            // Remove the energyReadingsConfirmedByRealEstateAgent property from the data, so we check the condition excluding the checkbox.
            // There is a use case when the data is completed, checkbox is checked, then remove some data after => the checkbox needs to be false
            delete data.energyReadingsConfirmedByRealEstateAgent;

            this.showTakeOverInformationSubject.next(
                (this.hasAssets() || MoveUtils.meterComplete(data)) && AuditUtils.isFalsy(this.data.move.legal.meterReadingsTakeover)
            );
            if (isAdmin) {
                this.meterReadingsTakeOverControl.clearValidators();
            } else {
                if (this.showTakeOverInformationSubject.value) {
                    this.meterReadingsTakeOverControl.setValidators(Validators.requiredTrue);
                } else {
                    this.meterReadingsTakeOverControl.clearValidators();
                }
            }
            this.meterReadingsTakeOverControl.updateValueAndValidity({ emitEvent: false });
        });
    }

    protected getInitialEnergyType(): EnergyType {
        return this.data.move.energyOffer?.energyType || EnergyType.Both;
    }

    protected getPatchData(): Partial<Move> {
        const meterReadingsTakeOver = this.meterReadingsTakeOverControl.value as boolean;
        const patch: Partial<Move> = {};
        if (this.showTakeOverInformationSubject.value) {
            void ObjectUtils.set(patch, 'legal.meterReadingsTakeover', meterReadingsTakeOver);
            void ObjectUtils.set(patch, 'energyReadingsConfirmedByRealEstateAgent', meterReadingsTakeOver);
        }
        return patch;
    }

    protected abstract hasAssets(): boolean;

    protected abstract isDirty(): boolean;
}
