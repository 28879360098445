import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EanResult } from '@app/energy/interfaces/ean-result';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ButtonAppearance, CellTemplateComponent, ModalSandbox } from '@smooved/ui';

@Component({
    templateUrl: './ean-info-table-actions.column.html',
})
export class EanInfoTableActionsColumnComponent extends CellTemplateComponent {
    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService,
        private readonly moveSandbox: MoveSandbox,
        private readonly dialogRef: MatDialogRef<any>
    ) {
        super();
    }

    public readonly buttonAppearance = ButtonAppearance;

    public save(eanResult: EanResult): void {
        const data = this.translateService.instant('EAN.TABLE.SAVE_CONFIRMATION');
        this.moveSandbox.idOnce$.subscribe((moveId) => {
            this.modalSandbox.openConfirmModal({ data }, this.overrideEanInformation(eanResult, moveId));
        });
    }

    private overrideEanInformation = (eanResult: EanResult, moveId: string) => {
        return (confirm): void => {
            if (confirm) {
                this.moveSandbox.patch({
                    moveId,
                    payload: this.patchFactory(eanResult),
                    withNotification: true,
                    callback: (move) => this.dialogRef.close(move),
                });
            }
        };
    };

    private patchFactory({
        eanCodeElectricity,
        meterNumberElectricity,
        eanCodeGas,
        meterNumberGas,
    }: EanResult): Pick<Move, 'eanCodeElectricity' | 'meterNumberElectricityByAdmin' | 'eanCodeGas' | 'meterNumberGasByAdmin'> {
        const result = {
            eanCodeElectricityByAdmin: eanCodeElectricity,
            meterNumberElectricityByAdmin: meterNumberElectricity,
            eanCodeGasByAdmin: eanCodeGas,
            meterNumberGasByAdmin: meterNumberGas,
        };
        return result;
    }
}
