<div class="__main u-container-lg u-margin-0-auto" *ngIf="{ isPhone: uiSandbox.upToAndIncludingPhoneLandscape$ | async } as vm">
    <div class="u-flex-row u-flex-justify-content-center u-flex-align-items-center u-w100p" [class.u-margin-bottom-double]="vm.isPhone">
        <div *ngFor="let broker of selectedBrokers | slice: 0:maxDisplayLogoSelectedBrokersSlice" class="__image-container u-padding">
            <img [src]="broker.logo" alt="{{ broker.name }}" />
        </div>
        <div *ngIf="!!moreBrokersCount" class="__image-container u-padding">
            <span class="__more-brokers">{{ moreBrokersCount }}+</span>
        </div>
    </div>
</div>
