<div *ngIf="!!order">
    <h6 class="u-margin-bottom-half">{{ 'ORDERS.ORDER_SCOPE.PRODUCT.RENTALINSPECTION' | translate }}</h6>

    <app-check-item-created-on
        *ngIf="partner"
        class="u-margin-bottom-double"
        [order]="order"
        [label]="'REQUESTED_AT' | translate: { partner: partner?.name }"
    >
        <app-rental-agreement-assets
            class="u-margin-top u-display-block u-font-size-default u-color-text"
            [order]="order"
        ></app-rental-agreement-assets>
    </app-check-item-created-on>

    <app-check-item-rental-inspection-planned
        class="u-margin-bottom-double"
        [order]="order"
        [readonly]="true"
        #planned
    ></app-check-item-rental-inspection-planned>

    <app-check-item-rental-inspection-asset
        class="u-margin-bottom-double u-padding-bottom-double u-bordered-bottom"
        [order]="order"
        (deleted)="reFetch.emit()"
        [disabled]="!planned.checked"
    ></app-check-item-rental-inspection-asset>

    <app-thread class="u-display-block u-margin-bottom-double" [thread]="order.thread"></app-thread>
    <app-new-message-form [label]="'MESSAGE.FOR_RENTAL_INSPECTION_AGENT' | translate" (send)="sendMessage($event)"></app-new-message-form>
</div>
