import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AgreementService {
    public static readonly baseUri: string = `${environment.apiUri}/agreements`;
    public static readonly initUri: string = `${AgreementService.baseUri}/init`;

    constructor(private httpClient: HttpClient) {}

    public createByMoveId(moveId: string, sendInvite: boolean, onlyPrint: boolean): Observable<{ url?: string }> {
        return this.httpClient.post(AgreementService.initUri, {
            moveId,
            sendInvite,
            onlyPrint,
        });
    }
}
