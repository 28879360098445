import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    changes = new Subject<void>();
    rangeLabel: string;

    constructor(private translate: TranslateService) {
        super();
        this.translate.onLangChange.subscribe(() => {
            this.getAndInitTranslations();
        });
        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translate
            .get([
                'PAGINATOR.ITEMS_PER_PAGE',
                'PAGINATOR.NEXT_PAGE',
                'PAGINATOR.PREVIOUS_PAGE',
                'PAGINATOR.FIRST_PAGE',
                'PAGINATOR.LAST_PAGE',
                'PAGINATOR.OF_LABEL',
                'PAGINATOR.RANGE_LABEL',
            ])
            .subscribe((translation: any) => {
                this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
                this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE'];
                this.lastPageLabel = translation['PAGINATOR.LAST_PAGE'];
                this.rangeLabel = translation['PAGINATOR.RANGE_LABEL'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 of ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${length}`;
    };
}
