import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CanDisable } from '@angular/material/core';
import { Checked } from '@app/ui/components/checked/checked.enum';
import { SimpleChangesUtils, StringUtils } from '@smooved/core';
import { UiIconSize } from '../../icon';
import { SvgIllustration } from '../../svg';
import { UiRotate, UiVerticalAlignment } from '../../ui.enums';
import { maxBadgeValue, menuItemBaseClass, menuItemClasses } from '../menu.constants';
import { MenuItemAppearance, MenuItemIconSize, MenuItemSize } from '../menu.enums';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnChanges, OnInit, CanDisable {
    @Input() public appearance = MenuItemAppearance.Default;
    @Input() public hasSubMenu = false;
    @Input() public icon: string;
    @Input() public svg: SvgIllustration;
    @Input() public svgSize = MenuItemIconSize.Default;
    @Input() public iconRotate: UiRotate;
    @Input() public goTo: string | any[];
    @Input() public title: string;
    @Input() public size = MenuItemSize.Default;
    @Input() public disabled: boolean;
    @Input() public badge: string;
    @Input() public badgeAlignment: UiVerticalAlignment.Top | UiVerticalAlignment.Middle = UiVerticalAlignment.Middle;
    @Input() public tag: string;
    @Input() public active = false;
    @Input() public level = 0;
    @Input() public checked = Checked;
    @Input() public readonly: boolean;
    @Input() public anchor: string;

    @Output() subMenuItemClicked: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class') public hostClass: string;

    public appearanceClass = menuItemBaseClass;
    public isWide = false;

    public readonly iconSize = UiIconSize;
    public readonly menuAppearance = MenuItemAppearance;
    public readonly illustrationEnum = SvgIllustration;

    public ngOnInit(): void {
        this.setAppearance();
    }

    public ngOnChanges({ appearance, size, badge }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(appearance, size, badge)) {
            this.setAppearance();
        }
    }

    public scrollTo(event: Event) {
        event.preventDefault();
        const yOffset = -100;
        const element = document.getElementById(this.anchor);
        if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    private setAppearance(): void {
        this.hostClass = `${menuItemBaseClass}-${this.appearance || MenuItemAppearance.Default}`;
        this.appearanceClass = StringUtils.join(
            [
                menuItemBaseClass,
                `${menuItemBaseClass}-size-${this.size}`,
                `${menuItemBaseClass}-level-${this.level}`,
                `${menuItemBaseClass}-svg-size-${this.svgSize}`,
                menuItemClasses[this.appearance],
                this.isHighValue(Number(this.badge)) ? menuItemClasses.highBadge : '',
            ],
            ' '
        );
        this.isWide = this.isHighValue(Number(this.badge));
    }

    private isHighValue = (value: number): boolean => value > maxBadgeValue;

    onClick(): void {
        this.subMenuItemClicked.emit();
    }
}
