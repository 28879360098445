import { EscalationActionType } from '@app/escalation/enums/action.enum';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { i18nEscalation } from '@app/move/constants/move.constants';
import { Mention } from '@app/user/interfaces/mention';
import { EnumUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';

export enum LogActionsControlNames {
    Type = 'type',
    Remark = 'remark',
    State = 'state',
}

export interface LogActionsForm {
    [LogActionsControlNames.Type]: EscalationActionType;
    [LogActionsControlNames.Remark]: Mention;
    [LogActionsControlNames.State]: EscalationState;
}

export const actionTypeOptions: DropdownInput<EscalationActionType>[] = EnumUtils.values<EscalationActionType>(EscalationActionType).map(
    (actionType) => ({
        id: 'action',
        name: `action-${actionType}`,
        labelResource: i18nEscalation(`ACTION.TYPE.${actionType.toUpperCase()}`),
        value: actionType,
    })
);
