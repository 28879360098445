import { EscalationState } from '@app/escalation/enums/state.enum';
import { i18nContactLog } from '@app/move/constants/move.constants';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { DropdownInput } from '@smooved/ui';

export enum FormControlNames {
    Value = 'value',
    Remark = 'remark',
    Escalation = 'escalation',
    State = 'state',
}

export interface CreateContactLogForm {
    [FormControlNames.Value]: ContactLogType;
    [FormControlNames.Remark]: string;
    [FormControlNames.Escalation]: string;
    [FormControlNames.State]: EscalationState;
}

export const contactLogTypeOptions: DropdownInput<ContactLogType>[] = [
    {
        id: ContactLogType.Call.toString(),
        name: `contact-log-type-${ContactLogType.Call.toString().toLowerCase()}`,
        labelResource: i18nContactLog(ContactLogType.Call.toUpperCase()),
        value: ContactLogType.Call,
    },
    {
        id: ContactLogType.Voicemail.toString(),
        name: `contact-log-type-${ContactLogType.Voicemail.toString().toLowerCase()}`,
        labelResource: i18nContactLog(ContactLogType.Voicemail.toUpperCase()),
        value: ContactLogType.Voicemail,
    },
];
