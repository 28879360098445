import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Language, RxjsComponent, SimpleChangesUtils, skipWhileEmpty, StringUtils } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { WidgetI18nKeyType } from '../../../i18n/i18n-key-type-map.constants';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { UiDirection, UiSize } from '../../../ui.enums';
import { WidgetQueryParams, WidgetViewModel } from '../../widget.interface';
import { WidgetService } from './widget.service';

@Component({
    selector: 'wis-widget-v2',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
    providers: [WidgetService],
})
export class WidgetV2Component extends RxjsComponent implements OnInit, OnChanges {
    @Input() public key: string;
    @Input() public language = Language.NL;
    @Input() public background = true;
    @Input() public shadow = true;
    @Input() public direction = UiDirection.Horizontal;
    @Input() public size = UiSize.Medium;
    @Input() public color: string;

    public logo = SvgIllustration.HouseMatchIconDefault;
    public widgetData: WidgetViewModel;
    public hasLogo = false;

    public readonly i18nKeyType = WidgetI18nKeyType;
    public readonly uiDirection = UiDirection;
    public readonly uiSize = UiSize;

    constructor(private readonly widgetService: WidgetService, private readonly route: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        const { id } = this.route.snapshot.params;
        const { lang, shadow, bg, direction, size, color }: WidgetQueryParams = this.route.snapshot.queryParams;

        this.language = (Language[lang?.toUpperCase()] as Language) || this.language;
        this.background = bg ? StringUtils.parseBoolean(bg) : this.background;
        this.shadow = shadow ? StringUtils.parseBoolean(shadow) : this.shadow;
        this.direction = direction || this.direction;
        this.size = size || this.size;
        this.color = color || this.color;
        this.logo = this.color ? SvgIllustration.HouseMatchIconMonochrome : SvgIllustration.HouseMatchIconDefault;
        this.widgetService.bootstrap(this.key || id, color);
        this.widgetService.widgetDataSubject.pipe(skipWhileEmpty(), takeUntil(this.destroy$)).subscribe((data) => (this.widgetData = data));
    }

    public ngOnChanges({ color }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(color)) {
            this.widgetService.setColors(this.color);
            this.logo = this.color ? SvgIllustration.HouseMatchIconMonochrome : SvgIllustration.HouseMatchIconDefault;
        }
    }
}
