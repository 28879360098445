import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UiFormModule } from '../form';
import { LocationsInputComponent } from './components/locations-input/locations-input.component';
import { RealEstateGroupLocationsService } from './services/real-estate-group-locations.service';
import { RealEstateGroupEffects } from './state/effects';
import { realEstateGroupFeatureKey, realEstateGroupReducer } from './state/reducer';

const components = [LocationsInputComponent];

const services = [RealEstateGroupLocationsService];

@NgModule({
    declarations: [...components],
    providers: [...services],
    exports: [...components],
    imports: [
        CommonModule,
        UiFormModule,
        StoreModule.forFeature(realEstateGroupFeatureKey, realEstateGroupReducer),
        EffectsModule.forFeature([RealEstateGroupEffects]),
        TranslateModule,
    ],
})
export class RealEstateGroupModule {}
