import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { InvoiceVersion } from '@app/invoicing/enums/invoice-version.enum';
import { RealEstateGroupInvoicingType } from '@app/invoicing/enums/invoicing-type.enum';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { AssetInputModal } from '@app/invoicing/modals/asset-input/asset-input.modal';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { PackageId } from '@app/package/enums/package-id.enum';
import { BillingCycle } from '@app/real-estate-group/enums/billing-cycle.enum';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox, SvgIllustration, UiContext, UiIcon, UiSandbox } from '@smooved/ui';
import { vat } from './invoice.constants';
import { FeatureScope } from '@smooved/core';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class InvoiceComponent {
    @Input() public invoice: Invoice;
    @Output() public upload: EventEmitter<void> = new EventEmitter<void>();

    public readonly uiContext = UiContext;
    public readonly featureScope = FeatureScope;
    public readonly version = InvoiceVersion;
    public readonly uiIcon = UiIcon;
    public readonly UiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;
    public readonly invoiceStatus = InvoiceStatus;
    public readonly desktopUp$ = this.uiSandbox.desktopUp$;

    constructor(
        private readonly uiSandbox: UiSandbox,
        private readonly invoicingSandbox: InvoicingSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly translate: TranslateService
    ) {}

    public openUploadInvoice(): void {
        const header = this.translate.instant('INVOICING.UPLOAD_INVOICE') as string;
        this.modalSandbox.openClosableModal({
            component: AssetInputModal,
            config: {
                header,
            },
            afterClosed: (formData: FormData) => {
                if (formData) {
                    this.invoicingSandbox.uploadAsset(this.invoice.id, formData, RealEstateGroupInvoicingType.commission).subscribe(() => {
                        this.upload.emit();
                    });
                }
            },
        });
    }

    public getCtaLabel(invoice: Invoice) {
        if (invoice.version === InvoiceVersion.v2) {
            return !!invoice.asset ? 'UPLOAD_COMMISSION_INVOICE_UPLOADED' : 'UPLOAD_COMMISSION_INVOICE';
        } else {
            return !!invoice.asset ? 'UPLOAD_INVOICE_UPLOADED' : 'UPLOAD_INVOICE';
        }
    }

    public showDownloadInfo = (): boolean =>
        this.invoice.package.key === PackageId.SmoovedPlus && this.invoice.billingCycle !== BillingCycle.annually;

    public includingVAT = (value: number): number => value * vat;
    protected readonly PackageId = PackageId;
}
