<ng-container *ngIf="showMeta; else defaultView">
    <div
        *ngIf="review.showReviewMeta || review.source !== reviewSources.Smooved; else blurred"
        class="__nps-review-author__quick-info u-color-muted u-w100p"
    >
        <div class="__info">
            <time [dateTime]="review.createdOn | formatDate: pubDateformat" class="u-color-muted" pubdate>{{
                    review.createdOn | formatDate
                }}
            </time>
        </div>
        <div *ngIf="review.createdBy" class="__info">
            <div
                [hasDistanceFromElement]="false"
                [paddingSize]="uiSize.Xs"
                [template]="tooltipReviewAuthor"
                [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
                appTooltip
                class="__info u-margin-right-none"
            >
                <app-icon [size]="iconSize.Md" aria-hidden="true" icon="person_outline" tabindex="-1"></app-icon>
                <span>{{ review.createdBy | authorCaption }}</span>
            </div>
            <ng-container *ngIf="isVerified">
                <span class="u-margin-x-axis-xs">&#183;</span>
                <smvd-ui-review-verification-badge
                    [isVerified]="isVerified"
                    [locationOrGroupName]="review.location?.name || review.group"
                    [reviewSource]="review.source"
                    [template]="verification"
                ></smvd-ui-review-verification-badge>
                <span class="u-margin-x-axis-xs">&#183;</span>
            </ng-container>
            <span>{{ assesorI18n | translate }}</span>
        </div>
        <div
            *ngIf="houseTypeI18n || review.city"
            [hasDistanceFromElement]="false"
            [paddingSize]="uiSize.Xs"
            [template]="tooltipHouseType"
            [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
            appTooltip
            class="__info"
        >
            <app-icon [icon]="houseTypeIcon" [size]="iconSize.Md" aria-hidden="true" tabindex="-1"></app-icon>
            <span>{{ houseTypeI18n | translate | capitalize }}</span>
            <span class="u-margin-x-axis-xs">&#183;</span>
            <span>{{ review.city }}</span>
        </div>
        <div
            *ngIf="review.location"
            [hasDistanceFromElement]="false"
            [paddingSize]="uiSize.Xs"
            [template]="tooltipBrokerLocation"
            [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-none'"
            appTooltip
            class="__info"
        >
            <app-svg-illustration [context]="uiContext.Muted" [svg]="svgIllustration.Office"></app-svg-illustration>
            <span class="city-span">{{ review.location?.address?.city }}</span>
        </div>
        <div *ngIf="templateConfig.showReviewVia && isVerified && reviewViaIcon" class="__info">
            <app-icon [icon]="reviewViaIcon" [size]="iconSize.Md" aria-hidden="true" tabindex="-1"></app-icon>
            <span class="review-via-i18n-span">{{ reviewViaI18n | translate | capitalize }} </span>
        </div>
    </div>
</ng-container>

<ng-template #defaultView>
    <div class="__nps-review-author__quick-info u-color-muted u-w100p">
        <div class="__info">
            <time [dateTime]="review.createdOn | formatDate: pubDateformat" class="u-color-muted" pubdate>{{
                    review.createdOn | formatDate
                }}
            </time>
        </div>
        <p class="__info">
            <span class="u-margin-right-xs">{{ 'UI.REVIEWS.SIMPLE_VIEW.LABEL' | translate }}</span>
            <smvd-ui-review-verification-badge
                *ngIf="isVerified"
                [isVerified]="isVerified"
                [reviewSource]="review.source"
                [template]="verification"
            ></smvd-ui-review-verification-badge>
        </p>
    </div>
</ng-template>

<ng-template #blurred>
    <div>
        <span
            class="u-text-blurred u-margin-right">{{ 'BLURRED_META.USER' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
        <span
            class="u-text-blurred u-margin-right">{{ 'BLURRED_META.CITY' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
        <span
            class="u-text-blurred">{{ 'BLURRED_META.TRANSACTION' | i18nKeyTranslate: i18nKeyTypes.ReviewTemplate }}</span>
    </div>
</ng-template>

<ng-template #tooltipReviewAuthor>
    <p class="u-padding-half">{{ 'UI.REVIEWS.AUTHOR.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipHouseType>
    <p class="u-padding-half">{{ 'UI.REVIEWS.PROPERTY.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #tooltipBrokerLocation>
    <p class="u-padding-half">{{ 'UI.REVIEWS.LINKED_LOCATION.TOOLTIP' | translate }}</p>
</ng-template>
