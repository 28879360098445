import { Component, forwardRef, Host, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ConsentModalComponent } from '@app/start/components/consent-modal/consent-modal.component';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { environment } from '@environments/environment';
import { BaseInput } from '@smooved/ui';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-smooved-terms-and-conditions',
    template: `
        <app-check-input
            [custom]="false"
            [(ngModel)]="innerModel"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [width]="width"
            (ngModelChange)="onLegalChange($event)"
        >
            <span class="u-display-block"
                >{{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SMOOVED.1' | translate }}
                <a class="u-link u-color-muted" target="_blank" [href]="termsAndConditionsUri">{{
                    'MOVE.LEGAL.TERMS_AND_CONDITIONS.SMOOVED.2' | translate
                }}</a>
                {{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SMOOVED.3' | translate }}
                <a class="u-link u-color-muted" (click)="openSmoovedPrivacyModal($event)">{{
                    'MOVE.LEGAL.TERMS_AND_CONDITIONS.SMOOVED.4' | translate
                }}</a>
                {{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SMOOVED.5' | translate }}
            </span>
        </app-check-input>
    `,
    styles: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SmoovedTermsAndConditionsComponent),
            multi: true,
        },
    ],
})
export class SmoovedTermsAndConditionsComponent extends BaseInput implements ControlValueAccessor, OnDestroy {
    @Input() public formControlName: string;
    @Input() public width = 'auto';
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: boolean;
    public innerDisabled: boolean;
    public required: boolean;
    public termsAndConditionsUri: string;

    private sub: Subscription;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private dialog: MatDialog,
        private moveSandbox: MoveSandbox,
        private translationSandbox: TranslationSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        // Unset legal on init
        // this.moveSandbox.patchMoveState('legal', 'termsAndConditions', false);
        // this.moveSandbox.patchMoveState('legal', 'gdpr', false);
        super.ngOnInit();
        this.termsAndConditionsFactory();
    }

    public ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    public openSmoovedPrivacyModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ConsentModalComponent);
    }

    public onLegalChange(value): void {
        // this.moveSandbox.patchMoveState('legal', 'termsAndConditions', value);
        // this.moveSandbox.patchMoveState('legal', 'gdpr', value);
        this.propagateChange(this.innerModel);
    }

    public writeValue(value: boolean): void {
        this.innerModel = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }

    private termsAndConditionsFactory(): void {
        this.sub = this.translationSandbox.selected$.subscribe((lang) => {
            this.termsAndConditionsUri = environment.termsAndConditionsUri[lang];
        });
    }
}
