import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, ModalSandbox, NotificationSandbox, TodoState } from '@smooved/ui';
import { Move } from '../../../move/interfaces/move';
import { serviceOrderedI18nKeys } from './todo-energy-eots-sent.constants';

@Component({
    selector: 'app-todo-energy-eots-sent',
    templateUrl: `./todo-energy-eots-sent.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEnergyEotsSentComponent {
    @Input() public move: Move;

    public readonly i18nKeys = serviceOrderedI18nKeys;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly todoState = TodoState;

    constructor(
        private moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService,
        private notificationSandbox: NotificationSandbox
    ) {}

    public resendConfirm(): void {
        this.modalSandbox.openConfirmModal({ data: this.translateService.instant(this.i18nKeys.Confirm) }, this.onResendConfirmModalClosed);
    }

    private onResendConfirmModalClosed = (result): void => {
        if (!result) return;
        this.moveSandbox.sendConfirmationEmail(DbUtils.getStringId(this.move)).subscribe((move) => {
            this.moveSandbox.fetch(DbUtils.getStringId(move));
            this.notificationSandbox.success(this.i18nKeys.Success);
        });
    };
}
