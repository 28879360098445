import { Breadcrumb } from '@app/header/interfaces/breadcrumb';
import { Step } from '@app/mortgage/enums/step.enum';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';

export const mortgageBaseRoute = 'mortgage';

export const mortgageBaseRouting: string[] = [mortgageBaseRoute];
export const mortgageAddressRouting: string[] = [...mortgageBaseRouting, Step.Address];
export const mortgageHomeConstructionYearRouting: string[] = [...mortgageBaseRouting, Step.HomeConstructionYear];
export const mortgageFinancialRouting: string[] = [...mortgageBaseRouting, Step.Financial];
export const mortgageSuggestionsRouting: string[] = [...mortgageBaseRouting, Step.Suggestions];
export const mortgageContactDetailsRouting: string[] = [...mortgageBaseRouting, Step.ContactDetails];

export const mortgageOverviewRouting: string[] = [Navigation.RealEstateAgent, Navigation.Mortgage, Navigation.Overview];
export const mortgageSuccessRouting: string[] = [...mortgageBaseRouting, Step.Success];

export const mortgageBreadcrumb: Breadcrumb = {
    titleResource: 'MORTGAGE.BREADCRUMB.TITLE',
    items: [
        {
            id: 'data',
            labelResource: 'MORTGAGE.BREADCRUMB.DATA',
            routes: [
                AppNavigationSandbox.getAbsolute(mortgageAddressRouting),
                AppNavigationSandbox.getAbsolute(mortgageHomeConstructionYearRouting),
                AppNavigationSandbox.getAbsolute(mortgageFinancialRouting),
            ],
        },
        {
            id: 'suggestions',
            labelResource: 'MORTGAGE.BREADCRUMB.SUGGESTIONS',
            routes: [AppNavigationSandbox.getAbsolute(mortgageSuggestionsRouting)],
        },
        {
            id: 'appointment',
            labelResource: 'MORTGAGE.BREADCRUMB.APPOINTMENT',
            routes: [AppNavigationSandbox.getAbsolute(mortgageContactDetailsRouting)],
        },
    ],
};
