import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NoopAction } from '../../../../../core/src/lib/store';
import { ReviewInsightsService } from '../services/review-insights.service';
import {
    getReviewInsightsHouseMatchScoreAction,
    getReviewInsightsHouseMatchScoreSuccess,
    getReviewInsightsOtherScoresAction,
    getReviewInsightsOtherScoresSuccess,
} from './actions';

@Injectable()
export class ReviewInisghtEffects {
    public getHouseMatchScore$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getReviewInsightsHouseMatchScoreAction),
            switchMap(({ realEstateGroupId }) => {
                return this.reviewInsightsService.getReviewScores(realEstateGroupId).pipe(
                    map((reviewScores) => getReviewInsightsHouseMatchScoreSuccess({ houseMatchScore: reviewScores })),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    public getOtherScores$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getReviewInsightsOtherScoresAction),
            switchMap(({ realEstateGroupIds }) => {
                return this.reviewInsightsService.getOtherScores(realEstateGroupIds).pipe(
                    map((otherScores) => getReviewInsightsOtherScoresSuccess({ otherScores })),
                    catchError((_) => of(new NoopAction()))
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly reviewInsightsService: ReviewInsightsService
    ) {}
}
