export enum ResetPasswordFields {
    ResetToken = 'resetToken',
    CurrentPassword = 'currentPassword',
    NewPassword = 'newPassword',
    ConfirmPassword = 'confirmPassword',
}

export interface ResetPasswordForm {
    [ResetPasswordFields.ResetToken]: string;
    [ResetPasswordFields.CurrentPassword]: string;
    [ResetPasswordFields.NewPassword]: string;
    [ResetPasswordFields.ConfirmPassword]: string;
}
