import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/interfaces/order';

@Component({
    selector: 'app-gift-track-and-trace',
    templateUrl: './gift-track-and-trace.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftTrackAndTraceComponent {
    @Input() public order: Order;
    @Input() public isAdmin: boolean;
}
