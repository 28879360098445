<div @fade>
    <div #tooltipBody class="__tooltip-body">
        <div [class]="'__tooltip-container-' + paddingSize" [ngClass]="tooltipClasses">
            <ng-container *ngIf="label">
                <h6 class="__title u-color-{{ context }}" [innerHtml]="label"></h6>
            </ng-container>
            <ng-container *ngIf="sub">
                <p class="__sub u-font-size-small u-color-muted" [innerHtml]="sub"></p>
            </ng-container>
            <ng-container *ngIf="templateRef">
                <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="templateOutletContext"></ng-container>
            </ng-container>
        </div>
    </div>
    <div #tooltipArrow *ngIf="!hidePointer" class="__tooltip-arrow"></div>
</div>
