import { Directive } from '@angular/core';
import { TodoState } from './todo-state.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[todoStateReadonly]',
})
export class TodoStateReadonlyDirective {
    public readonly state = TodoState.Readonly;
}
