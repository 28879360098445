import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';

export const statusOptions: DropdownInput<InvoiceStatus>[] = [
    ...Object.keys(InvoiceStatus).map((key) => ({
        id: key,
        name: 'status',
        labelResource: `INVOICING.STATUS.${key.toUpperCase()}`,
        value: InvoiceStatus[key],
    })),
];

export const columns: string[] = ['name', 'status', 'comission', 'subscription', 'feeTotal', 'asset.createdOn', 'options'];

export const defaultSortProperty = 'feeTotal';
