import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-energy-promo-modal',
    template: `
        <app-info-modal>
            <div
                *ngIf="
                    !!(
                        data.promoInfoElectricityLabels
                        | translatedLabel: { fallback: { object: data, label: 'promoInfoElectricity' } }
                        | async
                    )
                "
                [ngClass]="{ 'u-margin-bottom-double': !!data.promoInfoGasLabels }"
            >
                <h3 class="u-margin-bottom">{{ 'MOVE.ENERGY.SUGGESTION.SHOW_PROMO_INFO.ELECTRICITY.TITLE' | translate }}</h3>
                <p
                    [innerHTML]="
                        data.promoInfoElectricityLabels
                            | translatedLabel: { fallback: { object: data, label: 'promoInfoElectricity' } }
                            | async
                    "
                ></p>
            </div>
            <div *ngIf="!!(data.promoInfoGasLabels | translatedLabel: { fallback: { object: data, label: 'promoInfoGas' } } | async)">
                <h3 class="u-margin-bottom">{{ 'MOVE.ENERGY.SUGGESTION.SHOW_PROMO_INFO.GAS.TITLE' | translate }}</h3>
                <p
                    [innerHTML]="data.promoInfoGasLabels | translatedLabel: { fallback: { object: data, label: 'promoInfoGas' } } | async"
                ></p>
            </div>
        </app-info-modal>
    `,
})
export class EnergyPromoModal {
    constructor(@Inject(MAT_DIALOG_DATA) public data: EnergySuggestion, public translateService: TranslateService) {}
}
