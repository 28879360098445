export enum OtsConfirmationClusterLevel {
    Zero = 0,
    One,
    Two,
    Three,
    Four,
}

export enum ClusterLevelFilter {
    All = 'all',
}

export type ClusterLevelFilterType = OtsConfirmationClusterLevel | ClusterLevelFilter;
