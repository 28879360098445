<form [formGroup]="externalInfluencerService.form.get(formGroupNames.contact)" class="__form">
    <app-phone-input width="100%" [formControlName]="formControlNames.phoneNumber" [label]="'PHONE_NUMBER' | translate"></app-phone-input>
    <app-email-input width="100%" [formControlName]="formControlNames.email" [label]="'EMAIL' | translate"></app-email-input>
    <app-text-input width="100%" [formControlName]="formControlNames.linkedIn" [label]="'LINKEDIN.LABEL' | translate">
        <span prefix>{{ 'LINKEDIN.PREFIX' | translate }}</span>
    </app-text-input>
    <app-text-input width="100%" [formControlName]="formControlNames.facebook" [label]="'FACEBOOK.LABEL' | translate">
        <span prefix>{{ 'FACEBOOK.PREFIX' | translate }}</span>
    </app-text-input>
    <app-text-input width="100%" [formControlName]="formControlNames.instagram" [label]="'INSTAGRAM.LABEL' | translate">
        <span prefix>{{ 'INSTAGRAM.PREFIX' | translate }}</span>
    </app-text-input>
    <app-text-input width="100%" [formControlName]="formControlNames.youtube" [label]="'YOUTUBE.LABEL' | translate">
        <span prefix>{{ 'YOUTUBE.PREFIX' | translate }}</span>
    </app-text-input>
    <app-text-input width="100%" [formControlName]="formControlNames.twitter" [label]="'TWITTER.LABEL' | translate">
        <span prefix>{{ 'TWITTER.PREFIX' | translate }}</span>
    </app-text-input>
    <app-text-input
        width="100%"
        [formControlName]="formControlNames.website"
        [label]="'WEBSITE.LABEL' | translate"
        [hasMarginDouble]="false"
        [hasMargin]="false"
    ></app-text-input>
</form>
