import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { hideLoadingOverlay, moveLoading, patchLoading, showLoadingOverlay } from './ui.actions';

export interface UiState {
    loadingOverlay: boolean;
    moveLoading: boolean;
    patchLoading: boolean;
}

export const initialUiState: UiState = {
    loadingOverlay: false,
    moveLoading: false,
    patchLoading: false,
};

export const uiReducer = createReducer(
    initialUiState,
    on(showLoadingOverlay, (state) => ({
        ...state,
        loadingOverlay: true,
    })),
    on(hideLoadingOverlay, (state) => ({
        ...state,
        loadingOverlay: false,
    })),
    on(moveLoading, (state, { loading }) => ({
        ...state,
        moveLoading: loading,
    })),
    on(patchLoading, (state, { loading }) => ({
        ...state,
        patchLoading: loading,
        moveLoading: loading,
    }))
);

export const getUiState = createFeatureSelector<UiState>('ui');

export const getLoadingOverlay = createSelector(getUiState, (state) => state.loadingOverlay);
export const getMoveLoading = createSelector(getUiState, (state) => state.moveLoading);
export const getPatchLoading = createSelector(getUiState, (state) => state.patchLoading);
