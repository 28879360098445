import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/interfaces/order';

@Component({
    selector: 'app-user-details',
    template: `
        <ng-container *ngIf="order">
            <div>{{ order.formattedName || order.move.user.formattedName }}</div>
            <div>
                <a class="u-link" *ngIf="order.move.user.phoneNumber" href="tel:{{ order.move.user.phoneNumber }}">
                    {{ order.move.user.phoneNumber }}
                </a>
            </div>
            <app-copy-to-clipboard *ngIf="!!order.move.user.email">{{ order.move.user.email }}</app-copy-to-clipboard>
            <div *ngIf="!!order.move.user.gender">{{ order.move.user.gender.toString().toUpperCase() | translate }}</div>
            <app-user-language *ngIf="!!order.move.user.language" [language]="order.move.user.language"></app-user-language>
        </ng-container>
    `,
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent {
    @Input() public order: Order;
}
