import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveConversionSandbox } from '@app/move/sandboxes/move-conversion.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Observable, of } from 'rxjs';
import { exhaustMap, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class MoveDetailModalService {
    constructor(private readonly moveConversionSandbox: MoveConversionSandbox, private readonly moveSandbox: MoveSandbox) {}

    public activateMove(move: Move): Observable<Move> {
        const showConfirmBox = (activeMove?: Move): Observable<boolean> => {
            if (!activeMove) return of(true);
            return this.moveConversionSandbox.showActiveMoveModal(move, activeMove);
        };

        return this.moveSandbox.getActiveMoveByEmail(move.user?.email).pipe(
            exhaustMap(showConfirmBox),
            filter((confirmed) => confirmed),
            switchMap(() => this.moveSandbox.activateMove(move._id))
        );
    }
}
