import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@app/header/header.module';
import { UiModule } from '../ui/ui.module';
import { MainContainerTemplateComponent } from './main-container-template.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [MainContainerTemplateComponent],
    imports: [CommonModule, UiModule, HeaderModule, RouterModule, TranslateModule],
    exports: [MainContainerTemplateComponent],
})
export class MainContainerTemplateModule {}
