import { Component, OnInit } from '@angular/core';
import { MoveService } from '@app/move/services/move.service';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { BaseDashboardCardComponent } from '@smooved/ui';
import { allTodosQueryParams } from '../analytics-dashboard/analytics-dashboard.constants';

@Component({
    selector: 'app-dashboard',
    template: `
        <app-dashboard-card
            [first]="true"
            height="100%"
            class="u-h100p"
            [title]="'REAL_ESTATE_AGENT.START.DASHBOARD.TITLE' | translate"
            [text]="'REAL_ESTATE_AGENT.START.DASHBOARD.TEXT' | translate"
            [hasBorderBottom]="true"
            imageName="laptop.png"
            [last]="true"
        >
            <ng-container up-to-and-including-phone-landscape>
                <div class="u-flex-column u-flex-align-items-stretch">
                    <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-bottom-half' }"></ng-container>
                </div>
            </ng-container>
            <ng-container tablet-portrait-up>
                <div class="u-flex-row u-flex-align-items-center">
                    <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ class: 'u-margin-right' }"></ng-container>
                </div>
            </ng-container>
        </app-dashboard-card>

        <ng-template #actions let-class="class">
            <app-button theme="secondary" [class]="class" [routerLink]="dashboardRoute">{{
                'REAL_ESTATE_AGENT.START.DASHBOARD.LIST' | translate
            }}</app-button>
            <app-button
                *ngIf="urgentTodoCount"
                theme="secondary-outline"
                [badge]="urgentTodoCount"
                [routerLink]="dashboardRoute"
                [queryParams]="allTodosQueryParams"
                >{{ 'REAL_ESTATE_AGENT.START.DASHBOARD.TODO' | translate }}</app-button
            >
        </ng-template>
    `,
})
export class DashboardComponent extends BaseDashboardCardComponent implements OnInit {
    public readonly dashboardRoute = `/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentDashboard}`;
    public readonly allTodosQueryParams = allTodosQueryParams;

    public urgentTodoCount: number;

    constructor(public moveService: MoveService) {
        super();
    }

    public ngOnInit(): void {
        this.moveService.getRealEstateAgentUrgentTodoCount().subscribe((response) => {
            this.urgentTodoCount = response?.all;
        });
    }
}
