import { Injectable } from '@angular/core';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@app/move/state/move.utils';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { CreatedByFlowEnum, FeatureScope, FeatureScopesConfig, LeaverMeterCollectionScope, MoveTransactionType } from '@smooved/core';

@Injectable()
export class FeatureExecutionScopeUtils {
    public static getFeatureExecutionScope(move: Move, featureScopesConfig: FeatureScopesConfig): FeatureScope[] {
        const canExecute = (scope: FeatureScope): boolean => {
            return FeatureExecutionScopeUtils.canExecute(scope, move, featureScopesConfig);
        };
        const testScope = (scope: FeatureScope): FeatureScope => {
            if (canExecute(scope)) return scope;
        };

        return EnumUtils.values(FeatureScope)
            .map((featureScope: FeatureScope) => testScope(featureScope))
            .filter((x) => !!x);
    }

    public static canExecute(feature: FeatureScope | string, move: Move, featureConfig: FeatureScopesConfig): boolean {
        switch (feature) {
            case FeatureScope.RentalInspection:
                return this.canExecuteRentalInspectionModule(move);
            case FeatureScope.Gift:
                return this.canExecuteGiftModule(move);
            case FeatureScope.Eots:
                return this.canExecuteEotsModule(move);
            case FeatureScope.ExternalInfluencer:
                return this.canExecuteExternalInfluencerModule(move);
            case FeatureScope.LeaverMeterCollection:
                return this.canExecuteLeaverMetercollection(move, featureConfig);
            default:
                return false;
        }
    }

    private static canExecuteEotsModule(move: Move): boolean {
        if (!move) return false;

        const blockingCreatedByFlows = [CreatedByFlowEnum.Eots, CreatedByFlowEnum.EnergyOptimization, CreatedByFlowEnum.Vacancy];
        return (
            move.isDraft &&
            move.interested?.energy &&
            !move.moveStates?.energyOrderSentByRealEstateAgent &&
            !move.moveStates?.energyOrdered &&
            !move.energySelected &&
            !blockingCreatedByFlows.includes(move.createdByFlow as unknown as CreatedByFlowEnum)
        );
    }

    private static canExecuteRentalInspectionModule(move: Move): boolean {
        if (!move?.user) return false;
        return move.user.transfereeType === Transferee.TenantTransferee && !MoveUtils.getRentalInspection(move);
    }

    private static canExecuteGiftModule(move: Move): boolean {
        return !MoveUtils.getGifts(move)?.length;
    }

    private static canExecuteExternalInfluencerModule(_move: Move): boolean {
        return true;
    }

    private static canExecuteLeaverMetercollection(move: Move, featureConfig: FeatureScopesConfig): boolean {
        const moveTransactionType = MoveUtils.getMoveTransactionType(move);
        if (!moveTransactionType || featureConfig.leaverMeterCollectionScope === LeaverMeterCollectionScope.None) return false;
        return (
            featureConfig.leaverMeterCollectionScope === LeaverMeterCollectionScope.All ||
            (moveTransactionType == MoveTransactionType.Sale &&
                featureConfig.leaverMeterCollectionScope === LeaverMeterCollectionScope.OnlySales) ||
            (moveTransactionType == MoveTransactionType.Rental &&
                featureConfig.leaverMeterCollectionScope === LeaverMeterCollectionScope.OnlyRental)
        );
    }
}
