<div class="u-flex-column u-h100p">
    <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center u-margin-y-axis">
        <h4>{{ 'TRANSACTION.LABEL_PLURAL' | translate }}</h4>
        <a
            app-button
            appearance="link"
            [routerLink]="dashboardRoute"
            [queryParams]="groupQueryParams"
            [appendIcon]="true"
            icon="chevron_right"
            >{{ 'COMMON.DETAILS' | translate }}</a
        >
    </div>
    <app-context-card
        appTooltip
        [template]="tooltipContent"
        [border]="false"
        [context]="cardContext.Muted"
        class="u-h100p u-flex-column u-flex-justify-content-center"
    >
        <app-chart-container legendDirection="vertical">
            <app-pie-chart
                [width]="140"
                [height]="140"
                [data]="data"
                [spotValue]="total"
                [showTooltip]="false"
                [donutThick]="14"
            ></app-pie-chart>
            <app-chart-legend-item
                *ngFor="let item of legend"
                [color]="item.color"
                [label]="item.label"
                [sub]="item.sub"
            ></app-chart-legend-item>
        </app-chart-container>
    </app-context-card>
</div>

<ng-template #tooltipContent>
    <p class="u-font-size-default u-font-weight-normal u-w25vw">{{ 'ANALYTICS.DASHBOARD.TRANSACTIONS.TOOLTIP' | translate }}</p>
</ng-template>
