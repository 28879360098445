import { SmoovedApplicationSource } from '../enums';

export enum RealEstateGroupAppointmentEndpoint {
    Base = 'base',
    Id = 'id',
}

export const baseUri = 'real-estate-group-appointment';

export const uriMapFactory = (apiUri: string) =>
    new Map<SmoovedApplicationSource | RealEstateGroupAppointmentEndpoint, string>()
        .set(RealEstateGroupAppointmentEndpoint.Base, `${apiUri}/${baseUri}`)
        .set(RealEstateGroupAppointmentEndpoint.Id, `${apiUri}/${baseUri}/:id`)
        .set(SmoovedApplicationSource.CompanyPage, `${apiUri}/${baseUri}/company-page`)
        .set(SmoovedApplicationSource.ExternalInfluencer, `${apiUri}/${baseUri}/external-influencer`)
        .set(SmoovedApplicationSource.Match, `${apiUri}/${baseUri}/match`)
        .set(SmoovedApplicationSource.MapPage, `${apiUri}/${baseUri}/map-page`)
        .set(SmoovedApplicationSource.Upsell, `${apiUri}/${baseUri}/upsell`)
        .set(SmoovedApplicationSource.MatchHighIntent, `${apiUri}/${baseUri}/match-high-intent`)
        .set(SmoovedApplicationSource.DetailedEstimation, `${apiUri}/${baseUri}/detailed-estimation`)
        .set(SmoovedApplicationSource.Comparison, `${apiUri}/${baseUri}/comparison`);
