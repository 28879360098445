import { orderRentalInspectionModel } from '@shared/order/constants/models.constants';
import { OrderScope } from '@shared/order/enums/order-scope.enum';
import { DateUtils, forever, OrderState } from '@smooved/core';
import { TrackUtils } from '@smooved/ui';
import { Order } from '../interfaces/order';
import { OrderRentalInspection } from '../interfaces/order-rental-inspection';

export class OrderUtils {
    public static hasWarnings(order: Order): boolean {
        if (order.state === OrderState.Cancelled) return false;
        if (order.__t === orderRentalInspectionModel) return this.hasWarningsForRentalInspection(order as OrderRentalInspection);
        return false;
    }

    private static hasWarningsForRentalInspection(order: OrderRentalInspection): boolean {
        const now = DateUtils.tz().startOf('d');
        const deliveryDate = DateUtils.tz(order.deliveryDate);
        const planned = order.planned?.value;
        const plannedDate = DateUtils.tz(planned || forever);

        if (now.isSameOrAfter(deliveryDate) && !planned) return true;
        if (now.isSameOrAfter(plannedDate) && order.state !== OrderState.Closed) return true;
    }

    public static isCompleted(order: Order): boolean {
        if (order.__t === orderRentalInspectionModel) {
            const { metersCompleted, waterDocumentCompleted } = (order as OrderRentalInspection).track;
            return TrackUtils.checked(metersCompleted.state, waterDocumentCompleted.state);
        }
    }

    public static isOrderScopeRentalInspection(orderScope: string): boolean {
        if (!orderScope) return false;
        return orderScope.startsWith(OrderScope.RentalInspection);
    }

    public static isOrderScopePlant(orderScope: string): boolean {
        if (!orderScope) return false;
        return orderScope.startsWith(OrderScope.Plant);
    }

    public static isOrderScopeTelenet(orderScope: string): boolean {
        return orderScope === OrderScope.Telenet;
    }
}
