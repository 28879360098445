import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { stateOptions } from '@app/escalation/constants/escalation.constants';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { EscalationService } from '@app/escalation/services/escalation.service';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { UserGroups } from '@app/user/enums/user-groups.enum';
import { AuditUtils, DbUtils } from '@smooved/core';
import { ButtonAppearance, NotificationSandbox, UiContext } from '@smooved/ui';
import { Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { actionTypeOptions, LogActionsControlNames, LogActionsForm } from './log-action.constants';

@Component({
    selector: 'smvd-app-log-action',
    templateUrl: 'log-action.modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LogActionModal implements OnInit {
    @Input() public escalation: Escalation;

    public loading = false;

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly contactLogTypeOptions = actionTypeOptions;
    public readonly logActionsControlNames = LogActionsControlNames;
    public readonly stateOptions = stateOptions;
    public readonly mentionUserGroups = [UserGroups.Ops, UserGroups.Sales];

    public form: UntypedFormGroup;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly escalationService: EscalationService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly dialogRef: MatDialogRef<LogActionModal>
    ) {}

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            [LogActionsControlNames.Type]: [null, Validators.required],
            [LogActionsControlNames.Remark]: null,
            [LogActionsControlNames.State]: AuditUtils.getValue(this.escalation.state),
        });
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.loading = true;
        const { type, remark } = this.form.getRawValue() as LogActionsForm;
        this.escalationService
            .createAction(DbUtils.getStringId(this.escalation), { type, remark })
            .pipe(
                switchMap(() => this.updateEscalationState()),
                finalize(() => (this.loading = false))
            )
            .subscribe(() => {
                this.notificationSandbox.createdSuccess();
                this.dialogRef.close(true);
            });
    }

    private updateEscalationState(): Observable<void> {
        const formControl = this.form.get(LogActionsControlNames.State);
        if (formControl.pristine) return of<void>(null);
        return this.escalationService.updateEscalation(DbUtils.getStringId(this.escalation), {
            state: formControl.value as EscalationState,
        });
    }
}
