import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { MoveState } from '@app/move/enums/move-state.enum';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Move } from '@app/move/interfaces/move';
import { PaginationRequest, SortDirection } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { columns, columnsResponsive, defaultSortField } from './mortgage-table.constants';

@Component({
    selector: 'app-mortgage-table',
    templateUrl: 'mortgage-table.component.html',
    styleUrls: ['mortgage-table.component.scss'],
})
export class MortgageTableComponent extends CoreTableComponent implements OnInit {
    @Input() public dataSource;
    @Input() public count: number;
    @Output() public rowClick: EventEmitter<Move> = new EventEmitter<Move>();

    public columns: string[];
    public defaultSortField = defaultSortField;
    public defaultSortDirection = SortDirection.Asc;

    public moveState = MoveState;

    constructor(private readonly uiSandbox: AppUiSandbox) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.uiSandbox.tabletPortraitUp$.pipe(takeUntil(this.destroy$)).subscribe(this.setColumnsOnPageSizeChange);
    }

    public getPaginationOptions(): PaginationRequest {
        return {
            ...super.getPaginationOptions(),
            sortField: this.sort?.active || this.defaultSortField,
        };
    }

    private setColumnsOnPageSizeChange = (isTabletPortraitUp: boolean) => {
        this.columns = isTabletPortraitUp ? columns : columnsResponsive;
    };
}
