import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CardModule } from '@app/card/card.module';
import { DateOfBirthComponent } from '@app/move-wizard/components/date-of-birth/date-of-birth.component';
import { GoToContractComponent } from '@app/move-wizard/components/go-to-contract/go-to-contract.component';
import { InsurancesContractPdfComponent } from '@app/move-wizard/components/insurances-contract-pdf/insurances-contract-pdf.component';
import { InsurancesCoveredComponent } from '@app/move-wizard/components/insurances-covered/insurances-covered.component';
import { InsurancesFamilialComponent } from '@app/move-wizard/components/insurances-familial/insurances-familial.component';
import { InsurancesSuggestionMoreInfoComponent } from '@app/move-wizard/components/insurances-suggestion-more-info/insurances-suggestion-more-info.component';
import { InsurancesSuggestionReviewMoreInfoComponent } from '@app/move-wizard/components/insurances-suggestion-review-more-info/insurances-suggestion-review-more-info.component';
import { InsurancesSuggestionReviewComponent } from '@app/move-wizard/components/insurances-suggestion-review/insurances-suggestion-review.component';
import { InsurancesSuggestionComponent } from '@app/move-wizard/components/insurances-suggestion/insurances-suggestion.component';
import { InsurancesSuggestionsContentComponent } from '@app/move-wizard/components/insurances-suggestions-content/insurances-suggestions-content.component';
import { InsurancesSuggestionsLoadingComponent } from '@app/move-wizard/components/insurances-suggestions-loading/insurances-suggestions-loading.component';
import { InsurancesSuggestionsComponent } from '@app/move-wizard/components/insurances-suggestions/insurances-suggestions.component';
import { MoveNpsOutputComponent } from '@app/move-wizard/components/move-nps-output/move-nps-output.component';
import { TelecomFixedLineOutputComponent } from '@app/move-wizard/components/telecom-fixed-line-output/telecom-fixed-line-output.component';
import { TelecomInstallationDateOutputComponent } from '@app/move-wizard/components/telecom-installation-date-output/telecom-installation-date-output.component';
import { TelecomMobileBundleOutputComponent } from '@app/move-wizard/components/telecom-mobile-bundle-output/telecom-mobile-bundle-output.component';
import { TelecomTotalPriceOutputComponent } from '@app/move-wizard/components/telecom-total-price-output/telecom-total-price-output.component';
import { TransfereeTypeComponent } from '@app/move-wizard/components/transferee-type/transferee-type.component';
import { ReviewsModule } from '@app/reviews/reviews.module';
import { SharedModule } from '@app/shared/shared.module';
import { UiModule } from '@app/ui/ui.module';
import { AlertModule, ButtonModule, SvgModule } from '@smooved/ui';
import { CheckListComponent } from './components/check-list/check-list.component';
import { InsurancesProposalUrlComponent } from './components/insurances-proposal-url/insurances-proposal-url.component';
import { InsurancesSuggestionListComponent } from './components/insurances-suggestion-list/insurances-suggestion-list.component';
import { LateMovingDateRedNotificationComponent } from './components/late-moving-date-red-notification/late-moving-date-red-notification.component';
import { NoteForSmoovedComponent } from './components/note-for-smooved/note-for-smooved.component';

const components = [
    DateOfBirthComponent,
    InsurancesFamilialComponent,
    InsurancesSuggestionComponent,
    InsurancesSuggestionListComponent,
    InsurancesSuggestionsComponent,
    InsurancesSuggestionsLoadingComponent,
    InsurancesSuggestionsContentComponent,
    InsurancesProposalUrlComponent,
    InsurancesContractPdfComponent,
    CheckListComponent,
    InsurancesCoveredComponent,
    GoToContractComponent,
    TransfereeTypeComponent,
    TelecomInstallationDateOutputComponent,
    TelecomFixedLineOutputComponent,
    TelecomMobileBundleOutputComponent,
    TelecomTotalPriceOutputComponent,
    MoveNpsOutputComponent,
    NoteForSmoovedComponent,
    LateMovingDateRedNotificationComponent,
];

@NgModule({
    declarations: [
        ...components,
        InsurancesSuggestionReviewComponent,
        InsurancesSuggestionMoreInfoComponent,
        InsurancesSuggestionReviewMoreInfoComponent,
        CheckListComponent,
    ],
    imports: [SharedModule, CardModule, SvgModule, UiModule, OverlayModule, AlertModule, ButtonModule, ReviewsModule],
    exports: [...components],
})
export class MoveWizardModule {}
