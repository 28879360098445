import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@smooved/core';

@Component({
    selector: 'app-address-output',
    template: `
        <ng-container *ngIf="address">
            <p class="u-text-truncate">
                {{ address.street }} {{ address.houseNumber }}{{ address.busNumber ? ' (' + address.busNumber + ')' : '' }}<br />
                {{ address.zipCode }} {{ address.city }}{{ withCountry ? ', ' + address.country : '' }}
            </p>
        </ng-container>
    `,
    styleUrls: ['./address-output.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressOutputComponent {
    @Input() public address: Address;
    @Input() public withCountry = false;
}
