<header class="u-flex-row u-flex-align-items-center u-padding-bottom-double">
    <h6>{{ 'SMOOVED_ANALYTICS.DETAILS.INPUTS.TITLE' | translate }}</h6>
</header>
<app-loading-container [loading]="!loaded">
    <app-chart-container>
        <app-stacked-bar-chart
            [stacks]="stackNames"
            [data]="data"
            [hasTotals]="true"
            [percentage]="true"
            [colors]="colors"
            [width]="chartWidth"
        ></app-stacked-bar-chart>
        <app-chart-legend-item *ngFor="let item of legend" [color]="item.color" [label]="item.label"></app-chart-legend-item>
    </app-chart-container>
</app-loading-container>
