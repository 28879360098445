import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ElectricityGasPriceDetailsModalComponent } from '@app/wizard/energy/components/electricity-gas-price-details-modal/electricity-gas-price-details-modal.component';
import { ElectricityPriceDetailsModalComponent } from '@app/wizard/energy/components/electricity-price-details-modal/electricity-price-details-modal.component';
import { GasPriceDetailsModalComponent } from '@app/wizard/energy/components/gas-price-details-modal/gas-price-details-modal.component';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';
import { isNumber } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-energy-suggestion',
    template: `
        <app-card [padding]="true" [paddingDouble]="false" class="u-h100p __container">
            <app-ribbon *ngIf="reduction$(groupedSuggestion[termFormControl().value]) | async as reductionValue">
                <div [innerHTML]="'ENERGY_SUGGESTION.REDUCTION.RIBBON' | translate: { reduction: reductionValue | price }"></div>
            </app-ribbon>
            <div class="u-flex-column u-h100p">
                <div class="__image-container u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                    <img
                        *ngIf="groupedSuggestion[termFormControl().value]?.image as image"
                        [src]="'assets/images/suppliers/' + image"
                        width="120px"
                        [alt]="'Logo' + groupedSuggestion[termFormControl().value]?.supplier"
                        class="u-margin-bottom"
                    />
                </div>
                <h5>{{ groupedSuggestion[termFormControl().value]?.supplier }}</h5>
                <p class="u-margin-bottom">
                    {{
                        groupedSuggestion[termFormControl().value]?.nameLabels
                            | translatedLabel
                                : {
                                      fallback: {
                                          object: groupedSuggestion[termFormControl().value],
                                          label: 'name'
                                      }
                                  }
                            | async
                    }}
                </p>
                <div *ngIf="groupedSuggestion[termFormControl().value]?.uspLabels | translatedLabel | async as usp">
                    <div class="m-tag m-tag--success u-margin-bottom">{{ usp }}</div>
                </div>
                <div class="u-flex-column u-flex-1 u-margin-bottom">
                    <div class="u-display-table ">
                        <div class="u-display-table-row">
                            <div class="u-display-table-cell u-color-muted u-w40p u-text-align-right">
                                {{ 'MOVE.ENERGY.SUGGESTION.DURATION' | translate }}
                            </div>
                            <div class="u-display-table-cell u-padding-left" [ngSwitch]="groupedSuggestion.length > 1">
                                <form [formGroup]="termForm">
                                    <select *ngSwitchCase="true" [formControlName]="'term'">
                                        <option *ngFor="let option of groupedSuggestion; let index = index" [value]="index">
                                            {{ option.termInYears }} {{ 'MOVE.ENERGY.SUGGESTION.DURATION.YEAR' | translate }}
                                        </option>
                                    </select>
                                </form>
                                <span *ngSwitchCase="false"
                                    >{{ groupedSuggestion[termFormControl().value]?.termInYears }}
                                    {{ 'MOVE.ENERGY.SUGGESTION.DURATION.YEAR' | translate }}</span
                                >
                            </div>
                        </div>
                        <div class="u-display-table-row">
                            <div class="u-padding-bottom u-display-table-cell u-color-muted u-w40p u-text-align-right">
                                {{ 'MOVE.ENERGY.SUGGESTION.GREEN' | translate }}
                            </div>
                            <div class="u-padding-bottom u-padding-left u-display-table-cell">
                                {{
                                    groupedSuggestion[termFormControl().value]?.greenProduct?.toString()
                                        ? groupedSuggestion[termFormControl().value]?.greenProduct + '%'
                                        : ''
                                }}
                            </div>
                        </div>
                        <div class="u-display-table-row" *ngIf="showGasRow(groupedSuggestion[termFormControl().value])">
                            <div class="u-display-table-cell u-color-muted u-w40p u-text-align-right">
                                {{ 'MOVE.ENERGY.SUGGESTION.GAS' | translate }}
                            </div>
                            <div class="u-display-table-cell u-padding-left" [ngSwitch]="pricesPerMonth">
                                <span *ngSwitchCase="false">{{ groupedSuggestion[termFormControl().value]?.gasTotalPrice | price }}</span>
                                <span *ngSwitchCase="true">{{
                                    groupedSuggestion[termFormControl().value]?.gasTotalPrice / 12 | price
                                }}</span>
                            </div>
                        </div>
                        <div class="u-display-table-row" *ngIf="showElectricityRow(groupedSuggestion[termFormControl().value])">
                            <div class="u-padding-bottom u-display-table-cell u-color-muted u-w40p u-text-align-right">
                                {{ 'MOVE.ENERGY.SUGGESTION.ELECTRICITY' | translate }}
                            </div>
                            <div class="u-padding-bottom u-display-table-cell u-padding-left" [ngSwitch]="pricesPerMonth">
                                <span *ngSwitchCase="false">{{
                                    groupedSuggestion[termFormControl().value]?.electricityTotalPrice | price
                                }}</span>
                                <span *ngSwitchCase="true">{{
                                    groupedSuggestion[termFormControl().value]?.electricityTotalPrice / 12 | price
                                }}</span>
                            </div>
                        </div>
                        <div class="u-display-table-row">
                            <div class="u-padding-bottom u-display-table-cell u-color-muted u-w40p u-text-align-right">
                                {{ 'MOVE.SUGGESTION.TOTAL' | translate }}
                            </div>
                            <div class="u-padding-bottom u-display-table-cell u-padding-left">
                                <div class="u-flex-column" [ngSwitch]="pricesPerMonth">
                                    <span
                                        [ngClass]="{
                                            'u-color-muted u-text-line-through':
                                                !!groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions
                                        }"
                                    >
                                        <span *ngSwitchCase="false">{{
                                            groupedSuggestion[termFormControl().value]?.totalPrice | price
                                        }}</span>
                                        <span *ngSwitchCase="true">{{
                                            groupedSuggestion[termFormControl().value]?.totalPrice / 12 | price
                                        }}</span>
                                    </span>
                                    <h3
                                        *ngIf="groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions"
                                        class="u-color-accent"
                                    >
                                        <span *ngSwitchCase="false">{{
                                            groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions | price
                                        }}</span>
                                        <span *ngSwitchCase="true">{{
                                            groupedSuggestion[termFormControl().value]?.totalPriceInclusivePromotions / 12 | price
                                        }}</span>
                                    </h3>
                                    <span *ngSwitchCase="false" class="u-font-size-small u-color-muted"
                                        >{{ 'MOVE.ENERGY.SUGGESTION.PER_YEAR' | translate }} ({{
                                            (!!groupedSuggestion[termFormControl().value]?.professional
                                                ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL'
                                                : 'MOVE.ENERGY.SUGGESTION.VAT_INCL'
                                            ) | translate
                                        }}
                                        )</span
                                    >
                                    <span *ngSwitchCase="true" class="u-font-size-small u-color-muted"
                                        >{{ 'MOVE.ENERGY.SUGGESTION.PER_MONTH' | translate }} ({{
                                            (!!groupedSuggestion[termFormControl().value]?.professional
                                                ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL'
                                                : 'MOVE.ENERGY.SUGGESTION.VAT_INCL'
                                            ) | translate
                                        }}
                                        )</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="u-flex-column u-flex-align-items-center u-w100p">
                    <app-button
                        class="u-margin-bottom-half u-w100p"
                        theme="secondary"
                        (onClick)="onSelect(groupedSuggestion[termFormControl().value])"
                    >
                        {{ 'CHOOSE.LABEL' | translate }}
                    </app-button>
                    <div class="u-flex-row u-flex-align-items-center">
                        <a
                            class="u-link"
                            [ngClass]="{
                                'u-margin-right':
                                    !!groupedSuggestion[termFormControl().value]?.promoInfoElectricityLabels ||
                                    !!groupedSuggestion[termFormControl().value]?.promoInfoGasLabels
                            }"
                            (click)="showMoreInfo(groupedSuggestion[termFormControl().value])"
                            >{{ 'COMMON.MORE_INFO' | translate }}</a
                        >

                        <app-show-promo-info
                            [energySuggestion]="groupedSuggestion[termFormControl().value]"
                            *ngIf="
                                !!groupedSuggestion[termFormControl().value]?.promoInfoElectricityLabels ||
                                !!groupedSuggestion[termFormControl().value]?.promoInfoGasLabels
                            "
                        ></app-show-promo-info>
                    </div>
                </div>
            </div>
        </app-card>
    `,
    styleUrls: ['./energy-suggestion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnergySuggestionComponent),
            multi: true,
        },
    ],
})
export class EnergySuggestionComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    private sub: Subscription;

    @Input() public formControlName: string;
    @Input() public groupedSuggestion: EnergySuggestion[];
    @Input() public pricesPerMonth = false;

    public termForm: UntypedFormGroup = this.formBuilder.group({
        term: [0],
    });

    public innerModel: any;
    public currentLang: string = this.translateService.currentLang;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private moveSandbox: MoveSandbox,
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.sub = this.translateService.onLangChange.subscribe((langChange: LangChangeEvent) => {
            this.currentLang = langChange.lang;
            this.cdr.detectChanges();
        });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public reduction$(energySuggestion: EnergySuggestion): Observable<number> {
        return this.moveSandbox.monthlyEnergyAdvanceAmount$.pipe(
            map((monthlyEnergyAdvanceAmount) => {
                if (isNumber(monthlyEnergyAdvanceAmount)) {
                    const monthlyCost = (energySuggestion?.totalPriceInclusivePromotions || energySuggestion?.totalPrice || 0) / 12;
                    return Math.max(monthlyEnergyAdvanceAmount - monthlyCost, 0);
                }
                return 0;
            })
        );
    }

    public showGasRow(energySuggestion: EnergySuggestion): boolean {
        return !!energySuggestion && (energySuggestion.type === EnergyType.Gas || energySuggestion.type === EnergyType.Both);
    }

    public showElectricityRow(energySuggestion: EnergySuggestion): boolean {
        return !!energySuggestion && (energySuggestion.type === EnergyType.Electricity || energySuggestion.type === EnergyType.Both);
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onSelect(energySuggestion: EnergySuggestion): void {
        this.propagateChange(energySuggestion);
    }

    public showMoreInfo(energySuggestion: EnergySuggestion): void {
        if (!energySuggestion) {
            return;
        }
        let componentToOpen;

        switch (energySuggestion.type) {
            case EnergyType.Electricity:
                componentToOpen = ElectricityPriceDetailsModalComponent;
                break;
            case EnergyType.Gas:
                componentToOpen = GasPriceDetailsModalComponent;
                break;
            case EnergyType.Both:
                componentToOpen = ElectricityGasPriceDetailsModalComponent;
                break;
            default:
        }
        this.dialog.open(componentToOpen, {
            data: energySuggestion,
        });
    }

    public termFormControl(): AbstractControl {
        return this.termForm.get('term');
    }
}
