import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RxjsComponent } from '@smooved/core';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-all-contract-container-component',
    template: `
        <app-contract-container-component>
            <div class="u-flex-column u-flex-align-items-start">
                <h6 class="u-color-muted u-margin-bottom ">{{ 'MOVE.ENERGY.SUGGESTION.SELECTION.TITLE' | translate }}</h6>
                <div class="u-flex-column u-flex-align-items-start">
                    <app-suggestion-selection
                        *ngFor="let uiOffer of moveSandbox.shoppingCart$ | async; let last = last"
                        [ngClass]="{ 'u-margin-bottom': !last }"
                        [type]="uiOffer.type"
                        [minimalWithoutPrice]="!showExtraInfo"
                        [showExtraInfoInsurances]="true"
                        [suggestion]="uiOffer?.suggestion"
                        [extraInfoEnergy]="showExtraInfo"
                    ></app-suggestion-selection>
                </div>
            </div>
        </app-contract-container-component>
    `,
    styleUrls: ['./all-contract-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllContractContainerComponent extends RxjsComponent {
    public showExtraInfo: boolean;

    constructor(public moveSandbox: MoveSandbox) {
        super();

        moveSandbox.move$
            .pipe(
                map((move) => !!move.energyOffer?.energyType && !!move.meta?.homeDescription && !!move.energyOffer?.energyOfferSelection),
                takeUntil(this.destroy$)
            )
            .subscribe((showExtraInfo) => (this.showExtraInfo = showExtraInfo));
    }
}
