<app-ui-card [border]="false" class="u-padding-md-tablet-portrait-up">
    <h3 class="u-margin-bottom-md u-font-size-default">
        {{ 'TITLE' | i18nKeyTranslate : companyPageI18nKeyTypes.CtaBox : { name: externalInfluencer?.name } }}
    </h3>
    <button
        app-button
        [context]="uiContext.Primary"
        class="u-w100p u-margin-bottom-md u-flex-row u-flex-align-items-center u-flex-justify-content-center"
        (click)="initAppointment()"
    >
        <span class="u-font-size-18">{{ 'MAKE_AN_APPOINTMENT' | i18nKeyTranslate : companyPageI18nKeyTypes.CtaBox }}</span>
    </button>
    <p>{{ 'DIRECT_CONTACT' | i18nKeyTranslate : companyPageI18nKeyTypes.CtaBox }}</p>
    <div class="u-margin-top">
        <a (click)="initCallback()" app-button [appearance]="buttonAppearance.Link" class="u-padding-none u-h-auto" icon="phone">
            {{ 'CALLBACK' | i18nKeyTranslate : companyPageI18nKeyTypes.CtaBox }}
        </a>
        <a (click)="initMessage()" app-button [appearance]="buttonAppearance.Link" icon="email" class="u-padding-none u-h-auto">
            {{ 'MESSAGE' | i18nKeyTranslate : companyPageI18nKeyTypes.CtaBox }}
        </a>
    </div>
</app-ui-card>
