import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationData } from '@smooved/core';
import { ConfirmEnergyEotsSuccessContainer } from './containers/confirm-energy-eots-success/confirm-energy-eots-success.container';
import { ConfirmEnergySuccessContainer } from './containers/confirm-energy-success/confirm-energy-success.container';
import { ConfirmEnergyContainer } from './containers/confirm-energy/confirm-energy.container';
import { ConfirmContainer } from './containers/confirm/confirm.container';

const routes: Routes = [
    {
        path: 'confirm/:id',
        component: ConfirmContainer,
    },
    {
        path: 'confirm-energy',
        data: {
            [NavigationData.CoBranding]: true,
        },
        children: [
            {
                path: '',
                component: ConfirmEnergyContainer,
            },
            {
                path: 'success',
                component: ConfirmEnergySuccessContainer,
            },
            {
                path: 'energy-success',
                component: ConfirmEnergyEotsSuccessContainer,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MoveRoutingModule {}
