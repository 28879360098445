import { Component, Input } from '@angular/core';
import { SelectLanguageModal } from '@app/admin/modals/select-language/select-language.modal';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergyService } from '@app/wizard/energy/services/energy.service';
import { Move } from '@app/move/interfaces/move';
import { Language } from '@smooved/core';
import { ModalSandbox, NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-energy-open-booking-url',
    template: ` <app-button (onClick)="onOrder()" theme="secondary">{{ 'ORDER' | translate }}</app-button> `,
    styles: [],
})
export class EnergyOpenBookingUrlComponent {
    @Input() public move: Move;

    constructor(
        private modalSandbox: ModalSandbox,
        private energyService: EnergyService,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox
    ) {}

    /**
     * Go to web form of supplier
     */
    public onOrder(): void {
        const { language } = this.move?.user;

        if (language !== Language.NL && language !== Language.FR) {
            this.modalSandbox
                .openInfoModal(SelectLanguageModal)
                .afterClosed()
                .subscribe((lang) => {
                    if (!lang) return;
                    this.openBooking(lang);
                });
            return;
        }

        this.openBooking(language);
    }

    public openBooking(language: Language): void {
        this.uiSandbox.showLoadingOverlay();
        this.energyService
            .getBookingUrl({
                language,
                id: this.move?._id,
            })
            .subscribe(
                (response) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (response) {
                        window.open(response.redirectUrl);
                    } else {
                        this.notificationSandbox.error('ADMIN.DASHBOARD.DETAIL.ENERGY.BOOKING_URL_NOT_FOUND');
                    }
                },
                (_) => {
                    this.uiSandbox.hideLoadingOverlay();
                }
            );
    }
}
