import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveData } from '@app/move/classes/move-data.class';
import { ContactLogType } from '@app/move/enums/contact-log-type.enum';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { MoveEditModalComponent } from '@app/real-estate-agent/modals/move-edit/move-edit.modal';
import { SurveysSandbox } from '@app/surveys/sandboxes/surveys.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ObjectUtils, Role as AuthRole } from '@smooved/core';
import { ClosableModalTemplateComponent, ModalSandbox, TextModalComponent } from '@smooved/ui';
import { combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.modal.html',
})
export class DetailModal extends MoveData implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: false })
    public closableModalComponent: ClosableModalTemplateComponent;

    @Input() showNpsSection = true;
    @Input() showLogsSection = true;

    public shareNps = false;
    public canAddNps = false;
    public canRequestNps = false;
    public canEdit = false;

    private validateCanEdit = tap(([isAdmin, move]: [boolean, Move]): void => {
        this.canEdit = this.testCanEdit(isAdmin, move);
    });

    private validateCanAddNps = tap(([isAdmin, move]: [boolean, Move]): void => {
        this.canAddNps = this.testCanAddNps(isAdmin, move);
    });
    private validateCanRequestNps = tap(([isAdmin, move]: [boolean, Move]): void => {
        this.canRequestNps = this.testCanRequestNps(isAdmin, move);
    });

    public init$ = combineLatest([this.authSandbox.isAdmin$, this.move$]).pipe(
        this.validateCanEdit,
        this.validateCanAddNps,
        this.validateCanRequestNps
    );

    constructor(
        protected moveSandbox: MoveSandbox,
        public authSandbox: AuthenticationSandbox,
        public uiSandbox: AppUiSandbox,
        private dialogRef: MatDialogRef<any>,
        private router: Router,
        private modalSandbox: ModalSandbox,
        private translate: TranslateService,
        private surveysSandbox: SurveysSandbox,
        @Inject(MAT_DIALOG_DATA) public data: ModalData
    ) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit(): void {
        this.init$.pipe(takeUntil(this.destroy$)).subscribe();
        this.moveSandbox.updatedMoveTrigger
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.data.patchedSubject?.next(true);
                this.reloadMove();
            });
        this.reloadMove();
    }

    public openEdit(): void {
        this.modalSandbox.openModal(null, null, null, MoveEditModalComponent, { data: this.data.id }, (reFetch) => {
            if (reFetch) {
                this.data.patchedSubject?.next(true);
                this.reloadMove();
            }
        });
    }

    private reloadMove(): void {
        this.fetch(this.data.id, this.onMoveFetched);
    }

    private onMoveFetched = (move: Move): void => {
        this.shareNps = !move.surveys?.nps?.answers?.isAnonymous?.value;
    };

    public addNps(): void {
        this.dialogRef.close();
        this.moveOnce$.subscribe((move) => {
            this.router.navigate([`${Navigation.Surveys}/interviews/${move?._id}/start`]);
        });
    }

    public requestNps(move: Move): void {
        const data = {
            data: this.translate.instant('SURVEYS.SEND_REQUEST_EMAIL.CONFIRMATION'),
        };

        this.modalSandbox.openConfirmModal(
            data,
            this.handleConfirmationSendRequestSurveyEmail(move),
            data,
            this.handleConfirmationSendRequestSurveyEmail(move)
        );
    }

    public showAdditionalNotes(notes: string): void {
        this.modalSandbox.openInfoModal(TextModalComponent, notes);
    }

    private testCanEdit(isAdmin: boolean, move: Move): boolean {
        if (!move) return false;
        return !!isAdmin || (!move.energyOrderedByAdmin && !move.telecomOrderedByAdmin);
    }

    private testCanAddNps(isAdmin: boolean, move: Move): boolean {
        if (!isAdmin) return false;
        if (!move) return false;
        if (!move.realEstateGroup?.services?.nps) return false;
        if (move.createdByRole === AuthRole.Guest) return false;
        if (move.user.role === MoverRole.Leaver && move.leaverType === Leaver.TenantLeaver) return false;
        return ObjectUtils.isEmpty(move.surveys?.nps?.answers);
    }

    private testCanRequestNps(isAdmin: boolean, move: Move): boolean {
        if (!this.testCanAddNps(isAdmin, move)) return false;
        if (
            (move.user.role === MoverRole.Leaver && (!move.leaverType || move.leaverType === Leaver.LandAgent)) ||
            (move.user.role === MoverRole.Transferee && (!move.user.transfereeType || move.user.transfereeType === Transferee.LandAgent))
        )
            return false;

        return !move.contactLogs?.some((log) => log.value === ContactLogType.EmailSurveyRequest);
    }

    private handleConfirmationSendRequestSurveyEmail(move: Move): (_: boolean) => void {
        return (confirmation: boolean): void => {
            if (confirmation)
                this.surveysSandbox.sendSurveyRequestEmail(move).subscribe((updatedMove) => {
                    if (!updatedMove) return;
                    this.updateMove(updatedMove);
                });
        };
    }
}
