import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { createEscalationOptions, stateOptions } from '@app/escalation/constants/escalation.constants';
import { EscalationState } from '@app/escalation/enums/state.enum';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { EscalationService } from '@app/escalation/services/escalation.service';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { ArrayUtils, AuditUtils, DbUtils, ObjectUtils, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, DropdownInput, NotificationSandbox, UiContext } from '@smooved/ui';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { contactLogTypeOptions, CreateContactLogForm, FormControlNames } from './create-contact-log.constants';

@Component({
    selector: 'smvd-app-create-contact-log',
    templateUrl: 'create-contact-log.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateContactLogModal extends RxjsComponent implements OnInit {
    @Input() public move: Move;

    public loading = false;
    public escalationOptions: DropdownInput<string>[] = [];

    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly contactLogTypeOptions = contactLogTypeOptions;
    public readonly stateOptions = stateOptions;
    public readonly formControlNames = FormControlNames;
    public readonly form = this.formBuilder.group({
        [FormControlNames.Value]: [null, Validators.required],
        [FormControlNames.Remark]: null,
        [FormControlNames.Escalation]: null,
        [FormControlNames.State]: null,
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly escalationService: EscalationService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly dialogRef: MatDialogRef<CreateContactLogModal>,
        private readonly moveSandbox: MoveSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.setEscalationOptions();
        this.form.get(FormControlNames.Escalation).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onSelectEscalation);
    }

    private setEscalationOptions(): void {
        ArrayUtils.isEmpty(this.move.escalations)
            ? this.form.get(FormControlNames.Escalation).disable()
            : (this.escalationOptions = createEscalationOptions(this.move.escalations as Escalation[]));
    }

    public close(): void {
        this.dialogRef.close();
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.loading = true;
        const { value, remark, escalation: escalationId, state } = this.form.getRawValue() as CreateContactLogForm;
        const payload = {
            value,
            remark,
            escalationId,
        };
        ObjectUtils.removeEmpty(payload);
        this.moveSandbox
            .createContactLog(DbUtils.getStringId(this.move), payload)
            .pipe(
                switchMap((response) =>
                    this.updateEscalationState(response, payload.escalationId, this.form.get(FormControlNames.State).dirty ? state : null)
                ),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(() => {
                this.notificationSandbox.createdSuccess();
                this.dialogRef.close();
                this.moveSandbox.updatedMoveTrigger.next();
            });
    }

    private updateEscalationState(response: Move, escalationId?: string, state?: EscalationState): Observable<Move> {
        if (!state || !escalationId) return of(response);
        return this.escalationService.updateEscalation(escalationId, { state }).pipe(map(() => response));
    }

    private onSelectEscalation = (escalationId: string): void => {
        const escalation = this.move.escalations.find((escalation) => DbUtils.getStringId(escalation) === escalationId) as Escalation;
        this.form.get(FormControlNames.State).patchValue(AuditUtils.getValue(escalation.state));
    };
}
