<!-- context === text show red notification message -->
<app-alert *ngIf="showRedNotification && context === uiContext.Text" [context]="context" class="u-margin-top-half">
    <app-icon [icon]="uiIcon.Warning" [appearance]="iconAppearance.Round" [size]="uiIconSize.Md"></app-icon>
    {{ 'MOVE.USER.MOVING_DATE_TOO_LATE.MODAL.ALERT.LABEL' | translate: { days: maxPastDaysBeforeMovingDate } }}
    <button
        smvd-ui-button
        [context]="uiContext.Text"
        [appearance]="buttonAppearance.Link"
        class="u-padding-none u-font-weight-semi-bold u-color-danger-dark u-h-auto"
        (click)="openReadMoreModal()"
    >
        {{ 'MOVE.USER.MOVING_DATE_TOO_LATE.MODAL.ALERT.LINK' | translate }}
    </button>
</app-alert>

<!-- context === danger show red notification box -->
<app-alert *ngIf="showRedNotification && context === uiContext.Danger" [context]="context">
    <p>{{ 'MOVE.USER.MOVING_DATE_TOO_LATE.ALERT.LABEL' | translate: { days: maxPastDaysBeforeMovingDate } }}</p>
    <a
        smvd-ui-button
        [href]="'MOVE.USER.MOVING_DATE_TOO_LATE.ARTICLE.LINK_URL' | translate"
        [context]="uiContext.Text"
        [appearance]="buttonAppearance.Link"
        class="u-padding-none u-margin-top-half u-color-text u-h-auto"
        target="_blank"
        >{{ 'MOVE.USER.MOVING_DATE_TOO_LATE.ALERT.LINK' | translate }}</a
    >
</app-alert>
