import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }

    parse(value: any): Date | null {
        if (typeof value === 'string') {
            let stringArray: string[];

            if (value.indexOf('/') > -1) {
                stringArray = value.split('/');
            } else if (value.indexOf('-') > -1) {
                stringArray = value.split('-');
            } else {
                const timestamp = typeof value === 'number' ? value : Date.parse(value);
                return isNaN(timestamp) ? null : new Date(timestamp);
            }

            const year = Number(stringArray[2]);
            const month = Number(stringArray[1]) - 1;
            const date = Number(stringArray[0]);
            return new Date(year, month, date);
        }

        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: string): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${this.to2digit(day)}/${this.to2digit(month)}/${year}`;
        } else if (displayFormat === 'inputMonth') {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${this.to2digit(month)}/${year}`;
        } else {
            return date.toDateString();
        }
    }

    private to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}
