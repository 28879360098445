import { TranslationActions, TranslationActionTypes } from '@app/translation/state/translation.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Language } from '@smooved/core';

export interface TranslationState {
    selected: Language;
}

export const initialState: TranslationState = {
    selected: null,
};

export function translationReducer(state = initialState, action: TranslationActions): TranslationState {
    switch (action.type) {
        case TranslationActionTypes.SetLanguageSuccess:
            return {
                ...state,
                selected: action.payload.language,
            };

        default:
            return state;
    }
}

export const getTranslationState = createFeatureSelector<TranslationState>('translation');

export const getSelectedState = createSelector(getTranslationState, (translationState) => translationState?.selected);
