import { Component, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Move } from '@app/move/interfaces/move';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';

@Component({
    selector: 'app-active-move-modal',
    template: `
        <app-closable-modal-template>
            <p modal-header>{{ 'ADMIN.DASHBOARD.DETAIL.REACTIVATE' | translate }}</p>
            <p class="u-padding-bottom-half">
                {{ 'MOVE.ACTIVE_MOVE.REPLACE.TEXT.1' | translate: { email: data.activeMove.user?.email } }}
            </p>
            <p class="u-padding-bottom-double">
                {{ 'MOVE.USER.MOVING_DATE.LABEL' | translate }}:
                <span class="u-font-weight-bold u-font-style-italic">{{ activeMovingDate() | formatDate }}</span>
                <ng-container *ngIf="!!data.activeMove.user?.movingAddress || !!data.activeMove.user?.currentAddress">
                    <br />
                    {{ 'ENERGY_OPTIMIZATION.MOVE_DETAILS.ADDRESS.LABEL' | translate }}:
                    <span class="u-font-weight-bold u-font-style-italic">{{
                        data.activeMove.user?.formattedMovingAddress || (data.activeMove.user?.currentAddress | formatAddress)
                    }}</span>
                </ng-container>
            </p>
            <p class="u-padding-bottom-half">{{ 'MOVE.ACTIVE_MOVE.REPLACE.TEXT.2' | translate }}</p>
            <p>
                {{ 'MOVE.USER.MOVING_DATE' | translate }}:
                <span class="u-font-weight-bold u-font-style-italic">{{ data?.currentMove?.movingDate | formatDate }}</span> <br />{{
                    (data.role === leaver ? 'MOVE.USER.LEAVING_ADDRESS' : 'MOVE.USER.MOVING_ADDRESS') | translate
                }}: <span class="u-font-weight-bold u-font-style-italic">{{ data.currentMove.user?.movingAddress | formatAddress }}</span>
            </p>
            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-padding-top">
                <a (click)="dialogRef.close()" class="u-link u-color-muted u-margin-right">{{ 'CANCEL' | translate }}</a>
                <app-button (click)="dialogRef.close(true)">{{ 'SUBMIT' | translate }}</app-button>
            </div>
        </app-closable-modal-template>
    `,
})
@Injectable()
export class ActiveMoveModal {
    public leaver = MoverRole.Leaver;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: { activeMove: Move; currentMove: Move; role: MoverRole }
    ) {}

    public activeMovingDate(): Date {
        return this.data.activeMove.movingDate || (this.data.activeMove.linkedMove as Move)?.movingDate;
    }
}
