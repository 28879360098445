import { Pipe, PipeTransform } from '@angular/core';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { ArrayUtils } from '@smooved/core';

const filterFunction = (result: TopicAnalysis) => {
    return !result?.sentimentPositive && !result?.sentimentNegative;
};

@Pipe({
    name: 'topicAnalysisNoResults',
})
export class TopicAnalysisNoResultsPipe implements PipeTransform {
    transform(results: TopicAnalysis[]): TopicAnalysis[] {
        if (ArrayUtils.isEmpty(results)) return [];
        return results.filter(filterFunction);
    }
}
