import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { isImage } from '@app/form/utils/is-image.util';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { dateTimeFormatDefault } from '@smooved/core';
import { ConfirmModalComponent, ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-water-meter-reading-asset-modal',
    template: `
        <app-closable-modal-template>
            <header class="u-flex-row u-flex-align-items-center u-margin-bottom">
                <div class="__header-content u-flex-column u-margin-right">
                    <h6>{{ data.file.name }}</h6>
                    <p class="u-color-muted">{{ data.file.createdOn | formatDate: dateTimeFormat }}</p>
                </div>
                <app-button *ngIf="!data.readOnly" (onClick)="showRemoveModal()">{{ 'COMMON.DELETE' | translate }}</app-button>
            </header>

            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center" [ngSwitch]="isImage()">
                <img *ngSwitchCase="true" [src]="fileData$ | async" class="u-mw100p" />
                <a
                    class="m-button"
                    [class.u-disabled]="!loaded"
                    *ngSwitchCase="false"
                    [download]="data.file.name"
                    [href]="fileData$ | async"
                    download
                    >{{ 'DOWNLOAD_PREVIEW' | translate }}</a
                >
            </div>
        </app-closable-modal-template>
    `,
})
export class WaterMeterReadingAssetDetailComponent {
    public fileData$ = this.moveSandbox.getAsset(this.data.file).pipe(tap((url) => (this.loaded = !!url)));
    public loaded = false;
    public dateTimeFormat = dateTimeFormatDefault;

    constructor(
        private moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private translate: TranslateService,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox,
        private dialogRef: MatDialogRef<any>,
        public sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: FileModalData
    ) {}

    public isImage(): boolean {
        return isImage(this.data.file);
    }

    public showRemoveModal(): void {
        this.modalSandbox
            .openInfoModal(
                ConfirmModalComponent,
                this.translate.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER.DOCUMENT.CONFIRM_DELETE')
            )
            .afterClosed()
            .subscribe((response) => this.remove(response));
    }

    private remove(confirm?: boolean): void {
        if (!confirm) return;
        this.uiSandbox.showLoadingOverlay();
        this.moveSandbox
            .deleteWaterMeterReadingAsset(this.data.moveId, this.data.file.key)
            .pipe(tap(() => this.uiSandbox.hideLoadingOverlay()))
            .subscribe((move) => {
                this.notificationSandbox.success(NotificationLabel.MoveMeterPictureDeleteSuccess);
                this.dialogRef.close(move);
            });
    }
}
