import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNumber as isNumberLodash } from 'lodash';
import { EnergySuggestion } from '../../interfaces/energy-suggestion';

@Component({
    selector: 'app-gas-price-details-modal',
    template: `
        <app-closable-modal-template>
            <h2 class="u-margin-bottom">{{ 'MOVE.ENERGY.SUGGESTION.GAS_PRICE.DETAILS.TITLE' | translate }}</h2>
            <p class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_FIXED_FEE' | translate }} {{ data.gasPricesDetails.fixedFee | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.gas)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS.USAGE' | translate }} {{ data.gasPricesDetails.gas | kwh }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.gasCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PRICE' | translate }} {{ data.gasPricesDetails.gasCost | pricePerKwh }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.distribution)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_DISTRIBUTION_PRICE' | translate }} {{ data.gasPricesDetails.distribution | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.transmission)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_TRANSMISSION_PRICE' | translate }} {{ data.gasPricesDetails.transmission | price }}
            </p>
            <p *ngIf="isNumber(data.gasPricesDetails.charges)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_CHARGES_PRICE' | translate }} {{ data.gasPricesDetails.charges | price }}
            </p>
            <p *ngIf="isNumber(data.promoConsumption)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_CONSUMPTION' | translate }} {{ data.promoConsumption }}
            </p>
            <p *ngIf="isNumber(data.promoDiscountFixed)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_DISCOUNT_FIXED' | translate }} {{ data.promoDiscountFixed | price }}
            </p>
            <p *ngIf="isNumber(data.promoDiscountVariableCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_DISCOUNT_VARIABLE_COST' | translate }}
                {{ data.promoDiscountVariableCost | price }}
            </p>
            <p *ngIf="isNumber(data.promoPercentageVariableCost)" class="u-margin-bottom">
                {{ 'MOVE.ENERGY.SUGGESTION.DETAILS.GAS_PROMO_PERCENTAGE_VARIABLE_COST' | translate }}
                {{ data.promoPercentageVariableCost | percent }}
            </p>
            <a *ngIf="data.gasTariffCard" [href]="data.gasTariffCard" download target="_blank" class="u-link">{{
                'MOVE.ENERGY.GAS_RATE_CARD' | translate
            }}</a>
        </app-closable-modal-template>
    `,
    styleUrls: ['./gas-price-details-modal.component.scss'],
})
export class GasPriceDetailsModalComponent {
    public isNumber = isNumberLodash;

    constructor(@Inject(MAT_DIALOG_DATA) public data: EnergySuggestion) {}
}
