import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoteModalComponent } from '@app/modal/components/note-modal/note-modal.component';
import { NoteModalData, NoteModalDataOutput } from '@app/modal/components/note-modal/note-modal.interfaces';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { ReviewsSandbox } from '@app/reviews/sandboxes/reviews.sandbox';
import { AccessLevel, DbUtils, DomService, Note, NpsReview } from '@smooved/core';
import {
    AuthenticatedNpsReviewsTemplateService,
    ModalSandbox,
    NpsReviewsFilterService,
    PaginationService,
    ReviewsDataSource,
} from '@smooved/ui';

@Injectable()
export class AppNpsReviewsTemplateService extends AuthenticatedNpsReviewsTemplateService {
    constructor(
        readonly filterService: NpsReviewsFilterService,
        readonly paginationService: PaginationService,
        readonly domService: DomService,
        private readonly route: ActivatedRoute,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        public readonly dataSource: ReviewsDataSource,
        private readonly modalSandbox: ModalSandbox,
        private readonly reviewsSandbox: ReviewsSandbox
    ) {
        super(filterService, paginationService, domService, dataSource);
        this.handleFilterParams();
    }

    public addInternalNote(reviewId: string, note: string): void {
        this.reviewsSandbox.addOrUpdateNote(reviewId, note, AccessLevel.Internal).pipe(this.handleReload).subscribe();
    }

    public addPublicNote(reviewId: string, note: string): void {
        this.reviewsSandbox.addOrUpdateNote(reviewId, note, AccessLevel.Public).pipe(this.handleReload).subscribe();
    }

    public editNote(reviewId: string, noteId, note: string, isPublic: boolean): void {
        const accessLevel = isPublic ? AccessLevel.Public : AccessLevel.Internal;
        this.reviewsSandbox.addOrUpdateNote(reviewId, note, accessLevel, noteId).pipe(this.handleReload).subscribe();
    }

    public deleteNote(reviewId: string, note: string): void {
        this.reviewsSandbox.deleteNote(reviewId, note).pipe(this.handleReload).subscribe();
    }

    public resetUnreadCount(): void {
        this.realEstateAgentSandbox.resetUnreadReviewsCount();
    }

    public onEdit(review: NpsReview, note: Note): void {
        this.openCreateNote(review, note.access === AccessLevel.Public, note);
    }

    public openCreateNote(review: NpsReview, isPublic: boolean, note?: Note): void {
        const data: NoteModalData = {
            isPublic,
            note,
        };

        this.modalSandbox.openModal(
            NoteModalComponent,
            { data },
            this.handleNote(DbUtils.getStringId(review)),
            NoteModalComponent,
            { data },
            this.handleNote(DbUtils.getStringId(review))
        );
    }

    private handleNote(reviewId: string) {
        return ({ noteId, noteValue, isPublic, isDelete }: NoteModalDataOutput): void => {
            if (!noteValue) return;
            if (isDelete && noteId) {
                return this.deleteNote(reviewId, noteId);
            }
            if (noteId) {
                return this.editNote(reviewId, noteId, noteValue, isPublic);
            }
            isPublic ? this.addPublicNote(reviewId, noteValue) : this.addInternalNote(reviewId, noteValue);
        };
    }

    private handleFilterParams(): void {
        const { unanswered } = this.route.snapshot.queryParams;
        if (unanswered) this.filterService.filterByUnanswered(!!unanswered);
    }
}
