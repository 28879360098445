import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MaskSandbox {
    public idCardNumber = '000-0000000-00';
    public accountNumber = '00.0000.0000.0000';
    public nationalNumber = '00.00.00-000.00';
}
