import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { redoableContactLogs } from '@app/move/constants/contact-log.constants';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { Move } from '@app/move/interfaces/move';
import { MoveModalSandbox } from '@app/move/sandboxes/move-modal.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveService } from '@app/move/services/move.service';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils } from '@smooved/core';
import {
    ButtonAppearance,
    deleteConfirmation,
    ModalSandbox,
    NotificationSandbox,
    redoContactLogConfirmation,
    Svg,
    UiContext,
    UiIconAppearance,
} from '@smooved/ui';

@Component({
    selector: 'app-check-item-contact-log',
    template: `
        <div [ngSwitch]="checked" class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !checked }">
            <div
                [ngClass]="{ 'u-margin-right': !!remarkable || !!removable || canRedo() || !!contactLog.content }"
                class="u-flex-row u-flex-align-items-center"
            >
                <ng-container *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <ng-content select="checked"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <app-svg [name]="svg.Empty" class="u-margin-right-half" width="24" height="24"></app-svg>
                    <ng-content select="unchecked"></ng-content>
                </ng-container>
            </div>

            <button
                app-button
                [appearance]="buttonAppearance.Icon"
                [iconAppearance]="iconAppearance.Filled"
                *ngIf="remarkable"
                icon="edit"
                class="u-color-text u-margin-left-none"
                (click)="doShowRemark()"
            ></button>

            <button
                app-button
                [appearance]="buttonAppearance.Icon"
                [iconAppearance]="iconAppearance.Filled"
                *ngIf="removable"
                icon="delete"
                class="u-color-text u-margin-left-none"
                (click)="onRemove()"
            ></button>

            <button
                *ngIf="contactLog.content"
                app-button
                [appearance]="buttonAppearance.Icon"
                [context]="uiContext.Secondary"
                icon="info"
                class="u-margin-left-none"
                (click)="showInfo()"
            ></button>

            <button
                *ngIf="canRedo()"
                app-button
                icon="forward_to_inbox"
                class="u-margin-left-none"
                [context]="uiContext.Secondary"
                [appearance]="buttonAppearance.Icon"
                (click)="redo()"
            ></button>
        </div>

        <ng-content select="cta"></ng-content>

        <form
            *ngIf="showRemark"
            class="u-margin-top-half u-flex-column u-margin-bottom"
            [formGroup]="remarkForm"
            (ngSubmit)="submitRemark()"
        >
            <app-textarea-input formControlName="remark"></app-textarea-input>
            <app-previous-submit
                [showCancel]="true"
                [showPrevious]="false"
                submitTranslationLabel="SAVE"
                (cancel)="cancelRemark()"
            ></app-previous-submit>
        </form>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemContactLogComponent {
    @Input() public checked = false;
    @Input() public remarkable = false;
    @Input() public removable = false;
    @Input() public info: string;

    @Input() public move: Move;
    @Input() public contactLog: ContactLog;

    @Output() public remark: EventEmitter<string> = new EventEmitter<string>();
    @Output() public remove: EventEmitter<void> = new EventEmitter<void>();

    public readonly svg = Svg;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly iconAppearance = UiIconAppearance;
    public readonly uiContext = UiContext;

    public showRemark: boolean;

    public remarkForm = this.formBuilder.group({
        remark: [null, [Validators.required]],
    });

    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translationService: TranslateService,
        private readonly moveSandbox: MoveSandbox,
        private readonly moveService: MoveService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly moveModalSandbox: MoveModalSandbox
    ) {}

    public canRedo(): boolean {
        return redoableContactLogs.includes(this.contactLog?.value);
    }

    public doShowRemark(): void {
        if (this.contactLog.remark) {
            this.remarkFormControl().patchValue(this.contactLog.remark);
        }
        this.showRemark = true;
    }

    public cancelRemark(): void {
        this.showRemark = false;
        this.remarkFormControl().reset(null);
    }

    public submitRemark(): void {
        if (this.remarkForm.invalid) return;
        this.showRemark = false;
        this.remark.emit(this.remarkFormControl().value);
    }

    public onRemove(): void {
        const data = this.translationService.instant(deleteConfirmation) as string;

        this.modalSandbox.openConfirmModal({ data }, (result) => this.handleOnRemoveClose(result));
    }

    private handleOnRemoveClose(result): void {
        if (result) {
            this.remove.emit();
        }
    }

    public showInfo(): void {
        this.moveModalSandbox.showContactLogInfo(this.contactLog);
    }

    public redo(): void {
        const data = this.translationService.instant(redoContactLogConfirmation) as string;
        this.modalSandbox.openConfirmModal({ data }, this.handleOnRedo);
    }

    private handleOnRedo = (result: boolean): void => {
        if (!result) return;
        this.moveService
            .redoContactLog(DbUtils.getStringId(this.move), DbUtils.getStringId(this.contactLog))
            .subscribe(() => this.notificationSandbox.sendSuccess());
    };

    private remarkFormControl(): AbstractControl {
        return this.remarkForm?.get('remark');
    }
}
