import { Component, Input } from '@angular/core';
import { ButtonAppearance } from '../../../button';
import { UiIcon, UiIconSize } from '../../../icon';
import { ModalSandbox } from '../../../modal';
import { UiContext } from '../../../ui.enums';
import { ThumbsRating } from '../../enums/thumbs-rating.enum';

@Component({
    selector: 'app-rating-modal',
    templateUrl: './rating.modal.html',
    styleUrls: ['./rating.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RatingModal {
    @Input() public thumbsRating: ThumbsRating;
    @Input() public houseMatchMapUrl: string;

    public readonly uiIcon = UiIcon;
    public readonly uiIconSize = UiIconSize;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly thumbsRatings = ThumbsRating;

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public closeModal(): void {
        this.modalSandbox.closeAll();
    }
}
