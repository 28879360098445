import { Injectable } from '@angular/core';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { RealEstateGroupInsightsService } from '@app/real-estate-group/services/real-estate-group-insights.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DbUtils } from '@smooved/core';
import { AnalyticsService, NotificationSandbox } from '@smooved/ui';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BivRealEstateAgentService } from '../services/biv-real-estate-agent.service';
import { RealEstateGroupService } from '../services/real-estate-group.service';
import {
    createBivRealEstateAgent,
    createBivRealEstateAgentSuccess,
    deleteBivRealEstateAgents,
    fetchBivRealEstateAgents,
    patchFailure,
    patchFeatureScopeConfig,
    patchSuccess,
    setBivRealEstateAgents,
    updateBivRealEstateAgent,
    updateBivRealEstateAgentSuccess,
} from './real-estate-group.actions';

@Injectable()
export class RealEstateGroupEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly realEstateGroupService: RealEstateGroupService,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly bivRealEstateAgentService: BivRealEstateAgentService,
        private readonly realEstateGroupInsightsService: RealEstateGroupInsightsService,
        private readonly analyticsService: AnalyticsService,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public patchFeatureScopeConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(patchFeatureScopeConfig),
            switchMap((action) => {
                return this.realEstateGroupService.patchFeatureScopeConfig(action.id, action.featureScopesConfig).pipe(
                    map((realEstateGroup) => {
                        this.notificationSandbox.updatedSuccess();
                        return patchSuccess({ realEstateGroup });
                    }),
                    catchError((error) => of(patchFailure({ error })))
                );
            })
        )
    );

    public fetchBivRealEstateAgents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchBivRealEstateAgents),
            switchMap((action) => {
                return this.realEstateGroupService.getBivRealEstateAgents(DbUtils.getStringId(action.realEstateGroup)).pipe(
                    map((agents) => {
                        return setBivRealEstateAgents({ agents });
                    })
                );
            })
        )
    );

    public deleteBivRealEstateAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteBivRealEstateAgents),
            switchMap((action) => {
                return this.realEstateGroupService
                    .deleteBivRealEstateAgents(DbUtils.getStringId(action.realEstateGroup), action.agentIds)
                    .pipe(
                        map(() => {
                            this.notificationSandbox.deletedSuccess();
                            return fetchBivRealEstateAgents({ realEstateGroup: action.realEstateGroup });
                        })
                    );
            })
        )
    );

    public createBivRealEstateAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createBivRealEstateAgent),
            switchMap((action) => {
                return this.realEstateGroupService.createBivRealEstateAgent(DbUtils.getStringId(action.realEstateGroup), action.agent).pipe(
                    map(() => {
                        return createBivRealEstateAgentSuccess({ realEstateGroup: action.realEstateGroup });
                    })
                );
            })
        )
    );

    public createBivRealEstateAgentSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createBivRealEstateAgentSuccess),
            switchMap((action) => {
                this.notificationSandbox.createdSuccess();
                return of(fetchBivRealEstateAgents({ realEstateGroup: action.realEstateGroup }));
            })
        )
    );

    public patchBivRealEstateAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateBivRealEstateAgent),
            switchMap((action) => {
                return this.bivRealEstateAgentService.patch(DbUtils.getStringId(action.agent), action.agent).pipe(
                    map(() => {
                        return updateBivRealEstateAgentSuccess({ realEstateGroup: action.realEstateGroup });
                    })
                );
            })
        )
    );

    public patchBivRealEstateAgentSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateBivRealEstateAgentSuccess),
            switchMap((action) => {
                this.notificationSandbox.updatedSuccess();
                return of(fetchBivRealEstateAgents({ realEstateGroup: action.realEstateGroup }));
            })
        )
    );
}
