import { ChangeDetectorRef, Component, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-name-address',
    templateUrl: 'table-column-name-address.component.html',
    styleUrls: ['table-column-name-address.component.scss'],
})
export class TableColumnNameAddressComponent {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (!this.table) return;

        this.cdRef.detectChanges();
        this.table.addColumnDef(this.columnDef);
    }
}
