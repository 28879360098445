import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/surveys`;
export const basePageUri = `/surveys`;
export const npsSurveyUri = `${baseUri}/nps`;
export const npsScoreUri = `${baseUri}/nps-score`;
export const npsSuggestionsUri = `${baseUri}/nps-suggestions`;
export const interviewUri = `${baseUri}/interview/:interviewee`;
export const requestSurveyByEmail = `${baseUri}/by-email`;
export const pendingSurveysUri = `${baseUri}/pending/:id`;
export const pendingSurveysPublishPageUri = `${basePageUri}/pending/:id/publish?at=:at`;
export const pendingSurveysSuccessPageUri = `${basePageUri}/pending/:id/success?at=:at`;
export const pendingSurveysConfirmUri = `${baseUri}/:id/confirm`;
