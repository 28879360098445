import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nKeyModule, PipeModule as CorePipeModule } from '@smooved/core';
import { ChartsModule } from '../charts';
import { SvgModule } from '../svg/svg.module';
import { WidgetV1Component } from './components/v1/widget.component';
import { WidgetV2Component } from './components/v2/widget.component';
import { WidgetProvidersModule } from './widget-providers.module';

@NgModule({
    declarations: [WidgetV1Component, WidgetV2Component],
    imports: [CommonModule, I18nKeyModule, ChartsModule, TranslateModule, CorePipeModule, SvgModule, WidgetProvidersModule],
    exports: [WidgetV1Component, WidgetV2Component],
})
export class WidgetModule {}
