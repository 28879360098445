import { Injectable } from '@angular/core';
import { OrderSandbox } from '@app/order/sandboxes/order.sandbox';
import { OrderDetailService } from '@app/partner/services/order-detail.service';
import { orderGiftModel } from '@shared/order/constants/models.constants';
import { NotificationSandbox } from '@smooved/ui';

@Injectable()
export class GiftDetailService extends OrderDetailService {
    constructor(readonly orderSandbox: OrderSandbox, readonly notificationSandbox: NotificationSandbox) {
        super(orderSandbox, notificationSandbox);
    }

    public patchMoveByOrder(id: string, payload: object, cb: () => void): void {
        this.patch(this.orderSandbox.patchMoveByOrder(id, payload, orderGiftModel), cb);
    }
}
