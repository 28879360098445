<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <div class="__label-container">
        <div class="__score-group u-flex-column u-flex-align-items-left u-padding-top-half" *ngFor="let group of groups">
            <div class="__label-container u-flex-row">
                <label *ngFor="let scale of group.scales" class="u-margin-right-xs-up-to-and-including-phone-landscape u-margin-right">
                    <input
                        class="__input"
                        type="radio"
                        [(ngModel)]="innerModel"
                        (ngModelChange)="onModelChange()"
                        [value]="scale?.value"
                        [name]="id"
                        [disabled]="innerDisabled"
                    />
                    <div class="__container is-{{ group.type }} u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                        <span>{{ scale.label[currentLang] }}</span>
                    </div>
                </label>
            </div>
            <div class="__score-group-label u-color-muted u-font-size-small u-padding-top-half">
                {{ group.label[currentLang] }}
            </div>
        </div>
    </div>
    <mat-error class="u-margin-top-half" *ngIf="isErrorState()">{{ getFirstErrorState() | errorState }}</mat-error>
</app-label-container>
