import { TableDataSourceConfig } from './analytics-move-count-table.interfaces';

export const weekCount = 12;
export const unknownRealEstateAgent = { _id: null };
export const unknownRealEstateAgentLabel = 'SMOOVED_ANALYTICS.OVERVIEW.UNKNWON_REAL_ESTATE_AGENT';

export const tableConfig: TableDataSourceConfig = {
    tableTotals: true,
    groupTotals: true,
};
