import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnergySuggestionsQuery } from '@app/energy/interfaces/energy-suggestions-query';
import { select, Store } from '@ngrx/store';
import { ElectricityMeterType } from '@smooved/ui';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../../../store/state';
import { EmojiIcon } from '../../icon/models/emoji-icon.enum';
import { WizardInputSelection } from '../../interfaces/wizard-input-selection';
import { EanCodesAvailable } from '../enums/ean-codes-available.enum';
import { EnergySuggestion } from '../interfaces/energy-suggestion';
import { EnergyService } from '../services/energy.service';
import {
    getGroupedEnergySuggestions,
    getGroupedEnergySuggestionsByConsumptionProfile,
    getGroupedEnergySuggestionsSuccess,
} from '../state/energy.actions';
import { getEnergySuggestionsLoadingState, getEnergySuggestionsState, getGroupedEnergySuggestionsState } from '../state/energy.reducer';

@Injectable({
    providedIn: 'root',
})
export class EnergySandbox {
    public energySuggestions$: Observable<EnergySuggestion[]> = this.store$.pipe(select(getEnergySuggestionsState));
    public groupedEnergySuggestions$: Observable<EnergySuggestion[][]> = this.store$.pipe(
        select(getGroupedEnergySuggestionsState)
    ) as Observable<EnergySuggestion[][]>;
    public fixedEnergySuggestions$: Observable<EnergySuggestion[][]> = this.groupedEnergySuggestions$.pipe(this.tarifFilter(true));
    public variableEnergySuggestions$: Observable<EnergySuggestion[][]> = this.groupedEnergySuggestions$.pipe(this.tarifFilter(false));
    public energySuggestionsLoading$: Observable<boolean> = this.store$.pipe(select(getEnergySuggestionsLoadingState));

    public meterTypeOptionsDeprecated: WizardInputSelection<ElectricityMeterType>[] = [
        {
            icons: [EmojiIcon.SingleMeter],
            id: 'meter-type-single',
            label: 'Enkelvoudig',
            value: ElectricityMeterType.Single,
            name: 'meter-type',
        },
        {
            icons: [EmojiIcon.Sun, EmojiIcon.NightMoon],
            id: 'meter-type-double',
            label: 'Dubbel (dag/nacht)',
            value: ElectricityMeterType.Double,
            name: 'meter-type',
        },
        {
            icons: [EmojiIcon.WomanDontKnow],
            id: 'meter-type-unknown',
            label: 'Ik heb geen idee',
            value: ElectricityMeterType.Unknown,
            name: 'meter-type',
        },
    ];

    public eanCodeOptions: WizardInputSelection<EanCodesAvailable>[] = [
        {
            icons: [EmojiIcon.WomanRaisingHand],
            id: 'ean-code-known',
            label: 'Ik heb ze bij de hand',
            value: EanCodesAvailable.Known,
            name: 'ean-code-options',
        },
        {
            icons: [EmojiIcon.WomanDontKnow],
            id: 'ean-code-unknown',
            label: 'Ik heb geen idee',
            value: EanCodesAvailable.Unknown,
            name: 'ean-code-options',
        },
    ];

    public getGroupedEnergySuggestions(fixedTariff: string): void {
        this.store$.dispatch(
            getGroupedEnergySuggestions({
                fixedTariff,
            })
        );
    }

    public getGroupedEnergySuggestionsByConsumptionProfile(query: EnergySuggestionsQuery): void {
        this.store$.dispatch(
            getGroupedEnergySuggestionsByConsumptionProfile({
                query,
            })
        );
    }

    public clearGroupedSuggestions(): void {
        this.store$.dispatch(
            getGroupedEnergySuggestionsSuccess({
                groupedEnergySuggestions: null,
            })
        );
    }

    constructor(
        protected store$: Store<State>,
        private energyService: EnergyService,
        protected router: Router
    ) {}

    public getAllEnergySuppliers(): Observable<string[]> {
        return this.energyService.getAllEnergySuppliers();
    }

    private tarifFilter(fixedTariff: boolean): OperatorFunction<EnergySuggestion[][], EnergySuggestion[][]> {
        return map((providers) => {
            if (!providers) return providers;
            const results = providers.map((provider) => {
                const suggestions = provider.filter((suggestion) => (fixedTariff ? suggestion.fixedTariff : !suggestion.fixedTariff));
                return suggestions.length ? suggestions : null;
            });
            return results.filter((n) => !!n);
        });
    }
}
