<app-menu>
    <app-menu-item
        [goTo]="['..', 'metrics', 'analytics', 'overview']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Leaderboard"
    >
        {{ 'METRICS.MENU.OVERVIEW' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'metrics', 'analytics', 'inputs']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Added"
    >
        {{ 'METRICS.MENU.INPUT' | translate }}
    </app-menu-item>
    <app-menu-item
        [goTo]="['..', 'metrics', 'analytics', 'confirmations']"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.CheckOutline"
    >
        {{ 'METRICS.MENU.CONFIRMATION' | translate }}
    </app-menu-item>
</app-menu>
