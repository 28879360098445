import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiFormModule } from '../form';
import { IconModule } from '../icon';
import { SvgModule } from '../svg';
import { TooltipModule } from '../tooltip';
import { MeterReadingsElectricityComponent } from './components/meter-readings-electricity/meter-readings-electricity.component';
import { MeterReadingsGasComponent } from './components/meter-readings-gas/meter-readings-gas.component';
import { MeterStatePillBaseComponent } from './components/meter-state-pill-base/meter-state-pill-base.component';
import { PipeModule } from '@smooved/core';
import { EnergyMeterStatePillComponent } from './components/energy-meter-state-pill/energy-meter-state-pill.component';
import { WaterMeterStatePillComponent } from './components/water-meter-state-pill/water-meter-state-pill.component';

const components = [
    MeterReadingsElectricityComponent,
    MeterReadingsGasComponent,
    EnergyMeterStatePillComponent,
    WaterMeterStatePillComponent,
];

@NgModule({
    imports: [CommonModule, TranslateModule, SvgModule, UiFormModule, IconModule, TooltipModule, PipeModule],
    declarations: [...components, MeterStatePillBaseComponent],
    exports: components,
})
export class EnergyModule {}
