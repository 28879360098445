import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '@app/card/card.module';
import { FormModule } from '@app/form/form.module';
import { MoveModule } from '@app/move/move.module';
import { TranslateModule } from '@ngx-translate/core';
import { CardsModule } from '@smooved/ui';
import { ClientRoutingModule } from './client-routing.module';
import { TriggerWebhookComponent } from './components/trigger-webhook/trigger-webhook.component';
import { ClientCanceledContainer } from './containers/canceled/canceled.container';
import { ClientInitContainer } from './containers/init/init.container';
import { ClientLeadPassingContainer } from './containers/lead-passing/lead-passing.container';
import { ClientSuccessContainer } from './containers/success/success.container';

@NgModule({
    imports: [CommonModule, ClientRoutingModule, TranslateModule, FormModule, CardModule, CardsModule, MoveModule, FormModule],
    declarations: [
        ClientInitContainer,
        ClientCanceledContainer,
        ClientSuccessContainer,
        ClientLeadPassingContainer,
        TriggerWebhookComponent,
    ],
    exports: [TriggerWebhookComponent],
})
export class ClientModule {}
