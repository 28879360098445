import { FileInput } from '@app/form/interfaces/file-input';
import { getFileExtension } from '@app/form/utils/file-extension.util';

export const isImage = (file: FileInput): boolean => {
    if (!file) return false;

    if (!!file.value && isBase64Image(file.value as string)) {
        return true;
    }

    switch (getFileExtension(file)?.toLowerCase()) {
        case 'png':
        case 'jpg':
        case 'jpeg':
            return true;

        default:
            return false;
    }
};

const isBase64Image = (value: string): boolean => {
    return value.startsWith('data:image/png;') || value.startsWith('data:image/jpeg;');
};
