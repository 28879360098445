import { Action, combineReducers } from '@ngrx/store';
import * as OrderCreate from './order-create/order-create.reducer';

export const featureKey = 'order';

export interface State {
    [OrderCreate.featureKey]: OrderCreate.State;
}

export interface OrderState {
    [featureKey]: State;
}

export function combiner(state: State, action: Action): State {
    return combineReducers({
        [OrderCreate.featureKey]: OrderCreate.reducer,
    })(state, action);
}
