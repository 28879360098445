import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderRentalInspection } from '@app/order/interfaces/order-rental-inspection';
import { ModalData } from '@app/partner/interfaces/modal-data';
import { DbUtils, RxjsComponent } from '@smooved/core';
import { RentalInspectionDetailService } from './rental-inspection-detail.service';

@Component({
    selector: 'app-rental-inspection-detail-modal',
    templateUrl: './rental-inspection-detail.modal.html',
    providers: [RentalInspectionDetailService],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RentalInspectionDetailModal extends RxjsComponent {
    @ViewChild('download') download: ElementRef;

    public order: OrderRentalInspection;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: ModalData<OrderRentalInspection>,
        public readonly rentalInspectionDetailService: RentalInspectionDetailService
    ) {
        super();
        this.order = this.data.order;
        this.rentalInspectionDetailService.getOrder(DbUtils.getStringId(this.data.order)).subscribe(this.handleReload);
    }

    public onPatch(payload: object): void {
        this.rentalInspectionDetailService.patchOrder(DbUtils.getStringId(this.data.order), payload, this.onPatchHandler);
    }

    public reset(reload: boolean): void {
        if (reload) {
            this.rentalInspectionDetailService.getOrder(DbUtils.getStringId(this.data.order)).subscribe(this.handleReload);
        }
    }

    public onSend(value: string): void {
        this.rentalInspectionDetailService.createMessage(DbUtils.getStringId(this.data.order), value).subscribe(this.onSendSuccess);
    }

    private onSendSuccess = () => {
        this.reset(true);
    };

    private onPatchHandler = (order: OrderRentalInspection): void => {
        this.data.orderUpdated.next(true);
        this.order = order;
    };

    private handleReload = this.onPatchHandler;
}
