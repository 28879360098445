import { NpsScore } from '@app/move/interfaces/nps-score';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentRegisterData } from '@app/real-estate-agent/interfaces/real-estate-agent-register-data';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { RealEstateAgent } from '../interfaces/real-estate-agent';
import {
    getAllSuccess,
    getBadgeCountsSuccess,
    getDetails,
    getDetailsFailure,
    getDetailsSuccess,
    patchSuccess,
    resetBadgeCountsUnreadReviews,
    setFilter,
    setInviteData,
} from './real-estate-agents.actions';

export interface RealEstateAgentState {
    loading: boolean;
    realEstateAgent: RealEstateAgent;
    realEstateAgents: RealEstateAgent[];
    filter: RealEstateAgentFilter;
    registerData: RealEstateAgentRegisterData;
    npsScore: NpsScore;
    unreadReviews: number;
}

export const initialState: RealEstateAgentState = {
    loading: false,
    realEstateAgent: null,
    realEstateAgents: [],
    filter: null,
    registerData: null,
    npsScore: null,
    unreadReviews: 0,
};

export const realEstateAgentsReducer = createReducer(
    initialState,
    on(getDetails, (state) => ({
        ...state,
        realEstateAgent: null,
        loading: true,
    })),
    on(getDetailsSuccess, (state, { realEstateAgent }) => ({
        ...state,
        realEstateAgent,
        loading: false,
    })),
    on(getDetailsFailure, (state) => ({
        ...state,
        loading: false,
    })),
    on(getAllSuccess, (state, { realEstateAgents }) => ({
        ...state,
        realEstateAgents,
    })),
    on(patchSuccess, (state, { realEstateAgent }) => ({
        ...state,
        realEstateAgents: state.realEstateAgents.map((aRealEstateAgent) => {
            return aRealEstateAgent._id === realEstateAgent._id ? realEstateAgent : aRealEstateAgent;
        }),
    })),
    on(setFilter, (state, { filter }) => ({
        ...state,
        filter,
    })),
    on(setInviteData, (state, { inviteData }) => ({
        ...state,
        registerData: { ...state.registerData, ...inviteData },
    })),
    on(getBadgeCountsSuccess, (state, { unreadReviews }) => ({
        ...state,
        unreadReviews,
    })),
    on(resetBadgeCountsUnreadReviews, (state) => ({
        ...state,
        unreadReviews: 0,
    }))
);

export const getRealEstateAgentState = createFeatureSelector<RealEstateAgentState>('real-estate-agent');

export const getRealEstateAgentDetails = createSelector(getRealEstateAgentState, (state) => state?.realEstateAgent);

export const getRealEstateAgentsState = createSelector(
    getRealEstateAgentState,
    (realEstateAgentState) => realEstateAgentState?.realEstateAgents
);

export const getRealEstateAgentFilterState = createSelector(
    getRealEstateAgentState,
    (realEstateAgentState) => realEstateAgentState?.filter
);

export const getRealEstateAgentRegisterData = createSelector(
    getRealEstateAgentState,
    (realEstateAgentState) => realEstateAgentState?.registerData
);

export const getRealEstateAgentBadgeCounts = createSelector(getRealEstateAgentState, (realEstateAgentState) => {
    const { unreadReviews } = realEstateAgentState;
    return { unreadReviews };
});
