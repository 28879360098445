import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddresseesComponent } from '@app/admin/components/mail-form/addressees/addressees.component';
import { MailFields } from '@app/admin/enums/mail-fields.enum';
import { MailData, MailFormAlert } from '@app/admin/interfaces/mail-data';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { AttachmentId } from '@app/email/enums/attachment-id.enum';
import { Attachment } from '@app/email/interfaces/attachment';
import { createEscalationOptions, stateOptions } from '@app/escalation/constants/escalation.constants';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { ArrayUtils, AuditUtils, DbUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { DropdownInput, UiContext, UiIcon } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import * as constants from './mail-form.constants';

@Component({
    selector: 'app-mail-form',
    templateUrl: './mail-form.component.html',
    styleUrls: ['./mail-form.component.scss'],
})
export class MailFormComponent extends RxjsComponent implements OnChanges, OnInit {
    @ViewChildren(AddresseesComponent) public addressees: QueryList<AddresseesComponent>;

    @Input() public data: MailData;
    @Input() public assetsShowFileName = false;
    @Input() public assetsReadOnly = false;
    @Input() public showCc = false;
    @Input() public showBcc = false;
    @Input() public alert?: MailFormAlert;

    public readonly editorModules = constants.editorModules;
    public readonly mailFields = MailFields;
    public readonly uiContext = UiContext;
    public readonly uiIcon = UiIcon;
    public readonly i18nKeytype = appI18nKeyTypes;
    public readonly stateOptions = stateOptions;

    public editor;
    public form: UntypedFormGroup;
    public files: File[];
    public assets: Attachment[];
    public escalationOptions: DropdownInput<string>[] = [];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly documentCenter: DocumentCenterSandbox
    ) {
        super();
    }

    public ngOnChanges({ data }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(data)) {
            this.setData();
        }
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            [MailFields.to]: [this.data.preData?.to],
            [MailFields.cc]: [this.data.preData?.cc, { disabled: this.showCc }],
            [MailFields.bcc]: [this.data.preData?.bcc, { disabled: this.showBcc }],
            [MailFields.content]: [this.data.preData?.content, Validators.required],
            [MailFields.subject]: [this.data.preData?.subject, Validators.required],
            [MailFields.escalation]: DbUtils.getStringId(this.data.preData?.escalation),
            [MailFields.state]: AuditUtils.getValue(this.data.preData?.escalation?.state),
        });
        this.setEscalationOptions();
        this.form.get(MailFields.escalation).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onSelectEscalation);
    }

    public onFilesChange(event): void {
        const files: File[] = event.target.files;
        if (files?.length) {
            this.files = files;
        } else {
            this.files = null;
        }
    }

    public removeItem(item: Attachment): void {
        this.assets = this.assets.filter((asset) => asset !== item);
    }

    public openDetail(item: Attachment): void {
        item.id === AttachmentId.EnergyDocumentSummary
            ? this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(this.data.move))
            : this.documentCenter.downloadAttachment(item);
    }

    private setData(): void {
        if (!this.data) return;
        this.form?.patchValue(
            {
                [MailFields.to]: this.data.preData?.to,
                [MailFields.cc]: this.data.preData?.cc,
                [MailFields.bcc]: this.data.preData?.bcc,
                [MailFields.content]: this.data.preData?.content,
                [MailFields.subject]: this.data.preData?.subject,
                [MailFields.escalation]: DbUtils.getStringId(this.data.preData?.escalation),
                [MailFields.state]: AuditUtils.getValue(this.data.preData?.escalation?.state),
            },
            { emitEvent: false }
        );

        this.assets = [...(this.data.assets || [])];

        this.addressees?.forEach((addressesColumn) => {
            addressesColumn.disableMover(!!this.data.move.user && !this.data.receiversConfig?.[addressesColumn.prefix]?.mover?.disabled);
            addressesColumn.disableRealEstateAgent(
                !!this.data.move.realEstateAgent && !this.data.receiversConfig?.[addressesColumn.prefix]?.realEstateAgent?.disabled
            );
            addressesColumn.disableLinkedMove(
                !!this.data.move.linkedMove && !this.data.receiversConfig?.[addressesColumn.prefix]?.linkedMove?.disabled
            );
            addressesColumn.disableSuplier(!this.data.receiversConfig?.[addressesColumn.prefix]?.supplier?.disabled);
            addressesColumn.disableOther(!this.data.receiversConfig?.[addressesColumn.prefix]?.other?.disabled);
        });
    }

    private setEscalationOptions(): void {
        ArrayUtils.isEmpty(this.data.move.escalations)
            ? this.form.get(MailFields.escalation).disable()
            : (this.escalationOptions = createEscalationOptions(this.data.move.escalations as Escalation[]));
    }

    private onSelectEscalation = (escalationId: string): void => {
        const escalation = this.data.move.escalations.find((escalation) => DbUtils.getStringId(escalation) === escalationId) as Escalation;
        this.form.get(MailFields.state).patchValue(AuditUtils.getValue(escalation.state));
    };
}
