import { Component, OnInit } from '@angular/core';
import { DateUtils, RxjsComponent } from '@smooved/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsDashboardService } from './analytics-dashboard.service';

@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: 'analytics-dashboard.component.html',
    styleUrls: ['analytics-dashboard.component.scss'],
    providers: [AnalyticsDashboardService],
})
export class AnalyticsDashboardComponent extends RxjsComponent implements OnInit {
    public lastUpdateInMinutes$: Observable<number> = this.analyticsDashboardService.analytics$.pipe(
        map((analytics) => {
            const duration = moment.duration(DateUtils.tz().diff(DateUtils.tz(analytics.lastModifiedOn)));
            return Math.round(duration.asMinutes());
        })
    );

    constructor(public readonly analyticsDashboardService: AnalyticsDashboardService) {
        super();
    }

    public ngOnInit(): void {
        this.analyticsDashboardService.fetch();
    }
}
