import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CoreProvidersModule } from '../core.providers.module';

@Injectable({ providedIn: CoreProvidersModule })
export class DomBuilderService {
    constructor(@Inject(DOCUMENT) private readonly document) {}

    public createAnchor({ url, target, label }: { url: string; target?: string; label: string }): HTMLAnchorElement {
        const el = this.document.createElement('a') as HTMLAnchorElement;
        el.href = url;
        el.target = target;
        el.innerHTML = label;
        return el;
    }
}
