import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-commissions-notification-unavailable',
    template: `
        <div
            class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-border-radius u-background-gray u-color-white"
        >
            <h6 class="u-margin">
                {{
                    'INVOICING.COMMISSIONS.NOTIFICATION_UNAVAILABLE'
                        | translate
                            : {
                                  quarter: invoice?.quarter,
                                  year: invoice?.year
                              }
                }}
            </h6>
            <app-svg [name]="arrowRight" class="u-margin" width="28" height="28"></app-svg>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommissionsNotificationUnavailableComponent {
    @Input() public invoice: Invoice;
    public arrowRight = Svg.ChevronRight;
}
