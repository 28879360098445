import { Injectable } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { SharedSandbox } from '@app/shared/sandboxes/shared.sandbox';
import { State } from '@app/store/state';
import { MobileBundle } from '@app/telecom/interfaces/mobile-bundle';
import { Move } from '@app/move/interfaces/move';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { TvBundleResponse } from '../interfaces/tv-bundle-response';
import { FixedLine } from '../telecom.interfaces';

@Injectable({
    providedIn: 'root',
})
export class TelecomSandbox {
    constructor(
        private store$: Store<State>,
        private moveSandbox: MoveSandbox,
        private sharedSandbox: SharedSandbox,
        private translateService: TranslateService
    ) {}

    /**
     * Returns HTML
     * @param tvBundles
     */
    public getTvBundleInfo(tvBundles: TvBundleResponse[]): string {
        if (!tvBundles) {
            return '';
        }
        let htmlString = '<ul>';
        tvBundles.forEach((tvBundle) => {
            htmlString += `<li>${tvBundle.provider} - ${tvBundle.product}</li>`;
        });
        htmlString += '</ul>';
        return htmlString;
    }

    public getMobileBundleInfo(mobileBundle: MobileBundle[]): string {
        if (!mobileBundle || !mobileBundle.length) {
            return '';
        }
        let htmlString = '<div>';
        mobileBundle.forEach((m, index) => {
            htmlString += `<div class="u-flex-column u-margin-bottom">`;
            htmlString += `<span class="u-margin-bottom-half">${this.translateService.instant(
                'MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SIM_CARD'
            )} #${index + 1}</span>`;
            htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.MOBILE_BUNDLE.NEW_NUMBER')}: ${m.newNumber}</span>`;
            if (!m.newNumber) {
                // htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.MOBILE_BUNDLE.PROVIDER')}: ${m.provider}</span>`;
                htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SIM_CARD_NUMBER')}: ${
                    m.simCardNumber
                }</span>`;
                // htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.MOBILE_BUNDLE.MOBILE_DATA_USAGE')}: ${m.mobileDataUsage}</span>`;
                htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.MOBILE_BUNDLE.SUBSCRIPTION_TYPE')}: ${
                    m.subscriptionType
                }</span>`;
            }
            htmlString += `</div>`;
        });
        htmlString += '</div>';
        return htmlString;
    }

    public getFixedLineInfo(fixedLine: FixedLine): string {
        if (!fixedLine) {
            return '';
        }
        let htmlString = '<div class="u-flex-column">';
        htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.FIXED_LINE.NEW_NUMBER')}: ${fixedLine.newNumber}</span>`;
        if (!fixedLine.newNumber) {
            htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.FIXED_LINE.NUMBER')}: ${fixedLine.number}</span>`;
            htmlString += `<span>${this.translateService.instant('MOVE.TELECOM_OFFER.FIXED_LINE.PROVIDER')}: ${fixedLine.provider}</span>`;
        }
        htmlString += '</div>';
        return htmlString;
    }

    public getTotalPrice(move: Move): number {
        return MoveUtils.telecomTotalPrice(move);
    }

    /**
     * validate if exists new Telecom installation schedule
     * @param move
     */
    public validateNewTelecomSchedule(move: Move): boolean {
        return (
            !!move?.telecomInstallationScheduleByAdmin &&
            (move?.telecomInstallationScheduleByAdmin?.date !== move?.telecomOffer?.telecomInstallation?.date ||
                (move?.telecomInstallationScheduleByAdmin?.date === move?.telecomOffer?.telecomInstallation?.date &&
                    move?.telecomInstallationScheduleByAdmin?.timeSlot !== move?.telecomOffer?.telecomInstallation?.timeSlot))
        );
    }
}
