import { Component, Input } from '@angular/core';
import { QuarterlyInvoicesTotals } from '@app/invoicing/interfaces/quarterly-invoices-totals';

@Component({
    selector: 'app-billing-header-totals',
    template: `
        <app-card [bordered]="false" [paddingDouble]="false">
            <div class="u-padding u-flex-row u-w100p">
                <div class="u-flex-column u-flex-1">
                    <h4>{{ quarterDate | quarterYear }}</h4>
                    <span class="u-color-muted u-font-size-small">{{ 'BILLING.QUARTERS.Q' + (quarterDate | quarter) | translate }}</span>
                </div>
                <div class="u-flex-column u-flex-1">
                    <h4>{{ quarterlyInvoicesTotals?.totalAmount | price }}</h4>
                    <span class="u-color-muted u-font-size-small">{{ 'BILLING.TOTALS.AMOUNT_DUE' | translate }}</span>
                </div>
                <div class="u-flex-column u-flex-1">
                    <h4>{{ quarterlyInvoicesTotals?.totalAmountAlreadyInvoiced | price }}</h4>
                    <span class="u-color-muted u-font-size-small">{{ 'BILLING.TOTALS.INVOICED' | translate }}</span>
                </div>
                <div class="u-flex-column u-flex-1">
                    <h4>{{ quarterlyInvoicesTotals?.totalAmountAlreadyPaid | price }}</h4>
                    <span class="u-color-muted u-font-size-small">{{ 'BILLING.TOTALS.PAID' | translate }}</span>
                </div>
            </div>
        </app-card>
    `,
})
export class BillingHeaderTotalsComponent {
    @Input() public quarterDate: Date;
    @Input() public quarterlyInvoicesTotals: QuarterlyInvoicesTotals;
}
