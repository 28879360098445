import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-admin-todo-energy-stop-meter-readings-completed',
    templateUrl: './todo-energy-stop-meter-readings-completed.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEnergyStopMeterReadingsCompletedComponent extends BaseServiceActionComponent {
    @Input() public move: Move;

    public readonly i18nKeys = AppI18nKeyType;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;

    constructor(
        private readonly adminModalService: AdminModalService,
        protected readonly cdr: ChangeDetectorRef,
        protected readonly moveSandbox: MoveSandbox
    ) {
        super(cdr, moveSandbox);
    }

    public openMeterInfo(): void {
        this.adminModalService.openMeterInfo(this.move.linkedMove as Move, this.onCloseMeterInfo);
    }

    private onCloseMeterInfo = (move: Move): void => {
        if (move) {
            this.moveSandbox
                .get(DbUtils.getStringId(this.move))
                .pipe(take(1))
                .subscribe((move: Move): void => this.updateMove(move));
        }
    };
}
