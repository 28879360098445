import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '../svg/svg.module';
import { IconCheckedComponent } from './icon-checked.component';
import { IconComponent } from './icon.component';

@NgModule({
    declarations: [IconComponent, IconCheckedComponent],
    exports: [IconComponent, IconCheckedComponent],
    imports: [CommonModule, SvgModule],
})
export class IconModule {}
