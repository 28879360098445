import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { CardModule } from '@app/card/card.module';
import { ModalModule } from '@app/modal/modal.module';
import { NpsActionsComponent } from '@app/reviews/components/nps-actions/nps-actions.component';
import { AppNpsReviewsTemplateService } from '@app/reviews/components/nps-reviews-template/nps-reviews-template.service';
import { ReviewsTopicsSentimentFilterComponent } from '@app/reviews/components/reviews-topics-sentiment-filter/reviews-topics-sentiment-filter.component';
import { NpsReviewsDataSource } from '@app/reviews/data-sources/nps-reviews/nps-reviews.data-source';
import { SharedModule } from '@app/shared/shared.module';
import {
    ChartsModule,
    NoteModule,
    NpsReviewsTemplateService,
    PaginationModule,
    ReviewsDataSource,
    ReviewsModule as UiReviewsModule,
} from '@smooved/ui';
import { NpsReviewBucketsComponent } from './components/nps-review-buckets/nps-review-buckets.component';
import { NpsReviewsFilterComponent } from './components/nps-reviews-filter/nps-reviews-filter.component';
import { EditReviewModal } from './modals/edit-review/edit-review.modal';
import { ReviewsProvidersModule } from './reviews-providers.module';

export const components = [
    EditReviewModal,
    NpsReviewBucketsComponent,
    NpsReviewsFilterComponent,
    ReviewsTopicsSentimentFilterComponent,
    NpsActionsComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        MatChipsModule,
        SharedModule,
        CardModule,
        NoteModule,
        ChartsModule,
        ModalModule,
        PaginationModule,
        UiReviewsModule,
        ReviewsProvidersModule,
    ],
    exports: [...components],
    providers: [
        {
            provide: ReviewsDataSource,
            useClass: NpsReviewsDataSource,
        },
        {
            provide: NpsReviewsTemplateService,
            useClass: AppNpsReviewsTemplateService,
        },
    ],
})
export class ReviewsModule {}
