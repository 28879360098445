export enum ContactLogType {
    Call = 'call',
    Voicemail = 'voicemail',
    Chat = 'chat',
    Email = 'email',
    EmailPitch = 'email-pitch',
    EmailEnergyUrgency = 'email-energy-urgency',
    EmailRealEstateAgent = 'email-real-estate-agent',
    EmailMover = 'email-mover',
    EmailConfirm = 'email-confirm',
    EmailCustomMover = 'email-custom-mover',
    EmailCustomRealEstateAgent = 'email-custom-real-estate-agent',
    EmailCustomSupplier = 'email-custom-supplier',
    EmailCustomLinkedMove = 'email-custom-linked-move',
    EmailCustomOther = 'email-custom-other',
    EmailSurveyRequest = 'email-survey-request',
    EmailLeaverInvite = 'email-leaver-invite',
    EmailMeterCollection = 'email-meter-collection',
    EmailMeterCollectionReminder = 'email-meter-collection-reminder',
    EmailMeterCollectionFinalReminder = 'email-meter-collection-final-reminder',
    SmsCustomMover = 'sms-custom-mover',
    SmsLeaver = 'sms-leaver',
    SmsEotsRequestWithoutMover = 'sms-eots-request-without-mover',
    SmsEotsRequestWithMover = 'sms-eots-request-with-mover',
    SmsMeterCollection = 'sms-meter-collection',
    EmailEnergyStopLeaver = 'email-energy-stop-leaver',
    SmsEnergyStopLeaverExpectations = 'sms-energy-stop-leaver-expectations',
    EmailEnergyStopLeaverExpectations = 'email-energy-stop-leaver-expectations',
    EmailSettingTheScene = 'email-setting-the-scene',
    EmailProvisionalEnergyProposal = 'email-provisional-energy-proposal',
    EmailEnergyProposalReminder = 'email-energy-proposal-reminder',
    EmailEnergyProposal = 'email-energy-proposal',
    EmailEnergyProposalWithContext = 'email-energy-proposal-with-context',
    SmsEnergyProposal = 'sms-energy-proposal',
    EmailEnergyProposalLandAgent = 'email-energy-proposal-land-agent',
    EmailEnergyProposalVacancy = 'email-energy-proposal-vacancy',
    EmailEnergyOrdered = 'email-energy-ordered',
    EmailEnergyNotInterested = 'email-energy-not-interested',
    EmailMeterReadingsEnergy = 'email-meter-readings-energy',
    EmailMeterReadingsWater = 'email-meter-readings-water',
    EmailEnergyInstalled = 'email-energy-installed',
    EmailRealEstateAgentEnteredLeaverAfter15DaysLate = 'email-real-estate-agent-entered-leaver-after-15-days-late',
    EmailRealEstateAgentEnteredTransfereeAfter15DaysLate = 'email-real-estate-agent-entered-transferee-after-15-days-late',
    EmailRealEstateAgentEnteredLeaverAfter40DaysLate = 'email-real-estate-agent-entered-leaver-after-40-days-late',
    EmailRealEstateAgentEnteredTransfereeAfter40DaysLate = 'email-real-estate-agent-entered-transferee-after-40-days-late',
    EmailEnergyStopSupplierComplete = 'email-energy-stop-supplier-complete',
    EmailEnergyStopNotInterested = 'email-energy-stop-not-interested',
    EmailLeaverMeterReadingsEnergy = 'email-leaver-meter-readings-energy',
    EmailLeaverMeterReadingsWater = 'email-leaver-meter-readings-water',
    EmailTransfereeNewWaterContract = 'email-transferee-new-water-contract',
    EmailLeaverClosingWaterContract = 'email-leaver-closing-water-contract',
    EmailLeaverFollowUpClosingWaterContract = 'email-leaver-follow-up-closing-water-contract',
    EmailTransfereeWaterTransferDocumentNotUploadedReminder = 'email-transferee-water-transfer-document-not-uploaded-reminder',
    EmailLeaverWaterTransferDocumentNotUploadedReminder = 'email-leaver-water-transfer-document-not-uploaded-reminder',

    /** @deprecated */
    EmailMeterTransferForLeaver = 'email-meter-transfer-for-leaver',
}

export const smsContactLogTypes = [
    ContactLogType.SmsCustomMover,
    ContactLogType.SmsEotsRequestWithMover,
    ContactLogType.SmsEotsRequestWithoutMover,
    ContactLogType.SmsLeaver,
    ContactLogType.SmsMeterCollection,
    ContactLogType.SmsEnergyProposal,
];
