export enum Interviewee {
    Buyer = 'buyer',
    Seller = 'seller',
    Tenant = 'tenant',
    Landlord = 'landlord',
    Guest = 'guest',
}

export enum AuthenticatedInterviewee {
    Buyer = 'buyer',
    Seller = 'seller',
    Tenant = 'tenant',
    Landlord = 'landlord',
}
