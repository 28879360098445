import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';

@Component({
    selector: 'app-real-estate-agent-details',
    templateUrl: './real-estate-agent-details.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateAgentDetailsComponent {
    @Input() public agent: RealEstateAgent;
    @Input() public group: RealEstateGroup;
    @Input() public hideLogo = false;
}
