import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUri, supplierGroupsUri } from '@app/supplier/constants/uri.constants';
import { Supplier } from '@app/supplier/interfaces/supplier';
import { CreateSupplierGroup, SupplierGroup } from '@app/supplier/interfaces/supplier-group';
import { DbUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SupplierService {
    constructor(private readonly httpClient: HttpClient) {}

    public createSupplierGroup(payload: CreateSupplierGroup): Observable<SupplierGroup> {
        return this.httpClient.post<SupplierGroup>(supplierGroupsUri, payload);
    }

    public getAll(): Observable<Supplier[]> {
        return this.httpClient.get<Supplier[]>(baseUri);
    }

    public getAllGroups(): Observable<SupplierGroup[]> {
        return this.httpClient.get<SupplierGroup[]>(supplierGroupsUri);
    }

    public getSupplierOptions(): Observable<DropdownInput<Supplier>[]> {
        return this.getAll().pipe(
            map((suppliers) => {
                return suppliers.map((supplier: Supplier) => {
                    return {
                        id: DbUtils.getStringId(supplier),
                        value: supplier,
                        name: 'supplier',
                        label: supplier.email,
                    };
                });
            })
        );
    }

    public getSupplierGroupOptions(): Observable<DropdownInput<SupplierGroup>[]> {
        return this.getAllGroups().pipe(
            map((suppliersGroups) => {
                return suppliersGroups.map((supplierGroup: SupplierGroup) => {
                    return {
                        id: DbUtils.getStringId(supplierGroup),
                        value: supplierGroup,
                        name: 'supplierGroup',
                        label: supplierGroup.name,
                    };
                });
            })
        );
    }
}
