import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExternalInfluencerService } from '../../../company-page/services/external-influencer.service';

@Component({
    selector: 'smvd-influencer-review-filters-modal',
    templateUrl: './review-filters-modal.component.html',
    styleUrls: ['./review-filters-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewFiltersModalComponent {
    constructor(public readonly externalInfluencerService: ExternalInfluencerService) {}
}
