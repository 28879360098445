import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-invoice-info',
    template: `
        <h6>{{ 'INVOICING.INFO.TITLE' | translate }}</h6>
        <app-copy-to-clipboard>
            <div [innerHTML]="'INVOICING.INFO.CONTENT' | translate"></div>
        </app-copy-to-clipboard>
        <p>{{ 'INVOICING.INFO.PAYMENT' | translate }}</p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceInfoComponent {}
