import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { insightsDataUri, insightsScoresUri, realEstateGroupIdInsightsUri } from '@app/analytics/constants/uri.constants';
import { ClientSourceDto, InsightsData, ReviewYearOverview } from '@app/real-estate-agent/interfaces/insights';
import { RealEstateGroupProvidersModule } from '@app/real-estate-group/real-estate-group-providers.module';
import { TopicAnalysis } from '@shared/review/interfaces/topic-analysis';
import { ExternalInfluencerReviews, HttpUtils, RxjsService, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: RealEstateGroupProvidersModule })
export class RealEstateGroupInsightsService extends RxjsService {
    constructor(private httpClient: HttpClient) {
        super();
    }

    public getInsightsData(realEstateGroupId: string, locationId: string): Observable<InsightsData> {
        let httpParams: HttpParams;

        if (locationId) {
            httpParams = HttpUtils.buildQueryParameters({ locationId });
        }

        return this.httpClient.get<InsightsData>(StringUtils.parseUri(insightsDataUri, { realEstateGroupId }), {
            params: httpParams,
        });
    }

    public getReviewScores(realEstateGroupId: string): Observable<ExternalInfluencerReviews> {
        return this.httpClient.get<ExternalInfluencerReviews>(StringUtils.parseUri(insightsScoresUri, { realEstateGroupId }));
    }

    public getClientSources(realEstateGroupId: string, locationId?: string): Observable<ClientSourceDto> {
        let httpParams: HttpParams;

        if (locationId) {
            httpParams = HttpUtils.buildQueryParameters({ locationId });
        }

        return this.httpClient.get<ClientSourceDto>(
            `${StringUtils.parseUri(realEstateGroupIdInsightsUri, { realEstateGroupId })}/client-sources`,
            {
                params: httpParams,
            }
        );
    }

    public getYearOverviewPerMonth(realEstateGroupId: string, locationId?: string): Observable<ReviewYearOverview> {
        let httpParams: HttpParams;

        if (locationId) {
            httpParams = HttpUtils.buildQueryParameters({ locationId });
        }
        return this.httpClient.get<ReviewYearOverview>(
            `${StringUtils.parseUri(realEstateGroupIdInsightsUri, { realEstateGroupId })}/year-overview`,
            {
                params: httpParams,
            }
        );
    }

    public getTopicsAnalysis(realEstateGroupId: string, locationId?: string): Observable<TopicAnalysis[]> {
        let httpParams: HttpParams;

        if (locationId) {
            httpParams = HttpUtils.buildQueryParameters({ locationId });
        }

        return this.httpClient.get<TopicAnalysis[]>(
            `${StringUtils.parseUri(realEstateGroupIdInsightsUri, { realEstateGroupId })}/topics-analysis`,
            {
                params: httpParams,
            }
        );
    }
}
