import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { NumberUtils } from '@smooved/core';
import { TelecomSuggestion } from '../../interfaces/telecom-suggestion';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-telecom-suggestion',
    templateUrl: 'telecom-suggestion.component.html',
    styleUrls: ['./telecom-suggestion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelecomSuggestionComponent {
    @Input() public suggestion: TelecomSuggestion;
    @Input() public provider: Provider;

    @Output() public select: EventEmitter<TelecomSuggestion> = new EventEmitter<TelecomSuggestion>();

    public telenet = Provider.Telenet;

    constructor(
        public readonly moveSandbox: MoveSandbox,
        public readonly appUiSandbox: AppUiSandbox
    ) {}

    public hasDiscount(): boolean {
        return NumberUtils.isNumber(this.suggestion?.discount) && this.suggestion?.discount < this.suggestion?.price;
    }

    public hasNoDiscount(): boolean {
        return !this.hasDiscount();
    }
}
