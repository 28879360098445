import { Pipe, PipeTransform } from '@angular/core';
import { ActionAudit, Author } from '@smooved/core';
import { NamePipe } from './name.pipe';

@Pipe({
    name: 'lastModifiedBy',
})
export class LastModifiedByPipe implements PipeTransform {
    constructor(private readonly namePipe: NamePipe) {}

    public transform({ lastModifiedBy, createdBy }: ActionAudit<Author>): string {
        return this.namePipe.transform(lastModifiedBy || createdBy);
    }
}
