import { NgModule } from '@angular/core';
import { TelenetProductInputComponent } from '@app/telecom/components/telenet-product-input/telenet-product-input.component';
import { TranslationModule } from '@app/translation/translation.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PipeModule } from '@smooved/core';
import { SvgModule } from '@smooved/ui';
import { CardModule } from '../card/card.module';
import { SharedModule } from '../shared/shared.module';
import { TelecomSuggestionComponent } from './components/telecom-suggestion/telecom-suggestion.component';
import { TelecomSuggestionsFilterComponent } from './components/telecom-suggestions-filter/telecom-suggestions-filter.component';
import { ProviderContainer } from './containers/provider/provider.container';
import { TelecomBundleContainer } from './containers/telecom-bundle/telecom-bundle.container';
import { TelecomContainer } from './containers/telecom-container/telecom-container.container';
import { TelecomOfferSelectedContainer } from './containers/telecom-offer-selected/telecom-offer-selected.container';
import { TelecomSuggestionsContainer } from './containers/telecom-suggestions/telecom-suggestions.container';
import { TelecomEffects } from './state/telecom.effects';
import { telecomReducer } from './state/telecom.reducer';
import { TelecomRoutingModule } from './telecom-routing.module';

@NgModule({
    declarations: [
        TelecomContainer,
        TelecomBundleContainer,
        ProviderContainer,
        TelecomSuggestionsContainer,
        TelecomSuggestionsFilterComponent,
        TelecomSuggestionComponent,
        TelecomOfferSelectedContainer,
        TelenetProductInputComponent,
    ],
    imports: [
        CardModule,
        TelecomRoutingModule,
        SharedModule,
        SvgModule,
        PipeModule,
        StoreModule.forFeature('telecom', telecomReducer),
        EffectsModule.forFeature([TelecomEffects]),
        TranslationModule,
    ],
    exports: [TelecomBundleContainer, TelenetProductInputComponent],
})
export class TelecomModule {}
