import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateAgentLoginRequest } from '../interfaces';
import { CoreProvidersModule } from '../../core.providers.module';
import { HttpClient } from '@angular/common/http';
import { BASE_URI_CONFIG, BaseUriConfig } from '../../configs/base-uri-config';
import { realEstateAgentLoginUri, logoutUri } from '../constants/uri.constants';

@Injectable({
    providedIn: CoreProvidersModule,
})
@Injectable({ providedIn: CoreProvidersModule })
export class AuthenticationService {
    constructor(
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig,
        private httpClient: HttpClient
    ) {}

    public realEstateAgentLogin(realEstateAgentLoginRequest: RealEstateAgentLoginRequest): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUriConfig.apiUri}${realEstateAgentLoginUri}`, realEstateAgentLoginRequest);
    }

    public logout(): Observable<void> {
        return this.httpClient.get<void>(`${this.baseUriConfig.apiUri}${logoutUri}`);
    }
}
