import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Order } from '@app/order/interfaces/order';
import { ModalData } from '@app/partner/interfaces/modal-data';
import { TelecomDetailService } from '@app/partner/modals/telecom-detail/telecom-detail.service';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { ClosableModalTemplateComponent, UiHeaderVariant } from '@smooved/ui';

@Component({
    selector: 'app-telecom-detail-modal',
    templateUrl: './telecom-detail.modal.html',
    providers: [TelecomDetailService],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TelecomDetailModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;

    public paymentType = PaymentType;
    public uiHeaderVariant = UiHeaderVariant;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalData<Order>,
        public readonly telecomDetailService: TelecomDetailService,
        public readonly authenticationSandbox: AuthenticationSandbox
    ) {}

    public ngOnInit(): void {
        this.telecomDetailService.setOrder(this.data.order);
    }

    public patchMoveByOrder(payload: object): void {
        this.telecomDetailService.patchMoveByOrder(this.data.order._id, payload, this.onPatchHandler);
    }

    public onOrderNoteChange(orderNote: string): void {
        this.telecomDetailService.onOrderNoteChange(this.data.order._id, orderNote, this.onPatchHandler);
    }

    private onPatchHandler = () => {
        this.data.orderUpdated.next(true);
    };
}
