import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges } from '@angular/core';
import { EmojiIcon } from '@app/wizard/icon/models/emoji-icon.enum';
import { UiContext, UiRotate } from '../ui.enums';
import { loadingIcon } from './icon.constants';
import { Brand, UiIconAppearance, UiIconSize } from './icon.enums';

@Component({
    selector: 'app-icon, smvd-ui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
    @Input() public icon: string;
    @Input() public spinning = false;
    @Input() public size = UiIconSize.Default;
    @Input() public appearance = UiIconAppearance.Default;
    @Input() public round = false;
    @Input() public brand: Brand; // Shorthand for  icon=".." appearance="brand"
    @Input() public context: UiContext;
    @Input() public rotate = UiRotate.Default;
    @Input() public loading = false;

    /** depcated: only to be used for EmojiIcon **/
    @Input() public width = 30;
    @Input() public height = 30;

    public classNames = '';
    public isEmojiIcon = false;
    public isFontAwesomeIcon = false;
    public readonly loadingIcon = loadingIcon;

    constructor(public readonly element: ElementRef) {}

    public ngOnChanges(): void {
        this.isEmojiIcon = !!Object.values(EmojiIcon).includes(this.icon as EmojiIcon);
        this.isFontAwesomeIcon = !this.loading && (!!this.brand || this.appearance === UiIconAppearance.Brand);
        this.icon = this.loading ? loadingIcon : this.brand || this.icon;
        this.classNames = this.evaluateClassNames();
    }

    public evaluateClassNames(): string {
        let base = 'notranslate';
        let appearance: string;

        if (this.isFontAwesomeIcon) {
            appearance = `fab fa-${this.icon}`;
        } else {
            base += 'mat-icon mat-icon-no-color';
            appearance = this.appearance && !this.isEmojiIcon ? `material-icons-${this.appearance}` : 'material-icons';
        }
        return `${base} ${appearance} u-rotate-${this.rotate} app-icon--size-${this.size.toLowerCase()} ${this.getContextClass(
            this.context
        )}`;
    }

    private getContextClass(context): string {
        return context ? `u-color-${context}` : '';
    }
}
