import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { I18nKeyService } from './i18n-key.service';

@Pipe({
    name: 'i18nKeyTranslate',
    pure: false,
})
export class I18nKeyTranslatePipe extends TranslatePipe implements PipeTransform {
    constructor(private readonly i18nKeyService: I18nKeyService, translateSevice: TranslateService, cdr: ChangeDetectorRef) {
        super(translateSevice, cdr);
    }
    transform(value: string, keyType: string, interpolateParams: object, emptyAs?: string): string {
        if (!value || !keyType) return this.getReturnValue(value, emptyAs);
        const key = this.i18nKeyService.map(value, keyType);
        return key ? super.transform(key, interpolateParams) : this.getReturnValue(key, emptyAs);
    }

    private getReturnValue(value: string, emptyAs: string): string {
        return emptyAs == null ? value : emptyAs;
    }
}
