import { HttpErrorResponse } from '@angular/common/http';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { createAction, props } from '@ngrx/store';
import {
    BivRealEstateAgent,
    CreateBivRealEstateAgent,
    FeatureScopesConfig,
    RealEstateAgency,
    UpdateBivRealEstateAgent,
} from '@smooved/core';
import { Theme } from '../interfaces/theme.interface';

export enum RealEstateGroupActionTypes {
    SetRealEstateGroup = '[RealEstateGroup] Set real estate group',
    ResetRealEstateGroup = '[RealEstateGroup] Reset real estate group',
    SetLogo = '[RealEstateGroup] Set logo',
    SetTheme = '[RealEstateGroup] Set theme',
    SetLocations = '[RealEstateGroup] Set locations',
    PatchFeatureScopesConfig = '[RealEstateGroup] Patch feature scope config',
    PatchSuccess = '[RealEstateGroup] Patch real estate group success',
    PatchFailure = '[RealEstateGroup] Patch real estate group failure',
    GetBivRealEstateAgents = '[RealEstateGroup] Get real estate group Biv agents',
    SetBivRealEstateAgents = '[RealEstateGroup] Set real estate group Biv agents',
    DeleteBivRealEstateAgent = '[RealEstateGroup] Delete real estate group Biv agent',
    CreateBivRealEstateAgent = '[RealEstateGroup] Insert real estate group Biv agent',
    CreateBivRealEstateAgentSuccess = '[RealEstateGroup] Insert real estate group Biv agent successs',
    PatchBivRealEstateAgent = '[RealEstateGroup] patch real estate group Biv agent',
    PatchBivRealEstateAgentSuccess = '[RealEstateGroup] patch real estate group Biv agent success',
}

export type SetRealEstateGroup = { realEstateGroup: RealEstateGroup };
export type ResetRealEstateGroup = SetRealEstateGroup;
export type SetLocations = { locations: RealEstateAgency[] };
export type SetTheme = { theme: Theme };
export type SetLogo = { logo: string };
export type PatchFeatureScopesConfig = { id: string; featureScopesConfig: FeatureScopesConfig };
export type PatchSuccess = { realEstateGroup: RealEstateGroup };
export type FetchBivRealEstateAgents = { realEstateGroup: RealEstateGroup };
export type SetBivRealEstateAgents = { agents: BivRealEstateAgent[] };
export type DeleteBivRealEstateAgents = { realEstateGroup: RealEstateGroup; agentIds: string[] };
export type InsertBivRealEstateAgent = { realEstateGroup: RealEstateGroup; agent: CreateBivRealEstateAgent };
export type PatchBivRealEstateAgent = { realEstateGroup: RealEstateGroup; agent: UpdateBivRealEstateAgent };
export type CreateBivRealEstateAgentSuccess = PatchSuccess;
export type PatchBivRealEstateAgentSuccess = PatchSuccess;

export const setRealEstateGroup = createAction(RealEstateGroupActionTypes.SetRealEstateGroup, props<SetRealEstateGroup>());
export const resetRealEstateGroup = createAction(RealEstateGroupActionTypes.ResetRealEstateGroup);
export const setLogo = createAction(RealEstateGroupActionTypes.SetLogo, props<SetLogo>());
export const setTheme = createAction(RealEstateGroupActionTypes.SetTheme, props<SetTheme>());
export const setLocations = createAction(RealEstateGroupActionTypes.SetLocations, props<SetLocations>());
export const patchFeatureScopeConfig = createAction(RealEstateGroupActionTypes.PatchFeatureScopesConfig, props<PatchFeatureScopesConfig>());
export const patchSuccess = createAction(RealEstateGroupActionTypes.PatchSuccess, props<PatchSuccess>());
export const patchFailure = createAction(
    RealEstateGroupActionTypes.PatchFailure,
    props<{
        error: HttpErrorResponse;
    }>()
);
export const fetchBivRealEstateAgents = createAction(RealEstateGroupActionTypes.GetBivRealEstateAgents, props<FetchBivRealEstateAgents>());
export const setBivRealEstateAgents = createAction(RealEstateGroupActionTypes.SetBivRealEstateAgents, props<SetBivRealEstateAgents>());
export const deleteBivRealEstateAgents = createAction(
    RealEstateGroupActionTypes.DeleteBivRealEstateAgent,
    props<DeleteBivRealEstateAgents>()
);
export const createBivRealEstateAgent = createAction(
    RealEstateGroupActionTypes.CreateBivRealEstateAgent,
    props<InsertBivRealEstateAgent>()
);
export const createBivRealEstateAgentSuccess = createAction(
    RealEstateGroupActionTypes.CreateBivRealEstateAgentSuccess,
    props<CreateBivRealEstateAgentSuccess>()
);

export const updateBivRealEstateAgent = createAction(RealEstateGroupActionTypes.PatchBivRealEstateAgent, props<PatchBivRealEstateAgent>());
export const updateBivRealEstateAgentSuccess = createAction(
    RealEstateGroupActionTypes.PatchBivRealEstateAgentSuccess,
    props<PatchBivRealEstateAgentSuccess>()
);
