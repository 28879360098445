import {
    ChangeDetectorRef,
    Directive,
    EmbeddedViewRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ObjectUtils, RxjsDirective } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { ScreenSize } from '../ui.enums';
import { UiSandbox } from '../ui.sandbox';

interface TemplateRefWithContext {
    template: TemplateRef<unknown>;
    context?: any;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[collapsible]',
    exportAs: 'collapsible',
})
export class CollapsibleDirective extends RxjsDirective implements OnInit {
    @Input('collapsiblePhone') public phone: boolean;
    @Input('collapsibleTablet') public tablet: boolean;
    @Input('collapsibleDesktop') public desktop: boolean;
    @Input('collapsiblePhoneUp') public phoneUp: boolean;
    @Input('collapsibleTabletUp') public tabletUp: boolean;

    @Input()
    set collapsibleCollapsed(collapsed: boolean) {
        this.isCollapsed = collapsed;
        this.updateView();
        this.isCollapsed ? this.closed.emit() : this.opened.emit();
    }

    @Input()
    set initialCollapsed(collapsed: boolean) {
        this.isCollapsed = collapsed;
        this.updateView();
    }

    @Input()
    set collapsible(content: TemplateRef<unknown> | TemplateRefWithContext) {
        if (ObjectUtils.has(content, 'template')) {
            this.contentRef = (content as TemplateRefWithContext).template;
            this.contentContext = (content as TemplateRefWithContext).context || {};
        } else {
            this.contentRef = content as TemplateRef<any>;
        }
        this.updateView();
    }

    @Output() public opened: EventEmitter<void> = new EventEmitter<void>();
    @Output() public closed: EventEmitter<void> = new EventEmitter<void>();

    public isCollapsed = true;
    private contentRef: TemplateRef<unknown>;
    private contentContext: any = {};

    public contentView: EmbeddedViewRef<unknown>;
    public el: HTMLElement;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private uiSandbox: UiSandbox
    ) {
        super();
        const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.el = view.rootNodes[0] as HTMLElement;
        this.renderer.listen(this.el, 'click', this.toggle);
        this.renderer.addClass(this.el, 'ui-collapsible');
    }

    public ngOnInit(): void {
        this.setResponsive();

        this.uiSandbox.screenSize$.pipe(takeUntil(this.destroy$)).subscribe((screenSize: ScreenSize) => {
            if (
                this.phoneUp ||
                (this.tabletUp && [ScreenSize.Tablet, ScreenSize.Desktop].includes(screenSize)) ||
                (this.phone && screenSize === ScreenSize.Phone) ||
                (this.tablet && screenSize === ScreenSize.Tablet) ||
                (this.desktop && screenSize === ScreenSize.Desktop)
            ) {
                this.renderer.addClass(this.el, 'ui-collapsible-can-collapse');
            } else {
                this.renderer.removeClass(this.el, 'ui-collapsible-can-collapse');
                this.collapsibleCollapsed = false;
            }
        });
    }

    private updateView(): void {
        if (this.isCollapsed) {
            this.contentView?.destroy();
            this.renderer.addClass(this.el, 'collapsed');
        } else {
            if (!this.contentView || this.contentView.destroyed) {
                this.contentView = this.viewContainerRef.createEmbeddedView(this.contentRef, this.contentContext);
                this.cdr.detectChanges();
            }
            this.renderer.removeClass(this.el, 'collapsed');
        }
    }

    public toggle = (): void => {
        this.collapsibleCollapsed = !this.isCollapsed;
    };

    public close(): void {
        this.collapsibleCollapsed = true;
    }

    private setResponsive(): void {
        if ((this.phone ?? this.tablet ?? this.desktop ?? this.tabletUp ?? 'unset') === 'unset') {
            this.phoneUp = true;
        }

        if (this.tabletUp) {
            this.phone = false;
        }

        if (this.tabletUp === false) {
            this.phone = true;
        }
    }
}
