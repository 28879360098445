import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonAppearance } from '../../../button';
import { UiIconAppearance } from '../../../icon';
import { UiContext } from '../../../ui.enums';

@Component({
    selector: 'smvd-ui-verified-badge',
    templateUrl: 'verified-badge.component.html',
    styleUrls: ['verified-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifiedBadgeComponent {
    public readonly iconAppearance = UiIconAppearance;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uiContext = UiContext;
}
