import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { DbUtils, RxjsComponent } from '@smooved/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';

export class MoveData extends RxjsComponent {
    protected moveSubject: BehaviorSubject<Move> = new BehaviorSubject<Move>(null);
    public move$ = this.moveSubject.asObservable().pipe(skipWhile((move) => !move));
    public moveOnce$: Observable<Move> = this.move$.pipe(take(1));

    protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    protected moveLeaverSubject: BehaviorSubject<Move> = new BehaviorSubject<Move>(null);
    public moveLeaver$ = this.moveLeaverSubject.asObservable();

    constructor(protected moveSandbox: MoveSandbox, protected patchedSubject: BehaviorSubject<boolean>) {
        super();
    }

    public reload(): void {
        this.moveOnce$
            .pipe(
                map((move) => DbUtils.getStringId(move)),
                filter((x) => !!x)
            )
            .subscribe((moveId) => {
                this.fetch(moveId);
            });
    }

    public fetch(
        moveId: string,
        callbackMove?: (move: Move) => void,
        callbackMoveLeaver?: (move: Move) => void,
        isPatched?: boolean
    ): void {
        this.loadingSubject.next(true);
        this.moveSandbox
            .get(moveId)
            .pipe(tap(() => this.loadingSubject.next(false)))
            .subscribe((move) => {
                this.updateMove(move, isPatched);
                if (callbackMove) {
                    callbackMove(move);
                }
                if (!!move.linkedMove && move.user?.role === 'transferee') {
                    if ((move.linkedMove as Move)?._id) {
                        // when linkedMove is populated
                        this.updateMoveLeaver(move.linkedMove as Move);
                        if (callbackMoveLeaver) {
                            callbackMoveLeaver(move.linkedMove as Move);
                        }
                    } else {
                        this.moveSandbox.get(move.linkedMove as string).subscribe((linkedMove) => {
                            this.updateMoveLeaver(linkedMove);
                            if (callbackMoveLeaver) {
                                callbackMoveLeaver(linkedMove);
                            }
                        });
                    }
                }
            });
    }

    public updateMove(move: Move, isPatched: boolean = false): void {
        this.moveSubject.next(move);
        if (isPatched && this.patchedSubject) {
            this.patchedSubject.next(true);
        }
    }

    public updateMoveLeaver(move: Move): void {
        this.moveLeaverSubject.next(move);
    }
}
