import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/analytics`;
export const confirmations = `${baseUri}/confirmations`;
export const clusterLevels = `${baseUri}/cluster-levels`;
export const npsSummaryDataUri = `${baseUri}/nps-summary-data`;

export const baseReviewUri = `${environment.apiUri}/review`;
export const npsUnreadCount = `${baseReviewUri}/unread`;
export const npsReviewBucketsUri = `${baseReviewUri}/buckets`;
export const npsReviewsUri = `${baseReviewUri}`;

export const insightsUri = `${baseReviewUri}/insights`;
export const realEstateGroupInsightsUri = `${insightsUri}/real-estate-group`;
export const realEstateGroupIdInsightsUri = `${realEstateGroupInsightsUri}/:realEstateGroupId`;
export const insightsDataUri = `${realEstateGroupIdInsightsUri}/data`;
export const insightsScoresUri = `${realEstateGroupIdInsightsUri}/scores`;
