import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MoveActionTypes } from '@app/move/state/move.actions';
import { tap } from 'rxjs/operators';
import { Sentry } from '@smooved/core';
import { AuthenticationActionTypes, SetAuthorizationAction } from '@app/authentication/state/authentication.actions';
import { setUser } from '@sentry/angular';

@Injectable()
export class SentryEffects {
    constructor(private readonly actions$: Actions) {}

    public onTransactionPatchFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<any>(MoveActionTypes.PatchMoveFailure, MoveActionTypes.PatchPropertyFailure),
                tap((action) => {
                    new Sentry(action.type).forError(action.error).forTransaction(action.transactionId).forRequest(action.request).send();
                })
            ),
        { dispatch: false }
    );

    public onTransactionCreateFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<any>(MoveActionTypes.CreateMoveFailure),
                tap((action) => {
                    new Sentry(action.type).forError(action.payload?.error).forRequest(action.payload?.move).send();
                })
            ),
        { dispatch: false }
    );

    public onAuthorization$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetAuthorizationAction>(AuthenticationActionTypes.SetAuthorization),
                tap((action) => {
                    // when no payload is passed to this action, this means a logoff
                    if (!action.payload) {
                        setUser(null);
                        return;
                    }

                    const { id, role, realEstateAgentId, email, language } = action.payload;
                    setUser({
                        id: id || realEstateAgentId,
                        role,
                        email,
                        language,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );
}
