import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { NpsScore } from '@app/move/interfaces/nps-score';
import { Review } from '@app/reviews/interfaces/review';
import { Interviewee, RealEstateLevel, StringUtils } from '@smooved/core';
import { DurationFilter } from '@smooved/ui';
import { Observable } from 'rxjs';
import { ApplicationSource } from '../../../../projects/ui/src/lib/application/enums/application-source.enum';
import { defaultDurationFilter, defaultNpsLevel } from '../constants/surveys.constants';
import * as uri from '../constants/uri.constants';
import { InterviewInitiatedByType } from '../enums/interview-initiated-by-type.enum';
import { Interview } from '../interfaces/meta/interview';
import { NpsSurvey } from '../interfaces/nps-survey.interface';
import { PendingSurvey } from '../interfaces/pending-survey.interface';

@Injectable({
    providedIn: 'root',
})
export class SurveysService {
    constructor(private httpClient: HttpClient) {}

    public registerNpsSurvey(moveId: string, survey: NpsSurvey): Observable<void> {
        return this.httpClient.post<void>(uri.npsSurveyUri, { moveId, survey });
    }

    public npsScore(level?: RealEstateLevel, interval?: DurationFilter, locationId?: string, marketAverage = false): Observable<NpsScore> {
        return this.httpClient.get<NpsScore>(uri.npsScoreUri, {
            params: {
                level: level || defaultNpsLevel,
                interval: interval || defaultDurationFilter,
                locationId,
                marketAverage: marketAverage.toString(),
            },
        });
    }

    public getInterview(interviewee: Interviewee, initiatedBy: InterviewInitiatedByType): Observable<Interview> {
        return this.httpClient.get<Interview>(uri.interviewUri.replace(':interviewee', interviewee), {
            params: { initiatedBy },
        });
    }

    public sendSurveyRequestEmail(move: Move): Observable<Move> {
        return this.httpClient.post<Move>(uri.requestSurveyByEmail, { moveId: move._id });
    }

    public getPendingSurvey(pendingSurveyId: string): Observable<PendingSurvey> {
        return this.httpClient.get<PendingSurvey>(StringUtils.parseUri(uri.pendingSurveysUri, { id: pendingSurveyId }));
    }

    public updatePendingSurvey(pendingSurveyId: string, review: Partial<Review>, confirmToken: string): Observable<PendingSurvey> {
        return this.httpClient.patch<PendingSurvey>(StringUtils.parseUri(uri.pendingSurveysUri, { id: pendingSurveyId }), {
            review,
            confirmToken,
        });
    }

    public confirmPendingSurvey(pendingSurveyId: string, confirmToken: string): Observable<PendingSurvey> {
        return this.httpClient.patch<PendingSurvey>(StringUtils.parseUri(uri.pendingSurveysConfirmUri, { id: pendingSurveyId }), {
            confirmToken,
            source: ApplicationSource.SmoovedApp,
        });
    }
}
