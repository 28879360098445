import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyPipe, HouseTypePipe, I18nKeyModule, PipeModule as CorePipeModule } from '@smooved/core';
import { ButtonModule } from '../button';
import { CardsModule } from '../cards/cards.module';
import { ChartsModule } from '../charts/charts.module';
import { UiFormModule } from '../form/form.module';
import { IconModule } from '../icon';
import { LoadingModule } from '../loading/loading.module';
import { UiModalModule } from '../modal';
import { NoteModule } from '../note/note.module';
import { PaginationModule } from '../pagination/pagination.module';
import { PipeModule } from '../pipes/pipe.module';
import { RealEstateGroupModule } from '../real-estate-group';
import { SvgModule } from '../svg';
import { TooltipModule } from '../tooltip/tooltip.module';
import { FilterReviewBucketsComponent } from './components/filter-reviews-buckets/filter-reviews-buckets.component';
import { NpsReviewAuthorComponent } from './components/nps-review-author/nps-review-author.component';
import { NpsReviewFacebookHeaderComponent } from './components/nps-review-facebook-header/nps-review-facebook-header.component';
import { NpsReviewGoogleHeaderComponent } from './components/nps-review-google-header/nps-review-google-header.component';
import { NpsReviewItemComponent } from './components/nps-review-item/nps-review-item.component';
import { NpsReviewSmoovedHeaderComponent } from './components/nps-review-smooved-header/nps-review-smooved-header.component';
import { NpsReviewsTemplateComponent } from './components/nps-reviews-template/nps-reviews-template.component';
import { NpsScoreComponent } from './components/nps-score/nps-score.component';
import { ReviewRatingBarComponent } from './components/review-rating-bar/review-rating-bar.component';
import { ReviewTitleGeneratedSummaryComponent } from './components/review-title-generated-summary/review-title-generated-summary.component';
import { ReviewTopicSentimentPillComponent } from './components/review-topic-sentiment-pill/review-topic-sentiment-pill.component';
import { ReviewUpgradePackageComponent } from './components/review-upgrade-package/review-upgrade-package.component';
import { ReviewVerificationBadgeComponent } from './components/review-verification-badge/review-verification-badge.component';
import { ReviewArcScoreComponent } from './components/reviews-arc-score/reviews-arc-score.component';
import { ReviewsBucketsComponent } from './components/reviews-buckets/reviews-buckets.component';
import { ReviewsByLocationComponent } from './components/reviews-by-location/reviews-by-location.component';
import { ReviewsOnlyVerifiedComponent } from './components/reviews-only-verified/reviews-only-verified.component';
import { ReviewsScoreComponent } from './components/reviews-score/reviews-score.component';
import { ReviewsSourcesToggleComponent } from './components/reviews-sources-toggle/reviews-sources-toggle.component';
import { ReviewStarScoreComponent } from './components/reviews-star-score/reviews-star-score.component';
import { ReviewsSegmentsComponent } from './components/segments/reviews-segments.component';
import { StarScoreComponent } from './components/star-score/star-score.component';
import { TextLimitComponent } from './components/text-limit/text-limit.component';
import { NPS_REVIEWS_TEMPLATE_CONFIG, reviewsTemplateConfig } from './constants/reviews.constants';
import { ReviewFiltersModalComponent } from './modals/review-filters-modal/review-filters-modal.component';
import { FormatLinkedRealEstateAgentsPipe, FormatLocationPipe, HighlightSentimentPipe } from './pipes';
import { ArcScoreDataPipe } from './pipes/arc-score-data.pipe';
import { ScoreScalePipe } from './pipes/score-scale.pipe';
import { NpsReviewsFilterService } from './services/nps-reviews-filter.service';
import { ReviewInisghtEffects } from './state/effects';
import { reviewInsightsReducer, reviewInsightsStoreFeatureName } from './state/reducer';
import { ReviewComponent } from './components/review/review.component';
import { ReviewsByRealEstateAgentComponent } from './components/reviews-by-real-estate-agent/reviews-by-real-estate-agent.component';

const components = [
    ReviewsBucketsComponent,
    ReviewsSegmentsComponent,
    NpsReviewsTemplateComponent,
    NpsScoreComponent,
    NpsReviewAuthorComponent,
    NpsReviewItemComponent,
    ReviewFiltersModalComponent,
    ReviewsSourcesToggleComponent,
    FilterReviewBucketsComponent,
    NpsReviewSmoovedHeaderComponent,
    NpsReviewGoogleHeaderComponent,
    NpsReviewFacebookHeaderComponent,
    StarScoreComponent,
    ReviewArcScoreComponent,
    ReviewStarScoreComponent,
    ReviewsScoreComponent,
    TextLimitComponent,
    ReviewUpgradePackageComponent,
    ReviewsOnlyVerifiedComponent,
    ReviewsByLocationComponent,
    ReviewsByRealEstateAgentComponent,
    ReviewRatingBarComponent,
    ReviewTopicSentimentPillComponent,
    ReviewVerificationBadgeComponent,
    ReviewTitleGeneratedSummaryComponent,
    ReviewComponent,
];

const pipes = [ArcScoreDataPipe, ScoreScalePipe, HighlightSentimentPipe, FormatLinkedRealEstateAgentsPipe, FormatLocationPipe];
const slices = [
    StoreModule.forFeature(reviewInsightsStoreFeatureName, reviewInsightsReducer),
    EffectsModule.forFeature([ReviewInisghtEffects]),
];

@NgModule({
    declarations: [...components, ...pipes],
    providers: [
        NpsReviewsFilterService,
        {
            provide: NPS_REVIEWS_TEMPLATE_CONFIG,
            useValue: reviewsTemplateConfig,
        },
        HouseTypePipe,
        EmptyPipe,
    ],
    exports: [...components, ...pipes],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        I18nKeyModule,
        CardsModule,
        PaginationModule,
        MatChipsModule,
        TranslateModule,
        PipeModule,
        LoadingModule,
        UiFormModule,
        ChartsModule,
        CorePipeModule,
        RouterModule,
        IconModule,
        ButtonModule,
        NoteModule,
        TooltipModule,
        SvgModule,
        UiModalModule,
        TooltipModule,
        RealEstateGroupModule,
        ...slices,
    ],
})
export class ReviewsModule {}
