import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { InvoiceStatus } from '@app/invoicing/enums/invoice-status.enum';
import { InvoicingSandbox } from '@app/invoicing/sandboxes/invoicing.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DropdownInput } from '@smooved/ui';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-billing-filter',
    template: `
        <div class="u-flex-row u-flex-align-items-center u-flex-wrap">
            <form
                [formGroup]="realEstateGroupForm"
                class="u-flex-row u-flex-align-items-center white-inputs u-margin-right"
                autocomplete="off"
            >
                <app-real-estate-group-input
                    formControlName="realEstateGroup"
                    width="220px"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [placeholder]="'REAL_ESTATE_AGENT.DASHBOARD.FILTER.SEARCH.PLACEHOLDER' | translate"
                ></app-real-estate-group-input>
            </form>

            <form [formGroup]="filterForm" class="u-flex-row u-flex-align-items-center white-inputs u-flex-wrap">
                <app-quarter-input
                    formControlName="quarter"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right"
                ></app-quarter-input>

                <ui-dropdown-input
                    formControlName="status"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    width="220px"
                    [options]="statusOptions"
                    class="u-margin-right"
                ></ui-dropdown-input>
            </form>
        </div>
    `,
})
export class BillingFilterComponent implements OnInit, OnDestroy {
    @Input() public quarterDate: Date;

    @Output() public filterChange: EventEmitter<{
        realEstateGroup?: { name: string; _id: string };
        status?: InvoiceStatus;
    }> = new EventEmitter();

    public realEstateGroupForm = this.formBuilder.group({
        realEstateGroup: [null],
    });

    public filterForm = this.formBuilder.group({
        quarter: [null],
        status: [''],
    });

    public statusOptions: DropdownInput<InvoiceStatus>[] = [
        {
            id: 'all',
            name: 'status',
            label: this.translateService.instant(`INVOICING.STATUS.ALL`),
            value: '',
        },
        ...Object.keys(InvoiceStatus).map((key) => ({
            id: key,
            name: 'status',
            label: this.translateService.instant(`INVOICING.STATUS.${key.toUpperCase()}`),
            value: InvoiceStatus[key],
        })),
    ];

    private subscriptions: Subscription[] = [];

    constructor(private formBuilder: UntypedFormBuilder, private invoicingSandbox: InvoicingSandbox, private translateService: TranslateService) {}

    public ngOnInit(): void {
        this.filterForm.get('quarter').patchValue(this.quarterDate?.getTime());

        this.subscriptions.push(
            this.realEstateGroupForm.get('realEstateGroup').valueChanges.subscribe((value) => {
                this.filterChange.emit(this.getFilterValue());
            }),
            this.filterForm.get('quarter').valueChanges.subscribe((quarter) => {
                this.invoicingSandbox.setQuarter(new Date(quarter));
            }),
            this.filterForm.get('status').valueChanges.subscribe((status) => {
                this.filterChange.emit(this.getFilterValue());
            })
        );
    }

    public getFilterValue(): { realEstateGroup?: { _id: string; name: string }; status?: InvoiceStatus } {
        const realEstateGroup = this.realEstateGroupForm.get('realEstateGroup')?.value || null;
        const status = this.filterForm.get('status').value ? this.filterForm.get('status').value : null;
        return {
            realEstateGroup,
            status,
        };
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }
}
