<form [formGroup]="form" (ngSubmit)="submit()" class="u-padding-bottom-double-up-to-and-including-phone-landscape">
    <app-loading-container [loading]="loading">
        <div class="__form">
            <app-text-input
                [hasMargin]="false"
                [formControlName]="formControlNames.FirstName"
                [label]="'COMMON.FIRSTNAME' | translate"
                class="__form__first-name"
                width="100%"
            ></app-text-input>
            <app-text-input
                [label]="'COMMON.LASTNAME' | translate"
                class="__form__last-name"
                [hasMargin]="false"
                [formControlName]="formControlNames.LastName"
                width="100%"
            ></app-text-input>
            <smvd-ui-phone-input
                [label]="'COMMON.PHONE' | translate"
                class="__form__phone-number"
                [hasMargin]="false"
                [formControlName]="formControlNames.PhoneNumber"
                width="100%"
            ></smvd-ui-phone-input>
            <app-email-input
                [hasMargin]="false"
                [formControlName]="formControlNames.Email"
                class="__form__email"
                width="100%"
            ></app-email-input>
            <ui-dropdown-input
                [label]="'COMMON.SUBJECT' | translate"
                class="__form__subject"
                [hasMargin]="false"
                [options]="subjectOptions"
                width="100%"
                [formControlName]="formControlNames.Subject"
            ></ui-dropdown-input>

            <div class="__form__message">
                <app-textarea-input
                    [label]="messageLabel | translate"
                    [hasMargin]="false"
                    width="100%"
                    [formControlName]="formControlNames.Message"
                ></app-textarea-input>
                <p *ngIf="messageDescription" class="u-color-muted">{{ messageDescription }}</p>
            </div>

            <smvd-ui-terms-and-conditions-input
                class="__form__consent"
                [custom]="false"
                [formControlName]="formControlNames.Consent"
                width="100%"
            ></smvd-ui-terms-and-conditions-input>

            <re-captcha [formControlName]="formControlNames.Recaptcha" class="__form__recaptcha"></re-captcha>
        </div>
    </app-loading-container>
    <div class="__form__cta u-text-align-center u-text-align-right-tablet-portrait-up u-padding-top">
        <button app-button [disabled]="loading" type="submit" class="u-w100p-up-to-and-including-phone-landscape">
            {{ 'COMMON.SEND' | translate }}
        </button>
    </div>
</form>
