import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-fee',
    template: `
        <ng-container matColumnDef="fee">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'FEE' | translate }}</th>
            <td mat-cell *matCellDef="let row" [title]="row?.feeEnergy | price" class="__cell u-padding-left u-padding-right">
                <div class="u-white-space-no-wrap">{{ row?.feeEnergy | price }}</div>
            </td>
        </ng-container>
    `,
    styles: [
        `
                    .__cell {
                        width: 150px;
                    }
                `,
    ],
})
export class TableColumnFeeComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
