import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { modalRouterOutlet } from '@app/external-influencer/constants/routing.constants';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';
import { FeatureDetailReviewsService } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.service';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { ExternalInfluencer, HttpUtils, RxjsComponent } from '@smooved/core';
import { ButtonAppearance, NotificationSandbox, UiContext } from '@smooved/ui';
import { of } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { menuItems } from './configure-external-influencer.constants';

@Component({
    selector: 'app-configure-external-influencer-modal',
    templateUrl: './configure-external-influencer.modal.html',
})
export class ConfigureExternalInfluencerModal extends RxjsComponent implements OnInit {
    @ViewChild(RouterOutlet) public outlet: RouterOutlet;

    public menuItems = menuItems;
    public buttonAppearance = ButtonAppearance;
    public uiContext = UiContext;
    public loading = false;

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        private readonly router: Router,
        private readonly externalInfluencerService: ExternalInfluencerService,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly featureDetailReviewsService: FeatureDetailReviewsService,
        private readonly notificationSandbox: NotificationSandbox
    ) {
        super();
    }

    public onPublish(): void {
        if (!this.externalInfluencerService.form.value) return;
        this.loading = true;
        this.realEstateGroupSandbox.idOnce$.subscribe((realEstateGroupId) => this.publish(realEstateGroupId));
    }

    public ngOnInit(): void {
        const externalInfluencer = this.featureDetailReviewsService.externalInfluencerSubject.value;
        if (externalInfluencer) {
            this.externalInfluencerService.patchForm(externalInfluencer);
        }
        void this.router.navigate([{ outlets: { [modalRouterOutlet]: 'header' } }]);
        this.fixRouterDeactivation();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.externalInfluencerService.form.reset();
    }

    private publish(realEstateGroupId: string): void {
        const cover = this.externalInfluencerService.form.get(formGroupNames.header).get(formControlNames.cover).value;
        const coverUpdated = this.externalInfluencerService.form.get(formGroupNames.header).get(formControlNames.cover).dirty;
        this.externalInfluencerService
            .publishByFormData(realEstateGroupId)
            .pipe(
                switchMap((externalInfluencer) => {
                    return cover && coverUpdated
                        ? this.externalInfluencerService.updateCover(realEstateGroupId, this.convertCoverInFormData(cover))
                        : of(externalInfluencer);
                }),
                finalize(() => {
                    this.loading = false;
                    this.externalInfluencerService.form.get(formGroupNames.header).get(formControlNames.cover).markAsPristine();
                })
            )
            .subscribe((response) => this.onPublishComplete(response));
    }

    private onPublishComplete = (response: ExternalInfluencer): void => {
        this.notificationSandbox.createdSuccess();
        this.featureDetailReviewsService.externalInfluencerSubject.next(response);
    };

    /**
     * https://newbedev.com/angular-7-multiple-outlets-error-cannot-activate-an-already-activated-outlet
     * @private
     */
    private fixRouterDeactivation(): void {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e) => {
            if (e instanceof ActivationStart && e.snapshot.outlet === modalRouterOutlet) {
                this.outlet.deactivate();
            }
        });
    }

    private convertCoverInFormData = (cover: Blob): FormData => {
        return HttpUtils.addFile(new File([cover], 'name'), 'cover');
    };
}
