import { Component, Input } from '@angular/core';
import { TableDef } from '../../../table/table.interfaces';

@Component({
    selector: 'app-table-modal',
    templateUrl: './table-modal.component.html',
})
export class TableModalComponent<T, U> {
    @Input() public tableDef: TableDef<T>;
    @Input() public items: U[];
}
