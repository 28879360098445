import { MoverRole } from '@app/move/enums/mover-role.enum';
import { CheckInput } from '@smooved/ui';

export const formControlNames = {
    mover: 'mover',
    realEstateAgent: 'realEstateAgent',
    linkedMove: 'linkedMove',
    supplierChecked: 'supplierChecked',
    supplier: 'supplier',
    otherChecked: 'otherChecked',
    other: 'other',
};

const createRealEstateAgentOption = (prefix: string, email: string): CheckInput<boolean> => {
    return {
        id: `${prefix}-real-estate-agent`,
        labelResource: 'REAL_ESTATE_AGENT.LABEL_EMAIL',
        labelParams: { email },
        value: true,
        name: `${prefix}-real-estate-agent`,
    };
};

const createMoverOption = (prefix: string, email: string): CheckInput<boolean> => {
    return {
        id: `${prefix}-mover`,
        labelResource: 'MOVER_EMAIL',
        labelParams: { email },
        value: true,
        name: `${prefix}-mover`,
    };
};

const createLinkedMoveOption = (prefix: string, moverRole: MoverRole, email: string): CheckInput<boolean> => {
    return {
        id: `${prefix}-linkedMove`,
        labelResource: moverRole === MoverRole.Leaver ? 'MOVE.MOVER_ROLE.TRANSFEREE_EMAIL' : 'MOVE.MOVER_ROLE.LEAVER_EMAIL',
        labelParams: { email },
        value: true,
        name: `${prefix}-linkedMove`,
    };
};

const createSupplierOption = (prefix: string): CheckInput<boolean> => {
    return {
        id: `${prefix}-supplier`,
        name: `${prefix}-supplier`,
        value: true,
        labelResource: 'SUPPLIER',
    };
};

const createOtherOption = (prefix: string): CheckInput<boolean> => {
    return {
        id: `${prefix}-other`,
        name: `${prefix}-other`,
        value: true,
        labelResource: 'OTHER',
    };
};

export interface AddresseesOptionsConfig {
    prefix: string;
    moverRole: MoverRole;
    emailMover: string;
    emailRealEstateAgent: string;
    emailLinkedMove: string;
}

interface AddresseeOptions {
    supplierChecked: CheckInput<boolean>;
    otherChecked: CheckInput<boolean>;
    mover?: CheckInput<boolean>;
    realEstateAgent?: CheckInput<boolean>;
    linkedMove?: CheckInput<boolean>;
}

export const createAddresseesOptions = (config: AddresseesOptionsConfig): AddresseeOptions => {
    const result: AddresseeOptions = {
        supplierChecked: createSupplierOption(config.prefix),
        otherChecked: createOtherOption(config.prefix),
    };
    if (config.emailMover) result.mover = createMoverOption(config.prefix, config.emailMover);
    if (config.emailRealEstateAgent) result.realEstateAgent = createRealEstateAgentOption(config.prefix, config.emailRealEstateAgent);
    if (config.emailLinkedMove) result.linkedMove = createLinkedMoveOption(config.prefix, config.moverRole, config.emailLinkedMove);
    return result;
};
