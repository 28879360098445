<div
    class="m-alert {{ context ? 'm-alert--' + context : '' }}  {{ context === uiContext.Text ? '' : 'u-flex-row u-flex-align-items-start' }}
"
    [class.u-border-none]="!border"
    role="alert"
>
    <app-icon *ngIf="icon" [icon]="icon" [context]="iconContext" [appearance]="iconAppearance" [size]="iconSize" class="u-margin-right-half"></app-icon>
    <div>
        <ng-content></ng-content>
    </div>
</div>
