import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { defaultValue, tagifyOptions } from '@app/user/components/mention-input/mention-input.constants';
import { UserGroups } from '@app/user/enums/user-groups.enum';
import { Mention } from '@app/user/interfaces/mention';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import { DbUtils } from '@smooved/core';
import { BaseInput } from '@smooved/ui';
import * as Tagify from '@yaireo/tagify';

@Component({
    selector: 'smvd-app-mention-input',
    templateUrl: './mention-input.component.html',
    styleUrls: ['./mention-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MentionInputComponent),
            multi: true,
        },
    ],
})
export class MentionInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public rows = 3;
    @Input() public ngModelOptions;
    @Input() public userGroups: UserGroups[] = [];

    private tagify: Tagify;
    private innerModel = defaultValue;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly userSandbox: UserSandbox,
        private readonly cdr: ChangeDetectorRef
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.init();
    }

    public writeValue(value: Mention): void {
        const innerValue = value || defaultValue;
        this.innerModel = innerValue;
        if (this.input) {
            this.input.nativeElement.value = innerValue.inputValue;
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
        this.tagify?.setDisabled(isDisabled);
        this.cdr.detectChanges();
    }

    private init(): void {
        this.tagify = new Tagify(this.input.nativeElement, tagifyOptions);

        this.tagify.on('add', this.onAdd);
        this.tagify.on('remove', this.onRemove);
        this.tagify.on('input', this.onInput);

        this.userSandbox.getUsersByUserGroups(...this.userGroups).subscribe((users) => {
            this.tagify.whitelist = users.map((user) => ({
                value: DbUtils.getStringId(user),
                text: user.firstName,
                title: `${user.firstName} ${user.lastName}`,
            }));
        });
    }

    private onAdd = (e) => {
        this.setMentions();
        this.setValues();
        this.emit();
    };

    private onRemove = (e) => {
        this.setMentions();
        this.setValues();
        this.emit();
    };

    private onInput = (e) => {
        this.setValues();
        this.emit();
    };

    private setValues(): void {
        this.innerModel = {
            ...this.innerModel,
            value: this.tagify.DOM.input.textContent.trim(),
            inputValue: this.tagify.DOM.originalInput.value.trim(),
        };
    }

    private emit(): void {
        this.propagateChange(this.innerModel);
        this.cdr.markForCheck();
    }

    private setMentions(): void {
        this.innerModel = {
            ...this.innerModel,
            mentions: this.tagify.value.map((tag) => tag.value),
        };
    }
}
