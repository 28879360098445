import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Address } from '@smooved/core';
import { i18nStreet } from '@smooved/ui';
import { Subscription } from 'rxjs';
import { zipCodeOptional } from '../../validators/zip-code-optional.validator';
import { validateZipCode } from '../../validators/zip-code.validator';

@Component({
    selector: 'app-address',
    template: `
        <label *ngIf="labelResource || label" class="u-display-block u-margin-bottom-half __label">{{
            label || (labelResource | translate)
        }}</label>

        <form [formGroup]="form" class="__form" [ngClass]="{ '__form--small': small }" #formElement="ngForm">
            <ui-address-street-input
                formControlName="street"
                (addressSelected)="onAddressSelected($event)"
                width="auto"
                [label]="i18nStreet | translate"
                [placeholder]="i18nStreet | translate"
                [showLabel]="showLabels"
                [showPlaceholder]="showPlaceholders"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                class="__street"
            ></ui-address-street-input>

            <app-text-input
                formControlName="houseNumber"
                class="__house-number"
                width="auto"
                [placeholder]="showPlaceholders ? ('MOVE.USER.ADDRESS.HOUSE_NUMBER' | translate) : null"
                [label]="showLabels ? ('MOVE.USER.ADDRESS.HOUSE_NUMBER' | translate) : null"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></app-text-input>

            <app-text-input
                formControlName="busNumber"
                width="auto"
                class="__bus-number"
                [placeholder]="showPlaceholders ? ('MOVE.USER.ADDRESS.BUS_NUMBER' | translate) : null"
                [label]="showLabels ? ('MOVE.USER.ADDRESS.BUS_NUMBER' | translate) : null"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></app-text-input>

            <app-text-input
                formControlName="zipCode"
                width="auto"
                class="__zip-code"
                [placeholder]="showPlaceholders ? ('MOVE.USER.ADDRESS.ZIP_CODE' | translate) : null"
                [label]="showLabels ? ('MOVE.USER.ADDRESS.ZIP_CODE' | translate) : null"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></app-text-input>

            <app-text-input
                formControlName="city"
                width="auto"
                class="__city"
                [placeholder]="showPlaceholders ? ('MOVE.USER.ADDRESS.CITY' | translate) : null"
                [label]="showLabels ? ('MOVE.USER.ADDRESS.CITY' | translate) : null"
                [hasMargin]="false"
                [hasMarginDouble]="false"
            ></app-text-input>
        </form>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressComponent),
            multi: true,
        },
    ],
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public label: string;
    @Input() public labelResource: string;
    @Input() public small: boolean;
    @Input() public showLabels = false;
    @Input() public showPlaceholders = true;

    public readonly i18nStreet = i18nStreet;

    /**
     * Possible to turn off required validation.
     * E.g.: this is used in real estate agent edit move dialog.
     */
    @Input() public optional = false;

    @ViewChild('formElement', { static: true }) public formGroupDirective: FormGroupDirective;

    public form: UntypedFormGroup = this.formBuilder.group({
        street: [null],
        houseNumber: [null],
        busNumber: [null],
        zipCode: [null],
        city: [null],
        country: ['BE', [Validators.required]],
    });

    private subscription: Subscription;

    constructor(private formBuilder: UntypedFormBuilder, private changeDetectionRef: ChangeDetectorRef) {}

    public markAsSubmitted(): void {
        this.formGroupDirective.onSubmit(null);
        this.changeDetectionRef.detectChanges();
    }

    public ngOnInit(): void {
        if (this.optional) {
            this.form.get('zipCode').setValidators(zipCodeOptional);
        } else {
            this.form.get('street').setValidators(Validators.required);
            this.form.get('houseNumber').setValidators(Validators.required);
            this.form.get('zipCode').setValidators(validateZipCode);
            this.form.get('city').setValidators(Validators.required);
        }

        this.subscription = this.form.valueChanges.subscribe((value: Address) => {
            if (this.form.invalid) {
                this.propagateChange(null);
            } else {
                this.propagateChange(value);
            }
        });
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form?.disable();
        } else {
            this.form?.enable();
        }
        this.form?.updateValueAndValidity();
    }

    public writeValue(address: Address): void {
        this.form.patchValue(address || {});
    }

    public propagateChange = (_: any) => {};

    public propagateTouched = (_: any) => {};

    public onAddressSelected(event: Address): void {
        this.form.patchValue(event);
        this.propagateChange(event);
        this.form?.updateValueAndValidity();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
