import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RealEstateLevel, StringUtils } from '@smooved/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { NpsScore } from '../../../../../../src/app/move/interfaces/nps-score';
import { defaultNpsLevel } from '../../../../../shared/src/lib/survey/constants/surveys.constants';
import { BASE_URI_CONFIG, BaseUriConfig } from '../../configs';
import { OtherScore, OtherScores, npsScoreWithRealEstateGroupIdUri } from '../constants/reviews.constants';
import { DurationFilter } from '../enums/duration-filter.enum';

@Injectable({
    providedIn: 'root',
})
export class ReviewInsightsService {
    constructor(private httpClient: HttpClient, @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig) {}

    public getReviewScores(
        realEstateGroupId: string,
        level?: RealEstateLevel,
        interval?: DurationFilter,
        locationId?: string,
        marketAverage = false
    ): Observable<NpsScore> {
        return this.httpClient.get<NpsScore>(
            StringUtils.parseUri(npsScoreWithRealEstateGroupIdUri, {
                apiUri: this.baseUriConfig.apiUri,
                realEstateGroupId,
            }),
            {
                params: {
                    level: level || defaultNpsLevel,
                    interval: interval || DurationFilter.AllTime,
                    locationId,
                    marketAverage: marketAverage.toString(),
                },
            }
        );
    }

    public getOtherScores(
        realEstateGroupIds: string[],
        level?: RealEstateLevel,
        interval?: DurationFilter,
        locationId?: string,
        marketAverage = false
    ): Observable<OtherScores> {
        const requests = realEstateGroupIds.map((realEstateGroupId) => {
            return this.getReviewScores(realEstateGroupId, level, interval, locationId, marketAverage).pipe(
                map((score) => ({ id: realEstateGroupId, score: score.score } as OtherScore))
            );
        });

        return forkJoin(requests as unknown as OtherScores);
    }
}
