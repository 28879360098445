<app-two-panel-modal *ngIf="{loading: loading, data: realEstateGroupAppointment} as vm" [loading]="vm.loading">
    <ng-container modal-header>
        <h5>{{vm.data?.firstName}} {{vm.data?.lastName}}</h5>
    </ng-container>

    <ng-container modal-left>
        <app-menu>
            <app-menu-item
                *ngFor="let item of menuItems"
                [active]="active === item.goTo"
                (click)="setActive(item)"
                [appearance]="menuItemAppearance.Button"
                [size]="menuItemSize.Small"
                >{{ item.labelResource | translate }}</app-menu-item
            >
        </app-menu>
    </ng-container>

    <ng-container modal-right [ngSwitch]="active" *ngIf="vm.data">
        <main *ngSwitchCase="menu.Detail">
            <section class="u-flex-column u-margin-bottom-double">
                <h6 class="u-color-muted u-margin-bottom-half">
                    {{'PERSONAL' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}
                </h6>
                <span>{{vm.data.firstName}} {{vm.data.lastName}}</span>
                <a *ngIf="vm.data.email as email" [href]="email | uriSchema: uriSchemaType.MailTo">{{email}}</a>
                <a *ngIf="vm.data.phoneNumber as phoneNumber" [href]="phoneNumber | uriSchema: uriSchemaType.Tel">{{phoneNumber}}</a>
                <span>{{vm.data.address | formatAddress}}</span>
                <span>{{vm.data.language}}</span>
            </section>

            <section class="u-flex-column">
                <h6 class="u-color-muted u-margin-bottom-half">
                    {{'APPOINTMENT' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}
                </h6>
                <span *ngIf="vm.data.source as source">{{'COMMON.SOURCE' | translate}}: {{vm.data.source}}</span>
                <span *ngIf="vm.data.from as from">{{'COMMON.FROM' | translate}}: {{vm.data.from}}</span>
                <span *ngIf="vm.data.to as to">{{'COMMON.TO' | translate}}: {{to}}</span>
                <span *ngIf="vm.data.transactionDueTime as transactionDueTime"
                    >{{'TRANSACTION_DUE_TIME' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}:
                    {{transactionDueTime}}</span
                >
                <span *ngIf="vm.data.subject as subject">{{'COMMON.SUBJECT' | translate}}: {{subject}}</span>
                <span *ngIf="vm.data.message as message">{{'COMMON.MESSAGE' | translate}}: {{message}}</span>
                <span *ngIf="vm.data.estimationMin as estimationMin"
                    >{{'ESTIMATION_MIN' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}: {{estimationMin}}</span
                >
                <span *ngIf="vm.data.estimationMax as estimationMax"
                    >{{'ESTIMATION_MAX' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}: {{estimationMax}}</span
                >
                <span *ngIf="vm.data.noBedrooms as noBedrooms"
                    >{{'NO_BEDROOMS' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}: {{noBedrooms}}</span
                >
                <span *ngIf="vm.data.livingArea as livingArea"
                    >{{'LIVING_AREA' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}: {{livingArea}}</span
                >
                <span *ngIf="vm.data.propertyType as propertyType"
                    >{{'PROPERTY_TYPE' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}: {{propertyType}}</span
                >
                <span *ngIf="vm.data.moveTransactionType as moveTransactionType"
                    >{{'MOVE_TRANSACTION_TYPE' | i18nKeyTranslate: uiI18nKeyType.RealEstateGroupAppointmentDetail}}:
                    {{moveTransactionType}}</span
                >
            </section>
        </main>
    </ng-container>
</app-two-panel-modal>
