import { Gender } from '@app/move/enums/gender.enum';
import { Move } from '@app/move/interfaces/move';
import { HomeConstructionYear, HomeDescriptionSize } from '@app/shared/state/shared.reducer';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { HomeDescription } from '@smooved/core';
import { ElectricityMeterType } from '@smooved/ui';
import { Transferee } from '../enums/transferee.enum';
import { MoveActions } from './move.actions';
import { moveReducer } from './move.reducer';

// moving date
const d = new Date();
d.setDate(d.getDate() + 10);
const movingDate = d;

const d2 = new Date();
d2.setDate(d2.getDate() - 1000);
const dateOfBirth = d2;

export const initialMoveStateMock = {
    telecomOffer: {
        telecomBundle: {
            internet: true,
            digitalTelevision: true,
            mobilePhone: true,
            fixedLine: true,
        },
        providerChoice: Provider.Proximus,
    },
    movingDate: movingDate,
    user: {
        firstName: 'Laurens',
        lastName: 'Vanpoucke',
        gender: Gender.Male,
        phoneNumber: '0477525252',
        email: 'laurens.vanpoucke@smooved.be',
        dateOfBirth: dateOfBirth,
        currentAddress: {
            street: 'Antwerpsesteenweg',
            houseNumber: '72',
            zipCode: '2800',
            city: 'Mechelen',
            country: 'BE',
        },
        movingAddress: {
            street: 'Olmenlaan',
            houseNumber: '2',
            zipCode: '3191',
            city: 'Hever',
            country: 'BE',
        },
        passportNumber: '123456789456',
        landAgent: null,
        contactAddress: {
            street: 'Olmenlaan',
            houseNumber: '2',
            zipCode: '3191',
            city: 'Hever',
            country: 'BE',
        },
        transfereeType: Transferee.OwnerTransferee,
    },
    energyOrdered: false,
    energyOffer: {
        energyType: EnergyType.Electricity,
        meterType: ElectricityMeterType.Double,
        hasSolarPanels: true,
    },
    meta: {
        familyMemberCount: {
            children: 0,
            teenagers: 0,
            adults: 2,
        },
        homeDescription: HomeDescription.HalfOpen,
        homeDescriptionSize: HomeDescriptionSize.Size125to200,
        homeDescriptionSizeInput: 126.5,
        homeDescriptionApartmentLevel: null,
        homeConstructionYear: HomeConstructionYear.NewConstruction,
        bedrooms: 3,
        otherRoomsLargerThanFourSquaredMeter: 2,
    },
    telecomSelected: false,
    telecomOrdered: false,
    energySelected: true,
    insurancesSelected: true,
};

export function moveReducerMock(state = { ...initialMoveStateMock }, action: MoveActions): Move {
    return moveReducer(state, action);
}
