import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { ClickStopPropagation } from './click-stop-propagation.directive';
import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete.directive';
import { InvisibleDirective } from './invisible.directive';
import { IsBrowserDirective } from './is-browser.directive';
import { ShowIfNumberDirective } from './show-if-number.directive';

const directives = [
    ShowIfNumberDirective,
    InvisibleDirective,
    AutoFocusDirective,
    ClickStopPropagation,
    IsBrowserDirective,
    GooglePlacesAutocompleteDirective,
];

@NgModule({
    imports: [CommonModule],
    declarations: [...directives],
    exports: [...directives],
})
export class DirectivesModule {}
