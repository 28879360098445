import { Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-contracts-check-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-flex-column u-flex-align-items-center">
                    <img class="u-margin-bottom-double" width="220" src="assets/images/smooved-with-van.png" alt="Smooved" />

                    <h3
                        *ngIf="moveSandbox.canOrder$({ energy: true, telecom: true, insurances: true }) | async"
                        class="u-margin-bottom-double"
                    >
                        {{ 'CONTRACTS.CHECK.ALL.TITLE' | translate }}
                    </h3>

                    <h3
                        *ngIf="!(moveSandbox.canOrder$({ energy: true, telecom: true, insurances: true }) | async)"
                        class="u-margin-bottom-double"
                    >
                        {{ 'CONTRACTS.CHECK.TITLE' | translate }}
                    </h3>

                    <p
                        *ngIf="moveSandbox.canOrder$({ energy: true, telecom: false, insurances: false }) | async"
                        class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium"
                        [innerHTML]="
                            translationKeyOneEnergy$
                                | async
                                | translate
                                    : {
                                          product:
                                              (moveSandbox.energySuggestionSelection$ | async)?.nameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.energySuggestionSelection$ | async),
                                                            label: 'name'
                                                        }
                                                    }
                                              | async
                                      }
                        "
                    ></p>

                    <p
                        *ngIf="moveSandbox.canOrder$({ energy: false, telecom: true, insurances: false }) | async"
                        class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium"
                        [innerHTML]="
                            translationKeyOneTelecom$
                                | async
                                | translate
                                    : {
                                          product:
                                              (moveSandbox.telecomOfferSelection$ | async)?.productNameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.telecomOfferSelection$ | async),
                                                            label: 'productName'
                                                        }
                                                    }
                                              | async
                                      }
                        "
                    ></p>

                    <p
                        *ngIf="moveSandbox.canOrder$({ energy: true, telecom: true, insurances: false }) | async"
                        class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium"
                        [innerHTML]="
                            translationKeyForTwo$
                                | async
                                | translate
                                    : {
                                          product1:
                                              (moveSandbox.energySuggestionSelection$ | async)?.nameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.energySuggestionSelection$ | async),
                                                            label: 'name'
                                                        }
                                                    }
                                              | async,
                                          product2:
                                              (moveSandbox.telecomOfferSelection$ | async)?.productNameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.telecomOfferSelection$ | async),
                                                            label: 'productName'
                                                        }
                                                    }
                                              | async
                                      }
                        "
                    ></p>

                    <p
                        *ngIf="moveSandbox.canOrder$({ energy: true, telecom: true, insurances: true }) | async"
                        class="u-margin-bottom-double u-container-small u-text-align-center u-font-size-medium"
                        [innerHTML]="
                            translationKeyForAll$
                                | async
                                | translate
                                    : {
                                          product1:
                                              (moveSandbox.energySuggestionSelection$ | async)?.nameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.energySuggestionSelection$ | async),
                                                            label: 'name'
                                                        }
                                                    }
                                              | async,
                                          product2:
                                              (moveSandbox.telecomOfferSelection$ | async)?.productNameLabels
                                              | translatedLabel
                                                  : {
                                                        fallback: {
                                                            object: (moveSandbox.telecomOfferSelection$ | async),
                                                            label: 'productName'
                                                        }
                                                    }
                                              | async,
                                          product3: 'Ethias'
                                      }
                        "
                    ></p>

                    <div class="u-flex-column u-flex-align-items-center">
                        <app-button class="u-margin-bottom" [routerLink]="contractLink">{{
                            'CONTRACTS.PREPARE' | translate: { amount: (moveSandbox.amountCanOrder$ | async) }
                        }}</app-button>
                        <a [routerLink]="insurancesLink" class="u-link">{{ 'CONTRACTS.CHECK.ENERGY_TELECOM.INSURANCE' | translate }}</a>
                    </div>
                </app-card>
            </div>
        </app-card-container>
    `,
})
export class ContractsCheckContainer {
    public contractLink = `/${Navigation.Contract}`;
    public insurancesLink = `/${Navigation.Insurances}`;

    public translationKeyOneEnergy$: Observable<string> = this.moveSandbox.energyTransfer$.pipe(
        map((energyTransfer) => {
            if (energyTransfer) {
                return 'CONTRACTS.CHECK.ONE.ENERGY_TRANSFER.TEXT';
            } else {
                return 'CONTRACTS.CHECK.ONE.TEXT';
            }
        })
    );

    public translationKeyOneTelecom$: Observable<string> = this.moveSandbox.telecomTransfer$.pipe(
        map((telecomTransfer) => {
            if (telecomTransfer) {
                return 'CONTRACTS.CHECK.ONE.ENERGY_TRANSFER.TEXT';
            } else {
                return 'CONTRACTS.CHECK.ONE.TEXT';
            }
        })
    );

    public translationKeyForTwo$: Observable<string> = combineLatest(
        this.moveSandbox.energyTransfer$,
        this.moveSandbox.telecomTransfer$
    ).pipe(
        map(([energyTransfer, telecomTransfer]) => {
            if (!!energyTransfer && !!telecomTransfer) {
                return 'CONTRACTS.CHECK.TWO.ENERGY_AND_TELECOM_TRANSFER.TEXT';
            } else if (energyTransfer) {
                return 'CONTRACTS.CHECK.TWO.ENERGY_TRANSFER.TEXT';
            } else if (telecomTransfer) {
                return 'CONTRACTS.CHECK.TWO.TELECOM_TRANSFER.TEXT';
            } else {
                return 'CONTRACTS.CHECK.TWO.TEXT';
            }
        })
    );

    public translationKeyForAll$: Observable<string> = combineLatest(
        this.moveSandbox.energyTransfer$,
        this.moveSandbox.telecomTransfer$
    ).pipe(
        map(([energyTransfer, telecomTransfer]) => {
            if (!!energyTransfer && !!telecomTransfer) {
                return 'CONTRACTS.CHECK.ALL.ENERGY_AND_TELECOM_TRANSFER.TEXT';
            } else if (energyTransfer) {
                return 'CONTRACTS.CHECK.ALL.ENERGY_TRANSFER.TEXT';
            } else if (telecomTransfer) {
                return 'CONTRACTS.CHECK.ALL.TELECOM_TRANSFER.TEXT';
            } else {
                return 'CONTRACTS.CHECK.ALL.TEXT';
            }
        })
    );

    constructor(public readonly moveSandbox: MoveSandbox) {}
}
