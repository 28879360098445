export enum adminMeterInfoForm {
    MovingDate = 'movingDate',
    MeterReadingDate = 'meterReadingDate',
    HasGas = 'hasGas',
    HasElectricity = 'hasElectricity',
    EnergyType = 'energyType',
    MeterReadingsTakeover = 'meterReadingsTakeover',
}

export const confirmUncheck = 'ADMIN.DASHBOARD.DETAIL.METER_READINGS.CONFIRM_UNCHECK';
