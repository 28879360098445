import { HeaderPartnerAgentMenuConfig } from '@app/header/interfaces/header-partner-agent-menu-config.interface';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import * as routing from '@app/partner/constants/routing.constants';
import { ObjectUtils } from '@smooved/core';

export const defaultConfig: HeaderPartnerAgentMenuConfig = {
    menuItems: {
        open: {
            title: 'PARTNER.ORDER_LIST.OPEN.LINK_LABEL',
            url: AppNavigationSandbox.getAbsolute(routing.orderListOpenRouting),
            show: true,
        },
        inProgress: {
            title: 'PARTNER.ORDER_LIST.IN_PROGRESS.LINK_LABEL',
            url: AppNavigationSandbox.getAbsolute(routing.orderListInProgressRouting),
            show: false,
        },
        closed: {
            title: 'PARTNER.ORDER_LIST.CLOSED.LINK_LABEL',
            url: AppNavigationSandbox.getAbsolute(routing.orderListClosedRouting),
            show: true,
        },
    },
};

export const rentalInspectionConfig = ObjectUtils.objectAssign<HeaderPartnerAgentMenuConfig>({}, defaultConfig, {
    menuItems: {
        inProgress: { show: true },
        closed: { title: 'PARTNER.ORDER_LIST.CLOSED.LINK_LABEL_ALT', show: true },
    },
});

export const giftConfig = ObjectUtils.objectAssign<HeaderPartnerAgentMenuConfig>({}, defaultConfig, {
    menuItems: { closed: { title: 'PARTNER.ORDER_LIST.CLOSED.LINK_LABEL_SENT', show: true } },
});
