import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreProvidersModule } from '../core.providers.module';
import { I18nKeyTypeMap } from './i18n-key-type-map';
import { I18N_KEY_TYPE_MAP } from './i18n-key-type-map.token';

@Injectable({ providedIn: CoreProvidersModule })
export class I18nKeyService {
    constructor(@Inject(I18N_KEY_TYPE_MAP) private i18nKeyTypeMap: I18nKeyTypeMap, private readonly translateService: TranslateService) {}

    public map(value: string, keyType: string): string {
        if (!value) return '';
        return `${this.i18nKeyTypeMap[keyType]}.${value.toString().toUpperCase().replace(/-/gi, '_')}`;
    }

    public instant(value: string, keyType: string, interpolateParams?: object): string {
        return this.translateService.instant(this.map(value, keyType), interpolateParams);
    }
}
