import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AuthenticationSandbox } from '../../../authentication/sandboxes/authentication.sandbox';
import { AddressComponent } from '../../../form/components/address/address.component';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';
import { EmojiIcon } from '../../../wizard/icon/models/emoji-icon.enum';

@Component({
    selector: 'app-start',
    template: `
        <main class="u-flex-column u-flex-align-items-start u-w100p" [ngSwitch]="authenticationSandbox.isRealEstateAgent$ | async">
            <h3 class="u-margin-bottom-double">Hello! Vertel eens...</h3>

            <form novalidate class="u-flex-column u-w100p" (ngSubmit)="onSubmit()" [formGroup]="form">
                <app-text-input
                    [id]="'first-name'"
                    [width]="'100%'"
                    [label]="
                        (authenticationSandbox.isRealEstateAgent$ | async)
                            ? ('FORM.FIRST_NAME_MOVER.LABEL' | translate)
                            : ('FORM.FIRST_NAME.LABEL' | translate)
                    "
                    [placeholder]="'FORM.FIRST_NAME.PLACEHOLDER' | translate"
                    [formControlName]="'firstName'"
                ></app-text-input>

                <app-moving-date-input
                    [width]="'100%'"
                    [label]="'FORM.MOVING_DATE.LABEL' | translate"
                    [placeholder]="'FORM.MOVING_DATE.PLACEHOLDER' | translate"
                    [formControlName]="'movingDate'"
                ></app-moving-date-input>

                <app-address
                    [formControlName]="'movingAddress'"
                    class="u-margin-bottom"
                    [labelResource]="
                        (authenticationSandbox.isRealEstateAgent$ | async)
                            ? 'MOVE.USER.MOVING_ADDRESS.LABEL.REAL_ESTATE_AGENT'
                            : 'MOVE.USER.MOVING_ADDRESS.LABEL.MOVER'
                    "
                ></app-address>

                <app-powered-by class="u-margin-bottom-double"></app-powered-by>

                <div class="u-flex-column u-flex-align-items-center">
                    <app-button type="submit">
                        <div class="u-flex-row u-flex-align-items-center">
                            <span class="u-margin-right-half">Get me <strong>smooved</strong>!</span>
                            <app-icon [icon]="emojiIcon.Rocket"></app-icon>
                        </div>
                    </app-button>

                    <div *ngIf="!(authenticationSandbox.isLoggedIn$ | async)" class="u-w100p">
                        <div class="__or u-text-align-center u-margin-bottom u-margin-top">
                            <span class="__or__text u-padding-left-half u-padding-right-half">Of</span>
                        </div>
                        <app-login-button [extraClass]="'secondary'"></app-login-button>
                    </div>
                </div>
            </form>
        </main>
    `,
    styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
    public emojiIcon = EmojiIcon;

    public form: UntypedFormGroup = this.formBuilder.group({
        firstName: [null, [Validators.required]],
        movingDate: [null, [Validators.required]],
        movingAddress: [null, Validators.required],
    });

    @ViewChild(AddressComponent, { static: true }) public addressComponent: AddressComponent;

    constructor(
        public authenticationSandbox: AuthenticationSandbox,
        private formBuilder: UntypedFormBuilder,
        private moveSandbox: MoveSandbox,
        private dialog: MatDialog,
        private navigationSandbox: AppNavigationSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.userOnce$.subscribe((user) => {
            if (user) {
                this.firstNameFormControl().patchValue(user.firstName);
                this.movingAddressFormControl().patchValue(user.movingAddress);
            }
        });

        this.moveSandbox.movingDate$.pipe(take(1)).subscribe((movingDate) => {
            this.movingDateFormControl().patchValue(movingDate);
        });
    }

    public onSubmit(): void {
        this.addressComponent.markAsSubmitted();
        if (this.form.valid) {
            this.moveSandbox.patchMoveState('user', '', {
                firstName: this.form.value.firstName,
                movingAddress: this.form.value.movingAddress,
            });
            this.moveSandbox.patchMoveState('movingDate', '', this.form.value.movingDate);
            this.authenticationSandbox.isRealEstateAgentOnce$.subscribe((isRealEstateAgent) => {
                if (isRealEstateAgent) {
                    this.navigationSandbox.goToEnergyFlow().then();
                } else {
                    this.navigationSandbox.goToApp().then();
                }
            });
        }
    }

    private firstNameFormControl(): AbstractControl {
        return this.form.get('firstName');
    }

    private movingDateFormControl(): AbstractControl {
        return this.form.get('movingDate');
    }

    private movingAddressFormControl(): AbstractControl {
        return this.form.get('movingAddress');
    }
}
