import { Action, createReducer, on } from '@ngrx/store';
import { RealEstateAgency } from '@smooved/core';
import { getLocations, getLocationsFailure, getLocationsSuccess } from './actions';

export const realEstateGroupFeatureKey = 'realEstateGroup';

export interface RealEstateGroupState {
    locationsLoading: boolean;
    locations: RealEstateAgency[];
}

export const initialState: RealEstateGroupState = {
    locationsLoading: false,
    locations: null,
};

export const realEstateGroupReducer = (state: RealEstateGroupState, action: Action): RealEstateGroupState => {
    return createReducer(
        initialState,
        on(getLocations, (state) => ({ ...state, locationsLoading: true })),
        on(getLocationsSuccess, (state, { data }) => ({ ...state, locations: data, locationsLoading: false })),
        on(getLocationsFailure, (state) => ({ ...state, locationsLoading: false }))
    )(state, action);
};
