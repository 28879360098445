import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nKeyPipe, I18nKeyType, Unit } from '@smooved/core';
import { isString } from 'lodash';

@Pipe({
    name: 'pricePerKwh',
})
export class PricePerKwhPipe implements PipeTransform {
    transform(value: string | number, args?: any): string {
        if (isString(value)) return value;
        if (!value) return this.doTransform(0);
        return this.doTransform(value);
    }

    constructor(
        private readonly i18nKeyPipe: I18nKeyPipe,
        private readonly currencyPipe: CurrencyPipe,
        private readonly translate: TranslateService
    ) {}

    private doTransform(value): string {
        const price = this.currencyPipe.transform(value, 'EUR', 'symbol', null, 'nl-BE');
        const perKwh = this.translate.instant(this.i18nKeyPipe.transform(Unit.Electricity, I18nKeyType.Unit)) as string;
        return `${price}/${perKwh}`;
    }
}
