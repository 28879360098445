import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetFamilialSuggestionRequest } from '@app/iots/interfaces/get-familial-suggestion-request';
import { GetInsurancesSuggestionsRequest } from '@app/iots/interfaces/get-insurances-suggestions-request';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';
import { Role, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import * as uri from '../constants/uri.constants';

@Injectable({
    providedIn: 'root',
})
export class InsurancesService {
    constructor(private httpClient: HttpClient) {}

    public getSuggestions(getInsurancesSuggestionsRequest: GetInsurancesSuggestionsRequest): Observable<InsurancesSuggestion[]> {
        const httpParams: HttpParams = new HttpParams()
            .set('homeDescription', getInsurancesSuggestionsRequest?.homeDescription || null)
            .set('homeDescriptionApartmentLevel', getInsurancesSuggestionsRequest?.homeDescriptionApartmentLevel?.toString() || null)
            .set(
                'dateOfBirth',
                getInsurancesSuggestionsRequest?.dateOfBirth ? new Date(getInsurancesSuggestionsRequest?.dateOfBirth)?.toISOString() : null
            )
            .set('movingAddress', JSON.stringify(getInsurancesSuggestionsRequest?.movingAddress) || null)
            .set('bedrooms', getInsurancesSuggestionsRequest?.roomDivision?.bedrooms?.toString() || null)
            .set('livingRooms', getInsurancesSuggestionsRequest?.roomDivision?.livingRooms?.toString() || null)
            .set('kitchens', getInsurancesSuggestionsRequest?.roomDivision?.kitchens?.toString() || null)
            .set('bathrooms', getInsurancesSuggestionsRequest?.roomDivision?.bathrooms?.toString() || null)
            .set('verandas', getInsurancesSuggestionsRequest?.roomDivision?.verandas?.toString() || null)
            .set('attics', getInsurancesSuggestionsRequest?.roomDivision?.attics?.toString() || null)
            .set('basements', getInsurancesSuggestionsRequest?.roomDivision?.basements?.toString() || null)
            .set('parkingSpotsInGarage', getInsurancesSuggestionsRequest?.roomDivision?.parkingSpotsInGarage?.toString() || null)
            .set(
                'otherRoomsLargerThanFourSquaredMeter',
                getInsurancesSuggestionsRequest?.roomDivision?.otherRoomsLargerThanFourSquaredMeter?.toString() || null
            )
            .set(
                'outhouseLargerThanTenSquaredMeter',
                getInsurancesSuggestionsRequest?.roomDivision?.outhouseLargerThanTenSquaredMeter?.toString() || null
            )
            .set('createdByRole', getInsurancesSuggestionsRequest?.createdByRole || Role.Guest);

        return this.httpClient.get<InsurancesSuggestion[]>(uri.suggestionsUri, {
            params: httpParams,
        });
    }

    public getFamilial(getFamilialSuggestionRequest: GetFamilialSuggestionRequest): Observable<InsurancesSuggestion> {
        const httpParams: HttpParams = new HttpParams()
            .set('quoteId', getFamilialSuggestionRequest?.quoteId || null)
            .set('insurer', getFamilialSuggestionRequest?.insurer || null);

        return this.httpClient.get<InsurancesSuggestion>(uri.familialUri, {
            params: httpParams,
        });
    }

    public getContractUrl(quoteId: string): Observable<{ downloadUrl: string }> {
        return this.httpClient.get<{ downloadUrl: string }>(StringUtils.parseUri(uri.contractUri, { quoteId }));
    }
}
