import { Component, OnInit } from '@angular/core';
import { ChartItem } from '@smooved/ui';
import { tap } from 'rxjs/operators';
import { colors, labelKeys, legend, stacks } from './confirmations-cluster-levels-chart.constants';
import { ConfirmationsClusterLevelsChartService } from './confirmations-cluster-levels-chart.service';

@Component({
    selector: 'app-confirmations-cluster-levels-chart',
    templateUrl: 'confirmations-cluster-levels-chart.component.html',
    styleUrls: ['confirmations-cluster-levels-chart.component.scss'],
    providers: [ConfirmationsClusterLevelsChartService],
})
export class ConfirmationsClusterLevelsChartComponent implements OnInit {
    public data: ChartItem[] = [];
    public loaded = false;

    public stacks = stacks;
    public colors = colors;
    public legend = legend;
    public cardTitle = labelKeys.title;

    constructor(private readonly confirmationsClusterLevelsChartService: ConfirmationsClusterLevelsChartService) {}

    public ngOnInit(): void {
        this.initChart();
    }

    private initChart(): void {
        this.confirmationsClusterLevelsChartService
            .getData()
            .pipe(tap(() => (this.loaded = true)))
            .subscribe((data) => (this.data = data));
    }
}
