import { Injectable } from '@angular/core';
import { UrlUtils } from '@smooved/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MoveSandbox } from '../../move/sandboxes/move.sandbox';
import { RealEstateGroupSandbox } from '../../real-estate-group/sandboxes/real-estate-group.sandbox';
import { ReferrerType } from '../enums/referrer-type.enum';

@Injectable({
    providedIn: 'root',
})
export class ReferrerSandbox {
    constructor(
        private moveSandbox: MoveSandbox,
        private realEstateGroupSandbox: RealEstateGroupSandbox
    ) {}

    public init(): void {
        const referrer = this.getReferrerFromUrl();
        const referrerType = this.getReferrerTypeFromUrl();
        if (referrer && referrerType) {
            this.moveSandbox.patchProperty(
                '',
                {
                    referrer,
                    referrerType,
                },
                true
            );

            if (referrerType === ReferrerType.RealEstateAgent.toString()) {
                this.realEstateGroupSandbox
                    .getLogo(referrer)
                    .pipe(
                        map((response) => response.logo),
                        catchError((errorResponse) => of())
                    )
                    .subscribe((logo: string) => {
                        this.realEstateGroupSandbox.setLogo(logo);
                    });
            }
        }
    }

    public getRealEstateAgentId(): string {
        const referrer = this.getReferrerFromUrl();
        const referrerType = this.getReferrerTypeFromUrl();
        if (!referrer || referrerType !== ReferrerType.RealEstateAgent) return null;
        return referrer;
    }

    public getReferrerTypeFromUrl(): ReferrerType {
        return UrlUtils.getQueryParam('referrerType') as ReferrerType;
    }

    public getReferrerFromUrl(): string {
        return UrlUtils.getQueryParam('referrer');
    }
}
