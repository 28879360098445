<app-closable-modal-template maxWidth="auto" #closableModalRef>
    <ng-container modal-header>
        <h3>{{'MOVE.DELETE.TITLE' | translate}}</h3>
    </ng-container>

    <form [formGroup]="form" class="u-w40vw" (submit)="onSubmit()">
        <div class="u-margin-x-axis-double">
            <p class="u-color-error u-margin-bottom-double u-margin-top">{{'MOVE.DELETE.WARNING' | translate}}</p>
            <app-check-input
                *ngIf="data?.linkedMove"
                [custom]="false"
                [option]="removeLinkedMoveOption"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [formControlName]="formFields.RemoveLinkedMove"
            ></app-check-input>
            <app-textarea-input
                [formControlName]="formFields.Reason"
                [label]="'MOVE.DELETE.REASON.LABEL' | translate"
                [placeholder]="'MOVE.DELETE.REASON.PLACEHOLDER' | translate"
                class="u-w100p"
                [rows]="5"
                width="auto"
                maxWidth="auto"
            ></app-textarea-input>
        </div>

        <div class="u-flex-row u-flex-justify-content-flex-end">
            <button app-button (click)="closableModalRef.close()" [appearance]="buttonAppearance.Link" [context]="buttonContext.Primary">
                {{'CANCEL' | translate}}
            </button>
            <button app-button type="submit" [context]="buttonContext.Secondary">{{'SUBMIT' | translate}}</button>
        </div>
    </form>
</app-closable-modal-template>
