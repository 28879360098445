import { EnergyOrderedConfirmation } from '@app/analytics/enums/energy-ordered-confirmation.enum';
import { CreatedByFlowEnum, DateUtils } from '@smooved/core';
import { ChartLegend, Colors, ColorScale } from '@smooved/ui';

const i18n = 'SMOOVED_ANALYTICS.DETAILS.CONFIRMATION';
export const stackColors = [ColorScale.Color3, ColorScale.Color2, ColorScale.Color1, Colors.Danger, ColorScale.Color4];

export const otsTypes = [CreatedByFlowEnum.Eots, CreatedByFlowEnum.Iots];
export const stacks = Object.values(EnergyOrderedConfirmation);
export const last3Q = DateUtils.tz().subtract(3, 'Q').startOf('Q').toDate();
export const excludeCurrentQuarter = DateUtils.tz().subtract(1, 'Q').endOf('Q').toDate();

export const labelTranslateKeys = {
    title: `${i18n}.LONG_PERIOD_TITLE`,
    ots: `${i18n}.OTS`,
    assisted: `${i18n}.ASSISTED`,
    pending: `${i18n}.PENDING`,
    lost: `${i18n}.LOST`,
    total: `${i18n}.TOTAL`,

    otsSub: `${i18n}.OTS_SUB`,
    assistedSub: `${i18n}.ASSISTED_SUB`,
    pendingSub: `${i18n}.PENDING_SUB`,
    lostSub: `${i18n}.LOST_SUB`,

    flowLp: 'MOVE.CREATED_BY_FLOW.LP.EXTENSION',
    flowOts: 'MOVE.CREATED_BY_FLOW.OTS.EXTENSION',
};

export const mapTypeToLabel = {
    [EnergyOrderedConfirmation.Ots]: labelTranslateKeys.ots,
    [EnergyOrderedConfirmation.Assisted]: labelTranslateKeys.assisted,
    [EnergyOrderedConfirmation.Pending]: labelTranslateKeys.pending,
    [EnergyOrderedConfirmation.Lost]: labelTranslateKeys.lost,
};

export const mapLabelToSubLabel = {
    [labelTranslateKeys.ots]: labelTranslateKeys.otsSub,
    [labelTranslateKeys.assisted]: labelTranslateKeys.assistedSub,
    [labelTranslateKeys.pending]: labelTranslateKeys.pendingSub,
    [labelTranslateKeys.lost]: labelTranslateKeys.lostSub,
};

export const legend: ChartLegend[] = Object.values(EnergyOrderedConfirmation).map((type, i) => ({
    label: mapTypeToLabel[type],
    color: stackColors[i],
}));
