import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { ContractService } from '@app/contract/services/contract.service';
import { checkboxRequired } from '@app/form/validators/checkbox-required.validator';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { Legal } from '@app/move/interfaces/legal';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { SepaModalComponent } from '@app/wizard/legal/modals/sepa-modal/sepa-modal.component';
import { CheckInput } from '@smooved/ui';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-confirm-container',
    template: `
        <div class="u-flex-row u-flex-justify-content-center u-padding-top-triple u-padding-right u-padding-bottom-triple u-padding-left">
            <div class="u-container">
                <app-card>
                    <form [formGroup]="form" class="u-w100p u-flex-column u-flex-align-items-start" (ngSubmit)="onSubmit($event)">
                        <h3>{{ 'CONFIRMATION' | translate }}</h3>
                        <p class="u-color-muted u-margin-bottom-triple">{{ 'STEP_LAST' | translate }}</p>
                        <div class="u-flex-responsive u-flex-align-items-start u-margin-bottom-double u-w100p">
                            <div
                                class="u-flex-column u-flex-align-items-end __suggestion u-bordered u-padding-top u-padding-right-double u-padding-bottom u-padding-left"
                                [ngClass]="{
                                    'u-margin-right': uiSandbox.tabletPortraitUp$ | async,
                                    'u-margin-bottom u-w100p': uiSandbox.upToAndIncludingPhoneLandscape$ | async
                                }"
                                *ngIf="(moveSandbox.canOrder$({ energy: true }) | async) && !!(moveSandbox.energyOfferSelection$ | async)"
                            >
                                <a
                                    class="u-link-muted u-font-size-small u-margin-bottom-half"
                                    *ngIf="!(moveSandbox.landAgent$ | async)"
                                    routerLink="/energy/energy-suggestions"
                                    >{{ 'EDIT_CHOICE' | translate }}</a
                                >

                                <app-suggestion-selection
                                    [showPaymentType]="true"
                                    [type]="uiOfferType.Energy"
                                    [paymentType]="moveSandbox.energyPaymentType$ | async"
                                    [suggestion]="moveSandbox.energySuggestionSelection$ | async"
                                ></app-suggestion-selection>
                            </div>

                            <div
                                class="__suggestion u-bordered u-padding-top u-padding-right-double u-padding-bottom u-padding-left"
                                [ngClass]="{
                                    'u-margin-right': uiSandbox.tabletPortraitUp$ | async,
                                    'u-margin-bottom u-w100p': uiSandbox.upToAndIncludingPhoneLandscape$ | async
                                }"
                                *ngIf="(moveSandbox.canOrder$({ energy: true }) | async) && !!(moveSandbox.energyTransfer$ | async)"
                            >
                                <app-suggestion-selection [type]="uiOfferType.EnergyTransfer"></app-suggestion-selection>
                            </div>

                            <div
                                *ngIf="(moveSandbox.canOrder$({ telecom: true }) | async) && !!(moveSandbox.telecomOfferSelection$ | async)"
                                [ngClass]="{
                                    'u-margin-right': uiSandbox.tabletPortraitUp$ | async,
                                    'u-margin-bottom u-w100p': uiSandbox.upToAndIncludingPhoneLandscape$ | async
                                }"
                                class="u-flex-column u-flex-align-items-end __suggestion u-bordered u-padding-top u-padding-right-double u-padding-bottom u-padding-left"
                            >
                                <a class="u-link-muted u-font-size-small u-margin-bottom-half" routerLink="/telecom/suggestions">{{
                                    'EDIT_CHOICE' | translate
                                }}</a>

                                <app-suggestion-selection
                                    [showPaymentType]="true"
                                    [type]="uiOfferType.Telecom"
                                    [paymentType]="moveSandbox.telecomPaymentType$ | async"
                                    [suggestion]="moveSandbox.telecomOfferSelection$ | async"
                                ></app-suggestion-selection>
                            </div>

                            <div
                                class="__suggestion u-bordered u-padding-top u-padding-right-double u-padding-bottom u-padding-left"
                                [ngClass]="{
                                    'u-margin-right': uiSandbox.tabletPortraitUp$ | async,
                                    'u-margin-bottom u-w100p': uiSandbox.upToAndIncludingPhoneLandscape$ | async
                                }"
                                *ngIf="(moveSandbox.canOrder$({ telecom: true }) | async) && !!(moveSandbox.telecomTransfer$ | async)"
                            >
                                <app-suggestion-selection [type]="uiOfferType.TelecomTransfer"></app-suggestion-selection>
                            </div>
                        </div>

                        <app-suppliers-terms-and-conditions
                            [hasMarginDouble]="true"
                            [hasMargin]="false"
                            formControlName="suppliersTermsAndConditions"
                        ></app-suppliers-terms-and-conditions>

                        <app-smooved-terms-and-conditions
                            [hasMarginDouble]="true"
                            *ngIf="showSmoovedTermsAndConditions"
                            [hasMargin]="false"
                            formControlName="smoovedTermsAndConditions"
                        ></app-smooved-terms-and-conditions>

                        <app-check-input
                            formControlName="sepa"
                            [width]="'auto'"
                            *ngIf="moveSandbox.sepaRequired$ | async"
                            class="u-display-block u-margin-bottom-double"
                            [custom]="false"
                            [option]="sepaOption"
                        >
                            <span
                                >{{ 'SEPA' | translate }}
                                <a (click)="showMore($event)" class="u-link u-color-muted">{{ 'COMMON.MORE_INFO' | translate }}</a></span
                            >
                        </app-check-input>

                        <app-button type="submit" class="u-w100p-up-to-and-including-phone-landscape">{{
                            'SUBMIT' | translate
                        }}</app-button>
                    </form>
                </app-card>
            </div>
        </div>
    `,
    styleUrls: ['./confirm.container.scss'],
})
export class ConfirmContainer implements OnInit {
    public uiOfferType = UiOfferType;

    public form: UntypedFormGroup = this.formBuilder.group({
        suppliersTermsAndConditions: [null, checkboxRequired],
        smoovedTermsAndConditions: [null],
        sepa: [null],
    });

    // @ViewChild('sepaTemplate') public sepaTemplate: TemplateRef<any>;

    public sepaOption: CheckInput<boolean> = {
        id: 'sepa',
        name: 'sepa',
        value: true,
    };

    public move: Move;

    public showSmoovedTermsAndConditions: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authenticationSandbox: AuthenticationSandbox,
        private dialog: MatDialog,
        public moveSandbox: MoveSandbox,
        public uiSandbox: AppUiSandbox,
        private route: ActivatedRoute,
        private contractService: ContractService
    ) {}

    public ngOnInit(): void {
        const accessToken: string = this.route.snapshot.queryParams['at'];
        const id: string = this.route.snapshot.params['id'];

        if (accessToken && id) {
            this.authenticationSandbox.isGuest$.pipe(take(1)).subscribe((isGuest) => {
                if (isGuest) {
                    this.authenticationSandbox.loginByAccessTokenAndId(accessToken, id).subscribe();
                } else {
                    this.moveSandbox.getByAccessTokenAndId(accessToken, id, true);
                }
            });
        }

        combineLatest(this.moveSandbox.move$, this.moveSandbox.sepaRequired$)
            .pipe(
                filter(([move, _]) => !!move?._id),
                take(1)
            )
            .subscribe(([move, sepaRequired]) => {
                this.move = move;
                if (sepaRequired) {
                    this.sepaFormControl().setValidators(checkboxRequired);
                }
                this.showSmoovedTermsAndConditions = !!move._id && !move?.legal?.smoovedTermsAndConditions;
                if (this.showSmoovedTermsAndConditions) {
                    this.smoovedTermsAndConditionsFormControl().setValidators(checkboxRequired);
                }
            });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            const accessToken: string = this.route.snapshot.queryParams['at'];
            const id: string = this.route.snapshot.params['id'];

            const legalPatch: Legal = {
                suppliersTermsAndConditions: true,
            };

            if (this.showSmoovedTermsAndConditions) {
                legalPatch.smoovedTermsAndConditions = true;
            }

            this.contractService.confirmMove(id, accessToken, legalPatch);
        }
    }

    public showMore(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(SepaModalComponent);
    }

    private sepaFormControl(): AbstractControl {
        return this.form?.get('sepa');
    }

    private smoovedTermsAndConditionsFormControl(): AbstractControl {
        return this.form?.get('smoovedTermsAndConditions');
    }
}
