import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/interfaces/order';
import { dateTimeFormatDefault } from '@smooved/core';

@Component({
    selector: 'app-check-item-created-on',
    template: `
        <app-check-item [checked]="!!order.createdOn" align="top">
            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="unchecked">
                <ng-container *ngIf="label; else defaultLabel">{{ label }}</ng-container>
            </div>

            <div class="u-flex-column u-flex-align-items-start" ngProjectAs="checked">
                <ng-container *ngIf="label; else defaultLabel">{{ label }}</ng-container>
                <p class="u-color-muted u-font-size-small">{{ order.createdOn | formatDate: dateTimeFormat }}</p>
                <ng-content></ng-content>
            </div>
        </app-check-item>

        <ng-template #defaultLabel>{{ 'ORDERED' | translate }}</ng-template>
    `,
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckItemCreatedOnComponent {
    @Input() public order: Order;
    @Input() public label: string;

    public dateTimeFormat = dateTimeFormatDefault;
}
