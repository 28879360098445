import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';

@Component({
    selector: 'app-insurances-proposal-url',
    template: `
        <a
            class="u-link u-disabled"
            target="_blank"
            [ngClass]="{ 'u-disabled': !move?.insurancesProposal?.proposalUrl }"
            [href]="move?.insurancesProposal?.proposalUrl"
            >{{ 'INSURANCES.OPEN_PROPOSAL_URL' | translate }}</a
        >
    `,
    styles: [],
})
export class InsurancesProposalUrlComponent {
    @Input() public move: Move;
}
