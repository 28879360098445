import { AbstractControl, ValidationErrors } from '@angular/forms';
import { RegexUtils } from '@smooved/core';

export class PasswordValidators {
    public static validatePolicy(control: AbstractControl): ValidationErrors {
        if (!control?.value) return null;
        const valid =
            RegexUtils.matchAtLeastOneLowerCase(control.value) &&
            RegexUtils.matchAtLeastOneUpperCase(control.value) &&
            RegexUtils.matchAtLeastOneDecimal(control.value) &&
            RegexUtils.matchAtLeastSpecialChar(control.value) &&
            RegexUtils.matchMinMaxChars(control.value, 12);
        return !valid ? { pwPolicy: true } : null;
    }
}
