import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Directive, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Directive()
export abstract class BaseModalComponent implements AfterViewInit {
    constructor(
        protected cdr: ChangeDetectorRef,
        protected dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public abstract header: string;
    public abstract subheader: string;
    public component: ComponentType<any>;
    public input: object = {};
    public output: object = {};

    public ngAfterViewInit(): void {
        Object.assign(this as object, { ...this.input, ...this.output });
        this.cdr.markForCheck();
    }

    public close(result?: any): void {
        this.dialogRef.close(result);
    }
}
