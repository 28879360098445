import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AgreementEffects } from './state/agreement.effects';
import { agreementReducer } from './state/agreement.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('agreement', agreementReducer), EffectsModule.forFeature([AgreementEffects])],
})
export class AgreementModule {}
