import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { concatMap, tap } from 'rxjs/operators';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';
import { LocalStorageKeys } from '../../../storage/sandboxes/storage.constants';

@Component({
    selector: 'app-confirm-user-login-container',
    template: ``,
    styles: [],
})
export class ConfirmUserLoginContainer implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private authenticationSandbox: AuthenticationSandbox,
        private moveSandbox: MoveSandbox,
        private navigationSandbox: AppNavigationSandbox,
        private storageSandbox: StorageSandbox
    ) {}

    public ngOnInit(): void {
        const accessToken: string = this.activatedRoute.snapshot.queryParams['at'];
        if (accessToken) {
            this.authenticationSandbox
                .confirmUserLogin({ accessToken })
                .pipe(
                    tap((_) => this.storageSandbox.deleteToken(LocalStorageKeys.Language)), //delete any cookie language storage in order to get logged in user language
                    concatMap((_) => this.moveSandbox.initSessionForMover())
                )
                .subscribe(
                    (response) => {
                        this.goToStart();
                    },
                    (_) => this.goToStart()
                );
        } else {
            this.goToStart();
        }
    }

    private goToStart(): void {
        this.navigationSandbox.goToStart();
    }
}
