import { ChangeDetectorRef, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'app-table-column-moving-date',
    template: `
        <ng-container matColumnDef="movingDate" *ngIf="withSort">
            <th mat-header-cell mat-sort-header class="__cell u-padding-left u-padding-right" *matHeaderCellDef>
                {{ 'ADMIN.DASHBOARD.TABLE.MOVING_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="__cell u-padding-left u-padding-right">
                <div class="u-text-truncate">{{ row.formattedMovingDate }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="movingDate" *ngIf="!withSort">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>
                {{ 'ADMIN.DASHBOARD.TABLE.MOVING_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="__cell u-padding-left u-padding-right">
                <div class="u-text-truncate">{{ row.formattedMovingDate }}</div>
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-moving-date.component.scss'],
})
export class TableColumnMovingDateComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    @Input() public withSort = true;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
