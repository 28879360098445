import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moveUri from '@app/move/constants/uri.constants';
import { MoveCountByCreatedFlowPerQuarter } from '@app/move/interfaces/move-count-by-created-flow-per-quarter';
import { MovingDateVsCreatedOnGap } from '@app/move/interfaces/moving-date-vs-created-on-gap';
import { PerUnitOfTime } from '@app/shared/enums/per-unit-time.enum';
import { RealEstateLevel } from '@smooved/core';
import { Observable } from 'rxjs';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class SmoovedAnalyticsService {
    constructor(private readonly httpClient: HttpClient) {}

    public getMoveCountByCreatedFlow(
        from: Date,
        until: Date,
        perUnit: PerUnitOfTime,
        level: RealEstateLevel
    ): Observable<MoveCountByCreatedFlowPerQuarter[]> {
        const httpParams: HttpParams = new HttpParams()
            .set('level', level)
            .set('from', from.toISOString())
            .set('until', until.toISOString())
            .set('perUnit', perUnit);

        return this.httpClient.get<MoveCountByCreatedFlowPerQuarter[]>(moveUri.createdByFlowQuarterlyUri, {
            params: httpParams,
        });
    }

    public getMovingDateVsCreatedOnGap(
        from: Date,
        until: Date,
        perUnit: PerUnitOfTime,
        level: RealEstateLevel
    ): Observable<MovingDateVsCreatedOnGap> {
        const httpParams: HttpParams = new HttpParams()
            .set('level', level)
            .set('from', from.toISOString())
            .set('until', until.toISOString())
            .set('perUnit', perUnit);

        return this.httpClient.get<MovingDateVsCreatedOnGap>(moveUri.movingDateVsCreatedOnGapUri, {
            params: httpParams,
        });
    }
}
