import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from '@smooved/core';
import { Entry, Row, TableDataSourceConfig } from './analytics-move-count-table.interfaces';

@Injectable()
export class TableDataSource<T extends Row> {
    public data = new Map<string, T[]>();
    public initialList: T[];
    public rowFactory: () => T;
    public config: TableDataSourceConfig;

    constructor(private readonly translate: TranslateService) {}

    public static sortByGroupTotalsDesc = <T extends Row>([, a]: Entry<T>, [, b]: Entry<T>): number => {
        const totalA = ArrayUtils.sum(a.map((v) => v.total));
        const totalB = ArrayUtils.sum(b.map((v) => v.total));
        return totalB - totalA;
    };

    public static sortByTotalsDesc = <T extends Row>(a: T, b: T): number => b.total - a.total;

    public set(list: T[], rowFactory: () => T, config = {} as TableDataSourceConfig): this {
        this.initialList = list;
        this.rowFactory = rowFactory;
        this.groupBy((item: T) => item.group);
        this.config = config;
        return this;
    }

    public groupBy(keyGetter: (_: T) => string): this {
        const map = new Map<string, T[]>();
        this.initialList.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        this.data = map;
        return this;
    }

    public ungroup(): T[] {
        const ungroupedList = [...this.data.entries()].reduce((list: T[], [key, values]: Entry<T>) => {
            if (key) list.push(this.groupRowFactory(key, values));
            list.push(...values.map((v: T) => v));
            return list;
        }, []);

        if (this.config.tableTotals) ungroupedList.unshift(this.totalRowFactory());
        return ungroupedList;
    }

    public sortChildren(compareFn: (a: T, b: T) => number): this {
        this.data = new Map(
            [...this.data.entries()].map(([key, values]: Entry<T>) => {
                values.sort(compareFn);
                return [key, values];
            })
        );
        return this;
    }

    public sortGroups(compareFn: (a: Entry<T>, b: Entry<T>) => number): this {
        this.data = new Map([...this.data.entries()].sort(compareFn));
        return this;
    }

    private groupRowFactory(key: string, values: T[]): T {
        const groupRow = this.rowFactory();
        groupRow.group = key;
        groupRow.isGroup = true;
        groupRow.label = key;

        if (this.config.groupTotals) {
            this.setTotals(groupRow, values);
        }
        return groupRow;
    }

    private totalRowFactory(): T {
        const totalsRow = this.rowFactory();
        totalsRow.label = this.translate.instant('TOTAL') as string;
        totalsRow.isTotal = true;
        this.setTotals(totalsRow, ArrayUtils.flatMap([...this.data.entries()]));
        return totalsRow;
    }

    private setTotals(current: T, values: T[]): void {
        Object.keys(current).forEach((key: string) => {
            if (typeof current[key] === 'number') {
                current[key] = ArrayUtils.sum(values.map((v: T) => v[key] as number));
            }
        });
    }
}
