export const baseRoute = 'partner';
export const loginRoute = 'login';
export const orderListRoute = 'order-list';
export const openRoute = 'open';
export const inProgressRoute = 'in-progress';
export const closedRoute = 'closed';

export const baseRouting = [baseRoute];
export const loginRouting = [...baseRouting, loginRoute];
export const orderListRouting = [...baseRouting, orderListRoute];
export const orderListOpenRouting = [...orderListRouting, openRoute];
export const orderListClosedRouting = [...orderListRouting, closedRoute];
export const orderListInProgressRouting = [...orderListRouting, inProgressRoute];
