<form [formGroup]="form" #formElement="ngForm">
    <app-label-container [label]="label | translate" [required]="true" class="u-display-block u-w100p">
        <div class="u-flex-responsive u-w100p" [formGroupName]="telecomBundleForm.TelecomBundle">
            <app-check-input
                *ngFor="let option of telecomBundleOptions; let last = last; trackBy: trackByOptionId"
                [formControlName]="option.name"
                class="u-flex-1-1-0"
                [class.u-margin-right]="!last"
                [ignoreMinWidth]="true"
                [option]="option"
            ></app-check-input>
        </div>
    </app-label-container>

    <app-label-container
        *ngIf="showDigitalTV$ | async"
        [label]="'TELECOM.TELECOM_BUNDLE.DIGITAL_STREAM_TELEVISION.LABEL' | translate"
        class="u-margin-top"
        [required]="true"
    >
        <app-select-input
            [custom]="false"
            [formControlName]="telecomBundleForm.DigitalStreamTelevision"
            [options]="digitalStreamTelevisionOptions"
            direction="vertical"
        ></app-select-input>
    </app-label-container>
</form>
