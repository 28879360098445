import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-insurances-suggestions-loading',
    template: `
        <div class="u-container u-margin-0-auto">
            <app-card class="u-flex-column u-flex-align-items-center" [ngSwitch]="loading">
                <div [ngClass]="{ 'u-hidden': !loading }" class="u-flex-row u-flex-justify-content-center u-margin-bottom-double">
                    <mat-progress-spinner [diameter]="32" [strokeWidth]="4" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>

                <h3 class="u-margin-bottom-triple u-text-align-center u-container-small">
                    {{ 'INSURANCES.SUGGESTIONS.LOADING.TITLE' | translate }}
                </h3>

                <app-button *ngSwitchCase="false" type="button" class="u-margin-bottom-triple" (onClick)="next.emit()">{{
                    'INSURANCES.SUGGESTIONS.LOADING.SHOW' | translate
                }}</app-button>

                <div *ngSwitchCase="true" class="u-flex-row u-flex-justify-content-center u-margin-bottom-triple">
                    <img
                        *ngFor="let image of imagesUrls; let i = index"
                        height="60"
                        [src]="image"
                        [ngClass]="{ 'u-display-none': !isActiveImage(i) }"
                    />
                </div>

                <app-check-list class="u-container-extra-small u-display-block u-margin-bottom-double" [content]="uspList"></app-check-list>

                <p
                    class="u-container-extra-small u-font-size-small u-color-muted u-margin-bottom-half"
                    [innerHTML]="'INSURANCES.SUGGESTIONS.LOADING.DISCLAIMER1' | translate"
                ></p>
                <p
                    class="u-container-extra-small u-font-size-small u-color-muted u-margin-bottom-half"
                    [innerHTML]="'INSURANCES.SUGGESTIONS.LOADING.DISCLAIMER2' | translate"
                ></p>
                <p
                    class="u-container-extra-small u-font-size-small u-color-muted"
                    [innerHTML]="'INSURANCES.SUGGESTIONS.LOADING.DISCLAIMER3' | translate"
                ></p>
            </app-card>
        </div>
    `,
    styleUrls: ['./insurances-suggestions-loading.component.scss'],
})
export class InsurancesSuggestionsLoadingComponent implements OnInit {
    @Input() public loading: boolean;
    @Output() public next: EventEmitter<void> = new EventEmitter<void>();

    public imagesUrls: string[] = [
        'https://home.hopala.be/assets/img/logo/baloise.png',
        'https://home.hopala.be/assets/img/logo/optimco.png',
        'https://home.hopala.be/assets/img/logo/allianz.jpg',
        'https://home.hopala.be/assets/img/logo/fidea.png',
    ];

    public activeImageIndex = 0;

    public uspList = [1, 2, 3].map((number) => this.translateService.instant(`INSURANCES.SUGGESTIONS.LOADING.USP${number}`));

    constructor(private translateService: TranslateService) {}

    public ngOnInit(): void {
        setInterval(() => {
            if (this.activeImageIndex >= this.imagesUrls?.length - 1) {
                this.activeImageIndex = 0;
            } else {
                this.activeImageIndex++;
            }
        }, 1000);
    }

    public isActiveImage(index: number): boolean {
        return index === this.activeImageIndex;
    }
}
