import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as uri from '@app/order/constants/uri.constants';
import { CreateOrderRentalInspection } from '@app/order/interfaces/create-order-rental-inspection';
import { Order } from '@app/order/interfaces/order';
import { DbUtils, ObjectUtils } from '@smooved/core';
import { Observable } from 'rxjs';

@Injectable()
export class OrderCreateService {
    constructor(private readonly httpClient: HttpClient) {}

    public execute({ product, move, agreementDocument, deliveryDate, message }: CreateOrderRentalInspection): Observable<Order> {
        const payload = new FormData();
        const formattedOrder = {
            product: DbUtils.getStringId(product),
            move,
            agreementDocument,
            deliveryDate,
            message,
        };
        ObjectUtils.removeEmpty(formattedOrder);
        Object.entries(formattedOrder).forEach(this.appendTo(payload));
        return this.httpClient.post<Order>(uri.rentalInspectionUri, payload);
    }

    private appendTo(payload: FormData) {
        return ([key, value]): void => {
            if (value instanceof Date) payload.append(key, value.toISOString());
            else if (value instanceof File) payload.append('files', value, value.name);
            else payload.append(key, value);
        };
    }
}
